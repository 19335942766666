import { gql } from 'apollo-boost';

//! -------------------User---------------------------------

export const RESEND_VERIFICATION_EMAIL = gql`
  mutation RESEND_VERIFICATION_EMAIL {
    UserOps {
      resendVerification {
        error
        message
      }
    }
  }
`;

export const USER_RESET_PASSWORD = gql`
  mutation UserForgotPassword($email: String!, $type: String) {
    UserOps {
      forgotPassword(email: $email, type: $type) {
        error
        message
      }
    }
  }
`;

export const SCHEDULE_USER_TO_DELETE = gql`
  mutation UserDelete($cancel: Boolean) {
    UserOps {
      scheduleToDelete(cancel: $cancel) {
        error
        message
      }
    }
  }
`;

export const ADD_PAYMENT_METHOD = gql`
  mutation ADD_PAYMENT_METHOD($cvc: String!, $number: String!, $expiry: String!, $name: String!) {
    UserOps {
      addCreditCard(number: $number, name: $name, expiry: $expiry, cvc: $cvc) {
        error
        message
      }
    }
  }
`;
export const UPGRADE_PLAN = gql`
  mutation ($settings: UpdateHexomaticCreditsPackageInput) {
    HexomaticUserOps {
      updateHexomaticCreditsPackage(settings: $settings) {
        required_redirect_url
        error_code
      }
    }
  }
`;

export const UPDATE_HEXOMATIC_USER_SETTINGS = gql`
  mutation ($settings: CreateUserSettingsInput) {
    HexomaticUserOps {
      createUserSettings(settings: $settings) {
        send_mail
        allow_sharing_recipe
        allow_sharing_workflow
        error_code
      }
    }
  }
`;

export const UPDATE_PREMIUM_CREDITS = gql`
  mutation ($settings: UpdateMainUserPremiumCreditsPackageInput) {
    UserOps {
      updateMainUserPremiumCreditsPackage(settings: $settings) {
        error_code
      }
    }
  }
`;

export const UPDATE_USER_INFORMATION = gql`
  mutation UpdateUserInformation($settings: UserInformationInput) {
    UserOps {
      updateInformation(settings: $settings) {
        error
        message
      }
    }
  }
`;

export const ONBOARDING_SURVEY = gql`
  mutation ONBOARDING_SURVEY($settings: CompleteSurvey) {
    UserOps {
      completeSurvey(settings: $settings) {
        error_code
      }
    }
  }
`;

//!--------------------Recipes---------------------------

export const IGNORE_RECIPE_SHARE_UPDATES = gql`
  mutation IGNORE_RECIPE_SHARE_UPDATES($settings: IgnoreSharedRecipeUpdatesInput) {
    HexomaticScrapingRecipeOps {
      ignoreSharedRecipeUpdates(settings: $settings) {
        error_code
      }
    }
  }
`;

export const IMPORT_RECIPE = gql`
  mutation IMPORT_RECIPE($settings: ImportSharedScrapingRecipeInput) {
    HexomaticScrapingRecipeOps {
      importSharedScrapingRecipe(settings: $settings) {
        error_code
      }
    }
  }
`;

export const INVALIDATE_SHARE_LINK = gql`
  mutation INVALIDATE_SHARE_LINK($settings: InvalidateRecipeShareLinkInput) {
    HexomaticScrapingRecipeOps {
      invalidateRecipeShareLink(settings: $settings) {
        error_code
      }
    }
  }
`;

export const GENERATE_SHARE_LINK = gql`
  mutation GENERATE_SHARE_LINK_FOR_ANYONE($settings: GenerateRecipeShareLinkInput) {
    HexomaticScrapingRecipeOps {
      generateRecipeShareLink(settings: $settings) {
        link
        error_code
        restrictedEmails
      }
    }
  }
`;

export const CREATE_RECIPE = gql`
  mutation CREATE_RECIPE($settings: CreateScrapingRecipeInput) {
    HexomaticScrapingRecipeOps {
      createScrapingRecipe(settings: $settings) {
        error_code
      }
    }
  }
`;

export const DELETE_RECIPE = gql`
  mutation DELETE_RECIPE($settings: DeleteScrapingRecipeBulkInput) {
    HexomaticScrapingRecipeOps {
      deleteScrapingRecipeBulk(settings: $settings) {
        error_code
      }
    }
  }
`;

export const DUPLICATE_SCRAPING_RECIPE = gql`
  mutation ($settings: DuplicateScrapingRecipe) {
    HexomaticScrapingRecipeOps {
      duplicateScrapingRecipe(settings: $settings) {
        copiedRecipeId
        error_code
      }
    }
  }
`;

export const UPDATE_SCRAPING_RECIPE = gql`
  mutation ($settings: UpdateScrapingRecipeInput) {
    HexomaticScrapingRecipeOps {
      updateScrapingRecipe(settings: $settings) {
        error_code
      }
    }
  }
`;

export const MAKE_PUBLIC_RECIPE = gql`
  mutation ($settings: CreatePublicScrapingRecipeInput) {
    HexomaticPublicScrapingRecipeOps {
      createPublicScrapingRecipe(settings: $settings) {
        error_code
      }
    }
  }
`;
export const UPDATE_SCRAPING_PUBLIC_RECIPE = gql`
  mutation ($settings: UpdatePublicScrapingRecipeInput) {
    HexomaticPublicScrapingRecipeOps {
      updatePublicScrapingRecipe(settings: $settings) {
        error_code
      }
    }
  }
`;

//!-------------Workflows--------------------

export const IGNORE_WORKFLOW_SHARE_UPDATES = gql`
  mutation IGNORE_WORKFLOW_SHARE_UPDATES($settings: IgnoreSharedWorkflowUpdatesInput) {
    HexomaticWorkflowOps {
      ignoreSharedWorkflowUpdates(settings: $settings) {
        error_code
      }
    }
  }
`;

export const IMPORT_WORKFLOW = gql`
  mutation IMPORT_WORKFLOW($settings: ImportSharedWorkflowInput) {
    HexomaticWorkflowOps {
      importSharedWorkflow(settings: $settings) {
        error_code
      }
    }
  }
`;

export const INVALIDATE_WORKFLOW_SHARE_LINK = gql`
  mutation INVALIDATE_WORKFLOW_SHARE_LINK($settings: InvalidateWorkflowShareLinkInput) {
    HexomaticWorkflowOps {
      invalidateWorkflowShareLink(settings: $settings) {
        error_code
      }
    }
  }
`;

export const GENERATE_WORKFLOW_SHARE_LINK = gql`
  mutation GENERATE_WORKFLOW_SHARE_LINK($settings: GenerateWorkflowShareLinkInput) {
    HexomaticWorkflowOps {
      generateWorkflowShareLink(settings: $settings) {
        link
        restrictedEmails
        restrictedAutomations
        error_code
      }
    }
  }
`;

export const CREATE_WORKFLOW = gql`
  mutation CREATE_WORKFLOW($settings: CreateNewWorkflowInput) {
    HexomaticWorkflowOps {
      createNewWorkflow(settings: $settings) {
        error_code
        workflowId
      }
    }
  }
`;

export const DUPLICATE_WORKFLOW = gql`
  mutation DUPLICATE_WORKFLOW($settings: DuplicateWorkflowInput) {
    HexomaticWorkflowOps {
      duplicateWorkflow(settings: $settings) {
        copiedWorkflowId
        error_code
      }
    }
  }
`;

export const UPDATE_WORKFLOW = gql`
  mutation UPDATE_WORKFLOW($settings: UpdateWorkflowInput) {
    HexomaticWorkflowOps {
      updateWorkflow(settings: $settings) {
        error_code
      }
    }
  }
`;

export const DELETE_WORKFLOW = gql`
  mutation DELETE_WORKFLOW($settings: DeleteWorkflowInput) {
    HexomaticWorkflowOps {
      deleteWorkflows(settings: $settings) {
        error_code
      }
    }
  }
`;

export const CHANGE_WORKFLOW_STATE = gql`
  mutation CHANGE_WORKFLOW_STATE($settings: UpdateWorkflowStateBulkInput) {
    HexomaticWorkflowOps {
      updateWorkflowStateBulk(settings: $settings) {
        error_code
      }
    }
  }
`;

export const STOP_IN_PROGRESS_WORKFLOW = gql`
  mutation STOP_IN_PROGRESS_WORKFLOW($settings: UpdateInprogressWorkflowInput) {
    HexomaticWorkflowOps {
      updateInprogressWorkflow(settings: $settings) {
        error_code
      }
    }
  }
`;

export const CREATE_NEW_PUBLIC_WORKFLOW = gql`
  mutation CREATE_NEW_PUBLIC_WORKFLOW($settings: CreateNewPublicWorkflowInput) {
    HexomaticPublicWorkflowOps {
      createNewPublicWorkflow(settings: $settings) {
        error_code
      }
    }
  }
`;

export const UPDATE_PUBLIC_WORKFLOW = gql`
  mutation UPDATE_PUBLIC_WORKFLOW($settings: updatePublicWorkflowInput) {
    HexomaticPublicWorkflowOps {
      updatePublicWorkflow(settings: $settings) {
        error_code
      }
    }
  }
`;

export const DELETE_PUBLIC_WORKFLOWS = gql`
  mutation DELETE_PUBLIC_WORKFLOWS($settings: DeletePublicWorkflowInput) {
    HexomaticPublicWorkflowOps {
      deletePublicWorkflows(settings: $settings) {
        error_code
      }
    }
  }
`;

export const CREATE_API_KEY = gql`
  mutation {
    HexomaticUserOps {
      createWatchApiKey {
        watch_api_key
        error_code
      }
    }
  }
`;

export const ADD_SHORTCUT_LOG = gql`
  mutation addShortCutLog($type: String) {
    ShortcutLogOps {
      addShortcutLog(type: $type) {
        error
        message
      }
    }
  }
`;
//-------------Stripe/Payment/Mutation--------------------

export const SUBMIT_ENTERPRISE = gql`
  mutation SUBMIT_ENTERPRISE($settings: CreateEnterpriseSectionInput) {
    EnterpriseSectionOps {
      sendEmail(settings: $settings) {
        error
        message
      }
    }
  }
`;

export const ADD_CARD = gql`
  mutation addCard($settings: AddCardInput) {
    BillingOps {
      addCard(settings: $settings) {
        error_code
        cardId
      }
    }
  }
`;

export const DELETE_CREDIT_CARD = gql`
  mutation deleteCreditCard($settings: DeleteCardInput) {
    BillingOps {
      deleteCreditCard(settings: $settings) {
        error_code
      }
    }
  }
`;

export const UPDATE_STRIPE_CUSTEMER = gql`
  mutation updateStripeCustemer($settings: UpdateStripeCustomerInput) {
    BillingOps {
      updateStripeCustomer(settings: $settings) {
        error_code
      }
    }
  }
`;
export const CREATE_PAYMENT_INTENT = gql`
  mutation createPaymentIntent($settings: CreatePaymentIntentInput) {
    BillingOps {
      createPaymentIntent(settings: $settings) {
        error_code
      }
    }
  }
`;
export const CREATE_SUBSCRIBTION = gql`
  mutation createSubscription($settings: CreateSubscriptionInput) {
    BillingOps {
      createSubscription(settings: $settings) {
        error_code
      }
    }
  }
`;
export const CANCEL_SUBSCRIPTION = gql`
  mutation cancelSubscription($settings: CancelSubscriptionInput) {
    BillingOps {
      cancelSubscription(settings: $settings) {
        error_code
      }
    }
  }
`;

export const SUBSCRIBTION = gql`
  mutation updateUserHexomaticPackage($settings: UpdateUserHexomaticPackageInput) {
    HexomaticUserOps {
      updateUserHexomaticPackage(settings: $settings) {
        error_code
        card
        clientSecret
        approveUrl
      }
    }
  }
`;

export const CREDIT_SUBSCRIBTION = gql`
  mutation ($settings: UpdateHexomaticCreditsPricingPackageInput) {
    BillingOps {
      updateMainUserPremiumCreditsPackage(settings: $settings) {
        error_code
        card
        clientSecret
        approveUrl
      }
    }
  }
`;

export const TWO_FACTOR_SETTINGS = gql`
  mutation twofaSettings($status: Boolean, $type: String, $password: String) {
    UserOps {
      twofaSettings(status: $status, type: $type, password: $password) {
        error
        message
      }
    }
  }
`;

export const TWO_FACTOR_APP = gql`
  mutation twofaRegister($productType: String, $password: String, $type: String) {
    UserOps {
      twofaRegister(productType: $productType, password: $password, type: $type) {
        error
        message
        token
        hash
        base32
      }
    }
  }
`;

export const LOG_OUT_ALL_DEVICES = gql`
  mutation logoutFromAllDevices($password: String) {
    UserOps {
      logoutFromAllDevices(password: $password) {
        error
        message
      }
    }
  }
`;
export const LOG_OUT_TWO_FACTOR = gql`
  mutation twofaLogoutFromAllDevices {
    UserOps {
      twofaLogoutFromAllDevices {
        error
        message
      }
    }
  }
`;
export const USER_LOGIN_TWO_FACTOR = gql`
  mutation twofaVerify($email: String!, $code: String!, $productType: String!, $base32: String) {
    UserOps {
      twofaVerify(email: $email, code: $code, productType: $productType, base32: $base32) {
        error
        message
        token
        hash
      }
    }
  }
`;

export const USER_LOGIN = gql`
  mutation UserLogin($email: String!, $password: String!, $productType: String!) {
    UserOps {
      login(email: $email, password: $password, productType: $productType) {
        error
        message
        token
        hash
        twofa_status
        device_key
        refresh_token
      }
    }
  }
`;

export const GET_DEVICES = gql`
  mutation userDeviceList($action: String!, $id: ID!, $status: Boolean!, $password: String, $product_type: String) {
    UserOps {
      userDeviceList(action: $action, id: $id, status: $status, password: $password, product_type: $product_type) {
        list {
          created_at
          updated_at
          user_agent
          product_type
          status
          id
          key
        }
        message
      }
    }
  }
`;
//tags
export const CREATE_USER_WATCH_TAGS = gql`
  mutation ($settings: CreateUserHexomaticTagInput) {
    HexomaticTagOps {
      createUserHexomaticTag(settings: $settings) {
        hexomatic_tag
        error_code
      }
    }
  }
`;

export const DELETE_USER_WATCH_TAGS = gql`
  mutation ($settings: DeleteUserHexomaticTagInput) {
    HexomaticTagOps {
      deleteUserHexomaticTag(settings: $settings) {
        error_code
      }
    }
  }
`;

export const UPDATE_USER_WATCH_TAGS = gql`
  mutation ($settings: UpdateUserHexomaticTagInput) {
    HexomaticTagOps {
      updateUserHexomaticTag(settings: $settings) {
        error_code
      }
    }
  }
`;

export const UPDATE_WORKFLOW_TAGS = gql`
  mutation ($settings: UpdateWorkflowTagsInput) {
    HexomaticTagOps {
      updateWorkflowTags(settings: $settings) {
        error_code
      }
    }
  }
`;

export const GOOGLE_BIG_QUERY_DATA = gql`
  mutation ($action: String!, $value: String!, $query: String!) {
    BigQueryOps {
      query(action: $action, value: $value, query: $query) {
        error
        message
        data
      }
    }
  }
`;
export const LOG_OUT = gql`
  mutation ($id: ID) {
    UserOps {
      logout(id: $id) {
        error
        message
      }
    }
  }
`;

export const SEND_USER_FEEDBACK = gql`
  mutation SEND_USER_FEEDBACK($settings: CreateSuggestionFeedbackInput) {
    HexomaticUserOps {
      createSuggestionFeedback(settings: $settings) {
        error_code
      }
    }
  }
`;

// export const PAYPAL_UPGRADE = gql`
//   mutation executePaypalPostAction($settings: ExecutePaypalPostActionInput) {
//     BillingOps {
//       executePaypalPostAction(settings: $settings) {
//         error_code
//       }
//     }
//   }
// `;

// export const PAYPAL_PAYMENT_APPROVED = gql`
//   mutation paypalPaymentApproved($settings: PaypalPaymentApprovedInput) {
//     BillingOps {
//       paypalPaymentApproved(settings: $settings) {
//         error_code
//       }
//     }
//   }
// `;

export const MAKE_FAVORITE = gql`
  mutation ($settings: AddToFavoritesSettings) {
    FavoriteOpts {
      addToFavorites(settings: $settings) {
        error
        message
      }
    }
  }
`;

export const DELETE_FAVORITE = gql`
  mutation ($favorite_item_ids: [Int], $type: String!) {
    FavoriteOpts {
      deleteFromFavorites(favorite_item_ids: $favorite_item_ids, type: $type) {
        error
        message
      }
    }
  }
`;

export const DISABLE_WORKFLOW_TRIGGER = gql`
  mutation DISABLE_WORKFLOW_TRIGGER($settings: DisableTriggerWorkflow) {
    HexomaticWorkflowOps {
      disableTriggerWorkflow(settings: $settings) {
        error_code
      }
    }
  }
`;

//COMBO PLANS
export const BUY_TIER_STRIPE = gql`
  mutation updateUserComboPackage($settings: UpdateUserComboPackageInput) {
    UserOps {
      updateUserComboPackage(settings: $settings) {
        error_code
        clientSecret
        card
        approveUrl
      }
    }
  }
`;
