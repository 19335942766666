import { FC } from 'react';
import './styles.scss';
interface IRadio {
  checked?: boolean;
  value?: string;
  name?: string;
  onChange?: (val: any) => void;
  onClick?: () => void;
  className?: string;
  border?: boolean;
  label?: string;
}

const Radio: FC<IRadio> = ({ checked, name, value, onChange, onClick, className, border, label }) => {
  const handleChange = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onChange && onChange(e);
  };
  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onClick && onClick();
  };
  return (
    <div className={`radio-wrapper ${className ? className : ''} ${border ? 'border-color-radio' : ''}`}>
      <div className="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          id="rd_2"
          name={name}
          className="custom-control-input cursor-pointer"
          value={value}
          checked={checked}
          onChange={handleChange}
        />
        <label className="custom-control-label red" htmlFor="rd_2" onClick={handleClick}>
          {label || ''}
        </label>
      </div>
    </div>
  );
};

export default Radio;
