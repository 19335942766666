import { FC, useEffect, useState, memo, useRef, lazy } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAutomationGlobalMethods } from '../../hooks';
import { speechContryOptions } from 'automation-settings/constants';
import { isValidUrl, isValidDomain } from 'constants/validations';

import Input from 'shared-components/atom/input';
import Select from 'shared-components/atom/select';
import Datalist from 'shared-components/atom/dataList';
import Alert from 'shared-components/atom/alert-message';
import { cacheFunction } from 'helpers';

const fileTypeOptions = [
  { label: 'mp3', value: 'mp3' },
  { label: 'flac', value: 'flac' },
  { label: 'wav', value: 'wav' },
];

const SpechToText: FC<any> = ({
  automationSendSettings,
  selectedRecipes,
  automationIndex,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);
  const [clicked, setClicked] = useState(false);
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [inputText, setInputText] = useState('');
  const [language, setLanguage] = useState('');
  const [fileType, setFileType] = useState(fileTypeOptions[0]);
  const [errorMessage, setErrorMessage] = useState('');
  const [inputErrorBoder, setInputErrorBoder] = useState('');
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const {
    getPreviousAutomationOutputOrPreviousRecipKeys,
    liftingSettings,
    restoreSelectedSource,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (!language || errorMessage) return false;
    if (fieldList?.length === 0) {
      if (!inputText) {
        return false;
      }
      if (!isValidUrl(inputText) && !isValidDomain(inputText)) {
        return false;
      }
    } else {
      if (!selectedSource) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (inputText) {
      if (!isValidUrl(inputText) || !isValidDomain(inputText)) {
        setInputErrorBoder('true'); // type must be string
      }
      if (isValidUrl(inputText) || isValidDomain(inputText)) {
        setInputErrorBoder('');
      }
    } else setInputErrorBoder('');
  }, [inputText]);

  //getting previous outputs
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const currentAutomation = automationSendSettings.find((automation: any, index: any) => index === automationIndex);
    const { dynamic_settings } = currentAutomation;

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (automationSendSettings) {
      const currentAutomation = automationSendSettings.find((automation: any, index: any) => index === automationIndex);
      const { dynamic_settings } = currentAutomation;
      if (dynamic_settings) {
        const { keys } = dynamic_settings;
        const { settings, key } = keys[0];

        const langCode = settings ? settings.langCode : keys[0].langCode;
        const fileType = settings ? settings.fileType : keys[0].fileType;

        if (fieldList.length === 0) {
          const input = keys[0].input || keys[0].inputValue;
          setInputText(input);
        } else {
          restoreSelectedSource({ key: key, fieldList: fieldList, setSelectedSource: setSelectedSource });
        }
        const findedLanguageFrom = speechContryOptions.find(item => item.value === langCode);
        findedLanguageFrom && setLanguage(findedLanguageFrom.label);
        const foundFileType = fileTypeOptions.find(item => item.value === fileType);
        foundFileType && setFileType(foundFileType);
      }
    }
  }, [fieldList]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [...fieldList.map((item: { value: any }) => item.value), '_audio_transcription'],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);

  //memoize
  const handleSpeechContryOptions = (language: string) => {
    return speechContryOptions.find((item: { label: string; value: string }) => item.label === language);
  };
  const getMemoizedSpeechContryOption = useRef(cacheFunction(handleSpeechContryOptions));

  //lifting data
  useEffect(() => {
    if (!clicked) return;
    // setClicked(false);

    const selectOptionFrom = getMemoizedSpeechContryOption.current(language);
    if (!selectOptionFrom) {
      setCompletedFields({ index: automationIndex, checked: false });
    } else {
      setCompletedFields({ index: automationIndex, checked: checkFields() });
    }
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: {
        keys: [
          {
            inputValue: fieldList.length === 0 && automationIndex === 0 ? inputText.trim() : null,
            key: selectedSource ? selectedSource.value : null,
            settings: {
              langCode: selectOptionFrom ? selectOptionFrom.value : null,
              fileType: fileType.value,
            },
          },
        ],
      },
      outputKeysData: [...fieldList.map((item: { value: any }) => item.value), '_audio_transcription'],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [inputText, selectedSource, language, fileType, errorMessage, automationIndex]);

  return (
    <div>
      <Row>
        {fieldList.length > 0 || automationIndex > 0 ? (
          <Col lg={6} className="mb-4">
            <span className="label secondary-color">Source</span>
            <Select
              options={fieldList}
              value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
              placeholder={'Select source'}
              onChange={(selectedItem: any) => {
                setSelectedSource(selectedItem);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
        ) : (
          <Col lg={6} className="mb-4">
            <span className="label secondary-color">File URL</span>
            <Input
              error={inputErrorBoder}
              value={inputText}
              onChange={e => {
                setInputText(e.target.value);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
        )}
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">File type</span>
          <Select
            options={fileTypeOptions}
            value={fileType}
            placeholder={'Select source'}
            onChange={(selectedItem: any) => {
              setFileType(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6} className="google-translate-select">
          <span className="label secondary-color">Language</span>
          <Datalist
            list={speechContryOptions}
            value={language}
            placeholder={'Select language'}
            onChange={(text: string) => {
              if (text === language) return;
              setLanguage(text);
              setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
      </Row>
      {errorMessage && (
        <Row className="mt-4">
          <Col md={12}>
            <Alert type="error" className="create-workflow">
              {errorMessage}
            </Alert>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default memo(SpechToText);
