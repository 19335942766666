import { FC } from 'react';
import htmlParser from 'react-markdown/plugins/html-parser';
import MarkDown from 'react-markdown/with-html';
import breaks from 'remark-breaks';
import './styles.scss';

interface ITranslateMarkdown {
  text: string;
}

const parseHtml = htmlParser({
  isValidNode: (node: any) => node.type !== 'script',
  processingInstructions: [
    /* ... */
  ],
});

const Markdown: FC<ITranslateMarkdown> = ({ text }) => (
  <div className="react-markdown">
    <MarkDown
      source={text}
      astPlugins={[parseHtml]}
      escapeHtml={false}
      disallowedTypes={true ? ['paragraph'] : []}
      unwrapDisallowed={true}
      plugins={[breaks]}
    />
  </div>
);

export default Markdown;
