import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
import Checkbox from 'shared-components/atom/checkbox';
import { useAutomationGlobalMethods } from '../../hooks';
import Input from 'shared-components/atom/input';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import Button from 'shared-components/atom/button';
import { closeIcon, plusIcon } from 'assets/svg-icons';
import Alert from 'shared-components/atom/alert-message';
import { conditionAssociateObject, conditionOptions } from 'automation-settings/constants';
import './styles.scss';

interface ConditionsType {
  orAnd: null | string;
  keyword: string;
  condition: null | string;
}

const FilterDataByCriteriaSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [fieldList, setFieldList] = useState<any>([]);
  const [clicked, setClicked] = useState(false);
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [removeCells, setRemoveCells] = useState(false);
  const [isSensitive, setIsSensitive] = useState(true);

  const [conditions, setConditions] = useState<ConditionsType[]>([
    {
      orAnd: null,
      keyword: '',
      condition: null,
    },
  ]);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const {
    liftingSettings,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (errorMessage || !selectedSource) return false;
    const condition = conditions?.[conditions?.length - 1];
    if (condition?.condition === null || condition?.keyword === '') {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const { keys } = dynamic_settings;
      const { settings } = keys[0];
      const { caseSensitive } = settings ? settings : { caseSensitive: null };

      const conditionsProp = settings?.conditions;
      conditionsProp && setConditions(conditionsProp);
      typeof settings?.removeEmptyCells === 'boolean' && setRemoveCells(settings?.removeEmptyCells);
      caseSensitive !== null && setIsSensitive(caseSensitive);
    }
  }, []);

  //recipe
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();

    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const key = dynamic_settings.keys[0].key;
      restoreSelectedSource({ key: key, fieldList: options, setSelectedSource: setSelectedSource });
    }
    // setFieldList(options);
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [
          ...fieldList.map((item: { value: any }) => item.value),
          ...(selectedSource ? [`_filtered_data${selectedSource.value}`] : []),
        ],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, automationOutputTypes, fieldListWithTypes]);

  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);

    let localSettings = {
      keys: [
        {
          key: fieldList.length && selectedSource ? selectedSource.value : null,
          inputValue: null,
          settings: {
            conditions: conditions,
            removeEmptyCells: removeCells,
            caseSensitive: isSensitive,
          },
        },
      ],
    };
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: localSettings,
      outputKeysData: [
        ...fieldList.map((item: { value: any }) => item.value),
        ...(selectedSource ? [`_filtered_data${selectedSource.value}`] : []),
      ],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [selectedSource, conditions, removeCells, fieldList, fieldListWithTypes, automationOutputTypes, isSensitive]);

  const onChangeCondition = ({ selectedItem, index }: any) => {
    const newConditions: ConditionsType[] = conditions.map((item: ConditionsType, i: number) => {
      if (i === index) {
        return { ...item, condition: selectedItem.value };
      }
      return item;
    });
    setConditions(newConditions);
    errorMessage && setErrorMessage('');
    !clicked && setClicked(true);
    setIsDirty();
  };

  const onChangeInput = ({ value, index }: any) => {
    const newConditions: ConditionsType[] = conditions.map((item: ConditionsType, i: number) => {
      if (i === index) {
        return { ...item, keyword: value };
      }
      return item;
    });
    setConditions(newConditions);
    errorMessage && setErrorMessage('');
    !clicked && setClicked(true);
    setIsDirty();
  };

  const onDeleteCondition = (index: number) => {
    conditions.length - 1 === index && errorMessage && setErrorMessage('');

    let newConditions: ConditionsType[] = conditions.filter((item: ConditionsType, i: number) => i !== index);
    if (newConditions.length === 1 || index === 0) {
      newConditions = newConditions.map((item: ConditionsType, i: number) => {
        if (i === index) {
          return { ...item, orAnd: null };
        }
        return item;
      });
    }
    setConditions(newConditions);
    !clicked && setClicked(true);
    setIsDirty();
  };

  const onAddCondition = (operator: string) => {
    const condition: ConditionsType = conditions[conditions.length - 1];
    if (condition.condition === null || condition.keyword === '') {
      setErrorMessage('Please fill in the current condition before adding a new one.');
      return;
    }
    const newConditions: ConditionsType[] = [...conditions];
    newConditions.push({
      orAnd: operator,
      keyword: '',
      condition: null,
    });
    setConditions(newConditions);
    !clicked && setClicked(true);
    setIsDirty();
  };
  const handleChangeSensitive = () => {
    setIsSensitive(!isSensitive);
    !clicked && setClicked(true);
    setIsDirty();
  };
  return (
    <div className="condition-automation-settings">
      <Row>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Source</span>
          <Select
            options={fieldList}
            value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
            placeholder={'Select source'}
            onChange={(selectedItem: any) => {
              setSelectedSource(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
        <Col xs={12} lg={6} className="checkbox-wrapper">
          <div className="item-block mt-4">
            <Checkbox
              checked={removeCells}
              label="Remove empty cells"
              onChange={() => {
                setRemoveCells(!removeCells);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
            <Checkbox checked={isSensitive} label="Case sensitive" onChange={handleChangeSensitive} />
          </div>
        </Col>
      </Row>
      {conditions.map((item: any, index: number) => (
        <>
          {item.orAnd && (
            <Row>
              <Col xl={12}>
                <div className="operator-row">
                  <div className="line"></div>
                  <span className="operator">{item.orAnd}</span>
                  <div className="line"></div>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col xl={6}>
              <span className="label secondary-color">Condition type</span>
              <Select
                options={conditionOptions}
                value={item.condition ? conditionAssociateObject[item.condition] : item.condition}
                placeholder="Select type"
                textDefault
                onChange={(selectedItem: any) => {
                  const el = item.condition ? conditionAssociateObject[item.condition] : item.condition;
                  if (selectedItem.label === el) return;
                  onChangeCondition({ selectedItem, index });
                }}
              ></Select>
            </Col>
            <Col xl={6} className="d-flex value-container">
              <div className="w-100">
                <span className="label secondary-color">Value</span>
                <Input
                  placeholder="Enter value"
                  value={item.keyword}
                  onChange={e => {
                    onChangeInput({ value: e.target.value, index });
                  }}
                />
              </div>
              {conditions.length > 1 && (
                <div className="delete-icon">
                  <div>
                    <span>{item.label}</span>
                  </div>
                  <span className="close-icon" data-tip="Click to delete" onClick={() => onDeleteCondition(index)}>
                    {closeIcon}
                  </span>
                </div>
              )}
              {index === conditions.length - 1 && (
                <div className="and-or-buttons">
                  <Button
                    className="left-btn"
                    name={
                      <span className="icon-block-btn">
                        <span className="text">Or</span> {plusIcon}
                      </span>
                    }
                    onClick={() => onAddCondition('or')}
                  />
                  <Button
                    className="right-btn"
                    name={
                      <span className="icon-block-btn">
                        {plusIcon} <span className="text">And</span>
                      </span>
                    }
                    onClick={() => onAddCondition('and')}
                  />
                </div>
              )}
            </Col>
          </Row>
        </>
      ))}
      {errorMessage && (
        <Row className="mt-3">
          <Col md={12}>
            <Alert type="error" className="create-workflow">
              {errorMessage}
            </Alert>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default React.memo(FilterDataByCriteriaSettings);
