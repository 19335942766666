import { FC, useEffect, useState, useContext, KeyboardEvent, FormEvent, useCallback } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { Prompt, useHistory, useLocation, useParams } from 'react-router-dom';
import { Col, Row, Modal as ModalBootstrap } from 'react-bootstrap';
import moment from 'moment';

import { ThemeContext } from 'contexts/theme-context';
import { HexomaticUserContext } from 'contexts/hexomatic-user';
import { UserContext } from 'contexts/user-context';

// import { useTranslation } from 'react-i18next';

import Modal from 'shared-components/molecule/modal';
import Button from 'shared-components/atom/button';
import Textarea from 'shared-components/atom/texatarea';
import CustomModal from 'shared-components/molecule/customModal';
import Payment from 'shared-components/molecule/payment';
import Message from 'shared-components/atom/messages';
import Loader from 'shared-components/atom/loader';
import StickyFooter from 'layout/sticky-footer';
import UploadImage from 'shared-components/molecule/upload-image';
import EditableTextOpen from 'shared-components/molecule/editable-text-open';

import { chooseName } from 'helpers';
import Scraper from './scraper';
import ElementClickModal from './elementClickModal';
import Preview from './preview';
import RightSide from './rightSide';
import ContactUs from 'shared-components/molecule/payment/contact-us';

import recipeIcon from 'assets/icons/image-placeholder.svg';
import { arrowDownIcon, closeIcon } from 'assets/svg-icons';
import concierge from 'assets/concierge/concierge-recipe-page.svg';
import conciergeMobile from 'assets/concierge/concierge-recipe-page-mobile.svg';
import popupMobile from 'assets/concierge/popup-mobile.svg';
import popupDesktop from 'assets/concierge/popup-desktop.svg';

import {
  isValidUrl,
  isValidPath,
  isValidKeyword,
  isValidDomain,
  getDomains,
  isValidWorkflowName,
} from 'constants/validations';
import { scraperRecipeProxy, scraperRecipeBrowser, errorMessages, planLimits } from 'constants/index';
import { residential_geo_proxy_country_list } from 'constants/countries/residential-geo-proxy-countries';

import {
  GET_SCRAPER_SCAN_RESPONSE,
  GET_SCRAPING_RECIPE,
  GET_SCRAPING_PUBLIC_RECIPE,
  SEND_TRAFFIC_DATA,
  GET_RECIPIES_LIST,
} from 'graphql/queries';
import {
  CREATE_RECIPE,
  UPDATE_SCRAPING_RECIPE,
  UPDATE_SCRAPING_PUBLIC_RECIPE,
  IGNORE_RECIPE_SHARE_UPDATES,
} from 'graphql/mutations';

import 'i18n';
import './styles.scss';
import { scrapingDataTypes } from './cards';
import { useMediaQuery } from 'react-responsive';
//----------------------functions----------------------------//

export const getCountryCode = (countryName: string) => {
  const selectedCounty = residential_geo_proxy_country_list.find(item => item.label === countryName);
  return selectedCounty ? selectedCounty.value : '';
};

export const getCountryName = (countryCode: string) => {
  const selectedCounty = residential_geo_proxy_country_list.find(item => item.value === countryCode);
  return selectedCounty ? selectedCounty.label : '';
};

export const getPoxy = (proxyValue: string) => {
  const proxyVal = proxyValue === 'premium' || proxyValue === 'unmetered' ? 'premium' : proxyValue;
  const selectedProxy = scraperRecipeProxy.find(item => item.value === proxyVal);
  return selectedProxy ? selectedProxy : { label: 'Data Center', value: '' };
};

export const getBrowser = (bowser: boolean) => {
  const selectedBrowser = scraperRecipeBrowser.find(item => item.value === bowser);
  return selectedBrowser ? selectedBrowser : { label: 'Fast', value: false };
};
//------------------------------------------------------------//

const CreateRecipes: FC<any> = ({}) => {
  const history = useHistory();
  let location = useLocation();
  const { id: updateRecipId, ip }: { id: string; ip?: string } = useParams();
  /*
   * @description listener messages from webview
   */
  //@ts-ignore
  const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
  //@ts-ignore
  const eventer = window[eventMethod];
  const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

  //----------------------Context---------------------------------//
  const { user } = useContext(UserContext);
  const { hexomaticUser } = useContext(HexomaticUserContext);
  const { theme } = useContext(ThemeContext);
  //--------------------------------------------------------------//

  //----------------------States----------------------------------//
  const [timer, setTimer] = useState<any>();
  const [blockAds, setBlockAds] = useState(false);
  const [autoscrape, setAutoscrape] = useState(true);
  const [dataListClicked, setDataListClick] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [shouldUpgrade, setShouldUpgrade] = useState(false);
  const [paginationError, setPaginationError] = useState(false);
  const [creditLimitReached, setCreditLimitReached] = useState(false);
  const [buyPremiumCredits, setBuyPremiumCredits] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectTypeIsClicked, setSelectTypeIsClicked] = useState(false);
  const [loader, setLoader] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [render, setRender] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isModeChange, setIsModeChange] = useState(false);
  const [removeEditUrl, setRemoveEditUrl] = useState(false);
  const [actionRemoved, setActionRemoved] = useState(false);
  const [completed, setCompleted] = useState(true);
  const [isTimeSpent, setIsTimeSpent] = useState(false);
  const [loadingIframe, setLoadingIframe] = useState(false);
  const [retry, setRetry] = useState(false);
  const [hideScraper, setHideScraper] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [change, setChange] = useState(false);
  const [showAllActions, setShowAllActions] = useState(true);
  const [justNameChanging, setJustNameChanging] = useState(false);
  const [bulkResult, setBulkResult] = useState(window.location.pathname.includes('scraping-public-recipes'));
  const [bulkResultFunc, setBulkResultFunc] = useState(window.location.pathname.includes('scraping-public-recipes'));
  const [isSyncWithParentModalOpen, setIsSyncWithParentModalOpen] = useState(false);
  const [overwrite, setOverwrite] = useState(false);
  const [isSyncWithParent, setIsSyncWithParent] = useState(false);
  const [suggestionsBox, setSuggestionsBox] = useState(true);
  const [warningModal, setWarningModal] = useState(false);
  const [paidUser, setPaidUser] = useState(false);
  const [showConcierge, setShowConcierge] = useState(false);
  // const [showFooter, setShowFooter] = useState(false);

  const [itemIndex, setItemIndex] = useState(-1);
  const [traffic, setTraffic] = useState(0);
  const [previewCount, setPreviewCount] = useState(0);
  const [count, setCount] = useState(0);
  const [dangerItem, setDangerItem] = useState(-1);
  const [parentRecipeId, setParentRecipeId] = useState(0);
  const [selectedProxy, setSelectedProxy] = useState({ label: 'Data Center', value: '' });
  const [selectedActiveProxy, setSelectedActiveProxy] = useState({ label: 'Data Center', value: '' });
  const [selectedBrowser, setSelectedBrowser] = useState({ label: 'Fast', value: false });
  const [selectedActiveBrowser, setSelectedActiveBrowser] = useState({ label: 'Fast', value: false });
  const [modalOpenedcloseEdit, setModalOpenedcloseEdit] = useState<{
    show: boolean;
    location: any;
  }>({
    show: false,
    location: null,
  });

  const [address, setAddress] = useState('');
  const [addressWithoutHash, setAddressWithoutHash] = useState('');
  const [recipeName, setRecipeName] = useState('');
  const [recipeWrongName, setRecipeWrongName] = useState('');
  const [residentialGEOCountry, setResidentialGEOCountry] = useState(getCountryName('US'));
  const [residentialActiveGEOCountry, setResidentialActiveGEOCountry] = useState('');
  const [paginationErrorMessage, setPaginationErrorMessage] = useState('');
  const [path, setPath] = useState('');
  const [pattern, setPattern] = useState('');
  const [specify, setSpecify] = useState('');
  const [currentTypeElement, setCurrentTypeElement] = useState('');
  const [recipeJson, setRecipeJson] = useState('');
  const [src, setSrc] = useState('');
  const [comment, setComment] = useState('');
  const [ogImageUrl, setOgImageUrl] = useState('');
  const [uploadedImg, setUploadedImg] = useState('');
  const [savedAddress, setSavedAddress] = useState('');
  const [warningText, setWarningText] = useState('');
  const [lastEl, setLastEl] = useState('');
  const [error, setError] = useState('');

  const [goToData, setGoToData] = useState([] as any);
  const [confirmedItems, setConfirmedItems] = useState([] as any);
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const [scrapingList, setScrapingList] = useState([] as any);
  const [singleRecipeData, setSingleRecipeData] = useState<any>();

  const [data, setData] = useState(null as any);
  const [scraper, setScraper] = useState(null as any);

  const [nameError, setNameError] = useState<number[]>([]);
  const [actionsIndexes, setActionsIndexes] = useState<number[]>([]);
  const [startSearch, setStartSearch] = useState(setTimeout(() => {}));

  const [createRecipe, { loading: createRecipeLoading }] = useMutation(CREATE_RECIPE);
  const [ignoreRecipeShareUpdates, { loading: ignoreRecipeShareUpdatesLoading }] =
    useMutation(IGNORE_RECIPE_SHARE_UPDATES);
  const [selectOptions, setSelectOptions] = useState([]);

  const [autoscrapedData, setAutoscrapedData] = useState<any>([]);
  const [initialAutoscrapedData, setInitialAutoscrapedData] = useState<any>([]);

  // input IgnoreSharedRecipeUpdatesInput {
  //   importedRecipeId: Int!
  //   ignore: Boolean
  // }
  const { data: recipesData, loading: recipesDataLoading } = useQuery(GET_RECIPIES_LIST, {
    variables: {
      settings: {
        filter: {
          page: 1,
          limit: 1,
          date_from: null,
          date_to: null,
          sort_order: null,
          sort_by_creation_date: true,
          sort_by_updated_date: false,
          sort_by_name: false,
          sort_by: 'DESC',
          is_public: null,
          // is_public: false,
          search: {
            search_field: null,
            search_value: '',
          },
        },
      },
    },
  });

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isMobileLg = useMediaQuery({ maxWidth: 1200 });

  useEffect(() => {
    if (recipesData?.HexomaticScrapingRecipe?.getScrapingRecipes?.scraping_recipes) {
      setScrapingList(recipesData.HexomaticScrapingRecipe.getScrapingRecipes.scraping_recipes);
    }
  }, [recipesData]);

  //!------------------------------------------------------------//
  //!-------------------------Queries----------------------------//
  const [getScraperDataPreview, { data: scraperData, loading: scraperLoading }] = useLazyQuery(
    GET_SCRAPER_SCAN_RESPONSE,
    {
      fetchPolicy: 'no-cache',
      onCompleted(scraperData) {
        if (scraperData?.Scraper?.scan?.preview) {
          const el = scraperData?.Scraper?.scan?.elements;
          const parsedEl = el && JSON.parse(el);
          const parsedListWithUpdatedNames =
            parsedEl &&
            parsedEl.map((item: any) => {
              const type = scrapingDataTypes.find(sub => sub.value === item.type);
              return {
                ...item,
                property: item.property.includes('label_')
                  ? item.property.replace('label', type?.label.replace(' ', '_'))
                  : item.property,
              };
            });
          parsedListWithUpdatedNames && setInitialAutoscrapedData(parsedListWithUpdatedNames);
          const scraper = new Scraper({
            url: scraperData.Scraper.scan.preview,
            urlQueryString: `&_browser=${selectedActiveBrowser.value}&_proxy=${selectedActiveProxy.value}${
              selectedActiveProxy.value === 'premium' && residentialActiveGEOCountry ? '&_country_code=' : ''
            }${
              selectedActiveProxy.value === 'premium' ? getCountryCode(residentialActiveGEOCountry) : ''
            }&_ads_blocker=${String(blockAds && selectedActiveBrowser.label === 'Full-stack').trim()}`,
            ip,
          });
          setScraper(scraper);
          setLoader(!loader);
          setLoadingIframe(true);
        }
      },
    },
  );

  const [getSingleRecipe, { data: scrapingRecipe, loading: getRecipeLoading }] = useLazyQuery(GET_SCRAPING_RECIPE, {
    fetchPolicy: 'no-cache',
    onCompleted(scrapingRecipe) {
      if (scrapingRecipe?.HexomaticScrapingRecipe?.getScrapingRecipe?.scraping_recipe) {
        const recipeData = scrapingRecipe.HexomaticScrapingRecipe.getScrapingRecipe.scraping_recipe;
        if (recipeData?.synced_with_parent === false) {
          setParentRecipeId(recipeData.parent_recipe_id);
          setIsSyncWithParentModalOpen(true);
          setIsSyncWithParent(true);
        }
        if (overwrite) {
          setSingleRecipeData({
            ...singleRecipeData,
            url: recipeData.url,
            actions: recipeData.actions,
          });
          setIsSend(true);
        } else {
          setSingleRecipeData(recipeData);
          setComment(recipeData.description);
          setUploadedImg(recipeData.image);
          setIsSend(true);
        }
      }
    },
  });

  const [getSinglePublicRecipe, { data: scrapingPublicRecipe, loading: getRecipePublicLoading }] = useLazyQuery(
    GET_SCRAPING_PUBLIC_RECIPE,
    {
      fetchPolicy: 'no-cache',
      onCompleted(scrapingRecipe) {
        if (scrapingRecipe?.HexomaticPublicScrapingRecipe?.getPublicScrapingRecipe?.public_scraping_recipe) {
          const recipeData =
            scrapingRecipe.HexomaticPublicScrapingRecipe.getPublicScrapingRecipe.public_scraping_recipe;
          setSingleRecipeData(recipeData);
          setComment(recipeData.description);
          setUploadedImg(recipeData.image);
          setIsSend(true);
        }
      },
    },
  );
  const [updateSingleRecipe, { loading: updateSingleRecipeLoading }] = useMutation(UPDATE_SCRAPING_RECIPE);
  const [updateSinglePublicRecipe, { data: createFromImportData, loading: updateSingleRecipePublicLoading }] =
    useMutation(UPDATE_SCRAPING_PUBLIC_RECIPE);

  const [sendTrafficData, { loading: traficLoading }] = useLazyQuery(SEND_TRAFFIC_DATA);

  const func = () => {
    const parsedActions = singleRecipeData?.actions && JSON.parse(singleRecipeData.actions);
    if (parsedActions?.actions)
      setGoToData(parsedActions.actions.map((item: any) => (item.d ? { d: address.trim() } : item)));
    const finActions = typeof parsedActions === 'string' ? JSON.parse(parsedActions) : parsedActions;
    let newArrFin: any = [];
    if (sessionStorage.getItem('publicRecipe')) {
      const newArr: any[] = [];
      const editObj = finActions && finActions.pages && finActions.pages.find((item: any) => item.url === 'edit');
      editObj &&
        editObj.elements &&
        editObj.elements.map((item: any) => {
          if (!item.doneValue) return;
          newArr.push(
            `${item.path} ^^ ${item.type || 'unset'} ^^ ${item.pattern || ''} ^^ ${item.attribute || ''} ^^ ${
              item.specify || ''
            }`,
          );
        });
      newArrFin = newArr;
      setLoader(!loader);
      setRender(!render);
    }
    scraper && scraper.sendMessage('get_type_value_bulk', scraper.b64EncodeUnicode(newArrFin));
  };
  //!-------------------------------------------------------------//
  //!----------------------UseEffects----------------------------//
  useEffect(() => {
    !updateRecipId && setRecipeName(`New Recipe ${moment.utc(new Date()).format('DD.MM.YY hh:mm:ss')}`);
    window.location.pathname.includes('scraping-public-recipe') &&
      setRecipeName(`New Recipe ${moment.utc(new Date()).format('DD.MM.YY hh:mm:ss')}`);
    if (updateRecipId && !count) {
      if (sessionStorage.getItem('publicRecipe')) {
        if (JSON.parse(sessionStorage.getItem('publicRecipe') || '')?.id !== +updateRecipId)
          sessionStorage.removeItem('publicRecipe');
      }
      if (location.pathname.includes('scraping-recipes')) {
        getSingleRecipe({
          variables: {
            settings: {
              id: +updateRecipId,
            },
            toggle: Math.random(),
          },
        });
      } else {
        if (sessionStorage.getItem('publicRecipe')) {
          const data = JSON.parse(sessionStorage.getItem('publicRecipe') || '');
          setSingleRecipeData(data);
          setComment(data.description);
          setUploadedImg(data.image);
          setIsSend(true);
          //localStorage.removeItem('publicRecipe');
          return;
        }
        getSinglePublicRecipe({
          variables: {
            settings: {
              public_scraping_recipe_id: +updateRecipId,
            },
            toggle: Math.random(),
          },
        });
      }
      setCount(1);
    }
  }, []);

  const handleAutoElementSelect = (item: any, index: number) => {
    const arr: any = [...confirmedItems];
    const typeArr: any = [];

    arr.push({
      name: item.property,
      count: 0,
      path: item.path,
      type: item.type,
      done: true,
      custom: false,
    });

    setItemIndex(confirmedItems.length);
    scraper &&
      scraper.sendMessage(
        'get_type_value',
        scraper.b64EncodeUnicode(
          `${item.path} ^^ ${item.type || 'unset'} ^^ ${item.pattern || ''} ^^ ${item.attribute || ''} ^^ ${
            item.specify || ''
          }`,
        ),
      );

    setConfirmedItems(arr);
    scraper && scraper.propertyAction('confirm');
    setAutoscrapedData(autoscrapedData.filter((item: any, ind: number) => ind !== index));
  };

  useEffect(() => {
    if (initialAutoscrapedData && !loadingIframe && scraper && !error && !retry) {
      const arr: any = [];
      const typeArr: any = [];
      initialAutoscrapedData?.map((item: any) => {
        arr.push({
          name: item.property,
          count: 0,
          path: item.path,
          type: item.type,
          done: true,
          custom: false,
        });
        typeArr.push(
          `${item.path} ^^ ${item.type || 'unset'} ^^ ${item.pattern || ''} ^^ ${item.attribute || ''} ^^ ${
            item.specify || ''
          }`,
        );
      });
      setBulkResult(true);
      scraper && scraper.sendMessage('get_type_value_bulk', scraper.b64EncodeUnicode(typeArr));
      scraper && scraper.propertyAction('confirm');
    }
  }, [initialAutoscrapedData, loadingIframe, scraper, error, retry]);

  useEffect(() => {
    // if (!user?.verified) {
    //   history.push('/scraping-recipes');
    //   return
    // }
    setPaidUser(!!hexomaticUser?.pricing_package && hexomaticUser?.pricing_package !== 'FREE');
    if (
      hexomaticUser?.pricing_package === 'FREE' &&
      recipesData &&
      !recipesDataLoading &&
      scrapingList &&
      scrapingList.length &&
      !updateRecipId
    ) {
      setShouldUpgrade(true);
      setCreditLimitReached(false);
    } else if (hexomaticUser?.left_automation_credit === 0 && !updateRecipId) {
      setShouldUpgrade(true);
      setCreditLimitReached(true);
    }
  }, [user, hexomaticUser, recipesData, scrapingList]);

  useEffect(() => {
    if (singleRecipeData) {
      const parsedActions = singleRecipeData.actions && JSON.parse(singleRecipeData.actions);
      if (parsedActions.actions) setGoToData(parsedActions.actions);

      const finActions = typeof parsedActions === 'string' ? JSON.parse(parsedActions) : parsedActions;
      !window.location.pathname.includes('scraping-public-recipe') && setRecipeName(singleRecipeData.name);
      setAddress(singleRecipeData.url);
      setSavedAddress(singleRecipeData.url);
      if (finActions.proxy && finActions.proxy.country_code) {
        setResidentialGEOCountry(getCountryName(finActions.proxy.country_code));
        setResidentialActiveGEOCountry(getCountryName(finActions.proxy.country_code));
      }
      if (finActions.proxy && finActions.proxy.type) {
        setSelectedProxy(getPoxy(finActions.proxy.type));
        setSelectedActiveProxy(getPoxy(finActions.proxy.type));
      }
      if (finActions.browser) {
        setSelectedBrowser(getBrowser(finActions.browser));
        setSelectedActiveBrowser(getBrowser(finActions.browser));
      }
      if (finActions.blockAds) {
        setBlockAds(finActions.blockAds);
      }
      let filteredArr =
        finActions &&
        finActions.pages &&
        finActions.pages.filter((item: any) => item.url && (isValidUrl(item.url) || isValidDomain(item.url)));
      const scraper = new Scraper({
        url: `https://api.hexomatic.com/`,
        editUrl: removeEditUrl
          ? ''
          : (filteredArr && filteredArr[filteredArr.length - 1] && filteredArr[filteredArr.length - 1].url) ||
            singleRecipeData.url,
        urlQueryString: `&_browser=${finActions.browser || false}&_proxy=${
          (finActions.proxy && finActions.proxy.type) || ''
        }${
          finActions.proxy && finActions.proxy.type === 'premium' && finActions.proxy.country_code
            ? '&_country_code='
            : ''
        }${
          finActions.proxy && finActions.proxy.type === 'premium' ? finActions.proxy.country_code : ''
        }&_ads_blocker=${String(blockAds && selectedActiveBrowser.label === 'Full-stack').trim()}`,
        ip,
      });
      getScraperDataPreview({
        variables: { address: address.trim(), type: '', toggle: Math.random(), autoscrape },
      });
      setRetry(false);
      setScraper(scraper);
      setLoader(!loader);
      setLoadingIframe(true);
      const arr = finActions && finActions.pages && finActions.pages.find((el: any) => el.url === 'edit');
      if (arr && !removeEditUrl) {
        setConfirmedItems(arr.elements);
        setRecipeJson(JSON.stringify(arr.elements));
      } else if (!removeEditUrl) {
        const newArr =
          finActions &&
          finActions.pages &&
          finActions.pages[0] &&
          finActions.pages[0].elements &&
          finActions.pages[0].elements.map((item: any) => {
            return {
              name: item.property || '',
              type: item.type || '',
              path: item.path,
              done: true,
              count: item.path && (typeof item.path === 'string' ? 1 : item.path.length),
            };
          });

        if (newArr) {
          setConfirmedItems(newArr);
          setRecipeJson(JSON.stringify(newArr));
        }
      }
      scraper && scraper.token();
    }
  }, [singleRecipeData, removeEditUrl, autoscrape]);

  useEffect(() => {
    const idx = savedAddress.indexOf('#');
    setAddressWithoutHash((savedAddress || address).trim());
  }, [savedAddress, address]);

  useEffect(() => {
    scraper &&
      scraper.actions('goto', { data: '', goToData: selectedActiveBrowser.label === 'Full-stack' ? goToData : null });
  }, [scraper]);

  useEffect(() => {
    if (isSend && traffic) {
      setIsSend(false);
      sendTrafficData({
        variables: {
          settings: {
            type: selectedProxy.value ? `proxy_${selectedProxy.value}` : 'network',
            value: traffic,
          },
        },
      });
    }
  }, [change]);

  const handleEvents = (e: any) => {
    const data = e.data;
    if (data?.loaded) {
      setLoadingIframe(false);
      //!bulkResult
      // if (bulkResultFunc && confirmedItems.length > 0) {
      //   func();
      //   setBulkResultFunc(false);
      // }
    }
    if (data?.loaded) {
      scraper?.token();
    }
    if (data?.imageUrl) {
      setOgImageUrl(data.imageUrl);
    }
    if (isSend && data?.traffic && !traficLoading) {
      setTraffic(+data.traffic);
      setChange(!change);
    }

    if (e?.data) {
      setData(e.data);
    }
  };

  useEffect(() => {
    eventer(messageEvent, handleEvents, false);
    setRender(!render);
  }, [loader]);

  useEffect(() => {
    scraper && scraper.token();
  }, [render]);

  useEffect(() => {
    if (confirmedItems.length) {
      //get action card indexes
      const actions: number[] = [];
      confirmedItems.map((item: any, index: number) => {
        item.action && actions.push(index);
      });
      setActionsIndexes(actions);
      //---------------------//
      !isDirty && setIsDirty(true);

      if (!confirmedItems[confirmedItems.length - 1].name && !confirmedItems[confirmedItems.length - 1].action) return;
      if (updateRecipId && recipeJson === JSON.stringify(confirmedItems)) setIsDirty(false);
      if (
        (!confirmedItems[confirmedItems.length - 1].type ||
          !isValidPath(confirmedItems[confirmedItems.length - 1].path)) &&
        !confirmedItems[confirmedItems.length - 1].action &&
        !confirmedItems[confirmedItems.length - 1].pagination
      ) {
        setDangerItem(confirmedItems.length - 1);
        return;
      }
      setDangerItem(-1);
    } else setActionsIndexes([]);
  }, [confirmedItems]);
  useEffect(() => {
    if (!isDirty) return;
    window.onbeforeunload = (e: { returnValue: string }) => {
      e.returnValue = "Changes you've made will be lost if you exit now.";
      return "Changes you've made will be lost if you exit now.";
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, [isDirty]);
  //update modes
  useEffect(() => {
    if (isModeChange) {
      setSelectedActiveProxy(selectedProxy);
      setSelectedActiveBrowser(selectedBrowser);
      setResidentialActiveGEOCountry(residentialGEOCountry);
    }
  }, [isModeChange]);

  useEffect(() => {
    setIsModeChange(false);
  }, [selectedProxy, selectedBrowser, residentialGEOCountry]);

  useEffect(() => {
    if (
      scraper &&
      !(createRecipeLoading || scraperLoading || loadingIframe || updateSingleRecipeLoading || getRecipeLoading)
    ) {
      scraper.sendMessage('get_page_traffic', 1);
    }
  }, [loadingIframe, scraper]);

  useEffect(() => {
    if (!isTimeSpent) return;
    setIsTimeSpent(false);
    if (scraperLoading || loadingIframe || getRecipeLoading) {
      setError('Something went wrong. Please try again');
      setHideScraper(true);
      setLoadingIframe(false);
    }
  }, [isTimeSpent]);

  useEffect(() => {
    if (
      (!data?.pagination && paginationError && !data.pcmPixelPostMessageEvent) ||
      (data?.pagination?.length && !data?.pagination[0].includes('/'))
    ) {
      setPaginationErrorMessage(
        'Pagination could not be detected automatically, please use the advanced pagination option.',
      );
      setPaginationError(false);
      sendMessage('confirmed', 1);
    } else if (data?.pagination?.length && data?.pagination[0].includes('/')) {
      setPaginationErrorMessage('');
      setPaginationError(false);
    } else if (data && data.data) {
      setPaginationErrorMessage('');
      setPaginationError(false);
    }
    if (data) {
      if (data.type === 'select') {
        if (data.data && data.data[0] && data.data[0].data) {
          setSelectOptions(
            data.data[0].data.map((item: any, index: number) => {
              return {
                label: item,
                value: index + 1,
              };
            }),
          );
        }
      }

      try {
        if (isSend && data.traffic && !traficLoading) {
          setTraffic(+data.traffic);
          setChange(!change);
        }

        const dataValue = (data && data.data_value) || [];

        //filter autoscraped data
        if (initialAutoscrapedData && initialAutoscrapedData.length > 0) {
          const filtered: any = [];
          let notFound = false;
          initialAutoscrapedData.map((sub: any, index: number) => {
            const sampleVal = dataValue[index];
            if (
              (sampleVal && sampleVal.length > 1) ||
              (sampleVal && sampleVal[0] && !sampleVal[0].includes('not found'))
            ) {
              filtered.push(sub);
            } else if (sampleVal?.[0].includes('not found')) {
              notFound = true;
            }
          });

          filtered && filtered.length && setAutoscrapedData(filtered);
          ((filtered && filtered.length) || notFound) && setInitialAutoscrapedData(null);
        }
        if (dataValue && dataValue.length > 0) {
          if (!bulkResult && itemIndex === -1) return;
          const arr = [...confirmedItems];
          const bulkData: any = [];
          const elCount = dataValue.filter((item: any) => item !== null);
          let dataValueIndex = 0;
          bulkResult
            ? arr.map((item: any) => {
                if (!(item.action || item.pagination)) {
                  bulkData.push({
                    ...item,
                    value:
                      (dataValue &&
                        dataValue[dataValueIndex] &&
                        dataValue[dataValueIndex][0] &&
                        dataValue[dataValueIndex][0].slice(0, 2000)) ||
                      '',
                    doneValue:
                      (dataValue &&
                        dataValue[dataValueIndex] &&
                        dataValue[dataValueIndex][0] &&
                        dataValue[dataValueIndex][0].slice(0, 2000)) ||
                      '',
                    count: dataValue && dataValue[dataValueIndex] && dataValue[dataValueIndex].length,
                  });
                  dataValueIndex++;
                } else {
                  bulkData.push(item);
                }
              })
            : arr.splice(itemIndex, 1, {
                ...arr[itemIndex],
                value: (elCount && elCount[0]) || '',
                doneValue: arr[itemIndex].value && arr[itemIndex].value.slice(0, 2000),
                count: elCount?.length || 0,
              });
          bulkResult && setBulkResult(false);
          setConfirmedItems(bulkData.length ? bulkData : arr);
          document.getElementById(`${itemIndex - 1}`)?.scrollIntoView();
        }
        if (data.action) {
          selectedActiveBrowser.label !== 'Full-stack' && scraper && scraper.collectAction(data);
          if (data.action.type === 'click') {
            // this if causes infinite scroll
            scraper.actions('goto', {
              data: data.action.form_data,
              goToData: selectedActiveBrowser.label === 'Full-stack' ? goToData : null,
            });
          }
          actionRemoved && setActionRemoved(false);
          if (data.action.element !== 'body' && !actionRemoved) {
            const actionsssss = [...confirmedItems];
            const finList = actionsssss.map((item: any) => {
              return { ...item, last: false };
            });
            finList.push({ action: data.action, last: true });
            setConfirmedItems(finList);
          }
        }
        if (data.pagination && data.pagination.length > 0 && data.pagination[0].includes('/')) {
          const actionsssss = [...confirmedItems];
          actionsssss.push({ pagination: data.pagination });
          setConfirmedItems(actionsssss);
          sendMessage('confirmed', 1);
        }
        if (data.pagination && data.pagination.type) {
          const actionsssss = [...confirmedItems];
          actionsssss.push({ pagination: data.pagination });
          setConfirmedItems(actionsssss);
          sendMessage('confirmed', 1);
        }
        if (data.loaded) {
          scraper.token();
        }

        if (data && data.click) {
          if (Object.values(data) && Object.values(data)[0]) {
            const a = Object.values(data)[0];
            //@ts-ignore
            if (a && Object.values(a) && Object.values(a)[0] && Object.values(a)[0][0]) setSrc(Object.values(a)[0][0]);
            else setSrc('');
          } else setSrc('');
          //@ts-ignore
          setShowAllActions(true);
          setModalOpened(true);
          // scraper && scraper.sendMessage('pagination', 1);
        }
        // check selected only this and allow actions
        if (!data.only_this && data.data[0]) {
          data.only_this = data.data[0].path;
        }
        const objKeys = data && data.data && Object.keys(data.data);
        const objValues = data && data.data && Object.values(data.data);
        if (
          ((data && data.data && data.data.length > 0 && data.data[objKeys[1]] && data.data[objKeys[1]].length > 0) ||
            (data && data.data && data.data.length === 1)) &&
          selectTypeIsClicked
        ) {
          setSelectTypeIsClicked(false);
          let count = 0;
          let path = '';
          let title = null;
          Object.entries(data.data).map(item => {
            if (Array.isArray(item[1]) && item[1].length > count) {
              count = item[1].length;
              path = item[0]
                .replace('.hexoscraper-scale', '')
                .replace('.hexoScrapOverlay', '')
                .replace('.hexoScrapSelect', '')
                .replace('.hexoScrapHover', '')
                .replace('.hexoScrap-context', '')
                .replace('.hexoScrap-only-this', '')
                .replace('.hexoScrap-actions-ui', '')
                .replace('.hexoScrap-overlay', '');
              title = item[1];
            }
          });
          const arr = [...confirmedItems];
          if (arr.length === 0) {
            arr.push({
              name: `propertyName1`,
              doneNmae: `propertyName1`,
              done: false,
              simpleEdit: true,
              count: count
                ? count
                : data && data.data && data.data.length > 0 && data.data[objKeys[1]] && data.data[objKeys[1]].length > 0
                ? data.data[objKeys[1]].length
                : 1,
              type: 'unset',
              doneType: 'unset',
              donePath: '',
              path: path
                ? path
                : data.data[0].path
                    .replace('.hexoscraper-scale', '')
                    .replace('.hexoScrapOverlay', '')
                    .replace('.hexoScrapSelect', '')
                    .replace('.hexoScrapHover', '')
                    .replace('.hexoScrap-context', '')
                    .replace('.hexoScrap-only-this', '')
                    .replace('.hexoScrap-actions-ui', '')
                    .replace('.hexoScrap-overlay', ''),
              title: title ? title : data.data[0].data || src,
              attribute: '',
              doneAttribute: '',
              specify: '',
              doneSpecify: '',
            });
          } else {
            arr.splice(arr.length - 1, 1, {
              name: chooseName(confirmedItems) || `propertyName${arr.length}`,
              doneName: chooseName(confirmedItems) || `propertyName${arr.length}`,
              done: false,
              type: 'unset',
              doneType: 'unset',
              donePath: '',
              simpleEdit: true,
              count: count ? count : 1,
              path: path
                ? path
                : data.data[0].path
                    .replace('.hexoscraper-scale', '')
                    .replace('.hexoScrapOverlay', '')
                    .replace('.hexoScrapSelect', '')
                    .replace('.hexoScrapHover', '')
                    .replace('.hexoScrap-context', '')
                    .replace('.hexoScrap-only-this', '')
                    .replace('.hexoScrap-actions-ui', '')
                    .replace('.hexoScrap-overlay', ''),
              title: title ? title : data.data[0].data || src,
              attribute: '',
              doneAttribute: '',
              specify: '',
              doneSpecify: '',
            });
          }
          setConfirmedItems(arr);
          document.getElementById(`${arr.length - 2}`)?.scrollIntoView();
        }
        setLastEl(data.only_this || data.data[0].path);
        const res_data = {
          action: data.action,
          data: data.data,
          pagination: data.pagination,
        };
        scraper.finalizing({
          action: 'insertElements',
          elements: data.data,
        });
        scraper.setProperty(res_data);
      } catch (error) {}
    }
  }, [data]);
  useEffect(() => {
    if (loadingIframe) {
      setTimer(setTimeout(() => setIsTimeSpent(true), 120000));
      return;
    }
    setTimer(clearTimeout(timer));
  }, [loadingIframe]);

  //!-------------------------------------------------------//

  //!----------------------Functions-----------------------//
  //!helpers
  const dangerState = (val: boolean, index?: number) => {
    if (val) {
      setDangerItem(-1);
      setCompleted(true);
      return;
    }
    //@ts-ignore
    setDangerItem(index);
    setCompleted(false);
  };

  const changeLastelement = () => {
    return confirmedItems.map((item: any) => {
      return { ...item, last: false };
    });
  };

  const handelStopClick = () => {
    completed && setCompleted(false);
  };

  const changeEditState = (simpleEdit: boolean, index: number) => {
    if (confirmedItems[index].simpleEdit === simpleEdit) return;
    const arr = [...confirmedItems];
    arr[index].simpleEdit = simpleEdit;
    setConfirmedItems(arr);
  };

  const handleActionStatic = (objAction: any, objData: any) => {
    const items = changeLastelement();
    items.push({
      action: objAction,
      last: true,
    });
    setConfirmedItems(items);
    setSelectTypeIsClicked(false);
    const arr = [...goToData, objData];
    setGoToData(arr);
    scraper.actions('goto', {
      data: '',
      goToData: arr,
    });
    setLoadingIframe(true);
    setShowAllActions(true);
    setModalOpened(false);
  };

  const handelAddAction = () => {
    setError('');
    setModalOpened(true);
    setShowAllActions(false);
  };

  const sendMessage = (key: any, value: number) => {
    const data = '{"' + key + '": "' + (value || '') + '"}';
    //@ts-ignore
    document.getElementById('webview')?.contentWindow.postMessage(data, '*');
  };

  const preview = (obj: any) => {
    if (confirmedItems.length < 1) {
      handlePreview(false, obj);
      return true;
    }

    return false;
  };

  const changeProxy = (item: { label: string; value: string }) => {
    if (!address || item.label === 'Residential GEO') return;
    // if (!previewCount && !updateRecipId) return;
    if (preview({ selectedProxy: item })) {
      setSelectedActiveProxy(item);
      return;
    }
    if (item.label !== selectedActiveProxy.label) {
      setWarningText('Please note that this action could impact the outcome.');
    }
  };

  const changeBrowser = (item: { label: string; value: boolean }) => {
    setSelectedBrowser(item);
    if (!address) return;
    // if (!previewCount && !updateRecipId) return;

    if (preview({ selectedBrowser: item })) {
      setSelectedActiveBrowser(item);
      return;
    }
    if (item.label !== selectedActiveBrowser.label) {
      setWarningText('Please note that this action could impact the outcome.');
    }
  };

  const changeCountry = (text: string) => {
    if (!address || !getCountryCode(text)) return;
    setResidentialGEOCountry(text);
    // if (!previewCount && !updateRecipId) return;
    if (preview({ residentialGEOCountry: text })) {
      setResidentialActiveGEOCountry(text);
      return;
    }
    if (text !== residentialActiveGEOCountry) {
      setWarningText('Please note that this action could impact the outcome.');
    }
  };
  //----------
  //Events
  const handleEnterKeyPress = (e: KeyboardEvent<FormEvent>) => {
    if (e.key === 'Enter') {
      if (!isValidUrl(address.trim()) && !isValidDomain(address)) {
        setError('URL is not valid.');
        return;
      } else if (selectedProxy.value === 'premium' && !residentialGEOCountry) {
        setError('Please choose a country');
        return;
      }
      // setPreviewCount(1);
      setPaginationErrorMessage('');
      setConfirmedItems([]);
      setGoToData([{ d: address.trim() }]);
      getScraperDataPreview({ variables: { address: address.trim(), type: '', toggle: Math.random(), autoscrape } });
      setRetry(false);
      setError('');
      dangerState(true);
    }
  };

  const handlePreview = (showConfirmationMessage?: boolean, obj?: any, clearSuggestionsData?: boolean) => {
    const userPlan = planLimits.find((item: any) => item.planName === hexomaticUser?.pricing_package);
    if (
      (obj && obj.selectedProxy ? obj.selectedProxy : selectedProxy)?.value === 'premium' &&
      (!userPlan?.residentialGEOProxy ||
        (!user?.left_premium_credits && !hexomaticUser?.left_premium_automation_credit))
    ) {
      if (!userPlan?.residentialGEOProxy) {
        setShouldUpgrade(true);
        setBuyPremiumCredits(false);
      } else {
        setShouldUpgrade(true);
        hexomaticUser?.pricing_package !== 'FREE' && setBuyPremiumCredits(true);
      }
      return;
    } else {
      setHideScraper(false);
      const proxy = obj && obj.selectedProxy ? obj.selectedProxy : selectedProxy;
      const country = obj && obj.residentialGEOCountry ? obj.residentialGEOCountry : residentialGEOCountry;
      // if (!previewCount && !count) return;
      // setPreviewCount(1);
      // setAutoscrape(autoscrape || false);
      setIsSend(true);
      setIsModeChange(true);
      if (showConfirmationMessage) {
        setConfirmationModalOpen(true);
        return;
      } else {
        confirmationModalOpen && setConfirmationModalOpen(false);
      }
      if (!isValidUrl(address.trim()) && !isValidDomain(address.trim())) {
        setError('URL is not valid.');
        return;
      } else if (proxy.value === 'premium' && !country) {
        setError('Please choose a country');
        return;
      } else if (proxy.value === 'premium' && !getCountryCode(country)) {
        setError('Please choose a valid country');
        return;
      }
      //setTimeout(() => setIsTimeSpent(true), 120000);
      setPaginationErrorMessage('');
      // setConfirmedItems([]);
      // setGoToData([{ d: address.trim() }]);
      setSavedAddress(address.trim());
      getScraperDataPreview({ variables: { address: address.trim(), type: '', toggle: Math.random(), autoscrape } });
      if (clearSuggestionsData) {
        setSuggestionsBox(true);
        setAutoscrapedData([]);
      }
      setRetry(false);
      setError('');
      dangerState(true);
    }
  };

  const handleChangeSelectedProxy = (value: any) => {
    const userPlan = planLimits.find((item: any) => item.planName === hexomaticUser?.pricing_package);
    if (
      value.value === 'unmetered' &&
      (!userPlan?.residentialProxy || (!user?.left_premium_credits && !hexomaticUser?.left_premium_automation_credit))
    ) {
      if (!userPlan?.residentialGEOProxy) {
        setShouldUpgrade(true);
        setBuyPremiumCredits(false);
      } else {
        setShouldUpgrade(true);
        hexomaticUser?.pricing_package !== 'FREE' && setBuyPremiumCredits(true);
      }
      return;
    } else if (
      value.value === 'premium' &&
      (!userPlan?.residentialGEOProxy ||
        (!user?.left_premium_credits && !hexomaticUser?.left_premium_automation_credit))
    ) {
      if (!userPlan?.residentialGEOProxy) {
        setShouldUpgrade(true);
        setBuyPremiumCredits(false);
      } else {
        setShouldUpgrade(true);
        hexomaticUser?.pricing_package !== 'FREE' && setBuyPremiumCredits(true);
      }
      return;
    } else {
      setError('');
      setSelectedProxy(value);
      if (value.value === 'premium') {
        !residentialGEOCountry && setResidentialGEOCountry(getCountryName('US'));
        !selectedBrowser.value && setSelectedBrowser({ label: 'Full-stack', value: true });
      }
      setLoader(!loader);
    }
    changeProxy(value);
  };

  useEffect(() => {
    if (!updateRecipId) {
      const proxySettings = localStorage.getItem('proxy-settings');
      const parsedProxySettings = proxySettings && JSON.parse(proxySettings);
      const proxy = parsedProxySettings?.proxy || {
        label: '',
        value: selectedProxy?.value === 'premium' ? '' : 'premium',
      };
      const country = parsedProxySettings?.country || null;
      const browser = parsedProxySettings?.browser || null;
      setSelectedProxy(proxy);
      country
        ? setResidentialGEOCountry(country)
        : proxy.value === 'premium' && setResidentialGEOCountry(country || getCountryName('US'));
      browser
        ? setSelectedBrowser(browser || { label: 'Full-stack', value: true })
        : proxy.value === 'premium' && setSelectedBrowser(browser || { label: 'Full-stack', value: true });
    }
  }, [updateRecipId]);

  useEffect(() => {
    localStorage.setItem(
      'proxy-settings',
      JSON.stringify({ proxy: selectedProxy, country: residentialGEOCountry, browser: selectedBrowser }),
    );
  }, [selectedProxy, residentialGEOCountry, selectedBrowser]);

  const handleChangeResidentialGEOCountry = (text: string) => {
    setError('');
    setResidentialGEOCountry(text);
    !dataListClicked && setDataListClick(true);
    changeCountry(text);
  };
  const handleRecipeCreate = async () => {
    if (!confirmedItems.find((item: { name: string }) => item.name)) {
      setWarningModal(true);
      return;
    }
    if (nameError.length) return;
    //For the case when unknow actions are being added to the list
    const confirmedItemsFiltered =
      confirmedItems &&
      confirmedItems.filter(
        (item: any) =>
          (item.action &&
            item.action.type &&
            (item.action.type === 'delay' ||
              item.action.type === 'scroll' ||
              item.action.type === 'cookie' ||
              item.action.type === 'local storage' ||
              item.action.type === 'refresh' ||
              item.action.type === 'click' ||
              item.action.type === 'type' ||
              item.action.type === 'load more' ||
              item.action.type === 'Go to URL' ||
              item.action.type === 'block element' ||
              item.action.type === 'block' ||
              item.action.type === 'enter')) ||
          !item.action,
      );

    if (dangerItem !== -1) {
      changeEditState(
        !confirmedItemsFiltered[confirmedItemsFiltered.length - 1].type,
        confirmedItemsFiltered.length - 1,
      );
      setCompleted(false);
      return;
    }

    if (
      confirmedItemsFiltered.length &&
      (!confirmedItemsFiltered[confirmedItemsFiltered.length - 1].type ||
        !isValidPath(confirmedItemsFiltered[confirmedItemsFiltered.length - 1].path)) &&
      !confirmedItemsFiltered[confirmedItemsFiltered.length - 1].action &&
      !confirmedItemsFiltered[confirmedItemsFiltered.length - 1].pagination
    ) {
      dangerState(false, confirmedItemsFiltered.length - 1);
      return;
    }
    if (recipeWrongName && recipeWrongName === recipeName) return;
    const notDone = confirmedItemsFiltered.filter(
      (item: { done: boolean; action: any; pagination: any }) => !item.done && !item.pagination && !item.action,
    ).length;

    const cleanAddress =
      addressWithoutHash[addressWithoutHash.length - 1] === '/'
        ? addressWithoutHash.slice(0, addressWithoutHash.length - 1)
        : addressWithoutHash;
    const finData = [] as any;
    const actions = [] as any;
    const pages = [] as any;
    const goToNewArr = [...goToData];
    const paginationActions = [] as any;
    let index = -1;
    let indexAction = -1;
    confirmedItemsFiltered?.map((item: any) => {
      if (item.action) {
        goToData.map((subItem: any, idx: number) => {
          subItem.a === item.action.type && ((subItem.p && subItem.p === item.action.element) || true) && (index = idx);
        });
      }
      if (!item.action && item.name) {
        const finPath = item.path
          ? item.path
              .replace('.hexoscraper-scale', '')
              .replace('.hexoScrapOverlay', '')
              .replace('.hexoScrapSelect', '')
              .replace('.hexoScrapHover', '')
              .replace('.hexoScrap-context', '')
              .replace('.hexoScrap-only-this', '')
              .replace('.hexoScrap-actions-ui', '')
              .replace('.hexoScrap-overlay', '')
          : '';
        finData.push({
          property: item.name,
          path: finPath.trim(),
          type: item.type || '',
          action_index: index !== -1 ? index : 0,
          pattern: item.pattern || undefined,
          specify: item.specify || undefined,
          attribute: item.attribute,
        });
      }
    });
    const url = [addressWithoutHash.replace('https://', '').replace('http://', '')];
    confirmedItemsFiltered?.map((item: any) => {
      if (item.action && item.action.form_data && item.action.form_data.action) {
        const action =
          item.action.form_data.action.indexOf('/?') > -1
            ? item.action.form_data.action.slice(0, item.action.form_data.action.indexOf('/?'))
            : item.action.form_data.action;

        url.push(action.replace('https://', '').replace('http://', ''));
      }
    });
    confirmedItemsFiltered?.map((item: any, idx: number) => {
      if (selectedActiveBrowser.label === 'Full-stack' && item.action) return;
      if (item.action) indexAction = idx;
      if (item.action && item.action.form_data && item.action.form_data.action) {
        actions.push({
          url: `${item.action.form_data.action}${
            item.action.form_data.action.charAt(item.action.form_data.action.length - 1) === '/' ? '' : '/'
          }${item.action.form_data.queryString ? '?' : ''}${
            item.action.form_data.queryString ? item.action.form_data.queryString : ''
          }`,
          elements: finData,
        });
      } else if (item.pagination && item.pagination.length > 0) {
        item.pagination.map((sub: any, index: number) => {
          const s = sub.indexOf('/?') > -1 ? sub.slice(0, sub.indexOf('/?')) : sub;
          const subItem = s.replace('https://', '').replace('http://', '');
          const a = url.filter(item => item === subItem || item + '/' === subItem);
          if (a.length) return;
          if (sub && sub.indexOf('/') === 0) {
            selectedActiveBrowser.label === 'Full-stack' &&
              paginationActions.push({ a: 'goto', p: `${cleanAddress}${sub}` });
            const newUrl = `${
              (savedAddress || address).includes('https://')
                ? 'https://'
                : (savedAddress || address).includes('http://')
                ? 'http://'
                : ''
            }${getDomains(address)}${sub}`;
            selectedActiveBrowser.label === 'Full-stack'
              ? pages.push({
                  url: newUrl,
                  elements: finData.map((item: any) => {
                    return { ...item, action_index: goToData.length + index };
                  }),
                })
              : pages.push({
                  url: newUrl,
                  elements: finData,
                  // action_index: indexAction !== -1 ? indexAction : 0,
                });
          } else {
            selectedActiveBrowser.label === 'Full-stack' &&
              paginationActions.push({
                a: 'goto',
                p: `${sub
                  .replace('.hexoscraper-scale', '')
                  .replace('.hexoScrapOverlay', '')
                  .replace('.hexoScrapSelect', '')
                  .replace('.hexoScrapHover', '')
                  .replace('.hexoScrap-context', '')
                  .replace('.hexoScrap-only-this', '')
                  .replace('.hexoScrap-actions-ui', '')
                  .replace('.hexoScrap-overlay', '')}`,
              });
            selectedActiveBrowser.label === 'Full-stack'
              ? pages.push({
                  url: `${sub}`,
                  elements: finData.map((item: any) => {
                    return { ...item, action_index: goToData.length + index };
                  }),
                })
              : pages.push({ url: `${sub}`, elements: finData });
          }
        });
      } else if (item.pagination && item.pagination.type.includes(' > ')) {
        goToNewArr.push({
          a: 'pagination_advanced_path',
          p: item.pagination.type
            .replace('.hexoscraper-scale', '')
            .replace('.hexoScrapOverlay', '')
            .replace('.hexoScrapSelect', '')
            .replace('.hexoScrapHover', '')
            .replace('.hexoScrap-context', '')
            .replace('.hexoScrap-only-this', '')
            .replace('.hexoScrap-actions-ui', '')
            .replace('.hexoScrap-overlay', ''),
          f: item.pagination.from,
          t: item.pagination.to,
        });
      }
    });
    const fullD = [{ url: addressWithoutHash, elements: finData }].concat(actions);
    const sendData = fullD.concat(pages);
    sendData.push({
      url: 'edit',
      elements: confirmedItemsFiltered.map((item: { done: boolean; path?: string }) => {
        return {
          ...item,
          done: true,
          path: item.path
            ? item.path
                .replace('.hexoscraper-scale', '')
                .replace('.hexoScrapOverlay', '')
                .replace('.hexoScrapSelect', '')
                .replace('.hexoScrapHover', '')
                .replace('.hexoScrap-context', '')
                .replace('.hexoScrap-only-this', '')
                .replace('.hexoScrap-actions-ui', '')
                .replace('.hexoScrap-overlay', '')
            : '',
        };
      }),
    });
    const session = goToData.filter((item: any) => item.a === 'local_storage' || item.a === 'cookie');
    const actionsVal = JSON.stringify({
      pages: sendData,
      session_mode: session?.length ? true : false,
      proxy: {
        type: selectedActiveProxy.value,
        country_code: selectedActiveProxy.value === 'premium' ? getCountryCode(residentialActiveGEOCountry) : '',
      },
      browser: selectedActiveBrowser.value,
      blockAds,
      actions: goToNewArr,
    });
    // useEffect(() => {
    //   if(createFromImportData?.HexomaticPublicScrapingRecipeOps?.updatePublicScrapingRecipe) {
    //     const { error_code } = createFromImportData.HexomaticPublicScrapingRecipeOps.updatePublicScrapingRecipe
    //     if(error_code) {
    //       setError(error_code)
    //     }
    //   }
    // }, [createFromImportData])
    const result =
      updateRecipId && !location.pathname.includes('scraping-public-recipes')
        ? location.pathname.includes('scraping-recipes')
          ? await updateSingleRecipe({
              variables: {
                settings: {
                  id: updateRecipId && parseInt(updateRecipId),
                  actions: isDirty || overwrite ? actionsVal : undefined,
                  image: uploadedImg,
                  mimeType: 'image/png',
                  description: comment,
                  url: isDirty || overwrite ? savedAddress.trim() || address.trim() : undefined,
                  syncedWithParent: isSyncWithParent,
                  is_action: goToNewArr.find(
                    (item: any) => item.a === 'cookie' || item.a === 'type' || item.a === 'local_storage',
                  )
                    ? true
                    : false,
                  elements_count:
                    sendData && sendData[0] && sendData[0].elements
                      ? sendData && sendData[0] && sendData[0].elements.length
                      : 0,
                },
              },
            })
          : await updateSinglePublicRecipe({
              variables: {
                settings: {
                  name: recipeName,
                  url: isDirty || overwrite ? savedAddress.trim() || address.trim() : undefined,
                  id: updateRecipId && parseInt(updateRecipId),
                  actions: isDirty || overwrite ? actionsVal : undefined,
                  image: uploadedImg ? uploadedImg : '',
                  mimeType: 'image/png',
                  description: comment,
                  is_action: goToNewArr.find(
                    (item: any) => item.a === 'cookie' || item.a === 'type' || item.a === 'local_storage',
                  )
                    ? true
                    : false,
                  elements_count:
                    sendData && sendData[0] && sendData[0].elements
                      ? sendData && sendData[0] && sendData[0].elements.length
                      : 0,
                },
              },
            })
        : await createRecipe({
            variables: {
              settings: {
                name: recipeName,
                url: savedAddress.trim() || address.trim(),
                actions: actionsVal,
                image: uploadedImg,
                mimeType: 'image/png',
                description: comment,
                imageUrl: ogImageUrl,
                is_action: goToNewArr.find(
                  (item: any) => item.a === 'cookie' || item.a === 'type' || item.a === 'local_storage',
                )
                  ? true
                  : false,
                elements_count:
                  sendData && sendData[0] && sendData[0].elements
                    ? sendData && sendData[0] && sendData[0].elements.length
                    : 0,
              },
            },
          });

    if (result?.data?.HexomaticPublicScrapingRecipeOps?.updatePublicScrapingRecipe?.error_code) {
      const { error_code } = result.data.HexomaticPublicScrapingRecipeOps.updatePublicScrapingRecipe;
      if (error_code) {
        if (error_code === 'ALREADY_EXISTS') {
          setError(`"${recipeName}" already exists`);
          return;
        }
        setError(error_code);
        return;
      }
    }

    if (
      updateRecipId
        ? result?.data?.HexomaticScrapingRecipeOps?.updateScrapingRecipe?.error_code
        : result?.data?.HexomaticScrapingRecipeOps?.createScrapingRecipe?.error_code
    ) {
      //@ts-ignore
      if (
        updateRecipId
          ? //@ts-ignore
            errorMessages && errorMessages[result.data.HexomaticScrapingRecipeOps.updateScrapingRecipe.error_code]
          : //@ts-ignore
            errorMessages && errorMessages[result.data.HexomaticScrapingRecipeOps.createScrapingRecipe.error_code]
      ) {
        if (
          result?.data?.HexomaticScrapingRecipeOps?.createScrapingRecipe?.error_code === 'ALREADY_EXISTS' ||
          result?.data?.HexomaticScrapingRecipeOps?.updateScrapingRecipe?.error_code === 'ALREADY_EXISTS'
        ) {
          setRecipeWrongName(recipeName);
          setError(`"${recipeName}" already exists`);
        } else {
          //@ts-ignore
          setError(
            updateRecipId
              ? //@ts-ignore
                errorMessages[result.data.HexomaticScrapingRecipeOps.updateScrapingRecipe.error_code]
              : //@ts-ignore
                errorMessages[result.data.HexomaticScrapingRecipeOps.createScrapingRecipe.error_code],
          );
        }
      } else {
        setError(
          updateRecipId
            ? result.data.HexomaticScrapingRecipeOps.updateScrapingRecipe.error_code
            : result.data.HexomaticScrapingRecipeOps.createScrapingRecipe.error_code,
        );
      }
    } else {
      setScraper(null);
      setAddress('');
      window.onbeforeunload = null;
      setIsDirty(false);
      setConfirmedItems([]);
      !updateRecipId && localStorage.removeItem('recipes-details');
      history.push('/scraping-recipes');
    }
  };

  const handleRecipeCancel = () => {
    if (isDirty) {
      setModalOpenedcloseEdit({ show: true, location: `/scraping-recipes` });
      setIsDirty(false);
    } else {
      setScraper(null);
      setAddress('');
      setSelectedProxy({ label: 'Data Center', value: '' });
      setSelectedActiveProxy({ label: 'Data Center', value: '' });

      setSelectedBrowser({ label: 'Fast', value: false });
      setSelectedActiveBrowser({ label: 'Fast', value: false });

      setResidentialGEOCountry('');
      setResidentialActiveGEOCountry('');
      dangerState(true);
      history.push('/scraping-recipes');
    }
  };

  const handleConfirm = (index: number, bool?: boolean) => {
    if (
      !confirmedItems[index].type ||
      !confirmedItems[index].path ||
      (confirmedItems[index].path && !isValidPath(confirmedItems[index].path))
    ) {
      dangerState(false, index);
      !bool && !confirmedItems[index].type ? changeEditState(true, index) : changeEditState(false, index);
      return;
    }
    dangerState(true);
    if (nameError.includes(index)) return;
    if (!confirmedItems[index].name) {
      setNameError([...nameError, index]);
      return;
    }
    bool &&
      scraper &&
      scraper.sendMessage(
        'get_type_value',
        scraper.b64EncodeUnicode(
          `${confirmedItems[index].path} ^^ ${confirmedItems[index].type || 'unset'} ^^ ${
            confirmedItems[index].pattern || ''
          } ^^ ${confirmedItems[index].attribute || ''} ^^ ${confirmedItems[index].specify || ''}`,
        ),
      );

    //setNameError(-1);
    scraper && scraper.propertyAction('confirm');
    const arr = [...confirmedItems];
    if (!bool) {
      arr.splice(index, 1, {
        ...arr[index],
        done: true,
        doneName: arr[index].name,
        doneType: arr[index].type,
        doneValue: arr[index].value && arr[index].value.slice(0, 2000),
        donePath: arr[index].path,
        doneAttribute: arr[index].attribute,
        doneSpecify: arr[index].specify,
      });
      setConfirmedItems(arr);
      sendMessage('confirmed', 1);
    }
    setLoader(!loader);
    setCurrentTypeElement('');
  };

  const cancelEdit = (index: number) => {
    if (!confirmedItems[index].type || !isValidPath(confirmedItems[index].path)) {
      dangerState(false, index);
      !confirmedItems[index].type ? changeEditState(true, index) : changeEditState(false, index);
      return;
    }
    dangerState(true);

    const arr = [...confirmedItems];
    scraper && scraper.sendAction('select_cancel', arr[index].path);
    arr.splice(index, 1, {
      ...arr[index],
      //type: currentTypeElement,
      done: true,
      name: arr[index].doneName,
      type: arr[index].doneType ? arr[index].doneType : arr[index].type,
      attribute: arr[index].doneAttribute ? arr[index].doneAttribute : arr[index].attribute,
      specify: arr[index].doneSpecify ? arr[index].doneSpecify : arr[index].specify,
      value: arr[index].doneValue ? arr[index].doneValue : arr[index].value,
      path: arr[index].donePath ? arr[index].donePath : arr[index].path,
      doneType: arr[index].doneType ? arr[index].doneType : arr[index].type,
      doneValue: arr[index].doneValue ? arr[index].doneValue.slice(0, 2000) : arr[index].value?.slice(0, 2000),
      donePath: arr[index].donePath ? arr[index].donePath : arr[index].path,
      doneAttribute: arr[index].doneAttribute ? arr[index].doneAttribute : arr[index].attribute,
      doneSpecify: arr[index].doneSpecify ? arr[index].doneSpecify : arr[index].specify,
    });
    setConfirmedItems(arr);
  };

  const handleRemove = (index: number) => {
    const arr = [...confirmedItems];
    // scraper && scraper.propertyAction('close');
    scraper && scraper.sendAction('select_cancel', arr[index].path);
    arr.splice(index, 1);
    setLoader(!loader);
    setConfirmedItems(arr);
    if (index === dangerItem) {
      dangerState(true);
    }
    setNameError(nameError.filter(item => item !== index));
  };

  const handleChangeType = (index: number, type: any, path?: string) => {
    if (dangerItem === index && confirmedItems[index].path && isValidPath(confirmedItems[index].path)) {
      dangerState(true);
    }
    const arr = [...confirmedItems];
    arr.splice(index, 1, { ...arr[index], type: type || '', attribute: '' });
    setConfirmedItems(arr);
    scraper &&
      scraper.sendMessage(
        'get_type_value',
        scraper.b64EncodeUnicode(
          `${path ? path : confirmedItems[index].path} ^^ ${type || 'unset'} ^^ ${
            confirmedItems[index].pattern || ''
          } ^^  ^^ ${confirmedItems[index].specify || ''}`,
        ),
      );
    setLoader(!loader);
    setRender(!render);
  };

  // //SHOULD BE UPDATED!!!
  // const handleDownload = (json?: boolean) => {
  //   const newArr = [] as any;
  //   if (!json) {
  //     var str = '';

  //     for (var i = 0; i < newArr.length; i++) {
  //       var line = '';
  //       for (var index in newArr[i]) {
  //         if (line != '') line += ',';

  //         line += newArr[i][index];
  //       }

  //       str += line + '\r\n';
  //     }
  //     let fileToSave = new Blob([str], {
  //       type: 'text/csv;charset=utf-8',
  //     });

  //     saveAs(fileToSave, `preview_data.csv`);
  //     return;
  //   }
  //   let fileToSave = new Blob([JSON.stringify(newArr, null, 4)], {
  //     type: 'application/json',
  //   });

  //   saveAs(fileToSave, `preview_data.json`);
  //   return;
  // };
  const handleOnlyThisOrAll = (str: string) => {
    setSelectTypeIsClicked(true);
    setModalOpened(false);
    scraper && scraper.sendAction(str, lastEl);
    setLoader(!loader);
    const arr = [...confirmedItems];
    arr.push({ name: null, done: false, count: 0, type: '', simpleEdit: true, path: lastEl });
    setConfirmedItems(arr);
    setScrollToBottom(true);
  };
  const handleOnlyThis = () => {
    handleOnlyThisOrAll('select_only_this');
  };

  const handleAll = () => {
    handleOnlyThisOrAll('select_all');
    setScrollToBottom(true);
  };

  const handlePagination = (pageNumber: string, step: string) => {
    setSelectTypeIsClicked(false);
    setPaginationError(true);
    const parsedPageNumber = pageNumber ? parseInt(pageNumber) || pageNumber : 1;
    const parsedStep = step && parseInt(step) ? parseInt(step) : 1;
    // scraper && scraper.sendMessage('pagination', parsedPageNumber);
    scraper && scraper.pagination(parsedPageNumber, parsedStep);
    // scraper && scraper.propertyAction('pagination');

    setModalOpened(false);
    setLoader(!loader);
  };

  const handleAdvancedPagination = (
    pageFromNumber: string,
    pageToNumber: string,
    path: string,
    step?: string,
    elPath?: string,
  ) => {
    setSelectTypeIsClicked(false);
    const parsedPageFromNumber = pageFromNumber ? parseInt(pageFromNumber) || pageFromNumber : 1;
    const parsedPageToNumber = pageToNumber ? parseInt(pageToNumber) || pageToNumber : 1;
    const parsedStep = step && parseInt(step) ? parseInt(step) : 1;
    scraper &&
      scraper.sendMessage(
        'pagination_advanced',
        `${parsedPageFromNumber}:${parsedPageToNumber}${parsedStep ? ':' + parsedStep : ''} ^^ ${elPath || path || ''}${
          elPath ? ' ^^ path' : ''
        }`,
      );
    scraper && scraper.propertyAction('pagination_advanced');

    setModalOpened(false);
    setLoader(!loader);
  };

  const handleTypeAction = (typeText: string, path?: string) => {
    scraper && scraper.sendAction('select_only_this', path ? path : lastEl);
    setSelectTypeIsClicked(false);
    scraper && scraper.sendAction('type', path ? path : lastEl, typeText);
    setGoToData([
      ...goToData,
      {
        a: 'type',
        p: (path ? path : lastEl)
          .replace('.hexoscraper-scale', '')
          .replace('.hexoScrapOverlay', '')
          .replace('.hexoScrapSelect', '')
          .replace('.hexoScrapHover', '')
          .replace('.hexoScrap-context', '')
          .replace('.hexoScrap-only-this', '')
          .replace('.hexoScrap-actions-ui', '')
          .replace('.hexoScrap-overlay', ''),
        v: typeText,
      },
    ]);
    setModalOpened(false);
    setLoader(!loader);
  };

  const handleBlockElement = (path?: string) => {
    handleActionStatic(
      {
        type: 'block element',
        element: path ? path : lastEl,
      },
      {
        a: 'block',
        p: (path ? path : lastEl)
          .replace('.hexoscraper-scale', '')
          .replace('.hexoScrapOverlay', '')
          .replace('.hexoScrapSelect', '')
          .replace('.hexoScrapHover', '')
          .replace('.hexoScrap-context', '')
          .replace('.hexoScrap-only-this', '')
          .replace('.hexoScrap-actions-ui', '')
          .replace('.hexoScrap-overlay', ''),
      },
    );
  };

  const handleLoadMore = (count: string, path?: string) => {
    handleActionStatic(
      {
        type: 'load more',
        element: path ? path : lastEl,
      },
      {
        a: 'load_more',
        p: (path ? path : lastEl)
          .replace('.hexoscraper-scale', '')
          .replace('.hexoScrapOverlay', '')
          .replace('.hexoScrapSelect', '')
          .replace('.hexoScrapHover', '')
          .replace('.hexoScrap-context', '')
          .replace('.hexoScrap-only-this', '')
          .replace('.hexoScrap-actions-ui', '')
          .replace('.hexoScrap-overlay', ''),
        c: parseInt(count) ? parseInt(count) : 1,
      },
    );
  };

  const handleClickAction = (path?: string, index?: number) => {
    setIsSend(true);
    setSelectTypeIsClicked(false);
    setModalOpened(false);
    let lastElA =
      (path ? path : lastEl).indexOf(' > a > ') > -1
        ? (path ? path : lastEl).slice(0, (path ? path : lastEl).indexOf(' > a > ') + 4)
        : path
        ? path
        : lastEl;
    if (index) lastElA += ` > option:nth-child(${index})`;
    scraper && scraper.sendAction('select_only_this', lastElA);
    scraper && scraper.sendAction('click', lastElA);
    setGoToData([
      ...goToData,
      {
        a: 'click',
        p: lastElA
          .replace('.hexoscraper-scale', '')
          .replace('.hexoScrapOverlay', '')
          .replace('.hexoScrapSelect', '')
          .replace('.hexoScrapHover', '')
          .replace('.hexoScrap-context', '')
          .replace('.hexoScrap-only-this', '')
          .replace('.hexoScrap-actions-ui', '')
          .replace('.hexoScrap-overlay', ''),
      },
    ]);
    setLoader(!loader);
    setLoadingIframe(true);
  };
  const handleActionRemove = (item: any, index: number) => {
    setRemoveEditUrl(true);
    const arr = [...confirmedItems];
    arr.splice(index, 1);
    let lastIndex = -1;
    for (let i = arr.length - 1; i >= 0; i--) {
      if (arr[i].last) {
        lastIndex = i;
        return i;
      }
    }
    lastIndex > -1 && arr.splice(lastIndex, 1, { ...arr[lastIndex], last: true });
    scraper && scraper.sendAction('action_undo');
    setActionRemoved(true);
    setConfirmedItems(
      arr.map((item: any, index: number) => {
        return { ...item, last: index === arr.length - 1 };
      }),
    );
    if (selectedActiveBrowser.label === 'Full-stack') {
      const newArr = [...goToData];
      setGoToData(newArr.slice(0, newArr.length - 1));
      scraper &&
        scraper.sendAction('goto', {
          data: '',
          goToData: newArr.slice(0, newArr.length - 1),
        });
    }
    setLoadingIframe(true);
    setLoader(!loader);
  };

  const handlePaginationRemove = (index: number) => {
    if (!confirmedItems[index].type) {
      dangerState(true);
    }
    const arr = [...confirmedItems];
    arr.splice(index, 1);
    setConfirmedItems(arr);
    if (selectedActiveBrowser.label === 'Full-stack') {
      const removedPagination = goToData.filter((item: any) => item.a !== 'goto');
      setGoToData(removedPagination);
    }
  };
  const handleModalCancel = () => {
    scraper && scraper.sendAction('select_cancel');
  };

  const handleCloseActionModal = () => {
    setModalOpened(false);
    handleModalCancel();
    setShowAllActions(true);
  };

  const handleActionClick = () => {
    setShouldUpgrade(false);
    buyPremiumCredits ? setShowPaymentModal(true) : (window.location.href = 'https://hexomatic.com/pricing/');
  };

  const handleLeavePage = () => {
    setModalOpenedcloseEdit({ show: false, location: null });
    history.push(modalOpenedcloseEdit.location);
  };

  const closeModal = () => {
    setIsDirty(true);
    setModalOpenedcloseEdit({ show: false, location: null });
  };

  const handleShowPaymentMethods = () => {
    setShowPaymentModal(false);
    setBuyPremiumCredits(false);
  };

  const fieldLogoChange = (file: any) => {
    setUploadedImg(file);
  };

  const handleRecipeUpdate = async ({ id, text }: any) => {
    if (!text || !isValidWorkflowName(text)) {
      return { message: 'Invalid name' };
    }
    const path = location.pathname;
    if (
      path.includes('scraping-public-recipes') ||
      (path.includes('scraping-recipes') && !path.includes('scraping-recipes/current-recipe'))
    ) {
      setRecipeName(text);
      return;
    }
    setJustNameChanging(true);
    const result = await updateSingleRecipe({
      variables: {
        settings: {
          id: id && parseInt(id),
          name: text,
        },
      },
    });
    setJustNameChanging(false);
    if (result?.data?.HexomaticScrapingRecipeOps?.updateScrapingRecipe?.error_code) {
      const { error_code } = result.data.HexomaticScrapingRecipeOps.updateScrapingRecipe;
      //@ts-ignore
      if (errorMessages && errorMessages[error_code]) {
        if (error_code === 'ALREADY_EXISTS') {
          if (text) return { message: `"${text.length > 25 ? text.slice(0, 25) + '...' : text}" already exists` };
        } else {
          //@ts-ignore
          return { message: errorMessages[error_code] };
        }
      } else {
        return { message: error_code };
      }
    } else {
      setRecipeName(text);
      return { message: null };
    }
  };
  const handleWarningPreview = () => {
    setSelectedActiveProxy(selectedProxy);
    setSelectedActiveBrowser(selectedBrowser);
    setResidentialActiveGEOCountry(residentialGEOCountry);
    setWarningText('');
    handlePreview(false);
  };
  const handleCancelPreview = () => {
    setWarningText('');
    setSelectedBrowser(selectedActiveBrowser);
    setSelectedProxy(selectedActiveProxy);
    setResidentialGEOCountry(residentialActiveGEOCountry);
  };
  const handleChangeAttribute = (index: number, attribute: any, path?: string) => {
    scraper &&
      scraper.sendMessage(
        'get_type_value',
        scraper.b64EncodeUnicode(
          `${path ? path : confirmedItems[index].path} ^^ ${confirmedItems[index].type || 'unset'} ^^ ${
            confirmedItems[index].pattern || ''
          } ^^ ${attribute}  ^^ ${confirmedItems[index].specify || ''}`,
        ),
      );
    setLoader(!loader);
    setRender(!render);
  };

  const handleSyncWithParent = async (): Promise<any> => {
    setOverwrite(true);
    getSingleRecipe({
      variables: {
        settings: {
          id: parentRecipeId,
          isShared: true,
        },
      },
    });
    setIsSyncWithParentModalOpen(false);
  };

  const handleRecipeShareUpdates = async (): Promise<any> => {
    const { data } = await ignoreRecipeShareUpdates({
      variables: {
        settings: {
          importedRecipeId: +updateRecipId,
          ignore: true,
        },
      },
    });

    const { error_code } = data?.HexomaticScrapingRecipeOps?.ignoreSharedRecipeUpdates;

    if (error_code) {
      setWarningText('Something went wrong.');
      return;
    }
  };

  useEffect(() => {
    if (!modalOpened) setSelectOptions([]);
  }, [modalOpened]);

  const handleCancelClick = () => {
    setShouldUpgrade(false);
  };

  useEffect(() => {
    if (scraperData && !loadingIframe) {
      const el = document.getElementsByClassName('hexoScrapWebview');
      //@ts-ignore
      const height = el && el[0] && el[0].offsetHeight;
      //@ts-ignore
      const width = el && el[0] && el[0].offsetWidth;
      const iframeEl = document.getElementById('webview');
      iframeEl && height && iframeEl.style && (iframeEl.style.height = `${height / 0.55}px`);
      iframeEl && height && iframeEl.style && (iframeEl.style.margin = `-${(height * 275) / 1200 / 0.55}px -425px`);
    }
  }, [scraperData, loadingIframe]);

  const handleRetry = () => {
    if (createRecipeLoading || scraperLoading || updateSingleRecipeLoading || getRecipeLoading || loadingIframe) return;
    setError('');
    getScraperDataPreview({
      variables: { address: address.trim(), type: '', toggle: Math.random(), autoscrape },
    });
    setLoader(!loader);
    setLoadingIframe(true);
    setRetry(true);
  };

  const handleWarningModalClose = () => {
    setWarningModal(false);
  };

  const handleHighlight = (item: any) => {
    item.path &&
      scraper &&
      scraper.sendMessage(
        'highlight_element',
        scraper.b64EncodeUnicode(
          `${item.path} ^^ ${item.type || 'unset'} ^^ ${item.pattern || ''} ^^ ${item.attribute || ''} ^^ ${
            item.specify || ''
          }`,
        ),
      );
  };

  const handleRemoveHighlight = (item: any) => {
    item.path &&
      scraper &&
      scraper.sendMessage(
        'remove_highlight_element',
        scraper.b64EncodeUnicode(
          `${item.path} ^^ ${item.type || 'unset'} ^^ ${item.pattern || ''} ^^ ${item.attribute || ''} ^^ ${
            item.specify || ''
          }`,
        ),
      );
  };

  return (
    <div className={`preview-section ${scraper ? 'p-b-120' : ''}`}>
      <Prompt
        when={isDirty}
        message={(location, action) => {
          setModalOpenedcloseEdit({ show: true, location: location.pathname });
          setIsDirty(false);
          return false;
        }}
      />
      {savedAddress && savedAddress !== address && (
        <div className="warning-wrapper">
          <Message text="To save the changes, first Preview the page." type="warning" />
        </div>
      )}
      <Row className="recipe-header">
        {/* {updateRecipId ? (
          location.pathname.includes('scraping-recipes') ? (
            <Breadcrumb
              items={[{ to: `/scraping-recipes`, name: 'Scraping Recipes', active: false }]}
              className="workflow-create-breadcrumbs"
            />
          ) : (
            <Breadcrumb
              items={[{ to: `/scraping-recipes`, name: 'Scraping Recipes', active: false }]}
              className="workflow-create-breadcrumbs"
            />
          )
        ) : (
          <Breadcrumb
            items={[{ to: `/scraping-recipes`, name: 'Scraping Recipes', active: false }]}
            className="workflow-create-breadcrumbs"
          />
        )} */}
        <Col sm={paidUser ? 8 : 12}>
          {recipeName && (
            <EditableTextOpen
              name={recipeName || ''}
              id={+updateRecipId}
              onSave={async ({ id, text }: any) => {
                return handleRecipeUpdate({ id, text });
              }}
              absoluteError
              maxTextCount={64}
              errorMessageLength={50}
              existingWorkflow={!!updateRecipId}
            />
          )}
        </Col>

        {paidUser ? (
          <Col sm={4} className="text-left text-sm-right text-xl-center">
            <img
              src={isMobileLg ? conciergeMobile : concierge}
              className="ml-auto cursor-pointer"
              style={{ maxWidth: '100%' }}
              onClick={() => setShowConcierge(true)}
            />
          </Col>
        ) : null}
      </Row>

      {/* place modal for changes onleaving page */}

      <div>
        <Row className="scraped-container">
          <Col lg={8} xl={8} className="scraped-left pr-0">
            <Preview
              selectedProxy={selectedProxy}
              handleEnterKeyPress={handleEnterKeyPress}
              setAddress={(val: any) => {
                setIsDirty(true);
                setAddress(val);
              }}
              address={address?.trim()}
              selectedBrowser={selectedBrowser}
              setSelectedBrowser={(val: any) => {
                setIsDirty(true);
                changeBrowser(val);
              }}
              handleChangeSelectedProxy={(val: any) => {
                setIsDirty(true);
                handleChangeSelectedProxy(val);
              }}
              residentialGEOCountry={residentialGEOCountry}
              handlePreview={handlePreview}
              setBlockAds={(val: any) => {
                setIsDirty(true);
                setBlockAds(val);
              }}
              updateRecipId={updateRecipId}
              loading={
                createRecipeLoading ||
                (updateSingleRecipeLoading && !justNameChanging) ||
                scraperLoading ||
                loadingIframe ||
                updateSingleRecipeLoading ||
                getRecipeLoading
              }
              handleChangeResidentialGEOCountry={(val: any) => {
                setIsDirty(true);
                handleChangeResidentialGEOCountry(val);
              }}
              blockAds={blockAds}
              confirmedItemsLength={confirmedItems?.length}
              // autoscrape={autoscrape}
              // setAutoscrape={(val: boolean) => {
              //   localStorage.setItem('suggest-elements', `${val}`);
              //   setAutoscrape(val);
              // }}
            />

            {(error || paginationErrorMessage) && (
              <div className="mt-3">
                <Message type={'error'} text={error || paginationErrorMessage} />
              </div>
            )}
            {(createRecipeLoading ||
              scraperLoading ||
              loadingIframe ||
              (updateSingleRecipeLoading && !justNameChanging) ||
              getRecipeLoading) && <Loader light={theme === 'light'} />}
            {scraper && (
              <div className="position-relative">
                <div
                  className="hexoScrapWebview"
                  style={{
                    visibility:
                      !createRecipeLoading && !scraperLoading && !loadingIframe && !hideScraper ? 'visible' : 'hidden',
                  }}
                >
                  <div className="empty">{/* <label >Please input URL</label> */}</div>
                </div>
                <Row
                  className={`position-absolute overflow-auto iframe-hide ${dangerItem === -1 ? 'd-none' : ''}`}
                  onClick={handelStopClick}
                >
                  <Col
                    sm={{ span: 10, offset: 1 }}
                    md={{ span: 8, offset: 2 }}
                    lg={{ span: 12, offset: 0 }}
                    xl={{ span: 4, offset: 0 }}
                    className="scraped-right"
                  ></Col>
                </Row>
              </div>
            )}
          </Col>
          {(scraper || hideScraper) && !loadingIframe && (
            <RightSide
              confirmedItems={confirmedItems}
              autoscrapedData={autoscrapedData}
              handleElementSelect={handleAutoElementSelect}
              dangerItem={dangerItem}
              itemIndex={itemIndex}
              setCompleted={setCompleted}
              handelAddAction={handelAddAction}
              browserMode={selectedActiveBrowser.label}
              handlePaginationRemove={handlePaginationRemove}
              handleActionRemove={handleActionRemove}
              handleRemove={handleRemove}
              changeEditState={changeEditState}
              setCurrentTypeElement={setCurrentTypeElement}
              nameError={nameError}
              setNameError={setNameError}
              completed={completed}
              handleConfirm={handleConfirm}
              cancelEdit={cancelEdit}
              handleChangeType={handleChangeType}
              path={path}
              setPath={setPath}
              pattern={pattern}
              setPattern={setPattern}
              setConfirmedItems={setConfirmedItems}
              setDangerItem={setDangerItem}
              setItemIndex={setItemIndex}
              loading={
                createRecipeLoading || scraperLoading || loadingIframe || updateSingleRecipeLoading || getRecipeLoading
              }
              actionsIndexes={actionsIndexes}
              handelKeyUp={(index: number, attribute: any, path?: string) =>
                setStartSearch(setTimeout(() => handleChangeAttribute(index, attribute, path), 500))
              }
              handelKeyDown={() => clearTimeout(startSearch)}
              handleRetry={handleRetry}
              error={error === 'Something went wrong. Please try again'}
              specify={specify}
              setSpecify={setSpecify}
              handleHighlight={handleHighlight}
              handleRemoveHighlight={handleRemoveHighlight}
              suggestionsBox={suggestionsBox}
              setSuggestionsBox={setSuggestionsBox}
              setScrollToBottomProp={setScrollToBottom}
              scrollToBottomProp={scrollToBottom}
            />
          )}
        </Row>

        <div className="scraped">
          <div className="title"></div>
          <div className="data">
            <pre></pre>
          </div>
        </div>
      </div>
      <ElementClickModal
        isModalOpen={modalOpened}
        onCloseModal={handleCloseActionModal}
        handleOnlyThis={handleOnlyThis}
        handleAll={handleAll}
        handlePagination={handlePagination}
        handleTypeAction={handleTypeAction}
        handleAdvancedPagination={handleAdvancedPagination}
        handleClickAction={handleClickAction}
        showAllActions={showAllActions}
        browserMode={selectedActiveBrowser.label !== 'Fast'}
        advancedLimit15={confirmedItems.find(
          (item: any) => item.action && (item.action.type === 'cookie' || item.action.type === 'local storage'),
        )}
        handleActionStatic={handleActionStatic}
        notAllow={goToData.length > 50}
        notAllowCookies={
          selectedActiveBrowser.label === 'Full-stack' && confirmedItems.find((item: any) => item.pagination)
        }
        handleBlockElement={handleBlockElement}
        address={address}
        handleLoadMore={handleLoadMore}
        options={selectOptions}
        setOptions={setSelectOptions}
        selectOptionIsUsed={
          !!confirmedItems.find(
            (item: any) =>
              item.action && item.action.type === 'click' && item.action.element.includes('select > option:nth-child'),
          )
        }
        lastEl={lastEl}
      />
      {!!scraper && (
        <StickyFooter
          handleCancel={handleRecipeCancel}
          handleSave={handleRecipeCreate}
          disableBtns={updateSingleRecipeLoading}
        >
          <div className="row recipe-footer-part">
            {
              <div className="col-lg-8 col-md-12 img-coment-block">
                <div className="row">
                  <div className="col-md-2 col-sm-12 col-xs-12 upload-block">
                    <UploadImage onChange={fieldLogoChange} defaultImg={recipeIcon} uploadedImg={uploadedImg} />
                  </div>
                  <div className="col-md-10 col-sm-12 col-xs-12 textarea-block">
                    <Textarea
                      value={comment}
                      placeholder="Recipe description..."
                      rows={1}
                      disableResize={true}
                      onChange={(e: { target: { value: any } }) => {
                        const value = e.target.value;
                        if (value.length > 300) {
                          return;
                        }
                        setComment(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            }
            {/* {showFooter ? (
              <button onClick={() => setShowFooter(prev => !prev)} className="arrow-up">
                {arrowDownIcon}
              </button>
            ) : (
              <button onClick={() => setShowFooter(prev => !prev)} className="arrow-down">
                {arrowDownIcon}
              </button>
            )} */}
            <div className="col-lg-4 py-2 sticky-footer-buttons-recipe">
              <div className="buttons m-horizontal-auto">
                <Button
                  disabled={updateSingleRecipeLoading}
                  name="Cancel"
                  className="mr-4"
                  outline
                  onClick={handleRecipeCancel}
                />
                <Button disabled={updateSingleRecipeLoading} name={'Save'} onClick={handleRecipeCreate} />
              </div>
            </div>
          </div>
        </StickyFooter>
      )}

      <Modal
        isModalOpen={shouldUpgrade}
        title={
          !buyPremiumCredits ? (
            'Please upgrade your plan to continue'
          ) : user.premium_credits_paypal_subscription_id &&
            (user.premium_credits_pricing_package === 'PC_26400' ||
              user.premium_credits_pricing_package === 'PC_264000') ? (
            <ContactUs showCloseIcon handleCloseModal={handleCancelClick} />
          ) : (
            'Please add premium credits to continue'
          )
        }
        parentClass={
          (user.premium_credits_pricing_package === 'PC_26400' ||
            user.premium_credits_pricing_package === 'PC_264000') &&
          user.premium_credits_paypal_subscription_id &&
          buyPremiumCredits
            ? 'contact_us_modal'
            : ''
        }
        actionBtnText={
          (user.premium_credits_pricing_package === 'PC_26400' ||
            user.premium_credits_pricing_package === 'PC_264000') &&
          user.premium_credits_paypal_subscription_id &&
          buyPremiumCredits
            ? ''
            : 'Upgrade'
        }
        cancelBtnText={
          (user.premium_credits_pricing_package === 'PC_26400' ||
            user.premium_credits_pricing_package === 'PC_264000') &&
          user.premium_credits_paypal_subscription_id &&
          buyPremiumCredits
            ? ''
            : 'Cancel'
        }
        actionBtnColor="warning"
        cancelBtnColor="secondary"
        onActionClick={handleActionClick}
        onCancelClick={handleCancelClick}
        onlyBtns={
          !!(
            buyPremiumCredits &&
            (user.premium_credits_pricing_package === 'PC_26400' ||
              user.premium_credits_pricing_package === 'PC_264000') &&
            user.premium_credits_paypal_subscription_id
          )
        }
        escButtonClick={handleCancelClick}
      />
      {showPaymentModal && (
        <CustomModal
          ShowPopup={showPaymentModal}
          setShowPopup={handleShowPaymentMethods}
          noNavigate
          escButtonClick={() => setShowPaymentModal(false)}
        >
          <Payment
            setShowPopup={handleShowPaymentMethods}
            name={''}
            selectedValue={{ title: '105/monthly', value: 105 }}
            price={0}
            premium_credit
          />
        </CustomModal>
      )}
      <Modal
        isModalOpen={modalOpenedcloseEdit.show}
        text={'Are you sure you want to leave?'}
        title={"Changes you've made will be lost if you exit now."}
        actionBtnText={'Leave'}
        cancelBtnText={'Stay'}
        actionBtnColor={'danger'}
        cancelBtnColor={'secondary'}
        onActionClick={handleLeavePage}
        onCancelClick={closeModal}
        escButtonClick={closeModal}
      />
      <Modal
        isModalOpen={warningModal}
        text={'No element is selected. Please select an element before saving the scraping recipe.'}
        actionBtnText={'Ok'}
        actionBtnColor={'danger'}
        onActionClick={handleWarningModalClose}
        escButtonClick={handleWarningModalClose}
      />
      <Modal
        isModalOpen={confirmationModalOpen}
        text="Please note that this action could impact the outcome."
        actionBtnText={'OK'}
        cancelBtnText={'Cancel'}
        actionBtnColor={'warning'}
        cancelBtnColor={'secondary'}
        onActionClick={() => handlePreview(false, undefined, true)}
        onCancelClick={() => setConfirmationModalOpen(false)}
        escButtonClick={() => setConfirmationModalOpen(false)}
      />
      <Modal
        isModalOpen={!!warningText}
        text={warningText}
        actionBtnText="Preview"
        cancelBtnText={'Cancel'}
        actionBtnColor={'warning'}
        cancelBtnColor={'secondary'}
        onActionClick={handleWarningPreview}
        onCancelClick={handleCancelPreview}
        escButtonClick={handleCancelPreview}
      />

      <Modal
        isModalOpen={isSyncWithParentModalOpen}
        title="The user has made changes in the scraping recipe"
        text="Would you like to overwrite all the changes?"
        actionBtnText=""
        actionBtnColor="warning"
        onActionClick={() => {}}
        escButtonClick={() => setIsSyncWithParentModalOpen(false)}
      >
        <div className="sync-buttons-wrapper">
          <Button
            className="mx-2 no-wrap"
            outline
            name="Ignore once"
            onClick={() => setIsSyncWithParentModalOpen(false)}
          />
          <Button
            className="mx-2 no-wrap"
            outline
            name="Ignore future changes"
            onClick={() => {
              handleRecipeShareUpdates();
              setIsSyncWithParentModalOpen(false);
            }}
          />
          <Button className="mx-2" name="Overwrite" onClick={handleSyncWithParent} />
        </div>
      </Modal>
      {showConcierge && (
        <ModalBootstrap
          show={!!showConcierge}
          toggle={() => {}}
          className={`promo-modal modal-dialog--header theme-${theme}`}
        >
          <button
            className="panel__btn dark"
            style={{ marginTop: '5px', marginRight: '10px' }}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              setShowConcierge(false);
            }}
            type="button"
          >
            {closeIcon}
          </button>
          <div className="modal__body" style={{ padding: '0', background: '#301371' }}>
            <img
              onClick={() => window.open('https://calendly.com/hexact/concierge-service-hexact', '_blank')}
              src={isMobile ? popupMobile : popupDesktop}
              alt="Promo"
              className="cursor-pointer"
            />
          </div>
        </ModalBootstrap>
      )}
    </div>
  );
};

export default CreateRecipes;
