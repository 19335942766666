import { getParsedDate, getParsedDateTime } from 'helpers';
import { memo, useCallback } from 'react';
import Tooltip from 'shared-components/atom/tooltip';
import './styles.scss';

const TimeElement = ({ time, tooltipPosition }: { time: any, tooltipPosition?: 'top' | 'bottom' | 'right' | 'left' }) => {
  const onMouseEnter = useCallback((e: any) => {
    e.preventDefault();
  }, []);
  return (
    <Tooltip trigger={['hover', 'focus']} placement={tooltipPosition || "top"} text={getParsedDateTime(time)}>
      <span onMouseEnter={onMouseEnter} className='secondary-color'>{getParsedDate(time)}</span>
    </Tooltip>
  );
};
export default memo(TimeElement);
