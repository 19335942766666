import { arrowDownIcon } from 'assets/svg-icons';
import { FC, memo } from 'react';
import MultiSelect from 'react-multi-select-component';

interface Option {
  value: any;
  label: string;
  key?: string;
  disabled?: boolean;
}
interface CustomMultiSelectProps {
  options: Option[];
  value: Option[];
  onChange: any;
  labelledBy: string;
  hasSelectAll?: boolean;
  className?: string;
}

const CustomMultiSelect: FC<CustomMultiSelectProps> = ({
  options,
  value,
  onChange,
  labelledBy,
  hasSelectAll,
  className,
}) => {
  const filterOptions = (options: Option[], pattern: string) => {
    if (!pattern) {
      return options;
    }
    const re = new RegExp(pattern, 'i');
    const b = options.filter(({ label }) => label && label.toLowerCase().startsWith(pattern.toLowerCase()));
    const c = options.filter(
      ({ label }) => label && !label.toLowerCase().startsWith(pattern.toLowerCase()) && label.match(re),
    );

    return [...b, ...c];
  };

  return (
    <MultiSelect
      options={options}
      value={value}
      onChange={onChange}
      labelledBy={labelledBy}
      filterOptions={filterOptions}
      hasSelectAll={hasSelectAll !== undefined ? hasSelectAll : true}
      className={className}
      ArrowRenderer={({expanded}) => arrowDownIcon}
    />
  );
};

export default memo(CustomMultiSelect);
