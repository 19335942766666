import { FC } from 'react';
import './styles.scss';

interface IAppProps {
  percent: number;
  text?: string;
  noMessage?: boolean;
}

const CustomProgress: FC<IAppProps> = ({ percent, text, noMessage }) => {
  return (
    <section className="section">
      <div className={`final__animation-bar-2 ${noMessage ? '' : 'show-tooltip'}`}>
        <span data-label={text || percent + '%'} style={{ width: percent + '%' }} className="tooltip-bg"></span>
      </div>
    </section>
  );
};

export default CustomProgress;
