import { FC, useEffect, useState, useContext, useRef, useMemo, useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import { ThemeContext } from 'contexts/theme-context';
import { HexomaticUserContext } from 'contexts/hexomatic-user';

import { GET_PUBLIC_REICPES, GET_PUBLIC_SCRAPING_CATEGORIES } from 'graphql/queries';

import NothingToShow from 'shared-components/atom/nothing-to-show';
import Button from 'shared-components/atom/button';
import Loader from 'shared-components/atom/loader';
import Message from 'shared-components/atom/alert-message';
import Modal from 'shared-components/molecule/modal';
import SearchContainer from 'shared-components/molecule/search-container/updated';

import RecipeCard from '../components/public-recipe-card';
import { rgbColors } from 'constants/index';

import { longArrowLeft, loopIcon, nothingToShow } from 'assets/svg-icons';

import '../create/styles.scss';
import Fields from 'shared-components/molecule/filter-fields';
import TemplateCard from 'shared-components/atom/template-card';

export const newRecipes = [316, 284, 326, 325, 322, 258, 323, 324, 269, 270, 307, 327];

interface SelectOption {
  label: string;
  value: string;
}

interface SelectOptionColor extends SelectOption {
  colorObj: {
    color: string;
    background: string;
  };
}

interface Category {
  name: string;
  id: string;
  created_at: string;
}

const ScrapingRecipes: FC = () => {
  const { push } = useHistory();

  const { hexomaticUser } = useContext(HexomaticUserContext);
  const { theme } = useContext(ThemeContext);

  const mounted = useRef();
  const mounted1 = useRef();

  const [shouldUpgrade, setShouldUpgrade] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  // const [checkedIndexes, setCheckedIndexes] = useState([] as any);
  // const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [categoriesList, setCategoriesList] = useState<SelectOptionColor[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<SelectOptionColor[]>([]);

  const details: {
    page: number;
    searchText: string;
    selectedOption: SelectOption[];
  } =
    //@ts-ignore
    localStorage.getItem('recipes-details') ? JSON.parse(localStorage.getItem('recipes-details')) : null;

  const [error, setError] = useState('');
  const [showRecipes, setShowRecipes] = useState(false);

  const [recipesCard, setRecipesCard] = useState([] as any);
  const [searchText, setSearchText] = useState('');
  const [startSearch, setStartSearch] = useState(setTimeout(() => {}));
  const [totalCount, setTotalCount] = useState(0);
  const [cardLimit, setCardLimit] = useState(20);

  const [viewMode, setViewMode] = useState<'listView' | 'cardView'>(
    //@ts-ignore
    localStorage.getItem('recipe-view-mode') ? localStorage.getItem('recipe-view-mode') || 'listView' : 'listView',
  );

  const [recipePage, setRecipePage] = useState(viewMode === 'listView' && details && details.page ? details.page : 1);

  const [limit, setLimit] = useState<{ label: string; value: number }>(
    //@ts-ignore
    localStorage.getItem('recipeListLimit')
      ? //@ts-ignore
        JSON.parse(localStorage.getItem('recipeListLimit'))
      : { label: '20', value: 20 },
  );

  const { data: scrapingCategories, loading: scrapingCategoriesLoading } = useQuery(GET_PUBLIC_SCRAPING_CATEGORIES);

  const { data: recipesCardData, loading: recipesCardDataLoading } = useQuery(GET_PUBLIC_REICPES, {
    fetchPolicy: 'network-only',
    variables: {
      settings: {
        filter: {
          page: null,
          limit: cardLimit,
          date_from: null,
          date_to: null,
          sort_order: null,
          sort_by: null,
          search: {
            search_field: null,
            search_value: searchText,
          },
          categoryIds: selectedCategories.map((item: SelectOption) => item.value),
        },
      },
    },
  });

  useEffect(() => {
    if (scrapingCategories?.HexomaticScrapingCategory?.getScrapingCategories?.categories) {
      const data = scrapingCategories.HexomaticScrapingCategory.getScrapingCategories.categories.map(
        (item: Category, i: number) => ({ label: item.name, value: item.id, colorObj: rgbColors[i] }),
      );
      setCategoriesList(data);
    }
  }, [scrapingCategories]);

  useEffect(() => {
    if (
      hexomaticUser?.pricing_package &&
      (hexomaticUser?.pricing_package !== 'FREE')
    ) {
      setShowRecipes(true);
    } else {
      setShowRecipes(false);
    }
  }, [hexomaticUser]);

  useEffect(() => {
    if (recipesCardData?.HexomaticPublicScrapingRecipe?.getPublicScrapingRecipes?.public_scraping_recipes) {
      const data = recipesCardData.HexomaticPublicScrapingRecipe.getPublicScrapingRecipes.public_scraping_recipes;
      if (categoriesList.length && data?.length) {
        categoriesList.forEach(category => {
          data.forEach((itm: any) => {
            if (itm?.categories?.length) {
              const ind = itm.categories.findIndex((item: Category) => item.name === category.label);

              if (ind !== -1) {
                itm.categories[ind].colorObj = category.colorObj;
              }
            }
          });
        });
      }
      const paidUser = hexomaticUser?.pricing_package && hexomaticUser?.pricing_package !== 'FREE';
      const fullList = recipesCardData.HexomaticPublicScrapingRecipe.getPublicScrapingRecipes.public_scraping_recipes;
      if (paidUser) {
        const insertIndex = fullList.length > 3 ? 2 : fullList.length;
        !fullList.find((item: any) => item.demoLink) && fullList.splice(insertIndex, 0, {demoLink: true});
      }
      setRecipesCard(fullList);
    }
  }, [recipesCardData]);

  const handleCardLimit = (): void => {
    if (totalCount > cardLimit) {
      setCardLimit(cardLimit + 20);
    }
  };

  useEffect(() => {
    if (!mounted.current) {
      //@ts-ignore
      mounted.current = true;
    } else {
      localStorage.setItem(
        'recipes-details',
        JSON.stringify({
          page: recipePage,
          searchText: searchText,
          selectedOption: selectedCategories,
        }),
      );
    }
  }, [recipePage, searchText, selectedCategories]);
  useEffect(() => {
    const sidebarOpen = document.getElementById('nav-bar');
    sidebarOpen && setSidebarOpen(sidebarOpen?.offsetWidth > 100);
  }, []);

  const handelKeyUp = useCallback(
    (e: any) => {
      setStartSearch(setTimeout(() => e.target.value != searchText && setSearchText(e.target.value), 500));
    },
    [searchText],
  );

  const handelKeyDown = useCallback(() => {
    clearTimeout(startSearch);
  }, [startSearch, clearTimeout]);

  useEffect(() => {
    if (!mounted1.current) {
      //@ts-ignore
      mounted1.current = true;
    } else {
      setRecipePage(1);
    }
  }, [searchText, selectedCategories, limit]);

  const handleChangeMultiSelect = (val: string) => {
    const selected = selectedCategories.find(item => item.value === val);
    const obj = categoriesList.find(item => item.value === val);
    let list = val
      ? selected
        ? selectedCategories.filter((item: any) => item.value !== val)
        : obj
        ? [...selectedCategories, obj]
        : selectedCategories
      : [];

    setSelectedCategories(list);
    setRecipePage(1);
    localStorage.setItem(
      'recipes-details',
      JSON.stringify({
        page: 1,
        searchText: searchText,
        selectedOption: list,
      }),
    );
  };

  const list: any[] = useMemo(() => {
    return [
      {
        type: 'input',
        className: 'pl-3 pr-2 col-12',
        value: undefined,
        placeholder: 'Search...',
        onKeyUp: handelKeyUp,
        onKeyDown: handelKeyDown,
        icon: loopIcon,
      },
      // {
      //   type: 'multi select',
      //   className: 'pl-3 pr-2 col-xl-4 col-lg-6 mt-3',
      //   placeholder: 'Select categories',
      //   elClassName: `search-multi-select ${selectedCategories.length < 1 ? 'empty' : ''}`,
      //   value: selectedCategories,
      //   options: categoriesList,
      //   onChange: handleChangeMultiSelect,
      // },
    ];
  }, [handelKeyUp, handelKeyDown, searchText, selectedCategories, categoriesList]);

  const { state } = useLocation();

  //@ts-ignore
  const backUrl = state?.backUrl;

  useEffect(() => {
    setTimeout(() => {
      const el = document.getElementById('sticky-search-container');
      const elPadding = document.getElementById('sticky-search-container-padding');
      const header = document.getElementById('header');
      el && header?.offsetHeight && (el.style.top = `${header?.offsetHeight}px`);
      el && el.offsetHeight && elPadding && (elPadding.style.paddingTop = `${el.offsetHeight + 5}px`);
    }, 1000);
    const el = document.getElementById('sticky-search-container');
    const rSidebarShow = document.getElementsByClassName('r-show-sidebar');
    const current = (el && el.className) || '';
    el &&
      (rSidebarShow.length
        ? (el.className = current + ' r-sidebar-open')
        : (el.className = current.replace(/r-sidebar-open/g, '')));
  }, []);

  return (
    <div>
      <div className={`sticky-search-container ${sidebarOpen ? 'sidebar-open' : ''}`} id="sticky-search-container">
        <div className="content-wrapper">
          <div className="d-lg-flex mt-2">
            <h4 className="pl-1 my-auto page-title no-transform py-0" style={{ fontSize: '16px', fontWeight: 500 }}>
              {backUrl ? (
                <span onClick={() => push(`${backUrl}`)} className="mr-2 my-auto cursor-pointer">
                  {longArrowLeft}
                </span>
              ) : null}
              Scraping recipe templates
            </h4>
            <div className="ml-lg-auto">
              <SearchContainer list={list} maxWidth="400px" />
            </div>
          </div>

          <Fields
            loading={false}
            lists={[
              { name: 'All', id: '' },
              ...categoriesList.map(item => {
                return { name: item.label, id: item.value };
              }),
            ]}
            selectedList={selectedCategories.map(item => item.value)}
            onChange={val => typeof val === 'string' && handleChangeMultiSelect(val)}
          />
        </div>
      </div>
      <div className="sticky-search-container-padding" id="sticky-search-container-padding">
        {showRecipes && !recipesCardDataLoading ? (
          <>
            <div className="row" style={{ margin: '0 -5px' }}>
              {recipesCardDataLoading && recipesCard.length === 0 ? (
                <Col md={12} style={{ marginTop: '200px' }}>
                  <Loader light={theme === 'light'} />
                </Col>
              ) : recipesCard && recipesCard.length > 0 ? (
                <>
                  {recipesCard.map((obj: any) => (
                    <div style={{ padding: '5px' }} className="col-12 col-lg-6 col-xl-4">
                      <TemplateCard
                        theme={theme}
                        title={obj.name}
                        description={obj.description}
                        image={obj.image}
                        fullText
                        categories={obj.categories}
                        isNew={obj.is_new}
                        linkTo={''}
                        id={obj.id}
                        demoLink={obj.demoLink}
                        templatesPage
                      />
                    </div>
                  ))}

                  {recipesCardDataLoading || scrapingCategoriesLoading ? (
                    <Col className="load-more-button mt-4" xl={12}>
                      <Loader light={theme === 'light'} />
                    </Col>
                  ) : (
                    <>
                      {recipesCard.length < totalCount ? (
                        <Col className="load-more-button my-2" xl={12}>
                          <div className="clickable-text" onClick={handleCardLimit}>
                            LOAD MORE
                          </div>
                        </Col>
                      ) : null}
                    </>
                  )}
                </>
              ) : (
                <Col md={12} style={{ marginTop: '200px' }}>
                  {recipesCardDataLoading ? (
                    Array.apply(null, Array(24)).map(item => (
                      <Col sm={12} md={6} lg={4} style={{ minWidth: '220px', padding: '5px' }}>
                        <div className="blank-template"></div>
                      </Col>
                    ))
                  ) : (
                    <div className="with-center">
                      <span>{nothingToShow}</span>
                      <span className="empty">Nothing To Show!</span>
                    </div>
                  )}
                </Col>
              )}
            </div>

            <Modal
              isModalOpen={shouldUpgrade}
              title="Please upgrade your plan to continue"
              actionBtnText="Upgrade"
              cancelBtnText="Cancel"
              actionBtnColor="warning"
              cancelBtnColor="secondary"
              onActionClick={() => (window.location.href = 'https://hexomatic.com/pricing/')}
              onCancelClick={() => setShouldUpgrade(false)}
              escButtonClick={() => setShouldUpgrade(false)}
            />

            {/* {(optionParameters.label === 'Delete' || showDeleteModal) && (
        <Modal
          isModalOpen={!!(optionParameters.label === 'Delete') || showDeleteModal}
          text={`Confirm deleting ${
            showDeleteModal && checkedIndexes && checkedIndexes.length > 1
              ? checkedIndexes.length + ' recipes'
              : 'the recipe'
          }`}
          actionBtnText={deleteLoading ? <Loader light={theme === 'light'} className="d-inline" /> : 'Yes'}
          cancelBtnText="No"
          actionBtnColor="#B72CFF"
          onActionClick={handleDelete}
          onCancelClick={() => {
            setOptionParameters({ label: '', value: '' });
            setShowDeleteModal(false);
          }}
        />
      )} */}
            {error && viewMode === 'cardView' && (
              <div className="mb-2">
                <Message type="error" text={error} />
              </div>
            )}
          </>
        ) : recipesCardDataLoading ? (
          <Row style={{ margin: '0 -5px' }}>
            {Array.apply(null, Array(24)).map(item => (
              <Col sm={12} md={6} lg={4} style={{ minWidth: '220px', padding: '5px' }}>
                <div className="blank-template"></div>
              </Col>
            ))}
          </Row>
        ) : (
          <NothingToShow upgrade />
        )}
      </div>
    </div>
  );
};

export default ScrapingRecipes;
