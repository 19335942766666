import { useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Settings from '../pages/settings';
import { getAccessToken, getRawCookie } from '../helpers';
import { UserContext } from '../contexts/user-context';

import Automations from '../pages/automations';
import AutomationsDetails from '../pages/automations/automation-details';
import Dashboard from '../pages/dash';
import Profile from '../pages/profile';
import ScrapingRecipes from '../pages/scraping-recipes';
import scrapingPublicRecipes from 'pages/scraping-recipes/scraping-public-recipes';
import CreateScrapingRecipe from '../pages/scraping-recipes/create';
import SignOut from '../pages/auth/sign-out';
import Workflows from '../pages/workflows';
import WorkflowsCreateWizard from '../pages/workflows/create/create-wizard';
import CreateWorkFlow from '../pages/workflows/create';
import PublicWorkflows from '../pages/workflows/public-workflows';
import WorkflowsLogs from '../pages/workflows/log-data';
import Tasks from '../pages/tasks';
import Task from 'pages/tasks/task.tsx';
import { HexomaticUserContext } from 'contexts/hexomatic-user';
import WorkflowResult from 'pages/workflows/results';
import WorkflowHistory from 'pages/workflows/history';
import DevLogin from 'pages/dev-login';
import PromoPage from 'pages/promo-page';

const privateRoutes = [
  {
    path: '/automations',
    component: Automations,
    // component: lazy(() => import('../pages/automations')),
    exact: true,
  },
  {
    path: '/automations/types/:typeID',
    component: Automations,
    // component: lazy(() => import('../pages/automations')),
    exact: true,
  },
  {
    path: '/automations-without-source',
    component: Automations,
    // component: lazy(() => import('../pages/automations')),
    exact: true,
  },
  {
    path: '/automations/categories/:categoryID',
    component: Automations,
    // component: lazy(() => import('../pages/automations')),
    exact: true,
  },
  {
    path: '/automations/types/:typeID/categories/:categoryID',
    component: Automations,
    // component: lazy(() => import('../pages/automations')),
    exact: true,
  },
  {
    path: '/automations/:automationID',
    // component: lazy(() => import('../pages/automations')),
    component: AutomationsDetails,
    exact: true,
  },
  // {
  //   path: '/automations/:automationID',
  //   component: AutomationsDetails,
  //   // component: lazy(() => import('../pages/automations/details')),
  //   exact: true,
  // },
  {
    path: '/dashboard',
    component: Dashboard,
    // component: lazy(() => import('../pages/dash')),
    exact: true,
  },
  {
    path: '/profile',
    component: Profile,
    // component: lazy(() => import('../pages/profile')),
    // exact: true,
  },

  {
    path: '/scraping-recipes',
    component: ScrapingRecipes,
    // component: lazy(() => import('../pages/scraper')),
    exact: true,
  },
  {
    path: '/scraping-public-recipes',
    component: scrapingPublicRecipes,
    // component: lazy(() => import('../pages/scraper')),
    exact: true,
  },
  {
    path: '/scraping-public-recipes/current-recipe/:id',
    component: CreateScrapingRecipe,
    // component: lazy(() => import('../pages/scraper')),
    exact: true,
  },
  {
    path: '/scraping-recipes/create',
    component: CreateScrapingRecipe,
    // component: lazy(() => import('../pages/scraper')),
    exact: true,
  },
  {
    path: '/scraping-recipes/current-recipe/:id',
    component: CreateScrapingRecipe,
    // component: lazy(() => import('../pages/scraper')),
    exact: true,
  },
  {
    path: '/settings',
    component: Settings,
    // component: lazy(() => import('../pages/settings')),
    exact: true,
  },
  {
    path: '/sign-out',
    component: SignOut,
    // component: lazy(() => import('../pages/workflows/create')),
    exact: true,
  },
  {
    path: '/dev-login',
    component: DevLogin,
    // component: lazy(() => import('../pages/workflows/create')),
    exact: true,
  },
  {
    path: '/black-friday-deal',
    // component: PromoPage,
    // component: lazy(() => import('../pages/workflows/create')),
    exact: true,
    redirect: '/dashboard',
  },
  {
    path: '/spring-break-promo',
    // component: PromoPage,
    // component: lazy(() => import('../pages/workflows/create')),
    exact: true,
    redirect: '/dashboard',
  },
  {
    path: '/workflows',
    component: Workflows,
    // component: lazy(() => import('../pages/workflows')),
    exact: true,
  },
  {
    path: '/workflows/create-starter',
    component: WorkflowsCreateWizard,
    // component: lazy(() => import('../pages/workflows/create')),
    exact: true,
  },
  {
    path: '/workflows/create-starter/scraping-recipes',
    component: WorkflowsCreateWizard,
    // component: lazy(() => import('../pages/workflows/create')),
    exact: true,
  },
  {
    path: '/workflows/create',
    component: CreateWorkFlow,
    // component: lazy(() => import('../pages/workflows/create')),
    exact: true,
  },
  {
    path: '/workflows/current-workflow/:workflowId',
    component: CreateWorkFlow,
    exact: true,
  },
  {
    path: '/workflows/current-workflow-template/:workflowId',
    component: CreateWorkFlow,
    exact: true,
  },

  {
    path: '/public-workflows',
    component: PublicWorkflows,
    exact: true,
  },
  {
    path: '/workflows/log-data/:workflowId',
    component: WorkflowsLogs,
    exact: true,
  },
  {
    path: '/workflows/result/:workflowId',
    component: WorkflowResult,
    exact: true,
  },
  {
    path: '/workflows/history/:workflowId',
    component: WorkflowHistory,
    exact: true,
  },
  {
    path: '/tasks',
    component: Tasks,
    exact: true,
  },
  {
    path: '/tasks/:taskId',
    component: Task,
    exact: true,
  },
];
if (window.location.host !== 'dash.hexomatic.com') {
  privateRoutes.push(
    {
      path: '/scraping-recipes/create/:ip',
      component: CreateScrapingRecipe,
      // component: lazy(() => import('../pages/scraper')),
      exact: true,
    },
    {
      path: '/scraping-recipes/current-recipe/:id/:ip',
      component: CreateScrapingRecipe,
      // component: lazy(() => import('../pages/scraper')),
      exact: true,
    },
  );
}
// const guestRoutes = [
//   {
//     path: '/home',
//     component: lazy(() => import('../pages/add-url')),
//     exact: true,
//   },
//   //   {
//   //     path: '/tenant-preview/:id',
//   //     component: () => <TenantDetails preview goBackText="backFavorites" />,
//   //   },
// ];

// const publicRoutes = [
//   {
//     path: '/',
//     component: lazy(() => import('../pages/dash')),
//     exact: true,
//   },
//   //   {
//   //     path: '/not-found',
//   //     component: lazy(() => import('NotFound')),
//   //   },
// ];

export default function Routes() {
  const accessToken = getAccessToken() || '';
  const hasAccessToken = accessToken.length > 0;
  const { user } = useContext(UserContext);
  const { hexomaticUser } = useContext(HexomaticUserContext);

  if (!hasAccessToken || (user.loaded && !user.id) || !getRawCookie('ref_token') || !getRawCookie('device_key')) {
    if (window.location.href.includes('current-workflow')) {
      localStorage.setItem('current-workflow', window.location.href);
    }
    if (window.location.href.includes('/workflows/create')) {
      localStorage.setItem('navigate-create-workflow', '/workflows/create');
    }
    if (window.location.href.includes('/pricing')) {
      localStorage.setItem('navigate-pricing', '/pricing');
    }
    if (window.location.href.includes('/black-friday-deal') || window.location.href.includes('/spring-break-promo')) {
      localStorage.setItem('promo-page-path', '/spring-break-promo');
    }
    if (!window.location.href.includes('/dev-login')) {
      setTimeout(() => {
        window.location.href =
          window.location.href.indexOf('localhost') > -1
            ? 'http://localhost:3000/dev-login'
            : window.location.href.indexOf('37.186.119.181') > -1
            ? 'http://37.186.119.181:3279/dev-login'
            : 'https://hexomatic.com/login/';
        return <></>;
      }, 0);
    }
  } else {
    if (localStorage.getItem('current-workflow')) {
      window.location.href = localStorage.getItem('current-workflow') || '';
      localStorage.removeItem('current-workflow');
    } else if (localStorage.getItem('navigate-create-workflow')) {
      window.location.pathname = '/workflows/create';
      localStorage.removeItem('navigate-create-workflow');
    } else if (localStorage.getItem('navigate-pricing')) {
      window.location.pathname = '/pricing';
      localStorage.removeItem('navigate-pricing');
    } else if (localStorage.getItem('promo-page-path')) {
      window.location.pathname = '/spring-break-promo';
      localStorage.removeItem('promo-page-path');
    }
  }

  return (
    <Switch>
      {/* {publicRoutes.map(({ exact, path, component }) => (
        <Route key={path} exact={exact} path={path} component={component} />
      ))}
      {!user &&
        guestRoutes.map(({ exact, path, component }) => (
          <Route key={path} exact={exact} path={path} component={component} />
        ))} */}
      {user &&
        privateRoutes.map(({ redirect, exact, path, component }) => {
          if (hexomaticUser && !hexomaticUser.isPerformer && path.includes('tasks')) return;
          if (redirect) {
            return (
              <Route key={path} exact={!!exact} path={path}>
                <Redirect to={redirect} />
              </Route>
            );
          }
          return <Route key={path} exact={!!exact} path={path} component={component} />;
        })}
      <Route exact path="/">
        <Redirect to="/dashboard" />
      </Route>

      {/* <Redirect to="/not-found" /> */}
    </Switch>
  );
}
