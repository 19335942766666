import { FC, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAutomationGlobalMethods } from '../../hooks';
import { useSubscription } from '@apollo/react-hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import { ZAPIER_SUBSCRIPTION } from 'graphql/subscriptions';
import Button from 'shared-components/atom/button';
import { UserContext } from 'contexts/user-context';
import './styles.scss';

const ZapierSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  forZapier,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [options, setOptions] = useState<any>([]);
  const { user } = useContext(UserContext);
  const [clicked, setClicked] = useState(false);
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);
  const [zapierUrls, setZapierUrls] = useState<string[]>([]);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const { data: zapierData, loading: integrationsLoading } = useSubscription(ZAPIER_SUBSCRIPTION, {
    fetchPolicy: 'network-only',
    skip: !user?.id || !forZapier?.id,
    variables: {
      userId: +user.id,
      workflowId: forZapier?.id || '',
    },
  });

  const { getPreviousAutomationOutputOrPreviousRecipKeys, getDynamicAndIntegrationsSettings, liftingSettings, getPreviousAutomationOutputOrPreviousRecipKeysWithTypes } =
    useAutomationGlobalMethods({
      automationSendSettings,
      setAutomationSendSettings,
      selectedRecipes,
      automationIndex,
      id,
    });
  const checkFields = () => {
    if (zapierUrls?.length < 1) return false;
    return true;
  };

  //recipe fields or output fields
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex !== 0) {
      let options = getPreviousAutomationOutputOrPreviousRecipKeys();
      const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const filteredOptions = automationInputTypes && !automationInputTypes.includes('any') ? 
    options.filter((item: any) => (optionsWithTypes && optionsWithTypes[item.value] && (automationInputTypes.includes(optionsWithTypes[item.value]) || optionsWithTypes[item.value] === 'any')) || !(optionsWithTypes && optionsWithTypes[item.value])) :
    [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
      // setFieldList(options);
    }
  }, [isSettingsChange]);

  useEffect(() => {
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      outputKeysData: fieldList.map((item: { value: any }) => item.value),
      outputKeysDataWithTypes: {...fieldListWithTypes, ...jsonParse},
    });
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);

  // getting integrations
  useEffect(() => {
    if (zapierData?.zapierHookUrlEvent?.urls) {
      const urls = zapierData.zapierHookUrlEvent.urls;
      const { integration_settings } = getDynamicAndIntegrationsSettings();
      if (
        integration_settings &&
        integration_settings.zapier_hook_urls &&
        integration_settings.zapier_hook_urls.length
      ) {
        const { zapier_hook_urls } = integration_settings;
        setZapierUrls(zapier_hook_urls);
        setClicked(true);
      } else {
        setZapierUrls(urls || []);
        setClicked(true);
      }
      setOptions(urls);
    }
  }, [zapierData]);

  //lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    setClicked(false);
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: {
        keys: null,
      },
      integrationsSettings: {
        zapier_hook_urls: zapierUrls,
      },
      outputKeysData: fieldList.map((item: { value: any }) => item.value),
      outputKeysDataWithTypes: {...fieldListWithTypes, ...jsonParse},
    });
  }, [zapierUrls]);

  const addNewField = () => {
    if (zapierUrls.length > 1) {
      return;
    }
    !clicked && setClicked(true);
    setIsDirty();

    if (forZapier?.id && forZapier.updateWorkflow) {
      forZapier.updateWorkflow();
    } else if (forZapier?.saveAsDraft) {
      forZapier.saveAsDraft();
    }
    window.open('https://zapier.com/apps/hexomatic/integrations');
  };
  useEffect(() => {
    const { integration_settings } = getDynamicAndIntegrationsSettings();
    if (integration_settings && integration_settings.zapier_hook_urls) {
      const { zapier_hook_urls } = integration_settings;
      setZapierUrls(zapier_hook_urls);
      setClicked(true);
    }
  }, []);
  return (
    <Row className="zapier-settings mx-0">
      {zapierUrls.map((item, i) => (
        <Col md={12} className="mb-1">
          <div className="zapier-wrapper align-items-center">
            <div className="label-input-wrapper">
              <div className="label secondary-color">Zapier</div>
              <div className="zapier-url">{item}</div>
            </div>
          </div>
        </Col>
      ))}
      {!zapierUrls.length && <Button name={'Connect'} onClick={addNewField} />}
    </Row>
  );
};

export default ZapierSettings;
