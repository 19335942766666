import React, { FC, useState, useEffect } from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Legend, Label } from 'recharts';
import './styles.scss';

/*
  get array with 3 parametrs - example 
  [{ name: 'Active', value: 13, color: '#0AB8F7' }]
*/

interface IPiechartProps {
  width: any;
  height: number;
  innerRadius: number;
  outerRadius: number;
  noChangeWhenMobile: boolean;
  data: { name: string; value: number; color: string }[];
  colors: string[];
  click?: any;
  chartLabelFilter?: any;
  noLagend?: boolean;
  label?: string | number;
  secondLabel?: string | number;
}

const Piechart: FC<IPiechartProps> = ({
  data,
  width,
  height,
  innerRadius,
  outerRadius,
  noChangeWhenMobile,
  click,
  chartLabelFilter,
  noLagend,
  label,
  secondLabel,
}) => {
  const [outerPieData, setOutherPieData] = useState(null as any);

  useEffect(() => {
    const data = new Array(Math.floor(width ? width / 2 : 150)).fill({ name: 'dash', value: 1, color: '#b7b3b3' });
    setOutherPieData(data);
  }, [width]);

  const w = window.innerWidth;

  let respHeight = height ? height : 200;
  let PieChartHeight = height ? height : 200;
  let respWidth = width ? width : 300;

  if (data.length > 7) {
    respHeight = data.length * 28;
    PieChartHeight = data.length * 28;
  }

  if (w < 520 && !noChangeWhenMobile) {
    respHeight = data.length * 30 + 200;
    PieChartHeight = data.length * 30 + 130;
    respWidth = '100%';
  }

  return (
    <ResponsiveContainer min-width={respWidth} height={respHeight} className="vk-piechart">
      <PieChart min-width={respWidth} height={PieChartHeight}>
        <Pie
          data={data}
          innerRadius={innerRadius ? innerRadius : 60}
          outerRadius={outerRadius ? outerRadius : 75}
          cornerRadius={0}
          paddingAngle={0}
          fill="#8884d8"
          dataKey="value"
          startAngle={90}
          endAngle={-270}
        >
          {data.map((entry: any, index: number) => (
            <Cell key={`cell-${entry}`} fill={entry.color} style={{ stroke: entry.color }} />
          ))}
          {secondLabel && (
            <Label
              content={
                <text
                  offset="5"
                  x="110.578125"
                  y="65"
                  className="recharts-text pie-chart-second-label-inside"
                  text-anchor="middle"
                >
                  <tspan x="110.578125" dy="0em">
                    {secondLabel}
                  </tspan>
                </text>
              }
            />
          )}
          {label && (
            <Label value={label} position={secondLabel ? 'centerTop' : 'center'} className="pie-chart-label-inside" />
          )}
        </Pie>
        {/* {outerPieData && (
          <Pie
            data={outerPieData}
            dataKey="value"
            innerRadius={outerRadius ? outerRadius + 8 : 82}
            outerRadius={outerRadius ? outerRadius + 9 : 83}
            fill="transparent"
          >
            {outerPieData.map((entry: any, index: number) => (
              <Cell
                key={`cell-${entry}`}
                style={{ stroke: index % 2 === 0 ? entry.color : 'transparent' }}
                fill={index % 2 === 0 ? entry.color : 'transparent'}
              />
            ))}
          </Pie>
        )} */}
        {!noLagend && (
          <Legend
            verticalAlign={w < 520 && !noChangeWhenMobile ? 'bottom' : 'middle'}
            align={w < 520 && !noChangeWhenMobile ? 'center' : 'right'}
            layout="vertical"
            content={
              <div className="">
                {data.map((item: { name: string; color: string; value: number }) => (
                  <div key={item.name} className="custom-legend-checks">
                    <div className="legend-div" style={{ background: item.color }}></div>
                    <span
                      className={`name ${chartLabelFilter === item.name ? 'selected-label' : ''}`}
                      onClick={() => click(item.name)}
                    >
                      {item.name}
                    </span>
                    <span className="value">&nbsp;({item.value})</span>
                  </div>
                ))}
              </div>
            }
          />
        )}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default React.memo(Piechart);
