import { FC } from 'react';
import SortableCard from 'shared-components/molecule/sortable-card';
import Header from 'shared-components/atom/sortable-card-header';
import BodyItem from 'shared-components/atom/sortable-card-body-item';

const headerItems = [
  {
    title: 'Tasks',
    id: 2,
  },
  {
    title: 'Creation date',
    id: 1,
  },
  {
    title: 'Quantity',
    id: 3,
  },
  {
    title: 'Type',
    id: 5,
  },
  {
    title: 'Status',
    id: 4,
  },
];

interface ICardWrapper {
  sort?: {
    by: string;
    id: number;
  };
  setSort?: (val: { by: string; id: number }) => void;
  checkAll: boolean;
  setCheckAll?: (val: boolean) => void;
  items: any[];
  checkedItems: any[];
  setCheckedItems: (val: number) => void;
}

const CardWrapper: FC<ICardWrapper> = ({ items, checkedItems, setCheckedItems, ...props }) => {
  const returnStatusType = (status: string) => {
    return status === 'new'
      ? 'badge-alert'
      : status === 'completed'
      ? 'badge-success'
      : status === 'paused'
      ? 'badge-secondary'
      : status === 'archived'
      ? 'badge-warning'
      : 'badge-primary';
  };
  return (
    <SortableCard>
      <>
        <Header list={headerItems} {...props} />
        <div className="card-body">
          {items.map((item: any) => (
            <BodyItem
              onChange={() => setCheckedItems(item.id)}
              checked={!!checkedItems.find(id => id === item.id)}
              list={[
                { title: item.question },
                { title: item.created_at, date: true },
                { title: item.quantity },
                { title: item.automation_id == '35' ? 'YES/NO' : 'Detailed answers' },
                { title: item.status.toUpperCase(), status: returnStatusType(item.status) },
              ]}
              redirect={`/tasks/${item.id}`}
            />
          ))}
        </div>
      </>
    </SortableCard>
  );
};

export default CardWrapper;
