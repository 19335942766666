import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { useAutomationGlobalMethods } from '../../hooks';
import { Col, Row } from 'react-bootstrap';
import Input from 'shared-components/atom/input';
import Select from 'shared-components/atom/select';
import Datalist from 'shared-components/atom/dataList';
import { voiceContryOptions } from 'automation-settings/constants';
import Alert from 'shared-components/atom/alert-message';
import axios from 'axios';
import { HOST } from 'constants/host';
import { HexomaticUserContext } from 'contexts/hexomatic-user';
import Button from 'shared-components/atom/button';
import { cacheFunction } from 'helpers';
import CustomMultiSelect from 'shared-components/molecule/multiselect';
import StretchLoading from 'shared-components/molecule/stretch-loading';

const genderOptions = [
  // { label: 'Neutral', value: 'NEUTRAL' },
  { label: 'Male', value: 'MALE' },
  { label: 'Female', value: 'FEMALE' },
];

let shapeObj = {
  langCode: '',
  gender: genderOptions[0],
  allDialects: null,
  dialects: null,
  temporaryDialect: null,
  selectedDialect: null,
};

const ResearchForGoogleVoiceForTextSettings: FC<any> = ({
  automationSendSettings,
  selectedRecipes,
  automationIndex,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);
  const [clicked, setClicked] = useState(false);
  const [inputText, setInputText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { hexomaticUser } = useContext(HexomaticUserContext);
  const [mp3Link, setMp3Link] = useState<null | string>(null);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [isVoisButton, setIsVoisButton] = useState(false);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const [selectedSources, setSelectedSources] = useState<any>(
    automationIndex !== 0 ? [] : [{ label: null, value: null, values: shapeObj }],
  );

  const {
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    liftingSettings,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (errorMessage || !selectedSources?.length) return false;
    if (fieldList?.length === 0) {
      if (!inputText) {
        return false;
      }
    }
    return true;
  };

  //getting previous outputs
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const currentAutomation = automationSendSettings.find((automation: any, index: any) => index === automationIndex);
    const { dynamic_settings } = currentAutomation;

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSources([]);
      setClicked(true);
    }
    if (update) {
      if (dynamic_settings?.keys?.length > 1) {
        const filteredValue = filteredOptions?.filter((item: any) =>
          dynamic_settings?.keys.find((sub: any) => item.value === sub.key),
        );

        setSelectedSources(filteredValue || []);
        setClicked(true);
        setFieldList(filteredOptions);
        setFieldListWithTypes(optionsWithTypes);
      } else {
        const isArr = Array.isArray(dynamic_settings?.keys?.[0]?.key);
        const foundValue = !isArr
          ? filteredOptions?.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value)
          : null;
        const filteredValue = isArr
          ? filteredOptions?.filter((item: any) =>
              dynamic_settings?.keys?.[0]?.key.find((sub: any) => item.value === sub),
            )
          : [];
        const selOptionFound = isArr
          ? filteredValue.map((item: any) => {
              return { ...item, values: shapeObj };
            })
          : foundValue
          ? [{ ...foundValue, values: shapeObj }]
          : [];
        setSelectedSources(selOptionFound);
        setClicked(true);
        setFieldList(filteredOptions);
        setFieldListWithTypes(optionsWithTypes);
      }
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSources([{ label: fieldList[0].label, value: fieldList[0].value, values: shapeObj }]);
    }
  }, [fieldList]);
  //memoize
  const handleVoiceContryOptions = (language: string) => {
    return voiceContryOptions.find((item: { label: string; value: string }) => item.label === language);
  };
  const getMemoizedVoiceContryOptions = useRef(cacheFunction(handleVoiceContryOptions));

  useEffect(() => {
    if (automationSendSettings) {
      const currentAutomation = automationSendSettings.find((automation: any, index: any) => index === automationIndex);
      const { dynamic_settings } = currentAutomation;

      if (dynamic_settings) {
        setLoadingData(true);
        const { keys } = dynamic_settings;
        let newSelectedSources = keys.map((item: any) => {
          let finded: any = null;
          if (fieldList.length === 0) {
            const input = keys[0].input || keys[0].inputValue;
            setInputText(input);
          } else {
            finded =
              fieldList?.find(
                (field: { value: string; label: string }) => field.value === item?.key || field.label === item?.key,
              ) || fieldList?.[0];
          }
          const countryOption = voiceContryOptions?.find(
            (country: { label: string; value: string }) => country.value === item?.settings?.langCode,
          );
          return {
            label: finded ? finded.label : null,
            value: finded ? finded.value : null,
            values: {
              langCode: countryOption ? countryOption.label : null,
              gender: genderOptions?.find(gender => gender?.value === item?.settings.gender),
              allDialects: null,
              dialects: null,
              temporaryDialect: item?.settings?.name,
              selectedDialect: null,
            },
          };
        });

        setFuncton(newSelectedSources);
      }
    }
  }, [fieldList]);

  const setFuncton = async (newSelectedSources: any) => {
    let res = newSelectedSources.filter((item: any) => item.value);
    for (let index = 0; index < newSelectedSources.length; index++) {
      const countryOption = getMemoizedVoiceContryOptions.current(newSelectedSources[index].values.langCode);
      if (countryOption) {
        res = await fetchLang({
          index,
          lang: countryOption.value,
          copiedSelectedSources: res,
        });
      }
    }
    res?.length && setSelectedSources(res);
    setLoadingData(false);
  };

  const filterDialectsFunc = ({ index, allDialects, copiedSelectedSources }: any) => {
    let setting: any = copiedSelectedSources.find((_: any, i: number) => i === index);
    if (allDialects || setting.values.allDialects) {
      let list = [...(allDialects ? allDialects : setting.values.allDialects)];
      const findedWavnet = list.find(item => item.name.includes('Wavenet'));
      if (findedWavnet) {
        list = list.filter(voice => !voice.name.includes('Standard'));
      }
      if (setting.values.gender.value !== 'NEUTRAL') {
        list = list.filter(voice => voice.ssmlGender === setting.values.gender.value);
      }
      let arr = list.map((voice: { name: string }) => {
        return { label: voice.name.split('-').join(' '), value: voice.name };
      });
      let findedSelectedDialect: any = null;
      if (setting.values.temporaryDialect) {
        findedSelectedDialect = arr.find(item => item.value === setting.values.temporaryDialect);
      }
      arr.sort((obj1: any, obj2: any) => {
        if (obj1.label < obj2.label) {
          return -1;
        }
        if (obj1.label > obj2.label) {
          return 1;
        }
        return 0;
      });
      const newSelectedSources = copiedSelectedSources.map((item: { values: any }, i: number) => {
        if (i === index) {
          if (findedSelectedDialect) {
            return {
              ...item,
              values: {
                ...item.values,
                allDialects: allDialects,
                dialects: arr,
                temporaryDialect: null,
                selectedDialect: findedSelectedDialect,
              },
            };
          }
          return {
            ...item,
            values: { ...item.values, allDialects: allDialects, dialects: arr, selectedDialect: null },
          };
        }
        return item;
      });
      return newSelectedSources;
    }
  };

  const fetchLang = async ({
    index,
    lang,
    copiedSelectedSources,
  }: {
    index: number;
    lang: string;
    copiedSelectedSources: any;
  }) => {
    try {
      const { data: result } = await axios(
        `https://content-texttospeech.googleapis.com/v1/voices?languageCode=${lang}&key=AIzaSyBharYuJlXffjVzuZsPN5zvVvdwq0W0M9M`,
      );
      const voices = result?.voices;

      if (voices) {
        return filterDialectsFunc({ index, allDialects: voices, copiedSelectedSources });
      }
    } catch (error) {}
  };

  const fetchSpeachFile = async () => {
    const { gender, langCode, selectedDialect } = selectedSources[0].values;

    const selectOption = getMemoizedVoiceContryOptions.current(langCode);

    const data = {
      textToVoice:
        "Hi Max, I'm just sending over the marketing report I mentioned earlier. Let me know if you have any questions..",
      langCode: selectOption ? selectOption.value : null,
      gender: gender.value,
      name: selectedDialect && selectedDialect.value,
      userId: hexomaticUser.id,
      workflowId: id,
    };
    try {
      setLoading(true);
      const res = await axios(`${HOST.APP_API.APP}/hexomatic/text-to-speech-test/`, {
        method: 'POST',
        data: data,
      });
      setLoading(false);
      if (res && res.status === 200 && res.data && res.data.fileUrl) {
        setClicked(true);
        setIsDirty();
        setMp3Link(res.data.fileUrl);
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [
          ...fieldList.map((item: { value: any }) => item.value),
          ...(fieldList.length === 0
            ? ['_text_to_speech']
            : selectedSources?.length && selectedSources[0].value
            ? selectedSources.map((item: { value: string }) => `${item.value}_text_to_speech`)
            : []),
        ],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, selectedSources, fieldListWithTypes, automationOutputTypes]);

  //lifting data
  useEffect(() => {
    if (!clicked) return;
    let error = checkFields();

    // setClicked(false);

    const keys = selectedSources?.map((item: any) => {
      if (item?.values) {
        const { gender, selectedDialect } = item.values;
        const selectedOption = getMemoizedVoiceContryOptions.current(item.values.langCode);
        //for error
        if (!selectedDialect || !selectedOption) {
          error = false;
          isVoisButton && setIsVoisButton(false);
        } else {
          if (selectedSources.length === 1 && ['en-AU', 'en-IN', 'en-GB', 'en-US'].includes(selectedOption.value)) {
            !isVoisButton && setIsVoisButton(true);
          }
        }
        mp3Link && setMp3Link(null);

        return {
          inputValue: fieldList?.length === 0 ? inputText?.trim() : null,
          key: item?.value,
          settings: {
            langCode: selectedOption ? selectedOption.value : null,
            gender: gender?.value,
            name: selectedDialect ? selectedDialect?.value : null,
          },
        };
      }
    });

    setCompletedFields({ index: automationIndex, checked: error });
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: {
        keys: keys,
      },
      outputKeysData: [
        ...fieldList.map((item: { value: any }) => item.value),
        ...(fieldList.length === 0
          ? ['_text_to_speech']
          : selectedSources?.length && selectedSources[0].value
          ? selectedSources.map((item: { value: string }) => `${item.value}_text_to_speech`)
          : []),
      ],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [inputText, errorMessage, selectedSources, fieldList, fieldListWithTypes, automationOutputTypes]);

  const onSelectSources = (sel: any) => {
    let newsel = sel.map((source: any) => {
      if (source.values) {
        return source;
      }
      return { ...source, values: shapeObj };
    });
    setSelectedSources(newsel);
    !clicked && setClicked(true);
    setIsDirty();
  };
  const onGenderSelect = async ({ index, selectedItem, lang }: any) => {
    const newSelectedSources = selectedSources.map((item: { values: { settings: any } }, i: number) => {
      if (i === index) {
        return {
          ...item,
          values: { ...item.values, gender: selectedItem },
        };
      }
      return item;
    });
    setSelectedSources(newSelectedSources);
    const res = await fetchLang({ index, lang, copiedSelectedSources: newSelectedSources });
    setSelectedSources(res);

    !clicked && setClicked(true);
    setIsDirty();
  };

  const onLanguageSelect = async ({ index, text }: { index: number; text: string }) => {
    const findedCountry = getMemoizedVoiceContryOptions.current(text);

    const newSelectedSources = selectedSources.map((item: { values: { settings: any } }, i: number) => {
      if (i === index) {
        return { ...item, values: { ...item.values, langCode: text, allDialects: null } };
      }
      return item;
    });
    setClicked(true);
    setIsDirty();
    if (findedCountry) {
      const res = await fetchLang({ index, lang: findedCountry.value, copiedSelectedSources: newSelectedSources });
      setSelectedSources(res);
    } else {
      setSelectedSources(newSelectedSources);
    }
  };

  const onSelectDialect = ({ index, selectedItem }: any) => {
    const newSelectedSources = selectedSources.map((item: { values: { settings: any } }, i: number) => {
      if (i === index) {
        return { ...item, values: { ...item.values, selectedDialect: selectedItem } };
      }
      return item;
    });
    setSelectedSources(newSelectedSources);
    !clicked && setClicked(true);
    setIsDirty();
  };

  if (loadingData) {
    return <StretchLoading />;
  }

  return (
    <div className="">
      <Row>
        {fieldList?.length > 0 || automationIndex > 0 ? (
          <Col lg={6} className="mb-3">
            <span className="label secondary-color">Select fields</span>
            <CustomMultiSelect
              options={fieldList}
              value={selectedSources}
              onChange={onSelectSources}
              labelledBy="Select"
            />
          </Col>
        ) : (
          <Col lg={6} className="mb-4">
            <span className="label secondary-color">Text</span>
            <Input
              value={inputText}
              onChange={e => {
                setInputText(e.target.value);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
        )}
      </Row>
      {selectedSources.map((source: any, index: number) => (
        <Row>
          <Col lg={4} className="mb-4">
            <span className="label secondary-color">{source.label ? `${source.label} - ` : ''} Gender</span>
            <Select
              options={genderOptions}
              value={source.values.gender}
              placeholder={'Select source'}
              onChange={(selectedItem: any) =>
                onGenderSelect({
                  index,
                  selectedItem,
                  lang: voiceContryOptions.filter((item: any) => item.label === source?.values?.langCode)[0]?.value,
                })
              }
            />
          </Col>
          <Col lg={4} className="google-translate-select mb-4">
            <span className="label secondary-color">{source.label ? `${source.label} - ` : ''} Language</span>
            <Datalist
              list={voiceContryOptions}
              value={source.values.langCode}
              placeholder={'Select language'}
              onChange={(text: string) => onLanguageSelect({ index, text })}
            />
          </Col>
          {source.values.allDialects && source.values.dialects && (
            <Col lg={4} className="mb-4">
              <span className="label secondary-color">{source.label ? `${source.label} - ` : ''} Voices</span>
              <Select
                options={source.values.dialects}
                value={source.values.selectedDialect}
                placeholder={'Select source'}
                onChange={(selectedItem: any) => {
                  onSelectDialect({ index, selectedItem });
                }}
              />
            </Col>
          )}
        </Row>
      ))}

      {isVoisButton && (
        <Row>
          <Col lg={12} className="mb-4">
            {!mp3Link ? (
              <Button
                name={'Preview'}
                className="no-wrap m-1"
                active={true}
                loading={loading}
                onClick={() => fetchSpeachFile()}
              />
            ) : (
              <audio controls controlsList="nodownload">
                <source src={mp3Link || ''} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            )}
          </Col>
        </Row>
      )}
      {errorMessage && (
        <Row>
          <Col md={12}>
            <Alert type="error" className="create-workflow">
              {errorMessage}
            </Alert>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default React.memo(ResearchForGoogleVoiceForTextSettings);
