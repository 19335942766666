import React, { FC, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { seoMetaTegsOptions } from '../../constants';
import Select from 'shared-components/atom/select';
import CustomMultiSelect from 'shared-components/molecule/multiselect';
import Switcher from 'shared-components/molecule/switcher';
import DataList from 'shared-components/atom/dataList';

import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import { proxyList } from 'constants/index';
import { residential_geo_proxy_country_list } from 'constants/countries/residential-geo-proxy-countries';
import { cacheFunction } from 'helpers';
import { infoIcon } from 'assets/svg-icons';

const GetSeoMetaTagsSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [selected, setSelected] = useState<any>([]);
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);
  const [clicked, setClicked] = useState(false);
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);
  const [selectedMode, setSelectedMode] = useState('Standard');
  const [selectedProxy, setSelectedProxy] = useState({ label: 'Data Center', value: '' });
  const [residentialGEOCountry, setResidentialGEOCountry] = useState('');
  //memoize
  const handleResidentialCountryOptions = (residentialGEOCountry: string) => {
    return residential_geo_proxy_country_list.find(item => item.label === residentialGEOCountry)?.value;
  };
  const getMemoizeResidentialCountryOption = useRef(cacheFunction(handleResidentialCountryOptions));

  const {
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    liftingSettings,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (selected?.length === 0 || !selectedSource) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const { predefined_settings, dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings && dynamic_settings?.keys[0]?.settings) {
      const proxy = dynamic_settings.keys[0].settings.proxy;
      if (proxy) {
        const selectedProxy = proxyList.find((val: any) => val.value === proxy.type);
        const selectedCountry = residential_geo_proxy_country_list.find(
          (country: any) => country.value === proxy.country_code,
        );
        selectedProxy && setSelectedProxy(selectedProxy);
        selectedCountry && setResidentialGEOCountry(selectedCountry.label);
      }

      const { metaTags } = dynamic_settings.keys[0].settings;
      const fast = dynamic_settings.keys[0].settings && dynamic_settings.keys[0].settings.fast ? 'Fast' : 'Standard';
      setSelectedMode(fast);
      const selectedOptions = metaTags.map((metaTag: string) => {
        const finded = seoMetaTegsOptions.find(item => item.value === metaTag || item.label === metaTag);
        return finded ? finded : { label: metaTag, value: metaTag };
      });
      selectedOptions && setSelected(selectedOptions);
    } else if (predefined_settings) {
      const selectedOptions = predefined_settings.metaTags.map((metaTag: string) => {
        const finded = seoMetaTegsOptions.find(item => item.value === metaTag || item.label === metaTag);
        return finded ? finded : { label: metaTag, value: metaTag };
      });
      selectedOptions && setSelected(selectedOptions);
    }
    if (dynamic_settings && fieldList.length > 0) {
      const key = dynamic_settings.keys[0].key;
      restoreSelectedSource({ key: key, fieldList: fieldList, setSelectedSource: setSelectedSource });
    }
  }, [fieldList]);

  //recipe fields or output fields
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [
          ...fieldList.map((item: { value: any }) => item.value),
          ...selected.map((social: { value: any }) => social.value),
        ],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);
  const handleSwitcherClick = (val: string) => {
    setSelectedMode(val);
    !clicked && setClicked(true);
    setIsDirty();
  };
  //lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);

    let localSettings = {
      proxy: {
        type: selectedProxy.value,
        country_code: getMemoizeResidentialCountryOption.current(residentialGEOCountry) || '',
      },
      metaTags: selected.map((metaTag: { value: any }) => metaTag.value),
      fast: selectedMode === 'Fast',
    };
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: {
        keys: [
          {
            key: fieldList.length && selectedSource ? selectedSource.value : null,
            inputValue: null,
            settings: localSettings,
          },
        ],
      },
      outputKeysData: [
        ...fieldList.map((item: { value: any }) => item.value),
        ...selected.map((social: { value: any }) => social.value),
      ],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [
    selected,
    selectedSource,
    fieldList,
    fieldListWithTypes,
    automationOutputTypes,
    selectedMode,
    selectedProxy,
    residentialGEOCountry,
  ]);

  const handleChangeResidentialGEOCountry = (text: string) => {
    // setError('');
    setResidentialGEOCountry(text);
    !clicked && setClicked(true);
    setIsDirty();
  };

  return (
    <div className="discover-whois-settings">
      <Row>
        <Col md={12} className="d-flex">
          <Switcher
            leftText="Fast"
            rightText="Standard"
            handleSwitcherClick={handleSwitcherClick}
            selectedValue={selectedMode}
            hoverTextLeft="Loads pages faster but may have a lower success rate."
            // hoverTextRight="Loads pages slower but may have a higher success rate."
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6} className="mb-2">
          <span className="label secondary-color">Source</span>
          <Select
            fullWidth
            options={fieldList}
            value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
            placeholder={'Select source'}
            onChange={(selectedItem: any) => {
              setSelectedSource(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>

        <Col lg={6} className="mb-2">
          <span className="label secondary-color">Meta Tags for SEO</span>
          {/* <pre>{JSON.stringify(selected)}</pre> */}
          <CustomMultiSelect
            options={seoMetaTegsOptions}
            value={selected}
            onChange={(sel: any) => {
              setSelected(sel);
              !clicked && setClicked(true);
              setIsDirty();
            }}
            labelledBy="Select"
          />
        </Col>
      </Row>
      <Row className="automation-proxy-container">
        <Col lg={6} className="mb-2">
          <span className="label secondary-color">Proxy mode</span>
          <Select
            options={proxyList}
            value={selectedProxy}
            placeholder={'proxy mode'}
            onChange={(selectedItem: any) => {
              setSelectedProxy(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
          {selectedProxy.value && (
            <div className="mt-1">
              <span className="info-icon">{infoIcon}</span>
              <span className="info-message">
                This proxy consumes {selectedProxy.value === 'premium' ? '0.12' : '0.1'} premium credits per MB
              </span>
            </div>
          )}
        </Col>

        {selectedProxy.value === 'premium' && (
          <Col lg={6} className="mb-2">
            <span className="label secondary-color">Country</span>
            <DataList
              list={residential_geo_proxy_country_list}
              value={residentialGEOCountry}
              placeholder={'Select country'}
              onChange={(text: string) => {
                handleChangeResidentialGEOCountry(text);
              }}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default GetSeoMetaTagsSettings;
