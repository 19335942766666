import { useState, KeyboardEvent } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useMutation } from '@apollo/react-hooks';

import { USER_LOGIN } from 'graphql/mutations';

import Button from 'shared-components/atom/button';
import Input from 'shared-components/atom/input';
import Alert from 'shared-components/atom/alert-message';

import { setDeviceKey, setRefToken, setUserToken } from 'helpers';
import { eyeIcon } from 'assets/svg-icons';

const DevLogin = () => {
  const [loginMutation, { loading }] = useMutation(USER_LOGIN);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    checked: false,
    error: false,
    errorMessage: '',
  });

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      submitHandler();
    }
  };

  const submitHandler = async () => {
    setFormData({ ...formData, error: false, errorMessage: '' });
    const { data } = await loginMutation({
      variables: { email: formData.email, password: formData.password, productType: 'hexomatic' },
    });
    if (!data) return;
    const {
      UserOps: {
        login: { error, message, token, hash, twofa_status, device_key, refresh_token },
      },
    } = data;
    if (error) {
      setFormData({
        ...formData,
        error: true,
        errorMessage:
          message === 'TOO_MANY_ATTEMPTS'
            ? 'This account has been locked. Please use the forgot password link to unlock it.'
            : message,
      });
    } else {
      device_key && setDeviceKey(device_key);
      if (!token.includes('twofa_verify')) {
        setUserToken(token);
        refresh_token && setRefToken(refresh_token);
        window.location.pathname = '/dashboard';
        return;
      }
    }
  };

  return (
    <div style={{ maxWidth: '600px', width: '100%', margin: 'auto' }}>
      <Row className="DevLogin-row">
        <Col lg={12} className="mb-4">
          <span className="label secondary-color">Login</span>
          <Input
            value={formData.email}
            onChange={e => {
              setFormData({ ...formData, email: e.target.value });
            }}
          />
        </Col>
        <Col lg={12} className="mb-4">
          <span className="label secondary-color">Password</span>
          <Input
            onKeyDown={handleKeyDown}
            value={formData.password}
            onChange={e => {
              setFormData({ ...formData, password: e.target.value });
            }}
            type={showPassword ? 'text' : 'password'}
            rightAddon={
              <div
                className={`eye-icon position-absolute ${showPassword ? 'active' : ''}`}
                onClick={() => setShowPassword(!showPassword)}
              >
                {eyeIcon}
              </div>
            }
          />
        </Col>
        <Col lg={12} className="mb-4">
          <Button name={'Login'} onClick={submitHandler} loading={loading} disabled={loading} className="m-auto" />
        </Col>
        <Col lg={12}>{formData.errorMessage && <Alert type="error">{formData.errorMessage}</Alert>}</Col>
      </Row>
    </div>
  );
};

export default DevLogin;
