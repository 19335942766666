import React, { useState, useEffect, useContext } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import {
  GET_HEXOMATIC_USER,
  GET_ALL_INTEGRATIONS,
  UPDATE_EMAIL,
  CREATE_EMAIL,
  RESEND_EMAIL_VERIFICATION,
  DELETE_INTEGRATION,
  GET_HEXOMATIC_USER_SETTINGS,
} from 'graphql/queries';
import { UPDATE_HEXOMATIC_USER_SETTINGS } from 'graphql/mutations';
import CustomInput from '../../shared-components/atom/input';
import Message from '../../shared-components/atom/messages';
import SharedPropertyItem from '../../shared-components/atom/sharedPropertyItem';
import Button from '../../shared-components/atom/button';
import Loader from '../../shared-components/atom/loader';
import ModalComponent from '../../shared-components/molecule/modal';
import ToolCard from '../../shared-components/molecule/tool-card';

import { isValidEmail } from '../../constants/validations';
import { errorMessages } from '../../constants';

import { UserContext } from '../../contexts/user-context';
import { ThemeContext } from '../../contexts/theme-context';

import { emailIcon } from '../../assets/svg-icons';
import './styles.scss';
import { HexomaticUserContext } from 'contexts/hexomatic-user';
import Checkbox from 'shared-components/atom/checkbox';

const emailTypeOptions = [
  {
    label: 'Get notified every time a change is detected',
    value: false,
  },
  {
    label: 'Get a daily digest of detected changes',
    value: true,
  },
];

const Emails = ({allIntegrations, integrationsLoading, refetchIntegrations}: any) => {
  const { theme } = useContext(ThemeContext);
  const [emails, setEmails] = useState([] as any);
  const [email, setEmail] = useState('');
  const { user } = useContext(UserContext);
  const { hexomaticUser } = useContext(HexomaticUserContext);
  const [dailyDigest, setDailyDigest] = useState({
    label: 'Get notified every time a change is detected',
    value: false,
  });
  const [emailEnabled, setEmailEnabled] = useState(false);
  const [refetchLoading, setRefetchLoading] = useState(false);
  const [modalUpgradeOpened, setModalUpgradeOpened] = useState(false);
  const [modalItem, setModalItem] = useState(null as any);
  const [verifyModal, setVerifyModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState<any>({ open: false, id: null });
  let history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const [enableWorkflowNotifications, setEnableWorkflowNotifications] = useState(true);
  // const [disabledEmails, setDisabledEmails] = useState([]);

  const { data } = useQuery(GET_HEXOMATIC_USER);
  const { data: userSettings, refetch } = useQuery(GET_HEXOMATIC_USER_SETTINGS);
  const [updateUserSettings, { loading: updateSettings }] = useMutation(UPDATE_HEXOMATIC_USER_SETTINGS);

  useEffect(() => {
    if (userSettings?.HexomaticUser?.getUserSettings) {
      typeof userSettings?.HexomaticUser?.getUserSettings?.send_mail === 'boolean' &&
        setEnableWorkflowNotifications(userSettings.HexomaticUser.getUserSettings.send_mail);
    }
  }, [userSettings]);

  const handleSettingsUpdates = async (bool: boolean) => {
    const res = await updateUserSettings({
      fetchPolicy: 'no-cache',
      variables: {
        settings: {
          send_mail: bool,
        },
      },
    });
    if (res?.data?.HexomaticUserOps?.createUserSettings?.error_code) {
      setErrorMessage('Something went wrong. Please try again.');
    } else {
      setEnableWorkflowNotifications(bool);
      refetch();
    }
  };

  const [resendEmailVerification, { loading: resendUpdating, data: resendMailData }] =
    useLazyQuery(RESEND_EMAIL_VERIFICATION);
  // const {
  //   data: getAllIntegrations,
  //   loading: integtrationLoading,
  //   refetch: refetchIntegrations,
  // } = useQuery(GET_ALL_INTEGRATIONS, {
  //   skip: !(
  //     data &&
  //     data.HexomaticUser &&
  //     data.HexomaticUser.getByLegacyId &&
  //     data.HexomaticUser.getByLegacyId &&
  //     data.HexomaticUser.getByLegacyId.id
  //   ),
  //   variables: {
  //     id:
  //       data &&
  //       data.HexomaticUser &&
  //       data.HexomaticUser.getByLegacyId &&
  //       data.HexomaticUser.getByLegacyId &&
  //       data.HexomaticUser.getByLegacyId.id,
  //   },
  // });

  const [updateEmail, { loading: updateLoading, data: updateData }] = useLazyQuery(UPDATE_EMAIL, {
    fetchPolicy: 'no-cache',
    onCompleted() {
      if (data?.HexomaticIntegrations?.updateEmail?.error_code) {
        //@ts-ignore
        if (errorMessages && errorMessages[data.HexomaticIntegrations.updateEmail.error_code]) {
          //@ts-ignore
          setErrorMessage(errorMessages[data.HexomaticIntegrations.updateEmail.error_code]);
        } else {
          setErrorMessage(data.HexomaticIntegrations.updateEmail.error_code);
        }
      } else {
        setRefetchLoading(true);
        refetchIntegrations();
        setRefetchLoading(false);
      }
    },
  });

  const [createEmail, { loading: createLoading, data: addMailData }] = useLazyQuery(CREATE_EMAIL, {
    onCompleted(data) {
      if (data?.HexomaticIntegrations?.createEmail?.error_code) {
        //@ts-ignore
        if (errorMessages && errorMessages[data.HexomaticIntegrations.createEmail.error_code]) {
          //@ts-ignore
          setErrorMessage(errorMessages[data.HexomaticIntegrations.createEmail.error_code]);
        } else {
          
          setErrorMessage(data.HexomaticIntegrations.createEmail.error_code);
        }
      }
    },
  });

  const [deleteIntegration, { loading: deleteIntegrationLoading, data: deleteMailData }] =
    useLazyQuery(DELETE_INTEGRATION);
  const pricingPackage = hexomaticUser ? hexomaticUser.pricing_package || '' : 'FREE';

  useEffect(() => {
    if (
      allIntegrations &&
      allIntegrations.HexomaticIntegrations.getAllIntegrations &&
      allIntegrations.HexomaticIntegrations.getAllIntegrations.email
    ) {
      const integrations = allIntegrations.HexomaticIntegrations.getAllIntegrations.email;
      let emails: any = [];
      // let disabledArrs: any[];
      integrations.forEach((item: any) => {
        const parsedItem = JSON.parse(item.data);
        emails.push({
          email: parsedItem.email,
          id: item.id,
          verified: parsedItem.verified,
          enabled: parsedItem.enabled,
        });
        // disabledArrs.push({ id: item.id });
      });
      setEmails(emails);
    }
  }, [allIntegrations]);

  useEffect(() => {
    if (addMailData && addMailData.HexomaticIntegrations && addMailData.HexomaticIntegrations.updateEmail) {
      setErrorMessage('Something went wrong.');
      return;
    } else setEmail('');
    if (
      deleteMailData &&
      deleteMailData.HexomaticIntegrations &&
      deleteMailData.HexomaticIntegrations.deleteIntegration
    ) {
      setErrorMessage('Something went wrong.');
      return;
    }
    // setErrorMessage('');
    setRefetchLoading(true);
    refetchIntegrations();
    setRefetchLoading(false);
  }, [addMailData, deleteMailData]);

  useEffect(() => {
    if (
      resendMailData &&
      resendMailData.HexomaticIntegrations &&
      resendMailData.HexomaticIntegrations.resendVerificationEmail
    ) {
      setErrorMessage('Something went wrong.');
      return;
    }
    // setErrorMessage('');
  }, [resendMailData]);

  const handleAdd = async () => {
    if (pricingPackage === 'FREE') {
      setModalUpgradeOpened(true);
      return true;
    }

    if (emails && emails.length > 19) {
      setErrorMessage('Max emails count is 20');
      return;
    }
    if (isValidEmail(email)) {
      setErrorMessage('');

      createEmail({
        variables: {
          email: email,
          enabled: true,
        },
      });
    } else {
      setErrorMessage('Please enter valid email');
    }
  };

  const onSendVerification = (email: string, id: number) => {
    resendEmailVerification({
      variables: {
        email: email,
        id: id,
      },
    });
    !errorMessage && setVerifyModal(true);
  };

  const deleteEmail = async (id: string | number) => {
    setErrorMessage('')
    deleteIntegration({
      variables: {
        id: id,
      },
    });
  };

  const onChangeNotification = async (id: number) => {
    let copiedEmails = [...emails];
    let findedEmail = copiedEmails.find(item => item.id === id);
    if (!findedEmail) return;
    setErrorMessage('');
    updateEmail({
      variables: {
        settings: {
          id: findedEmail.id,
          enabled: !findedEmail.enabled,
        },
      },
    });

    // const newEmails = copiedEmails.map((item: any) => {
    //   if (item.id === id) {
    //     return { ...item, enabled: !item.enabled };
    //   }
    //   return item;
    // });
    // setEmails(newEmails);
  };

  return (
    <ToolCard
      title="Email Notifications"
      hexowatch
      loading={
        resendUpdating ||
        integrationsLoading ||
        deleteIntegrationLoading ||
        createLoading ||
        updateSettings ||
        updateLoading
      }
      icon={<div className="icon-wrapper-settings-email">{emailIcon}</div>}
      openClose
    >
      <Checkbox
        checked={enableWorkflowNotifications}
        labelRight="Send email when a workflow completes or fails"
        onChange={() => {
          handleSettingsUpdates(!enableWorkflowNotifications);
        }}
      />
      {/*<div style={{ maxWidth: '400px', marginBottom: '20px' }}>
        <CustomSelect
          value={dailyDigest.label}
          options={emailTypeOptions}
          click={item => setDailyDigest(item)}
          fullData={true}
          width={260}
          noTextTransform
        />
      </div>*/}
      <div className="settings-emails mt-2">
        <div className="form-items">
          <CustomInput
            label={`Add new email`}
            type="email"
            name="watchEmail1"
            className="mb-4"
            placeholder=""
            value={email}
            onChange={(e: { target: { value: React.SetStateAction<string> } }) => setEmail(e.target.value)}
            rightAddon={<Button name="Add" className="hexomatic-add-btn" onClick={handleAdd} />}
          />
        </div>
        {errorMessage && (
          <div className="mt-3">
            <Message type={'error'} text={errorMessage} />
          </div>
        )}
        <p className="mb-2 mt-4 settings-card-text">List of emails:</p>
        <div className="words-emails-ips-list">
          {emails && emails.length > 0 ? (
            emails.map((item: any) => {
              return (
                <div key={item.id} className="mb-2">
                  <SharedPropertyItem
                    hexowatch={{ verified: item.verified, send: () => onSendVerification(item.email, item.id) }}
                    name={item.email}
                    enabled={item.enabled}
                    onChangeNotification={() => onChangeNotification(item.id)}
                    onClick={() => {
                      setDeleteModal({ open: true, id: item.id });
                    }}
                  />
                </div>
              );
            })
          ) : (
            <p className="text-center m-2 primary-color">
              <small>Nothing to show</small>
            </p>
          )}
        </div>

        <ModalComponent
          isModalOpen={modalUpgradeOpened}
          title={'Please upgrade your package to enable this feature'}
          actionBtnText={'Upgrade'}
          cancelBtnText={'Cancel'}
          actionBtnColor={'warning'}
          cancelBtnColor={'secondary'}
          onActionClick={() => {
            window.location.href='https://hexomatic.com/pricing/'
          }}
          onCancelClick={() => setModalUpgradeOpened(false)}
          escButtonClick={() => setModalUpgradeOpened(false)}
        />
        <ModalComponent
          isModalOpen={deleteModal.open}
          text={'Are you sure you want to delete this email address?'}
          actionBtnText={'Delete'}
          cancelBtnText={'Cancel'}
          actionBtnColor={'danger'}
          cancelBtnColor={'secondary'}
          onActionClick={() => {
            setDeleteModal(false);
            setDeleteModal({ open: false, id: null });
            deleteEmail(deleteModal.id);
          }}
          onCancelClick={() => setDeleteModal({ open: false, id: null })}
          escButtonClick={() => setDeleteModal({ open: false, id: null })}
        />

        <ModalComponent
          isModalOpen={verifyModal}
          text={'Verification email is successfully sent'}
          actionBtnText={'OK'}
          actionBtnColor={'success'}
          onActionClick={() => setVerifyModal(false)}
          escButtonClick={() => setDeleteModal({ open: false, id: null })}
        />
      </div>
    </ToolCard>
  );
};

export default React.memo(Emails);
