import React, { FC, useEffect, useState } from 'react';
import { useAutomationGlobalMethods } from '../hooks';
import { Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
import DataList from 'shared-components/atom/dataList';
import CustomMultiSelect from 'shared-components/molecule/multiselect';
import { proxyList } from 'constants/index';
import { residential_geo_proxy_country_list } from 'constants/countries/residential-geo-proxy-countries';
import { infoIcon } from 'assets/svg-icons';

const obj = {
  inputValue: null,
  settings: null,
};

const SingleSourceSelectPatternSettings: FC<any> = ({
  automationSendSettings,
  selectedRecipes,
  automationIndex,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  outputKeys,
  multiSelect,
  proxy,
  isSettingsChange,
  zip,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);
  const [clicked, setClicked] = useState(false);
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [selectedOptions, setSelectedOptions] = useState<{ label: string; value: string }[]>([]);
  const [selectedProxy, setSelectedProxy] = useState({ label: 'Data Center', value: '' });
  const [residentialGEOCountry, setResidentialGEOCountry] = useState('');
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const {
    getPreviousAutomationOutputOrPreviousRecipKeys,
    liftingSettings,
    restoreSelectedSource,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (multiSelect && !selectedOptions?.length) {
      return false;
    }
    if (!multiSelect && !selectedSource) {
      return false;
    }
    if (proxy && selectedProxy?.value === 'premium' && !residentialGEOCountry) {
      return false;
    }
    return true;
  };

  //getting previous outputs
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;

    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      multiSelect ? setSelectedOptions(fieldList) : setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const currentAutomation = automationSendSettings.find((automation: any, index: any) => index === automationIndex);
    const { dynamic_settings } = currentAutomation;

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      multiSelect ? setSelectedOptions([]) : setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      if (multiSelect) {
        if (dynamic_settings?.keys?.length > 1) {
          const filteredValue = filteredOptions?.filter((item: any) =>
            dynamic_settings?.keys.find((sub: any) => item.value === sub.key),
          );

          setSelectedOptions(filteredValue || []);
          setClicked(true);
          setFieldList(filteredOptions);
          setFieldListWithTypes(optionsWithTypes);
        } else {
          const isArr = Array.isArray(dynamic_settings?.keys?.[0]?.key);
          const foundValue = !isArr
            ? filteredOptions?.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value)
            : null;
          const filteredValue = isArr
            ? filteredOptions?.filter((item: any) =>
                dynamic_settings?.keys?.[0]?.key.find((sub: any) => item.value === sub),
              )
            : [];
          const selOptionFound = isArr ? filteredValue : foundValue ? [foundValue] : [];

          setSelectedOptions(selOptionFound);
        }
      } else {
        const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
        setSelectedSource(!selOptionFound ? null : selOptionFound);
      }
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (automationSendSettings) {
      const currentAutomation = automationSendSettings.find((automation: any, index: any) => index === automationIndex);
      const { dynamic_settings, predefined_settings } = currentAutomation;
      if (predefined_settings) {
        if (predefined_settings.proxy) {
          const selectedProxy = proxyList.find((val: any) => val.value === predefined_settings.proxy.type);
          const selectedCountry = residential_geo_proxy_country_list.find(
            (country: any) => country.value === predefined_settings.proxy.country_code,
          );
          selectedProxy && setSelectedProxy(selectedProxy);
          selectedCountry && setResidentialGEOCountry(selectedCountry.label);
        }
      }
      if (dynamic_settings && fieldList.length > 0) {
        if (!multiSelect) {
          const { keys } = dynamic_settings;
          const key = keys[0].key;
          const { settings } = keys[0];
          const proxy = settings ? settings.proxy : keys[0].proxy;
          if (proxy) {
            const selectedProxy = proxyList.find((val: any) => val.value === proxy.type);
            const selectedCountry = residential_geo_proxy_country_list.find(
              (country: any) => country.value === proxy.country_code,
            );
            selectedProxy && setSelectedProxy(selectedProxy);
            selectedCountry && setResidentialGEOCountry(selectedCountry.label);
          }
          restoreSelectedSource({ key: key, fieldList: fieldList, setSelectedSource: setSelectedSource });
          return;
        }
        if (multiSelect) {
          const recreateSelected: any = [];
          dynamic_settings.keys.map((item: any) => {
            if (typeof item.key !== 'string') {
              //old stucture
              item.key &&
                Array.isArray(item.key) &&
                item.key.map((subitem: any) => {
                  fieldList.map(
                    (field: { value: any; label: string }) =>
                      (field.value === subitem || field.label === subitem) && recreateSelected.push(field),
                  );
                });
            } else {
              fieldList.map(
                (field: { value: any; label: string }) =>
                  (field.value === item.key || field.label === item.key) && recreateSelected.push(field),
              );
            }
          });
          setSelectedOptions(recreateSelected);
        } else {
          const keys = dynamic_settings.keys.map((item: any) => {
            const recreateSelected = fieldList.find(
              (field: { value: any; label: string }) => field.value === item.key || field.label === item.key,
            );
            const proxyObj = item.settings ? item.settings.proxy : item.proxy;
            if (proxyObj) {
              const selectedProxy = proxyList.find((proxy: any) => proxy.value === proxyObj.type);
              const selectedCountry = residential_geo_proxy_country_list.find(
                (country: any) => country.value === proxyObj.country_code,
              );
              selectedProxy && setSelectedProxy(selectedProxy);
              selectedCountry && setResidentialGEOCountry(selectedCountry.label);
            }
            return recreateSelected;
          });
          setSelectedOptions(keys);
        }
      }
    }
  }, [fieldList]);

  useEffect(() => {
    if (fieldList?.length) {
      if ((multiSelect && !selectedOptions.length) || clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData:
          multiSelect && !zip
            ? [...selectedOptions.map((item: { value: string }) => item.value.toLocaleLowerCase() + outputKeys)]
            : [...fieldList.map((item: { value: any }) => item.value), ...(outputKeys ? outputKeys : [])],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [
    fieldList,
    selectedOptions,
    selectedProxy,
    residentialGEOCountry,
    proxy,
    fieldListWithTypes,
    automationOutputTypes,
  ]);

  //lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);

    const proxySettings = {
      proxy: {
        type: selectedProxy.value,
        country_code:
          residential_geo_proxy_country_list.find(item => item.label === residentialGEOCountry)?.value || '',
      },
    };

    const localSettings: any = { keys: null };
    localSettings.keys = !multiSelect
      ? [{ key: selectedSource?.value, inputValue: null, settings: proxy ? { ...proxySettings } : null }]
      : selectedOptions.map(item => ({
          inputValue: null,
          key: item.value,
          settings: proxy ? { ...proxySettings } : null,
        }));
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: localSettings,
      outputKeysData:
        multiSelect && !zip
          ? [...selectedOptions.map((item: { value: string }) => item.value.toLocaleLowerCase() + outputKeys)]
          : [...fieldList.map((item: { value: any }) => item.value), ...(outputKeys ? outputKeys : [])],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [
    selectedSource,
    selectedOptions,
    selectedProxy,
    residentialGEOCountry,
    proxy,
    fieldList,
    fieldListWithTypes,
    automationOutputTypes,
    multiSelect,
  ]);

  const handleChangeResidentialGEOCountry = (text: string) => {
    // setError('');
    setResidentialGEOCountry(text);
    !clicked && setClicked(true);
    setIsDirty();
  };

  return (
    <div className="">
      <Row className="mb-4">
        <Col lg={6} className="">
          <span className="label secondary-color">Source</span>
          {multiSelect ? (
            <CustomMultiSelect
              options={fieldList}
              value={selectedOptions}
              onChange={(sel: any) => {
                setSelectedOptions(sel);
                !clicked && setClicked(true);
                setIsDirty();
              }}
              labelledBy="select"
            />
          ) : (
            <Select
              options={fieldList}
              value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
              placeholder={'Select source'}
              onChange={(selectedItem: any) => {
                setSelectedSource(selectedItem);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          )}
        </Col>
      </Row>
      {proxy && (
        <Row className="automation-proxy-container">
          <Col md={6}>
            <span className="label secondary-color">Proxy mode</span>
            <Select
              options={proxyList}
              value={selectedProxy}
              placeholder={'Proxy mode'}
              onChange={(selectedItem: any) => {
                setSelectedProxy(selectedItem);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
          {selectedProxy.value === 'premium' && (
            <Col md={6}>
              <span className="label secondary-color">Country</span>
              <DataList
                list={residential_geo_proxy_country_list}
                value={residentialGEOCountry}
                placeholder={'Select country'}
                onChange={(text: string) => {
                  handleChangeResidentialGEOCountry(text);
                }}
              />
            </Col>
          )}
          {selectedProxy.value && (
            <Col md={12} className="mt-2">
              <span className="info-icon">{infoIcon}</span>
              <span className="info-message">
                This proxy consumes {selectedProxy.value === 'premium' ? '0.12' : '0.1'} premium credits per MB
              </span>
            </Col>
          )}
        </Row>
      )}
    </div>
  );
};

export default React.memo(SingleSourceSelectPatternSettings);
