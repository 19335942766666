import React, { FC, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLazyQuery } from '@apollo/react-hooks';

import Tooltip from 'shared-components/atom/tooltip';
import Input from 'shared-components/atom/input';
import StretchLoading from 'shared-components/molecule/stretch-loading';
import Button from 'shared-components/atom/button';
import Select from 'shared-components/atom/select';
import Datalist from 'shared-components/atom/dataList';
import CustomMultiSelect from 'shared-components/molecule/multiselect';
import Message from 'shared-components/atom/alert-message';

import { GET_ALL_INTEGRATIONS, GET_FOLDERS } from 'graphql/queries';
import { HexomaticUserContext } from 'contexts/hexomatic-user';
import { HOST } from 'constants/host';
import { errorMessages } from 'constants/index';

import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';

import { reloadIcon } from 'assets/svg-icons';
import './styles.scss';

const SaveToGoogleDrive: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  let int: any;
  const { hexomaticUser } = useContext(HexomaticUserContext);

  const [connected, setConnected] = useState(false);
  const [showUpdateAndAppendFields, setShowUpdateAndAppendFields] = useState(false);

  const [selectedGoogle, setSelectedGoogle] = useState<any>(null);
  const [refData, setRefData] = useState<any>(null);

  const [folder, setFolder] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [newFolderName, setNewFolderName] = useState('');
  const [folderIdRestored, setFolderIdRestored] = useState('');

  const [userId, setUserId] = useState(() => (hexomaticUser ? hexomaticUser.id : -1));
  const [clicked, setClicked] = useState(false);

  const [folderOptions, setFoldersOptions] = useState<any>([{ label: 'create new folder', value: 'new' }]);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);
  const [fieldList, setFieldList] = useState<any>([]);
  const [googleOptions, setGoogleOptions] = useState<any>([]);
  const [selectedFields, setSelectedFields] = useState<any>([]);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [showGoogleMessage, setShowGoogleMessage] = useState(false);
  const [el, setEl] = useState(null as any);
  const [googleConnect, setGoogleConnect] = useState(false);

  const [getAllIntegrations, { data: integrationsData, loading: getIntegrationLoading }] = useLazyQuery(
    GET_ALL_INTEGRATIONS,
    {
      fetchPolicy: 'no-cache',
    },
  );
  const [getFolders, { data: foldersData, loading: getFoldersLoading }] = useLazyQuery(GET_FOLDERS, {
    fetchPolicy: 'network-only',
  });

  const {
    outputFormat,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    liftingSettings,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (!connected || errorMessage || !selectedOptions?.length) return false;
    if (folder === 'create new folder' && !newFolderName) {
      return false;
    }
    return true;
  };

  //! Restore
  useEffect(() => {
    userId &&
      getAllIntegrations({
        variables: { id: userId },
      });
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    let optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();

    const { dynamic_settings, integration_settings } = getDynamicAndIntegrationsSettings();
    // let keysArr: any[] = [];
    if (dynamic_settings) {
      // keysArr = dynamic_settings.keys[0].key;

      // if (!Array.isArray(keysArr)) {
      //   keysArr = [keysArr];
      // }

      const settings = dynamic_settings?.keys?.[0]?.settings;
      if (settings?.create_new_folder) {
        setNewFolderName(settings.new_folder_name);
        setFolder('create new folder');
      } else {
        setFolderIdRestored(settings.folder_id);
      }
    }
    if (integration_settings) {
      const finded = googleOptions.find(
        (option: { value: any; label: any }) =>
          option.value === integration_settings._ref_data || option.label === integration_settings._ref_data,
      );
      if (finded) {
        setSelectedGoogle(finded);
        setRefData(null);
      } else if (integration_settings._ref_data) {
        setRefData(integration_settings._ref_data);
      }
    }
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    // const srcArr: any = [];

    // if (keysArr.length) {
    //   keysArr.forEach((itm: string) => {
    //     const found = filteredOptions.find((item: { label: string; value: string }) => itm === item.value);
    //     if (found) {
    //       srcArr.push(found);
    //     }
    //   });
    // }

    // setSelectedOptions(srcArr.length ? srcArr : []);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, []);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedOptions(fieldList);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);


  //! Getting integrations
  useEffect(() => {
    if (integrationsData?.HexomaticIntegrations?.getAllIntegrations?.google_sheet) {
      const { google_sheet } = integrationsData.HexomaticIntegrations.getAllIntegrations;
      const options = google_sheet.map((item: { id: any; data: any; name: string }) => {
        const googleData = JSON.parse(item.data);
        return {
          label: item.name ? item.name : googleData && googleData.name,
          value: item.id,
        };
      });
      const currentAutomation = automationSendSettings.find((automation: any, index: any) => index === automationIndex);
      const { integration_settings } = currentAutomation;
      if (integration_settings) {
        const finded = options.find(
          (option: { value: any; label: any }) =>
            option.value === integration_settings._ref_data || option.label === integration_settings._ref_data,
        );
        if (finded) {
          setSelectedGoogle(finded);
          setRefData(null);
        } else if (options?.length === 1) {
          setSelectedGoogle(options[0]);
          setIsDirty();
          setClicked(true);
          setNewFolderName('');
          setFolder('');
          setRefData(null);
        } else if (integration_settings._ref_data) {
          setRefData(integration_settings._ref_data);
          // setSelectedGoogle({ label: integration_settings._ref_data, value: integration_settings._ref_data });
        }
      }
      setGoogleOptions(options);
      options && options.length ? setConnected(true) : setConnected(false);
    }
  }, [integrationsData]);

  const fetchFoldersSheets = () => {
    selectedGoogle?.value &&
      getFolders({
        variables: {
          settings: {
            integration_id: selectedGoogle ? selectedGoogle.value : null,
          },
        },
      });
  };

  useEffect(() => {
    fetchFoldersSheets();
  }, [selectedGoogle]);

  useEffect(() => {
    setErrorMessage('');

    if (foldersData?.HexomaticIntegrations?.getGoogleIntegrationDriveFolders) {
      const { error_code, folders } = foldersData.HexomaticIntegrations.getGoogleIntegrationDriveFolders;
      if (error_code) {
        //@ts-ignore
        setErrorMessage(errorMessages[error_code] || error_code);
        setSelectedGoogle(null);
        setRefData(null);
        return;
      }
      const options = folders?.map((item: { id: string; name: string }) => ({
        label: item.name,
        value: item.id,
      }));

      const { dynamic_settings, integration_settings } = getDynamicAndIntegrationsSettings();
      if (integration_settings) {
        const finded = googleOptions.find(
          (option: { value: any; label: any }) =>
            option.value === integration_settings._ref_data || option.label === integration_settings._ref_data,
        );
        if (finded) {
          setSelectedGoogle(finded);
          setRefData(null);
        } else if (integration_settings._ref_data) {
          setRefData(integration_settings._ref_data);
        }
      }
      if (dynamic_settings) {
        const settings = dynamic_settings.keys[0].settings;
        const finded = options?.find((item: { label: string; value: any }) => item.value === settings.folder_id);
        finded ? setFolder(finded.label) : dynamic_settings.keys[0].settings?.folder_id && setFolderIdRestored(dynamic_settings.keys[0].settings?.folder_id);
      }
      setFoldersOptions([{ label: 'create new folder', value: 'new' }, ...options]);
    }
  }, [foldersData]);

  useEffect(() => {
    const foundVal = folderOptions.find((item: any) => item.value === folderIdRestored);
    foundVal ? setFolder(foundVal.label) : setFolderIdRestored('');
  }, [folderOptions]);

  useEffect(() => {
    if (folder === 'create new folder') {
      showUpdateAndAppendFields && setShowUpdateAndAppendFields(false);
    } else {
      const selectedSpreadSheet = folderOptions.find((item: { label: string }) => item.label === folder);
      if (selectedSpreadSheet) {
        getFolders({
          variables: {
            settings: {
              integration_id: selectedGoogle ? selectedGoogle.value : null,
            },
          },
        });
      }
    }
  }, [folder]);

  //! Recipe
  useEffect(() => {
    if (selectedFields.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    let optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings, integration_settings } = getDynamicAndIntegrationsSettings();
   
    if (integration_settings) {
      const finded = googleOptions.find(
        (option: { value: any; label: any }) =>
          option.value === integration_settings._ref_data || option.label === integration_settings._ref_data,
      );
      if (finded) {
        setSelectedGoogle(finded);
        setRefData(null);
      } else if (integration_settings._ref_data) {
        setRefData(integration_settings._ref_data);
      }
    }
    if (dynamic_settings) {
      setConnected(true);
      setFolderIdRestored(dynamic_settings.keys[0].settings?.folder_id);
      const key = dynamic_settings.keys?.[0]?.key;
      restoreSelectedSource({ key: key, fieldList: options, setSelectedSource: setSelectedOptions });
    } else {
      options && options.length && setSelectedFields(options.map((item: any) => ({ key: item.label, value: '' })));
    }

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    filteredOptions && filteredOptions.length && setFieldList(filteredOptions);
  }, [selectedRecipes, isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedFields(fieldList);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedFields([]);
      setClicked(true);
    }
    if (update) {
      if (dynamic_settings?.keys?.length > 1) {
        const filteredValue = filteredOptions?.filter((item: any) =>
          dynamic_settings?.keys.find((sub: any) => item.value === sub.key),
        );

        setSelectedFields(filteredValue || []);
        setClicked(true);
        setFieldList(filteredOptions);
        setFieldListWithTypes(optionsWithTypes);
      } else {
        const isArr = Array.isArray(dynamic_settings?.keys?.[0]?.key);
        const foundValue = !isArr
          ? filteredOptions?.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value)
          : null;
        const filteredValue = isArr
          ? filteredOptions?.filter((item: any) =>
              dynamic_settings?.keys?.[0]?.key.find((sub: any) => item.value === sub),
            )
          : [];
        const selOptionFound = isArr ? filteredValue : foundValue ? [foundValue] : [];

        setSelectedFields(selOptionFound);
        setClicked(true);
        setFieldList(filteredOptions);
        setFieldListWithTypes(optionsWithTypes);
      }
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [
          ...fieldList.map((item: { label: any; value: string }) => item.value),
          ...selectedOptions.map((item: { label: any }) => outputFormat(item.label, 'drive_saved_url')),
        ],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, fieldListWithTypes, automationOutputTypes, selectedOptions]);
  //! Lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);
    const selectedFolderId = folderOptions?.find((item: { label: string }) => item.label === folder);
    const hashId: any = new Date().getTime().toString();
    let localSettings = {
      keys: [
        {
          inputValue: null,
          key: fieldList.length && selectedOptions ? selectedOptions.map((item: any) => item.value) : null,
          settings: {
            create_new_folder: folder === 'create new folder',
            folder_id: folder === 'create new folder' ? null : selectedFolderId?.value || folderIdRestored,
            new_folder_name: folder === 'create new folder' ? newFolderName : null,
            uniqueHash: hashId,
          },
        },
      ],
    };
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      integrationsSettings: {
        _ref_type: 'INTEGRATION',
        _ref_data: selectedGoogle ? selectedGoogle.value : refData ? refData : null,
      },
      dynamicSettings: localSettings,
      outputKeysData: [
        ...fieldList.map((item: { label: any; value: string }) => item.value),
        ...selectedOptions.map((item: { label: any }) => outputFormat(item.label, 'drive_saved_url')),
      ],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [
    folder,
    folderIdRestored,
    selectedOptions,
    errorMessage,
    selectedGoogle,
    selectedFields,
    newFolderName,
    fieldList,
    automationOutputTypes,
    refData,
  ]);

  const showGoogle = (secondWindow: any, url: string) => {
    secondWindow && (secondWindow.location = url);
    setEl(secondWindow);
    setShowGoogleMessage(true);
    setGoogleConnect(true);
    int = setInterval(() => {
      if (!secondWindow || secondWindow.closed) {
        clearInterval(int);
        setShowGoogleMessage(false);
        setEl(null);
      }
    });
  };

  useEffect(() => {
    if (!showGoogleMessage && googleConnect) {
      getAllIntegrations({
        variables: { id: userId },
      });
      setErrorMessage('');
    }
  }, [el, showGoogleMessage, googleConnect, userId]);

  return (
    <div className="google-automation-settings">
      {(connected === null || getFoldersLoading || getIntegrationLoading) && <StretchLoading />}
      {connected === null || connected ? (
        <>
          <Row className="mb-4">
            <Col lg={6}>
              <span className="label secondary-color">Source</span>
              <CustomMultiSelect
                options={fieldList}
                value={selectedOptions}
                onChange={(selitm: any) => {
                  setSelectedOptions(selitm);
                  !clicked && setClicked(true);
                  setIsDirty();
                }}
                labelledBy="select"
              />
            </Col>
            <Col lg={6}>
              <span className="label secondary-color">Account</span>
              <Select
                fullWidth
                options={googleOptions}
                value={selectedGoogle}
                placeholder={'Select account'}
                onChange={(selectedItem: any) => {
                  setSelectedGoogle(selectedItem);
                  setRefData(null);
                  !clicked && setClicked(true);
                  setIsDirty();
                }}
              ></Select>
            </Col>
          </Row>
          {selectedGoogle && (
            <Row className="mb-4">
              <Col lg={6}>
                <div className="d-flex align-items-end">
                  <div className="w-100">
                    <span className="label secondary-color">Folder (if not selected will be saved in root)</span>
                    <Datalist
                      list={folderOptions}
                      value={folder}
                      placeholder={'Select folder'}
                      onChange={(text: string) => {
                        setFolder(text);
                        !clicked && setClicked(true);
                        setIsDirty();
                      }}
                    />
                  </div>
                  <Tooltip
                    trigger={['hover', 'focus']}
                    placement="left"
                    text="Refetch google drive folders"
                    className="reload-icon-tooltip mb-1"
                  >
                    <span className="reload-icon" onClick={fetchFoldersSheets}>
                      {reloadIcon}
                    </span>
                  </Tooltip>
                </div>
              </Col>
              {folder === 'create new folder' && (
                <Col lg={6}>
                  <span className="label secondary-color">Folder name</span>
                  <Input
                    value={newFolderName}
                    onChange={e => {
                      setNewFolderName(e.target.value);
                      !clicked && setClicked(true);
                      setIsDirty();
                    }}
                  />
                </Col>
              )}
            </Row>
          )}
        </>
      ) : (
        <Col lg={6} md={12}>
          <Button
            name="Connect"
            onClick={() => {
              const y = (window.outerHeight - 470) / 2;
              const x = (window.outerHeight - 400) / 2;
              const secWindow = window.open('', 'secondary', `width=400,height=470,top=${y},left=${x}`);
              showGoogle(
                secWindow,
                `${HOST.APP_API.APP}/social-callback/hexomatic/google-sheet?hexomatic_user_id=${hexomaticUser.id}`,
              );
            }}
          />
        </Col>
      )}
      {errorMessage && <Message type="error" text={errorMessage} className="my-2 mr-2" />}
    </div>
  );
};

export default React.memo(SaveToGoogleDrive);
