import { FC, ReactNode, useContext } from 'react';
import { SidebarToggleContext } from 'contexts/sidebar-toggle-context';
import Button from '../shared-components/atom/button';
import './layout.scss';

interface IFooterProps {
  handleCancel: () => void;
  handleSave: () => void;
  handleUpdate?: () => void;
  workflowStatus?: 'error' | 'completed' | 'progress' | 'PAUSED' | 'DRAFT' | 'CREDIT_REACHED' | 'PREMIUM_CREDIT_REACHED' | 'STORAGE_REACHED' | 'CREDIT_STORAGE_REACHED';
  disableBtns?: boolean;
  children?: ReactNode;
  workflowId?: string;
}

const StickyFooter: FC<IFooterProps> = ({
  handleCancel,
  handleSave,
  disableBtns,
  children,
  workflowId,
  handleUpdate,
}) => {
  const { opened } = useContext(SidebarToggleContext);

  return (
    <div className={`sticky-footer`} style={{ bottom: '0px' }}>
      {!children ? (
        <div className="pt-2 pb-2 sticky-footer-buttons">
          {/* <Button disabled={disableBtns} name="Back to workflows" className="mr-4" outline onClick={handleCancel} /> */}
          {workflowId && handleUpdate && (
            <Button className="mr-4" disabled={disableBtns} name={'Update'} onClick={handleUpdate} />
          )}
          <Button disabled={disableBtns} name={workflowId ? 'Update and Run' : 'Continue'} onClick={handleSave} />
        </div>
      ) : (
        <div className={`children-block ${opened ? '' : 'hide'}`}>{children}</div>
      )}
      <div className="sticky-footer-row primary-color pt-3 pb-3">
        <div className="col-lg-12 col-md-12 col-sm-12 text-center">
          &copy; {new Date().getFullYear()} <span>Hexact, Inc. All rights reserved.</span>
        </div>
      </div>
    </div>
  );
};

export default StickyFooter;
