import { useEffect, useRef, useState } from 'react';

const useOutsideClickNormal = ({ propBool = false, enableListener = false }) => {
  let elRef = useRef<any>(null);
  const [bool, setbool] = useState<any>(propBool);
  const [enabledHook, setEnabledHook] = useState(enableListener)

  const handleOutside = (e: { target: any }) => {
    if (!enabledHook) return
    if (elRef && elRef.current) {
      if (elRef.current.contains(e.target)) {
        setbool(false);
        return;
      }
      setbool(true);
    }
  };

  useEffect(() => {
    if (!elRef) {
      return
    }
    if (enabledHook) {
      document.addEventListener('mousedown', handleOutside);
      return () => {
        document.removeEventListener('mousedown', handleOutside);
      };
    }
  }, [enabledHook, elRef]);

  return [bool, elRef, setbool, setEnabledHook];
};

export default useOutsideClickNormal;
