import { useEffect, useState, useContext, useRef } from 'react';
import Tooltip from 'shared-components/atom/tooltip';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import Button from '../../shared-components/atom/button';
import ToolCard from '../../shared-components/molecule/tool-card';
import ModalComponent from '../../shared-components/molecule/modal';
import PabblyLogo from '../../assets/images/pabbly.png';
import SlackLogo from '../../assets/images/slack.svg';
import ZapierLogo from '../../assets/images/zapier.png';
import konnectzitLogo from '../../assets/images/KonnectzIt.svg';
import SQL from '../../assets/images/sql.svg';
import TelegramLogo from '../../assets/images/telegram.svg';
import microsoftTeamsLogo from '../../assets/images/ms-teams.svg';
import { HOST } from '../../constants/host';
import {
  browserIcon,
  closeIcon,
  discortLogo,
  editIcon,
  eyeIcon,
  googleLogo,
  infoIcon,
  reloadIcon,
} from '../../assets/svg-icons';
import { questionIcon } from '../../assets/svg-icons';
import {
  DELETE_INTEGRATION,
  GET_SQL_CONNECTIONS,
  UPDATE_MICROSOFT_TEAMS_INTEGRATION,
  GET_API_KEY,
  CREATE_PABBLY_INTEGRATION,
  CREATE_CONNECTZIT_INTEGRATION,
  CREATE_TEAMS_INTEGRATION,
  GET_WORDPRESS_DATA,
  CREATE_CHATGPT_INTEGRATION,
} from 'graphql/queries';
import { HexomaticUserContext } from 'contexts/hexomatic-user';
import SQLModal from './sql-modal';
import WordpressModal from './wordpress-modal';
import { UserContext } from 'contexts/user-context';
import { ThemeContext } from 'contexts/theme-context';
import { CREATE_API_KEY } from 'graphql/mutations';
import KonnectzitAndPabbly from './modals-components/konnectzit-pabbly';
import IntegratelyLogo from '../../assets/images/integrately.svg';
import EditName from './editName';
import { isValidUrl } from 'constants/validations';
import './styles.scss';

const Integrations = ({ allIntegrations, integrationsLoading, refetchIntegrations }: any) => {
  let int: any;
  const { hexomaticUser } = useContext(HexomaticUserContext);
  const { user } = useContext(UserContext);
  const [createApiKey, { data: cratedApiData, loading: apiLoading }] = useMutation(CREATE_API_KEY);
  const { data: apiData, loading: getLoading } = useQuery(GET_API_KEY);
  const inputRef = useRef(null);
  const inputRefZapier = useRef(null);
  const [slackChanelNames, setSlackChanelNames] = useState<any>(null);
  const [telegramChatIds, setTelegramChatIds] = useState<any>(null);
  const [chatGPTIds, setChatGPTIds] = useState<any>(null);
  const [microsoftTeamsChannels, setMicrosoftTeamsChannels] = useState<any>(null);
  const [integromatIds, setIntegromatIds] = useState<any>(null);
  const [discordIds, setDiscordIds] = useState<any>(null);
  //const [pricingPackage, setPricingPackage] = useState('');
  const [modalUpgradeOpened, setModalUpgradeOpened] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [refetchLoading, setRefetchLoading] = useState(false);
  const [infoModal, setInfoModal] = useState({ message: '', error: false, open: false });
  const [deleteModal, setDeleteModal] = useState<any>({ open: false, email: null });
  const [googleIntegrations, setGoogleIntegrations] = useState<any>(null);
  const [sqlDatabasesList, setSqlDatabasesList] = useState(null as any);
  const [openSqlModal, setOpenSqlModal] = useState({ show: false, item: null as any });
  const [updateSqlConnectionId, setUpdateSqlConnectionId] = useState(null as any);
  const [hidden, setHidden] = useState(true);
  const [genterateKey, setGenterateKey] = useState(null);
  // const [microsoftTeamsOptions, setMicrosoftTeamsOptions] = useState<null | { label: string; value: string }[]>(null);
  // const [selectedMicrosoftTeam, setSelectedMicrosoftTeam] = useState<null | {
  //   label: string;
  //   value: string;
  //   integrationId: number;
  // }>(null);
  // const [microsoftTeamChannelsOptions, setMicrosoftTeamChannelsOptions] = useState<
  //   null | { label: string; value: string }[]
  // >([{ label: 'ddsfd', value: 'swda' }]);
  // const [selectedMicrosoftTeamChannel, setSelectedMicrosoftTeamChannel] = useState<null | {
  //   label: string;
  //   value: string;
  //   integrationId: number;
  // }>(null);
  const [isToolCardClosed, setIsToolCardClosed] = useState(true);
  //const [showMicrosoftTeamModal, setShowMicrosoftTeamModal] = useState(false);
  const [showApiKey, setShowApiKey] = useState({
    zapier: false,
    pabbly: false,
    integrately: false,
    make: false,
  });
  const [showPabblyModal, setShowPabblyModal] = useState(false);
  const [showChatGPTModal, setShowChatGPTModal] = useState(false);
  const [showTeamsModal, setShowTeamsModal] = useState(false);
  const [teamsInput, setTeamsInput] = useState('');
  const [teamsErrorMessage, setTeamsErrorMessage] = useState('');
  const [pabblyInputText, setPabblyInputText] = useState<string>('');
  const [chatGPTInputText, setChatGPTInputText] = useState<string>('');
  const [pabblyIntegrations, setPabblyIntegrations] = useState<any>(null);
  const [pabblyErrorMessage, setPabblyErrorMessage] = useState('');
  const [chatgptErrorMessage, setChatgptErrorMessage] = useState('');

  const [integratelyIntegrations, setIntegratelyIntegrations] = useState<any>(null);

  const [showKonnectzitModal, setShowKonnectzitModal] = useState(false);
  const [konnectzitInputText, setKonnectzitInputText] = useState<string>('');
  const [konnectzitIntegrations, setKonnectzitIntegrations] = useState<any>(null);
  const [konnectzitErrorMessage, setKonnectzitErrorMessage] = useState('');

  //---------wordpress----------------
  const [wordpressList, setWordpressList] = useState<any>(null);
  const [wordpressModal, setWordpressModal] = useState({ show: false, item: null as any });
  const [updateWordpressConnectionId, setUpdateWordpressConnectionId] = useState<any>(null);
  //-----------------------------------
  const [editIntegration, setEditIntegration] = useState<Number | null>(null);
  const { theme } = useContext(ThemeContext);
  const [showGoogleMessage, setShowGoogleMessage] = useState(false);
  const [el, setEl] = useState(null as any);
  const [googleConnect, setGoogleConnect] = useState(false);

  const history = useHistory();

  const { data: sqlDatabases, refetch: sqlDatabasesRefetch } = useQuery(GET_SQL_CONNECTIONS);
  const { data: wordpressData, refetch: wordpressRefetch } = useQuery(GET_WORDPRESS_DATA);
  const [deleteIntegration, { loading: deleteIntegrationLoading, data: deletaIntagrationdata }] =
    useLazyQuery(DELETE_INTEGRATION);

  //const [getMicrosoftTeams, { data: GMTeamsData, loading: teamsLoading }] = useLazyQuery(GET_MICROSOFT_TEAMS);

  const [updateMicrosoftTeamsIntegration, { data: MTeamsUpdatedRes, loading: updateLoading }] = useLazyQuery(
    UPDATE_MICROSOFT_TEAMS_INTEGRATION,
  );

  const [createPabblyIntegration, { data: pabblyCreatedData, loading: pabblycreatedLoading }] =
    useLazyQuery(CREATE_PABBLY_INTEGRATION);
  const [createChatGPTIntegration, { data: chatGPTCreatedData, loading: chatGPTcreatedLoading }] =
    useLazyQuery(CREATE_CHATGPT_INTEGRATION);

  const [createTeamsIntegration, { data: teamsCreatedData, loading: teamsCreatedLoading }] =
    useLazyQuery(CREATE_TEAMS_INTEGRATION);

  const [createKonnectzitIntegration, { data: konnectzitCreatedData, loading: konnectzitcreatedLoading }] =
    useLazyQuery(CREATE_CONNECTZIT_INTEGRATION);

  // const userId = user && user.id ? user.id : -1;
  const pricingPackage = hexomaticUser ? hexomaticUser.pricing_package || '' : 'FREE';

  useEffect(() => {
    if (sqlDatabases?.HexomaticIntegrations?.getSQLConnections?.connections) {
      setSqlDatabasesList(sqlDatabases.HexomaticIntegrations.getSQLConnections.connections);
    }
  }, [sqlDatabases]);
  //--------------------getWordpressList--------------------
  useEffect(() => {
    if (wordpressData?.HexomaticIntegrations?.getWordPressConnections?.connections) {
      setWordpressList(wordpressData.HexomaticIntegrations.getWordPressConnections.connections);
    }
  }, [wordpressData]);
  //0#
  // useEffect(() => {
  //   if (history.location.search) {
  //     const { search } = history.location;
  //     const splitedSearch = search.split('&');
  //     const findedCode = splitedSearch.find(param => param.indexOf('integration='));
  //     const code = findedCode?.split('=')[1];
  //     if (code) {
  //       getMSteams(+code);
  //     }
  //     history.replace(history.location.pathname);
  //   }
  // }, [history.location.search]);

  //1 get teams query
  // const getMSteams = (id: number) => {
  //   getMicrosoftTeams({
  //     variables: {
  //       integrationId: +id,
  //     },
  //   });
  //   setIsToolCardClosed(false);
  //   setShowMicrosoftTeamModal(true);
  // };

  //2 get microsoft teams
  // useEffect(() => {
  //   if (GMTeamsData?.HexomaticIntegrations?.getMicrosoftTeams) {
  //     const { getMicrosoftTeams } = GMTeamsData.HexomaticIntegrations;
  //     const teamsList = Array.from(getMicrosoftTeams, ({ displayName, id }) => ({
  //       label: displayName,
  //       value: id,
  //       integrationId: +microsoftTeamsChannels[0].id,
  //     }));
  //     setShowMicrosoftTeamModal(true);
  //     setMicrosoftTeamsOptions(teamsList);
  //   }
  // }, [GMTeamsData]);

  //3# get microsoft channels query
  // useEffect(() => {
  //   if (selectedMicrosoftTeam) {
  //     getMicrosoftTeamChannels({
  //       variables: {
  //         teamId: selectedMicrosoftTeam.value,
  //         integrationId: +selectedMicrosoftTeam.integrationId,
  //       },
  //     });
  //   }
  // }, [selectedMicrosoftTeam]);

  //4# get microsoft channels and set options
  // useEffect(() => {

  //   if (GMTChannelsData?.HexomaticIntegrations?.getMicrosoftTeamChannels) {
  //     const { getMicrosoftTeamChannels } = GMTChannelsData.HexomaticIntegrations;
  //     if (selectedMicrosoftTeam && getMicrosoftTeamChannels) {
  //       const teamChannelList = Array.from(getMicrosoftTeamChannels, ({ displayName, id }) => ({
  //         label: displayName,
  //         value: id,
  //         integrationId: +selectedMicrosoftTeam.integrationId,
  //       }));
  //       setMicrosoftTeamChannelsOptions(teamChannelList);
  //     }
  //   }
  // }, [GMTChannelsData]);

  //5# set microsoft selected channel query
  // const onSaveMicrosoftChannel = () => {
  //   if (selectedMicrosoftTeamChannel && selectedMicrosoftTeam) {
  //     updateMicrosoftTeamsIntegration({
  //       variables: {
  //         integrationId: selectedMicrosoftTeam.integrationId,
  //         data: {
  //           teamId: selectedMicrosoftTeam.value,
  //           teamDisplayName: selectedMicrosoftTeam.label,
  //           channelId: selectedMicrosoftTeamChannel.value,
  //           channelDisplayName: selectedMicrosoftTeamChannel.label,
  //         },
  //       },
  //     });
  //   }
  // };

  const saveKonnectzit = () => {
    if (!konnectzitInputText) return;
    createKonnectzitIntegration({
      variables: {
        webhookUrl: konnectzitInputText,
      },
    });
  };

  const savePabbly = () => {
    if (!pabblyInputText) return;
    createPabblyIntegration({
      variables: {
        webhookUrl: pabblyInputText,
      },
    });
  };

  const saveChatGPT = () => {
    if (!chatGPTInputText) return;
    createChatGPTIntegration({
      variables: {
        settings: {
          apiKey: chatGPTInputText,
        },
      },
    });
  };

  const saveTeams = () => {
    if (!teamsInput || !teamsInput.trim()) return;
    if (!isValidUrl(teamsInput)) {
      setTeamsErrorMessage('Please enter correct webhook URL');
      return;
    }
    createTeamsIntegration({
      variables: {
        webhookUrls: [teamsInput.trim()],
      },
    });
  };
  useEffect(() => {
    if (pabblyCreatedData?.HexomaticIntegrations?.createPabblyIntegration) {
      const { error } = pabblyCreatedData.HexomaticIntegrations.createPabblyIntegration;
      if (error) {
        setPabblyErrorMessage('Please enter correct webhook URL');
      } else {
        refetchIntegrations();
        setShowPabblyModal(false);
        setPabblyInputText('');
      }
    }
  }, [pabblyCreatedData]);

  useEffect(() => {
    if (chatGPTCreatedData?.HexomaticIntegrations) {
      if (chatGPTCreatedData?.HexomaticIntegrations?.createChatGptIntegration?.error_code) {
        setChatgptErrorMessage(chatGPTCreatedData.HexomaticIntegrations.createChatGptIntegration.error_code);
      } else {
        refetchIntegrations();
        setShowChatGPTModal(false);
        setChatGPTInputText('');
      }
    }
  }, [chatGPTCreatedData]);

  useEffect(() => {
    if (teamsCreatedData?.HexomaticIntegrations?.createMicrosoftTeamsIntegration) {
      const { error } = teamsCreatedData.HexomaticIntegrations.createMicrosoftTeamsIntegration;
      if (error) {
        setTeamsErrorMessage('Please enter correct webhook URL');
      } else {
        refetchIntegrations();
        setShowTeamsModal(false);
        setTeamsInput('');
      }
    }
  }, [teamsCreatedData]);

  useEffect(() => {
    if (konnectzitCreatedData?.HexomaticIntegrations?.createKonnectzitIntegration) {
      const { error } = konnectzitCreatedData.HexomaticIntegrations.createKonnectzitIntegration;
      if (error) {
        setKonnectzitErrorMessage('Please enter correct webhook URL');
      } else {
        refetchIntegrations();
        setShowKonnectzitModal(false);
        setKonnectzitInputText('');
      }
    }
  }, [konnectzitCreatedData]);

  useEffect(() => {
    if (MTeamsUpdatedRes?.HexomaticIntegrations?.updateMicrosoftTeamsIntegration) {
      refetchIntegrations();
      //setShowMicrosoftTeamModal(false);
    }
  }, [MTeamsUpdatedRes]);

  useEffect(() => {
    if (
      allIntegrations &&
      allIntegrations.HexomaticIntegrations.getAllIntegrations &&
      allIntegrations.HexomaticIntegrations.getAllIntegrations.google_sheet
    ) {
      const { getAllIntegrations } = allIntegrations.HexomaticIntegrations;
      const google = getAllIntegrations.google_sheet;
      const chatGPT = getAllIntegrations.chatGpt;
      const slack = getAllIntegrations.slack;
      const telegram = getAllIntegrations.telegram;
      const discord = getAllIntegrations.discord;
      const microsoftTeams = getAllIntegrations.microsoftTeams;
      const integromat = getAllIntegrations.integromat;
      const pabbly = getAllIntegrations.pabbly;
      const konnectzit = getAllIntegrations.konnectzit;
      const integrately = getAllIntegrations.integrately;

      let slackArr: any = [];
      let telegramArr: any = [];
      let chatGPTArr: any = [];
      let discordArr: any = [];
      let googleArr: any = [];
      let microsoftTeamsArr: any = [];
      let integromatArr: any = [];
      let pabblyArr: any = [];
      let konnectzitArr: any = [];
      let integratelyArr: any = [];

      slack.forEach((item: any) => {
        const data = JSON.parse(item.data);
        slackArr.push({
          id: item.id,
          name: item.name,
          slackIntegration: `${data.team.name || ''} - ${data.incoming_webhook.channel || ''}`,
        });
      });
      google.forEach((item: any) => {
        googleArr.push({ id: item.id, name: item.name, googleIntegration: JSON.parse(item.data).name || '' });
      });
      telegram.forEach((item: any) => {
        telegramArr.push({ id: item.id, name: item.name, telegramIntegration: JSON.parse(item.data).id || '' });
      });
      chatGPT?.forEach((item: any) => {
        chatGPTArr.push({ id: item.id, name: item.name, integrationId: JSON.parse(item.data).apiKey || '' });
      });
      discord.forEach((item: any) => {
        discordArr.push({ id: item.id, name: item.name, discordIntegration: JSON.parse(item.data).id || '' });
      });
      microsoftTeams.forEach((item: any) => {
        microsoftTeamsArr.push({
          id: item.id,
          name: item.name,
          microsoftTeamsIntegration: JSON.parse(item.data).webhookUrls[0],
        });
      });
      integromat.forEach((item: any) => {
        integromatArr.push({ id: item.id, name: item.name, integromatIntegration: JSON.parse(item.data) });
      });
      pabbly.forEach((item: any) => {
        pabblyArr.push({ id: item.id, name: item.name, pabblyIntegration: JSON.parse(item.data) });
      });

      integrately.forEach((item: any) => {
        integratelyArr.push({ id: item.id, name: item.name, integratelyIntegration: JSON.parse(item.data) });
      });
      konnectzit &&
        konnectzit.forEach((item: any) => {
          konnectzitArr.push({ id: item.id, name: item.name, konnectzitIntegration: JSON.parse(item.data) });
        });
      setMicrosoftTeamsChannels(microsoftTeamsArr);
      setGoogleIntegrations(googleArr);
      setTelegramChatIds(telegramArr);
      setChatGPTIds(chatGPTArr);
      setSlackChanelNames(slackArr);
      setDiscordIds(discordArr);
      setIntegromatIds(integromatArr);
      setPabblyIntegrations(pabblyArr);
      setIntegratelyIntegrations(integratelyArr);
      setKonnectzitIntegrations(konnectzitArr);
    }
  }, [allIntegrations]);
  useEffect(() => {
    if (
      deletaIntagrationdata &&
      deletaIntagrationdata.HexomaticIntegrations &&
      deletaIntagrationdata.HexomaticIntegrations.deleteIntegration
    ) {
      setInfoModal({ open: true, message: 'Something went wrong.', error: true });
      return;
    }
    if (deletaIntagrationdata && deletaIntagrationdata.HexomaticIntegrations) {
      setInfoModal({ open: true, message: 'Integration was removed successfully', error: false });
      setRefetchLoading(true);
      refetchIntegrations();
      sqlDatabasesRefetch();
      wordpressRefetch();
      setRefetchLoading(false);
      setUpdateSqlConnectionId(null);
    }
  }, [deletaIntagrationdata]);

  const ondeleteIntegration = async (id: string | number) => {
    deleteIntegration({
      variables: {
        id: id,
      },
    });
  };

  const telegramManualCoonect = {
    title: 'Connect Telegram Manually',
    text: (
      <span className="telegram-info">
        {' '}
        To connect Telegram manually find channel HexomaticBot then send message{' '}
        <strong>/start {btoa(`${hexomaticUser.id}`)}</strong>
      </span>
    ),
  };

  const sqlIPs = {
    title: 'WhiteList IPs',
    text: (
      <>
        <div>35.243.137.207</div>
        <div>35.227.82.215</div>
        <div>34.74.108.91</div>
      </>
    ),
  };
  const rightIconClick = (e: { stopPropagation: any }) => {
    e.stopPropagation();
    setHidden(!hidden);
  };

  useEffect(() => {
    if (cratedApiData?.HexomaticUserOps?.createWatchApiKey?.watch_api_key) {
      setGenterateKey(cratedApiData.HexomaticUserOps.createWatchApiKey.watch_api_key);
    }
  }, [cratedApiData]);

  useEffect(() => {
    if (apiData?.HexomaticUser?.getWatchApiKey?.watch_api_key) {
      setGenterateKey(apiData.HexomaticUser.getWatchApiKey.watch_api_key);
    }
  }, [apiData]);

  const onInputClick = (e: { stopPropagation: () => void }, inputRef: any) => {
    e.stopPropagation();
    //@ts-ignore
    navigator.clipboard.writeText(genterateKey ? genterateKey : '');
    const inputDom = inputRef.current;
    if (!inputDom) {
      return;
    }
    //@ts-ignore
    inputDom.focus();
    //@ts-ignore
    inputDom.setSelectionRange(0, genterateKey ? genterateKey.length : 0);
  };

  const showGoogle = (secondWindow: any, url: string) => {
    // const y = (window.outerHeight - 470) / 2;
    // const x = (window.outerWidth - 400) / 2;
    // secondWindow = window.open(url, 'secondary', `width=400,height=470,top=${y},left=${x}`);
    secondWindow && (secondWindow.location = url);
    setEl(secondWindow);
    setShowGoogleMessage(true);
    setGoogleConnect(true);
    int = setInterval(() => {
      if (!secondWindow || secondWindow.closed) {
        clearInterval(int);
        setShowGoogleMessage(false);
        setEl(null);
      }
    });
  };

  const checkAndGenerateKey = () => {
    if (hexomaticUser.pricing_package !== 'GOLD' && hexomaticUser.pricing_package !== 'SILVER' && hexomaticUser.pricing_package !== 'BRONZE') {
      setModalUpgradeOpened(true);
      return;
    }
    createApiKey();
  };

  const returnAPIKey = (title: string, inputRef: any) => {
    return (
      <div className={`api-webhook-settings ${!hidden ? 'active' : ''}`}>
        {genterateKey ? (
          <div>
            <label className="label">{title} API key</label>
            <Tooltip trigger={['focus']} placement="top" text="API key copied to clipboard">
              <div className={`input-wrapper ${!hidden ? 'active' : ''}`}>
                <span className={`icon right pointer noselect`} onClick={rightIconClick}>
                  {eyeIcon}
                </span>
                <input
                  type={hidden ? 'password' : 'text'}
                  value={genterateKey || ''}
                  readOnly
                  ref={inputRef}
                  className={`input-field right-icon`}
                  onClick={e => onInputClick(e, inputRef)}
                />
              </div>
            </Tooltip>
          </div>
        ) : (
          <div className="btn-and-link">
            <Button
              name="Generate API key"
              loading={getLoading}
              onClick={() => checkAndGenerateKey()}
              disabled={!hexomaticUser || !hexomaticUser.pricing_package}
            />
          </div>
        )}
      </div>
    );
  };

  const handleConnectWordpress = (e: any): void => {
    if (pricingPackage === 'GOLD' || pricingPackage === 'SILVER' || pricingPackage === 'BRONZE') {
      e.preventDefault();
      setWordpressModal({ show: true, item: null });
      return;
    } else {
      setModalUpgradeOpened(true);
    }
  };

  useEffect(() => {
    if (!showGoogleMessage && googleConnect) {
      refetchIntegrations();
    }
  }, [el, showGoogleMessage, googleConnect]);

  return (
    <ToolCard
      title="Integrations"
      className="dashboard"
      loading={refetchLoading || integrationsLoading || deleteIntegrationLoading}
      icon={<div className="icon-wrapper-settings-integrations">{browserIcon}</div>}
      openClose={isToolCardClosed}
    >
      <div className="integrations-settings">
        {googleIntegrations &&
          googleIntegrations.map((google: any) => (
            <>
              <div key={google.discordIntegration} className={`integration-item integrated`}>
                <div className="wrapper">
                  <div className="img-block">
                    <span className="google-logo">{googleLogo}</span>
                  </div>
                  <div className="conntect-info">
                    <p className="text">Google</p>
                    <p className="position-relative break-word">
                      <span style={{ color: '#516077', marginRight: '10px' }}>Connected to:</span>
                      {google.name || google.googleIntegration}{' '}
                      <span
                        className="edit-integration-name"
                        onClick={() => setEditIntegration(editIntegration === google.id ? null : google.id)}
                      >
                        {editIcon}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="btns-wrapper">
                  <span
                    className="close-icon"
                    data-tip="Click to delete"
                    onClick={() => setDeleteModal({ open: true, id: google.id })}
                  >
                    {closeIcon}
                  </span>
                </div>
              </div>
              {editIntegration === google.id && (
                <EditName
                  integrationId={google.id}
                  name={google.name || google.googleIntegration}
                  setIntegration={setEditIntegration}
                  refetch={refetchIntegrations}
                />
              )}
            </>
          ))}
        {googleIntegrations && googleIntegrations.length <= 10 && (
          <div className={`integration-item`}>
            <div className="wrapper">
              <div className="img-block">
                <span className="google-logo">{googleLogo}</span>
              </div>
              <p className="text">
                <span className="pr-1">Google</span>
                <Tooltip
                  trigger={['click']}
                  placement="bottom"
                  className="d-inline teams-tooltip"
                  content={{
                    title: 'How to connect to Google',
                    text: (
                      <>
                        <div className="teams-hint">
                          <strong>Step 1:</strong> Click connect with Google
                        </div>
                        <div className="teams-hint">
                          <strong>Step 2:</strong> Sign in to your Google Account
                        </div>
                        <div className="teams-hint">
                          <strong>Step 3:</strong> Allow access to your Google Account
                        </div>
                      </>
                    ),
                  }}
                >
                  <span className="help-icon cursor-pointer">{infoIcon}</span>
                </Tooltip>
              </p>
            </div>
            <Button
              name="Connect"
              outline
              className="btn_integration not-integrated"
              onClick={e => {
                // if (pricingPackage !== 'GOLD' && pricingPackage !== 'SILVER') {
                //   e.preventDefault();
                //   setModalUpgradeOpened(true);
                //   return;
                // }
                const y = (window.outerHeight - 470) / 2;
                const x = (window.outerHeight - 400) / 2;
                const secWindow = window.open('', 'secondary', `width=400,height=470,top=${y},left=${x}`);
                showGoogle(
                  secWindow,
                  `${HOST.APP_API.APP}/social-callback/hexomatic/google-sheet?hexomatic_user_id=${hexomaticUser.id}`,
                );
              }}
            />
          </div>
        )}
        {hexomaticUser?.enterprise && (
          <>
            {chatGPTIds?.length ? (
              chatGPTIds.map((token: any) => (
                <>
                  <div key={token.integrationId} className={`integration-item integrated`}>
                    <div className="wrapper">
                      <div className="img-block">
                        <img src="https://storage.googleapis.com/hexomatic-automation-icons/ChatGPT.svg" alt="" />
                      </div>
                      <div className="conntect-info">
                        <p className="text">ChatGPT</p>
                        <p className="position-relative break-word">
                          <span style={{ color: '#516077', marginRight: '10px' }}>Connected to:</span> Secret key -{' '}
                          {token.name
                            ? token.name
                            : token.integrationId.substring(0, 3) +
                              '...' +
                              token.integrationId.substring(token.integrationId.length - 4)}
                          {/* <span
                            className="edit-integration-name"
                            onClick={() => setEditIntegration(editIntegration === telegram.id ? null : telegram.id)}
                          >
                            {editIcon}
                          </span> */}
                        </p>
                      </div>
                    </div>
                    <div className="btns-wrapper">
                      {/* <a
                        href={
                          hexomaticUser?.id
                            ? `https://t.me/HexomaticBot?start=${btoa(`${hexomaticUser.id}_${telegram.id}`)}`
                            : ''
                        }
                        target="_blank"
                        className="btn_integration-link"
                        style={{ width: '', textTransform: 'none' }}
                      >
                        {'Change Account'}
                      </a> */}
                      <span
                        className="close-icon"
                        data-tip="Click to delete"
                        onClick={() => setDeleteModal({ open: true, id: token.id })}
                      >
                        {closeIcon}
                      </span>
                    </div>
                  </div>
                  {/* {editIntegration === telegram.id && (
                    <EditName
                      integrationId={telegram.id}
                      name={telegram.name || telegram.telegramIntegration}
                      setIntegration={setEditIntegration}
                      refetch={refetchIntegrations}
                    />
                  )} */}
                </>
              ))
            ) : (
              <div className={`integration-item`}>
                <div className="wrapper">
                  <div className="img-block">
                    <img src="https://storage.googleapis.com/hexomatic-automation-icons/ChatGPT.svg" alt="" />
                  </div>
                  <p className="text pr-2">ChatGPT</p>
                </div>
                <Button
                  name="Connect"
                  outline
                  className="btn_integration not-integrated"
                  onClick={e => setShowChatGPTModal(true)}
                />
              </div>
            )}
          </>
        )}
        {/* connected telegram chanels */}
        {discordIds &&
          discordIds.map((discord: any) => (
            <>
              <div key={discord.discordIntegration} className={`integration-item integrated`}>
                <div className="wrapper">
                  <div className="img-block">
                    <span className="discord-logo discord">{discortLogo}</span>
                  </div>
                  <div className="conntect-info">
                    <p className="text">Discord</p>
                    <p className="position-relative break-word">
                      <span style={{ color: '#516077', marginRight: '10px' }}>Connected to:</span> ID -{' '}
                      {discord.name || discord.discordIntegration}
                      <span
                        className="edit-integration-name"
                        onClick={() => setEditIntegration(editIntegration === discord.id ? null : discord.id)}
                      >
                        {editIcon}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="btns-wrapper">
                  <a
                    href={
                      hexomaticUser?.id > 0
                        ? `${HOST.APP_API.APP}/social-callback/hexomatic/discord?user=${btoa(
                            `${hexomaticUser.id}_${discord.id}`,
                          )}`
                        : ''
                    }
                    target="_blank"
                    className="btn_integration-link"
                    style={{ width: '', textTransform: 'none' }}
                  >
                    {'Change Account'}
                  </a>
                  <span
                    className="close-icon"
                    data-tip="Click to delete"
                    onClick={() => setDeleteModal({ open: true, id: discord.id })}
                  >
                    {closeIcon}
                  </span>
                </div>
              </div>
              {editIntegration === discord.id && (
                <EditName
                  integrationId={discord.id}
                  name={discord.name || discord.discordIntegration}
                  setIntegration={setEditIntegration}
                  refetch={refetchIntegrations}
                />
              )}
            </>
          ))}
        {/*not connected discord chanel */}
        {discordIds && discordIds.length <= 10 && (
          <div className={`integration-item`}>
            <div className="wrapper">
              <div className="img-block">
                <span className="discord-logo discord">{discortLogo}</span>
              </div>
              <p className="text">
                {' '}
                <span className="pr-1">Discord</span>
                <Tooltip
                  trigger={['click']}
                  placement="bottom"
                  className="d-inline teams-tooltip"
                  content={{
                    title: 'How to connect to Discord',
                    text: (
                      <>
                        <div className="teams-hint">
                          <strong>Step 1:</strong> Click connect with Discord
                        </div>
                        <div className="teams-hint">
                          <strong>Step 2:</strong> Sign in to your Discord account
                        </div>
                        <div className="teams-hint">
                          <strong>Step 3:</strong> Select the desired Server and Authorize
                        </div>
                      </>
                    ),
                  }}
                >
                  <span className="help-icon cursor-pointer">{infoIcon}</span>
                </Tooltip>
              </p>
            </div>
            <Button
              name="Connect"
              outline
              className="btn_integration not-integrated"
              onClick={e => {
                // if (pricingPackage !== 'GOLD' && pricingPackage !== 'SILVER') {
                //   e.preventDefault();
                //   setModalUpgradeOpened(true);
                //   return;
                // }
                window.open(
                  `${HOST.APP_API.APP}/social-callback/hexomatic/discord?user=${btoa(`${hexomaticUser.id}`)}`,
                );
              }}
            />
          </div>
        )}

        {/* connected telegram chanels */}
        {telegramChatIds &&
          telegramChatIds.map((telegram: any) => (
            <>
              <div key={telegram.telegramIntegration} className={`integration-item integrated`}>
                <div className="wrapper">
                  <div className="img-block">
                    <img src={TelegramLogo} alt={'Telegram'} />
                  </div>
                  <div className="conntect-info">
                    <p className="text">Telegram</p>
                    <p className="position-relative break-word">
                      <span style={{ color: '#516077', marginRight: '10px' }}>Connected to:</span> ID -{' '}
                      {telegram.name || telegram.telegramIntegration}
                      <span
                        className="edit-integration-name"
                        onClick={() => setEditIntegration(editIntegration === telegram.id ? null : telegram.id)}
                      >
                        {editIcon}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="btns-wrapper">
                  <a
                    href={
                      hexomaticUser?.id
                        ? `https://t.me/HexomaticBot?start=${btoa(`${hexomaticUser.id}_${telegram.id}`)}`
                        : ''
                    }
                    target="_blank"
                    className="btn_integration-link"
                    style={{ width: '', textTransform: 'none' }}
                  >
                    {'Change Account'}
                  </a>
                  <span
                    className="close-icon"
                    data-tip="Click to delete"
                    onClick={() => setDeleteModal({ open: true, id: telegram.id })}
                  >
                    {closeIcon}
                  </span>
                </div>
              </div>
              {editIntegration === telegram.id && (
                <EditName
                  integrationId={telegram.id}
                  name={telegram.name || telegram.telegramIntegration}
                  setIntegration={setEditIntegration}
                  refetch={refetchIntegrations}
                />
              )}
            </>
          ))}
        {/*not connected telegram chanel */}
        {telegramChatIds && telegramChatIds.length <= 10 && (
          <div className={`integration-item`}>
            <div className="wrapper">
              <div className="img-block">
                <img src={TelegramLogo} alt={'Telegram'} />
              </div>
              <p className="text pr-2">Telegram</p>
              <Tooltip
                trigger={['click']}
                placement="bottom"
                content={telegramManualCoonect}
                className="telegram-tooltip"
              >
                <span className="help-icon">{infoIcon}</span>
              </Tooltip>
            </div>
            <Button
              data-tip={`${pricingPackage === 'FREE' ? 'Please upgrade your package to enable this feature' : ''}`}
              name="Connect"
              outline
              className="btn_integration not-integrated"
              onClick={e => {
                hexomaticUser?.id && window.open(`https://t.me/HexomaticBot?start=${btoa(`${hexomaticUser.id}`)}`);
              }}
            />
          </div>
        )}
        {/* connected slack chanels */}
        {slackChanelNames &&
          slackChanelNames.map((slack: any) => (
            <>
              <div key={slack.slackIntegration} className={`integration-item`}>
                <div className="wrapper">
                  <div className="img-block">
                    <img src={SlackLogo} alt={'Slack'} />
                  </div>
                  <div className="conntect-info">
                    <p className="text">Slack</p>
                    <p className="position-relative break-word">
                      <span style={{ color: '#516077', marginRight: '10px' }}>Connected to: </span>
                      {slack.name || slack.slackIntegration}
                      <span
                        className="edit-integration-name"
                        onClick={() => setEditIntegration(editIntegration === slack.id ? null : slack.id)}
                      >
                        {editIcon}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="btns-wrapper">
                  <a
                    href={
                      hexomaticUser?.id
                        ? `${HOST.APP_API.APP}/social-callback/hexomatic/slack?user=${btoa(
                            `${hexomaticUser.id}_${slack.id}`,
                          )}`
                        : ''
                    }
                    target="_blank"
                    className="btn_integration-link"
                    style={{ width: '140', textTransform: 'none' }}
                  >
                    {`Change Account or Channel`}
                  </a>
                  <span
                    className="close-icon"
                    data-tip="Click to delete"
                    onClick={() => setDeleteModal({ open: true, id: slack.id })}
                  >
                    {closeIcon}
                  </span>
                </div>
              </div>
              {editIntegration === slack.id && (
                <EditName
                  integrationId={slack.id}
                  name={slack.name || slack.slackIntegration}
                  setIntegration={setEditIntegration}
                  refetch={refetchIntegrations}
                />
              )}
            </>
          ))}
        {/*not connected slack chanel */}
        {slackChanelNames && slackChanelNames.length <= 10 && (
          <div className={`integration-item`}>
            <div className="wrapper">
              <div className="img-block">
                <img src={SlackLogo} alt={'Slack'} />
              </div>
              <p className="text">
                <span className="pr-1">Slack</span>
                <Tooltip
                  trigger={['click']}
                  placement="bottom"
                  className="d-inline teams-tooltip"
                  content={{
                    title: 'How to connect to Slack',
                    text: (
                      <>
                        <div className="teams-hint">
                          <strong>Step 1:</strong> Click connect with Slack
                        </div>
                        <div className="teams-hint">
                          <strong>Step 2:</strong> Sign in to your workspace
                        </div>
                        <div className="teams-hint">
                          <strong>Step 3:</strong> Select the desired channel to receive notifications
                        </div>
                      </>
                    ),
                  }}
                >
                  <span className="help-icon cursor-pointer">{infoIcon}</span>
                </Tooltip>
              </p>
            </div>
            <Button
              name="Connect"
              data-tip={`${pricingPackage === 'FREE' ? 'Please upgrade your package to enable this feature' : ''}`}
              outline
              className="btn_integration not-integrated"
              onClick={e => {
                hexomaticUser?.id &&
                  window.open(
                    `${HOST.APP_API.APP}/social-callback/hexomatic/slack?user=${btoa(`${hexomaticUser.id}`)}`,
                  );
              }}
            />
          </div>
        )}
        {/* microsoft teams  */}
        {microsoftTeamsChannels &&
          microsoftTeamsChannels.map((team: any, i: number) => (
            <>
              <div key={team.id} className={`integration-item`}>
                <div className="wrapper">
                  <div className="img-block">
                    <img src={microsoftTeamsLogo} alt={'microsoft teams'} />
                  </div>
                  <div className="conntect-info">
                    <p className="text">Microsoft Teams</p>
                    <p>
                      <span style={{ color: '#516077', marginRight: '10px' }}>Connected to: </span>
                      {team.name || team.microsoftTeamsIntegration}
                      <span
                        className="edit-integration-name"
                        onClick={() => setEditIntegration(editIntegration === team.id ? null : team.id)}
                      >
                        {editIcon}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="btns-wrapper">
                  <span
                    className="close-icon"
                    data-tip="Click to delete"
                    onClick={() => setDeleteModal({ open: true, id: team.id })}
                  >
                    {closeIcon}
                  </span>
                </div>
              </div>
              {editIntegration === team.id && (
                <EditName
                  integrationId={team.id}
                  name={team.name || team.microsoftTeamsIntegration}
                  setIntegration={setEditIntegration}
                  refetch={refetchIntegrations}
                />
              )}
            </>
          ))}
        {/*not connected microsoft teams */}
        {!microsoftTeamsChannels ||
          (microsoftTeamsChannels.length <= 5 && (
            <div className={`integration-item`}>
              <div className="wrapper">
                <div className="img-block">
                  <img src={microsoftTeamsLogo} alt={'Slack'} />
                </div>
                <p className="text">
                  <span className="pr-1">Microsoft Teams</span>
                  <Tooltip
                    trigger={['click']}
                    placement="bottom"
                    className="d-inline teams-tooltip"
                    content={{
                      title: 'How to connect to Microsoft Teams',
                      text: (
                        <>
                          <div className="teams-hint">
                            <strong>Step 1:</strong> Click connect with Microsoft Teams
                          </div>
                          <div className="teams-hint">
                            <strong>Step 2:</strong> Sign in to your Microsoft Teams account
                          </div>
                          <div className="teams-hint">
                            <strong>Step 3:</strong> Go to the APPs
                          </div>
                          <div className="teams-hint">
                            <strong>Step 4:</strong> Search for "Incoming Webhooks"
                          </div>
                          <div className="teams-hint">
                            <strong>Step 5:</strong> "Add to a Team" and "Set up a connector"
                          </div>
                          <div className="teams-hint">
                            <strong>Step 6:</strong> Copy the generated Webhook URL and paste it under the webhook
                            section in Hexomatic
                          </div>
                        </>
                      ),
                    }}
                  >
                    <span className="help-icon cursor-pointer">{infoIcon}</span>
                  </Tooltip>
                </p>
              </div>
              <Button
                name="Connect"
                data-tip={`${pricingPackage === 'FREE' ? 'Please upgrade your package to enable this feature' : ''}`}
                outline
                className="btn_integration not-integrated"
                onClick={e => {
                  if (pricingPackage === 'GOLD' || pricingPackage === 'SILVER' || pricingPackage === 'BRONZE') {
                    e.preventDefault();
                    setShowTeamsModal(true);
                    return;
                  } else {
                    setModalUpgradeOpened(true);
                  }
                }}
              />
            </div>
          ))}

        {
          <>
            {integromatIds &&
              integromatIds.map((integromate: any) => (
                <>
                  <div key={integromate.id} className={`integration-item`}>
                    <div className="wrapper">
                      <div className="img-block">
                        <img
                          src={'https://storage.googleapis.com/hexomatic-automation-icons/make.png'}
                          alt={'Make, formerly Integromat'}
                        />
                      </div>
                      <div className="conntect-info">
                        <p className="text">Make, formerly Integromat</p>
                        <p>
                          <span style={{ color: '#516077', marginRight: '10px' }}>Connected to: </span>
                          {integromate.name || integromate.integromatIntegration.name}
                          <span
                            className="edit-integration-name"
                            onClick={() =>
                              setEditIntegration(editIntegration === integromate.id ? null : integromate.id)
                            }
                          >
                            {editIcon}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="btns-wrapper">
                      <span
                        className="close-icon"
                        data-tip="Click to delete"
                        onClick={() => setDeleteModal({ open: true, id: integromate.id })}
                      >
                        {closeIcon}
                      </span>
                    </div>
                  </div>
                  {editIntegration === integromate.id && (
                    <EditName
                      integrationId={integromate.id}
                      name={integromate.name || integromate.integromatIntegration.name}
                      setIntegration={setEditIntegration}
                      refetch={refetchIntegrations}
                    />
                  )}
                </>
              ))}
            {/*not connected slack chanel */}
            {!integromatIds ||
              (integromatIds.length <= 10 && (
                <div className={`integration-item`}>
                  <div className="wrapper">
                    <div className="img-block">
                      <img
                        src={'https://storage.googleapis.com/hexomatic-automation-icons/make.png'}
                        alt={'Make, formerly Integromat'}
                      />
                    </div>
                    <p className="text">
                      <span className="text">Make, formerly Integromat </span>
                      <Tooltip
                        trigger={['click']}
                        placement="bottom"
                        className="d-inline teams-tooltip"
                        content={{
                          title: 'Connect to Make, formerly Integromat ',
                          text: (
                            <>
                              <div className="teams-hint">
                                <strong>Step 1: </strong>
                                Copy your Make, formerly Integromat API key
                              </div>
                              <div className="teams-hint">
                                <strong>Step 2:</strong> Sign in to your Make.com or Integromat.com account
                              </div>
                              <div className="teams-hint">
                                <strong>Step 3:</strong> Create a new scenario and search for Hexomatic
                              </div>
                              <div className="teams-hint">
                                <strong>Step 4:</strong> Select action type, create a webhook and set a connection
                              </div>
                              <div className="teams-hint">
                                <strong>Step 5:</strong> Type in the API key and save
                              </div>
                            </>
                          ),
                        }}
                      >
                        <span className="help-icon cursor-pointer">{infoIcon}</span>
                      </Tooltip>
                    </p>
                  </div>
                  <div className="integromate-btns-wrapper">
                    {/* <Button
                      name={<span className="m-auto">Check {reloadIcon}</span>}
                      data-tip={`${
                        pricingPackage === 'FREE'
                          ? 'Please upgrade your package to enable this feature'
                          : 'Refetch Make connections data'
                      }`}
                      outline
                      loading={integrationsLoading}
                      className="btn_integration not-integrated mb-1"
                      onClick={e => {
                        e.preventDefault();
                        refetchIntegrations();
                      }}
                    /> */}
                    {/* <Button
                      name="Connect"
                      data-tip={`${
                        pricingPackage === 'FREE' ? 'Please upgrade your package to enable this feature' : ''
                      }`}
                      outline
                      className="btn_integration not-integrated"
                      onClick={e => {
                        hexomaticUser?.id && window.open(`https://www.integromat.com`);
                      }}
                    /> */}
                    <Button
                      name={`API key`}
                      outline
                      className="btn_integration not-integrated"
                      onClick={() => {
                        if (pricingPackage === 'GOLD' || pricingPackage === 'SILVER' || pricingPackage === 'BRONZE') {
                          setShowApiKey({ ...showApiKey, make: !showApiKey.make });
                        } else {
                          setModalUpgradeOpened(true);
                        }
                      }}
                    />
                  </div>
                </div>
              ))}

            {showApiKey.make && <p>{returnAPIKey('Make, formerly Integromat', inputRefZapier)}</p>}
          </>
        }
        {/* connected SQL Database chanels */}
        {sqlDatabasesList &&
          sqlDatabasesList.length > 0 &&
          sqlDatabasesList.map((sql: any) => (
            <>
              <div key={sql.host} className={`integration-item`}>
                <div className="wrapper">
                  <div className="img-block">
                    <img src={SQL} alt={'SQL Database'} />
                  </div>
                  <div className="conntect-info">
                    <p className="text">
                      <span className="pr-1">SQL Database</span>
                    </p>
                    <p className="position-relative break-word">
                      <span style={{ color: '#516077', marginRight: '10px' }}>Connected to: </span>
                      <span>
                        {sql.name ? sql.name : `${sql.type}/${sql.database}`}
                        <span
                          className="edit-integration-name"
                          onClick={() => setEditIntegration(editIntegration === sql.id ? null : sql.id)}
                        >
                          {editIcon}
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="btns-wrapper">
                  <span
                    className="btn_integration-link"
                    style={{ width: '140', textTransform: 'none', cursor: 'pointer' }}
                    onClick={() => {
                      setOpenSqlModal({ show: true, item: sql });
                      setUpdateSqlConnectionId(sql.id);
                    }}
                  >
                    {`Change connection`}
                  </span>
                  <span
                    className="close-icon"
                    data-tip="Click to delete"
                    onClick={() => setDeleteModal({ open: true, id: sql.id })}
                  >
                    {closeIcon}
                  </span>
                </div>
              </div>
              {editIntegration === sql.id && (
                <EditName
                  integrationId={sql.id}
                  name={sql.name ? sql.name : `${sql.type}/${sql.database}`}
                  setIntegration={setEditIntegration}
                  refetch={sqlDatabasesRefetch}
                />
              )}
            </>
          ))}
        {/*not connected SQL Database chanel */}
        {sqlDatabasesList && sqlDatabasesList.length <= 50 && (
          <div className={`integration-item`}>
            <div className="wrapper">
              <div className="img-block">
                <img src={SQL} alt={'Database'} />
              </div>
              <p className="text">
                <span className="pr-1">SQL Database</span>
                <Tooltip trigger={['click']} placement="bottom" content={sqlIPs} className="d-inline sql-tooltip">
                  <span className="help-icon cursor-pointer">{infoIcon}</span>
                </Tooltip>
              </p>
            </div>
            <Button
              name="Connect"
              data-tip={`${pricingPackage === 'FREE' ? 'Please upgrade your package to enable this feature' : ''}`}
              outline
              className="btn_integration not-integrated"
              onClick={e => setOpenSqlModal({ show: true, item: null })}
            />
          </div>
        )}
        {
          <div className={`integration-item api-key`}>
            <div className="wrapper">
              <div className="img-block">
                <img src={ZapierLogo} alt={'Zapier'} />
              </div>
              <p className="text">
                <span className="pr-1">Zapier</span>
                <Tooltip
                  trigger={['click']}
                  placement="bottom"
                  className="d-inline teams-tooltip"
                  content={{
                    title: 'How to connect to Zapier',
                    text: (
                      <>
                        <div className="teams-hint">
                          <strong>Step 1: </strong>
                          Copy your Zapier API key
                        </div>
                        <div className="teams-hint">
                          <strong>Step 2: </strong>Choose a workflow
                        </div>
                        <div className="teams-hint">
                          <strong>Step 3:</strong> Click on add new automation icon and search for Zapier integration
                        </div>
                        <div className="teams-hint">
                          <strong>Step 4:</strong> Click on connect
                        </div>
                        <div className="teams-hint">
                          <strong>Step 5:</strong> On the redirected page create a Zap
                        </div>
                        <div className="teams-hint">
                          <strong>Step 6:</strong> Click on Hexomatic and “Sign in to Hexomatic”
                        </div>
                        <div className="teams-hint">
                          <strong>Step 7:</strong> Enter your Hexomatic account email and paste your API key
                        </div>
                        <div className="teams-hint">
                          <strong>Step 8:</strong> Press continue and set up a trigger
                        </div>
                      </>
                    ),
                  }}
                >
                  <span className="help-icon cursor-pointer">{infoIcon}</span>
                </Tooltip>
              </p>
            </div>
            <Button
              name={`API key`}
              outline
              className="btn_integration not-integrated"
              onClick={() => {
                if (pricingPackage === 'GOLD' || pricingPackage === 'SILVER' || pricingPackage === 'BRONZE') {
                  setShowApiKey({ ...showApiKey, zapier: !showApiKey.zapier });
                } else {
                  setModalUpgradeOpened(true);
                }
              }}
            />
          </div>
        }
        {showApiKey.zapier && <p>{returnAPIKey('Zapier', inputRefZapier)}</p>}
        {
          <div className={`integration-item api-key`}>
            <div className="wrapper">
              <div className="img-block">
                <img src={IntegratelyLogo} alt={'Integrately'} />
              </div>
              <p className="text">
                Integrately{' '}
                <Tooltip
                  trigger={['click']}
                  placement="bottom"
                  className="d-inline teams-tooltip"
                  content={{
                    title: 'How to connect to Integrately',
                    text: (
                      <>
                        <div className="teams-hint">
                          <strong>Step 1: </strong>
                          Copy your Integrately API key
                        </div>
                        <div className="teams-hint">
                          <strong>Step 2: </strong>Sign in to your Integrately account
                        </div>
                        <div className="teams-hint">
                          <strong>Step 3:</strong> Search for Hexomatic in apps and create an automation
                        </div>
                        <div className="teams-hint">
                          <strong>Step 4:</strong> Click on Add Connection and paste your API key
                        </div>
                      </>
                    ),
                  }}
                >
                  <span className="help-icon cursor-pointer">{infoIcon}</span>
                </Tooltip>
              </p>
            </div>
            <Button
              name={`API key`}
              outline
              className="btn_integration not-integrated"
              onClick={() => {
                if (pricingPackage === 'GOLD' || pricingPackage === 'SILVER' || pricingPackage === 'BRONZE') {
                  setShowApiKey({ ...showApiKey, integrately: !showApiKey.integrately });
                } else {
                  setModalUpgradeOpened(true);
                }
              }}
            />
          </div>
        }
        {showApiKey.integrately && <p>{returnAPIKey('Integrately', inputRef)}</p>}
        {integratelyIntegrations &&
          integratelyIntegrations.map((integrately: any) => (
            <>
              <div key={integrately.id} className={`integration-item`}>
                <div className="wrapper">
                  <div className="img-block">
                    <img src={IntegratelyLogo} alt={'Integrately'} />
                  </div>
                  <div className="conntect-info">
                    <p className="text">Integrately</p>
                    <p className="position-relative break-word">
                      <span style={{ color: '#516077', marginRight: '10px' }}>Connected to: </span>
                      <span className="cut-text white-space">
                        {integrately.name || integrately?.integratelyIntegration?.url}{' '}
                        <span
                          className="edit-integration-name"
                          onClick={() => setEditIntegration(editIntegration === integrately.id ? null : integrately.id)}
                        >
                          {editIcon}
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="btns-wrapper">
                  <span
                    className="close-icon"
                    data-tip="Click to delete"
                    onClick={() => setDeleteModal({ open: true, id: integrately.id })}
                  >
                    {closeIcon}
                  </span>
                </div>
              </div>
              {editIntegration === integrately.id && (
                <EditName
                  integrationId={integrately.id}
                  name={integrately.name || integrately?.integratelyIntegration?.url}
                  setIntegration={setEditIntegration}
                  refetch={refetchIntegrations}
                />
              )}
            </>
          ))}
        {pabblyIntegrations &&
          pabblyIntegrations.map((pabbly: any) => (
            <>
              <div key={pabbly.id} className={`integration-item`}>
                <div className="wrapper">
                  <div className="img-block">
                    <img src={PabblyLogo} alt={'Pabbly Connect'} />
                  </div>
                  <div className="conntect-info">
                    <p className="text">Pabbly Connect</p>
                    <p className="position-relative break-word">
                      <span style={{ color: '#516077', marginRight: '10px' }}>Connected to: </span>
                      <span className="cut-text white-space">
                        {pabbly.name || pabbly?.pabblyIntegration?.webhookUrl}{' '}
                        <span
                          className="edit-integration-name"
                          onClick={() => setEditIntegration(editIntegration === pabbly.id ? null : pabbly.id)}
                        >
                          {editIcon}
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="btns-wrapper">
                  <span
                    className="close-icon"
                    data-tip="Click to delete"
                    onClick={() => setDeleteModal({ open: true, id: pabbly.id })}
                  >
                    {closeIcon}
                  </span>
                </div>
              </div>
              {editIntegration === pabbly.id && (
                <EditName
                  integrationId={pabbly.id}
                  name={pabbly.name || pabbly?.pabblyIntegration?.webhookUrl}
                  setIntegration={setEditIntegration}
                  refetch={refetchIntegrations}
                />
              )}
            </>
          ))}
        {!pabblyIntegrations ||
          (pabblyIntegrations.length <= 5 && (
            <div className="integration-item">
              <div className="wrapper">
                <div className="img-block">
                  <img src={PabblyLogo} alt={'Pabbly Connect'} />
                </div>
                <p className="text">
                  <span className="pr-1">Pabbly Connect</span>
                  <Tooltip
                    trigger={['click']}
                    placement="bottom"
                    className="d-inline teams-tooltip"
                    content={{
                      title: 'How to connect to Pabbly Connect',
                      text: (
                        <>
                          <div className="teams-hint">
                            <strong>Step 1:</strong> Click connect with Pabbly Connect
                          </div>
                          <div className="teams-hint">
                            <strong>Step 2:</strong> Sign in to your Pabbly Connect account
                          </div>
                          <div className="teams-hint">
                            <strong>Step 3:</strong> Create a workflow and search for “Webhook by Pabbly”
                          </div>
                        </>
                      ),
                    }}
                  >
                    <span className="help-icon cursor-pointer">{infoIcon}</span>
                  </Tooltip>
                </p>
              </div>
              <Button
                name="Connect"
                data-tip={`${
                  pricingPackage !== 'GOLD' && pricingPackage !== 'SILVER' && pricingPackage !== 'BRONZE'
                    ? 'Please upgrade your package to enable this feature'
                    : ''
                }`}
                outline
                className="btn_integration not-integrated"
                onClick={e => {
                  if (pricingPackage === 'GOLD' || pricingPackage === 'SILVER' || pricingPackage === 'BRONZE') {
                    e.preventDefault();
                    setShowPabblyModal(true);
                    return;
                  } else {
                    setModalUpgradeOpened(true);
                  }
                }}
              />
            </div>
          ))}
        {konnectzitIntegrations &&
          konnectzitIntegrations.map((Konnectzit: any) => (
            <>
              <div key={Konnectzit.id} className={`integration-item`}>
                <div className="wrapper">
                  <div className="img-block">
                    <img src={konnectzitLogo} alt={'KonnectzIT'} />
                  </div>
                  <div className="conntect-info">
                    <p className="text">KonnectzIT</p>
                    <p className="position-relative break-word">
                      <span style={{ color: '#516077', marginRight: '10px' }}>Connected to: </span>

                      <span className="cut-text break-word white-space">
                        {Konnectzit.name || Konnectzit?.konnectzitIntegration?.webhookUrl}{' '}
                        <span
                          className="edit-integration-name"
                          onClick={() => setEditIntegration(editIntegration === Konnectzit.id ? null : Konnectzit.id)}
                        >
                          {editIcon}
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="btns-wrapper">
                  <span
                    className="close-icon"
                    data-tip="Click to delete"
                    onClick={() => setDeleteModal({ open: true, id: Konnectzit.id })}
                  >
                    {closeIcon}
                  </span>
                </div>
              </div>
              {editIntegration === Konnectzit.id && (
                <EditName
                  integrationId={Konnectzit.id}
                  name={Konnectzit.name || Konnectzit?.konnectzitIntegration?.webhookUrl}
                  setIntegration={setEditIntegration}
                  refetch={refetchIntegrations}
                />
              )}
            </>
          ))}
        {!konnectzitIntegrations ||
          (konnectzitIntegrations.length <= 5 && (
            <div className="integration-item">
              <div className="wrapper">
                <div className="img-block">
                  <img src={konnectzitLogo} alt={'KonnectzIT'} />
                </div>
                <p className="text">
                  <span className="pr-1">KonnectzIT</span>
                  <Tooltip
                    trigger={['click']}
                    placement="bottom"
                    className="d-inline teams-tooltip"
                    content={{
                      title: 'How to connect to KonnectzIT',
                      text: (
                        <>
                          <div className="teams-hint">
                            <strong>Step 1:</strong> Click connect with KonnectzIT
                          </div>
                          <div className="teams-hint">
                            <strong>Step 2:</strong> Sign in to your KonnectzIT account
                          </div>
                          <div className="teams-hint">
                            <strong>Step 3:</strong> Create Konnectz and search for Hexomatic
                          </div>
                          <div className="teams-hint">
                            <strong>Step 4:</strong> Copy the webhook URL and add it under the webhook section in
                            Hexomatic
                          </div>
                        </>
                      ),
                    }}
                  >
                    <span className="help-icon cursor-pointer">{infoIcon}</span>
                  </Tooltip>
                </p>
              </div>
              <Button
                name="Connect"
                data-tip={`${
                  pricingPackage !== 'GOLD' && pricingPackage !== 'SILVER' && pricingPackage !== 'BRONZE'
                    ? 'Please upgrade your package to enable this feature'
                    : ''
                }`}
                outline
                className="btn_integration not-integrated"
                onClick={e => {
                  if (pricingPackage === 'GOLD' || pricingPackage === 'SILVER' || pricingPackage === 'BRONZE') {
                    e.preventDefault();
                    setShowKonnectzitModal(true);
                    return;
                  } else {
                    setModalUpgradeOpened(true);
                  }
                }}
              />
            </div>
          ))}
        {wordpressList &&
          wordpressList.length > 0 &&
          wordpressList.map((wordpress: any) => (
            <>
              <div key={wordpress.host} className={`integration-item`}>
                <div className="wrapper">
                  <div className="img-block">
                    <img
                      src="https://storage.googleapis.com/hexomatic-automation-icons/wordpress.svg"
                      alt="wordpress-logo"
                    />
                  </div>
                  <div className="conntect-info">
                    <p className="text">
                      <span className="pr-1">WordPress</span>
                    </p>
                    <p className="position-relative break-word">
                      <span style={{ color: '#516077', marginRight: '10px' }}>Connected to: </span>
                      <span>
                        {wordpress.name ? wordpress.name : wordpress.username + '/' + wordpress.url}
                        <span
                          className="edit-integration-name"
                          onClick={() => setEditIntegration(editIntegration === wordpress.id ? null : wordpress.id)}
                        >
                          {editIcon}
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="btns-wrapper">
                  <span
                    className="btn_integration-link"
                    style={{ width: '140', textTransform: 'none', cursor: 'pointer' }}
                    onClick={() => {
                      setWordpressModal({ show: true, item: wordpress });
                      setUpdateWordpressConnectionId(wordpress.id);
                    }}
                  >
                    {`Change connection`}
                  </span>
                  <span
                    className="close-icon"
                    data-tip="Click to delete"
                    onClick={() => setDeleteModal({ open: true, id: wordpress.id })}
                  >
                    {closeIcon}
                  </span>
                </div>
              </div>
              {editIntegration === wordpress.id && (
                <EditName
                  integrationId={wordpress.id}
                  name={wordpress.name ? wordpress.name : wordpress.username}
                  setIntegration={setEditIntegration}
                  refetch={wordpressRefetch}
                />
              )}
            </>
          ))}
        {/*not connected Wordpress chanel */}
        {wordpressList && wordpressList.length <= 50 && (
          <div className={`integration-item`}>
            <div className="wrapper">
              <div className="img-block">
                <img src={'https://storage.googleapis.com/hexomatic-automation-icons/wordpress.svg'} alt="WordPress" />
              </div>
              <p className="text">
                <span className="pr-1">WordPress</span>
              </p>
            </div>
            <Button
              name="Connect"
              data-tip={`${
                pricingPackage !== 'GOLD' && pricingPackage !== 'SILVER' && pricingPackage !== 'BRONZE'
                  ? 'Please upgrade your package to enable this feature'
                  : ''
              }`}
              outline
              className="btn_integration not-integrated"
              onClick={handleConnectWordpress}
            />
          </div>
        )}
      </div>
      {(error || success) && (
        <p style={{ color: error ? 'red' : '#16AC59', marginTop: '10px' }}>
          {error || (success && 'Successfully subscribed')}
        </p>
      )}
      {showTeamsModal && (
        <KonnectzitAndPabbly
          errorMessage={teamsErrorMessage}
          setErrorMessage={setTeamsErrorMessage}
          inputText={teamsInput}
          setInputText={setTeamsInput}
          showKonnectzitModal={showTeamsModal}
          setShowKonnectzitModal={setShowTeamsModal}
          createdLoading={teamsCreatedLoading}
          save={saveTeams}
          label="Microsoft Teams Connect"
        />
      )}
      {showPabblyModal && (
        <KonnectzitAndPabbly
          errorMessage={pabblyErrorMessage}
          setErrorMessage={setPabblyErrorMessage}
          inputText={pabblyInputText}
          setInputText={setPabblyInputText}
          showKonnectzitModal={showPabblyModal}
          setShowKonnectzitModal={setShowPabblyModal}
          createdLoading={pabblycreatedLoading}
          save={savePabbly}
          label={'Pabbly Connect'}
        />
      )}
      {showChatGPTModal && (
        <KonnectzitAndPabbly
          errorMessage={chatgptErrorMessage}
          setErrorMessage={setChatgptErrorMessage}
          inputText={chatGPTInputText}
          setInputText={setChatGPTInputText}
          showKonnectzitModal={showChatGPTModal}
          setShowKonnectzitModal={setShowChatGPTModal}
          createdLoading={chatGPTcreatedLoading}
          save={saveChatGPT}
          label={'ChatGPT API key'}
          placeholder={'Enter API key'}
        />
      )}
      {showKonnectzitModal && (
        <KonnectzitAndPabbly
          errorMessage={konnectzitErrorMessage}
          setErrorMessage={setKonnectzitErrorMessage}
          inputText={konnectzitInputText}
          setInputText={setKonnectzitInputText}
          showKonnectzitModal={showKonnectzitModal}
          setShowKonnectzitModal={setShowKonnectzitModal}
          createdLoading={konnectzitcreatedLoading}
          save={saveKonnectzit}
          label={'KonnectzIT'}
        />
      )}
      {modalUpgradeOpened && (
        <ModalComponent
          isModalOpen={modalUpgradeOpened}
          title={'Please upgrade your package to enable this feature'}
          actionBtnText={'Upgrade'}
          cancelBtnText={'Cancel'}
          actionBtnColor={'warning'}
          cancelBtnColor={'secondary'}
          onActionClick={() => (window.location.href = 'https://hexomatic.com/pricing/')}
          onCancelClick={() => setModalUpgradeOpened(false)}
          escButtonClick={() => setModalUpgradeOpened(false)}
        />
      )}
      {deleteModal.open && (
        <ModalComponent
          isModalOpen={deleteModal.open}
          text={'Are you sure you want to delete this integration?'}
          actionBtnText={'Delete'}
          cancelBtnText={'Cancel'}
          actionBtnColor={'danger'}
          cancelBtnColor={'secondary'}
          onActionClick={() => {
            setDeleteModal(false);
            setDeleteModal({ open: false, id: null });
            ondeleteIntegration(deleteModal.id);
          }}
          onCancelClick={() => setDeleteModal({ open: false, id: null })}
          escButtonClick={() => setDeleteModal({ open: false, id: null })}
        />
      )}
      {infoModal.open && (
        <ModalComponent
          isModalOpen={infoModal.open}
          text={infoModal.message}
          actionBtnText={'OK'}
          actionBtnColor={infoModal.error ? 'error' : 'success'}
          onActionClick={() => setInfoModal({ open: false, error: false, message: '' })}
          escButtonClick={() => setInfoModal({ open: false, error: false, message: '' })}
        />
      )}
      {openSqlModal.show && (
        <SQLModal
          openSqlModal={openSqlModal.show}
          updateSqlConnectionId={updateSqlConnectionId}
          setOpenSqlModal={(val: boolean) => {
            setOpenSqlModal({ show: val, item: null });
          }}
          item={openSqlModal.item}
          refetchDataList={() => sqlDatabasesRefetch()}
        />
      )}
      {wordpressModal.show && (
        <WordpressModal
          openModal={wordpressModal.show}
          updateConnectionId={updateWordpressConnectionId}
          setOpenModal={setWordpressModal}
          item={wordpressModal.item}
          refetchDataList={() => wordpressRefetch()}
        />
      )}
    </ToolCard>
  );
};

export default Integrations;
