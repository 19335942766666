import { FC } from 'react';
import Tooltip from 'shared-components/atom/tooltip';
import { minimizeIcon, maximizeIcon } from 'assets/svg-icons';
import './styles.scss';

interface IMinimize {
  bool: boolean;
}

const Minimize: FC<IMinimize> = ({ bool = false }) => {
  return (
    <Tooltip trigger={['hover', 'focus']} placement="left" text={bool ? 'Minimize' : 'Maximize'}>
      {bool ? <div className="minimize">{minimizeIcon}</div> : <div className="maximize">{maximizeIcon}</div>}
    </Tooltip>
  );
};

export default Minimize;
