import { useMutation, useQuery } from '@apollo/react-hooks';
import { LIST_CUSTOMER_CARD, PAYMENT_METHOD_QUERY } from 'graphql/queries';
import { ADD_CARD, ADD_PAYMENT_METHOD, DELETE_CREDIT_CARD, UPDATE_STRIPE_CUSTEMER } from 'graphql/mutations';
import CreditCardForm from 'shared-components/organizm/credit-card-form';
import { CardStateData } from 'shared-components/organizm/credit-card-form/types';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from 'contexts/user-context';
import Message from 'shared-components/atom/alert-message';

export const cardsFirstNumbers: any = {
  VISA: '4242',
  MASTERCARD: '5555',
  'AMERICAN EXPRESS': '3782',
  DISCOVER: '6011',
  DINERSCLUB: '3056',
  JCB: '3566',
  UNIONPAY: '6200',
  'DINERS CLUB': '3056',
};
const PaymentMethodsCard = () => {
  //-----------------Context-------------------------------
  const { user } = useContext(UserContext);
  //-----------------State---------------------------------

  const [cardsInfo, setCardsInfo] = useState([]);
  const [card, setCard] = useState<CardStateData | undefined>(undefined);
  const [error, setError] = useState('');
  const [deleteError, setDeleteError] = useState('');
  //-----------------Queries-------------------------------
  const [addPaymentMethod, { loading, error: paymentError }] = useMutation(ADD_CARD);
  const [deleteCreditCard, { loading: deleteLoading }] = useMutation(DELETE_CREDIT_CARD);
  const [changeDefaultCard, { loading: defaultLoading }] = useMutation(UPDATE_STRIPE_CUSTEMER);
  const {
    data: creditCards,
    loading: cardsLoading,
    refetch: refetchCreditCards,
  } = useQuery(LIST_CUSTOMER_CARD, {
    fetchPolicy: 'no-cache',
    variables: {
      settings: {
        limit: 1000,
      },
    },
  });
  //-------------------------useEffects-----------------------
  useEffect(() => {
    //@ts-ignore
    window['Stripe'].setPublishableKey(
      window.location.hostname.includes('dash.')
        ? 'pk_live_8KwSrH69LnrJea7rrCH7Phn1'
        : 'pk_test_awe3SQdhRsX7VjRATH8uPbyW',
    );
  }, []);

  useEffect(() => {
    if (
      creditCards &&
      creditCards.Billing &&
      creditCards.Billing.listCustomerCards &&
      creditCards.Billing.listCustomerCards.cards
    ) {
      setCardsInfo(creditCards.Billing.listCustomerCards.cards);
      const defaultCard = creditCards.Billing.listCustomerCards.cards.find((item: any) => item.isDefault === 'true');
      defaultCard &&
        setCard({
          cvc: '***',
          expiry: '**/**',
          focus: defaultCard.brand,
          name: '**** ****',
          number:
            (cardsFirstNumbers[defaultCard.brand.toUpperCase()]
              ? cardsFirstNumbers[defaultCard.brand.toUpperCase()]
              : '****') +
            '********' +
            defaultCard.lastDigits,
        });
    }
  }, [creditCards]);

  useEffect(() => {
    if (error) setTimeout(() => setError(''), 7000);
  }, [error]);
  //-------------------------Functions-----------------------
  const addCard = async (cardData: CardStateData, id: string) => {
    const res = await addPaymentMethod({
      variables: {
        settings: {
          email: user && user.email,
          name: cardData.name,
          source: id,
        },
      },
    });
    const errors =
      res && res.data && res.data.BillingOps && res.data.BillingOps.addCard
        ? res.data.BillingOps.addCard.error_code
        : null;
    if (!errors || errors.length === 0) {
      await refetchCreditCards();
    } else {
      setError(errors.includes('limit_reached') ? 'Attached cards limit reached' : errors.includes('Try later') ? 'Try later' : 'Invalid card');
    }
  };

  const handlePaymentFormSubmit = async (cardData: CardStateData) => {
    const expiry = cardData.expiry.split('/');
    try {
      //@ts-ignore
      window.Stripe.card.createToken(
        {
          number: cardData.number,
          exp_month: +expiry[0],
          exp_year: +expiry[1].slice(0, 2),
          cvc: cardData.cvc,
        },
        //@ts-ignore
        (status, response) => {
          if (status === 200) {
            if (response && response.id) {
              addCard(cardData, response.id);
            }
          } else {
            setError('Invalid parameters.');
          }
        },
      );
    } catch {
      console.log('Something went wrong');
    }
  };

  const handleRemove = async (id: string) => {
    const res = await deleteCreditCard({
      variables: {
        settings: {
          cardId: id,
        },
      },
    });
    if (res && res.data && res.data.BillingOps && res.data.BillingOps.deleteCreditCard) {
      if (res.data.BillingOps.deleteCreditCard.error_code) {
        setDeleteError('Something went wrong');
        setTimeout(() => setDeleteError(''), 10000);
      }
      !res.data.BillingOps.deleteCreditCard.error_code && refetchCreditCards();
    }
  };
  const handleChangeDeafault = async (id: string) => {
    const res = await changeDefaultCard({
      variables: {
        settings: {
          defaultSource: id,
        },
      },
    });
    if (
      res &&
      res.data &&
      res.data.BillingOps &&
      res.data.BillingOps.updateStripeCustomer &&
      !res.data.BillingOps.updateStripeCustomer.error_code
    )
      refetchCreditCards();
    else setError('Something went wrong');
  };
  return (
    <div className="position-relative col-12">
      {deleteError && <Message text={deleteError} type={'error'} />}
      <CreditCardForm
        onSubmit={handlePaymentFormSubmit}
        cardInfo={card}
        loading={loading || cardsLoading || deleteLoading || defaultLoading}
        error={
          error ||
          (paymentError &&
            paymentError.graphQLErrors &&
            paymentError.graphQLErrors[0] &&
            paymentError.graphQLErrors[0].message)
        }
        cards={cardsInfo}
        handleRemove={handleRemove}
        handleChangeDeafault={handleChangeDeafault}
      />
    </div>
  );
};

export default PaymentMethodsCard;
