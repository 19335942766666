import { FC } from 'react';
import Checkbox from 'shared-components/atom/checkbox';
import TimeElement from 'shared-components/atom/show-time';
import Badge from 'shared-components/atom/badge';
import { eventStop } from 'helpers';
import { useHistory } from 'react-router-dom';

interface IItem {
  onChange?: (val: boolean) => void;
  checked?: boolean;
  list: {
    title: string | number | any;
    status?: any;
    date?: boolean;
  }[];
  redirect?: string;
}

const BodyItem: FC<IItem> = ({ onChange, checked, list, redirect }) => {
  const history = useHistory();
  return (
    <div className="d-flex align-items-center justify-content-between body-items">
      {onChange && (
        <div className="pl-4 card-body-item mw-60">
          <span onClick={e => eventStop(e)}>
            <Checkbox checked={checked || false} onChange={onChange} />
          </span>
        </div>
      )}
      <div className={`items ${redirect ? 'cursor-pointer' : ''}`} onClick={() => redirect && history.push(redirect)}>
        {list.map(item => (
          <div className="card-body-item">
            {item.date ? (
              <TimeElement time={item.title} />
            ) : item.status ? (
              <Badge messageType={item.status} width={115} buttonType={'transparent'}>
                {item.title}
              </Badge>
            ) : (
              item.title
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BodyItem;
