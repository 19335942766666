import { FC } from 'react';
import './styles.scss';

interface WizardCardProps {
  title: string;
  description: string;
  icon: any;
}

const WizardCard: FC<WizardCardProps> = ({ title, icon, description }) => {
  return (
    <div className="wizard-card">
      <div className="wizard-card-header mt-3">
        <div className="wizard-card-icon mt-3">
          <img src={icon} alt="Tool icon" />
        </div>
        <h5 className="text-center mt-3">{title}</h5>
      </div>
      <div className="wizard-card-body mt-3">
        <p>{description}</p>
      </div>
    </div>
  );
};

export default WizardCard;
