import { FC, ReactNode, useState, useEffect, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { GET_PUBLIC_WORKFLOWS } from 'graphql/queries';

import WizardCard from 'shared-components/molecule/wizard-card';
import dataInputIcon from 'assets/icons/data-input.svg';
import automationIcon from 'assets/icons/automations.svg';
import scrapingRecipeIcon from 'assets/icons/scrapeing-recipe.svg';
import templateIcon from 'assets/icons/template.svg';
import newRecipeIcon from 'assets/icons/new-recipe.svg';
import useExisting from 'assets/icons/use-existing.svg';
import BreadcrumbLinks from 'shared-components/atom/breadcrumb';

import { StateChangeContext } from 'contexts/state-change-context';
import './styles.scss';

interface CreateWizardProps {}

const wizardOptions: { title: string; description: string; icon: ReactNode }[] = [
  {
    title: 'Ready to use workflow templates',
    description: 'Tap into our ready to use workflow templates',
    icon: templateIcon,
  },
  {
    title: 'Data Input',
    description: 'Start using a CSV / TXT / PDF / IMAGE / AUDIO  files, text, a list of keywords or URLs',
    icon: dataInputIcon,
  },
  {
    title: 'Automations',
    description: 'Use a ready made automation to find leads, tap into search engines or extract data at scale',
    icon: automationIcon,
  },
  { title: 'Scraping recipe', description: 'Start your workflow from a scraping recipe', icon: scrapingRecipeIcon },
];

const scrapingRecipeOptions: { title: string; description: string; icon: ReactNode }[] = [
  {
    title: 'Create New',
    description: 'Create new recipe from scratch',
    icon: newRecipeIcon,
  },
  // {
  //   title: 'Create from a template',
  //   description: 'Tap into our library of ready made recipe templates',
  //   icon: templateIcon,
  // },
  {
    title: 'Use existing',
    description: 'Create workflow using already created recipes',
    icon: useExisting,
  },
];

const CreateWizard: FC<CreateWizardProps> = props => {
  const { push } = useHistory();
  const { setStateChange } = useContext(StateChangeContext);

  const [scrapingRecipeOpen, setScrapingRecipeOpen] = useState(
    window.location.pathname.includes('/create-starter/scraping-recipes'),
  );
  const [count, setCount] = useState(0);

  const { data: workflows } = useQuery(GET_PUBLIC_WORKFLOWS, {
    fetchPolicy: 'no-cache',
    variables: {
      settings: {
        filter: {
          page: 1,
          limit: 1,
        },
      },
    },
  });

  useEffect(() => {
    if (workflows?.HexomaticPublicWorkflow?.getPublicWorkflows?.count) {
      setCount(workflows.HexomaticPublicWorkflow.getPublicWorkflows.count);
    }
  }, [workflows]);

  const handleNavigate = (title: string): void => {
    if (title === 'Data Input')
      setStateChange({
        id: 47,
        toggle: Math.floor(Math.random() * 10000),
      });
    switch (title) {
      case 'Ready to use workflow templates':
        push('/public-workflows');
        break;
      case 'Data Input':
        push('/workflows/create', { isAutomationSelected: 47 });
        break;
      case 'Automations':
        push('/automations-without-source');
        break;
      case 'Use existing':
        setStateChange({
          toggle: Math.floor(Math.random() * 10000),
          tab: '2',
        });
        push('/workflows/create', { isScrapingTab: true });
        break;
      case 'Scraping recipe':
        push('/workflows/create-starter/scraping-recipes');
        break;
      case 'Create New':
        push('/scraping-recipes/create');
        break;
      case 'Create from a template':
        push('/scraping-public-recipes');
        break;
    }
  };

  const switchUserMode = (): void => {
    localStorage.setItem('workflow-starter-mode', 'advanced');
    push('/workflows/create');
  };

  return (
    <div>
      {!scrapingRecipeOpen ? (
        <BreadcrumbLinks
          items={[
            {
              to: `/workflows`,
              name: 'Workflows',
              active: false,
            },
            {
              to: `/workflows/create-starter`,
              name: 'How would you like to start your workflow?',
              active: true,
            },
          ]}
          rightAddOn={
            <h4 className="clickable-text" onClick={switchUserMode}>
              Close guided setup
            </h4>
          }
          noBorderLine
        />
      ) : (
        <BreadcrumbLinks
          items={[
            {
              to: `/workflows`,
              name: 'Workflows',
              active: false,
            },
            {
              to: `/workflows/create-starter`,
              name: 'How would you like to start your workflow?',
              active: false,
            },
            {
              to: `/`,
              name: 'Scraping Recipe',
              active: true,
            },
          ]}
          rightAddOn={
            <h4 className="clickable-text" onClick={switchUserMode}>
              Close guided setup
            </h4>
          }
          noBorderLine
        />
      )}
      {scrapingRecipeOpen ? (
        <Row className="mt-3 justify-content-center">
          {scrapingRecipeOptions.map((item: { title: string; description: string; icon: ReactNode }) => (
            <Col
              onClick={() => handleNavigate(item.title)}
              key={item.title}
              md={6}
              lg={4}
              xl={3}
              className="wizard-card-wrapper px-3 py-2"
            >
              <WizardCard title={item.title} description={item.description} icon={item.icon} />
            </Col>
          ))}
        </Row>
      ) : (
        <Row className="mt-3 ">
          {wizardOptions.map((item: { title: string; description: string; icon: ReactNode }) => {
            return (
              <Col
                onClick={() => handleNavigate(item.title)}
                key={item.title}
                md={6}
                lg={4}
                xl={3}
                className="wizard-card-wrapper px-3 py-2"
              >
                <WizardCard title={item.title} description={item.description} icon={item.icon} />
              </Col>
            );
          })}
        </Row>
      )}
    </div>
  );
};

export default CreateWizard;
