import { FC } from 'react';
import Markdown from '../../atom/react-markdown';
import './styles.scss';

const AutomationDetailsContent: FC<{ description: string }> = ({ description }) => (
  <>
    <div className="automation-details-footer">
      <Markdown text={description} />
    </div>
  </>
);

export default AutomationDetailsContent;
