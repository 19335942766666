import { FC, useState, useLayoutEffect, ReactNode, memo, useCallback } from 'react';
import { arrowDownIcon } from 'assets/svg-icons';
import useClickOutside from 'hooks/useClickOutside';
import './styles.scss';

interface ISelect {
  value?: any;
  options?: any;
  onChange?: any;
  children?: ReactNode;
  disabled?: boolean;
  manySelect?: boolean;
  className?: string;
  placeholder?: string | ReactNode;
  bottom?: any;
  icon?: ReactNode;
  id?: string;
  showLeft?: boolean;
  showRight?: boolean;
  showBottom?: boolean;
  fullWidth?: boolean;
  noBorder?: boolean;
  width?: number;
  noTextTransform?: boolean;
  fullwidth?: any;
  label?: any;
  danger?: boolean;
  w100?: boolean;
  textDefault?: boolean;
  textUppercase?: boolean;
}

const Select: FC<ISelect> = ({
  value,
  options,
  onChange,
  children,
  disabled,
  manySelect,
  className,
  placeholder,
  bottom,
  icon,
  showLeft,
  showRight,
  showBottom,
  fullWidth,
  noBorder,
  width,
  label,
  danger,
  w100,
  textDefault,
  textUppercase,
}) => {
  const [showOptions, selectRef, setShowOptions] = useClickOutside(false, val => {
    setIsShow(val);
  });
  const [isShow, setIsShow] = useState(false);
  const [docHeight, setDocHeight] = useState(0);
  const [elOffsetHeight, setElOffsetHeight] = useState(0);

  useLayoutEffect(() => {
    setDocHeight(document.documentElement.clientHeight);
  }, [document.documentElement.clientHeight]);

  useLayoutEffect(() => {
    if (selectRef && selectRef.current) {
      const dimensions = selectRef.current.getBoundingClientRect();
      setElOffsetHeight(dimensions.y);
    }
  }, [selectRef]);

  const res = docHeight - elOffsetHeight;

  const getClassNamesByHeight = useCallback(() => {
    return res < 330 && res > 150 ? 'short-options' : res < 150 ? (bottom ? '' : 'bottom short-options') : '';
  }, [res]);

  const handleSetIsShow = useCallback(() => {
    setIsShow(!isShow);
  }, [isShow]);

  const handleShowOptions = useCallback(() => {
    !disabled && setShowOptions(!showOptions);
  }, [disabled, showOptions]);

  const handleClick = useCallback(
    item => {
      if (item && value && item.value === value.value) return;
      onChange(item);
      !manySelect && setShowOptions(false);
    },
    [manySelect, onChange, value],
  );
  return (
    <div className={`d-block ${w100 ? 'w-100' : ''}`}>
      {label && <div className="select-label primary-color d-flex">{label}</div>}
      <div
        className={`custom-universal-select noselect ${className ? className : ''} ${
          showLeft ? 'show-left' : showRight ? 'show-right' : showBottom ? 'show-bottom' : ''
        } ${fullWidth ? 'full-width' : ''} ${noBorder ? 'no-border' : ''} ${disabled ? 'disabled-select' : ''}`}
        ref={selectRef}
        onClick={handleSetIsShow}
      >
        {showLeft || showRight || showBottom ? (
          <span id="icon" onClick={handleShowOptions}>
            {icon}
          </span>
        ) : (
          <button
            className={`select ${isShow && showOptions ? 'border-color' : ''} ${danger ? 'border-danger' : ''} `}
            style={{ width: width ? width : '' }}
            disabled={disabled}
            onClick={handleShowOptions}
          >
            {placeholder && !value ? (
              <span className="placeholder">{placeholder}</span>
            ) : (
              <span
                className={`${textDefault ? 'text-transform-default' : ''}${
                  textUppercase ? 'text_upperCase' : ''
                } selected-value noselect`}
              >
                {typeof value === 'object' ? value && value.label : value}
              </span>
            )}
            {icon ? icon : <span className="arrow-icon">{arrowDownIcon}</span>}
          </button>
        )}
        {isShow && showOptions && (
          <div id="drop-down-profile" className={`options ${getClassNamesByHeight()}`} style={{ width: width || '' }}>
            {children
              ? children
              : options &&
                options.map((item: any, index: number) => (
                  <p
                    className={`option ${textDefault ? 'text-transform-default' : ''} ${
                      textUppercase ? 'text-transform-upperCase' : ''
                    } ${item.value}
                  ${
                    value === item.label ||
                    (manySelect && value && value.find((v: any) => v === item.value)) ||
                    (value === 'all' && item.value === '') ||
                    ((typeof value !== 'string' || typeof value !== 'number') && value && value.value === item.value)
                      ? !manySelect
                        ? ''
                        : 'selected'
                      : ''
                  }`}
                    key={item.value}
                    onClick={() => {
                      handleClick(item);
                    }}
                  >
                    <span className="option-value text-truncate">
                      {item.icon && item.icon} {item.label}
                    </span>
                  </p>
                ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Select);
