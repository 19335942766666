import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Tooltip from 'shared-components/atom/tooltip';
import Select from 'shared-components/atom/select';
import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import Alert from 'shared-components/atom/alert-message';
import Input from 'shared-components/atom/input';
import CustomMultiSelect from 'shared-components/molecule/multiselect';
import { settings } from 'cluster';
import { infoIcon } from 'assets/svg-icons';

// replace, append, prepend

const TypeOptions = [
  { label: 'replace', value: 'replace' },
  { label: 'append', value: 'append' },
  { label: 'prepend', value: 'prepend' },
  { label: 'remove', value: 'remove' },
];

// const outputs = ['_find_and_replace_result'];

const AiImageLabelingSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [fieldList, setFieldList] = useState<any>([]);
  const [clicked, setClicked] = useState(false);
  const [selectedType, setSelectedType] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [fromText, setFromText] = useState('');
  const [toText, setToText] = useState('');
  const [selectedFields, setSelectedFields] = useState<any>([]);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const {
    liftingSettings,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (!selectedFields?.length || !selectedType || (selectedType.value !== 'remove' && !toText)) return false;
    return true;
  };

  //recipe
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const { keys } = dynamic_settings;
      if (!keys.length) return;
      const { key, settings } = keys[0];
      const option = settings ? settings.option : keys[0].option;
      const fromText = settings ? settings.fromText : keys[0].fromText;
      const toText = settings ? settings.toText : keys[0].toText;

      let restoreSelectedKeys: any = [];
      keys.forEach((selected: any) => {
        const finded = options.find((item: any) => item.value === selected.key);
        finded && restoreSelectedKeys.push(finded);
      });
      restoreSelectedKeys && restoreSelectedKeys.length && setSelectedFields(restoreSelectedKeys);
      const finded = TypeOptions.find(item => item.value === option);
      finded && setSelectedType(finded);
      fromText && setFromText(fromText);
      toText && setToText(toText);
    }
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedFields(fieldList);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedFields([]);
      setClicked(true);
    }
    if (update) {
      if (dynamic_settings?.keys?.length > 1) {
        const filteredValue = filteredOptions?.filter((item: any) =>
          dynamic_settings?.keys.find((sub: any) => item.value === sub.key),
        );

        setSelectedFields(filteredValue || []);
        setClicked(true);
        setFieldList(filteredOptions);
        setFieldListWithTypes(optionsWithTypes);
      } else {
        const isArr = Array.isArray(dynamic_settings?.keys?.[0]?.key);
        const foundValue = !isArr
          ? filteredOptions?.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value)
          : null;
        const filteredValue = isArr
          ? filteredOptions?.filter((item: any) =>
              dynamic_settings?.keys?.[0]?.key.find((sub: any) => item.value === sub),
            )
          : [];
        const selOptionFound = isArr ? filteredValue : foundValue ? [foundValue] : [];

        setSelectedFields(selOptionFound);
        setClicked(true);
        setFieldList(filteredOptions);
        setFieldListWithTypes(optionsWithTypes);
      }
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: fieldList.map((item: { value: any }) => item.value),
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);

  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);

    let localSettings = {
      keys: selectedFields.map((field: any) => ({
        inputValue: null,
        key: field.value,
        settings: {
          option: selectedType ? selectedType.value : null,
          fromText: fromText,
          toText: selectedType && selectedType.value !== 'remove' ? toText : '',
        },
      })),
    };
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: localSettings,
      outputKeysData: fieldList.map((item: { value: any }) => item.value),
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [fromText, toText, selectedFields, selectedType]);

  return (
    <div className="google-automation-settings">
      <Row className="first-row">
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Select Sources</span>
          <CustomMultiSelect
            options={fieldList}
            value={selectedFields}
            onChange={(sel: any) => {
              setSelectedFields(sel);
              !clicked && setClicked(true);
              setIsDirty();
            }}
            labelledBy="Select"
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6} className="mb-4">
          <Tooltip
            trigger={['hover', 'focus']}
            placement="right"
            text="If left empty, the full data value will be selected"
          >
            <span className="label secondary-color">Text value {infoIcon}</span>
          </Tooltip>
          <Input
            value={fromText}
            placeholder="type text"
            onChange={e => {
              setFromText(e.target.value);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Operation</span>
          <Select
            options={TypeOptions}
            value={selectedType}
            placeholder={'Select operation type'}
            onChange={(selectedItem: any) => {
              setSelectedType(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
      </Row>
      {selectedType && selectedType.value !== 'remove' && (
        <Row>
          <Col lg={6} className="mb-4">
            <span className="label secondary-color">With text</span>
            <Input
              value={toText}
              placeholder="type text"
              onChange={e => {
                setToText(e.target.value);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
        </Row>
      )}
      {errorMessage && (
        <Row className="mb-4">
          <Col md={12}>
            <Alert type="error" className="create-workflow">
              {errorMessage}
            </Alert>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default React.memo(AiImageLabelingSettings);
