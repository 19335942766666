import { FC, useContext, useState } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import axios from 'axios';
import { ADD_CARD, SUBSCRIBTION, CREDIT_SUBSCRIBTION } from '../../../graphql/mutations';
import {
  GET_HEXOMATIC_USER,
  LEFT_PREMMIUMS_COUNT,
  LIST_CUSTOMER_CARD,
  LIST_PREMIUM_CREDITS,
  USER_QUERY,
} from '../../../graphql/queries';

import { useEffect } from 'react';

import Loader from 'shared-components/atom/loader';
import { IPayment } from '../types';
import { CardStateData } from 'shared-components/organizm/types';
import PaymentItem from 'shared-components/atom/paymentHeader';
import ChooseCard from 'shared-components/atom/paymentChooseCard';
import CreditCardForm from 'shared-components/organizm/credit-card-form-pro';
import PaymentSuccess from 'shared-components/atom/paymentSuccess';
import PaymentLoader from 'shared-components/atom/paymentLoader';
import PaymentFooter from 'shared-components/atom/paymentFooter';
import { ThemeContext } from 'contexts/theme-context';
import { setUserEmailInStroage, setFreshChatUserData } from '../../../helpers';
import { UserContext } from 'contexts/user-context';
import { HexomaticUserContext } from 'contexts/hexomatic-user';
import PremiumCreditOffer from './offer';
import Upgrade from './upgrade';
import ContactUs from './contact-us';
import { cardsFirstNumbers } from 'pages/profile/components/payment-methods';
import { closeIcon, paypalBtnIcon } from 'assets/svg-icons';
import './styles.scss';

const PaymentInfo: FC<IPayment> = ({
  name,
  selectedValue,
  price,
  packeges,
  premium_credit,
  setShowPopup,
  offer,
  id,
  interval,
  refetch,
  ltdPrice,
  ltdPriceSilver,
  ltdPriceGold,
  paypal_id,
  ltdPriceAjusted,
}) => {
  //----------------Context-----------------------
  const { user, setUser } = useContext(UserContext);
  const { theme } = useContext(ThemeContext);
  //-----------------states-----------------------
  let secondWindow: any;
  let int: any;
  const [showPaypalMessage, setShowPaypalMessage] = useState(false);
  const [el, setEl] = useState(null as any);
  const [stripe, setStripe] = useState<any>(null);
  const [cardId, setCardId] = useState('');
  const [customLoading, setCustumLoading] = useState(false);
  const [showPackeges, setShowPackeges] = useState(false);
  const [selectValue, setSelectValue] = useState(selectedValue);
  const [showMonthly, setShowMonthly] = useState(false);
  const [showAnnual, setShowAnnual] = useState(false);
  const [showOffer, setShowOffer] = useState(false);
  const [leftSeats, setLeftSeats] = useState(0);

  const [selectedPremium, setSelectedPremium] = useState(
    showOffer && !showMonthly
      ? { title: '1500', value: 1500, price: 999, id: '', paypal_id: '' }
      : {
          title: '',
          value: 0,
          price: 0,
          id: '',
          name: '',
          paypal_id: '',
        },
  );
  const [selectedPackege, setSelectedPackage] = useState({
    name,
    price,
    id,
    paypal_id,
    trial: name.includes('7-day free trial'),
  });

  const [defaultCardInfo, setDefaultCardInfo] = useState({
    name: '',
    number: '',
    expiry: '',
    cvc: '',
    focus: '',
  });
  const [cardInfo, setCardInfo] = useState({
    name: '',
    number: '',
    expiry: '',
    cvc: '',
    focus: '',
  });
  const [editCardInfo, setEditCardInfo] = useState(2);
  const [err, setErr] = useState('');
  const [success, setSuccess] = useState(false);
  const [paidWithPaypal, setPaidWithPaypal] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const { hexomaticUser, setHexomaticUser } = useContext(HexomaticUserContext);
  const [upgradeModal, setUpgradeModal] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const [premiumOptions, setPremiumOptions] = useState<any[]>([]);
  const [specialPremiumOptions, setSpecialPremiumOptions] = useState<any[]>([]);
  const [paypalToken, setPaypalToken] = useState('');
  const [pendingPayment, setPendingPayment] = useState(false);

  //queries
  //-----------------Queries----------------------------
  const {
    data: creditCards,
    loading: cardsLoading,
    refetch: refetchCardInfo,
  } = useQuery(LIST_CUSTOMER_CARD, {
    fetchPolicy: 'no-cache',
    variables: {
      settings: {
        limit: 100,
      },
    },
  });
  const [addPaymentMethod, { loading }] = useMutation(ADD_CARD);
  const [createSubscription, { loading: subscriptionLoading, error: subscribtionError }] = useMutation(SUBSCRIBTION);
  const [createCreditSubscription, { error, loading: premiumSubscriptionLoading }] = useMutation(CREDIT_SUBSCRIBTION);
  const [getPremiumCredits, { data }] = useLazyQuery(LIST_PREMIUM_CREDITS);
  const [loadUserInfo, { data: userData }] = useLazyQuery(USER_QUERY, {
    fetchPolicy: 'no-cache',
  });

  const [hexomaticUserInfo, { data: hexomaticUserData }] = useLazyQuery(GET_HEXOMATIC_USER, {
    fetchPolicy: 'no-cache',
  });
  //-------------------useEffects--------------------------
  //useEffects

  useEffect(() => {
    const pcPendingPayment = !!user?.pending_payment;
    const maticPedingPayment = !!hexomaticUser?.pending_payment;
    if ((pcPendingPayment && premium_credit) || (maticPedingPayment && !premium_credit)) {
      setPendingPayment(true);
    } else {
      setPendingPayment(false);
    }
  }, [user, hexomaticUser]);

  useEffect(() => {
    const hexomaticPlan = hexomaticUser?.pricing_package && hexomaticUser?.pricing_package !== 'FREE';
    const freePc = user?.premium_credits_pricing_package === 'FREE' || (user?.id && !user?.premium_credits_pricing_package);
      // user?.id &&
      // (user?.premium_credits_pricing_package === 'FREE' ||
      //   user?.premium_credits_pricing_package_interval === 'MONTHLY' ||
      //   user?.premium_credits_pricing_package === 'PC_15900' ||
      //   user?.premium_credits_pricing_package === 'PC_24900' ||
      //   user?.premium_credits_pricing_package === 'PC_49900' ||
      //   !user?.premium_credits_pricing_package);
    if (hexomaticPlan && freePc) {
      typeof offer === 'boolean' && setShowOffer(offer);
      // offer && !showMonthly && setSelectedPremium({ title: '1500', value: 1500, price: 999, id: '', paypal_id: '' });
    } else {
      setShowOffer(false);
    }
  }, [user, offer, hexomaticUser]);

  // const { data: seatsLeft } = useQuery(LEFT_PREMMIUMS_COUNT);

  // useEffect(() => {
  //   if (seatsLeft?.User?.getPromoLeftSeats?.seats) {
  //     const count = JSON.parse(seatsLeft.User.getPromoLeftSeats.seats);
  //     count && count.left_count > 0 && setLeftSeats(count.left_count);
  //   }
  // }, [seatsLeft]);

  useEffect(() => {
    if (success) {
      loadUserInfo();
      hexomaticUserInfo();
      refetch && refetch();
    }
  }, [success]);

  useEffect(() => {
    if (hexomaticUserData?.HexomaticUser?.getByLegacyId) {
      setHexomaticUser(hexomaticUserData.HexomaticUser.getByLegacyId);
    }
  }, [hexomaticUserData]);

  useEffect(() => {
    if (premium_credit && hexomaticUser.pricing_package === 'FREE') {
      setUpgradeModal(true);
    }
  }, [hexomaticUser]);

  useEffect(() => {
    if (
      premium_credit &&
      user &&
      (user.premium_credits_pricing_package === 'PC_264000' ||
        user.premium_credits_pricing_package === 'PC_26400' ||
        user.premium_credits_pricing_package === 'PC_49900')
    ) {
      setContactModal(true);
    }
  }, [user, premium_credit]);

  useEffect(() => {
    refetchCardInfo();
    //@ts-ignore
    window['Stripe'].setPublishableKey(
      window.location.hostname.includes('dash.')
        ? 'pk_live_8KwSrH69LnrJea7rrCH7Phn1'
        : 'pk_test_awe3SQdhRsX7VjRATH8uPbyW',
    );
    setStripe(
      //@ts-ignore
      window['Stripe'](
        window.location.hostname.includes('hexomatic.com')
          ? 'pk_live_8KwSrH69LnrJea7rrCH7Phn1'
          : 'pk_test_awe3SQdhRsX7VjRATH8uPbyW',
      ),
    );
  }, []);

  useEffect(() => {
    if (
      creditCards &&
      creditCards.Billing &&
      creditCards.Billing.listCustomerCards &&
      creditCards.Billing.listCustomerCards.cards
    ) {
      const defaultCard =
        creditCards.Billing.listCustomerCards.cards.length === 1
          ? creditCards.Billing.listCustomerCards.cards[0]
          : creditCards.Billing.listCustomerCards.cards.find((item: any) => item.isDefault === 'true');
      if (defaultCard) {
        setCardId(defaultCard.id);
        setDefaultCardInfo({
          cvc: '***',
          expiry: '**/**',
          focus: defaultCard.brand,
          name: '**** ****',
          number:
            (cardsFirstNumbers[defaultCard.brand.toUpperCase()]
              ? cardsFirstNumbers[defaultCard.brand.toUpperCase()]
              : '****') +
            '********' +
            defaultCard.lastDigits,
        });
        const paypal = !premium_credit
          ? hexomaticUser?.paypal_subscription_id
          : user?.premium_credits_paypal_subscription_id;
        if (!paypal) {
          setEditCardInfo(1);
        }
      }
    }
  }, [creditCards]);

  useEffect(() => {
    err !== 'Payment failed' && setErr('');
  }, [editCardInfo]);

  useEffect(() => {
    if (userData && userData.User && userData.User.get) {
      // @ts-ignore
      setFreshChatUserData(userData.User.get);
      setUser({ ...userData.User.get, loaded: true });
      userData.User.get.email && setUserEmailInStroage(userData.User.get.email);
    }
  }, [userData]);

  useEffect(() => {
    if (error || subscribtionError) {
      setCustumLoading(false);
      setErr(
        (error?.message && error.message.includes('Try later')) ||
          (subscribtionError?.message && subscribtionError.message.includes('Try later'))
          ? 'Try later'
          : 'Payment failed',
      );
      refetchCardInfo();
    } else setErr('');
  }, [error, subscribtionError]);

  useEffect(() => {
    if (err) {
      setCustumLoading(false);
      setTimeout(() => setErr(''), 10000);
    }
  });

  useEffect(() => {
    if (user?.premium_credits_pricing_package_interval === 'ANNUAL') {
      setShowAnnual(true);
      setShowMonthly(false);
    }
  }, [user]);

  useEffect(() => {
    if (data?.Billing?.listPremiumCredits?.premiumCredits) {
      const packeges: any[] = [];
      const packegesPaypal: any[] = [];
      data.Billing.listPremiumCredits.premiumCredits.map((item: any) => {
        if (
          !item.free &&
          ((!showOffer || showMonthly) && !showAnnual ? item.interval === 'MONTHLY' : item.interval === 'ANNUAL')
        ) {
          (item.productType === 'PAYPAL' ? packegesPaypal : packeges).push({
            title: item.limits.premium_credits_count,
            value: item.limits.premium_credits_count,
            price: item.amount,
            id: item.id,
            name: item.name,
            interval: item.interval === 'MONTHLY' ? 'monthly' : 'yearly',
          });
        }
      });
      packeges.sort(function (a, b) {
        return a.value - b.value;
      });
      packegesPaypal.sort(function (a, b) {
        return a.value - b.value;
      });
      const filteredPackages = packeges.filter((item: any) => item);
      const updatedPackages: any[] = [];
      const specialPlans: any[] = [];
      filteredPackages &&
        filteredPackages.map((item: any) => {
          const userPlanPrice = filteredPackages.find(
            (sub: any) => sub.name === user?.premium_credits_pricing_package,
          )?.price;
          const userPlan = filteredPackages.find(
            (sub: any) => sub.name === user?.premium_credits_pricing_package,
          )?.name;
          if (item.name.includes('PC_SPECIAL')) {
            if (
              userPlanPrice &&
              user?.premium_credits_pricing_package_interval === 'ANNUAL' &&
              userPlan.includes('SPECIAL')
                ? item?.price <= userPlanPrice
                : item?.price < userPlanPrice
            ) {
              return;
            } else if (
              userPlanPrice &&
              user &&
              user?.premium_credits_pricing_package_interval === 'MONTHLY' &&
              userPlan.includes('SPECIAL')
                ? item?.price <= userPlanPrice
                : item?.price < userPlanPrice
            ) {
              return;
            } else {
              const elem = packegesPaypal.find((el: { name: string }) => el.name === item.name);
              specialPlans.push({
                ...item,
                paypal_id: elem?.id || null,
              });
            }
            return;
          }
          if (
            item &&
            (item.name === 'MICROSOFT' ||
              item.name === 'ALIBABA' ||
              item.name === 'PC_3000' ||
              item.name === 'PC_1332' ||
              item.name === 'PC_499' ||
              item.name.includes('PC_SPECIAL'))
          )
            return;
          if (showAnnual) {
            if (user && user?.premium_credits_pricing_package_interval === 'ANNUAL' && item?.price <= userPlanPrice)
              return;
          } else {
            if (user && user?.premium_credits_pricing_package_interval === 'MONTHLY' && item?.price <= userPlanPrice)
              return;
          }
          const elem = packegesPaypal.find((el: { name: string }) => el.name === item.name);
          updatedPackages.push({
            ...item,
            newTitle: `${item.value}`,
            paypal_id: elem?.id || null,
          });
        });

      const selectedFromUrl = window.location.hash.replace('#premium-credits-', '');
      const listOfSpecialPlans = updatedPackages.filter(
        (item: any) => item.name === 'PC_15900' || item.name === 'PC_24900' || item.name === 'PC_49900',
      );
      const listOfRegularPlans =  updatedPackages.filter(
        (item: any) => item.name !== 'PC_15900' && item.name !== 'PC_24900' && item.name !== 'PC_49900',
      );
      offer && showOffer && !showMonthly
        ? setSelectedPremium(
            (selectedFromUrl === 'PC_15900' || selectedFromUrl === 'PC_24900' || selectedFromUrl === 'PC_49900') &&
            listOfSpecialPlans.find((item: any) => item.name === selectedFromUrl)
              ? listOfSpecialPlans.find((item: any) => item.name === selectedFromUrl)
              : listOfSpecialPlans[0],
          )
        : listOfRegularPlans[0] && setSelectedPremium(listOfRegularPlans[0]);
      setPremiumOptions(listOfRegularPlans);
      setSpecialPremiumOptions(listOfSpecialPlans);
    }
  }, [data, showMonthly, showOffer, offer, showAnnual]);

  useEffect(() => {
    if (premium_credit) getPremiumCredits();
  }, [premium_credit]);

  // useEffect(() => {
  //   if (!showPaypalMessage && localStorage.getItem('payment_success')) {
  //     setSuccess(true);
  //     localStorage.removeItem('payment_success');
  //     analytics();
  //   }
  // }, [showPaypalMessage]);

  //functions
  const analytics = () => {
    if (window.location.href.includes('hexomatic.com')) {
      //@ts-ignore
      window.dataLayer.push({
        event: 'purchase-button-click',
        purchaseOrderId: new Date().toISOString(),
        purchaseConversionValue: `${
          (premium_credit ? selectedPremium.price : selectedPackege.price || 0) *
          (premium_credit ? 1 : selectValue?.value || 1)
        }`,
      });
      //@ts-ignore
      window.dataLayer.push({
        event: 'eec.purchase',
        ecommerce: {
          currencyCode: 'USD',
          purchase: {
            actionField: {
              id: `${premium_credit ? selectedPremium.name : selectedPackege.name}_${new Date().toISOString()}`,
              revenue: `${
                (premium_credit ? selectedPremium.price : selectedPackege.price || 0) *
                (premium_credit ? selectedPremium.value : selectValue?.value || 1)
              }`,
            },
            products: [
              {
                item_id: `${premium_credit ? selectedPremium.name : selectedPackege.name}_${new Date().toISOString()}`,
                item_name: `${premium_credit ? selectedPremium.name : selectedPackege.name}_${interval?.toUpperCase()}`,
                price: `${premium_credit ? selectedPremium.price : selectedPackege.price || 0}`,
                quantity: premium_credit ? 1 : selectValue?.value || 1,
              },
            ],
          },
        },
      });
    }
  };

  const handleUpgrade = async (card: string) => {
    setCustumLoading(true);
    setSuccess(false);
    setPaidWithPaypal(false);
    setErr('');
    setFieldError(false);
    if (premium_credit) {
      const res = await createCreditSubscription({
        variables: {
          settings: {
            premiumCreditsId: selectedPremium.id,
            cardId: card,
            payment_type: 'STRIPE',
          },
        },
      });
      if (
        res &&
        res.data &&
        res.data.BillingOps &&
        res.data.BillingOps.updateMainUserPremiumCreditsPackage &&
        res.data.BillingOps.updateMainUserPremiumCreditsPackage.clientSecret
      ) {
        //@ts-ignore
        const { paymentIntent, error } = await stripe.confirmCardPayment(
          res.data.BillingOps.updateMainUserPremiumCreditsPackage.clientSecret,
          {
            payment_method: res.data.BillingOps.updateMainUserPremiumCreditsPackage.card,
          },
        );
        if (paymentIntent && paymentIntent.status === 'succeeded') {
          setSuccess(true);
          setPaidWithPaypal(false);
          analytics();
        } else {
          setErr(error.includes('Try later') ? 'Try later' : 'Payment failed');
          refetchCardInfo();
        }
        setCustumLoading(false);
        return;
      }
      if (
        res &&
        res.data &&
        res.data.BillingOps &&
        res.data.BillingOps.updateMainUserPremiumCreditsPackage &&
        res.data.BillingOps.updateMainUserPremiumCreditsPackage.error_code
      ) {
        setErr(
          res.data.BillingOps.updateMainUserPremiumCreditsPackage.error_code === 'ALREADY_UPDATED'
            ? 'Already subscribed.'
            : 'Payment failed',
        );
        refetchCardInfo();
      } else {
        setSuccess(true);
        setPaidWithPaypal(false);
        if (!window.location.host.includes('localhost') && !window.location.host.includes('37.186.119.181')) {
          analytics();
        }
      }
    } else {
      const res = await createSubscription({
        variables: {
          settings: {
            payment_type: 'STRIPE',
            productId: selectedPackege.id,
            cardId: card,
            credits_pricing_package_count:
              selectedPackege?.name?.toLocaleLowerCase() === 'gold' ? selectValue?.value : 1,
            startTrial: !!selectedPackege.name.includes('trial'),
          },
        },
      });
      if (
        res &&
        res.data &&
        res.data.HexomaticUserOps &&
        res.data.HexomaticUserOps.updateUserHexomaticPackage &&
        res.data.HexomaticUserOps.updateUserHexomaticPackage.clientSecret
      ) {
        //@ts-ignore
        const { paymentIntent, error } = await stripe.confirmCardPayment(
          res.data.HexomaticUserOps.updateUserHexomaticPackage.clientSecret,
          {
            payment_method: res.data.HexomaticUserOps.updateUserHexomaticPackage.card,
          },
        );
        if (paymentIntent && paymentIntent.status === 'succeeded') {
          setSuccess(true);
          setPaidWithPaypal(false);
          analytics();
        } else {
          setErr(error.includes('Try later') ? 'Try later' : 'Payment failed');
          refetchCardInfo();
        }
        setCustumLoading(false);
        return;
      }
      if (
        res &&
        res.data &&
        res.data.HexomaticUserOps &&
        res.data.HexomaticUserOps.updateUserHexomaticPackage &&
        res.data.HexomaticUserOps.updateUserHexomaticPackage.error_code
      ) {
        res &&
        res.data &&
        res.data.HexomaticUserOps &&
        res.data.HexomaticUserOps.updateUserHexomaticPackage &&
        res.data.HexomaticUserOps.updateUserHexomaticPackage.error_code.includes('Try later')
          ? setErr('Try later')
          : setErr('Payment failed');
        refetchCardInfo();
      } else {
        setSuccess(true);
        setPaidWithPaypal(false);
        if (!window.location.host.includes('localhost') && !window.location.host.includes('37.186.119.181')) {
          analytics();
        }
      }
    }

    setCustumLoading(false);
  };
  const handlePayment = async (cardData: CardStateData) => {
    const expiry = cardData.expiry.split('/');
    try {
      //@ts-ignore
      window.Stripe.card.createToken(
        {
          number: cardData.number,
          exp_month: +expiry[0],
          exp_year: +expiry[1].slice(0, 2),
          cvc: cardData.cvc,
        },
        //@ts-ignore
        (status, response) => {
          if (status === 200) {
            if (response && response.id) addCard(cardData, response.id);
            else setErr('Invalid parameters.');
          } else setErr('Invalid parameters.');
        },
      );
    } catch {
      setErr('Invalid card');
    }
  };

  const addCard = async (cardData: CardStateData, id: string) => {
    const res = await addPaymentMethod({
      variables: {
        settings: {
          email: user && user.email,
          name: cardData.name,
          source: id,
        },
      },
    });
    const errors =
      res && res.data && res.data.BillingOps && res.data.BillingOps.addCard
        ? res.data.BillingOps.addCard.error_code
        : null;
    if (errors) {
      setErr(
        errors.includes('limit_reached')
          ? 'Attached cards limit reached'
          : errors.includes('Try later')
          ? 'Try later'
          : 'Invalid card',
      );
    }
    if (res && res.data && res.data.BillingOps && res.data.BillingOps.addCard && res.data.BillingOps.addCard.cardId) {
      await handleUpgrade(res.data.BillingOps.addCard.cardId);
      setCustumLoading(false);
    }
  };

  const handlePaymentFormSubmit = async () => {
    setErr('');
    const obj = { ...cardInfo, focus: 'name' };

    if (Object.values(obj).filter(item => !item).length > 0) {
      setFieldError(true);
      return;
    }
    setFieldError(false);
    setCustumLoading(true);
    handlePayment(obj);
  };

  const showPaypal = (secondWindow: any, url: string) => {
    // const y = (window.outerHeight - 470) / 2;
    // const x = (window.outerWidth - 400) / 2;
    // secondWindow = window.open(url, 'secondary', `width=400,height=470,top=${y},left=${x}`);
    secondWindow && (secondWindow.location = url);
    setEl(secondWindow);
    setShowPaypalMessage(true);
    int = setInterval(() => {
      if (!secondWindow || secondWindow.closed) {
        clearInterval(int);
        setShowPaypalMessage(false);
        setEl(null);
      }
    });
  };

  const upgradeByPaypal = async () => {
    const y = (window.outerHeight - 470) / 2;
    const x = (window.outerHeight - 400) / 2;
    const secWindow = window.open('', 'secondary', `width=400,height=470,top=${y},left=${x}`);
    if (premium_credit) {
      const res = await createCreditSubscription({
        variables: {
          settings: {
            premiumCreditsId: selectedPremium.paypal_id,
            payment_type: 'PAYPAL',
            return_url: window.location.href + '?premium_credit',
          },
        },
      });
      if (
        res &&
        res.data &&
        res.data.BillingOps &&
        res.data.BillingOps.updateMainUserPremiumCreditsPackage &&
        res.data.BillingOps.updateMainUserPremiumCreditsPackage.approveUrl
      ) {
        showPaypal(secWindow, res.data.BillingOps.updateMainUserPremiumCreditsPackage.approveUrl);
        const token = res.data.BillingOps.updateMainUserPremiumCreditsPackage.approveUrl;
        token && setPaypalToken(token.slice(token.indexOf('token=') + 6));
      } else {
        secWindow?.close();
        return;
      }
      return;
    }
    const { data } = await createSubscription({
      variables: {
        settings: {
          productId: selectedPackege.paypal_id,
          payment_type: 'PAYPAL',
          return_url: window.location.href,
          credits_pricing_package_count: selectedPackege?.name?.toLocaleLowerCase() === 'gold' ? selectValue?.value : 1,
          startTrial: !!selectedPackege.name.includes('trial'),
        },
      },
    });
    if (
      data &&
      data.HexomaticUserOps &&
      data.HexomaticUserOps.updateUserHexomaticPackage &&
      data.HexomaticUserOps.updateUserHexomaticPackage.approveUrl
    ) {
      showPaypal(secWindow, data.HexomaticUserOps.updateUserHexomaticPackage.approveUrl);
      const token = data.HexomaticUserOps.updateUserHexomaticPackage.approveUrl;
      token && setPaypalToken(token.slice(token.indexOf('token=') + 6));
    } else {
      secWindow?.close();
    }
    return;
  };

  const handleCloseModal = () => {
    setShowPopup(false);
  };

  const handleNotShowPackages = () => {
    showPackeges && setShowPackeges(false);
  };
  const handleChangeShowPackages = () => {
    setShowPackeges(!showPackeges);
  };
  const eventStop = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onRadioChange = (item: any) => {
    item.name !== selectedPackege.name &&
      !item.disabled &&
      setSelectedPackage({
        name: item.name,
        price: item.price,
        id: item.id,
        paypal_id: item.paypal_id,
        trial: item.trial,
      });
    setShowPackeges(false);
  };

  const handleConfirm = () =>
    editCardInfo === 3
      ? upgradeByPaypal()
      : defaultCardInfo.name && editCardInfo === 1
      ? handleUpgrade(cardId)
      : handlePaymentFormSubmit();

  const handleShowSecondWindow = () => el && el.focus();
  const handleCloseSecondWindow = () => el && el.close();

  useEffect(() => {
    const paypal = !premium_credit
      ? !hexomaticUser?.stripe_subscription_id
      : !user?.premium_credits_stripe_subscription_id;

    const stripe = !premium_credit
      ? !hexomaticUser?.paypal_subscription_id
      : !user?.premium_credits_paypal_subscription_id;

    if (paypal && !stripe) {
      setEditCardInfo(3);
    }
  }, [premium_credit, hexomaticUser, user]);

  const getSuccessMessage = async () => {
    const res = await axios('https://api.hexomatic.com/paypal/check', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        token: !paypalToken.includes('BA-') ? paypalToken : undefined,
        ba_token: paypalToken.includes('BA-') ? paypalToken : undefined,
      },
    });
    if (res && res.data && res.data.success) {
      setSuccess(true);
      setPaidWithPaypal(true);
      analytics();
    }
  };

  useEffect(() => {
    if (!showPaypalMessage && paypalToken) {
      getSuccessMessage();
    }
  }, [showPaypalMessage, paypalToken]);

  return (
    <>
      {!showPaypalMessage ? (
        <div
          className={`payment ${showOffer && !showMonthly ? 'payment-offer' : ''} ${
            (upgradeModal || contactModal) && !success ? 'upgrade-payment' : ''
          } ${theme === 'light' ? 'light' : 'dark'}`}
          style={{ minHeight: success || customLoading || loading ? '350px' : '' }}
        >
          <span onClick={handleCloseModal} className="modal-close">
            {
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.88111 4.00011L7.8722 1.00889C7.95447 0.926545 7.99987 0.816691 8 0.699553C8 0.58235 7.9546 0.472366 7.8722 0.390155L7.61008 0.128106C7.52767 0.0455695 7.41782 0.000366211 7.30055 0.000366211C7.18348 0.000366211 7.07363 0.0455695 6.99122 0.128106L4.00013 3.11913L1.00891 0.128106C0.926634 0.0455695 0.816715 0.000366211 0.699512 0.000366211C0.582439 0.000366211 0.47252 0.0455695 0.390244 0.128106L0.128 0.390155C-0.0426667 0.560821 -0.0426667 0.838415 0.128 1.00889L3.11915 4.00011L0.128 6.9912C0.0456585 7.07367 0.000325203 7.18352 0.000325203 7.30066C0.000325203 7.4178 0.0456585 7.52765 0.128 7.61006L0.390179 7.87211C0.472455 7.95458 0.582439 7.99985 0.699447 7.99985C0.81665 7.99985 0.926569 7.95458 1.00885 7.87211L4.00006 4.88102L6.99115 7.87211C7.07356 7.95458 7.18341 7.99985 7.30049 7.99985H7.30062C7.41776 7.99985 7.52761 7.95458 7.61002 7.87211L7.87213 7.61006C7.95441 7.52772 7.9998 7.4178 7.9998 7.30066C7.9998 7.18352 7.95441 7.07367 7.87213 6.99126L4.88111 4.00011Z"
                  fill="#8C979D"
                />
              </svg>
            }
          </span>
          {!success && contactModal ? (
            <ContactUs />
          ) : !customLoading && !loading ? (
            upgradeModal ? (
              <Upgrade
                cancel={() => {
                  setUpgradeModal(false);
                  setShowPopup(false);
                }}
              />
            ) : !success ? (
              <>
                {showOffer && !showMonthly ? (
                  // <PremiumCreditOffer
                  //   selectedPremium={selectedPremium}
                  //   setSelectedPremium={setSelectedPremium}
                  //   showMonthly={showMonthly}
                  //   setShowMonthly={(val: boolean) => setShowMonthly(val)}
                  //   premiumOptions={specialPremiumOptions}
                  //   leftSeats={leftSeats}
                  // />
                  <PremiumCreditOffer
                    selectedPremium={selectedPremium}
                    setSelectedPremium={setSelectedPremium}
                    premiumOptions={premiumOptions}
                    showMonthly={showMonthly}
                    setShowMonthly={(val: boolean) => setShowMonthly(val)}
                  />
                ) : (
                  <div>
                    <PaymentItem
                      name={selectedPackege.name}
                      selectedValue={selectValue}
                      price={selectedPackege.price}
                      showPackeges={showPackeges}
                      onChange={handleChangeShowPackages}
                      selected
                      checked
                      handelSelectChange={val => (premium_credit ? setSelectedPremium(val) : setSelectValue(val))}
                      user={hexomaticUser}
                      premium_credit={premium_credit}
                      selectedPremium={selectedPremium}
                      premiumOptions={premiumOptions}
                      setShowMonthly={(val: boolean) => setShowMonthly(val)}
                      showMonthly={showMonthly}
                      ltdPrice={ltdPrice}
                      ltdPriceSilver={ltdPriceSilver}
                      ltdPriceGold={ltdPriceGold}
                      ltdPriceAjusted={ltdPriceAjusted}
                      offer={showOffer}
                      seatsLeft={leftSeats}
                      theme={theme}
                    />
                    {showPackeges && (
                      <div className="packeges-list" onClick={eventStop}>
                        {packeges &&
                          packeges.length > 0 &&
                          packeges.map(
                            item =>
                              !item.disabled && (
                                <PaymentItem
                                  disabled={item.disabled}
                                  name={item.name}
                                  selectedValue={selectValue}
                                  price={item.price}
                                  showPackeges
                                  checked={item.name.toLocaleLowerCase() === selectedPackege.name.toLocaleLowerCase()}
                                  handelSelectChange={val =>
                                    premium_credit ? setSelectedPremium(val) : setSelectValue(val)
                                  }
                                  onRadioChange={() => onRadioChange(item)}
                                  premiumOptions={premiumOptions}
                                  ltdPrice={ltdPrice}
                                  ltdPriceSilver={ltdPriceSilver}
                                  ltdPriceGold={ltdPriceGold}
                                  ltdPriceAjusted={ltdPriceAjusted}
                                  seatsLeft={leftSeats}
                                  theme={theme}
                                />
                              ),
                          )}
                      </div>
                    )}
                  </div>
                )}
                {cardsLoading ? (
                  <div className="d-flex align-items-center mt-5 pt-5 justify-content-center">
                    <Loader light={theme === 'light'} />
                  </div>
                ) : (
                  <div onClick={handleNotShowPackages}>
                    {!cardsLoading && (
                      <ChooseCard
                        onChange={setEditCardInfo}
                        editCardInfo={editCardInfo}
                        defaultCard={!!(defaultCardInfo && defaultCardInfo.name)}
                        paypal={
                          !premium_credit
                            ? !hexomaticUser?.stripe_subscription_id
                            : !user?.premium_credits_stripe_subscription_id
                        }
                        stripe={
                          !premium_credit
                            ? !hexomaticUser?.paypal_subscription_id
                            : !user?.premium_credits_paypal_subscription_id
                        }
                      />
                    )}
                    {!cardsLoading && (
                      <div className=" pricing-credit-card-form">
                        {editCardInfo !== 3 ? (
                          <CreditCardForm
                            cardInfo={defaultCardInfo.name && editCardInfo === 1 ? defaultCardInfo : cardInfo}
                            loading={loading || cardsLoading}
                            error={err}
                            changeData={setCardInfo}
                            disabled={!defaultCardInfo.name ? false : editCardInfo === 1}
                            fieldError={fieldError}
                            hideCards
                          />
                        ) : (
                          <div className="w-75 text-center mx-auto my-5 py-3 pay_with_paypal">
                            Click <strong>Pay with PayPal</strong> to log into your account and confirm your purchase.
                            You'll be redirected to this page to finish up.
                          </div>
                        )}
                        <PaymentFooter
                          confirm={handleConfirm}
                          paypal={editCardInfo === 3}
                          loading={subscriptionLoading || premiumSubscriptionLoading}
                          premium_credit={premium_credit}
                          pendingPayment={pendingPayment}
                        />
                      </div>
                    )}
                  </div>
                )}
              </>
            ) : (
              <PaymentSuccess setShowPopup={setShowPopup} paypal={paidWithPaypal} trial={selectedPackege.trial} />
            )
          ) : (
            <PaymentLoader />
          )}
        </div>
      ) : (
        <div className="show-paypal-info">
          <span onClick={handleCloseSecondWindow} className="close-icon-paypal">
            {closeIcon}
          </span>
          <div className="paypal-wrapper">
            <div className="pb-2">{paypalBtnIcon}</div>
            Don’t see the secure PayPal browser? We’ll help you re-launch the window to complete your purchase
            <div className="cursor-pointer pt-2" onClick={handleShowSecondWindow}>
              <strong>Click to Continue</strong>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentInfo;
