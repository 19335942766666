import { closeIcon } from 'assets/svg-icons';
import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import CustomMultiSelect from '../multiselect';
import Select from 'shared-components/atom/select';

interface FilterSelect {
  index: number;
  filterOption: any;
  filteroptions: any;
  setSelectedcategory: (val: any, index: number) => void;
  setSelectedSubcategory: (val: any, index: number, multi?: boolean) => void;
  onDelete: (index: number) => void;
  selectedFiltrations: any;
}
const FilterSelect: FC<FilterSelect> = ({
  index,
  filterOption,
  filteroptions,
  setSelectedcategory,
  setSelectedSubcategory,
  onDelete,
  selectedFiltrations,
}) => {
  return (
    <Row className="filter-option-container">
      <Col md={filterOption?.subCategories ? 4 : 12} className="d-flex">
        <Select
          options={filteroptions.filter(
            (item: any) => !selectedFiltrations.find((sub: any) => sub.label === item.label),
          )}
          value={filterOption}
          onChange={(item: any) => {
            setSelectedcategory(item, index);
          }}
          className={filterOption && !filterOption?.subCategories ? 'filter-select' : 'w-100'}
        />
        {filterOption && !filterOption?.subCategories && (
          <span onClick={() => onDelete(index)} className="close-icon">
            {closeIcon}
          </span>
        )}
      </Col>
      {filterOption?.subCategories && (
        <Col md={8} className="pl-0 d-flex">
          {filterOption.label === 'Automations' || filterOption.label === 'Tags' ? (
            <CustomMultiSelect
              options={filterOption.subCategories}
              value={filterOption.selectedSubcategories ? filterOption.selectedSubcategories : []}
              onChange={(item: any) => setSelectedSubcategory(item, index, true)}
              labelledBy="Select"
            />
          ) : (
            <Select
              options={filterOption.subCategories}
              value={filterOption?.selectedSubcategories ? filterOption.selectedSubcategories[0] : ''}
              onChange={(item: any) => {
                setSelectedSubcategory(item, index);
              }}
              className="filter-select"
            />
          )}

          <span onClick={() => onDelete(index)} className="close-icon">
            {closeIcon}
          </span>
        </Col>
      )}
    </Row>
  );
};

export default FilterSelect;
