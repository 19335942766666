import { FC } from 'react';
import { Card } from 'react-bootstrap';
import './styles.scss';

const SortableCard: FC<any> = ({ children, className }) => (
  <Card className={`pb-0 pt-2 sortable-card ${className ? className : ''}`}>
    <div className="card-wrapper mr-2">{children}</div>
  </Card>
);

export default SortableCard;
