import React, { FC, useEffect, useState, useContext, ReactElement, Children } from 'react';
import { createPortal } from 'react-dom';
import Button from '../../atom/button';
import Loader from '../../atom/loader';
import { removeCircleIcon, checkedCircleIcon, closeIcon, warningCircleIcon } from '../../../assets/svg-icons';
import { ThemeContext } from '../../../contexts/theme-context';
import { closePopupOnEsc } from 'helpers';

const body = document.getElementsByTagName('body')[0];
const el = document.createElement('div');
el.setAttribute('id', 'popup');

interface ModalProps {
  isModalOpen: boolean;
  text?: string | ReactElement;
  title?: string | ReactElement;
  actionBtnText: string | ReactElement;
  escButtonClick: () => void;
  cancelBtnText?: string;
  cancelBtnText2?: string;
  actionBtnColor: string;
  cancelBtnColor?: string;
  cancelBtnColor2?: string;
  onActionClick: () => void;
  onCancelClick?: () => void;
  onCancelClick2?: () => void;
  onCloseModal?: () => void;
  loading?: boolean;
  headerIcon?: string;
  elementContent?: any;
  onlyBtns?: boolean;
  className?: string;
  children?: any;
  parentClass?: string;
  icon?: any;
  cancelBtnDisabled?: boolean;
  cancelBtnDisabled2?: boolean;
  actionBtnDisabled?: boolean;
  topInfo?: any;
  dark?: boolean;
  wrapButtons?: boolean;
}

const ModalComponent: FC<ModalProps> = ({
  isModalOpen,
  text,
  title,
  escButtonClick,
  onActionClick,
  onCancelClick,
  onCancelClick2,
  actionBtnText,
  cancelBtnText,
  cancelBtnText2,
  actionBtnColor,
  cancelBtnColor,
  onCloseModal,
  loading,
  elementContent,
  onlyBtns,
  className,
  children,
  parentClass,
  icon,
  cancelBtnDisabled,
  cancelBtnDisabled2,
  actionBtnDisabled,
  topInfo,
  dark,
  wrapButtons,
}) => {
  const [element, setElement] = useState<HTMLDivElement>(el);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    if (!isModalOpen) return;

    body.appendChild(element);
    document.body.style.overflow = 'hidden';
    return () => {
      const getEl = document.getElementById('popup');
      getEl && body.removeChild(getEl);
      document.body.style.overflow = 'unset';
    };
  }, [isModalOpen]);

  const getLength = (text: string) => {
    return text.split(' ').find((item: any) => item.length > 30);
  };

  //Close Popup on Esc button click
   useEffect(() => {
    isModalOpen && closePopupOnEsc(() => escButtonClick());
  }, [isModalOpen]);

  return isModalOpen
    ? createPortal(
        <>
          <div className={`overlay theme-${dark ? 'dark' : theme} ${parentClass ? parentClass : ''}`}>
            <div
              className={`modal-dialog--default custom-alert-block theme-${theme} ${parentClass ? parentClass : ''}`}
            >
              <div className="modal-content">
                {loading ? (
                  <div className="modal__body">
                    <Loader light={theme === 'light'} />
                  </div>
                ) : (
                  <>
                    <div className="modal__body">
                      {onCloseModal && (
                        <div className="panel__btns">
                          <button className="panel__btn" onClick={onCloseModal} type="button">
                            {closeIcon}
                          </button>
                        </div>
                      )}
                      {topInfo && topInfo}
                      {!onlyBtns &&
                        (icon ? (
                          <span className="modal-head-icon">{icon}</span>
                        ) : actionBtnColor === 'success' ? (
                          <span className="modal-head-icon">{checkedCircleIcon}</span>
                        ) : actionBtnColor === 'warning' ? (
                          <span className="modal-head-icon">{warningCircleIcon}</span>
                        ) : actionBtnColor === 'none' ? (
                          ''
                        ) : (
                          <span className="modal-head-icon">{removeCircleIcon}</span>
                        ))}
                      {title && <span className="text title">{title}</span>}
                      {elementContent ? (
                        elementContent
                      ) : (
                        <span
                          className={`text ${typeof text === 'string' && getLength(text) ? 'break' : ''} ${
                            className ? className : ''
                          }`}
                        >
                          {text}
                        </span>
                      )}
                    </div>
                    {onlyBtns ? (
                      <div className={`modal__footer ${cancelBtnText ? 'cancel-is' : ''} d-block mt-0`}>
                        {actionBtnText && (
                          <div className="mb-2">
                            <Button
                              name={actionBtnText}
                              onClick={onActionClick}
                              className="m-auto modal-long-btn"
                              disabled={actionBtnDisabled}
                            />
                          </div>
                        )}
                        {cancelBtnText2 && (
                          <div className='mb-2'>
                            <Button
                              name={cancelBtnText2}
                              outline
                              onClick={onCancelClick2}
                              className="m-auto modal-long-btn"
                              disabled={cancelBtnDisabled2}
                            />
                          </div>
                        )}

                        {cancelBtnText && (
                          <div>
                            <Button
                              name={cancelBtnText}
                              outline
                              onClick={onCancelClick}
                              className="m-auto modal-long-btn"
                              disabled={cancelBtnDisabled}
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className={`modal__footer ${cancelBtnText ? 'cancel-is' : ''} ${wrapButtons ? 'd-block d-xl-flex' : ''}`}>
                        {cancelBtnText && (
                          <div className={`${wrapButtons ? 'mb-2 mr-0 mr-xl-2 mb-xl-0 ' : 'mr-2'}`}>
                            <Button name={cancelBtnText} outline onClick={onCancelClick} disabled={cancelBtnDisabled} className={`${wrapButtons ? 'mx-auto mx-0' : ''}`} />
                          </div>
                        )}
                        {cancelBtnText2 && (
                          <div className="cancel-btn-wrapper mt-2">
                            <Button name={cancelBtnText2} outline onClick={onCancelClick2} disabled={cancelBtnDisabled2} />
                          </div>
                        )}
                        {actionBtnText && (
                          <div>
                            <Button name={actionBtnText} onClick={onActionClick} disabled={actionBtnDisabled} className={`${wrapButtons ? 'mx-auto mx-0' : ''}`} />
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
                {children && <div className="mt-2">{children}</div>}
              </div>
            </div>
          </div>
        </>,
        element,
      )
    : null;
};

export default React.memo(ModalComponent);
