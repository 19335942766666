import { useEffect, useState } from 'react';
import Tooltip from 'shared-components/atom/tooltip';
import './styles.scss';

const ThreePoints = ({ itemId, localTags, tagsShowCount, showAllTags, theme, item, cardView }: any) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    //the OverlayTrigger has bug and does`nt work properly becose i hiden and show element for normal working
    setShow(false);
    return () => setShow(false);
  }, [localTags]);

  useEffect(() => {
    if (!show) {
      setShow(true);
    }
  }, [show]);

  return (
    <>
      {show && (
        <Tooltip
          placement="bottom"
          text={showAllTags(localTags.length === 1 ? localTags : localTags.slice(tagsShowCount))}
          className={`${theme === 'light' ? 'tags-light-bg' : 'tags-dark-bg'} ${cardView ? 'card-tooltip' : ''}`}
          notRelative={cardView}
          //transition={false}
          // delay={{ show: 200, hide: 0 }}
          // trigger={['click']}
        >
          <div
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
            id={`tag-${itemId}`}
            key={item.name}
            className="tag three-points-container p-2"
            style={{ color: item.color }}
          >
            <div className="three-points">
              <span>+ {localTags.length === 1 ? 1 : (localTags.length - tagsShowCount)}</span>
            </div>
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default ThreePoints;
