import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAutomationGlobalMethods } from '../../hooks';
import DataList from 'shared-components/atom/dataList';
import Input from 'shared-components/atom/input';
import Select from 'shared-components/atom/select';
// import { proxyList } from 'constants/index';
import { infoIcon } from 'assets/svg-icons';
// import Checkbox from 'shared-components/atom/checkbox';

// import { residential_geo_proxy_country_list } from 'constants/countries/residential-geo-proxy-countries';

import './styles.scss';

const outputs = ['_discover_profile_source', '_discover_profile_url', '_discover_profile_category'];

const DiscoverProfile: FC<any> = ({
  automationSendSettings,
  selectedRecipes,
  automationIndex,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);
  const [clicked, setClicked] = useState(false);
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [inputText, setInputText] = useState('');
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);
  // const [selectedProxy, setSelectedProxy] = useState({ label: 'Data Center', value: '' });
  // const [residentialGEOCountry, setResidentialGEOCountry] = useState('');
  // const [isAdult, setIsAdult] = useState<boolean>(false);

  const {
    getPreviousAutomationOutputOrPreviousRecipKeys,
    liftingSettings,
    restoreSelectedSource,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    getDynamicAndIntegrationsSettings,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (fieldList?.length === 0) {
      if (!inputText) {
        return false;
      }
    } else {
      // if (!selectedSource || (selectedProxy.value === 'premium' && !residentialGEOCountry)) {
      //   return false;
      // }
      if (!selectedSource) {
        return false;
      }
    }
    return true;
  };

  //getting previous outputs
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (automationSendSettings) {
      const currentAutomation = automationSendSettings.find((automation: any, index: any) => index === automationIndex);
      const { dynamic_settings } = currentAutomation;
      if (dynamic_settings) {
        // const { isAdult, proxy } = dynamic_settings?.keys[0]?.settings;
        // setIsAdult(isAdult);
        // if (proxy) {
        //   const selectedProxy = proxyList.find((val: any) => val.value === proxy.type);

        //   const selectedCountry = residential_geo_proxy_country_list.find(
        //     (country: any) => country.value === proxy.country_code,
        //   );
        //   selectedProxy && setSelectedProxy(selectedProxy); // data center // reasidential
        //   selectedCountry && setResidentialGEOCountry(selectedCountry.label);
        // }
        if (fieldList.length === 0) {
          const input = dynamic_settings.keys[0].inputValue;
          setInputText(input);
        } else {
          const key = dynamic_settings.keys[0].key;
          restoreSelectedSource({ key: key, fieldList: fieldList, setSelectedSource: setSelectedSource });
        }
      }
    }
  }, [fieldList]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...outputs],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);

  //lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: {
        keys: [
          {
            inputValue: fieldList.length === 0 && automationIndex === 0 ? inputText.trim() : null,
            key: fieldList.length && selectedSource ? selectedSource.value : null,
            settings: null,
            // settings: {
            //   proxy: {
            //     type: selectedProxy.value, // data center // reasidential
            //     country_code:
            //       residential_geo_proxy_country_list.find(item => item.label === residentialGEOCountry)?.value || '',
            //   },
            //   isAdult: isAdult,
            // },
          },
        ],
      },
      outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...outputs],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [
    inputText,
    selectedSource,
    automationIndex,
    // selectedProxy,
    //  isAdult,
    // residentialGEOCountry,
  ]);
  // const handleChangeResidentialGEOCountry = (text: string) => {
  //   // setError('');
  //   setResidentialGEOCountry(text);
  //   !clicked && setClicked(true);
  //   setIsDirty();
  // };
  return (
    <div className="discover-profile">
      <Row className="mb-4">
        {fieldList.length > 0 || automationIndex > 0 ? (
          <Col lg={6}>
            <span className="label secondary-color">Source</span>
            <Select
              options={fieldList}
              value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
              placeholder={'Select source'}
              onChange={(selectedItem: any) => {
                setSelectedSource(selectedItem);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
        ) : (
          <Col lg={6}>
            <span className="label secondary-color">Email or Username</span>
            <Input
              value={inputText}
              onChange={e => {
                setInputText(e.target.value);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
        )}
        {/* <Col lg={6} className="checkbox-wrapper mt-0">
          <div className="item-block mt-4">
            <Checkbox
              checked={isAdult}
              labelRight="Adult platforms"
              onChange={() => {
                setIsAdult(!isAdult);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </div>
        </Col> */}
      </Row>
      {/* 
      <Row className="mb-4">
        <Col lg={6} className="mb-2">
          <span className="label secondary-color">Proxy mode</span>
          <Select
            options={proxyList}
            value={selectedProxy}
            placeholder={'proxy mode'}
            onChange={(selectedItem: any) => {
              setSelectedProxy(selectedItem); // data center // reasidential
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
        {selectedProxy.value === 'premium' && (
          <Col lg={6} className="mb-2 country">
            <span className="label secondary-color">Country</span>
            <DataList
              list={residential_geo_proxy_country_list}
              value={residentialGEOCountry}
              placeholder="Select country"
              onChange={(text: string) => {
                handleChangeResidentialGEOCountry(text);
              }}
            />
          </Col>
        )}
        {selectedProxy.value && (
          <Col lg={12}>
            <span className="info-icon">{infoIcon}</span>
            <span className="info-message ">
              This proxy consumes {selectedProxy.value === 'premium' ? '0.12' : '0.1'} premium credits per MB
            </span>
          </Col>
        )}
      </Row> */}
    </div>
  );
};

export default React.memo(DiscoverProfile);
