import { FC, useEffect, useContext, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import jwt_decode from 'jwt-decode';
// import { useMediaQuery } from 'react-responsive';

import {
  getRefreshToken,
  getUserSignedUpViaGoogle,
  // setAccessToken,
  setUserEmailInStroage,
  setFreshChatUserData,
  setUserSignedUpViaGoogle,
  getRawCookie,
  setUserToken,
  getUserToken,
  extensionIds,
} from '../helpers';
import {
  USER_QUERY,
  GET_HEXOMATIC_USER,
  QUESTIEONNAIRE_CANCEL,
  GET_QUESTIEONNAIRE,
  GET_ACCESS_TOKEN,
  LEFT_PREMMIUMS_COUNT,
  USER_PLANS_QUERY,
  HEXOFY_USER,
} from 'graphql/queries';
import { Modal } from 'react-bootstrap';
import ModalCustom from 'shared-components/molecule/modal';
import { UserContext } from '../contexts/user-context';
import { HexomaticUserContext } from '../contexts/hexomatic-user';
import { ThemeContext } from '../contexts/theme-context';

import { AuthenticationProps } from './types';

import Button from 'shared-components/atom/button';
import Counter from 'shared-components/molecule/counter';
import CustomModal from 'shared-components/molecule/customModal';
import Payment from 'shared-components/molecule/payment';
import PersonalizeExperience from 'shared-components/molecule/personalize-experience-modal';

// import appsumo from 'assets/images/appsumo-popup.svg';
// import appsumoMobile from 'assets/images/appsumo-popup-mobile.svg';
// import lastCallLtd from 'assets/april-promo/popup-desktop.svg';
// import lastCallLtdMobile from 'assets/april-promo/popup-mobile.svg';

import premiumsPromo from 'assets/annual-pc-promo/popup-desktop.svg';
import premiumsPromoMobile from 'assets/annual-pc-promo/popup-mobile.svg';

// import premiumsPromo from 'assets/limited-time-ltd/popup-desktop.svg';
// import premiumsPromoMobile from 'assets/limited-time-ltd/popup-mobile.svg';
import premiumsPromoHf from 'assets/limited-time-ltd/popup-desktop.svg';
import premiumsPromoMobileHf from 'assets/limited-time-ltd/popup-mobile.svg';

// import {info} from 'constants/svgIcons';
// import discountHexometer from 'assets/images/50- off-annual.png';
// import blackFridayDark from 'assets/images/black_friday_image_dark_mode.png';
// import blackFridayLight from 'assets/images/black_friday_image.png';
// import hexomateLTD from 'assets/images/hexomate-ltd.png';
// import videoImg from 'assets/hexowatch-promo.png';
// import hexowatchAppsumo from 'assets/images/hexowatch-appsumo.jpg';
// import PromoModal from './promo-popup';

import { closeIcon, ellipse, shuttle } from 'assets/svg-icons';
import useExtensionDetect from 'hooks/useExtensionDetect';
// import videoImg from '../assets/hexospark-promo/video.png';
import extensionShortcut from 'assets/extension-shortcut.svg';
import './styles.scss';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import firefox from 'assets/images/firefox-add-on.png';
import chrome from 'assets/images/chrome-extension.png';
import VerificationEmail from 'shared-components/atom/verifcationEmail';
import useHasFocus from 'hooks/useHasFocus';

// import buyMaticGetMeterMobile from '../assets/EasterPromo22/buy-hexomatic-get-hexometer-mobile.svg';
// import buyMaticGetMeter from '../assets/EasterPromo22/buy-hexomatic-get-hexometer.svg';

// import buyMaticGetWatchMobile from '../assets/EasterPromo22/buy-hexomatic-get-hexowatch-mobile.svg';
// import buyMaticGetWatch from '../assets/EasterPromo22/buy-hexomatic-get-hexowatch.svg';

// import buyWtachGetMeterMobile from '../assets/EasterPromo22/buy-hexowatch-get-hexometer-mobile.svg';
// import buyWatchGetMeter from '../assets/EasterPromo22/buy-hexowatch-get-hexometer.svg';

// import buyWatchLtdMobile from '../assets/EasterPromo22/hexowatch-ltd-mobile.svg';
// import byWatchLtd from '../assets/EasterPromo22/hexowatch-ltd.svg';
// import { useMediaQuery } from 'react-responsive';

const sendUserDataToExtension = (cookie: string | null, refToken: string | any, deviceKey: string | any) => {
  //@ts-ignore
  if (window.chrome && window.chrome.runtime) {
    extensionIds.forEach(async (item: string) => {
      try {
        //@ts-ignore
        await window.chrome.runtime.sendMessage(item, { cookie, refToken, deviceKey });
      } catch {
        return;
      }
    });
  }

  (function () {
    document.dispatchEvent(
      new CustomEvent('extension-cookie-event', {
        detail: { cookie, refToken, deviceKey },
      }),
    );
  })();
};

const Authentication: FC<AuthenticationProps> = ({ children, setAccessToken }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isMobileSm = useMediaQuery({ maxWidth: 460 });
  const { user, setUser } = useContext(UserContext);
  const { theme } = useContext(ThemeContext);
  const { hexomaticUser, setHexomaticUser } = useContext(HexomaticUserContext);
  const [isInstalled, setIsInstalled] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [creationDate, setCreationDate] = useState(user?.createdAt);
  const [surveyModal, setSurveyModal] = useState(false);
  const [isSuccessfullySent, setIsSuccessfullySent] = useState(false);
  const [isValidPCPromoUser, setIsValidPCPromoUser] = useState(false);

  const [openedQuestionary14, setOpenedQuestionary14] = useState(false);
  const [updatedToken, setUpdatedToken] = useState('');
  const [tokenExpDate, setTokenExpDate] = useState(900000);
  const [newUser, setNewUser] = useState(false);
  const [showOnboardingVideo, setShowOnboardingVideo] = useState(false);
  const [surveyClosed, setSurveyClosed] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  // const [extensionButtonClicked, setExtensionButtonClicked] = useState<'closed' | 'clicked'>('closed');
  const { push } = useHistory();
  const focus = useHasFocus();
  // const { showExtensionModal, linkToExtension, setShowExtensionModal } = useExtensionDetect(user);
  const { data: getAccessTokenData, refetch: refetchAccessToken } = useQuery(GET_ACCESS_TOKEN, {
    pollInterval: tokenExpDate,
    skip: !getRawCookie('device_key') || !getRawCookie('ref_token'),
    variables: {
      settings: {
        refresh_token: getRawCookie('ref_token'),
        device_key: getRawCookie('device_key'),
        product_type: 'hexomatic',
      },
    },
  });

  useEffect(() => {
    focus && refetchAccessToken();
  }, [focus]);

  useEffect(() => {
    window.location.pathname.includes('pricing') && (window.location.href = 'https://hexomatic.com/pricing/');
    const video = window.location.search?.includes('video=workflow')
      ? 'https://player.vimeo.com/video/838966084'
      : window.location.search?.includes('video=welcome')
      ? 'https://player.vimeo.com/video/838996108?h=07e693bb41'
      : '';
    setVideoUrl(video);
  }, []);

  useEffect(() => {
    if (getAccessTokenData && getAccessTokenData.User && getAccessTokenData.User.getAccessToken) {
      if (getAccessTokenData.User.getAccessToken.token) {
        const jwtParsed = jwt_decode(getAccessTokenData.User.getAccessToken.token);
        //@ts-ignore
        const expDate = new Date((jwtParsed.exp || 0) * 1000) - new Date() - 55000;
        setTokenExpDate(expDate);
      }
      setUpdatedToken(getAccessTokenData.User.getAccessToken.token);
      setAccessToken(getAccessTokenData.User.getAccessToken.token);
      setUserToken(getAccessTokenData.User.getAccessToken.token);
      sendUserDataToExtension(
        getAccessTokenData.User.getAccessToken.token,
        getRawCookie('ref_token'),
        getRawCookie('device_key'),
      );
    }
  }, [getAccessTokenData]);

  // const [leftSeats, setSeatsLeft] = useState(0);

  // const { data: seatsLeft } = useQuery(LEFT_PACKAGES_COUNT);

  // const { data: questionnaire52 } = useQuery(GET_QUESTIEONNAIRE, {
  //   fetchPolicy: 'no-cache',
  //   variables: {
  //     type: 'questionnaire52',
  //   },
  // });

  // const { data: questionnaire60 } = useQuery(GET_QUESTIEONNAIRE, {
  //   fetchPolicy: 'no-cache',
  //   variables: {
  //     type: 'questionnaire60',
  //   },
  // });

  // const { data: questionnaire77 } = useQuery(GET_QUESTIEONNAIRE, {
  //   fetchPolicy: 'no-cache',
  //   variables: {
  //     type: 'questionnaire77',
  //   },
  // });

  const { data: questionnaire56 } = useQuery(GET_QUESTIEONNAIRE, {
    fetchPolicy: 'no-cache',
    variables: {
      type: 'questionnaire56',
    },
  });

  // const { data: allPlansData } = useQuery(USER_PLANS_QUERY, {
  //   fetchPolicy: 'no-cache',
  // });

  // const { data: hexofyUserData } = useQuery(HEXOFY_USER, {
  //   fetchPolicy: 'no-cache',
  // });

  useEffect(() => {
    if (
      newUser &&
      questionnaire56?.Questionnaire?.get?.showQuestionnaire &&
      surveyClosed &&
      !window.location.pathname.includes('black-friday-deal') &&
      !window.location.pathname.includes('spring-break-promo')
    ) {
      setShowOnboardingVideo(true);
    } else {
      setShowOnboardingVideo(false);
    }
  }, [newUser, questionnaire56, surveyClosed]);

  // const {data: hexometerData, loading: hexometerLoading} = useQuery(GET_HEXOMETER_USER_SETTINGS, {
  //   skip: !(user && user.id),
  //   variables: {user_id: user && user.id && +user.id},
  // });

  // const { data: hexowatchData, loading: hexowatchLoading } = useQuery(GET_USER_WATCH_SETTINGS_QUERY);

  // const { data: seatsLeft } = useQuery(LEFT_PREMMIUMS_COUNT);

  // useEffect(() => {
  //   if (seatsLeft?.User?.getPromoLeftSeats?.seats) {
  //     const count = JSON.parse(seatsLeft.User.getPromoLeftSeats.seats);
  //     count && count.left_count > 0 && setSeatsLeft(count.left_count);
  //   }
  // }, [seatsLeft]);

  // useEffect(() => {
  //   if (
  //     seatsLeft?.User?.getHexomateLeftLTDPackagesCount?.count &&
  //     seatsLeft.User.getHexomateLeftLTDPackagesCount.count > 0
  //   ) {
  //     setSeatsLeft(seatsLeft.User.getHexomateLeftLTDPackagesCount.count);
  //   }
  // }, [seatsLeft]);

  // const [modal7Open, setModal7Open] = useState(false);

  const [premiumsPromoModal, setPremiumsPromoModal] = useState(false);
  const [premiumsPromoModalHf, setPremiumsPromoModalHf] = useState(false);
  const [validPromoUser, setValidPromoUser] = useState(false);
  const [closed14, setClosed14] = useState(false);
  const [closed22, setClosed22] = useState(false);
  const [openPremiumCreditsPopup, setOpenPremiumCreditsPopup] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  // const { data: hexomatePackagesCount} = useQuery(LEFT_PACKAGES_COUNT);

  // const history = useHistory();
  // const calculateTimeLeft = () => {
  //   const difference = +new Date('April 22 2021 07:00:00 GMT') - +new Date(new Date().toUTCString());
  //   let timeLeft = {};

  //   if (difference > 0) {
  //     timeLeft = {
  //       days: Math.floor(difference / (1000 * 60 * 60 * 24)),
  //       hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
  //       minutes: Math.floor((difference / 1000 / 60) % 60),
  //       seconds: Math.floor((difference / 1000) % 60),
  //     };
  //   }

  //   return timeLeft;
  // };

  // const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  // useEffect(() => {
  //   setTimeout(() => {
  //     setTimeLeft(calculateTimeLeft());
  //   }, 1000);
  // });
  const [loadUserInfo, { data: userData }] = useLazyQuery(USER_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted(userData) {
      if (userData && userData.User && userData.User.get) {
        if (userData.User.get.error) {
          if (window.location.href.includes('current-workflow')) {
            localStorage.setItem('current-workflow', window.location.href);
          }
          if (window.location.href.includes('/workflows/create')) {
            localStorage.setItem('navigate-create-workflow', '/workflows/create');
          }
          if (window.location.href.includes('/pricing')) {
            localStorage.setItem('navigate-pricing', '/pricing');
          }
          if (window.location.href.includes('/black-friday-deal') || window.location.href.includes('/spring-break-promo')) {
            localStorage.setItem('promo-page-path', '/spring-break-promo');
          }
          if (!window.location.href.includes('/dev-login')) {
            setTimeout(() => {
              window.location.href =
                window.location.href.indexOf('localhost') > -1
                  ? 'http://localhost:3000/dev-login'
                  : window.location.href.indexOf('37.186.119.181') > -1
                  ? 'http://37.186.119.181:3279/dev-login'
                  : 'https://hexomatic.com/login/';
              return <></>;
            }, 0);
          }
          return;
        }
        // @ts-ignore
        setFreshChatUserData(userData.User.get);
        setUser({ ...userData.User.get, loaded: true });
        const cookie = getRefreshToken();

        const refToken = getRawCookie('ref_token');
        const deviceKey = getRawCookie('device_key');

        cookie && refToken && deviceKey && sendUserDataToExtension(cookie, refToken, deviceKey);

        userData.User.get.email && setUserEmailInStroage(userData.User.get.email);
        // setUserType(userData.User.get.type);
        return;
      }
      sendUserDataToExtension(null, null, null);
    },
  });

  useEffect(() => {
    if (user && user.id && !user.verified && !window.location.href.includes('scraping-recipes/create')) {
      setShowVerificationModal(true);
    }
  }, [user]);

  const [hexomaticUserInfo, { data: hexomaticUserData }] = useLazyQuery(GET_HEXOMATIC_USER, {
    onCompleted(hexomaticUserData) {
      if (hexomaticUserData?.HexomaticUser?.getByLegacyId) {
        setHexomaticUser({ ...hexomaticUserData.HexomaticUser.getByLegacyId, photo: user?.photo });
        !surveyClosed &&
          typeof hexomaticUserData.HexomaticUser.getByLegacyId.show_survey === 'boolean' &&
          setSurveyModal(hexomaticUserData.HexomaticUser.getByLegacyId.show_survey);
        // setHexomaticPackage(hexomaticUserData.HexomaticUser.getByLegacyId.pricing_package);
      }
    },
  });

  // const [generateApolloData] = useLazyQuery(GENERATE_APOLLO_DATA);

  // const { data: questionnaire14, refetch: refetchQuestionnaire14 } = useQuery(GET_QUESTIEONNAIRE, {
  //   fetchPolicy: 'no-cache',
  //   skip: updatedToken !== getUserToken(),
  //   variables: {
  //     type: 'questionnaire14',
  //   },
  // });

  useEffect(() => {
    const freePc =
      user?.id && (user?.premium_credits_pricing_package === 'FREE' || !user?.premium_credits_pricing_package);
    setIsValidPCPromoUser(!!freePc);
  }, [user]);

  // useEffect(() => {
  //   const showQuestionnaire = questionnaire77?.Questionnaire?.get?.showQuestionnaire;
  //   const hexomaticPlan = hexomaticUser?.pricing_package && hexomaticUser?.pricing_package !== 'FREE';
  //   const currentDate = new Date();
  //   const specifiedDate = new Date('Sep 11 2024 07:00:00 GMT');
  //   const promoClosed = currentDate.getTime() > specifiedDate.getTime();

  //   if (isValidPCPromoUser && showQuestionnaire && hexomaticPlan && !promoClosed) {
  //     setPremiumsPromoModal(true);
  //   } else {
  //     setPremiumsPromoModal(false);
  //   }
  // }, [questionnaire77, hexomaticUser, isValidPCPromoUser]);

  useEffect(() => {
    if (user?.createdAt) {
      setCreationDate(user?.createdAt);
    }
  }, [user]);

  // useEffect(() => {
  //   if (allPlansData?.User?.getUserPlans) {
  //     const userPlans = allPlansData?.User?.getUserPlans;
  //     const hexomaticPlanName = userPlans.hexomatic?.pricing_package;
  //     const hexowatchPlanName = userPlans.hexowatch?.pricing_package;
  //     const hexometerPlanName = userPlans.hexometer?.pricing_package;
  //     const hexosparkPlanName = userPlans.hexospark?.pricing_package;
  //     // const premiumCreditsPlan = userPlans.premiumCredits?.premium_credits_pricing_package;
  //     const hexofyPlanName = hexofyUserData?.HexofyUser?.getHexofyUser?.hfUser?.pricing_package;


  //     const hexomaticFree = hexomaticPlanName === 'FREE' || (userPlans && !hexomaticPlanName);
  //     const hexowatchFree = hexowatchPlanName === 'FREE' || (userPlans && !hexowatchPlanName);
  //     const hexometerFree = hexometerPlanName === 'FREE' || (userPlans && !hexometerPlanName);
  //     const hexosparkFree = hexosparkPlanName === 'FREE' || (userPlans && !hexosparkPlanName);
  //     const hexofyFree = hexofyPlanName === 'FREE' || (userPlans && !hexofyPlanName);
  //     const allFreeUser =
  //       hexomaticFree && hexowatchFree && hexometerFree && hexosparkFree && hexofyFree;

  //       if (allFreeUser && questionnaire77?.Questionnaire?.get?.showQuestionnaire && !window.location.pathname.includes('spring-break-promo')) {
  //         setPremiumsPromoModal(true);
  //       } else {
  //         setPremiumsPromoModal(false);
  //       }
  //     // const date1 = new Date(creationDate).getTime();
  //     // const date2 = new Date().getTime();
  //     // const diffTime = Math.abs(date2 - date1);
  //     // const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  //     // if (validPromoUser && questionnaire54?.Questionnaire?.get?.showQuestionnaire && hexofyPlanInterval && hexofyPlanInterval !== 'LTD') {
  //     //   setPremiumsPromoModal(false);
  //     //   setPremiumsPromoModalHf(true);
  //     // } else if (
  //     //   hexofyUserData?.HexofyUser?.getHexofyUser &&
  //     //   allFreeUser &&
  //     //   questionnaire52?.Questionnaire?.get?.showQuestionnaire &&
  //     //   diffDays >= 7
  //     // ) {
  //     //   setPremiumsPromoModal(false);
  //     //   setPremiumsPromoModalHf(false);
  //     // } else {
  //     //   setPremiumsPromoModal(false);
  //     //   setPremiumsPromoModalHf(false);
  //     // }
  //   }
  // }, [allPlansData, hexofyUserData, questionnaire77]);

  // useEffect(() => {
  //   if (
  //     user &&
  //     (user.premium_credits_pricing_package === 'FREE' ||
  //       user.premium_credits_pricing_package === 'PC_15900' ||
  //       user.premium_credits_pricing_package === 'PC_24900') &&
  //     user?.promoData?.hexomatic_ltd
  //   ) {
  //     setIsValidPCPromoUser(true);
  //   } else {
  //     setIsValidPCPromoUser(false);
  //   }
  // }, [user, hexomaticUser]);

  // useEffect(() => {
  //   const val = questionnaire66?.Questionnaire?.get?.showQuestionnaire;
  //   const hexowatchPlan = allPlansData?.User?.getUserPlans?.hexowatch?.pricing_package;
  //   const hexomaticPlan = allPlansData?.User?.getUserPlans?.hexomatic?.pricing_package;
  //   const hexomaticPlanInterval = allPlansData?.User?.getUserPlans?.hexomatic?.pricing_package_interval;

  //   const hexowatchFree = hexowatchPlan === 'FREE' || (allPlansData?.User?.getUserPlans && !hexowatchPlan);
  //   const hexomaticFree = hexomaticPlan === 'FREE' || (allPlansData?.User?.getUserPlans && !hexomaticPlan);
  //   const hexomaticSilverLtd = hexomaticPlan === 'SILVER' && hexomaticPlanInterval === 'LTD';
  //   const validHexomatic = hexomaticSilverLtd || hexomaticFree;
  //   if (
  //     val &&
  //     hexowatchFree &&
  //     validHexomatic &&
  //     !window.location.pathname.includes('black-friday-deal')
  //   ) {
  //     setPremiumsPromoModal(true);
  //   } else {
  //     setPremiumsPromoModal(false);
  //   }
  // }, [questionnaire66, allPlansData]);

  // useEffect(() => {
  //   // if (creationDate) {
  //   //   const date1 = new Date(creationDate).getTime();
  //   //   const date2 = new Date('2023-09-20T00:00:00.000Z').getTime();
  //   //   const diffTime = date1 < date2;
  //   //   if (
  //   //     questionnaire63?.Questionnaire?.get?.showQuestionnaire &&
  //   //     diffTime &&
  //   //     hexomaticUser?.pricing_package === 'FREE'
  //   //   ) {
  //   //     setPremiumsPromoModalHf(true);
  //   //   } else {
  //   //     setPremiumsPromoModalHf(false);
  //   //   }
  //   // }
  //   if (
  //     questionnaire63?.Questionnaire?.get?.showQuestionnaire &&
  //     validPromoUser
  //   ) {
  //     setPremiumsPromoModalHf(true);
  //   } else {
  //     setPremiumsPromoModalHf(false);
  //   }
  // }, [questionnaire63, validPromoUser]);

  useEffect(() => {
    if (
      user?.promoData?.hexomatic_ltd
      // hexomaticUser?.pricing_package &&
      // hexomaticUser?.pricing_package !== 'FREE' &&
      // user?.premium_credits_pricing_package === 'FREE'
    ) {
      setValidPromoUser(true);
    } else {
      setValidPromoUser(false);
    }
  }, [user]);

  const handleIsExtensionInsalled = (event: any): void => {
    //@ts-ignore
    if (event && event.detail && event.detail.isInstalled) {
      setIsInstalled(true);
    }
  };

  useEffect(() => {
    document.addEventListener('is-extension-installed', handleIsExtensionInsalled);
    return () => document.removeEventListener('is-extension-installed', handleIsExtensionInsalled);
  }, []);

  // useEffect(() => {
  //   if (
  //     user &&
  //     user.verified &&
  //     questionnaire14 &&
  //     questionnaire14.Questionnaire &&
  //     questionnaire14.Questionnaire.get &&
  //     questionnaire14.Questionnaire.get.showQuestionnaire &&
  //     !isMobile &&
  //     !isInstalled
  //   ) {
  //     setOpenedQuestionary14(true);
  //   } else {
  //     setOpenedQuestionary14(false);
  //   }
  // }, [questionnaire14, user, isMobile]);

  // useEffect(() => {
  //   let hexowatchPlan = '';
  //   if (
  //     hexowatchData &&
  //     hexowatchData.UserWatchSettings &&
  //     hexowatchData.UserWatchSettings.get &&
  //     hexowatchData.UserWatchSettings.get.pricing_package
  //   ) {
  //     hexowatchPlan = hexowatchData.UserWatchSettings.get.pricing_package;
  //   }
  //   if (
  //     !(hexowatchPlan &&
  //     !hexowatchLoading &&
  //     (hexowatchPlan === 'FREE' || !hexowatchPlan) &&
  //     questionnaire11 &&
  //     questionnaire11.Questionnaire &&
  //     questionnaire11.Questionnaire.get &&
  //     questionnaire11.Questionnaire.get.showQuestionnaire) &&
  //     user.verified &&
  //     questionnaire12 &&
  //     questionnaire12.Questionnaire &&
  //     questionnaire12.Questionnaire.get &&
  //     questionnaire12.Questionnaire.get.showQuestionnaire
  //   ) {
  //     setOpenPremiumCreditsPopup(true);
  //   }
  // }, [questionnaire11, questionnaire12, user, hexowatchData]);

  // const ids=["252", "5575", "11", "850", "3485", "11439", "12924", "9685", "9573"];

  // const [submitQuestionnaire, { loading: submitLoading }] = useMutation(QUESTIEONNAIRE_CREATE_UPDATE);
  const [cancelQuestionnaire, { loading: cancelLoading }] = useMutation(QUESTIEONNAIRE_CANCEL);
  // const [selected, setSelected] = useState<any>(null);

  //   const [accessTokenMutation, {data: res}] = useMutation(AUTH_ACCESS_TOKEN);

  useEffect(() => {
    // if (getRefreshToken()) {
    //   accessTokenMutation({variables: {refreshToken: getRefreshToken()}});
    // }
    if (getUserSignedUpViaGoogle()) {
      setNewUser(true);
      if (window.location.href.includes('dash.hexomatic')) {
        //@ts-ignore
        window.dataLayer.push({
          event: `accountRegistration-hexomatic`,
          registrationOrderId: new Date().toISOString(),
        });
        //@ts-ignore
        window.dataLayer.push({
          event: `registration-hexomatic`,
          ecommerce: {
            registration: {
              actionField: {
                id: `Registration_${new Date().toISOString()}`,
              },
            },
          },
        });
      }
      setUserSignedUpViaGoogle(false);
    }
    if (user && user.email) {
      // generateApolloData();
    }
  }, [user]);

  useEffect(() => {
    if (updatedToken === getUserToken() && !(user && user.id)) {
      loadUserInfo();
      hexomaticUserInfo();
      // refetchQuestionnaire14();
    }
  }, [updatedToken, getUserToken(), user]);

  // const [modal7Open, setModal7Open] = useState(false);

  // useEffect(() => {
  //   if (
  //     user &&
  //     user.verified &&
  //     hexomaticUser &&
  //     hexomaticUser.id &&
  //     (hexomaticUser.pricing_package === 'FREE' || !hexomaticUser.pricing_package) &&
  //     !window.location.href.includes('premium-credits-annual')
  //   ) {
  //     setModalOpen(
  //       questionnaire13 &&
  //         questionnaire13.Questionnaire &&
  //         questionnaire13.Questionnaire.get &&
  //         questionnaire13.Questionnaire.get.showQuestionnaire,
  //     );
  //   }

  //   // if ((!questionnaire6?.Questionnaire?.get?.showQuestionnaire || hexomaticUser?.pricing_package && hexomaticUser?.pricing_package !== 'FREE') && user?.verified) {
  //   //   setModal7Open(questionnaire7 && questionnaire7.Questionnaire && questionnaire7.Questionnaire.get && questionnaire7.Questionnaire.get.showQuestionnaire);
  //   // }
  // }, [questionnaire13, user, hexomaticUser]);

  // useEffect(() => {
  //   refetchQuestionnaire11();
  // }, [user, hexomaticUser]);

  // if (!userData || !userData.User) {
  //   return (
  //     <div className="load">
  //       <svg className="load__icon">
  //         <path fill="#16AC59" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
  //       </svg>
  //     </div>
  //   );
  // }

  const handleCancelQuestionnaire = async (type: string, clicked: boolean, chrome?: boolean): Promise<any> => {
    setOpenedQuestionary14(false);
    setClosed14(true);
    const { data } = await cancelQuestionnaire({
      variables: { type, answer1: clicked ? 'clicked' : 'closed' },
    });

    clicked &&
      window.open(
        chrome
          ? 'https://chrome.google.com/webstore/detail/hexomatic/gaeglheibddedbjiehaeeacoldaiimkl'
          : 'https://addons.mozilla.org/en-US/firefox/addon/hexomatic/',
        '_blank',
      );
  };

  // const handleSubmit = async () => {
  //   const { data } = await cancelQuestionnaire({
  //     variables: { type: 'questionnaire15', answer1: 'clicked' },
  //   });
  //   push('/pricing');
  // };

  // const handleSubmitQuestionnaire = async (isPremium?: boolean) => {
  //   // setSelected(selectedOption);
  //   // const {data} = await submitQuestionnaire({
  //   //   variables: {type: 'questionnaire11', answer1: 'clicked'},
  //   // });
  //   // setModalOpen(false);
  //   if (isPremium) {
  //     const href = window.location.href;
  //     href && window.open(`${href}${href.lastIndexOf('/') === href.length - 1 ? '' : '/'}?premium-credits-annual`, '_blank');
  //     return;
  //   }
  //   window.open('https://hexowatch.com/pricing', '_blank');
  // };

  const handleModalClose = async (clicked: boolean, val: string) => {
    setClosed22(true);
    if (clicked) {
      await cancelQuestionnaire({
        variables: { type: val, answer1: 'clicked' },
      });
      // setShowHexosparkPrelaunch(false);
      // window.open(val === 'questionnaire50' ? 'https://hexomatic.com/promo92535/?utm_source=hexomatic&utm_medium=popup' : 'https://hexomatic.com/premiums_promo/?utm_source=hexomatic&utm_medium=popup', '_blank');
      // val === 'questionnaire54'
      //   ? window.open('https://hexofy.com/?utm_source=hexomatic&utm_medium=popup', '_blank')
      //   : window.open('https://appsumo.8odi.net/QyYg0Y', '_blank');
      val === 'questionnaire77'
        ? setOpenPremiumCreditsPopup(true)
        : window.open('https://hexomatic.com/EVERYTHING-BF-2023/?utm_source=hexomatic&utm_medium=popup', '_blank');
      val === 'questionnaire77' && setPremiumsPromoModal(false);
      setPremiumsPromoModalHf(false);
      setModalOpen(false);
      setShowOnboardingVideo(false);
      // setOpenPremiumCreditsPopup(true);
    } else {
      val === 'questionnaire77' && setPremiumsPromoModal(false);
      setPremiumsPromoModalHf(false);
      setModalOpen(false);
      setClosed22(true);
      setShowOnboardingVideo(false);
      // setShowHexosparkPrelaunch(false);
      const { data } = await cancelQuestionnaire({
        variables: { type: val, answer1: 'closed' },
      });
    }
  };

  return (
    <>
      {children}
      {/* {modalOpen && (
        <Modal show={modalOpen} toggle={() => {}} className={`promo-modal modal-dialog--header theme-${theme}`}>
          <div className="modal__header">
          <div className="panel__btns">
            <button className="panel__btn" onClick={() => handleCancelQuestionnaire('questionnaire11')} type="button">
              {closeIcon}
            </button>
          </div>
        </div>
        <div className="modal__body" style={{ padding: '30px 30px 30px 50px' }}>
            <div className="questionnaire_container">
              <h5 className="dark-text mb-2 text-center">
                To celebrate the launch of Hexowatch version 3.0 you can take advantage of two very special promos
              </h5>
            </div>
            <Row className="text-center">
              <Col md={12}>
                {play ? (
                  //@ts-ignore
                  <iframe
                    src="https://player.vimeo.com/video/619918725?h=03a4be6ddc&title=0&byline=0&portrait=0&autoplay=1"
                    width={isMobile ? '300px' : '600px'}
                    height={isMobile ? '169px' : '338px'}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    style={{maxWidth: '100%'}}
                  ></iframe>
                ) : (
                  <div className="promo-video-cover" onClick={() => setPlay(true)}>
                    <div className="play-btn"></div>
                    <img
                      src={videoImg}
                      style={{maxWidth: isMobile ? '300px' : '600px', width: '100%', height: 'auto'}}
                    />
                  </div>
                )}
              </Col>
              <Col md={12} className="modal-footer-hexowatch-promo">
                <p className='pt-1 pb-1'>
                  ⚡{' '}
                  <span
                    onClick={() => {
                      handleSubmitQuestionnaire();
                    }}
                    className="hexowatch-external-link"
                  >
                    Click here
                  </span>{' '}
                  to save up to <span className="bold-text">55% off</span> all our Hexowatch plans
                </p>
                <p className='pt-1 pb-1'>
                  ⚡{' '}
                  <span
                    onClick={() => {
                      handleSubmitQuestionnaire(true);
                    }}
                    className="hexowatch-external-link"
                  >
                    Click here
                  </span>{' '}
                  to save up to <span className="bold-text">33% off</span> premium credits
                </p>
                <p className='pt-1 pb-1'>
                  Best of all, you can lock in our special discounted rates for life and premium credits can be used in
                  both Hexowatch and Hexomatic.
                </p>
                <Counter hours />
              </Col>
            </Row>
          </div>
        </Modal>
      )} */}
      {/* {modalOpen && (
        <Modal show={modalOpen} toggle={() => {}} className={`modal-dialog--header theme-${theme} black-friday-modal`}>
          <div className="modal__header">
            <div className="panel__btns">
              <button className="panel__btn" onClick={() => handleCancelQuestionnaire('questionnaire11')} type="button">
                {closeIcon}
              </button>
            </div>
          </div>

          <div className="modal__body" style={{ padding: '20px 30px 30px 30px' }}>
            <Row className="text-center">
              <Col md={12}>
                <div className="d-flex justify-content-center">
                  <img
                    src={theme === 'dark' ? blackFridayDark : blackFridayLight}
                    alt="Black Friday"
                    style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
                    onClick={() => window.open('https://dash.hexowatch.com/hexowatch/black-friday', '_blank')}
                  />
                </div>
                <p className="primary-text mt-4 black-friday-text">
                  For a limited time only, secure a 25% <span className="highlighted-text">lifetime</span> discount on
                  our Hexowatch annual plans
                </p>
              </Col>
              <Col md={12}>
                <Button
                  name={'Click here to upgrade your Hexowatch plan'}
                  onClick={() => window.open('https://dash.hexowatch.com/hexowatch/black-friday', '_blank')}
                  className="m-horizontal-auto mt-4 primary"
                />{' '}
              </Col>
             
            </Row>
          </div>
        </Modal>
      )} */}
      {/* {premiumsPromoModal && (
        <BlackFridayModal closeModal={(clicked: boolean, val: any) => handleModalClose(clicked, val)} />
      )} */}
      {/* {showHexosparkPrelaunch && (
        <Modal show={showHexosparkPrelaunch} toggle={() => {}} className={`promo-modal hexospark-prelaunch modal-dialog--header theme-${theme}`}>
          <button
            className="panel__btn close-icon"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              handleModalClose(false, 'questionnaire24');
            }}
            type="button"
          >
            {closeIcon}
          </button>
          <div className="modal__body" style={{ padding: '30px 30px 30px 50px' }}>
            <div className="questionnaire_container">
              <h5 className="title text-center">
                You're Invited to the <span className='hexospark-color'>Hexospark</span> pre-launch
              </h5>
            </div>
            <div className='my-4'>
              <Counter date="June 07 2022 19:00:00 GMT" leftSeats={leftSeats} light />
            </div>
           
            <div className="p-wrapper"></div>
            <Row className="text-center">
              <Col  md={12}>
                <h5 className='mb-3' style={{fontSize: '20px', fontWeight: 500, color: '#fff'}}>Today we have an exciting announcement to share...</h5>
              </Col>
              <Col md={12}>
                <img src={videoImg} style={{maxWidth: '360px', margin: 'auto', width: '100%', cursor: 'pointer'}} onClick={() => handleModalClose(true, 'questionnaire24')} />
              </Col>
              <Col md={12}>
                <Button
                  name={'Secure Your Pre-Launch Lifetime Deal'}
                  onClick={() => handleModalClose(true, 'questionnaire24')}
                  className='hexospark-btn'
                />
              </Col>
            </Row>
          </div>
        </Modal>
      )} */}
      {openPremiumCreditsPopup && (
        <CustomModal
          ShowPopup={openPremiumCreditsPopup}
          setShowPopup={setOpenPremiumCreditsPopup}
          hideModalBody
          className={'payment-modal'}
          theme={theme}
          escButtonClick={setOpenPremiumCreditsPopup}
        >
          <Payment
            name={''}
            selectedValue={{ title: '100/monthly', value: 100 }}
            price={0}
            premium_credit
            setShowPopup={setOpenPremiumCreditsPopup}
            // offer={!!window.location.href.includes('#premium-credits')}
            // offer
          />
        </CustomModal>
      )}
      {/* {openedQuestionary14 &&
        !window.location.pathname.includes('/pricing') &&
        !closed14 &&
        !premiumsPromoModal &&
        !premiumsPromoModalHf &&
        !window.location.pathname.includes('/black-friday-deal') &&
        !showOnboardingVideo && !surveyModal && !videoUrl && !isSuccessfullySent && (
          <Modal
            show={openedQuestionary14 && !window.location.pathname.includes('/pricing') && !closed14}
            toggle={() => {}}
            className={`promo-modal modal-dialog--header extension-modal theme-${theme}`}
          >
            <div className="ellipse-wrapper">{ellipse}</div>

            <div className="extension-modal-close-wrapper">
              <span
                className="button-span"
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleCancelQuestionnaire('questionnaire14', false);
                }}
              >
                {closeIcon}
              </span>
            </div>
            <div className="modal__body" style={{ padding: '0' }}>
              <div className="extension-view-wrapper">
                <img src={extensionShortcut} alt="short cut" />
              </div>
              <div className="extension-modal-wrapper">
                <h1>Have you Installed our browser extension yet?</h1>
                <h3>Our browser extension provides 1-click access to our most popular automations.</h3>
                <div className="mt-4">
                  <p className="text-center">Download extension on:</p>
                  <div className="pb-4 d-flex align-items-center justify-content-center">
                    <img
                      src={firefox}
                      alt="Firefox"
                      className="extension-logo"
                      onClick={() => handleCancelQuestionnaire('questionnaire14', true)}
                    />
                    <img
                      src={chrome}
                      alt="Chrome"
                      className="extension-logo"
                      onClick={() => handleCancelQuestionnaire('questionnaire14', true, true)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )} */}

      {/* {easterPromoHexowatchLTD && <PromoModal closeModal={handleModalClose} questionaireValue={'questionnaire18'} mobileImage={buyWatchLtdMobile} desktopImage={byWatchLtd} ltdSeats={leftSeats} />} */}
      {/* {buyHexowatchGetHexometer && <PromoModal closeModal={handleModalClose} questionaireValue={'questionnaire19'} mobileImage={buyWtachGetMeterMobile} desktopImage={buyWatchGetMeter} />}
      {buyHexomatichGetHexometer && <PromoModal closeModal={handleModalClose} questionaireValue={'questionnaire20'} mobileImage={buyMaticGetMeterMobile} desktopImage={buyMaticGetMeter} />}
      {buyHexomaticGetHexowatch && <PromoModal closeModal={handleModalClose} questionaireValue={'questionnaire21'} mobileImage={buyMaticGetWatchMobile} desktopImage={buyMaticGetWatch} />} */}

      {premiumsPromoModal && (
          <Modal
            show={premiumsPromoModal}
            toggle={() => {}}
            className={`promo-modal modal-dialog--header theme-${theme}`}
          >
            <button
              className="panel__btn dark d-flex"
              style={{
                marginTop: '5px',
                marginRight: '10px',
                background: '#fff',
                borderRadius: '50%',
                width: '16px',
                height: '16px',
              }}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                handleModalClose(false, 'questionnaire77');
              }}
              type="button"
            >
              {closeIcon}
            </button>
            <div className="modal__body" style={{ padding: '0', background: 'transparent' }}>
              <img
                onClick={() => handleModalClose(true, 'questionnaire77')}
                src={isMobile ? premiumsPromoMobile : premiumsPromo}
                alt="Promo"
                className="cursor-pointer"
              />
            </div>
            <div
              className={`counter-absolute cursor-pointer`}
              onClick={() => handleModalClose(true, 'questionnaire77')}
            >
              <Counter date={'Sep 11 2024 07:00:00 GMT'} text="DEAL ENDS IN..." textLarge  light/>
            </div>
          </Modal>
        )}
      {premiumsPromoModalHf &&
        !closed22 &&
        user?.verified &&
        !showOnboardingVideo &&
        !surveyModal &&
        !videoUrl &&
        !isSuccessfullySent && (
          <Modal
            show={premiumsPromoModalHf && !closed22}
            toggle={() => {}}
            className={`promo-modal modal-dialog--header theme-${theme}`}
          >
            <button
              className="panel__btn dark d-flex"
              style={{
                marginTop: '5px',
                marginRight: '10px',
                background: '#fff',
                borderRadius: '50%',
                width: '16px',
                height: '16px',
              }}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                handleModalClose(false, 'questionnaire63');
              }}
              type="button"
            >
              {closeIcon}
            </button>
            <div className="modal__body" style={{ padding: '0', background: 'transparent' }}>
              <img
                onClick={() => handleModalClose(true, 'questionnaire63')}
                src={isMobile ? premiumsPromoMobileHf : premiumsPromoHf}
                alt="Promo"
                className="cursor-pointer"
              />
            </div>
            <div className={`counter-absolute ${premiumsPromoModalHf ? 'ltd' : ''}`}>
              <Counter
                date={'Oct 17 2023 07:00:00 GMT'}
                text="DEAL ENDS IN..."
                textLarge
                textColor="#fff"
                light
                // hours
              />
            </div>
          </Modal>
        )}
      {/* {premiumsPromoModalHf && !closed22 && user?.verified && (
        <Modal
          show={premiumsPromoModalHf && !closed22}
          toggle={() => {}}
          className={`promo-modal modal-dialog--header theme-${theme}`}
        >
          <button
            className="panel__btn"
            style={{ marginTop: '5px', marginRight: '10px' }}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              handleModalClose(false, 'questionnaire54');
            }}
            type="button"
          >
            {closeIcon}
          </button>
          <div className="modal__body" style={{ padding: '0', background: 'transparent' }}>
            <img
              onClick={() => handleModalClose(true, 'questionnaire54')}
              src={isMobile ? premiumsPromoMobileHf : premiumsPromoHf}
              alt="Promo"
              className="cursor-pointer"
            />
          </div>
          <div className={`counter-absolute ${premiumsPromoModal ? '' : 'ltd'}`}>
            <Counter date={'Jun 15 2023 07:00:00 GMT'} text="HEXOFY PRICES ARE GOING UP IN..." textLarge />
          </div>
        </Modal>
      )} */}
      {showOnboardingVideo && !closed22 && !isSuccessfullySent && !premiumsPromoModal && (
        <Modal
          show={showOnboardingVideo && !closed22}
          toggle={() => {}}
          className={`promo-modal video modal-dialog--header theme-${theme}`}
        >
          <button
            className="panel__btn"
            style={{ marginTop: '5px', marginRight: '10px' }}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              handleModalClose(false, 'questionnaire56');
            }}
            type="button"
          >
            {closeIcon}
          </button>
          <div className="modal__body" style={{ padding: '30px', background: '#fff' }}>
            <iframe
              src="https://player.vimeo.com/video/838996108?h=07e693bb41"
              width={isMobile ? '300px' : '600px'}
              height={isMobile ? '169px' : '338px'}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              style={{ maxWidth: '100%', margin: 'auto' }}
            ></iframe>
          </div>
        </Modal>
      )}
      {!showOnboardingVideo && !!videoUrl && !closed22 && !surveyModal && !premiumsPromoModal && (
        <Modal
          show={!showOnboardingVideo && !!videoUrl && !closed22 && !surveyModal}
          toggle={() => {}}
          className={`promo-modal video modal-dialog--header theme-${theme}`}
        >
          <button
            className="panel__btn"
            style={{ marginTop: '5px', marginRight: '10px' }}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              setVideoUrl('');
              setShowOnboardingVideo(false);
              push(window.location.pathname);
            }}
            type="button"
          >
            {closeIcon}
          </button>
          <div className="modal__body" style={{ padding: '30px', background: '#fff' }}>
            <iframe
              src={videoUrl}
              width={isMobile ? '300px' : '600px'}
              height={isMobile ? '169px' : '338px'}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              style={{ maxWidth: '100%', margin: 'auto' }}
            ></iframe>
          </div>
        </Modal>
      )}
      <ModalCustom
        isModalOpen={showVerificationModal && !showOnboardingVideo && !surveyModal && !videoUrl && !isSuccessfullySent && !premiumsPromoModal}
        actionBtnText=""
        onActionClick={() => {}}
        actionBtnColor="transparent"
        text={<VerificationEmail />}
        onCloseModal={() => setShowVerificationModal(false)}
        onlyBtns
        className="p-0"
        escButtonClick={() => setShowVerificationModal(false)}
      />
      <ModalCustom
        isModalOpen={
          surveyModal && !videoUrl && !isSuccessfullySent && !premiumsPromoModal &&
          !window.location.pathname.includes('black-friday-deal') && 
          !window.location.pathname.includes('spring-break-promo')
        }
        actionBtnText=""
        onActionClick={() => {}}
        actionBtnColor="transparent"
        text={
          <PersonalizeExperience
            setIsSuccessfullySent={setIsSuccessfullySent}
            onClose={() => {
              setSurveyClosed(true);
              setSurveyModal(false);
            }}
          />
        }
        onCloseModal={() => {
          setSurveyClosed(true);
          setSurveyModal(false);
        }}
        onlyBtns
        className="p-0 m-0"
        escButtonClick={() => {
          setSurveyClosed(true);
          setSurveyModal(false);
        }}
      />
      <ModalCustom
        isModalOpen={isSuccessfullySent && !premiumsPromoModal}
        text="Your response has been successfully sent"
        actionBtnText="Ok"
        actionBtnColor="success"
        cancelBtnColor="primary"
        onActionClick={() => {
          setSurveyClosed(true);
          setIsSuccessfullySent(false);
        }}
        escButtonClick={() => {
          setSurveyClosed(true);
          setIsSuccessfullySent(false);
        }}
      />
    </>
  );
};

export default Authentication;
