import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
import Datalist from 'shared-components/atom/dataList';
import { spellcheckToolLanguages, spellcheckDatapoints, screenShotDelayOptions } from '../../constants';
import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import CustomMultiSelect from 'shared-components/molecule/multiselect';
import Alert from 'shared-components/atom/alert-message';
import { cacheFunction } from 'helpers';

const SourceTypeOptions = [
  { label: 'URL', value: 'url' },
  { label: 'Text', value: 'text' },
];

const DeepleTranslateApiSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  selectedRecipes,
  automationIndex,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [fieldList, setFieldList] = useState<any>([]);
  const [clicked, setClicked] = useState(false);
  const [language, setlanguage] = useState('');
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [spellchecSelectedkDatapoints, setSpellchecSelectedkDatapoints] = useState([] as any);
  const [errorMessage, setErrorMessage] = useState('');
  const [delay, setDelay] = useState(screenShotDelayOptions[0]);
  const [sourceType, setSourceType] = useState<any>(SourceTypeOptions[0]);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const {
    liftingSettings,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (!language || !selectedSource || !spellchecSelectedkDatapoints?.length || errorMessage || !sourceType) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const { keys } = dynamic_settings;
      const { settings } = keys[0];
      const language_code = settings ? settings.language_code : keys[0].language_code;
      const grammar_configs = settings ? settings.grammar_configs : keys[0].grammar_configs;
      let delay = settings ? settings.delay : null;
      const source_type = settings ? settings.type : null;

      const findedScreenShotDelay = !delay
        ? null
        : screenShotDelayOptions.find((item: { value: any }) => item.value === delay);
      findedScreenShotDelay && setDelay(findedScreenShotDelay);
      const findedLanguageFrom = spellcheckToolLanguages.find(item => item.value === language_code);
      findedLanguageFrom && setlanguage(findedLanguageFrom.label);
      const datapointsList = spellcheckDatapoints.filter(
        item => grammar_configs && grammar_configs.indexOf(item.value) > -1,
      );
      datapointsList && setSpellchecSelectedkDatapoints(datapointsList);
      const findedSourceTepe = SourceTypeOptions.find(item => item.value === source_type);
      findedSourceTepe && setSourceType(findedSourceTepe);
    }
  }, []);

  //recipe
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();

    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const key = dynamic_settings.keys[0].key;
      restoreSelectedSource({ key: key, fieldList: options, setSelectedSource: setSelectedSource });
    }
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [...fieldList.map((item: { value: any }) => item.value)].concat(
          spellchecSelectedkDatapoints.map((item: any) => item.value),
        ),
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);

  //memoize
  const handlespellcheckToolLanguages = (language: string) => {
    return spellcheckToolLanguages.find((item: { label: string; value: string }) => item.label === language);
  };
  const getMemoizespellcheckToolLanguageOption = useRef(cacheFunction(handlespellcheckToolLanguages));

  useEffect(() => {
    if (!clicked) return;
    // setClicked(false);

    const selectOptionFrom = getMemoizespellcheckToolLanguageOption.current(language);
    if (!selectOptionFrom) {
      setCompletedFields({ index: automationIndex, checked: false });
    } else {
      setCompletedFields({ index: automationIndex, checked: checkFields() });
    }

    const sortedSpellchecSelectedkDatapoints = spellchecSelectedkDatapoints.sort((obj1: any, obj2: any) => {
      if (obj1.order_index > obj2.order_index) {
        return 1;
      } else {
        return -1;
      }
    });
    let localSettings = {
      keys: [
        {
          inputValue: null,
          key: selectedSource ? selectedSource.value : null,
          settings: {
            language_code: selectOptionFrom ? selectOptionFrom.value : null,
            grammar_configs:
              sortedSpellchecSelectedkDatapoints.map((item: { label: string; value: string }) => item.value) || [],
            delay: sourceType.value === 'text' ? 0 : delay.value,
            type: sourceType.value,
          },
        },
      ],
    };
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: localSettings,
      outputKeysData: [...fieldList.map((item: { value: any }) => item.value)].concat(
        spellchecSelectedkDatapoints.map((item: any) => item.value),
      ),
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [
    selectedSource,
    sourceType,
    language,
    errorMessage,
    spellchecSelectedkDatapoints,
    delay,
    fieldList,
    fieldListWithTypes,
    automationOutputTypes,
  ]);

  const selectSourceType = (selectedItem: any) => {
    setSourceType(selectedItem);
    if (selectedItem.value === 'text') {
      setSpellchecSelectedkDatapoints(
        spellchecSelectedkDatapoints.filter(
          (item: any) =>
            item.value !== '_grammar_spelling_audit_screenshot_image' &&
            item.value !== '_grammar_spelling_audit_iterations',
        ),
      );
    }
    !clicked && setClicked(true);
    setIsDirty();
  };

  const dataPointsOptions = useMemo(
    () =>
      sourceType.value !== 'text'
        ? spellcheckDatapoints
        : spellcheckDatapoints.filter(
            item =>
              item.value !== '_grammar_spelling_audit_screenshot_image' &&
              item.value !== '_grammar_spelling_audit_iterations',
          ),
    [selectSourceType],
  );

  return (
    <div className="grammar-settings">
      <Row className="">
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Source</span>
          <Select
            options={fieldList}
            value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
            placeholder={'Select source'}
            onChange={(selectedItem: any) => {
              setSelectedSource(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
        <Col lg={3} className="mb-4">
          <span className="label secondary-color">Source type</span>
          <Select
            options={SourceTypeOptions}
            value={sourceType}
            placeholder={'Select column'}
            textDefault
            onChange={selectSourceType}
          ></Select>
        </Col>
        {sourceType && sourceType.label === 'URL' && (
          <Col lg={3} className="mb-4">
            <span className="label secondary-color">Delay (seconds)</span>
            <Select
              options={screenShotDelayOptions}
              value={delay}
              placeholder={'Select'}
              onChange={(selectedItem: any) => {
                setDelay(selectedItem);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col lg={6} className="grammar-select">
          <div className="select-block">
            <span className="label secondary-color">Language</span>
            <Datalist
              list={spellcheckToolLanguages}
              value={language}
              placeholder={'Select language'}
              onChange={(text: string) => {
                setlanguage(text);
                setClicked(true);
                setIsDirty();
              }}
            />
          </div>
        </Col>
        <Col lg={6} className="">
          <div className="select-block">
            <span className="label secondary-color">Datapoints</span>
            <CustomMultiSelect
              options={dataPointsOptions}
              value={spellchecSelectedkDatapoints}
              onChange={(sel: any) => {
                setSpellchecSelectedkDatapoints(sel);
                !clicked && setClicked(true);
                setIsDirty();
              }}
              labelledBy="Select"
            />
          </div>
        </Col>
      </Row>
      {errorMessage && (
        <Row className="mt-4">
          <Col md={12}>
            <Alert type="error" className="create-workflow">
              {errorMessage}
            </Alert>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default DeepleTranslateApiSettings;
