import { memo, FC, useState, useEffect, useRef, RefObject } from 'react';
import Button from '../../atom/button';
import './styles.scss';
import FilterSelect from '.';
import { Col, Row } from 'react-bootstrap';
import { useQuery } from '@apollo/react-hooks';
import { GET_AUTOMATIONS, GET_USER_WATCH_TAGS, WORKFOW_STATUS_COUNTS_RESULT } from 'graphql/queries';
import { workflowStatuses } from 'pages/workflows/helpers';
interface IProps {
  onApplyClick: (filtrationsList: any) => void;
  hide?: boolean;
  onClickOutside: (e?: any) => void;
  show?: boolean;
}

const SearchContainer1: FC<IProps> = ({ onApplyClick, hide, onClickOutside, show }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [filterOptions, setFilterOptions] = useState([
    {
      label: 'Status',
      value: 'status',
      subCategories: [],
    },
    {
      label: 'Shared',
      value: 'shared',
    },
    {
      label: 'Public',
      value: 'public',
    },
    {
      label: 'Scheduled',
      value: 'scheduled',
    },
    {
      label: 'Tags',
      value: 'tags',
      subCategories: [],
    },
    {
      label: 'Automations',
      value: 'automations',
      subCategories: [],
    },
  ]);

  const { data: tagsData } = useQuery(GET_USER_WATCH_TAGS, {
    fetchPolicy: 'no-cache',
  });

  const { data: statusesData } = useQuery(WORKFOW_STATUS_COUNTS_RESULT, {
    fetchPolicy: 'no-cache',
  });

  const { data: automationsData } = useQuery(GET_AUTOMATIONS, {
    variables: {
      settings: {
        filter: {
          active: true,
          page: null,
          limit: null,
          date_from: null,
          date_to: null,
          sort_order: null,
          sort_by: null,
          hasSource: null,
          automation_kind: undefined,
          search: {
            search_field: null,
            search_value: null,
          },
          short_info: true,
        },
      },
    },
    fetchPolicy: 'no-cache',
  });

  const [selectedFiltrations, setSelectedFiltrations] = useState<any[]>([
    {
      label: '',
      value: '',
    },
  ]);

  const getItemStatusWithCount = (item: { status: string; count: number }) => {
    const statusObj = workflowStatuses.find(obj => obj.value === item.status);
    if (statusObj) {
      return `${statusObj.label} (${item.count})`;
    } else {
      return '';
    }
  };

  const handleSelectCategory = (val: any, index: number) => {
    const newList = [...selectedFiltrations];
    newList[index] = val;
    // if (val.label === 'Automations') {
    //   getAutomationsList();
    // } else if (val.label === 'Tags') {
    //   getTagsList();
    // } else if (val.label === 'Status') {
    //   getStatusesWithCounts();
    // }

    setSelectedFiltrations(newList);
  };

  const handleSelectSubCategory = (val: any, index: number, multi?: boolean) => {
    const newList = [...selectedFiltrations];
    newList[index].selectedSubcategories = multi ? val : [val];

    setSelectedFiltrations(newList);
  };

  const handleAddFilter = () => {
    const newList = [
      ...selectedFiltrations,
      {
        label: '',
        value: '',
      },
    ];
    setSelectedFiltrations(newList);
  };

  const handleDelete = (index: number) => {
    const newList = [...selectedFiltrations];
    if (newList.length === 1) {
      setSelectedFiltrations([
        {
          label: '',
          value: '',
        },
      ]);
    } else {
      newList.splice(index, 1);
      setSelectedFiltrations(newList);
    }
  };

  useEffect(() => {
    const data = localStorage.getItem('workflows-filtration');
    const parsedData = data && JSON.parse(data);
    parsedData &&
      parsedData.length &&
      setSelectedFiltrations(
        parsedData.map((item: any) => {
          if (item.label === 'Tags' || item.label === 'Automations' || item.label === 'Status') {
            return {
              ...item,
              subCategories: filterOptions.find((sub: any) => sub.label === item.label)?.subCategories,
            };
          } else {
            return item;
          }
        }),
      );
  }, [filterOptions]);

  useEffect(() => {
    if (automationsData?.HexomaticAutomation?.getAutomations?.automations) {
      const arr = automationsData?.HexomaticAutomation?.getAutomations?.automations.map((item: any) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      const sorted = arr.sort((a: any, b: any) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });
      sorted.unshift({ value: 43, label: 'Scraping recipe' });
      const newArr = filterOptions.map((item: any) => {
        if (item.label === 'Automations') {
          return { ...item, subCategories: sorted };
        } else {
          return item;
        }
      });
      setFilterOptions(newArr);
    }
  }, [automationsData]);

  useEffect(() => {
    if (tagsData?.HexomaticTag?.getUserHexomaticTags?.tags) {
      const list = tagsData.HexomaticTag.getUserHexomaticTags.tags.map((item: any) => {
        return { label: item.name, value: `${item.id}` };
      });
      const newArr = filterOptions.map((item: any) => {
        if (item.label === 'Tags') {
          return { ...item, subCategories: list };
        } else {
          return item;
        }
      });
      setFilterOptions(newArr);
    }
  }, [tagsData]);

  useEffect(() => {
    if (statusesData?.HexomaticWorkflow?.getWorkflowStatusCounts?.result) {
      const list = statusesData.HexomaticWorkflow.getWorkflowStatusCounts.result.map((item: any) => {
        return { label: getItemStatusWithCount(item), value: item.status };
      });
      workflowStatuses.map(
        (item: any) =>
          !list.find((sub: any) => sub.value === item.value) &&
          list.push({ label: `${item.label} (0)`, value: item.value }),
      );
      const newArr = filterOptions.map((item: any) => {
        if (item.label === 'Status') {
          return { ...item, subCategories: list };
        } else {
          return item;
        }
      });
      setFilterOptions(newArr);
    }
  }, [statusesData]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      const containsClassName =
        typeof event.target.className === 'string'
          ? event.target.className.includes('filter')
          : event.target.className.baseVal
          ? event.target.className.baseVal.includes('filter')
          : false;
      if (ref.current && !ref.current.contains(event.target) && !containsClassName) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);
  if (!show) return null;
  return (
    <div className={`filter-container-wrap ${hide ? 'dashboard' : ''}`} ref={ref}>
      <div className="title">Filters</div>

      {selectedFiltrations.map((item: any, index: number) => {
        return (
          <FilterSelect
            filterOption={item}
            index={index}
            filteroptions={filterOptions}
            setSelectedcategory={handleSelectCategory}
            setSelectedSubcategory={handleSelectSubCategory}
            onDelete={handleDelete}
            selectedFiltrations={selectedFiltrations}
          />
        );
      })}

      <Row className="container-footer">
        <Col md={4} className="d-flex add-filter-wrapper">
          <span
            className={`${
              selectedFiltrations.length === 6 ||
              selectedFiltrations[selectedFiltrations.length - 1]?.label === '' ||
              (selectedFiltrations[selectedFiltrations.length - 1]?.label &&
                selectedFiltrations[selectedFiltrations.length - 1]?.subCategories &&
                !selectedFiltrations[selectedFiltrations.length - 1]?.selectedSubcategories)
                ? 'disable-button add-filter'
                : 'add-filter'
            } 
     `}
            onClick={handleAddFilter}
          >
            + Add Filter
          </span>
        </Col>
        <Col md={8} className="footer-buttons-container">
          <Button
            outline
            name="Clear all"
            onClick={() => {
              setSelectedFiltrations([
                {
                  label: '',
                  value: '',
                },
              ]);
            }}
          />
          <Button
            name="Apply"
            className="ml-3"
            disabled={
              (selectedFiltrations.length > 1 && selectedFiltrations[selectedFiltrations.length - 1]?.label === '') ||
              (selectedFiltrations[selectedFiltrations.length - 1]?.label &&
                selectedFiltrations[selectedFiltrations.length - 1]?.subCategories &&
                !selectedFiltrations[selectedFiltrations.length - 1]?.selectedSubcategories)
            }
            onClick={() =>
              onApplyClick(
                selectedFiltrations.filter(
                  (item: any) =>
                    (item.label && item.subCategories && item.selectedSubcategories.length) ||
                    (item.label && !item.subCategories),
                ),
              )
            }
          />
        </Col>
      </Row>
    </div>
  );
};

export default memo(SearchContainer1);
