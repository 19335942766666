import { FC, useState, useCallback, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import CustomMultiSelect from 'shared-components/molecule/multiselect';
import Select from 'shared-components/atom/select';
import Checkbox from 'shared-components/atom/checkbox';
import Alert from 'shared-components/atom/alert-message';
import { techStachNames } from '../../constants/tech-stack/tech-stach-names';
import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';

const DiscoverTeckstackSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  selectedRecipes,
  automationIndex,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [clicked, setClicked] = useState(false);
  const [selected, setSelected] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isTeckStack, setIsTechStack] = useState<boolean>(true);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const {
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    liftingSettings,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (!selectedSource || (!isTeckStack && !selected?.length)) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings && fieldList.length > 0) {
      const { key, settings } = dynamic_settings.keys[0];
      if (settings) {
        const { tech_stack_full_result, tech_stack_names } = settings;
        tech_stack_full_result !== null && setIsTechStack(tech_stack_full_result);
        const list =
          tech_stack_names &&
          tech_stack_names.length > 0 &&
          tech_stack_names.map((item: any) => {
            const val = techStachNames.find(sub => sub.label === item);
            return val;
          });
        list && list.length && setSelected(list.filter((item: any) => item));
      }
      restoreSelectedSource({ key: key, fieldList: fieldList, setSelectedSource: setSelectedSource });
    }
  }, [fieldList]);

  //recipe fields or output fields
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const outputs = [
        ...fieldList.map((item: { value: any }) => item.value),
        ...selected.map((item: any) => item.value?.toLowerCase()?.replace(/\s/g, '_')),
      ];
      isTeckStack && outputs.push('_tech_stack');
      // const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      const jsonParse: any = {};
      [...selected.map((item: any) => item.value?.toLowerCase()?.replace(/\s/g, '_'))].map(
        (item: any) => (jsonParse[item] = 'text'),
      );

      liftingSettings({
        outputKeysData: outputs,
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, selected, isTeckStack, automationOutputTypes, fieldListWithTypes]);

  //lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);

    const outputs = [
      ...fieldList.map((item: { value: any }) => item.value),
      ...selected.map((item: any) => item.value?.toLowerCase()?.replace(/\s/g, '_')),
    ];
    isTeckStack && outputs.push('_tech_stack');
    // const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    const jsonParse: any = {};
    [...selected.map((item: any) => item.value?.toLowerCase()?.replace(/\s/g, '_'))].map(
      (item: any) => (jsonParse[item] = 'text'),
    );

    liftingSettings({
      dynamicSettings: {
        keys: [
          {
            key: fieldList.length && selectedSource ? selectedSource.value : null,
            inputValue: null,
            settings: {
              tech_stack_full_result: isTeckStack,
              tech_stack_names: selected.map((item: any) => item.label),
            },
          },
        ],
      },
      outputKeysData: outputs,
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [selectedSource, selected, isTeckStack, fieldList, fieldListWithTypes, automationOutputTypes]);

  return (
    <div>
      <Row>
        <Col lg={6}>
          <span className="label secondary-color">Source</span>
          <Select
            fullWidth
            options={fieldList}
            value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
            placeholder={'Select source'}
            onChange={(selectedItem: any) => {
              setSelectedSource(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
        <Col lg={6}>
          <span className="label secondary-color">Specify stacks (optional)</span>
          <CustomMultiSelect
            options={techStachNames}
            value={selected}
            onChange={(sel: any) => {
              if ((sel.length >= selected.length && selected.length < 10) || sel.length < selected.length) {
                setSelected(sel);
                setErrorMessage('');
              } else {
                setErrorMessage('Maximum number of tech stacks that can be selected is 10.');
              }
              !clicked && setClicked(true);
              setIsDirty();
            }}
            labelledBy="Select"
            hasSelectAll={false}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6} className="checkbox-wrapper mt-0">
          <div className="item-block mt-4">
            <Checkbox
              checked={isTeckStack}
              labelRight="Return full list"
              onChange={() => {
                setIsTechStack(!isTeckStack);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </div>
        </Col>
        {errorMessage && (
          <Col md={12}>
            <Alert type="error" className="create-workflow">
              {errorMessage}
            </Alert>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default DiscoverTeckstackSettings;
