import { FC } from 'react';
import './layout.scss';

interface IFooterProps {
  toggle: boolean;
  noPadding?: boolean;
  dark?: boolean;
}

const Footer: FC<IFooterProps> = ({ toggle, noPadding, dark }) => {
  return (
    <footer className={`${toggle && !noPadding ? 'footer-width' : ''} ${dark ? 'dark-footer' : ''}`} style={{ width: noPadding ? '100%' : '' }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 text-center">
            &copy; {new Date().getFullYear()} <span>Hexact, Inc. All rights reserved.</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
