import { FC, memo, useState } from 'react';
import { checkedIcon, closeIcon, skippedIcon } from 'assets/svg-icons';
import moment from 'moment';
import Input from 'shared-components/atom/input';
import Loader from 'shared-components/atom/loader';

interface IQuestion {
  link: string;
  index: number;
  date: string;
  value?: string;
  handleChangeAnswer: (val: any) => void;
  status: string;
  type: string;
  loading: boolean;
}
const Question: FC<IQuestion> = ({ link, index, date, value, handleChangeAnswer, status, type, loading }) => {
  const [answer, setAnswer] = useState('');
  const [checkedAnswer, setCheckedAnswer] = useState('');
  const handleInputChange = (e: any) => {
    //if (e.target.value.length > 60) return;
    setAnswer(e.target.value);
  };
  const changeAnswer = (val: string) => {
    setCheckedAnswer(val);
    handleChangeAnswer(val);
  };

  return (
    <div className="d-flex justify-content-between align-items-center task p-2 question-bg" key={link}>
      <div className="d-flex">
        <div className="task-number"> {index} </div>
        <div className="task-link">
          <a href={link.includes('http') ? link : 'http://' + link} target="_blank" rel='noopener'>
            {link}
          </a>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <div className={`mx-2 d-flex align-items-center yes-no ${type === 'yes-no' ? '' : 'type-answer'}`}>
          {!value ? (
            type === 'yes-no' ? (
              <>
                <button
                  className="btn btn-success mr-3 d-flex align-items-center justify-content-center"
                  onClick={() => changeAnswer('YES')}
                >
                  {loading && checkedAnswer === 'YES' ? <Loader /> : 'YES'}
                </button>
                <button
                  className="btn btn-danger mr-3 d-flex align-items-center justify-content-center"
                  onClick={() => changeAnswer('NO')}
                >
                  {loading && checkedAnswer === 'NO' ? <Loader /> : 'NO'}
                </button>
                <button
                  className="btn btn btn-skip border d-flex align-items-center justify-content-center "
                  onClick={() => changeAnswer('SKIP')}
                >
                  {loading && checkedAnswer === 'SKIP' ? <Loader /> : 'SKIP'}
                </button>
              </>
            ) : (
              <>
                <div className="position-relative answer-input">
                  <Input
                    placeholder={`Type ${type === 'text' ? 'answer' : 'number'}...`}
                    onChange={handleInputChange}
                    value={answer}
                    type={type}
                    onKeyDown={e => e.key === 'Enter' && handleChangeAnswer(e.target.value)}
                  />
                  <span className="position-absolute" onClick={() => answer.trim() && handleChangeAnswer(answer)}>
                    {loading && checkedAnswer !== 'SKIP' ? <Loader /> : checkedIcon}
                  </span>
                </div>
                <button
                  className="btn btn btn-skip border d-flex align-items-center justify-content-center "
                  onClick={() => changeAnswer('SKIP')}
                >
                  {loading && checkedAnswer === 'SKIP' ? <Loader /> : 'SKIP'}
                </button>
              </>
            )
          ) : value === 'YES' ? (
            <span className="yes pl-1">
              {checkedIcon} <span className="text-success px-2 ">YES</span>{' '}
              {status !== 'completed' && (
                <span className="delete-answer" onClick={() => handleChangeAnswer('')}>
                  {closeIcon}
                </span>
              )}
            </span>
          ) : value === 'NO' ? (
            <span className="no pl-1">
              {closeIcon} <span className="text-danger px-2">NO</span>
              {status !== 'completed' && (
                <span className="delete-answer" onClick={() => handleChangeAnswer('')}>
                  {closeIcon}
                </span>
              )}
            </span>
          ) : value === 'SKIP' ? (
            <span className="skip">
              {skippedIcon}
              <span className="px-2">SKIPPED</span>
              {status !== 'completed' && (
                <span className="delete-answer" onClick={() => handleChangeAnswer('')}>
                  {closeIcon}
                </span>
              )}
            </span>
          ) : (
            <div className="answer d-flex align-items-center">
              <span className="px-2 text-center">{value}</span>
              {status !== 'completed' && (
                <span className="delete-answer" onClick={() => handleChangeAnswer('')}>
                  {closeIcon}
                </span>
              )}
            </div>
          )}
        </div>
        {date && value && (
          <div className="d-flex align-items-center">{moment(new Date(parseInt(date))).format('DD.MM.YYYY')}</div>
        )}
      </div>
    </div>
  );
};

export default memo(Question);
