import { FC, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AutomationCardsProps } from '../types';
import Select from '../../atom/select';
import Checkbox from 'shared-components/atom/checkbox';
import Tooltip from 'shared-components/atom/tooltip';
import RecipeName from 'pages/scraping-recipes/components/scraping-recipe-name';
import { StateChangeContext } from 'contexts/state-change-context';
import { newLabelIcon, favoriteIcon } from 'assets/svg-icons';
import './styles.scss';
import { tableActionsIcon } from '../../../assets/svg-icons';

const AutomationCards: FC<AutomationCardsProps> = ({
  options,
  onChange,
  isShow,
  title,
  icon,
  description,
  active,
  rate,
  isNew,
  id,
  recipes,
  credit_price,
  premium_credit_price,
  automationType,
  className,
  checkbox,
  rename,
  automationSrc,
  isFavorite,
  handleMarkAsStarred,
  handleRemoveFromStarred,
}) => {
  const { push } = useHistory();
  const { setStateChange } = useContext(StateChangeContext);

  const [checked, setChecked] = useState((checkbox && checkbox.checked) || false);
  const handleClick = () => {
    if (automationSrc) {
      push('/workflows/create', { isAutomationSelected: id });
      setStateChange({
        id: id && id,
        toggle: Math.floor(Math.random() * 10000),
      });
    } else push(recipes ? `/scraping-recipes/current-recipe/${id}` : `/automations/${id}`);
  };
  return (
    <div
      className={`automation-card position-relative ${recipes ? 'recipe' : 'cursor-pointer'} ${
        className ? className : ''
      }`}
      onClick={() => !recipes && handleClick()}
    >
      {checkbox && (
        <span
          className={`position-absolute ${checkbox.show ? 'recipe-card-chechkbox' : ''}`}
          style={{ right: '3px', top: '3px' }}
        >
          <Checkbox
            checked={checked}
            onChange={() => {
              setChecked(!checked);
              checkbox.handelChangeCheckbox(!checked);
            }}
          />
        </span>
      )}

      <div className="automation-card-header">
        <div className="d-flex w-100 cursor-pointer" onClick={() => recipes && handleClick()}>
          <div className={`${recipes ? 'm-vertical-auto' : ''} automation-card-icon`}>
            <img
              src={icon}
              alt={'Tool icon'}
              style={{ width: id === 35 ? '35px' : '', height: id === 35 ? '35px' : '' }}
            />
          </div>
          <div className="w-100 ml-3">
            {!recipes ? (
              <div className="d-flex w-100">
                <h5 className="text-truncate" style={{ maxWidth: `calc(100% - ${isNew ? 130 : 60}px)` }}>
                  {title}
                </h5>
                {isNew && (
                  <>
                    <div className="d-flex">
                      <span>{newLabelIcon}</span>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <>
                {rename && (
                  <RecipeName
                    optionParameters={rename.optionParameters}
                    name={title}
                    id={id}
                    setOptionParameters={rename.setOptionParameters}
                    refetchScrapingList={rename.refetchScrapingList}
                    refetchRecipe={rename.refetchRecipe}
                    className="recipe-card-input my-1"
                  />
                )}
              </>
            )}
            {recipes ? (
              <h6 className="pl-2">1 Credit per each URL</h6>
            ) : (
              <h6 className="text-truncate">{automationType}</h6>
            )}
          </div>
          <span
            className="starred-icon"
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              isFavorite
                ? handleRemoveFromStarred && handleRemoveFromStarred(id)
                : handleMarkAsStarred && handleMarkAsStarred(id);
            }}
          >
            <Tooltip
              trigger={['hover']}
              placement="left"
              text={
                isFavorite
                  ? 'Click to remove from starred automations list'
                  : 'Click to add into the starred automations list'
              }
              className="starred-icon mb-1"
            >
              <span className={isFavorite ? 'favorite-icon-bg' : ''}>{favoriteIcon}</span>
            </Tooltip>
          </span>
        </div>

        <div className="automation-header-left">
          {isShow && (
            <div className="automation-menu-btn">
              <Select showLeft icon={tableActionsIcon} options={options} onChange={onChange} />
            </div>
          )}
        </div>
      </div>
      <div className="automation-card-body">
        <p>{description}</p>
      </div>
    </div>
  );
};

export default AutomationCards;
