import { FC, useContext, memo, useCallback, Fragment, useState, useEffect } from 'react';

import { ThemeContext } from 'contexts/theme-context';
import { eventStop } from 'helpers';
import { limitOptions } from 'constants/index';

import Checkbox from 'shared-components/atom/checkbox';
import Loader from 'shared-components/atom/loader';
import Sort from 'shared-components/atom/sort';
import Tooltip from 'shared-components/atom/tooltip';
import TablePagination from 'shared-components/molecule/table-pagination';
import { IworkflowProps } from 'shared-components/atom/types';

import TableRow from '../table-row';

import { deleteIcon, pause, play } from 'assets/svg-icons';

import './styles.scss';
import { useMediaQuery } from 'react-responsive';
interface IProps {
  hide: boolean;
  isCheckedAll: boolean;
  checkedIndexes: number[];
  handleCheckedAll: () => void;
  sort: {
    by: string;
    id: number;
  };
  setSort: (val: { by: string; id: number }) => void;
  stateLoading: boolean;
  stateValue: boolean;
  setShouldContinue: (val: any) => void;
  handleChangeState: (bool: boolean) => void;
  handleDelete: () => void;
  workflowsList: any[];
  handleCheckboxClick: (index: number) => void;
  returnWorkflowStatus: (
    item: any,
    val?: boolean,
  ) =>
    | 'progress'
    | 'completed'
    | 'error'
    | 'PAUSED'
    | 'DRAFT'
    | 'CREDIT_REACHED'
    | 'PREMIUM_CREDIT_REACHED'
    | 'STORAGE_REACHED'
    | 'CREDIT_STORAGE_REACHED';
  returnActiveStatus: (item: any, reverseActive?: boolean) => boolean;
  changeActive: (id: number, active: boolean, date?: string) => void;
  id: number;
  updateLoading: boolean;
  optionParameters: {
    label: any;
    value: any;
  };
  hoverText: (item: any) => any;
  handleUpdateWorkflowName: (val: { id: number; name?: string }) => void;
  openedId: number;
  setOpenedId: (id: number) => void;
  addTag?: (data: any) => void;
  count: number;
  limit: {
    label: string;
    value: number;
  };
  handelLimitChange: (value: any) => void;
  page: number;
  setPage: (page: number) => void;
  handleUpdateWorkflowDescription: (val: { description: string; id: number }) => void;
  openedIdDesc: number;
  setOpenedIdDesc: (val: number) => void;
  selectedFilterTag: number[];
  deleteTagFromWorkflow: (val: { tagId: number; workflowId: number }) => void;
  handleFilterByTag: (tagId: number, workflowId: number) => void;
  returnStorageUsage: (item: any) => string;
  getNamesForTooltip: (steps: any, count: number) => string;
  returnShedule: (item: any) => string;
  isAdmin: boolean;
  setOpenExportModal: (val: boolean) => void;
  setSelectedWorkflowId: (id: number) => void;
  setSelectedHeaders: (val: any) => void;
  setScheduleConfig: (val: any) => void;
  setStartedAt: (val: any) => void;
  setOptionParameters: (val: {
    label: string;
    value: number | string;
    status?: string;
    recipeIds?: any;
    frequency?: string;
  }) => void;
  realTimeStatusData: null | any;
  setTrigger: any;
  setselectedOutputKeys: any;
  setSendMail: any;
  handleDisableWorkflowTrigger: (arg: number, arg1: number) => Promise<void>;
  handleScrollEvent?: any;
}

const WorkflowTable: FC<IProps> = ({
  handleDisableWorkflowTrigger,
  hide,
  isCheckedAll,
  checkedIndexes,
  handleCheckedAll,
  sort,
  setSort,
  stateLoading,
  stateValue,
  setShouldContinue,
  handleChangeState,
  handleDelete,
  workflowsList,
  handleCheckboxClick,
  returnWorkflowStatus,
  returnActiveStatus,
  changeActive,
  id,
  updateLoading,
  optionParameters,
  hoverText,
  handleUpdateWorkflowName,
  openedId,
  setOpenedId,
  addTag,
  count,
  limit,
  handelLimitChange,
  page,
  setPage,
  handleUpdateWorkflowDescription,
  openedIdDesc,
  setOpenedIdDesc,
  selectedFilterTag,
  deleteTagFromWorkflow,
  handleFilterByTag,
  returnStorageUsage,
  getNamesForTooltip,
  returnShedule,
  isAdmin,
  setOpenExportModal,
  setSelectedWorkflowId,
  setSelectedHeaders,
  setScheduleConfig,
  setStartedAt,
  setOptionParameters,
  realTimeStatusData,
  setTrigger,
  setselectedOutputKeys,
  setSendMail,
  handleScrollEvent,
}) => {
  const { theme } = useContext(ThemeContext);
  const [className, setClassName] = useState('');

  const [isHideSteps, setIsHideSteps] = useState(false);
  const [tableMaxHeight, setTableMaxHeight] = useState(800);

  useEffect(() => {
    if (workflowsList && workflowsList.length) {
      setIsHideSteps(!!workflowsList.find((item: any) => item?.schedule_config?.triggers?.[0]?.name));
    }
  }, [workflowsList]);
  const handlePlay = useCallback(() => {
    setShouldContinue({ isOpen: true, status: 'bulk' });
  }, [setShouldContinue]);

  const handlePause = useCallback(() => {
    handleChangeState(false);
  }, [handleChangeState]);

  useEffect(() => {
    const innerHeight = window.innerHeight;
    const headerEl = document.getElementById('header');
    const headerHeight = headerEl?.offsetHeight;
    innerHeight && headerHeight && setTableMaxHeight(innerHeight - headerHeight - (hide ? 400 : 374));
  }, [window.innerHeight, count, limit, hide, workflowsList]);

  const isMobile = useMediaQuery({ maxWidth: 991 });

  const isMobileLg = useMediaQuery({ maxWidth: 1300 });

  return (
    <div className="p-0 workflow-table-card card">
      <div className={`card-body workflow-tabel-wrapper ${hide ? 'zoom' : ''}`}>
        <div
          className={`table-responsive ${className}`}
          id="workflows-table"
          style={{
            maxHeight: isMobile
              ? '400px'
              : hide && !isMobileLg
              ? `760px`
              : hide && isMobileLg
              ? '600px'
              : `${tableMaxHeight}px`,
            minHeight: '230px',
            height: 'auto',
          }}
          onScroll={handleScrollEvent}
        >
          <table className={`table table--bordered table table-hover ${hide ? 'sm' : ''}`}>
            <thead id="workflow-thead">
              <tr>
                <th className="pl-2 checkbox_th">
                  <span className="workflow-header-checkbox" onClick={eventStop}>
                    <Checkbox checked={isCheckedAll} onChange={handleCheckedAll} />
                  </span>
                </th>
                <th className="pl-2 d-flex" style={{ border: 'none' }}>
                  <span className="selected-check-count checkbox-count">{checkedIndexes.length || ''}</span>
                  <div>
                    Name <Sort id={3} sort={sort} onClick={setSort} />
                  </div>
                </th>
                {/* <th className="text-center no-wrap">
                  Created <Sort id={1} sort={sort} onClick={setSort} />
                </th> */}
                <th className="text-center no-wrap">
                  Modified <Sort id={2} sort={sort} onClick={setSort} />
                </th>
                <th className="text-center no-wrap">
                  Storage
                  <Sort id={5} sort={sort} onClick={setSort} />
                </th>
                <th className="text-center">Status</th>
                <th id="hw-trigger-col"></th>
                <th></th>
                <th></th>
                <th colSpan={3} className="border-0">
                  <div className={`d-flex justify-content-end pr-4 ${!checkedIndexes.length && 'invisible'}`}>
                    {!(stateLoading && stateValue) ? (
                      <>
                        <Tooltip
                          placement="left"
                          text={`Click to run workflow${checkedIndexes.length === 1 ? '' : 's'}`}
                          className="closer"
                        >
                          <button onClick={handlePlay} className="delete-icon bulk">
                            {play}
                          </button>
                        </Tooltip>
                      </>
                    ) : (
                      <Loader size="sm" light={theme === 'light'} className="d-inline-block m-0 p-0 align-middle" />
                    )}
                    {!(stateLoading && !stateValue) ? (
                      <Tooltip placement="left" text="Click to pause" className="closer">
                        <button onClick={handlePause} className="delete-icon bulk">
                          {pause}
                        </button>
                      </Tooltip>
                    ) : (
                      <Loader size="sm" light={theme === 'light'} className="d-inline-block m-0 p-0 align-middle" />
                    )}
                    <Tooltip
                      placement="left"
                      text={`Delete Workflow${checkedIndexes.length === 1 ? '' : 's'}`}
                      className="closer"
                    >
                      <button onClick={handleDelete} className="delete-icon bulk">
                        {deleteIcon}
                      </button>
                    </Tooltip>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {workflowsList.map((item: IworkflowProps, idx: number) => (
                <Fragment key={item.name}>
                  <TableRow
                    handleDisableWorkflowTrigger={handleDisableWorkflowTrigger}
                    item={item}
                    idx={idx}
                    hide={hide}
                    checkedIndexes={checkedIndexes}
                    setShouldContinue={setShouldContinue}
                    workflowsList={workflowsList}
                    handleCheckboxClick={handleCheckboxClick}
                    returnWorkflowStatus={returnWorkflowStatus}
                    returnActiveStatus={returnActiveStatus}
                    changeActive={changeActive}
                    id={id}
                    updateLoading={updateLoading}
                    optionParameters={optionParameters}
                    hoverText={hoverText}
                    handleUpdateWorkflowName={handleUpdateWorkflowName}
                    openedId={openedId}
                    setOpenedId={setOpenedId}
                    addTag={addTag}
                    handleUpdateWorkflowDescription={handleUpdateWorkflowDescription}
                    openedIdDesc={openedIdDesc}
                    setOpenedIdDesc={setOpenedIdDesc}
                    selectedFilterTag={selectedFilterTag}
                    deleteTagFromWorkflow={deleteTagFromWorkflow}
                    handleFilterByTag={handleFilterByTag}
                    returnStorageUsage={returnStorageUsage}
                    getNamesForTooltip={getNamesForTooltip}
                    returnShedule={returnShedule}
                    isAdmin={isAdmin}
                    setOpenExportModal={setOpenExportModal}
                    setSelectedWorkflowId={setSelectedWorkflowId}
                    setSelectedHeaders={setSelectedHeaders}
                    setScheduleConfig={setScheduleConfig}
                    setStartedAt={setStartedAt}
                    setOptionParameters={setOptionParameters}
                    realTimeStatusData={realTimeStatusData}
                    setTrigger={setTrigger}
                    setselectedOutputKeys={setselectedOutputKeys}
                    setSendMail={setSendMail}
                    setClassName={setClassName}
                    isHideSteps={isHideSteps}
                  />
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
        <TablePagination
          count={count}
          limit={limit}
          setPage={setPage}
          handleLimitChange={handelLimitChange}
          page={page}
          limitOptions={limitOptions}
          showPagination={Math.ceil(count / limit.value) > 1}
        />
      </div>
    </div>
  );
};

export default memo(WorkflowTable);
