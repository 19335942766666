import { FC, memo } from 'react';
import recipeIcon from '../../../../assets/icons/recipe.svg';

interface IProps {
  list: any[][];
  onSelectAutomation: any;
  theme: any;
  automationSendSettings: any;
  deeplLight: any;
  index: number;
}
const SearchResultList: FC<IProps> = ({
  list,
  onSelectAutomation,
  theme,
  automationSendSettings,
  deeplLight,
  index,
}) => (
  <>
    {list.length > 0 &&
      list.map(
        (el: any[]) =>
          el &&
          el.length > 0 &&
          el.map((item: any) => (
            <div
              key={item.name}
              className="automation-option"
              onClick={() => {
                onSelectAutomation({
                  automation: item,
                  automationIndex: index !== 0 ? index + 1 : automationSendSettings.length === 0 ? index : index + 1,
                });
              }}
            >
              <span className="automation-card-icon m-vertical-auto">
                <img
                  src={item.icon_url === 34 && theme === 'dark' ? deeplLight : item.icon_url || recipeIcon}
                  alt="Tool icon"
                  style={{
                    width: item.icon_url === 35 ? '35px' : '',
                    height: item.icon_url === 35 ? '35px' : '',
                  }}
                />
              </span>
              <span className="m-vertical-auto ml-3">{item.name}</span>
            </div>
          )),
      )}
  </>
);

export default memo(SearchResultList);
