import React, { FC, useEffect, useState } from 'react';
import { AutomationSettingsFactoryTypesProps } from './types';
import RecipesGeneralSettings from './recipes-general-settings';

import './styles.scss';

const allAutomationSettings: any = {};
function importAllSettings(r: { (arg0: any): any; keys: () => any[] }) {
  r.keys().forEach(key => {
    const id = key.split('/')[1].split('_')[0];
    if (!isNaN(id)) {
      allAutomationSettings[id] = r(key).default;
    }
  });
}

const AutomationSettingsFactory: FC<AutomationSettingsFactoryTypesProps> = ({
  id,
  automationIndex,
  selectedRecipes,
  setCompletedFields,
  automationOutputTypes,
  automationInputTypes,
  automationSendSettings,
  setAutomationSendSettings,
  isRecipe,
  errorMessage,
  isSettingsChange,
  forZapier,
  setErrorMessage,
  setIsDirty,
  workflowId,
}) => {
  const [error, setError] = useState('');
  useEffect(() => {
    if (errorMessage && errorMessage.includes('The source of new automation')) {
      setError(errorMessage);
      return;
    }
    setError('');
  }, [errorMessage]);
  if (typeof id !== 'number') return null;

  Object.keys(allAutomationSettings).length === 0 &&
    //@ts-ignore
    importAllSettings(require.context('./components/', true, /\.tsx$/));

  const Component = isRecipe ? RecipesGeneralSettings : allAutomationSettings[id.toString()];
  if (!Component) return null;
  return (
    <div className="automations-settings pd-20">
      <Component
        id={id}
        automationIndex={automationIndex}
        selectedRecipes={selectedRecipes}
        automationSendSettings={automationSendSettings}
        setCompletedFields={setCompletedFields}
        setAutomationSendSettings={setAutomationSendSettings}
        isSettingsChange={isSettingsChange}
        forZapier={forZapier}
        setErrorMessage={setErrorMessage}
        setIsDirty={setIsDirty}
        automationOutputTypes={automationOutputTypes}
        automationInputTypes={automationInputTypes}
        workflowId={workflowId}
      />
    </div>
  );
};

export default React.memo(AutomationSettingsFactory);
