export const techStachNames = [
  {
    label: '1C-Bitrix',
    value: '_1c-bitrix_tech_stack',
  },
  {
    label: '2B Advice',
    value: '_2b_advice_tech_stack',
  },
  {
    label: '30namaPlayer',
    value: '_30namaplayer_tech_stack',
  },
  {
    label: '33Across',
    value: '_33across_tech_stack',
  },
  {
    label: '34SP.com',
    value: '_34sp.com_tech_stack',
  },
  {
    label: '3dCart',
    value: '_3dcart_tech_stack',
  },
  {
    label: '4-Tell',
    value: '_4-tell_tech_stack',
  },
  {
    label: '8base',
    value: '_8base_tech_stack',
  },
  {
    label: '<model-viewer>',
    value: '_<model-viewer>_tech_stack',
  },
  {
    label: '@sulu/web',
    value: '_@sulu/web_tech_stack',
  },
  {
    label: 'A-Frame',
    value: '_a-frame_tech_stack',
  },
  {
    label: 'A8.net',
    value: '_a8.net_tech_stack',
  },
  {
    label: 'AB Tasty',
    value: '_ab_tasty_tech_stack',
  },
  {
    label: 'ABOUT YOU Commerce Suite',
    value: '_about_you commerce suite_tech_stack',
  },
  {
    label: 'AD EBiS',
    value: '_ad_ebis_tech_stack',
  },
  {
    label: 'ADFOX',
    value: '_adfox_tech_stack',
  },
  {
    label: 'AFThemes CoverNews',
    value: '_afthemes_covernews_tech_stack',
  },
  {
    label: 'ALL-INKL',
    value: '_all-inkl_tech_stack',
  },
  {
    label: 'AMP',
    value: '_amp_tech_stack',
  },
  {
    label: 'AMP for WordPress',
    value: '_amp_for wordpress_tech_stack',
  },
  {
    label: 'AOLserver',
    value: '_aolserver_tech_stack',
  },
  {
    label: 'AOS',
    value: '_aos_tech_stack',
  },
  {
    label: 'APC',
    value: '_apc_tech_stack',
  },
  {
    label: 'ARI Network Services',
    value: '_ari_network services_tech_stack',
  },
  {
    label: 'ASP.NET Boilerplate',
    value: '_asp.net_boilerplate_tech_stack',
  },
  {
    label: 'AT Internet Analyzer',
    value: '_at_internet analyzer_tech_stack',
  },
  {
    label: 'AT Internet XiTi',
    value: '_at_internet xiti_tech_stack',
  },
  {
    label: 'ATSHOP',
    value: '_atshop_tech_stack',
  },
  {
    label: 'AWIN',
    value: '_awin_tech_stack',
  },
  {
    label: 'AWS Certificate Manager',
    value: '_aws_certificate manager_tech_stack',
  },
  {
    label: 'AWS WAF Captcha',
    value: '_aws_waf captcha_tech_stack',
  },
  {
    label: 'AWStats',
    value: '_awstats_tech_stack',
  },
  {
    label: 'Abicart',
    value: '_abicart_tech_stack',
  },
  {
    label: 'Absorb',
    value: '_absorb_tech_stack',
  },
  {
    label: 'Accentuate Custom Fields',
    value: '_accentuate_custom fields_tech_stack',
  },
  {
    label: 'AccessTrade',
    value: '_accesstrade_tech_stack',
  },
  {
    label: 'AccessiBe',
    value: '_accessibe_tech_stack',
  },
  {
    label: 'Accessibility Toolbar Plugin',
    value: '_accessibility_toolbar plugin_tech_stack',
  },
  {
    label: 'Accessible360',
    value: '_accessible360_tech_stack',
  },
  {
    label: 'Accessibly',
    value: '_accessibly_tech_stack',
  },
  {
    label: 'Accesso',
    value: '_accesso_tech_stack',
  },
  {
    label: 'AccuWeather',
    value: '_accuweather_tech_stack',
  },
  {
    label: 'Ace',
    value: '_ace_tech_stack',
  },
  {
    label: 'Ackee',
    value: '_ackee_tech_stack',
  },
  {
    label: 'Acoustic Experience Analytics',
    value: '_acoustic_experience analytics_tech_stack',
  },
  {
    label: 'Acquia Campaign Factory',
    value: '_acquia_campaign factory_tech_stack',
  },
  {
    label: 'Acquia Cloud Classic',
    value: '_acquia_cloud classic_tech_stack',
  },
  {
    label: 'Acquia Cloud IDE',
    value: '_acquia_cloud ide_tech_stack',
  },
  {
    label: 'Acquia Cloud Next',
    value: '_acquia_cloud next_tech_stack',
  },
  {
    label: 'Acquia Cloud Platform',
    value: '_acquia_cloud platform_tech_stack',
  },
  {
    label: 'Acquia Cloud Platform CDN',
    value: '_acquia_cloud platform cdn_tech_stack',
  },
  {
    label: 'Acquia Cloud Site Factory',
    value: '_acquia_cloud site factory_tech_stack',
  },
  {
    label: 'Acquia Content Hub',
    value: '_acquia_content hub_tech_stack',
  },
  {
    label: 'Acquia Customer Data Platform',
    value: '_acquia_customer data platform_tech_stack',
  },
  {
    label: 'Acquia Personalization',
    value: '_acquia_personalization_tech_stack',
  },
  {
    label: 'Acquia Site Studio',
    value: '_acquia_site studio_tech_stack',
  },
  {
    label: 'Acquire Cobrowse',
    value: '_acquire_cobrowse_tech_stack',
  },
  {
    label: 'Acquire Live Chat',
    value: '_acquire_live chat_tech_stack',
  },
  {
    label: 'Act-On',
    value: '_act-on_tech_stack',
  },
  {
    label: 'Actito',
    value: '_actito_tech_stack',
  },
  {
    label: 'ActiveCampaign',
    value: '_activecampaign_tech_stack',
  },
  {
    label: 'Acuity Scheduling',
    value: '_acuity_scheduling_tech_stack',
  },
  {
    label: 'AcuityAds',
    value: '_acuityads_tech_stack',
  },
  {
    label: 'Ad Lightning',
    value: '_ad_lightning_tech_stack',
  },
  {
    label: 'AdBridg',
    value: '_adbridg_tech_stack',
  },
  {
    label: 'AdInfinity',
    value: '_adinfinity_tech_stack',
  },
  {
    label: 'AdOcean',
    value: '_adocean_tech_stack',
  },
  {
    label: 'AdOpt',
    value: '_adopt_tech_stack',
  },
  {
    label: 'AdRiver',
    value: '_adriver_tech_stack',
  },
  {
    label: 'AdRoll',
    value: '_adroll_tech_stack',
  },
  {
    label: 'AdRoll CMP System',
    value: '_adroll_cmp system_tech_stack',
  },
  {
    label: 'AdScale',
    value: '_adscale_tech_stack',
  },
  {
    label: 'AdThrive',
    value: '_adthrive_tech_stack',
  },
  {
    label: 'Ada',
    value: '_ada_tech_stack',
  },
  {
    label: 'AdaSiteCompliance',
    value: '_adasitecompliance_tech_stack',
  },
  {
    label: 'Adabra',
    value: '_adabra_tech_stack',
  },
  {
    label: 'Adally',
    value: '_adally_tech_stack',
  },
  {
    label: 'Adalyser',
    value: '_adalyser_tech_stack',
  },
  {
    label: 'Adcash',
    value: '_adcash_tech_stack',
  },
  {
    label: 'AddShoppers',
    value: '_addshoppers_tech_stack',
  },
  {
    label: 'AddThis',
    value: '_addthis_tech_stack',
  },
  {
    label: 'AddToAny',
    value: '_addtoany_tech_stack',
  },
  {
    label: 'AddToAny Share Buttons',
    value: '_addtoany_share buttons_tech_stack',
  },
  {
    label: 'Addi',
    value: '_addi_tech_stack',
  },
  {
    label: 'Addsearch',
    value: '_addsearch_tech_stack',
  },
  {
    label: 'Adform',
    value: '_adform_tech_stack',
  },
  {
    label: 'Adloox',
    value: '_adloox_tech_stack',
  },
  {
    label: 'Adminer',
    value: '_adminer_tech_stack',
  },
  {
    label: 'Admiral',
    value: '_admiral_tech_stack',
  },
  {
    label: 'Admitad',
    value: '_admitad_tech_stack',
  },
  {
    label: 'Admixer',
    value: '_admixer_tech_stack',
  },
  {
    label: 'Admo.tv',
    value: '_admo.tv_tech_stack',
  },
  {
    label: 'Adnegah',
    value: '_adnegah_tech_stack',
  },
  {
    label: 'Adobe Analytics',
    value: '_adobe_analytics_tech_stack',
  },
  {
    label: 'Adobe Audience Manager',
    value: '_adobe_audience manager_tech_stack',
  },
  {
    label: 'Adobe Client Data Layer',
    value: '_adobe_client data layer_tech_stack',
  },
  {
    label: 'Adobe ColdFusion',
    value: '_adobe_coldfusion_tech_stack',
  },
  {
    label: 'Adobe DTM',
    value: '_adobe_dtm_tech_stack',
  },
  {
    label: 'Adobe Dynamic Media Classic',
    value: '_adobe_dynamic media classic_tech_stack',
  },
  {
    label: 'Adobe Experience Manager',
    value: '_adobe_experience manager_tech_stack',
  },
  {
    label: 'Adobe Experience Platform Identity Service',
    value: '_adobe_experience platform identity service_tech_stack',
  },
  {
    label: 'Adobe Experience Platform Launch',
    value: '_adobe_experience platform launch_tech_stack',
  },
  {
    label: 'Adobe Flash',
    value: '_adobe_flash_tech_stack',
  },
  {
    label: 'Adobe GoLive',
    value: '_adobe_golive_tech_stack',
  },
  {
    label: 'Adobe RoboHelp',
    value: '_adobe_robohelp_tech_stack',
  },
  {
    label: 'Adobe Target',
    value: '_adobe_target_tech_stack',
  },
  {
    label: 'AdonisJS',
    value: '_adonisjs_tech_stack',
  },
  {
    label: 'Advally',
    value: '_advally_tech_stack',
  },
  {
    label: 'Advanced Custom Fields',
    value: '_advanced_custom fields_tech_stack',
  },
  {
    label: 'Advert Stream',
    value: '_advert_stream_tech_stack',
  },
  {
    label: 'Adverticum',
    value: '_adverticum_tech_stack',
  },
  {
    label: 'Adyen',
    value: '_adyen_tech_stack',
  },
  {
    label: 'Aegea',
    value: '_aegea_tech_stack',
  },
  {
    label: 'Aero Commerce',
    value: '_aero_commerce_tech_stack',
  },
  {
    label: 'Affilae',
    value: '_affilae_tech_stack',
  },
  {
    label: 'Affiliate B',
    value: '_affiliate_b_tech_stack',
  },
  {
    label: 'Affiliate Future',
    value: '_affiliate_future_tech_stack',
  },
  {
    label: 'Affiliatly',
    value: '_affiliatly_tech_stack',
  },
  {
    label: 'Affilo',
    value: '_affilo_tech_stack',
  },
  {
    label: 'Affirm',
    value: '_affirm_tech_stack',
  },
  {
    label: 'Afosto',
    value: '_afosto_tech_stack',
  },
  {
    label: 'AfterBuy',
    value: '_afterbuy_tech_stack',
  },
  {
    label: 'AfterShip',
    value: '_aftership_tech_stack',
  },
  {
    label: 'AfterShip Returns Center',
    value: '_aftership_returns center_tech_stack',
  },
  {
    label: 'Afterpay',
    value: '_afterpay_tech_stack',
  },
  {
    label: 'Ahoy',
    value: '_ahoy_tech_stack',
  },
  {
    label: 'AiSpeed',
    value: '_aispeed_tech_stack',
  },
  {
    label: 'Aimtell',
    value: '_aimtell_tech_stack',
  },
  {
    label: 'Air360',
    value: '_air360_tech_stack',
  },
  {
    label: 'AirRobe',
    value: '_airrobe_tech_stack',
  },
  {
    label: 'Aircall',
    value: '_aircall_tech_stack',
  },
  {
    label: 'Airee',
    value: '_airee_tech_stack',
  },
  {
    label: 'Airform',
    value: '_airform_tech_stack',
  },
  {
    label: 'Airship',
    value: '_airship_tech_stack',
  },
  {
    label: 'Akamai',
    value: '_akamai_tech_stack',
  },
  {
    label: 'Akamai Bot Manager',
    value: '_akamai_bot manager_tech_stack',
  },
  {
    label: 'Akamai Web Application Protector',
    value: '_akamai_web application protector_tech_stack',
  },
  {
    label: 'Akamai mPulse',
    value: '_akamai_mpulse_tech_stack',
  },
  {
    label: 'Akaunting',
    value: '_akaunting_tech_stack',
  },
  {
    label: 'Akinon',
    value: '_akinon_tech_stack',
  },
  {
    label: 'Akismet',
    value: '_akismet_tech_stack',
  },
  {
    label: 'Akka HTTP',
    value: '_akka_http_tech_stack',
  },
  {
    label: 'Aksara CMS',
    value: '_aksara_cms_tech_stack',
  },
  {
    label: 'Albacross',
    value: '_albacross_tech_stack',
  },
  {
    label: 'AlertifyJS',
    value: '_alertifyjs_tech_stack',
  },
  {
    label: 'Alexa Certified Site Metrics',
    value: '_alexa_certified site metrics_tech_stack',
  },
  {
    label: 'Algolia',
    value: '_algolia_tech_stack',
  },
  {
    label: 'Ali Reviews',
    value: '_ali_reviews_tech_stack',
  },
  {
    label: 'All in One SEO Pack',
    value: '_all_in one seo pack_tech_stack',
  },
  {
    label: 'Alli',
    value: '_alli_tech_stack',
  },
  {
    label: 'AlloyUI',
    value: '_alloyui_tech_stack',
  },
  {
    label: 'Allyable',
    value: '_allyable_tech_stack',
  },
  {
    label: 'AlmaLinux',
    value: '_almalinux_tech_stack',
  },
  {
    label: 'Alpine Linux',
    value: '_alpine_linux_tech_stack',
  },
  {
    label: 'Alpine.js',
    value: '_alpine.js_tech_stack',
  },
  {
    label: 'AlternC',
    value: '_alternc_tech_stack',
  },
  {
    label: 'Amaya',
    value: '_amaya_tech_stack',
  },
  {
    label: 'Amazon ALB',
    value: '_amazon_alb_tech_stack',
  },
  {
    label: 'Amazon Advertising',
    value: '_amazon_advertising_tech_stack',
  },
  {
    label: 'Amazon Associates',
    value: '_amazon_associates_tech_stack',
  },
  {
    label: 'Amazon Aurora',
    value: '_amazon_aurora_tech_stack',
  },
  {
    label: 'Amazon CloudWatch RUM',
    value: '_amazon_cloudwatch rum_tech_stack',
  },
  {
    label: 'Amazon Cloudfront',
    value: '_amazon_cloudfront_tech_stack',
  },
  {
    label: 'Amazon Cognito',
    value: '_amazon_cognito_tech_stack',
  },
  {
    label: 'Amazon EC2',
    value: '_amazon_ec2_tech_stack',
  },
  {
    label: 'Amazon ECS',
    value: '_amazon_ecs_tech_stack',
  },
  {
    label: 'Amazon EFS',
    value: '_amazon_efs_tech_stack',
  },
  {
    label: 'Amazon ELB',
    value: '_amazon_elb_tech_stack',
  },
  {
    label: 'Amazon Pay',
    value: '_amazon_pay_tech_stack',
  },
  {
    label: 'Amazon S3',
    value: '_amazon_s3_tech_stack',
  },
  {
    label: 'Amazon SES',
    value: '_amazon_ses_tech_stack',
  },
  {
    label: 'Amazon Web Services',
    value: '_amazon_web services_tech_stack',
  },
  {
    label: 'Amazon Webstore',
    value: '_amazon_webstore_tech_stack',
  },
  {
    label: 'Ambassador',
    value: '_ambassador_tech_stack',
  },
  {
    label: 'Amber',
    value: '_amber_tech_stack',
  },
  {
    label: 'American Express',
    value: '_american_express_tech_stack',
  },
  {
    label: 'Ametys',
    value: '_ametys_tech_stack',
  },
  {
    label: 'Amex Express Checkout',
    value: '_amex_express checkout_tech_stack',
  },
  {
    label: 'Amiro.CMS',
    value: '_amiro.cms_tech_stack',
  },
  {
    label: 'Amobee',
    value: '_amobee_tech_stack',
  },
  {
    label: 'Amplience',
    value: '_amplience_tech_stack',
  },
  {
    label: 'Amplitude',
    value: '_amplitude_tech_stack',
  },
  {
    label: 'Analysys Ark',
    value: '_analysys_ark_tech_stack',
  },
  {
    label: 'AndersNoren Baskerville',
    value: '_andersnoren_baskerville_tech_stack',
  },
  {
    label: 'AndersNoren Fukasawa',
    value: '_andersnoren_fukasawa_tech_stack',
  },
  {
    label: 'AndersNoren Hemingway',
    value: '_andersnoren_hemingway_tech_stack',
  },
  {
    label: 'AndersNoren Hitchcock',
    value: '_andersnoren_hitchcock_tech_stack',
  },
  {
    label: 'AndersNoren Lovecraft',
    value: '_andersnoren_lovecraft_tech_stack',
  },
  {
    label: 'Anetwork',
    value: '_anetwork_tech_stack',
  },
  {
    label: 'Angular',
    value: '_angular_tech_stack',
  },
  {
    label: 'Angular Material',
    value: '_angular_material_tech_stack',
  },
  {
    label: 'AngularDart',
    value: '_angulardart_tech_stack',
  },
  {
    label: 'AngularJS',
    value: '_angularjs_tech_stack',
  },
  {
    label: 'Animate.css',
    value: '_animate.css_tech_stack',
  },
  {
    label: 'AnswerDash',
    value: '_answerdash_tech_stack',
  },
  {
    label: 'Ant Design',
    value: '_ant_design_tech_stack',
  },
  {
    label: 'AntV G2',
    value: '_antv_g2_tech_stack',
  },
  {
    label: 'AntV G6',
    value: '_antv_g6_tech_stack',
  },
  {
    label: 'Antsomi CDP 365',
    value: '_antsomi_cdp 365_tech_stack',
  },
  {
    label: 'AnyClip',
    value: '_anyclip_tech_stack',
  },
  {
    label: 'Apache',
    value: '_apache_tech_stack',
  },
  {
    label: 'Apache JSPWiki',
    value: '_apache_jspwiki_tech_stack',
  },
  {
    label: 'Apache Tomcat',
    value: '_apache_tomcat_tech_stack',
  },
  {
    label: 'Apache Traffic Server',
    value: '_apache_traffic server_tech_stack',
  },
  {
    label: 'Apache Wicket',
    value: '_apache_wicket_tech_stack',
  },
  {
    label: 'ApexPages',
    value: '_apexpages_tech_stack',
  },
  {
    label: 'Apigee',
    value: '_apigee_tech_stack',
  },
  {
    label: 'Apisearch',
    value: '_apisearch_tech_stack',
  },
  {
    label: 'Aplazame',
    value: '_aplazame_tech_stack',
  },
  {
    label: 'Apollo',
    value: '_apollo_tech_stack',
  },
  {
    label: 'Apollo13Themes Rife',
    value: '_apollo13themes_rife_tech_stack',
  },
  {
    label: 'ApostropheCMS',
    value: '_apostrophecms_tech_stack',
  },
  {
    label: 'AppDynamics',
    value: '_appdynamics_tech_stack',
  },
  {
    label: 'AppNexus',
    value: '_appnexus_tech_stack',
  },
  {
    label: 'Appcues',
    value: '_appcues_tech_stack',
  },
  {
    label: 'Appian',
    value: '_appian_tech_stack',
  },
  {
    label: 'Apple Business Chat',
    value: '_apple_business chat_tech_stack',
  },
  {
    label: 'Apple Pay',
    value: '_apple_pay_tech_stack',
  },
  {
    label: 'Apple Sign-in',
    value: '_apple_sign-in_tech_stack',
  },
  {
    label: 'ApplicantStack',
    value: '_applicantstack_tech_stack',
  },
  {
    label: 'Application Request Routing',
    value: '_application_request routing_tech_stack',
  },
  {
    label: 'Appointy',
    value: '_appointy_tech_stack',
  },
  {
    label: 'Appsflyer',
    value: '_appsflyer_tech_stack',
  },
  {
    label: 'Apptus',
    value: '_apptus_tech_stack',
  },
  {
    label: 'Aprimo',
    value: '_aprimo_tech_stack',
  },
  {
    label: 'AquilaCMS',
    value: '_aquilacms_tech_stack',
  },
  {
    label: 'Arastta',
    value: '_arastta_tech_stack',
  },
  {
    label: 'Arc',
    value: '_arc_tech_stack',
  },
  {
    label: 'Arc XP',
    value: '_arc_xp_tech_stack',
  },
  {
    label: 'ArcGIS API for JavaScript',
    value: '_arcgis_api for javascript_tech_stack',
  },
  {
    label: 'Artifactory',
    value: '_artifactory_tech_stack',
  },
  {
    label: 'Artifactory Web Server',
    value: '_artifactory_web server_tech_stack',
  },
  {
    label: 'Aruba.it',
    value: '_aruba.it_tech_stack',
  },
  {
    label: 'ArvanCloud',
    value: '_arvancloud_tech_stack',
  },
  {
    label: 'Asana',
    value: '_asana_tech_stack',
  },
  {
    label: 'AsciiDoc',
    value: '_asciidoc_tech_stack',
  },
  {
    label: 'Asciidoctor',
    value: '_asciidoctor_tech_stack',
  },
  {
    label: 'Asciinema',
    value: '_asciinema_tech_stack',
  },
  {
    label: 'Asendia',
    value: '_asendia_tech_stack',
  },
  {
    label: 'Astra',
    value: '_astra_tech_stack',
  },
  {
    label: 'Astra Widgets',
    value: '_astra_widgets_tech_stack',
  },
  {
    label: 'Astro',
    value: '_astro_tech_stack',
  },
  {
    label: 'Astute Solutions',
    value: '_astute_solutions_tech_stack',
  },
  {
    label: 'Atatus',
    value: '_atatus_tech_stack',
  },
  {
    label: 'Athena Search',
    value: '_athena_search_tech_stack',
  },
  {
    label: 'Atlassian Bitbucket',
    value: '_atlassian_bitbucket_tech_stack',
  },
  {
    label: 'Atlassian Confluence',
    value: '_atlassian_confluence_tech_stack',
  },
  {
    label: 'Atlassian FishEye',
    value: '_atlassian_fisheye_tech_stack',
  },
  {
    label: 'Atlassian Jira',
    value: '_atlassian_jira_tech_stack',
  },
  {
    label: 'Atlassian Jira Issue Collector',
    value: '_atlassian_jira issue collector_tech_stack',
  },
  {
    label: 'Atlassian Statuspage',
    value: '_atlassian_statuspage_tech_stack',
  },
  {
    label: 'Atome',
    value: '_atome_tech_stack',
  },
  {
    label: 'Attentive',
    value: '_attentive_tech_stack',
  },
  {
    label: 'Attraqt',
    value: '_attraqt_tech_stack',
  },
  {
    label: 'AudioEye',
    value: '_audioeye_tech_stack',
  },
  {
    label: 'Audiohook',
    value: '_audiohook_tech_stack',
  },
  {
    label: 'Aurelia',
    value: '_aurelia_tech_stack',
  },
  {
    label: 'Auryc',
    value: '_auryc_tech_stack',
  },
  {
    label: 'Australia Post',
    value: '_australia_post_tech_stack',
  },
  {
    label: 'Auth0',
    value: '_auth0_tech_stack',
  },
  {
    label: 'Auth0 Lock',
    value: '_auth0_lock_tech_stack',
  },
  {
    label: 'Autoketing',
    value: '_autoketing_tech_stack',
  },
  {
    label: 'Autoketing Product Reviews',
    value: '_autoketing_product reviews_tech_stack',
  },
  {
    label: 'Automizely',
    value: '_automizely_tech_stack',
  },
  {
    label: 'Autopilot',
    value: '_autopilot_tech_stack',
  },
  {
    label: 'Autoptimize',
    value: '_autoptimize_tech_stack',
  },
  {
    label: 'Avada AVASHIP',
    value: '_avada_avaship_tech_stack',
  },
  {
    label: 'Avada Boost Sales',
    value: '_avada_boost sales_tech_stack',
  },
  {
    label: 'Avada SEO',
    value: '_avada_seo_tech_stack',
  },
  {
    label: 'Avada Size Chart',
    value: '_avada_size chart_tech_stack',
  },
  {
    label: 'Avangate',
    value: '_avangate_tech_stack',
  },
  {
    label: 'Avasize',
    value: '_avasize_tech_stack',
  },
  {
    label: 'Avis Verifies',
    value: '_avis_verifies_tech_stack',
  },
  {
    label: 'Aweber',
    value: '_aweber_tech_stack',
  },
  {
    label: 'Awesomplete',
    value: '_awesomplete_tech_stack',
  },
  {
    label: 'Axeptio',
    value: '_axeptio_tech_stack',
  },
  {
    label: 'Axios',
    value: '_axios_tech_stack',
  },
  {
    label: 'Azion',
    value: '_azion_tech_stack',
  },
  {
    label: 'Azure',
    value: '_azure_tech_stack',
  },
  {
    label: 'Azure AD B2C',
    value: '_azure_ad b2c_tech_stack',
  },
  {
    label: 'Azure CDN',
    value: '_azure_cdn_tech_stack',
  },
  {
    label: 'Azure Front Door',
    value: '_azure_front door_tech_stack',
  },
  {
    label: 'Azure Monitor',
    value: '_azure_monitor_tech_stack',
  },
  {
    label: 'a-blog cms',
    value: '_a-blog_cms_tech_stack',
  },
  {
    label: 'a3 Lazy Load',
    value: '_a3_lazy load_tech_stack',
  },
  {
    label: 'aThemes Airi',
    value: '_athemes_airi_tech_stack',
  },
  {
    label: 'aThemes Astrid',
    value: '_athemes_astrid_tech_stack',
  },
  {
    label: 'aThemes Hiero',
    value: '_athemes_hiero_tech_stack',
  },
  {
    label: 'aThemes Moesia',
    value: '_athemes_moesia_tech_stack',
  },
  {
    label: 'aThemes Sydney',
    value: '_athemes_sydney_tech_stack',
  },
  {
    label: 'actionhero.js',
    value: '_actionhero.js_tech_stack',
  },
  {
    label: 'amCharts',
    value: '_amcharts_tech_stack',
  },
  {
    label: 'amoCRM',
    value: '_amocrm_tech_stack',
  },
  {
    label: 'anime.js',
    value: '_anime.js_tech_stack',
  },
  {
    label: 'augmented-ui',
    value: '_augmented-ui_tech_stack',
  },
  {
    label: 'B2C Europe',
    value: '_b2c_europe_tech_stack',
  },
  {
    label: 'BEM',
    value: '_bem_tech_stack',
  },
  {
    label: 'BIGACE',
    value: '_bigace_tech_stack',
  },
  {
    label: 'BON Loyalty',
    value: '_bon_loyalty_tech_stack',
  },
  {
    label: 'BOOM',
    value: '_boom_tech_stack',
  },
  {
    label: 'BRT',
    value: '_brt_tech_stack',
  },
  {
    label: 'BSmart',
    value: '_bsmart_tech_stack',
  },
  {
    label: 'Babel',
    value: '_babel_tech_stack',
  },
  {
    label: 'Bablic',
    value: '_bablic_tech_stack',
  },
  {
    label: 'Babylist',
    value: '_babylist_tech_stack',
  },
  {
    label: 'Back In Stock',
    value: '_back_in stock_tech_stack',
  },
  {
    label: 'Backbone.js',
    value: '_backbone.js_tech_stack',
  },
  {
    label: 'Backdrop',
    value: '_backdrop_tech_stack',
  },
  {
    label: 'Baidu Analytics (百度统计)',
    value: '_baidu_analytics (百度统计)_tech_stack',
  },
  {
    label: 'Baidu Maps',
    value: '_baidu_maps_tech_stack',
  },
  {
    label: 'BambooHR',
    value: '_bamboohr_tech_stack',
  },
  {
    label: 'Bambuser',
    value: '_bambuser_tech_stack',
  },
  {
    label: 'BandsInTown Events Widget',
    value: '_bandsintown_events widget_tech_stack',
  },
  {
    label: 'Banshee',
    value: '_banshee_tech_stack',
  },
  {
    label: 'Barba.js',
    value: '_barba.js_tech_stack',
  },
  {
    label: 'Base',
    value: '_base_tech_stack',
  },
  {
    label: 'Basic',
    value: '_basic_tech_stack',
  },
  {
    label: 'Basis Technologies',
    value: '_basis_technologies_tech_stack',
  },
  {
    label: 'Batflat',
    value: '_batflat_tech_stack',
  },
  {
    label: 'Bazaarvoice Curation',
    value: '_bazaarvoice_curation_tech_stack',
  },
  {
    label: 'Bazaarvoice Reviews',
    value: '_bazaarvoice_reviews_tech_stack',
  },
  {
    label: 'Beam AfterSell',
    value: '_beam_aftersell_tech_stack',
  },
  {
    label: 'Beam OutSell',
    value: '_beam_outsell_tech_stack',
  },
  {
    label: 'Beans',
    value: '_beans_tech_stack',
  },
  {
    label: 'Beeketing',
    value: '_beeketing_tech_stack',
  },
  {
    label: 'Beeswax',
    value: '_beeswax_tech_stack',
  },
  {
    label: 'Bentobox',
    value: '_bentobox_tech_stack',
  },
  {
    label: 'Better Price',
    value: '_better_price_tech_stack',
  },
  {
    label: 'Betty Blocks',
    value: '_betty_blocks_tech_stack',
  },
  {
    label: 'Beyable',
    value: '_beyable_tech_stack',
  },
  {
    label: 'BeyondMenu',
    value: '_beyondmenu_tech_stack',
  },
  {
    label: 'Big Cartel',
    value: '_big_cartel_tech_stack',
  },
  {
    label: 'BigCommerce',
    value: '_bigcommerce_tech_stack',
  },
  {
    label: 'Bigware',
    value: '_bigware_tech_stack',
  },
  {
    label: 'Bikayi',
    value: '_bikayi_tech_stack',
  },
  {
    label: 'Billbee',
    value: '_billbee_tech_stack',
  },
  {
    label: 'Birdeye',
    value: '_birdeye_tech_stack',
  },
  {
    label: 'Bitcoin',
    value: '_bitcoin_tech_stack',
  },
  {
    label: 'BiteSpeed',
    value: '_bitespeed_tech_stack',
  },
  {
    label: 'Bitrix24',
    value: '_bitrix24_tech_stack',
  },
  {
    label: 'BittAds',
    value: '_bittads_tech_stack',
  },
  {
    label: 'Bizweb',
    value: '_bizweb_tech_stack',
  },
  {
    label: 'Blackbaud Luminate Online',
    value: '_blackbaud_luminate online_tech_stack',
  },
  {
    label: 'Blade',
    value: '_blade_tech_stack',
  },
  {
    label: 'Blazor',
    value: '_blazor_tech_stack',
  },
  {
    label: 'Blessing Skin',
    value: '_blessing_skin_tech_stack',
  },
  {
    label: 'Blesta',
    value: '_blesta_tech_stack',
  },
  {
    label: 'Blitz',
    value: '_blitz_tech_stack',
  },
  {
    label: 'Blocksy',
    value: '_blocksy_tech_stack',
  },
  {
    label: 'Blogger',
    value: '_blogger_tech_stack',
  },
  {
    label: 'Bloomreach',
    value: '_bloomreach_tech_stack',
  },
  {
    label: 'Bloomreach Discovery',
    value: '_bloomreach_discovery_tech_stack',
  },
  {
    label: 'Blossom Travel',
    value: '_blossom_travel_tech_stack',
  },
  {
    label: 'Blue',
    value: '_blue_tech_stack',
  },
  {
    label: 'Blue Triangle',
    value: '_blue_triangle_tech_stack',
  },
  {
    label: 'BlueConic',
    value: '_blueconic_tech_stack',
  },
  {
    label: 'Bluecore',
    value: '_bluecore_tech_stack',
  },
  {
    label: 'Bluefish',
    value: '_bluefish_tech_stack',
  },
  {
    label: 'Bluehost',
    value: '_bluehost_tech_stack',
  },
  {
    label: 'Blueknow',
    value: '_blueknow_tech_stack',
  },
  {
    label: 'Blueshift',
    value: '_blueshift_tech_stack',
  },
  {
    label: 'Boats Group',
    value: '_boats_group_tech_stack',
  },
  {
    label: 'Boba.js',
    value: '_boba.js_tech_stack',
  },
  {
    label: 'Bokeh',
    value: '_bokeh_tech_stack',
  },
  {
    label: 'Bokun',
    value: '_bokun_tech_stack',
  },
  {
    label: 'Bold Brain',
    value: '_bold_brain_tech_stack',
  },
  {
    label: 'Bold Bundles',
    value: '_bold_bundles_tech_stack',
  },
  {
    label: 'Bold Chat',
    value: '_bold_chat_tech_stack',
  },
  {
    label: 'Bold Commerce',
    value: '_bold_commerce_tech_stack',
  },
  {
    label: 'Bold Custom Pricing',
    value: '_bold_custom pricing_tech_stack',
  },
  {
    label: 'Bold Motivator',
    value: '_bold_motivator_tech_stack',
  },
  {
    label: 'Bold Product Options',
    value: '_bold_product options_tech_stack',
  },
  {
    label: 'Bold Subscriptions',
    value: '_bold_subscriptions_tech_stack',
  },
  {
    label: 'Bold Upsell',
    value: '_bold_upsell_tech_stack',
  },
  {
    label: 'BoldGrid',
    value: '_boldgrid_tech_stack',
  },
  {
    label: 'Bolt CMS',
    value: '_bolt_cms_tech_stack',
  },
  {
    label: 'Bolt Payments',
    value: '_bolt_payments_tech_stack',
  },
  {
    label: 'Bonfire',
    value: '_bonfire_tech_stack',
  },
  {
    label: 'BookDinners',
    value: '_bookdinners_tech_stack',
  },
  {
    label: 'BookThatApp',
    value: '_bookthatapp_tech_stack',
  },
  {
    label: 'Bookatable',
    value: '_bookatable_tech_stack',
  },
  {
    label: 'Bookero',
    value: '_bookero_tech_stack',
  },
  {
    label: 'Booking.com',
    value: '_booking.com_tech_stack',
  },
  {
    label: 'Booking.com widget',
    value: '_booking.com_widget_tech_stack',
  },
  {
    label: 'Bookingkit',
    value: '_bookingkit_tech_stack',
  },
  {
    label: 'Booksy',
    value: '_booksy_tech_stack',
  },
  {
    label: 'Boomerang',
    value: '_boomerang_tech_stack',
  },
  {
    label: 'Boost Commerce',
    value: '_boost_commerce_tech_stack',
  },
  {
    label: 'Booster Page Speed Optimizer',
    value: '_booster_page speed optimizer_tech_stack',
  },
  {
    label: 'Bootstrap',
    value: '_bootstrap_tech_stack',
  },
  {
    label: 'Bootstrap Icons',
    value: '_bootstrap_icons_tech_stack',
  },
  {
    label: 'Bootstrap Table',
    value: '_bootstrap_table_tech_stack',
  },
  {
    label: 'Booxi',
    value: '_booxi_tech_stack',
  },
  {
    label: 'Borderfree',
    value: '_borderfree_tech_stack',
  },
  {
    label: 'Borlabs Cookie',
    value: '_borlabs_cookie_tech_stack',
  },
  {
    label: 'Botble CMS',
    value: '_botble_cms_tech_stack',
  },
  {
    label: 'Boutiq',
    value: '_boutiq_tech_stack',
  },
  {
    label: 'BowNow',
    value: '_bownow_tech_stack',
  },
  {
    label: 'Boxtal',
    value: '_boxtal_tech_stack',
  },
  {
    label: 'Bpost',
    value: '_bpost_tech_stack',
  },
  {
    label: 'BrainSINS',
    value: '_brainsins_tech_stack',
  },
  {
    label: 'Braintree',
    value: '_braintree_tech_stack',
  },
  {
    label: 'Branch',
    value: '_branch_tech_stack',
  },
  {
    label: 'Brandfolder',
    value: '_brandfolder_tech_stack',
  },
  {
    label: 'Braze',
    value: '_braze_tech_stack',
  },
  {
    label: 'Bread',
    value: '_bread_tech_stack',
  },
  {
    label: 'Breadcrumb NavXT',
    value: '_breadcrumb_navxt_tech_stack',
  },
  {
    label: 'Breinify',
    value: '_breinify_tech_stack',
  },
  {
    label: 'Bricksite',
    value: '_bricksite_tech_stack',
  },
  {
    label: 'BrightEdge',
    value: '_brightedge_tech_stack',
  },
  {
    label: 'BrightInfo',
    value: '_brightinfo_tech_stack',
  },
  {
    label: 'Brightcove',
    value: '_brightcove_tech_stack',
  },
  {
    label: 'Brightspot',
    value: '_brightspot_tech_stack',
  },
  {
    label: 'Broadstreet',
    value: '_broadstreet_tech_stack',
  },
  {
    label: 'Bronto',
    value: '_bronto_tech_stack',
  },
  {
    label: 'Brownie',
    value: '_brownie_tech_stack',
  },
  {
    label: 'Browser-Update.org',
    value: '_browser-update.org_tech_stack',
  },
  {
    label: 'BrowserCMS',
    value: '_browsercms_tech_stack',
  },
  {
    label: 'Bsale',
    value: '_bsale_tech_stack',
  },
  {
    label: 'Bubble',
    value: '_bubble_tech_stack',
  },
  {
    label: 'Budbee',
    value: '_budbee_tech_stack',
  },
  {
    label: 'BuddyPress',
    value: '_buddypress_tech_stack',
  },
  {
    label: 'BugHerd',
    value: '_bugherd_tech_stack',
  },
  {
    label: 'BugSnag',
    value: '_bugsnag_tech_stack',
  },
  {
    label: 'Bugcrowd',
    value: '_bugcrowd_tech_stack',
  },
  {
    label: 'Bugzilla',
    value: '_bugzilla_tech_stack',
  },
  {
    label: 'Builder.io',
    value: '_builder.io_tech_stack',
  },
  {
    label: 'Buildertrend',
    value: '_buildertrend_tech_stack',
  },
  {
    label: 'Bulma',
    value: '_bulma_tech_stack',
  },
  {
    label: 'Bump',
    value: '_bump_tech_stack',
  },
  {
    label: 'Bunny',
    value: '_bunny_tech_stack',
  },
  {
    label: 'Business Catalyst',
    value: '_business_catalyst_tech_stack',
  },
  {
    label: 'Buy me a coffee',
    value: '_buy_me a coffee_tech_stack',
  },
  {
    label: 'BuySellAds',
    value: '_buysellads_tech_stack',
  },
  {
    label: 'Buyapowa',
    value: '_buyapowa_tech_stack',
  },
  {
    label: 'BySide',
    value: '_byside_tech_stack',
  },
  {
    label: 'Bynder',
    value: '_bynder_tech_stack',
  },
  {
    label: 'bSecure',
    value: '_bsecure_tech_stack',
  },
  {
    label: 'basket.js',
    value: '_basket.js_tech_stack',
  },
  {
    label: 'C',
    value: '_c_tech_stack',
  },
  {
    label: 'CCV Shop',
    value: '_ccv_shop_tech_stack',
  },
  {
    label: 'CDN77',
    value: '_cdn77_tech_stack',
  },
  {
    label: 'CFML',
    value: '_cfml_tech_stack',
  },
  {
    label: 'CIVIC',
    value: '_civic_tech_stack',
  },
  {
    label: 'CJDropshipping app',
    value: '_cjdropshipping_app_tech_stack',
  },
  {
    label: 'CKEditor',
    value: '_ckeditor_tech_stack',
  },
  {
    label: 'CMS Made Simple',
    value: '_cms_made simple_tech_stack',
  },
  {
    label: 'CMSimple',
    value: '_cmsimple_tech_stack',
  },
  {
    label: 'CNZZ',
    value: '_cnzz_tech_stack',
  },
  {
    label: 'CPG Dragonfly',
    value: '_cpg_dragonfly_tech_stack',
  },
  {
    label: 'CS Cart',
    value: '_cs_cart_tech_stack',
  },
  {
    label: 'CSSIgniter Olsen Light',
    value: '_cssigniter_olsen light_tech_stack',
  },
  {
    label: 'CTT',
    value: '_ctt_tech_stack',
  },
  {
    label: 'Caast.tv',
    value: '_caast.tv_tech_stack',
  },
  {
    label: 'CacheFly',
    value: '_cachefly_tech_stack',
  },
  {
    label: 'Caddy',
    value: '_caddy_tech_stack',
  },
  {
    label: 'Cafe24',
    value: '_cafe24_tech_stack',
  },
  {
    label: 'CakePHP',
    value: '_cakephp_tech_stack',
  },
  {
    label: 'Caldera Forms',
    value: '_caldera_forms_tech_stack',
  },
  {
    label: 'CalendarHero',
    value: '_calendarhero_tech_stack',
  },
  {
    label: 'Calendly',
    value: '_calendly_tech_stack',
  },
  {
    label: 'CallRail',
    value: '_callrail_tech_stack',
  },
  {
    label: 'Callbell',
    value: '_callbell_tech_stack',
  },
  {
    label: 'Campaign Monitor',
    value: '_campaign_monitor_tech_stack',
  },
  {
    label: 'Candid Themes Fairy',
    value: '_candid_themes fairy_tech_stack',
  },
  {
    label: 'Canto',
    value: '_canto_tech_stack',
  },
  {
    label: 'Captch Me',
    value: '_captch_me_tech_stack',
  },
  {
    label: 'Carbon Ads',
    value: '_carbon_ads_tech_stack',
  },
  {
    label: 'CareCart',
    value: '_carecart_tech_stack',
  },
  {
    label: 'CareCart Sales Pop Up',
    value: '_carecart_sales pop up_tech_stack',
  },
  {
    label: 'Cargo',
    value: '_cargo_tech_stack',
  },
  {
    label: 'Carrd',
    value: '_carrd_tech_stack',
  },
  {
    label: 'Carro',
    value: '_carro_tech_stack',
  },
  {
    label: 'Cart Functionality',
    value: '_cart_functionality_tech_stack',
  },
  {
    label: 'Cart.com',
    value: '_cart.com_tech_stack',
  },
  {
    label: 'Cart.js',
    value: '_cart.js_tech_stack',
  },
  {
    label: 'CartKit',
    value: '_cartkit_tech_stack',
  },
  {
    label: 'CartStack',
    value: '_cartstack_tech_stack',
  },
  {
    label: 'Carts Guru',
    value: '_carts_guru_tech_stack',
  },
  {
    label: 'Cashew Payments',
    value: '_cashew_payments_tech_stack',
  },
  {
    label: 'Catberry.js',
    value: '_catberry.js_tech_stack',
  },
  {
    label: 'Catch',
    value: '_catch_tech_stack',
  },
  {
    label: 'Catch Themes Catch Box',
    value: '_catch_themes catch box_tech_stack',
  },
  {
    label: 'Catch Themes Fotografie',
    value: '_catch_themes fotografie_tech_stack',
  },
  {
    label: 'Cecil',
    value: '_cecil_tech_stack',
  },
  {
    label: 'Celeritas',
    value: '_celeritas_tech_stack',
  },
  {
    label: 'Celum',
    value: '_celum_tech_stack',
  },
  {
    label: 'Cendyn',
    value: '_cendyn_tech_stack',
  },
  {
    label: 'Censhare',
    value: '_censhare_tech_stack',
  },
  {
    label: 'CentOS',
    value: '_centos_tech_stack',
  },
  {
    label: 'Centminmod',
    value: '_centminmod_tech_stack',
  },
  {
    label: 'Chabokan',
    value: '_chabokan_tech_stack',
  },
  {
    label: 'Chakra UI',
    value: '_chakra_ui_tech_stack',
  },
  {
    label: 'Chameleon',
    value: '_chameleon_tech_stack',
  },
  {
    label: 'Chameleon system',
    value: '_chameleon_system_tech_stack',
  },
  {
    label: 'Chamilo',
    value: '_chamilo_tech_stack',
  },
  {
    label: 'ChannelAdvisor',
    value: '_channeladvisor_tech_stack',
  },
  {
    label: 'ChannelApe',
    value: '_channelape_tech_stack',
  },
  {
    label: 'Chaport',
    value: '_chaport_tech_stack',
  },
  {
    label: 'ChargeAfter',
    value: '_chargeafter_tech_stack',
  },
  {
    label: 'Chargebee',
    value: '_chargebee_tech_stack',
  },
  {
    label: 'Chart.js',
    value: '_chart.js_tech_stack',
  },
  {
    label: 'Chartbeat',
    value: '_chartbeat_tech_stack',
  },
  {
    label: 'Chatango',
    value: '_chatango_tech_stack',
  },
  {
    label: 'Chatra',
    value: '_chatra_tech_stack',
  },
  {
    label: 'Chatwoot',
    value: '_chatwoot_tech_stack',
  },
  {
    label: 'Checkfront',
    value: '_checkfront_tech_stack',
  },
  {
    label: 'Checkout.com',
    value: '_checkout.com_tech_stack',
  },
  {
    label: 'Chekkit',
    value: '_chekkit_tech_stack',
  },
  {
    label: 'Cherokee',
    value: '_cherokee_tech_stack',
  },
  {
    label: 'CherryPy',
    value: '_cherrypy_tech_stack',
  },
  {
    label: 'Chevereto',
    value: '_chevereto_tech_stack',
  },
  {
    label: 'Chili Piper',
    value: '_chili_piper_tech_stack',
  },
  {
    label: 'Chinese Menu Online',
    value: '_chinese_menu online_tech_stack',
  },
  {
    label: 'Chitika',
    value: '_chitika_tech_stack',
  },
  {
    label: 'Choices',
    value: '_choices_tech_stack',
  },
  {
    label: 'Chord',
    value: '_chord_tech_stack',
  },
  {
    label: 'Chorus',
    value: '_chorus_tech_stack',
  },
  {
    label: 'Chronofresh',
    value: '_chronofresh_tech_stack',
  },
  {
    label: 'Chronopost',
    value: '_chronopost_tech_stack',
  },
  {
    label: 'ChurnZero',
    value: '_churnzero_tech_stack',
  },
  {
    label: 'CitrusPay',
    value: '_citruspay_tech_stack',
  },
  {
    label: 'City Hive',
    value: '_city_hive_tech_stack',
  },
  {
    label: 'CityMail',
    value: '_citymail_tech_stack',
  },
  {
    label: 'Ckan',
    value: '_ckan_tech_stack',
  },
  {
    label: 'Clarity',
    value: '_clarity_tech_stack',
  },
  {
    label: 'Classeh',
    value: '_classeh_tech_stack',
  },
  {
    label: 'Classy',
    value: '_classy_tech_stack',
  },
  {
    label: 'ClearSale',
    value: '_clearsale_tech_stack',
  },
  {
    label: 'Clearbit Reveal',
    value: '_clearbit_reveal_tech_stack',
  },
  {
    label: 'Clerk.io',
    value: '_clerk.io_tech_stack',
  },
  {
    label: 'CleverTap',
    value: '_clevertap_tech_stack',
  },
  {
    label: 'Cleverbridge',
    value: '_cleverbridge_tech_stack',
  },
  {
    label: 'ClickCease',
    value: '_clickcease_tech_stack',
  },
  {
    label: 'ClickDimensions',
    value: '_clickdimensions_tech_stack',
  },
  {
    label: 'ClickFunnels',
    value: '_clickfunnels_tech_stack',
  },
  {
    label: 'ClickHeat',
    value: '_clickheat_tech_stack',
  },
  {
    label: 'ClickTale',
    value: '_clicktale_tech_stack',
  },
  {
    label: 'Clickbank',
    value: '_clickbank_tech_stack',
  },
  {
    label: 'Clicky',
    value: '_clicky_tech_stack',
  },
  {
    label: 'ClientJS',
    value: '_clientjs_tech_stack',
  },
  {
    label: 'Clinch',
    value: '_clinch_tech_stack',
  },
  {
    label: 'Clipboard.js',
    value: '_clipboard.js_tech_stack',
  },
  {
    label: 'Clockwork',
    value: '_clockwork_tech_stack',
  },
  {
    label: 'CloudCart',
    value: '_cloudcart_tech_stack',
  },
  {
    label: 'CloudSuite',
    value: '_cloudsuite_tech_stack',
  },
  {
    label: 'Cloudbeds',
    value: '_cloudbeds_tech_stack',
  },
  {
    label: 'Cloudera',
    value: '_cloudera_tech_stack',
  },
  {
    label: 'Cloudflare',
    value: '_cloudflare_tech_stack',
  },
  {
    label: 'Cloudflare Bot Management',
    value: '_cloudflare_bot management_tech_stack',
  },
  {
    label: 'Cloudflare Browser Insights',
    value: '_cloudflare_browser insights_tech_stack',
  },
  {
    label: 'Cloudflare Network Error Logging',
    value: '_cloudflare_network error logging_tech_stack',
  },
  {
    label: 'Cloudimage',
    value: '_cloudimage_tech_stack',
  },
  {
    label: 'Cloudinary',
    value: '_cloudinary_tech_stack',
  },
  {
    label: 'Cloudways',
    value: '_cloudways_tech_stack',
  },
  {
    label: 'Cloverly',
    value: '_cloverly_tech_stack',
  },
  {
    label: 'Cluep',
    value: '_cluep_tech_stack',
  },
  {
    label: 'ClustrMaps Widget',
    value: '_clustrmaps_widget_tech_stack',
  },
  {
    label: 'CoConstruct',
    value: '_coconstruct_tech_stack',
  },
  {
    label: 'CoRover',
    value: '_corover_tech_stack',
  },
  {
    label: 'Coaster CMS',
    value: '_coaster_cms_tech_stack',
  },
  {
    label: 'Cococart',
    value: '_cococart_tech_stack',
  },
  {
    label: 'CoconutSoftware',
    value: '_coconutsoftware_tech_stack',
  },
  {
    label: 'Cocos2d',
    value: '_cocos2d_tech_stack',
  },
  {
    label: 'CodeIgniter',
    value: '_codeigniter_tech_stack',
  },
  {
    label: 'CodeMirror',
    value: '_codemirror_tech_stack',
  },
  {
    label: 'CodeSandbox',
    value: '_codesandbox_tech_stack',
  },
  {
    label: 'Coin Currency Converter',
    value: '_coin_currency converter_tech_stack',
  },
  {
    label: 'CoinHive',
    value: '_coinhive_tech_stack',
  },
  {
    label: 'CoinHive Captcha',
    value: '_coinhive_captcha_tech_stack',
  },
  {
    label: 'Coinbase Commerce',
    value: '_coinbase_commerce_tech_stack',
  },
  {
    label: 'Coinhave',
    value: '_coinhave_tech_stack',
  },
  {
    label: 'Coinimp',
    value: '_coinimp_tech_stack',
  },
  {
    label: 'Colibri WP',
    value: '_colibri_wp_tech_stack',
  },
  {
    label: 'Colis Privé',
    value: '_colis_privé_tech_stack',
  },
  {
    label: 'Colissimo',
    value: '_colissimo_tech_stack',
  },
  {
    label: 'ColorMag',
    value: '_colormag_tech_stack',
  },
  {
    label: 'ColorMeShop',
    value: '_colormeshop_tech_stack',
  },
  {
    label: 'Colorlib Activello',
    value: '_colorlib_activello_tech_stack',
  },
  {
    label: 'Colorlib Illdy',
    value: '_colorlib_illdy_tech_stack',
  },
  {
    label: 'Colorlib Shapely',
    value: '_colorlib_shapely_tech_stack',
  },
  {
    label: 'Colorlib Sparkling',
    value: '_colorlib_sparkling_tech_stack',
  },
  {
    label: 'Colorlib Travelify',
    value: '_colorlib_travelify_tech_stack',
  },
  {
    label: 'Combeenation',
    value: '_combeenation_tech_stack',
  },
  {
    label: 'Comm100',
    value: '_comm100_tech_stack',
  },
  {
    label: 'Commanders Act TagCommander',
    value: '_commanders_act tagcommander_tech_stack',
  },
  {
    label: 'Commanders Act TrustCommander',
    value: '_commanders_act trustcommander_tech_stack',
  },
  {
    label: 'Commerce Server',
    value: '_commerce_server_tech_stack',
  },
  {
    label: 'Commerce.js',
    value: '_commerce.js_tech_stack',
  },
  {
    label: 'Commerce7',
    value: '_commerce7_tech_stack',
  },
  {
    label: 'Complianz',
    value: '_complianz_tech_stack',
  },
  {
    label: 'Concrete CMS',
    value: '_concrete_cms_tech_stack',
  },
  {
    label: 'Conekta',
    value: '_conekta_tech_stack',
  },
  {
    label: 'Confer With',
    value: '_confer_with_tech_stack',
  },
  {
    label: 'Conjured',
    value: '_conjured_tech_stack',
  },
  {
    label: 'Connectif',
    value: '_connectif_tech_stack',
  },
  {
    label: 'Constant Contact',
    value: '_constant_contact_tech_stack',
  },
  {
    label: 'Contabo',
    value: '_contabo_tech_stack',
  },
  {
    label: 'Contact Form 7',
    value: '_contact_form 7_tech_stack',
  },
  {
    label: 'Contao',
    value: '_contao_tech_stack',
  },
  {
    label: 'Contenido',
    value: '_contenido_tech_stack',
  },
  {
    label: 'Contensis',
    value: '_contensis_tech_stack',
  },
  {
    label: 'ContentBox',
    value: '_contentbox_tech_stack',
  },
  {
    label: 'ContentStudio',
    value: '_contentstudio_tech_stack',
  },
  {
    label: 'Contentful',
    value: '_contentful_tech_stack',
  },
  {
    label: 'Contently',
    value: '_contently_tech_stack',
  },
  {
    label: 'Contentsquare',
    value: '_contentsquare_tech_stack',
  },
  {
    label: 'Contentstack',
    value: '_contentstack_tech_stack',
  },
  {
    label: 'Contlo',
    value: '_contlo_tech_stack',
  },
  {
    label: 'Conversant Consent Tool',
    value: '_conversant_consent tool_tech_stack',
  },
  {
    label: 'Convert',
    value: '_convert_tech_stack',
  },
  {
    label: 'ConvertFlow',
    value: '_convertflow_tech_stack',
  },
  {
    label: 'ConvertKit',
    value: '_convertkit_tech_stack',
  },
  {
    label: 'Convertcart',
    value: '_convertcart_tech_stack',
  },
  {
    label: 'Convertr',
    value: '_convertr_tech_stack',
  },
  {
    label: 'ConveyThis',
    value: '_conveythis_tech_stack',
  },
  {
    label: 'Cookie Information',
    value: '_cookie_information_tech_stack',
  },
  {
    label: 'Cookie Information plugin',
    value: '_cookie_information plugin_tech_stack',
  },
  {
    label: 'Cookie Notice',
    value: '_cookie_notice_tech_stack',
  },
  {
    label: 'Cookie Script',
    value: '_cookie_script_tech_stack',
  },
  {
    label: 'CookieFirst',
    value: '_cookiefirst_tech_stack',
  },
  {
    label: 'CookieHub',
    value: '_cookiehub_tech_stack',
  },
  {
    label: 'CookieYes',
    value: '_cookieyes_tech_stack',
  },
  {
    label: 'Cookiebot',
    value: '_cookiebot_tech_stack',
  },
  {
    label: 'Cooladata',
    value: '_cooladata_tech_stack',
  },
  {
    label: 'Coppermine',
    value: '_coppermine_tech_stack',
  },
  {
    label: 'CopyPoison',
    value: '_copypoison_tech_stack',
  },
  {
    label: 'Corebine',
    value: '_corebine_tech_stack',
  },
  {
    label: 'Correos',
    value: '_correos_tech_stack',
  },
  {
    label: 'Correos Ecommerce',
    value: '_correos_ecommerce_tech_stack',
  },
  {
    label: 'Cosmoshop',
    value: '_cosmoshop_tech_stack',
  },
  {
    label: 'Cotonti',
    value: '_cotonti_tech_stack',
  },
  {
    label: 'CouchDB',
    value: '_couchdb_tech_stack',
  },
  {
    label: 'Countly',
    value: '_countly_tech_stack',
  },
  {
    label: 'Coureon',
    value: '_coureon_tech_stack',
  },
  {
    label: 'Coveo',
    value: '_coveo_tech_stack',
  },
  {
    label: 'CoverManager',
    value: '_covermanager_tech_stack',
  },
  {
    label: 'Covet.pics',
    value: '_covet.pics_tech_stack',
  },
  {
    label: 'Cowboy',
    value: '_cowboy_tech_stack',
  },
  {
    label: 'Cozy AntiTheft',
    value: '_cozy_antitheft_tech_stack',
  },
  {
    label: 'CppCMS',
    value: '_cppcms_tech_stack',
  },
  {
    label: 'Craft CMS',
    value: '_craft_cms_tech_stack',
  },
  {
    label: 'Craft Commerce',
    value: '_craft_commerce_tech_stack',
  },
  {
    label: 'Cratejoy',
    value: '_cratejoy_tech_stack',
  },
  {
    label: 'Crazy Egg',
    value: '_crazy_egg_tech_stack',
  },
  {
    label: 'CreateJS',
    value: '_createjs_tech_stack',
  },
  {
    label: 'Creativ.eMail',
    value: '_creativ.email_tech_stack',
  },
  {
    label: 'Crisp Live Chat',
    value: '_crisp_live chat_tech_stack',
  },
  {
    label: 'Criteo',
    value: '_criteo_tech_stack',
  },
  {
    label: 'Crobox',
    value: '_crobox_tech_stack',
  },
  {
    label: 'Cross Pixel',
    value: '_cross_pixel_tech_stack',
  },
  {
    label: 'Cross Sell',
    value: '_cross_sell_tech_stack',
  },
  {
    label: 'CrossBox',
    value: '_crossbox_tech_stack',
  },
  {
    label: 'CrownPeak',
    value: '_crownpeak_tech_stack',
  },
  {
    label: 'Cryout Creations Bravada',
    value: '_cryout_creations bravada_tech_stack',
  },
  {
    label: 'Cryout Creations Fluida',
    value: '_cryout_creations fluida_tech_stack',
  },
  {
    label: 'Cryout Creations Mantra',
    value: '_cryout_creations mantra_tech_stack',
  },
  {
    label: 'Cryout Creations Parabola',
    value: '_cryout_creations parabola_tech_stack',
  },
  {
    label: 'Crypto-Loot',
    value: '_crypto-loot_tech_stack',
  },
  {
    label: 'CubeCart',
    value: '_cubecart_tech_stack',
  },
  {
    label: 'Cubyn',
    value: '_cubyn_tech_stack',
  },
  {
    label: 'Cufon',
    value: '_cufon_tech_stack',
  },
  {
    label: 'Custom Fonts',
    value: '_custom_fonts_tech_stack',
  },
  {
    label: 'Customer.io',
    value: '_customer.io_tech_stack',
  },
  {
    label: 'Customily',
    value: '_customily_tech_stack',
  },
  {
    label: 'Cxense',
    value: '_cxense_tech_stack',
  },
  {
    label: 'CyberChimps Responsive',
    value: '_cyberchimps_responsive_tech_stack',
  },
  {
    label: 'Cybersource',
    value: '_cybersource_tech_stack',
  },
  {
    label: 'Czater',
    value: '_czater_tech_stack',
  },
  {
    label: 'cPanel',
    value: '_cpanel_tech_stack',
  },
  {
    label: 'cState',
    value: '_cstate_tech_stack',
  },
  {
    label: 'cdnjs',
    value: '_cdnjs_tech_stack',
  },
  {
    label: 'cgit',
    value: '_cgit_tech_stack',
  },
  {
    label: 'clickio',
    value: '_clickio_tech_stack',
  },
  {
    label: 'comScore',
    value: '_comscore_tech_stack',
  },
  {
    label: 'commercelayer',
    value: '_commercelayer_tech_stack',
  },
  {
    label: 'commercetools',
    value: '_commercetools_tech_stack',
  },
  {
    label: 'core-js',
    value: '_core-js_tech_stack',
  },
  {
    label: 'crypto-js',
    value: '_crypto-js_tech_stack',
  },
  {
    label: 'D3',
    value: '_d3_tech_stack',
  },
  {
    label: 'DDoS-Guard',
    value: '_ddos-guard_tech_stack',
  },
  {
    label: 'DERAK.CLOUD',
    value: '_derak.cloud_tech_stack',
  },
  {
    label: 'DHL',
    value: '_dhl_tech_stack',
  },
  {
    label: 'DHTMLX',
    value: '_dhtmlx_tech_stack',
  },
  {
    label: 'DM Polopoly',
    value: '_dm_polopoly_tech_stack',
  },
  {
    label: 'DNN',
    value: '_dnn_tech_stack',
  },
  {
    label: 'DPD',
    value: '_dpd_tech_stack',
  },
  {
    label: 'DPlayer',
    value: '_dplayer_tech_stack',
  },
  {
    label: 'DTScout',
    value: '_dtscout_tech_stack',
  },
  {
    label: 'DX',
    value: '_dx_tech_stack',
  },
  {
    label: 'Dachser',
    value: '_dachser_tech_stack',
  },
  {
    label: 'Daily Deals',
    value: '_daily_deals_tech_stack',
  },
  {
    label: 'DailyKarma',
    value: '_dailykarma_tech_stack',
  },
  {
    label: 'Dailymotion',
    value: '_dailymotion_tech_stack',
  },
  {
    label: 'Dancer',
    value: '_dancer_tech_stack',
  },
  {
    label: 'Danneo CMS',
    value: '_danneo_cms_tech_stack',
  },
  {
    label: 'Daphne',
    value: '_daphne_tech_stack',
  },
  {
    label: 'Dart',
    value: '_dart_tech_stack',
  },
  {
    label: 'Darwin',
    value: '_darwin_tech_stack',
  },
  {
    label: 'DataLife Engine',
    value: '_datalife_engine_tech_stack',
  },
  {
    label: 'DataMilk',
    value: '_datamilk_tech_stack',
  },
  {
    label: 'DataTables',
    value: '_datatables_tech_stack',
  },
  {
    label: 'Datadog',
    value: '_datadog_tech_stack',
  },
  {
    label: 'Datadome',
    value: '_datadome_tech_stack',
  },
  {
    label: 'DatoCMS',
    value: '_datocms_tech_stack',
  },
  {
    label: 'Day.js',
    value: '_day.js_tech_stack',
  },
  {
    label: 'Dealer Spike',
    value: '_dealer_spike_tech_stack',
  },
  {
    label: 'Debian',
    value: '_debian_tech_stack',
  },
  {
    label: 'Decibel',
    value: '_decibel_tech_stack',
  },
  {
    label: 'DedeCMS',
    value: '_dedecms_tech_stack',
  },
  {
    label: 'Delivengo',
    value: '_delivengo_tech_stack',
  },
  {
    label: 'Deliverr',
    value: '_deliverr_tech_stack',
  },
  {
    label: 'Demandbase',
    value: '_demandbase_tech_stack',
  },
  {
    label: 'Deno',
    value: '_deno_tech_stack',
  },
  {
    label: 'Deno Deploy',
    value: '_deno_deploy_tech_stack',
  },
  {
    label: 'Deta',
    value: '_deta_tech_stack',
  },
  {
    label: 'Detectify',
    value: '_detectify_tech_stack',
  },
  {
    label: 'Deutsche Post',
    value: '_deutsche_post_tech_stack',
  },
  {
    label: 'Dianomi',
    value: '_dianomi_tech_stack',
  },
  {
    label: 'Didomi',
    value: '_didomi_tech_stack',
  },
  {
    label: 'Digest',
    value: '_digest_tech_stack',
  },
  {
    label: 'DigiCert',
    value: '_digicert_tech_stack',
  },
  {
    label: 'Digistore24',
    value: '_digistore24_tech_stack',
  },
  {
    label: 'Digital Showroom',
    value: '_digital_showroom_tech_stack',
  },
  {
    label: 'DigitalRiver',
    value: '_digitalriver_tech_stack',
  },
  {
    label: 'DirectAdmin',
    value: '_directadmin_tech_stack',
  },
  {
    label: 'Directus',
    value: '_directus_tech_stack',
  },
  {
    label: 'Discourse',
    value: '_discourse_tech_stack',
  },
  {
    label: 'Discuz! X',
    value: '_discuz!_x_tech_stack',
  },
  {
    label: 'Disqus',
    value: '_disqus_tech_stack',
  },
  {
    label: 'Distributor',
    value: '_distributor_tech_stack',
  },
  {
    label: 'District M',
    value: '_district_m_tech_stack',
  },
  {
    label: 'Dito',
    value: '_dito_tech_stack',
  },
  {
    label: 'Divi',
    value: '_divi_tech_stack',
  },
  {
    label: 'DivideBuy',
    value: '_dividebuy_tech_stack',
  },
  {
    label: 'Divido',
    value: '_divido_tech_stack',
  },
  {
    label: 'Django',
    value: '_django_tech_stack',
  },
  {
    label: 'Django CMS',
    value: '_django_cms_tech_stack',
  },
  {
    label: 'DocFX',
    value: '_docfx_tech_stack',
  },
  {
    label: 'Docker',
    value: '_docker_tech_stack',
  },
  {
    label: 'DocuSign',
    value: '_docusign_tech_stack',
  },
  {
    label: 'Docusaurus',
    value: '_docusaurus_tech_stack',
  },
  {
    label: 'Dojo',
    value: '_dojo_tech_stack',
  },
  {
    label: 'Dokan',
    value: '_dokan_tech_stack',
  },
  {
    label: 'Dokeos',
    value: '_dokeos_tech_stack',
  },
  {
    label: 'DokuWiki',
    value: '_dokuwiki_tech_stack',
  },
  {
    label: 'DomainFactory',
    value: '_domainfactory_tech_stack',
  },
  {
    label: 'Dominate WooCommerce',
    value: '_dominate_woocommerce_tech_stack',
  },
  {
    label: 'Doofinder',
    value: '_doofinder_tech_stack',
  },
  {
    label: 'Dotclear',
    value: '_dotclear_tech_stack',
  },
  {
    label: 'Dotdigital',
    value: '_dotdigital_tech_stack',
  },
  {
    label: 'Dotdigital Chat',
    value: '_dotdigital_chat_tech_stack',
  },
  {
    label: 'DoubleClick Ad Exchange (AdX)',
    value: '_doubleclick_ad exchange (adx)_tech_stack',
  },
  {
    label: 'DoubleClick Campaign Manager (DCM)',
    value: '_doubleclick_campaign manager (dcm)_tech_stack',
  },
  {
    label: 'DoubleClick Floodlight',
    value: '_doubleclick_floodlight_tech_stack',
  },
  {
    label: 'DoubleClick for Publishers (DFP)',
    value: '_doubleclick_for publishers (dfp)_tech_stack',
  },
  {
    label: 'DoubleVerify',
    value: '_doubleverify_tech_stack',
  },
  {
    label: 'Dovetale',
    value: '_dovetale_tech_stack',
  },
  {
    label: 'Doxygen',
    value: '_doxygen_tech_stack',
  },
  {
    label: 'Draft.js',
    value: '_draft.js_tech_stack',
  },
  {
    label: 'Draftpress HFCM',
    value: '_draftpress_hfcm_tech_stack',
  },
  {
    label: 'Dragon',
    value: '_dragon_tech_stack',
  },
  {
    label: 'Drapr',
    value: '_drapr_tech_stack',
  },
  {
    label: 'DreamHost',
    value: '_dreamhost_tech_stack',
  },
  {
    label: 'DreamWeaver',
    value: '_dreamweaver_tech_stack',
  },
  {
    label: 'Dreamdata',
    value: '_dreamdata_tech_stack',
  },
  {
    label: 'Drift',
    value: '_drift_tech_stack',
  },
  {
    label: 'Drip',
    value: '_drip_tech_stack',
  },
  {
    label: 'Drop A Hint',
    value: '_drop_a hint_tech_stack',
  },
  {
    label: 'DropInBlog',
    value: '_dropinblog_tech_stack',
  },
  {
    label: 'Dropbox',
    value: '_dropbox_tech_stack',
  },
  {
    label: 'Dropzone',
    value: '_dropzone_tech_stack',
  },
  {
    label: 'Droxit',
    value: '_droxit_tech_stack',
  },
  {
    label: 'Droz Bot',
    value: '_droz_bot_tech_stack',
  },
  {
    label: 'Drupal',
    value: '_drupal_tech_stack',
  },
  {
    label: 'Drupal Commerce',
    value: '_drupal_commerce_tech_stack',
  },
  {
    label: 'Drupal Multisite',
    value: '_drupal_multisite_tech_stack',
  },
  {
    label: 'Duda',
    value: '_duda_tech_stack',
  },
  {
    label: 'Duel',
    value: '_duel_tech_stack',
  },
  {
    label: 'Dukaan',
    value: '_dukaan_tech_stack',
  },
  {
    label: 'Duopana',
    value: '_duopana_tech_stack',
  },
  {
    label: 'Dynamic Yield',
    value: '_dynamic_yield_tech_stack',
  },
  {
    label: 'Dynamicweb',
    value: '_dynamicweb_tech_stack',
  },
  {
    label: 'Dynatrace',
    value: '_dynatrace_tech_stack',
  },
  {
    label: 'Dynatrace RUM',
    value: '_dynatrace_rum_tech_stack',
  },
  {
    label: 'Dyte',
    value: '_dyte_tech_stack',
  },
  {
    label: 'db-ip',
    value: '_db-ip_tech_stack',
  },
  {
    label: 'decimal.js',
    value: '_decimal.js_tech_stack',
  },
  {
    label: 'deepMiner',
    value: '_deepminer_tech_stack',
  },
  {
    label: 'EC-CUBE',
    value: '_ec-cube_tech_stack',
  },
  {
    label: 'ECharts',
    value: '_echarts_tech_stack',
  },
  {
    label: 'EKM',
    value: '_ekm_tech_stack',
  },
  {
    label: 'ELOG',
    value: '_elog_tech_stack',
  },
  {
    label: 'ELOG HTTP',
    value: '_elog_http_tech_stack',
  },
  {
    label: 'EPiServer',
    value: '_episerver_tech_stack',
  },
  {
    label: 'EPrints',
    value: '_eprints_tech_stack',
  },
  {
    label: 'ERPNext',
    value: '_erpnext_tech_stack',
  },
  {
    label: 'ESW',
    value: '_esw_tech_stack',
  },
  {
    label: 'EWWW Image Optimizer',
    value: '_ewww_image optimizer_tech_stack',
  },
  {
    label: 'EX.CO',
    value: '_ex.co_tech_stack',
  },
  {
    label: 'Easy Hide PayPal',
    value: '_easy_hide paypal_tech_stack',
  },
  {
    label: 'Easy Redirects',
    value: '_easy_redirects_tech_stack',
  },
  {
    label: 'EasyDigitalDownloads',
    value: '_easydigitaldownloads_tech_stack',
  },
  {
    label: 'EasyEngine',
    value: '_easyengine_tech_stack',
  },
  {
    label: 'EasyStore',
    value: '_easystore_tech_stack',
  },
  {
    label: 'Easylog',
    value: '_easylog_tech_stack',
  },
  {
    label: 'Ebasnet',
    value: '_ebasnet_tech_stack',
  },
  {
    label: 'Ecovium',
    value: '_ecovium_tech_stack',
  },
  {
    label: 'Ecwid',
    value: '_ecwid_tech_stack',
  },
  {
    label: 'EdgeCast',
    value: '_edgecast_tech_stack',
  },
  {
    label: 'Edgio',
    value: '_edgio_tech_stack',
  },
  {
    label: 'Editor.js',
    value: '_editor.js_tech_stack',
  },
  {
    label: 'Eggplant',
    value: '_eggplant_tech_stack',
  },
  {
    label: 'Elastic APM',
    value: '_elastic_apm_tech_stack',
  },
  {
    label: 'ElasticPress',
    value: '_elasticpress_tech_stack',
  },
  {
    label: 'ElasticSuite',
    value: '_elasticsuite_tech_stack',
  },
  {
    label: 'Elasticsearch',
    value: '_elasticsearch_tech_stack',
  },
  {
    label: 'Elcodi',
    value: '_elcodi_tech_stack',
  },
  {
    label: 'Eleanor CMS',
    value: '_eleanor_cms_tech_stack',
  },
  {
    label: 'Element UI',
    value: '_element_ui_tech_stack',
  },
  {
    label: 'Elementor',
    value: '_elementor_tech_stack',
  },
  {
    label: 'Elementor Header & Footer Builder',
    value: '_elementor_header & footer builder_tech_stack',
  },
  {
    label: 'Elevar',
    value: '_elevar_tech_stack',
  },
  {
    label: 'Eleventy',
    value: '_eleventy_tech_stack',
  },
  {
    label: 'Elfsight',
    value: '_elfsight_tech_stack',
  },
  {
    label: 'Elm',
    value: '_elm_tech_stack',
  },
  {
    label: 'Elm-ui',
    value: '_elm-ui_tech_stack',
  },
  {
    label: 'Eloqua',
    value: '_eloqua_tech_stack',
  },
  {
    label: 'Emarsys',
    value: '_emarsys_tech_stack',
  },
  {
    label: 'Ematic Solutions',
    value: '_ematic_solutions_tech_stack',
  },
  {
    label: 'EmbedPlus',
    value: '_embedplus_tech_stack',
  },
  {
    label: 'EmbedSocial',
    value: '_embedsocial_tech_stack',
  },
  {
    label: 'EmbedThis Appweb',
    value: '_embedthis_appweb_tech_stack',
  },
  {
    label: 'Embedly',
    value: '_embedly_tech_stack',
  },
  {
    label: 'Ember.js',
    value: '_ember.js_tech_stack',
  },
  {
    label: 'Emotion',
    value: '_emotion_tech_stack',
  },
  {
    label: 'Emotive',
    value: '_emotive_tech_stack',
  },
  {
    label: 'Enable',
    value: '_enable_tech_stack',
  },
  {
    label: 'Endurance Page Cache',
    value: '_endurance_page cache_tech_stack',
  },
  {
    label: 'Engagio',
    value: '_engagio_tech_stack',
  },
  {
    label: 'Engintron',
    value: '_engintron_tech_stack',
  },
  {
    label: 'Enigma',
    value: '_enigma_tech_stack',
  },
  {
    label: 'Enjin CMS',
    value: '_enjin_cms_tech_stack',
  },
  {
    label: 'Enlistly',
    value: '_enlistly_tech_stack',
  },
  {
    label: 'Ensi',
    value: '_ensi_tech_stack',
  },
  {
    label: 'Ensighten',
    value: '_ensighten_tech_stack',
  },
  {
    label: 'Envialia',
    value: '_envialia_tech_stack',
  },
  {
    label: 'Envo Shop',
    value: '_envo_shop_tech_stack',
  },
  {
    label: 'Envo Storefront',
    value: '_envo_storefront_tech_stack',
  },
  {
    label: 'Envo eCommerce',
    value: '_envo_ecommerce_tech_stack',
  },
  {
    label: 'Envoy',
    value: '_envoy_tech_stack',
  },
  {
    label: 'Envybox',
    value: '_envybox_tech_stack',
  },
  {
    label: 'Enyo',
    value: '_enyo_tech_stack',
  },
  {
    label: 'Epoch',
    value: '_epoch_tech_stack',
  },
  {
    label: 'Epom',
    value: '_epom_tech_stack',
  },
  {
    label: 'EqualWeb',
    value: '_equalweb_tech_stack',
  },
  {
    label: 'EraofEcom Cartroids',
    value: '_eraofecom_cartroids_tech_stack',
  },
  {
    label: 'EraofEcom MTL',
    value: '_eraofecom_mtl_tech_stack',
  },
  {
    label: 'EraofEcom WinAds',
    value: '_eraofecom_winads_tech_stack',
  },
  {
    label: 'Erlang',
    value: '_erlang_tech_stack',
  },
  {
    label: 'Errorception',
    value: '_errorception_tech_stack',
  },
  {
    label: 'Essent SiteBuilder Pro',
    value: '_essent_sitebuilder pro_tech_stack',
  },
  {
    label: 'Essential Addons for Elementor',
    value: '_essential_addons for elementor_tech_stack',
  },
  {
    label: 'Essential JS 2',
    value: '_essential_js 2_tech_stack',
  },
  {
    label: 'Estore Compare',
    value: '_estore_compare_tech_stack',
  },
  {
    label: 'Estore Shopserve',
    value: '_estore_shopserve_tech_stack',
  },
  {
    label: 'Etherpad',
    value: '_etherpad_tech_stack',
  },
  {
    label: 'Ethers',
    value: '_ethers_tech_stack',
  },
  {
    label: 'EthicalAds',
    value: '_ethicalads_tech_stack',
  },
  {
    label: 'Etix',
    value: '_etix_tech_stack',
  },
  {
    label: 'Etracker',
    value: '_etracker_tech_stack',
  },
  {
    label: 'Everflow',
    value: '_everflow_tech_stack',
  },
  {
    label: 'Eveve',
    value: '_eveve_tech_stack',
  },
  {
    label: 'Evidon',
    value: '_evidon_tech_stack',
  },
  {
    label: 'ExactMetrics',
    value: '_exactmetrics_tech_stack',
  },
  {
    label: 'Exemptify',
    value: '_exemptify_tech_stack',
  },
  {
    label: 'Exhibit',
    value: '_exhibit_tech_stack',
  },
  {
    label: 'ExitIntel',
    value: '_exitintel_tech_stack',
  },
  {
    label: 'ExoClick',
    value: '_exoclick_tech_stack',
  },
  {
    label: 'ExpertRec',
    value: '_expertrec_tech_stack',
  },
  {
    label: 'Exponea',
    value: '_exponea_tech_stack',
  },
  {
    label: 'Express',
    value: '_express_tech_stack',
  },
  {
    label: 'ExpressionEngine',
    value: '_expressionengine_tech_stack',
  },
  {
    label: 'ExtJS',
    value: '_extjs_tech_stack',
  },
  {
    label: 'ExtendThemes Calliope',
    value: '_extendthemes_calliope_tech_stack',
  },
  {
    label: 'ExtendThemes EmpowerWP',
    value: '_extendthemes_empowerwp_tech_stack',
  },
  {
    label: 'ExtendThemes Highlight',
    value: '_extendthemes_highlight_tech_stack',
  },
  {
    label: 'ExtendThemes Materialis',
    value: '_extendthemes_materialis_tech_stack',
  },
  {
    label: 'ExtendThemes Mesmerize',
    value: '_extendthemes_mesmerize_tech_stack',
  },
  {
    label: 'Extendify',
    value: '_extendify_tech_stack',
  },
  {
    label: 'Extole',
    value: '_extole_tech_stack',
  },
  {
    label: 'Ezoic',
    value: '_ezoic_tech_stack',
  },
  {
    label: 'e-Shop Commerce',
    value: '_e-shop_commerce_tech_stack',
  },
  {
    label: 'e-goi',
    value: '_e-goi_tech_stack',
  },
  {
    label: 'e107',
    value: '_e107_tech_stack',
  },
  {
    label: 'eBay Partner Network',
    value: '_ebay_partner network_tech_stack',
  },
  {
    label: 'eCaupo',
    value: '_ecaupo_tech_stack',
  },
  {
    label: 'eClass',
    value: '_eclass_tech_stack',
  },
  {
    label: 'eDokan',
    value: '_edokan_tech_stack',
  },
  {
    label: 'eKomi',
    value: '_ekomi_tech_stack',
  },
  {
    label: 'ePages',
    value: '_epages_tech_stack',
  },
  {
    label: 'eSSENTIAL Accessibility',
    value: '_essential_accessibility_tech_stack',
  },
  {
    label: 'eShopCRM',
    value: '_eshopcrm_tech_stack',
  },
  {
    label: 'eSyndiCat',
    value: '_esyndicat_tech_stack',
  },
  {
    label: 'eWAY Payments',
    value: '_eway_payments_tech_stack',
  },
  {
    label: 'eZ Platform',
    value: '_ez_platform_tech_stack',
  },
  {
    label: 'eZ Publish',
    value: '_ez_publish_tech_stack',
  },
  {
    label: 'ef.js',
    value: '_ef.js_tech_stack',
  },
  {
    label: 'emBlue',
    value: '_emblue_tech_stack',
  },
  {
    label: 'enduro.js',
    value: '_enduro.js_tech_stack',
  },
  {
    label: 'etika',
    value: '_etika_tech_stack',
  },
  {
    label: 'eucookie.eu',
    value: '_eucookie.eu_tech_stack',
  },
  {
    label: 'experiencedCMS',
    value: '_experiencedcms_tech_stack',
  },
  {
    label: 'F5 BigIP',
    value: '_f5_bigip_tech_stack',
  },
  {
    label: 'FARFETCH Black & White',
    value: '_farfetch_black & white_tech_stack',
  },
  {
    label: 'FAST ESP',
    value: '_fast_esp_tech_stack',
  },
  {
    label: 'FAST Search for SharePoint',
    value: '_fast_search for sharepoint_tech_stack',
  },
  {
    label: 'Fabric',
    value: '_fabric_tech_stack',
  },
  {
    label: 'Facebook Ads',
    value: '_facebook_ads_tech_stack',
  },
  {
    label: 'Facebook Chat Plugin',
    value: '_facebook_chat plugin_tech_stack',
  },
  {
    label: 'Facebook Login',
    value: '_facebook_login_tech_stack',
  },
  {
    label: 'Facebook Pay',
    value: '_facebook_pay_tech_stack',
  },
  {
    label: 'Facebook Pixel',
    value: '_facebook_pixel_tech_stack',
  },
  {
    label: 'Facil-iti',
    value: '_facil-iti_tech_stack',
  },
  {
    label: 'Fact Finder',
    value: '_fact_finder_tech_stack',
  },
  {
    label: 'FalguniThemes Nisarg',
    value: '_falgunithemes_nisarg_tech_stack',
  },
  {
    label: 'FameThemes OnePress',
    value: '_famethemes_onepress_tech_stack',
  },
  {
    label: 'FameThemes Screenr',
    value: '_famethemes_screenr_tech_stack',
  },
  {
    label: 'FancyBox',
    value: '_fancybox_tech_stack',
  },
  {
    label: 'Fanplayr',
    value: '_fanplayr_tech_stack',
  },
  {
    label: 'FaraPy',
    value: '_farapy_tech_stack',
  },
  {
    label: 'FareHarbor',
    value: '_fareharbor_tech_stack',
  },
  {
    label: 'Fast Bundle',
    value: '_fast_bundle_tech_stack',
  },
  {
    label: 'Fast Checkout',
    value: '_fast_checkout_tech_stack',
  },
  {
    label: 'FastComet',
    value: '_fastcomet_tech_stack',
  },
  {
    label: 'Fastcommerce',
    value: '_fastcommerce_tech_stack',
  },
  {
    label: 'Fasterize',
    value: '_fasterize_tech_stack',
  },
  {
    label: 'Fastly',
    value: '_fastly_tech_stack',
  },
  {
    label: 'Fastspring',
    value: '_fastspring_tech_stack',
  },
  {
    label: 'Fat Zebra',
    value: '_fat_zebra_tech_stack',
  },
  {
    label: 'Fat-Free Framework',
    value: '_fat-free_framework_tech_stack',
  },
  {
    label: 'Fathom',
    value: '_fathom_tech_stack',
  },
  {
    label: 'Fbits',
    value: '_fbits_tech_stack',
  },
  {
    label: 'FeatherX',
    value: '_featherx_tech_stack',
  },
  {
    label: 'FedEx',
    value: '_fedex_tech_stack',
  },
  {
    label: 'Fedora',
    value: '_fedora_tech_stack',
  },
  {
    label: 'Feedback Fish',
    value: '_feedback_fish_tech_stack',
  },
  {
    label: 'Feefo',
    value: '_feefo_tech_stack',
  },
  {
    label: 'Fenicio',
    value: '_fenicio_tech_stack',
  },
  {
    label: 'Fera',
    value: '_fera_tech_stack',
  },
  {
    label: 'FilePond',
    value: '_filepond_tech_stack',
  },
  {
    label: 'FinanceAds',
    value: '_financeads_tech_stack',
  },
  {
    label: 'Findify',
    value: '_findify_tech_stack',
  },
  {
    label: 'Findmeashoe',
    value: '_findmeashoe_tech_stack',
  },
  {
    label: 'Fing',
    value: '_fing_tech_stack',
  },
  {
    label: 'FingerprintJS',
    value: '_fingerprintjs_tech_stack',
  },
  {
    label: 'FintechOS',
    value: '_fintechos_tech_stack',
  },
  {
    label: 'Firebase',
    value: '_firebase_tech_stack',
  },
  {
    label: 'Fireblade',
    value: '_fireblade_tech_stack',
  },
  {
    label: 'Firepush',
    value: '_firepush_tech_stack',
  },
  {
    label: 'FirstHive',
    value: '_firsthive_tech_stack',
  },
  {
    label: 'FirstImpression.io',
    value: '_firstimpression.io_tech_stack',
  },
  {
    label: 'Fit Analytics',
    value: '_fit_analytics_tech_stack',
  },
  {
    label: 'FlagSmith',
    value: '_flagsmith_tech_stack',
  },
  {
    label: 'Flarum',
    value: '_flarum_tech_stack',
  },
  {
    label: 'Flask',
    value: '_flask_tech_stack',
  },
  {
    label: 'Flat UI',
    value: '_flat_ui_tech_stack',
  },
  {
    label: 'Flazio',
    value: '_flazio_tech_stack',
  },
  {
    label: 'Fleksa',
    value: '_fleksa_tech_stack',
  },
  {
    label: 'FlexCMP',
    value: '_flexcmp_tech_stack',
  },
  {
    label: 'FlexSlider',
    value: '_flexslider_tech_stack',
  },
  {
    label: 'Flickity',
    value: '_flickity_tech_stack',
  },
  {
    label: 'Flits',
    value: '_flits_tech_stack',
  },
  {
    label: 'Flocktory',
    value: '_flocktory_tech_stack',
  },
  {
    label: 'Flow',
    value: '_flow_tech_stack',
  },
  {
    label: 'Flowbite',
    value: '_flowbite_tech_stack',
  },
  {
    label: 'Flowplayer',
    value: '_flowplayer_tech_stack',
  },
  {
    label: 'Flutter',
    value: '_flutter_tech_stack',
  },
  {
    label: 'FluxBB',
    value: '_fluxbb_tech_stack',
  },
  {
    label: 'Fly.io',
    value: '_fly.io_tech_stack',
  },
  {
    label: 'Flyspray',
    value: '_flyspray_tech_stack',
  },
  {
    label: 'Flywheel',
    value: '_flywheel_tech_stack',
  },
  {
    label: 'Fomo',
    value: '_fomo_tech_stack',
  },
  {
    label: 'Font Awesome',
    value: '_font_awesome_tech_stack',
  },
  {
    label: 'Fontify',
    value: '_fontify_tech_stack',
  },
  {
    label: 'FooPlugins FooGallery',
    value: '_fooplugins_foogallery_tech_stack',
  },
  {
    label: 'Food-Ordering.co.uk',
    value: '_food-ordering.co.uk_tech_stack',
  },
  {
    label: 'FoodBooking',
    value: '_foodbooking_tech_stack',
  },
  {
    label: 'Fork Awesome',
    value: '_fork_awesome_tech_stack',
  },
  {
    label: 'Fork CMS',
    value: '_fork_cms_tech_stack',
  },
  {
    label: 'Formidable Form',
    value: '_formidable_form_tech_stack',
  },
  {
    label: 'Formitable',
    value: '_formitable_tech_stack',
  },
  {
    label: 'ForoshGostar',
    value: '_foroshgostar_tech_stack',
  },
  {
    label: 'Forte',
    value: '_forte_tech_stack',
  },
  {
    label: 'Forter',
    value: '_forter_tech_stack',
  },
  {
    label: 'Fortune3',
    value: '_fortune3_tech_stack',
  },
  {
    label: 'Foswiki',
    value: '_foswiki_tech_stack',
  },
  {
    label: 'Four',
    value: '_four_tech_stack',
  },
  {
    label: 'Foursixty',
    value: '_foursixty_tech_stack',
  },
  {
    label: 'Fourthwall',
    value: '_fourthwall_tech_stack',
  },
  {
    label: 'Foxy.io',
    value: '_foxy.io_tech_stack',
  },
  {
    label: 'Framer Sites',
    value: '_framer_sites_tech_stack',
  },
  {
    label: 'France Express',
    value: '_france_express_tech_stack',
  },
  {
    label: 'Frappe',
    value: '_frappe_tech_stack',
  },
  {
    label: 'FreakOut',
    value: '_freakout_tech_stack',
  },
  {
    label: 'FreeBSD',
    value: '_freebsd_tech_stack',
  },
  {
    label: 'FreeTextBox',
    value: '_freetextbox_tech_stack',
  },
  {
    label: 'Freespee',
    value: '_freespee_tech_stack',
  },
  {
    label: 'Frequenceo',
    value: '_frequenceo_tech_stack',
  },
  {
    label: 'Frequently Bought Together',
    value: '_frequently_bought together_tech_stack',
  },
  {
    label: 'Fresco',
    value: '_fresco_tech_stack',
  },
  {
    label: 'Fresh',
    value: '_fresh_tech_stack',
  },
  {
    label: 'Freshchat',
    value: '_freshchat_tech_stack',
  },
  {
    label: 'Freshop',
    value: '_freshop_tech_stack',
  },
  {
    label: 'Freshteam',
    value: '_freshteam_tech_stack',
  },
  {
    label: 'Freshworks CRM',
    value: '_freshworks_crm_tech_stack',
  },
  {
    label: 'Friendbuy',
    value: '_friendbuy_tech_stack',
  },
  {
    label: 'Friendly Captcha',
    value: '_friendly_captcha_tech_stack',
  },
  {
    label: 'Frizbit',
    value: '_frizbit_tech_stack',
  },
  {
    label: 'Froala Editor',
    value: '_froala_editor_tech_stack',
  },
  {
    label: 'FrontPage',
    value: '_frontpage_tech_stack',
  },
  {
    label: 'Frontastic',
    value: '_frontastic_tech_stack',
  },
  {
    label: 'Frontify',
    value: '_frontify_tech_stack',
  },
  {
    label: 'Frontity',
    value: '_frontity_tech_stack',
  },
  {
    label: 'Frosmo',
    value: '_frosmo_tech_stack',
  },
  {
    label: 'FullContact',
    value: '_fullcontact_tech_stack',
  },
  {
    label: 'FullStory',
    value: '_fullstory_tech_stack',
  },
  {
    label: 'Fundiin',
    value: '_fundiin_tech_stack',
  },
  {
    label: 'Fusion Ads',
    value: '_fusion_ads_tech_stack',
  },
  {
    label: 'Future Shop',
    value: '_future_shop_tech_stack',
  },
  {
    label: 'Futurio',
    value: '_futurio_tech_stack',
  },
  {
    label: 'Fynd Platform',
    value: '_fynd_platform_tech_stack',
  },
  {
    label: 'fullPage.js',
    value: '_fullpage.js_tech_stack',
  },
  {
    label: 'GEODIS',
    value: '_geodis_tech_stack',
  },
  {
    label: 'GEOvendas',
    value: '_geovendas_tech_stack',
  },
  {
    label: 'GLS',
    value: '_gls_tech_stack',
  },
  {
    label: 'GOV.UK Elements',
    value: '_gov.uk_elements_tech_stack',
  },
  {
    label: 'GOV.UK Frontend',
    value: '_gov.uk_frontend_tech_stack',
  },
  {
    label: 'GOV.UK Template',
    value: '_gov.uk_template_tech_stack',
  },
  {
    label: 'GOV.UK Toolkit',
    value: '_gov.uk_toolkit_tech_stack',
  },
  {
    label: 'GSAP',
    value: '_gsap_tech_stack',
  },
  {
    label: 'GTranslate',
    value: '_gtranslate_tech_stack',
  },
  {
    label: 'GTranslate app',
    value: '_gtranslate_app_tech_stack',
  },
  {
    label: 'GX WebManager',
    value: '_gx_webmanager_tech_stack',
  },
  {
    label: 'Gallery',
    value: '_gallery_tech_stack',
  },
  {
    label: 'Gambio',
    value: '_gambio_tech_stack',
  },
  {
    label: 'Gameball',
    value: '_gameball_tech_stack',
  },
  {
    label: 'Gatsby',
    value: '_gatsby_tech_stack',
  },
  {
    label: 'Gatsby Cloud Image CDN',
    value: '_gatsby_cloud image cdn_tech_stack',
  },
  {
    label: 'Gauges',
    value: '_gauges_tech_stack',
  },
  {
    label: 'GemPages',
    value: '_gempages_tech_stack',
  },
  {
    label: 'Gemius',
    value: '_gemius_tech_stack',
  },
  {
    label: 'GeneXus',
    value: '_genexus_tech_stack',
  },
  {
    label: 'GeneratePress',
    value: '_generatepress_tech_stack',
  },
  {
    label: 'Genesis theme',
    value: '_genesis_theme_tech_stack',
  },
  {
    label: 'Genesys Cloud',
    value: '_genesys_cloud_tech_stack',
  },
  {
    label: 'Geniee',
    value: '_geniee_tech_stack',
  },
  {
    label: 'Gentoo',
    value: '_gentoo_tech_stack',
  },
  {
    label: 'Geo Targetly',
    value: '_geo_targetly_tech_stack',
  },
  {
    label: 'Gerrit',
    value: '_gerrit_tech_stack',
  },
  {
    label: 'Get Satisfaction',
    value: '_get_satisfaction_tech_stack',
  },
  {
    label: 'GetButton',
    value: '_getbutton_tech_stack',
  },
  {
    label: 'GetFeedback',
    value: '_getfeedback_tech_stack',
  },
  {
    label: 'GetMeAShop',
    value: '_getmeashop_tech_stack',
  },
  {
    label: 'GetResponse',
    value: '_getresponse_tech_stack',
  },
  {
    label: 'GetSimple CMS',
    value: '_getsimple_cms_tech_stack',
  },
  {
    label: 'GetSocial',
    value: '_getsocial_tech_stack',
  },
  {
    label: 'GetYourGuide',
    value: '_getyourguide_tech_stack',
  },
  {
    label: 'Getintent',
    value: '_getintent_tech_stack',
  },
  {
    label: 'Getsitecontrol',
    value: '_getsitecontrol_tech_stack',
  },
  {
    label: 'Ghost',
    value: '_ghost_tech_stack',
  },
  {
    label: 'Gist Giftship',
    value: '_gist_giftship_tech_stack',
  },
  {
    label: 'GitBook',
    value: '_gitbook_tech_stack',
  },
  {
    label: 'GitHub Pages',
    value: '_github_pages_tech_stack',
  },
  {
    label: 'GitLab',
    value: '_gitlab_tech_stack',
  },
  {
    label: 'GitLab CI/CD',
    value: '_gitlab_ci/cd_tech_stack',
  },
  {
    label: 'Gitea',
    value: '_gitea_tech_stack',
  },
  {
    label: 'Gitiles',
    value: '_gitiles_tech_stack',
  },
  {
    label: 'GiveWP',
    value: '_givewp_tech_stack',
  },
  {
    label: 'Gladly',
    value: '_gladly_tech_stack',
  },
  {
    label: 'GlassFish',
    value: '_glassfish_tech_stack',
  },
  {
    label: 'Glassbox',
    value: '_glassbox_tech_stack',
  },
  {
    label: 'Glider.js',
    value: '_glider.js_tech_stack',
  },
  {
    label: 'Glitch',
    value: '_glitch_tech_stack',
  },
  {
    label: 'Global-e',
    value: '_global-e_tech_stack',
  },
  {
    label: 'GlobalShopex',
    value: '_globalshopex_tech_stack',
  },
  {
    label: 'Globo Also Bought',
    value: '_globo_also bought_tech_stack',
  },
  {
    label: 'Globo Color Swatch',
    value: '_globo_color swatch_tech_stack',
  },
  {
    label: 'Globo Form Builder',
    value: '_globo_form builder_tech_stack',
  },
  {
    label: 'Globo Pre-Order',
    value: '_globo_pre-order_tech_stack',
  },
  {
    label: 'Glopal',
    value: '_glopal_tech_stack',
  },
  {
    label: 'GloriaFood',
    value: '_gloriafood_tech_stack',
  },
  {
    label: 'Glyphicons',
    value: '_glyphicons_tech_stack',
  },
  {
    label: 'Gnuboard',
    value: '_gnuboard_tech_stack',
  },
  {
    label: 'Go',
    value: '_go_tech_stack',
  },
  {
    label: 'Go Instore',
    value: '_go_instore_tech_stack',
  },
  {
    label: 'GoAffPro',
    value: '_goaffpro_tech_stack',
  },
  {
    label: 'GoAhead',
    value: '_goahead_tech_stack',
  },
  {
    label: 'GoAnywhere',
    value: '_goanywhere_tech_stack',
  },
  {
    label: 'GoCache',
    value: '_gocache_tech_stack',
  },
  {
    label: 'GoCertify',
    value: '_gocertify_tech_stack',
  },
  {
    label: 'GoDaddy',
    value: '_godaddy_tech_stack',
  },
  {
    label: 'GoDaddy CoBlocks',
    value: '_godaddy_coblocks_tech_stack',
  },
  {
    label: 'GoDaddy Escapade',
    value: '_godaddy_escapade_tech_stack',
  },
  {
    label: 'GoDaddy Go',
    value: '_godaddy_go_tech_stack',
  },
  {
    label: 'GoDaddy Lyrical',
    value: '_godaddy_lyrical_tech_stack',
  },
  {
    label: 'GoDaddy Online Store',
    value: '_godaddy_online store_tech_stack',
  },
  {
    label: 'GoDaddy Primer',
    value: '_godaddy_primer_tech_stack',
  },
  {
    label: 'GoDaddy Uptown Style',
    value: '_godaddy_uptown style_tech_stack',
  },
  {
    label: 'GoDaddy Website Builder',
    value: '_godaddy_website builder_tech_stack',
  },
  {
    label: 'GoJS',
    value: '_gojs_tech_stack',
  },
  {
    label: 'GoKwik',
    value: '_gokwik_tech_stack',
  },
  {
    label: 'GoStats',
    value: '_gostats_tech_stack',
  },
  {
    label: 'GoatCounter',
    value: '_goatcounter_tech_stack',
  },
  {
    label: 'Goftino',
    value: '_goftino_tech_stack',
  },
  {
    label: 'Gogs',
    value: '_gogs_tech_stack',
  },
  {
    label: 'Google AdSense',
    value: '_google_adsense_tech_stack',
  },
  {
    label: 'Google Ads',
    value: '_google_ads_tech_stack',
  },
  {
    label: 'Google Ads Conversion Tracking',
    value: '_google_ads conversion tracking_tech_stack',
  },
  {
    label: 'Google Analytics',
    value: '_google_analytics_tech_stack',
  },
  {
    label: 'Google Analytics Enhanced eCommerce',
    value: '_google_analytics enhanced ecommerce_tech_stack',
  },
  {
    label: 'Google App Engine',
    value: '_google_app engine_tech_stack',
  },
  {
    label: 'Google Call Conversion Tracking',
    value: '_google_call conversion tracking_tech_stack',
  },
  {
    label: 'Google Charts',
    value: '_google_charts_tech_stack',
  },
  {
    label: 'Google Cloud',
    value: '_google_cloud_tech_stack',
  },
  {
    label: 'Google Cloud CDN',
    value: '_google_cloud cdn_tech_stack',
  },
  {
    label: 'Google Cloud Storage',
    value: '_google_cloud storage_tech_stack',
  },
  {
    label: 'Google Cloud Trace',
    value: '_google_cloud trace_tech_stack',
  },
  {
    label: 'Google Code Prettify',
    value: '_google_code prettify_tech_stack',
  },
  {
    label: 'Google Customer Reviews',
    value: '_google_customer reviews_tech_stack',
  },
  {
    label: 'Google Font API',
    value: '_google_font api_tech_stack',
  },
  {
    label: 'Google Hosted Libraries',
    value: '_google_hosted libraries_tech_stack',
  },
  {
    label: 'Google Maps',
    value: '_google_maps_tech_stack',
  },
  {
    label: 'Google My Business',
    value: '_google_my business_tech_stack',
  },
  {
    label: 'Google Optimize',
    value: '_google_optimize_tech_stack',
  },
  {
    label: 'Google PageSpeed',
    value: '_google_pagespeed_tech_stack',
  },
  {
    label: 'Google Pay',
    value: '_google_pay_tech_stack',
  },
  {
    label: 'Google Publisher Tag',
    value: '_google_publisher tag_tech_stack',
  },
  {
    label: 'Google Sign-in',
    value: '_google_sign-in_tech_stack',
  },
  {
    label: 'Google Sites',
    value: '_google_sites_tech_stack',
  },
  {
    label: 'Google Tag Manager',
    value: '_google_tag manager_tech_stack',
  },
  {
    label: 'Google Tag Manager for WordPress',
    value: '_google_tag manager for wordpress_tech_stack',
  },
  {
    label: 'Google Wallet',
    value: '_google_wallet_tech_stack',
  },
  {
    label: 'Google Web Server',
    value: '_google_web server_tech_stack',
  },
  {
    label: 'Google Web Toolkit',
    value: '_google_web toolkit_tech_stack',
  },
  {
    label: 'Google Workspace',
    value: '_google_workspace_tech_stack',
  },
  {
    label: 'Gorgias',
    value: '_gorgias_tech_stack',
  },
  {
    label: 'GotiPath',
    value: '_gotipath_tech_stack',
  },
  {
    label: 'Govalo',
    value: '_govalo_tech_stack',
  },
  {
    label: 'Grab Pay Later',
    value: '_grab_pay later_tech_stack',
  },
  {
    label: 'Grafana',
    value: '_grafana_tech_stack',
  },
  {
    label: 'Graffiti CMS',
    value: '_graffiti_cms_tech_stack',
  },
  {
    label: 'GrandNode',
    value: '_grandnode_tech_stack',
  },
  {
    label: 'GraphCMS',
    value: '_graphcms_tech_stack',
  },
  {
    label: 'GraphQL',
    value: '_graphql_tech_stack',
  },
  {
    label: 'Graphene',
    value: '_graphene_tech_stack',
  },
  {
    label: 'Grav',
    value: '_grav_tech_stack',
  },
  {
    label: 'Gravatar',
    value: '_gravatar_tech_stack',
  },
  {
    label: 'Gravitec',
    value: '_gravitec_tech_stack',
  },
  {
    label: 'Gravity Forms',
    value: '_gravity_forms_tech_stack',
  },
  {
    label: 'Green Valley CMS',
    value: '_green_valley cms_tech_stack',
  },
  {
    label: 'Greenhouse',
    value: '_greenhouse_tech_stack',
  },
  {
    label: 'Griddo',
    value: '_griddo_tech_stack',
  },
  {
    label: 'Gridsome',
    value: '_gridsome_tech_stack',
  },
  {
    label: 'Grin',
    value: '_grin_tech_stack',
  },
  {
    label: 'GrocerKey',
    value: '_grocerkey_tech_stack',
  },
  {
    label: 'GroupBy',
    value: '_groupby_tech_stack',
  },
  {
    label: 'Growave',
    value: '_growave_tech_stack',
  },
  {
    label: 'GrowingIO',
    value: '_growingio_tech_stack',
  },
  {
    label: 'Guestonline',
    value: '_guestonline_tech_stack',
  },
  {
    label: 'GumGum',
    value: '_gumgum_tech_stack',
  },
  {
    label: 'Gumlet',
    value: '_gumlet_tech_stack',
  },
  {
    label: 'Gumroad',
    value: '_gumroad_tech_stack',
  },
  {
    label: 'Gumstack',
    value: '_gumstack_tech_stack',
  },
  {
    label: 'Gutenberg',
    value: '_gutenberg_tech_stack',
  },
  {
    label: 'Gutenberg Blocks',
    value: '_gutenberg_blocks_tech_stack',
  },
  {
    label: 'git',
    value: '_git_tech_stack',
  },
  {
    label: 'gitlist',
    value: '_gitlist_tech_stack',
  },
  {
    label: 'gitweb',
    value: '_gitweb_tech_stack',
  },
  {
    label: 'govCMS',
    value: '_govcms_tech_stack',
  },
  {
    label: 'gunicorn',
    value: '_gunicorn_tech_stack',
  },
  {
    label: 'HCL Commerce',
    value: '_hcl_commerce_tech_stack',
  },
  {
    label: 'HCL Digital Experience',
    value: '_hcl_digital experience_tech_stack',
  },
  {
    label: 'HHVM',
    value: '_hhvm_tech_stack',
  },
  {
    label: 'HP Compact Server',
    value: '_hp_compact server_tech_stack',
  },
  {
    label: 'HP iLO',
    value: '_hp_ilo_tech_stack',
  },
  {
    label: 'HSTS',
    value: '_hsts_tech_stack',
  },
  {
    label: 'HTTP/2',
    value: '_http/2_tech_stack',
  },
  {
    label: 'HTTP/3',
    value: '_http/3_tech_stack',
  },
  {
    label: 'Haddock',
    value: '_haddock_tech_stack',
  },
  {
    label: 'Halo',
    value: '_halo_tech_stack',
  },
  {
    label: 'Hamechio',
    value: '_hamechio_tech_stack',
  },
  {
    label: 'Hammer.js',
    value: '_hammer.js_tech_stack',
  },
  {
    label: 'Handlebars',
    value: '_handlebars_tech_stack',
  },
  {
    label: 'Handtalk',
    value: '_handtalk_tech_stack',
  },
  {
    label: 'Hansel',
    value: '_hansel_tech_stack',
  },
  {
    label: 'Happy Returns',
    value: '_happy_returns_tech_stack',
  },
  {
    label: 'HappyFox Helpdesk',
    value: '_happyfox_helpdesk_tech_stack',
  },
  {
    label: 'HappyFox Live Chat',
    value: '_happyfox_live chat_tech_stack',
  },
  {
    label: 'Haptik',
    value: '_haptik_tech_stack',
  },
  {
    label: 'Haravan',
    value: '_haravan_tech_stack',
  },
  {
    label: 'HashThemes Total',
    value: '_hashthemes_total_tech_stack',
  },
  {
    label: 'Hashnode',
    value: '_hashnode_tech_stack',
  },
  {
    label: 'Haskell',
    value: '_haskell_tech_stack',
  },
  {
    label: 'HeadJS',
    value: '_headjs_tech_stack',
  },
  {
    label: 'Headless UI',
    value: '_headless_ui_tech_stack',
  },
  {
    label: 'Heap',
    value: '_heap_tech_stack',
  },
  {
    label: 'Heartland Payment Systems',
    value: '_heartland_payment systems_tech_stack',
  },
  {
    label: 'Helhost',
    value: '_helhost_tech_stack',
  },
  {
    label: 'HeliumWeb',
    value: '_heliumweb_tech_stack',
  },
  {
    label: 'Helix Ultimate',
    value: '_helix_ultimate_tech_stack',
  },
  {
    label: 'Helixo UFE',
    value: '_helixo_ufe_tech_stack',
  },
  {
    label: 'Hello Bar',
    value: '_hello_bar_tech_stack',
  },
  {
    label: 'Hello Elementor',
    value: '_hello_elementor_tech_stack',
  },
  {
    label: 'Help Scout',
    value: '_help_scout_tech_stack',
  },
  {
    label: 'HelpDocs',
    value: '_helpdocs_tech_stack',
  },
  {
    label: 'Hermes',
    value: '_hermes_tech_stack',
  },
  {
    label: 'Hero',
    value: '_hero_tech_stack',
  },
  {
    label: 'Heroku',
    value: '_heroku_tech_stack',
  },
  {
    label: 'Hestia',
    value: '_hestia_tech_stack',
  },
  {
    label: 'HetrixTools',
    value: '_hetrixtools_tech_stack',
  },
  {
    label: 'Hetzner',
    value: '_hetzner_tech_stack',
  },
  {
    label: 'Hexo',
    value: '_hexo_tech_stack',
  },
  {
    label: 'Hextom Free Shipping Bar',
    value: '_hextom_free shipping bar_tech_stack',
  },
  {
    label: 'Hextom Ultimate Sales Boost',
    value: '_hextom_ultimate sales boost_tech_stack',
  },
  {
    label: 'Hiawatha',
    value: '_hiawatha_tech_stack',
  },
  {
    label: 'HighStore',
    value: '_highstore_tech_stack',
  },
  {
    label: 'Highcharts',
    value: '_highcharts_tech_stack',
  },
  {
    label: 'Highlight.js',
    value: '_highlight.js_tech_stack',
  },
  {
    label: 'Highstock',
    value: '_highstock_tech_stack',
  },
  {
    label: 'HikeOrders',
    value: '_hikeorders_tech_stack',
  },
  {
    label: 'Hinza Advanced CMS',
    value: '_hinza_advanced cms_tech_stack',
  },
  {
    label: 'Hireology',
    value: '_hireology_tech_stack',
  },
  {
    label: 'Histats',
    value: '_histats_tech_stack',
  },
  {
    label: 'History',
    value: '_history_tech_stack',
  },
  {
    label: 'Hoefler&Co',
    value: '_hoefler&co_tech_stack',
  },
  {
    label: 'Hogan.js',
    value: '_hogan.js_tech_stack',
  },
  {
    label: 'Homerr',
    value: '_homerr_tech_stack',
  },
  {
    label: 'Homestead',
    value: '_homestead_tech_stack',
  },
  {
    label: 'HostEurope',
    value: '_hosteurope_tech_stack',
  },
  {
    label: 'Hostens',
    value: '_hostens_tech_stack',
  },
  {
    label: 'Hostgator',
    value: '_hostgator_tech_stack',
  },
  {
    label: 'Hosting Ukraine',
    value: '_hosting_ukraine_tech_stack',
  },
  {
    label: 'Hostinger',
    value: '_hostinger_tech_stack',
  },
  {
    label: 'Hostiq',
    value: '_hostiq_tech_stack',
  },
  {
    label: 'Hostmeapp',
    value: '_hostmeapp_tech_stack',
  },
  {
    label: 'Hostpoint',
    value: '_hostpoint_tech_stack',
  },
  {
    label: 'Hotaru CMS',
    value: '_hotaru_cms_tech_stack',
  },
  {
    label: 'Hotjar',
    value: '_hotjar_tech_stack',
  },
  {
    label: 'Hotjar Incoming Feedback',
    value: '_hotjar_incoming feedback_tech_stack',
  },
  {
    label: 'Howler.js',
    value: '_howler.js_tech_stack',
  },
  {
    label: 'HrFlow.ai',
    value: '_hrflow.ai_tech_stack',
  },
  {
    label: 'HubSpot',
    value: '_hubspot_tech_stack',
  },
  {
    label: 'HubSpot Analytics',
    value: '_hubspot_analytics_tech_stack',
  },
  {
    label: 'HubSpot CMS Hub',
    value: '_hubspot_cms hub_tech_stack',
  },
  {
    label: 'HubSpot Chat',
    value: '_hubspot_chat_tech_stack',
  },
  {
    label: 'HubSpot Cookie Policy Banner',
    value: '_hubspot_cookie policy banner_tech_stack',
  },
  {
    label: 'Huberway',
    value: '_huberway_tech_stack',
  },
  {
    label: 'Huberway Analytics',
    value: '_huberway_analytics_tech_stack',
  },
  {
    label: 'Hugo',
    value: '_hugo_tech_stack',
  },
  {
    label: 'HulkApps Age Verification',
    value: '_hulkapps_age verification_tech_stack',
  },
  {
    label: 'HulkApps Form Builder',
    value: '_hulkapps_form builder_tech_stack',
  },
  {
    label: 'HulkApps GDPR/CCPA Compliance Manager',
    value: '_hulkapps_gdpr/ccpa compliance manager_tech_stack',
  },
  {
    label: 'HulkApps Infinite Product Options',
    value: '_hulkapps_infinite product options_tech_stack',
  },
  {
    label: 'HulkApps Product Reviews',
    value: '_hulkapps_product reviews_tech_stack',
  },
  {
    label: 'Humm',
    value: '_humm_tech_stack',
  },
  {
    label: 'Hund.io',
    value: '_hund.io_tech_stack',
  },
  {
    label: 'Hushly',
    value: '_hushly_tech_stack',
  },
  {
    label: 'Hypercorn',
    value: '_hypercorn_tech_stack',
  },
  {
    label: 'Hyperspeed',
    value: '_hyperspeed_tech_stack',
  },
  {
    label: 'Hypervisual Page Builder',
    value: '_hypervisual_page builder_tech_stack',
  },
  {
    label: 'Hypestyle CSS',
    value: '_hypestyle_css_tech_stack',
  },
  {
    label: 'h5ai',
    value: '_h5ai_tech_stack',
  },
  {
    label: 'hCaptcha',
    value: '_hcaptcha_tech_stack',
  },
  {
    label: 'hantana',
    value: '_hantana_tech_stack',
  },
  {
    label: 'hoolah',
    value: '_hoolah_tech_stack',
  },
  {
    label: 'hyva',
    value: '_hyva_tech_stack',
  },
  {
    label: 'IBM Coremetrics',
    value: '_ibm_coremetrics_tech_stack',
  },
  {
    label: 'IBM DataPower',
    value: '_ibm_datapower_tech_stack',
  },
  {
    label: 'IBM HTTP Server',
    value: '_ibm_http server_tech_stack',
  },
  {
    label: 'IIS',
    value: '_iis_tech_stack',
  },
  {
    label: 'INFOnline',
    value: '_infonline_tech_stack',
  },
  {
    label: 'IONOS',
    value: '_ionos_tech_stack',
  },
  {
    label: 'IPB',
    value: '_ipb_tech_stack',
  },
  {
    label: 'IPFS',
    value: '_ipfs_tech_stack',
  },
  {
    label: 'IPInfoDB',
    value: '_ipinfodb_tech_stack',
  },
  {
    label: 'IPinfo',
    value: '_ipinfo_tech_stack',
  },
  {
    label: 'Iamport',
    value: '_iamport_tech_stack',
  },
  {
    label: 'Ideasoft',
    value: '_ideasoft_tech_stack',
  },
  {
    label: 'Identrust',
    value: '_identrust_tech_stack',
  },
  {
    label: 'IdoSell Shop',
    value: '_idosell_shop_tech_stack',
  },
  {
    label: 'Iluria',
    value: '_iluria_tech_stack',
  },
  {
    label: 'Image Relay',
    value: '_image_relay_tech_stack',
  },
  {
    label: 'ImageEngine',
    value: '_imageengine_tech_stack',
  },
  {
    label: 'Imagely NextGEN Gallery',
    value: '_imagely_nextgen gallery_tech_stack',
  },
  {
    label: 'Imber',
    value: '_imber_tech_stack',
  },
  {
    label: 'Immutable.js',
    value: '_immutable.js_tech_stack',
  },
  {
    label: 'Impact',
    value: '_impact_tech_stack',
  },
  {
    label: 'Imperva',
    value: '_imperva_tech_stack',
  },
  {
    label: 'ImpressCMS',
    value: '_impresscms_tech_stack',
  },
  {
    label: 'ImpressPages',
    value: '_impresspages_tech_stack',
  },
  {
    label: 'Imweb',
    value: '_imweb_tech_stack',
  },
  {
    label: 'In Cart Upsell & Cross-Sell',
    value: '_in_cart upsell & cross-sell_tech_stack',
  },
  {
    label: 'InMoment',
    value: '_inmoment_tech_stack',
  },
  {
    label: 'InSyncai',
    value: '_insyncai_tech_stack',
  },
  {
    label: 'Incapsula',
    value: '_incapsula_tech_stack',
  },
  {
    label: 'Includable',
    value: '_includable_tech_stack',
  },
  {
    label: 'Index Exchange',
    value: '_index_exchange_tech_stack',
  },
  {
    label: 'Indexhibit',
    value: '_indexhibit_tech_stack',
  },
  {
    label: 'Indi',
    value: '_indi_tech_stack',
  },
  {
    label: 'Indico',
    value: '_indico_tech_stack',
  },
  {
    label: 'Indy',
    value: '_indy_tech_stack',
  },
  {
    label: 'Inertia',
    value: '_inertia_tech_stack',
  },
  {
    label: 'InfernoJS',
    value: '_infernojs_tech_stack',
  },
  {
    label: 'Infogram',
    value: '_infogram_tech_stack',
  },
  {
    label: 'Infolinks',
    value: '_infolinks_tech_stack',
  },
  {
    label: 'Infomaniak',
    value: '_infomaniak_tech_stack',
  },
  {
    label: 'Insider',
    value: '_insider_tech_stack',
  },
  {
    label: 'Inspectlet',
    value: '_inspectlet_tech_stack',
  },
  {
    label: 'Instabot',
    value: '_instabot_tech_stack',
  },
  {
    label: 'Instafeed',
    value: '_instafeed_tech_stack',
  },
  {
    label: 'Instana',
    value: '_instana_tech_stack',
  },
  {
    label: 'Instant.Page',
    value: '_instant.page_tech_stack',
  },
  {
    label: 'InstantCMS',
    value: '_instantcms_tech_stack',
  },
  {
    label: 'InstantClick',
    value: '_instantclick_tech_stack',
  },
  {
    label: 'InstantGeo',
    value: '_instantgeo_tech_stack',
  },
  {
    label: 'Instapage',
    value: '_instapage_tech_stack',
  },
  {
    label: 'Instatus',
    value: '_instatus_tech_stack',
  },
  {
    label: 'Integral Ad Science',
    value: '_integral_ad science_tech_stack',
  },
  {
    label: 'Intel Active Management Technology',
    value: '_intel_active management technology_tech_stack',
  },
  {
    label: 'IntenseDebate',
    value: '_intensedebate_tech_stack',
  },
  {
    label: 'Interact',
    value: '_interact_tech_stack',
  },
  {
    label: 'Intercom',
    value: '_intercom_tech_stack',
  },
  {
    label: 'Intercom Articles',
    value: '_intercom_articles_tech_stack',
  },
  {
    label: 'Intersection Observer',
    value: '_intersection_observer_tech_stack',
  },
  {
    label: 'Intershop',
    value: '_intershop_tech_stack',
  },
  {
    label: 'Invenio',
    value: '_invenio_tech_stack',
  },
  {
    label: 'Inventrue',
    value: '_inventrue_tech_stack',
  },
  {
    label: 'Inveon',
    value: '_inveon_tech_stack',
  },
  {
    label: 'Invoca',
    value: '_invoca_tech_stack',
  },
  {
    label: 'Ionic',
    value: '_ionic_tech_stack',
  },
  {
    label: 'Ionicons',
    value: '_ionicons_tech_stack',
  },
  {
    label: 'IrisLMS',
    value: '_irislms_tech_stack',
  },
  {
    label: 'Irroba',
    value: '_irroba_tech_stack',
  },
  {
    label: 'Isotope',
    value: '_isotope_tech_stack',
  },
  {
    label: 'Issuu',
    value: '_issuu_tech_stack',
  },
  {
    label: 'Iterable',
    value: '_iterable_tech_stack',
  },
  {
    label: 'Izooto',
    value: '_izooto_tech_stack',
  },
  {
    label: 'i-MSCP',
    value: '_i-mscp_tech_stack',
  },
  {
    label: 'i-mobile',
    value: '_i-mobile_tech_stack',
  },
  {
    label: 'i30con',
    value: '_i30con_tech_stack',
  },
  {
    label: 'iAdvize',
    value: '_iadvize_tech_stack',
  },
  {
    label: 'iEXExchanger',
    value: '_iexexchanger_tech_stack',
  },
  {
    label: 'iHomefinder IDX',
    value: '_ihomefinder_idx_tech_stack',
  },
  {
    label: 'iPresta',
    value: '_ipresta_tech_stack',
  },
  {
    label: 'iSina Chat',
    value: '_isina_chat_tech_stack',
  },
  {
    label: 'iThemes Security',
    value: '_ithemes_security_tech_stack',
  },
  {
    label: 'iWeb',
    value: '_iweb_tech_stack',
  },
  {
    label: 'idCloudHost',
    value: '_idcloudhost_tech_stack',
  },
  {
    label: 'ikiwiki',
    value: '_ikiwiki_tech_stack',
  },
  {
    label: 'imperia CMS',
    value: '_imperia_cms_tech_stack',
  },
  {
    label: 'inSided',
    value: '_insided_tech_stack',
  },
  {
    label: 'ip-api',
    value: '_ip-api_tech_stack',
  },
  {
    label: 'ip-label',
    value: '_ip-label_tech_stack',
  },
  {
    label: 'ipapi',
    value: '_ipapi_tech_stack',
  },
  {
    label: 'ipapi.co',
    value: '_ipapi.co_tech_stack',
  },
  {
    label: 'ipdata',
    value: '_ipdata_tech_stack',
  },
  {
    label: 'ipgeolocation',
    value: '_ipgeolocation_tech_stack',
  },
  {
    label: 'ipify',
    value: '_ipify_tech_stack',
  },
  {
    label: 'ipstack',
    value: '_ipstack_tech_stack',
  },
  {
    label: 'iubenda',
    value: '_iubenda_tech_stack',
  },
  {
    label: 'J2Store',
    value: '_j2store_tech_stack',
  },
  {
    label: 'JANet',
    value: '_janet_tech_stack',
  },
  {
    label: 'JAlbum',
    value: '_jalbum_tech_stack',
  },
  {
    label: 'JBoss Application Server',
    value: '_jboss_application server_tech_stack',
  },
  {
    label: 'JBoss Web',
    value: '_jboss_web_tech_stack',
  },
  {
    label: 'JET Enterprise',
    value: '_jet_enterprise_tech_stack',
  },
  {
    label: 'JS Charts',
    value: '_js_charts_tech_stack',
  },
  {
    label: 'JSEcoin',
    value: '_jsecoin_tech_stack',
  },
  {
    label: 'JSS',
    value: '_jss_tech_stack',
  },
  {
    label: 'JShop',
    value: '_jshop_tech_stack',
  },
  {
    label: 'JTL Shop',
    value: '_jtl_shop_tech_stack',
  },
  {
    label: 'JW Player',
    value: '_jw_player_tech_stack',
  },
  {
    label: 'Jahia DX',
    value: '_jahia_dx_tech_stack',
  },
  {
    label: 'Jalios',
    value: '_jalios_tech_stack',
  },
  {
    label: 'Java',
    value: '_java_tech_stack',
  },
  {
    label: 'Java Servlet',
    value: '_java_servlet_tech_stack',
  },
  {
    label: 'JavaScript Infovis Toolkit',
    value: '_javascript_infovis toolkit_tech_stack',
  },
  {
    label: 'JavaServer Faces',
    value: '_javaserver_faces_tech_stack',
  },
  {
    label: 'JavaServer Pages',
    value: '_javaserver_pages_tech_stack',
  },
  {
    label: 'Javadoc',
    value: '_javadoc_tech_stack',
  },
  {
    label: 'Jekyll',
    value: '_jekyll_tech_stack',
  },
  {
    label: 'Jenkins',
    value: '_jenkins_tech_stack',
  },
  {
    label: 'Jetpack',
    value: '_jetpack_tech_stack',
  },
  {
    label: 'Jetshop',
    value: '_jetshop_tech_stack',
  },
  {
    label: 'Jetty',
    value: '_jetty_tech_stack',
  },
  {
    label: 'Jibres',
    value: '_jibres_tech_stack',
  },
  {
    label: 'Jimdo',
    value: '_jimdo_tech_stack',
  },
  {
    label: 'Jirafe',
    value: '_jirafe_tech_stack',
  },
  {
    label: 'Jitsi',
    value: '_jitsi_tech_stack',
  },
  {
    label: 'Jive',
    value: '_jive_tech_stack',
  },
  {
    label: 'JivoChat',
    value: '_jivochat_tech_stack',
  },
  {
    label: 'Jivox',
    value: '_jivox_tech_stack',
  },
  {
    label: 'JobAdder',
    value: '_jobadder_tech_stack',
  },
  {
    label: 'JobberBase',
    value: '_jobberbase_tech_stack',
  },
  {
    label: 'Jobvite',
    value: '_jobvite_tech_stack',
  },
  {
    label: 'JoomShopping',
    value: '_joomshopping_tech_stack',
  },
  {
    label: 'Joomla',
    value: '_joomla_tech_stack',
  },
  {
    label: 'JouwWeb',
    value: '_jouwweb_tech_stack',
  },
  {
    label: 'JsObservable',
    value: '_jsobservable_tech_stack',
  },
  {
    label: 'JsRender',
    value: '_jsrender_tech_stack',
  },
  {
    label: 'JsViews',
    value: '_jsviews_tech_stack',
  },
  {
    label: 'Judge.me',
    value: '_judge.me_tech_stack',
  },
  {
    label: 'JuicyAds',
    value: '_juicyads_tech_stack',
  },
  {
    label: 'Jumbo',
    value: '_jumbo_tech_stack',
  },
  {
    label: 'Jumio',
    value: '_jumio_tech_stack',
  },
  {
    label: 'Jumpseller',
    value: '_jumpseller_tech_stack',
  },
  {
    label: 'Junip',
    value: '_junip_tech_stack',
  },
  {
    label: 'Juo',
    value: '_juo_tech_stack',
  },
  {
    label: 'Justo',
    value: '_justo_tech_stack',
  },
  {
    label: 'Justuno',
    value: '_justuno_tech_stack',
  },
  {
    label: 'jComponent',
    value: '_jcomponent_tech_stack',
  },
  {
    label: 'jQTouch',
    value: '_jqtouch_tech_stack',
  },
  {
    label: 'jQuery',
    value: '_jquery_tech_stack',
  },
  {
    label: 'jQuery CDN',
    value: '_jquery_cdn_tech_stack',
  },
  {
    label: 'jQuery DevBridge Autocomplete',
    value: '_jquery_devbridge autocomplete_tech_stack',
  },
  {
    label: 'jQuery Migrate',
    value: '_jquery_migrate_tech_stack',
  },
  {
    label: 'jQuery Mobile',
    value: '_jquery_mobile_tech_stack',
  },
  {
    label: 'jQuery Modal',
    value: '_jquery_modal_tech_stack',
  },
  {
    label: 'jQuery Sparklines',
    value: '_jquery_sparklines_tech_stack',
  },
  {
    label: 'jQuery UI',
    value: '_jquery_ui_tech_stack',
  },
  {
    label: 'jQuery-pjax',
    value: '_jquery-pjax_tech_stack',
  },
  {
    label: 'jqPlot',
    value: '_jqplot_tech_stack',
  },
  {
    label: 'jsDelivr',
    value: '_jsdelivr_tech_stack',
  },
  {
    label: 'K2',
    value: '_k2_tech_stack',
  },
  {
    label: 'KISSmetrics',
    value: '_kissmetrics_tech_stack',
  },
  {
    label: 'KMK',
    value: '_kmk_tech_stack',
  },
  {
    label: 'KQS.store',
    value: '_kqs.store_tech_stack',
  },
  {
    label: 'KaTeX',
    value: '_katex_tech_stack',
  },
  {
    label: 'Kadence WP Kadence',
    value: '_kadence_wp kadence_tech_stack',
  },
  {
    label: 'Kadence WP Virtue',
    value: '_kadence_wp virtue_tech_stack',
  },
  {
    label: 'Kaira Vogue',
    value: '_kaira_vogue_tech_stack',
  },
  {
    label: 'Kajabi',
    value: '_kajabi_tech_stack',
  },
  {
    label: 'Kakao',
    value: '_kakao_tech_stack',
  },
  {
    label: 'Kaltura',
    value: '_kaltura_tech_stack',
  },
  {
    label: 'Kameleoon',
    value: '_kameleoon_tech_stack',
  },
  {
    label: 'Kamva',
    value: '_kamva_tech_stack',
  },
  {
    label: 'Kapture CRM',
    value: '_kapture_crm_tech_stack',
  },
  {
    label: 'Karma',
    value: '_karma_tech_stack',
  },
  {
    label: 'Kartra',
    value: '_kartra_tech_stack',
  },
  {
    label: 'Keap',
    value: '_keap_tech_stack',
  },
  {
    label: 'Keen Delivery',
    value: '_keen_delivery_tech_stack',
  },
  {
    label: 'Kemal',
    value: '_kemal_tech_stack',
  },
  {
    label: 'Kendo UI',
    value: '_kendo_ui_tech_stack',
  },
  {
    label: 'Kentico CMS',
    value: '_kentico_cms_tech_stack',
  },
  {
    label: 'Keptify',
    value: '_keptify_tech_stack',
  },
  {
    label: 'Kerberos',
    value: '_kerberos_tech_stack',
  },
  {
    label: 'Kestrel',
    value: '_kestrel_tech_stack',
  },
  {
    label: 'Kevel',
    value: '_kevel_tech_stack',
  },
  {
    label: 'KeyCDN',
    value: '_keycdn_tech_stack',
  },
  {
    label: 'Kibana',
    value: '_kibana_tech_stack',
  },
  {
    label: 'Kibo Commerce',
    value: '_kibo_commerce_tech_stack',
  },
  {
    label: 'Kibo Personalization',
    value: '_kibo_personalization_tech_stack',
  },
  {
    label: 'KineticJS',
    value: '_kineticjs_tech_stack',
  },
  {
    label: 'Kinsta',
    value: '_kinsta_tech_stack',
  },
  {
    label: 'Kirki Customizer Framework',
    value: '_kirki_customizer framework_tech_stack',
  },
  {
    label: 'Kitcart',
    value: '_kitcart_tech_stack',
  },
  {
    label: 'Kiwi Sizing',
    value: '_kiwi_sizing_tech_stack',
  },
  {
    label: 'Klarna Checkout',
    value: '_klarna_checkout_tech_stack',
  },
  {
    label: 'Klaro',
    value: '_klaro_tech_stack',
  },
  {
    label: 'Klasha',
    value: '_klasha_tech_stack',
  },
  {
    label: 'Klaviyo',
    value: '_klaviyo_tech_stack',
  },
  {
    label: 'Klevu',
    value: '_klevu_tech_stack',
  },
  {
    label: 'Klickly',
    value: '_klickly_tech_stack',
  },
  {
    label: 'Knockout.js',
    value: '_knockout.js_tech_stack',
  },
  {
    label: 'Ko-fi',
    value: '_ko-fi_tech_stack',
  },
  {
    label: 'Koa',
    value: '_koa_tech_stack',
  },
  {
    label: 'Koala Framework',
    value: '_koala_framework_tech_stack',
  },
  {
    label: 'KobiMaster',
    value: '_kobimaster_tech_stack',
  },
  {
    label: 'Koha',
    value: '_koha_tech_stack',
  },
  {
    label: 'Kohana',
    value: '_kohana_tech_stack',
  },
  {
    label: 'Koken',
    value: '_koken_tech_stack',
  },
  {
    label: 'Komodo CMS',
    value: '_komodo_cms_tech_stack',
  },
  {
    label: 'Konduto',
    value: '_konduto_tech_stack',
  },
  {
    label: 'Koobi',
    value: '_koobi_tech_stack',
  },
  {
    label: 'Kooboo CMS',
    value: '_kooboo_cms_tech_stack',
  },
  {
    label: 'Kooomo',
    value: '_kooomo_tech_stack',
  },
  {
    label: 'Kotisivukone',
    value: '_kotisivukone_tech_stack',
  },
  {
    label: 'Kount',
    value: '_kount_tech_stack',
  },
  {
    label: 'Kubernetes Dashboard',
    value: '_kubernetes_dashboard_tech_stack',
  },
  {
    label: 'KueskiPay',
    value: '_kueskipay_tech_stack',
  },
  {
    label: 'Kustomer',
    value: '_kustomer_tech_stack',
  },
  {
    label: 'k-eCommerce',
    value: '_k-ecommerce_tech_stack',
  },
  {
    label: 'keep. archeevo',
    value: '_keep._archeevo_tech_stack',
  },
  {
    label: 'LEPTON',
    value: '_lepton_tech_stack',
  },
  {
    label: 'LINE Login',
    value: '_line_login_tech_stack',
  },
  {
    label: 'LKQD',
    value: '_lkqd_tech_stack',
  },
  {
    label: 'LOU',
    value: '_lou_tech_stack',
  },
  {
    label: 'Lagoon',
    value: '_lagoon_tech_stack',
  },
  {
    label: 'LangShop',
    value: '_langshop_tech_stack',
  },
  {
    label: 'Laravel',
    value: '_laravel_tech_stack',
  },
  {
    label: 'Laterpay',
    value: '_laterpay_tech_stack',
  },
  {
    label: 'LatitudePay',
    value: '_latitudepay_tech_stack',
  },
  {
    label: 'LaunchDarkly',
    value: '_launchdarkly_tech_stack',
  },
  {
    label: 'Launchrock',
    value: '_launchrock_tech_stack',
  },
  {
    label: 'LayBuy',
    value: '_laybuy_tech_stack',
  },
  {
    label: 'LayoutHub',
    value: '_layouthub_tech_stack',
  },
  {
    label: 'Lazada',
    value: '_lazada_tech_stack',
  },
  {
    label: 'LazySizes',
    value: '_lazysizes_tech_stack',
  },
  {
    label: 'LazySizes unveilhooks plugin',
    value: '_lazysizes_unveilhooks plugin_tech_stack',
  },
  {
    label: 'Leadinfo',
    value: '_leadinfo_tech_stack',
  },
  {
    label: 'Leaflet',
    value: '_leaflet_tech_stack',
  },
  {
    label: 'Leaflet platform',
    value: '_leaflet_platform_tech_stack',
  },
  {
    label: 'Leanplum',
    value: '_leanplum_tech_stack',
  },
  {
    label: 'LearnWorlds',
    value: '_learnworlds_tech_stack',
  },
  {
    label: 'Leaseweb',
    value: '_leaseweb_tech_stack',
  },
  {
    label: 'Lede',
    value: '_lede_tech_stack',
  },
  {
    label: 'Legal Monster',
    value: '_legal_monster_tech_stack',
  },
  {
    label: 'Less',
    value: '_less_tech_stack',
  },
  {
    label: "Let's Encrypt",
    value: "_let's_encrypt_tech_stack",
  },
  {
    label: 'Letro',
    value: '_letro_tech_stack',
  },
  {
    label: 'Level 5',
    value: '_level_5_tech_stack',
  },
  {
    label: 'Lever',
    value: '_lever_tech_stack',
  },
  {
    label: 'Libravatar',
    value: '_libravatar_tech_stack',
  },
  {
    label: 'Lieferando',
    value: '_lieferando_tech_stack',
  },
  {
    label: 'Liferay',
    value: '_liferay_tech_stack',
  },
  {
    label: 'Lift',
    value: '_lift_tech_stack',
  },
  {
    label: 'LightMon Engine',
    value: '_lightmon_engine_tech_stack',
  },
  {
    label: 'Lightbox',
    value: '_lightbox_tech_stack',
  },
  {
    label: 'Lightning',
    value: '_lightning_tech_stack',
  },
  {
    label: 'Lightspeed eCom',
    value: '_lightspeed_ecom_tech_stack',
  },
  {
    label: 'LimeChat',
    value: '_limechat_tech_stack',
  },
  {
    label: 'LimeSpot',
    value: '_limespot_tech_stack',
  },
  {
    label: 'Limepay',
    value: '_limepay_tech_stack',
  },
  {
    label: 'Limit Login Attempts Reloaded',
    value: '_limit_login attempts reloaded_tech_stack',
  },
  {
    label: 'LinkSmart',
    value: '_linksmart_tech_stack',
  },
  {
    label: 'Linkedin Insight Tag',
    value: '_linkedin_insight tag_tech_stack',
  },
  {
    label: 'Linkedin Sign-in',
    value: '_linkedin_sign-in_tech_stack',
  },
  {
    label: 'Linx Commerce',
    value: '_linx_commerce_tech_stack',
  },
  {
    label: 'Linx Impulse',
    value: '_linx_impulse_tech_stack',
  },
  {
    label: 'Liquid Web',
    value: '_liquid_web_tech_stack',
  },
  {
    label: 'List.js',
    value: '_list.js_tech_stack',
  },
  {
    label: 'Listrak',
    value: '_listrak_tech_stack',
  },
  {
    label: 'LiteSpeed',
    value: '_litespeed_tech_stack',
  },
  {
    label: 'Litespeed Cache',
    value: '_litespeed_cache_tech_stack',
  },
  {
    label: 'Lithium',
    value: '_lithium_tech_stack',
  },
  {
    label: 'Littledata',
    value: '_littledata_tech_stack',
  },
  {
    label: 'Live Story',
    value: '_live_story_tech_stack',
  },
  {
    label: 'LiveAgent',
    value: '_liveagent_tech_stack',
  },
  {
    label: 'LiveChat',
    value: '_livechat_tech_stack',
  },
  {
    label: 'LiveHelp',
    value: '_livehelp_tech_stack',
  },
  {
    label: 'LiveIntent',
    value: '_liveintent_tech_stack',
  },
  {
    label: 'LiveJournal',
    value: '_livejournal_tech_stack',
  },
  {
    label: 'LivePerson',
    value: '_liveperson_tech_stack',
  },
  {
    label: 'LiveRamp DPM',
    value: '_liveramp_dpm_tech_stack',
  },
  {
    label: 'LiveRamp PCM',
    value: '_liveramp_pcm_tech_stack',
  },
  {
    label: 'LiveSession',
    value: '_livesession_tech_stack',
  },
  {
    label: 'LiveStreet CMS',
    value: '_livestreet_cms_tech_stack',
  },
  {
    label: 'LiveZilla',
    value: '_livezilla_tech_stack',
  },
  {
    label: 'Livefyre',
    value: '_livefyre_tech_stack',
  },
  {
    label: 'Liveinternet',
    value: '_liveinternet_tech_stack',
  },
  {
    label: 'Livescale',
    value: '_livescale_tech_stack',
  },
  {
    label: 'Livewire',
    value: '_livewire_tech_stack',
  },
  {
    label: 'Loadable-Components',
    value: '_loadable-components_tech_stack',
  },
  {
    label: 'Loadify',
    value: '_loadify_tech_stack',
  },
  {
    label: 'LocalFocus',
    value: '_localfocus_tech_stack',
  },
  {
    label: 'Localised',
    value: '_localised_tech_stack',
  },
  {
    label: 'Locksmith',
    value: '_locksmith_tech_stack',
  },
  {
    label: 'LocomotiveCMS',
    value: '_locomotivecms_tech_stack',
  },
  {
    label: 'Lodash',
    value: '_lodash_tech_stack',
  },
  {
    label: 'LogRocket',
    value: '_logrocket_tech_stack',
  },
  {
    label: 'Loggly',
    value: '_loggly_tech_stack',
  },
  {
    label: 'LogiCommerce',
    value: '_logicommerce_tech_stack',
  },
  {
    label: 'Login with Amazon',
    value: '_login_with amazon_tech_stack',
  },
  {
    label: 'LoginRadius',
    value: '_loginradius_tech_stack',
  },
  {
    label: 'LogoiX',
    value: '_logoix_tech_stack',
  },
  {
    label: 'Loja Integrada',
    value: '_loja_integrada_tech_stack',
  },
  {
    label: 'Loja Mestre',
    value: '_loja_mestre_tech_stack',
  },
  {
    label: 'Loja Virtual',
    value: '_loja_virtual_tech_stack',
  },
  {
    label: 'Loja2',
    value: '_loja2_tech_stack',
  },
  {
    label: 'Loop Returns',
    value: '_loop_returns_tech_stack',
  },
  {
    label: 'Loop54',
    value: '_loop54_tech_stack',
  },
  {
    label: 'Lootly',
    value: '_lootly_tech_stack',
  },
  {
    label: 'Loox',
    value: '_loox_tech_stack',
  },
  {
    label: 'Loqate',
    value: '_loqate_tech_stack',
  },
  {
    label: 'LottieFiles',
    value: '_lottiefiles_tech_stack',
  },
  {
    label: 'Lotus Domino',
    value: '_lotus_domino_tech_stack',
  },
  {
    label: 'LoyaltyLion',
    value: '_loyaltylion_tech_stack',
  },
  {
    label: 'Lozad.js',
    value: '_lozad.js_tech_stack',
  },
  {
    label: 'Lua',
    value: '_lua_tech_stack',
  },
  {
    label: 'Luana',
    value: '_luana_tech_stack',
  },
  {
    label: 'Lucene',
    value: '_lucene_tech_stack',
  },
  {
    label: 'Lucky Orange',
    value: '_lucky_orange_tech_stack',
  },
  {
    label: 'Luigi’s Box',
    value: '_luigi’s_box_tech_stack',
  },
  {
    label: 'Luna',
    value: '_luna_tech_stack',
  },
  {
    label: 'LyraThemes Kale',
    value: '_lyrathemes_kale_tech_stack',
  },
  {
    label: 'Lytics',
    value: '_lytics_tech_stack',
  },
  {
    label: 'langify',
    value: '_langify_tech_stack',
  },
  {
    label: 'libphonenumber',
    value: '_libphonenumber_tech_stack',
  },
  {
    label: 'libwww-perl-daemon',
    value: '_libwww-perl-daemon_tech_stack',
  },
  {
    label: 'lighttpd',
    value: '_lighttpd_tech_stack',
  },
  {
    label: 'lit-element',
    value: '_lit-element_tech_stack',
  },
  {
    label: 'lit-html',
    value: '_lit-html_tech_stack',
  },
  {
    label: 'lite-youtube-embed',
    value: '_lite-youtube-embed_tech_stack',
  },
  {
    label: 'MAAK',
    value: '_maak_tech_stack',
  },
  {
    label: 'MAJIN',
    value: '_majin_tech_stack',
  },
  {
    label: 'MATORI.NET',
    value: '_matori.net_tech_stack',
  },
  {
    label: 'MDUI',
    value: '_mdui_tech_stack',
  },
  {
    label: 'MGID',
    value: '_mgid_tech_stack',
  },
  {
    label: 'MGPanel',
    value: '_mgpanel_tech_stack',
  },
  {
    label: 'MIYN Online Appointment',
    value: '_miyn_online appointment_tech_stack',
  },
  {
    label: 'MODX',
    value: '_modx_tech_stack',
  },
  {
    label: 'MRW',
    value: '_mrw_tech_stack',
  },
  {
    label: 'MTCaptcha',
    value: '_mtcaptcha_tech_stack',
  },
  {
    label: 'MUI',
    value: '_mui_tech_stack',
  },
  {
    label: 'Macaron',
    value: '_macaron_tech_stack',
  },
  {
    label: 'MachoThemes NewsMag',
    value: '_machothemes_newsmag_tech_stack',
  },
  {
    label: 'MadAdsMedia',
    value: '_madadsmedia_tech_stack',
  },
  {
    label: 'Magazord',
    value: '_magazord_tech_stack',
  },
  {
    label: 'MageWorx Search Autocomplete',
    value: '_mageworx_search autocomplete_tech_stack',
  },
  {
    label: 'Magento',
    value: '_magento_tech_stack',
  },
  {
    label: 'Magisto',
    value: '_magisto_tech_stack',
  },
  {
    label: 'MailChimp',
    value: '_mailchimp_tech_stack',
  },
  {
    label: 'MailChimp for WooCommerce',
    value: '_mailchimp_for woocommerce_tech_stack',
  },
  {
    label: 'MailChimp for WordPress',
    value: '_mailchimp_for wordpress_tech_stack',
  },
  {
    label: 'MailerLite',
    value: '_mailerlite_tech_stack',
  },
  {
    label: 'MailerLite Website Builder',
    value: '_mailerlite_website builder_tech_stack',
  },
  {
    label: 'MailerLite plugin',
    value: '_mailerlite_plugin_tech_stack',
  },
  {
    label: 'Mailgun',
    value: '_mailgun_tech_stack',
  },
  {
    label: 'Mailjet',
    value: '_mailjet_tech_stack',
  },
  {
    label: 'Mailman',
    value: '_mailman_tech_stack',
  },
  {
    label: 'Mailmunch',
    value: '_mailmunch_tech_stack',
  },
  {
    label: 'MainAd',
    value: '_mainad_tech_stack',
  },
  {
    label: 'Make-Sense',
    value: '_make-sense_tech_stack',
  },
  {
    label: 'MakeShop',
    value: '_makeshop_tech_stack',
  },
  {
    label: 'MakeShopKorea',
    value: '_makeshopkorea_tech_stack',
  },
  {
    label: 'Malomo',
    value: '_malomo_tech_stack',
  },
  {
    label: 'Mambo',
    value: '_mambo_tech_stack',
  },
  {
    label: 'Mangeznotez',
    value: '_mangeznotez_tech_stack',
  },
  {
    label: 'MantisBT',
    value: '_mantisbt_tech_stack',
  },
  {
    label: 'ManyChat',
    value: '_manychat_tech_stack',
  },
  {
    label: 'ManyContacts',
    value: '_manycontacts_tech_stack',
  },
  {
    label: 'Mapbox GL JS',
    value: '_mapbox_gl js_tech_stack',
  },
  {
    label: 'Mapp',
    value: '_mapp_tech_stack',
  },
  {
    label: 'Mapplic',
    value: '_mapplic_tech_stack',
  },
  {
    label: 'Maptalks',
    value: '_maptalks_tech_stack',
  },
  {
    label: 'Marchex',
    value: '_marchex_tech_stack',
  },
  {
    label: 'Marfeel',
    value: '_marfeel_tech_stack',
  },
  {
    label: 'MariaDB',
    value: '_mariadb_tech_stack',
  },
  {
    label: 'Marionette.js',
    value: '_marionette.js_tech_stack',
  },
  {
    label: 'Marked',
    value: '_marked_tech_stack',
  },
  {
    label: 'Marker',
    value: '_marker_tech_stack',
  },
  {
    label: 'Marketo',
    value: '_marketo_tech_stack',
  },
  {
    label: 'Marketo Forms',
    value: '_marketo_forms_tech_stack',
  },
  {
    label: 'Marko',
    value: '_marko_tech_stack',
  },
  {
    label: 'Mastercard',
    value: '_mastercard_tech_stack',
  },
  {
    label: 'MasterkinG32 Framework',
    value: '_masterking32_framework_tech_stack',
  },
  {
    label: 'Mastodon',
    value: '_mastodon_tech_stack',
  },
  {
    label: 'Matajer',
    value: '_matajer_tech_stack',
  },
  {
    label: 'Material Design Lite',
    value: '_material_design lite_tech_stack',
  },
  {
    label: 'Materialize CSS',
    value: '_materialize_css_tech_stack',
  },
  {
    label: 'MathJax',
    value: '_mathjax_tech_stack',
  },
  {
    label: 'Matomo Analytics',
    value: '_matomo_analytics_tech_stack',
  },
  {
    label: 'Matomo Tag Manager',
    value: '_matomo_tag manager_tech_stack',
  },
  {
    label: 'Mattermost',
    value: '_mattermost_tech_stack',
  },
  {
    label: 'Mautic',
    value: '_mautic_tech_stack',
  },
  {
    label: 'MaxCDN',
    value: '_maxcdn_tech_stack',
  },
  {
    label: 'MaxMind',
    value: '_maxmind_tech_stack',
  },
  {
    label: 'MaxSite CMS',
    value: '_maxsite_cms_tech_stack',
  },
  {
    label: 'Maxemail',
    value: '_maxemail_tech_stack',
  },
  {
    label: 'MaxenceDEVCMS',
    value: '_maxencedevcms_tech_stack',
  },
  {
    label: 'Measured',
    value: '_measured_tech_stack',
  },
  {
    label: 'Medallia',
    value: '_medallia_tech_stack',
  },
  {
    label: 'Media.net',
    value: '_media.net_tech_stack',
  },
  {
    label: 'MediaElement.js',
    value: '_mediaelement.js_tech_stack',
  },
  {
    label: 'MediaWiki',
    value: '_mediawiki_tech_stack',
  },
  {
    label: 'Mediavine',
    value: '_mediavine_tech_stack',
  },
  {
    label: 'Medium',
    value: '_medium_tech_stack',
  },
  {
    label: 'Meebo',
    value: '_meebo_tech_stack',
  },
  {
    label: 'Meeting Scheduler',
    value: '_meeting_scheduler_tech_stack',
  },
  {
    label: 'Megagroup CMS.S3',
    value: '_megagroup_cms.s3_tech_stack',
  },
  {
    label: 'Melis Platform',
    value: '_melis_platform_tech_stack',
  },
  {
    label: 'MemberStack',
    value: '_memberstack_tech_stack',
  },
  {
    label: 'Mention Me',
    value: '_mention_me_tech_stack',
  },
  {
    label: 'Menufy Online Ordering',
    value: '_menufy_online ordering_tech_stack',
  },
  {
    label: 'Menufy Website',
    value: '_menufy_website_tech_stack',
  },
  {
    label: 'Mercado Shops',
    value: '_mercado_shops_tech_stack',
  },
  {
    label: 'Mermaid',
    value: '_mermaid_tech_stack',
  },
  {
    label: 'MetaSlider',
    value: '_metaslider_tech_stack',
  },
  {
    label: 'Meteor',
    value: '_meteor_tech_stack',
  },
  {
    label: 'Methode',
    value: '_methode_tech_stack',
  },
  {
    label: 'Metomic',
    value: '_metomic_tech_stack',
  },
  {
    label: 'Metrilo',
    value: '_metrilo_tech_stack',
  },
  {
    label: 'Mews',
    value: '_mews_tech_stack',
  },
  {
    label: 'Microsoft 365',
    value: '_microsoft_365_tech_stack',
  },
  {
    label: 'Microsoft ASP.NET',
    value: '_microsoft_asp.net_tech_stack',
  },
  {
    label: 'Microsoft Advertising',
    value: '_microsoft_advertising_tech_stack',
  },
  {
    label: 'Microsoft Ajax Content Delivery Network',
    value: '_microsoft_ajax content delivery network_tech_stack',
  },
  {
    label: 'Microsoft Application Insights',
    value: '_microsoft_application insights_tech_stack',
  },
  {
    label: 'Microsoft Authentication',
    value: '_microsoft_authentication_tech_stack',
  },
  {
    label: 'Microsoft Clarity',
    value: '_microsoft_clarity_tech_stack',
  },
  {
    label: 'Microsoft Dynamics 365 Commerce',
    value: '_microsoft_dynamics 365 commerce_tech_stack',
  },
  {
    label: 'Microsoft Excel',
    value: '_microsoft_excel_tech_stack',
  },
  {
    label: 'Microsoft HTTPAPI',
    value: '_microsoft_httpapi_tech_stack',
  },
  {
    label: 'Microsoft PowerPoint',
    value: '_microsoft_powerpoint_tech_stack',
  },
  {
    label: 'Microsoft Publisher',
    value: '_microsoft_publisher_tech_stack',
  },
  {
    label: 'Microsoft SharePoint',
    value: '_microsoft_sharepoint_tech_stack',
  },
  {
    label: 'Microsoft Word',
    value: '_microsoft_word_tech_stack',
  },
  {
    label: 'Miestro',
    value: '_miestro_tech_stack',
  },
  {
    label: 'Milestone CMS',
    value: '_milestone_cms_tech_stack',
  },
  {
    label: 'Milligram',
    value: '_milligram_tech_stack',
  },
  {
    label: 'MinMaxify',
    value: '_minmaxify_tech_stack',
  },
  {
    label: 'MindBody',
    value: '_mindbody_tech_stack',
  },
  {
    label: 'Mindbox',
    value: '_mindbox_tech_stack',
  },
  {
    label: 'Minero.cc',
    value: '_minero.cc_tech_stack',
  },
  {
    label: 'MiniServ',
    value: '_miniserv_tech_stack',
  },
  {
    label: 'Mint',
    value: '_mint_tech_stack',
  },
  {
    label: 'Misskey',
    value: '_misskey_tech_stack',
  },
  {
    label: 'Mittwald',
    value: '_mittwald_tech_stack',
  },
  {
    label: 'Miva',
    value: '_miva_tech_stack',
  },
  {
    label: 'Mixin',
    value: '_mixin_tech_stack',
  },
  {
    label: 'Mixpanel',
    value: '_mixpanel_tech_stack',
  },
  {
    label: 'MizbanCloud',
    value: '_mizbancloud_tech_stack',
  },
  {
    label: 'MkDocs',
    value: '_mkdocs_tech_stack',
  },
  {
    label: 'MoEngage',
    value: '_moengage_tech_stack',
  },
  {
    label: 'Moat',
    value: '_moat_tech_stack',
  },
  {
    label: 'MobX',
    value: '_mobx_tech_stack',
  },
  {
    label: 'Mobify',
    value: '_mobify_tech_stack',
  },
  {
    label: 'Mobirise',
    value: '_mobirise_tech_stack',
  },
  {
    label: 'MochiKit',
    value: '_mochikit_tech_stack',
  },
  {
    label: 'MochiWeb',
    value: '_mochiweb_tech_stack',
  },
  {
    label: 'Modernizr',
    value: '_modernizr_tech_stack',
  },
  {
    label: 'ModiFace',
    value: '_modiface_tech_stack',
  },
  {
    label: 'Modified',
    value: '_modified_tech_stack',
  },
  {
    label: 'Module Federation',
    value: '_module_federation_tech_stack',
  },
  {
    label: 'Moguta.CMS',
    value: '_moguta.cms_tech_stack',
  },
  {
    label: 'MoinMoin',
    value: '_moinmoin_tech_stack',
  },
  {
    label: 'Mojolicious',
    value: '_mojolicious_tech_stack',
  },
  {
    label: 'Mokka',
    value: '_mokka_tech_stack',
  },
  {
    label: 'Mollom',
    value: '_mollom_tech_stack',
  },
  {
    label: 'Moment Timezone',
    value: '_moment_timezone_tech_stack',
  },
  {
    label: 'Moment.js',
    value: '_moment.js_tech_stack',
  },
  {
    label: 'Monaco Editor',
    value: '_monaco_editor_tech_stack',
  },
  {
    label: 'Mondial Relay',
    value: '_mondial_relay_tech_stack',
  },
  {
    label: 'Mondo Media',
    value: '_mondo_media_tech_stack',
  },
  {
    label: 'Moneris',
    value: '_moneris_tech_stack',
  },
  {
    label: 'Moneris Payment Gateway',
    value: '_moneris_payment gateway_tech_stack',
  },
  {
    label: 'MongoDB',
    value: '_mongodb_tech_stack',
  },
  {
    label: 'Mongrel',
    value: '_mongrel_tech_stack',
  },
  {
    label: 'Monkey HTTP Server',
    value: '_monkey_http server_tech_stack',
  },
  {
    label: 'Mono',
    value: '_mono_tech_stack',
  },
  {
    label: 'Mono.net',
    value: '_mono.net_tech_stack',
  },
  {
    label: 'Monsido',
    value: '_monsido_tech_stack',
  },
  {
    label: 'MonsterInsights',
    value: '_monsterinsights_tech_stack',
  },
  {
    label: 'MooTools',
    value: '_mootools_tech_stack',
  },
  {
    label: 'Moodle',
    value: '_moodle_tech_stack',
  },
  {
    label: 'Moon',
    value: '_moon_tech_stack',
  },
  {
    label: 'Moove GDPR Consent',
    value: '_moove_gdpr consent_tech_stack',
  },
  {
    label: 'Mopinion',
    value: '_mopinion_tech_stack',
  },
  {
    label: 'Moshimo',
    value: '_moshimo_tech_stack',
  },
  {
    label: 'MotoCMS',
    value: '_motocms_tech_stack',
  },
  {
    label: 'Mouse Flow',
    value: '_mouse_flow_tech_stack',
  },
  {
    label: 'Movable Ink',
    value: '_movable_ink_tech_stack',
  },
  {
    label: 'Movable Type',
    value: '_movable_type_tech_stack',
  },
  {
    label: 'Mozard Suite',
    value: '_mozard_suite_tech_stack',
  },
  {
    label: 'Mulberry',
    value: '_mulberry_tech_stack',
  },
  {
    label: 'Mura CMS',
    value: '_mura_cms_tech_stack',
  },
  {
    label: 'Mustache',
    value: '_mustache_tech_stack',
  },
  {
    label: 'Muuri',
    value: '_muuri_tech_stack',
  },
  {
    label: 'My Flying Box',
    value: '_my_flying box_tech_stack',
  },
  {
    label: 'My Food Link',
    value: '_my_food link_tech_stack',
  },
  {
    label: 'MyBB',
    value: '_mybb_tech_stack',
  },
  {
    label: 'MyBlogLog',
    value: '_mybloglog_tech_stack',
  },
  {
    label: 'MyCashFlow',
    value: '_mycashflow_tech_stack',
  },
  {
    label: 'MyFonts',
    value: '_myfonts_tech_stack',
  },
  {
    label: 'MyLiveChat',
    value: '_mylivechat_tech_stack',
  },
  {
    label: 'MyOnlineStore',
    value: '_myonlinestore_tech_stack',
  },
  {
    label: 'MySQL',
    value: '_mysql_tech_stack',
  },
  {
    label: 'MyWebsite',
    value: '_mywebsite_tech_stack',
  },
  {
    label: 'MyWebsite Creator',
    value: '_mywebsite_creator_tech_stack',
  },
  {
    label: 'MyWebsite Now',
    value: '_mywebsite_now_tech_stack',
  },
  {
    label: 'Myhkw player',
    value: '_myhkw_player_tech_stack',
  },
  {
    label: 'Mynetcap',
    value: '_mynetcap_tech_stack',
  },
  {
    label: 'MysteryThemes News Portal',
    value: '_mysterythemes_news portal_tech_stack',
  },
  {
    label: 'MysteryThemes News Portal Lite',
    value: '_mysterythemes_news portal lite_tech_stack',
  },
  {
    label: 'MysteryThemes News Portal Mag',
    value: '_mysterythemes_news portal mag_tech_stack',
  },
  {
    label: 'mParticle',
    value: '_mparticle_tech_stack',
  },
  {
    label: 'math.js',
    value: '_math.js_tech_stack',
  },
  {
    label: 'mdBook',
    value: '_mdbook_tech_stack',
  },
  {
    label: 'metisMenu',
    value: '_metismenu_tech_stack',
  },
  {
    label: 'microCMS',
    value: '_microcms_tech_stack',
  },
  {
    label: 'mini_httpd',
    value: '_mini_httpd_tech_stack',
  },
  {
    label: 'mirrAR',
    value: '_mirrar_tech_stack',
  },
  {
    label: 'mobicred',
    value: '_mobicred_tech_stack',
  },
  {
    label: 'mod_auth_pam',
    value: '_mod_auth_pam_tech_stack',
  },
  {
    label: 'mod_dav',
    value: '_mod_dav_tech_stack',
  },
  {
    label: 'mod_fastcgi',
    value: '_mod_fastcgi_tech_stack',
  },
  {
    label: 'mod_jk',
    value: '_mod_jk_tech_stack',
  },
  {
    label: 'mod_perl',
    value: '_mod_perl_tech_stack',
  },
  {
    label: 'mod_python',
    value: '_mod_python_tech_stack',
  },
  {
    label: 'mod_rack',
    value: '_mod_rack_tech_stack',
  },
  {
    label: 'mod_rails',
    value: '_mod_rails_tech_stack',
  },
  {
    label: 'mod_ssl',
    value: '_mod_ssl_tech_stack',
  },
  {
    label: 'mod_wsgi',
    value: '_mod_wsgi_tech_stack',
  },
  {
    label: 'NACEX',
    value: '_nacex_tech_stack',
  },
  {
    label: 'NEO - Omnichannel Commerce Platform',
    value: '_neo_- omnichannel commerce platform_tech_stack',
  },
  {
    label: 'NSW Design System',
    value: '_nsw_design system_tech_stack',
  },
  {
    label: 'NTLM',
    value: '_ntlm_tech_stack',
  },
  {
    label: 'NVD3',
    value: '_nvd3_tech_stack',
  },
  {
    label: 'Nacelle',
    value: '_nacelle_tech_stack',
  },
  {
    label: 'NagaCommerce',
    value: '_nagacommerce_tech_stack',
  },
  {
    label: 'Nagich',
    value: '_nagich_tech_stack',
  },
  {
    label: 'NagishLi',
    value: '_nagishli_tech_stack',
  },
  {
    label: 'Najva',
    value: '_najva_tech_stack',
  },
  {
    label: 'Narrativ',
    value: '_narrativ_tech_stack',
  },
  {
    label: 'Narvar',
    value: '_narvar_tech_stack',
  },
  {
    label: 'NationBuilder',
    value: '_nationbuilder_tech_stack',
  },
  {
    label: 'Nativo',
    value: '_nativo_tech_stack',
  },
  {
    label: 'Navegg',
    value: '_navegg_tech_stack',
  },
  {
    label: 'Naver Analytics',
    value: '_naver_analytics_tech_stack',
  },
  {
    label: 'Naver Maps',
    value: '_naver_maps_tech_stack',
  },
  {
    label: 'Naver RUA',
    value: '_naver_rua_tech_stack',
  },
  {
    label: 'Neat A/B testing',
    value: '_neat_a/b testing_tech_stack',
  },
  {
    label: 'Neos CMS',
    value: '_neos_cms_tech_stack',
  },
  {
    label: 'Neos Flow',
    value: '_neos_flow_tech_stack',
  },
  {
    label: 'Nepso',
    value: '_nepso_tech_stack',
  },
  {
    label: 'Nestify',
    value: '_nestify_tech_stack',
  },
  {
    label: 'NetSuite',
    value: '_netsuite_tech_stack',
  },
  {
    label: 'Netlify',
    value: '_netlify_tech_stack',
  },
  {
    label: 'Neto',
    value: '_neto_tech_stack',
  },
  {
    label: 'Nette Framework',
    value: '_nette_framework_tech_stack',
  },
  {
    label: 'Neve',
    value: '_neve_tech_stack',
  },
  {
    label: 'New Relic',
    value: '_new_relic_tech_stack',
  },
  {
    label: 'NewStore',
    value: '_newstore_tech_stack',
  },
  {
    label: 'Newspack',
    value: '_newspack_tech_stack',
  },
  {
    label: 'Newspack by Automattic',
    value: '_newspack_by automattic_tech_stack',
  },
  {
    label: 'Nexcess',
    value: '_nexcess_tech_stack',
  },
  {
    label: 'Nexive',
    value: '_nexive_tech_stack',
  },
  {
    label: 'Next Total',
    value: '_next_total_tech_stack',
  },
  {
    label: 'Next.js',
    value: '_next.js_tech_stack',
  },
  {
    label: 'NextGEN Gallery',
    value: '_nextgen_gallery_tech_stack',
  },
  {
    label: 'Nextdoor Ads',
    value: '_nextdoor_ads_tech_stack',
  },
  {
    label: 'Nextsale',
    value: '_nextsale_tech_stack',
  },
  {
    label: 'Nginx',
    value: '_nginx_tech_stack',
  },
  {
    label: 'Niagahoster',
    value: '_niagahoster_tech_stack',
  },
  {
    label: 'Nicepage',
    value: '_nicepage_tech_stack',
  },
  {
    label: 'Nift',
    value: '_nift_tech_stack',
  },
  {
    label: 'Ninja Forms',
    value: '_ninja_forms_tech_stack',
  },
  {
    label: 'NitroPack',
    value: '_nitropack_tech_stack',
  },
  {
    label: 'NoFraud',
    value: '_nofraud_tech_stack',
  },
  {
    label: 'Noddus',
    value: '_noddus_tech_stack',
  },
  {
    label: 'Node.js',
    value: '_node.js_tech_stack',
  },
  {
    label: 'NodeBB',
    value: '_nodebb_tech_stack',
  },
  {
    label: 'Norton Shopping Guarantee',
    value: '_norton_shopping guarantee_tech_stack',
  },
  {
    label: 'Nosto',
    value: '_nosto_tech_stack',
  },
  {
    label: 'Nosto Visual UGC',
    value: '_nosto_visual ugc_tech_stack',
  },
  {
    label: 'Notion',
    value: '_notion_tech_stack',
  },
  {
    label: 'Nudgify',
    value: '_nudgify_tech_stack',
  },
  {
    label: 'Nukeviet CMS',
    value: '_nukeviet_cms_tech_stack',
  },
  {
    label: 'Nuqlium',
    value: '_nuqlium_tech_stack',
  },
  {
    label: 'Nuvemshop',
    value: '_nuvemshop_tech_stack',
  },
  {
    label: 'Nuxt.js',
    value: '_nuxt.js_tech_stack',
  },
  {
    label: 'nghttpx - HTTP/2 proxy',
    value: '_nghttpx_- http/2 proxy_tech_stack',
  },
  {
    label: 'nopCommerce',
    value: '_nopcommerce_tech_stack',
  },
  {
    label: 'nopStation',
    value: '_nopstation_tech_stack',
  },
  {
    label: 'novomind iSHOP',
    value: '_novomind_ishop_tech_stack',
  },
  {
    label: 'OVHcloud',
    value: '_ovhcloud_tech_stack',
  },
  {
    label: 'OWL Carousel',
    value: '_owl_carousel_tech_stack',
  },
  {
    label: 'OXID eShop',
    value: '_oxid_eshop_tech_stack',
  },
  {
    label: 'OXID eShop Community Edition',
    value: '_oxid_eshop community edition_tech_stack',
  },
  {
    label: 'OXID eShop Enterprise Edition',
    value: '_oxid_eshop enterprise edition_tech_stack',
  },
  {
    label: 'Obsidian Incentivize',
    value: '_obsidian_incentivize_tech_stack',
  },
  {
    label: 'Obviyo',
    value: '_obviyo_tech_stack',
  },
  {
    label: 'Occasion',
    value: '_occasion_tech_stack',
  },
  {
    label: 'OceanWP',
    value: '_oceanwp_tech_stack',
  },
  {
    label: 'Ochanoko',
    value: '_ochanoko_tech_stack',
  },
  {
    label: 'Oct8ne',
    value: '_oct8ne_tech_stack',
  },
  {
    label: 'Octane AI',
    value: '_octane_ai_tech_stack',
  },
  {
    label: 'October CMS',
    value: '_october_cms_tech_stack',
  },
  {
    label: 'Octopress',
    value: '_octopress_tech_stack',
  },
  {
    label: 'Ocuco FitMix',
    value: '_ocuco_fitmix_tech_stack',
  },
  {
    label: 'Odoo',
    value: '_odoo_tech_stack',
  },
  {
    label: 'Oh Dear',
    value: '_oh_dear_tech_stack',
  },
  {
    label: 'Okendo',
    value: '_okendo_tech_stack',
  },
  {
    label: 'Okta',
    value: '_okta_tech_stack',
  },
  {
    label: 'Olapic',
    value: '_olapic_tech_stack',
  },
  {
    label: 'Olark',
    value: '_olark_tech_stack',
  },
  {
    label: 'Ometria',
    value: '_ometria_tech_stack',
  },
  {
    label: 'Omise',
    value: '_omise_tech_stack',
  },
  {
    label: 'Omni CMS',
    value: '_omni_cms_tech_stack',
  },
  {
    label: 'Omniconvert',
    value: '_omniconvert_tech_stack',
  },
  {
    label: 'Omnisend',
    value: '_omnisend_tech_stack',
  },
  {
    label: 'Omny Studio',
    value: '_omny_studio_tech_stack',
  },
  {
    label: 'Omurga Sistemi',
    value: '_omurga_sistemi_tech_stack',
  },
  {
    label: 'OnShop',
    value: '_onshop_tech_stack',
  },
  {
    label: 'OnUniverse',
    value: '_onuniverse_tech_stack',
  },
  {
    label: 'One.com',
    value: '_one.com_tech_stack',
  },
  {
    label: 'OneAPM',
    value: '_oneapm_tech_stack',
  },
  {
    label: 'OneAll',
    value: '_oneall_tech_stack',
  },
  {
    label: 'OnePage Express',
    value: '_onepage_express_tech_stack',
  },
  {
    label: 'OnePress Social Locker',
    value: '_onepress_social locker_tech_stack',
  },
  {
    label: 'OneSignal',
    value: '_onesignal_tech_stack',
  },
  {
    label: 'OneStat',
    value: '_onestat_tech_stack',
  },
  {
    label: 'OneTrust',
    value: '_onetrust_tech_stack',
  },
  {
    label: 'Oney',
    value: '_oney_tech_stack',
  },
  {
    label: 'Onfido',
    value: '_onfido_tech_stack',
  },
  {
    label: 'Ookla Speedtest Custom',
    value: '_ookla_speedtest custom_tech_stack',
  },
  {
    label: 'Oopy',
    value: '_oopy_tech_stack',
  },
  {
    label: 'Open AdStream',
    value: '_open_adstream_tech_stack',
  },
  {
    label: 'Open Classifieds',
    value: '_open_classifieds_tech_stack',
  },
  {
    label: 'Open Graph',
    value: '_open_graph_tech_stack',
  },
  {
    label: 'Open Journal Systems',
    value: '_open_journal systems_tech_stack',
  },
  {
    label: 'Open Web Analytics',
    value: '_open_web analytics_tech_stack',
  },
  {
    label: 'Open eShop',
    value: '_open_eshop_tech_stack',
  },
  {
    label: 'Open-Xchange App Suite',
    value: '_open-xchange_app suite_tech_stack',
  },
  {
    label: 'OpenBSD httpd',
    value: '_openbsd_httpd_tech_stack',
  },
  {
    label: 'OpenCart',
    value: '_opencart_tech_stack',
  },
  {
    label: 'OpenCms',
    value: '_opencms_tech_stack',
  },
  {
    label: 'OpenElement',
    value: '_openelement_tech_stack',
  },
  {
    label: 'OpenGSE',
    value: '_opengse_tech_stack',
  },
  {
    label: 'OpenGrok',
    value: '_opengrok_tech_stack',
  },
  {
    label: 'OpenLayers',
    value: '_openlayers_tech_stack',
  },
  {
    label: 'OpenNemas',
    value: '_opennemas_tech_stack',
  },
  {
    label: 'OpenPay',
    value: '_openpay_tech_stack',
  },
  {
    label: 'OpenResty',
    value: '_openresty_tech_stack',
  },
  {
    label: 'OpenSSL',
    value: '_openssl_tech_stack',
  },
  {
    label: 'OpenStreetMap',
    value: '_openstreetmap_tech_stack',
  },
  {
    label: 'OpenTable',
    value: '_opentable_tech_stack',
  },
  {
    label: 'OpenText Web Solutions',
    value: '_opentext_web solutions_tech_stack',
  },
  {
    label: 'OpenUI5',
    value: '_openui5_tech_stack',
  },
  {
    label: 'OpenWeb',
    value: '_openweb_tech_stack',
  },
  {
    label: 'OpenX',
    value: '_openx_tech_stack',
  },
  {
    label: 'OpinionLab',
    value: '_opinionlab_tech_stack',
  },
  {
    label: 'OptiMonk',
    value: '_optimonk_tech_stack',
  },
  {
    label: 'Optimise',
    value: '_optimise_tech_stack',
  },
  {
    label: 'Optimizely',
    value: '_optimizely_tech_stack',
  },
  {
    label: 'Optimove',
    value: '_optimove_tech_stack',
  },
  {
    label: 'OptinMonster',
    value: '_optinmonster_tech_stack',
  },
  {
    label: 'OptinMonster plugin',
    value: '_optinmonster_plugin_tech_stack',
  },
  {
    label: 'Oracle Application Server',
    value: '_oracle_application server_tech_stack',
  },
  {
    label: 'Oracle BlueKai',
    value: '_oracle_bluekai_tech_stack',
  },
  {
    label: 'Oracle Commerce',
    value: '_oracle_commerce_tech_stack',
  },
  {
    label: 'Oracle Commerce Cloud',
    value: '_oracle_commerce cloud_tech_stack',
  },
  {
    label: 'Oracle Dynamic Monitoring Service',
    value: '_oracle_dynamic monitoring service_tech_stack',
  },
  {
    label: 'Oracle HTTP Server',
    value: '_oracle_http server_tech_stack',
  },
  {
    label: 'Oracle Infinity',
    value: '_oracle_infinity_tech_stack',
  },
  {
    label: 'Oracle Maxymiser',
    value: '_oracle_maxymiser_tech_stack',
  },
  {
    label: 'Oracle Recommendations On Demand',
    value: '_oracle_recommendations on demand_tech_stack',
  },
  {
    label: 'Oracle Web Cache',
    value: '_oracle_web cache_tech_stack',
  },
  {
    label: 'Orankl',
    value: '_orankl_tech_stack',
  },
  {
    label: 'OrbitFox',
    value: '_orbitfox_tech_stack',
  },
  {
    label: 'Orchard CMS',
    value: '_orchard_cms_tech_stack',
  },
  {
    label: 'Orckestra',
    value: '_orckestra_tech_stack',
  },
  {
    label: 'Order Deadline',
    value: '_order_deadline_tech_stack',
  },
  {
    label: 'OrderLogic app',
    value: '_orderlogic_app_tech_stack',
  },
  {
    label: 'OrderYOYO',
    value: '_orderyoyo_tech_stack',
  },
  {
    label: 'Ordersify Product Alerts',
    value: '_ordersify_product alerts_tech_stack',
  },
  {
    label: 'OroCommerce',
    value: '_orocommerce_tech_stack',
  },
  {
    label: 'Osano',
    value: '_osano_tech_stack',
  },
  {
    label: 'Osterreichische Post',
    value: '_osterreichische_post_tech_stack',
  },
  {
    label: 'OutSystems',
    value: '_outsystems_tech_stack',
  },
  {
    label: 'OutTheBoxThemes Panoramic',
    value: '_outtheboxthemes_panoramic_tech_stack',
  },
  {
    label: 'Outbrain',
    value: '_outbrain_tech_stack',
  },
  {
    label: 'Outlook Web App',
    value: '_outlook_web app_tech_stack',
  },
  {
    label: 'Oxatis',
    value: '_oxatis_tech_stack',
  },
  {
    label: 'Oxi Social Login',
    value: '_oxi_social login_tech_stack',
  },
  {
    label: 'Oxygen',
    value: '_oxygen_tech_stack',
  },
  {
    label: 'osCommerce',
    value: '_oscommerce_tech_stack',
  },
  {
    label: 'osTicket',
    value: '_osticket_tech_stack',
  },
  {
    label: 'otrs',
    value: '_otrs_tech_stack',
  },
  {
    label: 'ownCloud',
    value: '_owncloud_tech_stack',
  },
  {
    label: 'PCRecruiter',
    value: '_pcrecruiter_tech_stack',
  },
  {
    label: 'PDF.js',
    value: '_pdf.js_tech_stack',
  },
  {
    label: 'PHP',
    value: '_php_tech_stack',
  },
  {
    label: 'PHP-Nuke',
    value: '_php-nuke_tech_stack',
  },
  {
    label: 'PHPDebugBar',
    value: '_phpdebugbar_tech_stack',
  },
  {
    label: 'PHPFusion',
    value: '_phpfusion_tech_stack',
  },
  {
    label: 'PIXIjs',
    value: '_pixijs_tech_stack',
  },
  {
    label: 'POLi Payment',
    value: '_poli_payment_tech_stack',
  },
  {
    label: 'POWR',
    value: '_powr_tech_stack',
  },
  {
    label: 'PWA',
    value: '_pwa_tech_stack',
  },
  {
    label: 'PWA Studio',
    value: '_pwa_studio_tech_stack',
  },
  {
    label: 'Pace',
    value: '_pace_tech_stack',
  },
  {
    label: 'Packlink PRO',
    value: '_packlink_pro_tech_stack',
  },
  {
    label: 'Paddle',
    value: '_paddle_tech_stack',
  },
  {
    label: 'PagSeguro',
    value: '_pagseguro_tech_stack',
  },
  {
    label: 'Pagar.me',
    value: '_pagar.me_tech_stack',
  },
  {
    label: 'Page Builder Framework',
    value: '_page_builder framework_tech_stack',
  },
  {
    label: 'PageFly',
    value: '_pagefly_tech_stack',
  },
  {
    label: 'Pagekit',
    value: '_pagekit_tech_stack',
  },
  {
    label: 'Pagely',
    value: '_pagely_tech_stack',
  },
  {
    label: 'Pagevamp',
    value: '_pagevamp_tech_stack',
  },
  {
    label: 'Paidy',
    value: '_paidy_tech_stack',
  },
  {
    label: 'Paloma',
    value: '_paloma_tech_stack',
  },
  {
    label: 'Pantheon',
    value: '_pantheon_tech_stack',
  },
  {
    label: 'Paradox',
    value: '_paradox_tech_stack',
  },
  {
    label: 'Parcelforce',
    value: '_parcelforce_tech_stack',
  },
  {
    label: 'Pardot',
    value: '_pardot_tech_stack',
  },
  {
    label: 'ParkingCrew',
    value: '_parkingcrew_tech_stack',
  },
  {
    label: 'Pars Elecom Portal',
    value: '_pars_elecom portal_tech_stack',
  },
  {
    label: 'Parse.ly',
    value: '_parse.ly_tech_stack',
  },
  {
    label: 'Partial.ly',
    value: '_partial.ly_tech_stack',
  },
  {
    label: 'Partnerize',
    value: '_partnerize_tech_stack',
  },
  {
    label: 'Parttrap ONE',
    value: '_parttrap_one_tech_stack',
  },
  {
    label: 'Partytown',
    value: '_partytown_tech_stack',
  },
  {
    label: 'Paths.js',
    value: '_paths.js_tech_stack',
  },
  {
    label: 'Patreon',
    value: '_patreon_tech_stack',
  },
  {
    label: 'Pattern by Etsy',
    value: '_pattern_by etsy_tech_stack',
  },
  {
    label: 'Pay It Later',
    value: '_pay_it later_tech_stack',
  },
  {
    label: 'PayBright',
    value: '_paybright_tech_stack',
  },
  {
    label: 'PayFast',
    value: '_payfast_tech_stack',
  },
  {
    label: 'PayJustNow',
    value: '_payjustnow_tech_stack',
  },
  {
    label: 'PayKickStart',
    value: '_paykickstart_tech_stack',
  },
  {
    label: 'PayPal',
    value: '_paypal_tech_stack',
  },
  {
    label: 'PayPal Credit',
    value: '_paypal_credit_tech_stack',
  },
  {
    label: 'PayPal Marketing Solutions',
    value: '_paypal_marketing solutions_tech_stack',
  },
  {
    label: 'PayWhirl',
    value: '_paywhirl_tech_stack',
  },
  {
    label: 'Payflex',
    value: '_payflex_tech_stack',
  },
  {
    label: 'Payl8r',
    value: '_payl8r_tech_stack',
  },
  {
    label: 'Paylocity',
    value: '_paylocity_tech_stack',
  },
  {
    label: 'PebblePost',
    value: '_pebblepost_tech_stack',
  },
  {
    label: 'Peek',
    value: '_peek_tech_stack',
  },
  {
    label: 'PeerBoard',
    value: '_peerboard_tech_stack',
  },
  {
    label: 'PeerTube',
    value: '_peertube_tech_stack',
  },
  {
    label: 'Pelican',
    value: '_pelican_tech_stack',
  },
  {
    label: 'PencilBlue',
    value: '_pencilblue_tech_stack',
  },
  {
    label: 'Pendo',
    value: '_pendo_tech_stack',
  },
  {
    label: 'Pepperjam',
    value: '_pepperjam_tech_stack',
  },
  {
    label: 'Percona',
    value: '_percona_tech_stack',
  },
  {
    label: 'Percussion',
    value: '_percussion_tech_stack',
  },
  {
    label: 'PerfectApps Swift',
    value: '_perfectapps_swift_tech_stack',
  },
  {
    label: 'Perfex CRM',
    value: '_perfex_crm_tech_stack',
  },
  {
    label: 'Performance Lab',
    value: '_performance_lab_tech_stack',
  },
  {
    label: 'PerimeterX',
    value: '_perimeterx_tech_stack',
  },
  {
    label: 'Periodic',
    value: '_periodic_tech_stack',
  },
  {
    label: 'Perl',
    value: '_perl_tech_stack',
  },
  {
    label: 'Permutive',
    value: '_permutive_tech_stack',
  },
  {
    label: 'PersonaClick',
    value: '_personaclick_tech_stack',
  },
  {
    label: 'Personio',
    value: '_personio_tech_stack',
  },
  {
    label: 'Personizely',
    value: '_personizely_tech_stack',
  },
  {
    label: 'Perzonalization',
    value: '_perzonalization_tech_stack',
  },
  {
    label: 'Phabricator',
    value: '_phabricator_tech_stack',
  },
  {
    label: 'Phaser',
    value: '_phaser_tech_stack',
  },
  {
    label: 'Phenomic',
    value: '_phenomic_tech_stack',
  },
  {
    label: 'Phlox',
    value: '_phlox_tech_stack',
  },
  {
    label: 'Phoenix',
    value: '_phoenix_tech_stack',
  },
  {
    label: 'Phoenix Site',
    value: '_phoenix_site_tech_stack',
  },
  {
    label: 'Photo Gallery',
    value: '_photo_gallery_tech_stack',
  },
  {
    label: 'PhotoShelter',
    value: '_photoshelter_tech_stack',
  },
  {
    label: 'PhotoShelter for Brands',
    value: '_photoshelter_for brands_tech_stack',
  },
  {
    label: 'PhotoSwipe',
    value: '_photoswipe_tech_stack',
  },
  {
    label: 'Photoslurp',
    value: '_photoslurp_tech_stack',
  },
  {
    label: 'Phusion Passenger',
    value: '_phusion_passenger_tech_stack',
  },
  {
    label: 'Piano',
    value: '_piano_tech_stack',
  },
  {
    label: 'PickyStory',
    value: '_pickystory_tech_stack',
  },
  {
    label: 'Pico',
    value: '_pico_tech_stack',
  },
  {
    label: 'Picreel',
    value: '_picreel_tech_stack',
  },
  {
    label: 'Picturepark',
    value: '_picturepark_tech_stack',
  },
  {
    label: 'Pimcore',
    value: '_pimcore_tech_stack',
  },
  {
    label: 'Pin Payments',
    value: '_pin_payments_tech_stack',
  },
  {
    label: 'Pingdom',
    value: '_pingdom_tech_stack',
  },
  {
    label: 'Pingoteam',
    value: '_pingoteam_tech_stack',
  },
  {
    label: 'Pinterest',
    value: '_pinterest_tech_stack',
  },
  {
    label: 'Pinterest Ads',
    value: '_pinterest_ads_tech_stack',
  },
  {
    label: 'Pinterest Conversion Tag',
    value: '_pinterest_conversion tag_tech_stack',
  },
  {
    label: 'Pipedrive',
    value: '_pipedrive_tech_stack',
  },
  {
    label: 'Piwik PRO Core',
    value: '_piwik_pro core_tech_stack',
  },
  {
    label: 'Pixc',
    value: '_pixc_tech_stack',
  },
  {
    label: 'PixelFed',
    value: '_pixelfed_tech_stack',
  },
  {
    label: 'PixelYourSite',
    value: '_pixelyoursite_tech_stack',
  },
  {
    label: 'Pixieset Store',
    value: '_pixieset_store_tech_stack',
  },
  {
    label: 'Pixieset Website',
    value: '_pixieset_website_tech_stack',
  },
  {
    label: 'Pixlee TurnTo',
    value: '_pixlee_turnto_tech_stack',
  },
  {
    label: 'Pixnet',
    value: '_pixnet_tech_stack',
  },
  {
    label: 'PizzaNetz',
    value: '_pizzanetz_tech_stack',
  },
  {
    label: 'Planet',
    value: '_planet_tech_stack',
  },
  {
    label: 'Platform.sh',
    value: '_platform.sh_tech_stack',
  },
  {
    label: 'PlatformOS',
    value: '_platformos_tech_stack',
  },
  {
    label: 'Plausible',
    value: '_plausible_tech_stack',
  },
  {
    label: 'Play',
    value: '_play_tech_stack',
  },
  {
    label: 'Plaza',
    value: '_plaza_tech_stack',
  },
  {
    label: 'Pleroma',
    value: '_pleroma_tech_stack',
  },
  {
    label: 'Plesk',
    value: '_plesk_tech_stack',
  },
  {
    label: 'Pligg',
    value: '_pligg_tech_stack',
  },
  {
    label: 'Plone',
    value: '_plone_tech_stack',
  },
  {
    label: 'Plotly',
    value: '_plotly_tech_stack',
  },
  {
    label: 'Plyr',
    value: '_plyr_tech_stack',
  },
  {
    label: 'Po.st',
    value: '_po.st_tech_stack',
  },
  {
    label: 'Podia',
    value: '_podia_tech_stack',
  },
  {
    label: 'Podigee',
    value: '_podigee_tech_stack',
  },
  {
    label: 'Podium',
    value: '_podium_tech_stack',
  },
  {
    label: 'Podsights',
    value: '_podsights_tech_stack',
  },
  {
    label: 'Pojo.me',
    value: '_pojo.me_tech_stack',
  },
  {
    label: 'Poloriz',
    value: '_poloriz_tech_stack',
  },
  {
    label: 'Polyfill',
    value: '_polyfill_tech_stack',
  },
  {
    label: 'Polylang',
    value: '_polylang_tech_stack',
  },
  {
    label: 'Polymer',
    value: '_polymer_tech_stack',
  },
  {
    label: 'Popmenu',
    value: '_popmenu_tech_stack',
  },
  {
    label: 'Popper',
    value: '_popper_tech_stack',
  },
  {
    label: 'PopularFX',
    value: '_popularfx_tech_stack',
  },
  {
    label: 'Popup Maker',
    value: '_popup_maker_tech_stack',
  },
  {
    label: 'Post Affiliate Pro',
    value: '_post_affiliate pro_tech_stack',
  },
  {
    label: 'PostNL',
    value: '_postnl_tech_stack',
  },
  {
    label: 'Poste Italiane',
    value: '_poste_italiane_tech_stack',
  },
  {
    label: 'Posterous',
    value: '_posterous_tech_stack',
  },
  {
    label: 'PostgreSQL',
    value: '_postgresql_tech_stack',
  },
  {
    label: 'Postpay',
    value: '_postpay_tech_stack',
  },
  {
    label: 'Postscript',
    value: '_postscript_tech_stack',
  },
  {
    label: 'PowerReviews',
    value: '_powerreviews_tech_stack',
  },
  {
    label: 'Powerboutique',
    value: '_powerboutique_tech_stack',
  },
  {
    label: 'Powergap',
    value: '_powergap_tech_stack',
  },
  {
    label: 'Preact',
    value: '_preact_tech_stack',
  },
  {
    label: 'Prebid',
    value: '_prebid_tech_stack',
  },
  {
    label: 'Prediggo',
    value: '_prediggo_tech_stack',
  },
  {
    label: 'Prefix-Free',
    value: '_prefix-free_tech_stack',
  },
  {
    label: 'Premio Chaty',
    value: '_premio_chaty_tech_stack',
  },
  {
    label: 'Press Customizr',
    value: '_press_customizr_tech_stack',
  },
  {
    label: 'Press Hueman',
    value: '_press_hueman_tech_stack',
  },
  {
    label: 'PressMaximum Customify',
    value: '_pressmaximum_customify_tech_stack',
  },
  {
    label: 'Pressable',
    value: '_pressable_tech_stack',
  },
  {
    label: 'PrestaShop',
    value: '_prestashop_tech_stack',
  },
  {
    label: 'Pretty Links',
    value: '_pretty_links_tech_stack',
  },
  {
    label: 'PriceSpider',
    value: '_pricespider_tech_stack',
  },
  {
    label: 'Primis',
    value: '_primis_tech_stack',
  },
  {
    label: 'Printful',
    value: '_printful_tech_stack',
  },
  {
    label: 'Priority Hints',
    value: '_priority_hints_tech_stack',
  },
  {
    label: 'Prism',
    value: '_prism_tech_stack',
  },
  {
    label: 'Prismic',
    value: '_prismic_tech_stack',
  },
  {
    label: 'Privy',
    value: '_privy_tech_stack',
  },
  {
    label: 'ProcessWire',
    value: '_processwire_tech_stack',
  },
  {
    label: 'Product Personalizer',
    value: '_product_personalizer_tech_stack',
  },
  {
    label: 'ProfilePress',
    value: '_profilepress_tech_stack',
  },
  {
    label: 'Profitwell',
    value: '_profitwell_tech_stack',
  },
  {
    label: 'Project Wonderful',
    value: '_project_wonderful_tech_stack',
  },
  {
    label: 'Projesoft',
    value: '_projesoft_tech_stack',
  },
  {
    label: 'PromoBuilding',
    value: '_promobuilding_tech_stack',
  },
  {
    label: 'Prototype',
    value: '_prototype_tech_stack',
  },
  {
    label: 'Protovis',
    value: '_protovis_tech_stack',
  },
  {
    label: 'ProvenExpert',
    value: '_provenexpert_tech_stack',
  },
  {
    label: 'Provide Support',
    value: '_provide_support_tech_stack',
  },
  {
    label: 'Proximis',
    value: '_proximis_tech_stack',
  },
  {
    label: 'Proximis Unified Commerce',
    value: '_proximis_unified commerce_tech_stack',
  },
  {
    label: 'Pterodactyl Panel',
    value: '_pterodactyl_panel_tech_stack',
  },
  {
    label: 'PubGuru',
    value: '_pubguru_tech_stack',
  },
  {
    label: 'PubMatic',
    value: '_pubmatic_tech_stack',
  },
  {
    label: 'PubSubJS',
    value: '_pubsubjs_tech_stack',
  },
  {
    label: 'Public CMS',
    value: '_public_cms_tech_stack',
  },
  {
    label: 'Pulse Secure',
    value: '_pulse_secure_tech_stack',
  },
  {
    label: 'Pure CSS',
    value: '_pure_css_tech_stack',
  },
  {
    label: 'Pure Chat',
    value: '_pure_chat_tech_stack',
  },
  {
    label: 'PureCars',
    value: '_purecars_tech_stack',
  },
  {
    label: 'PurpleAds',
    value: '_purpleads_tech_stack',
  },
  {
    label: 'PushDaddy Whatsapp Chat',
    value: '_pushdaddy_whatsapp chat_tech_stack',
  },
  {
    label: 'PushEngage',
    value: '_pushengage_tech_stack',
  },
  {
    label: 'PushOwl',
    value: '_pushowl_tech_stack',
  },
  {
    label: 'PushPushGo',
    value: '_pushpushgo_tech_stack',
  },
  {
    label: 'Pushnami',
    value: '_pushnami_tech_stack',
  },
  {
    label: 'Pygments',
    value: '_pygments_tech_stack',
  },
  {
    label: 'PyroCMS',
    value: '_pyrocms_tech_stack',
  },
  {
    label: 'Python',
    value: '_python_tech_stack',
  },
  {
    label: 'papaya CMS',
    value: '_papaya_cms_tech_stack',
  },
  {
    label: 'parcel',
    value: '_parcel_tech_stack',
  },
  {
    label: 'particles.js',
    value: '_particles.js_tech_stack',
  },
  {
    label: 'phpAlbum',
    value: '_phpalbum_tech_stack',
  },
  {
    label: 'phpBB',
    value: '_phpbb_tech_stack',
  },
  {
    label: 'phpCMS',
    value: '_phpcms_tech_stack',
  },
  {
    label: 'phpDocumentor',
    value: '_phpdocumentor_tech_stack',
  },
  {
    label: 'phpMyAdmin',
    value: '_phpmyadmin_tech_stack',
  },
  {
    label: 'phpPgAdmin',
    value: '_phppgadmin_tech_stack',
  },
  {
    label: 'phpSQLiteCMS',
    value: '_phpsqlitecms_tech_stack',
  },
  {
    label: 'phpwind',
    value: '_phpwind_tech_stack',
  },
  {
    label: 'pinoox',
    value: '_pinoox_tech_stack',
  },
  {
    label: 'pirobase CMS',
    value: '_pirobase_cms_tech_stack',
  },
  {
    label: 'plentyShop LTS',
    value: '_plentyshop_lts_tech_stack',
  },
  {
    label: 'plentymarkets',
    value: '_plentymarkets_tech_stack',
  },
  {
    label: 'prettyPhoto',
    value: '_prettyphoto_tech_stack',
  },
  {
    label: 'punBB',
    value: '_punbb_tech_stack',
  },
  {
    label: 'Q4',
    value: '_q4_tech_stack',
  },
  {
    label: 'Q4 Cookie Monster',
    value: '_q4_cookie monster_tech_stack',
  },
  {
    label: 'Qikify',
    value: '_qikify_tech_stack',
  },
  {
    label: 'Qstomizer',
    value: '_qstomizer_tech_stack',
  },
  {
    label: 'Qualaroo',
    value: '_qualaroo_tech_stack',
  },
  {
    label: 'Qualified',
    value: '_qualified_tech_stack',
  },
  {
    label: 'Qualtrics',
    value: '_qualtrics_tech_stack',
  },
  {
    label: 'Quanta',
    value: '_quanta_tech_stack',
  },
  {
    label: 'Quantcast Choice',
    value: '_quantcast_choice_tech_stack',
  },
  {
    label: 'Quantcast Measure',
    value: '_quantcast_measure_tech_stack',
  },
  {
    label: 'Quantum Metric',
    value: '_quantum_metric_tech_stack',
  },
  {
    label: 'Quasar',
    value: '_quasar_tech_stack',
  },
  {
    label: 'Qubit',
    value: '_qubit_tech_stack',
  },
  {
    label: 'Question2Answer',
    value: '_question2answer_tech_stack',
  },
  {
    label: 'Queue-it',
    value: '_queue-it_tech_stack',
  },
  {
    label: 'Quick.CMS',
    value: '_quick.cms_tech_stack',
  },
  {
    label: 'Quick.Cart',
    value: '_quick.cart_tech_stack',
  },
  {
    label: 'Quicklink',
    value: '_quicklink_tech_stack',
  },
  {
    label: 'Quicq',
    value: '_quicq_tech_stack',
  },
  {
    label: 'Quill',
    value: '_quill_tech_stack',
  },
  {
    label: 'Quora Pixel',
    value: '_quora_pixel_tech_stack',
  },
  {
    label: 'Qwik',
    value: '_qwik_tech_stack',
  },
  {
    label: 'qiankun',
    value: '_qiankun_tech_stack',
  },
  {
    label: 'RBS Change',
    value: '_rbs_change_tech_stack',
  },
  {
    label: 'RCMS',
    value: '_rcms_tech_stack',
  },
  {
    label: 'RD Station',
    value: '_rd_station_tech_stack',
  },
  {
    label: 'RDoc',
    value: '_rdoc_tech_stack',
  },
  {
    label: 'REDAXO',
    value: '_redaxo_tech_stack',
  },
  {
    label: 'REG.RU',
    value: '_reg.ru_tech_stack',
  },
  {
    label: 'RTB House',
    value: '_rtb_house_tech_stack',
  },
  {
    label: 'RX Web Server',
    value: '_rx_web server_tech_stack',
  },
  {
    label: 'RackCache',
    value: '_rackcache_tech_stack',
  },
  {
    label: 'Rain',
    value: '_rain_tech_stack',
  },
  {
    label: 'RainLoop',
    value: '_rainloop_tech_stack',
  },
  {
    label: 'Rakuten',
    value: '_rakuten_tech_stack',
  },
  {
    label: 'Rakuten Advertising',
    value: '_rakuten_advertising_tech_stack',
  },
  {
    label: 'Rakuten Digital Commerce',
    value: '_rakuten_digital commerce_tech_stack',
  },
  {
    label: 'Ramda',
    value: '_ramda_tech_stack',
  },
  {
    label: 'RankMath SEO',
    value: '_rankmath_seo_tech_stack',
  },
  {
    label: 'Raphael',
    value: '_raphael_tech_stack',
  },
  {
    label: 'RapidSec',
    value: '_rapidsec_tech_stack',
  },
  {
    label: 'RapidSpike',
    value: '_rapidspike_tech_stack',
  },
  {
    label: 'Raptor',
    value: '_raptor_tech_stack',
  },
  {
    label: 'Raspbian',
    value: '_raspbian_tech_stack',
  },
  {
    label: 'RateParity',
    value: '_rateparity_tech_stack',
  },
  {
    label: 'Raychat',
    value: '_raychat_tech_stack',
  },
  {
    label: 'Raygun',
    value: '_raygun_tech_stack',
  },
  {
    label: 'Rayo',
    value: '_rayo_tech_stack',
  },
  {
    label: 'Razorpay',
    value: '_razorpay_tech_stack',
  },
  {
    label: 'Re:amaze',
    value: '_re:amaze_tech_stack',
  },
  {
    label: 'ReCaptcha v2 for Contact Form 7',
    value: '_recaptcha_v2 for contact form 7_tech_stack',
  },
  {
    label: 'ReConvert',
    value: '_reconvert_tech_stack',
  },
  {
    label: 'ReDoc',
    value: '_redoc_tech_stack',
  },
  {
    label: 'React',
    value: '_react_tech_stack',
  },
  {
    label: 'React Bricks',
    value: '_react_bricks_tech_stack',
  },
  {
    label: 'React Redux',
    value: '_react_redux_tech_stack',
  },
  {
    label: 'React Router',
    value: '_react_router_tech_stack',
  },
  {
    label: 'Reactive',
    value: '_reactive_tech_stack',
  },
  {
    label: 'Really Simple CAPTCHA',
    value: '_really_simple captcha_tech_stack',
  },
  {
    label: 'RebelMouse',
    value: '_rebelmouse_tech_stack',
  },
  {
    label: 'Rebuy',
    value: '_rebuy_tech_stack',
  },
  {
    label: 'Recapture',
    value: '_recapture_tech_stack',
  },
  {
    label: 'Recart',
    value: '_recart_tech_stack',
  },
  {
    label: 'Recent Posts Widget With Thumbnails',
    value: '_recent_posts widget with thumbnails_tech_stack',
  },
  {
    label: 'Recharge',
    value: '_recharge_tech_stack',
  },
  {
    label: 'Recite Me',
    value: '_recite_me_tech_stack',
  },
  {
    label: 'Recomify',
    value: '_recomify_tech_stack',
  },
  {
    label: 'RecoverMyCart',
    value: '_recovermycart_tech_stack',
  },
  {
    label: 'Recruitee',
    value: '_recruitee_tech_stack',
  },
  {
    label: 'Recurly',
    value: '_recurly_tech_stack',
  },
  {
    label: 'Red Hat',
    value: '_red_hat_tech_stack',
  },
  {
    label: 'Red Hat Gluster',
    value: '_red_hat gluster_tech_stack',
  },
  {
    label: 'Red je Pakketje',
    value: '_red_je pakketje_tech_stack',
  },
  {
    label: 'RedCart',
    value: '_redcart_tech_stack',
  },
  {
    label: 'Reddit',
    value: '_reddit_tech_stack',
  },
  {
    label: 'Reddit Ads',
    value: '_reddit_ads_tech_stack',
  },
  {
    label: 'Redis',
    value: '_redis_tech_stack',
  },
  {
    label: 'Redis Object Cache',
    value: '_redis_object cache_tech_stack',
  },
  {
    label: 'Redmine',
    value: '_redmine_tech_stack',
  },
  {
    label: 'Redonner',
    value: '_redonner_tech_stack',
  },
  {
    label: 'Redux',
    value: '_redux_tech_stack',
  },
  {
    label: 'RedwoodJS',
    value: '_redwoodjs_tech_stack',
  },
  {
    label: 'Reelevant',
    value: '_reelevant_tech_stack',
  },
  {
    label: 'Reevoo',
    value: '_reevoo_tech_stack',
  },
  {
    label: 'ReferralCandy',
    value: '_referralcandy_tech_stack',
  },
  {
    label: 'Refersion',
    value: '_refersion_tech_stack',
  },
  {
    label: 'Reflektion',
    value: '_reflektion_tech_stack',
  },
  {
    label: 'Refundid',
    value: '_refundid_tech_stack',
  },
  {
    label: 'Regiondo',
    value: '_regiondo_tech_stack',
  },
  {
    label: 'Reinvigorate',
    value: '_reinvigorate_tech_stack',
  },
  {
    label: 'Relais Colis',
    value: '_relais_colis_tech_stack',
  },
  {
    label: 'Remarkable Commerce',
    value: '_remarkable_commerce_tech_stack',
  },
  {
    label: 'Remix',
    value: '_remix_tech_stack',
  },
  {
    label: 'Reputon',
    value: '_reputon_tech_stack',
  },
  {
    label: 'RequireJS',
    value: '_requirejs_tech_stack',
  },
  {
    label: 'ResDiary',
    value: '_resdiary_tech_stack',
  },
  {
    label: 'Resengo',
    value: '_resengo_tech_stack',
  },
  {
    label: 'Reservio',
    value: '_reservio_tech_stack',
  },
  {
    label: 'Resin',
    value: '_resin_tech_stack',
  },
  {
    label: 'Resmio',
    value: '_resmio_tech_stack',
  },
  {
    label: 'Responsive Lightbox & Gallery',
    value: '_responsive_lightbox & gallery_tech_stack',
  },
  {
    label: 'ResponsiveVoice',
    value: '_responsivevoice_tech_stack',
  },
  {
    label: 'Resy',
    value: '_resy_tech_stack',
  },
  {
    label: 'Retail Rocket',
    value: '_retail_rocket_tech_stack',
  },
  {
    label: 'ReturnGO',
    value: '_returngo_tech_stack',
  },
  {
    label: 'Returnly',
    value: '_returnly_tech_stack',
  },
  {
    label: 'RevJet',
    value: '_revjet_tech_stack',
  },
  {
    label: 'RevLifter',
    value: '_revlifter_tech_stack',
  },
  {
    label: 'Reveal.js',
    value: '_reveal.js_tech_stack',
  },
  {
    label: 'Revel',
    value: '_revel_tech_stack',
  },
  {
    label: 'RevenueHunt',
    value: '_revenuehunt_tech_stack',
  },
  {
    label: 'Revieve',
    value: '_revieve_tech_stack',
  },
  {
    label: 'ReviewSolicitors',
    value: '_reviewsolicitors_tech_stack',
  },
  {
    label: 'Reviews.io',
    value: '_reviews.io_tech_stack',
  },
  {
    label: 'RevolverMaps',
    value: '_revolvermaps_tech_stack',
  },
  {
    label: 'Revy',
    value: '_revy_tech_stack',
  },
  {
    label: 'Rewardful',
    value: '_rewardful_tech_stack',
  },
  {
    label: 'Rezdy',
    value: '_rezdy_tech_stack',
  },
  {
    label: 'Rezgo',
    value: '_rezgo_tech_stack',
  },
  {
    label: 'RichRelevance',
    value: '_richrelevance_tech_stack',
  },
  {
    label: 'Richpanel',
    value: '_richpanel_tech_stack',
  },
  {
    label: 'Rickshaw',
    value: '_rickshaw_tech_stack',
  },
  {
    label: 'RightJS',
    value: '_rightjs_tech_stack',
  },
  {
    label: 'Riot',
    value: '_riot_tech_stack',
  },
  {
    label: 'Rise.ai',
    value: '_rise.ai_tech_stack',
  },
  {
    label: 'Riskified',
    value: '_riskified_tech_stack',
  },
  {
    label: 'RiteCMS',
    value: '_ritecms_tech_stack',
  },
  {
    label: 'Rive',
    value: '_rive_tech_stack',
  },
  {
    label: 'Roadiz CMS',
    value: '_roadiz_cms_tech_stack',
  },
  {
    label: 'Robin',
    value: '_robin_tech_stack',
  },
  {
    label: 'RockRMS',
    value: '_rockrms_tech_stack',
  },
  {
    label: 'Rockerbox',
    value: '_rockerbox_tech_stack',
  },
  {
    label: 'Rocket.Chat',
    value: '_rocket.chat_tech_stack',
  },
  {
    label: 'Rokt',
    value: '_rokt_tech_stack',
  },
  {
    label: 'Rollbar',
    value: '_rollbar_tech_stack',
  },
  {
    label: 'Rotic',
    value: '_rotic_tech_stack',
  },
  {
    label: 'RoundCube',
    value: '_roundcube_tech_stack',
  },
  {
    label: 'Route',
    value: '_route_tech_stack',
  },
  {
    label: 'Royal Mail',
    value: '_royal_mail_tech_stack',
  },
  {
    label: 'Rubicon Project',
    value: '_rubicon_project_tech_stack',
  },
  {
    label: 'Ruby',
    value: '_ruby_tech_stack',
  },
  {
    label: 'Ruby Receptionists',
    value: '_ruby_receptionists_tech_stack',
  },
  {
    label: 'Ruby on Rails',
    value: '_ruby_on rails_tech_stack',
  },
  {
    label: 'Rudderstack',
    value: '_rudderstack_tech_stack',
  },
  {
    label: 'Rumble',
    value: '_rumble_tech_stack',
  },
  {
    label: 'Rust',
    value: '_rust_tech_stack',
  },
  {
    label: 'RxJS',
    value: '_rxjs_tech_stack',
  },
  {
    label: 'Ryviu',
    value: '_ryviu_tech_stack',
  },
  {
    label: 'reCAPTCHA',
    value: '_recaptcha_tech_stack',
  },
  {
    label: 'SALESmanago',
    value: '_salesmanago_tech_stack',
  },
  {
    label: 'SAP',
    value: '_sap_tech_stack',
  },
  {
    label: 'SAP Commerce Cloud',
    value: '_sap_commerce cloud_tech_stack',
  },
  {
    label: 'SAP Customer Data Cloud Sign-in',
    value: '_sap_customer data cloud sign-in_tech_stack',
  },
  {
    label: 'SAP Upscale Commerce',
    value: '_sap_upscale commerce_tech_stack',
  },
  {
    label: 'SDL Tridion',
    value: '_sdl_tridion_tech_stack',
  },
  {
    label: 'SEMrush',
    value: '_semrush_tech_stack',
  },
  {
    label: 'SEOmatic',
    value: '_seomatic_tech_stack',
  },
  {
    label: 'SEUR',
    value: '_seur_tech_stack',
  },
  {
    label: 'SHE Media',
    value: '_she_media_tech_stack',
  },
  {
    label: 'SIMsite',
    value: '_simsite_tech_stack',
  },
  {
    label: 'SMF',
    value: '_smf_tech_stack',
  },
  {
    label: 'SOBI 2',
    value: '_sobi_2_tech_stack',
  },
  {
    label: 'SPDY',
    value: '_spdy_tech_stack',
  },
  {
    label: 'SPIP',
    value: '_spip_tech_stack',
  },
  {
    label: 'SPNEGO',
    value: '_spnego_tech_stack',
  },
  {
    label: 'SQL Buddy',
    value: '_sql_buddy_tech_stack',
  },
  {
    label: 'SQLite',
    value: '_sqlite_tech_stack',
  },
  {
    label: 'STN Video',
    value: '_stn_video_tech_stack',
  },
  {
    label: 'STUDIO',
    value: '_studio_tech_stack',
  },
  {
    label: 'SUSE',
    value: '_suse_tech_stack',
  },
  {
    label: 'SVG Support',
    value: '_svg_support_tech_stack',
  },
  {
    label: 'SWFObject',
    value: '_swfobject_tech_stack',
  },
  {
    label: 'SaaSquatch',
    value: '_saasquatch_tech_stack',
  },
  {
    label: 'Saba.Host',
    value: '_saba.host_tech_stack',
  },
  {
    label: 'SabaVision',
    value: '_sabavision_tech_stack',
  },
  {
    label: 'Saber',
    value: '_saber_tech_stack',
  },
  {
    label: 'Sails.js',
    value: '_sails.js_tech_stack',
  },
  {
    label: 'Sailthru',
    value: '_sailthru_tech_stack',
  },
  {
    label: 'Sakura Internet',
    value: '_sakura_internet_tech_stack',
  },
  {
    label: 'SaleCycle',
    value: '_salecycle_tech_stack',
  },
  {
    label: 'Saleor',
    value: '_saleor_tech_stack',
  },
  {
    label: 'SalesFire',
    value: '_salesfire_tech_stack',
  },
  {
    label: 'SalesReps.io',
    value: '_salesreps.io_tech_stack',
  },
  {
    label: 'Salesfloor',
    value: '_salesfloor_tech_stack',
  },
  {
    label: 'Salesforce',
    value: '_salesforce_tech_stack',
  },
  {
    label: 'Salesforce Audience Studio',
    value: '_salesforce_audience studio_tech_stack',
  },
  {
    label: 'Salesforce Commerce Cloud',
    value: '_salesforce_commerce cloud_tech_stack',
  },
  {
    label: 'Salesforce Interaction Studio',
    value: '_salesforce_interaction studio_tech_stack',
  },
  {
    label: 'Salesforce Service Cloud',
    value: '_salesforce_service cloud_tech_stack',
  },
  {
    label: 'Salla',
    value: '_salla_tech_stack',
  },
  {
    label: 'Salonist',
    value: '_salonist_tech_stack',
  },
  {
    label: 'Salsify',
    value: '_salsify_tech_stack',
  },
  {
    label: 'Saly',
    value: '_saly_tech_stack',
  },
  {
    label: 'Sana Commerce',
    value: '_sana_commerce_tech_stack',
  },
  {
    label: 'Sanity',
    value: '_sanity_tech_stack',
  },
  {
    label: 'Sapper',
    value: '_sapper_tech_stack',
  },
  {
    label: 'Sapren',
    value: '_sapren_tech_stack',
  },
  {
    label: 'Sarka-SPIP',
    value: '_sarka-spip_tech_stack',
  },
  {
    label: 'Sass',
    value: '_sass_tech_stack',
  },
  {
    label: 'Satori',
    value: '_satori_tech_stack',
  },
  {
    label: 'Satori Studio Bento',
    value: '_satori_studio bento_tech_stack',
  },
  {
    label: 'Sazito',
    value: '_sazito_tech_stack',
  },
  {
    label: 'Scala',
    value: '_scala_tech_stack',
  },
  {
    label: 'Scalapay',
    value: '_scalapay_tech_stack',
  },
  {
    label: 'Scalefast',
    value: '_scalefast_tech_stack',
  },
  {
    label: 'Schedule Engine',
    value: '_schedule_engine_tech_stack',
  },
  {
    label: 'Scientific Linux',
    value: '_scientific_linux_tech_stack',
  },
  {
    label: 'Scissor Themes Writee',
    value: '_scissor_themes writee_tech_stack',
  },
  {
    label: 'Scoop.it',
    value: '_scoop.it_tech_stack',
  },
  {
    label: 'Scorpion',
    value: '_scorpion_tech_stack',
  },
  {
    label: 'ScrollMagic',
    value: '_scrollmagic_tech_stack',
  },
  {
    label: 'Scully',
    value: '_scully_tech_stack',
  },
  {
    label: 'SeQura',
    value: '_sequra_tech_stack',
  },
  {
    label: 'Seal Subscriptions',
    value: '_seal_subscriptions_tech_stack',
  },
  {
    label: 'SeamlessCMS',
    value: '_seamlesscms_tech_stack',
  },
  {
    label: 'SearchFit',
    value: '_searchfit_tech_stack',
  },
  {
    label: 'Searchanise',
    value: '_searchanise_tech_stack',
  },
  {
    label: 'Searchspring',
    value: '_searchspring_tech_stack',
  },
  {
    label: 'Secomapp',
    value: '_secomapp_tech_stack',
  },
  {
    label: 'Sectigo',
    value: '_sectigo_tech_stack',
  },
  {
    label: 'Section.io',
    value: '_section.io_tech_stack',
  },
  {
    label: 'Sections.design Shopify App Optimization',
    value: '_sections.design_shopify app optimization_tech_stack',
  },
  {
    label: 'SeedProd Coming Soon',
    value: '_seedprod_coming soon_tech_stack',
  },
  {
    label: 'Seers',
    value: '_seers_tech_stack',
  },
  {
    label: 'Segmanta',
    value: '_segmanta_tech_stack',
  },
  {
    label: 'Segment',
    value: '_segment_tech_stack',
  },
  {
    label: 'Segment Consent Manager ',
    value: '_segment_consent manager _tech_stack',
  },
  {
    label: 'SegmentStream',
    value: '_segmentstream_tech_stack',
  },
  {
    label: 'Select2',
    value: '_select2_tech_stack',
  },
  {
    label: 'Sellacious',
    value: '_sellacious_tech_stack',
  },
  {
    label: 'Sellfy',
    value: '_sellfy_tech_stack',
  },
  {
    label: 'Sellingo',
    value: '_sellingo_tech_stack',
  },
  {
    label: 'Sellix',
    value: '_sellix_tech_stack',
  },
  {
    label: 'Sellsy',
    value: '_sellsy_tech_stack',
  },
  {
    label: 'Selly',
    value: '_selly_tech_stack',
  },
  {
    label: 'Semantic UI',
    value: '_semantic_ui_tech_stack',
  },
  {
    label: 'Sematext Experience',
    value: '_sematext_experience_tech_stack',
  },
  {
    label: 'Semplice',
    value: '_semplice_tech_stack',
  },
  {
    label: 'Sencha Touch',
    value: '_sencha_touch_tech_stack',
  },
  {
    label: 'Sendgrid',
    value: '_sendgrid_tech_stack',
  },
  {
    label: 'Sendinblue',
    value: '_sendinblue_tech_stack',
  },
  {
    label: 'Sensors Data',
    value: '_sensors_data_tech_stack',
  },
  {
    label: 'Sentry',
    value: '_sentry_tech_stack',
  },
  {
    label: 'Seravo',
    value: '_seravo_tech_stack',
  },
  {
    label: 'Serendipity',
    value: '_serendipity_tech_stack',
  },
  {
    label: 'Service Management Group',
    value: '_service_management group_tech_stack',
  },
  {
    label: 'Service Provider Pro',
    value: '_service_provider pro_tech_stack',
  },
  {
    label: 'ServiceNow',
    value: '_servicenow_tech_stack',
  },
  {
    label: 'Setmore',
    value: '_setmore_tech_stack',
  },
  {
    label: 'SevenRooms',
    value: '_sevenrooms_tech_stack',
  },
  {
    label: 'Sezzle',
    value: '_sezzle_tech_stack',
  },
  {
    label: 'Shaka Player',
    value: '_shaka_player_tech_stack',
  },
  {
    label: 'Shanon',
    value: '_shanon_tech_stack',
  },
  {
    label: 'Shapecss',
    value: '_shapecss_tech_stack',
  },
  {
    label: 'ShareThis',
    value: '_sharethis_tech_stack',
  },
  {
    label: 'Sharethrough',
    value: '_sharethrough_tech_stack',
  },
  {
    label: 'Sharetribe',
    value: '_sharetribe_tech_stack',
  },
  {
    label: 'SharpSpring',
    value: '_sharpspring_tech_stack',
  },
  {
    label: 'SharpSpring Ads',
    value: '_sharpspring_ads_tech_stack',
  },
  {
    label: 'SheerID',
    value: '_sheerid_tech_stack',
  },
  {
    label: 'Shelf',
    value: '_shelf_tech_stack',
  },
  {
    label: 'ShellInABox',
    value: '_shellinabox_tech_stack',
  },
  {
    label: 'Shiny',
    value: '_shiny_tech_stack',
  },
  {
    label: 'ShinyStat',
    value: '_shinystat_tech_stack',
  },
  {
    label: 'ShipTection',
    value: '_shiptection_tech_stack',
  },
  {
    label: 'ShippyPro',
    value: '_shippypro_tech_stack',
  },
  {
    label: 'Shogun',
    value: '_shogun_tech_stack',
  },
  {
    label: 'Shop Pay',
    value: '_shop_pay_tech_stack',
  },
  {
    label: 'Shop Pay Installments',
    value: '_shop_pay installments_tech_stack',
  },
  {
    label: 'ShopGold',
    value: '_shopgold_tech_stack',
  },
  {
    label: 'ShopPad Infinite Options',
    value: '_shoppad_infinite options_tech_stack',
  },
  {
    label: 'ShopWired',
    value: '_shopwired_tech_stack',
  },
  {
    label: 'Shopaholic',
    value: '_shopaholic_tech_stack',
  },
  {
    label: 'Shopapps',
    value: '_shopapps_tech_stack',
  },
  {
    label: 'Shopatron',
    value: '_shopatron_tech_stack',
  },
  {
    label: 'Shopcada',
    value: '_shopcada_tech_stack',
  },
  {
    label: 'Shoper',
    value: '_shoper_tech_stack',
  },
  {
    label: 'Shopery',
    value: '_shopery_tech_stack',
  },
  {
    label: 'Shopfa',
    value: '_shopfa_tech_stack',
  },
  {
    label: 'Shopify',
    value: '_shopify_tech_stack',
  },
  {
    label: 'Shopify Buy Button',
    value: '_shopify_buy button_tech_stack',
  },
  {
    label: 'Shopify Chat',
    value: '_shopify_chat_tech_stack',
  },
  {
    label: 'Shopify Consent Management',
    value: '_shopify_consent management_tech_stack',
  },
  {
    label: 'Shopify Geolocation App',
    value: '_shopify_geolocation app_tech_stack',
  },
  {
    label: 'Shopify Product Reviews',
    value: '_shopify_product reviews_tech_stack',
  },
  {
    label: 'Shopistry',
    value: '_shopistry_tech_stack',
  },
  {
    label: 'Shopline',
    value: '_shopline_tech_stack',
  },
  {
    label: 'Shoplo',
    value: '_shoplo_tech_stack',
  },
  {
    label: 'Shoporama',
    value: '_shoporama_tech_stack',
  },
  {
    label: 'Shoppiko',
    value: '_shoppiko_tech_stack',
  },
  {
    label: 'ShoppingFeeder',
    value: '_shoppingfeeder_tech_stack',
  },
  {
    label: 'ShoppingGives',
    value: '_shoppinggives_tech_stack',
  },
  {
    label: 'Shoppy',
    value: '_shoppy_tech_stack',
  },
  {
    label: 'Shoprenter',
    value: '_shoprenter_tech_stack',
  },
  {
    label: 'Shoprunner',
    value: '_shoprunner_tech_stack',
  },
  {
    label: 'Shoptet',
    value: '_shoptet_tech_stack',
  },
  {
    label: 'Shopware',
    value: '_shopware_tech_stack',
  },
  {
    label: 'ShortPixel Image Optimizer',
    value: '_shortpixel_image optimizer_tech_stack',
  },
  {
    label: 'Shortcodes Ultimate',
    value: '_shortcodes_ultimate_tech_stack',
  },
  {
    label: 'Shortly',
    value: '_shortly_tech_stack',
  },
  {
    label: 'ShoutOut',
    value: '_shoutout_tech_stack',
  },
  {
    label: 'Shuttle',
    value: '_shuttle_tech_stack',
  },
  {
    label: 'Sift',
    value: '_sift_tech_stack',
  },
  {
    label: 'Signal',
    value: '_signal_tech_stack',
  },
  {
    label: 'Signifyd',
    value: '_signifyd_tech_stack',
  },
  {
    label: 'SilverStripe',
    value: '_silverstripe_tech_stack',
  },
  {
    label: 'Simbel',
    value: '_simbel_tech_stack',
  },
  {
    label: 'Simon',
    value: '_simon_tech_stack',
  },
  {
    label: 'Simpl',
    value: '_simpl_tech_stack',
  },
  {
    label: 'Simple Analytics',
    value: '_simple_analytics_tech_stack',
  },
  {
    label: 'SimpleHTTP',
    value: '_simplehttp_tech_stack',
  },
  {
    label: 'Simplero',
    value: '_simplero_tech_stack',
  },
  {
    label: 'Simplero Websites',
    value: '_simplero_websites_tech_stack',
  },
  {
    label: 'Simpli.fi',
    value: '_simpli.fi_tech_stack',
  },
  {
    label: 'Simplio Upsells',
    value: '_simplio_upsells_tech_stack',
  },
  {
    label: 'Simplo7',
    value: '_simplo7_tech_stack',
  },
  {
    label: 'Simplébo',
    value: '_simplébo_tech_stack',
  },
  {
    label: 'Simvoly',
    value: '_simvoly_tech_stack',
  },
  {
    label: 'Sinatra',
    value: '_sinatra_tech_stack',
  },
  {
    label: 'Sirclo',
    value: '_sirclo_tech_stack',
  },
  {
    label: 'Sirdata',
    value: '_sirdata_tech_stack',
  },
  {
    label: 'Site Kit',
    value: '_site_kit_tech_stack',
  },
  {
    label: 'Site Meter',
    value: '_site_meter_tech_stack',
  },
  {
    label: 'Site Search 360',
    value: '_site_search 360_tech_stack',
  },
  {
    label: 'Site24x7',
    value: '_site24x7_tech_stack',
  },
  {
    label: 'SiteEdit',
    value: '_siteedit_tech_stack',
  },
  {
    label: 'SiteGround',
    value: '_siteground_tech_stack',
  },
  {
    label: 'SiteGuard WP Plugin',
    value: '_siteguard_wp plugin_tech_stack',
  },
  {
    label: 'SiteJabber',
    value: '_sitejabber_tech_stack',
  },
  {
    label: 'SiteManager',
    value: '_sitemanager_tech_stack',
  },
  {
    label: 'SiteMinder',
    value: '_siteminder_tech_stack',
  },
  {
    label: 'SiteOrigin Page Builder',
    value: '_siteorigin_page builder_tech_stack',
  },
  {
    label: 'SiteOrigin Vantage',
    value: '_siteorigin_vantage_tech_stack',
  },
  {
    label: 'SiteOrigin Widgets Bundle',
    value: '_siteorigin_widgets bundle_tech_stack',
  },
  {
    label: 'SitePad',
    value: '_sitepad_tech_stack',
  },
  {
    label: 'SiteSpect',
    value: '_sitespect_tech_stack',
  },
  {
    label: 'SiteVibes',
    value: '_sitevibes_tech_stack',
  },
  {
    label: 'SiteW',
    value: '_sitew_tech_stack',
  },
  {
    label: 'Sitecore',
    value: '_sitecore_tech_stack',
  },
  {
    label: 'Sitefinity',
    value: '_sitefinity_tech_stack',
  },
  {
    label: 'Siteglide',
    value: '_siteglide_tech_stack',
  },
  {
    label: 'Sitevision CMS',
    value: '_sitevision_cms_tech_stack',
  },
  {
    label: 'Sivuviidakko',
    value: '_sivuviidakko_tech_stack',
  },
  {
    label: 'Sizmek',
    value: '_sizmek_tech_stack',
  },
  {
    label: 'Skai',
    value: '_skai_tech_stack',
  },
  {
    label: 'Skedify',
    value: '_skedify_tech_stack',
  },
  {
    label: 'Skimlinks',
    value: '_skimlinks_tech_stack',
  },
  {
    label: 'Skio',
    value: '_skio_tech_stack',
  },
  {
    label: 'Sky-Shop',
    value: '_sky-shop_tech_stack',
  },
  {
    label: 'SkyVerge',
    value: '_skyverge_tech_stack',
  },
  {
    label: 'Slice',
    value: '_slice_tech_stack',
  },
  {
    label: 'Slick',
    value: '_slick_tech_stack',
  },
  {
    label: 'SlickStack',
    value: '_slickstack_tech_stack',
  },
  {
    label: 'Slider Revolution',
    value: '_slider_revolution_tech_stack',
  },
  {
    label: 'Slimbox',
    value: '_slimbox_tech_stack',
  },
  {
    label: 'Slimbox 2',
    value: '_slimbox_2_tech_stack',
  },
  {
    label: 'Smart Ad Server',
    value: '_smart_ad server_tech_stack',
  },
  {
    label: 'Smart Slider 3',
    value: '_smart_slider 3_tech_stack',
  },
  {
    label: 'SmartRecruiters',
    value: '_smartrecruiters_tech_stack',
  },
  {
    label: 'SmartSite',
    value: '_smartsite_tech_stack',
  },
  {
    label: 'SmartWeb',
    value: '_smartweb_tech_stack',
  },
  {
    label: 'Smarter Click',
    value: '_smarter_click_tech_stack',
  },
  {
    label: 'Smartling',
    value: '_smartling_tech_stack',
  },
  {
    label: 'Smartlook',
    value: '_smartlook_tech_stack',
  },
  {
    label: 'Smartstore',
    value: '_smartstore_tech_stack',
  },
  {
    label: 'Smartstore Page Builder',
    value: '_smartstore_page builder_tech_stack',
  },
  {
    label: 'Smartstore biz',
    value: '_smartstore_biz_tech_stack',
  },
  {
    label: 'Smartsupp',
    value: '_smartsupp_tech_stack',
  },
  {
    label: 'Smash Balloon Instagram Feed',
    value: '_smash_balloon instagram feed_tech_stack',
  },
  {
    label: 'Smile',
    value: '_smile_tech_stack',
  },
  {
    label: 'SmugMug',
    value: '_smugmug_tech_stack',
  },
  {
    label: 'Snap',
    value: '_snap_tech_stack',
  },
  {
    label: 'Snap Pixel',
    value: '_snap_pixel_tech_stack',
  },
  {
    label: 'Snap.svg',
    value: '_snap.svg_tech_stack',
  },
  {
    label: 'SnapEngage',
    value: '_snapengage_tech_stack',
  },
  {
    label: 'SnapWidget',
    value: '_snapwidget_tech_stack',
  },
  {
    label: 'Snipcart',
    value: '_snipcart_tech_stack',
  },
  {
    label: 'SniperFast',
    value: '_sniperfast_tech_stack',
  },
  {
    label: 'Sniply',
    value: '_sniply_tech_stack',
  },
  {
    label: 'Snoobi',
    value: '_snoobi_tech_stack',
  },
  {
    label: 'Snowplow Analytics',
    value: '_snowplow_analytics_tech_stack',
  },
  {
    label: 'SobiPro',
    value: '_sobipro_tech_stack',
  },
  {
    label: 'Social9',
    value: '_social9_tech_stack',
  },
  {
    label: 'SocialLadder',
    value: '_socialladder_tech_stack',
  },
  {
    label: 'Socket.io',
    value: '_socket.io_tech_stack',
  },
  {
    label: 'SoftTr',
    value: '_softtr_tech_stack',
  },
  {
    label: 'Soisy',
    value: '_soisy_tech_stack',
  },
  {
    label: 'SolidPixels',
    value: '_solidpixels_tech_stack',
  },
  {
    label: 'Solodev',
    value: '_solodev_tech_stack',
  },
  {
    label: 'Solr',
    value: '_solr_tech_stack',
  },
  {
    label: 'Solusquare OmniCommerce Cloud',
    value: '_solusquare_omnicommerce cloud_tech_stack',
  },
  {
    label: 'Solve Media',
    value: '_solve_media_tech_stack',
  },
  {
    label: 'Solvemate',
    value: '_solvemate_tech_stack',
  },
  {
    label: 'Solvvy',
    value: '_solvvy_tech_stack',
  },
  {
    label: 'SonarQubes',
    value: '_sonarqubes_tech_stack',
  },
  {
    label: 'Sonobi',
    value: '_sonobi_tech_stack',
  },
  {
    label: 'Sortable',
    value: '_sortable_tech_stack',
  },
  {
    label: 'Sorted Return',
    value: '_sorted_return_tech_stack',
  },
  {
    label: 'SoteShop',
    value: '_soteshop_tech_stack',
  },
  {
    label: 'Sotel',
    value: '_sotel_tech_stack',
  },
  {
    label: 'Sotoon',
    value: '_sotoon_tech_stack',
  },
  {
    label: 'SoundCloud',
    value: '_soundcloud_tech_stack',
  },
  {
    label: 'SoundManager',
    value: '_soundmanager_tech_stack',
  },
  {
    label: 'Sovrn',
    value: '_sovrn_tech_stack',
  },
  {
    label: 'Sovrn//Commerce',
    value: '_sovrn//commerce_tech_stack',
  },
  {
    label: 'SparkPost',
    value: '_sparkpost_tech_stack',
  },
  {
    label: 'Speed Kit',
    value: '_speed_kit_tech_stack',
  },
  {
    label: 'SpeedCurve',
    value: '_speedcurve_tech_stack',
  },
  {
    label: 'SpeedSize',
    value: '_speedsize_tech_stack',
  },
  {
    label: 'Speedimize',
    value: '_speedimize_tech_stack',
  },
  {
    label: 'Sphinx',
    value: '_sphinx_tech_stack',
  },
  {
    label: 'SpiceThemes SpicePress',
    value: '_spicethemes_spicepress_tech_stack',
  },
  {
    label: 'Spin-a-Sale',
    value: '_spin-a-sale_tech_stack',
  },
  {
    label: 'Spinnakr',
    value: '_spinnakr_tech_stack',
  },
  {
    label: 'Split',
    value: '_split_tech_stack',
  },
  {
    label: 'SplitIt',
    value: '_splitit_tech_stack',
  },
  {
    label: 'Splitbee',
    value: '_splitbee_tech_stack',
  },
  {
    label: 'SplittyPay',
    value: '_splittypay_tech_stack',
  },
  {
    label: 'Splunk RUM',
    value: '_splunk_rum_tech_stack',
  },
  {
    label: 'Splunkd',
    value: '_splunkd_tech_stack',
  },
  {
    label: 'SpotHopper',
    value: '_spothopper_tech_stack',
  },
  {
    label: 'SpotX',
    value: '_spotx_tech_stack',
  },
  {
    label: 'Spotify Web API',
    value: '_spotify_web api_tech_stack',
  },
  {
    label: 'Spotify Widgets',
    value: '_spotify_widgets_tech_stack',
  },
  {
    label: 'Spotii',
    value: '_spotii_tech_stack',
  },
  {
    label: 'Spree',
    value: '_spree_tech_stack',
  },
  {
    label: 'Sprig',
    value: '_sprig_tech_stack',
  },
  {
    label: 'Spring',
    value: '_spring_tech_stack',
  },
  {
    label: 'Spring for creators',
    value: '_spring_for creators_tech_stack',
  },
  {
    label: 'Spryker',
    value: '_spryker_tech_stack',
  },
  {
    label: 'SpurIT',
    value: '_spurit_tech_stack',
  },
  {
    label: 'SpurIT Abandoned Cart Reminder',
    value: '_spurit_abandoned cart reminder_tech_stack',
  },
  {
    label: 'SpurIT Loyalty App',
    value: '_spurit_loyalty app_tech_stack',
  },
  {
    label: 'SpurIT Partial Payments App',
    value: '_spurit_partial payments app_tech_stack',
  },
  {
    label: 'SpurIT Recurring Payments App',
    value: '_spurit_recurring payments app_tech_stack',
  },
  {
    label: 'Sqreen',
    value: '_sqreen_tech_stack',
  },
  {
    label: 'Squadded',
    value: '_squadded_tech_stack',
  },
  {
    label: 'Square',
    value: '_square_tech_stack',
  },
  {
    label: 'Square Online',
    value: '_square_online_tech_stack',
  },
  {
    label: 'Squarespace',
    value: '_squarespace_tech_stack',
  },
  {
    label: 'Squarespace Commerce',
    value: '_squarespace_commerce_tech_stack',
  },
  {
    label: 'SquirrelMail',
    value: '_squirrelmail_tech_stack',
  },
  {
    label: 'Squiz Matrix',
    value: '_squiz_matrix_tech_stack',
  },
  {
    label: 'Stack Analytix',
    value: '_stack_analytix_tech_stack',
  },
  {
    label: 'StackCommerce',
    value: '_stackcommerce_tech_stack',
  },
  {
    label: 'StackPath',
    value: '_stackpath_tech_stack',
  },
  {
    label: 'StackerHQ',
    value: '_stackerhq_tech_stack',
  },
  {
    label: 'Stackify',
    value: '_stackify_tech_stack',
  },
  {
    label: 'Stage Try',
    value: '_stage_try_tech_stack',
  },
  {
    label: 'Stamped',
    value: '_stamped_tech_stack',
  },
  {
    label: 'Starhost',
    value: '_starhost_tech_stack',
  },
  {
    label: 'Starlet',
    value: '_starlet_tech_stack',
  },
  {
    label: 'Statamic',
    value: '_statamic_tech_stack',
  },
  {
    label: 'Statcounter',
    value: '_statcounter_tech_stack',
  },
  {
    label: 'Statically',
    value: '_statically_tech_stack',
  },
  {
    label: 'SteelHouse',
    value: '_steelhouse_tech_stack',
  },
  {
    label: 'Stencil',
    value: '_stencil_tech_stack',
  },
  {
    label: 'Stimulus',
    value: '_stimulus_tech_stack',
  },
  {
    label: 'StimulusReflex',
    value: '_stimulusreflex_tech_stack',
  },
  {
    label: 'Stitches',
    value: '_stitches_tech_stack',
  },
  {
    label: 'StoreHippo',
    value: '_storehippo_tech_stack',
  },
  {
    label: 'Storeden',
    value: '_storeden_tech_stack',
  },
  {
    label: 'Storefront UI',
    value: '_storefront_ui_tech_stack',
  },
  {
    label: 'StorifyMe',
    value: '_storifyme_tech_stack',
  },
  {
    label: 'StoryStream',
    value: '_storystream_tech_stack',
  },
  {
    label: 'Storyblok',
    value: '_storyblok_tech_stack',
  },
  {
    label: 'Strapdown.js',
    value: '_strapdown.js_tech_stack',
  },
  {
    label: 'Strapi',
    value: '_strapi_tech_stack',
  },
  {
    label: 'Strato',
    value: '_strato_tech_stack',
  },
  {
    label: 'Strato Website',
    value: '_strato_website_tech_stack',
  },
  {
    label: 'Strattic',
    value: '_strattic_tech_stack',
  },
  {
    label: 'Strikingly',
    value: '_strikingly_tech_stack',
  },
  {
    label: 'Stripe',
    value: '_stripe_tech_stack',
  },
  {
    label: 'StrutFit',
    value: '_strutfit_tech_stack',
  },
  {
    label: 'Stylitics',
    value: '_stylitics_tech_stack',
  },
  {
    label: 'Sub2Tech',
    value: '_sub2tech_tech_stack',
  },
  {
    label: 'Subbly',
    value: '_subbly_tech_stack',
  },
  {
    label: 'Sublime',
    value: '_sublime_tech_stack',
  },
  {
    label: 'SublimeVideo',
    value: '_sublimevideo_tech_stack',
  },
  {
    label: 'Subrion',
    value: '_subrion_tech_stack',
  },
  {
    label: 'Substack',
    value: '_substack_tech_stack',
  },
  {
    label: 'Sucuri',
    value: '_sucuri_tech_stack',
  },
  {
    label: 'Suiteshare',
    value: '_suiteshare_tech_stack',
  },
  {
    label: 'Sulu',
    value: '_sulu_tech_stack',
  },
  {
    label: 'SummerCart',
    value: '_summercart_tech_stack',
  },
  {
    label: 'Sumo',
    value: '_sumo_tech_stack',
  },
  {
    label: 'SunOS',
    value: '_sunos_tech_stack',
  },
  {
    label: 'Super Builder',
    value: '_super_builder_tech_stack',
  },
  {
    label: 'Super Socializer',
    value: '_super_socializer_tech_stack',
  },
  {
    label: 'SuperLemon app',
    value: '_superlemon_app_tech_stack',
  },
  {
    label: 'Supersized',
    value: '_supersized_tech_stack',
  },
  {
    label: 'Superspeed',
    value: '_superspeed_tech_stack',
  },
  {
    label: 'Support Hero',
    value: '_support_hero_tech_stack',
  },
  {
    label: 'Survicate',
    value: '_survicate_tech_stack',
  },
  {
    label: 'Svbtle',
    value: '_svbtle_tech_stack',
  },
  {
    label: 'Svelte',
    value: '_svelte_tech_stack',
  },
  {
    label: 'SvelteKit',
    value: '_sveltekit_tech_stack',
  },
  {
    label: 'Swagger UI',
    value: '_swagger_ui_tech_stack',
  },
  {
    label: 'Swagify',
    value: '_swagify_tech_stack',
  },
  {
    label: 'SweetAlert',
    value: '_sweetalert_tech_stack',
  },
  {
    label: 'SweetAlert2',
    value: '_sweetalert2_tech_stack',
  },
  {
    label: 'Swell',
    value: '_swell_tech_stack',
  },
  {
    label: 'Swiftype',
    value: '_swiftype_tech_stack',
  },
  {
    label: 'Swiper',
    value: '_swiper_tech_stack',
  },
  {
    label: 'Swym Wishlist Plus',
    value: '_swym_wishlist plus_tech_stack',
  },
  {
    label: 'Sylius',
    value: '_sylius_tech_stack',
  },
  {
    label: 'Symfony',
    value: '_symfony_tech_stack',
  },
  {
    label: 'Sympa',
    value: '_sympa_tech_stack',
  },
  {
    label: 'Syndeca',
    value: '_syndeca_tech_stack',
  },
  {
    label: 'Synerise',
    value: '_synerise_tech_stack',
  },
  {
    label: 'Synology DiskStation',
    value: '_synology_diskstation_tech_stack',
  },
  {
    label: 'SyntaxHighlighter',
    value: '_syntaxhighlighter_tech_stack',
  },
  {
    label: 'Syte',
    value: '_syte_tech_stack',
  },
  {
    label: 'sIFR',
    value: '_sifr_tech_stack',
  },
  {
    label: 'sNews',
    value: '_snews_tech_stack',
  },
  {
    label: 'script.aculo.us',
    value: '_script.aculo.us_tech_stack',
  },
  {
    label: 'scrollreveal',
    value: '_scrollreveal_tech_stack',
  },
  {
    label: 'shine.js',
    value: '_shine.js_tech_stack',
  },
  {
    label: 'siimple',
    value: '_siimple_tech_stack',
  },
  {
    label: 'snigel AdConsent',
    value: '_snigel_adconsent_tech_stack',
  },
  {
    label: 'snigel AdEngine',
    value: '_snigel_adengine_tech_stack',
  },
  {
    label: 'stores.jp',
    value: '_stores.jp_tech_stack',
  },
  {
    label: 'styled-components',
    value: '_styled-components_tech_stack',
  },
  {
    label: 'T-Soft',
    value: '_t-soft_tech_stack',
  },
  {
    label: 'T1 Comercios',
    value: '_t1_comercios_tech_stack',
  },
  {
    label: 'T1 Envios',
    value: '_t1_envios_tech_stack',
  },
  {
    label: 'T1 Paginas',
    value: '_t1_paginas_tech_stack',
  },
  {
    label: 'T1 Pagos',
    value: '_t1_pagos_tech_stack',
  },
  {
    label: 'TDesign',
    value: '_tdesign_tech_stack',
  },
  {
    label: 'THG Ingenuity',
    value: '_thg_ingenuity_tech_stack',
  },
  {
    label: 'TN Express Web',
    value: '_tn_express web_tech_stack',
  },
  {
    label: 'TNS Payments',
    value: '_tns_payments_tech_stack',
  },
  {
    label: 'TRISOshop',
    value: '_trisoshop_tech_stack',
  },
  {
    label: 'TRUENDO',
    value: '_truendo_tech_stack',
  },
  {
    label: 'TVSquared',
    value: '_tvsquared_tech_stack',
  },
  {
    label: 'TWiki',
    value: '_twiki_tech_stack',
  },
  {
    label: 'TYPO3 CMS',
    value: '_typo3_cms_tech_stack',
  },
  {
    label: 'Tabarnapp',
    value: '_tabarnapp_tech_stack',
  },
  {
    label: 'Tabby',
    value: '_tabby_tech_stack',
  },
  {
    label: 'TableBooker',
    value: '_tablebooker_tech_stack',
  },
  {
    label: 'TableCheck',
    value: '_tablecheck_tech_stack',
  },
  {
    label: 'TablePress',
    value: '_tablepress_tech_stack',
  },
  {
    label: 'Taboola',
    value: '_taboola_tech_stack',
  },
  {
    label: 'Tachyons',
    value: '_tachyons_tech_stack',
  },
  {
    label: 'Tada',
    value: '_tada_tech_stack',
  },
  {
    label: 'TagPro',
    value: '_tagpro_tech_stack',
  },
  {
    label: 'Tagboard',
    value: '_tagboard_tech_stack',
  },
  {
    label: 'Taggbox',
    value: '_taggbox_tech_stack',
  },
  {
    label: 'Taiga',
    value: '_taiga_tech_stack',
  },
  {
    label: 'Tail',
    value: '_tail_tech_stack',
  },
  {
    label: 'Tailwind CSS',
    value: '_tailwind_css_tech_stack',
  },
  {
    label: 'TakeDrop',
    value: '_takedrop_tech_stack',
  },
  {
    label: 'Talkable',
    value: '_talkable_tech_stack',
  },
  {
    label: 'Tallentor',
    value: '_tallentor_tech_stack',
  },
  {
    label: 'Tallentor Widget',
    value: '_tallentor_widget_tech_stack',
  },
  {
    label: 'Tamago',
    value: '_tamago_tech_stack',
  },
  {
    label: 'Tamara',
    value: '_tamara_tech_stack',
  },
  {
    label: 'Tangled Network',
    value: '_tangled_network_tech_stack',
  },
  {
    label: 'Tapad',
    value: '_tapad_tech_stack',
  },
  {
    label: 'Tapcart',
    value: '_tapcart_tech_stack',
  },
  {
    label: 'Target2Sell',
    value: '_target2sell_tech_stack',
  },
  {
    label: 'Tatari',
    value: '_tatari_tech_stack',
  },
  {
    label: 'Tawk.to',
    value: '_tawk.to_tech_stack',
  },
  {
    label: 'Teachable',
    value: '_teachable_tech_stack',
  },
  {
    label: 'Teads',
    value: '_teads_tech_stack',
  },
  {
    label: 'Tealium',
    value: '_tealium_tech_stack',
  },
  {
    label: 'Tealium AudienceStream',
    value: '_tealium_audiencestream_tech_stack',
  },
  {
    label: 'Tealium Consent Management',
    value: '_tealium_consent management_tech_stack',
  },
  {
    label: 'TeamCity',
    value: '_teamcity_tech_stack',
  },
  {
    label: 'Tebex',
    value: '_tebex_tech_stack',
  },
  {
    label: 'Telescope',
    value: '_telescope_tech_stack',
  },
  {
    label: 'Tencent Analytics (腾讯分析)',
    value: '_tencent_analytics (腾讯分析)_tech_stack',
  },
  {
    label: 'Tencent Cloud',
    value: '_tencent_cloud_tech_stack',
  },
  {
    label: 'Tencent QQ',
    value: '_tencent_qq_tech_stack',
  },
  {
    label: 'Tencent Waterproof Wall',
    value: '_tencent_waterproof wall_tech_stack',
  },
  {
    label: 'Tengine',
    value: '_tengine_tech_stack',
  },
  {
    label: 'Termly',
    value: '_termly_tech_stack',
  },
  {
    label: 'TerriaJS',
    value: '_terriajs_tech_stack',
  },
  {
    label: 'Tessitura',
    value: '_tessitura_tech_stack',
  },
  {
    label: 'TestFreaks',
    value: '_testfreaks_tech_stack',
  },
  {
    label: 'Texthelp',
    value: '_texthelp_tech_stack',
  },
  {
    label: 'Textpattern CMS',
    value: '_textpattern_cms_tech_stack',
  },
  {
    label: 'The Arena Group',
    value: '_the_arena group_tech_stack',
  },
  {
    label: 'The Events Calendar',
    value: '_the_events calendar_tech_stack',
  },
  {
    label: 'The Hotels Network',
    value: '_the_hotels network_tech_stack',
  },
  {
    label: 'The SEO Framework',
    value: '_the_seo framework_tech_stack',
  },
  {
    label: 'The Theme Foundry Make',
    value: '_the_theme foundry make_tech_stack',
  },
  {
    label: 'The.com',
    value: '_the.com_tech_stack',
  },
  {
    label: 'Thefork',
    value: '_thefork_tech_stack',
  },
  {
    label: 'Thelia',
    value: '_thelia_tech_stack',
  },
  {
    label: 'Theme Freesia Edge',
    value: '_theme_freesia edge_tech_stack',
  },
  {
    label: 'Theme Freesia Photograph',
    value: '_theme_freesia photograph_tech_stack',
  },
  {
    label: 'Theme Freesia ShoppingCart',
    value: '_theme_freesia shoppingcart_tech_stack',
  },
  {
    label: 'Theme Horse Attitude',
    value: '_theme_horse attitude_tech_stack',
  },
  {
    label: 'Theme Horse NewsCard',
    value: '_theme_horse newscard_tech_stack',
  },
  {
    label: 'Theme Vision Agama',
    value: '_theme_vision agama_tech_stack',
  },
  {
    label: 'Theme4Press Evolve',
    value: '_theme4press_evolve_tech_stack',
  },
  {
    label: 'ThemeGrill Accelerate',
    value: '_themegrill_accelerate_tech_stack',
  },
  {
    label: 'ThemeGrill Cenote',
    value: '_themegrill_cenote_tech_stack',
  },
  {
    label: 'ThemeGrill ColorMag',
    value: '_themegrill_colormag_tech_stack',
  },
  {
    label: 'ThemeGrill Flash',
    value: '_themegrill_flash_tech_stack',
  },
  {
    label: 'ThemeGrill Radiate',
    value: '_themegrill_radiate_tech_stack',
  },
  {
    label: 'ThemeGrill Spacious',
    value: '_themegrill_spacious_tech_stack',
  },
  {
    label: 'ThemeGrill eStore',
    value: '_themegrill_estore_tech_stack',
  },
  {
    label: 'ThemeIsle Menu Icons',
    value: '_themeisle_menu icons_tech_stack',
  },
  {
    label: 'ThemeZee Donovan',
    value: '_themezee_donovan_tech_stack',
  },
  {
    label: 'ThemeZee Poseidon',
    value: '_themezee_poseidon_tech_stack',
  },
  {
    label: 'ThemeZee Wellington',
    value: '_themezee_wellington_tech_stack',
  },
  {
    label: 'Themeansar Newsberg',
    value: '_themeansar_newsberg_tech_stack',
  },
  {
    label: 'Themeansar Newsup',
    value: '_themeansar_newsup_tech_stack',
  },
  {
    label: 'Themebeez Cream Magazine',
    value: '_themebeez_cream magazine_tech_stack',
  },
  {
    label: 'Themebeez Orchid Store',
    value: '_themebeez_orchid store_tech_stack',
  },
  {
    label: 'Themegraphy Graphy',
    value: '_themegraphy_graphy_tech_stack',
  },
  {
    label: 'Themes4Wp Bulk',
    value: '_themes4wp_bulk_tech_stack',
  },
  {
    label: 'ThemezHut Bam',
    value: '_themezhut_bam_tech_stack',
  },
  {
    label: 'ThemezHut HitMag',
    value: '_themezhut_hitmag_tech_stack',
  },
  {
    label: 'Themonic Iconic One',
    value: '_themonic_iconic one_tech_stack',
  },
  {
    label: 'Thesis',
    value: '_thesis_tech_stack',
  },
  {
    label: 'Thimatic',
    value: '_thimatic_tech_stack',
  },
  {
    label: 'Think Up Themes Consulting',
    value: '_think_up themes consulting_tech_stack',
  },
  {
    label: 'Think Up Themes Minamaze',
    value: '_think_up themes minamaze_tech_stack',
  },
  {
    label: 'ThinkPHP',
    value: '_thinkphp_tech_stack',
  },
  {
    label: 'Thinkific',
    value: '_thinkific_tech_stack',
  },
  {
    label: 'ThreatMetrix',
    value: '_threatmetrix_tech_stack',
  },
  {
    label: 'Threekit',
    value: '_threekit_tech_stack',
  },
  {
    label: 'ThriveCart',
    value: '_thrivecart_tech_stack',
  },
  {
    label: 'Ticimax',
    value: '_ticimax_tech_stack',
  },
  {
    label: 'Tictail',
    value: '_tictail_tech_stack',
  },
  {
    label: 'TiddlyWiki',
    value: '_tiddlywiki_tech_stack',
  },
  {
    label: 'Tidio',
    value: '_tidio_tech_stack',
  },
  {
    label: 'Tiendanube',
    value: '_tiendanube_tech_stack',
  },
  {
    label: 'TikTok Pixel',
    value: '_tiktok_pixel_tech_stack',
  },
  {
    label: 'Tiki Wiki CMS Groupware',
    value: '_tiki_wiki cms groupware_tech_stack',
  },
  {
    label: 'Tilda',
    value: '_tilda_tech_stack',
  },
  {
    label: 'Tiledesk',
    value: '_tiledesk_tech_stack',
  },
  {
    label: 'Timeplot',
    value: '_timeplot_tech_stack',
  },
  {
    label: 'Timify',
    value: '_timify_tech_stack',
  },
  {
    label: 'TinyMCE',
    value: '_tinymce_tech_stack',
  },
  {
    label: 'Tippy.js',
    value: '_tippy.js_tech_stack',
  },
  {
    label: 'Tipsa',
    value: '_tipsa_tech_stack',
  },
  {
    label: 'Tiqets',
    value: '_tiqets_tech_stack',
  },
  {
    label: 'Titan',
    value: '_titan_tech_stack',
  },
  {
    label: 'TomTom Maps',
    value: '_tomtom_maps_tech_stack',
  },
  {
    label: 'TomatoCart',
    value: '_tomatocart_tech_stack',
  },
  {
    label: 'TornadoServer',
    value: '_tornadoserver_tech_stack',
  },
  {
    label: 'TotalCode',
    value: '_totalcode_tech_stack',
  },
  {
    label: 'Totango',
    value: '_totango_tech_stack',
  },
  {
    label: 'Totara',
    value: '_totara_tech_stack',
  },
  {
    label: 'Touch2Success',
    value: '_touch2success_tech_stack',
  },
  {
    label: 'TownNews',
    value: '_townnews_tech_stack',
  },
  {
    label: 'Trac',
    value: '_trac_tech_stack',
  },
  {
    label: 'TrackJs',
    value: '_trackjs_tech_stack',
  },
  {
    label: 'Trackify X',
    value: '_trackify_x_tech_stack',
  },
  {
    label: 'Tradedoubler',
    value: '_tradedoubler_tech_stack',
  },
  {
    label: 'TrafficStars',
    value: '_trafficstars_tech_stack',
  },
  {
    label: 'Transcend',
    value: '_transcend_tech_stack',
  },
  {
    label: 'Transcy',
    value: '_transcy_tech_stack',
  },
  {
    label: 'Transifex',
    value: '_transifex_tech_stack',
  },
  {
    label: 'Translate WordPress',
    value: '_translate_wordpress_tech_stack',
  },
  {
    label: 'Translucide',
    value: '_translucide_tech_stack',
  },
  {
    label: 'Transmart',
    value: '_transmart_tech_stack',
  },
  {
    label: 'Tray',
    value: '_tray_tech_stack',
  },
  {
    label: 'Trbo',
    value: '_trbo_tech_stack',
  },
  {
    label: 'Treasure Data',
    value: '_treasure_data_tech_stack',
  },
  {
    label: 'Trengo',
    value: '_trengo_tech_stack',
  },
  {
    label: 'Tripadviser.Widget',
    value: '_tripadviser.widget_tech_stack',
  },
  {
    label: 'Triple Whale',
    value: '_triple_whale_tech_stack',
  },
  {
    label: 'TripleLift',
    value: '_triplelift_tech_stack',
  },
  {
    label: 'Tritac Katana Commerce',
    value: '_tritac_katana commerce_tech_stack',
  },
  {
    label: 'Trix',
    value: '_trix_tech_stack',
  },
  {
    label: 'Trove Recommerce',
    value: '_trove_recommerce_tech_stack',
  },
  {
    label: 'TruValidate',
    value: '_truvalidate_tech_stack',
  },
  {
    label: 'True Fit',
    value: '_true_fit_tech_stack',
  },
  {
    label: 'TrueCommerce',
    value: '_truecommerce_tech_stack',
  },
  {
    label: 'Trumba',
    value: '_trumba_tech_stack',
  },
  {
    label: 'Trunkrs',
    value: '_trunkrs_tech_stack',
  },
  {
    label: 'TrustArc',
    value: '_trustarc_tech_stack',
  },
  {
    label: 'TrustYou',
    value: '_trustyou_tech_stack',
  },
  {
    label: 'Trusted Shops',
    value: '_trusted_shops_tech_stack',
  },
  {
    label: 'Trustindex Google Reviews',
    value: '_trustindex_google reviews_tech_stack',
  },
  {
    label: 'Trustpilot',
    value: '_trustpilot_tech_stack',
  },
  {
    label: 'Trustspot',
    value: '_trustspot_tech_stack',
  },
  {
    label: 'Trustvox',
    value: '_trustvox_tech_stack',
  },
  {
    label: 'Tumblr',
    value: '_tumblr_tech_stack',
  },
  {
    label: 'Turbo',
    value: '_turbo_tech_stack',
  },
  {
    label: 'Turbolinks',
    value: '_turbolinks_tech_stack',
  },
  {
    label: 'TurfJS',
    value: '_turfjs_tech_stack',
  },
  {
    label: 'Twenty Eleven',
    value: '_twenty_eleven_tech_stack',
  },
  {
    label: 'Twenty Fifteen',
    value: '_twenty_fifteen_tech_stack',
  },
  {
    label: 'Twenty Fourteen',
    value: '_twenty_fourteen_tech_stack',
  },
  {
    label: 'Twenty Nineteen',
    value: '_twenty_nineteen_tech_stack',
  },
  {
    label: 'Twenty Seventeen',
    value: '_twenty_seventeen_tech_stack',
  },
  {
    label: 'Twenty Sixteen',
    value: '_twenty_sixteen_tech_stack',
  },
  {
    label: 'Twenty Ten',
    value: '_twenty_ten_tech_stack',
  },
  {
    label: 'Twenty Thirteen',
    value: '_twenty_thirteen_tech_stack',
  },
  {
    label: 'Twenty Twelve',
    value: '_twenty_twelve_tech_stack',
  },
  {
    label: 'Twenty Twenty',
    value: '_twenty_twenty_tech_stack',
  },
  {
    label: 'Twenty Twenty-One',
    value: '_twenty_twenty-one_tech_stack',
  },
  {
    label: 'TwicPics',
    value: '_twicpics_tech_stack',
  },
  {
    label: 'Twik',
    value: '_twik_tech_stack',
  },
  {
    label: 'Twilight CMS',
    value: '_twilight_cms_tech_stack',
  },
  {
    label: 'TwistPHP',
    value: '_twistphp_tech_stack',
  },
  {
    label: 'TwistedWeb',
    value: '_twistedweb_tech_stack',
  },
  {
    label: 'Twitch Player',
    value: '_twitch_player_tech_stack',
  },
  {
    label: 'Twitter',
    value: '_twitter_tech_stack',
  },
  {
    label: 'Twitter Ads',
    value: '_twitter_ads_tech_stack',
  },
  {
    label: 'Twitter Analytics',
    value: '_twitter_analytics_tech_stack',
  },
  {
    label: 'Twitter Emoji (Twemoji)',
    value: '_twitter_emoji (twemoji)_tech_stack',
  },
  {
    label: 'Twitter Flight',
    value: '_twitter_flight_tech_stack',
  },
  {
    label: 'Twitter typeahead.js',
    value: '_twitter_typeahead.js_tech_stack',
  },
  {
    label: 'TypeDoc',
    value: '_typedoc_tech_stack',
  },
  {
    label: 'TypePad',
    value: '_typepad_tech_stack',
  },
  {
    label: 'TypeScript',
    value: '_typescript_tech_stack',
  },
  {
    label: 'Typecho',
    value: '_typecho_tech_stack',
  },
  {
    label: 'Typeform',
    value: '_typeform_tech_stack',
  },
  {
    label: 'Typekit',
    value: '_typekit_tech_stack',
  },
  {
    label: 'Typof',
    value: '_typof_tech_stack',
  },
  {
    label: 'Tyslo EasySell',
    value: '_tyslo_easysell_tech_stack',
  },
  {
    label: 'theTradeDesk',
    value: '_thetradedesk_tech_stack',
  },
  {
    label: 'three.js',
    value: '_three.js_tech_stack',
  },
  {
    label: 'thttpd',
    value: '_thttpd_tech_stack',
  },
  {
    label: 'toastr',
    value: '_toastr_tech_stack',
  },
  {
    label: 'total.js',
    value: '_total.js_tech_stack',
  },
  {
    label: 'U-KOMI',
    value: '_u-komi_tech_stack',
  },
  {
    label: 'UIKit',
    value: '_uikit_tech_stack',
  },
  {
    label: 'UK Mail',
    value: '_uk_mail_tech_stack',
  },
  {
    label: 'UKFast',
    value: '_ukfast_tech_stack',
  },
  {
    label: 'UMI.CMS',
    value: '_umi.cms_tech_stack',
  },
  {
    label: 'UNIX',
    value: '_unix_tech_stack',
  },
  {
    label: 'UPS',
    value: '_ups_tech_stack',
  },
  {
    label: 'USPS',
    value: '_usps_tech_stack',
  },
  {
    label: 'USWDS',
    value: '_uswds_tech_stack',
  },
  {
    label: 'Ubercart',
    value: '_ubercart_tech_stack',
  },
  {
    label: 'Ubuntu',
    value: '_ubuntu_tech_stack',
  },
  {
    label: 'Ueeshop',
    value: '_ueeshop_tech_stack',
  },
  {
    label: 'Ultimate GDPR & CCPA',
    value: '_ultimate_gdpr & ccpa_tech_stack',
  },
  {
    label: 'UltimatelySocial',
    value: '_ultimatelysocial_tech_stack',
  },
  {
    label: 'UltraCart',
    value: '_ultracart_tech_stack',
  },
  {
    label: 'Umami',
    value: '_umami_tech_stack',
  },
  {
    label: 'Umbraco',
    value: '_umbraco_tech_stack',
  },
  {
    label: 'UmiJs',
    value: '_umijs_tech_stack',
  },
  {
    label: 'Umso',
    value: '_umso_tech_stack',
  },
  {
    label: 'Unbounce',
    value: '_unbounce_tech_stack',
  },
  {
    label: 'Unbxd',
    value: '_unbxd_tech_stack',
  },
  {
    label: 'Underscore.js',
    value: '_underscore.js_tech_stack',
  },
  {
    label: 'Understrap',
    value: '_understrap_tech_stack',
  },
  {
    label: 'Uniconsent',
    value: '_uniconsent_tech_stack',
  },
  {
    label: 'Unicorn Platform',
    value: '_unicorn_platform_tech_stack',
  },
  {
    label: 'Unpkg',
    value: '_unpkg_tech_stack',
  },
  {
    label: 'Unruly',
    value: '_unruly_tech_stack',
  },
  {
    label: 'UpSellit',
    value: '_upsellit_tech_stack',
  },
  {
    label: 'Uploadcare',
    value: '_uploadcare_tech_stack',
  },
  {
    label: 'Upptime',
    value: '_upptime_tech_stack',
  },
  {
    label: 'Upserve',
    value: '_upserve_tech_stack',
  },
  {
    label: 'UptimeRobot',
    value: '_uptimerobot_tech_stack',
  },
  {
    label: 'Uptrends',
    value: '_uptrends_tech_stack',
  },
  {
    label: 'Upvoty',
    value: '_upvoty_tech_stack',
  },
  {
    label: 'UsableNet',
    value: '_usablenet_tech_stack',
  },
  {
    label: 'Uscreen',
    value: '_uscreen_tech_stack',
  },
  {
    label: 'UserLike',
    value: '_userlike_tech_stack',
  },
  {
    label: 'UserReport',
    value: '_userreport_tech_stack',
  },
  {
    label: 'UserRules',
    value: '_userrules_tech_stack',
  },
  {
    label: 'UserVoice',
    value: '_uservoice_tech_stack',
  },
  {
    label: 'UserWay',
    value: '_userway_tech_stack',
  },
  {
    label: 'UserZoom',
    value: '_userzoom_tech_stack',
  },
  {
    label: 'Usercentrics',
    value: '_usercentrics_tech_stack',
  },
  {
    label: 'Userflow',
    value: '_userflow_tech_stack',
  },
  {
    label: 'Userpilot',
    value: '_userpilot_tech_stack',
  },
  {
    label: 'Ushahidi',
    value: '_ushahidi_tech_stack',
  },
  {
    label: 'Usizy',
    value: '_usizy_tech_stack',
  },
  {
    label: 'Uvicorn',
    value: '_uvicorn_tech_stack',
  },
  {
    label: 'uKnowva',
    value: '_uknowva_tech_stack',
  },
  {
    label: 'uLogin',
    value: '_ulogin_tech_stack',
  },
  {
    label: 'uPortal',
    value: '_uportal_tech_stack',
  },
  {
    label: 'uRemediate',
    value: '_uremediate_tech_stack',
  },
  {
    label: 'user.com',
    value: '_user.com_tech_stack',
  },
  {
    label: 'utterances',
    value: '_utterances_tech_stack',
  },
  {
    label: 'VAPTCHA',
    value: '_vaptcha_tech_stack',
  },
  {
    label: 'VDX.tv',
    value: '_vdx.tv_tech_stack',
  },
  {
    label: 'VIVVO',
    value: '_vivvo_tech_stack',
  },
  {
    label: 'VK Pixel',
    value: '_vk_pixel_tech_stack',
  },
  {
    label: 'VP-ASP',
    value: '_vp-asp_tech_stack',
  },
  {
    label: 'VTEX',
    value: '_vtex_tech_stack',
  },
  {
    label: 'VWO',
    value: '_vwo_tech_stack',
  },
  {
    label: 'VWO Engage',
    value: '_vwo_engage_tech_stack',
  },
  {
    label: 'Vaadin',
    value: '_vaadin_tech_stack',
  },
  {
    label: 'ValueCommerce',
    value: '_valuecommerce_tech_stack',
  },
  {
    label: 'Vanilla',
    value: '_vanilla_tech_stack',
  },
  {
    label: 'Varbase',
    value: '_varbase_tech_stack',
  },
  {
    label: 'Variti',
    value: '_variti_tech_stack',
  },
  {
    label: 'Varnish',
    value: '_varnish_tech_stack',
  },
  {
    label: 'Venmo',
    value: '_venmo_tech_stack',
  },
  {
    label: 'VentraIP',
    value: '_ventraip_tech_stack',
  },
  {
    label: 'Veoxa',
    value: '_veoxa_tech_stack',
  },
  {
    label: 'Vercel',
    value: '_vercel_tech_stack',
  },
  {
    label: 'Verifone 2Checkout',
    value: '_verifone_2checkout_tech_stack',
  },
  {
    label: 'VerifyPass',
    value: '_verifypass_tech_stack',
  },
  {
    label: 'Verizon Media',
    value: '_verizon_media_tech_stack',
  },
  {
    label: 'Verloop',
    value: '_verloop_tech_stack',
  },
  {
    label: 'ViaBill',
    value: '_viabill_tech_stack',
  },
  {
    label: 'Vidazoo',
    value: '_vidazoo_tech_stack',
  },
  {
    label: 'Video Greet',
    value: '_video_greet_tech_stack',
  },
  {
    label: 'VideoJS',
    value: '_videojs_tech_stack',
  },
  {
    label: 'Vigbo',
    value: '_vigbo_tech_stack',
  },
  {
    label: 'Vigil',
    value: '_vigil_tech_stack',
  },
  {
    label: 'Vignette',
    value: '_vignette_tech_stack',
  },
  {
    label: 'Vimeo',
    value: '_vimeo_tech_stack',
  },
  {
    label: 'Vimeo OTT',
    value: '_vimeo_ott_tech_stack',
  },
  {
    label: 'Viral Loops',
    value: '_viral_loops_tech_stack',
  },
  {
    label: 'Virgool',
    value: '_virgool_tech_stack',
  },
  {
    label: 'Virtooal',
    value: '_virtooal_tech_stack',
  },
  {
    label: 'VirtueMart',
    value: '_virtuemart_tech_stack',
  },
  {
    label: 'Virtuoso',
    value: '_virtuoso_tech_stack',
  },
  {
    label: 'Virtusize',
    value: '_virtusize_tech_stack',
  },
  {
    label: 'Visa',
    value: '_visa_tech_stack',
  },
  {
    label: 'Visa Checkout',
    value: '_visa_checkout_tech_stack',
  },
  {
    label: 'Visely',
    value: '_visely_tech_stack',
  },
  {
    label: 'Visual Composer',
    value: '_visual_composer_tech_stack',
  },
  {
    label: 'Visual Quiz Builder',
    value: '_visual_quiz builder_tech_stack',
  },
  {
    label: 'Visualsoft',
    value: '_visualsoft_tech_stack',
  },
  {
    label: 'Vitals',
    value: '_vitals_tech_stack',
  },
  {
    label: 'Vite',
    value: '_vite_tech_stack',
  },
  {
    label: 'Vitrin.me',
    value: '_vitrin.me_tech_stack',
  },
  {
    label: 'Vizury',
    value: '_vizury_tech_stack',
  },
  {
    label: 'Vntana',
    value: '_vntana_tech_stack',
  },
  {
    label: 'Volusion',
    value: '_volusion_tech_stack',
  },
  {
    label: 'Vonage Video API',
    value: '_vonage_video api_tech_stack',
  },
  {
    label: 'Voog.com Website Builder',
    value: '_voog.com_website builder_tech_stack',
  },
  {
    label: 'Voracio',
    value: '_voracio_tech_stack',
  },
  {
    label: 'VuFind',
    value: '_vufind_tech_stack',
  },
  {
    label: 'Vue Storefront',
    value: '_vue_storefront_tech_stack',
  },
  {
    label: 'Vue.ai',
    value: '_vue.ai_tech_stack',
  },
  {
    label: 'Vue.js',
    value: '_vue.js_tech_stack',
  },
  {
    label: 'Vue2-animate',
    value: '_vue2-animate_tech_stack',
  },
  {
    label: 'VuePress',
    value: '_vuepress_tech_stack',
  },
  {
    label: 'Vuetify',
    value: '_vuetify_tech_stack',
  },
  {
    label: 'vBulletin',
    value: '_vbulletin_tech_stack',
  },
  {
    label: 'vcita',
    value: '_vcita_tech_stack',
  },
  {
    label: 'vibecommerce',
    value: '_vibecommerce_tech_stack',
  },
  {
    label: 'W3 Total Cache',
    value: '_w3_total cache_tech_stack',
  },
  {
    label: 'W3Counter',
    value: '_w3counter_tech_stack',
  },
  {
    label: 'WEBDEV',
    value: '_webdev_tech_stack',
  },
  {
    label: 'WEBXPAY',
    value: '_webxpay_tech_stack',
  },
  {
    label: 'WEN Themes Education Hub',
    value: '_wen_themes education hub_tech_stack',
  },
  {
    label: 'WEN Themes Signify Dark',
    value: '_wen_themes signify dark_tech_stack',
  },
  {
    label: 'WHMCS',
    value: '_whmcs_tech_stack',
  },
  {
    label: 'WP Engine',
    value: '_wp_engine_tech_stack',
  },
  {
    label: 'WP Fastest Cache',
    value: '_wp_fastest cache_tech_stack',
  },
  {
    label: 'WP Google Map Plugin',
    value: '_wp_google map plugin_tech_stack',
  },
  {
    label: 'WP Job Openings',
    value: '_wp_job openings_tech_stack',
  },
  {
    label: 'WP Maintenance Mode',
    value: '_wp_maintenance mode_tech_stack',
  },
  {
    label: 'WP Puzzle Basic',
    value: '_wp_puzzle basic_tech_stack',
  },
  {
    label: 'WP Rocket',
    value: '_wp_rocket_tech_stack',
  },
  {
    label: 'WP-Optimize',
    value: '_wp-optimize_tech_stack',
  },
  {
    label: 'WP-PageNavi',
    value: '_wp-pagenavi_tech_stack',
  },
  {
    label: 'WP-Royal Ashe',
    value: '_wp-royal_ashe_tech_stack',
  },
  {
    label: 'WP-Royal Bard',
    value: '_wp-royal_bard_tech_stack',
  },
  {
    label: 'WP-Statistics',
    value: '_wp-statistics_tech_stack',
  },
  {
    label: 'WPCacheOn',
    value: '_wpcacheon_tech_stack',
  },
  {
    label: 'WPForms',
    value: '_wpforms_tech_stack',
  },
  {
    label: 'WPMU DEV Smush',
    value: '_wpmu_dev smush_tech_stack',
  },
  {
    label: 'Wagtail',
    value: '_wagtail_tech_stack',
  },
  {
    label: 'Wair',
    value: '_wair_tech_stack',
  },
  {
    label: 'Wakav Performance Monitoring',
    value: '_wakav_performance monitoring_tech_stack',
  },
  {
    label: 'WalkMe',
    value: '_walkme_tech_stack',
  },
  {
    label: 'Wangsu',
    value: '_wangsu_tech_stack',
  },
  {
    label: 'Warp',
    value: '_warp_tech_stack',
  },
  {
    label: 'Weaver Xtreme',
    value: '_weaver_xtreme_tech_stack',
  },
  {
    label: 'Web Shop Manager',
    value: '_web_shop manager_tech_stack',
  },
  {
    label: 'Web Stories',
    value: '_web_stories_tech_stack',
  },
  {
    label: 'Web Stories for WordPress',
    value: '_web_stories for wordpress_tech_stack',
  },
  {
    label: 'Web2py',
    value: '_web2py_tech_stack',
  },
  {
    label: 'WebAssembly',
    value: '_webassembly_tech_stack',
  },
  {
    label: 'WebEngage',
    value: '_webengage_tech_stack',
  },
  {
    label: 'WebFactory Maintenance',
    value: '_webfactory_maintenance_tech_stack',
  },
  {
    label: 'WebFactory Under Construction',
    value: '_webfactory_under construction_tech_stack',
  },
  {
    label: 'WebGUI',
    value: '_webgui_tech_stack',
  },
  {
    label: 'WebHostUK',
    value: '_webhostuk_tech_stack',
  },
  {
    label: 'WebMetric',
    value: '_webmetric_tech_stack',
  },
  {
    label: 'WebNode',
    value: '_webnode_tech_stack',
  },
  {
    label: 'WebRTC',
    value: '_webrtc_tech_stack',
  },
  {
    label: 'WebSite X5',
    value: '_website_x5_tech_stack',
  },
  {
    label: 'WebZi',
    value: '_webzi_tech_stack',
  },
  {
    label: 'Webflow',
    value: '_webflow_tech_stack',
  },
  {
    label: 'Webgains',
    value: '_webgains_tech_stack',
  },
  {
    label: 'Webix',
    value: '_webix_tech_stack',
  },
  {
    label: 'Weblication',
    value: '_weblication_tech_stack',
  },
  {
    label: 'Weblium',
    value: '_weblium_tech_stack',
  },
  {
    label: 'Weblogic Server',
    value: '_weblogic_server_tech_stack',
  },
  {
    label: 'Webmin',
    value: '_webmin_tech_stack',
  },
  {
    label: 'Webriti Busiprof',
    value: '_webriti_busiprof_tech_stack',
  },
  {
    label: 'WebsPlanet',
    value: '_websplanet_tech_stack',
  },
  {
    label: 'Websale',
    value: '_websale_tech_stack',
  },
  {
    label: 'Website Creator',
    value: '_website_creator_tech_stack',
  },
  {
    label: 'WebsiteBaker',
    value: '_websitebaker_tech_stack',
  },
  {
    label: 'Websocket',
    value: '_websocket_tech_stack',
  },
  {
    label: 'Webtrends',
    value: '_webtrends_tech_stack',
  },
  {
    label: 'Webzie',
    value: '_webzie_tech_stack',
  },
  {
    label: 'Weebly',
    value: '_weebly_tech_stack',
  },
  {
    label: 'Weglot',
    value: '_weglot_tech_stack',
  },
  {
    label: 'Welcart',
    value: '_welcart_tech_stack',
  },
  {
    label: 'Whatfix',
    value: '_whatfix_tech_stack',
  },
  {
    label: 'WhatsApp Business Chat',
    value: '_whatsapp_business chat_tech_stack',
  },
  {
    label: 'Wheelio',
    value: '_wheelio_tech_stack',
  },
  {
    label: 'Whistl',
    value: '_whistl_tech_stack',
  },
  {
    label: 'Whooshkaa',
    value: '_whooshkaa_tech_stack',
  },
  {
    label: 'WideBundle',
    value: '_widebundle_tech_stack',
  },
  {
    label: 'Widen',
    value: '_widen_tech_stack',
  },
  {
    label: 'WidgetWhats',
    value: '_widgetwhats_tech_stack',
  },
  {
    label: 'Wigzo',
    value: '_wigzo_tech_stack',
  },
  {
    label: 'Wiki.js',
    value: '_wiki.js_tech_stack',
  },
  {
    label: 'Wikinggruppen',
    value: '_wikinggruppen_tech_stack',
  },
  {
    label: 'WikkaWiki',
    value: '_wikkawiki_tech_stack',
  },
  {
    label: 'WildJar',
    value: '_wildjar_tech_stack',
  },
  {
    label: 'Windows CE',
    value: '_windows_ce_tech_stack',
  },
  {
    label: 'Windows Server',
    value: '_windows_server_tech_stack',
  },
  {
    label: 'Wink',
    value: '_wink_tech_stack',
  },
  {
    label: 'Winstone Servlet Container',
    value: '_winstone_servlet container_tech_stack',
  },
  {
    label: 'Wirecard',
    value: '_wirecard_tech_stack',
  },
  {
    label: 'Wisepops',
    value: '_wisepops_tech_stack',
  },
  {
    label: 'Wishlist King',
    value: '_wishlist_king_tech_stack',
  },
  {
    label: 'Wistia',
    value: '_wistia_tech_stack',
  },
  {
    label: 'With Reach',
    value: '_with_reach_tech_stack',
  },
  {
    label: 'Wix',
    value: '_wix_tech_stack',
  },
  {
    label: 'Wix Answers',
    value: '_wix_answers_tech_stack',
  },
  {
    label: 'Wix eCommerce',
    value: '_wix_ecommerce_tech_stack',
  },
  {
    label: 'WiziShop',
    value: '_wizishop_tech_stack',
  },
  {
    label: 'Wizpay',
    value: '_wizpay_tech_stack',
  },
  {
    label: 'Wolf CMS',
    value: '_wolf_cms_tech_stack',
  },
  {
    label: 'Woltlab Community Framework',
    value: '_woltlab_community framework_tech_stack',
  },
  {
    label: 'WooCommerce',
    value: '_woocommerce_tech_stack',
  },
  {
    label: 'WooCommerce Blocks',
    value: '_woocommerce_blocks_tech_stack',
  },
  {
    label: 'WooCommerce Multilingual',
    value: '_woocommerce_multilingual_tech_stack',
  },
  {
    label: 'WooCommerce PayPal Checkout Payment Gateway',
    value: '_woocommerce_paypal checkout payment gateway_tech_stack',
  },
  {
    label: 'WooCommerce PayPal Payments',
    value: '_woocommerce_paypal payments_tech_stack',
  },
  {
    label: 'WooCommerce Stripe Payment Gateway',
    value: '_woocommerce_stripe payment gateway_tech_stack',
  },
  {
    label: 'Woopra',
    value: '_woopra_tech_stack',
  },
  {
    label: 'Woostify',
    value: '_woostify_tech_stack',
  },
  {
    label: 'WoowUp',
    value: '_woowup_tech_stack',
  },
  {
    label: 'WordAds',
    value: '_wordads_tech_stack',
  },
  {
    label: 'WordPress',
    value: '_wordpress_tech_stack',
  },
  {
    label: 'WordPress Default',
    value: '_wordpress_default_tech_stack',
  },
  {
    label: 'WordPress Super Cache',
    value: '_wordpress_super cache_tech_stack',
  },
  {
    label: 'WordPress VIP',
    value: '_wordpress_vip_tech_stack',
  },
  {
    label: 'WordPress.com',
    value: '_wordpress.com_tech_stack',
  },
  {
    label: 'Wordfence',
    value: '_wordfence_tech_stack',
  },
  {
    label: 'Wordfence Login Security',
    value: '_wordfence_login security_tech_stack',
  },
  {
    label: 'Workable',
    value: '_workable_tech_stack',
  },
  {
    label: 'Workarea',
    value: '_workarea_tech_stack',
  },
  {
    label: 'World4You',
    value: '_world4you_tech_stack',
  },
  {
    label: 'WorldPay',
    value: '_worldpay_tech_stack',
  },
  {
    label: 'WorldShopping',
    value: '_worldshopping_tech_stack',
  },
  {
    label: 'Worldz',
    value: '_worldz_tech_stack',
  },
  {
    label: 'Wufoo',
    value: '_wufoo_tech_stack',
  },
  {
    label: 'Wuilt',
    value: '_wuilt_tech_stack',
  },
  {
    label: 'Wunderkind',
    value: '_wunderkind_tech_stack',
  },
  {
    label: 'Wurfl',
    value: '_wurfl_tech_stack',
  },
  {
    label: 'WysiBB',
    value: '_wysibb_tech_stack',
  },
  {
    label: 'web-vitals',
    value: '_web-vitals_tech_stack',
  },
  {
    label: 'webEdition',
    value: '_webedition_tech_stack',
  },
  {
    label: 'webpack',
    value: '_webpack_tech_stack',
  },
  {
    label: 'wisyCMS',
    value: '_wisycms_tech_stack',
  },
  {
    label: 'wpBakery',
    value: '_wpbakery_tech_stack',
  },
  {
    label: 'wpCache',
    value: '_wpcache_tech_stack',
  },
  {
    label: 'X-Cart',
    value: '_x-cart_tech_stack',
  },
  {
    label: 'X.ai',
    value: '_x.ai_tech_stack',
  },
  {
    label: 'XAMPP',
    value: '_xampp_tech_stack',
  },
  {
    label: 'XMB',
    value: '_xmb_tech_stack',
  },
  {
    label: 'XOOPS',
    value: '_xoops_tech_stack',
  },
  {
    label: 'XRegExp',
    value: '_xregexp_tech_stack',
  },
  {
    label: 'XSLT',
    value: '_xslt_tech_stack',
  },
  {
    label: 'XWiki',
    value: '_xwiki_tech_stack',
  },
  {
    label: 'Xajax',
    value: '_xajax_tech_stack',
  },
  {
    label: 'Xanario',
    value: '_xanario_tech_stack',
  },
  {
    label: 'XenForo',
    value: '_xenforo_tech_stack',
  },
  {
    label: 'Xeora',
    value: '_xeora_tech_stack',
  },
  {
    label: 'Xitami',
    value: '_xitami_tech_stack',
  },
  {
    label: 'Xonic',
    value: '_xonic_tech_stack',
  },
  {
    label: 'XpressEngine',
    value: '_xpressengine_tech_stack',
  },
  {
    label: 'Xpresslane',
    value: '_xpresslane_tech_stack',
  },
  {
    label: 'Xserver',
    value: '_xserver_tech_stack',
  },
  {
    label: 'Xtremepush',
    value: '_xtremepush_tech_stack',
  },
  {
    label: 'xCharts',
    value: '_xcharts_tech_stack',
  },
  {
    label: 'xtCommerce',
    value: '_xtcommerce_tech_stack',
  },
  {
    label: 'YMQ Product Options Variant Option',
    value: '_ymq_product options variant option_tech_stack',
  },
  {
    label: 'YNAP Ecommerce',
    value: '_ynap_ecommerce_tech_stack',
  },
  {
    label: 'YUI',
    value: '_yui_tech_stack',
  },
  {
    label: 'YUI Doc',
    value: '_yui_doc_tech_stack',
  },
  {
    label: 'YaBB',
    value: '_yabb_tech_stack',
  },
  {
    label: 'Yahoo Advertising',
    value: '_yahoo_advertising_tech_stack',
  },
  {
    label: 'Yahoo! Ecommerce',
    value: '_yahoo!_ecommerce_tech_stack',
  },
  {
    label: 'Yahoo! Tag Manager',
    value: '_yahoo!_tag manager_tech_stack',
  },
  {
    label: 'Yahoo! Web Analytics',
    value: '_yahoo!_web analytics_tech_stack',
  },
  {
    label: 'YalinHost',
    value: '_yalinhost_tech_stack',
  },
  {
    label: 'Yampi Checkout',
    value: '_yampi_checkout_tech_stack',
  },
  {
    label: 'Yampi Virtual store',
    value: '_yampi_virtual store_tech_stack',
  },
  {
    label: 'Yandex.Cloud',
    value: '_yandex.cloud_tech_stack',
  },
  {
    label: 'Yandex.Cloud CDN',
    value: '_yandex.cloud_cdn_tech_stack',
  },
  {
    label: 'Yandex.Direct',
    value: '_yandex.direct_tech_stack',
  },
  {
    label: 'Yandex.Messenger',
    value: '_yandex.messenger_tech_stack',
  },
  {
    label: 'Yandex.Metrika',
    value: '_yandex.metrika_tech_stack',
  },
  {
    label: 'Yaws',
    value: '_yaws_tech_stack',
  },
  {
    label: 'Yektanet',
    value: '_yektanet_tech_stack',
  },
  {
    label: 'Yelp Reservations',
    value: '_yelp_reservations_tech_stack',
  },
  {
    label: 'Yelp Review Badge',
    value: '_yelp_review badge_tech_stack',
  },
  {
    label: 'Yepcomm',
    value: '_yepcomm_tech_stack',
  },
  {
    label: 'Yett',
    value: '_yett_tech_stack',
  },
  {
    label: 'Yext',
    value: '_yext_tech_stack',
  },
  {
    label: 'Yieldify',
    value: '_yieldify_tech_stack',
  },
  {
    label: 'Yieldlab',
    value: '_yieldlab_tech_stack',
  },
  {
    label: 'Yii',
    value: '_yii_tech_stack',
  },
  {
    label: 'Yoast Duplicate Post',
    value: '_yoast_duplicate post_tech_stack',
  },
  {
    label: 'Yoast SEO',
    value: '_yoast_seo_tech_stack',
  },
  {
    label: 'Yoast SEO Premium',
    value: '_yoast_seo premium_tech_stack',
  },
  {
    label: 'Yoast SEO for Shopify',
    value: '_yoast_seo for shopify_tech_stack',
  },
  {
    label: 'Yodel',
    value: '_yodel_tech_stack',
  },
  {
    label: 'Yola',
    value: '_yola_tech_stack',
  },
  {
    label: 'YooMoney',
    value: '_yoomoney_tech_stack',
  },
  {
    label: 'Yotpo Loyalty & Referrals',
    value: '_yotpo_loyalty & referrals_tech_stack',
  },
  {
    label: 'Yotpo Reviews',
    value: '_yotpo_reviews_tech_stack',
  },
  {
    label: 'Yotpo SMSBump',
    value: '_yotpo_smsbump_tech_stack',
  },
  {
    label: 'Yottaa',
    value: '_yottaa_tech_stack',
  },
  {
    label: 'YouCam Makeup',
    value: '_youcam_makeup_tech_stack',
  },
  {
    label: 'YouCan',
    value: '_youcan_tech_stack',
  },
  {
    label: 'YouPay',
    value: '_youpay_tech_stack',
  },
  {
    label: 'YouTrack',
    value: '_youtrack_tech_stack',
  },
  {
    label: 'YouTube',
    value: '_youtube_tech_stack',
  },
  {
    label: 'yellow.ai',
    value: '_yellow.ai_tech_stack',
  },
  {
    label: 'ZK',
    value: '_zk_tech_stack',
  },
  {
    label: 'ZURB Foundation',
    value: '_zurb_foundation_tech_stack',
  },
  {
    label: 'Zabbix',
    value: '_zabbix_tech_stack',
  },
  {
    label: 'Zakeke',
    value: '_zakeke_tech_stack',
  },
  {
    label: 'Zakeke Interactive Product Designer',
    value: '_zakeke_interactive product designer_tech_stack',
  },
  {
    label: 'Zakeke Visual Customizer',
    value: '_zakeke_visual customizer_tech_stack',
  },
  {
    label: 'Zakra',
    value: '_zakra_tech_stack',
  },
  {
    label: 'Zanox',
    value: '_zanox_tech_stack',
  },
  {
    label: 'Zaraz',
    value: '_zaraz_tech_stack',
  },
  {
    label: 'Zeleris',
    value: '_zeleris_tech_stack',
  },
  {
    label: 'Zen Cart',
    value: '_zen_cart_tech_stack',
  },
  {
    label: 'Zend',
    value: '_zend_tech_stack',
  },
  {
    label: 'Zendesk',
    value: '_zendesk_tech_stack',
  },
  {
    label: 'Zendesk Chat',
    value: '_zendesk_chat_tech_stack',
  },
  {
    label: 'Zenfolio',
    value: '_zenfolio_tech_stack',
  },
  {
    label: 'Zeotap',
    value: '_zeotap_tech_stack',
  },
  {
    label: 'Zepto',
    value: '_zepto_tech_stack',
  },
  {
    label: 'ZestMoney',
    value: '_zestmoney_tech_stack',
  },
  {
    label: 'Zeus Technology',
    value: '_zeus_technology_tech_stack',
  },
  {
    label: 'Zid',
    value: '_zid_tech_stack',
  },
  {
    label: 'Zimbra',
    value: '_zimbra_tech_stack',
  },
  {
    label: 'ZingChart',
    value: '_zingchart_tech_stack',
  },
  {
    label: 'Zinnia',
    value: '_zinnia_tech_stack',
  },
  {
    label: 'Zinrelo',
    value: '_zinrelo_tech_stack',
  },
  {
    label: 'Zip',
    value: '_zip_tech_stack',
  },
  {
    label: 'Zipify OCU',
    value: '_zipify_ocu_tech_stack',
  },
  {
    label: 'Zipify Pages',
    value: '_zipify_pages_tech_stack',
  },
  {
    label: 'Zipkin',
    value: '_zipkin_tech_stack',
  },
  {
    label: 'Zmags Creator',
    value: '_zmags_creator_tech_stack',
  },
  {
    label: 'Zocdoc',
    value: '_zocdoc_tech_stack',
  },
  {
    label: 'Zoey',
    value: '_zoey_tech_stack',
  },
  {
    label: 'Zoho',
    value: '_zoho_tech_stack',
  },
  {
    label: 'Zoho Mail',
    value: '_zoho_mail_tech_stack',
  },
  {
    label: 'Zoho PageSense',
    value: '_zoho_pagesense_tech_stack',
  },
  {
    label: 'Zoko',
    value: '_zoko_tech_stack',
  },
  {
    label: 'Zone.js',
    value: '_zone.js_tech_stack',
  },
  {
    label: 'Zonos',
    value: '_zonos_tech_stack',
  },
  {
    label: 'ZoodPay',
    value: '_zoodpay_tech_stack',
  },
  {
    label: 'Zoominfo',
    value: '_zoominfo_tech_stack',
  },
  {
    label: 'Zoominfo Chat',
    value: '_zoominfo_chat_tech_stack',
  },
  {
    label: 'Zope',
    value: '_zope_tech_stack',
  },
  {
    label: 'Zozo',
    value: '_zozo_tech_stack',
  },
  {
    label: 'Zuppler',
    value: '_zuppler_tech_stack',
  },
  {
    label: 'Zyro',
    value: '_zyro_tech_stack',
  },
];
