import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
import { measurementOption, units } from '../../constants';
import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import './styles.scss';

const Measurement: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [selectedUnitType, setSelectedUnitType] = useState<{ label: string; value: string } | null>(null);
  const [selectedUnitFrom, setSelectedUnitFrom] = useState<{ label: string; value: string } | null>(null);
  const [selectedUnitTo, setSelectedUnitTo] = useState<{ label: string; value: string } | null>(null);
  const [fieldList, setFieldList] = useState<any>([]);
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [clicked, setClicked] = useState(false);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const checkFields = () => {
    if (!selectedUnitType || !selectedUnitFrom || !selectedUnitTo) {
      return false;
    }
    if (selectedSource?.length === 0) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!clicked) return;

    setSelectedUnitFrom(null);
    setSelectedUnitTo(null);
  }, [selectedUnitType]);

  const {
    outputFormat,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    liftingSettings,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  //recipe fields or output fields
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings && dynamic_settings.keys && options.length > 0) {
      const { keys } = dynamic_settings;
      const { settings } = keys[0];
      // settings new structure
      const measure = settings ? settings : keys[0];
      const type: string = measure.measurementType || '';
      restoreSelectedSource({ key: keys[0].key, fieldList: options, setSelectedSource: setSelectedSource });
      const unitType = measurementOption.find((item: { value: any }) => item.value === type);
      unitType && setSelectedUnitType(unitType);
      //@ts-ignore
      const unitFrom = units[type] && units[type].find((item: { value: any }) => item.value === measure.inputDataType);
      unitFrom && setSelectedUnitFrom(unitFrom);
      //@ts-ignore
      const unitTo = units[type] && units[type].find((item: { value: any }) => item.value === measure.outputDataType);
      unitTo && setSelectedUnitTo(unitTo);
    } else {
      setSelectedSource(options.map((item: any, index: number) => ({ label: item.label, value: item.value })));
    }

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [
          ...fieldList.map((item: { value: any }) => item.value),
          ...(selectedSource
            ? Array.isArray(selectedSource)
              ? selectedSource.map((sub: any) => outputFormat(sub.label, 'measurement_transformation'))
              : selectedSource.label && outputFormat(selectedSource.label, 'measurement_transformation')
            : []),
        ],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);

  //lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);

    let localSettings = {
      keys: [
        {
          key: fieldList.length && selectedSource ? selectedSource.value : null,
          inputValue: null,
          settings: {
            outputDataType: selectedUnitTo ? selectedUnitTo.value : null, //'KG',
            inputDataType: selectedUnitFrom ? selectedUnitFrom.value : null, //'G',
            measurementType: selectedUnitType ? selectedUnitType.value : null, //'MEASUREMENT_WEIGHT',
            add: true,
          },
        },
      ],
    };
    // const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    const vals = [
      ...(selectedSource
        ? Array.isArray(selectedSource)
          ? selectedSource.map((sub: any) => outputFormat(sub.label, 'measurement_transformation'))
          : selectedSource.label && [outputFormat(selectedSource.label, 'measurement_transformation')]
        : []),
    ];
    const jsonParse: any = {};
    Object.keys(vals).map((key: any) => (jsonParse[key] = 'any'));
    liftingSettings({
      dynamicSettings: localSettings,
      //@ts-ignore
      outputKeysData: [
        ...fieldList.map((item: { value: any }) => item.value),
        ...(selectedSource
          ? Array.isArray(selectedSource)
            ? selectedSource.map((sub: any) => outputFormat(sub.label, 'measurement_transformation'))
            : selectedSource.label && [outputFormat(selectedSource.label, 'measurement_transformation')]
          : []),
      ],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [selectedUnitType, selectedUnitFrom, selectedUnitTo, selectedSource, automationOutputTypes, fieldList, fieldListWithTypes]);

  return (
    <div className="measurment-settings">
      <Row className="mb-4">
        <Col lg={6}>
          <span className="label secondary-color">Select field</span>
          <Select
            value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
            options={fieldList}
            fullWidth
            onChange={(selected: any) => {
              setSelectedSource(selected);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          ></Select>
        </Col>
        <Col lg={6}>
          <span className="label secondary-color">Select type</span>
          <Select
            value={selectedUnitType}
            options={measurementOption}
            fullWidth
            onChange={(selected: any) => {
              setSelectedUnitType(selected);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          ></Select>
        </Col>
      </Row>
      {selectedUnitType && (
        <Row className="mb-4">
          {selectedUnitType && (
            <>
              <Col lg={6} className="measurment-select">
                <h5 className="pointer-title">From</h5>
                <div className="select-block">
                  <span className="label secondary-color">{selectedUnitType.label}</span>
                  <Select
                    value={selectedUnitFrom}
                    //@ts-ignore
                    options={units[selectedUnitType.value ? selectedUnitType.value : []]}
                    fullWidth
                    onChange={(selectedItem: any) => {
                      setSelectedUnitFrom(selectedItem);
                      !clicked && setClicked(true);
                      setIsDirty();
                    }}
                  ></Select>
                </div>
              </Col>
              <Col lg={6} className="measurment-select">
                <h5 className="pointer-title">To</h5>
                <div className="select-block">
                  <span className="label secondary-color">{selectedUnitType.label}</span>
                  <Select
                    value={selectedUnitTo}
                    //@ts-ignore
                    options={units[selectedUnitType.value ? selectedUnitType.value : []]}
                    fullWidth
                    onChange={(selectedItem: any) => {
                      setSelectedUnitTo(selectedItem);
                      !clicked && setClicked(true);
                      setIsDirty();
                    }}
                  ></Select>
                </div>
              </Col>
            </>
          )}
        </Row>
      )}
    </div>
  );
};

export default Measurement;
