import { UserContext } from 'contexts/user-context';
import { useContext, useEffect, useState } from 'react';
import Message from 'shared-components/atom/alert-message';
import Input from 'shared-components/atom/input';
import ModalComponent from 'shared-components/molecule/modal';
import { useMutation } from '@apollo/react-hooks';
import { GET_DEVICES, LOG_OUT_ALL_DEVICES } from 'graphql/mutations';
import { eyeIcon, lockClosedIconLight } from 'assets/svg-icons';
import { useHistory } from 'react-router-dom';
import Loader from 'shared-components/atom/loader';
import { ThemeContext } from 'contexts/theme-context';
import Tooltip from 'shared-components/atom/tooltip';
//@ts-ignore
import UAParser from 'ua-parser-js';
import { getDateAndTime } from 'helpers';

const Devices = (props: any) => {
  var parser = new UAParser();
  const { user } = useContext(UserContext);
  const { theme } = useContext(ThemeContext);
  const history = useHistory();
  const [password, setPassword] = useState<string | undefined>();
  const [passwordError, setPasswordError] = useState<string>('');
  const [passwordType, setPasswordType] = useState(false);
  const [logOutModal, setLogOutModal] = useState(false);
  const [deleteDeviceIndex, setDeleteDeviceIndex] = useState('');
  const [devices, setDevices] = useState<any[]>([]);

  //------------------------Query---------------------
  const [logOut, { loading: logOutLoading }] = useMutation(LOG_OUT_ALL_DEVICES);
  const [getAllDevices, { data: devicesList, loading: devicesLoading }] = useMutation(GET_DEVICES, {
    variables: {
      action: 'get',
      id: 0,
      status: true,
      product_type: 'hexomatic',
    },
  });
  const [removeDevice, { loading: removeDeviceLoading }] = useMutation(GET_DEVICES);

  //------------------UseEffect-------------------
  useEffect(() => {
    getAllDevices();
  }, []);

  useEffect(() => {
    setPasswordError('');
    setPassword('');
    setPasswordType(false);
  }, [logOutModal]);

  useEffect(() => {
    if (devicesList?.UserOps?.userDeviceList?.list) {
      setDevices(devicesList.UserOps.userDeviceList.list);
      if (!devicesList.UserOps.userDeviceList.list.filter((item: any) => item.product_type === 'hexomatic').length) {
        props && props.setDevices && props.setDevices(true);
      }
    }
  }, [devicesList]);

  //------------------Functions------------------

  const handleChangePassword = (e: any) => {
    setPassword(e.target.value);
    setPasswordError('');
  };
  const handleShowLogOutModal = () => setLogOutModal(true);

  const handleCancelLogOut = () => {
    setDeleteDeviceIndex('');
    setLogOutModal(false);
    setPassword(undefined);
    setPasswordType(false);
    setPasswordError('');
  };

  const handleLogOut = async () => {
    if (!password) return;
    if (deleteDeviceIndex) {
      const res = await removeDevice({
        variables: {
          action: 'update',
          id: deleteDeviceIndex,
          status: false,
          password: password,
        },
      });
      if (res?.data?.UserOps?.userDeviceList) {
        if (res.data.UserOps.userDeviceList.message === 'SUCCESS_UPDATED') {
          setDeleteDeviceIndex('');
          getAllDevices();
          setLogOutModal(false);
        } else setPasswordError('Invalid password');
      }
      return;
    }
    const res = await logOut({
      variables: {
        password: password,
      },
    });
    if (res?.data?.UserOps?.logoutFromAllDevices?.error) {
      setPasswordError('Invalid password');
      setPassword('');
    } else {
      window.location.href = 'https://hexomatic.com/login/';
    };
  };
  const handleLogOutKeyUp = (e: any) => {
    if (e.key === 'Enter') handleLogOut();
  };
  const handleRemoveFromTrustedList = (val: string) => {
    setDeleteDeviceIndex(val);
    setLogOutModal(true);
  };

  const getDeviceType = (user_agent: string) => {
    parser.setUA(user_agent);
    const device = parser.getResult();
    return device?.device?.vendor ? device.device.vendor + ' ' + device.device.model : device.os.name;
  };
  return devices && devices.filter((item: any) => item.product_type === 'hexomatic').length ? (
    <>
      <div className="package-info-container mb-4">
        {!user?.id || devicesLoading ? (
          <div className="mx-auto my-5">
            <Loader light={theme === 'light'} />
          </div>
        ) : (
          <>
            <div className="plans-header">
              <div>
                <h5 className="plans-title">YOUR DEVICES</h5>
                <p className="primary-text my-2">You’ve signed in to your account from these devices</p>
              </div>
            </div>
            {
              <div className="plans-body two-factor " id="devices-footer">
                {devices.map(
                  (item: any) =>
                    item.product_type === 'hexomatic' && (
                      <div className="d-flex justify-content-between mb-4">
                        <div>
                          <div className="device_name"> {getDeviceType(item.user_agent)}</div>
                          <div className="title">Last sign-in {getDateAndTime(+item.created_at)}</div>
                        </div>
                        {item.status && user?.twofa?.status && (
                          <div className="sub-title">
                            <Tooltip
                              trigger={['hover', 'focus', 'click']}
                              placement="left"
                              text="Click to remove this device from trusted devices list"
                            >
                              <span
                                className="cursor-pointer mr-2"
                                onClick={() => handleRemoveFromTrustedList(item.id)}
                              >
                                {lockClosedIconLight}
                              </span>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    ),
                )}
              </div>
            }
            <div className={`plans-footer justify-content-end`}>
              <span className={`action-link cursor-pointer`} onClick={handleShowLogOutModal}>
                Sign out from all devices
              </span>
            </div>
          </>
        )}
      </div>

      <ModalComponent
        isModalOpen={logOutModal}
        parentClass="logout-modal"
        text={
          <>
            <div className="position-relative">
              <div className="text">
                {deleteDeviceIndex
                  ? 'Are you sure you want to remove this device from trusted devices list?'
                  : 'Please confirm that you want to Sign out from all devices'}
              </div>
              <Input
                type={!passwordType ? 'password' : 'text'}
                value={password}
                onChange={handleChangePassword}
                error={password !== undefined && !password && passwordError ? 'error' : ''}
                placeholder="password"
                className="pr-4"
                autocomplete={false}
                onKeyUp={handleLogOutKeyUp}
              />
              <span
                className={`eye-icon position-absolute ${passwordType ? 'active' : ''}`}
                onClick={() => setPasswordType(!passwordType)}
              >
                {eyeIcon}
              </span>
            </div>

            <Message
              type="error"
              text={passwordError}
              className={`px-2 mt-1 ${passwordError ? 'visible' : 'invisible'}`}
            />
          </>
        }
        actionBtnText={'Confirm'}
        cancelBtnText={'Cancel'}
        actionBtnColor={'danger'}
        cancelBtnColor={'danger'}
        onActionClick={handleLogOut}
        onCancelClick={handleCancelLogOut}
        loading={logOutLoading || removeDeviceLoading}
        escButtonClick={handleCancelLogOut}
      />
    </>
  ) : (
    <></>
  );
};
export default Devices;
