import { FC } from 'react';

interface ICustomElement {
  show: boolean;
  notAllow: boolean;
  icon: any;
  iconType: boolean;
  onClick: any;
  text: string;
  title?: string;
  hide?: boolean;
  notAllowCookies?: boolean;
}
const CustomElement: FC<ICustomElement> = ({
  show,
  notAllow,
  icon,
  iconType,
  onClick,
  text,
  title,
  hide,
  notAllowCookies,
}) => (
  <div className={`position-relative action-row`}>
    <p
      className={`modal-line ${show && !notAllow && !notAllowCookies ? '' : hide ? 'd-none' : 'disabled'}`}
      onClick={() => show && !notAllow && !notAllowCookies && onClick()}
    >
      {!iconType ? <span className="icon-wrapper">{icon}</span> : icon} {text}
    </p>
    {(!show || notAllow || notAllowCookies) && (
      <p className={`action-hover position-absolute ${title === 'Element is not detected' ? 'sm' : ''}`}>
        {' '}
        {notAllow
          ? 'Actions limits reached'
          : notAllowCookies
          ? "This action can't be used after pagination"
          : title
          ? title === 'Element is not detected' || 'You can use this action only once'
            ? title
            : 'This action is available ' + title
          : 'To use this action please select an element'}
      </p>
    )}
  </div>
);

export default CustomElement;
