import { IPayment } from './../molecule/types';
import { SyntheticEvent, FormEvent, ReactElement, RefObject } from 'react';

export interface ButtonTypes {
  name?: string | ReactElement;
  outline?: boolean;
  secondary?: boolean;
  disabled?: boolean | undefined;
  loading?: boolean | undefined;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  className?: string;
  children?: ReactElement;
  onClick?: (e: any) => void;
  gradient?: boolean;
  active?: boolean;
  ref?: RefObject<HTMLButtonElement>;
  light?: boolean;
  lightPink?: boolean;
}

export interface InputTypes {
  type?: string;
  value?: string | number;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  error?: string;
  ref?: any;
  label?: string;
  className?: string;
  parentClassName?: string;
  leftIcon?: ReactElement | string;
  rightIcon?: ReactElement;
  onChange?: (e: any) => any;
  onFocus?: (e: any) => any;
  onBlur?: (e: any) => any;
  onKeyDown?: (e: any) => any;
  onKeyUp?: (e: any) => any;
  id?: string;
  name?: string;
  defaultValue?: string;
  dataUrl?: boolean;
  noBorders?: boolean;
  rightAddon?: ReactElement;
  autocomplete?: boolean;
  maxLength?: number;
  min?: string;
  max?: string;
  autoFocus?: boolean;
}

export interface ToggleButtonTypes {
  checked?: boolean;
  className?: string;
  onChange: (e: FormEvent) => void;
  label1?: string;
  label2?: string;
  tooltipText?: string;
}

export interface SelectTypes {
  value?: string | number | any | { label?: string; value: string };
  options: any;
  children?: any;
  onChange: (arg0: string | any) => void;
  width?: any;
  disabled?: boolean;
  manySelect?: boolean;
  className?: string;
  placeholder?: string;
  bottom?: boolean;
  icon?: ReactElement;
  showLeft?: boolean;
  showRight?: boolean;
}

export interface CheckboxTypes {
  checked: boolean;
  disabled?: boolean;
  label?: string;
  labelRight?: string;
  onChange: (arg0: boolean) => void;
  className?: string;
  hoverText?: string;
}
export interface BadgeProps {
  children: ReactElement | string | number | ReactElement[];
  buttonType?: 'background' | 'light-background' | 'outline' | 'transparent';
  messageType?:
    | 'badge-alert'
    | 'badge-success'
    | 'badge-warning'
    | 'badge-primary'
    | 'badge-secondary'
    | 'badge-limited-reached'
    | 'badge-danger'
    | 'badge-product'
    | 'badge-chatgpt';
  tool?: string;
  className?: string;
  width?: number;
  hoverText?: string;
  onClick?: () => void;
}

export interface BreadcrumbItemType {
  to: string;
  name: string | ReactElement;
  active?: boolean;
  externalLink?: boolean;
  onClick?: () => void;
}
export interface BreadcrumbComponentProps {
  items: BreadcrumbItemType[];
  scanDate?: string;
  urlName?: string;
  rightAddOn?: ReactElement | null;
  text?: string;
  editName?: string;
  setName?: (value: string) => void;
  updateNameLocally?: boolean;
  workflowId?: string;
  recipeId?: string;
  className?: string;
  noBorderLine?: boolean;
}
export interface CheckBoxFieldPropsType {
  label?: string;
  name?: string;
  handleCheckBox?: (e: SyntheticEvent<any>) => any;
  checked?: boolean;
  id?: string;
  value?: string;
  disabled?: boolean;
  className?: string;
  rectangle?: boolean;
  lightIcon?: boolean;
}

export enum StatusEnums {
  progress = 'IN PROGRESS',
  completed = 'DONE',
  error = 'FAIL',
  PAUSED = 'PAUSED',
  DRAFT = 'DRAFT',
  CREDIT_REACHED = 'Limit reached',
  PREMIUM_CREDIT_REACHED = 'Limit reached',
  STORAGE_REACHED = 'Limit reached',
  CREDIT_STORAGE_REACHED = 'Limit reached',
}
export interface IworkflowProps {
  [x: string]: any;
  name: string;
  id: number;
  // status?: 'APPROVED' | 'DECLINED' | 'PENDING';
  // execution_data: {
  //   status:
  //     | 'error'
  //     | 'completed'
  //     | 'progress'
  //     | 'PAUSED'
  //     | 'DRAFT'
  //     | 'CREDIT_REACHED'
  //     | 'PREMIUM_CREDIT_REACHED'
  //     | 'STORAGE_REACHED'
  //     | 'CREDIT_STORAGE_REACHED';
  // };
  status:
    | 'error'
    | 'completed'
    | 'progress'
    | 'PAUSED'
    | 'DRAFT'
    | 'CREDIT_REACHED'
    | 'PREMIUM_CREDIT_REACHED'
    | 'STORAGE_REACHED'
    | 'CREDIT_STORAGE_REACHED';
  steps: any;
  active: boolean;
  created_at: string;
  updated_at: string;
  is_public?: boolean;
  description: string;
  frequency: string;
  token_to_share: boolean;
  currentIteration: number;
  currentAutomation: string;
  lastActivityTime: string;
  scan_times?: {
    last_scan_time: string;
    next_scan_time: string;
  };
  storage: number;
}
export interface RoundedElementProps {
  text: string;
  active?: boolean;
  onChange: (value: string) => void;
}

export interface FieldsProps {
  lists: {
    id: number | string;
    name: string;
  }[];
  onChange: (value: number | string) => void;
  loading: boolean;
  active?: number;
  selectedList?: any;
}
export interface IPaymentItem extends IPayment {
  showPackeges: boolean;
  checked: boolean;
  onRadioChange?: () => void;
  disabled?: boolean;
  handelSelectChange: (val: any) => void;
  selectedValue: {
    title: string;
    value: number;
  };
  selectedPremium?: {
    title: string;
    value: number;
    price: number;
    newTitle?: string;
    interval?: string;
  };
  setShow?: () => void;
  premiumOptions?: any;
  setShowMonthly?: any;
  showMonthly?: any;
  theme?: string;
}
