import { FC, useEffect, useState, useContext, ReactElement, memo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { createPortal } from 'react-dom';
import moment from 'moment';
import WindowedSelect from 'react-windowed-select';
import { GET_USER_WATCH_SETTINGS_QUERY, GET_WATCH_PROPERTIES } from 'graphql/queries';

import Calendar from '../calendar';
import Select from '../../atom/select';
import Button from '../../atom/button';
import Loader from 'shared-components/atom/loader';
import { HexomaticUserContext } from 'contexts/hexomatic-user';
import { ThemeContext } from 'contexts/theme-context';
import { timeZones } from 'constants/time-zones';
import { closePopupOnEsc, getDaysInMonthForScheduling, getHexowatchToolName } from 'helpers';
import { ScheduleConfigType } from 'pages/workflows/types';
import { closeIcon, externalLinkIcon } from 'assets/svg-icons';
import './styles.scss';

interface IModalCalendarProps {
  isModalOpen: boolean;
  text?: string | ReactElement;
  actionBtnText: string;
  cancelBtnText?: string;
  onActionClick: (
    startDate?: string,
    frequency?: string,
    schedule_config?: ScheduleConfigType,
    trigger?: string,
  ) => void;
  onCancelClick?: () => void;
  onCloseModal?: () => void;
  loading?: boolean;
  startedAt?: string;
  frequency?: string;
  setSelectedSheduleOption?: any;
  schedule_config: ScheduleConfigType | null;
  isNew: boolean;
  propTrigger: any;
  isScheduled: boolean;
}

interface WatchPropertyType {
  id: number;
  name: string;
  url: string;
  tool: string;
  active: boolean;
}

export function checkDateDifference(originalDate: any, currentDate: any) {
  const originalDay = originalDate.getDate();
  const currentDay = currentDate.getDate();
  const originalMonth = originalDate.getMonth();
  const currentMonth = currentDate.getMonth();
  const originalYear = originalDate.getFullYear();
  const currentYear = currentDate.getFullYear();

  if (originalYear === currentYear && originalMonth === currentMonth) {
    if (currentDay === originalDay + 1) {
      return 1;
    } else if (currentDay === originalDay - 1) {
      return -1;
    }
  }
  return 0;
}

const ModalCalendar: FC<IModalCalendarProps> = ({
  isModalOpen,
  text,
  onActionClick,
  onCancelClick,
  actionBtnText,
  cancelBtnText,
  loading,
  onCloseModal,
  startedAt,
  frequency,
  setSelectedSheduleOption,
  schedule_config,
  isNew,
  propTrigger,
  isScheduled,
}) => {
  //------------------Context----------------------------
  const { theme } = useContext(ThemeContext);
  //---------------------State-----------------------------
  const [body] = useState(document.getElementsByTagName('body')[0]);
  const [el] = useState(document.createElement('div'));
  const [selectedTrigger, setSelectedTrigger] = useState<{ label: string; value: string } | null>(null);
  const [triggerOptions, setTriggerOptions] = useState<WatchPropertyType[]>([]);
  const [searchedText, setSearchedText] = useState('');
  const [upgradeMatic, setUpgradeMatic] = useState(false);
  const [upgradeWatch, setUpgradeWatch] = useState(false);
  const { hexomaticUser } = useContext(HexomaticUserContext);
  const { data: hexowatchData, loading: hexowatchLoading } = useQuery(GET_USER_WATCH_SETTINGS_QUERY, {
    skip: isScheduled,
    fetchPolicy: 'no-cache',
  });

  //Schedule settings
  const [selectedDays, setSelectedDays] = useState([
    { label: 'Sun', value: 7, selected: new Date().getDay() === 7 },
    { label: 'Mon', value: 1, selected: new Date().getDay() === 1 },
    { label: 'Tue', value: 2, selected: new Date().getDay() === 2 },
    { label: 'Wed', value: 3, selected: new Date().getDay() === 3 },
    { label: 'Thu', value: 4, selected: new Date().getDay() === 4 },
    { label: 'Fri', value: 5, selected: new Date().getDay() === 5 },
    { label: 'Sat', value: 6, selected: new Date().getDay() === 6 },
  ]);

  //const [startDateshow, setStartDateshow] = useState<boolean>(false);
  const [element] = useState<HTMLDivElement>(el);
  const [timeZonePlaceholder, setTimeZonePlaceholder] = useState('Time zone');
  const [startDate, setStartDate] = useState<Date | null>(
    startedAt
      ? new Date(parseInt(startedAt))
      : new Date(moment(new Date()).utcOffset(0).format('MMM DD, YYYY h:mm:ss A (UTC)')),
  );
  const [timeZoneValue, setTimeZoneValue] = useState<{ label: string; value: number }>({
    value: 0,
    label: '(UTC) Western Europe Time, London, Lisbon, Casablanca',
  });

  const getHoursWithOffset = () => {
    const date = startDate
      ? new Date(
          //@ts-ignore
          startDate - (new Date().getTimezoneOffset() + timeZoneValue.value * 60) * 60000,
        )
      : new Date();
    return new Date(moment(new Date(date)).utcOffset(0).format('MMM DD, YYYY h:mm:ss A (UTC)')).getHours();
  };

  const [options, setOptions] = useState([
    {
      value: '',
      label: 'One time',
    },
    {
      value: '',
      label: 'Every 30 min after workflow completes',
    },
    {
      value: '',
      label: 'Every 1h after workflow completes',
    },
    {
      value: '',
      label: 'Every 3h after workflow completes',
    },
    {
      value: '',
      label: 'Every 6h after workflow completes',
    },
    {
      value: '',
      label: 'Every 12h after workflow completes',
    },
    {
      value: `${new Date().getUTCMinutes()} ${getHoursWithOffset()} * * *`,
      label: 'Daily',
    },
    {
      value: `${new Date().getUTCMinutes()} ${getHoursWithOffset()} * * ${moment().isoWeekday()}`,
      label: `Weekly`,
    },
    {
      value: `${new Date().getUTCMinutes()} ${getHoursWithOffset()} ? * ${getDaysInMonthForScheduling(
        new Date(),
        true,
      )}`,
      label: `Monthly on the ${getDaysInMonthForScheduling(
        new Date(moment.utc(new Date()).format('MMM DD, YYYY h:mm:ss A (UTC)')),
      )} ${moment().format('dddd')},
      )}`,
    },
    {
      value: `${new Date().getUTCMinutes()} ${getHoursWithOffset()} ${moment().date()} ${moment().month() + 1} *`,
      label: `Annually on ${moment().format('MMM Do')}`,
    },
  ]);
  const [selectedFrequency, setSelectedFrequency] = useState<{ label: string; value: string }>({
    value: '',
    label: 'One time',
  });

  //------------------Requests----------------------------

  const { data: watchPropertiesData, loading: propertiesLoading } = useQuery(GET_WATCH_PROPERTIES, {
    skip: isScheduled,
    fetchPolicy: 'no-cache',
    variables: {
      settings: {
        active: true,
        search: searchedText,
        sortField: 'created_at',
        sortDir: 'DESC',
      },
    },
  });

  //--------------------useEffect--------------------------
  //Close Popup on Esc button click
  useEffect(() => {
    isModalOpen && onCloseModal && closePopupOnEsc(() => onCloseModal());
  }, [isModalOpen]);

  useEffect(() => {
    el && el.setAttribute('id', 'popup');
  }, [el]);

  useEffect(() => {
    if (!isModalOpen) return;
    body.appendChild(element);
    document.body.style.overflow = 'hidden';
    return () => {
      const getEl = document.getElementById('popup');
      getEl && body.removeChild(getEl);
      document.body.style.overflow = 'unset';
    };
  }, [isModalOpen]);

  useEffect(() => {
    const watchPlan =
      hexowatchData &&
      hexowatchData.UserWatchSettings &&
      hexowatchData.UserWatchSettings.get &&
      hexowatchData.UserWatchSettings.get.pricing_package;
    const maticPlan = hexomaticUser && hexomaticUser.pricing_package;

    if (watchPlan && (watchPlan === 'PRO' || watchPlan.includes('BUSINESS'))) {
      setUpgradeWatch(false);
    } else if (hexowatchData && hexowatchData.UserWatchSettings && hexowatchData.UserWatchSettings.get) {
      setUpgradeWatch(true);
    }

    if (maticPlan === 'SILVER' || maticPlan === 'GOLD') {
      setUpgradeMatic(false);
    } else if (hexomaticUser) {
      setUpgradeMatic(true);
    }
  }, [hexowatchData, hexomaticUser]);

  useEffect(() => {
    if (
      watchPropertiesData &&
      watchPropertiesData.Watch &&
      watchPropertiesData.Watch.getWatchProperties &&
      watchPropertiesData.Watch.getWatchProperties.watchProperties &&
      watchPropertiesData.Watch.getWatchProperties.watchProperties.length
    ) {
      setTriggerOptions(
        watchPropertiesData.Watch.getWatchProperties.watchProperties.map((item: any) => {
          return { label: `${getHexowatchToolName(item.tool)} | ${item.name}`, value: `${item.id}` };
        }),
      );
      const sel =
        propTrigger?.watchPropertyId &&
        watchPropertiesData.Watch.getWatchProperties.watchProperties.find(
          (item: any) => item.id === +propTrigger.watchPropertyId,
        );
      sel && setSelectedTrigger({ label: `${getHexowatchToolName(sel.tool)} | ${sel.name}`, value: `${sel.id}` });
      // sel && setIsSheduled(false);
    }
  }, [watchPropertiesData, propTrigger]);


  //Restoring
  useEffect(() => {
    if (startedAt) {
      setStartDate(new Date(parseInt(startedAt)));
    }
    if (!schedule_config) return;
    const browserTimeZoneOffsetInHours = new Date().getTimezoneOffset() / 60;

    if (schedule_config.timezone) {
      const el = JSON.parse(schedule_config.timezone);

      const newDate = el?.startDate
        ? new Date(el.startDate).setHours(
            new Date(el.startDate).getHours() + browserTimeZoneOffsetInHours + (el?.timeZoneValue?.value || 0),
          )
        : null;
      newDate && setStartDate(new Date(newDate));
      el?.timeZoneValue && setTimeZoneValue(el.timeZoneValue);
    }

    if (schedule_config.selected && frequency && frequency !== 'hexowatch') {
      setSelectedFrequency({
        label: schedule_config.selected,
        value: frequency,
      });

      setSelectedSheduleOption && setSelectedSheduleOption(schedule_config.selected);
    };
    
    if (schedule_config.weekdays && schedule_config.weekdays.length) {
      const el = JSON.parse(schedule_config.timezone);

      const newDate = el?.startDate
        ? new Date(el.startDate).setHours(
            new Date(el.startDate).getHours() + browserTimeZoneOffsetInHours + (el?.timeZoneValue?.value || 0),
          )
        : null;
      const utcAdjDate = el?.startDate
        ? new Date(el.startDate).setHours(new Date(el.startDate).getHours() + browserTimeZoneOffsetInHours)
        : null;

      const date1 = newDate ? new Date(newDate) : null; // First date
      const date2 = utcAdjDate ? new Date(utcAdjDate) : null;

      const dateOffset = date1 && date2 ? checkDateDifference(date1, date2) : 0;
      const list = [...selectedDays];

      let selWeekdays: any = schedule_config.weekdays;
      if (dateOffset === -1) {
        selWeekdays = schedule_config.weekdays.map((item: any) => (item === 7 ? 1 : item + 1));
      }
      if (dateOffset === 1) {
        selWeekdays = schedule_config.weekdays.map((item: any) => (item === 1 ? 7 : item - 1));
      }
      list.map((item: { value: number; selected: boolean }) => {
        if (selWeekdays.find((el: number) => el === item.value)) item.selected = true;
        else item.selected = false;
      });
    }

    //For create workflow page
    if (frequency && frequency !== 'hexowatch') {
      const currentFrequency = options.find(
        item =>
          item.value === frequency ||
          (item.label.includes('Monthly on') && schedule_config?.selected.includes('Monthly on')) ||
          (item.label.includes('Annually on') && schedule_config?.selected.includes('Annually on')),
      );
      currentFrequency && setSelectedFrequency(currentFrequency);
      if (!setSelectedSheduleOption) return;
      if (checkShedule(frequency)) {
        setSelectedSheduleOption(checkShedule(frequency));
        return;
      }
      if (frequency.includes('1-5')) {
        setSelectedSheduleOption('Every weekday (Monday to Friday)');
        return;
      }
      if (frequency.includes('* * *')) {
        setSelectedSheduleOption('Daily');
        return;
      }
      if (frequency[frequency.length - 1] === '*') {
        setSelectedSheduleOption('Annualy');
        return;
      }
      setSelectedSheduleOption('Weekly');
    }


  }, [frequency, startedAt, schedule_config]);


  useEffect(() => {
    if (!startDate) return;

    let weekDays = '';

    // Calculate the browser's timezone offset in hours
    const browserTimeZoneOffsetInHours = new Date().getTimezoneOffset() / 60;

    // Adjust dates for timezone and user-specified offset
    const adjustedStartDate = startDate
      ? new Date(
          new Date(startDate).setHours(
            new Date(startDate).getHours() + browserTimeZoneOffsetInHours - (timeZoneValue?.value || 0),
          ),
        )
      : null;

    const utcAdjustedDate = startDate
      ? new Date(new Date(startDate).setHours(new Date(startDate).getHours() + browserTimeZoneOffsetInHours))
      : null;

    // Calculate the date offset difference
    const dateOffset =
      adjustedStartDate && utcAdjustedDate ? checkDateDifference(adjustedStartDate, utcAdjustedDate) : 0;

    // Generate weekDays string based on selectedDays and date offset
    selectedDays.forEach((item: { selected: boolean; value: number }) => {
      if (item.selected) {
        const dayValue =
          dateOffset === 1
            ? item.value === 1
              ? 7
              : item.value - 1
            : dateOffset === -1
            ? item.value === 7
              ? 1
              : item.value + 1
            : item.value;

        weekDays = weekDays ? `${weekDays},${dayValue}` : `${dayValue}`;
      }
    });

    // Generate scheduling options based on adjustedStartDate
    const newVals = adjustedStartDate && [
      { value: '', label: 'One time' },
      { value: '*/30 * * * *', label: 'Every 30 min after workflow completes' },
      { value: `${adjustedStartDate.getUTCMinutes()} */1 * * *`, label: 'Every 1h after workflow completes' },
      { value: `${adjustedStartDate.getUTCMinutes()} */3 * * *`, label: 'Every 3h after workflow completes' },
      { value: `${adjustedStartDate.getUTCMinutes()} */6 * * *`, label: 'Every 6h after workflow completes' },
      { value: `${adjustedStartDate.getUTCMinutes()} */12 * * *`, label: 'Every 12h after workflow completes' },
      { value: `${adjustedStartDate.getUTCMinutes()} ${getHoursWithOffset()} * * *`, label: 'Daily' },
      {
        value: `${adjustedStartDate.getUTCMinutes()} ${getHoursWithOffset()} * * ${weekDays}`,
        label: 'Weekly',
      },
      {
        value: `${adjustedStartDate.getUTCMinutes()} ${getHoursWithOffset()} ? * ${getDaysInMonthForScheduling(
          adjustedStartDate,
          true,
        )}`,
        label: `Monthly on the ${getDaysInMonthForScheduling(adjustedStartDate)} ${moment(adjustedStartDate).format(
          'dddd',
        )}`,
      },
      {
        value: `${adjustedStartDate.getUTCMinutes()} ${getHoursWithOffset()} ${moment(adjustedStartDate).date()} ${
          moment(adjustedStartDate).month() + 1
        } *`,
        label: `Annually on ${moment(adjustedStartDate).format('MMM Do')}`,
      },
    ];

    if (newVals) {
      setOptions(newVals);

      // Automatically set selected frequency based on newVals
      const valStart = selectedFrequency.label.split(' ')[0] + (selectedFrequency.label.split(' ')[1] || '');
      const newSel = newVals.find(item => item.label.includes(valStart));
      if (newSel) setSelectedFrequency(newSel);
    }
  }, [startDate, selectedDays, timeZoneValue]);

  useEffect(() => {
    if (startDate) {
      const index = startDate.toString().indexOf('G');
      setTimeZonePlaceholder(startDate.toString().slice(index));
    }
  }, [startDate]);

  //--------------------Functions--------------------------
  const checkShedule = (val: string) => {
    return val.includes('*/30')
      ? 'Every 30 min'
      : val.includes('*/1 ')
      ? 'Every 1h'
      : val.includes('*/6')
      ? 'Every 3h'
      : val.includes('*/6')
      ? 'Every 6h'
      : val.includes('*/12')
      ? 'Every 12h'
      : null;
  };

  const handleChangeTimeZone = (val: { label: string; value: number }) => {
    setTimeZoneValue(val);
  };

  const handleChangeDate = (date: Date) => {
    setStartDate(date);
  };

  const handleChangeFrequency = (val: any) => {
    setSelectedFrequency(val);
  };

  const selectDays = (index: number) => {
    const days = [...selectedDays];
    days[index].selected = !selectedDays[index].selected;
    setSelectedDays(days);
  };

  const handleSubmit = () => {
    if (selectedTrigger) {
      onActionClick(undefined, undefined, undefined, selectedTrigger.value);
      return;
    }

    const browserTimeZoneOffsetInHours = new Date().getTimezoneOffset() / 60;


    const finDate = startDate
      ? new Date(
          //@ts-ignore
          startDate - (new Date().getTimezoneOffset() + timeZoneValue.value * 60) * 60000,
        ).toISOString()
      : new Date().toISOString();
      
    const adjustedStartDate = startDate
    ? new Date(
        new Date(startDate).setHours(
          new Date(startDate).getHours() + browserTimeZoneOffsetInHours - (timeZoneValue?.value || 0),
        ),
      )
    : null;

  const utcAdjustedDate = startDate
    ? new Date(new Date(startDate).setHours(new Date(startDate).getHours() + browserTimeZoneOffsetInHours))
    : null;

  // Calculate the date offset difference
  const dateOffset =
    adjustedStartDate && utcAdjustedDate ? checkDateDifference(adjustedStartDate, utcAdjustedDate) : 0;
    
    let weekdays: number[] = [];

    selectedFrequency &&
      selectedFrequency.label.includes('Weekly') &&
      selectedDays.map((item: { selected: boolean; value: any }) => {
        if (item.selected)
          weekdays.push(
            dateOffset === -1
              ? item.value === 7
                ? 1
                : item.value + 1
              : dateOffset === 1
              ? item.value === 1
                ? 7
                : item.value - 1
              : item.value,
          );
      });

      onActionClick(finDate, selectedFrequency?.value ? selectedFrequency.value : '', {
      timezone: JSON.stringify({ timeZoneValue, startDate: finDate }),
      selected: selectedFrequency.label,
      weekdays: weekdays,
    });
  };

  //-----------------------------------------------
  return isModalOpen
    ? createPortal(
        <>
          <div className={`overlay theme-${theme}`}>
            <div className={`modal-dialog--default custom-alert-block-calendar theme-${theme}`}>
              <div className="modal-content">
                <div className="modal__body">
                  {onCloseModal && (
                    <div className="panel__btns">
                      <button className="panel__btn" onClick={onCloseModal} type="button">
                        {closeIcon}
                      </button>
                    </div>
                  )}
                  <p className={`text-center modal-calendar-title`}>{text}</p>
                  {/* <div className="tabs-conatiner">
                    <p
                      className={`text-center modal-calendar-title ${isScheduled ? 'active' : ''}`}
                      onClick={() => setIsSheduled(true)}
                    >
                      {text}
                    </p>
                    <p
                      className={`text-center modal-calendar-tab ${!isScheduled ? 'active' : ''}`}
                      onClick={() => setIsSheduled(false)}
                    >
                      Trigger
                    </p>
                  </div> */}
                  {isScheduled ? (
                    <>
                      <div className="time-zone-wrapper el-wrapper">
                        <div className="time-zone">
                          <label>Time zone</label>
                          <Select
                            options={timeZones}
                            value={timeZoneValue}
                            onChange={handleChangeTimeZone}
                            placeholder={timeZonePlaceholder}
                            fullWidth
                          />
                        </div>
                      </div>
                      <div className="el-wrapper">
                        <label>Start date</label>
                        <Calendar
                          showTimeSelect
                          handleChange={handleChangeDate}
                          startPropDate={startDate}
                          selected={startDate}
                          endPropDate={new Date()}
                          maxDate={new Date()}
                          minDate={moment().toDate()}
                          dateFormat="eeee, MMM dd, h:mm aa"
                          placeholderText="select start date"
                          workflowModal
                          dontClose
                          minTime={null}
                          maxTime={null}
                        />
                      </div>
                      <div className="el-wrapper">
                        <label>Frequency</label>
                        <Select
                          options={options}
                          value={selectedFrequency}
                          onChange={handleChangeFrequency}
                          fullwidth
                          placeholder="One time"
                          textDefault
                        />
                      </div>

                      {selectedFrequency.label.includes('Weekly') && (
                        <div className="el-wrapper">
                          <label>Days of the week:</label>
                          <div className="d-flex days-wrapper">
                            {selectedDays.map((item: { label: string; selected: boolean }, index: number) => (
                              <div
                                key={item.label}
                                onClick={() => selectDays(index)}
                                className={`day ${item.selected ? 'active' : ''}`}
                              >
                                {item.label}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {hexowatchLoading || propertiesLoading ? (
                        <div>
                          <Loader light={theme === 'light'} />
                        </div>
                      ) : (
                        !hexowatchLoading &&
                        !propertiesLoading && (
                          <div>
                            {!upgradeMatic && !upgradeWatch && triggerOptions && triggerOptions.length > 0 ? (
                              <div className="el-wrapper hexowatch-wrapper">
                                <label>Hexowatch active monitors</label>
                                <WindowedSelect
                                  options={triggerOptions}
                                  onChange={(selectedOption: any) => {
                                    setSelectedTrigger(selectedOption);
                                  }}
                                  value={selectedTrigger}
                                  className="hexowatch-trigger-select"
                                />
                              </div>
                            ) : (
                              <div className="el-wrapper text-center mt-4">
                                <p className="text-center">
                                  {upgradeMatic || upgradeWatch
                                    ? 'This feature requires Hexomatic Silver or higher plan and Hexowatch Pro or higher plan'
                                    : 'You have no active monitors in Hexowatch.'}
                                </p>
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </>
                  )}
                </div>
                {(!isScheduled && triggerOptions && triggerOptions.length > 0 && !upgradeMatic && !upgradeWatch) ||
                isScheduled ? (
                  <div className={`modal__footer ${cancelBtnText ? 'cancel-is' : ''}`}>
                    {cancelBtnText && (
                      <div className="cancel-btn-wrapper">
                        <Button name={cancelBtnText} outline onClick={onCancelClick} />
                      </div>
                    )}
                    {actionBtnText && (
                      <div>
                        <Button
                          name={actionBtnText}
                          onClick={handleSubmit}
                          loading={loading}
                          disabled={
                            !selectedTrigger &&
                            selectedFrequency.label.includes('Weekly') &&
                            selectedDays.filter((item: { selected: boolean }) => item.selected).length < 1
                          }
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  !hexowatchLoading &&
                  !propertiesLoading && (
                    <>
                      {upgradeMatic || upgradeWatch ? (
                        <div className={`modal__footer justify-content-center`}>
                          <Button
                            name="Upgrade"
                            onClick={() =>
                              window.open(
                                upgradeMatic ? 'https://hexomatic.com/pricing/' : 'https://hexowatch.com/pricing/',
                                '_blank',
                              )
                            }
                            className="mx-0"
                          />
                        </div>
                      ) : (
                        <div className={`modal__footer justify-content-center`}>
                          <Button
                            name="Go to Hexowatch monitors"
                            onClick={() => window.open('https://dash.hexowatch.com/hexowatch/monitored-urls', '_blank')}
                            className="mx-0"
                            rightIcon={externalLinkIcon}
                          />
                        </div>
                      )}
                    </>
                  )
                )}
              </div>
            </div>
          </div>
        </>,
        element,
      )
    : null;
};

export default memo(ModalCalendar);
