import { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { checkedIcon } from '../../../assets/svg-icons';
import './styles.scss';

const PaymentSuccess: FC<{ setShowPopup: (val: boolean) => void, paypal?: boolean, trial?: boolean }> = ({ setShowPopup,  paypal, trial }) => {
  const history = useHistory();
  const location = useLocation();
  const handleClick = () => {
    setShowPopup(false);
    if (location.pathname !== '/dashboard') {
      history.push('/dashboard');
    }
  };
  return (
    <>
      <div className="mx-auto" style={{ width: 'fit-content', marginTop: '4vh' }}>
        <div className="d-flex align-items-center justify-content-center success-icon">{checkedIcon}</div>
      </div>
      <h2 className="text-center mt-5" style={{ fontSize: '18px' }}>
        {trial
          ? 'Your trial has been successfully activated'
          : paypal
          ? 'Thank you for your order, your payment is being processed (can take a few minutes to show in your account)'
          : 'Congratulations! Payment successfully completed'}
      </h2>
      <div className="d-flex align-items-center justify-content-center mt-5">
        <button onClick={handleClick} className="btn go-dashboard border-0">
          {location.pathname === '/dashboard' ? 'Close' : 'Go to Dashboard'}
        </button>
      </div>
    </>
  );
};

export default PaymentSuccess;
