import { FC, useState, useEffect, useContext, useLayoutEffect, useCallback, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';

import { ThemeContext } from 'contexts/theme-context';
import Breadcrumb from 'shared-components/atom/breadcrumb';

import {
  GET_AUTOMATIONS,
  GET_AUTOMATION_CATEGORIES,
  GET_AUTOMATION_TYPES,
  GET_AUTOMATION_TYPES_CATEGORIES,
} from 'graphql/queries';
import { DELETE_FAVORITE, MAKE_FAVORITE } from 'graphql/mutations';

import Fields from 'shared-components/molecule/filter-fields';
import ToastLoader from 'shared-components/molecule/toast-loader';
import AutomationCards from 'shared-components/molecule/automation-card';
import SearchContainer from 'shared-components/molecule/search-container/updated';
import NothingToShow from 'shared-components/atom/nothing-to-show';
import Loader from 'shared-components/atom/loader';

import { loopIcon } from 'assets/svg-icons';
import deepLight from 'assets/images/deepL-light.svg';

import './automation-details/styles.scss';
export interface IAppProps {
  match: {
    params: {
      categoryID?: string;
      typeID?: string;
    };
  };
}

const mockArray = [...Array(25)].map((_, i) => i + 1);

const Automations: FC<IAppProps> = ({
  match: {
    params: { categoryID, typeID },
  },
}) => {
  const [markAsStarred] = useMutation(MAKE_FAVORITE, {
    fetchPolicy: 'no-cache',
  });
  const [deleteFromStarred] = useMutation(DELETE_FAVORITE, {
    fetchPolicy: 'no-cache',
  });

  const history = useHistory();
  const { pathname, state }: { pathname: string; state: { searchText: string; selectedOption: any } } = useLocation();

  const { theme } = useContext(ThemeContext);
  const [selectedOption, setSelectedOption] = useState<{ name: string; id: number }>(
    state?.selectedOption || {
      name: 'All',
      id: -1,
    },
  );

  const [selectedTypeOption, setSelectedTypeOption] = useState<{ label: string; value: number | string | null }>({
    label: 'All Types',
    value: null,
  });

  const automationSrc = pathname.includes('automations-without-source');

  const [automationsList, setAutomationsList] = useState([] as any);
  const [automationsCategories, setAutomationsCategories] = useState([] as any);
  const [automationsFilterCategories, setAutomationsFilterCategories] = useState([] as any);
  const [automationsTypes, setAutomationsTypes] = useState([] as any);
  const [searchText, setSearchText] = useState(state?.searchText || '');
  const [startSearch, setStartSearch] = useState(setTimeout(() => {}));
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [toasterInfo, setToasterInfo] = useState({
    show: false,
    errorMessage: '',
    loadingMessage: '',
    successMessage: '',
  });
  const [starred, setStarred] = useState(false);
  const handleToastShow = (show: boolean, errorMessage: string, loadingMessage: string, successMessage: string) => {
    setToasterInfo({ show: show, errorMessage, loadingMessage, successMessage });
  };

  const {
    data: automations,
    loading,
    refetch,
  } = useQuery(GET_AUTOMATIONS, {
    fetchPolicy: 'no-cache',
    variables: {
      settings: {
        filter: {
          active: true,
          page: 1,
          limit: 300,
          date_from: null,
          date_to: null,
          sort_order: null,
          sort_by: null,
          hasSource: automationSrc || null,
          automation_kind:
            selectedOption.id > -1 || selectedTypeOption.value
              ? {
                  automation_category_id: selectedOption.id > -1 ? selectedOption.id : undefined,
                  automation_type_id: selectedTypeOption.value || undefined,
                }
              : undefined,
          search: {
            search_field: null,
            search_value: searchText && searchText.length >= 1 ? searchText : null,
          },
          isFavorite: starred,
        },
      },
    },
  });

  const {
    data: automationCategories,
    loading: categoriesLoading,
    refetch: categoriesRefetch,
  } = useQuery(GET_AUTOMATION_CATEGORIES, {
    variables: {
      settings: {
        filter: {
          search: {
            search_value: null,
          },
        },
      },
    },
  });

  const { data: automationTypes, refetch: typesRefetch } = useQuery(GET_AUTOMATION_TYPES, {
    variables: {
      settings: {
        filter: {
          search: {
            search_value: null,
          },
        },
      },
    },
  });

  const [getTypesOrCategories, { data: typesOrCategories }] = useLazyQuery(GET_AUTOMATION_TYPES_CATEGORIES, {
    variables: {
      settings: {
        automation_type_id: selectedTypeOption.value,
      },
    },
  });

  useLayoutEffect(() => {
    if (typesOrCategories?.HexomaticAutomation?.getAutomationsCategoriesOrTypesById?.categories) {
      setAutomationsFilterCategories(
        typesOrCategories?.HexomaticAutomation?.getAutomationsCategoriesOrTypesById?.categories,
      );
      if (selectedOption.id) {
        if (!!typesOrCategories?.HexomaticAutomation?.getAutomationsCategoriesOrTypesById?.categories.length) {
          if (
            !typesOrCategories?.HexomaticAutomation?.getAutomationsCategoriesOrTypesById?.categories.filter(
              (item: { id: number }) => item.id == selectedOption.id,
            )[0]
          ) {
            history.push({
              pathname: `/automations/types/${selectedTypeOption.value}`,
              state: {
                searchText,
                selectedOption,
              },
            });
          }
          return;
        }
        history.push({
          pathname: `/automations/types/${selectedTypeOption.value}`,
          state: {
            searchText,
            selectedOption,
          },
        });
      }
    }
  }, [typesOrCategories]);

  useEffect(() => {
    if (automationTypes?.HexomaticAutomation?.getAutomationTypes?.types) {
      setAutomationsTypes(
        automationTypes.HexomaticAutomation.getAutomationTypes.types.filter((type: any) => type.id !== 6),
      );
      if (
        typeID &&
        automationTypes?.HexomaticAutomation?.getAutomationTypes?.types.filter(
          (item: { id: number }) => item.id == +typeID,
        )[0]
      ) {
        setSelectedTypeOption({
          label:
            automationTypes.HexomaticAutomation.getAutomationTypes.types.filter((type: any) => type.id == typeID)[0] &&
            automationTypes.HexomaticAutomation.getAutomationTypes.types.filter((type: any) => type.id == typeID)[0]
              .name,
          value: +typeID,
        });
      }
    }
  }, [automationTypes]);

  useEffect(() => {
    if (automations?.HexomaticAutomation?.getAutomations?.automations) {
      const chatGPT = automations.HexomaticAutomation.getAutomations.automations.find((item: any) => item.id === 143);
      const chatGPT16k = automations.HexomaticAutomation.getAutomations.automations.find(
        (item: any) => item.id === 145,
      );
      const chatGPT4 = automations.HexomaticAutomation.getAutomations.automations.find((item: any) => item.id === 146);
      const googleBard = automations.HexomaticAutomation.getAutomations.automations.find((item: any) => item.id === 147);
      const googleGemini = automations.HexomaticAutomation.getAutomations.automations.find((item: any) => item.id === 149);
      const chatGPTVision = automations.HexomaticAutomation.getAutomations.automations.find((item: any) => item.id === 150);
      const youtubeSearch = automations.HexomaticAutomation.getAutomations.automations.find((item: any) => item.id === 152);
      const youtubeTranscript = automations.HexomaticAutomation.getAutomations.automations.find((item: any) => item.id === 153);
      const newList = youtubeSearch || youtubeTranscript || chatGPT || chatGPT16k || chatGPT4 || googleBard || googleGemini || chatGPTVision
        ? [
          youtubeSearch, youtubeTranscript, chatGPTVision, googleGemini, googleBard, chatGPT4, chatGPT16k, chatGPT,
            ...automations.HexomaticAutomation.getAutomations.automations.filter((item: any) => item.id !== 143 && item.id !== 145 && item.id !== 146 && item.id !== 147 && item.id !== 149 && item.id !== 150 && item.id !== 152 && item.id !== 153),
          ]
        : automations.HexomaticAutomation.getAutomations.automations;
      setAutomationsList(newList);
    }
  }, [automations]);

  useEffect(() => {
    if (automationCategories?.HexomaticAutomation?.getAutomationCategories?.categories) {
      const sorted = automationCategories.HexomaticAutomation.getAutomationCategories.categories.sort(
        (a: any, b: any) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        },
      );
      setAutomationsCategories(sorted);
      if (
        categoryID &&
        automationCategories?.HexomaticAutomation?.getAutomationCategories?.categories.filter(
          (item: { id: number }) => item.id == +categoryID,
        )[0]
      ) {
        setSelectedOption({
          name:
            automationCategories.HexomaticAutomation.getAutomationCategories.categories.filter(
              (category: any) => category.id == categoryID,
            )[0] &&
            automationCategories.HexomaticAutomation.getAutomationCategories.categories.filter(
              (category: any) => category.id == categoryID,
            )[0].name,
          id: +categoryID,
        });
      }
    }
  }, [automationCategories]);

  useEffect(() => {
    refetch();
  }, [selectedOption, selectedTypeOption, searchText]);

  useEffect(() => {
    selectedTypeOption.value && getTypesOrCategories();
  }, [selectedTypeOption]);

  useEffect(() => {
    categoriesRefetch();
    typesRefetch();
  }, []);

  useEffect(() => {
    const sidebarOpen = document.getElementById('nav-bar');
    sidebarOpen && setSidebarOpen(sidebarOpen?.offsetWidth > 100);
  }, []);

  const optionArr = (arr: [], initialLabel: string) => {
    return [
      { label: initialLabel, value: null },
      ...arr.map((obj: { name: string; id: number | null | string }) => {
        return {
          label: obj.name,
          value: obj.id,
        };
      }),
    ];
  };

  const changeType = (option: { label: string; value: number | string | null }) => {
    if (option.label != selectedTypeOption.label) {
      setSelectedTypeOption(option);
      if (selectedOption.id) {
        option.value
          ? history.push({
              pathname: `/automations/types/${option.value}/categories/${selectedOption.id}`,
              state: { searchText, selectedOption },
            })
          : history.push({
              pathname: `/automations/categories/${selectedOption.id}`,
              state: { searchText, selectedOption },
            });
        return;
      }
      option.value
        ? history.push({
            pathname: `/automations/types/${option.value}`,
            state: { searchText, selectedOption },
          })
        : history.push({
            pathname: `/automations`,
            state: { searchText, selectedOption },
          });
    }
  };

  const changeCategory = (value: number) => {
    const obj = automationsCategories.find((item: any) => item.id == value);
    setSelectedOption(obj || { label: 'All', id: -1 });
    if (value != selectedOption.id && value > -1) {
      if (selectedTypeOption.value) {
        value
          ? history.push({
              pathname: `/automations/types/${selectedTypeOption.value}/categories/${value}`,
              state: { searchText, selectedOption: obj },
            })
          : history.push({
              pathname: `/automations/types/${selectedTypeOption.value}`,
              state: { searchText, selectedOption: obj },
            });
        return;
      }
      value
        ? history.push({
            pathname: `/automations/categories/${value}`,
            state: { searchText, selectedOption: obj },
          })
        : history.push({
            pathname: `/automations`,
            state: { searchText, selectedOption: obj },
          });
    } else {
      if (selectedTypeOption.value) {
        history.push({
          pathname: `/automations/types/${selectedTypeOption.value}`,
          state: { searchText, selectedOption: obj },
        });
      } else {
        history.push({
          pathname: `/automations`,
          state: { searchText, selectedOption: obj },
        });
      }
    }
  };

  const handelKeyUp = useCallback(
    (e: any) => {
      setStartSearch(setTimeout(() => e.target.value !== searchText && setSearchText(e.target.value), 500));
    },
    [searchText],
  );

  const handelKeyDown = useCallback(() => {
    clearTimeout(startSearch);
  }, [startSearch, clearTimeout]);

  const handleMarkAsStarred = async (id: number) => {
    handleToastShow(true, '', 'Adding into starred automations list...', '');
    const data = await markAsStarred({
      variables: {
        settings: {
          type: 'AUTOMATION',
          favorite_item_ids: [id],
        },
      },
    });
    const { error } = data?.data?.FavoriteOpts?.addToFavorites;
    if (error) {
      handleToastShow(true, 'Something went wrong. Please try again.', '', '');
    } else {
      refetch();
      handleToastShow(true, '', '', 'Successfully added into starred automations list.');
    }
    changeError();
  };
  const handleRemoveFromStarred = async (id: number) => {
    handleToastShow(true, '', 'Removing from starred automations list...', '');
    const data = await deleteFromStarred({
      variables: {
        type: 'AUTOMATION',
        favorite_item_ids: [id],
      },
    });
    const { error } = data?.data?.FavoriteOpts?.deleteFromFavorites;
    if (error) {
      handleToastShow(true, 'Something went wrong. Please try again.', '', '');
    } else {
      refetch();
      handleToastShow(true, '', '', 'Successfully removed from starred automations list.');
    }
    changeError();
  };

  const changeError = () => {
    setTimeout(() => {
      handleToastShow(false, '', '', '');
    }, 5000);
  };

  const list: any = useMemo(() => {
    return [
      {
        type: 'select',
        className: `px-2 py-1 col-md-6 ${automationSrc ? 'd-none' : ''}`,
        value: selectedTypeOption,
        options: optionArr(automationsTypes, 'All Types'),
        onChange: changeType,
      },
      // {
      //   type: 'select',
      //   className: `px-2 py-1 col-xl-3 col-lg-4 col-md-6 ${automationSrc ? 'd-none' : ''}`,
      //   value: selectedOption,
      //   options:
      //     automationsCategories &&
      //     optionArr(selectedTypeOption.value ? automationsFilterCategories : automationsCategories, 'All Categories'),
      //   onChange: changeCategory,
      // },
      {
        type: 'input',
        className: 'px-2 py-1 col-md-6',
        value: searchText,
        onChange: (e: any) => {
          setSearchText(e.target.value);
          if (state?.searchText) {
            state.searchText = e.target.value;
          }
        },
        placeholder: 'Search...',
        onKeyUp: handelKeyUp,
        onKeyDown: handelKeyDown,
        icon: loopIcon,
      },
    ];
  }, [
    automationSrc,
    selectedTypeOption,
    automationsTypes,
    optionArr,
    changeType,
    selectedOption,
    automationsCategories,
    automationsFilterCategories,
    automationsCategories,
    handelKeyUp,
    handelKeyDown,
  ]);

  useEffect(() => {
    setTimeout(() => {
      const el = document.getElementById('sticky-search-container');
      const elPadding = document.getElementById('sticky-search-container-padding');
      const header = document.getElementById('header');
      el && header?.offsetHeight && (el.style.top = `${header?.offsetHeight}px`);
      el && el.offsetHeight && elPadding && (elPadding.style.paddingTop = `${el.offsetHeight + 5}px`);
    }, 1000);
    const el = document.getElementById('sticky-search-container');
    const rSidebarShow = document.getElementsByClassName('r-show-sidebar');
    const current = (el && el.className) || '';
    el &&
      (rSidebarShow.length
        ? (el.className = current + ' r-sidebar-open')
        : (el.className = current.replace(/r-sidebar-open/g, '')));
  }, []);

  return (
    <div className="automation-main-wrapper">
      <div className={`sticky-search-container ${sidebarOpen ? 'sidebar-open' : ''}`} id="sticky-search-container">
        <div className="content-wrapper">
          <div className="d-lg-flex mt-2">
            <h4 className="my-auto f_size_16 f_w_500" style={{ color: '#303A52' }}>
              {automationSrc ? (
                <Breadcrumb
                  items={[
                    { to: `/workflows`, name: 'Workflows', active: false },
                    {
                      to: `/workflows/create-starter`,
                      name: 'How would you like to start your workflow?',
                      active: false,
                    },
                    { to: `/automations-without-source`, name: 'Automations without source required', active: true },
                  ]}
                  className="workflow-create-breadcrumbs"
                  noBorderLine
                />
              ) : (
                <h4 className="page-title no-transform">
                  {automationSrc ? 'Automations without source required' : 'Automations'}
                </h4>
              )}
            </h4>
            <div className="ml-lg-auto">
              <SearchContainer
                list={list}
                showStarIcon
                starIconClick={() => setStarred(!starred)}
                starred={starred}
                lgSelect
              />
            </div>
          </div>

          <Fields
            loading={false}
            lists={[{ name: 'All', id: -1 }, ...automationsCategories]}
            selectedList={[selectedOption.id]}
            onChange={val => typeof val === 'number' && changeCategory(val)}
          />
        </div>
      </div>

      <div className="sticky-search-container-padding" id="sticky-search-container-padding">
        {automationsList && automationsList.length > 0 ? (
          <Row style={{ margin: '0 -5px' }}>
            {loading ? (
              <Col md={12} style={{ marginTop: '200px' }}>
                <Loader light={theme === 'light'} />
              </Col>
            ) : (
              <>
                {automationsList.map(
                  (item: any) =>
                    item && (
                      <Col key={item.id} md={6} lg={4} className="p-1">
                        <AutomationCards
                          id={item.id}
                          title={item.name}
                          description={item.short_description}
                          icon={item.id === 34 && theme === 'dark' ? deepLight : item.icon_url}
                          isNew={item.is_new}
                          rate={4.1}
                          active={true}
                          credit_price={item.credit_price}
                          premium_credit_price={item.premium_credit_price}
                          automationType={item.type && item.type.name}
                          className="m-auto"
                          automationSrc={automationSrc}
                          isFavorite={item.isFavorite}
                          handleMarkAsStarred={handleMarkAsStarred}
                          handleRemoveFromStarred={handleRemoveFromStarred}
                        />
                      </Col>
                    ),
                )}
              </>
            )}
          </Row>
        ) : loading ? (
          <Row style={{ margin: '0 -5px' }}>
            {mockArray.map((el: number) => (
              <Col key={el} md={6} lg={4} className="p-1">
                <div className="pulse">
                  <div className="line-graph"></div>
                </div>
              </Col>
            ))}
          </Row>
        ) : (
          <Row>
            <Col md={12} style={{ marginTop: '100px' }}>
              <NothingToShow />
            </Col>
          </Row>
        )}
      </div>
      {toasterInfo.show && (
        <ToastLoader
          errorMessage={toasterInfo.errorMessage}
          loadingMessage={toasterInfo.loadingMessage}
          successMessage={toasterInfo.successMessage}
        />
      )}
    </div>
  );
};

export default Automations;
