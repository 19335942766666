import { FC, useEffect, useState, memo } from 'react';
import { Col, Row } from 'react-bootstrap';
import WindowedSelect from 'react-windowed-select';

import { sentimentLanguageOption } from '../../constants';
import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';

import Input from 'shared-components/atom/input';
import Select from 'shared-components/atom/select';
import Alert from 'shared-components/atom/alert-message';

import './styles.scss';

type CommonOptionType = { label: string; value: string };

const DocumentTypeOptions: CommonOptionType[] = [
  { label: 'URL', value: 'HTML' },
  { label: 'Text', value: 'PLAIN_TEXT' },
];

const output: string[] = ['_document_sentiment', '_sentence_text_content', '_sentence_text_content_sentiment'];

const SentimentAnalysis: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const {
    liftingSettings,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const [docType, setDocType] = useState(DocumentTypeOptions[0]);
  const [languageText, setLanguageText] = useState({
    label: 'English',
    value: 'en',
  });

  const [fieldList, setFieldList] = useState<any>([]);
  const [selectedSource, setSelectedSource] = useState<any>(null);

  const zeroIndexOutput: string[] = automationIndex === 0 ? ['_sentiment_source'] : [];

  const [searchKeyword, setSearchKeyword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [clicked, setClicked] = useState(false);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const checkFields = (): boolean => {
    if (errorMessage || !docType || !languageText) {
      return false;
    }
    if (fieldList?.length === 0 ? !searchKeyword : Array.isArray(selectedSource) ? !selectedSource?.length : !selectedSource) return false;
    return true;
  };

  useEffect(() => {
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const { keys } = dynamic_settings;
      const { settings } = keys[0];
      const inputValue = settings && keys[0].inputValue;
      const language_code = settings ? settings.language : keys[0].language;
      const type = settings ? settings.type : keys[0].type;

      const foundSelectedLangCode = sentimentLanguageOption.find(item => item.value === language_code);
      const foundDocumentType = DocumentTypeOptions.find(item => item.value === type);
      foundSelectedLangCode && setLanguageText(foundSelectedLangCode);
      foundDocumentType && setDocType(foundDocumentType);
      inputValue && setSearchKeyword(inputValue);
    }
  }, []);
  //recipe
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();

    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const key = dynamic_settings.keys[0].key;
      restoreSelectedSource({ key: key, fieldList: options, setSelectedSource: setSelectedSource });
    }
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...zeroIndexOutput, ...output],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);

  useEffect(() => {
    if (!clicked) return;
    if (!docType || !languageText) {
      setCompletedFields({ index: automationIndex, checked: false });
    } else {
      setCompletedFields({ index: automationIndex, checked: checkFields() });
    }

    const localSettings = {
      keys: [
        {
          inputValue: fieldList.length === 0 && automationIndex === 0 ? searchKeyword.trim() : null,
          key: fieldList.length && selectedSource ? selectedSource.value : null,
          settings: {
            type: docType ? docType.value : null,
            language: languageText ? languageText.value : null,
          },
        },
      ],
    };
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: localSettings,
      outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...zeroIndexOutput, ...output],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [searchKeyword, errorMessage, selectedSource, docType, languageText, automationIndex]);

  const selectDocType = (selectedItem: CommonOptionType): void => {
    setDocType(selectedItem);
    !clicked && setClicked(true);
    setIsDirty();
  };

  return (
    <div className="sentiment-analysis">
      <Row>
        {fieldList.length > 0 || automationIndex > 0 ? (
          <Col lg={6} className="mb-4">
            <span className="label secondary-color">Source</span>
            <Select
              options={fieldList}
              value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
              placeholder={'Select source'}
              onChange={(selectedItem: CommonOptionType) => {
                setSelectedSource(selectedItem);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
        ) : (
          <Col lg={6} className="mb-4">
            <span className="label secondary-color">Source</span>
            <Input
              value={searchKeyword}
              onChange={e => {
                setSearchKeyword(e.target.value);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
        )}
        <Col lg={3} className="mb-4">
          <span className="label secondary-color">Source type</span>
          <Select
            options={DocumentTypeOptions}
            value={docType}
            placeholder={'Select column'}
            textDefault
            onChange={selectDocType}
          ></Select>
        </Col>
        <Col lg={3} className="mb-4">
          <span className="label secondary-color">Language</span>
          <WindowedSelect
            options={sentimentLanguageOption}
            onChange={(selectedOption: CommonOptionType) => {
              if (selectedOption && languageText && selectedOption.value === languageText.value) return;
              !clicked && setClicked(true);
              setLanguageText(selectedOption);
              setIsDirty();
            }}
            value={languageText}
          />
        </Col>
      </Row>
      {errorMessage && (
        <Row className="mb-4">
          <Col md={12}>
            <Alert type="error" className="create-workflow">
              {errorMessage}
            </Alert>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default memo(SentimentAnalysis);
