import { useLazyQuery } from '@apollo/react-hooks';
import { CHANGE_INTEGRATION_NAME } from 'graphql/queries';
import { FC, useEffect, useState } from 'react';
import Button from 'shared-components/atom/button';
import Input from 'shared-components/atom/input';
interface IProps {
  name: string;
  integrationId: number;
  setIntegration: (val: null) => void;
  refetch: any;
}

const EditName: FC<IProps> = ({ name, integrationId, setIntegration, refetch }) => {
  const [integrationName, setIntegrationName] = useState('');
  const [error, setError] = useState('');
  const [changeName, { data, loading }] = useLazyQuery(CHANGE_INTEGRATION_NAME, {
    variables: { integrationId: integrationId, name: integrationName },
  });

  useEffect(() => {
    if (data?.HexomaticIntegrations?.updateIntegrationName) {
      if (!data.HexomaticIntegrations.updateIntegrationName.error) {
        setIntegration(null);
        refetch();
      } else {
        setError(data.HexomaticIntegrations.updateIntegrationName.error);
        setTimeout(() => setError(''), 5000);
      }
    }
  }, [data]);
  const handleChangeInput = (e: any) => {
    setIntegrationName(e.target.value);
    setError('');
  };
  const handleClick = async () => {
    if (!integrationName || integrationName === name) {
      setIntegration(null);
      return;
    }

    await changeName();
  };
  const handleEnterKeyPress = (e: any) => {
    if (e.key === 'Enter') handleClick();
  };

  return (
    <div className="position-relative edit-integration-name-wrap">
      <Input
        maxLength={2000}
        value={integrationName}
        onChange={handleChangeInput}
        error={error}
        onKeyDown={handleEnterKeyPress}
        placeholder="Enter integration name"
      />
      <Button name="Save" onClick={handleClick} loading={loading} className="position-absolute edit-name-btn" />
    </div>
  );
};

export default EditName;
