import { FC, ReactElement } from 'react';
import { Col, Row } from 'react-bootstrap';

import CustomMultiSelect from '../multiselect';
import Select from '../../atom/select';
import Input from '../../atom/input';
import Button from '../../atom/button';

import { plusIcon, dotsmenuIcon, listMenuIcon } from 'assets/svg-icons';
import './styles.scss';

interface SearchContainerProps {
  typeSelector?: boolean;
  options?: any;
  selectButtonOptions?: any;
  selectedValue: { label: string; value: string | number | null | undefined };
  handleSelectClick: (option: {
    label: string;
    value: string | number | null;
    is_public: boolean;
    is_scheduled: boolean;
  }) => void;
  inputPlaceholder: string;
  handleSearch: (e: any) => void;
  handelKeyUp?: (e: any) => void;
  handelKeyDown?: (e: any) => void;
  buttonName?: string;
  handleButtonClick?: (data?: any) => void;
  typeOptions?: any;
  selectedTypeValue?: { label: string; value: string | number | null };
  handleTypeSelectClick?: (option: { label: string; value: string | number | null }) => void;
  setViewMode?: (value: 'cardView' | 'listView') => void;
  showViewMode?: boolean;
  viewMode?: 'cardView' | 'listView';
  inputValue?: string;
  deleteIcon?: ReactElement;
  children?: any;
  multiSelect?: boolean;
  selectedSource?: Option[];
  multiSelectOptions?: Option[];
  handleChangeMultiSelect?: (sel: any) => void;
  handleTagChangeMultiSelect?: (arg: any) => void;

  multiSelectCategories?: boolean;
  selectedCategoriesSource?: Option[];
  multiSelectOptionsCategories?: Option[];
  handleChangeMultiSelectCategories?: (sel: any) => void;

  isPublicWorkflow?: boolean;
  tagOptions?: any;
  selectedTag?: any;
  title?: string;
}
interface Option {
  value: any;
  label: string;
  key?: string;
  disabled?: boolean;
}
const SearchContainer: FC<SearchContainerProps> = ({
  typeSelector,
  options,
  selectButtonOptions,
  selectedValue,
  handleSelectClick,
  inputPlaceholder,
  handleSearch,
  handelKeyUp,
  handelKeyDown,
  buttonName,
  handleButtonClick,
  typeOptions,
  handleTypeSelectClick,
  selectedTypeValue,
  showViewMode,
  setViewMode,
  viewMode,
  inputValue,
  deleteIcon,
  children,
  multiSelect,
  selectedSource,
  multiSelectOptions,
  handleChangeMultiSelect,
  handleTagChangeMultiSelect,
  multiSelectCategories,
  selectedCategoriesSource,
  multiSelectOptionsCategories,
  handleChangeMultiSelectCategories,
  isPublicWorkflow,
  tagOptions,
  selectedTag,
  title,
}) => {
  return (
    <Row style={{ margin: '0 -5px' }}>
      {title ? <div className="page-title">{title}</div> : null}
      {options && (
        <Col md={6} lg={multiSelectOptions ? 3 : 4} xl={2} className="px-2 py-1">
          <Select fullWidth options={options} onChange={handleSelectClick} value={selectedValue} />
        </Col>
      )}
      {tagOptions?.length > 1 && (
        <Col md={6} lg={tagOptions ? 3 : 4} xl={2} className="tag-multiSelect px-2 py-1">
          <CustomMultiSelect
            options={tagOptions}
            value={selectedTag}
            onChange={handleTagChangeMultiSelect}
            labelledBy="Select tags"
            className={`search-multi-select ${selectedTag?.length < 1 ? 'empty-tag' : ''}`}
          />
        </Col>
      )}
      {typeSelector && (
        <Col
          md={6}
          lg={multiSelectOptions ? 3 : 4}
          xl={!isPublicWorkflow && showViewMode && multiSelectOptions ? 2 : 3}
          className="px-2 py-1"
        >
          <Select fullWidth options={typeOptions} onChange={handleTypeSelectClick} value={selectedTypeValue} />
        </Col>
      )}
      <Col
        md={6}
        lg={multiSelectOptions ? 3 : 4}
        xl={!isPublicWorkflow && showViewMode && multiSelectOptions ? 2 : 3}
        className="px-2 py-1"
      >
        <Input
          defaultValue={inputValue && inputValue}
          placeholder={inputPlaceholder}
          onChange={handleSearch}
          onKeyUp={handelKeyUp && handelKeyUp}
          onKeyDown={handelKeyDown && handelKeyDown}
          value={isPublicWorkflow && inputValue ? inputValue : undefined}
        />
      </Col>

      {/* {multiSelectCategories && multiSelectOptionsCategories && selectedCategoriesSource && (
        <Col md={6} lg={3} xl={!isPublicWorkflow && showViewMode ? 2 : 3} className="workflow-categories px-2 py-1">
          <MultiSelect
            options={multiSelectOptionsCategories}
            value={selectedCategoriesSource}
            onChange={handleChangeMultiSelectCategories}
            labelledBy="Select category"
            className={`search-multi-select ${selectedCategoriesSource.length < 1 ? 'empty' : ''}`}
          />
        </Col>
      )} */}
      {multiSelectCategories && multiSelectOptionsCategories && selectedCategoriesSource && (
        <Col md={6} lg={3} xl={!isPublicWorkflow && showViewMode ? 2 : 3} className="workflow-categories px-2 py-1">
          <Select
            fullWidth
            options={multiSelectOptionsCategories}
            onChange={handleChangeMultiSelectCategories}
            value={selectedCategoriesSource}
          />
        </Col>
      )}

      {multiSelect && multiSelectOptions && selectedSource && (
        <Col md={6} lg={3} xl={!isPublicWorkflow && showViewMode ? 2 : 3} className="automations-multiSelect px-2 py-1">
          <CustomMultiSelect
            options={multiSelectOptions}
            value={selectedSource}
            onChange={handleChangeMultiSelect}
            labelledBy="Select automations"
            className={`search-multi-select ${selectedSource.length < 1 ? 'empty' : ''}`}
          />
        </Col>
      )}
      {buttonName && handleButtonClick && (
        <Col md={multiSelect ? 5 : 6} lg={multiSelect ? 3 : 4} xl={2} className="px-2 py-1">
          {!selectButtonOptions ? (
            <Button name={buttonName} leftIcon={plusIcon} onClick={handleButtonClick} gradient className="d-inline" />
          ) : (
            <Select
              showBottom
              icon={<Button name={buttonName} leftIcon={plusIcon} gradient className="d-inline" />}
              options={selectButtonOptions}
              className="btn-select"
              onChange={handleButtonClick}
            />
          )}
        </Col>
      )}
      {/* {tagOptions?.length <= 1 && (
        <Col
          md={6}
          lg={multiSelectOptions ? 3 : 4}
          xl={!isPublicWorkflow && showViewMode && multiSelectOptions ? 2 : 2}
          className="px-2 py-1 d-none d-xl-block"
        ></Col>
      )} */}
      {isPublicWorkflow && (
        <Col
          md={multiSelect ? 3 : 6}
          lg={multiSelect ? 2 : 4}
          xl={isPublicWorkflow ? 3 : tagOptions?.length <= 1 ? 4 : !tagOptions ? 5 : 2}
          className="px-2 pt-1"
        ></Col>
      )}
      {showViewMode && (
        <Col
          md={multiSelect ? 3 : 6}
          lg={multiSelect ? 2 : 4}
          xl={isPublicWorkflow ? 3 : tagOptions?.length <= 1 ? 4 : !tagOptions ? 5 : 2}
          className="px-2 pt-1"
        >
          <div className="select-recipes-view mr-2">
            {deleteIcon && deleteIcon}
            <button
              className={`view-mode-icon ${viewMode === 'cardView' ? 'colored-icon' : ''}`}
              onClick={() => {
                setViewMode && setViewMode('cardView');
              }}
            >
              {dotsmenuIcon}
            </button>
            <button
              className={`view-mode-icon ml-4 ${viewMode === 'listView' ? 'colored-icon' : ''}`}
              onClick={() => {
                setViewMode && setViewMode('listView');
              }}
            >
              {listMenuIcon}
            </button>
          </div>
        </Col>
      )}

      {children}
    </Row>
  );
};

export default SearchContainer;
