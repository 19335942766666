import { FC, memo } from 'react';
import { ISwitch } from './types';
import Tooltip from '../tooltip';
import './styles.scss';
import { infoIconCircle } from 'assets/svg-icons';

const Switch: FC<ISwitch> = ({ className = '', checked, setChecked, tooltip = '', label }) => (
  <div className='d-flex switch-with-label'>
    <div id="switch" onClick={setChecked} className={`${className} ${checked ? 'checked' : ''}`}>
      <div className="circle rounded-circle"></div>
    </div>
    {label ? <div className='mx-2'>{label}</div> : null}
    {label && tooltip ? <Tooltip text={tooltip} placement={'right'}>{infoIconCircle}</Tooltip> : null}
  </div>
);

export default memo(Switch);
