import { useEffect } from 'react';
import { FC, useState } from 'react';
import Input from 'shared-components/atom/input';
import './styles.scss';

interface IUrlVariables {
  url: string;
  setEditUrl: (val: string) => void;
  setError: (val: boolean) => void;
}

const UrlVariables: FC<IUrlVariables> = ({ url, setEditUrl, setError }) => {
  const [newUrl, setNewUrl] = useState(url);
  const [newError, setNewError] = useState(false);

  useEffect(() => {
    setEditUrl(newUrl);
    if (
      (newUrl.match(/%/g)?.length || 0) - (url.match(/%/g)?.length || 0) !==
      (newUrl.match(/%var/g)?.length || 0) * 2
    ) {
      setNewError(true);
    } else setNewError(false);
  }, [newUrl]);

  useEffect(() => {
    setError(newError);
  }, [newError]);

  return (
    <>
      <div className="mb-4 fs-16">Please add variables for this URL</div>
      <div className="text-left mb-2 max-width-550">
        <strong>Template URL:</strong> {url}
      </div>
      <Input
        dataUrl
        type="url"
        id="lname"
        name="url"
        className="website-url preview-input max-width-550"
        placeholder="URL"
        value={newUrl}
        autocomplete
        onChange={e => {
          setNewUrl(e.target.value);
        }}
        error={newError ? 'error' : ''}
      />
      <div className="text-left mt-1 fs-12">Example: https://example.com/%var_type%/%var_page%/ </div>
    </>
  );
};

export default UrlVariables;
