import { useContext, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import Select from 'shared-components/atom/select';
import Button from 'shared-components/atom/button';
import Pagination from 'shared-components/molecule/pagination';
import CustomModal from 'shared-components/molecule/customModal';
import ExportModalWithOptions from '../components/export-modal-with-options';
import Tabs from '../components/tabs';
import { isValidWorkflowName } from 'constants/validations';
import EditableTextOpen from 'shared-components/molecule/editable-text-open';
import ToastLoader from 'shared-components/molecule/toast-loader';
import Modal from 'shared-components/molecule/modal';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { UPDATE_WORKFLOW } from 'graphql/mutations';
import { GET_WORKFLOW_BY_ID, GET_WORKFLOW_RESULTS, GET_WORKFLOWS_SCAN_LOGS } from 'graphql/queries';
import { Col, Table } from 'react-bootstrap';
import Loader from 'shared-components/atom/loader';
import { ThemeContext } from 'contexts/theme-context';
import { downloadIcon } from 'assets/svg-icons';
import { getDateAndTime, getParsedDateAndTime } from 'helpers';
import NothingToShow from 'shared-components/atom/nothing-to-show';
import './styles.scss';

const WorkflowResult = () => {
  const { workflowId }: { workflowId: string } = useParams();
  const { theme } = useContext(ThemeContext);
  //----------------State-----------------------------------
  const [workflowName, setWorkflowName] = useState('');
  const [workflowCreatedAt, setWorkflowCreatedAt] = useState<any>(null);
  const [results, setResults] = useState([]);
  const [tableHeader, setTableHeader] = useState([]);
  const [text, setText] = useState('');
  const [positionEl, setPositionEl] = useState({ top: 0, left: 0, display: 'none' });
  const [openExportModal, setOpenExportModal] = useState(false);
  const [scanId, setScanId] = useState<any>(null);
  const [shouldUpgrade, setShouldUpgrade] = useState(false);
  const { push } = useHistory();
  const [activePage, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedHeaders, setSelectedHeaders] = useState(null as any);
  const [limit, setLimit] = useState(
    localStorage.getItem('history-rows-per-page')
      ? {
          label: localStorage.getItem('history-rows-per-page') || '25',
          value: +(localStorage.getItem('history-rows-per-page') || 25),
        }
      : { label: '25', value: 25 },
  );
  const [toasterInfo, setToasterInfo] = useState({
    show: false,
    errorMessage: '',
    loadingMessage: '',
    successMessage: '',
  });
  const [sort, setSort] = useState(
    //@ts-ignore
    localStorage.getItem('history-sort') ? eval(localStorage.getItem('hostory-sort')) : false,
  );
  const [refetchLoading, setRefetchLoading] = useState(false);

  //-------------------Queris---------------------------------
  const [updateWorkflow, { loading: updateWorkflowLoading }] = useMutation(UPDATE_WORKFLOW);
  const [getWorkflowResults, { data, loading }] = useLazyQuery(GET_WORKFLOWS_SCAN_LOGS, {
    fetchPolicy: 'no-cache',
    variables: {
      settings: {
        workflow_id: +workflowId,
        filter: {
          page: activePage,
          limit: limit?.value || 25,
          sort_order: sort ? 'DESC' : 'ASC',
          sort_by_start_date: null,
          sort_by_end_date: true,
        },
      },
    },
  });
  const { data: workflowData, refetch: workflowDataRefetch } = useQuery(GET_WORKFLOW_BY_ID, {
    skip: !workflowId,
    variables: {
      settings: {
        workflow_id: workflowId ? parseInt(workflowId) : -1,
      },
    },
  });

  //-----------------useEffect----------------------------
  useEffect(() => {
    getWorkflowResults();
  }, []);

  useEffect(() => {
    workflowDataRefetch();
  }, [workflowId]);

  useEffect(() => {
    if (workflowData?.HexomaticWorkflow?.getWorkflow?.workflow) {
      setWorkflowName(workflowData.HexomaticWorkflow.getWorkflow.workflow.name);
      setWorkflowCreatedAt(workflowData.HexomaticWorkflow.getWorkflow.workflow.created_at);
      workflowData.HexomaticWorkflow.getWorkflow.workflow.selected_headers &&
        setSelectedHeaders(workflowData.HexomaticWorkflow.getWorkflow.workflow.selected_headers);
    }
  }, [workflowData]);

  useEffect(() => {
    if (data?.HexomaticWorkflow?.getWorkflowScanLogs?.logs) {
      setResults(data.HexomaticWorkflow.getWorkflowScanLogs.logs);
      setTotalCount(data.HexomaticWorkflow.getWorkflowScanLogs.count);
    }
  }, [data]);

  //------------------Functions---------------------------
  const workflowNameUpdate = async ({ id, name }: any) => {
    if ((name && (!name.trim() || !isValidWorkflowName(name))) || !name) {
      return { message: 'Invalid name' };
    }
    if (!workflowId && !id) {
      setWorkflowName(name);
      return;
    }
    const result = await updateWorkflow({
      variables: {
        settings: {
          workflow_id: id,
          name: name && name.trim(),
          isUpdated: false,
        },
      },
    });
    if (result?.data?.HexomaticWorkflowOps?.updateWorkflow?.error_code) {
      const { error_code } = result.data.HexomaticWorkflowOps.updateWorkflow;
      //@ts-ignore
      if (errorMessages && errorMessages[error_code]) {
        if (error_code === 'ALREADY_EXISTS') {
          if (name) return { message: `"${name.length > 25 ? name.slice(0, 25) + '...' : name}" already exists` };
        } else {
          //@ts-ignore
          return { message: errorMessages[error_code] };
        }
      } else {
        return { message: error_code };
      }
    } else {
      setWorkflowName(name);
      return { message: null };
    }
  };

  const handleToastShow = (show: boolean, errorMessage: string, loadingMessage: string, successMessage: string) => {
    setToasterInfo({ show: show, errorMessage, loadingMessage, successMessage });
  };

  const changeSortOption = () => {
    setPage(1);
    setSort(!sort);
    localStorage.setItem('history-sort', String(!sort));
  };

  useEffect(() => {
    if (toasterInfo.show && (toasterInfo.errorMessage || toasterInfo.successMessage)) {
      setTimeout(() => {
        handleToastShow(false, '', '', '');
      }, 5000);
    }
  }, [toasterInfo]);

  return (
    <>
      <div className="workflow-header">
        {workflowName ? (
          <EditableTextOpen
            name={workflowName || ''}
            id={+workflowId}
            onSave={async ({ id, text }: any) => {
              return await workflowNameUpdate({ id, name: text });
            }}
            propLoading={updateWorkflowLoading}
            maxTextCount={64}
            absoluteError={true}
            errorMessageLength={50}
          />
        ) : (
          <div style={{ width: '1px', height: '33px' }}></div>
        )}
      </div>
      {workflowId && (
        <Tabs
          loading={!workflowData?.HexomaticWorkflow?.getWorkflow}
          status={workflowData?.HexomaticWorkflow?.getWorkflow?.workflow?.execution_data}
          selectedHeaders={selectedHeaders}
          setSelectedHeaders={setSelectedHeaders}
          workflowId={Number(workflowId)}
          active={4}
          handelRefetch={getWorkflowResults}
          sortBy={sort}
          changeSortBy={changeSortOption}
        />
      )}
      {loading ? (
        <div className="workflow-history-conatiner">
          <Loader light={theme === 'light'} />
        </div>
      ) : results.length > 0 ? (
        <div className="card-body workflow-history-conatiner mb-3">
          {workflowCreatedAt && (
            <div className="mb-1 pb-1 pl-4" style={{ borderBottom: '1px dashed #d7e0e8', display: 'flex' }}>
              <span className="info-message" style={{ marginLeft: 'auto', display: 'inline-block' }}>
                Workflow is created on {getParsedDateAndTime(workflowCreatedAt)}
              </span>
            </div>
          )}
          <Table
            className={`table ${tableHeader.length > 3 ? 'table--bordered' : 'table--bordered-small'}`}
            responsive
            hover
          >
            <thead>
              <tr>
                <th className="pl-4">Result Date</th>
                <th className="pl-4"></th>
              </tr>
            </thead>
            <tbody style={{ maxHeight: window.innerHeight - 330 }}>
              {results.map((result: any) => (
                <tr>
                  <td className={`pl-4 position-relative`}>{getParsedDateAndTime(result.updated_at)}</td>
                  <td className={`pr-4 pl-4 position-relative`}>
                    <Button
                      name="Export"
                      onClick={() => {
                        setScanId(result._id);
                        setOpenExportModal(true);
                      }}
                      leftIcon={downloadIcon}
                      outline
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className={`mr-2 row mt-2 rows-count`}>
            <Col className="d-flex m-auto" md={5}></Col>
            <Col className="d-flex m-auto" md={2}></Col>
            <Col className="d-flex ml-auto" md={5}>
              <p className="label my-auto ml-auto mr-2">Rows per page:</p>
              <Select
                value={limit}
                options={[
                  { label: '5', value: 5 },
                  { label: '15', value: 15 },
                  { label: '25', value: 25 },
                  { label: '30', value: 30 },
                  { label: '50', value: 50 },
                ]}
                onChange={(val: any) => {
                  setPage(1);
                  setLimit(val);
                  localStorage.setItem('history-rows-per-page', val.label);
                }}
                width={80}
                noTextTransform
                className="rows-per-page"
              />
            </Col>
          </div>
          {totalCount > limit.value && (
            <Pagination
              activePage={activePage}
              onChange={val => {
                setPage(val);
              }}
              totalCount={totalCount}
              itemsPerPage={limit.value}
              className="justify-content-center"
            />
          )}
        </div>
      ) : (
        <div className="workflow-logs-conatiner">
          <NothingToShow text="No data collected yet" />
        </div>
      )}
      {openExportModal && (
        <CustomModal ShowPopup={openExportModal} noNavigate escButtonClick={() => setOpenExportModal(false)}>
          <ExportModalWithOptions
            workflowId={+workflowId}
            scanId={scanId}
            setShow={setOpenExportModal}
            showUpgradeModal={setShouldUpgrade}
            setShowToastInfo={handleToastShow}
            noCustom
          />
        </CustomModal>
      )}
      <Modal
        isModalOpen={shouldUpgrade}
        title={'Please upgrade your plan to continue'}
        actionBtnText={'Upgrade'}
        cancelBtnText={'Cancel'}
        actionBtnColor={'warning'}
        cancelBtnColor={'secondary'}
        onActionClick={() => {
          window.location.href='https://hexomatic.com/pricing/';
          setShouldUpgrade(false);
        }}
        onCancelClick={() => setShouldUpgrade(false)}
        escButtonClick={() => setShouldUpgrade(false)}
      />
      {toasterInfo.show && (
        <ToastLoader
          errorMessage={toasterInfo.errorMessage}
          loadingMessage={toasterInfo.loadingMessage}
          successMessage={toasterInfo.successMessage}
        />
      )}
    </>
  );
};

export default WorkflowResult;
