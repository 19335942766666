// React modules
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// App component
// import relativeTime from 'dayjs/plugin/relativeTime';
// import dayjs from 'dayjs';
import App from './App';
// Service Worker
import * as serviceWorker from './serviceWorker';

// dayjs.extend(relativeTime);

// styles

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import './styles/index.scss';

import './i18n';

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.register();
