import { FC, useContext, useState, useEffect } from 'react';
import { AutomationCardDetailProps } from '../types';
import { useHistory } from 'react-router-dom';
import { favoriteIcon, newLabelIcon } from '../../../assets/svg-icons';
import Modal from 'shared-components/molecule/modal';
import CreateNewWorkflowOrRecipe from 'shared-components/organizm/create-new-element';
import Tooltip from 'shared-components/atom/tooltip';

import { ThemeContext } from '../../../contexts/theme-context';
import { StateChangeContext } from 'contexts/state-change-context';
import deeplLight from 'assets/images/deepL-light.svg';
import Button from 'shared-components/atom/button';
import { automationsUsedWithoutSourceDetailsPage } from 'helpers';
import './styles.scss';


const AutomationDetailsHeader: FC<AutomationCardDetailProps> = ({ props }) => {
  const { theme } = useContext(ThemeContext);
  const [creditPrice, setCreditPrice] = useState(0);
  const [premiumPrice, setPremiumPrice] = useState(0);
  const [createNew, setCreateNew] = useState(false);
  const { push } = useHistory();
  const { setStateChange } = useContext(StateChangeContext);
  useEffect(() => {
    let credit = 0,
      premium = 0;
    props &&
      props.prices &&
      props.prices.map((item: any) => {
        item && item.credit_price && (credit += item.credit_price);
        item && item.premium_credit_price && (premium += item.premium_credit_price);
        return;
      });
    setCreditPrice(credit);
    setPremiumPrice(premium);
  }, [props]);

  const handleAutomationClick = (hasSource: boolean, id: number) => {
    push('/workflows/create', { isAutomationSelected: id });
      setStateChange({
        id: id && id,
        toggle: Math.floor(Math.random() * 10000),
        requriesDataInput: !(hasSource || automationsUsedWithoutSourceDetailsPage.includes(id))
    });
    // if () {
    //   push('/workflows/create', { isAutomationSelected: id });
    //   setStateChange({
    //     id: id && id,
    //     toggle: Math.floor(Math.random() * 10000),
    //   });
    // } else {
    //   setCreateNew(true);
    // }
  };

  return (
    <div className="automation-details">
      <div className="automation-card-header">
        <div className="automation-header-right">
          <div className="automation-card-icon">
            <img
              src={props.id === 34 && theme === 'dark' ? deeplLight : props.icon_url}
              style={{ width: props.id === 35 ? '45px' : '', maxHeight: props.id === 35 ? '45px' : '' }}
            />
          </div>
          <div className="automation-info">
            <div className="automation-card-title">
              <div className="automation-details-title">{props.name}</div>
              <span
                className="starred-icon ml-2"
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.isFavorite
                    ? props.handleRemoveFromStarred && props.handleRemoveFromStarred(props.id)
                    : props.handleMarkAsStarred && props.handleMarkAsStarred(props.id);
                }}
              >
                <Tooltip
                  trigger={['hover']}
                  placement="right"
                  text={
                    props.isFavorite
                      ? 'Click to remove from starred automations list'
                      : 'Click to add into the starred automations list'
                  }
                  className="starred-icon mb-1"
                >
                  <span className={props.isFavorite ? 'favorite-icon-bg' : ""}>{favoriteIcon}</span>
                </Tooltip>
              </span>
              <div className="d-flex">
                {props.is_new && <div className="automation-new-tag">{newLabelIcon}</div>}
                {(props.id === 35 || props.id === 36) && <div className="automation-beta-tag">BETA</div>}
              </div>
            </div>
            <div className="short-description">{props.short_description}</div>
            {/* <div className="automation-rating-block">
            <span className="automation-star-icon">{starIcon}</span>
            <span className="automation-progress-bar">
              <span className="automation-progress-bar-filled" style={{ width: `${(props.rate / 5) * 100}%` }}></span>
            </span>
            <span className="automation-rating">{rate}</span>
          </div> */}
            <h6 className="m-0">
              {props.type && props.type.name ? `${props.type.name} | ` : ''}
              {creditPrice ? `Credits: ${creditPrice} ` : ''}
              {creditPrice && premiumPrice && !(props.id === 6 || props.id === 7 || props.id === 81 || props.id === 97)
                ? ' | '
                : ''}
              {premiumPrice && !(props.id === 6 || props.id === 7 || props.id === 81 || props.id === 97)
                ? `Premium Credits: ${props.id === 125 ? 'Based on query volume' : premiumPrice}`
                : ''}
            </h6>
          </div>
        </div>
        <div className="create-workflow-btn">
          <Button
            loading={false}
            onClick={() => handleAutomationClick(props.has_source, props.id)}
            name="Create Workflow"
          />
        </div>
      </div>
      <Modal
        isModalOpen={createNew}
        actionBtnText=""
        onActionClick={() => {}}
        actionBtnColor="transparent"
        text={<CreateNewWorkflowOrRecipe onClick={() => setCreateNew(false)} />}
        onCloseModal={() => setCreateNew(false)}
        onlyBtns
        className="p-0"
        parentClass="create-new-modal "
        escButtonClick={() => setCreateNew(false)}
      />
    </div>
  );
};

export default AutomationDetailsHeader;
