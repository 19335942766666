import { FC, useState } from 'react';
import {
  closeIcon,
  checkedCircleIcon,
  warningCircleIcon,
  bellNotificationIcon,
  bellDisabledNotificationIcon,
} from '../../../assets/svg-icons';
import Tooltip from '../tooltip';
import './styles.scss';

interface ISharedPropertyItem {
  name: string;
  hexowatch: { verified: any; send: () => any };
  onClick: (arg: any) => void;
  value?: any;
  propId?: string | number;
  color?: string;
  profile?: any;
  enabled?: boolean;
  onChangeNotification?: any;
}

const SharedPropertyItem: FC<ISharedPropertyItem> = ({
  name,
  value,
  onClick,
  propId,
  color,
  profile,
  hexowatch,
  enabled,
  onChangeNotification,
}) => {
  return (
    <div className="shared-property-item">
      <span
        className={`title text-truncate ${profile ? 'link' : ''}`}
        onClick={() => profile && (window.location.pathname = `/properties/${btoa(`--${propId}--`)}`)}
        title={value ? `${name}: ${value}` : name}
      >
        {value ? `${name}: ${value}` : name}
      </span>
      {hexowatch ? (
        <div className="hexowatch-email-actions">
          {/*<ReactTooltip effect={'solid'} place={'bottom'} backgroundColor={'#36b8f7'} isCapture={false} />*/}
          {/* <span className="email-notification-ring" onClick={onChangeNotification}>
          {enabled ? bellNotificationIcon : bellDisabledNotificationIcon}
        </span> */}
          {hexowatch.verified ? (
            <>
              <Tooltip placement="left" text={`${enabled ? 'Disable' : 'Enable'} notifications`}>
                <button className="email-notification-ring btn" onClick={onChangeNotification} id={name}>
                  {enabled ? bellNotificationIcon : bellDisabledNotificationIcon}
                </button>
              </Tooltip>
              <span data-tip="Verified">{checkedCircleIcon}</span>&nbsp;
            </>
          ) : (
            <>
              <span data-tip="Not Verified">{warningCircleIcon}</span>
              <a
                href="#"
                className="resend"
                onClick={e => {
                  e.preventDefault();
                  hexowatch.send();
                }}
              >
                Resend Email
              </a>
            </>
          )}
          <span className="close-icon" data-tip="Click to delete" onClick={onClick}>
            {closeIcon}
          </span>
        </div>
      ) : (
        <span className="close-icon" data-tip="Click to delete" onClick={onClick}>
          {closeIcon}
        </span>
      )}
    </div>
  );
};

export default SharedPropertyItem;
