import { memo, useContext } from 'react';
import { ThemeContext } from '../contexts/theme-context';
import Switch from '../shared-components/atom/switchh';

const ModeSwitcher = () => {
  //!----------------Context------------------
  const { theme, setTheme } = useContext(ThemeContext);
  //!----------------Function-----------------
  const handleChangeMode = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
    localStorage.setItem('theme', theme === 'light' ? 'dark' : 'light');
  };
  //!-----------------------------------------
  return (
    <div className="pl-1 d-flex align-items-center">
      <Switch
        className="mode-switcher"
        setChecked={handleChangeMode}
        checked={theme === 'light'}
        tooltip={`Switch to ${theme === 'light' ? 'dark' : 'light'} mode`}
      />
    </div>
  );
};

export default memo(ModeSwitcher);
