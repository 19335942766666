import { Component } from 'react';
import { Alert } from 'react-bootstrap';
import { AlertComponentProps, AlertComponentState } from '../types';
// import './styles.scss'

export default class AlertComponent extends Component<AlertComponentProps, AlertComponentState> {
  state = {
    visible: true,
  };

  static defaultProps = {
    color: '',
    icon: false,
    className: '',
  };

  onShow = () => {
    this.setState({ visible: true });
  };

  onDismiss = () => {
    this.setState({ visible: false });
  };

  render() {
    const { visible } = this.state;

    const { color, className, icon, closeBtn, children } = this.props;
    let Icon;

    switch (color) {
      case 'info':
        Icon = null;
        break;
      case 'success':
        Icon = null;
        break;
      case 'warning':
        Icon = null;
        break;
      case 'danger':
        Icon = null;
        break;
      default:
        break;
    }

    if (visible) {
      return (
        <Alert variant={color} show={visible} className={className}>
          {icon && <div className="alert__icon">{Icon}</div>}
          {closeBtn && (
            <button className="close" type="button" onClick={this.onDismiss}>
              &times;
            </button>
          )}
          <div className="alert__content">{children}</div>
        </Alert>
      );
    }
    return null;
  }
}
