import { FC, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';

import {
  GET_ALL_WORKSPACES,
  GET_HEXOSPARK_CONTACTS_COLUMNS,
  GET_HEXOSPARK_ORGANIZATION_COLUMNS,
  GET_HEXOSPARK_WS_FOLDER,
  HEXOSPARK_USER,
} from 'graphql/queries';

import Select from 'shared-components/atom/select';
import Button from 'shared-components/atom/button';
import StretchLoading from 'shared-components/molecule/stretch-loading';
import Alert from 'shared-components/atom/alert-message';
import Radio from 'shared-components/atom/radio-button';

import { UserContext } from 'contexts/user-context';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import { useAutomationGlobalMethods } from '../../hooks';
import Column from './column';

import { sharedPeopleIcon } from 'assets/svg-icons';
import './styles.scss';

const typeOptions = [
  {
    label: 'Contact',
    value: 'crm_contact',
  },
  {
    label: 'Organization',
    value: 'crm_organization',
  },
];

const labelsWithOrderContact = [
  { label: 'email' },
  { label: 'first name' },
  { label: 'middle name' },
  { label: 'last name' },
  { label: 'ice breaker' },
  { label: 'date of birth' },
  { label: 'gender' },
  { label: 'position' },
  { label: 'organization' },
  { label: 'phone' },
  { label: 'social url' },
  { label: 'website url' },
  { label: 'language' },
  { label: 'time zone' },
  { label: 'experience organization' },
  { label: 'experience position' },
  { label: 'experience date' },
  { label: 'volunteering experience organization' },
  { label: 'volunteering experience position' },
  { label: 'volunteering experience date' },
  { label: 'education institution' },
  { label: 'education course' },
  { label: 'education date' },
  { label: 'honors and awards institution' },
  { label: 'honors and awards name' },
  { label: 'honors and awards date' },
  { label: 'licenses and certifications institution' },
  { label: 'licenses and certifications name' },
  { label: 'licenses and certifications date' },
  { label: 'leads contacts' },
  { label: 'lead category' },
  { label: 'leads source' },
  { label: 'lead status' },
  { label: 'activity' },
  { label: 'description' },
  { label: 'notes' },
  { label: 'address line 1' },
  { label: 'address line 2' },
  { label: 'country' },
  { label: 'state' },
  { label: 'city' },
];
const labelsWithOrderOrg = [
  { label: 'name' },
  { label: 'founded' },
  { label: 'industry' },
  { label: 'website url' },
  { label: 'email' },
  { label: 'phone' },
  { label: 'social url' },
  { label: 'employees' },
  { label: 'employees on linkedIn' },
  { label: 'followers' },
  { label: 'tagline' },
  { label: 'legal name' },
  { label: 'other id' },
  { label: 'vat' },
  { label: 'status' },
  { label: 'description' },
  { label: 'notes' },
  { label: 'bank details' },
  { label: 'account owner' },
  { label: 'routing number' },
  { label: 'currency' },
  { label: 'address line 1' },
  { label: 'address line 2' },
  { label: 'country' },
  { label: 'state' },
  { label: 'city' },
  { label: 'zip code' },
];

const multiTables = ['crm_social', 'crm_activity', 'crm_language', 'crm_industry', 'crm_phone', 'crm_website'];

type IMPORT_TYPES = 'update' | 'overwrite' | 'skip';

const HexosparkSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const { user } = useContext(UserContext);

  const [errorMessage, setErrorMessage] = useState('');
  const [importType, setImportType] = useState<IMPORT_TYPES>('update');

  const [clicked, setClicked] = useState(false);

  const [fieldList, setFieldList] = useState<any>([]);
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);
  const [selectedWs, setSelectedWs] = useState<any>(null);
  const [selectedFolder, setSelectedFolder] = useState<any>(null);
  const [selectedType, setSelectedType] = useState({ label: 'Contact', value: 'crm_contact' });
  const [workspacesList, setWorkspacesList] = useState<any>([]);
  const [foldersList, setFoldersList] = useState<any>([]);
  const [selectedList, setSelectedList] = useState<any>([]);
  const [hexosparkUser, setHexosparkUser] = useState<any>(null);
  const [contactOptions, setContactOptions] = useState<any>([]);
  const [organizationOptions, setOrganizationOptions] = useState<any>([]);
  const [skippedValues, setSkippedValues] = useState<any>([]);

  const { data: hexosparkUserData } = useQuery(HEXOSPARK_USER, {
    fetchPolicy: 'no-cache',
  });

  const { data: contactColumns } = useQuery(GET_HEXOSPARK_CONTACTS_COLUMNS, {
    fetchPolicy: 'no-cache',
  });

  const { data: organizationColumns } = useQuery(GET_HEXOSPARK_ORGANIZATION_COLUMNS, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (contactColumns?.HexosparkCrmContact?.getCrmContactColumns?.keys) {
      const optionsList: any = [];
      Object.entries(JSON.parse(contactColumns.HexosparkCrmContact.getCrmContactColumns.keys)).forEach((item: any) => {
        if (item[1]) {
          Object.entries(item[1]).forEach((element: any) => {
            let ind = -1;
            labelsWithOrderContact.find((sub, index) => sub.label === element[1] && (ind = index));
            optionsList[ind] = {
              label: element[1],
              title: element[1].length > 20 ? element[1] : '',
              value: element[0],
              table: item[0],
              required: item.required,
            };
          });
        }
      });
      setContactOptions(optionsList);
    }
  }, [contactColumns]);

  useEffect(() => {
    if (organizationColumns?.HexosparkCrmOrganization?.getCrmOrganizationColumns?.keys) {
      const optionsList: any = [...labelsWithOrderOrg];
      Object.entries(JSON.parse(organizationColumns.HexosparkCrmOrganization.getCrmOrganizationColumns.keys)).forEach(
        (item: any) => {
          if (item[1]) {
            Object.entries(item[1]).forEach((element: any) => {
              let ind = -1;
              labelsWithOrderOrg.find((sub, index) => sub.label === element[1] && (ind = index));
              optionsList[ind] = {
                label: element[1],
                title: element[1].length > 20 ? element[1] : '',
                value: element[0],
                table: item[0],
                required: item.required,
              };
            });
          }
        },
      );
      setOrganizationOptions(optionsList);
    }
  }, [organizationColumns]);

  useEffect(() => {
    if (hexosparkUserData?.HexosparkUser?.getHexosparkUser) {
      setHexosparkUser(hexosparkUserData.HexosparkUser.getHexosparkUser);
    }
  }, [hexosparkUserData]);

  const {
    data: hexosparkWorkspaces,
    loading: wsLoading,
    refetch,
  } = useQuery(GET_ALL_WORKSPACES, {
    variables: { settings: { filter: { limit: null, page: 1 } } },
  });
  const [getWsFolders] = useLazyQuery(GET_HEXOSPARK_WS_FOLDER, {
    fetchPolicy: 'no-cache',
    onCompleted(foldersData) {
      if (foldersData?.HexosparkCrmFolder?.showAllFolders?.rootTree) {
        const folders = JSON.parse(foldersData.HexosparkCrmFolder.showAllFolders.rootTree);
        const listFolders: any = [];
        folders && folders['id'] && listFolders.push({ label: folders['name'], value: folders['id'], order: 1 });
        folders['subFolders'] &&
          folders['subFolders'].map((item: any) => {
            listFolders.push({ label: item.name, value: item.id, order: 2 });
            if (item['subFolders']) {
              item['subFolders'].map((sub: any) => {
                listFolders.push({ label: sub.name, value: sub.id, order: 3 });
                if (sub['subFolders']) {
                  sub['subFolders'].map((sub1: any) => {
                    listFolders.push({ label: sub1.name, value: sub1.id, order: 4 });
                  });
                }
              });
            }
          });
        setFoldersList(listFolders);
      }
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  const {
    liftingSettings,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (
      !selectedWs ||
      (!selectedFolder && selectedType.value === 'crm_contact') ||
      (selectedList?.filter((item: any) => item)?.length || 0) + (skippedValues?.length || 0) < fieldList?.length ||
      selectedList?.filter((item: any) => item)?.find((item: any) => !item?.skip && !item?.value)
    ) {
      return false;
    }
    if (
      selectedType?.value === 'crm_contact' &&
      !selectedList?.find((item: any) => (item?.labelVal === 'email' || item?.label === 'email') && !item.skip)
    ) {
      return false;
    }
    if (
      selectedType?.value === 'crm_organization' &&
      !selectedList?.find((item: any) => (item?.labelVal === 'name' || item?.label === 'name') && !item.skip)
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const { keys } = dynamic_settings;
      const { settings } = keys[0];

      const selectedWorkspace =
        workspacesList &&
        workspacesList.length &&
        workspacesList.find((item: any) => item.value === settings?.hs_workspace);
      const selectedFolderLocal =
        foldersList && foldersList.length && foldersList.find((item: any) => item.value === settings?.folderId);
      const selectedTable = typeOptions.find((item: any) => item.value === settings?.table);

      const selectedValues = settings?.keyValues;

      const selectedValuesFin: any = [];

      selectedValues &&
        Object.entries(selectedValues)?.map((item: any) => {
          const parsedVal = JSON.parse(item[1]);
          Array.isArray(parsedVal)
            ? parsedVal.map((val: any) =>
                Object.entries(val).map((subVal: any) => {
                  let prefix = '';
                  let label = '';
                  if (
                    subVal[0] === 'url' ||
                    subVal[0] === 'name' ||
                    subVal[0] === 'date' ||
                    subVal[0] === 'institution' ||
                    (subVal[0] === 'organization' && item[0] !== 'crm_contact') ||
                    subVal[0] === 'course'
                  ) {
                    prefix = item[0].replace('crm_', '').replace('volunteering_experience', 'volunteering') + '_';
                  }

                  label = (prefix + subVal[0]).replace(/_/g, ' ');

                  if (item[0] === 'crm_organization' && subVal[0] === 'name') {
                    label = subVal[0];
                  }

                  selectedValuesFin.push({
                    label,
                    value: item[0],
                    outputKey: subVal[1],
                  });
                }),
              )
            : Object.entries(parsedVal).map((subVal: any) => {
                let prefix = '';
                let label = '';
                if (
                  subVal[0] === 'url' ||
                  subVal[0] === 'name' ||
                  subVal[0] === 'date' ||
                  subVal[0] === 'institution' ||
                  (subVal[0] === 'organization' && item[0] !== 'crm_contact') ||
                  subVal[0] === 'course'
                ) {
                  prefix = item[0].replace('crm_', '').replace('volunteering_experience') + '_';
                }

                label = (prefix + subVal[0]).replace(/_/g, ' ');

                if (item[0] === 'crm_organization' && subVal[0] === 'name') {
                  label = subVal[0];
                }

                selectedValuesFin.push({
                  label,
                  value: item[0],
                  outputKey: subVal[1],
                });
              });
        });

      const filteredOutputKeys = [...fieldList.map((item: { value: any }) => item.value)].map((sub: any) => {
        const found = selectedValuesFin.find((sel: any) => sel.outputKey === sub);
        if (found) {
          return found;
        } else {
          return { label: '', value: '', outputKey: sub, skip: true };
        }
      });
      setSelectedList(filteredOutputKeys);
      selectedWorkspace && setSelectedWs(selectedWorkspace);
      selectedFolderLocal && setSelectedFolder(selectedFolderLocal);
      selectedTable && setSelectedType(selectedTable);
      settings?.skippedValues && setSkippedValues(settings.skippedValues);
      settings?.type && setImportType(settings.type);
    }
  }, [workspacesList, fieldList]);

  useEffect(() => {
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const { keys } = dynamic_settings;
      const { settings } = keys[0];
      const selectedFolderLocal =
        foldersList && foldersList.length && foldersList.find((item: any) => item.value === settings?.folderId);
      selectedFolderLocal && setSelectedFolder(selectedFolderLocal);
    }
  }, [foldersList]);

  useEffect(() => {
    if (hexosparkWorkspaces?.HexosparkUserWorkspace?.getFull?.workspaces) {
      setWorkspacesList(
        hexosparkWorkspaces.HexosparkUserWorkspace.getFull.workspaces
          .map((item: any) => {
            return {
              label: item.name,
              value: item.id,
              disabled: item.disabled,
              icon: item.isOwner ? null : sharedPeopleIcon,
            };
          })
          .filter((item: any) => {
            return item.disabled === false;
          }),
      );
    }
  }, [hexosparkWorkspaces]);

  useEffect(() => {
    selectedWs &&
      selectedWs.value &&
      getWsFolders({
        variables: {
          settings: {
            hs_workspace_id: selectedWs?.value,
            withContacts: false,
          },
        },
      });
  }, [selectedWs]);

  //recipe
  useEffect(() => {
    // if (fieldList.length > 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const key = dynamic_settings.keys[0].key;
      restoreSelectedSource({ key: key, fieldList: options, setSelectedSource: setSelectedSource });
    }
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [...fieldList.map((item: { value: any }) => item.value)],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);

  useEffect(() => {
    if (!clicked || !foldersList?.length) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    let selectedListFormatted: any = {};
    const filteredList = selectedList.filter((item: any) => item && !item.skip);
    filteredList?.map((item: { label: string; value: string; outputKey: string; labelVal: string; table: string }) => {
      if (item?.table || item?.value) {
        selectedListFormatted = {
          ...selectedListFormatted,
          [item.table || item.value]: [
            ...(selectedListFormatted[item.table || item.value] || []),
            {
              [item.label
                .replace('website url', 'url')
                .replace('industry', 'name')
                .replace('language', 'name')
                .replace('social url', 'url')
                .replace('education ', '')
                .replace('experience ', '')
                .replace('volunteering ', '')
                .replace('honors and awards ', '')
                .replace('licenses and certifications ', '')
                .replace('contact position', 'position')
                .replace(/ /g, '_')]: item.outputKey,
            },
          ],
        };
      }
    });
    for (const property in selectedListFormatted) {
      let arr: any = selectedListFormatted[property];
      if (!multiTables.includes(property)) {
        const arrLocal = selectedListFormatted[property];
        let obj = {};
        arrLocal.map((item: any) => {
          obj = { ...obj, ...item };
        });
        arr = [obj];
      }

      selectedListFormatted[property] = JSON.stringify(arr);
    }

    const localSettings = {
      keys: [
        {
          inputValue: null,
          key: null,
          settings: {
            userId: user?.id ? +user?.id : undefined,
            hsUserId: hexosparkUser?.hsUser?.id ? hexosparkUser?.hsUser?.id : undefined,
            dbLocation: hexosparkUser?.hsUser?.db_location,

            hs_workspace: selectedWs?.value,
            folderId: selectedType?.value === 'crm_contact' ? selectedFolder?.value : null,
            table: selectedType?.value,
            keyValues: selectedListFormatted,
            skippedValues: skippedValues,
            type: importType,
          },
        },
      ],
    };
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: localSettings,
      outputKeysData: [...fieldList.map((item: { value: any }) => item.value)],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [
    fieldList,
    selectedWs,
    selectedType,
    selectedList,
    user,
    hexosparkUser,
    skippedValues,
    selectedFolder,
    foldersList,
    workspacesList,
    importType,
  ]);

  return (
    <div>
      {wsLoading && <StretchLoading />}
      {!wsLoading && hexosparkWorkspaces && !workspacesList?.length ? (
        <Row>
          <Col lg={6} md={12}>
            <Button
              name={'Connect with Hexospark'}
              onClick={() => window.open('https://hexospark.com/login/', '_blank')}
            />
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col className="mb-2">
              <h2 className="text-align-start">When existing entries are found</h2>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col lg={3}>
              <Radio
                enabled={importType === 'update'}
                onChange={() => {
                  setImportType('update');
                  !clicked && setClicked(true);
                }}
                label="Update missing fields"
                className="mb-2"
              />
            </Col>
            <Col lg={3}>
              <Radio
                enabled={importType === 'skip'}
                onChange={() => {
                  setImportType('skip');
                  !clicked && setClicked(true);
                }}
                label="Skip existing contacts"
                className="mb-2"
              />
            </Col>
            <Col lg={3}>
              <Radio
                enabled={importType === 'overwrite'}
                onChange={() => {
                  setImportType('overwrite');
                  !clicked && setClicked(true);
                }}
                label="Overwrite"
                className="mb-2"
              />
            </Col>
          </Row>

          <Row>
            <Col lg={4} className="mb-4">
              <span className="label secondary-color">Workspace</span>
              <Select
                options={workspacesList}
                value={selectedWs}
                placeholder={'Select workspace'}
                onChange={(selectedItem: any) => {
                  setSelectedFolder(null);
                  setSelectedWs(selectedItem);
                  !clicked && setClicked(true);
                  setIsDirty();
                }}
              />
            </Col>
            <Col lg={4} className="mb-4">
              <span className="label secondary-color">Type</span>
              <Select
                options={typeOptions}
                value={selectedType}
                placeholder={'Select type'}
                onChange={(selectedItem: any) => {
                  setSelectedList([]);
                  setSelectedType(selectedItem);
                  !clicked && setClicked(true);
                  setIsDirty();
                }}
              />
            </Col>
            {selectedWs && selectedType.value === 'crm_contact' && (
              <Col lg={4} className="mb-4">
                <span className="label secondary-color">Folder</span>
                <Select
                  options={foldersList}
                  value={selectedFolder}
                  placeholder={'Select folder'}
                  onChange={(selectedItem: any) => {
                    setSelectedFolder(selectedItem);
                    !clicked && setClicked(true);
                    setIsDirty();
                  }}
                />
              </Col>
            )}
          </Row>
          <div className="col-select-wrapper">
            <small>* {selectedType.value === 'crm_contact' ? 'Contact email' : 'Organization name'} is requried</small>
            {fieldList.map((item: any, index: number) => (
              <Column
                key={index}
                outputKey={item}
                outputKeyType={
                  Object.entries(fieldListWithTypes)?.find((sub: any) => sub[0].includes(item.value))?.[1] || 'any'
                }
                type={selectedType.value}
                index={index}
                value={selectedList[index] || ''}
                selectedList={selectedList}
                setSelectedList={(val: any) => {
                  !clicked && setClicked(true);
                  setIsDirty();
                  setSelectedList(val);
                }}
                organizationOptions={organizationOptions}
                contactOptions={contactOptions}
                skippedValues={skippedValues}
                setSkippedValues={setSkippedValues}
              />
            ))}
          </div>
        </>
      )}
      {errorMessage && (
        <Row className="mb-4">
          <Col md={12}>
            <Alert type="error" className="create-workflow">
              {errorMessage}
            </Alert>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default HexosparkSettings;
