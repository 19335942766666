import { FC, useEffect, useReducer, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { Col, Row } from 'react-bootstrap';

import { CREATE_WORDPRESS_CONNECTION, UPDATE_WORDPRESS_CONNECTION } from 'graphql/queries';

import Input from 'shared-components/atom/input';
import Button from 'shared-components/atom/button';
import Alert from 'shared-components/atom/alert-message';
import CustomModal from 'shared-components/molecule/customModal';

import { closeIcon, eyeIcon, infoIcon } from 'assets/svg-icons';

import { isValidDomain, isValidUrl } from 'constants/validations';
import './styles.scss';
import Tooltip from 'shared-components/atom/tooltip';

interface IModalProps {
  openModal: boolean;
  setOpenModal: (val: { show: boolean; item: null }) => void;
  updateConnectionId: null | number;
  refetchDataList: () => void;
  item?: any;
}
const WordpressModal: FC<IModalProps> = ({ openModal, setOpenModal, updateConnectionId, refetchDataList, item }) => {
  const [fields, setFields] = useState({ url: '', username: '', password: '' });

  const [showPassword, setShowPassword] = useReducer(show => !show, false);
  const [customLoading, setCustomLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  //--------------------Queries---------------
  const [createConnection, { data, loading }] = useLazyQuery(CREATE_WORDPRESS_CONNECTION, {
    onCompleted(data) {
      setCustomLoading(true);
      if (data?.HexomaticIntegrations?.createWordPressIntegration?.error_code) {
        setErrorMessage('Something went wrong. Please try again.');
      } else {
        handleModalClose();
        refetchDataList();
      }
      setCustomLoading(false);
    },
  });
  const [updateConnection, { loading: updateLoading }] = useLazyQuery(UPDATE_WORDPRESS_CONNECTION, {
    onCompleted(data) {
      setCustomLoading(true);
      if (data?.HexomaticIntegrations?.updateWordPressConnection?.error_code) {
        setErrorMessage('Something went wrong. Please try again.');
      } else {
        handleModalClose();
        refetchDataList();
      }
      setCustomLoading(false);
    },
  });
  //------------------------------------------
  useEffect(() => {
    setErrorMessage('');
  }, [fields]);

  useEffect(() => {
    if (item) {
      setFields({ username: item.username, password: item.password, url: item.url });
    }
  }, [item]);

  const handleModalClose = () => {
    setOpenModal({ show: false, item: null });
    setFields({ url: '', username: '', password: '' });
  };

  const handleCreateConnection = async () => {
    if (
      !fields.url ||
      !fields.url.trim() ||
      !fields.username ||
      !fields.username.trim() ||
      !fields.password ||
      !fields.password.trim() ||
      (fields.url && !isValidUrl(fields.url) && !isValidDomain(fields.url))
    )
      return;

    try {
      if (updateConnectionId) {
        updateConnection({
          variables: {
            settings: { url: fields.url, username: fields.username, password: fields.password, id: updateConnectionId },
          },
        });
      } else {
        createConnection({
          variables: {
            settings: { url: fields.url, username: fields.username, password: fields.password },
          },
        });
      }
      refetchDataList();
    } catch {
      setErrorMessage('Something went wrong. Please try again.');
    }
  };

  const handleChangeInput = (e: any) => {
    setFields({ ...fields, [e.target.name]: e.target.value });
  };
  return (
    <CustomModal ShowPopup={openModal} noNavigate escButtonClick={handleModalClose}>
      <div className="sql-modal-wrapper">
        <div className="close-button-wrapper">
          <button onClick={handleModalClose}>{closeIcon}</button>
        </div>
        <h5 className="text-center dark-text">Please fill in the fields</h5>
        <Row>
          <Col lg={12} className="mb-2">
            <span className="input-label">URL (your WordPress website home URL)</span>
            <Input
              value={fields.url}
              onChange={handleChangeInput}
              name="url"
              error={(fields.url && !isValidUrl(fields.url) && !isValidDomain(fields.url)) || errorMessage === 'Something went wrong. Please try again.'? 'Invalid URL' : ''}
            />
          </Col>

          <Col lg={6} className="mb-2">
            <span className="input-label">Username</span>
            <Input
              value={fields.username}
              onChange={handleChangeInput}
              className={(errorMessage && !fields.username?.trim()) || errorMessage === 'Something went wrong. Please try again.' ? 'error-border' : ''}
              name="username"
            />
          </Col>

          <Col lg={6} className="mb-2 wordpress-tooltip-container">
            <span className="input-label mr-2">Application Password</span>
            <Tooltip trigger={['hover']} placement="bottom" className="d-inline wordpress-tooltip" content={{
                title: 'How to generate Application Password',
                text: (
                  <>
                    <div className='wordpress-hint'><strong>Step 1:</strong> Login to your WordPress website</div>
                    <div className='wordpress-hint'><strong>Step 2:</strong> Go to "Users" section, find your user and press edit</div>
                    <div className='wordpress-hint'><strong>Step 3:</strong> Go to "Application Passwords" section and input "New Application Password Name" and press "Add New Application Password" button</div>
                    <div className='wordpress-hint'><strong>Step 4:</strong> Copy the generated password</div>
                  </>
                ),
              }}>
              <span className="help-icon cursor-pointer">{infoIcon}</span>
            </Tooltip>
            <Input
              value={fields.password}
              onChange={handleChangeInput}
              type={showPassword ? 'text' : 'password'}
              rightAddon={
                <div className={`eye-icon position-absolute ${showPassword ? 'active' : ''}`} onClick={setShowPassword}>
                  {eyeIcon}
                </div>
              }
              className={errorMessage ? 'error-border pr-4' : 'pr-4'}
              name="password"
            />
          </Col>

          {errorMessage && (
            <Col md={12} className="mt-2 mb-2 text-center">
              <Alert type="error" className="create-workflow">
                {errorMessage}
              </Alert>
            </Col>
          )}
          <Col md={12} className="mt-2 mb-2">
            <Button
              name="Connect"
              outline
              className="btn_integration not-integrated m-auto"
              onClick={handleCreateConnection}
              disabled={loading || updateLoading || customLoading}
              loading={loading || updateLoading || customLoading}
            />
          </Col>
        </Row>
      </div>
    </CustomModal>
  );
};

export default WordpressModal;
