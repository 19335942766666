import { FC, useEffect, useContext, useRef, useState, SyntheticEvent } from 'react';
import { useLazyQuery, useSubscription } from '@apollo/react-hooks';
import { Col, Row } from 'react-bootstrap';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { GET_CSV_COUMNS, GET_JSON_KEYS } from 'graphql/queries';
import {
  FILE_UPLOAD_LIMIT_REACHED_SUBSCRIPTION,
  // FILE_UPLOAD_SUBSCRIPTION,
  // FILE_DELETE_SUBSCRIPTION,
} from 'graphql/subscriptions';

import { encrypt } from 'helpers';

import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import { HexomaticUserContext } from 'contexts/hexomatic-user';
import { HOST } from 'constants/host';

import Checkbox from 'shared-components/atom/checkbox';
import TextArea from 'shared-components/atom/texatarea';
import Button from 'shared-components/atom/button';
import Loader from 'shared-components/atom/loader';
import Input from 'shared-components/atom/input';
import Alert from 'shared-components/atom/alert-message';
import ModalComponent from 'shared-components/molecule/modal';
import CustomMultiSelect from 'shared-components/molecule/multiselect';

import audioImage from 'assets/images/upload-images/audio.svg';
import imageImage from 'assets/images/upload-images/image.svg';
import jsonImage from 'assets/images/upload-images/json.svg';
import csvImage from 'assets/images/upload-images/csv.svg';
import txtImage from 'assets/images/upload-images/txt.svg';
import pdfImage from 'assets/images/upload-images/pdf.svg';
import txtPasteImage from 'assets/images/upload-images/txt-paste.svg';
import listOfinputsImage from 'assets/images/upload-images/list-of-inputs.svg';
// import { publicAllowedUserIDs } from 'constants/index';

import UploadedFilesList from './components/uploaded-list';
import ImportCsv from './components/import-csv';

import { infoIcon } from 'assets/svg-icons';
import './styles.scss';

interface CommonOptionType {
  label: string;
  value: string;
}

interface CommonOptionTypeImage extends CommonOptionType {
  image: '*.svg';
  name: string;
}

interface UploadDataFilesType {
  url: string;
  size: number;
  name: string;
  error?: boolean;
}

// input data types
const UPLOAD_FILE_TYPE = 'UPLOAD_TYPE';
const PASTE_URL_TYPE = 'PASTE_URL_TYPE';
const MANUAL_ADD_TYPE = 'MANUAL_ADD_TYPE';

// data format
const CSV_FILE = 'csv-file';
const TEXT_FILE = 'text-file';
const JSON_FILE = 'json-file';
const PDF_FILE = 'pdf-file';
const IMAGE_FILE = 'image-file';
const AUDIO_FILE = 'audio-file';

const TEXT = 'text';
const STRUCTURED_DATA = 'Structured data';

// options
const formatOptions: CommonOptionType[] = [
  { label: 'CSV file', value: CSV_FILE },
  { label: 'TXT file', value: TEXT_FILE },
  { label: 'IMAGE', value: IMAGE_FILE },
  { label: 'PDF', value: PDF_FILE },
  { label: 'AUDIO', value: AUDIO_FILE },
  { label: 'JSON', value: JSON_FILE },
];

const formatOptionsWithImage: CommonOptionTypeImage[] = [
  { label: 'CSV file', value: CSV_FILE, image: csvImage, name: 'CSV' },
  { label: 'TXT file', value: TEXT_FILE, image: txtImage, name: 'TXT' },
  { label: 'JSON file', value: JSON_FILE, image: jsonImage, name: 'JSON' },
];

const formatManuallOptions: CommonOptionType[] = [
  { label: 'List of inputs', value: STRUCTURED_DATA },
  { label: 'Text', value: TEXT },
];

const PAST_TXT_ERROR = 'Please insert a valid .txt link.';
const PAST_CSV_ERROR = 'Please insert a valid .csv link.';
const PAST_JSON_ERROR = 'Please insert a valid .json link.';

const errorCodes: any = {
  EMPTY_COLUMN_HEADER: 'Column header is empty',
  DUPLICATE_COLUMN_HEADER: 'Column header is not unique',
};

const DataInput: FC<AutomationSettingsFactoryTypesProps> = ({
  workflowId,
  automationIndex,
  automationSendSettings,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  setIsDirty,
  automationInputTypes,
  automationOutputTypes,
}) => {
  const { hexomaticUser } = useContext(HexomaticUserContext);

  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpenCSVModal, setisOpenCSVModal] = useState(false);

  const [useCsvFirstRowsAsColumnHeaders, setUseCsvFirstRowsAsColumnHeaders] = useState(true);
  const [enableCsvCustomColumHeaders, setEnableCsvCustomColumHeaders] = useState(false);

  const [fileIdIndex, setFileIdIndex] = useState(0);
  const [csvOrTxtSize, setCsvOrTxtSize] = useState(0);
  const [storage, setStorage] = useState(0);

  const [uploadData, setUploadData] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [inputTextValue, setInputTextValue] = useState('');
  const [inputJsonValue, setInputJsonValue] = useState('');
  const [fileName, setFileName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [deleteUrl, setDeleteUrl] = useState('');
  const [workflowUploadId, setWorkflowUploadId] = useState('');
  const [dataType, setDataType] = useState(MANUAL_ADD_TYPE);

  const inputCsvRef = useRef<HTMLInputElement>(null);
  const inputTextRef = useRef<HTMLInputElement>(null);
  const inputJsonRef = useRef<HTMLInputElement>(null);
  const inputImageRef = useRef<HTMLInputElement>(null);
  const inputAudioRef = useRef<HTMLInputElement>(null);
  const inputPdfRef = useRef<HTMLInputElement>(null);

  const [disabledButton, setDisabledButton] = useState({
    [PDF_FILE]: true,
    [IMAGE_FILE]: true,
    [AUDIO_FILE]: true,
  });

  const [event, setEvent] = useState<any>(null);

  const [dataFormat, setDataFormat] = useState<any>({ label: 'List of inputs', value: STRUCTURED_DATA });
  const [alertMsg, setAlertMsg] = useState({ show: false, text: '' });
  const [confirmModal, setConfirmModal] = useState<any>({ show: false, text: '', type: null });

  const [deleteUrlArr, setDeleteUrlArr] = useState<string[]>([]);
  const [uniqueFileId, setUniqueFileId] = useState<string[]>([]);

  const [uploadDataFiles, setUploadDataFiles] = useState<UploadDataFilesType[]>([]);

  const [fieldList, setFieldList] = useState<CommonOptionType[]>([]);
  const [csvColumnsOptions, setCsvColumnsOptions] = useState<CommonOptionType[]>([]);
  const [selectedcsvColumns, setSelectedcsvColumns] = useState<CommonOptionType[]>([]);
  const [jsonKeysOptions, setJsonKeysOptions] = useState<CommonOptionType[]>([]);
  const [selectedjsonKeys, setSelectedjsonKeys] = useState<CommonOptionType[]>([]);
  const [shouldUpgrade, setShouldUpgrade] = useState(false);

  const [getCsvColumnsQuery, { data: csvCoulumnsData, loading: getCsvLoading }] = useLazyQuery(GET_CSV_COUMNS, {
    fetchPolicy: 'network-only',
  });

  const [getJsonKeysQuery, { data: jsonKeysData, loading: getJsonLoading }] = useLazyQuery(GET_JSON_KEYS, {
    fetchPolicy: 'network-only',
  });

  // const { data: uploadSub } = useSubscription(FILE_UPLOAD_SUBSCRIPTION, {
  //   skip: !hexomaticUser?.id,
  //   fetchPolicy: 'network-only',
  //   variables: {
  //     hcUserId: hexomaticUser.id,
  //     fileId: uniqueFileId[fileIdIndex],
  //   },
  // });

  // const { data: deleteSub } = useSubscription(FILE_DELETE_SUBSCRIPTION, {
  //   skip: !hexomaticUser?.id,
  //   fetchPolicy: 'network-only',
  //   variables: {
  //     hcUserId: hexomaticUser.id,
  //   },
  // });

  const { data: storageLimitSub } = useSubscription(FILE_UPLOAD_LIMIT_REACHED_SUBSCRIPTION, {
    skip: !hexomaticUser?.id,
    fetchPolicy: 'network-only',
    variables: {
      hcUserId: hexomaticUser.id,
    },
  });

  // !via socket
  // useEffect(() => {
  //   if (uploadSub?.fileUploadEvent?.error) {
  //     const { name, size } = uploadSub?.fileUploadEvent;
  //     const obj = {
  //       name,
  //       size,
  //       url: '',
  //       error: true,
  //     };
  //     setUploadDataFiles([...uploadDataFiles, obj]);
  //     fileIdIndex < uniqueFileId.length - 1 && setFileIdIndex(prev => prev + 1);
  //     if (fileIdIndex === uniqueFileId.length - 1) {
  //       setFileIdIndex(0);
  //       setUniqueFileId([]);
  //     }
  //     return;
  //   }

  //   if (uploadSub?.fileUploadEvent && !uploadSub?.fileUploadEvent?.error) {
  //     const { name, size, fileUrl } = uploadSub?.fileUploadEvent;

  //     const obj = {
  //       name,
  //       size,
  //       url: fileUrl,
  //     };

  //     setUploadDataFiles([...uploadDataFiles, obj]);

  //     fileIdIndex < uniqueFileId.length - 1 && setFileIdIndex(prev => prev + 1);
  //     if (fileIdIndex === uniqueFileId.length - 1) {
  //       setFileIdIndex(0);
  //       setUniqueFileId([]);
  //     }
  //     return;
  //   }
  // }, [uploadSub]);

  // !via socket
  // useEffect(() => {
  //   if (deleteSub?.fileDeleteEvent?.error) {
  //     setAlertMsg({ show: true, text: 'Something went wrong, could not delete' });
  //     return;
  //   }

  //   if (deleteSub?.fileDeleteEvent?.fileUrl && deleteSub?.fileDeleteEvent?.deleted) {
  //     const newArr = uploadDataFiles.filter(
  //       (item: UploadDataFilesType) => item.url !== deleteSub.fileDeleteEvent.fileUrl,
  //     );
  //     setUploadDataFiles(newArr);
  //   }
  // }, [deleteSub]);

  const handleRemove = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setErrorMessage('');
    if (inputCsvRef?.current?.value) {
      inputCsvRef.current.value = '';
    }
    setEvent(null);
  };

  useEffect(() => {
    if (storageLimitSub?.storageLimitEvent?.storageLimitReached) {
      setAlertMsg({ show: true, text: 'Storage limit reached' });
      setLoading(false);
    }
  }, [storageLimitSub]);

  const {
    getPreviousAutomationOutputOrPreviousRecipKeys,
    liftingSettings,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (!dataFormat) {
      return false;
    }
    if (dataType === MANUAL_ADD_TYPE) {
      if (
        (dataFormat?.value === TEXT && !inputTextValue.trim()) ||
        (dataFormat?.value === STRUCTURED_DATA && !inputValue.trim())
      ) {
        return false;
      }
    }
    if (dataType === PASTE_URL_TYPE) {
      if (
        (dataFormat?.value === TEXT_FILE && !inputTextValue.trim()) ||
        (dataFormat?.value === JSON_FILE && !inputJsonValue.trim()) ||
        (dataFormat?.value === TEXT_FILE && inputTextValue.indexOf('.txt') < 0)
      ) {
        return false;
      }

      if (dataFormat?.value === CSV_FILE && !inputValue.trim()) {
        return false;
      }
      if (dataFormat?.value === CSV_FILE && !selectedcsvColumns.length) {
        return false;
      }
      if (dataFormat?.value === JSON_FILE && !selectedjsonKeys.length) {
        return false;
      }
    }
    if (dataType === UPLOAD_FILE_TYPE) {
      if (!uploadData && !uploadDataFiles.length) {
        return false;
      }

      if (dataFormat?.value === CSV_FILE && !selectedcsvColumns.length) {
        return false;
      }
      if (dataFormat?.value === JSON_FILE && !selectedjsonKeys.length) {
        return false;
      }
    }
    return true;
  };

  const findFormat = (type: string) => {
    const found = formatOptions.find(item => item.value === type);

    if (!found) {
      const foundInSecond = formatManuallOptions.find(item => item.value === type);
      return foundInSecond;
    }
    return found;
  };

  const oldModeRestore = (dynamic_settings: any) => {
    const { data, type, url } = dynamic_settings;
    const foundFormat = findFormat(type);
    foundFormat && setDataFormat(foundFormat);
    if (url) {
      setUploadData(url);
      setFileName(url);
    } else if (type === TEXT) {
      setInputTextValue(data[0]);
    } else if (type === JSON_FILE) {
      setInputJsonValue(data[0]);
    } else {
      setInputValue(data.join('\n'));
    }
  };

  useEffect(() => {
    if (!clicked) return;
    setDataFormat(null);
    csvColumnsOptions.length && setCsvColumnsOptions([]);
    selectedcsvColumns.length && setSelectedcsvColumns([]);
    jsonKeysOptions.length && setJsonKeysOptions([]);
    selectedjsonKeys.length && setSelectedjsonKeys([]);
    errorMessage && setErrorMessage('');
  }, [dataType]);

  useEffect(() => {
    errorMessage && setErrorMessage('');
    if (dataFormat?.value === CSV_FILE || dataFormat?.value === TEXT_FILE || dataFormat?.value === JSON_FILE) {
      setDisabledButton({ [AUDIO_FILE]: true, [PDF_FILE]: true, [IMAGE_FILE]: true });
    }
  }, [dataFormat]);

  //! Restore data
  useEffect(() => {
    if (automationSendSettings) {
      const currentAutomation = automationSendSettings.find(
        (automation: any, index: number) => index === automationIndex,
      );

      if (currentAutomation.dynamic_settings) {
        const { dynamic_settings } = currentAutomation;
        const { keys } = dynamic_settings;
        if (!keys) {
          //must be removed in 2022
          oldModeRestore(dynamic_settings);
          return;
        }

        const { inputValue, settings } = keys[0];
        const {
          type,
          columns,
          jsonKeys,
          inputType,
          uploadedFiles,
          workflowUploadId,
          storage,
          useCsvFirstRowsAsColumnHeaders,
          enableCsvCustomColumHeaders,
        } = settings;
        workflowUploadId && setWorkflowUploadId(workflowUploadId);
        storage && setStorage(storage);

        if (inputType) {
          if (inputType === UPLOAD_FILE_TYPE) {
            if (
              type === CSV_FILE ||
              type === TEXT_FILE ||
              type === IMAGE_FILE ||
              type === AUDIO_FILE ||
              type === PDF_FILE ||
              type === JSON_FILE
            ) {
              uploadedFiles?.length &&
                setUploadDataFiles(uploadedFiles.filter((item: UploadDataFilesType) => item.url !== ''));

              !Array.isArray(inputValue) && setUploadData(inputValue);
              !Array.isArray(inputValue) && setFileName(inputValue);

              if (type === PDF_FILE || type === AUDIO_FILE || type === IMAGE_FILE) {
                setDisabledButton({ ...disabledButton, [type]: false });
              }
            }
            if (type === CSV_FILE) {
              typeof useCsvFirstRowsAsColumnHeaders === 'boolean' &&
                setUseCsvFirstRowsAsColumnHeaders(useCsvFirstRowsAsColumnHeaders);
              typeof enableCsvCustomColumHeaders === 'boolean' &&
                setEnableCsvCustomColumHeaders(enableCsvCustomColumHeaders);
              inputValue && getCsvColumns(inputValue, enableCsvCustomColumHeaders);
            }

            if (type === JSON_FILE) {
              inputValue && getJsonKeys(inputValue);
            }
          }
          if (inputType === PASTE_URL_TYPE) {
            type === TEXT_FILE && setInputTextValue(inputValue || '');
            if (type === CSV_FILE) {
              setInputValue(inputValue || '');
              typeof useCsvFirstRowsAsColumnHeaders === 'boolean' &&
                setUseCsvFirstRowsAsColumnHeaders(useCsvFirstRowsAsColumnHeaders);
              typeof enableCsvCustomColumHeaders === 'boolean' &&
                setEnableCsvCustomColumHeaders(enableCsvCustomColumHeaders);
              inputValue && getCsvColumns(inputValue, enableCsvCustomColumHeaders);
            }
            if (type === JSON_FILE) {
              inputValue && getJsonKeys(inputValue);
              setInputJsonValue(inputValue || '');
            }
          }
          if (inputType === MANUAL_ADD_TYPE) {
            type === TEXT && setInputTextValue(inputValue[0] || '');
            type === STRUCTURED_DATA && setInputValue(inputValue.join('\n'));
          }
          setDataType(inputType);
          const foundFormat = findFormat(type);
          foundFormat && setDataFormat(foundFormat);

          if (jsonKeys) {
            const options = jsonKeys.map((col: string) => ({ label: col, value: col }));
            setSelectedjsonKeys(options);
          }
          if (columns) {
            const options = columns.map((col: string) => ({ label: col, value: col }));
            setSelectedcsvColumns(options);
          }
        } else {
          // for old data input  version
          // can be deleted on 06/06/2022 =)
          if (type === TEXT) {
            setDataType(MANUAL_ADD_TYPE);
            setInputTextValue(inputValue[0]);
          }
          if (type === STRUCTURED_DATA) {
            setDataType(MANUAL_ADD_TYPE);
            setInputValue(inputValue.join('\n'));
          }
          if (type === TEXT_FILE || type === CSV_FILE) {
            setDataType(UPLOAD_FILE_TYPE);
            setUploadData(inputValue);
            if (type === CSV_FILE) {
              inputValue && getCsvColumns(inputValue);
              setSelectedcsvColumns([{ label: 'A', value: 'A' }]);
            } else if (type === JSON_FILE) {
              inputValue && getJsonKeys(inputValue);
              setSelectedjsonKeys([{ label: 'A', value: 'A' }]);
            }
          }
          const foundFormat = findFormat(type);
          foundFormat && setDataFormat(foundFormat);
        }
      }
    }
  }, []);
  //! Getting previous outputs
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    // setFieldList(options);
  }, []);

  useEffect(() => {
    const vals = [
      ...(dataFormat?.value === CSV_FILE || dataFormat?.value === JSON_FILE ? [] : ['_data_input']),
      ...selectedcsvColumns.map((item: { value: string }) => `_data_input_${item.value}`),
      ...selectedjsonKeys.map((item: { value: string }) => `_data_input_${item.value}`),
    ];
    const json: any = {};

    vals.forEach((item: any) =>
      dataFormat?.value === AUDIO_FILE || dataFormat?.value === IMAGE_FILE || dataFormat?.value === PDF_FILE
        ? (json[item] = 'url')
        : dataFormat?.value === TEXT_FILE || dataFormat?.value === TEXT
        ? (json[item] = 'text')
        : (json[item] = 'any'),
    );

    if(dataFormat?.value === STRUCTURED_DATA && !inputValue) {
      return
    }
    if (dataFormat?.value) {
      liftingSettings({
        outputKeysData: [
          ...fieldList.map((item: { value: any }) => item.value),
          ...(dataFormat.value === CSV_FILE || dataFormat.value === JSON_FILE ? [] : ['_data_input']),
          ...selectedcsvColumns.map((item: { value: string }) => `_data_input_${item.value}`),
          ...selectedjsonKeys.map((item: { value: string }) => `_data_input_${item.value}`),
        ],
        outputKeysDataWithTypes: { ...json },
      });
    }
  }, [fieldList, selectedcsvColumns, selectedjsonKeys, dataFormat]);

  //! Lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });

    let input: string | string[] | null = null;
    if (dataType === MANUAL_ADD_TYPE) {
      if (dataFormat?.value === TEXT) {
        input = [inputTextValue];
      }
      if (dataFormat?.value === STRUCTURED_DATA) {
        input = inputValue
          .split('\n')
          .map(item => item.trim())
          .filter(item => item !== '');
      }
    }
    if (dataType === PASTE_URL_TYPE) {
      input = inputValue || inputTextValue || inputJsonValue;
    }

    if (dataType === UPLOAD_FILE_TYPE && uploadDataFiles.length) {
      input = uploadDataFiles.map(elem => elem.url);
    }

    const sortedCsvColumns = [...selectedcsvColumns];

    if (!enableCsvCustomColumHeaders) {
      sortedCsvColumns.sort((a: CommonOptionType, b: CommonOptionType) => {
        if (a.value < b.value) return -1;
        if (a.value > b.value) return 1;
        return 0;
      });
    }

    const localSettings = {
      keys: [
        {
          key: null,
          inputValue: uploadData || input || null,
          settings: {
            type: dataFormat?.value,
            jsonKeys: selectedjsonKeys.map((item: { value: string }) => item.value),
            columns: sortedCsvColumns.map((item: { value: string }) => item.value),
            inputType: dataType,
            workflowUploadId: workflowUploadId || null,
            storage: storage || null,
            uploadedFiles: uploadDataFiles || null,
            useCsvFirstRowsAsColumnHeaders,
            enableCsvCustomColumHeaders,
          },
        },
      ],
    };
    const vals = [
      ...(dataFormat?.value === CSV_FILE || dataFormat?.value === JSON_FILE ? [] : ['_data_input']),
      ...sortedCsvColumns.map((item: { value: string }) => `_data_input_${item.value}`),
      ...selectedjsonKeys.map((item: { value: string }) => `_data_input_${item.value}`),
    ];
    const json: any = {};
    vals.map((item: any) =>
      dataFormat?.value === AUDIO_FILE || dataFormat?.value === IMAGE_FILE || dataFormat?.value === PDF_FILE
        ? (json[item] = 'url')
        : dataFormat?.value === TEXT_FILE || dataFormat?.value === TEXT
        ? (json[item] = 'text')
        : (json[item] = 'any'),
    );

    if (dataFormat?.value) {
      liftingSettings({
        dynamicSettings: localSettings,
        outputKeysData: [
          ...fieldList.map((item: { value: any }) => item.value),
          ...(dataFormat.value === CSV_FILE || dataFormat.value === JSON_FILE ? [] : ['_data_input']),
          ...sortedCsvColumns.map((item: { value: string }) => `_data_input_${item.value}`),
          ...selectedjsonKeys.map((item: { value: string }) => `_data_input_${item.value}`),
        ],
        outputKeysDataWithTypes: { ...json },
      });
    }
  }, [
    inputValue,
    workflowUploadId,
    inputTextValue,
    inputJsonValue,
    uploadData,
    dataType,
    dataFormat,
    selectedcsvColumns,
    selectedjsonKeys,
    uploadDataFiles,
    useCsvFirstRowsAsColumnHeaders,
    enableCsvCustomColumHeaders,
    storage,
  ]);

  // manipulations -----------
  const handleTextAreaCSV = (e: { target: HTMLInputElement }) => {
    const target = e.target as HTMLInputElement;
    setInputValue(target.value);
    setClicked(true);
    setIsDirty();
  };

  const handleTextAreaTXT = (e: { target: HTMLInputElement }) => {
    const target = e.target as HTMLInputElement;
    setInputTextValue(target.value);
    setClicked(true);
    setIsDirty();
  };

  const handleFileUpload = async (e: { target: { files: any } }) => {
    setInputTextValue('');
    setInputJsonValue('');
    setInputValue('');

    setConfirmModal({ show: false, text: '', type: null });

    const files: Blob[] = Array.from(e.target.files);
    if (!files.length) {
      return;
    }

    // if (files.length > 10) {
    //   setAlertMsg({ show: true, text: 'Maximum file count is 10' });
    //   return;
    // }
    const isMaxSize = files.some((file: any) => file.size >= 500000000);

    if (isMaxSize) {
      setAlertMsg({ show: true, text: 'Maximum file size is 500MB' });
      return;
    }

    const type =
      dataFormat?.value === IMAGE_FILE
        ? [
            'image/jpeg',
            'image/png8',
            'image/png24',
            'image/png',
            'image/gif',
            'image/bmp',
            'image/webp',
            'image/raw',
            'image/ico',
            'image/jpg',
          ]
        : dataFormat?.value === AUDIO_FILE
        ? ['audio/wav', 'audio/mp3', 'audio/flac', 'audio/mpeg']
        : dataFormat?.value === PDF_FILE
        ? ['application/pdf']
        : [];

    if (files.find((item: any) => !type.includes(item.type))) {
      setAlertMsg({
        show: true,
        text: `Please upload supported file type ${
          dataFormat?.value === IMAGE_FILE
            ? '(.jpeg, .png8, .png24, .png, .gif, .bmp, .webp, .raw, .ico, .jpg)'
            : dataFormat?.value === AUDIO_FILE
            ? '(.wav, .mp3, .flac)'
            : '(.pdf)'
        }.`,
      });
      return;
    }

    const data = new FormData();
    const stringArr: string[] = [];

    data.append('iv', encrypt(hexomaticUser?.id?.toString()).iv);
    data.append('content', encrypt(hexomaticUser?.id?.toString()).content);
    workflowUploadId && data.append('workflowUploadId', workflowUploadId);
    workflowId && data.append('workflowId', workflowId);

    files.forEach((file: any) => {
      const files_id = uuidv4();

      stringArr.push(files_id);
      data.append('files', file);
    });

    data.append('fileIds', JSON.stringify(stringArr));

    setUniqueFileId(stringArr);
    setLoading(true);

    try {
      const res = await axios(`${HOST.APP_API.UPLOAD}/uploader/app/upload`, {
        method: 'POST',
        data,
      });
      if (res && res.status === 200 && res?.data?.uploadedFiles?.length) {
        const limiteReached = res.data.uploadedFiles.find(
          (item: any) => item.errorCode === 'hexomatic data storage limit reached.',
        );
        limiteReached && setShouldUpgrade(true);
        const { data } = res;
        setClicked(true);
        setIsDirty();

        //!delete if tests passed
        if (workflowUploadId) {
          setUploadDataFiles([...uploadDataFiles, ...data.uploadedFiles]);
        } else {
          setUploadDataFiles(data.uploadedFiles);
        }

        //! we should get and set storage for calculation
        data.storage && setStorage(data.storage + storage);
        data.workflowUploadId && setWorkflowUploadId(data.workflowUploadId);
        setLoading(false);
      } else if (res?.data?.uploadedFiles?.length === 0) {
        setAlertMsg({ show: true, text: 'Could not upload' });
      }
    } catch (e: any) {
      console.log(e);
      setLoading(false);
      setAlertMsg({ show: true, text: e?.response?.data?.error?.message || e?.message });
    }
  };

  const onUpload = async (e: { target: { files: any } }, csv?: boolean) => {
    setInputTextValue('');
    setInputJsonValue('');

    setInputValue('');
    setConfirmModal({ show: false, text: '', type: null });
    const files = e?.target?.files;
    if (!files?.[0]) {
      return;
    }
    if (files[0] && files[0].size >= 500000000) {
      setAlertMsg({ show: true, text: 'Maximum file size is 500MB' });
      return;
    }
    const type =
      dataFormat?.value === TEXT_FILE
        ? 'text/plain'
        : dataFormat?.value === JSON_FILE
        ? 'application/json'
        : 'text/csv';
    if (files[0].type !== type) {
      if (
        csv &&
        (files[0].type === 'application/vnd.ms-excel' ||
          files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
      ) {
      } else {
        setAlertMsg({
          show: true,
          text: `Please upload a valid ${
            type === 'text/plain' ? '.txt' : type === 'application/json' ? '.json' : '.csv'
          } file.`,
        });
        return;
      }
    }
    const files_id = [uuidv4()];

    const data = new FormData();
    data.append('iv', encrypt(hexomaticUser?.id?.toString()).iv);
    data.append('content', encrypt(hexomaticUser?.id?.toString()).content);
    data.append('files', files[0]);
    data.append('fileIds', JSON.stringify(files_id));

    data.append('enableCsvCustomColumHeaders', JSON.stringify(enableCsvCustomColumHeaders));
    data.append('useCsvFirstRowsAsColumnHeaders', JSON.stringify(useCsvFirstRowsAsColumnHeaders));

    workflowId && data.append('workflowId', workflowId);

    setFileName(files[0].name);
    setUniqueFileId(files_id);
    setLoading(true);
    try {
      const res = await axios(`${HOST.APP_API.UPLOAD}/uploader/app/upload`, {
        method: 'POST',
        data,
      });
      if (res && res.status === 200 && res.data && res.data.uploadedFiles && res.data.uploadedFiles.length) {
        const limiteReached = res.data.uploadedFiles.find(
          (item: any) => item.errorCode === 'hexomatic data storage limit reached.',
        );
        if (res.data.uploadedFiles[0]?.error) {
          const errorCode: string = res.data.uploadedFiles[0].errorCode;
          limiteReached
            ? setShouldUpgrade(true)
            : setAlertMsg({ show: true, text: errorCodes[errorCode] || 'Something went wrong' });
          setLoading(false);
          setEvent(null);
          if (inputCsvRef?.current?.value) {
            inputCsvRef.current.value = '';
          }
          return;
        }

        const { data } = res;
        if (dataFormat?.value === CSV_FILE && data.uploadedFiles[0]?.columns) {
          const options = data.uploadedFiles[0].columns.map((col: string) => ({ label: col, value: col }));
          setCsvColumnsOptions(options);
          if (inputCsvRef?.current?.value) {
            inputCsvRef.current.value = '';
          }
          setEvent(null);
        }

        if (dataFormat?.value === JSON_FILE && data.uploadedFiles[0]?.keys) {
          const options = data.uploadedFiles[0].keys.map((key: string) => ({
            label: key.split('.').join('_'),
            value: key.split('.').join('_'),
          }));
          setJsonKeysOptions(options);
        }

        setClicked(true);
        setIsDirty();

        setUploadData(data.uploadedFiles[0].url);
        setCsvOrTxtSize(data.uploadedFiles[0].size);
        setFileName(data.uploadedFiles[0].name);

        // !delete if tests passed
        setUploadDataFiles([
          ...uploadDataFiles,
          { url: data.uploadedFiles[0].url, name: data.uploadedFiles[0].name, size: data.uploadedFiles[0].size },
        ]);

        //! we should get and set storage for calculation

        data.storage && setStorage(data.storage + storage);
        data.workflowUploadId && setWorkflowUploadId(data.workflowUploadId);
        setLoading(false);
      } else if (res?.data?.uploadedFiles?.length === 0) {
        setAlertMsg({ show: true, text: 'Could not upload' });
        setLoading(false);
      }
    } catch (e: any) {
      setLoading(false);
      setAlertMsg({ show: true, text: e?.response?.data?.error?.message || e?.message });
    }
  };

  const getCsvColumns = (propUrl?: string, enableHeaders?: boolean) => {
    const url = propUrl || inputValue;
    if (!url) return;
    if (url.indexOf('.csv') < 0) {
      setAlertMsg({ show: true, text: PAST_CSV_ERROR });
      return;
    }
    getCsvColumnsQuery({
      variables: {
        settings: {
          csvUrl: url,
          enableCsvCustomColumHeaders: enableHeaders,
        },
      },
    });
  };

  const getJsonKeys = (propUrl?: string) => {
    const url = propUrl || inputValue;
    if (!url) return;
    // if (url.indexOf('.json') < 0) {
    //   setAlertMsg({ show: true, text: PAST_JSON_ERROR });
    //   return;
    // }
    getJsonKeysQuery({
      variables: {
        settings: {
          jsonUrl: url,
        },
      },
    });
  };

  useEffect(() => {
    if (csvCoulumnsData?.HexomaticWorkflow?.getCSVColumns) {
      const { columns, error_code } = csvCoulumnsData.HexomaticWorkflow.getCSVColumns;
      if (error_code) {
        setAlertMsg({ show: true, text: error_code });
      } else {
        const options = columns.map((col: string) => ({ label: col, value: col }));
        setCsvColumnsOptions(options);
      }
    }
  }, [csvCoulumnsData]);

  useEffect(() => {
    if (jsonKeysData?.HexomaticWorkflow?.getJsonKeys) {
      const { keys, error } = jsonKeysData.HexomaticWorkflow.getJsonKeys;

      if (error) {
        setAlertMsg({ show: true, text: error });
        return;
      }
      if (dataType === PASTE_URL_TYPE) {
        if (!keys.length) {
          setAlertMsg({ show: true, text: 'Parsing failed' });
          return;
        }
      }

      if (keys.length) {
        const options = keys.map((key: string) => ({
          label: key.split('.').join('_'),
          value: key.split('.').join('_'),
        }));
        setJsonKeysOptions(options);
      }
    }
  }, [jsonKeysData]);

  const deleteFile = async (): Promise<void> => {
    setConfirmModal({ show: false, text: '', type: null });

    let urls: string[] | null = null;

    if (deleteUrl) {
      urls = [deleteUrl];
    }
    if (deleteUrlArr.length) {
      urls = deleteUrlArr;
    }

    try {
      setLoading(true);
      const res = await axios(`${HOST.APP_API.APP}/delete-files`, {
        method: 'DELETE',
        data: {
          iv: encrypt(hexomaticUser?.id?.toString()).iv,
          content: encrypt(hexomaticUser?.id?.toString()).content,
          workflowUploadId,
          fileUrls: urls,
          workflowId,
        },
      });

      if (res.status === 200 && !res.data?.error) {
        setLoading(false);
        setConfirmModal({ show: false, text: '', type: null });
        setClicked(true);
        setIsDirty();

        setDeleteUrlArr([]);
        setUploadData(null);
        setFileName('');
        csvColumnsOptions.length && setCsvColumnsOptions([]);
        selectedcsvColumns.length && setSelectedcsvColumns([]);
        jsonKeysOptions.length && setJsonKeysOptions([]);
        selectedjsonKeys.length && setSelectedjsonKeys([]);
        setEvent(null);
        if (inputCsvRef?.current?.value) {
          inputCsvRef.current.value = '';
        }

        if (uploadDataFiles.length === urls?.length || !urls) {
          setUploadDataFiles([]);
          setUniqueFileId([]);
          setFileIdIndex(0);
          return;
        }
        const arr = [...uploadDataFiles];
        if (arr.length) {
          const newArr = arr.filter((item: UploadDataFilesType) => {
            const url = urls?.find((urlItem: string) => urlItem === item.url);
            return item.url !== url;
          });
          setUploadDataFiles(newArr);
          setUniqueFileId([]);
          setFileIdIndex(0);
        }
      }
    } catch (e) {
      setLoading(false);
      setAlertMsg({ show: true, text: 'Something went wrong' });
      console.log(e);
    }
  };

  const clickToUpload = (format: string) => {
    // if (format === CSV_FILE && inputCsvRef && inputCsvRef.current) {
    // inputCsvRef.current.click();
    // }
    if (format === TEXT_FILE && inputTextRef && inputTextRef.current) {
      inputTextRef.current.click();
    }
    if (format === JSON_FILE && inputJsonRef && inputJsonRef.current) {
      inputJsonRef.current.click();
    }
    if (format === IMAGE_FILE && inputImageRef && inputImageRef.current) {
      setDisabledButton({ [AUDIO_FILE]: true, [PDF_FILE]: true, [IMAGE_FILE]: false });
      inputImageRef.current.click();
    }
    if (format === AUDIO_FILE && inputAudioRef && inputAudioRef.current) {
      setDisabledButton({ [AUDIO_FILE]: false, [PDF_FILE]: true, [IMAGE_FILE]: true });
      inputAudioRef.current.click();
    }
    if (format === PDF_FILE && inputPdfRef && inputPdfRef.current) {
      setDisabledButton({ [AUDIO_FILE]: true, [PDF_FILE]: false, [IMAGE_FILE]: true });
      inputPdfRef.current.click();
    }
  };

  const onConfirm = () => {
    setClicked(true);
    setIsDirty();

    confirmModal.show && setConfirmModal({ show: false, text: '', type: '', callback: null });
    inputValue && setInputValue('');
    inputTextValue && setInputTextValue('');
    inputJsonValue && setInputJsonValue('');
    if (dataType === UPLOAD_FILE_TYPE) {
      clickToUpload(dataFormat?.value);
    }
  };

  const CheckBeforeChangeDataTypeOrFormat = ({ format, callback }: { format?: string; callback: any }) => {
    if (inputValue || inputTextValue || inputJsonValue) {
      const cb = () => {
        csvColumnsOptions.length && setCsvColumnsOptions([]);
        selectedcsvColumns.length && setSelectedcsvColumns([]);
        jsonKeysOptions.length && setJsonKeysOptions([]);
        selectedjsonKeys.length && setSelectedjsonKeys([]);
        callback();
      };
      setConfirmModal({ show: true, text: 'Your entered data will be lost', type: 'confirm', callback: cb });
    } else {
      callback();
      csvColumnsOptions.length && setCsvColumnsOptions([]);
      selectedcsvColumns.length && setSelectedcsvColumns([]);
      jsonKeysOptions.length && setJsonKeysOptions([]);
      selectedjsonKeys.length && setSelectedjsonKeys([]);
      if (dataType === UPLOAD_FILE_TYPE && format) {
        clickToUpload(format);
      }
    }
  };

  const handleSelect = (selectedItem: { label: string; value: string }): void => {
    if (dataType !== UPLOAD_FILE_TYPE && selectedItem.value === dataFormat?.value) {
      return;
    }
    CheckBeforeChangeDataTypeOrFormat({
      format: selectedItem.value,
      callback: () => {
        setDataFormat(selectedItem);
        !clicked && setClicked(true);
        setIsDirty();
      },
    });
  };

  const onCSVFileSelect = (e: any) => {
    const file = e?.target?.files?.[0];
    if (!file) {
      return;
    }
    if (
      file &&
      file.type !== 'text/csv' &&
      file.type !== '.xlsx' &&
      file.type !== '.xls' &&
      file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
      file.type !== 'application/vnd.ms-excel'
    ) {
      setErrorMessage(`Please upload supported file type (current type: ${file.type})`);
      return;
    }
    if (file && file.size >= 500000000) {
      setErrorMessage('Maximum file size is 500MB');
      return;
    }
    setDataFormat({ label: 'CSV file', value: CSV_FILE });
    handleSelect({ label: 'CSV file', value: CSV_FILE });
    setErrorMessage('');
    setEvent(e);
  };

  const cancelClick = () => {
    setShouldUpgrade(false);
  };

  const actionClick = () => {
    setShouldUpgrade(false);
    window.location.href = 'https://hexomatic.com/pricing/';
  };

  return (
    <div className="data-input-settings">
      <Row>
        <Col lg={12} style={{ marginBottom: '-25px' }}>
          <div className="input-header-page-wrapper h-75">
            <button
              disabled={!!uploadData?.length || !!uploadDataFiles.length || getCsvLoading || getJsonLoading || loading}
              className={`input-header ${dataType === MANUAL_ADD_TYPE ? 'active' : ''} `}
              onClick={() => CheckBeforeChangeDataTypeOrFormat({ callback: () => setDataType(MANUAL_ADD_TYPE) })}
            >
              Manual paste
            </button>
            <button
              disabled={!!uploadData?.length || !!uploadDataFiles.length || getCsvLoading || getJsonLoading || loading}
              className={`input-header ${dataType === UPLOAD_FILE_TYPE ? 'active' : ''} `}
              onClick={() => CheckBeforeChangeDataTypeOrFormat({ callback: () => setDataType(UPLOAD_FILE_TYPE) })}
            >
              Upload file
            </button>
            <button
              disabled={!!uploadData?.length || !!uploadDataFiles.length || getCsvLoading || getJsonLoading || loading}
              className={`input-header ${dataType === PASTE_URL_TYPE ? 'active' : ''} `}
              onClick={() => CheckBeforeChangeDataTypeOrFormat({ callback: () => setDataType(PASTE_URL_TYPE) })}
            >
              File URL
            </button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="mb-2 input-files-wrapper">
          {dataType === MANUAL_ADD_TYPE ? (
            <>
              <button
                onClick={() => handleSelect({ label: 'List of inputs', value: STRUCTURED_DATA })}
                className={`inp-file ${dataFormat?.value === STRUCTURED_DATA ? 'active' : ''}`}
              >
                <img src={listOfinputsImage} alt="list of inputs" />
                <span>LIST OF INPUTS</span>
              </button>
              <button
                onClick={() => handleSelect({ label: 'Text', value: TEXT })}
                className={`inp-file ${dataFormat?.value === TEXT ? 'active' : ''}`}
              >
                <img src={txtPasteImage} alt="pdf" />
                <span>TXT</span>
              </button>
            </>
          ) : null}

          {dataType !== UPLOAD_FILE_TYPE && dataType !== MANUAL_ADD_TYPE ? (
            <>
              {formatOptionsWithImage.map((el: CommonOptionTypeImage) => (
                <button
                  key={el.name}
                  onClick={() => handleSelect({ label: el.label, value: el.value })}
                  className={`inp-file ${dataFormat?.value === el.value ? 'active' : ''}`}
                  disabled={!!uploadData || !!uploadDataFiles.length}
                >
                  <img src={el.image} alt={el.name} />
                  <span>{el.name}</span>
                </button>
              ))}
            </>
          ) : null}

          {dataType === UPLOAD_FILE_TYPE ? (
            <>
              <button
                disabled={!!uploadData || !!uploadDataFiles.length || loading}
                // onClick={() => handleSelect({ label: 'CSV file', value: CSV_FILE })}
                onClick={() => setisOpenCSVModal(true)}
                className={`inp-file ${dataFormat?.value === CSV_FILE ? 'active' : ''}
                ${!!uploadData || !!uploadDataFiles.length || loading ? 'opacity' : ''}`}
              >
                <img src={csvImage} alt="csv" />
                <span>CSV</span>
              </button>
              <button
                disabled={!!uploadData || !!uploadDataFiles.length || loading}
                onClick={() => handleSelect({ label: 'TXT file', value: TEXT_FILE })}
                className={`inp-file ${dataFormat?.value === TEXT_FILE ? 'active' : ''}
                ${!!uploadData || !!uploadDataFiles.length || loading ? 'opacity' : ''}`}
              >
                <img src={txtImage} alt="txt" />
                <span>TXT</span>
              </button>
              <button
                disabled={!!uploadData || !!uploadDataFiles.length || loading}
                onClick={() => handleSelect({ label: 'JSON', value: JSON_FILE })}
                className={`inp-file ${dataFormat?.value === JSON_FILE ? 'active' : ''} ${
                  !!uploadData || !!uploadDataFiles.length || loading ? 'opacity' : ''
                }`}
              >
                <img src={jsonImage} alt="json" />
                <span>JSON</span>
              </button>
              <button
                disabled={((!!uploadData || !!uploadDataFiles.length) && disabledButton[PDF_FILE]) || loading}
                onClick={() => handleSelect({ label: 'PDF', value: PDF_FILE })}
                className={`inp-file ${dataFormat?.value === PDF_FILE ? 'active' : ''} ${
                  ((!!uploadData || !!uploadDataFiles.length) && disabledButton[PDF_FILE]) || loading ? 'opacity' : ''
                }`}
              >
                <img src={pdfImage} alt="json" />
                <span>PDF</span>
              </button>
              <button
                disabled={((!!uploadData || !!uploadDataFiles.length) && disabledButton[IMAGE_FILE]) || loading}
                onClick={() => handleSelect({ label: 'IMAGE', value: IMAGE_FILE })}
                className={`inp-file ${dataFormat?.value === IMAGE_FILE ? 'active' : ''} ${
                  ((!!uploadData || !!uploadDataFiles.length) && disabledButton[IMAGE_FILE]) || loading ? 'opacity' : ''
                }`}
              >
                <img src={imageImage} alt="image" />
                <span>IMAGE</span>
              </button>
              <button
                disabled={((!!uploadData || !!uploadDataFiles.length) && disabledButton[AUDIO_FILE]) || loading}
                onClick={() => handleSelect({ label: 'AUDIO', value: AUDIO_FILE })}
                className={`inp-file ${dataFormat?.value === AUDIO_FILE ? 'active' : ''} ${
                  ((!!uploadData || !!uploadDataFiles.length) && disabledButton[AUDIO_FILE]) || loading ? 'opacity' : ''
                }`}
              >
                <img src={audioImage} alt="audio" />
                <span>AUDIO</span>
              </button>
            </>
          ) : null}
        </Col>
      </Row>
      {!uploadData && (
        //! "It needs to mount and unmount for correct work" - quote by Vlad :)
        <>
          <input
            accept=".csv"
            type="file"
            id="file-upload-csv"
            name="file"
            ref={inputCsvRef}
            onChange={onCSVFileSelect}
          />
          <input
            accept="text/plain"
            type="file"
            id="file-upload-text"
            name="file"
            ref={inputTextRef}
            onChange={onUpload}
          />
          <input accept=".json" type="file" id="file-upload-json" name="file" ref={inputJsonRef} onChange={onUpload} />
        </>
      )}
      <>
        <input
          accept="application/pdf"
          type="file"
          id="file-upload-pdf"
          name="file"
          multiple
          ref={inputPdfRef}
          onChange={handleFileUpload}
        />
        <input
          type="file"
          id="file-upload-image"
          ref={inputImageRef}
          accept="image/jpeg, image/png8, image/png24, image/png, image/gif, image/bmp, image/webp, image/raw, image/ico, image/jpg"
          multiple
          onChange={handleFileUpload}
        />
        <input
          type="file"
          id="file-upload-audio"
          ref={inputAudioRef}
          accept="audio/mp3, audio/flac, audio/wav"
          multiple
          onChange={handleFileUpload}
        />
      </>
      {loading && <Loader />}
      {uploadData || uploadDataFiles.length ? (
        <UploadedFilesList
          data={
            uploadDataFiles && uploadDataFiles.length
              ? uploadDataFiles
              : uploadData
              ? [{ url: uploadData, size: csvOrTxtSize, name: fileName }]
              : []
          }
          dataFormat={dataFormat}
          setDeleteUrl={setDeleteUrl}
          setConfirmModal={setConfirmModal}
          setDeleteUrlArr={setDeleteUrlArr}
        />
      ) : null}
      {dataType === MANUAL_ADD_TYPE && (
        <Row>
          <Col xl={12}>
            {(dataFormat?.value === STRUCTURED_DATA || inputValue) && (
              <TextArea
                value={inputValue}
                placeholder="Paste a list of inputs or keywords"
                onChange={(e: any) => {
                  handleTextAreaCSV(e);
                  setClicked(true);
                }}
                infoText="Each new line input will be considered as one source."
              />
            )}
            {(dataFormat?.value === TEXT || inputTextValue) && (
              <TextArea
                value={inputTextValue}
                placeholder={'Paste a text or a single input'}
                onChange={(e: any) => {
                  handleTextAreaTXT(e);
                  !clicked && setClicked(true);
                }}
                infoText={'The whole input will be cosidered as one source.'}
              />
            )}
          </Col>
        </Row>
      )}
      {dataType === PASTE_URL_TYPE && (
        <Row>
          <Col xl={12}>
            {(dataFormat?.value === CSV_FILE || inputValue) && (
              <>
                <Input
                  value={inputValue}
                  onChange={e => {
                    setInputValue(e.target.value);
                    csvColumnsOptions.length && setCsvColumnsOptions([]);
                    selectedcsvColumns.length && setSelectedcsvColumns([]);
                    !clicked && setClicked(true);
                    setIsDirty();
                  }}
                />
                <div className="info-message-block">
                  <span className="info-icon">{infoIcon}</span>
                  <span className="info-message">After entering the link press "Parse File" button</span>
                </div>
                {!csvColumnsOptions.length && (
                  <div className="d-flex mt-3">
                    <Checkbox
                      checked={useCsvFirstRowsAsColumnHeaders}
                      className="mr-3"
                      labelRight="Include headers"
                      onChange={() => setUseCsvFirstRowsAsColumnHeaders(!useCsvFirstRowsAsColumnHeaders)}
                    />
                    <Checkbox
                      checked={enableCsvCustomColumHeaders}
                      labelRight="Map headers to column names"
                      onChange={() => setEnableCsvCustomColumHeaders(!enableCsvCustomColumHeaders)}
                    />
                  </div>
                )}
              </>
            )}
            {(dataFormat?.value === TEXT_FILE || inputTextValue) && (
              <Input
                value={inputTextValue}
                onChange={e => {
                  if (e.target.value.indexOf('.txt') < 0) {
                    setErrorMessage(PAST_TXT_ERROR);
                  } else {
                    errorMessage.includes(PAST_TXT_ERROR) && setErrorMessage('');
                  }
                  setInputTextValue(e.target.value);
                  !clicked && setClicked(true);
                  setIsDirty();
                }}
              />
            )}
            {(dataFormat?.value === JSON_FILE || inputJsonValue) && (
              <>
                <Input
                  value={inputJsonValue}
                  onChange={e => {
                    setInputJsonValue(e.target.value);
                    jsonKeysOptions.length && setJsonKeysOptions([]);
                    selectedjsonKeys.length && setSelectedjsonKeys([]);
                    !clicked && setClicked(true);
                    setIsDirty();
                  }}
                />
                <div className="info-message-block">
                  <span className="info-icon">{infoIcon}</span>
                  <span className="info-message">After entering the link press "Parse File" button</span>
                </div>
              </>
            )}
          </Col>
          {(dataFormat?.value === CSV_FILE || dataFormat?.value === JSON_FILE) && (
            <Col className="mt-3">
              <Button
                name="Parse file"
                disabled={(!inputValue && !inputJsonValue) || !!csvColumnsOptions.length || !!uploadDataFiles.length}
                loading={getCsvLoading || getJsonLoading}
                className="no-wrap m-1"
                onClick={() =>
                  dataFormat?.value === JSON_FILE
                    ? getJsonKeys(inputJsonValue)
                    : getCsvColumns(inputValue, enableCsvCustomColumHeaders)
                }
              />
            </Col>
          )}
        </Row>
      )}
      {csvColumnsOptions.length > 0 && (
        <Row className="mb-2 mt-3">
          <Col className="d-flex" lg={12}>
            <Checkbox
              checked={useCsvFirstRowsAsColumnHeaders}
              className="mr-3 mb-2 cursor-def"
              labelRight="Include headers"
              onChange={() => {}}
            />
            <Checkbox
              className="mb-2 cursor-def"
              checked={enableCsvCustomColumHeaders}
              labelRight="Map headers to column names"
              onChange={() => {}}
            />
          </Col>
          <Col lg={6}>
            <span className="label secondary-color">Select output columns</span>
            <CustomMultiSelect
              options={csvColumnsOptions}
              value={selectedcsvColumns}
              onChange={(sel: any) => {
                setSelectedcsvColumns(sel);
                !clicked && setClicked(true);
                setIsDirty();
              }}
              labelledBy="Select"
            />
          </Col>
        </Row>
      )}
      {jsonKeysOptions.length > 0 && (
        <Row className="mb-2 mt-3">
          <Col lg={6}>
            <span className="label secondary-color">Select output columns</span>
            <CustomMultiSelect
              options={jsonKeysOptions}
              value={selectedjsonKeys}
              onChange={(sel: any) => {
                setSelectedjsonKeys(sel);
                !clicked && setClicked(true);
                setIsDirty();
              }}
              labelledBy="Select"
            />
          </Col>
        </Row>
      )}
      {errorMessage && (
        <Row className="mt-4">
          <Col md={12}>
            <Alert type="error" className="create-workflow">
              {errorMessage}
            </Alert>
          </Col>
        </Row>
      )}
      {alertMsg.show && (
        <ModalComponent
          isModalOpen={alertMsg.show}
          text={alertMsg.text}
          actionBtnText={'OK'}
          actionBtnColor={'error'}
          onActionClick={() => setAlertMsg({ show: false, text: '' })}
          escButtonClick={() => setAlertMsg({ show: false, text: '' })}
        />
      )}
      {confirmModal.show && (
        <ModalComponent
          isModalOpen={confirmModal.show}
          text={confirmModal.text}
          actionBtnText={confirmModal.type === 'confirm' ? 'Continue' : 'Delete'}
          cancelBtnText={'Cancel'}
          actionBtnColor={'danger'}
          cancelBtnColor={'secondary'}
          loading={loading}
          onActionClick={() => {
            confirmModal.callback && confirmModal.callback();
            confirmModal.type === 'confirm' ? onConfirm() : deleteFile();
          }}
          onCancelClick={() => setConfirmModal({ show: false, text: '', type: null })}
          escButtonClick={() => setConfirmModal({ show: false, text: '', type: null })}
        />
      )}
      <ModalComponent
        isModalOpen={isOpenCSVModal}
        actionBtnText=""
        onActionClick={() => {}}
        actionBtnColor="transparent"
        onCloseModal={() => setisOpenCSVModal(false)}
        onlyBtns
        className="p-0"
        parentClass="import-csv-modal"
        escButtonClick={() => setisOpenCSVModal(false)}
      >
        <ImportCsv
          errorMessage={errorMessage}
          handleRemove={handleRemove}
          onUpload={onUpload}
          closeModal={() => setisOpenCSVModal(false)}
          inpref={inputCsvRef}
          event={event}
          useCsvFirstRowsAsColumnHeaders={useCsvFirstRowsAsColumnHeaders}
          setUseCsvFirstRowsAsColumnHeaders={setUseCsvFirstRowsAsColumnHeaders}
          enableCsvCustomColumHeaders={enableCsvCustomColumHeaders}
          setEnableCsvCustomColumHeaders={setEnableCsvCustomColumHeaders}
        />
      </ModalComponent>
      <ModalComponent
        isModalOpen={shouldUpgrade}
        text={'Storage limit reached.'}
        title={'Please upgrade to continue'}
        actionBtnText={'Upgrade'}
        cancelBtnText={'Cancel'}
        actionBtnColor={'warning'}
        cancelBtnColor={'secondary'}
        onActionClick={actionClick}
        onCancelClick={cancelClick}
        escButtonClick={cancelClick}
      />
    </div>
  );
};

export default DataInput;
