import React, { FC, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
import { crawlerOptions } from '../../constants';
import Checkbox from 'shared-components/atom/checkbox';
import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import DataList from 'shared-components/atom/dataList';
import CustomMultiSelect from 'shared-components/molecule/multiselect';
import Input from 'shared-components/atom/input';
import Alert from 'shared-components/atom/alert-message';
import { proxyList } from 'constants/index';
import { infoIcon } from 'assets/svg-icons';
import { residential_geo_proxy_country_list } from 'constants/countries/residential-geo-proxy-countries';
import { isValidUrl, isValidDomain } from 'constants/validations';
import { cacheFunction } from 'helpers';
import './styles.scss';

const outputs = [
  '_source_url',
  '_crawled_url',
  '_crawler_detected_on',
  '_crawler_status',
  '_crawler_link_type',
  '_crawler_url_type',
];

const CrawlerSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  selectedRecipes,
  automationIndex,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [fieldList, setFieldList] = useState<any>('');
  const [clicked, setClicked] = useState(false);
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [selectedProxy, setSelectedProxy] = useState({ label: 'Data Center', value: '' });
  const [residentialGEOCountry, setResidentialGEOCountry] = useState('');
  const [ignoreUrls, setIgnoreUrls] = useState('');
  const [fullStack, setFullStack] = useState(false);
  const [selectedURLTypes, setSelectedURLTypes] = useState([
    { label: 'Internal Links', value: 'internal', order_index: 1 },
    { label: 'Page URLs', value: 'page', order_index: 3 },
  ]);
  const [errorMessage, setErrorMessage] = useState('');

  const [urlsLimit, setUrlsLimit] = useState<number | string>(1000);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const handleUrlsLimitUpdate = (e: { target: { value: any } }) => {
    errorMessage && setErrorMessage('');
    let val = e.target.value;
    val = val === '' ? '' : +val;
    val = val > 100000 ? 100000 : val;
    val = val < 0 ? val * -1 : val;
    if (isNaN(val)) {
      setErrorMessage('Please use only numbers');
      return;
    }
    setUrlsLimit(val);
    !clicked && setClicked(true);
    setIsDirty();
  };

  useEffect(() => {
    setTimeout(() => setErrorMessage(''), 3000);
  }, [errorMessage]);

  const { liftingSettings, getDynamicAndIntegrationsSettings } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (!selectedURLTypes?.length || !fieldList || (!isValidUrl(fieldList) && !isValidDomain(fieldList))) {
      return false;
    }
    if (selectedProxy?.value === 'premium' && !residentialGEOCountry) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const { predefined_settings, dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const { keys } = dynamic_settings;
      const url = keys[0].url || keys[0].inputValue;
      // restoreSelectedSource({ key: key, fieldList: fieldList, setSelectedSource: setSelectedSource });
      setFieldList(url);

      const { settings } = keys[0];
      if (settings) {
        const proxy = settings.proxy;
        if (proxy) {
          const selectedProxy = proxyList.find((val: any) => val.value === proxy.type);
          const selectedCountry = residential_geo_proxy_country_list.find(
            (country: any) => country.value === proxy.country_code,
          );
          selectedProxy && setSelectedProxy(selectedProxy);
          selectedCountry && setResidentialGEOCountry(selectedCountry.label);
        }
        settings.limit && setUrlsLimit(settings.limit);
        settings.ignore_urls && setIgnoreUrls(settings.ignore_urls);
        typeof settings.browser === 'boolean' && setFullStack(settings.browser);
        const internalOrExternal = crawlerOptions.filter(item =>
          settings.url_type === 'all'
            ? item.value === 'internal' || item.value === 'external'
            : item.value === settings.url_type,
        );
        const pageOrUrl = crawlerOptions.filter(item =>
          settings.page_type === 'all'
            ? item.value === 'page' || item.value === 'url'
            : item.value === settings.page_type,
        );
        setSelectedURLTypes(internalOrExternal.concat(pageOrUrl));
        return;
      }
    }

    if (predefined_settings) {
      const proxy = predefined_settings.proxy;
      if (proxy) {
        const selectedProxy = proxyList.find((val: any) => val.value === proxy.type);
        const selectedCountry = residential_geo_proxy_country_list.find(
          (country: any) => country.value === proxy.country_code,
        );
        selectedProxy && setSelectedProxy(selectedProxy);
        selectedCountry && setResidentialGEOCountry(selectedCountry.label);
      }
      predefined_settings.limit && setUrlsLimit(predefined_settings.limit);
      const internalOrExternal = crawlerOptions.filter(item =>
        predefined_settings.url_type === 'all'
          ? item.value === 'internal' || item.value === 'external'
          : item.value === predefined_settings.url_type,
      );
      const pageOrUrl = crawlerOptions.filter(item =>
        predefined_settings.page_type === 'all'
          ? item.value === 'page' || item.value === 'url'
          : item.value === predefined_settings.page_type,
      );
      setSelectedURLTypes(internalOrExternal.concat(pageOrUrl));
    }
  }, [isSettingsChange]);

  //memoize
  const handleResidentialCountryOptions = (residentialGEOCountry: string) => {
    return residential_geo_proxy_country_list.find(item => item.label === residentialGEOCountry)?.value;
  };
  const getMemoizeResidentialCountryOption = useRef(cacheFunction(handleResidentialCountryOptions));

  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    setClicked(false);

    const internalOrExternal = selectedURLTypes.filter(item => item.value === 'internal' || item.value === 'external');
    const pageOrUrl = selectedURLTypes.filter(item => item.value === 'page' || item.value === 'url');
    const sortedOutputKeys = selectedURLTypes.sort((obj1: any, obj2: any) => {
      if (obj1.order_index > obj2.order_index) {
        return 1;
      } else {
        return -1;
      }
    });

    const localSettings = {
      limit: urlsLimit,
      url_type: internalOrExternal.length === 2 ? 'all' : internalOrExternal[0].value,
      page_type: pageOrUrl.length === 2 ? 'all' : pageOrUrl[0].value,
      proxy: {
        type: selectedProxy.value,
        country_code: getMemoizeResidentialCountryOption.current(residentialGEOCountry) || '',
      },
      ignore_urls: ignoreUrls.trim(),
      browser: fullStack,
    };
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: {
        keys: [
          {
            inputValue: fieldList || null,
            key: null,
            settings: localSettings,
          },
        ],
      },
      outputKeysData: outputs,
      outputKeysDataWithTypes: { ...jsonParse },
    });
  }, [
    selectedSource,
    selectedURLTypes,
    urlsLimit,
    fieldList,
    selectedProxy,
    residentialGEOCountry,
    ignoreUrls,
    fullStack,
    automationOutputTypes,
  ]);

  const handleChangeResidentialGEOCountry = (text: string) => {
    // setError('');
    setResidentialGEOCountry(text);
    !clicked && setClicked(true);
    setIsDirty();
  };
  const handleChangeIgnore = (e: any) => {
    setIgnoreUrls(e.target.value);
    setClicked(true);
    setIsDirty();
  };
  return (
    <div className="deepl-translate-settings crawler">
      <Row>
        <Col lg={4} className="">
          <span className="label secondary-color">Source URL</span>
          <Input
            value={fieldList}
            onChange={e => {
              setFieldList(e.target.value);
              !clicked && setClicked(true);
              setIsDirty();
            }}
            error={fieldList && !isValidUrl(fieldList) && !isValidDomain(fieldList) ? 'Invalid URL' : ''}
          />
        </Col>

        <Col lg={4}>
          <div className="select-block">
            <span className="label secondary-color">URL types</span>
            <CustomMultiSelect
              options={crawlerOptions}
              value={selectedURLTypes}
              onChange={(sel: any) => {
                const pageOrUrl = sel.find((item: any) => item.value === 'page' || item.value === 'url');
                const internalOrExternal = sel.find(
                  (item: any) => item.value === 'internal' || item.value === 'external',
                );
                if (!pageOrUrl) {
                  setErrorMessage('At least one of the "Page URLs" or "Source URLs" should be selected.');
                  return;
                } else if (!internalOrExternal) {
                  setErrorMessage('At least one of the "Internal Links" or "External Links" should be selected.');
                  return;
                } else {
                  errorMessage !== 'Please enter valid URLs limit' && setErrorMessage('');
                }
                setSelectedURLTypes(sel);
                !clicked && setClicked(true);
                setIsDirty();
              }}
              labelledBy="Select"
            />
          </div>
        </Col>
        <Col lg={3}>
          <div className="select-block">
            <span className="label secondary-color" style={{ maxWidth: '200px' }}>
              URL limit
            </span>
            <Input type="number" value={urlsLimit} onChange={handleUrlsLimitUpdate} />
          </div>
        </Col>
      </Row>
      <Row className="automation-proxy-container">
        <Col lg={4} md={12}>
          <div className="select-block">
            <span className="label secondary-color ignore-label" style={{ maxWidth: '200px' }}>
              Ignore URLs containing
            </span>
            <Input type="text" value={ignoreUrls} onChange={handleChangeIgnore} />
          </div>
        </Col>
        <Col lg={4}>
          <span className="label secondary-color">Proxy mode</span>
          <Select
            options={proxyList}
            value={selectedProxy}
            placeholder={'proxy mode'}
            onChange={(selectedItem: any) => {
              setSelectedProxy(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
          {selectedProxy.value && (
            <div className="mt-1">
              <span className="info-icon">{infoIcon}</span>
              <span className="info-message">
                This proxy consumes {selectedProxy.value === 'premium' ? '0.12' : '0.1'} premium credits per MB
              </span>
            </div>
          )}
        </Col>

        {selectedProxy.value === 'premium' && (
          <Col lg={3}>
            <span className="label secondary-color">Country</span>
            <DataList
              list={residential_geo_proxy_country_list}
              value={residentialGEOCountry}
              placeholder={'Select country'}
              onChange={(text: string) => {
                handleChangeResidentialGEOCountry(text);
              }}
            />
          </Col>
        )}
      </Row>
      {
        <Row>
          <Col lg={6} className="checkbox-wrapper">
            <div className="item-block mt-2 crawler-checkbox">
              <Checkbox
                checked={fullStack}
                label="React compatibility mode"
                onChange={() => {
                  setFullStack(!fullStack);
                  !clicked && setClicked(true);
                }}
                hoverText="Use this mode if you encounter any issues crawling React based websites"
              />
            </div>
          </Col>
        </Row>
      }
      {errorMessage && (
        <Row className="mt-4">
          <Col md={12}>
            <Alert type="error" className="create-workflow">
              {errorMessage}
            </Alert>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default CrawlerSettings;
