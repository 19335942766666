import { FC, useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import DashboardStats from 'shared-components/organizm/dashboard-stats';
import Chart from 'shared-components/organizm/chart';
import Workflows from 'pages/workflows';

import './styles.scss';
import { dashClockIcon } from 'assets/svg-icons';
import { HexomaticUserContext } from 'contexts/hexomatic-user';
import { UserContext } from 'contexts/user-context';
import { premiumCreditPlans } from 'constants/index';

export interface IDashboard {
  handleDateChange: string;
}

const Dashboard: FC<IDashboard> = props => {
  const { hexomaticUser, setHexomaticUser } = useContext(HexomaticUserContext);
  const { user } = useContext(UserContext);

  const getSavedHours = () => {
    if (
      hexomaticUser &&
      user &&
      typeof hexomaticUser.premium_automation_credit === 'number' &&
      typeof hexomaticUser.left_premium_automation_credit === 'number' &&
      typeof user.left_premium_credits === 'number' &&
      typeof hexomaticUser.automation_credit === 'number' &&
      typeof hexomaticUser.left_automation_credit === 'number'
    ) {
      const premiumPlan = user.premium_credits_pricing_package
        ? premiumCreditPlans.find((item: any) => item.planName === user.premium_credits_pricing_package)
        : null;
      const usedOneTimePremiumCredits =
        hexomaticUser.premium_automation_credit - hexomaticUser.left_premium_automation_credit;
      const usedPremiumCredits = premiumPlan ? premiumPlan.value - user.left_premium_credits : 0;
      const usedCredits = hexomaticUser.automation_credit - hexomaticUser.left_automation_credit;
      const savedHoursForPremium = (usedPremiumCredits + usedOneTimePremiumCredits || 0) * 15;
      const savedHoursForCredits = (usedCredits || 0) * 3;
      const totalSaved = savedHoursForPremium + savedHoursForCredits;
      const totalHours = Math.floor(totalSaved / 60);
      const totalMins = Math.floor(totalSaved % 60);
      const savedHours =
        totalHours || totalMins
          ? `~ ${totalHours ? totalHours : ''}${
              totalHours && totalHours > 1 ? ' h' : totalHours && totalHours === 1 ? ' h' : ''
            } ${totalMins ? totalMins : ''}${
              totalMins && totalMins > 1 ? ' min' : totalMins && totalMins === 1 ? ' min' : ''
            }`
          : '~ 0 h';
      return savedHours;
    }
    return '~ 0 h';
  };
  // const userIds = [
  //   17383, 21522, 21920, 252, 19137, 9685, 16797, 850, 19026, 20258, 21887, 9573, 17191, 17177, 11367, 11, 3485,
  // ];

  return (
    <div>
      <div className="d-block d-md-flex w-100">
        <h4 className="page-title">Dashboard</h4>
        <div className="ml-md-auto pl-2 pl-md-0 my-auto d-flex">
          <div className="mr-2 my-auto primary-color">Productive time saved</div>
          <div className='d-flex'>
            {dashClockIcon}{' '}
            <strong className="my-auto primary-color ml-1">
              {hexomaticUser &&
              typeof hexomaticUser.premium_automation_credit === 'number' &&
              typeof hexomaticUser.left_premium_automation_credit === 'number' &&
              typeof hexomaticUser.automation_credit === 'number' &&
              typeof hexomaticUser.left_automation_credit === 'number'
                ? getSavedHours()
                : '~ 0 hour'}
            </strong>
          </div>
        </div>
      </div>

      <Row className="dashboard-row">
        <Col xl={8} className="chart-row pb-2">
          {hexomaticUser?.settings?.show_dashboard ? <Chart /> : null}
          <Workflows hide />
        </Col>
        <Col xl={4} className="states-row">
          <DashboardStats showPieChart={hexomaticUser?.settings?.show_dashboard}/>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
