import { memo, FC, useEffect, useState, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import Input from 'shared-components/atom/input';
import Checkbox from 'shared-components/atom/checkbox';
import Radio from 'shared-components/atom/radio-button';
import { infoIcon } from 'assets/svg-icons';
import Tooltip from 'shared-components/atom/tooltip';
import { useMediaQuery } from 'react-responsive';
import './styles.scss';

const actionTypes = ['Compress_and_resize', 'Compress', 'Resize'];
const TypeOptions = [
  { label: 'pixels', value: 'pixels', data: 'size' },
  { label: 'percent', value: 'percent', data: 'percent' },
];

const mimeTypes = [
  { label: 'default', value: '' },
  { label: 'PNG', value: 'png' },
  { label: 'JPEG', value: 'jpeg' },
  { label: 'WEBP', value: 'webp' },
];

const outputs = ['_resize_image'];

const AiImageLabelingSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 670 });

  //-------------------State/reducer-------------------------
  const [fieldList, setFieldList] = useState<any>([]);
  const [clicked, setClicked] = useState(false);
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [actionType, setActionType] = useState(actionTypes[0]);
  const [selectedType, setSelectedType] = useState<any>(TypeOptions[0]);
  const [selectedMimeType, setSelectedMimeType] = useState<any>(mimeTypes[0]);
  const [width, setWidth] = useState('100');
  const [height, setHeight] = useState('');
  const [percent, setPercent] = useState('1');
  const [quality, setQuality] = useState('100');
  const [sizeRequire, setSizeRequire] = useState(true);
  const [enlarge, setEnlarge] = useState(false);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  //------------------------------------------------------------
  const {
    liftingSettings,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = useCallback(() => {
    const checkPixel = Number(!!Number(width)) + Number(!!Number(height)) + Number(!!Number(!!sizeRequire));
    if (
      (fieldList?.length && (Array.isArray(selectedSource) ? !selectedSource?.length : !selectedSource)) ||
      (actionType !== 'Resize' && !Number(quality)) ||
      (actionType !== 'Compress' &&
        ((selectedType?.value === 'pixels' && checkPixel < 2) || (selectedType?.value === 'percent' && !percent)))
    )
      return false;

    return true;
  }, [selectedSource, selectedType, width, height, percent, quality, sizeRequire, actionType, fieldList]);
  //recipe
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();

    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const { keys } = dynamic_settings;
      const { key, settings } = keys[0];
      if (settings) {
        const { width, height, percent, sizeRequire, enlarge, quality, actionType, mimeType, type } = settings;

        width !== null && setWidth(String(width));
        !width && height && sizeRequire && setWidth('');
        height !== null && setHeight(String(height));
        percent !== null && setPercent(String(percent));
        quality !== null && setQuality(String(quality));
        actionType && setActionType(actionType);
        mimeType && setSelectedMimeType({ label: mimeType.toUpperCase(), value: mimeType });
        setSizeRequire(sizeRequire);
        setEnlarge(enlarge);
        const selType = TypeOptions.find((item: { data: string }) => item.data === type);
        selType && setSelectedType(selType);
      }

      restoreSelectedSource({ key: key, fieldList: options, setSelectedSource: setSelectedSource });
    }
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...outputs],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);

  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);

    let localSettings = {
      keys: [
        {
          inputValue: null,
          key: fieldList.length && selectedSource ? selectedSource.value : null,
          settings: {
            type: selectedType ? selectedType.data : null,
            width: actionType !== 'Compress' && selectedType.data === 'size' && width ? +width : null,
            height: actionType !== 'Compress' && selectedType.data === 'size' && height ? +height : null,
            percent: actionType !== 'Compress' && selectedType.data !== 'size' && percent ? +percent : null,
            mimeType: selectedMimeType && selectedMimeType.value ? selectedMimeType.value : null,
            quality: actionType !== 'Resize' ? quality : null,
            compressing: actionType !== 'Resize',
            sizeRequire: actionType !== 'Compress' && selectedType.data === 'size' ? sizeRequire : null,
            enlarge: actionType !== 'Compress' && selectedType.data === 'size' ? enlarge : null,
            actionType,
          },
        },
      ],
    };
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: localSettings,
      outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...outputs],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [
    selectedSource,
    selectedType,
    selectedMimeType,
    width,
    height,
    quality,
    percent,
    sizeRequire,
    enlarge,
    actionType,
  ]);

  const handleChangeSource = useCallback(
    (selectedItem: any) => {
      setSelectedSource(selectedItem);
      !clicked && setClicked(true);
      setIsDirty();
    },
    [clicked],
  );

  const handleChangeType = useCallback(
    (selectedItem: any) => {
      setSelectedType(selectedItem);
      !clicked && setClicked(true);
      setIsDirty();
    },
    [clicked],
  );
  const handleChangeInput = useCallback(
    (e: any) => {
      let val = e.target.value.replace('-', '').replace('+', '');
      if (val && +val === 0) val = 1;
      const func = {
        width: () => {
          setWidth(+val < 50000 ? val : '50000');
          if (sizeRequire && val) setHeight('');
        },
        height: () => {
          setHeight(+val < 50000 ? val : '50000');
          if (sizeRequire && val) setWidth('');
        },
        percent: () => setPercent(+val < 200 ? val : '200'),
        quality: () => setQuality(+val < 100 ? val : '100'),
      };
      //@ts-ignore
      func[e.target.name] && func[e.target.name]();
      !clicked && setClicked(true);
      setIsDirty();
    },
    [sizeRequire, clicked],
  );

  const handleChangeMaintain = useCallback(
    (val: boolean) => {
      setSizeRequire(val);
      val && width && setHeight('');
      !clicked && setClicked(true);
      setIsDirty();
    },
    [clicked],
  );

  const handleChangeEnlarge = useCallback(
    (val: boolean) => {
      setEnlarge(val);
      !clicked && setClicked(true);
      setIsDirty();
    },
    [clicked],
  );

  const handleChangeMimeType = useCallback(
    (selectedItem: any) => {
      setSelectedMimeType(selectedItem);
      !clicked && setClicked(true);
      setIsDirty();
    },
    [clicked],
  );

  const handleChangeActionType = useCallback(
    item => {
      setActionType(item);
      !clicked && setClicked(true);
      setIsDirty();
    },
    [clicked],
  );
  return (
    <div className="google-automation-settings">
      <Row>
        <Col md={6} className="mb-4">
          <span className="label secondary-color">Source</span>
          <Select
            options={fieldList}
            value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
            placeholder={'Select source'}
            onChange={handleChangeSource}
          />
        </Col>
        <Col md={6} className="mb-4">
          <span className="label secondary-color">Save as</span>
          <Select
            options={mimeTypes}
            value={selectedMimeType}
            placeholder={'Select type'}
            onChange={handleChangeMimeType}
          />
        </Col>
        <Col md={12}>
          <Col md={6} className="mb-4  px-0">
            <div className="align-items-center h-75 d-sm-flex ">
              {actionTypes.map((item: string) => (
                <Radio
                  label={item.replaceAll('_', ' ')}
                  enabled={actionType === item}
                  onChange={() => handleChangeActionType(item)}
                  className="pr-1 pr-lg-4 mb-2 mb-sm-0"
                />
              ))}
            </div>
          </Col>
        </Col>
        {actionType !== 'Resize' && (
          <Col md={6} className="mb-4">
            <span className="label secondary-color">
              Compression level
              <Tooltip
                trigger={['hover', 'focus']}
                placement={isMobile ? 'top' : 'right'}
                text="Compression level 1 provides best performance and worst quality."
                isSmall
              >
                {infoIcon}
              </Tooltip>
            </span>
            <Input value={quality} type="number" name="quality" onChange={handleChangeInput} min={'1'} />
          </Col>
        )}
        {actionType !== 'Compress' && (
          <>
            <Col md={6} className="mb-4">
              <span className="label secondary-color">Resize by</span>
              <Select options={TypeOptions} value={selectedType} placeholder={'Select'} onChange={handleChangeType} />
            </Col>

            {selectedType.value === 'pixels' ? (
              <>
                <Col md={6} className="row mx-0">
                  <Col md={6} className="mb-4 pl-0">
                    <span className="label secondary-color">Width (px)</span>
                    <Input
                      value={width}
                      type="number"
                      name="width"
                      onChange={handleChangeInput}
                      placeholder={sizeRequire ? 'auto' : ''}
                      min={'1'}
                    />
                  </Col>
                  <Col md={6} className="mb-4 pl-0">
                    <span className="label secondary-color">Height (px)</span>
                    <Input
                      value={height}
                      type="number"
                      name="height"
                      onChange={handleChangeInput}
                      placeholder={sizeRequire ? 'auto' : ''}
                      min={'1'}
                    />
                  </Col>
                </Col>
                <Col md={6} className="mb-4 d-flex justify-content-end flex-column">
                  <Checkbox
                    checked={sizeRequire}
                    onChange={handleChangeMaintain}
                    labelRight="Maintain aspect ratio"
                    className="mt-2"
                  />
                  <Checkbox
                    checked={enlarge}
                    onChange={handleChangeEnlarge}
                    labelRight="Do not enlarge if smaller"
                    className="mt-2"
                  />
                </Col>
              </>
            ) : (
              <Col md={6} className="mb-4">
                <span className="label secondary-color">Percentage (%) </span>
                <Input value={percent} type="number" name="percent" onChange={handleChangeInput} />
              </Col>
            )}
          </>
        )}
      </Row>
    </div>
  );
};

export default memo(AiImageLabelingSettings);
