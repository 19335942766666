import { FC, memo, useEffect, useContext, useState, useRef } from 'react';
import { ThemeContext } from 'contexts/theme-context';
import { isValidKeyword, isValidWorkflowName } from 'constants/validations';
import useOutsideClickNormal from 'hooks/useOutsideClickNormal';

import AlertComponent from 'shared-components/atom/alert-message';
import Loader from 'shared-components/atom/loader';
// import ModalComponent from 'shared-components/molecule/modal';
import Tooltip from 'shared-components/atom/tooltip';
import { checkedIcon, closeIcon, editIcon, tagIcon, tagIconNew } from 'assets/svg-icons';
import './styles.scss';
import { useMediaQuery } from 'react-responsive';

interface IRecipeName {
  id: number;
  name: string;
  isDescription?: boolean;
  propLoading?: boolean;
  className?: string;
  onSave?: any;
  maxTextCount?: number;
  absoluteError?: boolean;
  setOpenInfo?: (num: any) => void;
  openedInfo?: any;
  errorMessageLength?: number;
  addTag?: any;
  allowingToAddTag?: boolean;
  showTooltip?: boolean;
  existingWorkflow?: boolean;
}

const EditableTextOpen: FC<IRecipeName> = ({
  id,
  name,
  propLoading,
  className,
  onSave,
  maxTextCount,
  absoluteError,
  setOpenInfo,
  openedInfo,
  errorMessageLength,
  isDescription,
  addTag,
  allowingToAddTag,
  showTooltip,
  existingWorkflow,
}) => {
  const { theme } = useContext(ThemeContext);
  const [alertMessage, setAlertMessage] = useState('');
  const [text, setText] = useState(name || '');
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const ref = useRef(null);
  const [outsideClick, elRef, setOutsideClick, setEnabledHook] = useOutsideClickNormal({
    propBool: false,
    enableListener: false,
  });

  useEffect(() => {
    if (((text && (!text.trim() || !isValidWorkflowName(text))) || !text) && !isDescription) {
      setAlertMessage('Invalid name');
      return;
    }

    if (((text && (!text.trim() || !isValidKeyword(text))) || !text) && isDescription) {
      setAlertMessage('Invalid description');
      return;
    }

    if (text && text.trim() && isValidWorkflowName(text)) {
      setAlertMessage('');
    }
  }, [text]);

  useEffect(() => {
    // if (openedInfo === id) {
    //   !editMode && setEditMode(true);
    //   setEnabledHook(true);
    // } else {
    //   editMode && setEditMode(false);
    // }
    setEditMode(true);
    setEnabledHook(true);
  }, [openedInfo]);

  useEffect(() => {
    if (outsideClick) {
      setOutsideClick(false);
      sendSave(id);
    }
  }, [outsideClick]);

  const eventStop = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleEnterKeyPress = async (e: any, name?: string) => {
    if (e.key === 'Enter') {
      setLoading(true);
      sendSave(id);
    }
  };

  const sendSave = async (id: any) => {
    if (text === name) {
      setOpenInfo && setOpenInfo(null);
      setEditMode(false);
      setOutsideClick(false);
      setLoading(false);
      return;
    }
    setLoading(true);
    const data = await onSave({ id, text });
    setLoading(false);
    if (data && data.message) {
      setAlertMessage(data.message);
      setOutsideClick(false);
      // setEnabledHook(true);
      return;
    }
    setOpenInfo && setOpenInfo(null);
    setEditMode(false);
  };

  useEffect(() => {
    const el = document.querySelector("#open-input") as HTMLElement;
    el && el.blur();
  }, []);

  const isMobile = useMediaQuery({maxWidth: 991});
  
  return (
    <>
      <span className={`m-vertical-auto no-wrap text-edit-open-component ${existingWorkflow ? '' : 'new'}`} ref={elRef}>
        <span className={`input-wrap ${absoluteError ? 'd-column' : ''}`} style={{width: !isMobile ? `${(text.length || 1) * 8}px` : '100%', minWidth: isMobile ? '' : '200px'}}>
          <Tooltip trigger={['hover']} placement="bottom" text="Rename" className="reload-icon-tooltip mb-1">
            <input
              id='open-input'
              className={`editable-input p-1 change-input ${className ? className : ''} ${
                className && alertMessage ? 'error' : absoluteError && alertMessage ? 'error-border' : ''
              }`}
              autoFocus={true}
              placeholder={'Enter name'}
              value={text}
              onChange={e => {
                const value = e.target.value;
                if (maxTextCount && value.length > maxTextCount) {
                  return;
                }
                setText(e.target.value);
              }}
              onClick={(e: any) => eventStop(e)}
              onKeyDown={e => handleEnterKeyPress(e, name)}
            />
          </Tooltip>
          {absoluteError && alertMessage ? (
            <small className="absolute-error">
              {alertMessage.length > (errorMessageLength || 40)
                ? alertMessage.slice(0, errorMessageLength || 40) + '...'
                : alertMessage}
            </small>
          ) : (
            alertMessage && (
              <span className="mr-2">
                <AlertComponent type="error" text={alertMessage} />
              </span>
            )
          )}
          {/* {alertMessage && (
              <ModalComponent
                isModalOpen={!!alertMessage}
                text={alertMessage}
                actionBtnText={'OK'}
                actionBtnColor={'error'}
                onActionClick={() => setAlertMessage('')}
              />
            )} */}
        </span>

        {/* {loading || propLoading ? (
            <span className={`d-inline-block ${className ? '' : 'h-100'}`}>
              <Loader size="sm" light={theme === 'light'} className="d-inline-block m-0 p-0 align-middle" />
            </span>
          ) : (
            <>
              <span
                className="check-icon cursor-pointer mr-2"
                onClick={(e: any) => {
                  eventStop(e);
                  setLoading(true);
                  sendSave(id);
                }}
              >
                {checkedIcon}
              </span>
              <span
                className="cancel-icon cursor-pointer"
                onClick={(e: any) => {
                  eventStop(e);
                  setEditMode(false);
                  setText('');
                  setAlertMessage('');
                }}
              >
                {closeIcon}
              </span>
            </>
          )} */}
      </span>
    </>
  );
};

export default memo(EditableTextOpen);
