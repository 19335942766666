import { FC } from 'react';
import Tooltip from 'shared-components/atom/tooltip';
import './styles.scss';

const Switcher: FC<{
  leftText: string;
  rightText: string;
  handleSwitcherClick: (value: string) => void;
  selectedValue: string;
  hoverTextLeft?: string;
  hoverTextRight?: string;
}> = ({ leftText, rightText, handleSwitcherClick, selectedValue, hoverTextLeft, hoverTextRight }) => {
  return (
    <div className="switcher-counatiner">
      {hoverTextLeft ? (
        <Tooltip trigger={['hover']} placement="bottom" text={hoverTextLeft} className="mb-1">
          <span
            className={`${selectedValue === leftText ? 'active' : ''} left`}
            onClick={() => handleSwitcherClick(leftText)}
          >
            {leftText}
          </span>
        </Tooltip>
      ) : (
        <span
          className={`${selectedValue === leftText ? 'active' : ''} left`}
          onClick={() => handleSwitcherClick(leftText)}
        >
          {leftText}
        </span>
      )}
      {hoverTextRight ? (
        <Tooltip trigger={['hover']} placement="bottom" text={hoverTextRight} className="mb-1">
          <span
            className={`${selectedValue === rightText ? 'active' : ''} right`}
            onClick={() => handleSwitcherClick(rightText)}
          >
            {rightText}
          </span>
        </Tooltip>
      ) : (
        <span
          className={`${selectedValue === rightText ? 'active' : ''} right`}
          onClick={() => handleSwitcherClick(rightText)}
        >
          {rightText}
        </span>
      )}
    </div>
  );
};

export default Switcher;
