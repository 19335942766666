import { useMutation } from '@apollo/react-hooks';
import { ThemeContext } from 'contexts/theme-context';
import { RESEND_VERIFICATION_EMAIL } from 'graphql/mutations';
import { useContext } from 'react';
import { useState } from 'react';
import Loader from '../loader';

const VerificationEmail = () => {
  //state
  const [message, setMessage] = useState('');
  const { theme } = useContext(ThemeContext);
  //queries
  const [receiveEmail, { loading }] = useMutation(RESEND_VERIFICATION_EMAIL, {
    onCompleted: (data: any) => handleConfirm(data),
  });
  const handleConfirm = async (data: any): Promise<void> => {
    if (data.UserOps.resendVerification && !data.UserOps.resendVerification.error) {
      setMessage('success');
    } else if (
      data.UserOps.resendVerification.error &&
      data.UserOps.resendVerification.message === 'Please try few minutes later.'
    ) {
      setMessage(data.UserOps.resendVerification.message);
    } else {
      setMessage('error');
    }
  };

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '120px' }}>
          <Loader light={theme === 'light'} />
        </div>
      ) : (
        <>
          <p className="h5 mb-3 mt-1">Welcome to Dashboard</p>
          {!message || message === 'Please try few minutes later.' ? (
            <p style={{ lineHeight: '25px' }}>
              Please verify the account, click the confirmation link in the email to activate your account, and make the
              purchase.
              <p className="mt-2" style={{ lineHeight: '25px' }}>
                Didn't receive the email? Please check your spam folder or{' '}
                <span className="cursor-pointer click-here" onClick={() => receiveEmail()}>
                  click here
                </span>{' '}
                to resend the confirmation email.
              </p>
              {message && <p className="text-danger">{message}</p>}
            </p>
          ) : (
            <>
              {message === 'error' ? (
                <p className="text-danger">Verification Failed !</p>
              ) : (
                <div>
                  <p className="mb-2 text-success">Verification email successfully sent! </p>
                  Please check the last verification email received for your account confirmation link.
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default VerificationEmail;
