import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { getPageContentOptions } from '../../constants';
import Select from 'shared-components/atom/select';
import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import Input from 'shared-components/atom/input';
import CustomMultiSelect from 'shared-components/molecule/multiselect';
import { useCallback } from 'react';
import Switcher from 'shared-components/molecule/switcher';
import './styles.scss';

const GetPageContentSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  selectedRecipes,
  automationIndex,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [selected, setSelected] = useState<any>([]);
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);
  const [clicked, setClicked] = useState(false);
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [customSelector, setcustomSelector] = useState('');
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);
  const [selectedMode, setSelectedMode] = useState('Standard');

  const {
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    liftingSettings,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (selected?.length === 0 || !selectedSource) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const { predefined_settings, dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (predefined_settings) {
      const selectedOptions =
        predefined_settings.options &&
        predefined_settings.options.map((option: string) => {
          const finded = getPageContentOptions.find(item => item.value === option || item.label === option);
          return finded ? finded : { label: option, value: option };
        });
      selectedOptions && setSelected(selectedOptions);
    }
    if (dynamic_settings && fieldList.length > 0) {
      const { keys } = dynamic_settings;
      const { key, settings } = keys[0];

      const fast = settings && settings.fast ? 'Fast' : 'Standard';
      setSelectedMode(fast);
      const custom_selector = settings ? settings.custom_selector : keys[0].custom_selector;
      const options = settings ? settings.options : keys[0].options;

      restoreSelectedSource({ key: key, fieldList: fieldList, setSelectedSource: setSelectedSource });
      const selectedOptions =
        options &&
        options.map((option: string) => {
          const finded = getPageContentOptions.find(item => item.value === option || item.label === option);
          return finded ? finded : { label: option, value: option };
        });
      selectedOptions &&
        setSelected(custom_selector ? [getPageContentOptions[0], ...selectedOptions] : selectedOptions);
      custom_selector && setcustomSelector(custom_selector);
    }
  }, [fieldList]);

  //recipe fields or output fields
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};

      liftingSettings({
        outputKeysData: [
          ...fieldList.map((item: { value: any }) => item.value),
          ...selected.map((social: { value: any }) => social.value),
        ],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, selected, fieldListWithTypes, automationOutputTypes]);

  //lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);

    const findedCustomSelector = selected.find((item: { value: string }) => item.value === '_custom_selector');

    findedCustomSelector && !customSelector && setCompletedFields({ index: automationIndex, checked: false });

    const sendingOptions = findedCustomSelector
      ? selected
          .filter((item: { value: string }) => item.value !== '_custom_selector')
          .map((item: { value: any }) => item.value)
      : selected.map((item: { value: any }) => item.value);
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: {
        keys: [
          {
            inputValue: null,
            key: fieldList.length && selectedSource ? selectedSource.value : null,
            settings: {
              options: sendingOptions,
              custom_selector: findedCustomSelector ? customSelector : null,
              fast: selectedMode === 'Fast',
            },
          },
        ],
      },
      outputKeysData: [
        ...fieldList.map((item: { value: any }) => item.value),
        ...selected.map((item: { value: any }) => item.value),
      ],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [selected, customSelector, selectedSource, automationOutputTypes, fieldList, fieldListWithTypes, selectedMode]);

  const showCustom = useCallback(() => {
    return !!selected.find((item: { value: string }) => item.value === '_custom_selector');
  }, [selected]);

  const handleSwitcherClick = (val: string) => {
    setSelectedMode(val);
    !clicked && setClicked(true);
    setIsDirty();
  };

  return (
    <div className="discover-whois-settings">
      <Row>
        <Col md={12} className="d-flex">
          <Switcher
            leftText="Fast"
            rightText="Standard"
            handleSwitcherClick={handleSwitcherClick}
            selectedValue={selectedMode}
            hoverTextLeft="Loads pages faster but may have a lower success rate."
            // hoverTextRight="Loads pages slower but may have a higher success rate."
          />
        </Col>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Source</span>
          <Select
            fullWidth
            options={fieldList}
            value={selectedSource}
            placeholder={'Select source'}
            onChange={(selectedItem: any) => {
              setSelectedSource(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col lg={6}>
          <span className="label secondary-color">Select options</span>
          {/* <pre>{JSON.stringify(selected)}</pre> */}
          <CustomMultiSelect
            options={getPageContentOptions}
            value={selected}
            onChange={(sel: any) => {
              setSelected(sel);
              !clicked && setClicked(true);
              setIsDirty();
            }}
            labelledBy="Select"
          />
        </Col>
        {showCustom() && (
          <Col lg={6} className="mb-4">
            <span className="label secondary-color">Selector</span>
            <Input
              value={customSelector}
              onChange={e => {
                setcustomSelector(e.target.value);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default GetPageContentSettings;
