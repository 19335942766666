import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
import { useAutomationGlobalMethods } from '../../hooks';
import Input from 'shared-components/atom/input';
import { AutomationSettingsFactoryTypesProps } from '../../types';

import './styles.scss';

const output = ['_text'];

const YoutubeTranscriptSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [fieldList, setFieldList] = useState<any>([]);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const zeroIndexOutput: string[] = automationIndex === 0 ? ['_url'] : [];

  const {
    liftingSettings,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (
      fieldList?.length === 0
        ? !searchKeyword
        : Array.isArray(selectedSource)
        ? !selectedSource?.length
        : !selectedSource
    )
      return false;
    return true;
  };

  useEffect(() => {
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const { keys } = dynamic_settings;
      const inputValue = keys[0].inputValue || keys[0].keyword;

      setSearchKeyword(inputValue);
    }
  }, []);

  //recipe
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const key = dynamic_settings.keys[0].key;
      restoreSelectedSource({ key: key, fieldList: options, setSelectedSource: setSelectedSource });
    }
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...zeroIndexOutput, ...output],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList]);

  // //memoize
  // const handleCountry = (text: string) => {
  //   return countryOptions.find((item: { label: string; value: number }) => item.label === text);
  // };
  // const getMemoizeCountryOption = useRef(cacheFunction(handleCountry));

  useEffect(() => {
    if (!clicked) return;

    setCompletedFields({ index: automationIndex, checked: checkFields() });

    let localSettings = {
      keys: [
        {
          inputValue: fieldList.length === 0 && automationIndex === 0 ? searchKeyword.trim() : null,
          key: fieldList.length && selectedSource ? selectedSource.value : null,
          settings: null,
        },
      ],
    };
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: localSettings,
      outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...zeroIndexOutput, ...output],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [searchKeyword, selectedSource, automationIndex]);

  return (
    <div className="youtube-transcript-automation-settings">
      <Row className="first-row">
        {fieldList.length > 0 || automationIndex > 0 ? (
          <Col lg={6} className="mb-4">
            <span className="label secondary-color">Source</span>
            <Select
              options={fieldList}
              value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
              placeholder={'Select source'}
              onChange={(selectedItem: any) => {
                setSelectedSource(selectedItem);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
        ) : (
          <Col lg={6} className="mb-4">
            <span className="label secondary-color">URL</span>
            <Input
              value={searchKeyword}
              onChange={e => {
                setSearchKeyword(e.target.value);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default React.memo(YoutubeTranscriptSettings);
