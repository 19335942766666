import { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import StretchLoading from 'shared-components/molecule/stretch-loading';
import Button from 'shared-components/atom/button';
import { GET_WORDPRESS_DATA } from 'graphql/queries';
import { useQuery } from '@apollo/react-hooks';
import './styles.scss';

const outputs = ['_wordpress_uploader'];

const WordpressSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  selectedRecipes,
  automationIndex,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [connected, setConnected] = useState<any>(null);
  const [integration, setIntegration] = useState<any>(null);
  const [list, setList] = useState<{ label: string; value: string }[]>([]);
  const [source, setSource] = useState<null | { label: string; value: string }>(null);
  const [title, setTitle] = useState<null | { label: string; value: string }>(null);
  const [alt, setAlt] = useState<null | { label: string; value: string }>(null);
  const [caption, setCaption] = useState<null | { label: string; value: string }>(null);
  const [description, setDescription] = useState<null | { label: string; value: string }>(null);
  const [fieldList, setFieldList] = useState<any>(null);
  const [clicked, setClicked] = useState(false);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const { data: wordpressData, loading } = useQuery(GET_WORDPRESS_DATA);

  const {
    liftingSettings,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (!source || !source.value || !fieldList || !title || !title?.value) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (wordpressData?.HexomaticIntegrations?.getWordPressConnections?.connections) {
      if (
        wordpressData.HexomaticIntegrations.getWordPressConnections.connections &&
        wordpressData.HexomaticIntegrations.getWordPressConnections.connections.length > 0
      ) {
        setIntegration(
          wordpressData.HexomaticIntegrations.getWordPressConnections.connections.map((item: any) => {
            return { ...item, label: item.name || item.username + '/' + item.url, value: item.id };
          }),
        );
        setConnected(true);
      } else setConnected(false);
    }
  }, [wordpressData]);

  useEffect(() => {
    const { predefined_settings, dynamic_settings } = getDynamicAndIntegrationsSettings();
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    setList([...(filteredOptions ? filteredOptions : [])]);
    setFieldListWithTypes(optionsWithTypes);

    if (dynamic_settings && predefined_settings) {
      setConnected(true);
      const { keys } = dynamic_settings;
      const { url, username, password, id, name } = predefined_settings;
      setFieldList({ url, username, password, id, label: name || username + '/' + url, value: id });

      const { settings, key } = keys[0];
      const { secondKey } = settings;
      const list = secondKey.split(',');
      key && setSource({ label: key.replaceAll('_', ' ').trim(), value: key });
      list[0] && setTitle({ label: list[0].replaceAll('_', ' ').trim(), value: list[0] });
      list[1] && setAlt({ label: list[1].replaceAll('_', ' ').trim(), value: list[1] });
      list[2] && setCaption({ label: list[2].replaceAll('_', ' ').trim(), value: list[2] });
      list[3] && setDescription({ label: list[3].replaceAll('_', ' ').trim(), value: list[3] });
    }
  }, [isSettingsChange]);

  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    setClicked(false);

    const localSettings = {
      //title: title?.value,
      //alt_text: alt?.value,
      //caption: caption?.value,
      //description: description?.value,
      secondKey:
        (title?.value ? title.value : '') +
        (alt?.value ? ',' + alt.value : ',' + '') +
        (caption?.value ? ',' + caption.value : ',' + '') +
        (description?.value ? ',' + description.value : ',' + ''),
    };
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: {
        keys: [
          {
            inputValue: null,
            key: source?.value,
            settings: localSettings,
          },
        ],
      },
      preDefinedSettings: {
        url: fieldList?.url,
        username: fieldList?.username,
        password: fieldList?.password,
        id: fieldList?.id,
        name: fieldList?.name || '',
      },
      outputKeysData: [...list.map((item: { value: any }) => item.value), ...outputs],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [alt, source, caption, description, fieldList, title, list, fieldListWithTypes]);

  const handleChangeSelect = (selectedItem: any) => {
    setFieldList(selectedItem);
    !clicked && setClicked(true);
    setIsDirty();
  };
  const handleChangeAlt = (selectedItem: any) => {
    setAlt(!selectedItem.value ? null : selectedItem);
    !clicked && setClicked(true);
    setIsDirty();
  };

  const handleChangeDescription = (selectedItem: any) => {
    setDescription(selectedItem);
    !clicked && setClicked(true);
    setIsDirty();
  };

  const handleChangeCaption = (selectedItem: any) => {
    setCaption(selectedItem);
    !clicked && setClicked(true);
    setIsDirty();
  };
  const handleChangeTitle = (selectedItem: any) => {
    setTitle(selectedItem);
    !clicked && setClicked(true);
    setIsDirty();
  };
  const handleChangeSource = (selectedItem: any) => {
    setSource(selectedItem);
    !clicked && setClicked(true);
    setIsDirty();
  };
  const openSetting = () => {
    window.open(`http://${window.location.host}/settings`, '_blank');
  };

  return (
    <div className="wordpress">
      <div>
        {(connected === null || loading) && <StretchLoading />}
        {connected === null || connected ? (
          <>
            <Row>
              <Col lg={4} className="">
                <span className="label secondary-color">Account</span>
                <Select
                  fullWidth
                  options={integration}
                  value={fieldList}
                  placeholder={'Select'}
                  onChange={handleChangeSelect}
                />
              </Col>
              <Col lg={4}>
                <span className="label secondary-color">Select Source</span>
                <Select fullWidth options={list} value={source} placeholder="Select" onChange={handleChangeSource} />
              </Col>
              <Col lg={4} className="order_1">
                <span className="label secondary-color">Select Title </span>
                <Select fullWidth options={list} value={title} placeholder="Select" onChange={handleChangeTitle} />
              </Col>
            </Row>
            <Row>
              <Col lg={4} className="">
                <span className="label secondary-color">Select Alt (optional)</span>
                <Select
                  fullWidth
                  options={alt && alt.value ? [{ label: 'No alt', value: '' }, ...list] : list}
                  value={alt}
                  placeholder="Select"
                  onChange={handleChangeAlt}
                />
              </Col>
              <Col lg={4} className="">
                <span className="label secondary-color">Select Caption (optional)</span>
                <Select
                  fullWidth
                  options={caption && caption.value ? [{ label: 'No caption', value: '' }, ...list] : list}
                  value={caption}
                  placeholder="Select"
                  onChange={handleChangeCaption}
                />
              </Col>
              <Col lg={4} className="">
                <span className="label secondary-color">Select Description (optional)</span>
                <Select
                  fullWidth
                  options={description && description.value ? [{ label: 'No description', value: '' }, ...list] : list}
                  value={description}
                  placeholder="Select"
                  onChange={handleChangeDescription}
                />
              </Col>
            </Row>
          </>
        ) : (
          <Col lg={6} md={12}>
            <Button name={'Please check the Settings>Integrations to Connect'} onClick={openSetting} />
          </Col>
        )}
      </div>
    </div>
  );
};

export default WordpressSettings;
