import { FC, useEffect, useState, memo } from 'react';
import useClickOutside from 'hooks/useClickOutside';
import arrowDown from 'assets/icons/down-arrow.svg';
import './styles.scss';

const DataList: FC<any> = ({
  openTop,
  list,
  value,
  placeholder,
  onChange,
  fullData,
  label,
  width,
  autoComplete,
  setPage,
  page,
  pageSize,
}) => {
  const [active, selectRef, setActive] = useClickOutside();
  const [clicked, setClicked] = useState(false);
  const [typed, setTyped] = useState(false);
  const [filterdList, setfilterdList] = useState(list);

  useEffect(() => {
    setfilterdList(list);
  }, [list]);

  useEffect(() => {
    if (value && clicked) {
      const newList =
        list &&
        list.filter((item: any) => {
          if (item && typeof item === 'string') {
            return item.toLowerCase().includes(value.toString().toLowerCase());
          }
          if (item && typeof item === 'object') {
            if (typeof item.label === 'string') {
              return item.label.toLowerCase().includes(value.toString().toLowerCase());
            } else {
              return +item.label.toString().toLowerCase().includes(value.toString().toLowerCase());
            }
          }
          return;
        });
      // it is need to be deleted
      // if (newList?.length === 0) {
      //   setfilterdList(list);
      //   setActive(true);
      //   setClicked(true);
      //   return;
      // }
      setfilterdList(typed ? newList : list);
      setActive(true);
      setClicked(true);
      return;
    }
    if (!value) {
      setfilterdList(list);
    }
  }, [value, clicked, typed]);

  // useEffect(() => {
  //   if (filterdList.length === 0) {
  //     setErrorMessage && setErrorMessage('Invalid value provided');
  //   } else {
  //     setErrorMessage && setErrorMessage('');
  //   }
  // }, [filterdList]);

  useEffect(() => {
    setActive(false);
    setClicked(false);
    setTyped(false);
  }, []);

  useEffect(() => {
    !active && setTyped(false);
  }, [active]);

  const onScroll = (e: any) => {
    if (page && pageSize) {
      if (list.length <= page * pageSize) return;
      const el = e.target;

      // if (el) {
      //   el.scrollTop = el.scrollHeight - el?.offsetHeight - 70;
      //   return;
      // }
      const scrollTop = el && el.scrollTop;
      if (scrollTop) {
        el.scrollHeight - el?.offsetHeight - 160 <= Math.round(scrollTop) && setPage(page + 1);
      } else {
        setPage(1);
      }
      return;
    }
  };

  return (
    <div className="d-block">
      {label && <div className="select-label primary-color">{label}</div>}
      <div
        id="datalist"
        className={`${active && clicked ? 'active' : ''}`}
        style={{ width: width || '', minWidth: width || '' }}
        ref={selectRef}
      >
        <div className="form-item">
          <input
            id="datalist-input"
            className={`input-field ${filterdList?.length === 0 ? 'error-border' : ''} `}
            type="text"
            value={value}
            placeholder={placeholder || 'Type text'}
            onChange={e => {
              setPage && setPage(1);
              onChange(e.target.value);
              setClicked(true);
              setTyped(true);
            }}
            onFocus={() => {
              setActive(true);
              setClicked(true);
            }}
            style={{ minWidth: '100px' }}
            autoComplete={autoComplete || 'off'}
          />
          <img
            onClick={() => {
              setActive(true);
              setClicked(true);
            }}
            className="arrow-img"
            src={arrowDown}
            alt="arrow-down"
          />
        </div>

        {active && clicked && filterdList && filterdList.length > 0 && (
          <>
            <i id="datalist-icon" className="icon iconfont icon-arrow"></i>
            <ul id="datalist-ul" className={`datalist-ul ${openTop ? 'open-top' : ''}`} onScroll={onScroll}>
              {filterdList &&
                filterdList.map((item: any) => {
                  if (item && item.length && typeof item === 'string') {
                    return (
                      <li
                        key={item}
                        id={item}
                        onClick={() => {
                          setClicked(false);
                          if (value === item) return;
                          onChange(item);
                        }}
                      >
                        {item}
                      </li>
                    );
                  }
                  if (item && typeof item === 'object') {
                    return (
                      <li
                        key={item.label}
                        id={item.label}
                        onClick={() => {
                          setClicked(false);
                          if (value === item.label) return;
                          onChange(fullData ? item : item.label);
                        }}
                      >
                        {item.label}
                      </li>
                    );
                  }
                  return;
                })}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default memo(DataList);
