import { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useQuery } from '@apollo/react-hooks';

import { GET_AUTOMATIONS, GET_PUBLIC_WORKFLOWS, GET_PUBLIC_WORKFLOW_CATEGORIES } from 'graphql/queries';

import { ThemeContext } from 'contexts/theme-context';
import { HexomaticUserContext } from 'contexts/hexomatic-user';

import Loader from 'shared-components/atom/loader';
import NothingToShow from 'shared-components/atom/nothing-to-show';
import SearchContainer from 'shared-components/molecule/search-container/updated';
import Modal from 'shared-components/molecule/modal';
import ModalComponent from 'shared-components/molecule/modal';

import ToastLoader from 'shared-components/molecule/toast-loader';
import Fields from 'shared-components/molecule/filter-fields';
import { useHistory, useLocation } from 'react-router-dom';
import TemplateCard from 'shared-components/atom/template-card';
import { longArrowLeft, loopIcon } from 'assets/svg-icons';
import '../styles.scss';
import './styles.scss';

interface PublicWorkflowsProps {
  hide?: boolean;
}

export default function PublicWorkflows(props: PublicWorkflowsProps) {
  const { theme } = useContext(ThemeContext);
  const { hexomaticUser } = useContext(HexomaticUserContext);

  const [cardLimit, setCardLimit] = useState(24);
  const [selectedAutomations, setSelectedAutomations] = useState<any>([]);
  const [automationsList, setAutomationsList] = useState([]);

  const [categoriesList, setCategoriesList] = useState([] as any);
  const [selectedCategories, setSelectedCategories] = useState<any>([-1]);

  const [searchText, setSearchText] = useState('');
  const [text, setText] = useState('');
  const [publicWorkflowsList, setPublicWorkflowsList] = useState([] as any);
  const [startSearch, setStartSearch] = useState(setTimeout(() => {}));
  const [alertMsg, setAlertMsg] = useState<any>({ show: false, text: '', type: '' });
  const [infoModal, setInfoModal] = useState<any>({ message: '', error: false, open: false, cb: null });
  const [isClickedLoadMore, setIsClickedLoadMore] = useState<boolean>(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const { data: publicWorkflowCategories } = useQuery(GET_PUBLIC_WORKFLOW_CATEGORIES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (
      publicWorkflowCategories &&
      publicWorkflowCategories.HexomaticWorkflowCategory.getWorkflowCategories &&
      publicWorkflowCategories.HexomaticWorkflowCategory.getWorkflowCategories &&
      publicWorkflowCategories.HexomaticWorkflowCategory.getWorkflowCategories.categories
    ) {
      setCategoriesList(publicWorkflowCategories.HexomaticWorkflowCategory.getWorkflowCategories.categories.reverse());
    }
  }, [publicWorkflowCategories]);

  const [sort, setSort] = useState({
    by: 'DESC',
    id: 1,
  });

  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    if (localStorage.getItem('public-worklows-details')) {
      const data = JSON.parse(localStorage.getItem('public-worklows-details') || '');
      if (data) {
        setSearchText(data.searchText);
        setText(data.searchText);
        setSelectedAutomations(data.selectedAutomations || []);
        if (data.selectedCategories && data.selectedCategories.label && !data.selectedCategories.value) {
          setSelectedCategories([-1]);
        } else if (data.selectedCategories && data.selectedCategories.label && data.selectedCategories.value) {
          setSelectedCategories([data.selectedCategories.value]);
        } else if (data.selectedCategories && Array.isArray(data.selectedCategories)) {
          setSelectedCategories(data.selectedCategories);
        }
      }
    }
  }, [localStorage.getItem('public-worklows-details')]);

  // const [updateWorkflowState, { error: stateErr, loading: stateLoading }] = useMutation(CHANGE_WORKFLOW_STATE);

  const { data: workflows, loading } = useQuery(GET_PUBLIC_WORKFLOWS, {
    fetchPolicy: 'no-cache',
    variables: {
      settings: {
        filter: {
          // page: viewMode === 'cardView' ? 1 : page,
          // limit: viewMode === 'listView' ? limit.value : cardLimit,
          page: 1,
          limit: cardLimit,
          date_from: null,
          date_to: null,
          sort_order: null,
          sort_by_creation_date: sort.id === 1,
          sort_by_updated_date: sort.id === 2,
          sort_by_name: sort.id === 3,
          // sort_by_status: sort.id === 4,
          sort_by: sort.by,
          //active: selectedTypeOption.value,
          automation_kind_id: null,
          last_status: null,
          status: null,
          search: {
            search_field: null,
            search_value: searchText,
          },
          automation_ids: selectedAutomations?.map((item: any) => item.value),
          // categoryIds: selectedCategories?.length > 0 ? selectedCategories.map((item: any) => item.value) : [],
          categoryIds: selectedCategories?.filter((item: number) => item > -1) || [],
        },
        get_total: true,
      },
    },
  });
  const { data: automations } = useQuery(GET_AUTOMATIONS, {
    variables: {
      settings: {
        filter: {
          active: true,
          page: null,
          limit: null,
          date_from: null,
          date_to: null,
          sort_order: null,
          sort_by: null,
          hasSource: null,
          automation_kind: undefined,
          search: {
            search_field: null,
            search_value: null,
          },
          short_info: true,
        },
      },
    },
  });

  useEffect(() => {
    const paidUser = hexomaticUser?.pricing_package && hexomaticUser?.pricing_package !== 'FREE';
    if (workflows?.HexomaticPublicWorkflow?.getPublicWorkflows?.public_workflows) {
      setIsClickedLoadMore(false);
      const fullList = workflows?.HexomaticPublicWorkflow?.getPublicWorkflows?.public_workflows;
      if (paidUser) {
        const insertIndex = fullList.length > 3 ? 2 : fullList.length;
        !fullList.find((item: any) => item.demoLink) && fullList.splice(insertIndex, 0, {demoLink: true});
      }
      setPublicWorkflowsList(fullList);
    }
    if (typeof workflows?.HexomaticPublicWorkflow?.getPublicWorkflows?.count === 'number') {
      setCount(workflows?.HexomaticPublicWorkflow?.getPublicWorkflows?.count);
    }
  }, [workflows, hexomaticUser]);

  useEffect(() => {
    localStorage.setItem('public-workflows-count', `${count || ''}`);
  }, [count]);

  useEffect(() => {
    if (automations?.HexomaticAutomation?.getAutomations?.automations) {
      setAutomationsList(
        automations?.HexomaticAutomation?.getAutomations?.automations.map((item: any) => {
          return { label: item.name, value: item.id };
        }),
      );
    }
  }, [automations]);

  const getNamesForTooltip = useCallback((steps: any, count: number): string => {
    return steps.slice(count).map((curent: any, index: number, arr: any) => {
      return ` ${curent.automation.name}${index === arr.length - 1 ? '' : ','} `;
    });
  }, []);

  const handleCardLimit = (): void => {
    setIsClickedLoadMore(true);
    if (count > cardLimit) setCardLimit(cardLimit + 24);
  };

  const handleChangeMultiSelect = (val: any) => {
    // const selected = selectedAutomations.find((item: any) => item === val);
    // let list =
    //   val > -1
    //     ? selected
    //       ? selectedAutomations.filter((item: any) => item !== val)
    //       : [...selectedAutomations, val]
    //     : [];

    setSelectedAutomations(val || []);
    localStorage.setItem(
      'public-worklows-details',
      JSON.stringify({
        page: 1,
        searchText: searchText,
        selectedAutomations: val,
        selectedCategories: selectedCategories,
      }),
    );
  };

  const handleChangeMultiSelectCategories = (val: any) => {
    setSelectedAutomations(val || []);
    localStorage.setItem(
      'public-worklows-details',
      JSON.stringify({
        page: 1,
        searchText: searchText,
        selectedAutomations: selectedAutomations,
        selectedCategories: val,
      }),
    );
  };

  const handelKeyUp = useCallback(
    (e: any) => {
      setStartSearch(setTimeout(() => e.target.value != searchText && setSearchText(e.target.value), 500));
    },
    [searchText],
  );

  const handelKeyDown = useCallback(() => {
    clearTimeout(startSearch);
  }, [startSearch, clearTimeout]);

  const list: any = useMemo(() => {
    return [
      // {
      //   type: 'select',
      //   value: selectedCategories,
      //   className: 'workflow-categories px-2 py-1 col-md-4',
      //   options: [
      //     { label: 'All', value: '' },
      //     ...categoriesList.map((item: any) => {
      //       return { label: item.name, value: item.id };
      //     }),
      //   ],
      //   onChange: handleChangeMultiSelectCategories,
      // },
      {
        type: 'multi select',
        className: 'automations-multiSelect px-2 py-1 col-md-4',
        placeholder: 'Select automations',
        elClassName: `search-multi-select ${selectedAutomations.length < 1 ? 'empty' : ''}`,
        value: selectedAutomations,
        options: automationsList,
        onChange: handleChangeMultiSelect,
      },
      {
        type: 'input',
        value: undefined,
        placeholder: 'Search...',
        className: 'px-2 py-1 col-md-8',
        onKeyDown: handelKeyDown,
        onKeyUp: handelKeyUp,
        icon: loopIcon,
      },
    ];
  }, [
    handelKeyDown,
    handelKeyUp,
    selectedCategories,
    categoriesList,
    handleChangeMultiSelectCategories,
    selectedAutomations,
    automationsList,
    handleChangeMultiSelect,
  ]);

  useEffect(() => {
    const sidebarOpen = document.getElementById('nav-bar');
    sidebarOpen && setSidebarOpen(sidebarOpen?.offsetWidth > 100);
  }, []);
  const { push } = useHistory();
  const { state } = useLocation();

  //@ts-ignore
  const backUrl = state?.backUrl;

  useEffect(() => {
    setTimeout(() => {
      const el = document.getElementById('sticky-search-container');
      const elPadding = document.getElementById('sticky-search-container-padding');
      const header = document.getElementById('header');
      el && header?.offsetHeight && (el.style.top = `${header?.offsetHeight}px`);
      el && el.offsetHeight && elPadding && (elPadding.style.paddingTop = `${el.offsetHeight + 5}px`);
    }, 1000);
    const el = document.getElementById('sticky-search-container');
    const rSidebarShow = document.getElementsByClassName('r-show-sidebar');
    const current = (el && el.className) || '';
    el &&
      (rSidebarShow.length
        ? (el.className = current + ' r-sidebar-open')
        : (el.className = current.replace(/r-sidebar-open/g, '')));
  }, []);

  return (
    <div>
      {' '}
      <div className={`sticky-search-container ${sidebarOpen ? 'sidebar-open' : ''}`} id="sticky-search-container">
        <div className="content-wrapper">
          <div className="d-lg-flex mt-2">
            <h4 className="pl-1 my-auto page-title no-transform py-0" style={{ fontSize: '16px', fontWeight: 500 }}>
              {backUrl ? (
                <span onClick={() => push(backUrl)} className="mr-2 my-auto cursor-pointer">
                  {longArrowLeft}
                </span>
              ) : null}
              Ready to use workflow templates
            </h4>
            <div className="ml-lg-auto">
              <SearchContainer list={list} />
            </div>
          </div>
          <Fields
            loading={false}
            lists={[
              { name: 'All', id: -1 },
              ...categoriesList.map((item: any) => {
                return item;
              }),
            ]}
            selectedList={selectedCategories}
            onChange={val => setSelectedCategories([val])}
          />
        </div>
      </div>
      <div className="sticky-search-container-padding" id="sticky-search-container-padding">
        {publicWorkflowsList && publicWorkflowsList.length > 0 && !loading ? (
          <div className="row" id="workflow-cards" style={{ margin: '0 -5px' }}>
            {publicWorkflowsList.map((item: any) => (
              <div style={{ padding: '5px' }} className="col-12 col-lg-6 col-xl-4">
                <TemplateCard
                  theme={theme}
                  title={item.name}
                  description={item.description}
                  linkTo={`/workflows/current-workflow-template/${item.id}`}
                  steps={item.steps}
                  image={item.image}
                  fullText
                  categories={item.categories}
                  demoLink={item.demoLink}
                  templatesPage
                />
              </div>
            ))}
            {publicWorkflowsList.length < count && (
              <Col className="load-more-button my-2" xl={12}>
                <div className="clickable-text" onClick={handleCardLimit}>
                  LOAD MORE
                </div>
              </Col>
            )}
          </div>
        ) : loading ? (
          <Row style={{ margin: '0 -5px' }}>
            {Array.apply(null, Array(24)).map(item => (
              <Col sm={12} md={6} lg={4} style={{ minWidth: '220px', padding: '5px' }}>
                <div className="blank-template"></div>
              </Col>
            ))}
          </Row>
        ) : (
          <Col md={12} className={`mt-2 ml-0 pl-2`}>
            <div style={{ marginTop: '200px' }}>
              <NothingToShow />
            </div>
          </Col>
        )}
      </div>
      {alertMsg.show && (
        <ModalComponent
          isModalOpen={alertMsg.show}
          text={alertMsg.text}
          actionBtnText={'OK'}
          actionBtnColor={alertMsg.type}
          onActionClick={() => setAlertMsg({ show: false, text: '', type: '' })}
          escButtonClick={() => setAlertMsg({ show: false, text: '', type: '' })}
        />
      )}
      {infoModal.open && (
        <Modal
          isModalOpen={infoModal.open}
          text={infoModal.message}
          actionBtnText={'OK'}
          actionBtnColor={infoModal.error ? 'error' : 'success'}
          onActionClick={() => {
            setInfoModal({ open: false, error: false, message: '' });
            infoModal.cb && infoModal.cb();
          }}
          escButtonClick={() => {
            setInfoModal({ open: false, error: false, message: '' });
            infoModal.cb && infoModal.cb();
          }}
        />
      )}
      {loading && !isClickedLoadMore && publicWorkflowsList && publicWorkflowsList.length > 0 && (
        <ToastLoader loadingMessage="Loading" />
      )}
    </div>
  );
}
