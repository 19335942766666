import { useContext, useEffect, useState, FC } from 'react';
import { useParams } from 'react-router';
import { Table } from 'react-bootstrap';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';

import { UPDATE_WORKFLOW } from 'graphql/mutations';
import { GET_WORKFLOW_BY_ID, GET_WORKFLOW_RESULTS } from 'graphql/queries';

import { ThemeContext } from 'contexts/theme-context';
import { cleanURL } from 'helpers';
import { isValidDomain, isValidUrl, isValidWorkflowName } from 'constants/validations';

//import Breadcrumbs from '../components/breadCrumbs';
import Tabs from '../components/tabs';

// import EditableText from 'shared-components/molecule/editable-text';
import Loader from 'shared-components/atom/loader';
import NothingToShow from 'shared-components/atom/nothing-to-show';
import EditableTextOpen from 'shared-components/molecule/editable-text-open';

import { infoIcon } from 'assets/svg-icons';
import './styles.scss';

const WorkflowResult: FC = () => {
  const { workflowId }: { workflowId: string } = useParams();
  const { theme } = useContext(ThemeContext);
  //----------------State-----------------------------------
  const [workflowName, setWorkflowName] = useState('');
  const [text, setText] = useState('');

  const [results, setResults] = useState([]);
  const [tableHeader, setTableHeader] = useState<string[]>([]);
  const [positionEl, setPositionEl] = useState({ top: 0, left: 0, display: 'none' });
  const [selectedHeaders, setSelectedHeaders] = useState(null as any);
  //-------------------Queris---------------------------------
  const [updateWorkflow, { loading: updateWorkflowLoading }] = useMutation(UPDATE_WORKFLOW);
  const [getWorkflowResults, { data, loading }] = useLazyQuery(GET_WORKFLOW_RESULTS, {
    fetchPolicy: 'no-cache',
    variables: {
      settings: {
        workflow_id: +workflowId,
      },
    },
  });
  const { data: workflowData, refetch: workflowDataRefetch } = useQuery(GET_WORKFLOW_BY_ID, {
    skip: !workflowId,
    variables: {
      settings: {
        workflow_id: workflowId ? parseInt(workflowId) : -1,
      },
    },
  });
  //-----------------useEffect----------------------------
  useEffect(() => {
    getWorkflowResults();
  }, []);
  useEffect(() => {
    workflowDataRefetch();
  }, [workflowId]);
  useEffect(() => {
    if (workflowData?.HexomaticWorkflow?.getWorkflow?.workflow) {
      setWorkflowName(workflowData.HexomaticWorkflow.getWorkflow.workflow.name);
      workflowData.HexomaticWorkflow.getWorkflow.workflow.selected_headers &&
      setSelectedHeaders(workflowData.HexomaticWorkflow.getWorkflow.workflow.selected_headers);
    }
  }, [workflowData]);
  useEffect(() => {
    if (data?.HexomaticWorkflow?.getWorkflowResultPreview?.result) {
      const result = JSON.parse(data.HexomaticWorkflow.getWorkflowResultPreview.result);
      setResults(result);
      if (result[0]) {
        const arr = Object.keys(result[0]).map((header: string) => header);
        setTableHeader(arr);
      }
    }
  }, [data]);
  useEffect(() => {
    const items = document.querySelectorAll('.td-property');
    items && items.forEach(item => item && contextMenuListener(item));
  }, [results]);

  useEffect(() => {
    const listener = () => {
      setPositionEl({
        top: 0,
        left: 0,
        display: 'none',
      });
    };
    document.getElementById('root')?.addEventListener('click', listener);
    document.getElementById('root')?.addEventListener('contextmenu', listener);
    return () => {
      document.getElementById('root')?.removeEventListener('click', listener);
      document.getElementById('root')?.removeEventListener('contextmenu', listener);
    };
  }, []);
  //------------------Functions---------------------------
  const workflowNameUpdate = async ({ id, name }: any) => {
    if ((name && (!name.trim() || !isValidWorkflowName(name))) || !name) {
      return { message: 'Invalid name' };
    }
    if (!workflowId && !id) {
      setWorkflowName(name);
      return;
    }
    const result = await updateWorkflow({
      variables: {
        settings: {
          workflow_id: id,
          name: name && name.trim(),
          isUpdated: false,
        },
      },
    });
    if (result?.data?.HexomaticWorkflowOps?.updateWorkflow?.error_code) {
      const { error_code } = result.data.HexomaticWorkflowOps.updateWorkflow;
      //@ts-ignore
      if (errorMessages && errorMessages[error_code]) {
        if (error_code === 'ALREADY_EXISTS') {
          if (name) return { message: `"${name.length > 25 ? name.slice(0, 25) + '...' : name}" already exists` };
        } else {
          //@ts-ignore
          return { message: errorMessages[error_code] };
        }
      } else {
        return { message: error_code };
      }
    } else {
      setWorkflowName(name);
      return { message: null };
    }
  };
  //--------------------copyText--------------------
  const onInputClick = (e: { stopPropagation: () => void; preventDefault: () => void }) => {
    e.stopPropagation();
    e.preventDefault();
    //@ts-ignore
    navigator.clipboard.writeText(text || '');
    setPositionEl({
      top: 0,
      left: 0,
      display: 'none',
    });
  };
  //----------------change mouse right click ---------------------
  const contextMenuListener = (el: any) => {
    el.addEventListener('contextmenu', function (e: any) {
      e.preventDefault();
      e.stopPropagation();
      const ev = window.event;
      //@ts-ignore
      const posX = ev.clientX;
      //@ts-ignore
      const posY = ev.clientY;
      setText(el.innerText.trim());
      setPositionEl({
        top: posY,
        left: posX,
        display: 'block',
      });
    });
  };
  const transform = (val: string | []) => {
    if (typeof val === 'string') return val.trim();
    return JSON.stringify(val).replaceAll('[', '').replaceAll(']', '').replaceAll('"', '');
  };
  return (
    <>
      <div className="copy-element position-fixed" style={positionEl} onClick={onInputClick}>
        Copy
      </div>
      <div className="workflow-header">
        {/* <Breadcrumbs className="workflow-create-breadcrumbs" workflowId={workflowId} /> */}
        {workflowName ? (
          <EditableTextOpen
            name={workflowName || ''}
            id={+workflowId}
            onSave={async ({ id, text }: any) => {
              return await workflowNameUpdate({ id, name: text });
            }}
            propLoading={updateWorkflowLoading}
            maxTextCount={64}
            absoluteError={true}
            errorMessageLength={50}
          />
        ) : (
          <div style={{ width: '1px', height: '33px' }}></div>
        )}
      </div>
      {workflowId && (
        <Tabs
          loading={!workflowData?.HexomaticWorkflow?.getWorkflow}
          status={workflowData?.HexomaticWorkflow?.getWorkflow?.workflow?.execution_data}
          selectedHeaders={selectedHeaders}
          setSelectedHeaders={setSelectedHeaders}
          workflowId={Number(workflowId)}
          active={3}
          handelRefetch={getWorkflowResults}
        />
      )}
      {loading ? (
        <div className="workflow-results-conatiner">
          <Loader light={theme === 'light'} />
        </div>
      ) : results.length > 0 ? (
        <div className="card-body workflow-results-conatiner mb-3">
          <div className="mb-1 pb-1" style={{ borderBottom: '1px dashed #d7e0e8' }}>
            <span className="info-icon">{infoIcon}</span>
            <span className="info-message">
              Showing first 20 results. To download full results press the export button.
            </span>
          </div>
          <Table
            className={`table ${tableHeader.length > 3 ? 'table--bordered' : 'table--bordered-small'}`}
            responsive
            hover
          >
            <thead>
              <tr>
                {tableHeader.map((header: string) => (
                  <th key={header} className="pl-3" title={header}>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody style={{ maxHeight: window.innerHeight - 330 }}>
              {results.slice(0, 20).map((result: any) => (
                <tr>
                  {tableHeader.map((header: string) => (
                    <td
                      key={header}
                      className={`pl-3 position-relative ${
                        result[header] && result[header][0] ? 'custom-cursor td-property' : ''
                      }`}
                      title={
                        result[header] && result[header][0] && result[header][0].length > 25 ? result[header][0] : ''
                      }
                    >
                      {result[header] && result[header][0] ? (
                        isValidUrl(transform(result[header][0])) ||
                        isValidDomain(transform(result[header][0])) ||
                        transform(result[header][0]).indexOf('http') === 0 ? (
                          <a target="_blank" href={cleanURL(transform(result[header][0]))} rel="noopener">
                            {result[header][0]}
                          </a>
                        ) : (
                          transform(result[header][0])
                        )
                      ) : (
                        ''
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="workflow-logs-conatiner">
          <NothingToShow text="No data collected yet" />
        </div>
      )}
    </>
  );
};

export default WorkflowResult;
