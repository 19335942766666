import { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAutomationGlobalMethods } from '../../hooks';
import Select from 'shared-components/atom/select';
import { dateOptions } from '../../constants';
import CustomMultiSelect from 'shared-components/molecule/multiselect';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import './styles.scss';

const DateTransformation: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setCompletedFields,
  setAutomationSendSettings,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [clicked, setClicked] = useState(false);
  const [fieldDateList, setFieldDateList] = useState<any>([]);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);
  const [selectedDateFields, setSelectedDateFields] = useState<any>([]);
  const [fieldList, setFieldList] = useState<any>([]);
  const [selectedFields, setSelectedFields] = useState<any>([]);
  const [values, setValues] = useState<any>([]);

  const {
    outputFormat,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    liftingSettings,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (values?.length === 0) {
      return false;
    }
    let bool = true;
    values?.forEach((item: { label: any }) => {
      const finded = selectedDateFields.find((field: { key: any }) => field.key === item.label);
      if (finded) {
        if (!finded.value.preType || !finded.value.type) bool = false;
      }
    });
    return bool;
  };

  //recipe fields or output fields
  useEffect(() => {
    if (fieldDateList.length > 0) return;
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings && dynamic_settings.keys) {
      const { keys } = dynamic_settings;
      let fieldsArr: any = [];
      const recreateSelectedFields = keys.map((item: any) => {
        const { settings } = item;
        const outputDataType = settings ? settings.outputDataType : item.outputDataType;
        const inputDataType = settings ? settings.inputDataType : item.inputDataType;
        const convertToUTC = settings ? settings.inputDataType : item.inputDataType;

        const finded = dateOptions.find(option => option.value === outputDataType);
        const findedPreType = dateOptions.find(option => option.value === inputDataType);
        fieldsArr.push(item.key);
        return {
          key: item.key,
          value: {
            type: finded ? finded.label : null,
            preType: findedPreType ? findedPreType.label : null,
            value: convertToUTC,
          },
        };
      });
      const getValues = options.filter(
        (option: { label: any; value: string }) => fieldsArr.includes(option.label) || fieldsArr.includes(option.value),
      );
      setValues(getValues);
      const finded = recreateSelectedFields.find((item: { key: any }) => !!item.key === true);
      finded && setSelectedFields({ label: finded.key, value: finded.key });
      const newOptions = options.map((option: { label: any; value: string }) => {
        const finded = recreateSelectedFields.find(
          (field: { key: any }) => field.key === option.label || field.key === option.value,
        );
        if (finded) return { key: option.label, value: finded.value };
        return {
          key: option.label,
          value: { type: dateOptions[0].label, preType: dateOptions[3].label, value: false },
        };
      });
      setSelectedDateFields(newOptions);
    } else {
      setSelectedDateFields(
        options.map((item: any, index: number) => ({
          key: item.label,
          value: { type: dateOptions[0].label, preType: dateOptions[3].label, value: false },
        })),
      );
    }
    // setClicked(true);
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldDateList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldDateList(options);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setValues([]);
      setClicked(true);
    }
    if (update) {
      if (dynamic_settings?.keys?.length > 1) {
        const filteredValue = filteredOptions?.filter((item: any) =>
          dynamic_settings?.keys.find((sub: any) => item.value === sub.key),
        );

        setValues(filteredValue || []);
        setClicked(true);
        setFieldList(filteredOptions);
        setFieldListWithTypes(optionsWithTypes);
      } else {
        const isArr = Array.isArray(dynamic_settings?.keys?.[0]?.key);
        const foundValue = !isArr
          ? filteredOptions?.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value)
          : null;
        const filteredValue = isArr
          ? filteredOptions?.filter((item: any) =>
              dynamic_settings?.keys?.[0]?.key.find((sub: any) => item.value === sub),
            )
          : [];
        const selOptionFound = isArr ? filteredValue : foundValue ? [foundValue] : [];

        setValues(selOptionFound);
        setClicked(true);
        setFieldList(filteredOptions);
        setFieldListWithTypes(optionsWithTypes);
      }
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setValues(fieldList);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      // const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      const vals = [...values.map((item: { label: any }) => outputFormat(item.label, 'date_transformation'))];
      const jsonParse: any = {};
      Object.keys(vals).map((key: any) => (jsonParse[key] = 'any'));
      liftingSettings({
        outputKeysData: [
          ...fieldList.map((item: { value: any }) => item.value),
          ...values.map((item: { label: any }) => outputFormat(item.label, 'date_transformation')),
        ],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, automationOutputTypes, fieldListWithTypes]);

  //linfting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);

    let localSettings = {
      keys: values.map((item: { key: any; label: any; value: any }) => {
        const findedSelectedField = selectedDateFields.find(
          (field: { key: any }) => field.key === item.label || field.key === item.value,
        );
        const finded = dateOptions.find(
          option => option.label === findedSelectedField.value.type || option.value === findedSelectedField.value.type,
        );
        const findedPreType = dateOptions.find(
          option =>
            option.label === findedSelectedField.value.preType || option.value === findedSelectedField.value.preType,
        );
        return {
          inputValue: null,
          key: item.value,
          settings: {
            outputDataType: finded ? finded.value : null,
            inputDataType: findedPreType ? findedPreType.value : null,
            add: true,
            // dateWithGTM: false,
            convertToUTC: findedSelectedField ? findedSelectedField.value.value : null,
          },
        };
      }),
    };
    const vals = [...values.map((item: { label: any }) => outputFormat(item.label, 'date_transformation'))];
    const jsonParse: any = {};
    Object.keys(vals).map((key: any) => (jsonParse[key] = 'any'));
    liftingSettings({
      dynamicSettings: localSettings,
      outputKeysData: [
        ...fieldList.map((item: { value: any }) => item.value),
        ...values.map((item: { label: any }) => outputFormat(item.label, 'date_transformation')),
      ],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [selectedDateFields, values, fieldList]);

  const handleMultiInputs = (value: string | boolean, keyName: string, proptype: string) => {
    const copiedSelectedDateFields = [...selectedDateFields];
    const arr = copiedSelectedDateFields.map((field: { key: string; value: any }) => {
      if (field.key === keyName) {
        return { ...field, value: { ...field.value, [proptype]: value } };
      }
      return field;
    });
    setSelectedDateFields(arr);
  };

  const findFrom = (value: string, label: string, type: string) => {
    const finded = selectedDateFields.find((field: { key: string }) => field.key === label || field.key === value);
    if (finded && finded.value) {
      return finded.value[type];
    }
    return '';
  };

  return (
    <div className="date-transformation-settings">
      <Row className="mb-4">
        <Col lg={6} className="">
          <span className="label secondary-color">Select fields for transformation</span>
          <CustomMultiSelect
            options={fieldList}
            value={values}
            onChange={(sel: any) => {
              setValues(sel);
              !clicked && setClicked(true);
              setIsDirty();
            }}
            labelledBy="Select"
          />
        </Col>
      </Row>
      {selectedDateFields.length > 0 &&
        values.map((property: { value: string; label: string }, index: number) => (
          <Row key={index}>
            <Col lg={6} md={8} className="mb-4">
              <span className="label secondary-color">{property.label} - from</span>
              <Select
                options={dateOptions}
                value={findFrom(property.value, property.label, 'preType')}
                placeholder={'Select column'}
                onChange={(selectedItem: any) => {
                  if (selectedItem && selectedItem.label === findFrom(property.value, property.label, 'preType'))
                    return;
                  handleMultiInputs(selectedItem.label, property.label, 'preType');
                  !clicked && setClicked(true);
                  setIsDirty();
                }}
              ></Select>
            </Col>
            <Col lg={6} md={8} className="mb-4">
              <span className="label secondary-color">{property.label} - convert to</span>
              <Select
                options={dateOptions}
                value={findFrom(property.value, property.label, 'type')}
                placeholder={'Select column'}
                onChange={(selectedItem: any) => {
                  if (selectedItem && selectedItem.label === findFrom(property.value, property.label, 'type')) return;
                  handleMultiInputs(selectedItem.label, property.label, 'type');
                  !clicked && setClicked(true);
                  setIsDirty();
                }}
              ></Select>
            </Col>
          </Row>
        ))}
    </div>
  );
};

export default DateTransformation;
