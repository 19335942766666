import React, { FC, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
import WindowedSelect from 'react-windowed-select';
import { countryOptions, googleSearchRestrictedCountOptions } from '../../constants';
import { useAutomationGlobalMethods } from '../../hooks';
import Input from 'shared-components/atom/input';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import Alert from 'shared-components/atom/alert-message';

import ToggleBtn from 'shared-components/atom/toggle';
// import { cacheFunction } from 'helpers';
import './styles.scss';

const output = [
  '_url',
  '_rank_group',
  '_rank_absolute',
  '_domain',
  '_title',
  '_cache_url',
  '_breadcrumb',
  '_description',
  '_search_type',
];

const deviceOptions = [
  { label: 'desktop', value: 'desktop' },
  { label: 'mobile', value: 'mobile' },
];

const searchTypeOptions = [
  { label: 'organic', value: 'organic' },
  { label: 'paid', value: 'paid' },
  { label: 'both', value: 'both' },
];

const GoogleSearchSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [fieldList, setFieldList] = useState<any>([]);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedMaxSearchCount, setSelectedMaxSearchCount] = useState(googleSearchRestrictedCountOptions[0]);
  const [countryText, setCountryText] = useState({ label: 'United States', value: 2840, country_coe: 'US' });
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [selectedDevice, setSelectedDevice] = useState<any>({ label: 'desktop', value: 'desktop' });
  const [selectedSearchType, setSelectedSearchType] = useState<any>(searchTypeOptions[0]);
  const [saveFrom, setSaveFrom] = useState<number | string>(1);
  const [saveTo, setSaveTo] = useState<number | string>(100);
  const [errorMessage, setErrorMessage] = useState('');
  const [showCountry, setShowCountry] = useState(true);
  const [radius, setRadius] = useState<number | string>('');
  const [latitude, setLatitude] = useState<number | string>('');
  const [longitude, setLongitude] = useState<number | string>('');
  const zeroIndexOutput: string[] = automationIndex === 0 ? ['_keyword'] : [];

  const {
    liftingSettings,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if ((showCountry && !countryText) || (!showCountry && !(latitude && longitude && radius)) || errorMessage) {
      return false;
    }
    if (fieldList?.length === 0 ? !searchKeyword : Array.isArray(selectedSource) ? !selectedSource?.length : !selectedSource) return false;
    if (typeof saveFrom !== 'number' || !saveTo) return false;
    return true;
  };

  useEffect(() => {
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const { keys } = dynamic_settings;
      const { settings } = keys[0];
      const depth = settings ? settings.depth : keys[0].depth;
      const inputValue = settings ? keys[0].inputValue : keys[0].keyword;
      const device = settings ? settings.device : keys[0].device;
      const location_code = settings ? settings.location_code : keys[0].location_code;
      const save_from = settings ? settings.save_from : keys[0].save_from;
      const save_to = settings ? settings.save_to : keys[0].save_to;
      const searchType = settings ? settings.searchType : 'organic';
      const latitude = settings ? settings.latitude : null;
      const longitude = settings ? settings.longitude : null;
      const radius = settings ? settings.radius : null;

      setSearchKeyword(inputValue);
      setSelectedDevice({ label: device, value: device });
      const findedCount = googleSearchRestrictedCountOptions.find(item => item.value === depth);
      findedCount && setSelectedMaxSearchCount(findedCount);
      const findedCountry = countryOptions.find(item => item.value === location_code);
      findedCountry && setCountryText(findedCountry);
      //there may be zero here, since initially these fields were not
      setSaveFrom(save_from === undefined ? 1 : save_from);
      setSaveTo(save_to === undefined ? 100 : save_to);
      searchType && setSelectedSearchType({ label: searchType, value: searchType });
      typeof latitude === 'number' && setLatitude(latitude);
      typeof longitude === 'number' && setLongitude(longitude);
      typeof radius === 'number' && setRadius(radius);
      setShowCountry(findedCountry ? true : false);
    }
  }, []);

  //recipe
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const key = dynamic_settings.keys[0].key;
      restoreSelectedSource({ key: key, fieldList: options, setSelectedSource: setSelectedSource });
    }
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...zeroIndexOutput, ...output],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList]);

  // //memoize
  // const handleCountry = (text: string) => {
  //   return countryOptions.find((item: { label: string; value: number }) => item.label === text);
  // };
  // const getMemoizeCountryOption = useRef(cacheFunction(handleCountry));

  useEffect(() => {
    if (!showCountry && radius && (radius < 200 || radius > 199999)) {
      setErrorMessage('radius value should be between 200 and 199999');
      return;
    }
    if (!clicked) return;

    // setClicked(false);
    const hashId: any = new Date().getTime().toString();
    const selectOption = showCountry ? countryText.value : null;
    if (showCountry && !selectOption) {
      setCompletedFields({ index: automationIndex, checked: false });
    } else {
      setCompletedFields({ index: automationIndex, checked: checkFields() });
    }

    let localSettings = {
      keys: [
        {
          inputValue: fieldList.length === 0 && automationIndex === 0 ? searchKeyword.trim() : null,
          key: fieldList.length && selectedSource ? selectedSource.value : null,
          settings: {
            depth: selectedMaxSearchCount ? selectedMaxSearchCount.value : null,
            // keyword: fieldList.length === 0 ? searchKeyword : null,
            device: selectedDevice.value,
            location_code: showCountry ? (selectOption ? selectOption : null) : null,
            save_from: saveFrom,
            save_to: saveTo,
            uniqueHash: hashId,
            searchType: selectedSearchType.value,
            latitude: !showCountry ? +latitude : '',
            longitude: !showCountry ? +longitude : '',
            radius: !showCountry ? radius : '',
          },
        },
      ],
    };
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: localSettings,
      outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...zeroIndexOutput, ...output],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [
    searchKeyword,
    errorMessage,
    selectedSource,
    selectedMaxSearchCount,
    countryText,
    selectedDevice,
    saveTo,
    saveFrom,
    selectedSearchType,
    longitude,
    latitude,
    radius,
    showCountry,
    automationIndex,
  ]);

  const clearErrorMsg = () => {
    setTimeout(() => {
      setErrorMessage('');
    }, 3000);
  };

  const handleSaveFrom = (e: { target: { value: any } }) => {
    errorMessage && setErrorMessage('');
    let val = e.target.value;
    val = val === '' ? '' : +val;
    if (isNaN(val)) {
      setErrorMessage('Please use only numbers');
      clearErrorMsg();
      return;
    }
    if (selectedMaxSearchCount.value <= val) {
      setErrorMessage('"Save from" value can`t be greater than or equal to "Search result" value');
      clearErrorMsg();
      return;
    }
    if (saveTo < val) {
      setErrorMessage('"Save from" value can`t be greater than or equal to "Save to" value');
    }
    if (val === '') {
      setErrorMessage('Please fill "Save from" field');
    }
    if (saveTo === '') {
      setErrorMessage('Please fill "Save to" field');
    }
    setSaveFrom(val);
    !clicked && setClicked(true);
  };

  const handleSaveTo = (e: { target: { value: any } }) => {
    errorMessage && setErrorMessage('');
    let val = e.target.value;
    val = val === '' ? '' : +val;
    if (isNaN(val)) {
      setErrorMessage('Please use only numbers');
      clearErrorMsg();
      return;
    }
    if (selectedMaxSearchCount.value < val) {
      setErrorMessage('"Save to" value can`t be greater than "Search result" value');
      clearErrorMsg();
      return;
    }
    if (saveFrom > val) {
      setErrorMessage('"Save to" value can`t be less than or equal to "Save from" value');
    }
    if (val === '') {
      setErrorMessage('Please fill "Save to" field');
    }
    if (saveFrom === '') {
      setErrorMessage('Please fill "Save from" field');
    }
    setSaveTo(val);
    !clicked && setClicked(true);
    setIsDirty();
  };

  const handleLatitude = (e: { target: { value: any } }) => {
    errorMessage && setErrorMessage('');
    let val = e.target.value;
    const decimals = val.toString().split('.');
    const decimalsCount = (decimals && decimals[1] && decimals[1].length) || 0;

    if (decimalsCount > 7) {
      setErrorMessage('The maximum number of the decimal digits for Latitude is 7');
      clearErrorMsg();
      return;
    }
    if (val < -90 || val > 90) {
      setErrorMessage('The latitude value should be between -90 and 90');
      clearErrorMsg();
      return;
    }
    setLatitude(val);
    !clicked && setClicked(true);
    setIsDirty();
  };

  const handleLongitude = (e: { target: { value: any } }) => {
    errorMessage && setErrorMessage('');
    let val = e.target.value;
    const decimals = val.toString().split('.');
    const decimalsCount = (decimals && decimals[1] && decimals[1].length) || 0;
    if (decimalsCount > 7) {
      setErrorMessage('The maximum number of the decimal digits for Longitude is 7');
      clearErrorMsg();
      return;
    }
    if (val < -180 || val > 180) {
      setErrorMessage('The longitude value should be between -180 and 180');
      clearErrorMsg();
      return;
    }
    setLongitude(val);
    !clicked && setClicked(true);
    setIsDirty();
  };

  const handleRadius = (e: { target: { value: any } }) => {
    errorMessage && setErrorMessage('');
    //@ts-ignore
    let val = e.target.valueAsNumber;
    setRadius(isNaN(val) ? '' : val);
    !clicked && setClicked(true);
    setIsDirty();
  };

  return (
    <div className="google-automation-settings">
      <Row className="first-row">
        {fieldList.length > 0 || automationIndex > 0 ? (
          <Col lg={6} className="mb-4">
            <span className="label secondary-color">Keyword</span>
            <Select
              options={fieldList}
              value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
              placeholder={'Select source'}
              onChange={(selectedItem: any) => {
                setSelectedSource(selectedItem);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
        ) : (
          <Col lg={6} className="mb-4">
            <span className="label secondary-color">Keyword</span>
            <Input
              value={searchKeyword}
              onChange={e => {
                setSearchKeyword(e.target.value);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
        )}
        <Col xl={6} className="search-fields">
          <Row>
            <Col lg={4} xl={4} className="mb-4">
              <span className="label secondary-color">Search results</span>
              <Select
                fullWidth
                options={googleSearchRestrictedCountOptions}
                value={selectedMaxSearchCount}
                placeholder={'Select column'}
                onChange={(selectedItem: any) => {
                  setSelectedMaxSearchCount(selectedItem);
                  setSaveFrom(1);
                  setSaveTo(selectedItem.value);
                  !clicked && setClicked(true);
                  setIsDirty();
                }}
              />
            </Col>
            <Col lg={4} xl={4} className="mb-4 results-from-to">
              <h5 className="pointer-title">From</h5>
              <div>
                <span className="label secondary-color" style={{ whiteSpace: 'nowrap' }}>
                  Save results
                </span>
                <Input value={saveFrom} onChange={handleSaveFrom} />
              </div>
            </Col>
            <Col lg={4} xl={4} className="mb-4 results-from-to">
              <h5 className="pointer-title">To</h5>
              <div>
                <span className="label secondary-color" style={{ visibility: 'hidden' }}>
                  to
                </span>
                <Input value={saveTo} onChange={handleSaveTo} />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Device</span>
          <Select
            fullWidth
            options={deviceOptions}
            value={selectedDevice}
            placeholder={'Select column'}
            onChange={(selectedItem: any) => {
              setSelectedDevice(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Search type</span>
          <Select
            fullWidth
            options={searchTypeOptions}
            value={selectedSearchType}
            placeholder={'Select column'}
            onChange={(selectedItem: any) => {
              setSelectedSearchType(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={showCountry ? 6 : 12} className="mb-4">
          <ToggleBtn
            onChange={() => {
              setShowCountry(!showCountry);
              !clicked && setClicked(true);
              setIsDirty();
            }}
            label1={'Coordinates'}
            label2={'Country'}
            checked={showCountry}
            className="small"
          />
          <div>
            {showCountry ? (
              <div>
                <span className="label secondary-color">Country</span>
                <WindowedSelect
                  options={countryOptions}
                  onChange={(selectedOption: any) => {
                    if (selectedOption && countryText && selectedOption.value === countryText.value) return;
                    setCountryText(selectedOption);
                    !clicked && setClicked(true);
                    setIsDirty();
                  }}
                  value={countryText}
                />
              </div>
            ) : (
              <div className="d-lg-flex">
                <div className="mr-2" style={{ maxWidth: '300px' }}>
                  <span className="label secondary-color">Latitude</span>
                  <Input value={latitude} onChange={handleLatitude} type="number" />
                </div>
                <div className="mr-2" style={{ maxWidth: '300px' }}>
                  <span className="label secondary-color">Longitude</span>
                  <Input value={longitude} onChange={handleLongitude} type="number" />
                </div>
                <div style={{ maxWidth: '300px' }}>
                  <span className="label secondary-color">
                    Radius<span className="small ml-1">(between 200 to 199999)</span>
                  </span>
                  <Input value={radius} onChange={handleRadius} type="number" />
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
      {errorMessage && (
        <Row>
          <Col md={12}>
            <Alert type="error" className="create-workflow">
              {errorMessage}
            </Alert>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default React.memo(GoogleSearchSettings);
