import { replacedResultTypes } from './types';
import { allowedToUseAutomationsAgain } from '../../constants';
import { excludeAutomationsOrRecipt, move, reorder } from 'helpers';
import { STARTING_AUTOMATION_ERROR } from './create';
import { useState } from 'react';

const CHANGES_IN_OUTPUTS_ERROR = 'We have detected change in automation output keys. Please check automation sources.';
const CHANGES_IN_RECEPIE_SETTINGS = 'We have detected changes in recipe. Update the workflow to implement the changes accordingly.';

export const useDragMethods = ({
  setErrorMessage,
  setClicked,
  setOpenedSettingsIds,
  setSelectedAutomations,
  setAutomationSendSettings,
  setDisabledRecipes,
  setDisabledPublicRecipes,
  setRecipesList,
  setPublicRecipesList,
  setAutomationsList,
  setDisabledAutmations,
  setShowSearch,
  setAutomationSearch,
  setCompletedFields,
  setGeneralErrorMessage,
  createAutomationSendSettings,
}: any) => {
  const onDragEnd = ({
    result,
    completedFields,
    selectedAutomations,
    automationSendSettings,
    recipesList,
    publicRecipesList,
    isAutomationTab,
    automationsList,
    disabledAutmations,
    disabledRecipes,
    disabledPublicRecipes,
    showSearch,
    errorMessage,
    isDirty,
    clicked,
  }: any) => {
    if (!completedFields.every(Boolean)) {
      setErrorMessage('Please complete the fields before adding the next automation');
      window.scrollTo(0, document.body.scrollHeight);
      return;
    }
    showSearch.index !== null && setShowSearch({ show: false, index: null });
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === 'droppable' && destination.droppableId === 'droppable2') {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const reOrderedSelectedAutomations: any = reorder(
        source.droppableId === 'droppable' ? selectedAutomations : [],
        source.index,
        destination.index,
      );
      if (destination.index === 0 && reOrderedSelectedAutomations[0].multi) {
        setGeneralErrorMessage(STARTING_AUTOMATION_ERROR);
        return;
      }
      const reOrderedCompletedFields = reorder(completedFields, source.index, destination.index);

      const reOrderedAutomationSendSettings = reorder(automationSendSettings || [], source.index, destination.index);

      const findedAutomations: any = reOrderedAutomationSendSettings.find(
        (item: any, i: number) => i === destination.index,
      );
      if (source.droppableId === 'droppable2') {
        // setSelected(items);
      } else {
        setClicked(true);
        findedAutomations && setOpenedSettingsIds([{ index: destination.index, id: findedAutomations.automation_id }]);

        setSelectedAutomations(reOrderedSelectedAutomations || []);
        setAutomationSendSettings(reOrderedAutomationSendSettings);
        setCompletedFields(reOrderedCompletedFields);
      }
    } else {
      let recipesForAutomation: any = [];
      if (isAutomationTab !== '1') {
        recipesForAutomation = (isAutomationTab === '2' ? recipesList : publicRecipesList).map((item: any) => {
          return {
            recipe: item,
          };
        });
      }
      const result = move(
        source.droppableId === 'droppable'
          ? selectedAutomations
          : isAutomationTab === '1'
          ? automationsList
          : recipesForAutomation,
        destination.droppableId === 'droppable2'
          ? isAutomationTab === '1'
            ? automationsList
            : recipesForAutomation
          : selectedAutomations,
        source,
        destination,
      );
      const findedAutomations = result.droppable.find((item: any, i: number) => i === destination.index);
      if (destination.index === 0 && findedAutomations?.multi) {
        setGeneralErrorMessage(STARTING_AUTOMATION_ERROR);
        return;
      }
      findedAutomations && setOpenedSettingsIds([{ index: destination.index, id: findedAutomations.id }]);
      if (!findedAutomations?.multi) {
        const replacedResults: replacedResultTypes = excludeAutomationsOrRecipt({
          sourceIndex: source.index,
          sourceList:
            isAutomationTab === '1' ? automationsList : isAutomationTab === '2' ? recipesList : publicRecipesList,
          destinationList:
            isAutomationTab === '1'
              ? disabledAutmations
              : isAutomationTab === '2'
              ? disabledRecipes
              : disabledPublicRecipes,
        });
        if (isAutomationTab === '2') {
          setDisabledRecipes(replacedResults.destination);
          setRecipesList(replacedResults.source);
        } else if (isAutomationTab === '3') {
          setDisabledPublicRecipes(replacedResults.destination);
          setPublicRecipesList(replacedResults.source);
        } else {
          setDisabledAutmations(replacedResults.destination);
          setAutomationsList(replacedResults.source);
        }
      }
      const newField = [
        ...completedFields.slice(0, destination.index),
        isAutomationTab !== '1' ? true : false,
        ...completedFields.slice(destination.index, completedFields.length),
      ];
      setCompletedFields(newField);
      setAutomationSearch('');
      setClicked(true);
      setSelectedAutomations(result.droppable || []);

      createAutomationSendSettings({
        selectedAutomations: result.droppable,
        automationSendSettings,
        errorMessage,
        completedFields,
        isDirty,
        clicked: true,
        addedAutomationIndex: destination.index,
        removedAutomationIndex: null,
      });
    }
  };

  return {
    onDragEnd,
  };
};

export const useAutomationManipulationMethods = ({
  setClicked,
  setErrorMessage,
  setDisabledRecipes,
  setDisabledPublicRecipes,
  setRecipesList,
  setPublicRecipesList,
  setSelectedAutomations,
  setInputSchemaIds,
  setDisabledAutmations,
  setOpenedSettingsIds,
  setAutomationsList,
  automationsList,
  disabledAutmations,
  setInputValue,
  setCompletedFields,
  setIsDirty,
  setAutomationSendSettings,
  setExtractedRecipesFromSelectedAutomations,
  setShowSearch,
  setAutomationSearch,
}: any) => {
  const selectAutomationOrRecipe = ({
    fullInfo,
    index,
    completedFields,
    clicked,
    selectedAutomations,
    recipesList,
    publicRecipesList,
    disabledRecipes,
    disabledPublicRecipes,
    showSearch,
    automationSendSettings,
    errorMessage,
    isDirty,
    automationsFullList,
  }: any) => {
    !clicked && setClicked(true);
    if (!completedFields.every(Boolean)) {
      setErrorMessage('Please complete the fields before adding the next automation');
      window.scrollTo(0, document.body.scrollHeight);
      return;
    }

    setErrorMessage('');
    const list = [...selectedAutomations];
    if (fullInfo.actions !== undefined) {
      // if (selectedAutomations.length === 0) {
      list.push({ recipe: fullInfo });
      const replacedResult: replacedResultTypes = excludeAutomationsOrRecipt({
        sourceIndex: index,
        sourceList: fullInfo.is_public === false ? recipesList : publicRecipesList,
        destinationList: fullInfo.is_public === false ? disabledRecipes : disabledPublicRecipes,
      });
      fullInfo.is_public === false
        ? setDisabledRecipes(replacedResult.destination)
        : setDisabledPublicRecipes(replacedResult.destination);
      fullInfo.is_public === false
        ? setRecipesList(replacedResult.source)
        : setPublicRecipesList(replacedResult.source);
      setSelectedAutomations(list || []);
      createAutomationSendSettings({
        selectedAutomations: list,
        automationSendSettings,
        errorMessage,
        completedFields,
        isDirty,
        clicked: true,
        addedAutomationIndex: list.length - 1,
        removedAutomationIndex: null,
      });
    } else {
      setInputSchemaIds(fullInfo.output_schema_ids);
      list.push(fullInfo);

      if (!fullInfo.multi) {
        const replacedResult: replacedResultTypes = excludeAutomationsOrRecipt({
          sourceIndex: index,
          sourceList: automationsFullList ? automationsFullList : automationsList,
          destinationList: disabledAutmations,
        });
        setDisabledAutmations(replacedResult.destination);
        setAutomationsList(replacedResult.source);
      }
      setSelectedAutomations(list || []);
      createAutomationSendSettings({
        selectedAutomations: list,
        automationSendSettings,
        errorMessage,
        completedFields,
        isDirty,
        clicked: true,
        addedAutomationIndex: list.length - 1,
        removedAutomationIndex: null,
      });
    }
    showSearch.index !== null && setShowSearch({ show: false, index: null });
    setCompletedFields([...completedFields, fullInfo.actions ? true : false]);
    //setAutomationSearch('');
    setOpenedSettingsIds([{ index: list.length - 1, id: fullInfo.id }]);
  };

  const onSelectAutomation = ({
    automation,
    automationIndex,
    completedFields,
    selectedAutomations,
    isAutomationTab,
    recipesList,
    publicRecipesList,
    disabledRecipes,
    disabledPublicRecipes,
    clicked,
    showSearch,
    automationSendSettings,
    errorMessage,
    isDirty,
    recommended,
  }: any) => {
    if (!completedFields.every(Boolean)) {
      setErrorMessage('Please complete the fields before adding the next automation');
      return;
    }

    let list = [...selectedAutomations];

    const insertPlaceIndex = automationIndex; //+ (list.length === 0 ? 0 : 1);
    if (insertPlaceIndex !== selectedAutomations.length && !recommended) {
      list = [
        ...list.slice(0, insertPlaceIndex),
        automation.actions !== undefined ? { recipe: automation } : automation,
        ...list.slice(insertPlaceIndex, list.length),
      ];
      setOpenedSettingsIds([{ index: insertPlaceIndex, id: automation.id }]);
    } else {
      list.push(automation.actions !== undefined ? { recipe: automation } : automation);
      !clicked && setClicked(true);
      setOpenedSettingsIds([{ index: insertPlaceIndex, id: automation.id }]);
    }

    // const currentIndex = [
    //   ...(isAutomationTab === '1' ? automationsList : isAutomationTab === '2' ? recipesList : publicRecipesList),
    // ].findIndex(item => item.id === automation.id);
    const currentAutomationIndex = automationsList.findIndex((item: any) => item.id === automation.id);
    const currentRecipeIndex = recipesList.findIndex((item: any) => item.id === automation.id);
    const currentPublicRecipeIndex = publicRecipesList.findIndex((item: any) => item.id === automation.id);
    if (!automation.multi) {
      // const replacedResults: replacedResultTypes = excludeAutomationsOrRecipt({
      //   sourceIndex: currentIndex,
      //   sourceList:
      //     isAutomationTab === '1' ? automationsList : isAutomationTab === '2' ? recipesList : publicRecipesList,
      //   destinationList:
      //     isAutomationTab === '1'
      //       ? disabledAutmations
      //       : isAutomationTab === '2'
      //       ? disabledRecipes
      //       : disabledPublicRecipes,
      // });
      const replacedAutomationResults: replacedResultTypes = excludeAutomationsOrRecipt({
        sourceIndex: currentAutomationIndex,
        sourceList: automationsList,
        destinationList: disabledAutmations,
      });
      const replacedRecipeResults: replacedResultTypes = excludeAutomationsOrRecipt({
        sourceIndex: currentRecipeIndex,
        sourceList: recipesList,
        destinationList: disabledRecipes,
      });
      const replacedPublicRecipeResults: replacedResultTypes = excludeAutomationsOrRecipt({
        sourceIndex: currentPublicRecipeIndex,
        sourceList: publicRecipesList,
        destinationList: disabledPublicRecipes,
      });
      setDisabledRecipes(replacedRecipeResults.destination);
      setRecipesList(replacedRecipeResults.source);

      setDisabledAutmations(replacedAutomationResults.destination);
      setAutomationsList(replacedAutomationResults.source);

      setDisabledPublicRecipes(replacedPublicRecipeResults.destination);
      setPublicRecipesList(replacedPublicRecipeResults.source);
    }
    let copiedCompletedFields = [...completedFields];
    const isTrue = automation.actions ? true : false;
    if (copiedCompletedFields.length >= automationIndex) {
      //if it is not last position insert right place
      copiedCompletedFields = [
        ...copiedCompletedFields.slice(0, insertPlaceIndex),
        isTrue,
        ...copiedCompletedFields.slice(insertPlaceIndex),
      ];
    } else {
      copiedCompletedFields[insertPlaceIndex] = isTrue;
    }
    setCompletedFields(copiedCompletedFields);
    setSelectedAutomations(list || []);

    createAutomationSendSettings({
      selectedAutomations: list,
      automationSendSettings,
      errorMessage,
      completedFields,
      isDirty,
      clicked: true,
      addedAutomationIndex: automationIndex,
      removedAutomationIndex: null,
    });
    showSearch && showSearch.index !== null && setShowSearch({ show: false, index: null });
    setInputValue('');
    //setAutomationSearch('');
  };

  const showSearchBar = ({ index, clicked, completedFields, selectedAutomations, showSearch }: any) => {
    !clicked && setClicked(true);
    if (!completedFields.every(Boolean)) {
      setErrorMessage('Please complete the fields before adding the next automation');
      window.scrollTo(0, document.body.scrollHeight);
      return;
    }
    setErrorMessage('');
    if (showSearch.index !== index) {
      setShowSearch({ show: true, index: index });
    } else {
      setShowSearch({ show: false, index: null });
    }
    setInputValue('');
    setOpenedSettingsIds([{ index: null, id: null }]);
  };

  const restoreAutomationsRecipes = ({ id, sourceList, destinationList, type }: any) => {
    const copiedDestination = [...destinationList];
    const findedEl = sourceList.find((item: { id: any }) => item.id === id);
    const filteredSourceList = sourceList.filter((item: { id: any }) => item.id !== id);
    findedEl && copiedDestination.push(findedEl);
    let finArr = [];
    if (type !== 'recipe' && type !== 'public_recipe') {
      const starred = copiedDestination.filter((item: any) => item?.isFavorite);
      const notStarred = copiedDestination.filter((item: any) => !item?.isFavorite);

      (starred.length > 0 ? starred : copiedDestination).sort((a: { name: string }, b: { name: string }) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });
      (notStarred.length > 0 ? notStarred : copiedDestination).sort((a: { name: string }, b: { name: string }) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });
      finArr = starred.concat(notStarred);
    } else if (type === 'recipe' || type === 'public_recipe') {
      const starred = copiedDestination.filter((item: any) => item.isFavorite);
      const notStarred = copiedDestination.filter((item: any) => !item.isFavorite);

      starred.sort((a: { created_at: string }, b: { created_at: string }) => {
        if (a.created_at < b.created_at) {
          return 1;
        }
        if (a.created_at > b.created_at) {
          return -1;
        }
        return 0;
      });
      notStarred.sort((a: { created_at: string }, b: { created_at: string }) => {
        if (a.created_at < b.created_at) {
          return 1;
        }
        if (a.created_at > b.created_at) {
          return -1;
        }
        return 0;
      });
      finArr = starred.concat(notStarred);
    }

    return { destination: finArr, source: filteredSourceList };
  };

  const deleteAutomatinoOrRecipe = ({
    indexNumber,
    index,
    id,
    multi,
    type,
    clicked,
    selectedAutomations,
    disabledRecipes,
    disabledPublicRecipes,
    recipesList,
    publicRecipesList,
    automationSearch,
    showDeletedRecipesInWorkflow,
    setShowDeletedRecipesInWorkflow,
  }: any) => {
    !clicked && setClicked(true);
    setErrorMessage('');
    if (type === 'deleted') {
      const newArr = showDeletedRecipesInWorkflow.filter((item: number) => item !== indexNumber);
      newArr.length ? setShowDeletedRecipesInWorkflow(newArr) : setShowDeletedRecipesInWorkflow(null);
      return;
    }
    const list = [...selectedAutomations];
    if (indexNumber === index) {
      setErrorMessage('');
      setOpenedSettingsIds([{ index: null, id: null }]);
      const removed: any = list.splice(indexNumber, 1);
      const automationName = removed ? removed[0]?.name?.toLowerCase() || removed[0]?.recipe?.name?.toLowerCase() : '';
      if (type === 'recipe' || type === 'public_recipe' ? true : !multi) {
        const replacedResults: replacedResultTypes = restoreAutomationsRecipes({
          id,
          sourceList:
            type === 'recipe' ? disabledRecipes : type === 'public_recipe' ? disabledPublicRecipes : disabledAutmations,
          destinationList:
            type === 'recipe' ? recipesList : type === 'public_recipe' ? publicRecipesList : automationsList,
          type,
        });
        if (type === 'recipe') {
          setDisabledRecipes(replacedResults.source);
          if (automationName?.includes(automationSearch.toLowerCase())) {
            setRecipesList(replacedResults.destination);
          }
        } else if (type === 'public_recipe') {
          setDisabledPublicRecipes(replacedResults.source);
          if (automationName?.includes(automationSearch.toLowerCase())) {
            setPublicRecipesList(replacedResults.destination);
          }
        } else {
          setDisabledAutmations(replacedResults.source);
          if (automationName?.includes(automationSearch.toLowerCase())) {
            setAutomationsList(replacedResults.destination.filter((item: any) => item));
          }
        }
      }
      setSelectedAutomations(list || []);
    }
  };

  const createAutomationSendSettings = ({
    selectedAutomations,
    automationSendSettings,
    errorMessage,
    completedFields,
    isDirty,
    clicked,
    addedAutomationIndex,
  }: any) => {
    let arr: any = [];

    if (typeof addedAutomationIndex === 'number') {
      const automation =
        automationSendSettings.length < 1 && selectedAutomations.length === 1
          ? selectedAutomations[0]
          : selectedAutomations.find((automation: any, index: number) => index === addedAutomationIndex);
      let recipeActions: any = null;
      if (automation?.recipe) {
        const actions = JSON.parse(automation.recipe.actions);
        let newActions = {
          ...{
            pages: actions.pages,
            ...{ recipeId: automation.recipe.id },
            actions: actions.actions,
            proxy: actions.proxy || { type: '' },
            browser: actions.browser,
            session_mode: typeof actions.session_mode === 'boolean' ? actions.session_mode : true,
          },
        };
        recipeActions = newActions;
      }
      const workflowItem = {
        automation_id: automation.recipe ? 43 : automation.id,
        predefined_settings: automation.recipe ? recipeActions : null,
        dynamic_settings: null,
        integration_settings: null,
        output_keys_data: null,
        source: automation.recipe ? null : automation.source,
      };
      const newAutomation = automation.recipe
        ? { ...workflowItem, is_public: automation.recipe.is_public }
        : workflowItem;
      const automationLength = automationSendSettings.length;
      if (automationSendSettings.length === 0) {
        arr = [newAutomation];
      } else {
        arr =
          addedAutomationIndex === automationLength
            ? [...automationSendSettings, newAutomation]
            : [
                ...automationSendSettings.slice(0, addedAutomationIndex),
                newAutomation,
                ...automationSendSettings.slice(addedAutomationIndex, automationLength),
              ];
      }
    }

    // it will be deleted
    // const arr = selectedAutomations.map(
    //   (automation: { id: any; source: any; recipe: { actions: string; id: any; is_public: boolean } }, index: any) => {
    //     debugger
    //     let recipeActions: any = null;
    //     if (automation.recipe) {
    //       const actions = JSON.parse(automation.recipe.actions);
    //       let newActions = {
    //         ...{
    //           pages: actions.pages,
    //           ...{ recipeId: automation.recipe.id },
    //           actions: actions.actions,
    //           proxy: actions.proxy || { type: '' },
    //           browser: actions.browser,
    //           session_mode: typeof actions.session_mode === 'boolean' ? actions.session_mode : true,
    //         },
    //       };
    //       recipeActions = newActions;
    //     }
    //     const cumputedIndex = (changesIndexAndId && (changesIndexAndId.diff === 1 ? changesIndexAndId.index <= index : changesIndexAndId.index < index)) ? index + changesIndexAndId?.diff : index
    //     const findedSettings =
    //       automationSendSettings &&
    //       automationSendSettings?.find(
    //         (settings: any, autoIndex: any) =>
    //           (automation.id === settings.automation_id && autoIndex === cumputedIndex) ||
    //           (automation.recipe &&
    //             settings.predefined_settings &&
    //             settings.predefined_settings.recipeId &&
    //             automation.recipe.id === settings.predefined_settings.recipeId),
    //       );
    //     const workflowItem = {
    //       automation_id: automation.recipe ? 43 : automation.id,
    //       predefined_settings: automation.recipe
    //         ? recipeActions
    //         : findedSettings
    //           ? findedSettings.predefined_settings
    //           : null,
    //       dynamic_settings: findedSettings ? findedSettings.dynamic_settings : null,
    //       integration_settings: findedSettings ? findedSettings.integration_settings : null,
    //       output_keys_data: findedSettings ? findedSettings.output_keys_data : null,
    //       source: automation.recipe ? null : automation.source,
    //     };
    //     return automation.recipe ? { ...workflowItem, is_public: automation.recipe.is_public } : workflowItem;
    //   },
    // );
    !!errorMessage && setErrorMessage('');
    clicked && !isDirty && setIsDirty(true);
    clicked && setAutomationSendSettings(arr);
    const selRecipes = selectedAutomations.filter((selected: { recipe: any }) => !!selected.recipe === true);
    clicked && setExtractedRecipesFromSelectedAutomations(selRecipes);
  };

  const restoreSelectedAutomationsAndAutomationSendSettings = ({
    workflowSteps,
    requiredRecipeListUsed,
    requiredAutomationListUsed,
    showDeletedRecipesInWorkflow,
    setGeneralErrorMessage,
  }: any) => {
    const workflow = showDeletedRecipesInWorkflow
      ? workflowSteps.filter((item: any, i: number) => !showDeletedRecipesInWorkflow.includes(i - 1))
      : workflowSteps;
    let selecteArr: any[] = [];
    let sendSettingsArr: any[] = [];
    workflow.forEach((step: any) => {
      if (step.automation.id === 43) {
        let setting = JSON.parse(step.setting);
        if (setting.predefined_settings) {
          let parsedpredefinedSetting = setting.predefined_settings;
          if (parsedpredefinedSetting.recipeId) {
            let finded = requiredRecipeListUsed.find(
              (recipe: { id: any }) => recipe.id === parsedpredefinedSetting.recipeId,
            );
            finded && selecteArr.push({ recipe: finded });
          }
          sendSettingsArr.push({ ...setting });
        }
      } else {
        let finded = requiredAutomationListUsed.find((automation: { id: any }) => automation.id === step.automation.id);
        finded && selecteArr.push(finded);
        let setting = JSON.parse(step.setting);
        sendSettingsArr.push({ ...setting });
      }
    });
    setSelectedAutomations(selecteArr || []);
    const selRecipes = selecteArr?.filter((selected: { recipe: any }) => !!selected?.recipe === true);
    setExtractedRecipesFromSelectedAutomations(selRecipes);
    if (!selRecipes.length) {
      setAutomationSendSettings(sendSettingsArr);
      setCompletedFields(new Array(selecteArr.length).fill(true));
    } else {
      // at first needed to check actual recipe properties
      updateAllOutputKeys({
        automationSendSettings: sendSettingsArr,
        setAutomationSendSettings,
        selectedRecipes: selRecipes,
        setCompletedFields,
        setGeneralErrorMessage,
      });
    }
  };

  const setWizardData = ({
    automationSendSettings,
    automationsArray,
    selectedAutomationIds,
    output_keys_data,
    output_keys_data_with_types,
    predefined_settings,
    dataInputValue,
    is_public,
    source,
    automationsListLocal,
    setAutomationSearch,
  }: any) => {
    // console.log('4', automationsArray, selectedAutomationIds, predefined_settings)
    setSelectedAutomations(automationsArray || []);
    setOpenedSettingsIds(
      selectedAutomationIds[1]
        ? [
            { index: 0, id: selectedAutomationIds[0] },
            { index: 1, id: selectedAutomationIds[1] },
          ]
        : [{ index: 0, id: selectedAutomationIds[0] }],
    );
    const newAutomationSettings = [...automationSendSettings];
    selectedAutomationIds[0] &&
      newAutomationSettings.push({
        automation_id: selectedAutomationIds[0],
        dynamic_settings: selectedAutomationIds[1]
          ? {
              keys: [
                {
                  inputValue: [dataInputValue],
                  key: null,
                  settings: {
                    columns: [],
                    inputType: 'MANUAL_ADD_TYPE',
                    jsonKeys: [],
                    storage: null,
                    type: 'Structured data',
                    uploadedFiles: [],
                    workflowUploadId: null,
                  },
                },
              ],
            }
          : null,
        integration_settings: null,
        output_keys_data: ['_data_input'],
        output_keys_data_with_types: '{"_data_input":"any"}',
        predefined_settings: null,
        source: { source_csv: false, source_txt: false },
      });
      
    is_public && setExtractedRecipesFromSelectedAutomations([automationsArray[1]]);
    selectedAutomationIds[1] &&
      newAutomationSettings.push({
        automation_id: is_public ? 43 : selectedAutomationIds[1],
        dynamic_settings: { keys: [{ key: '_data_input' }] },
        integration_settings: null,
        output_keys_data: ['_data_input', ...output_keys_data],
        output_keys_data_with_types: output_keys_data_with_types || [],
        predefined_settings: predefined_settings || null,
        is_public: is_public ? is_public : undefined,
        source: source !== undefined ? source : undefined,
      });
    setAutomationSendSettings(newAutomationSettings);
    automationsListLocal?.length &&
      setAutomationsList(
        automationsListLocal.filter(
          (item: any) => item.id !== selectedAutomationIds[0] && item.id !== selectedAutomationIds[1],
        ),
      );
    newAutomationSettings?.length && setAutomationSearch && setAutomationSearch('');
  };

  return {
    selectAutomationOrRecipe,
    onSelectAutomation,
    showSearchBar,
    deleteAutomatinoOrRecipe,
    createAutomationSendSettings,
    restoreSelectedAutomationsAndAutomationSendSettings,
    setWizardData,
  };
};

export const useTagsMethods = ({ workflowId, setWorkflowId, handleUpdateTags }: any) => {
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [userTagsList, setUserTagsList] = useState<any[]>([]);

  const handleTagSelect = (id: string, TagData: any) => {
    const tagsList = [...selectedTags];
    const tag = userTagsList.find(item => item.id === id);
    if (tag && tagsList.indexOf(tag) === -1) {
      tagsList.push(tag);
    } else if (TagData) {
      tagsList.push(TagData);
    }
    setSelectedTags(tagsList);
    workflowId && handleUpdateTags({ id: +workflowId, tagList: tagsList });
    setWorkflowId && setWorkflowId(null);
  };

  const handleTagDelete = (tagId: number, id?: number, list?: any) => {
    if (id) {
      const updatedList = list.filter((item: { id: number }) => item.id !== tagId);
      handleUpdateTags({ id: +id, tagList: updatedList });
      return;
    }
    const updatedList = selectedTags.filter(item => item.id !== tagId);
    setSelectedTags(updatedList);
    workflowId && handleUpdateTags({ id: +workflowId, tagList: updatedList });
  };

  return {
    selectedTags,
    setSelectedTags,
    userTagsList,
    setUserTagsList,
    handleTagSelect,
    handleTagDelete,
  };
};

// this automation work only when we open workflow and one time for fill actual recipe data
const updateAllOutputKeys = ({
  automationSendSettings,
  setAutomationSendSettings,
  selectedRecipes,
  setCompletedFields,
  setGeneralErrorMessage,
}: any) => {
  let recipeOldOutputs: { index: number; value: string }[] = [];
  let recipeOldUrl = '';
  let recipeNewOutputs: { index: number; value: string }[] = [];
  let recipeNewUrl = '';
  //if recipe changed, it will changed and saved in newArr
  let newArr: any = null;
  let completedFields: any = [];
  let changedList: any = [...automationSendSettings];
  let proxy_old = '';
  let proxyGeo_old = '';
  let blockAds_old: any;
  let browser_old = true;

  let proxy_new = '';
  let proxyGeo_new = '';
  let blockAds_new: any;
  let browser_new = true;

  // loop every automation and fill old and new properties name for ouputs
  automationSendSettings.forEach((item: any, index: number) => {
    const isRecipe = item.automation_id === 43;
    const id = item.automation_id === 43 ? item.predefined_settings.recipeId : item.automation_id;

    if (isRecipe) {
      // getting actual recipe properties name
      let currentRecipe =
        selectedRecipes && selectedRecipes.find((item: { recipe: { id: any } }) => item.recipe.id === id);
      let recipedata: any = null;
      if (currentRecipe?.recipe) {
        recipedata = JSON.parse(currentRecipe.recipe.actions);
        while (typeof recipedata === 'string') {
          recipedata = JSON.parse(recipedata);
        }
        if (recipedata?.pages?.[0]) {
          recipedata.pages[0].elements.forEach((element: { property: any }) => {
            element.property && recipeNewOutputs.push({ index: index, value: element.property });
          });
          recipeNewUrl = recipedata?.pages?.[0].url;
        }
        proxy_new = recipedata.proxy?.type || ''
        proxyGeo_new = recipedata.proxy?.country_code || ''
        browser_new = !!recipedata.browser
        if (recipedata.blockAds === 'boolean') {
          blockAds_new = recipedata.blockAds;
        }
      }
      // getting saved or old recipe properties name
      if (
        item?.predefined_settings?.pages &&
        item.predefined_settings.pages[0] &&
        item.predefined_settings.pages[0].elements
      ) {
        recipeOldUrl = item.predefined_settings.pages[0].url;
        item.predefined_settings.pages[0].elements.forEach((element: { property: any }) => {
          element.property && recipeOldOutputs.push({ index: index, value: element.property });
        });
      }

      if (item?.predefined_settings) {
        proxy_old = item?.predefined_settings.proxy?.type || ''
        proxyGeo_old = item?.predefined_settings.proxy?.country_code || ''
        browser_old = !!item?.predefined_settings.browser
        if (item?.predefined_settings.blockAds === 'boolean') {
          blockAds_old = item?.predefined_settings.blockAds;
        }
      }
      
      if (JSON.stringify(recipeOldOutputs) === JSON.stringify(recipeNewOutputs) && recipeOldUrl === recipeNewUrl && proxy_old === proxy_new && proxyGeo_old === proxyGeo_new && browser_old === browser_new && blockAds_old === blockAds_new) {
        return;
      }

      if (recipedata && recipedata.pages && recipedata.pages[0])
        changedList[index].predefined_settings.pages[0] = recipedata.pages[0];
      // here we change only outputs if recipe properties changed
      newArr = automationSendSettings.map((item: any, i: number) => {
        let lowerOrEqualIndexOldOutputs = Array.from(
          recipeOldOutputs.filter(output => output.index <= i),
          ({ value }) => value,
        );
        let lowerorEqualIndexNewOutputs = Array.from(
          recipeNewOutputs.filter(output => output.index <= i),
          ({ value }) => value,
        );
        const newOutputs: any = item?.output_keys_data?.filter(
          (output: string) => !lowerOrEqualIndexOldOutputs.includes(output),
        );
        const outputs = newOutputs?.length
          ? [...newOutputs, ...lowerorEqualIndexNewOutputs]
          : [...lowerorEqualIndexNewOutputs];
        //removing keys which are were deleted
        const keys =
          item.dynamic_settings && item.dynamic_settings.keys
            ? item.dynamic_settings.keys.map((key: any) => {
                const filteredArr = Array.isArray(key.key)
                  ? key.key.filter((sub: any) => sub === null || outputs.includes(sub))
                  : null;
                return {
                  ...key,
                  key: filteredArr ? filteredArr : key.key === null || outputs.includes(key.key) ? key.key : null,
                };
              })
            : null;

        const filteredDynamicSettings = keys?.length > 0 ? { keys: keys } : null;
        // checking if dynamic settings had keys and now it removed push error
        const isTrue = keys?.length > 0 ? true : keys === item.dynamic_settings ? true : false;
        if (i === 0) completedFields = [];
        completedFields.push(isTrue);
        if (index === i) {
          return {
            ...item,
            dynamic_settings: filteredDynamicSettings,
            output_keys_data: outputs,
            predefined_settings: { ...recipedata, recipeId: id },
          };
        }
        return {
          ...item,
          dynamic_settings: filteredDynamicSettings,
          output_keys_data: outputs,
        };
      });
    }
  });
  const settings = newArr ? newArr : changedList;

  if (completedFields.length === 0 && proxy_old === proxy_new && proxyGeo_old === proxyGeo_new && browser_old === browser_new && blockAds_old === blockAds_new) {
    setCompletedFields(new Array(settings.length).fill(true));
  } else if (proxy_old !== proxy_new || proxyGeo_old !== proxyGeo_new || browser_old !== browser_new || blockAds_old !== blockAds_new) {
    setGeneralErrorMessage(CHANGES_IN_RECEPIE_SETTINGS);
  } else {
    completedFields && setCompletedFields(completedFields);
    setGeneralErrorMessage(CHANGES_IN_OUTPUTS_ERROR);
  }
  setAutomationSendSettings(settings);
};
