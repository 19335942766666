import { forwardRef, useContext } from 'react';
import { ThemeContext } from 'contexts/theme-context';

import Loader from 'shared-components/atom/loader';
import { ButtonTypes } from 'shared-components/atom/types';

import './style.scss';

export type IRef = HTMLButtonElement | null;

const Button = forwardRef<IRef, ButtonTypes>(
  (
    {
      name,
      outline,
      secondary,
      light,
      disabled,
      loading,
      leftIcon,
      rightIcon,
      children,
      className,
      onClick,
      gradient,
      active,
      lightPink,
    },
    ref,
  ) => {
    const { theme } = useContext(ThemeContext);

    return (
      <button
        ref={ref || null}
        disabled={disabled}
        onClick={e => onClick && onClick(e)}
        className={`custom-button ${outline ? 'outline' : ''} ${light ? 'light' : ''} ${lightPink ? 'lightPink' : ''} ${secondary ? 'secondary' : ''} ${
          loading ? 'loading' : ''
        } ${gradient ? 'gradient' : ''} ${className ? className : ''} ${disabled ? 'disabled' : ''} ${
          active ? 'active' : ''
        }`}
      >
        {children ? (
          children
        ) : (
          <>
            <div className="btn-loader">
              <Loader light={theme === 'light'} />
            </div>
            {leftIcon && <span className="left-icon">{leftIcon}</span>}
            <span>{name}</span>
            {rightIcon && <span className="right-icon">{rightIcon}</span>}
          </>
        )}
      </button>
    );
  },
);

export default Button;
