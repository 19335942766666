import { FC, useEffect, useState } from 'react';
// import Loader from '../loader/index'
// import Select from '../../atom/select';
import Input from 'shared-components/atom/input';
import Minimize from 'shared-components/atom/minimize-icon';
import { AutomationLongCardProps } from 'shared-components/molecule/types';
import { deleteIcon, infoIcon, plusIcon } from 'assets/svg-icons';
import ModalComponent from 'shared-components/molecule/modal';
import InfoModal from 'shared-components/molecule/info-modal';
import moveImg from 'assets/icons/drag.svg';
import Tooltip from 'shared-components/atom/tooltip';
import './styles.scss';

const AutomationLongCard: FC<AutomationLongCardProps> = ({
  indexNumber,
  title,
  icon,
  description,
  longDescription,
  children,
  inputField,
  inputValue,
  handleInputValue,
  inputPlaceholder,
  noAddIcon,
  setAddNew,
  handleAutomationDelete,
  recipe,
  overflowUnset,
  propShowSettings,
  selectedRef,
  minimizeIcon,
  dragable,
  id,
  type,
  creditPrice,
  premiumPrice,
  recommended,
  last,
  output_input,
  lastUpdatedAutomations,
  resetLastUpdatedAutomations,
}) => {
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState({ show: false, text: '' });
  const [showHowToModal, setShowHowToModal] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      const inp = document.getElementById('automation-long-card-input');
      inp && inp.focus();
    }, 300);
  }, []);

  useEffect(() => {
    if (typeof propShowSettings === 'boolean') {
      setShowSettings(propShowSettings);
      return;
    }
    setTimeout(() => {
      setShowSettings(lastUpdatedAutomations?.includes(indexNumber));
    }, 80);
  }, [lastUpdatedAutomations, propShowSettings]);

  return (
    <div
      className={`automation-long-card ${showSettings && children ? 'open' : ''} ${noAddIcon ? '' : 'add-icon'} ${
        last ? 'last' : ''
      }`}
      ref={selectedRef}
    >
      {!noAddIcon && (
        <div className="position-absolute plus-add-icon">
          <div className="border-el top" />
          <Tooltip text="Add new automation" placement="right">
            <div className="svg-wrap" onClick={() => setAddNew && setAddNew(indexNumber || 0)}>
              {plusIcon}
            </div>
          </Tooltip>
          <div className="border-el bottom" />
        </div>
      )}

      {inputField ? (
        <div className="automation-long-card-header">
          <Input
            id="automation-long-card-input"
            placeholder={inputPlaceholder}
            leftIcon={icon}
            noBorders
            value={inputValue}
            onChange={e => handleInputValue && handleInputValue(e)}
            autoFocus
          />
        </div>
      ) : (
        <div
          className={`automation-long-card-header ${minimizeIcon ? '' : 'no-border-bottom'}`}
          onClick={e => {
            //@ts-ignore
            e.target.nodeName !== 'svg' && setShowSettings(!showSettings);
            //@ts-ignore
            e.target.nodeName !== 'svg' && resetLastUpdatedAutomations && resetLastUpdatedAutomations(indexNumber);
          }}
        >
          {!recipe && (
            <div style={{ width: '35px' }}>
              <div className="automation-long-card-icon">
                {icon && typeof icon === 'string' && <img src={icon} alt="automation type icon" />}
              </div>
            </div>
          )}
          <div className="absolute-icon">{minimizeIcon && <Minimize bool={showSettings && children} />}</div>
          <div className="ml-3 automation-long-text text-truncate">
            <h5>{recipe ? `${recipe.name}` : title}</h5>
            <p>{recipe ? recipe.url : description}</p>
          </div>

          {dragable && (
            <div className="automation-long-header-left p-1">
              <div className="move-icon-wrapper">
                <Tooltip placement="right" text="Automation is draggable" className="pr-2">
                  <img src={moveImg} className="move" />
                </Tooltip>
              </div>
            </div>
          )}
          <div className="automation-long-header-left p-1">
            {recipe?.name !== 'Deleted Recipe' && !recommended && (
              <span className="info-icon" onClick={() => setShowHowToModal(true)}>
                {infoIcon}
              </span>
            )}
            {handleAutomationDelete && (
              <div
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowConfirmModal({ show: true, text: 'Are you sure you want to delete the automation?' });
                }}
              >
                <Tooltip placement="left" text={`Remove ${recipe ? 'scraping recipe' : 'automation'}`}>
                  <span>{deleteIcon}</span>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      )}
      {showSettings && children && (
        <div
          className={`automation-long-body ${overflowUnset ? 'overflow-unset' : ''} ${inputField ? 'max-height' : ''} ${
            !minimizeIcon && !inputField ? 'uncollabsable' : ''
          }`}
          style={{ height: inputField ? 'auto' : minimizeIcon ? 'auto' : 0 }}
        >
          {children}
        </div>
      )}
      {/* {!noAddIcon && setAddNew && (
        <div className="position-absolute add-new-wrap">
          <Tooltip text="Add new automation" placement="bottom">
            <span className="add-new-automation" onClick={() => setAddNew(indexNumber || 0)} />
          </Tooltip>
        </div>
      )} */}
      {
        <ModalComponent
          isModalOpen={showConfirmModal.show}
          text={showConfirmModal.text}
          actionBtnText={'Delete'}
          cancelBtnText={'Cancel'}
          actionBtnColor={'danger'}
          cancelBtnColor={'secondary'}
          onActionClick={() => {
            handleAutomationDelete && handleAutomationDelete(indexNumber || 0);
            setShowConfirmModal({ show: false, text: '' });
          }}
          onCancelClick={() => setShowConfirmModal({ show: false, text: '' })}
          escButtonClick={() => setShowConfirmModal({ show: false, text: '' })}
        />
      }
      {showHowToModal && (
        <InfoModal
          isModalOpen={showHowToModal}
          description={longDescription || ''}
          title={title || ''}
          setShowModal={(val: boolean) => setShowHowToModal(val)}
          id={id}
          type={type}
          creditPrice={creditPrice}
          premiumPrice={premiumPrice}
          output_input={output_input}
          recipeName={recipe?.name}
        />
      )}
    </div>
  );
};

export default AutomationLongCard;
