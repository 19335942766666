import React, { FC, useEffect, useState } from 'react';
import { useAutomationGlobalMethods } from '../../hooks';
import { Col, Row } from 'react-bootstrap';
import Input from 'shared-components/atom/input';
import Select from 'shared-components/atom/select';
import Alert from 'shared-components/atom/alert-message';
import DataList from 'shared-components/atom/dataList';

import { proxyList } from 'constants/index';
import { residential_geo_proxy_country_list } from 'constants/countries/residential-geo-proxy-countries';
import { infoIcon } from 'assets/svg-icons';

const limitOptions = [
  { label: 'Extract all URLs', value: '' },
  { label: 'Limit URLs', value: 'limit' },
];

const SitemapExtractorSettings: FC<any> = ({
  automationSendSettings,
  selectedRecipes,
  automationIndex,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);
  const [clicked, setClicked] = useState(false);
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [inputText, setInputText] = useState('');
  const [selectedLimitOption, setSelectedLimitOption] = useState({
    label: 'Extract all URLs',
    value: '',
  });
  const [from, setFrom] = useState<number | string>(0);
  const [to, setTo] = useState<number | string>(100);
  const [errorMessage, setErrorMessage] = useState('');
  const [filterOption, setFilterOption] = useState<{ label: string; value: null | boolean }>({
    label: 'Select type',
    value: null,
  });
  const [filterText, setFilterText] = useState('');
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);
  const [selectedProxy, setSelectedProxy] = useState({ label: 'Data Center', value: '' });
  const [residentialGEOCountry, setResidentialGEOCountry] = useState('');

  const {
    getPreviousAutomationOutputOrPreviousRecipKeys,
    liftingSettings,
    restoreSelectedSource,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    getDynamicAndIntegrationsSettings,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (fieldList?.length === 0) {
      if (!inputText) {
        return false;
      }
    } else {
      if (!selectedSource) {
        return false;
      }
    }
    if (selectedLimitOption && ((typeof from !== 'number' && !from) || (typeof to !== 'number' && !to))) {
      return false;
    }
    if (selectedProxy?.value === 'premium' && !residentialGEOCountry) {
      return false;
    }
    return true;
  };

  //getting previous outputs
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    if (automationSendSettings) {
      const { dynamic_settings } = getDynamicAndIntegrationsSettings();

      if (dynamic_settings) {
        if (fieldList.length === 0) {
          const { keys } = dynamic_settings;
          const input = keys[0].input || keys[0].inputValue;
          setInputText(input);
        } else {
          const keys = dynamic_settings.keys;
          const { key, settings } = dynamic_settings.keys[0];

          restoreSelectedSource({ key: key, fieldList: fieldList, setSelectedSource: setSelectedSource });
        }
        const settings = dynamic_settings.keys[0].settings;
        const proxy = settings.proxy;

        if (proxy) {
          const selectedProxy = proxyList.find((val: any) => val.value === proxy.type);
          const selectedCountry = residential_geo_proxy_country_list.find(
            (country: any) => country.value === proxy.country_code,
          );
          selectedProxy && setSelectedProxy(selectedProxy);
          selectedProxy?.value === 'premium' && selectedCountry && setResidentialGEOCountry(selectedCountry.label);
        }
        setFilterOption({
          label:
            !settings.contains && !settings.notContains
              ? 'Select type'
              : settings.contains
              ? 'Contains'
              : 'Does Not Contain',
          value: !settings.contains && !settings.notContains ? null : !!settings.contains,
        });
        setFilterText(settings.contains || settings.notContains);
        if (settings && typeof settings.specifiedUrlFrom === 'number' && typeof settings.specifiedUrlTo === 'number') {
          setSelectedLimitOption(limitOptions[1]);
          setFrom(settings.specifiedUrlFrom);
          setTo(settings.specifiedUrlTo);
        }
      }
    }
  }, [fieldList]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [...fieldList.map((item: { value: any }) => item.value), '_sitemap', '_sitemap_url'],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);

  //lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: {
        keys: [
          {
            inputValue: fieldList.length === 0 || automationIndex === 0 ? inputText.trim() : null,
            key: fieldList.length && selectedSource ? selectedSource.value : null,
            settings: {
              specifiedUrlFrom: selectedLimitOption?.value && (typeof from === 'number' || from) ? +from : null,
              specifiedUrlTo: selectedLimitOption?.value && (typeof to === 'number' || to) ? +to : null,
              contains: filterOption.value ? filterText : '',
              notContains: !filterOption.value ? filterText : '',
              proxy: {
                type: selectedProxy.value,
                country_code:
                  residential_geo_proxy_country_list.find(item => item.label === residentialGEOCountry)?.value || '',
              },
            },
          },
        ],
      },
      outputKeysData: [...fieldList.map((item: { value: any }) => item.value), '_sitemap', '_sitemap_url'],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [
    inputText,
    filterText,
    filterOption,
    selectedSource,
    from,
    to,
    selectedLimitOption,
    fieldList,
    fieldListWithTypes,
    automationOutputTypes,
    selectedProxy,
    residentialGEOCountry,
    automationIndex,
  ]);

  const clearErrorMsg = () => {
    setTimeout(() => {
      setErrorMessage('');
    }, 3000);
  };

  const handleSaveFrom = (e: { target: { value: any } }) => {
    errorMessage && setErrorMessage('');
    let val = e.target.value;
    val = val === '' ? '' : +val;
    if (isNaN(val)) {
      setErrorMessage('Please use only numbers');
      clearErrorMsg();
      return;
    }
    if (to < val) {
      setErrorMessage('"from" value can`t be greater than or equal to "to" value');
    }
    if (val === '') {
      setErrorMessage('Please fill "from" field');
    }
    if (to === '') {
      setErrorMessage('Please fill "to" field');
    }
    setFrom(val);
    !clicked && setClicked(true);
    setIsDirty();
  };

  const handleSaveTo = (e: { target: { value: any } }) => {
    errorMessage && setErrorMessage('');
    let val = e.target.value;
    val = val === '' ? '' : +val;
    if (isNaN(val)) {
      setErrorMessage('Please use only numbers');
      clearErrorMsg();
      return;
    }
    if (from > val) {
      setErrorMessage('"to" value can`t be less than or equal to "from" value');
    }
    if (val === '') {
      setErrorMessage('Please fill "to" field');
    }
    if (from === '') {
      setErrorMessage('Please fill "from" field');
    }
    setTo(val);
    !clicked && setClicked(true);
    setIsDirty();
  };
  const handleChangeResidentialGEOCountry = (text: string) => {
    // setError('');
    setResidentialGEOCountry(text);
    !clicked && setClicked(true);
    setIsDirty();
  };

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  return (
    <>
      <Row className="">
        {fieldList.length > 0 || automationIndex > 0 ? (
          <Col className="mb-4" lg={6}>
            <span className="label secondary-color">Source</span>
            <Select
              options={fieldList}
              value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
              placeholder={'Select source'}
              onChange={(selectedItem: any) => {
                setSelectedSource(selectedItem);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
        ) : (
          <Col lg={6} className="mb-4">
            <span className="label secondary-color">URL</span>
            <Input
              value={inputText}
              onChange={e => {
                setInputText(e.target.value);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Limit option</span>
          <Select
            options={limitOptions}
            value={selectedLimitOption}
            placeholder={'Select limit'}
            onChange={(selectedItem: any) => {
              setSelectedLimitOption(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
        {selectedLimitOption.value && (
          <Col lg={6} className="mb-4">
            <Row>
              <Col md={6}>
                <span className="label secondary-color">from</span>
                <Input value={from} onChange={handleSaveFrom} />
              </Col>
              <Col md={6}>
                <span className="label secondary-color">to</span>
                <Input value={to} onChange={handleSaveTo} />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <Row>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Filter type (optional)</span>
          <Select
            options={[
              { label: 'Contains', value: true },
              { label: 'Does Not Contain', value: false },
            ]}
            value={filterOption}
            placeholder={'Select option'}
            onChange={(selectedItem: any) => {
              setFilterOption(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
        {filterOption.value != null && (
          <Col lg={6} className="mb-4">
            <span className="label secondary-color">Filter criteria (optional)</span>
            <Input
              value={filterText}
              onChange={e => {
                setFilterText(e.target.value);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col lg={6} className="mb-2">
          <span className="label secondary-color">Proxy mode</span>
          <Select
            options={proxyList}
            value={selectedProxy}
            placeholder={'proxy mode'}
            onChange={(selectedItem: any) => {
              setResidentialGEOCountry('');
              setSelectedProxy(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
        {selectedProxy.value === 'premium' && (
          <Col lg={6} className="mb-2">
            <span className="label secondary-color">Country</span>
            <DataList
              list={residential_geo_proxy_country_list}
              value={residentialGEOCountry}
              placeholder={'Select country'}
              onChange={(text: string) => {
                handleChangeResidentialGEOCountry(text);
              }}
            />
          </Col>
        )}
        {selectedProxy.value && (
          <Col md={12}>
            <span className="info-icon">{infoIcon}</span>
            <span className="info-message">
              This proxy consumes {selectedProxy.value === 'premium' ? '0.12' : '0.1'} premium credits per MB
            </span>
          </Col>
        )}
      </Row>
      {errorMessage && (
        <Row>
          <Col md={12}>
            <Alert type="error" className="create-workflow">
              {errorMessage}
            </Alert>
          </Col>
        </Row>
      )}
    </>
  );
};

export default React.memo(SitemapExtractorSettings);
