import { useEffect, useState } from 'react';
import { downloadIcon, expIcon, reloadIcon } from 'assets/svg-icons';
import Tooltip from 'shared-components/atom/tooltip';

import { Link, useHistory } from 'react-router-dom';

import ExportModalWithOptions from '../export-modal-with-options';
import CustomModal from 'shared-components/molecule/customModal';
import Modal from 'shared-components/molecule/modal';
import Button from 'shared-components/atom/button';

import ToastLoader from 'shared-components/molecule/toast-loader';
import '../../styles.scss';

const Tabs = ({
  workflowId,
  active = 1,
  handelRefetch,
  sortBy,
  changeSortBy,
  status,
  loading,
  selectedHeaders,
  setSelectedHeaders,
}: {
  workflowId: number;
  active?: number;
  handelRefetch?: () => any;
  sortBy?: boolean;
  changeSortBy?: () => void;
  status?: string;
  loading?: any;
  selectedHeaders?: any;
  setSelectedHeaders?: any;
}) => {
  const { push } = useHistory();

  const [openExportModal, setOpenExportModal] = useState(false);
  const [shouldUpgrade, setShouldUpgrade] = useState(false);
  const [toasterInfo, setToasterInfo] = useState({
    show: false,
    errorMessage: '',
    loadingMessage: '',
    successMessage: '',
  });

  const handleToastShow = (show: boolean, errorMessage: string, loadingMessage: string, successMessage: string) => {
    setToasterInfo({ show: show, errorMessage, loadingMessage, successMessage });
  };

  useEffect(() => {
    if (toasterInfo.show && (toasterInfo.errorMessage || toasterInfo.successMessage)) {
      setTimeout(() => {
        handleToastShow(false, '', '', '');
      }, 5000);
    }
  }, [toasterInfo]);

  return (
    <div className="d-flex tabs workflow-tabs" style={{ justifyContent: 'flex-start' }}>
      <div className="d-flex">
        <Link to={`/workflows/current-workflow/${workflowId}`} className="text-decoration-none">
          <div className={`tab mr-4 h-100 ${active === 1 && 'active-tab'}`}>Current Workflow</div>
        </Link>
        <Link to={`/workflows/log-data/${workflowId}`} className="text-decoration-none">
          <div className={`tab  mr-4 h-100 ${active === 2 && 'active-tab'}`}>Log Data</div>
        </Link>
        <Link to={`/workflows/result/${workflowId}`} className="text-decoration-none">
          <div className={`tab mr-4 h-100 ${active === 3 && 'active-tab'}`}>Results Preview</div>
        </Link>
        <Link to={`/workflows/history/${workflowId}`} className="text-decoration-none">
          <div className={`tab h-100 ${active === 4 && 'active-tab'}`}>History</div>
        </Link>
      </div>
      <div className="d-flex align-items-center buttons-wrap w-100">
        {active !== 1 && (
          <Tooltip
            trigger={['hover']}
            placement={window.innerWidth > 600 ? 'bottom' : 'right'}
            text={`Refresh ${active === 2 ? 'log data' : 'results'}`}
          >
            <button
              onClick={handelRefetch}
              className={`tabs-reload-icon mx-2 d-flex my-auto ${active === 1 ? 'invisible' : 'visible'}`}
            >
              <span className="m-auto">{reloadIcon}</span>
            </button>
          </Tooltip>
        )}
        {changeSortBy && (
          <Tooltip
            trigger={['hover']}
            placement={window.innerWidth > 600 ? 'bottom' : 'right'}
            text="Sort by date"
          >
            <button
              onClick={changeSortBy}
              className={`tabs-reload-icon d-flex my-auto mr-2 align-items-center ${active !== 2 ? 'd-none' : ''}`}
            >
              {sortBy ? (
                <span style={{ color: '#888c98' }} className="lnr lnr-arrow-up ml-2" />
              ) : (
                <span style={{ color: '#888c98' }} className="lnr lnr-arrow-down ml-2" />
              )}
            </button>
          </Tooltip>
        )}
        <div className="my-auto mb-0 export">
          <Button name="Export" onClick={() => !(status === 'DRAFT' || loading) && setOpenExportModal(true)} leftIcon={expIcon} disabled={status === 'DRAFT' || loading} />
        </div>
      </div>

      {openExportModal && (
        <CustomModal ShowPopup={openExportModal} noNavigate escButtonClick={() => setOpenExportModal(false)}>
          <ExportModalWithOptions
            workflowId={workflowId}
            setShow={setOpenExportModal}
            showUpgradeModal={setShouldUpgrade}
            setShowToastInfo={handleToastShow}
            selectedHeaders={selectedHeaders}
            setSelectedHeaders={setSelectedHeaders}
          />
        </CustomModal>
      )}
      <Modal
        isModalOpen={shouldUpgrade}
        title={'Please upgrade your plan to continue'}
        actionBtnText={'Upgrade'}
        cancelBtnText={'Cancel'}
        actionBtnColor={'warning'}
        cancelBtnColor={'secondary'}
        onActionClick={() => {
          window.location.href='https://hexomatic.com/pricing/';
          setShouldUpgrade(false);
        }}
        onCancelClick={() => setShouldUpgrade(false)}
        escButtonClick={() => setShouldUpgrade(false)}
      />
      {toasterInfo.show && (
        <ToastLoader
          errorMessage={toasterInfo.errorMessage}
          loadingMessage={toasterInfo.loadingMessage}
          successMessage={toasterInfo.successMessage}
        />
      )}
    </div>
  );
};

export default Tabs;
