import { paypalBtnIcon } from 'assets/svg-icons';
import { FC, memo } from 'react';
import Loader from '../loader';

const PaymentFooter: FC<{
  confirm: () => void;
  premium_credit?: boolean;
  paypal: boolean;
  loading: boolean;
  pendingPayment: boolean;
  disabled?: boolean;
}> = ({ confirm, premium_credit, paypal, loading, pendingPayment, disabled }) => (
  <>
    <div className="row mt-2">
      <div className="col-12 text-center">
        <button
          className={`btn confirm-and-pay border-0 ${paypal ? 'paypal' : ''}`}
          onClick={confirm}
          disabled={loading || pendingPayment || disabled}
          title={pendingPayment ? 'You have pending payment' : ''}
        >
          {loading ? (
            <Loader className='p-0'/>
          ) : paypal ? (
            <>
              <span className="text">Pay with</span> <span className="icon">{paypalBtnIcon}</span>
            </>
          ) : (
            'Confirm and Pay'
          )}
        </button>
      </div>
    </div>
    {/* <div className={`payment-text ${paypal ? 'pt-3' : ''}`}>
      {!paypal && 'We do not store your credit card information in our system at any point in time.'} Your payment
      information is securely transmitted to {paypal ? 'Paypal' : 'Stripe'}. If you have any questions, please don't
      hesitate to contact us at billing@hexact.io
    </div> */}
    {/* {premium_credit && (
      <div className="mt-2">You can start, stop and restart your premium credit subscription at any time.</div>
    )} */}
    {premium_credit && (
      <div>
        <div style={{ fontSize: '12px', marginTop: '10px' }}>* Payments for premium credits are non-refundable</div>
      </div>
    )}
  </>
);

export default memo(PaymentFooter);
