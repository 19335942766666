import { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Checkbox from 'shared-components/atom/checkbox';
import Select from 'shared-components/atom/select';
import { fileTypeOptions } from '../../constants';
import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import './styles.scss';

const ImageConverterSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [selectedUnitFrom, setSelectedUnitFrom] = useState<{ label: string; value: string } | null>(null);
  const [selectedUnitTo, setSelectedUnitTo] = useState<{ label: string; value: string } | null>(null);
  const [fieldList, setFieldList] = useState<any>([]);
  const [selectedSource, setSelectedSource] = useState<any>([]);
  const [clicked, setClicked] = useState(false);
  const [allInOne, setAllInOne] = useState(false);
  const [optionsForFrom, setOptionsForFrom] = useState(fileTypeOptions.filter(item => item.label !== 'pdf'));
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const checkFields = () => {
    if (!selectedUnitFrom || !selectedUnitTo) {
      return false;
    }
    if (selectedSource?.length === 0) {
      return false;
    }
    return true;
  };

  const {
    outputFormat,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    liftingSettings,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  //recipe fields or output fields
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();

    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings && options.length > 0) {
      const { keys } = dynamic_settings;
      const { settings } = keys[0];
      const from = settings ? settings.from : keys[0].from;
      const to = settings ? settings.to : keys[0].to;
      const allInOne = settings ? settings.allInOne : keys[0].allInOne;

      setAllInOne(allInOne);
      restoreSelectedSource({ key: keys[0].key, fieldList: options, setSelectedSource: setSelectedSource });
      const unitFrom = fileTypeOptions.find((item: { value: any }) => item.value === from);
      unitFrom && setSelectedUnitFrom(unitFrom);
      const unitTo = fileTypeOptions.find((item: { value: any }) => item.value === to);
      unitTo && setSelectedUnitTo(unitTo);
    } else {
      setSelectedSource(options.map((item: any, index: number) => ({ label: item.label, value: '' })));
    }
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [
          ...fieldList.map((item: { value: any }) => item.value),
          ...(selectedUnitTo ? [outputFormat(selectedUnitTo.value, 'converter')] : []),
        ],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);

  useEffect(() => {
    if (selectedUnitFrom && selectedUnitFrom.value === 'gif') {
      if (selectedUnitTo && selectedUnitTo.value === 'pdf') {
        setSelectedUnitTo(null);
      }
    }
  }, [selectedUnitFrom]);

  //lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);

    let localSettings = {
      keys: [
        {
          inputValue: null,
          key: fieldList.length && selectedSource ? selectedSource.value : null,
          settings: {
            to: selectedUnitTo ? selectedUnitTo.value : null,
            from: selectedUnitFrom ? selectedUnitFrom.value : null,
            allInOne: allInOne,
          },
        },
      ],
    };
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: localSettings,
      //@ts-ignore
      outputKeysData: [
        ...fieldList.map((item: { value: any }) => item.value),
        ...(selectedUnitTo ? [outputFormat(selectedUnitTo.value, 'converter')] : []),
      ],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [selectedUnitFrom, selectedUnitTo, selectedSource, allInOne, fieldList, fieldListWithTypes, automationOutputTypes]);

  return (
    <div className="image-converter-settings">
      <Row className="mb-4">
        <Col lg={6}>
          <span className="label secondary-color">Select field</span>
          <Select
            value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
            options={fieldList}
            fullWidth
            onChange={(selected: any) => {
              setSelectedSource(selected);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          ></Select>
        </Col>
      </Row>
      <Row className="">
        <Col lg={6} className="type-select mb-4">
          <h5 className="pointer-title">From</h5>
          <div className="select-block">
            <Select
              value={selectedUnitFrom}
              options={optionsForFrom}
              fullWidth
              onChange={(selectedItem: any) => {
                setSelectedUnitFrom(selectedItem);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            ></Select>
          </div>
        </Col>
        <Col lg={6} className="type-select mb-4">
          <h5 className="pointer-title">To</h5>
          <div className="select-block">
            <Select
              value={selectedUnitTo}
              options={
                selectedUnitFrom && selectedUnitFrom.value === 'gif'
                  ? fileTypeOptions.filter(tipe => tipe.value !== 'pdf')
                  : fileTypeOptions
              }
              fullWidth
              onChange={(selectedItem: any) => {
                setSelectedUnitTo(selectedItem);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            ></Select>
          </div>
        </Col>
        {selectedUnitTo && selectedUnitTo.value === 'pdf' && (
          <Col lg={6}>
            <div className="item-block">
              <Checkbox
                checked={allInOne}
                label="all images convert to one pdf"
                onChange={() => {
                  setAllInOne(!allInOne);
                  !clicked && setClicked(true);
                  setIsDirty();
                }}
              />
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default ImageConverterSettings;
