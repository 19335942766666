import { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
import { useAutomationGlobalMethods } from '../../hooks';
import DataList from 'shared-components/atom/dataList';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import { residential_geo_proxy_country_list } from 'constants/countries/residential-geo-proxy-countries';
import Input from 'shared-components/atom/input';
import { proxyList } from 'constants/index';
import { infoIcon } from 'assets/svg-icons';
import Switcher from 'shared-components/molecule/switcher';
import './styles.scss';

const resultsLimitOptions = [
  { label: 'All', value: 'all' },
  { label: '1', value: 1 },
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '20', value: 20 },
];

const filterConditionsOptions = [
  { label: 'Select condition', value: null },
  { label: 'Contains', value: 'CONTAINS' },
  { label: 'Does not contain', value: 'DOES_NOT_CONTAIN' },
];

const EmailScraperSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [selectedProxy, setSelectedProxy] = useState({ label: 'Data Center', value: '' });
  const [residentialGEOCountry, setResidentialGEOCountry] = useState('');
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [resultsLimit, setResultsLimit] = useState<{ label: string; value: number | string }>({
    label: '10',
    value: 10,
  });
  const [filterText, setFilterText] = useState('');
  const [filterCondition, setFilterCondition] = useState<{ label: string; value: null | string }>({
    label: 'Select condition',
    value: null,
  });
  const [selectedMode, setSelectedMode] = useState('Standard');

  const {
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    liftingSettings,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (!selectedSource || (filterCondition?.value && !filterText)) {
      return false;
    }
    if (selectedProxy?.value === 'premium' && !residentialGEOCountry) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const { dynamic_settings, predefined_settings } = getDynamicAndIntegrationsSettings();
    if (predefined_settings) {
      if (predefined_settings.proxy) {
        const selectedProxy = proxyList.find((val: any) => val.value === predefined_settings.proxy.type);
        const selectedCountry = residential_geo_proxy_country_list.find(
          (country: any) => country.value === predefined_settings.proxy.country_code,
        );
        selectedProxy && setSelectedProxy(selectedProxy);
        selectedCountry && setResidentialGEOCountry(selectedCountry.label);
      }
    }
    if (dynamic_settings && fieldList.length > 0) {
      const keys = dynamic_settings.keys;
      const { key, settings } = keys[0];
      const max_results_per_domain = settings ? settings.max_results_per_domain : keys[0].max_results_per_domain;
      const condition = settings ? settings.condition : keys[0].condition;
      const filter_data = settings ? settings.filter_data : keys[0].filter_data;
      const proxy = settings ? settings.proxy : keys[0].proxy;
      const fast = settings && settings.fast ? 'Fast' : 'Standard';
      setSelectedMode(fast);
      if (proxy) {
        const selectedProxy = proxyList.find((val: any) => val.value === proxy.type);
        const selectedCountry = residential_geo_proxy_country_list.find(
          (country: any) => country.value === proxy.country_code,
        );
        selectedProxy && setSelectedProxy(selectedProxy);
        selectedCountry && setResidentialGEOCountry(selectedCountry.label);
      }
      if (max_results_per_domain) {
        const max_results_per_domain_selected = resultsLimitOptions.find(item => item.value === max_results_per_domain);
        max_results_per_domain_selected && setResultsLimit(max_results_per_domain_selected);
      }
      if (condition) {
        const condition_selected = filterConditionsOptions.find(item => item.value === condition);
        condition_selected && setFilterCondition(condition_selected);
      }
      filter_data && setFilterText(filter_data);
      restoreSelectedSource({ key: key, fieldList: fieldList, setSelectedSource: setSelectedSource });
    }
  }, [fieldList]);

  //recipe fields or output fields
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      //its set from props
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [
          ...fieldList.map((item: { value: any }) => item.value),
          ...[...fieldList.map((item: { value: any }) => item.value), ...['_email_scraper']],
        ],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);

  //lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: {
        keys: [
          {
            key: fieldList.length && selectedSource ? selectedSource.value : null,
            inputValue: null,
            settings: {
              proxy: {
                type: selectedProxy.value,
                country_code:
                  residential_geo_proxy_country_list.find(item => item.label === residentialGEOCountry)?.value || '',
              },
              condition: filterCondition && filterCondition.value ? filterCondition.value : null,
              filter_data: filterCondition && filterCondition.value ? filterText.trim() : null,
              max_results_per_domain: resultsLimit.value,
              fast: selectedMode === 'Fast',
            },
          },
        ],
      },
      outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...['_email_scraper']],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [
    selectedSource,
    filterCondition,
    filterText,
    resultsLimit,
    selectedProxy,
    residentialGEOCountry,
    fieldList,
    fieldListWithTypes,
    automationOutputTypes,
    selectedMode,
  ]);

  const handleChangeResidentialGEOCountry = (text: string) => {
    // setError('');
    setResidentialGEOCountry(text);
    !clicked && setClicked(true);
    setIsDirty();
  };

  const handleSwitcherClick = (val: string) => {
    setSelectedMode(val);
    !clicked && setClicked(true);
    setIsDirty();
  };

  return (
    <div className="">
      <Row>
        <Col md={12} className="d-flex">
          <Switcher
            leftText="Fast"
            rightText="Standard"
            handleSwitcherClick={handleSwitcherClick}
            selectedValue={selectedMode}
            hoverTextLeft="Loads pages faster but may have a lower success rate."
            // hoverTextRight="Loads pages slower but may have a higher success rate."
          />
        </Col>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Source</span>
          <Select
            fullWidth
            options={fieldList}
            value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
            placeholder={'Select source'}
            onChange={(selectedItem: any) => {
              setSelectedSource(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Result limit per domain</span>
          <Select
            fullWidth
            options={resultsLimitOptions}
            value={resultsLimit}
            placeholder={'Select limit'}
            onChange={(selectedItem: any) => {
              setResultsLimit(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
      </Row>
      <Row className="automation-proxy-container mb-4">
        <Col md={6} className="mb-2">
          <span className="label secondary-color">Proxy mode</span>
          <Select
            options={proxyList}
            value={selectedProxy}
            placeholder={'proxy mode'}
            onChange={(selectedItem: any) => {
              setResidentialGEOCountry('');
              setSelectedProxy(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
        {selectedProxy.value === 'premium' && (
          <Col md={6} className="mb-2">
            <span className="label secondary-color">Country</span>
            <DataList
              list={residential_geo_proxy_country_list}
              value={residentialGEOCountry}
              placeholder={'Select country'}
              onChange={(text: string) => {
                handleChangeResidentialGEOCountry(text);
              }}
            />
          </Col>
        )}
        {selectedProxy.value && (
          <Col md={12}>
            <span className="info-icon">{infoIcon}</span>
            <span className="info-message">
              This proxy consumes {selectedProxy.value === 'premium' ? '0.12' : '0.1'} premium credits per MB
            </span>
          </Col>
        )}
      </Row>
      <Row>
        <Col md={6} className="mb-4">
          <span className="label secondary-color">Filter condition (optional)</span>
          <Select
            fullWidth
            options={filterConditionsOptions}
            value={filterCondition}
            placeholder={'Select condition'}
            onChange={(selectedItem: any) => {
              !selectedItem.value && setFilterText('');
              setFilterCondition(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
        {filterCondition && filterCondition.value && (
          <Col md={6} className="mb-4">
            <span className="label secondary-color">Filter value</span>
            <Input
              value={filterText}
              onChange={e => {
                setFilterText(e.target.value);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default EmailScraperSettings;
