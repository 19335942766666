import React from 'react';
import CustomProgress from 'shared-components/molecule/custom-progress-tooltip';
import './styles.scss';

const PaymentLoader = () => (
  <div className="text-center payment-loading-section ">
    <div className="payment-wait">Please wait</div>
    <div className="payment-process">Payment in process</div>
    <CustomProgress noMessage />
  </div>
);

export default PaymentLoader;
