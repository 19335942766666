import { FC, ReactNode, useState, useContext, useEffect, Fragment } from 'react';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
// import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import { GET_HEXOMATIC_USER, USER_PLANS_QUERY } from 'graphql/queries';
import { useMediaQuery } from 'react-responsive';
import { ADD_SHORTCUT_LOG } from 'graphql/mutations';

import { ThemeContext } from 'contexts/theme-context';
import { UserContext } from 'contexts/user-context';

// import { saveHideDash, getHideDash } from 'helpers';
import {
  getCookies,
  getItemFromLocalStorage,
  getPremiumAnnualModal,
  setHotJarUser,
  // setItemToLocalStorage,
  setPremiumAnnualModal,
} from 'helpers';

import Header from './header';
import Footer from './footer';

import Button from 'shared-components/atom/button';
import Payment from 'shared-components/molecule/payment';
import Modal from 'shared-components/molecule/modal';
import FeedbackModal from 'shared-components/atom/feedback-modal';
import CustomModal from 'shared-components/molecule/customModal';
import InviteModal from 'shared-components/organizm/invite-modal';
import CreateNewWorkflowOrRecipe from 'shared-components/organizm/create-new-element';

import RightSidebar from './components/right-sidebar';

import { SidebarToggleContext } from 'contexts/sidebar-toggle-context';
import { StateChangeContext } from 'contexts/state-change-context';
import { HexomaticUserContext } from 'contexts/hexomatic-user';

import {
  dashboardSpeedometerIcon,
  dashboardLampIcon,
  dashboardScrapingIcon,
  dashboardWorkflowIcon,
  dashboardSettingsIcon,
  mailIcon,
  feedbackIcon,
  leftArrow,
  longArrowRight,
  chatIcon,
  plusIcon,
  sidebarNewBtnIcon,
  sidebarCloseIcon,
  sidebarOpenIcon,
  firefoxIcon,
  chromeIcon,
  dashboardLibraryIcon,
  recipeLibrary,
} from 'assets/svg-icons';
// import HexomaticLogoRect from 'assets/logos/hexomatic-logo-rectangle.svg';
// import HexomaticLogoRectWhite from 'assets/logos/hexomatic-logo-rectangle-white.svg';
// import HexomaticLogo from 'assets/logos/hexomatic-logo.svg';
// import giftGif from 'assets/hexomatic-gift-gif.gif';
// import giftGifDark from 'assets/hexomatic-gift-gif-dark.gif';
// import giftImg from 'assets/hexomatic-gift-img.png';
// import hgiftImg from 'assets/hexomatic-gift.png';
// import giftImgDark from 'assets/hexomatic-gift-img-dark.png';

import './layout.scss';

interface LayoutProps {
  children: ReactNode;
}

const settings = [
  { icon: dashboardSpeedometerIcon, title: 'Dashboard', path: '/dashboard', arrow: false },
  { icon: dashboardWorkflowIcon, title: 'Workflows', path: '/workflows', arrow: false },
  { icon: dashboardLibraryIcon, title: 'Workflow Templates', path: '/public-workflows', arrow: false },
  { icon: dashboardLampIcon, title: 'Automations', path: '/automations', arrow: false },
  { icon: dashboardScrapingIcon, title: 'Scraping Recipes', path: '/scraping-recipes', arrow: false },
  // { icon: recipeLibrary, title: 'Scraping Templates', path: '/scraping-public-recipes', arrow: false },
  { icon: dashboardSettingsIcon, title: 'Settings', path: '/settings', arrow: false },
];

const getRightSidebarStatus = (): boolean => {
  const item = getItemFromLocalStorage('products-sidebar');
  localStorage.removeItem('r-sidebar');

  // if (item === null) {
  //   setItemToLocalStorage('r-sidebar', true);
  //   return true;
  // }

  return !!item;
};

const getLeftSidebarStatus = (): boolean => {
  const item = getItemFromLocalStorage('nav-sidebar');
  return typeof item === 'boolean' ? !!item : !!(window?.innerWidth > 575);
};

const Layout: FC<LayoutProps> = ({ children }) => {
  const { user } = useContext(UserContext);
  const { hexomaticUser, setHexomaticUser } = useContext(HexomaticUserContext);
  const { data: hexomaticUserData } = useQuery(GET_HEXOMATIC_USER, {
    fetchPolicy: 'no-cache',
  });
  const { theme } = useContext(ThemeContext);
  const { setStateChange } = useContext(StateChangeContext);
  const { pathname } = useLocation();
  const history = useHistory();

  const [toggle, setToggle] = useState(getLeftSidebarStatus());
  const [rightToggle, setRightToggle] = useState(getRightSidebarStatus);
  const [hexometerPlan, setHexometerPlan] = useState('');

  const [isAlert, setIsAlert] = useState(false);
  const [openPremiumCreditsPopup, setOpenPremiumCreditsPopup] = useState(false);
  const [createNew, setCreateNew] = useState(false);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [isSuccessfullySent, setIsSuccessfullySent] = useState(false);

  const [addShortCutLog] = useMutation(ADD_SHORTCUT_LOG, {
    variables: {
      type: 'hexomatic',
    },
  });

  const { data: allPlansData } = useQuery(USER_PLANS_QUERY, {
    fetchPolicy: 'no-cache',
  });

  // const [paypalToken] = useMutation(PAYPAL_UPGRADE);
  // const [paypalApproved] = useMutation(PAYPAL_PAYMENT_APPROVED);

  useEffect(() => {
    if (hexomaticUserData?.HexomaticUser?.getByLegacyId?.id) {
      setHexomaticUser(hexomaticUserData.HexomaticUser.getByLegacyId);
    }
  }, [hexomaticUserData]);

  useEffect(() => {
    if (allPlansData?.User?.getUserPlans?.hexometer) {
      setHexometerPlan(allPlansData.User.getUserPlans.hexometer.pricing_package);
    }
  }, [allPlansData]);

  useEffect(() => {
    if (hexomaticUser && hexomaticUser.id && user && user.id) {
      // if (
      //   (hexomaticUser.pricing_package &&
      //     (hexomaticUser.pricing_package === 'GOLD' || hexomaticUser.pricing_package === 'SILVER')) ||
      //   !hexomaticUser.pricing_package
      // ) {
      //   saveHideDash(false);
      // } else {
      //   saveHideDash(true);
      // }

      setHotJarUser(
        +user.id,
        hexomaticUser.pricing_package === 'FREE' ? 'free' : 'paid',
        hexomaticUser.pricing_package,
        hexomaticUser.pricing_package_interval,
        user.premium_credits_pricing_package,
      );
    }
  }, [hexomaticUser, user]);

  useEffect(() => {
    if (window.location.href.includes('#invite')) {
      setOpenInviteModal(true);
    }
  }, []);

  useEffect(() => {
    if (pathname === '/:jj') history.push(pathname.replace('/:jj', ''));
    !pathname.includes('workflow') && localStorage.removeItem('worklows-details');
    !pathname.includes('scraping-recipes') && localStorage.removeItem('recipes-details');
    if (!pathname.includes('tasks')) {
      localStorage.removeItem('tasksPage');
      localStorage.removeItem('tasksOption');
    }
    if (!pathname.includes('workflow') || pathname === '/workflows') {
      localStorage.removeItem('public-worklows-details');
    }
    !pathname.includes('scraping-public-recipes/current-recipe') && localStorage.removeItem('publicRecipe');
    pathname !== '/workflows/create' && setStateChange({});
  }, [pathname]);

  const handleChatClick = (e: any) => {
    e.preventDefault();
    //@ts-ignore
    window.Intercom('show');
  };
  const check = async () => {
    if (window.matchMedia('(display-mode: standalone)').matches && !sessionStorage.getItem('standalone')) {
      sessionStorage.setItem('standalone', 'true');
      await addShortCutLog();
    }
  };
  useEffect(() => {
    check();
    if (window.location.href.includes('premium-credits') || getPremiumAnnualModal()) {
      setOpenPremiumCreditsPopup(true);
      setPremiumAnnualModal('');
    }
  }, []);

  useEffect(() => {
    if (window.location.href.includes('PayerID=') || window.location.href.includes('&ba_token=')) {
      window.close();
    }
  }, []);

  const handleCloseModal = (val: boolean) => {
    setOpenPremiumCreditsPopup(val);
    if (!val && window.location.search.includes('premium-credits')) {
      history.replace(window.location.pathname);
    }
  };

  const handleEmptyCookieRedirectMain = () => {
    const cookieKeys = Object.keys(getCookies());
    if (cookieKeys.length && cookieKeys.includes('user-token')) {
      return;
    }
    if (!window.location.href.includes('/dev-login')) {
      window.location.href.indexOf('localhost') > -1
        ? window.location.replace('http://localhost:3000/dev-login')
        : window.location.href.indexOf('dashhexomatic.hexact.io') > -1
        ? window.location.replace('https://hexomatic.hexact.io')
        : window.location.replace('https://hexomatic.com/');
    }
  };

  handleEmptyCookieRedirectMain();

  window.addEventListener('resize', check);

  //Sidebar height offset depending on header height changes
  const headerEl = document.getElementById('header');
  const headerHeight = headerEl && headerEl.offsetHeight;

  const navEl = document.getElementById('nav');
  const navElH = navEl && navEl.offsetHeight;

  const isMobile = useMediaQuery({ maxWidth: 585 });

  useEffect(() => {
    const el = document.getElementById('sticky-search-container');
    if (el) {
      const current = el.className || '';
      toggle ? (el.className = current + ' sidebar-open') : (el.className = current.replace(/sidebar-open/g, ''));
    }
  }, [toggle]);

  useEffect(() => {
    const el = document.getElementById('sticky-search-container');
    if (el) {
      const current = el.className || '';
      rightToggle
        ? (el.className = current + ' r-sidebar-open')
        : (el.className = current.replace(/r-sidebar-open/g, ''));
    }
  }, [rightToggle]);

  const handleScrollEvent = () => {
    const top: number = document.documentElement.scrollTop || document.body.scrollTop;
    const el = document.getElementById('sticky-search-container');
    if (el) {
      top > 40 ? el.classList.add('scroll') : el.classList.remove('scroll');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollEvent);
    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, []);

  return (
    <div
      className={`${
        window.location.pathname.includes('/black-friday-deal') || window.location.pathname.includes('/spring-break-promo') ? 'page-container-dark' : 'page-container'
      }`}
    >
      {window.location.pathname.includes('/black-friday-deal') || window.location.pathname.includes('/spring-break-promo') ? (
        <>
          <div className="mt-3">
            {/* {getHideDash() && window.location.pathname !== '/sign-out' ? (
            <h5
              style={{ maxWidth: '1000px', margin: 'auto', textAlign: 'center', marginTop: '50px' }}
              className="secondary-color"
            >
              Thanks for joining the early access program. <br />
              We are currently running a closed beta with our existing customers and we will notify you as soon as we
              open for our wait-list early adopters. <br />
              We will get in touch closer to the launch date via email and our{' '}
              <a href="https://www.facebook.com/groups/731525803929555" className="clickable-text" target="_blank" rel='noopener'>
                facebook group here
              </a>{' '}
            </h5>
          ) : (
            children
          )} */}
            {children}
          </div>
        </>
      ) : (
        <>
          <Header
            toggle={toggle}
            setToggle={setToggle}
            isAlert={isAlert}
            setIsAlert={setIsAlert}
            user={user}
            logo={false}
            hexometerPlan={hexometerPlan}
          />
          <SidebarToggleContext.Provider value={{ opened: toggle, rightOpened: rightToggle }}>
            <div
              className={`body ${toggle && 'body-pd'} ${rightToggle ? 'body-r-pd' : ''} ${
                user && user.shouldBeDeletedAt ? 'alert-space-padding' : ''
              } ${window.location.pathname === '/pricing' ? 'body-pricing' : ''}`}
            >
              {
                <div
                  className={`l-navbar ${toggle ? 'show-sidebar' : 'show-mobile'}`}
                  id="nav-bar"
                  style={{ height: `calc(100vh - ${headerHeight || 60}px)` }}
                >
                  {/* <button onClick={() => setToggle(!toggle)} className={`sidebar-collapse-button`}>
                <span className={`${toggle ? '' : 'rotated'}`}>{leftArrow}</span>
              </button> */}
                  <nav id="nav" className="nav">
                    <div>
                      {/* <div className="nav__logos d-flex justify-content-center">
                    <Link to="/" onClick={() => window && window.innerWidth < 1025 && setToggle(!toggle)}>
                      <span className="d-block">
                        <img
                          src={
                            toggle ? (theme === 'light' ? HexomaticLogoRect : HexomaticLogoRectWhite) : HexomaticLogo
                          }
                          alt="Hexomatic Logo"
                        />
                      </span>
                    </Link>
                  </div> */}
                      <div className="sidebar-create-new">
                        {toggle ? (
                          <Button
                            name="New"
                            leftIcon={sidebarNewBtnIcon}
                            outline
                            className={`d-inline ${toggle ? '' : 'pr-0 pl-1'} btn-hv-color `}
                            onClick={setCreateNew}
                          />
                        ) : (
                          <div className="closed-sidebar-new-btn">
                            <span onClick={() => setCreateNew(true)} className="text-center">
                              {sidebarNewBtnIcon}
                            </span>
                          </div>
                        )}
                      </div>
                      {/* <div className={`sidebar-create-new ${toggle ? '' : 'min'}`}>
                    <Button
                      name={toggle ? 'New' : ''}
                      leftIcon={plusIcon}
                      outline
                      className={`d-inline ${toggle ? '' : 'pr-0 pl-1'}`}
                      onClick={() => setCreateNew(true)}
                    />
                  </div> */}

                      <div className="nav__list">
                        {settings.map((obj, index) => {
                          return (
                            <Fragment key={obj.title}>
                              {/* {index === 2 || index === 4 ? <div className="nav-border-top" /> : null} */}
                              <NavLink
                                key={obj.title}
                                to={obj.path}
                                activeClassName="active-nav-link"
                                className={`nav__link ${index > 2 ? 'secondary' : ''}`}
                                onClick={() => window && window.innerWidth < 1025 && setToggle(!toggle)}
                              >
                                <span className="nav-link-left-icon">{obj.icon}</span>
                                <span className={`nav__name ${toggle ? '' : 'hidden_name'}`}>{obj.title}</span>
                                {obj.arrow && <span className="nav-link-right-icon">{longArrowRight}</span>}
                              </NavLink>
                            </Fragment>
                          );
                        })}
                      </div>
                    </div>

                    <div className="">
                      <div className="sidebar-collapse">
                        <button onClick={() => {
                          setToggle(!toggle);
                          localStorage.setItem('nav-sidebar', JSON.stringify(!toggle));
                        }} className="sidebar-collapse-button">
                          {toggle ? sidebarCloseIcon : sidebarOpenIcon}
                        </button>
                      </div>
                      {/* <div
                    className={`nav__link__gift ${toggle ? 'pl-3' : ''}`}
                    onClick={() => {
                      localStorage.setItem('invite-clicked', 'true');
                      setOpenInviteModal(true);
                    }}
                  >
                    <img
                      src={
                        localStorage.getItem('invite-clicked')
                          ? theme === 'light'
                            ? hgiftImg
                            : hgiftImg
                          : theme === 'light'
                          ? giftGif
                          : giftGifDark
                      }
                      style={{ width: '25px', height: '25px', marginBottom: '10px' }}
                    />
                    {toggle && (
                      <span className={`nav__name ${toggle ? '' : 'hidden_name'}`} style={{ color: '#B72CFF' }}>
                        Earn Premium Credits
                      </span>
                    )}
                  </div> */}
                    </div>

                    <div className="sidebar__block__bottom my-0">
                      {/* {toggle && <span className="block__bottom__title mobile-d-none">Any Questions?</span>} */}
                      <a
                        role="button"
                        className={`${toggle ? 'toggle' : ''} nav__link__feedback`}
                        onClick={() => user && setFeedbackModal(true)}
                      >
                        {feedbackIcon} <span className={`nav__name ${toggle ? '' : 'hidden_name'}`}>Feedback</span>
                      </a>
                      <a
                        role="button"
                        className={`${toggle ? 'toggle' : ''} nav__link__chat`}
                        onClick={handleChatClick}
                      >
                        {chatIcon}
                        <span className={`nav__name ${toggle ? '' : 'hidden_name'}`}>Chat Now</span>
                      </a>
                      <a
                        className={`${toggle ? 'toggle' : ''} nav__link__email`}
                        href="mailto:support@hexomatic.com"
                        target="_blank"
                        rel="noopener"
                      >
                        {mailIcon}
                        <span className={`nav__name ${toggle ? '' : 'hidden_name'}`}>Contact Support</span>
                      </a>

                      {!isMobile && (
                        <div className="nav__link__extensions">
                          <div className="d-flex justify-content-center">
                            <p className={`${toggle ? '' : 'hidden_name'}`}>DOWNLOAD EXTENSION:</p>
                            <a
                              href="https://chrome.google.com/webstore/detail/hexomatic/gaeglheibddedbjiehaeeacoldaiimkl"
                              target="_blank"
                              className="extension-icon"
                              rel="noopener"
                            >
                              {chromeIcon}
                            </a>
                            <a
                              href="https://addons.mozilla.org/en-US/firefox/addon/hexomatic/"
                              target="_blank"
                              className="extension-icon"
                              rel="noopener"
                            >
                              {firefoxIcon}
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </nav>
                </div>
              }
              <div className="mt-2 mx-1 mx-md-3 page-content">
                {/* {getHideDash() && window.location.pathname !== '/sign-out' ? (
            <h5
              style={{ maxWidth: '1000px', margin: 'auto', textAlign: 'center', marginTop: '50px' }}
              className="secondary-color"
            >
              Thanks for joining the early access program. <br />
              We are currently running a closed beta with our existing customers and we will notify you as soon as we
              open for our wait-list early adopters. <br />
              We will get in touch closer to the launch date via email and our{' '}
              <a href="https://www.facebook.com/groups/731525803929555" className="clickable-text" target="_blank" rel='noopener'>
                facebook group here
              </a>{' '}
            </h5>
          ) : (
            children
          )} */}
                {children}
              </div>
              <RightSidebar
                navElH={navElH}
                headerHeight={headerHeight}
                rightToggle={rightToggle}
                setRightToggle={setRightToggle}
                hexometerPlan={hexometerPlan}
              />
            </div>
          </SidebarToggleContext.Provider>
          <Footer toggle={toggle} noPadding={false} />
        </>
      )}
      <Modal
        isModalOpen={createNew}
        actionBtnText=""
        onActionClick={() => {}}
        actionBtnColor="transparent"
        text={<CreateNewWorkflowOrRecipe onClick={() => setCreateNew(false)} />}
        onCloseModal={() => setCreateNew(false)}
        onlyBtns
        className="p-0"
        parentClass="create-new-modal "
        escButtonClick={() => setCreateNew(false)}
      />
      <Modal
        isModalOpen={feedbackModal}
        actionBtnText=""
        onActionClick={() => {}}
        actionBtnColor="transparent"
        text={<FeedbackModal setIsSuccessfullySent={setIsSuccessfullySent} onClose={() => setFeedbackModal(false)} />}
        onCloseModal={() => setFeedbackModal(false)}
        onlyBtns
        className="p-0"
        parentClass="feedback-modal"
        escButtonClick={() => setFeedbackModal(false)}
      />
      <Modal
        isModalOpen={isSuccessfullySent}
        text="Feedback has been successfully sent"
        actionBtnText="Ok"
        actionBtnColor="success"
        cancelBtnColor="primary"
        onActionClick={() => setIsSuccessfullySent(false)}
        escButtonClick={() => setIsSuccessfullySent(false)}
      />
      {openPremiumCreditsPopup && (
        <CustomModal
          ShowPopup={openPremiumCreditsPopup}
          setShowPopup={(val: boolean) => handleCloseModal(val)}
          hideModalBody
          className={'payment-modal modal-zoom'}
          escButtonClick={(val: boolean) => handleCloseModal(val)}
        >
          <Payment
            name={''}
            selectedValue={{ title: '105/monthly', value: 105 }}
            price={0}
            premium_credit
            setShowPopup={(val: boolean) => handleCloseModal(val)}
            // offer={!!window.location.href.includes('#premium-credits')}
            // offer
          />
        </CustomModal>
      )}
      {openInviteModal && <InviteModal onClose={() => setOpenInviteModal(false)} isDarkMode={theme === 'dark'} />}
    </div>
  );
};

export default Layout;
