import { FC } from 'react';
import { eventStop } from 'helpers';
import Checkbox from 'shared-components/atom/checkbox';
import Sort from 'shared-components/atom/sort';

interface IHead {
  list: { title: string; id?: number }[];
  sort?: { id: number; by: string };
  setSort?: (val: { id: number; by: string }) => void;
  checkAll?: boolean;
  setCheckAll?: (val: boolean) => void;
}

const Header: FC<IHead> = ({ list, sort, setSort = () => {}, checkAll, setCheckAll }) => (
  <div className="card-header">
    <div className="d-flex align-items-center justify-content-between">
      {setCheckAll && (
        <div className="pl-4 card-header-item d-flex align-items-center justify-content-start mw-60">
          <span onClick={e => eventStop(e)}>
            <Checkbox checked={checkAll || false} onChange={setCheckAll} />
          </span>
        </div>
      )}
      {list.map(item => (
        <div className="text-center no-wrap pl-3 card-header-item d-flex align-items-center justify-content-center">
          {item.title} {sort && item.id && <Sort id={item.id} sort={sort} onClick={setSort} />}
        </div>
      ))}
    </div>
  </div>
);

export default Header;
