import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { videoLinksSocial } from '../../constants';
import Select from 'shared-components/atom/select';
import CustomMultiSelect from 'shared-components/molecule/multiselect';
import { useAutomationGlobalMethods } from '../../hooks';
import './styles.scss';

const VideoExtractor: FC<any> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [selected, setSelected] = useState<any>([]);
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);
  const [clicked, setClicked] = useState(false);
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const {
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    liftingSettings,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (selected?.length === 0) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    if (dynamic_settings && fieldList.length > 0) {
      const key = dynamic_settings.keys[0].key;
      restoreSelectedSource({ key: key, fieldList: fieldList, setSelectedSource: setSelectedSource });
      const { keys } = dynamic_settings;
      const { settings } = keys && keys[0];
      const socialLinks = settings?.socialLinks;

      const selectedOptions = socialLinks?.map((social: string) => {
        const finded = videoLinksSocial.find(item => item.value === `_video_link_${social}`);
        return finded ? finded : { label: social, value: social };
      });
      selectedOptions && setSelected(selectedOptions);
    }
  }, [fieldList]);

  //recipe fields or output fields
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, []);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [
          ...fieldList.map((item: { value: any }) => item.value),
          ...selected.map((social: { value: any }) => `_video_link_${social.value}`),
        ],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);

  //lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);

    let localSettings = {
      socialLinks: selected.map((social: { value: any }) => social.value),
    };
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: {
        keys: [
          {
            key: fieldList.length && selectedSource ? selectedSource.value : null,
            inputValue: null,
            settings: localSettings,
          },
        ],
      },
      preDefinedSettings: {
        browser: false,
      },
      outputKeysData: [
        ...fieldList.map((item: { value: any }) => item.value),
        ...selected.map((social: { value: any }) => `_video_link_${social.value}`),
      ],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [selected, selectedSource, automationOutputTypes, fieldListWithTypes]);

  return (
    <div className="social-link-scraper-settings">
      <Row>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Source</span>
          <Select
            fullWidth
            options={fieldList}
            value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
            placeholder={'Select source'}
            onChange={(selectedItem: any) => {
              setSelectedSource(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col lg={6}>
          <span className="label secondary-color">Select channels</span>
          <CustomMultiSelect
            options={videoLinksSocial}
            value={selected}
            onChange={(sel: any) => {
              setSelected(sel);
              !clicked && setClicked(true);
              setIsDirty();
            }}
            labelledBy="Select"
          />
        </Col>
      </Row>
    </div>
  );
};

export default VideoExtractor;
