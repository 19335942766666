import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
import CustomMultiSelect from 'shared-components/molecule/multiselect';
import { fixNumbers, numberOption } from '../../constants';
import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import './styles.scss';

const NumbersTransformation: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [clicked, setClicked] = useState(false);
  const [fieldList, setFieldList] = useState<any>([]);
  const [fieldListWithTypes, setFieldListWithTypes] = useState<any>([]);
  const [selectedFields, setSelectedFields] = useState<any>([]);
  const [values, setValues] = useState<any>([]);

  const {
    outputFormat,
    liftingSettings,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (values?.length === 0) {
      return false;
    }
    let bool = true;
    values?.forEach((item: { label: any }) => {
      const finded = selectedFields?.find((field: { key: any }) => field.key === item.label);
      if (finded) {
        if (!finded.value) bool = false;
      }
    });
    return bool;
  };

  //recipe fields or output fields
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    let optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings && dynamic_settings.keys) {
      const { keys } = dynamic_settings;
      let fieldsArr: any = [];
      const recreateSelectedFields = keys.map((item: any) => {
        // settings new structure
        const outputDataType = item.settings ? item.settings.outputDataType : item.outputDataType;
        const fixed_digits_Count = item.settings ? item.settings.fixed_digits_Count : item.fixed_digits_Count;
        const finded = numberOption.find(option => option.value === outputDataType);
        fieldsArr.push(item.key);
        return {
          key: item.key,
          value: {
            type: finded ? finded.label : null,
            value: fixed_digits_Count,
          },
        };
      });
      const getValues = options.filter(
        (option: { label: any; value: string }) => fieldsArr.includes(option.label) || fieldsArr.includes(option.value),
      );
      setValues(getValues);
      const newOptions = options.map((option: { label: any; value: string }) => {
        const finded = recreateSelectedFields.find(
          (field: { key: any }) => field.key === option.label || field.key === option.value,
        );
        if (finded) return { key: option.label, value: finded.value };
        return { key: option.label, value: { type: numberOption[1].label, value: null } };
      });
      setSelectedFields(newOptions);
    } else {
      setSelectedFields(
        options.map((item: any, index: number) => ({
          key: item.label,
          value: { type: numberOption[1].label, value: null },
        })),
      );
    }
    // setClicked(true);
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setValues([]);
      setClicked(true);
    }
    if (update) {
      if (dynamic_settings?.keys?.length > 1) {
        const filteredValue = filteredOptions?.filter((item: any) =>
          dynamic_settings?.keys.find((sub: any) => item.value === sub.key),
        );

        setValues(filteredValue || []);
        setClicked(true);
        setFieldList(filteredOptions);
        setFieldListWithTypes(optionsWithTypes);
      } else {
        const isArr = Array.isArray(dynamic_settings?.keys?.[0]?.key);
        const foundValue = !isArr
          ? filteredOptions?.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value)
          : null;
        const filteredValue = isArr
          ? filteredOptions?.filter((item: any) =>
              dynamic_settings?.keys?.[0]?.key.find((sub: any) => item.value === sub),
            )
          : [];
        const selOptionFound = isArr ? filteredValue : foundValue ? [foundValue] : [];

        setValues(selOptionFound);
        setClicked(true);
        setFieldList(filteredOptions);
        setFieldListWithTypes(optionsWithTypes);
      }
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setValues(fieldList);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      // const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      const vals = [...values.map((item: { label: any }) => outputFormat(item.label, 'number_transformation'))];
      const jsonParse: any = {};
      Object.keys(vals).map((key: any) => (jsonParse[key] = 'any'));
      liftingSettings({
        outputKeysData: [
          ...fieldList.map((item: { value: string }) => item.value),
          ...values.map((item: { label: any }) => outputFormat(item.label, 'number_transformation')),
        ],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, automationOutputTypes, fieldListWithTypes]);

  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);

    let localSettings = {
      keys: values.map((item: { label: any; value: any }) => {
        const findedSelectedField = selectedFields.find((field: { key: any }) => field.key === item.label);
        const finded = numberOption.find(
          option => option.label === findedSelectedField.value.type || option.value === findedSelectedField.value.type,
        );
        // return {
        //   outputDataType: finded ? finded.value : null,
        //   add: true,
        //   fixed_digits_Count: findedSelectedField ? findedSelectedField.value.value : null,
        //   key: item.value,
        // };
        return {
          key: item.value,
          inputValue: null,
          settings: {
            outputDataType: finded ? finded.value : null,
            add: true,
            fixed_digits_Count: findedSelectedField ? findedSelectedField.value.value : null,
          },
        };
      }),
    };
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: localSettings,
      outputKeysData: [
        ...fieldList.map((item: { value: string }) => item.value),
        ...values.map((item: { label: any }) => outputFormat(item.label, 'number_transformation')),
      ],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [selectedFields, values, fieldList, automationOutputTypes, automationOutputTypes]);

  const handleMultiInputs = (value: string, keyName: string, proptype: string) => {
    const copiedSelectedFields = [...selectedFields];
    const arr = copiedSelectedFields.map((field: { key: string; value: any }) => {
      if (field.key === keyName) {
        return {
          ...field,
          value: {
            ...field.value,
            value: proptype === 'type' && value === 'TO FIXED N' ? 1 : null,
            [proptype]: proptype === 'type' ? value : +value,
          },
        };
      }
      return { ...field };
    });
    setSelectedFields(arr);
  };

  const findFrom = (label: string, type: string) => {
    const finded = selectedFields.find((field: { key: string }) => field.key === label);
    if (finded && finded.value) {
      return finded.value[type];
    }
    return '';
  };

  return (
    <div className="measurment-unit-settings">
      <Row className="mb-4">
        <Col lg={6}>
          <span className="label secondary-color">Select fields for transformation</span>
          <CustomMultiSelect
            options={fieldList}
            value={values}
            onChange={(sel: any) => {
              setValues(sel);
              !clicked && setClicked(true);
              setIsDirty();
            }}
            labelledBy="Select"
          />
        </Col>
      </Row>
      {selectedFields.length > 0 &&
        values.map((property: { value: string; label: string }, index: number) => (
          <Row key={index}>
            <Col lg={6} className="mb-4">
              <span className="label secondary-color">{property.label} - convert to</span>
              <Select
                options={numberOption}
                value={findFrom(property.label, 'type')}
                placeholder={'Select column'}
                onChange={(selectedItem: any) => {
                  if (findFrom(property.label, 'type') === selectedItem.label) return;
                  handleMultiInputs(selectedItem.label, property.label, 'type');
                  !clicked && setClicked(true);
                  setIsDirty();
                }}
                fullWidth
              ></Select>
            </Col>
            {findFrom(property.label, 'type') === 'TO FIXED N' && (
              <Col lg={6} className="mb-4">
                <span className="label secondary-color">how much to fix</span>
                <Select
                  options={fixNumbers}
                  value={findFrom(property.label, 'value')}
                  placeholder={'Select column'}
                  onChange={(selectedItem: any) => {
                    handleMultiInputs(selectedItem.label, property.label, 'value');
                    !clicked && setClicked(true);
                    setIsDirty();
                  }}
                  fullWidth
                ></Select>
              </Col>
            )}
          </Row>
        ))}
    </div>
  );
};

export default NumbersTransformation;
