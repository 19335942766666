interface InewPackageDescriptions {
  name: string;
  UIName: string;
  annual: string;
  annualMonth: string;
  month: string;
  m21Month: string;
  m21Annual: string;
  propertyLimit: number;
  agency?: boolean;
  agancy?: boolean;
  discount?: string;
}

interface IPackageDescriptionsHexowatch {
  name: string;
  annual: string;
  annualMonth: string;
  month: string;
  LTD: string;
  m21Month: string;
  m21Annual: string;
}

interface IerrorMessages {
  ALREADY_EXISTS: string;
  AUTOMATION_CATEGORY_NOT_FOUND: string;
  AUTOMATION_TYPE_NOT_FOUND: string;
  AUTOMATION_NOT_FOUND: string;
  INVALID_AUTOMATION_IO_ARGS: string;
  INTEGRATION_NOT_FOUND: string;
  INTEGRATION_DATA_VALIDATION_FAILED: string;
  INTEGRATION_WORKSPACE_IS_NOT_SET: string;
  INTEGRATION_ENTITY_IS_NOT_SET: string;
  INTEGRATION_DATA_IS_NOT_SET: string;
  PUBLIC_SCRAPING_RECIPE_NOT_FOUND: string;
  PUBLIC_WORKFLOW_NOT_FOUND: string;
  INVALID_SETTINGS_PROVIDED: string;
  SCRAPING_RECIPE_NOT_FOUND: string;
  WORKFLOW_NOT_FOUND: string;
  WORKFLOW_NOT_FOUND_M: string;
  ERROR_DURING_WORKFLOW_STEPS_SAVING: string;
  SCRAPING_RECIPE_ACTIONS_INVALID_FORMAT: string;
}

export const getHexometerPackageName = (name: string) => {
  if (name === 'FREE') {
    return 'N/A';
  } else {
    const selected = newPackageDescriptions.find((item: any) => item.name === name);
    return selected ? selected.UIName : '';
  }
};

export const newPackageDescriptions: InewPackageDescriptions[] = [
  {
    name: 'STANDARD',
    UIName: 'Standard',
    annual: '120',
    annualMonth: '10',
    month: '12',
    // discount: '60',
    m21Month: '7.5',
    m21Annual: '90',
    agency: false,
    propertyLimit: 2,
  },
  {
    name: 'ADVANCED',
    UIName: 'Advanced',
    annual: '240',
    annualMonth: '20',
    month: '24',
    // discount: '120',
    m21Month: '15',
    m21Annual: '180',
    agency: false,
    propertyLimit: 3,
  },
  {
    name: 'GURU',
    UIName: 'Guru',
    annual: '480',
    annualMonth: '40',
    month: '48',
    // discount: '240',
    m21Month: '30',
    m21Annual: '360',
    agency: false,
    propertyLimit: 5,
  },
  {
    name: 'AGENCY_STANDARD',
    UIName: 'Agency Standard',
    annual: '456',
    annualMonth: '38',
    month: '45',
    agancy: true,
    // LTD: '149',
    m21Month: '28.5',
    m21Annual: '342',
    propertyLimit: 10,
  },
  {
    name: 'AGENCY_ADVANCED',
    UIName: 'Agency Advanced',
    annual: '756',
    annualMonth: '63',
    month: '75',
    agancy: true,
    // LTD: '249',
    m21Month: '47.25',
    m21Annual: '567',
    propertyLimit: 15,
  },
  {
    name: 'AGENCY_GURU',
    UIName: 'Agency Guru',
    annual: '1248',
    annualMonth: '104',
    month: '125',
    agancy: true,
    // LTD: '399',
    m21Month: '78',
    m21Annual: '936',
    propertyLimit: 20,
  },
];

export const PackageDescriptionsHexowatch: IPackageDescriptionsHexowatch[] = [
  {
    name: 'STANDARD',
    annual: '149.90',
    annualMonth: '12.49',
    month: '14.99',
    LTD: '',
    m21Month: '9.5',
    m21Annual: '112',
  },
  {
    name: 'PRO',
    annual: '249.90',
    annualMonth: '20.83',
    month: '24.99',
    LTD: '',
    m21Month: '15.6',
    m21Annual: '187',
  },
  {
    name: 'BUSINESS_SCANS_10000',
    annual: '499.90',
    annualMonth: '41.66',
    month: '49.99',
    LTD: '',
    m21Month: '31.25',
    m21Annual: '375',
  },
  {
    name: 'BUSINESS_SCANS_25000',
    annual: '999',
    annualMonth: '83.25',
    month: '99.9',
    LTD: '',
    m21Month: '62.5',
    m21Annual: '750',
  },
];

export const errorMessages: IerrorMessages = {
  ALREADY_EXISTS: 'Please enter valid unique name',
  AUTOMATION_CATEGORY_NOT_FOUND: 'Automation category not found',
  AUTOMATION_TYPE_NOT_FOUND: 'Automation type not found',
  AUTOMATION_NOT_FOUND: 'Automation not found',
  INVALID_AUTOMATION_IO_ARGS: 'Invalid arguments provided',
  INTEGRATION_NOT_FOUND: 'Integration not found',
  INTEGRATION_DATA_VALIDATION_FAILED: 'Integration failed',
  INTEGRATION_WORKSPACE_IS_NOT_SET: 'Integration failed',
  INTEGRATION_ENTITY_IS_NOT_SET: 'Integration failed',
  INTEGRATION_DATA_IS_NOT_SET: 'Integration failed',
  PUBLIC_SCRAPING_RECIPE_NOT_FOUND: 'Recipe not found',
  PUBLIC_WORKFLOW_NOT_FOUND: 'Workflow not found',
  INVALID_SETTINGS_PROVIDED: 'Invalid settings provided',
  SCRAPING_RECIPE_NOT_FOUND: 'Recipe not found',
  WORKFLOW_NOT_FOUND: 'Workflow not found',
  WORKFLOW_NOT_FOUND_M: 'Workflow not found',
  ERROR_DURING_WORKFLOW_STEPS_SAVING: 'Something went wrong during saving workflow',
  SCRAPING_RECIPE_ACTIONS_INVALID_FORMAT: 'Something went wrong during saving the recipe',
};

export const proxyList = [
  { label: 'Data Center', value: '' },
  { label: '* Residential', value: 'unmetered' },
  { label: '* Residential GEO', value: 'premium' },
];

export const scraperRecipeProxy = [
  { label: 'Data Center', value: '' },
  { label: 'Residential', value: 'unmetered' },
  { label: 'Residential GEO', value: 'premium' },
];

export const scraperRecipeBrowser = [
  { label: 'Fast', value: false },
  { label: 'Full-stack', value: true },
];

export const allowedDragSettings = [25, 26, 27, 29, 114, 117];
export const allowedToUseAutomationsAgain = [1, 3, 5, 25, 26, 27, 29, 92, 110, 114, 117];
export const useOnlyFistAutomations = [125];

export const premiumCreditPlans = [
  { planName: 'PC_499', value: 50, price: 4.99 },
  { planName: 'PC_999', value: 105, price: 9.99 },
  { planName: 'PC_2499', value: 275, price: 24.99 },
];

export const planLimits = [
  {
    planName: 'FREE',
    workflows: 1,
    credits: 75,
    scheduling: false,
    advancedIntegration: false,
    residentialProxy: false,
    residentialGEOProxy: false,
    ownAutomation: false,
    API: false,
  },
  {
    planName: 'BRONZE',
    workflows: 3,
    credits: 2000,
    scheduling: false,
    advancedIntegration: true,
    residentialProxy: true,
    residentialGEOProxy: true,
    ownAutomation: true,
    API: false,
  },
  {
    planName: 'SILVER',
    workflows: 10,
    credits: 4500,
    scheduling: true,
    advancedIntegration: true,
    residentialProxy: true,
    residentialGEOProxy: true,
    ownAutomation: true,
    API: false,
  },
  {
    planName: 'GOLD',
    workflows: undefined,
    credits: undefined,
    scheduling: true,
    advancedIntegration: true,
    residentialProxy: true,
    residentialGEOProxy: true,
    ownAutomation: true,
    API: true,
  },
];

export const publicAllowedUserIDs: string[] = [
  '21522',
  '17383',
  '20258',
  '850',
  '21887',
  '58303',
  '47354',
  '11',
  '3485',
  '19026',
  '26424',
  '252',
  '39282',
  '44472',
  '103007',
  '21303',
  '21920',
  '31684',
  '86060',
];

export const blockTags: string[] = [
  'address',
  'article',
  'aside',
  'blockquote',
  'canvas',
  'dd',
  'div',
  'dl',
  'dt',
  'fieldset',
  'figcaption',
  'figure',
  'footer',
  'form',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'header',
  'hr',
  'li',
  'main',
  'nav',
  'noscript',
  'ol',
  'p',
  'pre',
  'section',
  'table',
  'tbody',
  'thead',
  'tfoot',
  'ul',
  'video',
  'section',
];
export const automationOutputs = {
  2: ['transformed number'],
  8: ['transformed measurement unit'],
  18: ['reflected on input'],
  21: ['exported to Google Sheets'],
  33: ['google translation'],
  34: ['deepl translation'],
  44: ['transformed text'],
  47: ['inputted data'],
  92: ['reflected on input'],
  125: ['bigquery full csv', 'specified columns'],
  136: ['wordpress uploader'],
};

export const automationWithoutOutput = [1, 3, 5, 25, 26, 27, 29, 110, 114, 117];

export const limitOptions = [
  { label: '5', value: 5 },
  { label: '15', value: 15 },
  { label: '25', value: 25 },
  { label: '30', value: 30 },
  { label: '50', value: 50 },
];

export const rgbColors: ReadonlyArray<{ color: string; background: string }> = [
  {
    background: 'rgba(223, 214, 235, 1)',
    color: 'rgba(112, 74, 165, 1)',
  },
  {
    background: 'rgba(200, 253, 204, 1)',
    color: 'rgba(8, 219, 24, 1)',
  },
  {
    background: 'rgba(173, 231, 255, 1)',
    color: 'rgba(7, 142, 199, 1)',
  },
  {
    color: 'rgba(212, 83, 96, 1)',
    background: 'rgba(254, 228, 226, 1)',
  },
  {
    background: 'rgba(213, 246, 225, 1)',
    color: 'rgba(66, 216, 123, 1)',
  },
  {
    background: 'rgba(255, 239, 188, 1)',
    color: 'rgba(229, 165, 68, 1)',
  },
  {
    background: 'rgba(254, 206, 172, 1)',
    color: 'rgba(252, 109, 6, 1)',
  },
  {
    background: 'rgba(174, 220, 217, 1)',
    color: 'rgba(13, 151, 143, 1)',
  },
  {
    background: 'rgba(253, 200, 223, 1)',
    color: 'rgba(247, 10, 112, 1)',
  },
  {
    background: 'rgba(173, 216, 198, 1)',
    color: 'rgba(11, 139, 85, 1)',
  },
  {
    background: 'rgba(172, 230, 236, 1)',
    color: 'rgba(7, 180, 199, 1)',
  },
  {
    background: 'rgba(245, 183, 255, 1)',
    color: 'rgba(214, 10, 247, 1)',
  },
  {
    background: 'rgba(253, 216, 238, 1)',
    color: 'rgba(246, 81, 180, 1)',
  },
  {
    background: 'rgba(226, 210, 253, 1)',
    color: 'rgba(126, 53, 246, 1)',
  },
  {
    background: 'rgba(200, 251, 244, 1)',
    color: 'rgba(11, 237, 209, 1)',
  },
  {
    background: 'rgba(224, 240, 255, 1)',
    color: 'rgba(116, 188, 255, 1)',
  },
  {
    background: 'rgba(254, 224, 255, 1)',
    color: 'rgba(252, 116, 255, 1)',
  },
  {
    background: 'rgba(200, 239, 253, 1)',
    color: 'rgba(10, 184, 247, 1)',
  },
  {
    background: 'rgba(247, 226, 199, 1)',
    color: 'rgba(221, 127, 4, 1)',
  },
  {
    background: 'rgba(247, 226, 199, 1)',
    color: 'rgba(221, 127, 4, 1)',
  },
  {
    background: 'rgba(199, 224, 221, 1)',
    color: 'rgba(5, 119, 104, 1)',
  },
];
