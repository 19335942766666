import { FC, memo, useCallback } from 'react';
import Tooltip from 'shared-components/atom/tooltip';
import { CheckboxTypes } from '../types';
import { eventStop } from 'helpers';
import './styles.scss';

const Checkbox: FC<CheckboxTypes> = ({ checked, label, labelRight, disabled, onChange, className, hoverText }) => {
  const handelClick = useCallback(
    (e?: any) => {
      if (!disabled) {
        e && eventStop(e);
        onChange(!checked);
      }
    },
    [disabled, onChange, eventStop],
  );
  return (
    <div className={`custom-checkbox-block noselect ${className ? className : ''}`}>
      {!hoverText
        ? label && <span className="label secondary-color">{label}</span>
        : label && (
            <Tooltip
              trigger={['hover']}
              placement="bottom"
              text={hoverText}
              isSmall={hoverText.length > 50}
              className="checkbox-tooltip"
            >
              <span className="label secondary-color">{label}</span>
            </Tooltip>
          )}

      {checked ? (
        <div
          id="custom-checkbox"
          className={`custom-checkbox  checked ${disabled ? 'disabled' : ''}`}
          onClick={handelClick}
        />
      ) : (
        <div className={`custom-checkbox ${disabled ? 'disabled' : ''}`} onClick={handelClick} />
      )}
      {labelRight && (
        <span title={hoverText || ''} className="label-right secondary-color">
          {labelRight}
        </span>
      )}
    </div>
  );
};

export default memo(Checkbox);
