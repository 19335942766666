import {
  FC,
  useContext,
  memo,
  useCallback,
  useState,
  Fragment,
  useMemo,
  SyntheticEvent,
  useEffect,
  useReducer,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ThemeContext } from 'contexts/theme-context';
import { eventStop, getDateAndTime } from 'helpers';
import ActionsModal from '../actions-modal';
import WorkflowItemTags from '../workflow-item-tags';
import Checkbox from 'shared-components/atom/checkbox';
import Loader from 'shared-components/atom/loader';
import Tooltip from 'shared-components/atom/tooltip';
import TimeElement from 'shared-components/atom/show-time';
import EditableText from 'shared-components/molecule/editable-text';
import Modal from 'shared-components/molecule/modal';
import { StatusEnums } from 'shared-components/atom/types';
import './styles.scss';

import {
  arrowRight,
  clockIconWithoutBorder,
  closeIcon,
  downloadIcon,
  globeIcon,
  pause,
  play,
  sharedPeopleIcon,
  tableActionsIcon,
  triggerIcon,
} from 'assets/svg-icons';
import recipeIcon from 'assets/icons/recipe.svg';
import deeplLight from 'assets/images/deepL-light.svg';
import StatusBadge from '../status-badge';
import { useMediaQuery } from 'react-responsive';
import { SidebarToggleContext } from 'contexts/sidebar-toggle-context';

interface IProps {
  item: any;
  idx: number;
  hide: boolean;
  checkedIndexes: number[];
  setShouldContinue: (val: any) => void;
  workflowsList: any[];
  handleCheckboxClick: (index: number) => void;
  returnWorkflowStatus: (
    item: any,
    val?: boolean,
  ) =>
    | 'progress'
    | 'completed'
    | 'error'
    | 'PAUSED'
    | 'DRAFT'
    | 'CREDIT_REACHED'
    | 'PREMIUM_CREDIT_REACHED'
    | 'STORAGE_REACHED'
    | 'CREDIT_STORAGE_REACHED';
  returnActiveStatus: (item: any, reverseActive?: boolean) => boolean;
  changeActive: (id: number, active: boolean, date?: string) => void;
  id: number;
  updateLoading: boolean;
  optionParameters: {
    label: any;
    value: any;
  };
  hoverText: (item: any) => any;
  handleUpdateWorkflowName: (val: { id: number; name?: string }) => void;
  openedId: number;
  setOpenedId: (id: number) => void;
  addTag?: (data: any) => void;
  handleUpdateWorkflowDescription: (val: { description: string; id: number }) => void;
  openedIdDesc: number;
  setOpenedIdDesc: (val: number) => void;
  selectedFilterTag: number[];
  deleteTagFromWorkflow: (val: { tagId: number; workflowId: number }) => void;
  handleFilterByTag: (tagId: number, workflowId: number) => void;
  returnStorageUsage: (item: any) => string;
  getNamesForTooltip: (steps: any, count: number) => string;
  returnShedule: (item: any) => string;
  isAdmin: boolean;
  setOpenExportModal: (val: boolean) => void;
  setSelectedWorkflowId: (id: number) => void;
  setSelectedHeaders: (val: any) => void;
  setScheduleConfig: (val: any) => void;
  setStartedAt: (val: any) => void;
  setOptionParameters: (val: {
    label: string;
    value: number | string;
    status?: string;
    recipeIds?: any;
    frequency?: string;
  }) => void;
  realTimeStatusData: null | any;
  setTrigger: any;
  setselectedOutputKeys: any;
  setSendMail: any;
  handleDisableWorkflowTrigger: (arg: number, arg1: number) => Promise<void>;
  setClassName: (val: string) => void;
  isHideSteps: boolean;
}
const TableRow: FC<IProps> = ({
  handleDisableWorkflowTrigger,
  item,
  idx,
  hide,
  checkedIndexes,
  setShouldContinue,
  workflowsList,
  handleCheckboxClick,
  returnWorkflowStatus,
  returnActiveStatus,
  changeActive,
  id,
  updateLoading,
  optionParameters,
  hoverText,
  handleUpdateWorkflowName,
  openedId,
  setOpenedId,
  addTag,
  handleUpdateWorkflowDescription,
  openedIdDesc,
  setOpenedIdDesc,
  selectedFilterTag,
  deleteTagFromWorkflow,
  handleFilterByTag,
  returnStorageUsage,
  getNamesForTooltip,
  returnShedule,
  isAdmin,
  setOpenExportModal,
  setSelectedWorkflowId,
  setSelectedHeaders,
  setOptionParameters,
  setScheduleConfig,
  setStartedAt,
  realTimeStatusData,
  setTrigger,
  setselectedOutputKeys,
  setSendMail,
  setClassName,
  isHideSteps,
}) => {
  const { theme } = useContext(ThemeContext);
  const { opened, rightOpened } = useContext(SidebarToggleContext);
  const { push } = useHistory();
  //------------------State-------------------------
  const [showActionModal, setShowActionModal] = useState(false);
  const [showTriggerActionModal, setShowTriggerActionModal] = useState(false);
  const [checkWidth, setCheckWidth] = useState(false);
  const [resize, setResize] = useReducer((val: boolean) => !val, false);
  const [toggle, setToggle] = useReducer((val: boolean) => !val, false);
  //----------------Functions-----------------------
  const isMobile = useMediaQuery({ maxWidth: 1200 });
  const isMobileSmall = useMediaQuery({ maxWidth: 670 });

  useEffect(() => {
    const handleResize = () => {
      setResize();
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setTimeout(
      () => {
        setToggle();
      },
      opened || rightOpened ? 500 : 0,
    );
  }, [opened, rightOpened]);

  useEffect(() => {
    setClassName(
      `${
        (checkWidth || item?.tags?.length > 0) && idx === workflowsList.length - 1 && workflowsList.length > 1
          ? 'pb-5'
          : ''
      }`,
    );
  }, [checkWidth, item, workflowsList]);

  const showClockIcon = (next_scan_time: any, last_scan_time: any, frequency: string, id: number) => {
    const currentWorkflow = realTimeStatusData && realTimeStatusData[id];
    return (
      (frequency &&
        (currentWorkflow?.next_scan_time || next_scan_time) &&
        new Date(currentWorkflow?.next_scan_time || next_scan_time).getTime() > new Date().getTime()) ||
      ((currentWorkflow?.next_scan_time || next_scan_time) &&
        new Date(currentWorkflow?.next_scan_time || next_scan_time).getTime() > new Date().getTime())
    );
  };

  const handleChecked = useCallback(() => {
    handleCheckboxClick(item.id);
  }, [item, handleCheckboxClick]);

  const handleOpenWorkflow = useCallback(() => {
    push(`/workflows/current-workflow/${item.id}`);
  }, [item]);

  const handlePlayPause = useCallback(
    (e: any) => {
      eventStop(e);
      if (returnWorkflowStatus(item) === StatusEnums.DRAFT) return;
      returnActiveStatus(item, true) && returnWorkflowStatus(item) === 'completed'
        ? setShouldContinue({
            isOpen: true,
            status: returnWorkflowStatus(item),
            item: item,
          })
        : changeActive(+item.id, returnActiveStatus(item, true));
    },
    [item, returnWorkflowStatus, returnActiveStatus, changeActive],
  );

  const handleSaveName = useCallback(({ id, text }: any) => handleUpdateWorkflowName({ id, name: text }), []);

  const handleSaveDescription = useCallback(
    ({ id, text }: any) => handleUpdateWorkflowDescription({ id, description: text }),
    [handleUpdateWorkflowDescription],
  );

  const handleDownload = useCallback(
    (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      if (!(item && returnWorkflowStatus(item) === 'completed')) return;
      setOpenExportModal(true);
      setSelectedWorkflowId(item.id);
      setSelectedHeaders(item.selected_headers);
    },
    [returnWorkflowStatus, item, setSelectedHeaders],
  );

  const handleOpenModal = useCallback((e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setShowActionModal(true);
  }, []);

  const width = useMemo(() => {
    const tableWidth = document.getElementById('workflows-table');
    return tableWidth?.offsetWidth || 0;
  }, [document.getElementById('workflows-table'), resize, toggle]);

  const tagsCount = useMemo(() => {
    const nameEl = document.getElementById('editable-name');
    const nameWidth = nameEl?.offsetWidth;
    const tabeWidth = document.getElementById('workflows-table');
    const width = tabeWidth?.offsetWidth;
    return width && nameWidth ? Math.round((width - 900 - nameWidth) / 150) : 1;
  }, [width, resize, document.getElementById('editable-name'), document.getElementById('workflows-table')]);

  const tdMaxWidth = useMemo(() => {
    const trigger = workflowsList.find((item: any) => item.schedule_config?.triggers?.length) ? 200 : 0;
    const new_width = hide ? width - 600 - trigger : width - 740 - trigger;

    return {
      maxWidth: `${new_width > 200 ? new_width : 200}px`,
      width: `${new_width > 200 ? new_width : 200}px`,
      minWidth: `${new_width > 200 ? new_width : 200}px`,
    };
  }, [width, isMobileSmall, hide, workflowsList]);

  const handleDisableTriggerModalOpen = useCallback((e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowTriggerActionModal(true);
  }, []);
  return (
    <>
      <Link to={`/workflows/current-workflow/${item.id}`} key={item.id} className="cursor-pointer workflow-table-row">
        {/* <Link to={`/workflows/current-workflow/${item.id}`}></Link> */}
        <td
          className="pl-2 checkbox-td"
          style={{
            height: item.description ? '32px' : '',
            display: 'flex',
          }}
        >
          <span onClick={eventStop} className="d-flex justify-content-between align-items-center py-2">
            <Checkbox checked={checkedIndexes.indexOf(item.id) > -1} onChange={handleChecked} />
          </span>
        </td>
        <td className="m_w_100_per" style={tdMaxWidth} onClick={handleOpenWorkflow}>
          <div className="d-flex">
            <span className="play-pause-icon d-flex align-items-center ml-2 mr-2" onClick={handlePlayPause}>
              {id === item.id && updateLoading && optionParameters.label !== 'Restart workflow' ? (
                <div className="loader-margin">
                  <Loader light={theme === 'light'} />
                </div>
              ) : returnWorkflowStatus(item) !== StatusEnums.DRAFT ? (
                <Tooltip placement="right" text={hoverText(item)} className="">
                  {returnActiveStatus(item) ? pause : play}
                </Tooltip>
              ) : (
                <div className="draft-play-icon">{play}</div>
              )}
            </span>
            <div
              className={
                hide
                  ? 'workflow-name-edit-container workflow-name-edit-container-size-mobile'
                  : 'workflow-name-edit-container-size workflow-name-edit-container'
              }
            >
              <div className="d-flex" style={{ maxWidth: '100%', width: '100%' }}>
                <EditableText
                  key={item.id}
                  name={item.name}
                  id={item.id}
                  onSave={handleSaveName}
                  maxTextCount={64}
                  openedInfo={openedId}
                  setOpenInfo={setOpenedId}
                  addTag={addTag}
                  allowingToAddTag={item.tags === null || item.tags?.length <= 9}
                  showTooltip
                  checkWidth={checkWidth}
                  setCheckWidth={setCheckWidth}
                  tags={
                    item.tags && !hide ? (
                      <div
                        className={`workflow-item-tags m-1 ${hide ? 'pl-none' : ''}`}
                        // style={{ maxWidth: `calc(100% - ${nameWidth}px)` }}
                      >
                        <WorkflowItemTags
                          itemId={item.id}
                          tagsCount={tagsCount}
                          wiewType={hide ? 'card' : 'table'}
                          propTags={item.tags.map((sub: any) => {
                            return {
                              ...sub,
                              active: !!selectedFilterTag.find((val: any) => +val?.value === sub.id),
                            };
                          })}
                          deleteAction={deleteTagFromWorkflow}
                          handleFilterByTag={handleFilterByTag}
                        />
                      </div>
                    ) : undefined
                  }
                />
              </div>
              {item.description && !hide && (
                <EditableText
                  isDescription
                  key={item.id}
                  name={item.description}
                  id={item.id}
                  onSave={handleSaveDescription}
                  // maxTextCount={300}
                  openedInfo={openedIdDesc}
                  setOpenInfo={setOpenedIdDesc}
                />
              )}
            </div>
          </div>
        </td>
        {/* <td className="text-center m_w_100 text-secondary" onClick={handleOpenWorkflow}>
          <TimeElement time={item.created_at} />
        </td> */}
        <td className="text-center m_w_100 text-secondary" onClick={handleOpenWorkflow}>
          <TimeElement time={item.updated_at} />
        </td>
        <td className="text-center m_w_100 text-secondary" onClick={handleOpenWorkflow}>
          {returnStorageUsage(item)}
        </td>
        <td className="text-center m_w_100 text-secondary" onClick={handleOpenWorkflow}>
          <StatusBadge
            workflowsLength={workflowsList.length}
            item={item}
            realTimeStatusData={realTimeStatusData}
            viewMode="listView"
            index={idx}
          />
        </td>
        <td className=" pl-1 pr-1" onClick={handleOpenWorkflow}>
          {item?.schedule_config?.triggers &&
          item.schedule_config.triggers[0] &&
          item.schedule_config.triggers[0].watchPropertyId ? (
            <Tooltip text={item.schedule_config.triggers[0].name || ''} placement="top">
              <div className="watch-trigger-wrapper">
                <span>{triggerIcon}</span>
                <span className="ml-2 text-truncate">{item.schedule_config.triggers[0].name || ''}</span>
                <span className="ml-2 close-btn-wrapper" onClick={handleDisableTriggerModalOpen}>
                  {closeIcon}
                </span>
              </div>
            </Tooltip>
          ) : (item?.frequency || (item?.next_scan_time && new Date(item?.next_scan_time) > new Date())) &&
            ((returnShedule(item) && !item?.status) || item?.status === 'progress') ? (
            <Tooltip
              text="Next scan time will be set after workflow completes"
              placement="top"
              className="schedule-hover"
            >
              <span className="globe-icon-wrapper">{clockIconWithoutBorder}</span>
            </Tooltip>
          ) : showClockIcon(item?.next_scan_time, item?.last_scan_time, item?.frequency, item?.id) &&
            item?.status !== 'PAUSED' ? (
            <Tooltip
              text={item?.next_scan_time && getDateAndTime(item?.next_scan_time)}
              placement="top"
              className="schedule-hover"
            >
              <span className="globe-icon-wrapper">{clockIconWithoutBorder}</span>
            </Tooltip>
          ) : (
            item?.frequency &&
            item?.status === 'PAUSED' && (
              <Tooltip text=" Workflow is paused" placement="top" className="schedule-hover">
                <span className="globe-icon-wrapper">{clockIconWithoutBorder}</span>
              </Tooltip>
            )
          )}
        </td>

        {isAdmin && (
          <td className="text-center pl-1 pr-1 m_w-30" onClick={handleOpenWorkflow}>
            <Tooltip text="Public workflow" placement="right">
              <span className="globe-icon-wrapper">{item.is_public && globeIcon}</span>
            </Tooltip>
          </td>
        )}

        <td className="text-center pl-1 pr-1 m_w-30" onClick={handleOpenWorkflow}>
          <Tooltip text="Shared workflow" placement="right">
            <span className="globe-icon-wrapper">{item.token_to_share && sharedPeopleIcon}</span>
          </Tooltip>
        </td>

        <td
          className={`text-center pr-2 icons-wrapper icons-width-style ${hide ? 'hide' : ''}`}
          onClick={handleOpenWorkflow}
        >
          <div
            className={`d-flex icons-width-style ${isHideSteps && !isMobile && !isMobileSmall ? 'mx-130' : ''} ${
              hide ? 'hide' : ''
            }`}
          >
            {item.steps &&
              item.steps.map(
                (subItem: any, index: number) =>
                  index < (isHideSteps && !isMobile && !isMobileSmall ? 1 : hide ? 1 : 5) &&
                  subItem.automation_icon_url && (
                    <Fragment key={subItem.automation_id + subItem.automation_name}>
                      <Tooltip key={subItem.automation_icon_url} text={subItem.automation_name} placement="left">
                        <div className="automation-card-icon overlaping">
                          <img
                            src={
                              subItem.automation_id === 43
                                ? recipeIcon
                                : subItem.automation_id === 34 && theme === 'dark'
                                ? deeplLight
                                : subItem.automation_icon_url
                            }
                            alt="Workflow icon"
                            className={`${subItem.automation_id === 35 ? 'img_35' : ''}`}
                          />
                        </div>
                      </Tooltip>
                    </Fragment>
                  ),
              )}
            {item.steps && item.steps.length > (isHideSteps && !isMobile && !isMobileSmall ? 1 : hide ? 1 : 5) && (
              <Tooltip
                text={getNamesForTooltip(item.steps, isHideSteps && !isMobile && !isMobileSmall ? 1 : hide ? 1 : 5)}
                placement="left"
              >
                <div className="automation-card-icon overlaping">
                  <span>+{item.steps.length - (isHideSteps && !isMobile && !isMobileSmall ? 1 : hide ? 1 : 5)}</span>
                </div>
              </Tooltip>
            )}
          </div>
        </td>

        <td
          className={`text-center pl-0 m_w-30 ${
            !idx ? 'download-files-first' : idx === workflowsList.length - 1 ? 'download-files' : ''
          }`}
        >
          <div onClick={handleDownload} className="position-relative">
            {item && returnWorkflowStatus(item) === 'completed' ? (
              <Tooltip text="Export the result" placement="left">
                <span
                  className={`download-icon-wrapper${
                    item && returnWorkflowStatus(item) === 'completed' ? '-active' : ''
                  }`}
                >
                  {downloadIcon}
                </span>
              </Tooltip>
            ) : (
              <span
                className={`download-icon-wrapper${
                  item && returnWorkflowStatus(item) === 'completed' ? '-active' : ''
                }`}
              >
                {downloadIcon}
              </span>
            )}
          </div>
        </td>
        <td className="taction-icon-td">
          <div
            className={`${
              (idx === workflowsList.length - 1 || idx === workflowsList.length - 2) && 'change-option-position'
            } d-flex align-items-center menu-container`}
          >
            <span className="px-2" onClick={handleOpenModal}>
              {tableActionsIcon}
            </span>
            {/* <div onClick={handleOpenWorkflow}>{arrowRight}</div> */}
          </div>
        </td>
      </Link>
      {/* {item.description && (
        <tr className="cursor-pointer description-row-sec border-top-0">
          <td
            colSpan={6}
            className="pl-4 pt-0 editable-description"
            style={descMaxWidth}
            onClick={handleOpenWorkflow}
          >
            {item.description && (
              <EditableText
                isDescription
                key={item.id}
                name={item.description}
                id={item.id}
                onSave={handleSaveDescription}
                // maxTextCount={300}
                openedInfo={openedIdDesc}
                setOpenInfo={setOpenedIdDesc}
              />
            )}
          </td>
        </tr>
      )} */}
      {showActionModal && (
        <ActionsModal
          returnActiveStatus={returnActiveStatus}
          returnWorkflowStatus={returnWorkflowStatus}
          item={item}
          setShowActionModal={setShowActionModal}
          setScheduleConfig={setScheduleConfig}
          setStartedAt={setStartedAt}
          setOptionParameters={setOptionParameters}
          isAdmin={isAdmin}
          setTrigger={setTrigger}
          setselectedOutputKeys={setselectedOutputKeys}
          setSendMail={setSendMail}
        />
      )}

      {showTriggerActionModal && (
        <Modal
          isModalOpen={showTriggerActionModal}
          text="Disable the trigger from Hexowatch monitoring?"
          actionBtnText="Yes"
          cancelBtnText="No"
          actionBtnColor="error"
          cancelBtnColor="primary"
          onActionClick={() => {
            handleDisableWorkflowTrigger(item?.id, item?.schedule_config?.triggers[0]?.watchIntegrationId);
            setShowTriggerActionModal(false);
          }}
          onCancelClick={() => setShowTriggerActionModal(false)}
          escButtonClick={() => setShowTriggerActionModal(false)}
        />
      )}
    </>
  );
};

export default memo(TableRow);
