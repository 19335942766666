import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { extractLinksOptions, findFolderOptions } from '../../constants';
import Select from 'shared-components/atom/select';
import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import Input from 'shared-components/atom/input';
import CustomMultiSelect from 'shared-components/molecule/multiselect';
import Switcher from 'shared-components/molecule/switcher';
import './styles.scss';

const ExtractLinksFromAPageSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [selected, setSelected] = useState<any>([]);
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);
  const [clicked, setClicked] = useState(false);
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [selectedLinkType, setSelectedLinkType] = useState<{ label: string; value: string } | null>(null);
  const [customType, setCustomType] = useState('');
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);
  const [selectedMode, setSelectedMode] = useState('Standard');

  const {
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    liftingSettings,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if ((selected?.length === 0 && !customType) || !selectedLinkType || !selectedSource) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings && fieldList.length > 0) {
      const keys = dynamic_settings.keys;
      const { key, settings } = keys[0];
      const options = settings ? settings.options : keys[0].options;
      const links_option = settings ? settings.links_option : keys[0].links_option;
      const custom_options = settings ? settings.custom_options : keys[0].custom_options;
      const fast = settings && settings.fast ? 'Fast' : 'Standard';

      const selectedOptions = options?.map((option: string) => {
        const finded = findFolderOptions.find(item => item.value === option || item.label === option);
        return finded ? finded : { label: option, value: option };
      });
      selectedOptions && setSelected(selectedOptions);

      if (links_option) {
        const findedLinkType = extractLinksOptions.find(item => item.value === links_option);
        findedLinkType && setSelectedLinkType(findedLinkType);
      }
      setSelectedMode(fast);
      custom_options && setCustomType(custom_options?.join(', '));
      restoreSelectedSource({ key: key, fieldList: fieldList, setSelectedSource: setSelectedSource });
    }
  }, [fieldList]);

  //recipe fields or output fields
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      //its set from props
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [
          ...fieldList.map((item: { value: any }) => item.value),
          ...(selectedLinkType ? [selectedLinkType.value] : []),
        ],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, selectedLinkType, fieldListWithTypes, automationOutputTypes]);

  //lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: {
        keys: [
          {
            key: fieldList.length && selectedSource ? selectedSource.value : null,
            inputValue: null,
            settings: {
              options: selected.map((item: { value: any }) => item.value),
              links_option: selectedLinkType && selectedLinkType.value,
              custom_options: customType.split(',').map(item => item.trim()),
              fast: selectedMode === 'Fast',
            },
          },
        ],
      },
      outputKeysData: [
        ...fieldList.map((item: { value: any }) => item.value),
        ...(selectedLinkType ? [selectedLinkType.value] : []),
      ],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [selected, selectedSource, selectedLinkType, customType, fieldList, fieldListWithTypes, automationOutputTypes, selectedMode]);

  const handleSwitcherClick = (val: string) => {
    setSelectedMode(val);
    !clicked && setClicked(true);
    setIsDirty();
  };

  return (
    <div className="">
      <Row>
        <Col md={12} className="d-flex">
          <Switcher
            leftText="Fast"
            rightText="Standard"
            handleSwitcherClick={handleSwitcherClick}
            selectedValue={selectedMode}
            hoverTextLeft="Loads pages faster but may have a lower success rate."
            // hoverTextRight="Loads pages slower but may have a higher success rate."
          />
        </Col>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Source</span>
          <Select
            fullWidth
            options={fieldList}
            value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
            placeholder={'Select source'}
            onChange={(selectedItem: any) => {
              setSelectedSource(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Custom option</span>
          <Input
            value={customType}
            placeholder={'.png, .txt, .svg'}
            onChange={e => {
              setCustomType(e.target.value);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Select extensions</span>
          <CustomMultiSelect
            options={findFolderOptions}
            value={selected}
            onChange={(sel: any) => {
              setSelected(sel);
              !clicked && setClicked(true);
              setIsDirty();
            }}
            labelledBy="Select"
          />
        </Col>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Select links type</span>
          <Select
            fullWidth
            options={extractLinksOptions}
            value={selectedLinkType}
            placeholder={'Select source'}
            onChange={(selectedItem: any) => {
              setSelectedLinkType(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ExtractLinksFromAPageSettings;
