import moment from 'moment';
import Cookies from 'js-cookie';
import md5 from 'md5';
import URL from 'url';
import crypto from 'crypto';
import { CountryInfoType } from '../types';
import CountryCodes from '../constants/countries';

const algorithm = 'aes-256-ctr';
const secretKey = 'vOVH7kqwpNWjRRIqCc4uyts01lwNGat8';
const iv = crypto.randomBytes(16);

const CookieDomains = ['37.186.119.181', 'localhost', '.hexomatic.com', '.hexometer.com', '.hexowatch.com'];

// DANGER!!!! CHANGE THIS LATER ON
// CLEANUP default cookies if we have one
//@ts-ignore
if (global.window) {
  Cookies.remove('__session', {
    domain: undefined, // current default domain name
  });
  Cookies.remove('device_key', {
    domain: undefined, // current default domain name
  });
  Cookies.remove('ref_token', {
    domain: undefined, // current default domain name
  });
}

export const getCookies = (): any => {
  let cookiesStr: string | undefined;
  let cookies = {};
  for (const domain of CookieDomains) {
    // @ts-ignore
    cookiesStr = Cookies.get('__session', { domain });
    if (cookiesStr) {
      try {
        cookies = JSON.parse(cookiesStr);
      } catch (e) {}
    }
  }
  return cookies;
};

export const setCookie = (key: string, value: string | null | boolean) => {
  CookieDomains.map(domain => {
    Cookies.set('__session', JSON.stringify({ ...getCookies(), [key]: value }), {
      expires: 30,
      domain,
      sameSite: 'Strict',
    });
    return null;
  });
};

export const setBase32 = (base32: string) => {
  CookieDomains.map(domain => {
    Cookies.set('base32', base32, {
      expires: 30,
      domain,
      sameSite: 'Strict',
    });
  });
};

export const getCookie = (key: string): string => getCookies()[key];
export const removeCookie = (key: string) => {
  const cookies = getCookies();
  delete cookies[key];
  CookieDomains.map(domain => {
    Cookies.remove('__session', {
      expires: 30,
      domain,
    });
    return null;
  });
};

export const setPremiumAnnualModal = (val: string) => setCookie('premium-credits-annual', val);
export const getPremiumAnnualModal = (): string | undefined => getCookie('premium-credits-annual');
export const removePremiumAnnualModal = () => removeCookie('premium-credits-annual');

// export const getGravatar = (email: string, size?: number) => {
//   size = size || 80;
//   return `https://www.gravatar.com/avatar/${md5(email)}?s=${size}&d=monsterid`;
// };

export const GetCountry = (countryCode: string): CountryInfoType => CountryCodes[countryCode];

export const setUserToken = (token: string) => setCookie('user-token', token);
export const getUserToken = () => getCookie('user-token');

export const setDeviceKey = (hash: string) => {
  CookieDomains.map(domain => {
    Cookies.set('device_key', hash, {
      expires: 30,
      domain: domain,
      sameSite: 'Strict',
    });
  });
};

export const setRefToken = (hash: string) => {
  CookieDomains.map(domain => {
    Cookies.set('ref_token', hash, {
      expires: 30,
      domain: domain,
      sameSite: 'Strict',
    });
  });
};

export const removeRawCookie = (name: string) => Cookies.remove(name);

export const getRefreshToken = (): string | undefined => getCookie('user-token');
export const setRefreshToken = (token: string) => setCookie('user-token', token);
export const removeRefreshToken = () => removeCookie('user-token');
export const getRefreshHash = (): string | undefined => getCookie('user-hash');
export const setRefreshHash = (hash: string) => setCookie('user-hash', hash);
export const removeRefreshHash = () => removeCookie('user-hash');

export const getUserSignedUpViaGoogle = (): string | undefined => getCookie('new-user');
export const setUserSignedUpViaGoogle = (val: boolean) => setCookie('new-user', val);
export const removeUserSignedUpViaGoogle = () => removeCookie('new-user');

export const getAccessToken = getRefreshToken;
export const setAccessToken = setRefreshToken;
export const removeAccessToken = removeRefreshToken;

export const setDefaultProperty = (property: string) => localStorage.setItem('defaultProperty', property);
export const getDefaultProperty = (): string => localStorage.getItem('defaultProperty') || '';
export const removeDefaultProperty = () => localStorage.removeItem('defaultProperty');
export const setUserEmailInStroage = (email: string) => localStorage.setItem('UserEmail', email);
export const removeUserEmail = () => localStorage.removeItem('UserEmail');

export const saveUptimePageUrl = (url: string) => setCookie('uptime-url', url);
export const getUptimePageUrl = () => getCookie('uptime-url');
export const removeUptimePageUrl = () => setCookie('uptime-url', null);

// export const saveHideDash = (hide: boolean) => setCookie('hide-dash', hide);
// export const getHideDash = () => getCookie('hide-dash');
export const removeHideDash = () => removeCookie('hide-dash');

export const getRawCookie = (name: string): string | undefined => Cookies.get(name);

export const getDateAndTime = (date: string | number) => {
  return date && moment.utc(new Date(date).toISOString()).format('MMM DD, YYYY h:mm:ss A (UTC)');
};

export const getDateAndTimeWithoutUTC = (date: string | number) => {
  return date && moment.utc(new Date(date).toISOString()).format('MMM DD, YYYY h:mm:ss A');
};

export const getParsedDateAndTime = (date: string) => {
  const parsedDate = parseInt(date) && !isNaN(parseInt(date)) && new Date(parseInt(date));
  return parsedDate ? moment.utc(parsedDate.toISOString()).format('MMM DD, YYYY h:mm:ss A') : '';
};

export const getDate = (date: string | number) => {
  return date && moment.utc(new Date(date).toISOString()).format('MMM DD, YYYY');
};

export const getParsedDate = (date: string) => {
  const parsedDate = parseInt(date) && !isNaN(parseInt(date)) && new Date(parseInt(date));
  return parsedDate ? moment.utc(parsedDate).format('MMM DD, YYYY') : '';
};
export const getParsedDateTime = (date: string) => {
  const parsedDate = parseInt(date) && !isNaN(parseInt(date)) && new Date(parseInt(date));
  return parsedDate ? moment.utc(parsedDate).format('MMM DD, YYYY, h:mm:ss A (UTC)') : '';
};

export const getParsedDateAndTimeGMT = (date: string) => {
  const parsedDate = parseInt(date) && !isNaN(parseInt(date)) && new Date(parseInt(date));
  return parsedDate ? moment(parsedDate).format('MM DD YYYY HH:mm:ss') : '';
};

export const cleanURL = (baseUrl: string): string => {
  let linkURL = baseUrl;
  if (baseUrl.indexOf('://') === -1) {
    linkURL = `http://${linkURL}`;
  }

  return linkURL || baseUrl;
};

export const convertURLToName = (baseUrl: string) => {
  const { hostname } = URL.parse(cleanURL(baseUrl.toLocaleLowerCase()));
  return hostname;
};

export const setFreshChatUserData = (user: any) => {
  const setUserInterval = setInterval(() => {
    const initData = {
      app_id: 'kxq8ogrw',
    };
    if (user && user.firstName && user.email) {
      const userInfo = {
        email: user.email,
        name: user.firstName + ' ' + user.lastName,
        user_hash: getRefreshHash(),
      };
      // @ts-ignore
      global.window &&
        // @ts-ignore
        window.Intercom('boot', {
          ...initData,
          ...userInfo,
        });
    } else {
      // @ts-ignore
      global.window &&
        // @ts-ignore
        window.Intercom('boot', {
          ...initData,
          name: 'there',
        });
    }
    clearInterval(setUserInterval);
  }, 2000);
};

export const excludeAutomationsOrRecipt = ({ sourceIndex, sourceList, destinationList }: any) => {
  const copiedDestination = [...destinationList];
  const findedEl = sourceList.find((item: any, index: number) => index === sourceIndex);
  const filteredSourceList = sourceList.filter((item: any, index: number) => index !== sourceIndex);
  copiedDestination.push(findedEl);
  return { destination: copiedDestination.filter((item: any) => item), source: filteredSourceList };
};

// a little function to help us with reordering the result
export const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
export const move = (source: any, destination: any, droppableSource: any, droppableDestination: any) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result: any = {};
  // result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

export const eventStop = (e: any) => {
  e.preventDefault();
  e.stopPropagation();
};

export const getHexowatchPricingPackageName = (value: string, additionalScansCount?: number) => {
  if (value && value.indexOf('FREE') > -1) {
    return `Free ${
      additionalScansCount && additionalScansCount > 1000
        ? (additionalScansCount / 1000).toFixed(1)
        : additionalScansCount
        ? additionalScansCount
        : ''
    }${additionalScansCount && additionalScansCount > 1000 ? 'k' : ''} `;
  }
  if (value && value.indexOf('STANDARD') > -1) {
    return `Standard ${
      additionalScansCount
        ? ((additionalScansCount + 2000) / 1000).toFixed((additionalScansCount + 2000) % 1000 > 0 ? 1 : 0)
        : 2
    }k`;
  }
  if (value && value.indexOf('PRO') > -1) {
    return `Pro ${
      additionalScansCount
        ? ((additionalScansCount + 4500) / 1000).toFixed((additionalScansCount + 4500) % 1000 > 0 ? 1 : 0)
        : 4.5
    }k`;
  }
  if (value && value.indexOf('BUSINESS_SCANS_') > -1) {
    const limit = value.slice(value.lastIndexOf('_') + 1);
    const limitIn1000 = +limit;
    if (value !== 'BUSINESS_SCANS_10000') {
      return `Business+ ${
        additionalScansCount
          ? ((additionalScansCount + limitIn1000) / 1000).toFixed(
              (additionalScansCount + limitIn1000) % 1000 > 0 ? 1 : 0,
            )
          : limitIn1000 / 1000
      }k`;
    } else {
      return `Business ${
        additionalScansCount
          ? ((additionalScansCount + 10000) / 1000).toFixed((additionalScansCount + 10000) % 1000 > 0 ? 1 : 0)
          : 10
      }k`;
    }
  }
};

export const PackagesSelectPromo = [
  { label: 'Hexometer Free Plan', value: 'free' },
  { label: 'Hexometer Agency Plan', value: 'agency' },
  { label: 'Hexometer Business Plan', value: 'business' },
  { label: 'Hexometer Premium Plan (15000 URLs)', value: 'premium_15000' },
  { label: 'Hexometer Premium Plan (25000 URLs)', value: 'premium_25000' },
  { label: 'Hexometer Premium Plan (50000 URLs)', value: 'premium_50000' },
  { label: 'Hexometer Premium Plan (100000 URLs)', value: 'premium_100000' },
];

export const cacheFunction = (fn: any) => {
  const cache: any = {};
  return function (arg: string) {
    let argument = typeof arg === 'string' ? arg.trim() : arg;
    if (cache[argument] !== undefined) {
      return cache[argument];
    }
    let value = fn(argument);
    cache[argument] = value ?? null;
    return cache[argument];
  };
};

export const cacheSomethingByKey = () => {
  const cache: any = {};
  return function (arg: string) {
    let argument = arg.trim();
    if (cache[argument] !== undefined) {
      return cache[argument];
    }
    cache[argument] = true;
    return false;
  };
};

export const chooseName = (confirmedItems: any) => {
  let name = '';
  const arr = [...confirmedItems];
  const names = arr.filter((item: { name: string }) => item.name && item.name.includes('propertyName'));
  if (names.length < 1) {
    name = 'propertyName1';
    return;
  }
  const transName = names
    .map((item: any) => {
      return +item.name.replace('propertyName', '');
    })
    .filter(item => !isNaN(item))
    .sort(function (a, b) {
      return b - a;
    });
  name = 'propertyName' + (transName[0] + 1);
  return name;
};

export const automationsUsedWithoutSource = [47, 20, 5, 46, 117, 114, 110, 26, 108, 43, 1, 28, 3, 25, 125];

export const automationsUsedWithoutSourceDetailsPage = [47, 46, 108, 125];

export const hexToRgbA = (hex: string, opacity = 1) => {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
  }
  throw new Error('Bad Hex');
};

export const GetTextColor = (backgroundColor: string) => {
  const color = +('0x' + backgroundColor.slice(1));

  const r = color >> 16;
  const g = (color >> 8) & 255;
  const b = color & 255;

  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
  return hsp > 127.5 ? '#000000' : '#ffffff';
};

export const insertion_Sort = (arr: any) => {
  for (let i = 1; i < arr.length; i++) {
    let j = i - 1;
    let temp = arr[i];
    while (j >= 0 && arr[j].name.length > temp.name.length) {
      arr[j + 1] = arr[j];
      j--;
    }
    arr[j + 1] = temp;
  }
  return arr;
};

export const removeDublicateStrings = (arr1: string[], arr2: string[]) =>
  (arr1 = arr1.filter((val: string) => !arr2.includes(val)));

export const setHotJarUser = (
  userId: number,
  pricing_status: string,
  pricing_plan: string | null,
  pricing_interval: string | null,
  premium_credits_pricing_package: string | null,
) => {
  window &&
    //@ts-ignore
    window.hj &&
    //@ts-ignore
    window.hj('identify', userId, {
      userId,
      pricing_status,
      pricing_plan,
      pricing_interval,
      premium_credits_pricing_plan: premium_credits_pricing_package,
    });
};

export const encrypt = (text: string) => {
  const cipher = crypto.createCipheriv(algorithm, secretKey, iv);
  const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);
  return {
    iv: iv.toString('hex'),
    content: encrypted.toString('hex'),
  };
};

export const getMB = (size: number) => {
  const kb = 1000;
  const mb = kb * 1000;
  if (size > mb) {
    return `${(size / mb).toFixed(2)} MB`;
  }

  return `${(size / kb).toFixed(2)} KB`;
};

export const getDaysInMonthForScheduling = function (date: any, returnNumber?: boolean) {
  const el = {
    '2': 'second',
    '3': 'third',
    '4': 'forth',
    '5': 'fifth',
  };
  const elNum = {
    '2': 2,
    '3': 3,
    '4': 4,
  };
  const month = new Date(date).getMonth() + 1;
  const year = new Date(date).getFullYear();
  const daysCount = new Date(year, month, 0).getDate();
  const currentDay = new Date(date).getDate();
  const before = currentDay - 1;
  const after = daysCount - currentDay;
  const detect = String(Math.ceil(currentDay / 7));
  //@ts-ignore
  if (returnNumber)
    //@ts-ignore
    return after - 7 < 0
      ? `${moment(date).isoWeekday()}L`
      : before - 6 < 1
      ? `${moment(date).isoWeekday()}#1`
      : //@ts-ignore
        `${moment(date).isoWeekday()}#${elNum[detect]}`;
  //@ts-ignore
  return after - 7 < 0 ? 'last' : before - 6 < 1 ? 'first' : el[detect];
};

export const getWeekDay = (val: number) => {
  const weeks = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  return weeks[val] || '';
};

export const twoDaysDifference = (date1: any, date2: any) => {
  const diffTime = +new Date(date2) - +new Date(date1);
  return diffTime < 500;
};

const hexowatchTools = [
  {
    name: 'HTML element',
    value: 'sectionElement',
  },
  {
    name: 'Keyword',
    value: 'keywordSection',
  },
  {
    name: 'Technology',
    value: 'techStackTool',
  },
  {
    name: 'Visual',
    value: 'sectionScreenTool',
  },
  {
    name: 'Content',
    value: 'contentSectionTool',
  },
  {
    name: 'Source code',
    value: 'htmlSectionTool',
  },
  {
    name: 'Availability',
    value: 'pingTool',
  },
  {
    name: 'Automatic AI',
    value: 'automaticAITool',
  },
  {
    name: 'Domain WHOIS',
    value: 'domainWhoisTool',
  },
  {
    name: 'Sitemap',
    value: 'sitemapTool',
  },
  {
    name: 'Backlink',
    value: 'backlinkTool',
  },
  {
    name: 'API',
    value: 'httpRequestTool',
  },
  {
    name: 'RSS feed',
    value: 'rssTool',
  },
];

export const getHexowatchToolName = (toolValue: string) => {
  const val = hexowatchTools.find(item => item.value === toolValue);
  return val && val.name;
};

export const closePopupOnEsc = (handlePopupStateChange: any) => {
  const handleEsc = (e: any) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      handlePopupStateChange && handlePopupStateChange();
    }
  };
  window.addEventListener('keydown', handleEsc);

  return () => {
    window.removeEventListener('keydown', handleEsc);
  };
};

export const extensionIds: string[] = [
  'gaeglheibddedbjiehaeeacoldaiimkl', //! The ID of the extension we want to talk to.
  'jmpflegmbfmkimhdhfbjhpnncahdmodo',
  'pdafbekgencanldlcjdagfjmihjjcoef',
  'nhibigcgaipebhhkncfgblijenpjhgpp',
  'bmnlpebnhjbjgbdmiaalflcjjfkfbpgf',
  'jjphfeeonaidkhddfiihhiemoaabpaah',
  'fiebpkfinhoigbcfenmclogdkaligfbl',
  'jldcheckmiaadnhakbpdjhcdddeiglcc',
];

export const getItemFromLocalStorage = (key: string) => {
  const item = localStorage.getItem(key);

  if (item) {
    return JSON.parse(item);
  }

  return null;
};

export const setItemToLocalStorage = (key: string, item: any) => localStorage.setItem(key, JSON.stringify(item));

export const sortedFirstStepAutomationIds = [
  47, 43, 31, 30, 81, 46, 59, 98, 71, 95, 119, 38, 32, 88, 85, 84, 22, 126, 118, 96, 135, 125, 122, 75, 107, 131, 105,
  128, 87, 86, 108, 111, 7, 21, 6, 120, 29, 1, 97, 138, 25, 89, 83, 79, 102, 2, 44, 5, 3, 101, 14,
];

export const getPromoDate = () => {
  // Get current date and time in GMT
  const currentDate = new Date();
  const currentDay = currentDate.getUTCDay(); // Sunday is 0, Monday is 1, ..., Saturday is 6
  const currentHour = currentDate.getUTCHours();

  // Calculate time remaining until the next Friday 11 AM GMT
  let daysUntilFriday = (5 - currentDay + 7) % 7; // 5 is the day index for Friday
  if (currentDay === 5 && currentHour >= 7) {
    // If it's already Friday after 11 AM, consider the next Friday
    daysUntilFriday += 7;
  }

  // Calculate the next Friday's date
  const nextFriday = new Date(currentDate);
  nextFriday.setUTCDate(currentDate.getUTCDate() + daysUntilFriday);
  nextFriday.setUTCHours(7, 0, 0, 0); // Set time to 11 AM GMT

  return nextFriday.toUTCString();
};
