import { FC } from 'react';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import SingleSourceSelectPatternSettings from 'automation-settings/single-source-select-settings-pattern';

const outputs = [
  '_article_curator_title',
  '_article_curator_text',
  '_article_curator_top_image',
  '_article_curator_date',
  '_article_curator_author',
  '_article_curator_description',
  '_article_curator_keyword',
];

const ArticleCuratorSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  return (
    <SingleSourceSelectPatternSettings
      id={id}
      automationIndex={automationIndex}
      selectedRecipes={selectedRecipes}
      automationSendSettings={automationSendSettings}
      setCompletedFields={setCompletedFields}
      setAutomationSendSettings={setAutomationSendSettings}
      outputKeys={outputs}
      isSettingsChange={isSettingsChange}
      setIsDirty={setIsDirty}
      automationOutputTypes={automationOutputTypes}
      automationInputTypes={automationInputTypes}
    />
  );
};

export default ArticleCuratorSettings;
