export const workflowStatuses = [
  // { label: 'All', value: 'all', is_public: false, is_scheduled: false, status: null },
  { label: 'Done', value: 'completed', is_public: false, is_scheduled: false, status: 'completed' },
  { label: 'In Progress', value: 'progress', is_public: false, is_scheduled: false, status: 'progress' },
  { label: 'Fail', value: 'error', is_public: false, is_scheduled: false, status: 'error' },
  { label: 'Paused', value: 'PAUSED', is_public: false, is_scheduled: false, status: 'PAUSED' },
  { label: 'Draft', value: 'DRAFT', is_public: false, is_scheduled: false, status: 'DRAFT' },
  // { label: 'Shared', value: 'shared', is_public: false, is_scheduled: false, status: null },
  // { label: 'Public', value: 'public', is_public: true, is_scheduled: false, status: null },
  // { label: 'Scheduled', value: 'scheduled', is_public: false, is_scheduled: true, status: null },
  {
    label: 'Automation Credits limit reached',
    value: 'CREDIT_REACHED',
    is_public: false,
    is_scheduled: false,
    status: 'CREDIT_REACHED',
  },
  {
    label: 'Premium credits limit reached',
    value: 'PREMIUM_CREDIT_REACHED',
    is_public: false,
    is_scheduled: false,
    status: 'PREMIUM_CREDIT_REACHED',
  },
  {
    label: 'Storage limit reached',
    value: 'STORAGE_REACHED',
    is_public: false,
    is_scheduled: false,
    status: 'STORAGE_REACHED',
  },
];

export const statusTexts = {
  CREDIT_REACHED: 'Automation credits limit reached',
  PREMIUM_CREDIT_REACHED: 'Premium credits limit reached',
  STORAGE_REACHED: 'Storage limit reached',
  CREDIT_STORAGE_REACHED: 'Automation credits and storage limit reached',
  PREMIUM_CREDIT_STORAGE_REACHED: 'Premium credits and storage limit reached',
};
