import { FC, useEffect, useState, memo } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
import { useAutomationGlobalMethods } from '../../hooks';
import Input from 'shared-components/atom/input';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import Checkbox from 'shared-components/atom/checkbox';
import Switcher from 'shared-components/molecule/switcher';
import './styles.scss';

const outputs = ['_keyword', '_found_keyword_output', '_keyword_count'];

const KeywordFinderSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [fieldList, setFieldList] = useState<any>([]);
  const [clicked, setClicked] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedSource, setSelectedSource] = useState<any>([]);
  const [isSensitive, setIsSensitive] = useState(false);
  const [isCheckFull, setIsCheckFull] = useState(false);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);
  const [selectedMode, setSelectedMode] = useState('Standard');

  const {
    liftingSettings,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (fieldList?.length === 0 || !searchKeyword) return false;
    return true;
  };

  useEffect(() => {
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const { keys } = dynamic_settings;
      const { settings } = keys[0];
      const { case_sensitive, search_in_html } = settings ? settings : { case_sensitive: null, search_in_html: null };
      const keywords = settings ? settings.keywords : keys[0].keywords;
      const fast = settings && settings.fast ? 'Fast' : 'Standard';
      setSelectedMode(fast);
      keywords && setSearchKeyword(keywords.join(', '));
      case_sensitive !== null && setIsSensitive(case_sensitive);
      search_in_html !== null && setIsCheckFull(search_in_html);
    }
  }, []);

  //recipe
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();

    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const key = dynamic_settings.keys[0].key;
      restoreSelectedSource({ key: key, fieldList: options, setSelectedSource: setSelectedSource });
    }
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...outputs],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);

  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);

    let localSettings = {
      keys: [
        {
          key: fieldList.length && selectedSource ? selectedSource.value : null,
          inputValue: null,
          settings: {
            keywords: searchKeyword
              .split(',')
              .map(item => item.trim())
              .filter(item => item),
            case_sensitive: isSensitive,
            search_in_html: isCheckFull,
            fast: selectedMode === 'Fast',
          },
        },
      ],
    };
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: localSettings,
      outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...outputs],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [searchKeyword, selectedSource, isSensitive, fieldList, isCheckFull, selectedMode]);
  const handleChangeCheckbox = () => {
    setIsCheckFull(!isCheckFull);
    !clicked && setClicked(true);
    setIsDirty();
  };
  const handleChangeSensitive = () => {
    setIsSensitive(!isSensitive);
    !clicked && setClicked(true);
    setIsDirty();
  };
  const handleChangeInput = (e: any) => {
    setSearchKeyword(e.target.value);
    !clicked && setClicked(true);
    setIsDirty();
  };
  const handleChangeSelect = (selectedItem: any) => {
    setSelectedSource(selectedItem);
    !clicked && setClicked(true);
    setIsDirty();
  };
  const handleSwitcherClick = (val: string) => {
    setSelectedMode(val);
    !clicked && setClicked(true);
    setIsDirty();
  };

  return (
    <div>
      <Row>
        <Col md={12} className="d-flex">
          <Switcher
            leftText="Fast"
            rightText="Standard"
            handleSwitcherClick={handleSwitcherClick}
            selectedValue={selectedMode}
            hoverTextLeft="Loads pages faster but may have a lower success rate."
          />
        </Col>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Select source</span>
          <Select
            options={fieldList}
            value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
            placeholder={'Select source'}
            onChange={handleChangeSelect}
          />
        </Col>

        <Col lg={6} className="checkbox-wrapper">
          <div className="item-block mt-4">
            <Checkbox checked={isCheckFull} label="Check within full HTML" onChange={handleChangeCheckbox} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={6}>
          <span className="label secondary-color">Keyword</span>
          <Input value={searchKeyword} onChange={handleChangeInput} />
        </Col>
        <Col lg={6} className="checkbox-wrapper">
          <div className="item-block mt-4">
            <Checkbox checked={isSensitive} label="Case sensitive" onChange={handleChangeSensitive} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default memo(KeywordFinderSettings);
