import { useState, useEffect, useContext } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useParams, useHistory, Prompt } from 'react-router-dom';
import { UserContext } from 'contexts/user-context';
import { ThemeContext } from 'contexts/theme-context';
import { Col, Row } from 'react-bootstrap';
import { GET_TASK, CHANGE_TASK_STATUS } from 'graphql/queries';
import Breadcrumb from 'shared-components/atom/breadcrumb';
import Button from 'shared-components/atom/button';
import Questions from '../components/all-questions';
import UserFullInfo from '../components/user-info';
import Modal from 'shared-components/molecule/modal';
import Loader from 'shared-components/atom/loader';
import AlertComponent from 'shared-components/atom/alert-message';
import '../styles.scss';

const Task = () => {
  const { taskId }: { taskId: string } = useParams();
  const { push } = useHistory();
  //----------------------Context----------------------------
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);

  //-----------------------Stacte-----------------------------
  const [modalState, setModalState] = useState('');
  const [task, setTask] = useState<any>({});
  const [questions, setQuestions] = useState<{ link: string; createdAt: string; answer?: string; id: number }[]>([]);
  const [isDirty, setIsDirty] = useState(false);
  const [modalOpened, setModalOpened] = useState({ show: false, location: '' });
  const [error, setError] = useState('');
  //---------------------Queries-----------------------------
  const { data, loading, refetch } = useQuery(GET_TASK, { variables: { id: +taskId } });
  const [changeStatus, { loading: statusLoading }] = useMutation(CHANGE_TASK_STATUS);
  //------------------UseEffects-----------------------------
  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (data?.HexomaticHumanValidationsTasks?.getHumanValidationTask) {
      setTask(data.HexomaticHumanValidationsTasks.getHumanValidationTask);
      setQuestions(data.HexomaticHumanValidationsTasks?.getHumanValidationTask?.elements);
    }
  }, [data]);

  useEffect(() => {
    setIsDirty(!!(task && task.status === 'progress'));
  }, [task]);

  useEffect(() => {
    if (!statusLoading && modalState !== '' && modalState !== 'completed') setModalState('');
  }, [statusLoading]);
  //---------------------Functions-------------------------------
  const handleChangeStatus = async (status?: string) => {
    const res = await changeStatus({
      variables: {
        taskId: taskId,
        status: status || modalState,
      },
    });
    if (res?.data?.HexomaticHumanValidationsTasksOps?.updateTaskStatus) {
      if (res?.data?.HexomaticHumanValidationsTasksOps?.updateTaskStatus.error) {
        setError(
          res.data.HexomaticHumanValidationsTasksOps.updateTaskStatus.message === 'WORKFLOW_PAUSED'
            ? 'The task creator changed workflow status to Paused.'
            : 'Something went wrong.',
        );
        setTimeout(() => {
          setError('');
        }, 10000);
        return;
      }
      refetch();
      if (status === 'paused') setModalState('paused');
    }
  };
  const handlePaused = () => {
    handleChangeStatus('paused');
  };
  const handleCompleted = () => {
    refetch();
    setModalState('completed');
    handleChangeStatus('completed');
  };
  const handleActionClick = () => {
    if (modalState === 'progress' || modalState === 'paused') handleChangeStatus('progress');
    if (modalState === 'completed') push('/tasks');
  };
  const handleCancelClick = () => {
    if (modalState === 'paused') push('/tasks');
    setModalState('');
  };
  if (
    task &&
    user &&
    task.userInfo &&
    task.userInfo.performer &&
    task.userInfo.performer.email &&
    task.userInfo.performer.email !== user?.email
  )
    return null;
  return (
    <div className="task-page">
      <Prompt
        when={isDirty}
        message={(location, action) => {
          setModalOpened({ show: true, location: location.pathname });
          setIsDirty(false);
          return false;
        }}
      />
      <Breadcrumb
        items={[
          {
            to: `/tasks`,
            name: 'Tasks',
            active: false,
          },
          {
            to: `/tasks/${taskId}`,
            name: task?.question || '',
            active: true,
          },
        ]}
      />
      {loading ? (
        <Row className="justify-content-center">
          <Loader light={theme === 'light'} />
        </Row>
      ) : (
        task.question && (
          <>
            <Row className="mx-0 my-2">
              <Col sm={6}>
                <UserFullInfo info={task} isCreator />
              </Col>
              {(task.status === 'new' || task.status === 'paused') && (
                <Col sm={6} className="d-flex justify-content-end p-0 align-items-end">
                  {error && <AlertComponent type="error" text={error} className="mr-3 py-2" />}
                  <Button
                    name={`${task.status === 'new' ? 'Start' : 'Continue'}`}
                    onClick={() => setModalState('progress')}
                  />
                </Col>
              )}
            </Row>
            <Questions
              questions={questions}
              disabled={task.status === 'new' || task.status === 'paused'}
              taskId={task.id}
              refetch={refetch}
              status={task.status}
              type={task.type}
            />
            <Row className="mx-0 mt-2 flex-column-reverse flex-sm-row">
              <Col sm={6}>
                {task?.userInfo?.performer?.firstName && (
                  <UserFullInfo info={task} count={questions && questions.filter((item: any) => item.answer).length} />
                )}
              </Col>
              {task.status === 'progress' && (
                <Col sm={6} className="d-flex justify-content-end p-0">
                  <Button name="Pause" outline className="mr-4" onClick={handlePaused} />
                  <Button
                    name="Complete"
                    onClick={handleCompleted}
                    disabled={!!questions.find((item: any) => !item.answer)}
                  />
                </Col>
              )}
            </Row>
          </>
        )
      )}

      <Modal
        parentClass="tasks-modal"
        isModalOpen={!!modalState}
        title={
          modalState === 'progress'
            ? `Are you sure you want to ${task.status === 'new' ? 'start' : 'continue'} the task?`
            : modalState === 'completed'
            ? 'Congratulations'
            : 'Your task is paused.'
        }
        text={modalState === 'completed' ? `You completed your task in ${task.spentTime} min.` : ''}
        actionBtnText={
          statusLoading ? (
            <Loader light={theme === 'light'} />
          ) : modalState === 'progress' ? (
            'OK'
          ) : modalState === 'completed' ? (
            'Go to current tasks'
          ) : (
            'Continue task'
          )
        }
        cancelBtnText={modalState === 'progress' ? 'Cancel' : modalState === 'paused' ? 'Go to Current tasks' : ''}
        actionBtnColor={modalState === 'progress' ? 'warning' : 'success'}
        onActionClick={handleActionClick}
        onCancelClick={handleCancelClick}
        escButtonClick={handleCancelClick}
      />
      <Modal
        isModalOpen={modalOpened.show}
        text={'Are you sure you want to leave?'}
        title={'The time will continue to run if you leave now.'}
        actionBtnText={'Leave'}
        cancelBtnText={'Stay'}
        actionBtnColor={'danger'}
        cancelBtnColor={'secondary'}
        onActionClick={() => {
          setModalOpened({ show: false, location: '' });
          push(modalOpened.location);
        }}
        onCancelClick={() => {
          setIsDirty(true);
          setModalOpened({ show: false, location: '' });
        }}
        escButtonClick={() => {
          setIsDirty(true);
          setModalOpened({ show: false, location: '' });
        }}
      />
    </div>
  );
};

export default Task;
