import { FC, ReactElement } from 'react';
import { checkedCircleIcon, removeCircleIcon, warningCircleIcon } from '../../../assets/svg-icons';
import './styles.scss';

interface IMessage {
  type: string;
  text?: string;
  children?: any;
  link?: ReactElement;
}

const Message: FC<IMessage> = ({ type, text, children, link }) => {
  return (
    <div className={`message-block ${type}`}>
      <span className="icon">
        {type === 'error' ? removeCircleIcon : type === 'success' ? checkedCircleIcon : warningCircleIcon}
      </span>
      {children ? (
        children
      ) : (
        <span className="text">
          {text}
          {link}
        </span>
      )}
    </div>
  );
};

export default Message;
