import { FC, useState, Fragment, useEffect } from 'react';

import RoundedElement from 'shared-components/atom/rounded-element';
import { FieldsProps } from 'shared-components/atom/types';

import { arrowDownIcon, arrowRight } from 'assets/svg-icons';
import './styles.scss';

const Fields: FC<FieldsProps> = ({ lists, onChange, loading, selectedList, active }) => {
  const [scroll, setScroll] = useState(0);
  const [isScroll, setIsScroll] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const scrollLeft = () => {
    if (!scroll) return;
    const el = document.querySelector('.fields');
    if (el) {
      if (el.scrollLeft > 0) el.scrollLeft -= 70;
      setScroll(el.scrollLeft);
      setIsScroll(el.scrollWidth - el.clientWidth === el.scrollLeft);
    }
  };

  const scrollRight = () => {
    const el = document.querySelector('.fields');
    if (el) {
      if (el.scrollWidth - el.clientWidth === el.scrollLeft) return;
      if (el.scrollLeft <= el.scrollWidth) el.scrollLeft += 70;
      setScroll(el.scrollLeft);
      setIsScroll(el.scrollWidth - el.clientWidth === el.scrollLeft);
    }
  };

  const change = (id: number | string) => {
    onChange(id);
    const el = document.querySelector('.fields');
    if (el) {
      setScroll(el.scrollLeft);
    }
  };

  useEffect(() => {
    const el = document.querySelector('.fields');

    let childerenLenght = 0;
    var arr = el?.children ? [...el?.children] : [];
    arr.map((item: any) => (childerenLenght += item.clientWidth));
    if (el?.clientWidth && el?.clientWidth - 100 < childerenLenght) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [lists]);

  if (loading) return null;
  return (
    <div className="fields-wrapper">
      <div onClick={scrollLeft} className={`icon left-icon ${disabled ? 'd-none' : !scroll ? 'disabled' : ''}`}>
        {arrowRight}
      </div>
      <div
        className="fields overflow-auto no-wrap my-2"
        //@ts-ignore
        onClick={e => e.target.scrollIntoView(true)}
      >
        {lists.map(list => (
          <Fragment key={list.name}>
            {list.name !== 'Crowdsourced automations' && (
              <RoundedElement
                text={list.name}
                active={
                  active === list.id ||
                  selectedList?.includes(list.id) ||
                  (selectedList?.length === 0 && (list.id === -1 || list.id === ''))
                }
                onChange={() => change(list.id)}
              />
            )}
          </Fragment>
        ))}
      </div>
      <div
        onClick={scrollRight}
        className={`icon right-icon ml-auto ${disabled ? 'd-none' : isScroll ? 'disabled' : ''}`}
      >
        {arrowRight}
      </div>
    </div>
  );
};
export default Fields;
