import { FC, useState, useEffect, SyntheticEvent } from 'react';

import Alert from 'shared-components/atom/alert-message';
import Tooltip from 'shared-components/atom/tooltip';
import Button from 'shared-components/atom/button';
import Checkbox from 'shared-components/atom/checkbox';

import csvImageGray from 'assets/images/upload-images/upload-images-for-list-gray/csv-icon.svg';

import { closeIcon } from 'assets/svg-icons';

import './styles.scss';

interface IImportModal {
  closeModal: () => void;
  useCsvFirstRowsAsColumnHeaders: boolean;
  enableCsvCustomColumHeaders: boolean;

  setUseCsvFirstRowsAsColumnHeaders: (arg: boolean) => void;
  setEnableCsvCustomColumHeaders: (arg: boolean) => void;
  errorMessage: string;

  onUpload: (arg: any, arg1: boolean) => void;
  handleRemove: (e: SyntheticEvent) => void;
  inpref: any;
  event: any;
}

const Import: FC<IImportModal> = ({
  onUpload,
  closeModal,
  enableCsvCustomColumHeaders,
  setEnableCsvCustomColumHeaders,
  useCsvFirstRowsAsColumnHeaders,
  setUseCsvFirstRowsAsColumnHeaders,
  inpref,
  event,
  handleRemove,
  errorMessage,
}) => {
  const [name, setName] = useState('');

  const handleClick = () => {
    if (event?.target?.files?.[0]) {
      onUpload(event, true);
      closeModal();
    }
  };

  useEffect(() => {
    if (event?.target?.files?.[0]?.name) {
      setName(event.target.files[0].name);
    }
  }, [event]);

  return (
    <div className="import-csv-modal-wrapper">
      <div className="upload-section">
        <h1>Import CSV</h1>

        <div className="upload-wrapper d-flex align-items-center justify-content-center">
          {event ? (
            <div className="d-flex align-items-center justify-content-start csv-file">
              <img src={csvImageGray} alt="scs" width="20" />
              <span>{name}</span>
              <Tooltip text="Remove" placement="right">
                <span className="delete-icon" onClick={handleRemove}>
                  {closeIcon}
                </span>
              </Tooltip>
            </div>
          ) : (
            <label onClick={() => inpref && inpref.current?.click()} className="cursor-pointer">
              <div className="select-file">Select file</div>
            </label>
          )}
        </div>
        <div className="checkboxes-wrapper ">
          <Checkbox
            checked={useCsvFirstRowsAsColumnHeaders}
            className="mr-3 my-2"
            labelRight="Include headers"
            onChange={() => setUseCsvFirstRowsAsColumnHeaders(!useCsvFirstRowsAsColumnHeaders)}
          />
          <Checkbox
            className="my-2"
            checked={enableCsvCustomColumHeaders}
            labelRight="Map headers to column names"
            onChange={() => setEnableCsvCustomColumHeaders(!enableCsvCustomColumHeaders)}
          />
        </div>

        {errorMessage && (
          <div className="d-flex">
            <Alert type="danger" text={errorMessage} />
          </div>
        )}

        <div className="d-flex justify-content-end">
          <Button name="Import" disabled={!name} onClick={handleClick} />
        </div>
      </div>
    </div>
  );
};

export default Import;
