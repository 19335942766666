import { FC, useEffect, useState, SetStateAction, useContext, useCallback } from 'react';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
// import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useMutation, useLazyQuery, useQuery } from '@apollo/react-hooks';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Col, Row } from 'react-bootstrap';
import axios from 'axios';

import Select from 'shared-components/atom/select';
import { errorMessages, allowedDragSettings, planLimits } from 'constants/index';
import { isValidKeyword, isValidWorkflowName } from 'constants/validations';
import { HOST } from 'constants/host';

// import MultiSelect from 'react-multi-select-component';
import { ThemeContext } from 'contexts/theme-context';
import { UserContext } from 'contexts/user-context';
import { HexomaticUserContext } from 'contexts/hexomatic-user';
import { WorkflowsRouteParams } from 'types';
import { leftArrow, tagIconNew, infoIcon, shareIcon } from 'assets/svg-icons';

import useClickOutside from 'hooks/useClickOutside';
import {
  GET_AUTOMATIONS_BY_IO,
  GET_RECIPIES_LIST,
  GET_WORKFLOW_BY_ID,
  GET_PUBLIC_WORKFLOW_BY_ID,
  GET_AUTOMATIONS_BY_IDS,
  GET_RECIPIES_LIST_BY_IDS,
  GET_AUTOMATION_TYPES,
  GET_AUTOMATIONS,
  GET_HEXOMATIC_USER_SETTINGS,
  GET_PUBLIC_REICPES,
  GET_USER_WATCH_TAGS,
  GET_WATCH_PROPERTY_BY_ID,
  GET_ALL_INTEGRATIONS,
} from 'graphql/queries';

import {
  CREATE_WORKFLOW,
  DELETE_FAVORITE,
  DELETE_WORKFLOW,
  MAKE_FAVORITE,
  // STOP_IN_PROGRESS_WORKFLOW,
  UPDATE_WORKFLOW,
  UPDATE_WORKFLOW_TAGS,
} from 'graphql/mutations';
import { useAutomationManipulationMethods, useDragMethods, useTagsMethods } from '../hooks';
import { StateChangeContext } from 'contexts/state-change-context';

import Alert from 'shared-components/atom/alert-message';
import TextArea from 'shared-components/atom/texatarea';
import Loader from 'shared-components/atom/loader';
import Checkbox from 'shared-components/atom/checkbox';
import Button from 'shared-components/atom/button';
import Payment from 'shared-components/molecule/payment';
import Tooltip from 'shared-components/atom/tooltip';
import CustomModalTag from 'shared-components/molecule/modal-container';
import CustomModal from 'shared-components/molecule/customModal';
import EditableTextOpen from 'shared-components/molecule/editable-text-open';
import Modal from 'shared-components/molecule/modal';
import ModalCalendar from 'shared-components/molecule/modal-calendar';
import ContactUs from 'shared-components/molecule/payment/contact-us';
import ToastLoader from 'shared-components/molecule/toast-loader';
import RightMenu from 'shared-components/organizm/right-sidebar';

import StickyFooter from 'layout/sticky-footer';
import TagsContent from 'pages/settings/TagsContent';
import Tag from 'pages/settings/tag/tag';

import SelectedAutomation from '../components/selectedAutomation';
import ShareWorkflowModal from '../components/share-workflow-modal';
import Tabs from '../components/tabs';
import RecommendedAutomations from '../components/recomended-automations';
// import Breadcrumbs from '../components/breadCrumbs';

import { ScheduleConfigType } from '../types';
import {
  automationsUsedWithoutSource,
  automationsUsedWithoutSourceDetailsPage,
  closePopupOnEsc,
  encrypt,
  getDateAndTime,
  getWeekDay,
  hexToRgbA,
  sortedFirstStepAutomationIds,
} from 'helpers';

import recipeIcon from 'assets/icons/recipe.svg';
import deeplLight from 'assets/images/deepL-light.svg';

import './styles.scss';
import { useMediaQuery } from 'react-responsive';

const COMPLETED_ERROR_MSG = 'Please complete the fields before saving workflow';
export const STARTING_AUTOMATION_ERROR = `This automation can't be the starting point of the workflow.`;
const USED_ONLY_AS_FIRST = 'This automation can only be used at the starting point of the workflow.';

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: `15px 0 15px 0`,
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: any, blank?: boolean) => ({
  paddingBottom: blank ? 0 : 30,
});

const exportOptionsArr: ReadonlyArray<{ label: string; value: string }> = [
  { label: 'All', value: 'all' },
  { label: 'Custom', value: 'custom' },
];

const AddNewWorkflow: FC<WorkflowsRouteParams> = ({ match }) => {
  const { workflowId } = match.params;
  const { pathname, state } = useLocation();
  const history = useHistory();
  //@ts-ignore
  const [isScrapingTab, setIsScrapingTab] = useState(state?.isScrapingTab);
  //@ts-ignore
  // const isAutomationSelected = state?.isAutomationSelected;/

  // const { t, i18n } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const { hexomaticUser } = useContext(HexomaticUserContext);
  const { stateChange, setStateChange } = useContext(StateChangeContext);
  const [preselectedAutomationId, setPreselectedAutomationId] = useState<any>(null);
  const [workflowName, setWorkflowName] = useState('');
  const [workflowWrongName, setWorkflowWrongName] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [generalErrorMessage, setGeneralErrorMessage] = useState('');
  const [automationSearch, setAutomationSearch] = useState('');
  const [comment, setComment] = useState('');
  //const [defaultSettings, setDefaultsettings] = useState('');
  const [selectedSheduleOption, setSelectedSheduleOption] = useState('');
  const [trigger, setTrigger] = useState<any>(null);
  const [workflowUploadId, setWorkflowUploadId] = useState<string | null>(null);
  const [currentWorkflowStatus, setCurrentWorkflowStatus] = useState<
    | 'error'
    | 'completed'
    | 'progress'
    | 'PAUSED'
    | 'DRAFT'
    | 'CREDIT_REACHED'
    | 'PREMIUM_CREDIT_REACHED'
    | 'STORAGE_REACHED'
    | 'CREDIT_STORAGE_REACHED'
  >('progress');
  const [commonModalMsg, setCommonModalMsg] = useState('Workflow has been successfully shared');
  const [commonModalMsgStatus, setCommonModalMsgStatus] = useState<'success' | 'warning' | 'error'>('success');

  const [isAutomationTab, setIsAutomationTab] = useState(
    localStorage.getItem('workflow-public-recipe-id')
      ? '3'
      : isScrapingTab
      ? isScrapingTab
      : localStorage.getItem('workflow-recipe-id')
      ? '2'
      : '1',
  );
  const [openedSettingsIds, setOpenedSettingsIds] = useState<any>([{ index: null, id: null }]);
  const [modalOpenedcloseEdit, setModalOpenedcloseEdit] = useState<any>({ show: false, location: null });
  const [showSearch, setShowSearch] = useState({ show: false, index: null });
  const [showUpgradeModalOnAutomationClick, setShowUpgradeModalOnAutomationClick] = useState({
    show: false,
    type: '',
  });

  const [automationsForInput, setAutomationsForInput] = useState([] as any);
  const [recipesForInput, setRecipesForInput] = useState([] as any);
  const [publicRecipesForInput, setPublicRecipesForInput] = useState([] as any);
  const [workflowSteps, setWorkflowSteps] = useState<any>([]);
  const [startSearchTimeout, setStartSearchTimeout] = useState<any>(null);
  const [completedFields, setCompletedFields] = useState<boolean[]>([]);
  //its used when in edit we need defined automations and recipes
  const [requiredAutomationListUsed, setRequiredAutomationListUsed] = useState<any>(null);
  const [requiredRecipeListUsed, setrequiredRecipeListUsed] = useState<any>(null);
  const [disabledAutmations, setDisabledAutmations] = useState<any>([]);
  const [disabledRecipes, setDisabledRecipes] = useState([]);
  const [disabledPublicRecipes, setDisabledPublicRecipes] = useState([]);
  const [automationsList, setAutomationsList] = useState([] as any);
  const [automationsListForCredits, setAutomationsListForCredits] = useState([] as any);
  const [automationsFullList, setAutomationsFullList] = useState([] as any);
  const [recipesList, setRecipesList] = useState([] as any);
  const [publicRecipesList, setPublicRecipesList] = useState([] as any);
  const [selectedAutomations, setSelectedAutomations] = useState([] as any);
  const [extractedRecipesFromSelectedAutomations, setExtractedRecipesFromSelectedAutomations] = useState<any>([]);
  const [automationSendSettings, setAutomationSendSettings] = useState<any>([]);
  const [lastUpdatedAutomations, setLastUpdatedAutomations] = useState<number[]>([]);
  const [closedAutomation, setClosedAutomation] = useState(-1);
  const [inputSchemaIds, setInputSchemaIds] = useState<any>(null);
  const [scheduleSettings, setScheduleSettings] = useState(null as any);
  const [localTrigger, setLocalTrigger] = useState('');
  const [currentWorkflow, setCurrentWorkflow] = useState(null as any);
  const [hexowatchProperty, setHexowatchProperty] = useState('');
  const [isOverwrite, setIsOverwrite] = useState(false);
  const [selectedTag, setSelectedTag] = useState<any>(null);
  const [creditUsagePerAutom, setCreditUsagePerAutom] = useState([] as any);
  const [premiumCreditUsagePerAutom, setPremiumCreditUsagePerAutom] = useState([] as any);
  const [showDeletedRecipesInWorkflow, setShowDeletedRecipesInWorkflow] = useState<null | number[]>(null);
  const [selectedEmailFields, setSelectedEmailFields] = useState<any>([]);
  const [emailFields, setEmailFields] = useState([]);
  const [uploadedFilesUrl, setUploadedFilesUrl] = useState<string[]>([]);
  const [uploadedCurrentFilesUrl, setUploadedCurrentFilesUrl] = useState<string[]>([]);
  const [types, setTypes] = useState<{ name: string; id: number }[]>([]);

  const [recipeSelected, setRecipeSelected] = useState(() => localStorage.getItem('workflow-recipe-id'));
  const [publicRecipeSelected, setPublicRecipeSelected] = useState(localStorage.getItem('workflow-public-recipe-id'));
  const [isPathnameTemplate] = useState(() => pathname.includes('current-workflow-template'));
  const [isCreatedWorkFlow] = useState(() => pathname.includes('current-workflow'));
  const [dataInputscvError, setDataInputscvError] = useState(false);
  const [startSearchFlag, setStartSearchFlag] = useState(false);
  const [startSearchInRightMenuFlag, setStartSearchInRightMenuFlag] = useState(false);
  const [outsideClick, elRef, setOutsideClick] = useClickOutside(true);
  const [isDirty, setIsDirty] = useState(false);
  //!its for render all automations for provide all outputs from first to end automations
  // const [renderAllAutomations, setRenderAllAutomations] = useState(false);
  const [hereIsDeletedRecipe, setHereIsDeletedRecipe] = useState(false);
  const [requireDataInput, setRequireDataInput] = useState(false);
  const [customSchedule, setCustomSchedule] = useState(false);
  const [triggerWorkflow, setTriggerWorkflow] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [buyPremiumCredits, setBuyPremiumCredits] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [enabledNotification, setEnabledNotification] = useState(false);
  const [exportOption, setExportOption] = useState<{ label: string; value: string }>(exportOptionsArr[0]);
  // const [isSyncWithParentModalOpen, setIsSyncWithParentModalOpen] = useState(false);
  const [isSettingsChange, setIsSettingsChange] = useState(false);
  const [zapierUpdate, setZapierUpdate] = useState(false);
  const [shouldUpgrade, setShouldUpgrade] = useState(false);
  const [trafficInsightsWarning, setTrafficInsightsWarning] = useState(false);
  const [shouldContinue, setShouldContinue] = useState(false);
  const [showTagModal, setShowTagModal] = useState(false);
  const [creditsDetails, setCreditsDetails] = useState(false);
  const [premiumCreditsDetails, setPremiumCreditsDetails] = useState(false);
  const [runNow, setRunNow] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [disableEmail, setDisableEmail] = useState(false);
  // const [isSyncWithParent, setIsSyncWithParent] = useState(false);
  const [isCommonModalOpen, setIsCommonModalOpen] = useState(false);

  const [editbleTextId, setEditbleTextId] = useState(+workflowId);
  const [startedAt, setStartedAt] = useState(null as any);
  // const [parentWorkflowId, setParentWorkflowId] = useState(0);
  const [zapier, setZapier] = useState(0);
  const [storage, setStorage] = useState(0);
  const [activeType, setActiveType] = useState(0);
  const [toasterInfo, setToasterInfo] = useState({
    show: false,
    errorMessage: '',
    loadingMessage: '',
    successMessage: '',
  });
  const [selectedHeaders, setSelectedHeaders] = useState(null as any);
  const handleToastShow = (show: boolean, errorMessage: string, loadingMessage: string, successMessage: string) => {
    setToasterInfo({ show: show, errorMessage, loadingMessage, successMessage });
  };

  const [recommendedList, setRecommendedList] = useState([]);

  const { data: userSettings } = useQuery(GET_HEXOMATIC_USER_SETTINGS, {
    skip: !!workflowId,
  });

  const { data: watchPropertyData } = useQuery(GET_WATCH_PROPERTY_BY_ID, {
    skip: !trigger?.watchPropertyId,
    fetchPolicy: 'no-cache',
    variables: {
      id: trigger?.watchPropertyId,
    },
  });

  const [deleteWorkflow, { error: err, loading: deleteLoading }] = useMutation(DELETE_WORKFLOW);
  const [markAsStarred] = useMutation(MAKE_FAVORITE, {
    fetchPolicy: 'no-cache',
  });
  const [deleteFromStarred] = useMutation(DELETE_FAVORITE, {
    fetchPolicy: 'no-cache',
  });
  // const [ignoreWorkflowShareUpdates] = useMutation(IGNORE_WORKFLOW_SHARE_UPDATES);

  const { data: userTags } = useQuery(GET_USER_WATCH_TAGS);

  const handleUpdateTags = async ({ id, tagList }: any) => {
    const { data } = await updateWorkflowTags({
      variables: {
        settings: {
          workflow_id: id,
          tags: tagList.length === 0 ? null : tagList.map((item: any) => item.id),
        },
      },
    });
  };

  const { selectedTags, setSelectedTags, userTagsList, setUserTagsList, handleTagSelect, handleTagDelete } =
    useTagsMethods({ workflowId, handleUpdateTags });

  useEffect(() => {
    if (!workflowId) {
      const userPlan = planLimits.find((item: any) => item.planName === hexomaticUser?.pricing_package);
      // if (!user?.verified) {
      //   history.push('/workflows');
      //   return
      // }
      const workflowsCount = localStorage.getItem('workflows-count');
      if (
        hexomaticUser?.left_credits === 0 ||
        (userPlan?.planName === 'FREE' &&
          userPlan?.workflows &&
          workflowsCount &&
          +workflowsCount >= userPlan?.workflows)
      ) {
        setShouldUpgrade(true);
      }
    }
  }, [user, hexomaticUser, workflowId]);
  useEffect(() => {
    if (watchPropertyData?.Watch?.getWatchPropertyById?.watchProperty?.name) {
      setHexowatchProperty(watchPropertyData.Watch.getWatchPropertyById.watchProperty.name);
    }
  }, [watchPropertyData]);

  useEffect(() => {
    setEditbleTextId(zapier || +workflowId);
    // if (workflowId) {
    //   sessionStorage.setItem('workflowId', workflowId);
    // }
  }, [zapier, workflowId]);

  const addTag = async () => {
    setShowTagModal(true);
  };

  useEffect(() => {
    userTags?.HexomaticTag?.getUserHexomaticTags?.tags &&
      setUserTagsList(userTags.HexomaticTag.getUserHexomaticTags.tags);
  }, [userTags]);

  useEffect(() => {
    if (userSettings?.HexomaticUser?.getUserSettings && !workflowId) {
      typeof userSettings.HexomaticUser.getUserSettings.send_mail === 'boolean' &&
        setEnabledNotification(userSettings.HexomaticUser.getUserSettings.send_mail);
    }
  }, [userSettings]);

  useEffect(() => {
    setIsSettingsChange(errorMessage.includes('The source of new automation'));
  }, [errorMessage]);

  useEffect(() => {
    var timeout: any = null;
    if (generalErrorMessage) {
      timeout = setTimeout(() => {
        setGeneralErrorMessage('');
      }, 10000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [generalErrorMessage]);

  useEffect(() => {
    dataInputscvError && setDataInputscvError(false);

    if (selectedAutomations.length && automationSendSettings.length) {
      const dataInput = automationSendSettings.find((item: { automation_id: number }) => item.automation_id === 47);
      const fileUploadedID =
        dataInput?.dynamic_settings?.keys && dataInput.dynamic_settings.keys[0]?.settings?.workflowUploadId;
      const fileStorage = dataInput?.dynamic_settings?.keys && dataInput?.dynamic_settings?.keys[0]?.settings?.storage;
      const urlArr = dataInput?.dynamic_settings?.keys && dataInput.dynamic_settings.keys[0]?.inputValue;

      urlArr && setUploadedCurrentFilesUrl(urlArr);
      fileUploadedID && setWorkflowUploadId(fileUploadedID);
      fileStorage && setStorage(fileStorage);

      const suggestAutomations = selectedAutomations[selectedAutomations.length - 1].suggestAutomations;
      const recommended: any = [];
      if (suggestAutomations && suggestAutomations.length > 0) {
        suggestAutomations.map((el: { id: number }) => {
          const findItem = automationSendSettings.find(
            (item: { automation_id: number }) => item.automation_id === el.id,
          );
          if (!findItem || findItem.multi) recommended.push(el);
        });
      }
      setRecommendedList(recommended);
      // const item = selectedAutomations[selectedAutomations.length - 1];
      // const source = item.source;

      // setDataInputscvError(false);
      // if (source && !source.source_csv && !source.source_txt) return;
      // if (source && !source.source_csv && source.source_txt) {
      //   const dataInput = automationSendSettings.find((item: { automation_id: number }) => item.automation_id === 47);
      //   if (
      //     !dataInput ||
      //     (dataInput &&
      //       dataInput.dynamic_settings &&
      //       (dataInput.dynamic_settings.keys[0].settings.type === 'Structured data' ||
      //         dataInput.dynamic_settings.keys[0].settings.type === 'csv-file'))
      //   ) {
      //     !errorMessage &&
      //       setErrorMessage(
      //         'The source of new automation can be only txt file. Plesae, add Data input automation before adding new automation with txt file input',
      //       );
      //     setDataInputscvError(true);
      //   } else if (errorMessage.includes('The source of new automation')) {
      //     setErrorMessage('');
      //     setDataInputscvError(false);
      //   }
      // }
      // if (source && source.source_csv && !source.source_txt) {
      //   const inputs = automationSendSettings.filter(
      //     (item: { automation_id: number }) =>
      //       item.automation_id !== 47 &&
      //       item.automation_id !== 5 &&
      //       item.automation_id !== 1 &&
      //       item.automation_id !== 3,
      //   );
      //   const dataInput = automationSendSettings.find((item: { automation_id: number }) => item.automation_id === 47);
      //   if (
      //     (!dataInput && inputs.length === 1) ||
      //     (dataInput &&
      //       dataInput.dynamic_settings &&
      //       dataInput.dynamic_settings.keys[0].settings.type !== 'Structured data' &&
      //       dataInput.dynamic_settings.keys[0].settings.type !== 'csv-file' &&
      //       inputs.length === 1)
      //   ) {
      //     !errorMessage && setErrorMessage('The source of new automation can be only structured data.');
      //     setDataInputscvError(true);
      //   } else if (errorMessage.includes('The source of new automation')) {
      //     setErrorMessage('');
      //     setDataInputscvError(false);
      //   }
      // }
    } else {
      setDisabledAutmations([]);
      setDisabledPublicRecipes([]);
      setDisabledRecipes([]);
    }
  }, [selectedAutomations, automationSendSettings]);

  useEffect(() => {
    const list = automationSendSettings && automationSendSettings[automationSendSettings.length - 1]?.output_keys_data;

    const filteredList =
      list &&
      list.length > 0 &&
      list.map((item: any) => {
        return { label: item?.replace(/_/g, ' '), value: item };
      });
    filteredList && filteredList.length > 0 && setEmailFields(filteredList);

    // filteredList && filteredList.length > 0 && selectedEmailFields.length === 0 && setSelectedEmailFields(filteredList);
    if (selectedEmailFields?.length && selectedEmailFields?.length < filteredList?.length) {
      setExportOption({ label: 'Custom', value: 'custom' });
    }
  }, [automationSendSettings]);

  const { data: allIntegrations } = useQuery(GET_ALL_INTEGRATIONS, {
    skip: !hexomaticUser.id,
    variables: {
      id: hexomaticUser.id,
    },
  });

  useEffect(() => {
    if (
      allIntegrations &&
      allIntegrations.HexomaticIntegrations.getAllIntegrations &&
      allIntegrations.HexomaticIntegrations.getAllIntegrations.email
    ) {
      const integrations = allIntegrations.HexomaticIntegrations.getAllIntegrations.email;
      let emails: any = [];
      integrations.forEach((item: any) => {
        const parsedItem = JSON.parse(item.data);
        parsedItem.verified &&
          parsedItem.enabled &&
          emails.push({
            email: parsedItem.email,
            id: item.id,
            verified: parsedItem.verified,
            enabled: parsedItem.enabled,
          });
      });
      setDisableEmail(!emails.length);
    }
  }, [allIntegrations]);

  const handleSetAutomationSettings = (val: any, index?: number) => {
    if (val.length > automationSendSettings.length && val.length > 1) {
      let newAutomationIndex = -1;
      val.map(
        (sub: any, ind: number) =>
          !automationSendSettings?.find((automation: any) => automation.automation_id === sub.automation_id) &&
          (newAutomationIndex = ind),
      );
      if (newAutomationIndex > -1 && newAutomationIndex + 1 < val?.length) {
        const newUpdatedArr = val.map((item: any, i: number) => {
          if (
            item?.output_keys_data?.find(
              (sub: any) =>
                sub?.includes('bard') ||
                sub?.includes('ChatGPT') ||
                sub?.includes('Chat GPT') ||
                sub?.includes('gemini') ||
                sub?.includes('prompt'),
            ) &&
            i > newAutomationIndex &&
            newAutomationIndex > -1
          ) {
            const outputKeys = item.output_keys_data.map((sub: any) => {
              const ind = sub.slice(sub.lastIndexOf('_') + 1);
              const pastesInd = parseInt(ind);
              const newInd = typeof pastesInd === 'number' ? pastesInd + 1 : null;
              const val =
                typeof newInd === 'number' && newInd > 0 ? sub.slice(0, sub.lastIndexOf('_') + 1) + newInd : sub;
              return val;
            });
            const pastedOUtputKeys = JSON.parse(item.output_keys_data_with_types);
            const objEnries = pastedOUtputKeys ? Object.entries(pastedOUtputKeys) : [];
            const outputKeysWithTypesArr = objEnries.map((sub: any) => {
              const ind = sub[0].slice(sub[0].lastIndexOf('_') + 1);
              const pastesInd = parseInt(ind);
              const newInd = typeof pastesInd === 'number' ? pastesInd + 1 : null;
              const val =
                typeof newInd === 'number' && newInd > 0
                  ? sub[0].slice(0, sub[0].lastIndexOf('_') + 1) + newInd
                  : sub[0];
              return [val, sub[1]];
            });
            const outputKeysWithTypes = outputKeysWithTypesArr?.length
              ? Object.fromEntries(outputKeysWithTypesArr)
              : pastedOUtputKeys;
            return {
              ...item,
              output_keys_data: outputKeys,
              output_keys_data_with_types: JSON.stringify(outputKeysWithTypes),
            };
          } else {
            return item;
          }
        });
        setAutomationSendSettings(newUpdatedArr);
      } else {
        setAutomationSendSettings(val);
      }
    } else {
      setAutomationSendSettings(val);
    }
    setClosedAutomation(-1);
    if (typeof index === 'number' && val[index] && automationSendSettings[index]) {
      const equalSettings =
        JSON.stringify(val[index].output_keys_data) ===
          JSON.stringify(automationSendSettings[index].output_keys_data) &&
        JSON.stringify(val[index].output_keys_data_with_types) ===
          JSON.stringify(automationSendSettings[index].output_keys_data_with_types);
      const indLength: any = [];
      val.map((item: any, ind: number) => {
        ind >= index && !lastUpdatedAutomations.includes(ind) && indLength.push(ind);
      });
      !equalSettings && setLastUpdatedAutomations([...lastUpdatedAutomations, ...indLength]);
    }
  };

  const handleResetLastUpdatedAutomations = (ind: any) => {
    if (lastUpdatedAutomations.includes(ind)) {
      setLastUpdatedAutomations(lastUpdatedAutomations.filter((item: any) => item !== ind));
    } else {
      setLastUpdatedAutomations([...lastUpdatedAutomations, ind]);
    }
  };

  const {
    selectAutomationOrRecipe,
    onSelectAutomation,
    showSearchBar,
    deleteAutomatinoOrRecipe,
    createAutomationSendSettings,
    restoreSelectedAutomationsAndAutomationSendSettings,
    setWizardData,
  } = useAutomationManipulationMethods({
    setClicked,
    setErrorMessage,
    setDisabledRecipes,
    setDisabledPublicRecipes,
    setRecipesList,
    setPublicRecipesList,
    setSelectedAutomations,
    setInputSchemaIds,
    setDisabledAutmations,
    setOpenedSettingsIds,
    setAutomationsList,
    automationsList,
    disabledAutmations,
    setInputValue,
    setCompletedFields,
    setIsDirty,
    setAutomationSendSettings: handleSetAutomationSettings,
    setExtractedRecipesFromSelectedAutomations,
    setShowSearch,
    setAutomationSearch,
  });

  const { onDragEnd } = useDragMethods({
    setErrorMessage,
    setClicked,
    setOpenedSettingsIds,
    setSelectedAutomations,
    setAutomationSendSettings: handleSetAutomationSettings,
    setDisabledRecipes,
    setDisabledPublicRecipes,
    setRecipesList,
    setPublicRecipesList,
    setAutomationsList,
    setDisabledAutmations,
    setShowSearch,
    setAutomationSearch,
    setCompletedFields,
    setGeneralErrorMessage,
    createAutomationSendSettings,
  });

  const variablesForFetchAutomation = (value: string) => {
    return {
      settings: {
        filter: {
          active: true,
          page: null,
          limit: null,
          date_from: null,
          date_to: null,
          sort_order: null,
          sort_by: null,
          search: {
            search_field: null,
            search_value: value ? value : null,
          },
        },
        input_schema_ids: null,
        // input_schema_ids: inputSchemaIds,
        output_schema_ids: null,
      },
    };
  };

  const variablesForFetchRecipes = (value: string) => {
    return {
      settings: {
        filter: {
          page: null,
          limit: null,
          date_from: null,
          date_to: null,
          sort_order: null,
          sort_by_name: null,
          sort_by_updated_date: null,
          sort_by: null,
          //sort_by: 'ASC',
          search: {
            search_field: null,
            search_value: value ? value : null,
          },
          sortField: 'isFavorite',
          sortDir: 'ASC',
        },
      },
    };
  };
  //automations types
  const { data: automationTypes, loading: typesLoading } = useQuery(GET_AUTOMATION_TYPES, {
    variables: {
      settings: {
        filter: {
          search: {
            search_value: null,
          },
        },
      },
    },
  });

  useEffect(() => {
    if (automationTypes?.HexomaticAutomation?.getAutomationTypes?.types) {
      setTypes([{ name: 'All', id: 0 }, ...automationTypes.HexomaticAutomation.getAutomationTypes.types]);
    }
  }, [automationTypes]);
  //
  const [createWorkflow, { loading: createWorkflowLoading }] = useMutation(CREATE_WORKFLOW);

  const [updateWorkflow, { loading: updateWorkflowLoading }] = useMutation(UPDATE_WORKFLOW);
  const [updateWorkflowTags] = useMutation(UPDATE_WORKFLOW_TAGS);

  // const [stopInProgressWorkflow, { error: stopWorkflowError }] = useMutation(STOP_IN_PROGRESS_WORKFLOW);

  const [getRecipesByIds, { data: recipesDataByIds }] = useLazyQuery(GET_RECIPIES_LIST_BY_IDS, {
    fetchPolicy: 'no-cache',
  });
  const [getAutomationByIds, { data: automationsDataByIds }] = useLazyQuery(GET_AUTOMATIONS_BY_IDS, {
    fetchPolicy: 'no-cache',
  });
  const [getWorkflowById, { data: workflowData, loading, ...args }] = useLazyQuery(GET_WORKFLOW_BY_ID, {
    fetchPolicy: 'no-cache',
  });

  const [getPublicWorkflowById, { data: publicWorkflowData, loading: publicWorkflowDataLoading }] = useLazyQuery(
    GET_PUBLIC_WORKFLOW_BY_ID,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [getAutomationByIo, { data: automationsInput, loading: loadingAutomationsInput }] = useLazyQuery(
    GET_AUTOMATIONS_BY_IO,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [getAutomationList, { data: automationsByIO, loading: loadingAutomationsByIO }] = useLazyQuery(
    GET_AUTOMATIONS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        settings: {
          filter: {
            active: true,
            page: 1,
            limit: 200,
            date_from: null,
            date_to: null,
            sort_order: null,
            sort_by: null,
            sortField: 'isFavorite',
            sortDir: 'ASC',
            automation_kind: activeType
              ? {
                  automation_type_id: activeType || undefined,
                }
              : undefined,
            search: {
              search_field: null,
              search_value: automationSearch || null,
            },
          },
        },
      },
      onCompleted(automationsByIO) {
        if (automationsByIO?.HexomaticAutomation?.getAutomations?.automations && !automationSearch && !activeType) {
          setAutomationsFullList(automationsByIO.HexomaticAutomation.getAutomations.automations);
        }
      },
    },
  );

  useEffect(() => {
    if (automationsByIO?.HexomaticAutomation?.getAutomations?.automations && !automationSearch && !activeType) {
      setAutomationsFullList(automationsByIO.HexomaticAutomation.getAutomations.automations);
    }
  }, [automationsByIO]);

  const [getRecipes, { data: recipes, loading: recipesLoading }] = useLazyQuery(GET_RECIPIES_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      settings: {
        filter: {
          sortField: 'isFavorite',
          sortDir: 'ASC',
        },
      },
    },
    onCompleted(recipes) {
      if (recipes?.HexomaticScrapingRecipe?.getScrapingRecipes?.scraping_recipes) {
        const findedList = recipes.HexomaticScrapingRecipe.getScrapingRecipes.scraping_recipes;
        if (disabledRecipes.length === 0) {
          setRecipesForInput(findedList);
          return;
        }
        const filteredList = findedList.filter(
          (item: { id: any }) =>
            disabledRecipes.some((disabledRecipe: any) => disabledRecipe?.id === item?.id) === false,
        );
        setRecipesForInput(filteredList);
      }
    },
  });

  const [getRecipesList, { data: recipesData, loading: recipesListLoading }] = useLazyQuery(GET_RECIPIES_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      settings: {
        filter: {
          sortField: 'isFavorite',
          sortDir: 'ASC',
        },
      },
    },
    onCompleted(recipesData) {
      if (recipesData?.HexomaticScrapingRecipe?.getScrapingRecipes?.scraping_recipes) {
        const list = recipesData.HexomaticScrapingRecipe.getScrapingRecipes.scraping_recipes;
        //when not clicked it`s mean first load\
        if (requiredRecipeListUsed && !clicked) {
          if (requiredRecipeListUsed.length === 0) {
            setRecipesList(list);
            return;
          }
          const filteredList: any = [];
          const disabledArr: any = [];
          list.forEach((item: { id: any }, index: number) => {
            const finded = requiredRecipeListUsed.some((recipe: any) => recipe.id === item.id);
            if (finded) {
              //@ts-ignore
              localStorage.getItem('workflow-recipe-id') && item && selectAutomationFromRightMenu(item, index);
              disabledArr.push(item);
              localStorage.removeItem('workflow-recipe-id');
            } else {
              filteredList.push(item);
            }
          });
          setDisabledRecipes(disabledArr);
          setRecipesList(filteredList);
          return;
        } else {
          if (disabledRecipes.length < 1) {
            setRecipesList(list);
            return;
          }
          const filteredList = list.filter(
            (item: { id: any }) =>
              disabledRecipes.some((disabledRecipe: any) => disabledRecipe?.id === item?.id) === false,
          );
          setRecipesList(filteredList);
        }
        // setRecipesList(list);
      }
    },
  });
  //-----------------get Public Resipes------------------------
  const [getPublicRecipes, { data: publicRecipes, loading: publicRecipesLoading, refetch: refetchPublicRecipes }] =
    useLazyQuery(GET_PUBLIC_REICPES, {
      fetchPolicy: 'no-cache',
    });
  const [
    getPublicRecipesList,
    { data: publicRecipesData, loading: publicRecipesListLoading, refetch: refetchPublicRecipesList },
  ] = useLazyQuery(GET_PUBLIC_REICPES, {
    fetchPolicy: publicRecipesList && publicRecipesList.length > 0 ? 'cache-first' : 'no-cache',
  });
  //-----------------------------------------------------------
  useEffect(() => {
    if (workflowData?.HexomaticWorkflow?.getWorkflow?.error_code) {
      history.push('/workflows');
      return;
    }
    if (workflowData?.HexomaticWorkflow?.getWorkflow?.started_at) {
      setStartedAt(workflowData?.HexomaticWorkflow?.getWorkflow?.started_at);
    }
    if (
      workflowData?.HexomaticWorkflow?.getWorkflow?.workflow?.schedule_config?.triggers &&
      workflowData.HexomaticWorkflow.getWorkflow.workflow.schedule_config.triggers[0]
    ) {
      setTrigger(workflowData.HexomaticWorkflow.getWorkflow.workflow.schedule_config.triggers[0]);
    }
    if (isOverwrite && workflowData?.HexomaticWorkflow?.getWorkflow?.workflow) {
      takeWorkflowSteps();
      return;
    }
    if (workflowData?.HexomaticWorkflow?.getWorkflow?.workflow?.execution_data && !isOverwrite) {
      setCurrentWorkflowStatus(workflowData.HexomaticWorkflow.getWorkflow.workflow.execution_data);
    }
    if (workflowData?.HexomaticWorkflow?.getWorkflow?.workflow && !isOverwrite) {
      const { workflow } = workflowData.HexomaticWorkflow.getWorkflow;
      const { tags } = workflow;

      //!sync with parent
      // if (workflow?.synced_with_parent === false) {
      //   setParentWorkflowId(workflow.parent_workflow_id);
      //   setIsSyncWithParentModalOpen(true);
      //   setIsSyncWithParent(true);
      // }

      setCurrentWorkflow(workflow);
      tags && setSelectedTags(tags);
    }
    takeWorkflowSteps();
  }, [workflowData, isOverwrite]);

  useEffect(() => {
    if (workflowData?.HexomaticWorkflow?.getWorkflow?.workflow?.selected_headers) {
      setSelectedHeaders(workflowData.HexomaticWorkflow.getWorkflow.workflow.selected_headers);
      setSelectedEmailFields(workflowData.HexomaticWorkflow.getWorkflow.workflow.selected_headers || []);
      if (workflowData?.HexomaticWorkflow?.getWorkflow.workflow?.selected_headers?.length) {
        setExportOption({ label: 'Custom', value: 'custom' });
      }
    }
  }, [workflowData]);

  useEffect(() => {
    if (publicWorkflowData?.HexomaticPublicWorkflow?.getPublicWorkflow?.public_workflow?.execution_data) {
      setCurrentWorkflowStatus(
        publicWorkflowData?.HexomaticPublicWorkflow?.getPublicWorkflow?.public_workflow?.execution_data,
      );
    }
  }, [publicWorkflowData]);

  //edit restore
  useEffect(() => {
    if (!workflowId) {
      setWorkflowName(`New workflow ${moment.utc(new Date()).format('DD.MM.YY hh:mm:ss')}`);
      return;
    }

    if (isPathnameTemplate) {
      getPublicWorkflowById({
        variables: {
          settings: {
            public_workflow_id: +workflowId,
          },
        },
      });
      return;
    }

    getWorkflowById({
      variables: {
        settings: {
          workflow_id: +workflowId,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (recipesDataByIds?.HexomaticScrapingRecipe?.getScrapingRecipesByIds?.scraping_recipes) {
      const scraping_recipes = recipesDataByIds.HexomaticScrapingRecipe.getScrapingRecipesByIds.scraping_recipes;
      setrequiredRecipeListUsed(scraping_recipes);
      //check have we all recipes
      if (+workflowId) {
        const deletedArr: number[] = [];
        workflowSteps.map((step: any, i: number) => {
          if (step.automation.id === 43) {
            const setting = JSON.parse(step.setting);
            const id = step.automation.id !== 43 ? step.automation.id : setting.predefined_settings.recipeId;
            const finded = scraping_recipes.find((item: any) => id === item.id);
            if (!finded) {
              deletedArr.push(i - 1);
            }
          }
        });

        if (deletedArr.length) {
          setHereIsDeletedRecipe(true);
          setShowDeletedRecipesInWorkflow(deletedArr);
        }
      }
    }
  }, [recipesDataByIds]);

  useEffect(() => {
    setIsNew(false);
    if (!modalOpenedcloseEdit.show) {
      setDisabledAutmations(
        !preselectedAutomationId ? [] : disabledAutmations.filter((item: any) => item.id === preselectedAutomationId),
      );
      setDisabledPublicRecipes(
        !preselectedAutomationId
          ? []
          : disabledPublicRecipes.filter((item: any) => item.id === preselectedAutomationId),
      );
      setDisabledRecipes(
        !preselectedAutomationId ? [] : disabledRecipes.filter((item: any) => item.id === preselectedAutomationId),
      );
      setCompletedFields([]);
      setSelectedAutomations([]);
      setAutomationSendSettings([]);
      stateChange.tab && setIsScrapingTab(stateChange.tab);
      setIsAutomationTab(!!stateChange.tab ? stateChange.tab : '1');
      setRecipeSelected(null);
    }
  }, [stateChange]);

  useEffect(() => {
    if (automationsDataByIds?.HexomaticAutomation?.getAutomationsByIds?.automations) {
      !stateChange.clear &&
        setRequiredAutomationListUsed(automationsDataByIds.HexomaticAutomation.getAutomationsByIds.automations);
      if (isNew || (!isDirty && !modalOpenedcloseEdit.show)) {
        const leftPremiumCredits =
          ((hexomaticUser && hexomaticUser.left_premium_automation_credit) || 0) + (user.left_premium_credits || 0);
        const packageName = hexomaticUser && hexomaticUser.pricing_package;
        const premiumsPackage = user.premium_credits_pricing_package;
        const draggableAutomation = automationsList.find((item: any) => item.id === preselectedAutomationId);
        if (
          draggableAutomation?.type?.id === 5 &&
          // draggableAutomation.automation_id !== 143 &&
          (packageName === 'FREE' || leftPremiumCredits <= 0)
        ) {
          setShowUpgradeModalOnAutomationClick({
            show: true,
            type:
              packageName === 'FREE'
                ? 'package'
                : premiumsPackage === 'FREE'
                ? 'premiums-subscribe'
                : 'premiums-upgrade',
          });
          return;
        }

        if (stateChange.requriesDataInput || window.location.search.includes('requries-data-input')) {
          const newArr = automationsDataByIds.HexomaticAutomation.getAutomationsByIds.automations.filter(
            (item: any) => item.id === 47,
          );
          const selectedAutomation = automationsDataByIds.HexomaticAutomation.getAutomationsByIds.automations.find(
            (item: any) => item.id === preselectedAutomationId,
          );
          newArr.push(selectedAutomation);
          setWizardData({
            automationSendSettings: [],
            automationsArray: preselectedAutomationId ? newArr : [],
            selectedAutomationIds: preselectedAutomationId ? [47, preselectedAutomationId] : [],
            output_keys_data: [],
            output_keys_data_with_types: undefined,
            predefined_settings: undefined,
            dataInputValue: undefined,
            is_public: undefined,
            source: selectedAutomation?.source,
            automationsListLocal: automationsFullList,
          });
          const completedAutomationIds = [
            106, 89, 94, 104, 15, 134, 13, 45, 61, 74, 6, 39, 78, 103, 142, 76, 130, 109, 18, 133, 64, 19, 138, 23,
          ];
          setCompletedFields([false, completedAutomationIds.includes(preselectedAutomationId) ? true : false]);
        } else {
          const selectedAutomation = automationsDataByIds.HexomaticAutomation.getAutomationsByIds.automations.find(
            (item: any) => item.id === preselectedAutomationId,
          );

          setWizardData({
            automationSendSettings: [],
            automationsArray: preselectedAutomationId
              ? automationsDataByIds.HexomaticAutomation.getAutomationsByIds.automations.filter(
                  (item: any) => item.id === preselectedAutomationId,
                )
              : [],
            selectedAutomationIds: preselectedAutomationId ? [preselectedAutomationId] : [],
            output_keys_data: [],
            output_keys_data_with_types: undefined,
            predefined_settings: undefined,
            dataInputValue: undefined,
            is_public: undefined,
            source: selectedAutomation?.source,
            automationsListLocal: automationsFullList,
          });
          setCompletedFields([false]);
        }

        isNew && setIsNew(false);
        return;
      }
      isNew && setIsNew(false);
    }
  }, [automationsDataByIds, isNew, stateChange]);

  useEffect(() => {
    takeWorkflowSteps();
  }, [publicWorkflowData]);

  useEffect(() => {
    if (preselectedAutomationId) {
      getAutomationByIds({
        variables: {
          settings: {
            automation_ids:
              stateChange.requriesDataInput || window.location.search.includes('requries-data-input')
                ? [47, preselectedAutomationId]
                : [preselectedAutomationId], //data input id
          },
        },
      });
    }
  }, [stateChange, preselectedAutomationId]);

  useEffect(() => {
    if (recipeSelected) {
      getRecipesByIds({
        variables: {
          settings: {
            scraping_recipes_ids: [+recipeSelected], //recpie id
          },
        },
      });
    }
  }, [recipeSelected]);

  useEffect(() => {
    var timeout: any = null;
    if (errorMessage && !errorMessage.includes('The source of new automation')) {
      timeout = setTimeout(() => {
        setErrorMessage('');
      }, 5000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [errorMessage]);

  const takeWorkflowSteps = () => {
    let currWorkflow: any;

    if (workflowData?.HexomaticWorkflow?.getWorkflow) {
      currWorkflow = workflowData?.HexomaticWorkflow?.getWorkflow;
    }

    if (publicWorkflowData?.HexomaticPublicWorkflow?.getPublicWorkflow) {
      currWorkflow = publicWorkflowData?.HexomaticPublicWorkflow?.getPublicWorkflow;
    }

    if (currWorkflow?.steps) {
      const { configs } = currWorkflow?.workflow || currWorkflow?.public_workflow;
      configs && setEnabledNotification(!!configs.send_mail);
      configs?.selectedOutputKeys &&
        (configs.selectedOutputKeys.length > 0
          ? setSelectedEmailFields(configs.selectedOutputKeys)
          : setSelectedEmailFields(emailFields.map((item: any) => item.value)));
      let { steps } = currWorkflow;

      let { workflow } = currWorkflow;
      let { public_workflow } = currWorkflow;

      let recipeIds: any[] = [];
      let automationIds: any[] = [];
      steps.forEach((step: any) => {
        if (step.automation.id === 47) {
          const setting = JSON.parse(step.setting);
          const urlArr = setting?.dynamic_settings?.keys && setting.dynamic_settings.keys[0]?.inputValue;
          urlArr && setUploadedFilesUrl(urlArr);
        }

        if (step.automation.id === 43) {
          let setting = JSON.parse(step.setting);
          if (setting.predefined_settings) {
            let parsedpredefinedSetting = setting.predefined_settings;
            if (parsedpredefinedSetting.recipeId) {
              recipeIds.push(parsedpredefinedSetting.recipeId);
            }
          }
        } else {
          automationIds.push(step.automation.id);
        }
      });
      if (recipeIds.length > 0) {
        getRecipesByIds({
          variables: {
            settings: {
              scraping_recipes_ids: recipeIds,
            },
          },
        });
      } else {
        setrequiredRecipeListUsed([]);
      }
      if (automationIds.length > 0) {
        getAutomationByIds({
          variables: {
            settings: {
              automation_ids: automationIds,
            },
          },
        });
      } else {
        setRequiredAutomationListUsed([]);
      }

      getRecipesList({
        variables: variablesForFetchRecipes(''),
      });

      setWorkflowName(
        workflow?.name || public_workflow?.name || `New workflow ${moment.utc(new Date()).format('DD.MM.YY hh:mm:ss')}`,
      );

      // (workflow?.description || public_workflow?.description) &&
      //   setComment(workflow?.description || public_workflow?.description);
      workflow?.description && setComment(workflow?.description);
      setWorkflowSteps(steps);
    }
  };

  useEffect(() => {
    if (!workflowId) return;
    if (workflowSteps.length < 1 || requiredAutomationListUsed === null || requiredRecipeListUsed === null) {
      return;
    }
    if (selectedAutomations.length > 0 && automationSendSettings.length > 0) {
      return;
    }
    restoreSelectedAutomationsAndAutomationSendSettings({
      workflowSteps,
      requiredRecipeListUsed,
      requiredAutomationListUsed,
      showDeletedRecipesInWorkflow,
      setGeneralErrorMessage,
    });
  }, [workflowSteps, requiredAutomationListUsed, requiredRecipeListUsed]);
  //edit restore

  useEffect(() => {
    isAutomationTab === '1' && getAutomationList();

    isAutomationTab === '2' &&
      getRecipesList({
        variables: variablesForFetchRecipes(''),
      });

    isAutomationTab === '3' &&
      getPublicRecipesList({
        variables: variablesForFetchRecipes(''),
      });
    setActiveType(0);
  }, [isAutomationTab]);

  // useEffect(() => {
  //   //isAutomationTab === '1' &&
  //   getAutomationByIo({
  //     variables: variablesForFetchAutomation(''),
  //   });
  //   //isAutomationTab === '2' &&
  //   getRecipes({
  //     variables: variablesForFetchRecipes(''),
  //   });
  //   //isAutomationTab === '3' &&
  //   getPublicRecipes({
  //     variables: variablesForFetchRecipes(''),
  //   });
  // }, [isAutomationTab]);

  useEffect(() => {
    if (recipes?.HexomaticScrapingRecipe?.getScrapingRecipes?.scraping_recipes) {
      const findedList = recipes.HexomaticScrapingRecipe.getScrapingRecipes.scraping_recipes;
      if (disabledRecipes.length === 0) {
        setRecipesForInput(findedList);
        return;
      }
      const filteredList = findedList.filter(
        (item: { id: any }) => disabledRecipes.some((disabledRecipe: any) => disabledRecipe?.id === item?.id) === false,
      );
      setRecipesForInput(filteredList);
    }
  }, [recipes]);

  useEffect(() => {
    if (publicRecipes?.HexomaticPublicScrapingRecipe?.getPublicScrapingRecipes?.public_scraping_recipes) {
      const findedList = publicRecipes.HexomaticPublicScrapingRecipe.getPublicScrapingRecipes.public_scraping_recipes;
      if (disabledPublicRecipes.length === 0) {
        setPublicRecipesForInput(
          findedList.map((item: any) => {
            return { ...item, is_public: true };
          }),
        );
        return;
      }
      // const filteredList = findedList.filter(
      //   (item: { id: any }) =>
      //     disabledPublicRecipes.some((disabledPublicRecipe: any) => disabledPublicRecipe.id === item.id) === false,
      // );
      setPublicRecipesForInput(
        findedList.map((item: any) => {
          return { ...item, is_public: true };
        }),
      );
    }
  }, [publicRecipes]);
  //automation right menu
  useEffect(() => {
    if (automationsByIO?.HexomaticAutomation?.getAutomations?.automations) {
      const list = automationsByIO.HexomaticAutomation.getAutomations.automations;
      setAutomationsListForCredits(list);
      //when not clicked it`s mean first load
      if (requiredAutomationListUsed && !clicked) {
        if (requiredAutomationListUsed.length === 0) {
          setAutomationsList(
            list.filter((item: any) => item.multi || !selectedAutomations.find((sub: any) => sub.id === item.id)),
          );
          return;
        }
        const filteredList: any = [];
        const disabledArr: any = [];
        list.forEach((item: { id: any; multi: boolean }) => {
          const finded = requiredAutomationListUsed.some((automation: any) => automation.id === item.id);
          finded && !item.multi ? disabledArr.push(item) : filteredList.push(item);
        });
        setDisabledAutmations(disabledArr);
        setAutomationsList(
          filteredList.filter((item: any) => item.multi || !selectedAutomations.find((sub: any) => sub.id === item.id)),
        );
        return;
      } else {
        if (disabledAutmations.length < 1) {
          setAutomationsList(
            list.filter((item: any) => item.multi || !selectedAutomations.find((sub: any) => sub.id === item.id)),
          );
          return;
        }
        const filteredList = list.filter(
          (item: { id: any }) =>
            disabledAutmations.some((disabledAutomation: any) => disabledAutomation.id === item.id) === false,
        );
        setAutomationsList(
          filteredList.filter((item: any) => item.multi || !selectedAutomations.find((sub: any) => sub.id === item.id)),
        );
      }
    }
  }, [automationsByIO, requiredAutomationListUsed, selectedAutomations]);

  useEffect(() => {
    if (recipesData?.HexomaticScrapingRecipe?.getScrapingRecipes?.scraping_recipes) {
      const list = recipesData.HexomaticScrapingRecipe.getScrapingRecipes.scraping_recipes;
      //when not clicked it`s mean first load\
      if (requiredRecipeListUsed && !clicked) {
        if (requiredRecipeListUsed.length === 0) {
          setRecipesList(list);
          return;
        }
        const filteredList: any = [];
        const disabledArr: any = [];
        list.forEach((item: { id: any }, index: number) => {
          const finded = requiredRecipeListUsed.some((recipe: any) => recipe.id === item.id);
          if (finded) {
            //@ts-ignore
            localStorage.getItem('workflow-recipe-id') && item && selectAutomationFromRightMenu(item, index);
            disabledArr.push(item);
            localStorage.removeItem('workflow-recipe-id');
          } else {
            filteredList.push(item);
          }
        });
        setDisabledRecipes(disabledArr);
        setRecipesList(filteredList);
        return;
      } else {
        if (disabledRecipes.length < 1) {
          setRecipesList(list);
          return;
        }
        const filteredList = list.filter(
          (item: { id: any }) =>
            disabledRecipes.some((disabledRecipe: any) => disabledRecipe?.id === item?.id) === false,
        );
        setRecipesList(filteredList);
      }
      // setRecipesList(list);
    }
  }, [recipesData, requiredRecipeListUsed, automationsDataByIds]);
  useEffect(() => {
    if (publicRecipesData?.HexomaticPublicScrapingRecipe?.getPublicScrapingRecipes?.public_scraping_recipes) {
      const list = publicRecipesData.HexomaticPublicScrapingRecipe.getPublicScrapingRecipes.public_scraping_recipes;
      //when not clicked it`s mean first load
    
      if (!clicked) {
        setPublicRecipesList(list);
        const id = localStorage.getItem('workflow-public-recipe-id');
        if (id) {
          localStorage.removeItem('workflow-public-recipe-id');
          let el: any = false;
          let idx = -1;
          list.map((item: any, index: number) => {
            if (item.id === +id) {
              idx = index;
              el = item;
            }
          });
          if (el) {
            setIsAutomationTab('3');
            selectAutomationFromRightMenu({ ...el, is_public: true }, idx, list);
          }
        }
        return;
      } else {
        if (disabledPublicRecipes.length < 1) {
          setPublicRecipesList(list);
          return;
        }
        const filteredList = list.filter(
          (item: { id: any }) =>
            disabledPublicRecipes.some((disabledPublicRecipe: any) => disabledPublicRecipe.id === item.id) === false,
        );
        setPublicRecipesList(filteredList);
      }
      // setRecipesList(list);
    }
  }, [publicRecipesData, disabledPublicRecipes]);

  useEffect(() => {
    if (automationsInput?.HexomaticAutomation?.getAutomationsByIO?.automations) {
      const findedList = automationsInput.HexomaticAutomation.getAutomationsByIO.automations;
      if (disabledAutmations.length === 0) {
        setAutomationsForInput(findedList);
        return;
      }
      const filteredList = findedList.filter(
        (item: { id: any }) =>
          disabledAutmations.some((disabledAutomation: any) => disabledAutomation.id === item.id) === false,
      );
      setAutomationsForInput(filteredList);
    }
  }, [automationsInput]);

  useEffect(() => {
    setAutomationSearch('');
    setInputValue('');
  }, [isAutomationTab]);

  const workflowNameUpdate = async ({ id, name }: any) => {
    if ((name && (!name.trim() || !isValidWorkflowName(name))) || !name) {
      return { message: 'Invalid name' };
    }
    if (comment && comment.trim() && !isValidKeyword(comment.trim())) {
      return { message: 'Invalid description' };
    }
    if ((!workflowId && !id) || isPathnameTemplate) {
      setWorkflowName(name);
      return;
    }

    const result = await updateWorkflow({
      variables: {
        settings: {
          workflow_id: id,
          name: name && name.trim(),
          description: comment.trim(),
          isUpdated: false,
        },
      },
    });
    if (result?.data?.HexomaticWorkflowOps?.updateWorkflow?.error_code) {
      const { error_code } = result.data.HexomaticWorkflowOps.updateWorkflow;
      //@ts-ignore
      if (errorMessages && errorMessages[error_code]) {
        if (error_code === 'ALREADY_EXISTS') {
          if (name) return { message: `"${name.length > 25 ? name.slice(0, 25) + '...' : name}" already exists` };
        } else {
          //@ts-ignore
          return { message: errorMessages[error_code] };
        }
      } else {
        return { message: error_code };
      }
    } else {
      setWorkflowName(name);
      return { message: null };
    }
  };

  const handleDeleteFile = async (urls: string[] | null): Promise<void> => {
    try {
      const res = await axios(`${HOST.APP_API.APP}/delete-files`, {
        method: 'DELETE',
        data: {
          iv: encrypt(hexomaticUser?.id?.toString()).iv,
          content: encrypt(hexomaticUser?.id?.toString()).content,
          workflowUploadId,
          fileUrls: urls,
          workflowId,
        },
      });
    } catch (e) {
      setErrorMessage('Something went wrong');
      console.log(e);
    }
  };

  const handleWorkflowUpdate = async (
    startDate?: any,
    frequency?: any,
    draft?: any,
    isZapier?: any,
    schedule_config?: any,
    triggerLocal?: string,
    isUpdateAndRun?: boolean,
  ) => {
    if (isZapier) {
      const result = await updateWorkflow({
        variables: {
          settings: {
            workflow_id: +workflowId || zapier,
            // name: workflowName,
            // started_at: startDate,
            // frequency: frequency || '',
            // active: draft ? false : true,
            // send_mail: enabledNotification,
            configs: {
              send_mail: enabledNotification,
              selectedOutputKeys: selectedEmailFields.length === emailFields.length ? [] : selectedEmailFields,
              workflowUploadId,
              triggers: triggerLocal
                ? [
                    {
                      watchPropertyId: (triggerLocal && +triggerLocal) || trigger.watchPropertyId,
                      watchIntegrationId: trigger?.watchIntegrationId || undefined,
                    },
                  ]
                : [],
            },
            automations_sequence_settings: automationSendSettings.map((item: any) => JSON.stringify(item)),
            // isDraft: draft,
            description: comment.trim(),
            isUpdated: true,
            tags: !!selectedTags.length ? selectedTags.map(item => item.id) : null,
            schedule_config: schedule_config || null,
            isUpdateAndRun: isUpdateAndRun,
          },
        },
      });
      setZapierUpdate(true);
      return;
    }
    if (dataInputscvError || checkAllWorkflowKeysAreExists()) return;
    draft && setRunNow(false);
    !errorMessage.includes('The source of new automation') && setErrorMessage('');
    if (!workflowName) {
      setErrorMessage('Please enter workflow name');
      window.scrollTo(0, document.body.scrollHeight);
      return;
    }
    if (!completedFields.every(Boolean)) {
      setErrorMessage(COMPLETED_ERROR_MSG);
      window.scrollTo(0, document.body.scrollHeight);
      return;
    }
    automationSendSettings.map((item: any) => {
      if (item.automation_id !== 101) return;
      if (user && item?.dynamic_settings?.keys[0].settings?.selected) {
        item.dynamic_settings.keys[0].settings.logo = user.logo || null;
      }
    });
    if (startDate && currentWorkflowStatus === 'progress') {
      // const stopWorkflowResult = await stopInProgressWorkflow({
      //   variables: {
      //     settings: {
      //       workflow_id: +workflowId,
      //     },
      //   },
      // });
      const result = await updateWorkflow({
        variables: {
          settings: {
            workflow_id: +workflowId || zapier,
            // name: workflowName,
            started_at: startDate,
            frequency: frequency || '',
            active: draft ? false : true,
            // send_mail: enabledNotification,
            configs: {
              send_mail: enabledNotification,
              selectedOutputKeys: selectedEmailFields.length === emailFields.length ? [] : selectedEmailFields,
              workflowUploadId,
              triggers: triggerLocal
                ? [
                    {
                      watchPropertyId: (triggerLocal && +triggerLocal) || trigger.watchPropertyId,
                      watchIntegrationId: trigger?.watchIntegrationId || undefined,
                    },
                  ]
                : [],
            },
            //! sync with paren ===>  automations_sequence_settings: isDirty ? automationSendSettings.map((item: any) => JSON.stringify(item)) : undefined,
            automations_sequence_settings: automationSendSettings.map((item: any) => JSON.stringify(item)),
            isDraft: draft,
            description: comment.trim(),
            isUpdated: draft ? false : true,
            tags: !!selectedTags.length ? selectedTags.map(item => item.id) : null,
            schedule_config: schedule_config || null,
            isUpdateAndRun: isUpdateAndRun,
          },
        },
      });
      if (result?.data?.HexomaticWorkflowOps?.updateWorkflow?.error_code) {
        const { error_code } = result.data.HexomaticWorkflowOps.updateWorkflow;
        //@ts-ignore
        if (errorMessages && errorMessages[error_code]) {
          if (error_code === 'ALREADY_EXISTS') {
            setErrorMessage(`"${workflowName}" already exists`);
          } else {
            //@ts-ignore
            setErrorMessage(errorMessages[error_code]);
          }
          window.scrollTo(0, document.body.scrollHeight);
        } else {
          setErrorMessage(error_code);
        }
      } else {
        setWorkflowName('');
        setSelectedAutomations([]);
        window.onbeforeunload = null;
        setIsDirty(false);
        history.push('/workflows');
      }
      setIsOpen(false);
      setCustomSchedule(false);
      setTriggerWorkflow(false);
    } else {
      const result = startDate
        ? await updateWorkflow({
            variables: {
              settings: {
                workflow_id: +workflowId,
                // name: workflowName,
                started_at: startDate,
                frequency: frequency || '',
                active: draft ? false : true,
                // send_mail: enabledNotification,
                configs: {
                  send_mail: enabledNotification,
                  selectedOutputKeys: selectedEmailFields.length === emailFields.length ? [] : selectedEmailFields,
                  workflowUploadId,
                  triggers: triggerLocal
                    ? [
                        {
                          watchPropertyId: triggerLocal && +triggerLocal,
                          watchIntegrationId: trigger?.watchIntegrationId || undefined,
                        },
                      ]
                    : [],
                },
                //! sync with paren ===>  automations_sequence_settings: isDirty ? automationSendSettings.map((item: any) => JSON.stringify(item)) : undefined,
                automations_sequence_settings: automationSendSettings.map((item: any) => JSON.stringify(item)),
                isDraft: draft,
                description: comment.trim(),
                isUpdated: draft ? false : true,
                tags: !!selectedTags.length ? selectedTags.map(item => item.id) : null,
                //! sync with paren ===> syncedWithParent: isSyncWithParent,
                schedule_config: schedule_config || null,
                isUpdateAndRun: isUpdateAndRun,
              },
            },
          })
        : await updateWorkflow({
            variables: {
              settings: {
                workflow_id: +workflowId,
                // send_mail: enabledNotification,
                configs: {
                  send_mail: enabledNotification,
                  selectedOutputKeys: selectedEmailFields.length === emailFields.length ? [] : selectedEmailFields,
                  workflowUploadId,
                  triggers: triggerLocal
                    ? [
                        {
                          watchPropertyId: (triggerLocal && +triggerLocal) || trigger.watchPropertyId,
                          watchIntegrationId: trigger?.watchIntegrationId || undefined,
                        },
                      ]
                    : [],
                },
                //! sync with paren ===>  automations_sequence_settings: isDirty ? automationSendSettings.map((item: any) => JSON.stringify(item)) : undefined,
                automations_sequence_settings: automationSendSettings.map((item: any) => JSON.stringify(item)),
                description: comment.trim(),
                isUpdated: draft ? false : true,
                tags: !!selectedTags.length ? selectedTags.map(item => item.id) : null,
                //! sync with paren ===> syncedWithParent: isSyncWithParent,
                schedule_config: schedule_config || null,
                isUpdateAndRun: isUpdateAndRun,
              },
            },
          });
      if (result?.data?.HexomaticWorkflowOps?.updateWorkflow?.error_code) {
        const { error_code } = result.data.HexomaticWorkflowOps.updateWorkflow;
        if (error_code === 'CANNOT_ADD_INTEGRATION_TO_SHARED_WORKFLOW') {
          setCommonModalMsg('Can not add integration to shared workflow');
          setCommonModalMsgStatus('error');
          setIsCommonModalOpen(true);
          return;
        }
        //@ts-ignore
        if (errorMessages && errorMessages[error_code]) {
          if (error_code === 'ALREADY_EXISTS') {
            setErrorMessage(`"${workflowName}" already exists`);
          } else {
            //@ts-ignore
            setErrorMessage(errorMessages[error_code]);
          }
          window.scrollTo(0, document.body.scrollHeight);
        } else {
          setErrorMessage(error_code);
        }
      } else {
        setWorkflowName('');
        setSelectedAutomations([]);
        window.onbeforeunload = null;
        setIsDirty(false);
        history.push('/workflows');
      }
      setIsOpen(false);
      setCustomSchedule(false);
      setTriggerWorkflow(false);
    }
  };

  useEffect(() => {
    if (completedFields.every(Boolean)) {
      !errorMessage.includes('The source of new automation') && setErrorMessage('');
    }
  }, [completedFields]);

  const checkAllWorkflowKeysAreExists = () => {
    let outputKeys: any = [];
    const copiedCompletedFields = automationSendSettings.length ? [...completedFields] : [];
    automationSendSettings.forEach((automation: any, automationIndex: number) => {
      const keys = automation?.dynamic_settings?.keys;
      const outputs = automation?.output_keys_data;
      if (automationIndex !== 0) {
        //for google sheets
        if (automation?.automation_id !== 21) {
          let usedKey = keys ? keys[0]?.key : null;

          if (Array.isArray(keys?.[0]?.key)) {
            usedKey = keys[0]?.key[0];
          }
          const isOk = usedKey === null ? true : outputKeys.includes(usedKey);
          if (!isOk) {
            copiedCompletedFields[automationIndex] = false;
          }
          outputKeys = [...new Set([...(outputKeys ? outputKeys : []), ...(outputs ? outputs : [])])];
        } else {
          //  copiedCompletedFields[automationIndex] = true;
        }
      } else {
        outputKeys = [...(outputs ? outputs : [])];
      }
    });
    const error = copiedCompletedFields.some(item => item === false);
    error && setErrorMessage(COMPLETED_ERROR_MSG);
    setCompletedFields(copiedCompletedFields);
    return !!error;
  };

  const handleWorkflowCreate = async (
    startDate?: string,
    frequency?: string,
    draft?: boolean,
    isZapier?: boolean,
    schedule_config?: any,
    triggerLocal?: string,
  ) => {
    if (!isZapier) {
      if (dataInputscvError || checkAllWorkflowKeysAreExists()) return;
      !errorMessage.includes('The source of new automation') && setErrorMessage('');
      // if (!workflowName) {
      //   setErrorMessage('Please enter workflow name');
      //   return;
      // }

      if (!completedFields.every(Boolean)) {
        setErrorMessage(COMPLETED_ERROR_MSG);
        window.scrollTo(0, document.body.scrollHeight);
        return;
      }
    }

    if (automationSendSettings.find((item: any) => item.automation_id === 38 || item.automation_id === 11)) {
      setTrafficInsightsWarning(true);
      return;
    }
    const result = await createWorkflow({
      variables: {
        settings: {
          name: workflowName,
          started_at: startDate || null,
          frequency: frequency || '',
          // send_mail: enabledNotification,
          configs: {
            send_mail: enabledNotification,
            selectedOutputKeys: selectedEmailFields.length === emailFields.length ? [] : selectedEmailFields,
            workflowUploadId,
            triggers: triggerLocal
              ? [
                  {
                    watchPropertyId: (triggerLocal && +triggerLocal) || trigger.watchPropertyId,
                    watchIntegrationId: trigger?.watchIntegrationId || undefined,
                  },
                ]
              : [],
          },
          automations_sequence_settings: automationSendSettings.map((item: any) => JSON.stringify(item)),
          isDraft: draft,
          storage,
          description: comment.trim(),
          tags: !!selectedTags.length ? selectedTags.map(item => item.id) : null,
          schedule_config: schedule_config || null,
        },
      },
    });
    if (result?.data?.HexomaticWorkflowOps?.createNewWorkflow?.error_code) {
      //@ts-ignore
      if (errorMessages && errorMessages[result.data.HexomaticWorkflowOps.createNewWorkflow.error_code]) {
        if (result.data.HexomaticWorkflowOps.createNewWorkflow.error_code === 'ALREADY_EXISTS') {
          setWorkflowWrongName(workflowName);
          setErrorMessage(`"${workflowName}" already exists`);
        } else {
          //@ts-ignore
          setErrorMessage(errorMessages[result.data.HexomaticWorkflowOps.createNewWorkflow.error_code]);
        }
        window.scrollTo(0, document.body.scrollHeight);
      } else {
        setErrorMessage(result.data.HexomaticWorkflowOps.createNewWorkflow.error_code);
      }
    } else {
      if (isZapier) {
        setZapier(+result.data.HexomaticWorkflowOps.createNewWorkflow.workflowId);
        return;
      }
      setWorkflowName('');
      setSelectedAutomations([]);
      setIsDirty(false);
      localStorage.removeItem('worklows-details');
      window.onbeforeunload = null;
      history.push('/workflows');
    }
    setIsOpen(false);
    setCustomSchedule(false);
    setTriggerWorkflow(false);
  };

  useEffect(() => {
    if (!outsideClick) {
      setInputValue('');
      setAutomationsForInput([]);
      setRecipesForInput([]);
      setPublicRecipesForInput([]);
    }
  }, [outsideClick]);

  useEffect(() => {
    if (startSearchFlag) {
      //isAutomationTab === '1' &&
      getAutomationByIo({
        variables: variablesForFetchRecipes(inputValue),
      });
      //isAutomationTab === '2' &&
      getRecipes({
        variables: variablesForFetchRecipes(inputValue),
      });
      //isAutomationTab === '3' &&
      getPublicRecipes({
        variables: variablesForFetchRecipes(inputValue),
      });
    }
  }, [startSearchFlag]);

  useEffect(() => {
    if (startSearchInRightMenuFlag) {
      isAutomationTab === '1' && getAutomationList();
      isAutomationTab === '2' &&
        getRecipesList({
          variables: variablesForFetchRecipes(automationSearch),
        });
      isAutomationTab === '3' &&
        getPublicRecipesList({
          variables: variablesForFetchRecipes(automationSearch),
        });
    }
  }, [startSearchInRightMenuFlag]);

  const onChangeTimeout = (e: { target: { value: SetStateAction<string> } }) => {
    setInputValue(e.target.value);
    if (startSearchTimeout) {
      setStartSearchFlag(false);
      clearTimeout(startSearchTimeout);
    }
    setStartSearchTimeout(setTimeout(() => setStartSearchFlag(true), 500));
  };

  const onChangeRightMenuTimeout = (val: string) => {
    setAutomationSearch(val);
    if (startSearchTimeout) {
      setStartSearchInRightMenuFlag(false);
      clearTimeout(startSearchTimeout);
    }
    setStartSearchTimeout(setTimeout(() => setStartSearchInRightMenuFlag(true), 500));
  };

  useEffect(() => {
    if (!isDirty) return;
    window.onbeforeunload = (e: { returnValue: string }) => {
      const flag = window.confirm("Changes you've made will be lost if you exit now.");
      return flag;
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, [isDirty]);

  useEffect(() => {
    if (currentWorkflow && currentWorkflow?.credit_usage) {
      const parsed = JSON.parse(currentWorkflow.credit_usage);
      const parsedCredit = parsed && parsed[workflowId] && parsed[workflowId]?.credit;
      const parsedPremiumCredit = parsed && parsed[workflowId] && parsed[workflowId]?.premium_credit;

      const arrCredit: { name: string; count: number }[] = [];
      const arrPremiumCredit: { name: string; count: number }[] = [];

      if (parsedCredit && parsedPremiumCredit && automationsListForCredits.length) {
        Object.keys(parsedCredit).forEach((key: string) => {
          if (key === '43') {
            arrCredit.push({
              name: 'Scraping recipe', // parsedCredit['scraping_recipe_name'],
              count: parsedCredit[key],
            });
          }
          automationsListForCredits.forEach((ob: any) => {
            if (+key === ob.id) {
              arrCredit.push({ name: ob.name, count: parsedCredit[key] });
            }
          });
        });

        Object.keys(parsedPremiumCredit).forEach((key: string) => {
          if (key === '43') {
            arrPremiumCredit.push({
              name: 'Scraping recipe', //parsedPremiumCredit['scraping_recipe_name'],
              count: parsedPremiumCredit[key],
            });
          }
          automationsListForCredits.forEach((ob: any) => {
            if (+key === ob.id) {
              arrPremiumCredit.push({ name: ob.name, count: parsedPremiumCredit[key] });
            }
          });
        });

        if (arrCredit.length && arrPremiumCredit.length) {
          setCreditUsagePerAutom(arrCredit);
          setPremiumCreditUsagePerAutom(arrPremiumCredit);
        }
      }
    }
  }, [currentWorkflow, workflowData, automationsListForCredits]);

  const equalSelectedAutomationAndSettings = !selectedAutomations
    ? automationSendSettings === null
    : automationSendSettings !== null
    ? selectedAutomations.length === automationSendSettings.length
    : false;

  const onDrag = (result: any) => {
    if (dataInputscvError) return;
    const leftPremiumCredits =
      ((hexomaticUser && hexomaticUser.left_premium_automation_credit) || 0) + (user.left_premium_credits || 0);
    const packageName = hexomaticUser && hexomaticUser.pricing_package;
    const premiumsPackage = user.premium_credits_pricing_package;
    const draggableAutomation = automationsList.find((item: any) => item.name === result.draggableId);
    const draggablePublicRecipe = publicRecipesList.find((item: any) => item.name === result.draggableId && item.is_public);
    if (
      draggableAutomation?.type?.id === 5 &&
      // draggableAutomation.automation_id !== 143 &&
      (packageName === 'FREE' || leftPremiumCredits <= 0)
    ) {
      setShowUpgradeModalOnAutomationClick({
        show: true,
        type:
          packageName === 'FREE' ? 'package' : premiumsPackage === 'FREE' ? 'premiums-subscribe' : 'premiums-upgrade',
      });
      return;
    }
    if (
      draggableAutomation &&
      !selectedAutomations.length &&
      !draggableAutomation.has_source &&
      !automationsUsedWithoutSource.includes(draggableAutomation.id)
    ) {
      if (!result?.destination) {
        return;
      }

      if (result?.destinationSource?.droppableId === 'droppable' && result?.destination?.droppableId === 'droppable2') {
        return;
      }

      const newArr = automationsFullList.filter((item: any) => item.id === 47);
      const selectedAutomation = automationsFullList.find((item: any) => item.id === draggableAutomation.id);
      newArr.push(selectedAutomation);
      setWizardData({
        automationSendSettings: [],
        automationsArray: draggableAutomation.id ? newArr : [],
        selectedAutomationIds: [47, draggableAutomation.id],
        output_keys_data: [],
        output_keys_data_with_types: undefined,
        predefined_settings: undefined,
        dataInputValue: undefined,
        is_public: undefined,
        source: selectedAutomation?.source,
        automationsListLocal: automationsFullList,
        setAutomationSearch,
      });
      const completedAutomationIds = [
        106, 89, 94, 104, 15, 134, 13, 45, 61, 74, 6, 39, 78, 103, 142, 76, 130, 109, 18, 133, 64, 19, 138, 23,
      ];
      setCompletedFields([false, completedAutomationIds.includes(draggableAutomation.id) ? true : false]);
      return;
    }
    if (draggablePublicRecipe?.is_public && !selectedAutomations?.length) {
      handleSelctPublicRecipe(draggablePublicRecipe);
      return;
    }
    if (
      draggableAutomation &&
      selectedAutomations.length &&
      selectedAutomations.find((item: any) => item.id === 125 || item.id === 81 || item.id === 108) &&
      result.destination.index === 0
    ) {
      const selected =
        result.destination.index === 0 &&
        selectedAutomations.find((item: any) => item.id === 125 || item.id === 81 || item.id === 108);
      setErrorMessage(
        `You cannot use other automation before ${
          selected.id === 125
            ? 'Google BigQuery'
            : selected.id === 81
            ? 'Crawler'
            : selected.id === 108
            ? 'SQL database connector'
            : ''
        } automation.`,
      );
      return;
    }
    if (
      draggableAutomation &&
      selectedAutomations.length &&
      (draggableAutomation.id === 125 || draggableAutomation.id === 81 || draggableAutomation.id === 108) &&
      result.destination.index !== 0
    ) {
      setErrorMessage(USED_ONLY_AS_FIRST);
      return;
    }
    const finList = handleFilterAutomationsBySourceType(automationsList);
    onDragEnd({
      result,
      completedFields,
      selectedAutomations,
      automationSendSettings,
      recipesList,
      publicRecipesList,
      isAutomationTab,
      automationsList: finList,
      disabledAutmations,
      disabledRecipes,
      disabledPublicRecipes,
      showSearch,
      errorMessage,
      isDirty,
      clicked,
      setAutomationSearch,
    });
  };

  const handleSelctPublicRecipe = (fullInfo: any, recipesListLocal?: any) => {
    const newArr = automationsFullList?.filter((item: any) => item.id === 47);
    const selectedRecipe = (recipesListLocal?.length ? recipesListLocal : publicRecipesList)?.find((item: any) => item.id === fullInfo.id);
    newArr?.push({ recipe: selectedRecipe });
    const predefinedSettings = fullInfo?.actions && JSON.parse(fullInfo.actions);
    const outputOptions: any = [];
    const outputOptionsWithTypes: any = {};

    if (selectedRecipe) {
      let data = JSON.parse(selectedRecipe.actions);
      while (typeof data === 'string') {
        data = JSON.parse(data);
      }
      data &&
        data.pages &&
        data.pages[0] &&
        data.pages[0].elements.forEach((element: { property: any; type: any }) => {
          element.property && outputOptions.push({ label: element.property, value: element.property });
          element.property &&
            (outputOptionsWithTypes[element.property] =
              element.type === 'text' || element.type === 'HTML tag' || element.type === 'date'
                ? 'text'
                : element.type === 'img_src' || element.type === 'a_href'
                ? 'url'
                : element.type === 'number'
                ? 'number'
                : element.type === 'email'
                ? 'email address'
                : element.type === 'phone'
                ? 'phone number'
                : 'any');
        });
    }
   
    predefinedSettings &&
      outputOptions &&
      outputOptionsWithTypes &&
      fullInfo.id &&
      setWizardData({
        automationSendSettings: [],
        automationsArray: fullInfo.id ? newArr : [],
        selectedAutomationIds: [47, fullInfo.id],
        output_keys_data: ['__url', ...outputOptions.map((item: { label: any; value: string }) => item.value)],
        output_keys_data_with_types: { __url: 'url', ...outputOptionsWithTypes },
        predefined_settings: { ...predefinedSettings, recipeId: fullInfo.id },
        dataInputValue: fullInfo.url,
        is_public: true,
        source: null,
        automationsListLocal: automationsFullList,
      });
    setCompletedFields([true, true]);
  };

  const selectAutomationFromRightMenu = (
    fullInfo: {
      id: number;
      actions?: string;
      output_schema_ids: any;
      type: { id: number; name: string };
      has_source: boolean;
      multi: boolean;
      is_public?: boolean;
      url?: string;
    },
    index: number,
    automationsFullListLocal?: null | any[],
  ) => {
    if (dataInputscvError || checkAllWorkflowKeysAreExists()) return;
    if (selectedAutomations.length > 0 && (fullInfo.id === 125 || fullInfo.id === 81 || fullInfo.id === 108)) {
      setGeneralErrorMessage(USED_ONLY_AS_FIRST);
      return;
    }
    const leftPremiumCredits =
      ((hexomaticUser && hexomaticUser.left_premium_automation_credit) || 0) + (user.left_premium_credits || 0);
    const packageName = hexomaticUser && hexomaticUser.pricing_package;
    const premiumsPackage = user.premium_credits_pricing_package;
    if (
      fullInfo.type?.id === 5 &&
      // fullInfo.id !== 143 &&
      (packageName === 'FREE' || leftPremiumCredits <= 0)
    ) {
      setShowUpgradeModalOnAutomationClick({
        show: true,
        type:
          packageName === 'FREE' ? 'package' : premiumsPackage === 'FREE' ? 'premiums-subscribe' : 'premiums-upgrade',
      });
      return;
    }
    setAutomationSearch('');
    if (
      !selectedAutomations.length &&
      !fullInfo.has_source &&
      !automationsUsedWithoutSource.includes(fullInfo.id) &&
      !fullInfo.actions
    ) {
      const newArr = automationsFullList?.filter((item: any) => item.id === 47);
      const selectedAutomation = automationsFullList?.find((item: any) => item.id === fullInfo.id);
      newArr?.push(selectedAutomation);
      setWizardData({
        automationSendSettings: [],
        automationsArray: fullInfo.id ? newArr : [],
        selectedAutomationIds: [47, fullInfo.id],
        output_keys_data: [],
        output_keys_data_with_types: undefined,
        predefined_settings: undefined,
        dataInputValue: undefined,
        is_public: undefined,
        source: selectedAutomation?.source,
        automationsListLocal: automationsFullList,
      });
      const completedAutomationIds = [
        106, 89, 94, 104, 15, 134, 13, 45, 61, 74, 6, 39, 78, 103, 142, 76, 130, 109, 18, 133, 64, 19, 138, 23,
      ];
      setCompletedFields([false, completedAutomationIds.includes(fullInfo.id) ? true : false]);
      return;
    } else if (fullInfo?.is_public && !selectedAutomations?.length) {
      handleSelctPublicRecipe(fullInfo, automationsFullListLocal);
      return;
    }
    selectAutomationOrRecipe({
      fullInfo,
      index,
      completedFields,
      clicked,
      selectedAutomations,
      recipesList,
      publicRecipesList,
      disabledRecipes,
      disabledPublicRecipes,
      showSearch,
      automationSendSettings,
      errorMessage,
      isDirty,
      automationsFullListLocal,
    });
  };

  const showAutomationSearch = (index: number) => {
    showSearchBar({
      index,
      clicked,
      completedFields,
      selectedAutomations,
      showSearch,
    });
  };

  const selectAutomation = ({ automation, automationIndex }: any) => {
    // return;
    if (dataInputscvError || checkAllWorkflowKeysAreExists()) return;
    if (selectedAutomations.length > 0 && (automation.id === 125 || automation.id === 81 || automation.id === 108)) {
      setGeneralErrorMessage(USED_ONLY_AS_FIRST);
      return;
    }
    const leftPremiumCredits =
      ((hexomaticUser && hexomaticUser.left_premium_automation_credit) || 0) + (user.left_premium_credits || 0);
    const packageName = hexomaticUser && hexomaticUser.pricing_package;
    const premiumsPackage = user.premium_credits_pricing_package;
    if (
      automation?.type?.id === 5 &&
      // automation?.id !== 143 &&
      (packageName === 'FREE' || leftPremiumCredits <= 0)
    ) {
      setShowUpgradeModalOnAutomationClick({
        show: true,
        type:
          packageName === 'FREE' ? 'package' : premiumsPackage === 'FREE' ? 'premiums-subscribe' : 'premiums-upgrade',
      });
      return;
    }
    if (
      !selectedAutomations.length &&
      !automation.has_source &&
      !automationsUsedWithoutSource.includes(automation.id) &&
      !automation.actions
    ) {
      const newArr = automationsFullList.filter((item: any) => item.id === 47);
      const selectedAutomation = automationsFullList.find((item: any) => item.id === automation.id);
      newArr.push(selectedAutomation);
      setWizardData({
        automationSendSettings: [],
        automationsArray: automation.id ? newArr : [],
        selectedAutomationIds: [47, automation.id],
        output_keys_data: [],
        output_keys_data_with_types: undefined,
        predefined_settings: undefined,
        dataInputValue: undefined,
        is_public: undefined,
        source: selectedAutomation?.source,
        automationsListLocal: automationsFullList,
      });
      const completedAutomationIds = [
        106, 89, 94, 104, 15, 134, 13, 45, 61, 74, 6, 39, 78, 103, 142, 76, 130, 109, 18, 133, 64, 19, 138, 23,
      ];
      setCompletedFields([false, completedAutomationIds.includes(automation.id) ? true : false]);
      return;
    }
    onSelectAutomation({
      automation,
      automationIndex,
      completedFields,
      selectedAutomations,
      isAutomationTab,
      recipesList,
      publicRecipesList,
      disabledRecipes,
      disabledPublicRecipes,
      clicked,
      showSearch,
      automationSendSettings,
      errorMessage,
      isDirty,
    });
  };

  const removeAutomationOrRecipe = ({ indexNumber, index, id, multi, type }: any) => {
    //if automation isn`t last check his output used in the next autmation, if used need to set error
    if (index + 1 !== selectedAutomations.length && index > -1) {
      checkIsTheNextAutomationsUsedThisAutomationOutputs({ indexNumber, index: index, automationSendSettings });
    } else {
      setAutomationSendSettings(automationSendSettings.filter((automation: any, i: number) => index !== i));
      setCompletedFields(completedFields.filter((item: boolean, i: number) => i !== index));
    }

    if (workflowUploadId && id === 47) {
      handleDeleteFile(null);
    }

    deleteAutomatinoOrRecipe({
      indexNumber,
      index,
      id,
      multi,
      type,
      clicked,
      selectedAutomations,
      disabledRecipes,
      disabledPublicRecipes,
      recipesList,
      publicRecipesList,
      automationSearch,
      showDeletedRecipesInWorkflow,
      setShowDeletedRecipesInWorkflow,
    });
    setIsDirty(true);
  };

  const checkIsTheNextAutomationsUsedThisAutomationOutputs = ({ index, automationSendSettings }: any) => {
    let restrictedOuputKeys: any = [];
    if (index === 0) {
      let { output_keys_data } = automationSendSettings[0];
      restrictedOuputKeys = [...(output_keys_data ? output_keys_data : [])];
    } else {
      // to figure out differenc for understand what outputs is belong to current automation
      const previousAutomationOutputs = [...automationSendSettings[index - 1]?.output_keys_data];
      const currentAutomationOutputs = [...automationSendSettings[index]?.output_keys_data];
      restrictedOuputKeys = currentAutomationOutputs.filter((key: string) => !previousAutomationOutputs.includes(key));
    }

    const copiedCompletedFields = [...completedFields];
    // we need to check if deleted automation outputs used in the next automation
    // if so we need to delete that keys from dynamic_settings.keys[0].key and remove from outputs
    const copiedAutomationSendSettings = automationSendSettings.map((automationSettings: any, i: number) => {
      const keys = automationSettings.dynamic_settings?.keys;
      let usedKey = keys ? keys[0]?.key : null;

      if (index < i && usedKey) {
        let isOutputUsedAskey: boolean = Array.isArray(usedKey)
          ? !usedKey.filter((sub: any) => !restrictedOuputKeys.find((item: any) => item === sub))?.length
          : restrictedOuputKeys.includes(usedKey);
        const prevOutputLength =
          automationSendSettings[index - 1]?.output_keys_data &&
          [...automationSendSettings[index - 1]?.output_keys_data].length === 1;
        copiedCompletedFields[i] = prevOutputLength || !isOutputUsedAskey;
        const copiedKeys = automationSettings.dynamic_settings?.keys.map((item: any, keyIndex: number) => {
          if (keyIndex === 0) {
            return { ...item, key: isOutputUsedAskey ? null : item.key };
          }
          return item;
        });
        const filteredOuputs = automationSettings.output_keys_data?.filter(
          (key: string) => !restrictedOuputKeys.includes(key),
        );
        return {
          ...automationSettings,
          dynamic_settings: { ...automationSettings.dynamic_settings, keys: copiedKeys },
          output_keys_data: filteredOuputs,
        };
      }
      return automationSettings;
    });
    const newCopiedArr = copiedAutomationSendSettings.filter((automation: any, i: number) => index !== i);
    const newUpdatedArr = newCopiedArr.map((item: any, i: number) => {
      if (
        item.output_keys_data.find(
          (sub: any) =>
            sub.includes('bard') || sub.includes('ChatGPT') || sub?.includes('gemini') || sub.includes('prompt'),
        ) &&
        i >= index
      ) {
        const outputKeys = item.output_keys_data.map((sub: any) => {
          const ind = sub.slice(sub.lastIndexOf('_') + 1);
          const pastesInd = parseInt(ind);
          const newInd = typeof pastesInd === 'number' ? pastesInd - 1 : null;
          const val = typeof newInd === 'number' && newInd > 0 ? sub.slice(0, sub.lastIndexOf('_') + 1) + newInd : sub;
          return val;
        });
        const pastedOUtputKeys = JSON.parse(item.output_keys_data_with_types);
        const objEnries = pastedOUtputKeys ? Object.entries(pastedOUtputKeys) : [];
        const outputKeysWithTypesArr = objEnries.map((sub: any) => {
          const ind = sub[0].slice(sub[0].lastIndexOf('_') + 1);
          const pastesInd = parseInt(ind);
          const newInd = typeof pastesInd === 'number' ? pastesInd - 1 : null;
          const val =
            typeof newInd === 'number' && newInd > 0 ? sub[0].slice(0, sub[0].lastIndexOf('_') + 1) + newInd : sub[0];
          return [val, sub[1]];
        });
        const outputKeysWithTypes = outputKeysWithTypesArr?.length
          ? Object.fromEntries(outputKeysWithTypesArr)
          : pastedOUtputKeys;
        return {
          ...item,
          output_keys_data: outputKeys,
          output_keys_data_with_types: JSON.stringify(outputKeysWithTypes),
        };
      } else {
        return item;
      }
    });
    setAutomationSendSettings(newUpdatedArr);
    if (index === 0 && automationSendSettings[index + 1].predefined_settings) {
      setCompletedFields([
        false,
        ...copiedCompletedFields.filter((item: boolean, i: number) => i !== index && index !== 0),
      ]);
    } else {
      setCompletedFields(copiedCompletedFields.filter((item: boolean, i: number) => i !== index));
    }
    const newInds: any = [];
    selectedAutomations.map(
      (item: any, ind: any) =>
        ind >= index && !lastUpdatedAutomations.includes(ind) && typeof ind === 'number' && newInds.push(ind),
    );
    setLastUpdatedAutomations([...lastUpdatedAutomations, ...newInds]);
  };

  const getShow = (item: { id: null; recipe: { id: any } }, index: number) => {
    const first = openedSettingsIds[0];
    const second = openedSettingsIds[1];
    const show =
      equalSelectedAutomationAndSettings && item.id === null
        ? !workflowId
        : (item.id === first?.id && index === first?.index) ||
          (item.id === second?.id && index === second?.index) ||
          (item.recipe && item.recipe.id === first.id && index === first.index);
  };

  useEffect(() => {
    const newIds = openedSettingsIds
      .map((item: any) => item.index)
      .filter((item: any) => !lastUpdatedAutomations.includes(item) && typeof item === 'number');
    newIds.length && setLastUpdatedAutomations([...newIds]);
  }, [openedSettingsIds]);

  const handleFooterCancel = () => {
    if (isDirty) {
      setModalOpenedcloseEdit({ show: true, location: '/workflows' });
      setIsDirty(false);
    } else {
      !errorMessage.includes('The source of new automation') && setErrorMessage('');
      setSelectedAutomations([]);
      setAutomationSendSettings([]);
      history.push('/workflows');
    }
  };

  const handleFooterUpdate = () => {
    if (updateWorkflowLoading) return;
    if (selectedAutomations.find((item: any) => item.id === 38 || item.id === 11)) {
      setTrafficInsightsWarning(true);
      return;
    }
    if (!zapier) {
      if (dataInputscvError) return;
      if (!completedFields.every(Boolean)) {
        setErrorMessage('Before saving the step please fill in the required fields for current step');
        window.scrollTo(0, document.body.scrollHeight);
        return;
      }
      !(workflowWrongName && workflowName === workflowWrongName) && handleWorkflowUpdate();
    } else {
      handleWorkflowUpdate();
    }
  };

  const handleFooterSave = () => {
    if (hexomaticUser?.left_automation_credit === 0) {
      setShouldUpgrade(true);
      return;
    }
    if (selectedAutomations.find((item: any) => item.id === 38 || item.id === 11)) {
      setTrafficInsightsWarning(true);
      return;
    }
    let premiumAutoUsed = !!selectedAutomations.find(
      (item: { type: { name: string }; id: number }) => item?.type?.name === 'Premium automations',
      // && item?.id !== 143,
    );
    if (premiumAutoUsed && user?.left_premium_credits === 0 && hexomaticUser?.left_premium_automation_credit === 0) {
      setShouldUpgrade(true);
      hexomaticUser?.pricing_package !== 'FREE' ? setBuyPremiumCredits(true) : setBuyPremiumCredits(false);
      setCustomSchedule(false);
      return;
    }
    if (dataInputscvError || checkAllWorkflowKeysAreExists()) return;
    if (!completedFields.every(Boolean)) {
      setErrorMessage('Before saving the step please fill in the required fields for current step');
      window.scrollTo(0, document.body.scrollHeight);
      return;
    }

    !(workflowWrongName && workflowName === workflowWrongName) && setIsOpen(true);
  };

  const checkAutomationFields = ({ index, checked }: { index: number; checked: boolean }) => {
    if (completedFields[index] === checked) return;
    setCompletedFields(completedFields.map((item, i) => (i === index ? checked : item)));
  };

  const handleLeavePage = async () => {
    if (zapier) {
      await deleteWorkflow({
        variables: {
          settings: {
            workflow_ids: [zapier],
          },
        },
      });
    }
    if (zapierUpdate) {
      const automationSendSettingsFiltered = automationSendSettings.filter((item: any) => item.automation_id !== 25);
      const result = await updateWorkflow({
        variables: {
          settings: {
            workflow_id: +workflowId || zapier,
            // send_mail: enabledNotification,
            configs: {
              send_mail: enabledNotification,
              selectedOutputKeys:
                selectedEmailFields.length === emailFields.length
                  ? []
                  : selectedEmailFields.map((item: any) => item.value),
            },
            automations_sequence_settings: automationSendSettingsFiltered.map((item: any) => JSON.stringify(item)),
            description: comment.trim(),
            isUpdated: true,
            tags: !!selectedTags.length ? selectedTags.map(item => item.id) : null,
          },
        },
      });
    }

    if (workflowUploadId) {
      if (
        Array.isArray(uploadedCurrentFilesUrl) &&
        uploadedCurrentFilesUrl.length &&
        Array.isArray(uploadedFilesUrl) &&
        uploadedFilesUrl.length
      ) {
        let urls: string[] = uploadedCurrentFilesUrl.filter(
          (item: string) => item !== uploadedFilesUrl.find((el: string) => el === item),
        );

        if (urls?.length) {
          await handleDeleteFile(urls);
        }
      }

      if (
        Array.isArray(uploadedCurrentFilesUrl) &&
        uploadedCurrentFilesUrl.length &&
        Array.isArray(uploadedFilesUrl) &&
        !uploadedFilesUrl.length
      ) {
        await handleDeleteFile(null);
      }
    }
    setModalOpenedcloseEdit({ show: false, location: null });
    pathname !== modalOpenedcloseEdit.location && history.push(modalOpenedcloseEdit.location);
  };

  useEffect(() => {
    if (isDirty && !automationSendSettings.length) {
      setDisabledAutmations([]);
      setDisabledPublicRecipes([]);
      setDisabledRecipes([]);
      setCompletedFields([]);
    }
  }, [automationSendSettings, isDirty]);

  useEffect(() => {
    if (stateChange.clear) {
      setPreselectedAutomationId(null);
    } else {
      let idFromUrl: any = undefined;
      const queryData = qs.parse(window.location.search);

      if (queryData && queryData['automation-id'] && queryData['automation-id'].length > 0) {
        idFromUrl = +queryData['automation-id'];
      }
      const id = stateChange.id || idFromUrl;

      setPreselectedAutomationId(id);
    }
  }, [stateChange]);

  const reset = () => {
    // const id =
    //   typeof +location.replace('/workflows/create?', '') === 'number'
    //     ? +location.replace('/workflows/create?', '')
    //     : null;
    const id = preselectedAutomationId;

    const disabledAutmationsList = disabledAutmations.filter((item: any) => item.id === id);
    const dataInput =
      stateChange.requriesDataInput || window.location.search.includes('requries-data-input')
        ? disabledAutmations.find((item: any) => item.id === 47)
        : null;

    setAutomationSearch('');
    setInputValue('');
    setIsNew(true);
    setAutomationSendSettings([]);
    setSelectedAutomations([]);
    setPreselectedAutomationId(null);
    setDisabledAutmations(!id ? [] : dataInput ? [dataInput, ...disabledAutmationsList] : disabledAutmationsList);
    setDisabledPublicRecipes(!id ? [] : disabledPublicRecipes.filter((item: any) => item.id === id));
    setDisabledRecipes(!id ? [] : disabledRecipes.filter((item: any) => item.id === id));
    setCompletedFields([]);
    setIsScrapingTab(stateChange.tab);
    setIsAutomationTab(!!stateChange.tab ? stateChange.tab : '1');
    setRecipeSelected(null);
    setPublicRecipeSelected(null);
    setRequiredAutomationListUsed([]);
    automationsByIO?.HexomaticAutomation?.getAutomations?.automations &&
      setAutomationsList(automationsByIO.HexomaticAutomation.getAutomations?.automations);
    recipesData?.HexomaticScrapingRecipe?.getScrapingRecipes?.scraping_recipes &&
      setRecipesList(recipesData?.HexomaticScrapingRecipe?.getScrapingRecipes?.scraping_recipes);
    publicRecipesData?.HexomaticPublicScrapingRecipe?.getPublicScrapingRecipes?.public_scraping_recipes &&
      setPublicRecipesList(
        publicRecipesData?.HexomaticPublicScrapingRecipe?.getPublicScrapingRecipes?.public_scraping_recipes,
      );
  };

  useEffect(() => {
    if (automationSendSettings && automationSendSettings.length < 1) return;
    const disabledAutomationsList: any = disabledAutmations ? [...disabledAutmations] : [];
    const automationsToDisable =
      automationsList && automationsList.filter((item: any) => item.id === 81 || item.id === 125 || item.id === 108);

    automationSendSettings &&
      automationSendSettings.length === 1 &&
      automationsToDisable &&
      automationsToDisable.map(
        (item: any) =>
          !disabledAutomationsList.find((sub: any) => sub.id === item.id) && disabledAutomationsList.push(item),
      );
    disabledAutomationsList && setDisabledAutmations(disabledAutomationsList);
  }, [automationSendSettings]);

  const isMobile = useMediaQuery({ maxWidth: 1200 });

  useEffect(() => {
    setToggle(!isMobile);
  }, [isMobile]);

  const handleRecommendedClick = (item: any) => {
    let info: any;
    let index: any;
    let fromFullList = false;
    automationsList.map((el: { id: number }, idx: number) => {
      if (el.id === item.id) {
        info = el;
        index = idx;
      }
    });
    if (!info) {
      automationsFullList.map((el: { id: number }, idx: number) => {
        if (el.id === item.id) {
          info = el;
          index = idx;
          fromFullList = true;
        }
      });
    }
    info && selectAutomationFromRightMenu(info, index, fromFullList ? automationsFullList : null);
  };

  //Close Popup on Esc button click
  useEffect(() => {
    (triggerWorkflow || customSchedule) &&
      closePopupOnEsc(() => {
        setTriggerWorkflow(false);
        setCustomSchedule(false);
      });
  }, [triggerWorkflow, customSchedule]);

  const CreditsInfo = () => (
    <>
      {currentWorkflow?.scan_times?.last_scan_time && (
        <div>
          <strong>Last run: </strong>
          {getDateAndTime(currentWorkflow?.scan_times?.last_scan_time)}
        </div>
      )}
      {currentWorkflow?.scan_times?.next_scan_time &&
        new Date(currentWorkflow?.scan_times?.next_scan_time).getTime() > new Date().getTime() && (
          <div className="l_h_19">
            <strong>Next scheduled run:</strong> {getDateAndTime(currentWorkflow?.scan_times?.next_scan_time)}
          </div>
        )}
      {trigger && trigger.watchPropertyId && (
        <div className="l_h_19">
          <strong>Next scheduled run:</strong> Triggered via Hexowatch
        </div>
      )}
      {trigger && trigger.watchPropertyId && hexowatchProperty && (
        <div className="l_h_19" style={{ overflowWrap: 'break-word' }}>
          <strong>Hexowatch property name: </strong> {hexowatchProperty}
        </div>
      )}
      {currentWorkflow?.scan_times?.next_scan_time &&
        (currentWorkflow?.schedule_config?.selected || selectedSheduleOption) && (
          <div className="l_h_19">
            <strong>Running interval:</strong>{' '}
            {currentWorkflow?.schedule_config?.selected ? (
              <>
                <>{currentWorkflow?.schedule_config?.selected}</>
                {currentWorkflow.schedule_config.weekdays?.length > 0 &&
                  currentWorkflow.schedule_config.weekdays.map((day: number, index: number) => (
                    <>
                      {!index && ' on '}
                      {getWeekDay(day -1)}
                      {index === currentWorkflow.schedule_config.weekdays.length - 1 ? '.' : ', '}
                    </>
                  ))}
              </>
            ) : (
              selectedSheduleOption
            )}
          </div>
        )}
      {currentWorkflow?.storage ? (
        <div>
          <strong>Storage used: </strong> {(currentWorkflow.storage / (1000 * 1000)).toFixed(2) + ' MB'}
        </div>
      ) : (
        <div>
          <strong>Storage used: </strong> 0
        </div>
      )}

      {currentWorkflow?.credit ? (
        <div className="py-0">
          <div className="d-flex p-0">
            <div className="d-flex px-0 py-1" style={{ width: 'calc(100% - 15px)' }}>
              <strong className="mr-1">Automation credits used:</strong>
              {+currentWorkflow?.credit.toFixed(0)}
            </div>
            <div
              className={`link ml-1 py-1 ${creditUsagePerAutom.length && creditsDetails ? 'open' : ''}`}
              onClick={() => setCreditsDetails(!creditsDetails)}
            >
              {leftArrow}
            </div>
          </div>
          {creditUsagePerAutom.length && creditsDetails ? (
            <div className="automations-usage-container">
              {creditUsagePerAutom.map((el: { name: string; count: number }, index: number) => (
                <div className={`automation-usage ${index + 1 === creditUsagePerAutom.length ? 'border-0' : ''}`}>
                  <div className="auto-name text-truncate">
                    <strong>{el.name}:</strong>
                  </div>
                  <div className="auto-value">{+el.count.toFixed(0)}</div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      ) : (
        <div className="d-flex">
          <strong className="mr-1">Automation credits used:</strong> 0
        </div>
      )}
      {currentWorkflow?.premiumCredit ? (
        <div className="pl-0 py-0">
          <div className="d-flex p-0">
            <div className="d-flex py-1" style={{ width: 'calc(100% - 15px)' }}>
              <strong className="mr-1">Premium credits used: </strong> {+currentWorkflow?.premiumCredit.toFixed(3)}
            </div>
            <div
              className={`link ml-1 ${premiumCreditUsagePerAutom.length && premiumCreditsDetails ? 'open' : ''}`}
              onClick={() => setPremiumCreditsDetails(!premiumCreditsDetails)}
            >
              {leftArrow}
            </div>
          </div>
          {premiumCreditUsagePerAutom.length && premiumCreditsDetails ? (
            <div className="automations-usage-container">
              {premiumCreditUsagePerAutom.map((el: { name: string; count: number }, index: number) => (
                <div
                  className={`automation-usage ${index + 1 === premiumCreditUsagePerAutom.length ? 'border-0' : ''}`}
                >
                  <div className="auto-name text-truncate">
                    <strong>{el.name}:</strong>
                  </div>
                  <div className="auto-value">{+el.count.toFixed(3)}</div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      ) : (
        <div className="d-flex">
          <strong className="mr-1">Premium credits used: </strong> 0
        </div>
      )}
    </>
  );

  const handleChangeIsDirty = useCallback(() => {
    !isDirty && setIsDirty(true);
  }, [isDirty]);

  const handleFilterAutomationsBySourceType = (list: any) => {
    const outputData =
      automationSendSettings &&
      automationSendSettings[automationSendSettings.length - 1] &&
      automationSendSettings[automationSendSettings.length - 1].output_keys_data_with_types;
    const parsedOutputData = outputData && typeof outputData === 'string' ? JSON.parse(outputData) : outputData;
    const parsedValues = parsedOutputData && Object.values(parsedOutputData);

    const filteredList: any = [];
    if (parsedOutputData) {
      list.map((item: any) => {
        const acceptableInput = item.actions ? ['url'] : item.inputs && item.inputs.split(', ');
        if (
          (acceptableInput && acceptableInput.includes('any')) ||
          (parsedValues && parsedValues.includes('any'))
          // item.id !== 125 &&
          // item.id !== 81 &&
          // item.id !== 108
        ) {
          filteredList.push(item);
        } else if (
          acceptableInput &&
          acceptableInput.find((sub: any) => parsedValues.includes(sub))
          // item.id !== 125 &&
          // item.id !== 81 &&
          // item.id !== 108
        ) {
          filteredList.push(item);
        }
      });
    }
    const finList = parsedValues && filteredList ? filteredList : list;
    const chatGPTs = finList.filter(
      (item: any) =>
        item.id === 153 ||
        item.id === 152 ||
        item.id === 143 ||
        item.id === 144 ||
        item.id === 145 ||
        item.id === 146 ||
        item.id === 147 ||
        item.id === 149 ||
        item.id === 150,
    );
    const withoutChatGPT = finList.filter(
      (item: any) =>
        item.id !== 143 &&
        item.id !== 144 &&
        item.id !== 145 &&
        item.id !== 146 &&
        item.id !== 147 &&
        item.id !== 149 &&
        item.id !== 150 &&
        item.id !== 152 &&
        item.id !== 153,
    );
    const finalList = chatGPTs.length && withoutChatGPT.length ? [...chatGPTs, ...withoutChatGPT] : finList;
    finalList.sort((item1: any, item2: any) => item2.isFavorite - item1.isFavorite);
    return finalList;
  };

  const onCancelUpgradeModal = () => {
    setShouldUpgrade(false);
    hexomaticUser && hexomaticUser.pricing_package === 'FREE' && history.push('/workflows');
  };

  const handleMarkAsStarred = async (id: number, type: string) => {
    handleToastShow(true, '', `Adding into starred ${type === 'AUTOMATION' ? 'automations' : 'recipes'} list...`, '');
    const data = await markAsStarred({
      variables: {
        settings: {
          type: type,
          favorite_item_ids: [id],
        },
      },
    });
    const { error } = data?.data?.FavoriteOpts?.addToFavorites;
    if (error) {
      handleToastShow(true, 'Something went wrong. Please try again.', '', '');
    } else {
      if (type === 'AUTOMATION') {
        getAutomationList();
      } else {
        getRecipes({
          variables: variablesForFetchRecipes(inputValue),
        });
        getRecipesList({
          variables: variablesForFetchRecipes(automationSearch),
        });
      }
      handleToastShow(
        true,
        '',
        '',
        `Successfully added into starred ${type === 'AUTOMATION' ? 'automations' : 'recipes'} list.`,
      );
    }
    hideToast();
  };

  const handleRemoveFromStarred = async (id: number, type: string) => {
    handleToastShow(true, '', `Removing from starred ${type === 'AUTOMATION' ? 'automations' : 'recipes'} list...`, '');
    const data = await deleteFromStarred({
      variables: {
        type: type,
        favorite_item_ids: [id],
      },
    });
    const { error } = data?.data?.FavoriteOpts?.deleteFromFavorites;
    if (error) {
      handleToastShow(true, 'Something went wrong. Please try again.', '', '');
    } else {
      if (type === 'AUTOMATION') {
        getAutomationList();
      } else {
        getRecipes({
          variables: variablesForFetchRecipes(inputValue),
        });
        getRecipesList({
          variables: variablesForFetchRecipes(''),
        });
      }
      handleToastShow(
        true,
        '',
        '',
        `Successfully removed from starred ${type === 'AUTOMATION' ? 'automations' : 'recipes'} list.`,
      );
    }
    hideToast();
  };

  const hideToast = () => {
    setTimeout(() => {
      handleToastShow(false, '', '', '');
    }, 5000);
  };

  // console.log('automationSendSettings', automationSendSettings);
  // console.log('selected', selectedAutomations);
  // console.log('completedFields', completedFields);

  return (
    <div
      style={{ paddingBottom: selectedAutomations.length > 0 ? '220px' : '0px' }}
      onClick={() => isMobile && toggle && setToggle(false)}
    >
      <Prompt
        when={isDirty}
        message={(location, action) => {
          setModalOpenedcloseEdit({
            show: true,
            location: location.pathname.includes('current-workflow')
              ? modalOpenedcloseEdit.location || '/workflows'
              : location.pathname + (location.search || ''),
          });
          setIsDirty(false);
          return false;
        }}
      />
      <DragDropContext
        onDragEnd={onDrag}
        onDragStart={() => {
          showSearch.index !== null && setShowSearch({ show: false, index: null });
        }}
      >
        <RightMenu
          isScrapingTab={isScrapingTab === '2' || !!recipeSelected}
          isPublicScrapingTab={isScrapingTab === '3' || !!publicRecipeSelected}
          handleAutomationSearch={val => onChangeRightMenuTimeout(val)}
          resetTab={isNew}
          loading={publicRecipesListLoading || recipesListLoading || loadingAutomationsByIO}
          paddingBottom={selectedAutomations.length > 0}
          automations={handleFilterAutomationsBySourceType(automationsList).map((item: any) => {
            if (!item) return;
            return {
              title: item.name,
              icon: item.id === 34 && theme === 'dark' ? deeplLight : item.icon_url,
              fullInfo: item,
            };
          })}
          recipes={recipesList.map((item: any) => {
            return { title: item.name, icon: recipeIcon, fullInfo: item };
          })}
          publicRecipes={
            hexomaticUser.pricing_package &&
            (hexomaticUser.pricing_package === 'GOLD' ||
              hexomaticUser.pricing_package === 'SILVER' ||
              hexomaticUser.pricing_package === 'BRONZE')
              ? publicRecipesList.map((item: any) => {
                  return { title: item.name, icon: recipeIcon, fullInfo: item };
                })
              : []
          }
          onClick={(fullInfo: any, index: number) => selectAutomationFromRightMenu(fullInfo, index)}
          onChangeTabs={val => setIsAutomationTab(val)}
          inputValue={automationSearch}
          fields={{
            lists: types,
            active: activeType,
            loading: typesLoading,
            onChange: val => typeof val == 'number' && setActiveType(val),
          }}
          toggle={toggle}
          setToggle={(val: boolean) => setToggle(val)}
          handleMarkAsStarred={handleMarkAsStarred}
          handleRemoveFromStarred={handleRemoveFromStarred}
        />
        <div className="workflow-header">
          <div className="header-top">
            {workflowName ? (
              <>
                <EditableTextOpen
                  name={workflowName || ''}
                  id={editbleTextId}
                  onSave={async ({ id, text }: any) => {
                    return await workflowNameUpdate({ id, name: text });
                  }}
                  maxTextCount={64}
                  absoluteError={true}
                  errorMessageLength={50}
                  existingWorkflow={isCreatedWorkFlow}
                />
                {selectedTags.length <= 9 ? (
                  <span className="edit-icon tag" onClick={addTag}>
                    {tagIconNew}
                  </span>
                ) : (
                  <Tooltip
                    text="You've reached tags limit per workflow"
                    placement="bottom"
                    className="d-flex align-items-center"
                  >
                    <span className="edit-icon tag">{tagIconNew}</span>
                  </Tooltip>
                )}
                {isCreatedWorkFlow && (
                  <span
                    className="edit-icon tag share-workflow"
                    onClick={() => {
                      if (hexomaticUser?.pricing_package === 'FREE') {
                        setShouldUpgrade(true);
                      } else setShareModal(true);
                    }}
                  >
                    {shareIcon}
                  </span>
                )}
              </>
            ) : (
              <div style={{ width: '1px', height: '33px' }}></div>
            )}
            {workflowName && workflowId && !isPathnameTemplate && (
              <div className="d-flex justify-content-between workflow-details ml-1">
                <Tooltip
                  placement="bottom"
                  trigger={['click']}
                  content={{
                    title: 'Workflow details',
                    text: <CreditsInfo />,
                  }}
                >
                  <span className="info-icon">{infoIcon}</span>
                </Tooltip>
              </div>
            )}
          </div>
          <div className="header-bottom" style={{ maxWidth: '100%' }}>
            {selectedTags && selectedTags.length > 0 && (
              <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '100%' }}>
                {selectedTags.map((item: any) => {
                  return (
                    <div key={item.name} className="mb-2" style={{ maxWidth: '100%' }}>
                      <Tag
                        name={item.name}
                        color={item.color}
                        bgColor={hexToRgbA(item.color, 0.1)}
                        onClick={() => {
                          handleTagDelete(item.id);
                        }}
                        onTagClick={() => {
                          setShowTagModal(true);
                          setSelectedTag(item);
                        }}
                        settings
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        {workflowId && !isPathnameTemplate && (
          <Tabs
            loading={!workflowData?.HexomaticWorkflow?.getWorkflow?.workflow?.execution_data}
            status={workflowData?.HexomaticWorkflow?.getWorkflow?.workflow?.execution_data}
            selectedHeaders={selectedHeaders}
            setSelectedHeaders={(val: any) => {
              setSelectedHeaders(val);
              setSelectedEmailFields(val || []);
              if (val?.length) {
                setExportOption({ label: 'Custom', value: 'custom' });
              } else {
                setExportOption({ label: 'All', value: 'all' });
              }
            }}
            workflowId={+workflowId}
          />
        )}
        {workflowId && !isPathnameTemplate && loading && !zapier ? (
          <p className="loader-wrapper">
            <Loader light={theme === 'light'} />{' '}
          </p>
        ) : (
          <div className="workflow-logs-conatiner">
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={getListStyle(snapshot.isDraggingOver, !selectedAutomations.length)}
                >
                  {[
                    ...(selectedAutomations?.length ? selectedAutomations : [{ id: 'id', createNewClicked: true }]),
                  ].map((item, index) => (
                    <Draggable
                      key={(index + 1).toString()}
                      draggableId={(index + 1).toString()}
                      index={index}
                      isDragDisabled={!allowedDragSettings.includes(item?.recipe ? item?.recipe?.id : item?.id)}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <SelectedAutomation
                            value={item}
                            index={index}
                            theme={theme}
                            showAutomationSearch={showAutomationSearch}
                            onSelectAutomation={selectAutomation}
                            deleteAutomatinoOrRecipe={removeAutomationOrRecipe}
                            getShow={getShow}
                            lastUpdatedAutomations={lastUpdatedAutomations}
                            equalSelectedAutomationAndSettings={equalSelectedAutomationAndSettings}
                            extractedRecipesFromSelectedAutomations={extractedRecipesFromSelectedAutomations}
                            deeplLight={deeplLight}
                            automationSendSettings={automationSendSettings}
                            setCompletedFields={checkAutomationFields}
                            setAutomationSendSettings={handleSetAutomationSettings}
                            resetLastUpdatedAutomations={handleResetLastUpdatedAutomations}
                            onChangeTimeout={onChangeTimeout}
                            inputValue={inputValue}
                            isAutomationTab={isAutomationTab}
                            elRef={elRef}
                            automationsForInput={handleFilterAutomationsBySourceType(automationsForInput)}
                            recipesForInput={handleFilterAutomationsBySourceType(recipesForInput)}
                            publicRecipesForInput={handleFilterAutomationsBySourceType(publicRecipesForInput)}
                            loadingAutomationsInput={loadingAutomationsInput || recipesLoading || publicRecipesLoading}
                            showSearch={showSearch}
                            noAddIcon={showSearch.index === selectedAutomations.length - 1 || item.createNewClicked}
                            showDeletedRecipesInWorkflow={showDeletedRecipesInWorkflow}
                            setShowDeletedRecipesInWorkflow={setShowDeletedRecipesInWorkflow}
                            errorMessage={errorMessage}
                            isSettingsChange={isSettingsChange}
                            forZapier={{
                              id: zapier || +workflowId,
                              saveAsDraft: () => handleWorkflowCreate('', '', true, true),
                              updateWorkflow: () => handleWorkflowUpdate(undefined, undefined, undefined, true),
                            }}
                            setErrorMessage={setErrorMessage}
                            setIsDirty={handleChangeIsDirty}
                            automationOutputTypes={item.outputs}
                            automationInputTypes={item.inputs}
                            last={selectedAutomations.length - 1 === index}
                            workflowId={workflowId}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        )}
      </DragDropContext>
      {selectedAutomations.length > 0 && (
        <Row className="mb-4">
          {errorMessage && (
            <Col md={12}>
              <Alert type="error" className="create-workflow">
                {errorMessage}
              </Alert>
            </Col>
          )}
        </Row>
      )}
      {(!showSearch || !showSearch.show) &&
        selectedAutomations &&
        selectedAutomations.length > 0 &&
        recommendedList &&
        recommendedList.length > 0 && (
          <RecommendedAutomations handleClick={handleRecommendedClick} list={recommendedList} />
        )}
      {selectedAutomations.length === 0 ? (
        <RecommendedAutomations
          handleClick={handleRecommendedClick}
          list={[
            {
              iconUrl: 'https://storage.googleapis.com/hexomatic-automation-icons/data-input.svg',
              id: 47,
              name: 'Data input',
              type: 'Built-in automations',
            },
            {
              iconUrl: 'https://storage.googleapis.com/hexomatic-automation-icons/google-maps.svg',
              id: 31,
              name: 'Google Maps',
              type: 'Premium automations',
            },
            {
              iconUrl: 'https://storage.googleapis.com/hexomatic-automation-icons/google-search.png',
              id: 30,
              name: 'Google Search',
              type: 'Premium automations',
            },
          ]}
          blank
        />
      ) : null}

      <Row className="mb-4">
        {generalErrorMessage && (
          <Col md={12}>
            <Alert type="error" className="create-workflow">
              {generalErrorMessage}
            </Alert>
          </Col>
        )}
      </Row>

      {selectedAutomations.length > 0 && (
        <StickyFooter handleCancel={handleFooterCancel} handleSave={handleFooterSave} handleUpdate={handleFooterUpdate}>
          <div className="workflow-footer-part d-flex align-items-center ">
            <div className="pt-2 pb-2 workflow-textarea-wrapper">
              <TextArea
                value={comment}
                rows={1}
                placeholder="Workflow description..."
                disableResize={true}
                onChange={(e: { target: { value: any } }) => {
                  const value = e.target.value;
                  if (value.length > 300) {
                    return;
                  }
                  setComment(e.target.value);
                }}
              />
            </div>
            <div className="pt-2 pb-2 sticky-footer-buttons">
              {/* <Button disabled={false} name="Back to workflows" outline onClick={handleFooterCancel} /> */}
              {workflowId && !isPathnameTemplate && handleFooterUpdate && (
                <Button disabled={false} name={'Update'} onClick={handleFooterUpdate} />
              )}
              {!(workflowId && !isPathnameTemplate) && (
                <Button
                  disabled={false}
                  name={'Save as draft'}
                  onClick={() => handleWorkflowCreate('', '', true)}
                  outline
                />
              )}
              <Button
                disabled={false}
                name={workflowId && !isPathnameTemplate ? 'Update and Run' : 'Continue'}
                onClick={handleFooterSave}
              />
            </div>
          </div>
        </StickyFooter>
      )}

      <Modal
        isModalOpen={modalOpenedcloseEdit.show}
        text={'Are you sure you want to leave?'}
        title={"Changes you've made will be lost if you exit now."}
        actionBtnText={'Leave'}
        cancelBtnText={'Stay'}
        actionBtnColor={'danger'}
        cancelBtnColor={'secondary'}
        onActionClick={() => {
          handleLeavePage();
          setModalOpenedcloseEdit({ show: false, location: null });
          pathname !== modalOpenedcloseEdit.location && history.push(modalOpenedcloseEdit.location);
          reset();
        }}
        onCancelClick={() => {
          setIsDirty(true);
          setModalOpenedcloseEdit({ show: false, location: null });
        }}
        escButtonClick={() => {
          setIsDirty(true);
          setModalOpenedcloseEdit({ show: false, location: null });
        }}
      />
      <Modal
        isModalOpen={isOpen}
        text=""
        title=""
        actionBtnText={
          runNow && (createWorkflowLoading || updateWorkflowLoading) ? (
            <Loader light={theme === 'light'} className="p-0" />
          ) : (
            'Run Now'
          )
        }
        cancelBtnText="Schedule start time/date"
        cancelBtnText2="Trigger via Hexowatch"
        actionBtnColor="success"
        cancelBtnColor="success"
        cancelBtnColor2="success"
        onActionClick={() => {
          if (hexomaticUser && !hexomaticUser.left_automation_credit) {
            setIsOpen(false);
            setShouldUpgrade(true);
            return;
          }
          if (
            user?.left_premium_credits === 0 &&
            hexomaticUser?.left_premium_automation_credit === 0 &&
            selectedAutomations.find(
              (item: { type: { id: number }; id: number }) => item?.type?.id === 5,
              // && item?.id !== 143
            )
          ) {
            setIsOpen(false);
            setShouldUpgrade(true);
            hexomaticUser?.pricing_package !== 'FREE' ? setBuyPremiumCredits(true) : setBuyPremiumCredits(false);
            return;
          }
          if (workflowId && !isPathnameTemplate && currentWorkflowStatus !== 'DRAFT') {
            setShouldContinue(true);
            setScheduleSettings(null);
            setCustomSchedule(false);
            setTriggerWorkflow(false);
            setIsOpen(false);
          } else {
            setRunNow(true);
            (workflowId && !isPathnameTemplate) || zapier
              ? handleWorkflowUpdate(new Date().toISOString(), '', undefined, undefined, undefined, undefined, true)
              : handleWorkflowCreate(new Date().toISOString(), '');
          }
        }}
        onCancelClick={() => {
          if (hexomaticUser?.pricing_package === 'FREE' || hexomaticUser?.pricing_package === 'BRONZE') {
            setShouldUpgrade(true);
            setIsOpen(false);
          } else {
            setCustomSchedule(true);
            setIsOpen(false);
          }
        }}
        onCancelClick2={() => {
          setTriggerWorkflow(true);
          setIsOpen(false);
        }}
        onCloseModal={() => setIsOpen(false)}
        escButtonClick={() => setIsOpen(false)}
        onlyBtns
        actionBtnDisabled={!runNow && (updateWorkflowLoading || createWorkflowLoading)}
        cancelBtnDisabled={updateWorkflowLoading || createWorkflowLoading}
        cancelBtnDisabled2={updateWorkflowLoading || createWorkflowLoading}
        topInfo={
          <div className="mb-3 pb-3" style={{ borderBottom: '1px grey dashed' }}>
            {disableEmail ? (
              <Tooltip
                trigger={['hover']}
                placement="bottom"
                text="You do not have any verified and enabled emails. Check your Settings page."
                className="mb-1"
              >
                <div>
                  <Checkbox
                    checked={disableEmail ? false : enabledNotification}
                    labelRight="Send email when a workflow completes or fails"
                    onChange={() => {
                      setEnabledNotification(!enabledNotification);
                    }}
                    className={`justify-content-center ${disableEmail ? 'disabled-checkbox' : ''}`}
                    disabled={!!(+updateWorkflowLoading + +createWorkflowLoading) || disableEmail}
                  />
                </div>
              </Tooltip>
            ) : (
              <div>
                <Checkbox
                  checked={disableEmail ? false : enabledNotification}
                  labelRight="Send email when a workflow completes or fails"
                  onChange={() => {
                    setEnabledNotification(!enabledNotification);
                  }}
                  className={`justify-content-center ${disableEmail ? 'disabled-checkbox' : ''}`}
                  disabled={!!(+updateWorkflowLoading + +createWorkflowLoading) || disableEmail}
                />
              </div>
            )}
            {enabledNotification && !disableEmail && (
              <div className="email-custom-fields-container">
                <span className="select-label primary-color">
                  Select columns to include in the results file:
                  <Tooltip
                    trigger={['hover']}
                    placement="bottom"
                    text="This enables you to select which columns to include when exporting the results of your workflow."
                    className="reload-icon-tooltip mb-1"
                  >
                    <span className="reload-icon">{infoIcon}</span>
                  </Tooltip>
                </span>
                <Select
                  options={exportOptionsArr}
                  value={exportOption}
                  placeholder={'Select source'}
                  onChange={(selectedItem: { label: string; value: string }) => {
                    if (selectedItem.value === 'all') {
                      setSelectedEmailFields(emailFields.map((item: any) => item.value));
                    }
                    setExportOption(selectedItem);
                  }}
                />
                {exportOption.value === 'custom' && (
                  <div className="export-option-checkbox-wrapper">
                    {emailFields.map((el: { label: string; value: string }) => (
                      <Checkbox
                        // hoverText={el[0]}
                        key={el.value}
                        checked={selectedEmailFields.includes(el.value)}
                        labelRight={el.value}
                        onChange={() => {
                          if (selectedEmailFields.includes(el.value)) {
                            const data = selectedEmailFields.filter((item: string) => item !== el.value);
                            setSelectedEmailFields(data);
                          } else {
                            setSelectedEmailFields([...selectedEmailFields, el.value]);
                          }
                        }}
                      />
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        }
      >
        {(!workflowId || isPathnameTemplate) && (
          <>
            <Button
              name={
                !runNow && (createWorkflowLoading || updateWorkflowLoading) ? (
                  <Loader light={theme === 'light'} className="p-0" />
                ) : (
                  'Save as draft'
                )
              }
              outline
              onClick={() =>
                workflowId && !isPathnameTemplate
                  ? handleWorkflowUpdate('', '', true)
                  : handleWorkflowCreate('', '', true)
              }
              className="mx-auto mb-2 modal-long-btn"
              disabled={+updateWorkflowLoading + +createWorkflowLoading + +runNow === 2}
            />
          </>
        )}
      </Modal>
      {customSchedule ? (
        <ModalCalendar
          isModalOpen={customSchedule}
          text="Schedule workflow"
          actionBtnText="Proceed"
          cancelBtnText="Cancel"
          setSelectedSheduleOption={setSelectedSheduleOption}
          propTrigger={trigger}
          isScheduled
          startedAt={startedAt}
          onActionClick={(
            startDate?: string,
            frequency?: string,
            schedule_config?: ScheduleConfigType,
            trigger?: string,
          ) => {
            let premiumAutoUsed = !!selectedAutomations.find(
              (item: { type: { name: string }; id: number }) => item?.type?.name === 'Premium automations',
              // && item?.id !== 143,
            );
            if (
              premiumAutoUsed &&
              user?.left_premium_credits === 0 &&
              hexomaticUser?.left_premium_automation_credit === 0
            ) {
              setShouldUpgrade(true);
              hexomaticUser?.pricing_package !== 'FREE' ? setBuyPremiumCredits(true) : setBuyPremiumCredits(false);
              setCustomSchedule(false);
              return;
            }
            if (workflowId && !isPathnameTemplate && currentWorkflowStatus !== 'DRAFT') {
              setShouldContinue(true);
              setScheduleSettings({ startDate: startDate, frequency: frequency, schedule_config: schedule_config });
              setCustomSchedule(false);
              setIsOpen(false);
            } else {
              (workflowId === undefined || isPathnameTemplate) && !zapier
                ? handleWorkflowCreate(startDate, frequency, undefined, undefined, schedule_config, trigger)
                : handleWorkflowUpdate(startDate, frequency, undefined, undefined, schedule_config, trigger, true);
            }
          }}
          onCloseModal={() => setCustomSchedule(false)}
          onCancelClick={() => {
            setCustomSchedule(false);
            setIsOpen(true);
          }}
          schedule_config={currentWorkflow && currentWorkflow.schedule_config ? currentWorkflow.schedule_config : null}
          loading={createWorkflowLoading || updateWorkflowLoading}
          frequency={workflowData?.HexomaticWorkflow?.getWorkflow?.workflow?.frequency}
          isNew={!workflowId}
        />
      ) : (
        <ModalCalendar
          isModalOpen={triggerWorkflow}
          text="Trigger via Hexowatch"
          actionBtnText="Proceed"
          cancelBtnText="Cancel"
          setSelectedSheduleOption={setSelectedSheduleOption}
          propTrigger={trigger}
          isScheduled={false}
          startedAt={startedAt}
          onActionClick={(
            startDate?: string,
            frequency?: string,
            schedule_config?: ScheduleConfigType,
            trigger?: string,
          ) => {
            let premiumAutoUsed = !!selectedAutomations.find(
              (item: { type: { name: string }; id: number }) => item?.type?.name === 'Premium automations',
              // && item?.id !== 143,
            );
            if (
              premiumAutoUsed &&
              user?.left_premium_credits === 0 &&
              hexomaticUser?.left_premium_automation_credit === 0
            ) {
              setShouldUpgrade(true);
              hexomaticUser?.pricing_package !== 'FREE' ? setBuyPremiumCredits(true) : setBuyPremiumCredits(false);
              setTriggerWorkflow(false);
              return;
            }
            if (workflowId && !isPathnameTemplate && currentWorkflowStatus !== 'DRAFT') {
              setShouldContinue(true);
              setScheduleSettings({ startDate: startDate, frequency: frequency, schedule_config: schedule_config });
              setTriggerWorkflow(false);
              trigger && setLocalTrigger(trigger);
              setIsOpen(false);
            } else {
              (workflowId === undefined || isPathnameTemplate) && !zapier
                ? handleWorkflowCreate(startDate, frequency, undefined, undefined, schedule_config, trigger)
                : handleWorkflowUpdate(startDate, frequency, undefined, undefined, schedule_config, trigger, true);
            }
          }}
          onCloseModal={() => setTriggerWorkflow(false)}
          onCancelClick={() => {
            setTriggerWorkflow(false);
            setIsOpen(true);
          }}
          schedule_config={currentWorkflow && currentWorkflow.schedule_config ? currentWorkflow.schedule_config : null}
          loading={createWorkflowLoading || updateWorkflowLoading}
          frequency={workflowData?.HexomaticWorkflow?.getWorkflow?.workflow?.frequency}
          isNew={!workflowId}
        />
      )}
      <Modal
        isModalOpen={shouldContinue}
        text={
          workflowData?.HexomaticWorkflow?.getWorkflow?.workflow?.execution_data === 'progress'
            ? 'The workflow is currently running, this will restart the workflow losing existing data and credits used so far for it.'
            : 'The workflow will restart running. You can download the previously generated data in the History section.'
        }
        actionBtnText="Continue anyway"
        cancelBtnText="Cancel"
        actionBtnColor="warning"
        cancelBtnColor="secondary"
        onActionClick={() => {
          if (scheduleSettings) {
            let premiumAutoUsed = !!selectedAutomations.find(
              (item: { type: { name: string }; id: number }) => item?.type?.name === 'Premium automations',
              // && item?.id !== 143,
            );
            if (
              premiumAutoUsed &&
              user?.left_premium_credits === 0 &&
              hexomaticUser?.left_premium_automation_credit === 0
            ) {
              setShouldContinue(false);
              setShouldUpgrade(true);
              hexomaticUser?.pricing_package !== 'FREE' ? setBuyPremiumCredits(true) : setBuyPremiumCredits(false);
              setCustomSchedule(false);
              setTriggerWorkflow(false);
            } else {
              handleWorkflowUpdate(
                scheduleSettings.startDate,
                scheduleSettings.frequency,
                undefined,
                undefined,
                scheduleSettings.schedule_config,
                localTrigger,
                true,
              );
            }
          } else {
            setRunNow(true);
            let premiumAutoUsed = !!selectedAutomations.find(
              (item: { type: { name: string }; id: number }) => item?.type?.name === 'Premium automations',
              // && item?.id !== 143,
            );
            if (
              premiumAutoUsed &&
              user?.left_premium_credits === 0 &&
              hexomaticUser?.left_premium_automation_credit === 0
            ) {
              setIsOpen(false);
              setShouldContinue(false);
              setShouldUpgrade(true);
              hexomaticUser?.pricing_package !== 'FREE' ? setBuyPremiumCredits(true) : setBuyPremiumCredits(false);
            } else {
              handleWorkflowUpdate(new Date().toISOString(), '', undefined, undefined, undefined, localTrigger, true);
            }
          }
          setShouldContinue(false);
        }}
        onCancelClick={() => {
          setIsOpen(false);
          setShouldContinue(false);
        }}
        escButtonClick={() => {
          setIsOpen(false);
          setShouldContinue(false);
        }}
      />
      <Modal
        isModalOpen={shouldUpgrade}
        title={
          !buyPremiumCredits ? (
            'Please upgrade your plan to continue'
          ) : user.premium_credits_paypal_subscription_id &&
            (user.premium_credits_pricing_package === 'PC_26400' ||
              user.premium_credits_pricing_package === 'PC_264000') ? (
            <ContactUs showCloseIcon handleCloseModal={onCancelUpgradeModal} />
          ) : (
            'Please add premium credits to continue'
          )
        }
        parentClass={
          user.premium_credits_paypal_subscription_id &&
          (user.premium_credits_pricing_package === 'PC_26400' ||
            user.premium_credits_pricing_package === 'PC_264000') &&
          buyPremiumCredits
            ? 'contact_us_modal'
            : ''
        }
        actionBtnText={
          user.premium_credits_paypal_subscription_id &&
          (user.premium_credits_pricing_package === 'PC_26400' ||
            user.premium_credits_pricing_package === 'PC_264000') &&
          buyPremiumCredits
            ? ''
            : 'Upgrade'
        }
        cancelBtnText={
          user.premium_credits_paypal_subscription_id &&
          (user.premium_credits_pricing_package === 'PC_26400' ||
            user.premium_credits_pricing_package === 'PC_264000') &&
          buyPremiumCredits
            ? ''
            : 'Cancel'
        }
        actionBtnColor="warning"
        cancelBtnColor="secondary"
        onActionClick={() => {
          if (buyPremiumCredits) {
            setShowPaymentModal(true);
            setShouldUpgrade(false);
          } else {
            setShouldUpgrade(false);
            window.location.href = 'https://hexomatic.com/pricing/';
          }
        }}
        onCancelClick={onCancelUpgradeModal}
        escButtonClick={onCancelUpgradeModal}
        onlyBtns={
          !!(
            buyPremiumCredits &&
            user.premium_credits_paypal_subscription_id &&
            (user.premium_credits_pricing_package === 'PC_26400' ||
              user.premium_credits_pricing_package === 'PC_264000')
          )
        }
      />
      <Modal
        isModalOpen={showUpgradeModalOnAutomationClick.show}
        title={
          showUpgradeModalOnAutomationClick.type === 'premiums-subscribe' ? (
            'To use premium automations please subscribe to our premium credit add-on.'
          ) : showUpgradeModalOnAutomationClick.type === 'premiums-upgrade' ? (
            user.premium_credits_paypal_subscription_id &&
            (user.premium_credits_pricing_package === 'PC_26400' ||
              user.premium_credits_pricing_package === 'PC_264000') ? (
              <ContactUs
                showCloseIcon
                handleCloseModal={() => {
                  setShouldUpgrade(false);
                  setShowUpgradeModalOnAutomationClick({ show: false, type: '' });
                }}
              />
            ) : (
              'You currently have 0 premium credits remaining please upgrade to proceed.'
            )
          ) : (
            'Premium automations require a Bronze or above plan.'
          )
        }
        parentClass={
          user.premium_credits_paypal_subscription_id &&
          showUpgradeModalOnAutomationClick.type === 'premiums-upgrade' &&
          (user.premium_credits_pricing_package === 'PC_26400' || user.premium_credits_pricing_package === 'PC_264000')
            ? 'contact_us_modal'
            : ''
        }
        actionBtnText={
          user.premium_credits_paypal_subscription_id &&
          showUpgradeModalOnAutomationClick.type === 'premiums-upgrade' &&
          (user.premium_credits_pricing_package === 'PC_26400' || user.premium_credits_pricing_package === 'PC_264000')
            ? ''
            : 'Upgrade'
        }
        cancelBtnText={
          user.premium_credits_paypal_subscription_id &&
          showUpgradeModalOnAutomationClick.type === 'premiums-upgrade' &&
          (user.premium_credits_pricing_package === 'PC_26400' || user.premium_credits_pricing_package === 'PC_264000')
            ? ''
            : 'Cancel'
        }
        actionBtnColor={'warning'}
        cancelBtnColor={'secondary'}
        onlyBtns={
          !!(
            showUpgradeModalOnAutomationClick.type === 'premiums-upgrade' &&
            user.premium_credits_paypal_subscription_id &&
            (user.premium_credits_pricing_package === 'PC_26400' ||
              user.premium_credits_pricing_package === 'PC_264000')
          )
        }
        onActionClick={() => {
          if (showUpgradeModalOnAutomationClick.type.includes('premiums')) {
            setShowPaymentModal(true);
            setShouldUpgrade(false);
            setShowUpgradeModalOnAutomationClick({ show: false, type: '' });
          } else {
            setShouldUpgrade(false);
            setShowUpgradeModalOnAutomationClick({ show: false, type: '' });
            window.location.href = 'https://hexomatic.com/pricing/';
          }
        }}
        onCancelClick={() => {
          setShouldUpgrade(false);
          setShowUpgradeModalOnAutomationClick({ show: false, type: '' });
        }}
        escButtonClick={() => {
          setShouldUpgrade(false);
          setShowUpgradeModalOnAutomationClick({ show: false, type: '' });
        }}
      />
      <Modal
        isModalOpen={requireDataInput}
        title={'This automation requires a source.'}
        actionBtnText={'Use Data Input'}
        cancelBtnText={'Cancel'}
        actionBtnColor={'warning'}
        cancelBtnColor={'secondary'}
        onActionClick={() => {
          const dataInputAutomation = automationsFullList
            .map((item: any, index: number) => item.id === 47 && { val: item, index: index })
            ?.filter((sub: any) => sub);
          dataInputAutomation &&
            dataInputAutomation[0] &&
            selectAutomationFromRightMenu(dataInputAutomation[0].val, dataInputAutomation[0].index);
          const disabledAutomationsList = disabledAutmations ? [...disabledAutmations] : [];
          //@ts-ignore
          dataInputAutomation && dataInputAutomation[0] && disabledAutomationsList.push(dataInputAutomation[0].val);
          disabledAutomationsList && setDisabledAutmations(disabledAutomationsList);
          setRequireDataInput(false);
        }}
        onCancelClick={() => setRequireDataInput(false)}
        escButtonClick={() => setRequireDataInput(false)}
      />
      <CustomModalTag
        title={'ADD TAG'}
        ShowPopup={showTagModal}
        setShowPopup={setShowTagModal}
        // longModal
        escButtonClick={setShowTagModal}
      >
        <TagsContent
          propSelectedTag={selectedTag}
          propSetSelectedTag={setSelectedTag}
          propSetSelectedTags={setSelectedTags}
          propSelectedTags={selectedTags}
          handleTagSelect={handleTagSelect}
          setShowTagModal={setShowTagModal}
          // propSetUserTagsList={setUserTagsList}
        />
      </CustomModalTag>
      {showPaymentModal && (
        <CustomModal
          ShowPopup={showPaymentModal}
          noNavigate
          escButtonClick={() => {
            setShowPaymentModal(false);
            setBuyPremiumCredits(false);
          }}
        >
          <Payment
            name={''}
            selectedValue={{ title: '105', value: 105 }}
            price={0}
            premium_credit
            setShowPopup={() => {
              setShowPaymentModal(false);
              setBuyPremiumCredits(false);
            }}
            // offer
          />
        </CustomModal>
      )}

      {shareModal && (
        <CustomModal escButtonClick={() => setShareModal(false)}>
          <ShareWorkflowModal
            setIsSuccessfullyShared={setIsCommonModalOpen}
            workflowId={+workflowId}
            onClose={() => setShareModal(false)}
          />
        </CustomModal>
      )}

      <Modal
        isModalOpen={isCommonModalOpen}
        text={commonModalMsg}
        actionBtnText="Ok"
        actionBtnColor={commonModalMsgStatus}
        cancelBtnColor="primary"
        onActionClick={() => setIsCommonModalOpen(false)}
        escButtonClick={() => setIsCommonModalOpen(false)}
      />
      <Modal
        isModalOpen={trafficInsightsWarning}
        text={
          automationSendSettings.find((item: any) => item.automation_id === 11)
            ? 'Twitter profile data automation can no longer be executed'
            : 'Traffic Insights automation can no longer be executed'
        }
        actionBtnText="Ok"
        actionBtnColor="warning"
        cancelBtnColor="primary"
        onActionClick={() => setTrafficInsightsWarning(false)}
        escButtonClick={() => setTrafficInsightsWarning(false)}
      />
      {toasterInfo.show && (
        <ToastLoader
          errorMessage={toasterInfo.errorMessage}
          loadingMessage={toasterInfo.loadingMessage}
          successMessage={toasterInfo.successMessage}
        />
      )}
    </div>
  );
};

export default AddNewWorkflow;
