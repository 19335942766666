import { ChangeEvent, FC, Fragment, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import DOMPurify from 'dompurify';

import { ONBOARDING_SURVEY } from 'graphql/mutations';

import Button from 'shared-components/atom/button';
import CustomSelect from 'shared-components/atom/select';
import Input from 'shared-components/atom/input';

import { closeIcon } from 'assets/svg-icons';
import './styles.scss';

interface PersonalizeExperienceProps {
  onClose: () => void;
  setIsSuccessfullySent: (arg: boolean) => void;
}

interface Question {
  question: string;
  answer: string;
  otherAnswer: string;
}

interface SelectOption {
  value: string;
  label: string;
}

const questionArr = [
  {
    question: 'I am a',
    data: [
      { label: 'Business owner', value: 'Business owner' },
      { label: 'Employee', value: 'Employee' },
      { label: 'Freelancer / Consultant', value: 'Freelancer / Consultant' },
      { label: 'Other', value: 'Other' },
    ],
  },
  {
    question: 'I work in',
    data: [
      { label: 'Sales', value: 'Sales' },
      { label: 'Marketing', value: 'Marketing' },
      { label: 'Research / Admin', value: 'Research / Admin' },
      { label: 'Other', value: 'Other' },
    ],
  },
  {
    question: 'What business are you in',
    data: [
      { label: 'Agency', value: 'Agency' },
      { label: 'SaaS', value: 'SaaS' },
      { label: 'eCommerce', value: 'eCommerce' },
      { label: 'Real estate', value: 'Real estate' },
      { label: 'Affiliate', value: 'Affiliate' },
      { label: 'Other', value: 'Other' },
    ],
  },
  {
    question: 'I am looking to',
    data: [
      { label: 'Scrape websites', value: 'Scrape websites' },
      { label: 'Find new leads', value: 'Find new leads' },
      { label: 'Enrich data', value: 'Enrich data' },
      { label: 'Create content', value: 'Create content' },
      { label: 'Automate tasks', value: 'Automate tasks' },
    ],
  },
];

const OTHER = 'Other';

const PersonalizeExperience: FC<PersonalizeExperienceProps> = ({ onClose, setIsSuccessfullySent }) => {
  const [errorMsg, setErrorMsg] = useState('');
  const [surveyArr, setSurveyArr] = useState<Question[]>(() =>
    questionArr.map(item => ({
      question: item.question,
      answer: '',
      otherAnswer: '',
    })),
  );

  const [createOnboardingSurvey, {loading}] = useMutation(ONBOARDING_SURVEY);

  const handleValidate = (): boolean => {
    const bool = surveyArr.every(item => {
      item.otherAnswer = item.otherAnswer.trim();
      return item.answer === OTHER ? item.otherAnswer : item.answer;
    });

    if (!bool) {
      setErrorMsg('Please answer all the questions.');
    }

    return bool;
  };

  const handleQuestionSelect = (
    item: {
      question: string;
      data: SelectOption[];
    },
    sub: SelectOption,
  ): void => {
    setSurveyArr(
      (prev: Question[]) =>
        prev.map(curr =>
          item.question === curr.question ? { ...curr, answer: sub.value, otherAnswer: '' } : curr,
        ) as Question[],
    );
    setErrorMsg('');
  };

  const handleOtherChange = (
    e: ChangeEvent<HTMLTextAreaElement>,
    item: {
      question: string;
      data: SelectOption[];
    },
  ): void => {
    const scriptRegex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
    const messageBodyWithoutScript = e.target.value.replace(scriptRegex, '');
    let sanitizedInput = DOMPurify.sanitize(messageBodyWithoutScript);

    if (sanitizedInput.length > 320) {
      sanitizedInput = sanitizedInput.slice(0, 320);
    }

    setSurveyArr(
      (prev: Question[]) =>
        prev.map(curr =>
          item.question === curr.question ? { ...curr, otherAnswer: sanitizedInput } : curr,
        ) as Question[],
    );
    setErrorMsg('');
  };

  const handleClick = async (): Promise<void> => {
    if (!handleValidate()) return;

    try {
      const { data } = await createOnboardingSurvey({
        variables: {
          settings: {
            product: 'hexomatic',
            skipped: false,
            answers: surveyArr.map(item => ({
              question: item.question,
              answer: item.answer === OTHER ? item.otherAnswer : item.answer,
            })),
          },
        },
      });

      if (data?.UserOps?.completeSurvey?.error_code) {
        onClose();
        return;
      }
      onClose();

      setIsSuccessfullySent(true);
    } catch (e: any) {
      console.log(e.message);
    }
  };

  const handleSkipSurvey = async (): Promise<void> => {
    try {
      await createOnboardingSurvey({
        variables: {
          settings: {
            product: 'hexomatic',
            skipped: true,
            answers: surveyArr.map(item => ({
              question: item.question,
              answer: 'N/A',
            })),
          },
        },
      });
    } catch (e: any) {
      console.log(e.message);
    } finally {
      onClose();
    }
  };

  return (
    <div className="personalize-experience-modal">
      <button className="panel__btn" onClick={handleSkipSurvey} type="button">
        {closeIcon}
      </button>
      <div className="feedback-header text-center mb-2">
        <h1>Let's personalize your Hexomatic experience</h1>
      </div>

      {errorMsg && <label className="feedback-err">{errorMsg}</label>}

      {questionArr.map(item => {
        const valueObj = surveyArr.find(itm => itm.question === item.question);

        return (
          <Fragment key={item.question}>
            <label className="label-select">{item.question}</label>
            <CustomSelect
              className="industry"
              options={item.data}
              value={valueObj?.answer}
              onChange={(selectedVal: SelectOption) => {
                handleQuestionSelect(item, selectedVal);
              }}
              placeholder="Select answer"
            />
            {valueObj?.answer === OTHER && (
              <Input
                maxLength={320}
                className="mt-1"
                placeholder="Type here..."
                value={valueObj?.otherAnswer}
                onChange={e => handleOtherChange(e, item)}
              />
            )}
          </Fragment>
        );
      })}

      <Button className="w-100 d-flex justify-content-center mt-4" name="Get Started" onClick={handleClick} disabled={loading} loading={loading} />

      <button className="skip-btn" onClick={handleSkipSurvey}>
        Skip
      </button>
    </div>
  );
};

export default PersonalizeExperience;
