import { FC, useCallback, useState } from 'react';

import Loader from 'shared-components/atom/loader';

import { closeIcon } from 'assets/svg-icons';
import './styles.scss';
import { useMediaQuery } from 'react-responsive';

interface VideoModalProps {
  setIsVideoModalOpen: (arg: boolean) => void;
  src: string;
}

const VideoModal: FC<VideoModalProps> = ({ setIsVideoModalOpen, src }) => {
  const [iframeLoading, setIframeLoading] = useState(true);
  const isTablet = useMediaQuery({ maxWidth: 992, minWidth: 586 });
  const isMobile = useMediaQuery({ maxWidth: 585 });

  const calcIframeDimensions = useCallback(() => {
    let width = '900px';
    let height = '500px';

    if (isTablet) {
      width = '550px';
      height = '300px';
    }
    if (isMobile) {
      width = '300px';
      height = '165px';
    }
    return {
      width,
      height,
    };
  }, []);

  return (
    <div className="video-wrapper">
      <div className="close-icon">
        <span onClick={() => setIsVideoModalOpen(false)}>{closeIcon}</span>
      </div>
      <div>
        {iframeLoading ? <Loader /> : null}
        <iframe
          onLoad={() => setIframeLoading(false)}
          src={src}
          width={calcIframeDimensions().width}
          height={calcIframeDimensions().height}
          allow="autoplay; fullscreen; picture-in-picture"
        />
      </div>
    </div>
  );
};

export default VideoModal;
