import { FC, memo } from 'react';
import Pagination from '../pagination';
import Select from 'shared-components/atom/select';
import './styles.scss';

interface TablePaginationTypeProps {
  count: number;
  limit: { value: number; label: string | number };
  page: number;
  setPage: any;
  limitOptions: any;
  handleLimitChange: any;
  showPagination: boolean;
}

const TablePagination: FC<TablePaginationTypeProps> = ({
  count,
  limit,
  page,
  setPage,
  limitOptions,
  handleLimitChange,
  showPagination,
}) => {
  return (
    <div className="mr-2 my-3 row rows-count d-block d-md-flex position-relative">
      <div className="m-auto d-flex justify-content-center">
        {showPagination ? (
          <Pagination
            activePage={page}
            onChange={setPage}
            totalCount={Math.ceil(count / limit.value)}
            itemsPerPage={1}
            className={Math.ceil(count / limit.value) > 1 ? 'visible' : 'invisible'}
          />
        ) : null}
      </div>

      <div className="d-flex rows-per-page-container">
        <p className="label">Rows per page:</p>
        <Select
          value={limit}
          options={limitOptions}
          onChange={handleLimitChange}
          width={80}
          noTextTransform
          className="rows-per-page"
        />
      </div>
    </div>
  );
};

export default memo(TablePagination);
