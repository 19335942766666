import { FC, useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { RESEND_VERIFICATION_EMAIL, SCHEDULE_USER_TO_DELETE } from 'graphql/mutations';
import { USER_QUERY } from 'graphql/queries';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Loader from 'shared-components/atom/loader';
import Alert from 'shared-components/molecule/alert/index';

import './layout.scss';
import { useContext } from 'react';
import { ThemeContext } from 'contexts/theme-context';
import { UserContext } from 'contexts/user-context';
import { HexomaticUserContext } from 'contexts/hexomatic-user';

interface IHeaderAlert {}

const HeaderAlert: FC<IHeaderAlert> = ({}, props) => {
  const [showAlert, setShowAlert] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [userData, setUserData] = useState<any>(null);
  const [refetchLoading, setRefetchLoading] = useState(false);
  const { theme } = useContext(ThemeContext);
  const [shouldBeDeletedAt, setShouldBeDeletedAt] = useState('');
  const [isAlert, setIsAlert] = useState(false);
  const { user } = useContext(UserContext);
  const { hexomaticUser } = useContext(HexomaticUserContext);

  const [pricingPackage, setPricingPackage] = useState('');

  const { data: userInfo, loading: userDataLoading, refetch } = useQuery(USER_QUERY);
  const [receiveEmail, { loading }] = useMutation(RESEND_VERIFICATION_EMAIL, {
    onCompleted: (data: any) => handleConfirm(data),
  });
  const [cancelMutation, { loading: deleteLoading }] = useMutation(SCHEDULE_USER_TO_DELETE);

  useEffect(() => {
    if (userInfo?.User?.get) {
      setUserData(userInfo.User.get);
    }

    if (userInfo && userInfo.User && userInfo.User.get) {
      let shouldBeDeletedAt = userInfo.User.get.shouldBeDeletedAt;
      setShouldBeDeletedAt(shouldBeDeletedAt);
      setIsAlert(shouldBeDeletedAt);
    }
  }, [userInfo]);

  useEffect(() => {
    hexomaticUser && hexomaticUser.pricing_package && setPricingPackage(hexomaticUser.pricing_package);
  }, [hexomaticUser]);

  const handleConfirm = async (data: any): Promise<void> => {
    const { alert } = props;
    if (data.UserOps.resendVerification && !data.UserOps.resendVerification.error) {
      alert.show(`Verification email sent to address ${user.email}!`, { type: 'success' });
    } else {
      alert.show(data.UserOps.resendVerification ? data.UserOps.resendVerification.message : 'Verification Failed !', {
        type: 'error',
      });
    }
  };

  const handleError = (message: string) => {
    // const { alert } = props;
    // alert.show({ message }, { type: 'error' });
  };

  const handleCancel = () => {
    const { alert } = props;
    // alert.show(
    //   <div>We are happy with your decision<span style={{ paddingLeft: '10px', color: '#63bb1e' }} className='lnr lnr-smile' /></div>,
    //   { type: 'success', timeout: 5000 },
    // );

    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
      setShowCancel(false);
    }, 3000);
  };

  const cancelAccountDelete = async () => {
    setShowCancel(true);
    const { data } = await cancelMutation({ variables: { cancel: true } });
    if (!data) return;
    const {
      UserOps: {
        scheduleToDelete: { error },
      },
    } = data;

    if (error) {
      handleError('Cancellation Failed!');
      setShowCancel(false);
      return;
    }
    setRefetchLoading(true);
    handleCancel();
    await refetch();
    setRefetchLoading(false);
  };

  return (
    <div className="header-alert">
      {/* {user && user.verified === false && (
        <Alert className="alert-wrapper" color="danger">
          <div className="d-flex justify-content-center align-items-center">
            To continue using our services please verify your email address.
            <span
              // style={loading ? { pointerEvents: "none" } : {pointerEvents: 'auto'}}
              className={`m-1 cursor-pointer  ${loading ? 'disabled' : ''}`}
              onClick={() => receiveEmail()}
            >
              Click here to receive verification email.
            </span>
          </div>
          {!loading ? null : <Loader light={theme === 'light'} />}
        </Alert>
      )} */}
      {shouldBeDeletedAt && (
        <Alert className="alert-wrapper" color="danger">
          <div className="d-flex justify-content-center text-center align-items-center">
            Your account is scheduled to be deleted {moment(parseInt(shouldBeDeletedAt)).fromNow()}.
            <span
              className={`m-1 alert-danger alert-link cursor-pointer ${showCancel && 'd-none'}`}
              onClick={() => {
                cancelAccountDelete();
              }}
            >
              Cancel Deletion
            </span>
            <span className="ml-2">
              {' '}
              {!deleteLoading && !userDataLoading && !refetchLoading ? null : <Loader light={theme === 'light'} />}
            </span>
          </div>
        </Alert>
      )}
      {/* {pricingPackage === 'FREE' && user && user.verified && (
        <Alert className="alert-wrapper alert-primary">
          <div className="d-flex justify-content-center align-items-center">
            Upgrade package.
            <Link to={`/upgrade-package`} className={`m-1 clickable-text cursor-pointer ${showCancel && 'd-none'}`}>
              Click here to upgrade
            </Link>
          </div>
        </Alert>
      )} */}
    </div>
  );
};

export default HeaderAlert;
