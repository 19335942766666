import { FC, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAutomationGlobalMethods } from '../../hooks';
import { useLazyQuery } from '@apollo/react-hooks';
import { HexomaticUserContext } from '../../../contexts/hexomatic-user';
import { GET_ALL_INTEGRATIONS } from 'graphql/queries';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import Input from 'shared-components/atom/input';
import Button from 'shared-components/atom/button';
import { closeIcon } from 'assets/svg-icons';
import Alert from 'shared-components/atom/alert-message';
import CustomMultiSelect from 'shared-components/molecule/multiselect';
import StretchLoading from 'shared-components/molecule/stretch-loading';
import { isValidUrl } from 'constants/validations';

import './styles.scss';

const CUSTOM_VALUE = 'CUSTOM_VALUE';

const TeamsSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const { hexomaticUser } = useContext(HexomaticUserContext);
  const [userId, setUserId] = useState(hexomaticUser ? hexomaticUser.id : -1);
  const [clicked, setClicked] = useState(false);
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);
  const [selectedWebhookUrls, setSelectedWebhookUrls] = useState<any>([]);
  const [teamsOptions, setTeamsOptions] = useState<any>([]);
  const [selectedWebhookUrlsList, setSelectedWebhookUrlsList] = useState<any>([]);
  const [inputText, setInputText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedFields, setSelectedFields] = useState([] as any);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const [getAllIntegrations, { data: integrationsData, loading: integrationsLoading }] = useLazyQuery(
    GET_ALL_INTEGRATIONS,
    { fetchPolicy: 'network-only' },
  );

  const {
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getDynamicAndIntegrationsSettings,
    liftingSettings,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (!selectedWebhookUrls?.length || !selectedFields?.length) return false;
    return true;
  };

  //restore
  useEffect(() => {
    getAllIntegrations({
      variables: { id: userId },
    });
  }, []);

  //recipe fields or output fields
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex !== 0) {
      let options = getPreviousAutomationOutputOrPreviousRecipKeys();
      const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
      const filteredOptions =
        automationInputTypes && !automationInputTypes.includes('any')
          ? options.filter(
              (item: any) =>
                (optionsWithTypes &&
                  optionsWithTypes[item.value] &&
                  (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                    optionsWithTypes[item.value] === 'any')) ||
                !(optionsWithTypes && optionsWithTypes[item.value]),
            )
          : [...options];

      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
      // setFieldList(options);
    }
  }, [isSettingsChange]);

  useEffect(() => {
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    let optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    selectedFields?.length === 0 && setSelectedFields(filteredOptions);
  }, []);

  useEffect(() => {
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      outputKeysData: fieldList.map((item: { value: any }) => item.value),
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);

  // getting integrations
  useEffect(() => {
    if (integrationsData?.HexomaticIntegrations?.getAllIntegrations?.microsoftTeams) {
      const { microsoftTeams } = integrationsData.HexomaticIntegrations.getAllIntegrations;
      const { dynamic_settings } = getDynamicAndIntegrationsSettings();
      if (dynamic_settings) {
        const { keys } = dynamic_settings;
        const webhooks = keys?.[0]?.settings?.webhookUrlsList;
        const integrationOutputKeys = keys?.[0]?.settings?.integrationOutputKeys;
        if (webhooks) {
          setSelectedWebhookUrls(webhooks);
          setSelectedWebhookUrlsList(webhooks);
        }
        !clicked && setClicked(true);
        if (integrationOutputKeys) {
          if (integrationOutputKeys.length === 0) {
            setSelectedFields(fieldList);
          } else {
            setSelectedFields(
              integrationOutputKeys.map((item: any) => fieldList.find((sub: any) => sub.value === item)),
            );
          }
          !clicked && setClicked(true);
        }
      }

      let arr: any = null;
      if (microsoftTeams && microsoftTeams.length > 0) {
        arr = microsoftTeams.map((item: any) => {
          const parsedData = JSON.parse(item.data);
          return { label: item.name || parsedData.webhookUrls[0], value: parsedData.webhookUrls[0] };
        });
      }
      setTeamsOptions([{ label: 'add custom webhook url', value: CUSTOM_VALUE }, ...(arr ? arr : [])]);
      !clicked && setClicked(true);
      setCompletedFields({ index: automationIndex, checked: checkFields() });
    }
  }, [integrationsData, fieldList]);

  //lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: {
        keys: [
          {
            key: null,
            settings: {
              webhookUrlsList: selectedWebhookUrls,
              webhookUrls: selectedWebhookUrls.map((item: { value: any }) => item.value),
              integrationOutputKeys:
                fieldList.length === selectedFields.length
                  ? []
                  : fieldList
                      .map((item: { value: any }) => selectedFields.find((sub: any) => sub.value === item.value)?.value)
                      ?.filter((val: any) => val),
            },
            inputValue: null,
          },
        ],
      },
      outputKeysData: fieldList.map((item: { value: any }) => item.value),
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [selectedWebhookUrls, selectedFields, fieldList]);

  const onTestAndAdd = () => {
    if (!inputText || !inputText.trim()) return;
    if (!isValidUrl(inputText)) {
      setErrorMessage('Please enter correct webhook URL');
      return;
    }
    setSelectedWebhookUrls([...selectedWebhookUrls, { label: inputText, value: inputText }]);
    setSelectedWebhookUrlsList([...selectedWebhookUrls, { label: inputText, value: inputText }]);
    setInputText('');
  };

  const onDelete = (index: number) => {
    !clicked && setClicked(true);
    const newValues = selectedWebhookUrls.filter((item: any, i: number) => i !== index);
    setSelectedWebhookUrls(newValues);
    setIsDirty();
  };

  const handleChangeWebhooks = (sel: any) => {
    !clicked && setClicked(true);
    // const new_sel = JSON.parse(JSON.stringify(sel)).map((item: { label: string; value: string }) => {
    //   if (item.value === CUSTOM_VALUE) {
    //     return {
    //       value: item.value,
    //     };
    //   } else return item;
    // });
    // setSelectedWebhookUrlsList(new_sel);
    setIsDirty();
    setSelectedWebhookUrlsList(sel);
    setSelectedWebhookUrls(sel.filter((item: { value: string }) => item.value !== 'CUSTOM_VALUE'));
  };

  const handleChangeSource = (sel: any) => {
    setSelectedFields(sel);
    !clicked && setClicked(true);
    setIsDirty();
  };

  if (integrationsLoading) {
    return <StretchLoading />;
  }
  return (
    <div className="webhook-settings">
      <Row>
        <Col lg={6} className="mb-4 order-1 microsoft-teams-multi-select">
          <span className="label secondary-color">Select Webhook</span>
          <CustomMultiSelect
            options={teamsOptions}
            value={selectedWebhookUrlsList}
            onChange={handleChangeWebhooks}
            labelledBy="Select"
            hasSelectAll={false}
          />
        </Col>
        <Col lg={6} className="order-lg-2 order-4">
          <span className="label secondary-color">Specify columns</span>
          <CustomMultiSelect
            options={fieldList}
            value={selectedFields}
            onChange={handleChangeSource}
            labelledBy="Select"
          />
        </Col>
        {selectedWebhookUrlsList.find((item: { value: string }) => item.value === CUSTOM_VALUE) && (
          <Col lg={6} md={12} className="mb-4 order-lg-3 order-2">
            <div className="webhook-wrapper">
              <div className="label-input-wrapper">
                <span className="label secondary-color">Webhook</span>
                <Input
                  value={inputText}
                  onChange={e => {
                    errorMessage && setErrorMessage('');
                    setInputText(e.target.value);
                    !clicked && setClicked(true);
                    setIsDirty();
                  }}
                />
              </div>
              <Button name={'Add'} disabled={!inputText.length} onClick={onTestAndAdd} />
            </div>
          </Col>
        )}
        <Col lg={12} className="order-lg-5 order-3">
          {selectedWebhookUrls.map((item: { label: string }, i: number) => {
            return (
              <Row>
                <Col lg={6} className="mb-2">
                  <div className="webhook-item mt-2 align-items-center">
                    <div>
                      <span className="break-all">{item.label}</span>
                    </div>
                    <span className="close-icon" data-tip="Click to delete" onClick={() => onDelete(i)}>
                      {closeIcon}
                    </span>
                  </div>
                </Col>
              </Row>
            );
          })}
        </Col>
      </Row>

      {errorMessage && (
        <Row className="mb-2 mt-2">
          <Col md={12}>
            <Alert type="error" className="create-workflow">
              {errorMessage}
            </Alert>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default TeamsSettings;
