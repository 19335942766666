import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_USER_WATCH_TAGS } from 'graphql/queries';
import { CREATE_USER_WATCH_TAGS, DELETE_USER_WATCH_TAGS, UPDATE_USER_WATCH_TAGS } from 'graphql/mutations';
import ToolCard from '../../shared-components/molecule/tool-card';
import { ChromePicker } from 'react-color';
import ModalComponent from 'shared-components/molecule/modal';
// import Message from '../../../shared/components/messages/index';
import Tag from './tag/tag';
import { propertyIcon } from 'assets/svg-icons/index';
import { hexToRgbA } from 'helpers/index';
import CustomInput from 'shared-components/atom/input';
import useClickOutside from 'hooks/useClickOutside';
import Button from 'shared-components/atom/button';
import Message from 'shared-components/atom/messages';
import './styles.scss';

const colors = [
  '#F35D5D',
  '#E5A544',
  '#FD8B39',
  '#0D978F',
  '#0FBA72',
  '#0AB0F7',
  '#0A69F7',
  '#D60AF7',
  '#F651B4',
  '#7E35F6',
];

const Tags = () => {
  const [tagsList, setTagsList] = useState<string[] | []>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [tagName, setTagName] = useState('');
  const [tagColor, setTagColor] = useState(colors[0]);
  const [tagId, setTagId] = useState('');
  const [refetchLoading, setRefetchLoading] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  // const [updateColorPickerOpen, setUpdateColorPickerOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState<any>(null);

  const [updateColorPickerOpen, selectRef, setUpdateColorPickerOpen] = useClickOutside();

  const { data: userTags, loading, refetch } = useQuery(GET_USER_WATCH_TAGS);
  const [createUserTag, { loading: createLoading }] = useMutation(CREATE_USER_WATCH_TAGS);
  const [updateUserTag, { loading: updateLoading }] = useMutation(UPDATE_USER_WATCH_TAGS);
  const [deleteUserTag, { loading: deleteLoading }] = useMutation(DELETE_USER_WATCH_TAGS);

  useEffect(() => {
    userTags?.HexomaticTag?.getUserHexomaticTags?.tags && setTagsList(userTags.HexomaticTag.getUserHexomaticTags.tags);
  }, [userTags]);

  const handleCreate = async () => {
    setSelectedTag(null);
    setColorPickerOpen(false);
    setTagName('');
    setErrorMessage('');
    setTagColor('#FB6F6F');
    if (!tagName || !tagName.trim()) {
      setErrorMessage('Please enter tag name');
    } else {
      const { data } = await createUserTag({
        variables: {
          settings: {
            name: tagName.trim(),
            color: tagColor,
          },
        },
      });
      const { error_code } = data && data.HexomaticTagOps && data.HexomaticTagOps.createUserHexomaticTag;
      if (error_code) {
        setErrorMessage(error_code);
      } else {
        setRefetchLoading(true);
        await refetch();
        setRefetchLoading(false);
      }
    }
  };

  const handleUpdate = async () => {
    if (!tagName || !tagName.trim()) {
      setErrorMessage('Please enter tag name');
      return;
    }
    const { data } = await updateUserTag({
      variables: {
        settings: {
          hexomatic_tag_id: selectedTag.id,
          name: selectedTag.name,
          color: selectedTag.color,
        },
      },
    });
    const { error_code } = data && data.HexomaticTagOps && data.HexomaticTagOps.updateUserHexomaticTag;
    if (error_code) {
      setErrorMessage(error_code);
    } else {
      setRefetchLoading(true);
      await refetch();
      setRefetchLoading(false);
      setSelectedTag(null);
    }
  };

  const handleDelete = async () => {
    setSelectedTag(null);
    setErrorMessage('');
    setModalOpened(false);
    const { data } = await deleteUserTag({
      variables: {
        settings: {
          hexomatic_tag_id: tagId,
        },
      },
    });
    const { error_code } = data && data.HexomaticTagOps && data.HexomaticTagOps.deleteUserHexomaticTag;
    if (error_code) {
      setErrorMessage(error_code);
    } else {
      setRefetchLoading(true);
      await refetch();
      setRefetchLoading(false);
    }
  };

  useEffect(() => {
    if (selectedTag && selectedTag.id) {
      setTagColor(selectedTag.color);
      setTagName(selectedTag.name);
    } else {
      setTagColor(colors[0]);
      setTagName('');
    }
  }, [selectedTag]);

  return (
    <ToolCard
      title="Tags"
      hexowatch
      loading={loading || createLoading || refetchLoading || deleteLoading || updateLoading}
      icon={<div className="icon-wrapper-settings-tags">{propertyIcon}</div>}
      openClose
    >
      <div className="tags-settings">
        <div className="tag-name-block">
          <CustomInput
            label={'Tag name'}
            type="text"
            placeholder="Add tag name..."
            value={tagName}
            onChange={(e: { target: { value: React.SetStateAction<string> } }) => {
              if (e.target.value && e.target.value.length > 64) return;
              setErrorMessage('');
              setTagName(e.target.value);
              selectedTag && setSelectedTag({ ...selectedTag, name: e.target.value });
            }}
            maxLength={119}
          />
        </div>
        <div className="color-picker-block">
          <span className="label">Color</span>
          <div className="wrapper">
            <div className="colors">
              {colors.map(color => (
                <div
                  key={color}
                  className="color"
                  style={
                    tagColor === color ? { background: 'white', border: `5px solid ${color}` } : { background: color }
                  }
                  onClick={() => {
                    setTagColor(color);
                    selectedTag && setSelectedTag({ ...selectedTag, color: color });
                  }}
                ></div>
              ))}
            </div>
            <div className="">
              <div className="color-picker-wrapper">
                <button className="picked-color" ref={selectRef} style={{ position: 'relative' }}>
                  <div className="d-flex" onClick={() => setUpdateColorPickerOpen(!updateColorPickerOpen)}>
                    <p className="color-picker__color" style={{ width: '80px', margin: 'auto' }}>
                      <span className="sign">#</span>
                      {selectedTag
                        ? selectedTag.color.slice(1, selectedTag.color.length)
                        : tagColor.slice(1, tagColor.length)}
                    </p>
                  </div>
                  {updateColorPickerOpen && (
                    <div style={{ position: 'absolute', top: '50px', left: '-30px', zIndex: 100 }}>
                      <ChromePicker
                        disableAlpha
                        color={selectedTag ? selectedTag.color : tagColor}
                        onChange={color =>
                          selectedTag ? setSelectedTag({ ...selectedTag, color: color.hex }) : setTagColor(color.hex)
                        }
                        onChangeComplete={color =>
                          selectedTag ? setSelectedTag({ ...selectedTag, color: color.hex }) : setTagColor(color.hex)
                        }
                      />
                    </div>
                  )}
                </button>
              </div>
            </div>
            {selectedTag ? (
              <>
                <Button name="Save" onClick={handleUpdate} />
                &nbsp;
                <Button name="Cancel" onClick={() => setSelectedTag(null)} />
              </>
            ) : (
              <Button name="Add" onClick={handleCreate} />
            )}
          </div>
        </div>
        <div className="tags-block">
          <span className="label">List of tags</span>

          <div className="badge-wrapper">
            {tagsList && tagsList.length > 0 ? (
              //@ts-ignore
              tagsList.map((item: any) => {
                return (
                  (!selectedTag || selectedTag.id !== item.id) && (
                    <div key={item.name} className="mb-2 mw-100">
                      <Tag
                        name={item.name}
                        color={item.color}
                        bgColor={hexToRgbA(item.color, 0.1)}
                        onClick={() => {
                          setTagId(item.id);
                          setModalOpened(true);
                        }}
                        onTagClick={() => setSelectedTag(item)}
                        settings
                        disabled={selectedTag}
                      />
                    </div>
                  )
                );
              })
            ) : (
              <p className="text-center m-2 primary-color">
                <small>Nothing to show</small>
              </p>
            )}
          </div>
        </div>
        {errorMessage && <Message type="error" text={errorMessage} />}
        <ModalComponent
          isModalOpen={modalOpened}
          text={`Confirm deleting the tag`}
          actionBtnText={'Delete'}
          cancelBtnText={'Cancel'}
          actionBtnColor={'danger'}
          cancelBtnColor={'secondary'}
          onActionClick={handleDelete}
          onCancelClick={() => setModalOpened(false)}
          escButtonClick={() => setModalOpened(false)}
        />
      </div>
    </ToolCard>
  );
};

export default Tags;
