import { gql } from 'apollo-boost';

export const WORKFLOW_LOG_SUBSCRIPTION = gql`
  subscription ($workflowId: Int, $hexomaticUserId: Int) {
    workflowLogEvent(workflowId: $workflowId, hexomaticUserId: $hexomaticUserId) {
      workflowId
      log {
        time
        log
        type
      }
    }
  }
`;

export const WORKFLOW_STATUS_SUBSCRIPTION = gql`
  subscription ($hexomaticUserId: Int) {
    workflowStatusEvent(hexomaticUserId: $hexomaticUserId) {
      workflowId
      progress {
        status
      }
      active
      storage
      next_scan_time
      currentAutomation
      currentIteration
      lastActivityTime
    }
  }
`;
export const ZAPIER_SUBSCRIPTION = gql`
  subscription ($userId: Int, $workflowId: Int) {
    zapierHookUrlEvent(userId: $userId, workflowId: $workflowId) {
      urls
    }
  }
`;

export const FILE_UPLOAD_SUBSCRIPTION = gql`
  subscription FILE_UPLOAD_SUBSCRIPTION($hcUserId: Int, $fileId: String) {
    fileUploadEvent(hcUserId: $hcUserId, fileId: $fileId) {
      fileUrl
      fileId
      error
      name
      size
    }
  }
`;
export const FILE_UPLOAD_LIMIT_REACHED_SUBSCRIPTION = gql`
  subscription FILE_UPLOAD_LIMIT_REACHED_SUBSCRIPTION($hcUserId: Int) {
    storageLimitEvent(hcUserId: $hcUserId) {
      storageLimitReached
    }
  }
`;

export const FILE_DELETE_SUBSCRIPTION = gql`
  subscription FILE_DELETE_SUBSCRIPTION($hcUserId: Int) {
    fileDeleteEvent(hcUserId: $hcUserId) {
      fileUrl
      deleted
      error
    }
  }
`;
