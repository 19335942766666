import { FC } from 'react';
import './styles.scss';

interface ModalCardProps {
  title: string;
  icon: any;
  onClick: (arg: string) => void;
}

const ModalCard: FC<ModalCardProps> = ({ title, icon, onClick }) => {
  return (
    <div onClick={() => onClick(title)} className="modal-card mx-2 my-2">
      <span className="modal-card-title">{icon}</span>
      <span className="modal-card-title text-truncate">{title}</span>
    </div>
  );
}; 

export default ModalCard;
