import { FC, useState, FormEvent, useEffect } from 'react';
import Cards from 'react-credit-cards';
import { CreditCardFormProps } from './types';
import CreditCardFormComponent from './cardForm';
import Payment from 'payment';
import 'react-credit-cards/es/styles-compiled.css';
import './styles.scss';
import Message from 'shared-components/atom/alert-message';

const CreditCardForm: FC<CreditCardFormProps> = ({
  onSubmit,
  cardInfo,
  loading,
  error,
  changeData,
  disabled,
  fieldError,
  promoPage,
  hideCards,
}) => {
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);
  const [cardData, setCardData] = useState({
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
  });
  const [formData, setFormData] = useState({
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
  });

  useEffect(() => {
    setCardData(formData);
    setFormData(formData);
    setShowPaymentMethod(false);
  }, [cardInfo]);

  const fieldChange = (e: FormEvent) => {
    const target = e.target as HTMLInputElement;
    setCardData({
      ...cardData,
      [target.name]: target.value,
    });
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const setFocusName = (e: FormEvent) => {
    const target = e.target as HTMLInputElement;
    setCardData({
      ...cardData,
      focus: target.name,
    });
    setFormData({
      ...formData,
      focus: target.name,
    });
  };

  const unsetFocusName = (e: FormEvent) => {
    setCardData({
      ...cardData,
      focus: '',
    });
    setFormData({
      ...formData,
      focus: '',
    });
  };

  const handlePaymentFormSubmit = async () => {
    onSubmit && (await onSubmit(formData));
    setShowPaymentMethod(false);
  };
  useEffect(() => {
    changeData && changeData(formData);
  }, [formData]);
  return (
    <div className={`row mt-2 `}>
      {promoPage || hideCards ? null : (
        <div className={`pt-2 col-12 col-md-6`}>
          {disabled ? (
            <div className="checked-card">
              <Cards
                cvc={cardInfo.cvc && cardInfo.cvc.toString().replace(/[0-9]/g, '*')}
                expiry={cardInfo.expiry.toString().replace(/[0-9]/g, '*')}
                focused={cardInfo.focus as any}
                name={cardInfo.name
                  .toString()
                  .replace(/[a-z,A-Z]/g, '*')
                  .replace(/[0-9]/g, '*')}
                number={cardInfo.number.toString().replace(/[0-9]/g, '*')}
                issuer={Payment.fns.cardType(cardInfo.number)}
                preview
              />
            </div>
          ) : (
            <Cards
              cvc={cardData.cvc.toString().replace(/[0-9]/g, '*')}
              expiry={cardData.expiry.toString().replace(/[0-9]/g, '*')}
              focused={cardData.focus as any}
              name={cardData.name.toString().replace(/[a-z,A-Z]/g, '*')}
              number={cardData.number.toString().replace(/[0-9]/g, '*')}
              issuer={Payment.fns.cardType(cardData.number)}
              preview
            />
          )}
        </div>
      )}
      <div className={`col-12 ${promoPage || hideCards ? '' : 'col-md-6'}`}>
        <CreditCardFormComponent
          onSubmit={handlePaymentFormSubmit}
          cardData={formData}
          loading={loading}
          onFieldBlur={unsetFocusName}
          onFieldFocus={setFocusName}
          onFieldChange={fieldChange}
          disabled={disabled}
          fieldError={fieldError}
        />
      </div>
      <p className={` w-100 pl-3 text-center ${error ? 'visible' : 'invisible'}`}>
        <Message type={'error'} text={error} className="py-1" />
      </p>
    </div>
  );
};

export default CreditCardForm;
