import { FC } from 'react';
import { RoundedElementProps } from '../types';
import './styles.scss';

const RoundedElement: FC<RoundedElementProps> = ({ text, active, onChange }) => (
  <div id={text} onClick={() => onChange(text)} className={`${active ? 'rounded-element active' : 'rounded-element'}`}>
    {text}
  </div>
);

export default RoundedElement;
