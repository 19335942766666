import React, { FC, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
import Datalist from 'shared-components/atom/dataList';
import { cryptoCurrencyOptions } from '../../constants';
import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import Alert from 'shared-components/atom/alert-message';
import { cacheFunction } from 'helpers';
import './styles.scss';

const CurrencyConverterSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  selectedRecipes,
  automationIndex,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [fieldList, setFieldList] = useState<any>([]);
  const [clicked, setClicked] = useState(false);
  const [currencyFrom, setCurrencyFrom] = useState('');
  const [currencyTo, setCurrencyTo] = useState('');
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const {
    liftingSettings,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (!currencyFrom || !currencyTo || errorMessage) {
      return false;
    }
    if (!selectedSource) return false;
    return true;
  };

  useEffect(() => {
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const { keys } = dynamic_settings;
      const { settings } = keys[0];
      const from = settings ? settings.from : keys[0].from;
      const to = settings ? settings.to : keys[0].to;
      const findedCurrencyFrom = cryptoCurrencyOptions.find(item => item.value === from);
      const findedCurrencyTo = cryptoCurrencyOptions.find(item => item.value === to);

      findedCurrencyFrom && setCurrencyFrom(findedCurrencyFrom.label);
      findedCurrencyTo && setCurrencyTo(findedCurrencyTo.label);
    }
  }, []);

  //recipe
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();

    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const key = dynamic_settings.keys[0].key;
      restoreSelectedSource({ key: key, fieldList: options, setSelectedSource: setSelectedSource });
    }
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [...fieldList.map((item: { value: any }) => item.value), '_converted_cryptocurrency'],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);

  //memoize
  const handleCryptoCurrencyFrom = (currencyFrom: string) => {
    return cryptoCurrencyOptions.find((item: { label: string; value: string }) => item.label === currencyFrom);
  };
  const getMemoizeCryptoCurrencyFromOption = useRef(cacheFunction(handleCryptoCurrencyFrom));

  const handleCryptoCurrencyTo = (currencyTo: string) => {
    return cryptoCurrencyOptions.find((item: { label: string; value: string }) => item.label === currencyTo);
  };
  const getMemoizeCryptoCurrencyToOption = useRef(cacheFunction(handleCryptoCurrencyTo));

  useEffect(() => {
    if (!clicked) return;
    // setClicked(false);

    const selectOptionFrom = getMemoizeCryptoCurrencyFromOption.current(currencyFrom);
    const selectOptionTo = getMemoizeCryptoCurrencyToOption.current(currencyTo);
    if (!selectOptionFrom || !selectOptionTo) {
      setCompletedFields({ index: automationIndex, checked: false });
    } else {
      setCompletedFields({ index: automationIndex, checked: checkFields() });
    }

    let localSettings = {
      keys: [
        {
          key: fieldList.length && selectedSource ? selectedSource.value : null,
          inputValue: null,
          settings: {
            from: selectOptionFrom ? selectOptionFrom.value : null,
            to: selectOptionTo ? selectOptionTo.value : null,
          },
        },
      ],
    };
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: localSettings,
      outputKeysData: [...fieldList.map((item: { value: any }) => item.value), '_converted_cryptocurrency'],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [selectedSource, currencyFrom, currencyTo, errorMessage]);

  return (
    <div className="google-translate-settings">
      <Row className="mb-4">
        <Col lg={6} className="">
          <span className="label secondary-color">Source</span>
          <Select
            options={fieldList}
            value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
            placeholder={'Select source'}
            onChange={(selectedItem: any) => {
              setSelectedSource(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col lg={6} className="google-translate-select">
          <h5 className="pointer-title">From</h5>
          <div className="select-block">
            <span className="label secondary-color">Cryptocurrency</span>
            <Datalist
              list={cryptoCurrencyOptions}
              value={currencyFrom}
              placeholder={'Select currency'}
              onChange={(text: string) => {
                setCurrencyFrom(text);
                setClicked(true);
                setIsDirty();
              }}
            />
          </div>
        </Col>
        <Col lg={6} className="google-translate-select">
          <h5 className="pointer-title">To</h5>
          <div className="select-block">
            <span className="label secondary-color">Cryptocurrency</span>
            <Datalist
              list={cryptoCurrencyOptions}
              value={currencyTo}
              placeholder={'Select currency'}
              onChange={(text: string) => {
                setCurrencyTo(text);
                setClicked(true);
                setIsDirty();
              }}
            />
          </div>
        </Col>
      </Row>{' '}
      {errorMessage && (
        <Row className="mb-4">
          <Col md={12}>
            <Alert type="error" className="create-workflow">
              {errorMessage}
            </Alert>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default CurrencyConverterSettings;
