import { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import CustomMultiSelect from 'shared-components/molecule/multiselect';
import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';

const RemoveDuplicatesFromSpreadsheet: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [clicked, setClicked] = useState(false);
  const [fieldList, setFieldList] = useState<any>([]);
  const [selectedFields, setSelectedFields] = useState<any>([]);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const {
    liftingSettings,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (selectedFields?.length === 0) {
      return false;
    }
    return true;
  };

  //recipe fields or output fields
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();

    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings && dynamic_settings.keys) {
      const { keys } = dynamic_settings;
      const fieldsArr = keys.map((item: { key: any }) => {
        return item.key;
      });
      const getValues = options.filter(
        (option: { label: any; value: string }) => fieldsArr.includes(option.label) || fieldsArr.includes(option.value),
      );
      setSelectedFields(getValues);
    }

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedFields(fieldList);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedFields([]);
      setClicked(true);
    }
    if (update) {
      if (dynamic_settings?.keys?.length > 1) {
        const filteredValue = filteredOptions?.filter((item: any) =>
          dynamic_settings?.keys.find((sub: any) => item.value === sub.key),
        );

        setSelectedFields(filteredValue || []);
        setClicked(true);
        setFieldList(filteredOptions);
        setFieldListWithTypes(optionsWithTypes);
      } else {
        const isArr = Array.isArray(dynamic_settings?.keys?.[0]?.key);
        const foundValue = !isArr
          ? filteredOptions?.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value)
          : null;
        const filteredValue = isArr
          ? filteredOptions?.filter((item: any) =>
              dynamic_settings?.keys?.[0]?.key.find((sub: any) => item.value === sub),
            )
          : [];
        const selOptionFound = isArr ? filteredValue : foundValue ? [foundValue] : [];

        setSelectedFields(selOptionFound);
        setClicked(true);
        setFieldList(filteredOptions);
        setFieldListWithTypes(optionsWithTypes);
      }
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [...fieldList.map((item: { value: string }) => item.value)],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, automationOutputTypes, automationOutputTypes]);

  //lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);

    let localSettings = {
      keys: selectedFields.map((item: { label: any; value: any }) => {
        return {
          inputValue: null,
          key: item.value,
          settings: null,
        };
      }),
    };
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: localSettings,
      outputKeysData: [...fieldList.map((item: { value: string }) => item.value)],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [selectedFields]);

  return (
    <div className="measurment-unit-settings">
      <Row className="mb-4">
        <Col lg={6}>
          <span className="label secondary-color">Select Sources</span>
          <CustomMultiSelect
            options={fieldList}
            value={selectedFields}
            onChange={(sel: any) => {
              setSelectedFields(sel);
              !clicked && setClicked(true);
              setIsDirty();
            }}
            labelledBy="Select"
          />
        </Col>
      </Row>
    </div>
  );
};

export default RemoveDuplicatesFromSpreadsheet;
