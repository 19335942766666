import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_WORKFLOW_OUTPUT_COLUMN_NAMES } from 'graphql/queries';

const useCustomDownloadOption = (custom: boolean, workflowId: number) => {
  const [columns, setColumns] = useState<string[]>([]);

  const [error, setError] = useState('');

  const [getCustomDownloadOption, { loading, error: gqlError }] = useLazyQuery(GET_WORKFLOW_OUTPUT_COLUMN_NAMES, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      if (data?.HexomaticWorkflow?.getWorkflowOutputColumNames?.columns) {
        setColumns(data?.HexomaticWorkflow?.getWorkflowOutputColumNames?.columns);
        return;
      }
      setError('Something went wrong');
    },
  });

  useEffect(() => {
    if (custom) {
      getCustomDownloadOption({
        variables: {
          id: workflowId,
        },
      });
    }
  }, [custom]);

  return { columns, error, loading, gqlError } as const;
};

export default useCustomDownloadOption;
