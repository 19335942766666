import { FC } from 'react';
import Tooltip from 'shared-components/atom/tooltip';
import { BadgeProps } from '../types';
import './styles.scss';

const Badge: FC<BadgeProps> = ({ children, tool, messageType, buttonType, width, className, hoverText, onClick }) => {
  return (
    <div
      className={`custom-badge ${tool || ''} ${messageType || ''} ${buttonType || ''} ${className ? className : ''}`}
      style={{ width: width ? `${width}px` : '' }}
      onClick={onClick && onClick}
    >
      {hoverText ? (
        <Tooltip trigger={['hover']} placement="bottom" text={hoverText}>
          <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>{children}</span>
        </Tooltip>
      ) : (
        children
      )}
    </div>
  );
};

export default Badge;
