import { forwardRef, memo, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { InputTypes } from '../types';
import './style.scss';

export type IRef = HTMLInputElement | null;

const Input = forwardRef<IRef, InputTypes>(
  (
    {
      type,
      value,
      placeholder,
      disabled,
      required,
      error,
      label,
      className,
      parentClassName,
      leftIcon,
      rightAddon,
      rightIcon,
      onChange,
      onBlur,
      onFocus,
      onKeyUp,
      onKeyDown,
      id,
      name,
      defaultValue,
      dataUrl,
      noBorders,
      autocomplete,
      maxLength,
      min,
      max,
      autoFocus,
    },
    ref,
  ) => {
    const handleKeyDown = useCallback(
      (e: any) => {
        if (type === 'number' && (e.key === 'e' || e.key === 'E')) e.preventDefault();
        onKeyDown && onKeyDown(e);
      },
      [onKeyDown],
    );

    const handleChange = useCallback(
      (e: any) => {
        onChange && onChange(e);
      },
      [onChange],
    );

    const handleBlur = useCallback(
      (e: any) => {
        onBlur && onBlur(e);
      },
      [onBlur],
    );

    const handleFocus = useCallback(
      (e: any) => {
        onFocus && onFocus(e);
      },
      [onFocus],
    );

    const handleKeyUp = useCallback(
      (e: any) => {
        onKeyUp && onKeyUp(e);
      },
      [onKeyUp],
    );
    return (
      <div className={`custom-input ${noBorders ? 'no-borders' : ''} ${parentClassName ? parentClassName : ''}`}>
        {label && (
          <label className="label" htmlFor={label}>
            {label}
          </label>
        )}
        <div className="input-wrapper">
          {leftIcon && <span className="icon left">{leftIcon}</span>}
          {rightIcon && <span className="icon right">{rightIcon}</span>}
          <input
            data-url={dataUrl}
            id={id || uuidv4()}
            ref={ref ? ref : null}
            type={type ? type : 'text'}
            placeholder={placeholder || ''}
            value={value}
            disabled={disabled ? true : false}
            required={required ? required : false}
            className={`input-field ${error ? 'error-border' : ''} ${leftIcon ? 'left-icon' : ''} ${
              rightIcon ? 'right-icon' : ''
            } ${className || ''}`}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDown}
            name={name}
            defaultValue={defaultValue}
            autoComplete={autocomplete ? 'on' : 'off'}
            maxLength={maxLength}
            min={min}
            max={max}
            autoFocus={autoFocus}
          />
          {rightAddon}
        </div>
      </div>
    );
  },
);

export default memo(Input);
