import { FC, useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { Col, Row } from 'react-bootstrap';
import { GET_ALL_INTEGRATIONS } from 'graphql/queries';
import CustomMultiSelect from 'shared-components/molecule/multiselect';
import Select from 'shared-components/atom/select';
import Button from 'shared-components/atom/button';
import { HexomaticUserContext } from 'contexts/hexomatic-user';
import { useAutomationGlobalMethods } from '../../hooks';
import StretchLoading from 'shared-components/molecule/stretch-loading';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import './styles.scss';

const TelegramSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [options, setOptions] = useState<any>([]);
  const [selectedintegration, setSelectedintegration] = useState<any>(null);
  const { hexomaticUser } = useContext(HexomaticUserContext);
  const [userId, setUserId] = useState(hexomaticUser ? hexomaticUser.id : -1);
  const [clicked, setClicked] = useState(true);
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);
  const [fieldListWithTypes, setFieldListWithTypes] = useState<any>([]);
  const [selectedFields, setSelectedFields] = useState<any>([]);
  const [connected, setConnected] = useState<any>(null);

  const [getAllIntegrations, { data: integrationsData, loading: integrationsLoading }] = useLazyQuery(
    GET_ALL_INTEGRATIONS,
    { fetchPolicy: 'network-only' },
  );

  const {
    liftingSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    getDynamicAndIntegrationsSettings,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (!selectedintegration || !selectedFields?.length) return false;
    return true;
  };

  //restore
  useEffect(() => {
    getAllIntegrations({
      variables: { id: userId },
    });
  }, []);

  //recipe fields or output fields
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    let optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    // setFieldList(options);
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    selectedFields?.length === 0 && setSelectedFields(filteredOptions);
    selectedFields?.length === 0 && !clicked && setClicked(true);
  }, []);

  // getting integrations
  useEffect(() => {
    if (integrationsData?.HexomaticIntegrations?.getAllIntegrations?.telegram) {
      const { telegram } = integrationsData.HexomaticIntegrations.getAllIntegrations;
      const options = telegram.map((item: { data: any; id: any; name: string }) => {
        let data = JSON.parse(item.data);
        return {
          label: item.name || data.id,
          value: item.id,
        };
      });
      const { integration_settings, dynamic_settings } = getDynamicAndIntegrationsSettings();
      let channel = false;
      if (integration_settings) {
        const finded = options.find((item: { value: any }) => item.value === integration_settings._ref_data);
        finded && setSelectedintegration(finded);
        !clicked && setClicked(true);
      }
      if (
        dynamic_settings &&
        dynamic_settings.keys &&
        dynamic_settings.keys[0] &&
        dynamic_settings.keys[0].settings &&
        dynamic_settings.keys[0].settings.integrationOutputKeys
      ) {
        if (dynamic_settings.keys[0].settings.integrationOutputKeys.length === 0) {
          setSelectedFields(fieldList);
        } else {
          setSelectedFields(
            dynamic_settings.keys[0].settings.integrationOutputKeys.map((item: any) =>
              fieldList.find((sub: any) => sub.value === item),
            ),
          );
        }
        !clicked && setClicked(true);
      }
      !channel && options && setSelectedintegration(options[0]);
      setOptions(options);
      setConnected(options && options.length > 0 ? true : false);
      !clicked && setClicked(true);
      setCompletedFields({ index: automationIndex, checked: checkFields() });
    }
  }, [integrationsData, fieldList]);

  //restore
  useEffect(() => {
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      outputKeysData: fieldList.map((item: { value: any }) => item.value),
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [fieldList, automationOutputTypes, fieldListWithTypes]);

  //lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);
    if (!selectedintegration || !selectedFields?.length) return;
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: {
        keys: [
          {
            key: null,
            inputValue: null,
            settings: {
              integrationOutputKeys:
                fieldList.length === selectedFields?.length
                  ? []
                  : fieldList
                      .map((item: { value: any }) => selectedFields.find((sub: any) => sub.value === item.value)?.value)
                      ?.filter((val: any) => val),
            },
          },
        ],
      },
      integrationsSettings: {
        _ref_type: 'INTEGRATION',
        _ref_data: selectedintegration?.value,
      },
      outputKeysData: fieldList.map((item: { value: any }) => item.value),
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [selectedintegration, selectedFields, fieldList]);

  const openSetting = () => {
    window.open(`http://${window.location.host}/settings`, '_blank');
  };

  return (
    <Row className="mb-4 telegram-notification-settings">
      {(connected === null || integrationsLoading) && <StretchLoading />}
      {connected === null || connected ? (
        <>
          <Col lg={6}>
            <span className="label secondary-color">Telegram</span>
            <span style={{ marginLeft: '10px' }}></span>
            <Select
              value={selectedintegration ? selectedintegration.label : ''}
              // manySelect
              options={options}
              fullWidth
              onChange={(selectedItem: any) => {
                if (selectedItem.label === (selectedintegration ? selectedintegration.label : '')) return;
                setSelectedintegration(selectedItem);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            ></Select>
          </Col>
          <Col lg={6}>
            <span className="label secondary-color">Specify columns</span>
            <CustomMultiSelect
              options={fieldList}
              value={selectedFields}
              onChange={(sel: any) => {
                setSelectedFields(sel);
                !clicked && setClicked(true);
                setIsDirty();
              }}
              labelledBy="Select"
            />
          </Col>
        </>
      ) : (
        <Col lg={6} md={12}>
          <Button name={'Please check the Settings>Integrations to Connect'} onClick={openSetting} />
        </Col>
      )}
    </Row>
  );
};

export default TelegramSettings;
