import { FC, useContext } from 'react';
import { ThemeContext } from 'contexts/theme-context';
import { useHistory } from 'react-router';
import Badge from 'shared-components/atom/badge';

import recipeIcon from 'assets/icons/recipe.svg';
import deeplLight from 'assets/images/deepL-light.svg';
import Tooltip from 'shared-components/atom/tooltip';

import './styles.scss';

interface PublicWorkflowCardProps {
  item: any;
  getNamesForTooltip: (arg1: any[], arg2: number) => string;
  fullWidth?: boolean;
}

const PublicWorkflowCard: FC<PublicWorkflowCardProps> = ({ item, getNamesForTooltip, fullWidth }) => {
  const { theme } = useContext(ThemeContext);
  const { push } = useHistory();

  return (
    <div className={`public-workflow-card col-12 ${fullWidth ? 'col-xl-6' : 'col-lg-6 col-xl-4'}`}>
      <div className="single-card-item" onClick={() => push(`/workflows/current-workflow-template/${item.id}`)}>
        <div className="main-container">
          <div className="d-flex mx-2 align-items-center el-one border-bottom">
            <span className="text-truncate secondart-text">{item.name}</span>
          </div>
          {item.description && (
            <div className="d-flex pt-2">
              <p className="px-2 description">{item.description}</p>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-between mx-2 align-items-center el-border-top">
          <div className="d-flex px-1 align-items-center">
            {item.categories.map((item: any) => (
              <div key={item.name} className="mr-2">
                <Badge
                  messageType={
                    item.name === 'ChatGPT' ? 'badge-chatgpt' :
                    item.name === 'Sales'
                      ? 'badge-warning'
                      : item.name === 'Marketing'
                      ? 'badge-product'
                      : 'badge-success'
                  }
                  buttonType="light-background"
                >
                  <span>{item.name}</span>
                </Badge>
              </div>
            ))}
          </div>
          <div className="d-flex">
            {item.steps &&
              item.steps.map(
                (subItem: any, index: number) =>
                  index < 3 &&
                  subItem?.automation?.icon_url && (
                    <Tooltip
                      key={subItem.automation.name}
                      trigger={['hover', 'focus']}
                      placement="left"
                      text={subItem.automation.name}
                    >
                      <div className="automation-card-icon overlaping">
                        <img
                          src={
                            subItem.automation.id === 43
                              ? recipeIcon
                              : subItem.automation.id === 34 && theme === 'dark'
                              ? deeplLight
                              : subItem.automation.icon_url
                          }
                          alt="Workflow icon"
                          style={{
                            width: subItem.automation.id === 35 ? '25px' : '',
                            height: subItem.automation.id === 35 ? '25px' : '',
                          }}
                        />
                      </div>
                    </Tooltip>
                  ),
              )}
            {item.steps && item.steps.length > 3 && (
              <Tooltip trigger={['hover', 'focus']} placement="left" text={getNamesForTooltip(item.steps, 3)}>
                <div className="automation-card-icon overlaping">
                  <span>+{item.steps.length - 3}</span>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default PublicWorkflowCard;
