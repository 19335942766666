import { FC, useState, KeyboardEvent, FormEvent } from 'react';
import { Row, Col } from 'react-bootstrap';
import { eyeIcon } from '../../../assets/svg-icons/index';
import CustomInput from '../../../shared-components/atom/input';
import Button from '../../../shared-components/atom/button';
import { CardFormComponentProps } from './types';
import { formatCreditCardNumber, formatExpirationDate, formatCVC } from './helpers';
import './styles.scss';

const CreditCardFormComponent: FC<CardFormComponentProps> = ({
  cardData,
  onSubmit,
  onFieldChange,
  onFieldBlur,
  onFieldFocus,
  loading,
}) => {
  const [showCVV, setShowCVV] = useState(false);

  const handleEnterKeyPress = (e: KeyboardEvent<FormEvent>) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <div className="profile-payment-inputs">
      <div>
        <CustomInput
          onKeyDown={handleEnterKeyPress}
          label={'Cardholder’s Name'}
          name="name"
          placeholder="Name on the card"
          value={cardData.name}
          onChange={onFieldChange}
          onFocus={onFieldFocus}
          onBlur={onFieldBlur}
          required
        />
      </div>
      <div>
        <CustomInput
          onKeyDown={handleEnterKeyPress}
          label={'Credit/Debit Card Number'}
          name="number"
          placeholder="Card number"
          value={formatCreditCardNumber(cardData.number)}
          onChange={onFieldChange}
          onFocus={onFieldFocus}
          onBlur={onFieldBlur}
          required
        />
      </div>
      <Row>
        <Col lg={6} sm={6} xs={12}>
          <CustomInput
            onKeyDown={handleEnterKeyPress}
            label={'Expiration'}
            name="expiry"
            placeholder="MM/YY"
            value={formatExpirationDate(cardData.expiry)}
            onChange={onFieldChange}
            onFocus={onFieldFocus}
            onBlur={onFieldBlur}
            required
          />
        </Col>
        <Col lg={6} sm={6} xs={12}>
          <CustomInput
            onKeyDown={handleEnterKeyPress}
            type={showCVV ? 'text' : 'password'}
            label={'Security code'}
            name="cvc"
            placeholder="CVV/CVC"
            value={formatCVC(cardData.cvc, cardData.number)}
            onChange={onFieldChange}
            rightIcon={<div onClick={() => setShowCVV(!showCVV)}>{eyeIcon}</div>}
            onFocus={onFieldFocus}
            onBlur={onFieldBlur}
            required
          />

          {/* <div className="form-item">
            <span className="label">Security code</span>
            <div className="input-wrapper">
              <input
                type={showCVV ? "text" : "password"}
                name="cvc"
                placeholder="CVV/CVC"
                className="input-field"
                value={formatCVC(cardData.cvc, cardData.number)}
                onChange={() => {}}
                // onFocus={onFieldFocus}
                // onBlur={onFieldBlur}
                required
              />
              <div className={`eye-icon ${showCVV ? ' active' : ''}`}
                onClick={e => setShowCVV(!showCVV)}
              >
                {
                  eyeIcon
                }
              </div>
            </div>
          </div> */}
        </Col>
        <div className="upgrade-btn">
          <Button onClick={onSubmit} loading={loading} name="Add credit card data" />
        </div>
      </Row>
    </div>
  );
};

export default CreditCardFormComponent;
