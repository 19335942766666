import { gql } from 'apollo-boost';

//User

export const USER_QUERY = gql`
  query USER_QUERY {
    User {
      get {
        id
        firstName
        lastName
        email
        createdAt
        updatedAt
        companyWebsite
        industry
        countryUser
        title
        company
        phone
        error
        verified
        shouldBeDeletedAt
        logo
        type
        hexometer_pricing_package
        hexometer_pricing_package_interval
        hexometer_pricing_package_count
        hexomatic_pricing_package
        hexomatic_pricing_package_interval
        hexomatic_pricing_package_count
        premium_credit_subscription_status
        premium_credit_subscription_cancel_at
        premium_credits_paypal_subscription_id
        premium_credits_stripe_subscription_id
        premium_credits_pricing_package
        premium_credits_pricing_package_interval
        left_premium_credits
        left_one_time_premium_credits
        referral_code
        photo
        combo_pricing_package
        twofa {
          status
          type
          date
        }
        info {
          address1
          address2
          city
          state
          zip
          tax_type
          tax_value
        }
        promoData {
          hexowatch_ltd
          hexomatic_ltd
        }
        pending_payment
      }
    }
  }
`;

export const GENERATE_APOLLO_DATA = gql`
  query {
    User {
      generateApolloData {
        message
        error
      }
    }
  }
`;

export const USER_PLANS_QUERY = gql`
  query USER_QUERY {
    User {
      getUserPlans {
        hexomatic {
          pricing_package
          pricing_package_interval
          pending_payment
          pricing_package_count
        }
        hexowatch {
          pricing_package
          pricing_package_interval
          pending_payment
          pricing_package_count
        }
        hexofy {
          pricing_package
          pricing_package_interval
          pending_payment
          pricing_package_count
        }
        hexometer {
          pricing_package
          pricing_package_interval
          pending_payment
          pricing_package_count
        }
        hexospark {
          pricing_package
          pricing_package_interval
          pending_payment
          pricing_package_count
        }
        premiumCredits {
          premium_credits_pricing_package
          premium_credits_pricing_package_interval
        }
        error_code
      }
    }
  }
`;

export const GET_HEXOMATIC_USER = gql`
  query GET_HEXOMATIC_USER {
    HexomaticUser {
      getByLegacyId {
        isPerformer
        id
        automation_credit
        premium_automation_credit
        user_legacy_id
        storage
        storage_used
        pricing_package
        pricing_package_interval
        pricing_package_count
        stripe_subscription_id
        left_automation_credit
        left_premium_automation_credit
        error_code
        promo_codes_count
        subscription_status
        enterprise
        billing_info {
          next_billing_date
        }
        paypal_subscription_id
        pending_payment
        can_use_trial
        trial_packages
        settings {
          show_dashboard
        }
        show_survey
      }
    }
  }
`;

export const GET_HEXOMATIC_USER_SETTINGS = gql`
  query GET_HEXOMATIC_USER {
    HexomaticUser {
      getUserSettings {
        send_mail
        allow_sharing_workflow
        allow_sharing_recipe
        error_code
      }
    }
  }
`;

export const LEFT_PACKAGES_COUNT = gql`
  query {
    User {
      getHexomateLeftLTDPackagesCount {
        count
        error
        message
      }
    }
  }
`;

export const LEFT_PREMMIUMS_COUNT = gql`
  query {
    User {
      getPromoLeftSeats {
        seats
        error_code
      }
    }
  }
`;

// QUESTIONNAIRES

export const GET_QUESTIEONNAIRE = gql`
  query GET_QUESTIEONNAIRE($type: String!) {
    Questionnaire {
      get(type: $type) {
        showQuestionnaire
      }
    }
  }
`;

export const QUESTIEONNAIRE_CREATE_UPDATE = gql`
  mutation QUESTIEONNAIRE_CREATE_UPDATE($type: String!, $answer1: String) {
    QuestionnaireOps {
      createUpdate(type: $type, answer1: $answer1) {
        error
        message
      }
    }
  }
`;

export const QUESTIEONNAIRE_CANCEL = gql`
  mutation QUESTIEONNAIRE_CANCEL($type: String!, $answer1: String) {
    QuestionnaireOps {
      cancel(type: $type, answer1: $answer1) {
        error
        message
      }
    }
  }
`;

//! -----------------------Recipes--------------------------

export const GET_SHARED_EMAILS = gql`
  query GET_SHARED_EMAILS($settings: GetEmailsOfSharedRecipeInput) {
    HexomaticScrapingRecipe {
      getEmailsOfSharedRecipe(settings: $settings) {
        emails
        error_code
        isShareLinkValid
      }
    }
  }
`;

export const GET_SCRAPER_SCAN_RESPONSE = gql`
  query GET_SCRAPER_SCAN_RESPONSE($address: String!, $type: String!, $country: String, $autoscrape: Boolean) {
    Scraper {
      scan(address: $address, type: $type, country: $country, autoscrape: $autoscrape) {
        address
        preview
        error
        message
        elements
      }
    }
  }
`;

export const GET_RECIPIES_LIST = gql`
  query GET_RECIPIES_LIST($settings: GetScrapingRecipesInput) {
    HexomaticScrapingRecipe {
      getScrapingRecipes(settings: $settings) {
        scraping_recipes {
          id
          name
          url
          actions
          is_public
          status
          created_at
          updated_at
          description
          image
          synced_with_parent
          parent_recipe_id
          isFavorite
        }
        total
        error_code
      }
    }
  }
`;

export const GET_RECIPIES_CARD = gql`
  query GET_RECIPIES_CARD($settings: GetScrapingRecipesInput) {
    HexomaticScrapingRecipe {
      getScrapingRecipes(settings: $settings) {
        scraping_recipes {
          id
          name
          url
          actions
          is_public
          status
          created_at
          updated_at
          description
          image
          isFavorite
        }
        total
        error_code
      }
    }
  }
`;

export const GET_RECIPIES_LIST_BY_IDS = gql`
  query getScrapingRecipesByIds($settings: GetScrapingRecipesByIdsInput) {
    HexomaticScrapingRecipe {
      getScrapingRecipesByIds(settings: $settings) {
        scraping_recipes {
          id
          name
          url
          actions
          is_public
          status
          description
        }
        error_code
      }
    }
  }
`;

export const GET_SCRAPING_RECIPE = gql`
  query GET_SCRAPING_RECIPE($settings: GetScrapingRecipeInput) {
    HexomaticScrapingRecipe {
      getScrapingRecipe(settings: $settings) {
        scraping_recipe {
          name
          url
          actions
          is_public
          status
          description
          image
          synced_with_parent
          parent_recipe_id
        }
        error_code
      }
    }
  }
`;

export const GET_SCRAPING_PUBLIC_RECIPE = gql`
  query GET_SCRAPING_PUBLIC_RECIPE($settings: GetPublicScrapingRecipeInput) {
    HexomaticPublicScrapingRecipe {
      getPublicScrapingRecipe(settings: $settings) {
        public_scraping_recipe {
          name
          url
          actions
          is_public
          status
          description
          image
          var_url
        }
        error_code
      }
    }
  }
`;

export const GET_PUBLIC_REICPES = gql`
  query GET_PUBLIC_REICPES($settings: GetPublicScrapingRecipesInput) {
    HexomaticPublicScrapingRecipe {
      getPublicScrapingRecipes(settings: $settings) {
        public_scraping_recipes {
          name
          id
          url
          actions
          is_public
          status
          description
          image
          var_url
          firstName: user_firstname
          lastName: user_lastname
          user_email
          is_new
          most_used
          categories {
            name
            id
          }
        }
        error_code
      }
    }
  }
`;

export const SEND_TRAFFIC_DATA = gql`
  query ($settings: UserStatsUpdateInput) {
    HexomaticUser {
      updateStatsofUser(settings: $settings) {
        error_code
      }
    }
  }
`;

//Automation

const AutomationType = `
  id
  name
  created_at
  updated_at
`;

export const GET_AUTOMATIONS = gql`
  query GET_AUTOMATIONS($settings: GetAutomationsUserInput) {
    HexomaticAutomation {
      getAutomations(settings: $settings) {
        automations {
          id
          name
          # credit_price
          # premium_credit_price
          created_at
          updated_at
          outputs
          inputs
          order
          multi
          prices {
            id
            unit_name
            credit_price
            premium_credit_price
          }
          url
          meta_title
          short_description
          has_source
          long_description
          icon_url
          category {
            id
            name
          }
          type {
            id
            name
          }
          is_new
          isFavorite
          source {
            source_csv
            source_txt
          }
          suggestAutomations {
            id: suggestAutomationId
            name
            type
            iconUrl
          }
        }
        error_code
      }
    }
  }
`;

export const GET_AUTOMATIONS_BY_IDS = gql`
  query getAutomationsByIds($settings: GetAutomationsByIdsInput) {
    HexomaticAutomation {
      getAutomationsByIds(settings: $settings) {
        automations {
          id
          name
          prices {
            id
            unit_name
            credit_price
            premium_credit_price
          }
          url
          meta_title
          short_description
          has_source
          long_description
          icon_url
          outputs
          inputs
          category {
            id
            name
          }
          type {
            id
            name
          }
          source {
            source_csv
            source_txt
          }
          suggestAutomations {
            id: suggestAutomationId
            name
            type
            iconUrl
          }
        }
        # error_code
      }
    }
  }
`;

//not used
export const GET_AUTOMATIONS_WITH_IO = gql`
  query GET_AUTOMATIONS_WITH_IO($settings: GetAutomationsWithIOUserInput) {
    HexomaticAutomation {
      getAutomationsWithIO(settings: $settings) {
        automations {
          id
          name
          prices {
            id
            unit_name
            credit_price
            premium_credit_price
          }
          # url
          # meta_title
          short_description
          has_source
          long_description
          icon_url
          category {
            id
            name
          }
          type {
            id
            name
          }
          input_schema_ids
          output_schema_ids
          prices {
            unit_name
            credit_price
            premium_credit_price
          }
        }
        error_code
      }
    }
  }
`;

export const GET_AUTOMATIONS_BY_IO = gql`
  query GET_AUTOMATIONS_BY_IO($settings: GetAutomationsByIOUserInput) {
    HexomaticAutomation {
      getAutomationsByIO(settings: $settings) {
        automations {
          id
          name
          prices {
            id
            unit_name
            credit_price
            premium_credit_price
          }
          # url
          # meta_title
          short_description
          long_description
          icon_url
          outputs
          inputs
          order
          multi
          category {
            id
            name
          }
          type {
            id
            name
          }
          input_schema_ids
          output_schema_ids
          has_source
          prices {
            unit_name
            credit_price
            premium_credit_price
          }
          source {
            source_csv
            source_txt
          }
          suggestAutomations {
            id: suggestAutomationId
            name
            type
            iconUrl
          }
        }
        error_code
      }
    }
  }
`;

export const GET_AUTOMATION_BY_ID = gql`
  query GET_AUTOMATION_BY_ID($settings: GetAutomationUserInput) {
    HexomaticAutomation {
      getAutomation(settings: $settings) {
        automation {
          id
          name
          is_new
          isFavorite
          # credit_price
          # premium_credit_price
          containedPublicWorkflows {
            public_workflow_id
            public_workflow_name
            public_workflow_description
            public_workflow_created_at
            steps {
              automation_icon_url
              automation_name
            }
            categories {
              id
              name
            }
          }
          prices {
            id
            unit_name
            credit_price
            premium_credit_price
          }
          url
          meta_title
          short_description
          long_description
          long_description_details
          has_source
          icon_url
          category {
            id
            name
          }
          type {
            id
            name
          }
          outputs
          inputs
        }
        error_code
      }
    }
  }
`;

export const GET_AUTOMATION_TYPES = gql`
  query GET_AUTOMATION_TYPES($settings: GetAutomationTypesInput) {
    HexomaticAutomation {
      getAutomationTypes(settings: $settings) {
        types{
          ${AutomationType}
        }
        error_code
      }
    }
  }
`;
export const GET_AUTOMATION_TYPES_CATEGORIES = gql`
  query GET_AUTOMATION_TYPES_CATEGORIES($settings: GetAutomationsCategoriesOrTypesByIdInput) {
    HexomaticAutomation {
      getAutomationsCategoriesOrTypesById(settings: $settings) {
        types{
          ${AutomationType}
        }
        categories{
          ${AutomationType}
        }
        error_code
      }
    }
  }
`;

export const GET_AUTOMATION_CATEGORIES = gql`
  query GET_AUTOMATION_CATEGORIES($settings: GetAutomationCategoriesInput) {
    HexomaticAutomation {
      getAutomationCategories(settings: $settings) {
        categories{
          ${AutomationType}
        }
        error_code
      }
    }
  }
`;

//! -----------------------Workflows--------------------------

export const GET_SHARED_WORKFLOW_EMAILS = gql`
  query GET_SHARED_WORKFLOW_EMAILS($settings: GetEmailsOfSharedWorkflowInput) {
    HexomaticWorkflow {
      getEmailsOfSharedWorkflow(settings: $settings) {
        emails
        error_code
        isShareLinkValid
      }
    }
  }
`;

export const GET_WORKFLOWS = gql`
  query GET_WORKFLOWS($settings: GetWorkflowsInput) {
    HexomaticWorkflow {
      getWorkflows(settings: $settings) {
        workflows {
          id
          name
          is_public
          status
          active
          created_at
          updated_at
          started_at
          frequency
          description
          last_scan_time
          next_scan_time
          storage
          credit
          premiumCredit
          token_to_share
          selected_headers
          configs {
            workflowUploadId
            send_mail
            selectedOutputKeys
          }
          steps {
            index
            setting
            automation_id
            automation_icon_url
            automation_name
            automation_type_name
            automation_type_id
          }
          tags {
            id
            name
            color
          }
          schedule_config {
            timezone
            selected
            weekdays
            triggers {
              watchPropertyId
              watchIntegrationId
              name
              url
              tool
            }
          }
        }
        count
        error_code
      }
    }
  }
`;

export const GET_WORKFLOW_BY_ID = gql`
  query GET_WORKFLOW_BY_ID($settings: GetWorkflowInput) {
    HexomaticWorkflow {
      getWorkflow(settings: $settings) {
        workflow {
          id
          name
          is_public
          status
          started_at
          created_at
          frequency
          selected_headers
          configs {
            workflowUploadId
            send_mail
            selectedOutputKeys
          }
          execution_data
          description
          scan_times {
            last_scan_time
            next_scan_time
          }
          storage
          credit
          credit_usage
          premiumCredit
          tags {
            id
            name
            color
          }
          synced_with_parent
          parent_workflow_id
          schedule_config {
            timezone
            selected
            weekdays
            triggers {
              watchPropertyId
              watchIntegrationId
            }
          }
        }
        steps {
          id
          setting
          index
          created_at
          updated_at
          automation {
            id
            name
            # credit_price
            # premium_credit_price
            url
            meta_title
            short_description
            long_description
            has_source
            icon_url
          }
        }

        error_code
      }
    }
  }
`;
export const GET_WORKFLOW_RESULTS = gql`
  query ($settings: GetWorkflowResultPreview) {
    HexomaticWorkflow {
      getWorkflowResultPreview(settings: $settings) {
        result
        error_code
      }
    }
  }
`;
export const PAYMENT_METHOD_QUERY = gql`
  query PAYMENT_METHOD_QUERY {
    User {
      creditCardInfo {
        number
        name
        expiry
      }
    }
  }
`;

export const GET_HEXOMETER_USER_SETTINGS = gql`
  query GET_HEXOMETER_USER_SETTINGS($user_id: Int!) {
    HexometerUserSettings {
      get(user_id: $user_id) {
        pricing_package
        pricing_package_interval
        pricing_package_count
        billing_info {
          last_billing_date
          next_billing_date
        }
        paypal_subscription_id
        subscription_status
      }
    }
  }
`;

export const GET_USER_WATCH_SETTINGS_QUERY = gql`
  query GET_USER_WATCH_SETTINGS_QUERY {
    UserWatchSettings {
      get {
        id
        phoneNumber
        phoneNumberVerified
        phoneEnabled
        monthly_limit
        emails {
          email
          enabled
          is_user_email
          verified
          verify_email_send_at
        }
        emailEnabled
        daily_digest_alert_email_time
        browsers {
          name
          enabled
        }
        slackIntegration
        telegramChatId
        pricing_package_interval
        pricing_package
        left_monthly
        zapierIntegration
        watchApiKey
        webhooks {
          webhook
          subscriptionId
        }
        billing_info {
          next_billing_date
          last_billing_date
        }
        config {
          additional_scans_count
          daily_email_alert_limit
        }
        activated_promo_codes_count
        subscription_status
        billing_info {
          next_billing_date
        }
        paypal_subscription_id
      }
    }
  }
`;

export const GET_PUBLIC_WORKFLOWS = gql`
  query GET_PUBLIC_WORKFLOWS($settings: GetPublicWorkflowsInput) {
    HexomaticPublicWorkflow {
      getPublicWorkflows(settings: $settings) {
        public_workflows {
          id
          name
          status
          started_at
          frequency
          created_at
          updated_at
          description
          most_used
          image
          categories {
            id
            name
          }
          steps {
            id
            setting
            index
            created_at
            updated_at
            automation {
              id
              name
              # credit_price
              # premium_credit_price
              url
              meta_title
              short_description
              long_description
              has_source
              icon_url
            }
          }
        }
        count
        error_code
      }
    }
  }
`;

export const GET_PUBLIC_WORKFLOW_BY_ID = gql`
  query GET_PUBLIC_WORKFLOW_BY_ID($settings: GetPublicWorkflowInput) {
    HexomaticPublicWorkflow {
      getPublicWorkflow(settings: $settings) {
        public_workflow {
          id
          name
          status
          started_at
          frequency
          created_at
          updated_at
          description
        }
        steps {
          id
          setting
          index
          created_at
          updated_at
          automation {
            id
            name
            # credit_price
            # premium_credit_price
            url
            meta_title
            short_description
            long_description
            has_source
            icon_url
          }
          created_at
          updated_at
        }
        error_code
      }
    }
  }
`;

export const GET_WORKFLOW_RESULT_CSV = gql`
  query GET_WORKFLOW_RESULT_CSV($settings: GetWorkflowResultCSVInput) {
    HexomaticWorkflow {
      getWorkflowResultCSV(settings: $settings) {
        csv_url
        error_code
      }
    }
  }
`;

export const GET_WORKFLOW_RESULT_JSON = gql`
  query getWorkflowResultJSON($settings: GetWorkflowResultJSONInput) {
    HexomaticWorkflow {
      getWorkflowResultJSON(settings: $settings) {
        json_url
        error_code
      }
    }
  }
`;

export const GET_WORKFLOW_OUTPUT_COLUMN_NAMES = gql`
  query GET_WORKFLOW_OUTPUT_COLUMN_NAMES($id: Int) {
    HexomaticWorkflow {
      getWorkflowOutputColumNames(workflow_id: $id) {
        columns
      }
    }
  }
`;

export const GET_WORKFLOWS_LOGS = gql`
  query GET_WORKFLOWS_LOGS($settings: GetWorkflowLogsInput) {
    HexomaticWorkflow {
      getWorkflowLogs(settings: $settings) {
        logs {
          log {
            time
            log
            type
          }
          progress {
            status
          }
        }
        error_code
      }
    }
  }
`;

export const GET_WORKFLOWS_SCAN_LOGS = gql`
  query GET_WORKFLOWS_SCAN_LOGS($settings: GetWorkflowScanLogsInput) {
    HexomaticWorkflow {
      getWorkflowScanLogs(settings: $settings) {
        count
        logs {
          _id
          created_at
          updated_at
        }
        error_code
      }
    }
  }
`;

//Integrations

export const GET_ALL_INTEGRATIONS = gql`
  query GET_ALL_INTEGRATIONS($id: Int) {
    HexomaticIntegrations {
      getAllIntegrations(id: $id) {
        discord {
          id
          data
          name
        }
        email {
          id
          data
          name
        }
        telegram {
          id
          data
          name
        }
        slack {
          id
          data
          name
        }
        chatGpt {
          id
          data
          name
        }
        google_sheet {
          id
          data
          name
        }
        hexomaticWebhook {
          id
          data
          name
        }
        microsoftTeams {
          id
          data
          name
        }
        integromat {
          id
          data
          name
        }
        integrately {
          id
          data
          name
        }
        pabbly {
          id
          data
          name
        }
        konnectzit {
          id
          data
          name
        }
        microsoftTeams {
          id
          data
          name
        }
      }
    }
  }
`;

export const CREATE_EMAIL = gql`
  query CREATE_EMAIL($email: String, $enabled: Boolean) {
    HexomaticIntegrations {
      createEmail(email_info: [{ email: $email, enabled: $enabled }]) {
        error_code
      }
    }
  }
`;

export const UPDATE_EMAIL = gql`
  query UPDATE_EMAIL($settings: UpdateEmailArgs) {
    HexomaticIntegrations {
      updateEmail(settings: $settings) {
        error_code
      }
    }
  }
`;

export const RESEND_EMAIL_VERIFICATION = gql`
  query RESEND_EMAIL_VERIFICATION($email: String, $id: Int) {
    HexomaticIntegrations {
      resendVerificationEmail(email_info: { email: $email, id: $id }) {
        error
      }
    }
  }
`;

export const DELETE_INTEGRATION = gql`
  query DELETE_INTEGRATION($id: Int) {
    HexomaticIntegrations {
      deleteIntegration(id: $id) {
        error
      }
    }
  }
`;

export const GET_GOOGLE_SPREAD_SHEETS = gql`
  query GET_GOOGLE_SPREAD_SHEETS($settings: GetGoogleSheetIntegrationSpreadsheetsInput) {
    HexomaticIntegrations {
      getGoogleSheetIntegrationSpreadsheets(settings: $settings) {
        spreadsheets {
          files {
            id
            name
          }
          nextPageToken
        }
        error_code
      }
    }
  }
`;

export const GET_SHEETS = gql`
  query GET_SHEETS($settings: GetGoogleSheetIntegrationSpreadsheetsSheetsInput) {
    HexomaticIntegrations {
      getGoogleSheetIntegrationSpreadsheetsSheets(settings: $settings) {
        sheets {
          sheet_id
          col_count
          title
          index
          row_count
        }
        error_code
      }
    }
  }
`;

export const GET_FOLDERS = gql`
  query GET_FOLDERS($settings: GetGoogleIntegrationDriveFoldersInput) {
    HexomaticIntegrations {
      getGoogleIntegrationDriveFolders(settings: $settings) {
        folders {
          id
          name
        }
        error_code
      }
    }
  }
`;

export const CHANGE_INTEGRATION_NAME = gql`
  query CHANGE_INTEGRATION_NAME($integrationId: Int, $name: String) {
    HexomaticIntegrations {
      updateIntegrationName(integrationId: $integrationId, name: $name) {
        error_code
        error
      }
    }
  }
`;
export const GET_DAILY_CREDITS_OF_USER = gql`
  query GET_DAILY_CREDITS_OF_USER($settings: GetUserDailyCreditsInput) {
    HexomaticUser {
      getDailyCreditsOfUser(settings: $settings) {
        daily_credits
        error_code
      }
    }
  }
`;

export const GET_DAILY_STATS_OF_USER = gql`
  query GET_DAILY_STATS_OF_USER($settings: GetUserDailyStatsInput) {
    HexomaticUser {
      getDailyStatsOfUser(settings: $settings) {
        daily_stats
        total_stats {
          network
          proxy_premium
          proxy_unmetered
          request
          total
        }
        error_code
      }
    }
  }
`;

export const GET_USER_STATS = gql`
  query GET_USER_STATS {
    HexomaticUser {
      getUserStats {
        user_stats
        error_code
      }
    }
  }
`;

export const GET_USER_WORKFLOW_STATUSES = gql`
  query GET_USER_WORKFLOW_STATUSES {
    HexomaticUser {
      getUserWorkflowStatuses {
        workflow_statuses
        error_code
      }
    }
  }
`;
export const GET_API_KEY = gql`
  query {
    HexomaticUser {
      getWatchApiKey {
        error_code
        watch_api_key
      }
    }
  }
`;
export const GET_TASKS = gql`
  query GET_TASKS($settings: getHumanValidationTasksType) {
    HexomaticHumanValidationsTasks {
      getHumanValidationsTasks(settings: $settings) {
        tasks {
          id
          question
          status
          created_at
          quantity
          automation_id
        }
        count
      }
    }
  }
`;
export const GET_TASK = gql`
  query GET_TASK($id: Int) {
    HexomaticHumanValidationsTasks {
      getHumanValidationTask(id: $id) {
        id
        question
        status
        price
        created_at
        updated_at
        legacy_id
        type
        spentTime
        userInfo {
          creator {
            firstName
            lastName
            email
          }
          performer {
            firstName
            lastName
            email
          }
        }
        elements {
          link
          answer
          id
          createdAt
        }
        taskStartTime
      }
    }
  }
`;

export const DELETE_TASKS = gql`
  mutation ($taskIds: [Int]) {
    HexomaticHumanValidationsTasksOps {
      deleteHumanValidationTasks(taskIds: $taskIds) {
        error
        message
      }
    }
  }
`;
export const ARCHIVE_TASKS = gql`
  mutation ($taskIds: [Int]) {
    HexomaticHumanValidationsTasksOps {
      archiveHumanValidationTasks(taskIds: $taskIds) {
        error
        message
      }
    }
  }
`;
export const CHANGE_TASK_STATUS = gql`
  mutation ($taskId: String, $status: String) {
    HexomaticHumanValidationsTasksOps {
      updateTaskStatus(taskId: $taskId, status: $status) {
        error
        message
      }
    }
  }
`;
export const CHANGE_TASK_ANSWER = gql`
  mutation ($taskId: String, $elementId: String, $answer: String, $type: String) {
    HexomaticHumanValidationsTasksOps {
      updateElement(taskId: $taskId, elementId: $elementId, answer: $answer, type: $type) {
        error
      }
    }
  }
`;

export const PROMO_CODE_BALANCE_QUERY = gql`
  query {
    User {
      promoBalance {
        count
      }
    }
  }
`;

export const ACTIVATE_PROMO_CODE = gql`
  mutation ($code: String!) {
    PromoCodeOps {
      activate(code: $code) {
        error
        message
      }
    }
  }
`;
export const BILING_DATES = gql`
  query ($settings: RetrieveUpcomingInvoiceInput) {
    Billing {
      retrieveUpcomingInvoice(settings: $settings) {
        packagesBillingInfo {
          hexowatch {
            last_billing_date
            next_billing_date
          }
          hexofy {
            last_billing_date
            next_billing_date
          }
          hexomatic {
            last_billing_date
            next_billing_date
          }
          hexometer {
            last_billing_date
            next_billing_date
          }
          hexospark {
            last_billing_date
            next_billing_date
          }
          premium_credits {
            last_billing_date
            next_billing_date
          }
        }

        error_code
      }
    }
  }
`;
export const GET_RENEWAL_DATE = gql`
  query ($settings: GetPackageRenewalDateInput) {
    User {
      getPackageRenewalDate(settings: $settings) {
        hexomatic
        hexowatch
        hexometer
        premium_credits
        error_code
      }
    }
  }
`;

export const LIST_PRODUCTS = gql`
  query ($settings: ListProductsInput) {
    Billing {
      listProducts(settings: $settings) {
        products {
          hexomaticProducts {
            id
            name
            interval
            productId
            amount
            max_stack_count
            limits {
              additional_premium_credits_count
              credits_count
            }
            actions {
              scraping_recipe {
                can_create_scraping_recipe
              }
              workflow {
                scheduling
                simultaneous_running_workflow_count
              }
              specify_server_location
            }
            integrations {
              integrately {
                allowed
              }
              pabbly {
                allowed
              }
              syncspider {
                allowed
              }
              zapier {
                allowed
              }
              webhook {
                allowed
              }
              hexomatic_api {
                allowed
              }
            }
            isPopular
            productType
          }
          comboProducts {
            id
            name
            enable
            interval
            amount
            productType
          }
        }
        error_code
      }
    }
  }
`;
export const LIST_CUSTOMER_CARD = gql`
  query ($settings: ListCustomerCardsInput) {
    Billing {
      listCustomerCards(settings: $settings) {
        cards {
          id
          createdAt
          updatedAt
          #cardId
          customerId
          brand
          lastDigits
          isDefault
        }
        error_code
      }
    }
  }
`;
export const LIST_PREMIUM_CREDITS = gql`
  query {
    Billing {
      listPremiumCredits {
        premiumCredits {
          name
          free
          limits {
            premium_credits_count
          }
          enable
          interval
          productId
          amount
          id
          productType
        }
        error_code
      }
    }
  }
`;
export const LIST_CUSTOMER_INVOICES = gql`
  query ($settings: ListCustomerInvoicesInput) {
    Billing {
      listCustomerInvoices(settings: $settings) {
        invoices {
          invoiceId
          amountDue
          amountPaid
          total
          createdAt
          status
          invoicePdf
        }
        error_code
      }
    }
  }
`;

export const GET_SQL_CONNECTION = gql`
  query ($settings: GetSQLConnectionArgs) {
    HexomaticIntegrations {
      getSQLConnection(settings: $settings) {
        id
        type
        host
        port
        username
        password
        database
        error_code
      }
    }
  }
`;

export const GET_SQL_CONNECTIONS = gql`
  query {
    HexomaticIntegrations {
      getSQLConnections {
        connections {
          id
          type
          host
          port
          username
          password
          database
          error_code
          name
        }
      }
    }
  }
`;

export const GET_CHATGPT_CONNECTIONS = gql`
  query {
    HexomaticIntegrations {
      getChatGptIntegrations {
        integrations {
          id
          data {
            apiKey
          }
          name
        }
      }
    }
  }
`;

export const GET_WORDPRESS_DATA = gql`
  query {
    HexomaticIntegrations {
      getWordPressConnections {
        connections {
          id
          username
          password
          error_code
          url
          name
        }
      }
    }
  }
`;

export const GET_WORDPRESS_CATEGORIES_BY_ID = gql`
  query ($settings: GetWordPressCategoriesArgs) {
    HexomaticIntegrations {
      getWordPressCategories(settings: $settings) {
        categories {
          id
          name
        }
      }
    }
  }
`;

export const GET_SQL_DATABASE_TABLES = gql`
  query ($settings: GetSQLDatabaseTablesArgs) {
    HexomaticIntegrations {
      getSQLDatabaseTables(settings: $settings) {
        tables
        error_code
      }
    }
  }
`;

export const GET_SQL_TABLE_COLLECTION_NAMES = gql`
  query ($settings: GetSQLTableCollectionNamesArgs) {
    HexomaticIntegrations {
      getSQLTableCollectionNames(settings: $settings) {
        collection_names
        error_code
      }
    }
  }
`;

export const CREATE_SQL_CONNECTION = gql`
  query ($settings: CreateSQLConnectionArgs) {
    HexomaticIntegrations {
      createSQLConnection(settings: $settings) {
        error_code
      }
    }
  }
`;

export const UPDATE_SQL_CONNECTION = gql`
  query ($settings: UpdateSQLConnectionArgs) {
    HexomaticIntegrations {
      updateSQLConnection(settings: $settings) {
        error_code
      }
    }
  }
`;

export const GET_MICROSOFT_TEAMS = gql`
  query getMicrosoftTeams($integrationId: Int) {
    HexomaticIntegrations {
      getMicrosoftTeams(integrationId: $integrationId) {
        displayName
        id
      }
    }
  }
`;

// export const GET_MICROSOFT_TEAM_CHANNELLS = gql`
//   query getMicrosoftTeamChannels($teamId: String, $integrationId: Int) {
//     HexomaticIntegrations {
//       getMicrosoftTeamChannels(teamId: $teamId, integrationId: $integrationId) {
//         displayName
//         id
//       }
//     }
//   }
// `;

export const UPDATE_MICROSOFT_TEAMS_INTEGRATION = gql`
  query updateMicrosoftTeamsIntegration($integrationId: Int, $data: MicrosoftTeamsData) {
    HexomaticIntegrations {
      updateMicrosoftTeamsIntegration(integrationId: $integrationId, data: $data) {
        error_code
      }
    }
  }
`;

export const CREATE_TEAMS_INTEGRATION = gql`
  query createMicrosoftTeamsIntegration($webhookUrls: [String]) {
    HexomaticIntegrations {
      createMicrosoftTeamsIntegration(webhookUrls: $webhookUrls) {
        error
        message
      }
    }
  }
`;

export const GET_CSV_COUMNS = gql`
  query getCSVColumns($settings: GetCSVColumnsInput) {
    HexomaticWorkflow {
      getCSVColumns(settings: $settings) {
        columns
        error_code
      }
    }
  }
`;

export const GET_JSON_KEYS = gql`
  query GET_JSON_KEYS($settings: GetJsonKeysInput) {
    HexomaticWorkflow {
      getJsonKeys(settings: $settings) {
        keys
        # error //! add error in the future
      }
    }
  }
`;

export const CREATE_PABBLY_INTEGRATION = gql`
  query createPabblyIntegration($webhookUrl: String) {
    HexomaticIntegrations {
      createPabblyIntegration(webhookUrl: $webhookUrl) {
        error_code
        error
      }
    }
  }
`;

export const CREATE_CHATGPT_INTEGRATION = gql`
  query createChatGPTIntegration($settings: CreateChatGptIntegrationSettings) {
    HexomaticIntegrations {
      createChatGptIntegration(settings: $settings) {
        error_code
      }
    }
  }
`;

export const CREATE_CONNECTZIT_INTEGRATION = gql`
  query createKonnectzitIntegration($webhookUrl: String) {
    HexomaticIntegrations {
      createKonnectzitIntegration(webhookUrl: $webhookUrl) {
        error_code
        error
      }
    }
  }
`;

export const TEST_WEBHOOK_URL = gql`
  query testWebhookUrl($url: String) {
    HexomaticIntegrations {
      testWebhookUrl(url: $url) {
        isValid
      }
    }
  }
`;

export const GET_USER_WATCH_TAGS = gql`
  query GET_USER_WATCH_TAGS {
    HexomaticTag {
      getUserHexomaticTags {
        tags {
          id
          name
          color
        }
      }
    }
  }
`;

export const GET_WORKFLOW_TAGS = gql`
  query GET_WORKFLOW_TAGS($settings: GetUserHexomaticTagsInput) {
    HexomaticTag {
      getWorkflowTags(settings: $settings) {
        tags {
          id
          name
          color
        }
        error_code
      }
    }
  }
`;

export const GET_PUBLIC_WORKFLOW_CATEGORIES = gql`
  query GET_PUBLIC_WORKFLOW_CATEGORIES {
    HexomaticWorkflowCategory {
      getWorkflowCategories {
        categories {
          id
          name
          long_description
          short_description
          image
          created_at
        }
      }
    }
  }
`;

export const GET_PUBLIC_SCRAPING_CATEGORIES = gql`
  query GET_PUBLIC_SCRAPING_CATEGORIES {
    HexomaticScrapingCategory {
      getScrapingCategories {
        categories {
          id
          name
          created_at
        }
      }
    }
  }
`;

export const CREATE_WORDPRESS_CONNECTION = gql`
  query ($settings: CreateWordPressIntegrationArgs) {
    HexomaticIntegrations {
      createWordPressIntegration(settings: $settings) {
        error_code
      }
    }
  }
`;

export const UPDATE_WORDPRESS_CONNECTION = gql`
  query ($settings: UpdateWordPressConnectionArgs) {
    HexomaticIntegrations {
      updateWordPressConnection(settings: $settings) {
        error_code
      }
    }
  }
`;

export const GET_FLASH_PROMO_DATA = gql`
  query {
    User {
      getHcFlashPromoCronDate {
        end_date
        error_code
      }
    }
  }
`;

export const GET_WATCH_PROPERTIES = gql`
  query ($settings: GetWatchPropertiesSettings) {
    Watch {
      getWatchProperties(settings: $settings) {
        watchProperties {
          id
          name
          url
          active
          tool
        }
        total
        error
        message
      }
    }
  }
`;

export const GET_WATCH_PROPERTY_BY_ID = gql`
  query ($id: Int) {
    Watch {
      getWatchPropertyById(id: $id) {
        watchProperty {
          id
          name
          url
          active
          tool
        }
      }
    }
  }
`;

export const GET_ACCESS_TOKEN = gql`
  query ($settings: GetAccessTokenInput) {
    User {
      getAccessToken(settings: $settings) {
        error_code
        token
      }
    }
  }
`;


export const HEXOSCOPE_USER = gql`
  query HEXOSCOPE_USER {
    HexoscopeUser {
      getHexoscopeUser {
        hsUser {
          id
          user_legacy_id
          pricing_package
          pricing_package_interval
          pricing_package_count
          stripe_subscription_id
          paypal_subscription_id
          subscription_status
          billing_info {
            next_billing_date
            last_billing_date
            subscription_status
          }

          pending_payment
          error_code
          settings {
            storage
            checks
          }
        }
      }
    }
  }
`;

export const HEXOFY_USER = gql`
  query HEXOFY_USER {
    HexofyUser {
      getHexofyUser {
        hfUser {
          id
          user_legacy_id
          pricing_package
          pricing_package_interval
          pricing_package_count
          stripe_subscription_id
          paypal_subscription_id
          subscription_status
          billing_info {
            next_billing_date
            last_billing_date
            subscription_status
          }

          pending_payment
          error_code
          settings {
            storage
            checks
          }
        }
      }
    }
  }
`;

export const HEXOSPARK_USER = gql`
  query HEXOSPARK_USER {
    HexosparkUser {
      getHexosparkUser {
        promo_codes_count
        hsUser {
          id
          user_legacy_id
          pricing_package
          pricing_package_interval
          pricing_package_count
          stripe_subscription_id
          paypal_subscription_id
          subscription_status
          billing_info {
            next_billing_date
            last_billing_date
            subscription_status
          }
          settings {
            storage
            emails_sent
            emails_limit
            renewal_date
            teamAccess
            workspaces
            emailAccounts
            totalPromptsCount
            usedPromptsCount
          }
          db_location
          uploadHash
          error_code
        }
      }
    }
  }
`;

export const GET_PROGRESS_WORKFLOW_DATA = gql`
  query GET_PROGRESS_WORKFLOW_DATA($workflowId: Int) {
    HexomaticWorkflow {
      getWorkflowProgressData(workflowId: $workflowId) {
        currentIteration
        currentAutomation
        lastActivityTime
      }
    }
  }
`;
export const WORKFOW_STATUS_COUNTS_RESULT = gql`
  query {
    HexomaticWorkflow {
      getWorkflowStatusCounts {
        result {
          status
          count
        }
        error_code
      }
    }
  }
`;

export const GET_ALL_WORKSPACES = gql`
  query GET_ALL_WORKSPACES($settings: GetFullInput) {
    HexosparkUserWorkspace {
      getFull(settings: $settings) {
        workspaces {
          id
          name
          isOwner
          disabled
        }
        count
      }
    }
  }
`;

export const GET_HEXOSPARK_WS_FOLDER = gql`
  query GET_HEXOSPARK_WS_FOLDER($settings: ShowAllFoldersInput) {
    HexosparkCrmFolder {
      showAllFolders(settings: $settings) {
        rootTree
        error_code
      }
    }
  }
`;

export const GET_HEXOSPARK_CONTACTS_COLUMNS = gql`
  query GET_HEXOSPARK_CONTACTS_COLUMNS {
    HexosparkCrmContact {
      getCrmContactColumns {
        keys
      }
    }
  }
`;

export const GET_HEXOSPARK_ORGANIZATION_COLUMNS = gql`
  query GET_HEXOSPARK_ORGANIZATION_COLUMNS {
    HexosparkCrmOrganization {
      getCrmOrganizationColumns {
        keys
      }
    }
  }
`;
