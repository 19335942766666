import { FC, useEffect, useState } from 'react';

import hexowatchLogo from 'assets/logos/hexowatch-logo.svg';
import hexometerLogo from 'assets/logos/hexometer-logo.svg';
import hexosparkLogo from 'assets/logos/hexospark-icon.svg';
import hexofyLogo from 'assets/logos/hexofy-icon.svg';

import { setItemToLocalStorage } from 'helpers';

import Tooltip from 'shared-components/atom/tooltip';

import { sidebarCloseIcon, rightSidebarCloseIcon } from 'assets/svg-icons';

import './styles.scss';

interface RightIconsSidebarProps {
  rightToggle: boolean;
  setRightToggle: (value: any) => void;
  headerHeight: number | null;
  navElH: number | null;
  hexometerPlan: string;
}

const RightIconsSidebar: FC<RightIconsSidebarProps> = ({
  rightToggle,
  setRightToggle,
  headerHeight,
  navElH,
  hexometerPlan,
}) => {
  const [rightMenuSettings, setRightMenuSettings] = useState([
    { icon: hexowatchLogo, href: 'https://dash.hexowatch.com', product: 'Hexowatch' },
    { icon: hexosparkLogo, href: 'https://dash.hexospark.com', product: 'Hexospark' },
    { icon: hexometerLogo, href: 'https://dash.hexometer.com', product: 'Hexometer' },
    { icon: hexofyLogo, href: 'https://hexofy.com', product: 'Hexofy' },
  ]);

  const handleSideBarClose = () => {
    setRightToggle((prev: any) => !prev);
    setItemToLocalStorage('products-sidebar', !rightToggle);
  };

  useEffect(() => {
    if (!hexometerPlan || hexometerPlan === 'FREE') {
      setRightMenuSettings([
        { icon: hexowatchLogo, href: 'https://dash.hexowatch.com', product: 'Hexowatch' },
        { icon: hexosparkLogo, href: 'https://dash.hexospark.com', product: 'Hexospark' },
        { icon: hexometerLogo, href: 'https://hexometer.com/pricing/', product: 'Hexometer' },
        { icon: hexofyLogo, href: 'https://hexofy.com', product: 'Hexofy' },
      ]);
    } else {
      setRightMenuSettings([
        { icon: hexowatchLogo, href: 'https://dash.hexowatch.com', product: 'Hexowatch' },
        { icon: hexosparkLogo, href: 'https://dash.hexospark.com', product: 'Hexospark' },
        { icon: hexometerLogo, href: 'https://dash.hexometer.com', product: 'Hexometer' },
        { icon: hexofyLogo, href: 'https://hexofy.com', product: 'Hexofy' },
      ]);
    }
  }, [hexometerPlan]);

  return (
    <div className={`r-navbar ${rightToggle ? 'r-show-sidebar' : 'r-show-mobile'}`}>
      <nav className="r-nav">
        <div style={{ paddingTop: headerHeight ? headerHeight - 8 : 52 }}>
          {rightMenuSettings.map(
            obj =>
              obj && (
                <Tooltip key={obj?.href} trigger={['hover', 'focus']} placement="left" text={`${obj?.product}`}>
                  <a
                    className={`r-nav__link ${obj?.product}`}
                    href={obj?.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={obj?.icon} alt={`${obj?.product} logo`} width="33px" height="33px" />
                  </a>
                </Tooltip>
              ),
          )}
        </div>

        <div className={`${rightToggle ? '' : 'collapsed'} r-sidebar-collapse`}>
          <button
            onClick={handleSideBarClose}
            style={{
              top: navElH ? `calc(${navElH}px / 2 - ${81}px)` : 'calc(100vh / 2 - 110px)',
            }}
            className="r-sidebar-collapse-button"
          >
            {rightToggle ? rightSidebarCloseIcon : sidebarCloseIcon}
          </button>
        </div>
      </nav>
    </div>
  );
};

export default RightIconsSidebar;
