import { useState, FC } from 'react';
import { GetTextColor } from 'helpers/index';
import { editIcon, closeIcon } from 'assets/svg-icons/index';

import Tooltip from 'shared-components/atom/tooltip';
import './styles.scss';

interface TagProps {
  name: string;
  color: string;
  bgColor?: string;
  onClick: () => void;
  onTagClick?: () => void;
  onFilterTagClick?: () => void;
  settings?: boolean;
  disabled?: boolean;
  active?: boolean;
}

const Tag: FC<TagProps> = ({
  name,
  color,
  bgColor,
  onClick,
  onTagClick,
  onFilterTagClick,
  settings,
  disabled,
  active,
}) => {
  const [showActions, setShowActions] = useState(false);

  const hover = (e: any) => {
    setShowActions(true);
  };

  const blur = (e: any) => {
    setShowActions(false);
  };

  return (
    <div
      className={`mr-2 tag-badge  ${showActions ? 'no-pd' : 'pd'}`}
      onMouseOver={e => !disabled && hover(e)}
      onMouseLeave={blur}
      style={{ color: color, background: bgColor }}
      onClick={e => {
        if (onFilterTagClick) {
          e.preventDefault();
          e.stopPropagation();
          onFilterTagClick();
        }
      }}
    >
      {name.length <= 20 ? (
        <span style={{ color }}>
          {name}
        </span>
      ) : (
        <Tooltip className="text-truncate" trigger={['hover', 'focus']} placement="bottom" text={name}>
          <span className="cut-tags" style={{ color }}>
            {name}
          </span>
        </Tooltip>
      )}
      <div className={`wrapper ${showActions ? 'hover' : 'leave'}`}>
        {settings && (
          <span
            className="badge-edit"
            style={{ cursor: !disabled ? 'pointer' : 'default', marginLeft: '0.5rem' }}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              !disabled && onTagClick && onTagClick();
            }}
          >
            {/* <PencilOutlineIcon size={10} /> */}
            {editIcon}
          </span>
        )}
        {
          <span
            className="badge-close"
            style={{ cursor: !disabled ? 'pointer' : 'default', marginLeft: '0.5rem' }}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              !disabled && onClick();
            }}
          >
            {closeIcon}
          </span>
        }
      </div>
    </div>
  );
};

export default Tag;
