import { FC, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../button';

import { nothingToShow } from 'assets/svg-icons';
import './styles.scss';
import { HexomaticUserContext } from 'contexts/hexomatic-user';

interface INothingToShow {
  text?: string;
  upgrade?: boolean;
}

const NothingToShow: FC<INothingToShow> = ({ text, upgrade }) => {
  // const { push } = useHistory();
  const {hexomaticUser} = useContext(HexomaticUserContext);

  return (
    <div className="with-center m-auto">
      <span>{nothingToShow}</span>
      <span className="empty">Nothing To Show! </span>
      {!upgrade && <span className="empty-list">{text || 'Your list is empty.'}</span>}
      {upgrade && hexomaticUser?.pricing_package === 'FREE' && (
        <Button
          name="Upgrade to Unlock"
          onClick={() => (window.location.href = 'https://hexomatic.com/pricing/')}
          className="mx-auto mt-2"
        />
      )}
    </div>
  );
};

export default NothingToShow;
