import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import CustomMultiSelect from 'shared-components/molecule/multiselect';
import Checkbox from 'shared-components/atom/checkbox';
import Input from 'shared-components/atom/input';
import Select from 'shared-components/atom/select';
import Alert from 'shared-components/atom/alert-message';
import { seoReferringDomainsOptions } from '../../constants';
import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import './styles.scss';

const domainOptions = [
  { label: 'domain', value: 'domain' },
  { label: 'webpage', value: 'webpage' },
];

const backlinksStatusTypeOptions = [
  { label: 'All', value: 'all' },
  { label: 'Live', value: 'live' },
  { label: 'Lost', value: 'Lost' },
];

const limitValues = [
  { label: '1000', value: 1000 },
  { label: '2000', value: 2000 },
  { label: '3000', value: 3000 },
  { label: '4000', value: 4000 },
  { label: '5000', value: 5000 },
  { label: '6000', value: 6000 },
  { label: '7000', value: 7000 },
  { label: '8000', value: 8000 },
  { label: '9000', value: 9000 },
  { label: '10,000', value: 10000 },
  { label: '15,000', value: 15000 },
  { label: '20,000', value: 20000 },
  { label: '25,000', value: 25000 },
];

const SEOReferringDomains: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [fieldList, setFieldList] = useState<any>([]);
  const [clicked, setClicked] = useState(false);
  const [source, setSource] = useState('');
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [selectedSourceType, setSelectedSourceType] = useState<any>({ label: 'domain', value: 'domain' });
  const [selectedMaxSearchCount, setSelectedMaxSearchCount] = useState(limitValues[0]);
  const [saveTo, setSaveTo] = useState<number | string>(limitValues[0].value);
  const [saveFrom, setSaveFrom] = useState<number | string>(1);
  const [errorMessage, setErrorMessage] = useState('');
  const [selected, setSelected] = useState<any>([]);
  const [backlinksStatusType, setBacklinksStatusType] = useState({ label: 'Live', value: 'live' });
  const [excludeInternalBacklinks, setExcludeInternalBacklinks] = useState(true);
  const zeroIndexOutput: string[] = automationIndex === 0 ? ['_seo_referring_target'] : [];
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const {
    liftingSettings,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (errorMessage) {
      return false;
    }
    if (selected?.length <= 0) return false;
    if (fieldList?.length === 0 ? !source : Array.isArray(selectedSource) ? !selectedSource?.length : !selectedSource) return false;
    if (typeof saveFrom !== 'number' || !saveTo) return false;
    return true;
  };

  useEffect(() => {
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const { keys } = dynamic_settings;
      const { settings } = keys[0];

      const referringDomainConfigs = settings ? settings.referringDomainConfigs : keys[0].referringDomainConfigs;
      const isDomain = settings ? settings.isDomain : keys[0].isDomain;
      const mode = settings ? settings.mode : keys[0].mode;
      // filters: JSON.stringify(finList),
      const exclude_internal_backlinks = settings
        ? settings.exclude_internal_backlinks
        : keys[0].exclude_internal_backlinks;
      const backlinks_status_type = settings ? settings.backlinks_status_type : keys[0].backlinks_status_type;
      const to = settings ? settings.to : keys[0].to;
      const from = settings ? settings.from : keys[0].from;
      const depth = settings ? settings.depth : keys[0].depth;
      const inputValue = settings ? keys[0].inputValue : keys[0].target;

      inputValue && setSource(inputValue);
      setSelectedSourceType({
        label: isDomain ? 'domain' : 'webpage',
        value: isDomain ? 'domain' : 'webpage',
      });
      const saveVal = limitValues.find((item: any) => item.value === (depth || to));
      typeof to === 'number' && setSaveTo(to);
      typeof from === 'number' && setSaveFrom(from);
      saveVal && setSelectedMaxSearchCount(saveVal);

      const arr = [] as any;
      referringDomainConfigs &&
        referringDomainConfigs.map((output: string) => {
          const val = seoReferringDomainsOptions.find(item => item.value === output);
          val && arr.push(val);
        });
      setSelected(arr);
      const selectedBacklinksStatusType =
        backlinks_status_type && backlinksStatusTypeOptions.find(item => backlinks_status_type === item.value);
      selectedBacklinksStatusType && setBacklinksStatusType(selectedBacklinksStatusType);
      typeof exclude_internal_backlinks === 'boolean' && setExcludeInternalBacklinks(exclude_internal_backlinks);
    }
  }, []);

  //recipe
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const key = dynamic_settings.keys[0].key;
      restoreSelectedSource({ key: key, fieldList: options, setSelectedSource: setSelectedSource });
    }
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [
          ...fieldList.map((item: { value: any }) => item.value),
          ...zeroIndexOutput,
          ...selected.map((item: any) => item.value),
        ],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, selected, fieldListWithTypes, automationOutputTypes]);

  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });

    let localSettings = {
      keys: [
        {
          inputValue: fieldList.length === 0 && automationIndex === 0 ? source.trim() : null,
          key: fieldList.length && selectedSource ? selectedSource.value : null,
          settings: {
            referringDomainConfigs: selected.map((item: { value: any }) => item.value),
            isDomain: selectedSourceType.value === 'domain' ? true : false,
            mode: 'as_is',
            // filters: JSON.stringify(finList),
            exclude_internal_backlinks: excludeInternalBacklinks,
            backlinks_status_type: backlinksStatusType.value || 'live',
            to: +saveTo,
            depth: selectedMaxSearchCount.value,
            from: +saveFrom,
          },
        },
      ],
    };
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: localSettings,
      outputKeysData: [
        ...fieldList.map((item: { value: any }) => item.value),
        ...zeroIndexOutput,
        ...selected.map((item: any) => item.value),
      ],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [
    source,
    errorMessage,
    selectedSource,
    selectedSourceType,
    saveTo,
    saveFrom,
    selectedMaxSearchCount,
    selectedSourceType,
    selected,
    excludeInternalBacklinks,
    backlinksStatusType,
    fieldList,
    fieldListWithTypes,
    automationOutputTypes,
    automationIndex,
  ]);

  const clearErrorMsg = () => {
    setTimeout(() => {
      setErrorMessage('');
    }, 3000);
  };

  const handleSaveFrom = (e: { target: { value: any } }) => {
    errorMessage && setErrorMessage('');
    let val = e.target.value;
    val = val === '' ? '' : +val;
    if (isNaN(val)) {
      setErrorMessage('Please use only numbers');
      clearErrorMsg();
      return;
    }
    if (selectedMaxSearchCount.value <= val) {
      setErrorMessage('"Save from" value can`t be greater than or equal to "Limit" value');
      clearErrorMsg();
      return;
    }
    if (saveTo < val) {
      setErrorMessage('"Save from" value can`t be greater than or equal to "Save to" value');
    }
    if (val === '') {
      setErrorMessage('Please fill "Save from" field');
    }
    if (saveTo === '') {
      setErrorMessage('Please fill "Save to" field');
    }
    setSaveFrom(val);
    !clicked && setClicked(true);
    setIsDirty();
  };

  const handleSaveTo = (e: { target: { value: any } }) => {
    errorMessage && setErrorMessage('');
    let val = e.target.value;
    val = val === '' ? '' : +val;
    if (isNaN(val)) {
      setErrorMessage('Please use only numbers');
      clearErrorMsg();
      return;
    }
    if (selectedMaxSearchCount.value < val) {
      setErrorMessage('"Save to" value can`t be greater than "Search result" value');
      clearErrorMsg();
      return;
    }
    if (saveFrom > val) {
      setErrorMessage('"Save to" value can`t be less than or equal to "Save from" value');
    }
    if (val === '') {
      setErrorMessage('Please fill "Save to" field');
    }
    if (saveFrom === '') {
      setErrorMessage('Please fill "Save from" field');
    }
    setSaveTo(val);
    !clicked && setClicked(true);
    setIsDirty();
  };

  return (
    <div className="google-automation-settings">
      <Row>
        {fieldList.length > 0 || automationIndex > 0 ? (
          <Col lg={6} className="mb-4">
            <span className="label secondary-color">Source</span>
            <Select
              options={fieldList}
              value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
              placeholder={'Select source'}
              onChange={(selectedItem: any) => {
                setSelectedSource(selectedItem);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
        ) : (
          <Col lg={6} className="mb-4">
            <span className="label secondary-color">{selectedSourceType.value === 'domain' ? 'Domain' : 'URL'}</span>
            <Input
              value={source}
              onChange={e => {
                setSource(e.target.value);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
        )}
        <Col xl={6} className="search-fields">
          <Row>
            <Col lg={4} xl={4} className="mb-4">
              <span className="label secondary-color">Limit</span>
              <Select
                options={limitValues}
                value={selectedMaxSearchCount}
                placeholder={'Select limit'}
                onChange={(selectedItem: any) => {
                  setSelectedMaxSearchCount(selectedItem);
                  !clicked && setClicked(true);
                  setIsDirty();
                }}
              />
            </Col>
            <Col lg={4} xl={4} className="mb-4 results-from-to">
              <h5 className="pointer-title">From</h5>
              <div>
                <span className="label secondary-color" style={{ whiteSpace: 'nowrap' }}>
                  Save results
                </span>
                <Input value={saveFrom} onChange={handleSaveFrom} />
              </div>
            </Col>
            <Col lg={4} xl={4} className="mb-4 results-from-to">
              <h5 className="pointer-title">To</h5>
              <div>
                <span className="label secondary-color" style={{ visibility: 'hidden' }}>
                  to
                </span>
                <Input value={saveTo} onChange={handleSaveTo} />
              </div>
            </Col>
          </Row>
        </Col>

        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Explore mode</span>
          <Select
            options={domainOptions}
            value={selectedSourceType}
            placeholder={'Select mode'}
            onChange={(selectedItem: any) => {
              setSelectedSourceType(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Fields</span>
          <CustomMultiSelect
            options={seoReferringDomainsOptions}
            value={selected}
            onChange={(sel: any) => {
              setSelected(sel);
              !clicked && setClicked(true);
              setIsDirty();
            }}
            labelledBy="Select"
          />
        </Col>
        <Col lg={12}>
          <Row>
            <Col lg={6}>
              <span className="label secondary-color">Status type</span>
              <Select
                options={backlinksStatusTypeOptions}
                value={backlinksStatusType}
                placeholder={'Select mode'}
                onChange={(selectedItem: any) => {
                  setBacklinksStatusType(selectedItem);
                  !clicked && setClicked(true);
                  setIsDirty();
                }}
                textDefault
              />
            </Col>
            <Col lg={6} className="checkbox-wrapper">
              <div className="item-block mt-3">
                <Checkbox
                  checked={excludeInternalBacklinks}
                  label="Exclude internal backlinks"
                  onChange={() => {
                    setExcludeInternalBacklinks(!excludeInternalBacklinks);
                    !clicked && setClicked(true);
                    setIsDirty();
                  }}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {errorMessage && (
        <Row className="mt-2">
          <Col md={12}>
            <Alert type="error" className="create-workflow">
              {errorMessage}
            </Alert>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default React.memo(SEOReferringDomains);
