import { FC, memo, useEffect, useContext, useState, useRef, useCallback } from 'react';
import { ThemeContext } from 'contexts/theme-context';
import { isValidKeyword, isValidWorkflowName } from 'constants/validations';
import useOutsideClickNormal from 'hooks/useOutsideClickNormal';

import Loader from 'shared-components/atom/loader';
// import ModalComponent from 'shared-components/molecule/modal';
import Tooltip from 'shared-components/atom/tooltip';
import { checkedIcon, closeIcon, editIcon, tagIconNew } from 'assets/svg-icons';
import { eventStop } from 'helpers';
import './styles.scss';

interface IRecipeName {
  id: number;
  name: string;
  isDescription?: boolean;
  propLoading?: boolean;
  className?: string;
  onSave?: any;
  maxTextCount?: number;
  absoluteError?: boolean;
  setOpenInfo?: (num: any) => void;
  openedInfo?: any;
  errorMessageLength?: number;
  addTag?: any;
  allowingToAddTag?: boolean;
  showTooltip?: boolean;
  tags?: any;
  checkWidth?: boolean;
  setCheckWidth?: (val: boolean) => void;
  viewOption?: 'tableView' | 'cardView';
}

const EditableText: FC<IRecipeName> = ({
  id,
  name,
  propLoading,
  className,
  onSave,
  maxTextCount,
  absoluteError,
  setOpenInfo,
  openedInfo,
  errorMessageLength,
  isDescription,
  addTag,
  allowingToAddTag,
  showTooltip,
  tags,
  checkWidth,
  setCheckWidth,
  viewOption,
}) => {
  const { theme } = useContext(ThemeContext);
  const [alertMessage, setAlertMessage] = useState('');
  const [text, setText] = useState(name || '');
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isForceRerender, setIsForceRerender] = useState<any>(null);

  const ref = useRef<any>(null);
  const [outsideClick, elRef, setOutsideClick, setEnabledHook] = useOutsideClickNormal({
    propBool: false,
    enableListener: false,
  });

  useEffect(() => {
    if (((text && (!text.trim() || !isValidWorkflowName(text))) || !text) && !isDescription) {
      setAlertMessage('Invalid name');
      return;
    }

    if (((text && (!text.trim() || !isValidKeyword(text))) || !text) && isDescription) {
      setAlertMessage('Invalid description');
      return;
    }

    if (text && text.trim() && isValidWorkflowName(text)) {
      setAlertMessage('');
    }
  }, [text]);

  useEffect(() => {
    if (openedInfo === id) {
      !editMode && setEditMode(true);
      setEnabledHook(true);
    } else {
      editMode && setEditMode(false);
    }
  }, [openedInfo]);

  useEffect(() => {
    if (editMode && outsideClick) {
      setLoading(true);
      setOutsideClick(false);
      sendSave(id);
    }
  }, [outsideClick]);

  useEffect(() => {
    let timerID: any = null;
    const tick = () => {
      setIsForceRerender(new Date());
    };
    if (isForceRerender) {
      clearInterval(timerID);
      return;
    }
    timerID = setInterval(() => tick(), 500);
    return () => clearInterval(timerID);
  }, [isForceRerender]);

  useEffect(() => {
    setCheckWidth && setCheckWidth(ref?.current?.scrollWidth !== ref?.current?.offsetWidth);
  }, [editMode, ref?.current, isForceRerender]);

  // const handleEnterKeyPress = useCallback(
  //   async (e: any) => {
  //     if (e.key === 'Enter') {
  //       setLoading(true);
  //       sendSave(id);
  //     }
  //   },
  //   [id],
  // );

  const handleEnterKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      setLoading(true);
      sendSave(id);
    }
  };

  const sendSave = useCallback(
    async (id: any) => {
      if (text.trim() === name) {
        setOpenInfo && setOpenInfo(null);
        setEditMode(false);
        setOutsideClick(false);
        setLoading(false);
        return;
      }
      setLoading(true);
      const data = await onSave({ id, text });
      setLoading(false);
      if (data && data.message) {
        setAlertMessage(data.message);
        setOutsideClick(false);
        // setEnabledHook(true);
        return;
      }
      setOpenInfo && setOpenInfo(null);
      setEditMode(false);
    },
    [text, name, onSave, setOpenInfo],
  );

  const handelEdit = useCallback(
    (e: any) => {
      eventStop(e);
      setText(name);
      setEditMode(true);
      setEnabledHook(true);
      setAlertMessage('');
      setOpenInfo && setOpenInfo(id);
    },
    [name, setOpenInfo, id],
  );

  const handelAddTag = useCallback(
    (e: any) => {
      eventStop(e);
      addTag(id);
    },
    [addTag, id],
  );

  const handelInputChange = useCallback(
    (e: any) => {
      const value = e.target.value;
      if (maxTextCount && value.length > maxTextCount) {
        return;
      }
      setText(e.target.value);
    },
    [maxTextCount],
  );

  const handelSave = useCallback(
    (e: any) => {
      eventStop(e);
      setLoading(true);
      sendSave(id);
    },
    [sendSave, id],
  );

  const handelCancel = useCallback((e: any) => {
    eventStop(e);
    setEditMode(false);
    setText('');
    setAlertMessage('');
  }, []);

  return (
    <>
      {!editMode ? (
        <span
          className={`${
            viewOption === 'cardView' ? 'workflow-card-view' : ''
          } m-vertical-auto d-flex text-edit-component`}
        >
          <Tooltip
            text={showTooltip && checkWidth ? name : ''}
            className={tags ? 'editable-text-tooltip with-tags' : 'editable-text-tooltip'}
            isSmall
            placement="bottom"
            maxLengthInRow={30}
          >
            <h5 className={`recipe-${isDescription ? 'description' : 'name'}-wrapper ${viewOption === 'cardView' ? 'ml-3 mr-2' : ''} cut-text`} ref={ref} id="editable-name">
              {name}
            </h5>
          </Tooltip>

          {tags && tags}
          <div className="edit-icons-flex">
            {addTag && (
              <span className="edit-icon tag" onClick={handelAddTag}>
                {allowingToAddTag ? (
                  <span>{tagIconNew}</span>
                ) : (
                  <Tooltip
                    trigger={['hover', 'focus', 'click']}
                    placement="bottom"
                    text="You've reached tags limit per workflow"
                  >
                    {tagIconNew}
                  </Tooltip>
                )}
              </span>
            )}
            <span className="edit-icon" onClick={handelEdit}>
              {editIcon}
            </span>
          </div>
        </span>
      ) : (
        <span className="m-vertical-auto no-wrap text-edit-component" ref={elRef}>
          <span className={`input-wrap ${absoluteError ? 'd-column' : ''}`}>
            <input
              className={`editable-input px-1 change-input ${className ? className : ''} ${
                className && alertMessage ? 'error' : absoluteError && alertMessage ? 'error-border' : ''
              } ${alertMessage ? 'red' : ''}`}
              autoFocus
              placeholder={text}
              value={text}
              onChange={handelInputChange}
              onClick={eventStop}
              onKeyDown={handleEnterKeyPress}
            />
            {absoluteError && alertMessage ? (
              <small className="absolute-error">
                {alertMessage.length > (errorMessageLength || 40)
                  ? alertMessage.slice(0, errorMessageLength || 40) + '...'
                  : alertMessage}
              </small>
            ) : (
              <></>
              // alertMessage && (
              //   <span className="mr-2">
              //     <AlertComponent type="error" text={alertMessage} />
              //   </span>
              // )
            )}
            {/* {alertMessage && (
              <ModalComponent
                isModalOpen={!!alertMessage}
                text={alertMessage}
                actionBtnText={'OK'}
                actionBtnColor={'error'}
                onActionClick={() => setAlertMessage('')}
              />
            )} */}
          </span>

          {loading || propLoading ? (
            <span className={`d-inline-block ${className ? '' : 'h-100'}`}>
              <Loader size="sm" light={theme === 'light'} className="d-inline-block m-0 p-0 align-middle" />
            </span>
          ) : (
            <>
              <span className="check-icon cursor-pointer mr-2" onClick={handelSave}>
                {checkedIcon}
              </span>
              <span className="cancel-icon cursor-pointer" onClick={handelCancel}>
                {closeIcon}
              </span>
            </>
          )}
        </span>
      )}
    </>
  );
};

export default memo(EditableText);
