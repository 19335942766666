import { checkedCircleIcon } from 'assets/svg-icons';
import { useHistory } from 'react-router';
import Button from 'shared-components/atom/button';

const Upgrade = ({ cancel }: { cancel: () => void }) => {
  const history = useHistory();

  return (
    <div className="upgrade-package">
      <span className="modal-head-icon pb-5">{checkedCircleIcon}</span>

      <div className="title my-4">Please upgrade to the Bronze or above plan to enable this feature</div>
      <div className="d-flex justify-content-center mt-4">
        <Button outline onClick={cancel} className="mr-3" name="Cancel" />
        <Button
          onClick={() => {
            cancel();
            window.scrollTo(0, 0);
            window.location.href = 'https://hexomatic.com/pricing/';
          }}
          name="Upgrade now"
        />
      </div>
    </div>
  );
};
export default Upgrade;
