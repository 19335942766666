import { useEffect, useState, useContext, useRef, useCallback, Fragment, useMemo } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useSubscription } from '@apollo/react-hooks';
import { useMediaQuery } from 'react-responsive';

import { ThemeContext } from 'contexts/theme-context';
import { HexomaticUserContext } from 'contexts/hexomatic-user';
import { UserContext } from 'contexts/user-context';
import { useTagsMethods } from './hooks';

import { GET_PUBLIC_WORKFLOWS, GET_USER_WATCH_TAGS, GET_WORKFLOWS } from 'graphql/queries';
import {
  UPDATE_WORKFLOW,
  DELETE_WORKFLOW,
  CHANGE_WORKFLOW_STATE,
  CREATE_NEW_PUBLIC_WORKFLOW,
  DUPLICATE_WORKFLOW,
  UPDATE_WORKFLOW_TAGS,
  IMPORT_WORKFLOW,
  UPDATE_PUBLIC_WORKFLOW,
  DISABLE_WORKFLOW_TRIGGER,
} from 'graphql/mutations';

import { WORKFLOW_STATUS_SUBSCRIPTION } from 'graphql/subscriptions';
import { isValidKeyword, isValidWorkflowName } from 'constants/validations';
import { publicAllowedUserIDs } from 'constants/index';
import { ScheduleConfigType, WorkflowsProps } from './types';

import { IworkflowProps } from 'shared-components/atom/types';
import Loader from 'shared-components/atom/loader';
import SearchContainer from 'shared-components/molecule/search-container/updated';
import SearchContainer1 from 'shared-components/molecule/search-container-copy/updated';
import Modal from 'shared-components/molecule/modal';
import Payment from 'shared-components/molecule/payment';
import CustomModal from 'shared-components/molecule/customModal';
import ModalComponent from 'shared-components/molecule/modal';
import ToastLoader from 'shared-components/molecule/toast-loader';
import ModalTagContainer from 'shared-components/molecule/modal-container';
import ModalCalendar from 'shared-components/molecule/modal-calendar';
import ContactUs from 'shared-components/molecule/payment/contact-us';
import VideoModal from 'shared-components/atom/video-modal';
import NothingToShow from 'shared-components/atom/nothing-to-show';
import TemplatesSection from 'shared-components/organizm/templates-section';
import TableCardFacadeLoading from 'shared-components/molecule/table-card-facade-loading';
import TagsContent from 'pages/settings/TagsContent';

import ExportModalWithOptions from './components/export-modal-with-options';
import ShareWorkflowModal from './components/share-workflow-modal';
import WorkflowCard from './components/workflow-card';
import WorkflowTable from './components/workflow-table';
import NothingToShowRecommended from './components/nothing-to-show-recommended';

import { loopIcon } from 'assets/svg-icons';
import './styles.scss';

export default function Workflows({ hide }: WorkflowsProps) {
  //-------------Context------------------------
  const { theme } = useContext(ThemeContext);
  const { hexomaticUser } = useContext(HexomaticUserContext);
  const { user } = useContext(UserContext);
  //--------------------------------------------
  const { search } = useLocation();
  const { push } = useHistory();

  const mounted = useRef();
  const mounted1 = useRef();
  //----------------------State--------------------
  const [details] = useState<{
    page: number;
    searchText: string;
    selectedOption: {
      label: string;
      value: number | string | null;
      is_public: boolean | null;
      is_scheduled: boolean | null;
      status: string | number | null;
    };
    selectedTypeOption: { label: string; value: number | string | null };
    selectedTagOption: any[];
    selectedAutomations: any[];
  }>(
    //@ts-ignore
    localStorage.getItem('worklows-details')
      ? JSON.parse(localStorage.getItem('worklows-details') || '')
      : {
          page: 1,
          searchText: '',
          selectedOption: {
            label: 'All',
            value: null,
          },
          selectedTypeOption: {
            label: 'All',
            value: null,
          },
          selectedTagOption: [],
          selectedAutomations: [],
        },
  );

  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [shouldUpgrade, setShouldUpgrade] = useState(false);
  const [buyPremiumCredits, setBuyPremiumCredits] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [makingPublic, setMakingPublic] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [showTagModal, setShowTagModal] = useState(false);
  const [isTagsChange, setIsTagsChange] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [isSuccessfullyShared, setIsSuccessfullyShared] = useState(false);
  const [trafficInsightsWarning, setTrafficInsightsWarning] = useState(false);
  const [filterPopupOpen, setFilterPopupOpen] = useState<any>(false);
  // const [isClickedLoadMore, setIsClickedLoadMore] = useState<boolean>(false);
  const [customSchedule, setCustomSchedule] = useState(false);
  const [triggerWorkflow, setTriggerWorkflow] = useState(false);
  const [stateValue, setStateValue] = useState(false);
  // const [wizardToggle, setWizardToggle] = useState(
  //   () =>
  //     localStorage.getItem('workflow-starter-mode') === 'wizard' || !!!localStorage.getItem('workflow-starter-mode'),
  // );
  const [searchText, setSearchText] = useState(details.searchText);
  const [checkedIndexes, setCheckedIndexes] = useState([] as any);
  const [workflowsList, setWorkflowsList] = useState([] as any[]);
  const [isWorkflowListReceived, setIsWorkflowListReceived] = useState(false);
  const [startSearch, setStartSearch] = useState(setTimeout(() => {}));
  const [openedId, setOpenedId] = useState<any>(null);
  const [openedIdDesc, setOpenedIdDesc] = useState<any>(null);
  const [realTimeStatusData, setRealTimeStatusData] = useState(null as any);
  const [alertMsg, setAlertMsg] = useState<{ show: boolean; text: string; type: 'error' | 'success' | '' }>({
    show: false,
    text: '',
    type: '',
  });
  const [infoModal, setInfoModal] = useState<{ message: string; error: boolean; open: boolean }>({
    message: '',
    error: false,
    open: false,
  });

  const [selectedWorkflowId, setSelectedWorkflowId] = useState<any>(null);
  const [selectedHeaders, setSelectedHeaders] = useState(null as any);
  const [toasterInfo, setToasterInfo] = useState({
    show: false,
    errorMessage: '',
    loadingMessage: '',
    successMessage: '',
  });

  const [isAdmin, setIsAdmin] = useState(publicAllowedUserIDs.includes(user.id));
  const [selectedTag, setSelectedTag] = useState<any>(null);
  const [workflowId, setWorkflowId] = useState<any>(null);
  const [scheduleSettings, setScheduleSettings] = useState(null as any);
  const [trigger, setTrigger] = useState<any>({
    watchPropertyId: undefined,
    watchIntegrationId: undefined,
  });
  const [selectedOutputKeys, setSelectedOutputKeys] = useState(null);
  const [sendMail, setSendMail] = useState(null);
  const [shouldContinue, setShouldContinue] = useState<{ isOpen: boolean; status: string; item?: IworkflowProps }>({
    isOpen: false,
    status: '',
  });

  const [isPublic, setIsPublic] = useState<any>(null);
  const [isScheduled, setIsScheduled] = useState<any>(null);
  const [isShared, setIsShared] = useState<any>(null);
  const [filteredAutomations, setFilteredAutomations] = useState<any>([]);
  const [filteredTags, setFilteredTags] = useState<any>([]);
  const [filteredStatus, setFilteredStatus] = useState<any>(null);
  const [filtersCount, setFiltersCount] = useState(0);

  const [selectedTypeOption, setSelectedTypeOption] = useState<{ label: string; value: number | string | null }>(
    details.selectedTypeOption,
  );
  const [optionParameters, setOptionParameters] = useState<{
    label: string;
    value: number | string;
    status?: string;
    recipeIds?: any;
    frequency?: string;
    includePremium?: boolean;
  }>({
    label: '',
    value: '',
    recipeIds: [],
  });
  const [workflowIds, setWorkflowIds] = useState([] as any);
  const [id, setId] = useState<number>(0);
  const [sort, setSort] = useState<{ by: string; id: number }>(
    localStorage.getItem('workflow-sort')
      ? JSON.parse(localStorage.getItem('workflow-sort') || '')
      : {
          by: 'DESC',
          id: 1,
        },
  );
  const [page, setPage] = useState<number>(details.page);
  //@ts-ignore
  const [limit, setLimit] = useState<{ label: string; value: number }>(
    //@ts-ignore
    localStorage.getItem('workflowLimit')
      ? //@ts-ignore
        JSON.parse(localStorage.getItem('workflowLimit'))
      : { label: '15', value: 15 },
  );
  // const isZoom = useMediaQuery({minWidth: 1300, maxWidth: 1800});
  const isMobile = useMediaQuery({ maxWidth: 991 });

  const [cardLimit, setCardLimit] = useState(24);
  const [nameChanged, setNameChanged] = useState(false);
  const [publicWorkflowsList, setPublicWorkflowsList] = useState([] as any[]);
  const [viewMode, setViewMode] = useState<'listView' | 'cardView'>(
    //@ts-ignore
    localStorage.getItem('workflow-view-mode')
      ? localStorage.getItem('workflow-view-mode') || (isMobile ? 'cardView' : 'listView')
      : isMobile
      ? 'cardView'
      : 'listView',
  );
  const [count, setCount] = useState<number>(0);
  const [tableMaxHeight, setTableMaxHeight] = useState(800);

  const [schedule_config, setScheduleConfig] = useState<null | ScheduleConfigType>(null);
  const [startedAt, setStartedAt] = useState(null as any);
  //---------------------Queries------------------------
  const [updateWorkflow, { loading: updateLoading }] = useMutation(UPDATE_WORKFLOW);
  const [disableTrigger, { loading: disableTriggerLoading }] = useMutation(DISABLE_WORKFLOW_TRIGGER);

  const [importWorkFlow] = useMutation(IMPORT_WORKFLOW);

  const [makePublic, { loading: publicPrivateLoading }] = useMutation(CREATE_NEW_PUBLIC_WORKFLOW);
  const [updatePublic, { loading: updatePublicDataLoading }] = useMutation(UPDATE_PUBLIC_WORKFLOW);

  const [deleteWorkflow, { error: err, loading: deleteLoading }] = useMutation(DELETE_WORKFLOW);

  const [updateWorkflowState, { error: stateErr, loading: stateLoading }] = useMutation(CHANGE_WORKFLOW_STATE);

  const [copyWorkflow, { loading: copyingLoading }] = useMutation(DUPLICATE_WORKFLOW);

  const { data: workflowRealTimeStatusData } = useSubscription(WORKFLOW_STATUS_SUBSCRIPTION, {
    skip: !hexomaticUser?.id,
    variables: {
      hexomaticUserId: hexomaticUser?.id,
    },
  });

  const { data: publicWorkflows } = useQuery(GET_PUBLIC_WORKFLOWS, {
    fetchPolicy: 'no-cache',
    variables: {
      settings: {
        filter: {
          page: 1,
          most_used: true,
        },
      },
    },
  });

  const [updateWorkflowTags] = useMutation(UPDATE_WORKFLOW_TAGS);
  const { data: userTags } = useQuery(GET_USER_WATCH_TAGS);

  const {
    data: workflows,
    loading,
    refetch,
  } = useQuery(GET_WORKFLOWS, {
    fetchPolicy: 'no-cache',
    variables: {
      settings: {
        filter: {
          page: viewMode === 'cardView' ? 1 : page,
          limit: viewMode === 'cardView' ? cardLimit : limit.value,
          // date_from: null,
          // date_to: null,
          // sort_order: null,
          sort_by_creation_date: sort.id === 1,
          sort_by_updated_date: sort.id === 2,
          sort_by_name: sort.id === 3,
          // sort_by_status: sort.id === 4,
          sort_by_storage: sort.id === 5,
          sort_by: sort.by,
          //active: selectedTypeOption.value,
          // automation_kind_id: null,
          last_status: filteredStatus,
          is_public: !!isPublic || null,
          is_scheduled: !!isScheduled || null,
          is_shared: !!isShared || null,
          // status: null,
          search: {
            search_field: null,
            search_value: searchText,
          },
          automation_ids: filteredAutomations.map((item: { value: number; label: string }) => item.value),
          tags: filteredTags.map((item: { value: string; label: string }) => +item.value),
        },
        get_total: true,
      },
    },
  });

  useEffect(() => {
    sort && localStorage.setItem('workflow-sort', JSON.stringify(sort));
  }, [sort]);

  useEffect(() => {
    const innerHeight = window.innerHeight;
    const headerEl = document.getElementById('header');
    const headerHeight = headerEl?.offsetHeight;
    innerHeight &&
      headerHeight &&
      setTableMaxHeight(innerHeight - headerHeight - 220 > 230 ? innerHeight - headerHeight - 220 : 230);
  }, [window.innerHeight]);

  const handleFiltersApply = async (filtersList: any, doNotSet?: boolean) => {
    !doNotSet && setPage(1);
    !doNotSet && localStorage.setItem('workflows-filtration', JSON.stringify(filtersList));
    const statusObj = filtersList.find((item: any) => item.label === 'Status');
    const tagsObj = filtersList.find((item: any) => item.label === 'Tags');
    const automationsObj = filtersList.find((item: any) => item.label === 'Automations');
    const publicObj = filtersList.find((item: any) => item.label === 'Public');
    const scheduledObj = filtersList.find((item: any) => item.label === 'Scheduled');
    const sharedObj = filtersList.find((item: any) => item.label === 'Shared');
    setFiltersCount(filtersList.length);
    if (statusObj) {
      setFilteredStatus(statusObj.selectedSubcategories[0]?.value);
    } else {
      setFilteredStatus(null);
    }
    if (tagsObj) {
      setFilteredTags(tagsObj.selectedSubcategories);
    } else {
      setFilteredTags([]);
    }
    if (automationsObj) {
      setFilteredAutomations(automationsObj.selectedSubcategories);
    } else {
      setFilteredAutomations([]);
    }
    if (publicObj) {
      setIsPublic(true);
    } else {
      setIsPublic(null);
    }
    if (scheduledObj) {
      setIsScheduled(true);
    } else {
      setIsScheduled(null);
    }
    if (sharedObj) {
      setIsShared(true);
    } else {
      setIsShared(null);
    }
    setFilterPopupOpen(false);
  };

  //--------------------useEffect-----------------------
  useEffect(() => {
    const list = localStorage.getItem('workflows-filtration');
    const parsedList = list && JSON.parse(list);
    parsedList && handleFiltersApply(parsedList, true);
    refetch();
  }, []);

  useEffect(() => {
    if (user) setIsAdmin(publicAllowedUserIDs.includes(user.id));
  }, [user]);

  useEffect(() => {
    if (publicWorkflows?.HexomaticPublicWorkflow?.getPublicWorkflows?.public_workflows) {
      setPublicWorkflowsList(
        publicWorkflows.HexomaticPublicWorkflow.getPublicWorkflows.public_workflows.map((item: any) => {
          return {
            ...item,
            linkTo: `/workflows/current-workflow-template/${item.id}`,
          };
        }),
      );
    }
  }, [publicWorkflows]);

  useEffect(() => {
    if (search.includes('token=') && !search.includes('ba_token')) {
      (async function () {
        const { data } = await importWorkFlow({
          variables: {
            settings: {
              token: search.slice(7),
            },
          },
        });
        const { error_code } = data?.HexomaticWorkflowOps?.importSharedWorkflow;
        if (error_code === "Can't create workflow") {
          setShouldUpgrade(true);
          return;
        } else {
          if (error_code) {
            if (error_code === 'CANNOT_SHARE_UPGRADE_PACKAGE') {
              setShouldUpgrade(true);
              return;
            }
            handleToastShow(true, 'Something went wrong', '', '');
            return;
          }
        }
        refetch();
      })();
    }
  }, [search]);

  useEffect(() => {
    if (!showTagModal && isTagsChange) {
      setIsTagsChange(false);
      refetch();
    }
  }, [showTagModal]);

  useEffect(() => {
    if (
      (updateLoading && !nameChanged) ||
      deleteLoading ||
      stateLoading ||
      copyingLoading ||
      publicPrivateLoading ||
      updatePublicDataLoading
    ) {
      const loadingMessage = publicPrivateLoading
        ? makingPublic
          ? 'Making public...'
          : 'Making private...'
        : updatePublicDataLoading
        ? 'Updating public workflow'
        : updateLoading
        ? 'In process...'
        : deleteLoading
        ? 'Deleting...'
        : copyingLoading
        ? 'Duplicating...'
        : stateLoading && !stateValue
        ? 'Pausing...'
        : stateLoading && stateValue && 'Activating...';
      handleToastShow(true, '', loadingMessage || '', '');
    }
  }, [updateLoading, deleteLoading, stateLoading, copyingLoading, publicPrivateLoading, updatePublicDataLoading]);

  useEffect(() => {
    const obj = realTimeStatusData ? { ...realTimeStatusData } : {};
    if (workflowRealTimeStatusData?.workflowStatusEvent?.workflowId) {
      obj[workflowRealTimeStatusData.workflowStatusEvent.workflowId] = {
        status: workflowRealTimeStatusData.workflowStatusEvent.progress.status,
        active: workflowRealTimeStatusData.workflowStatusEvent.active,
        storage: workflowRealTimeStatusData.workflowStatusEvent.storage,
        next_scan_time: workflowRealTimeStatusData.workflowStatusEvent.next_scan_time,
        currentAutomation: workflowRealTimeStatusData.workflowStatusEvent.currentAutomation,
        currentIteration: workflowRealTimeStatusData.workflowStatusEvent.currentIteration,
        lastActivityTime: workflowRealTimeStatusData.workflowStatusEvent.lastActivityTime,
      };
    }
    setRealTimeStatusData(obj);
  }, [workflowRealTimeStatusData]);

  // useEffect(() => {
  //   hide && setViewMode('listView');
  // }, [hide]);

  useEffect(() => {
    if (isSuccessfullyShared) refetch();
  }, [isSuccessfullyShared]);

  useEffect(() => {
    //if (viewMode === 'cardView' && workflowsList.length > page * 20) return;
    if (workflows?.HexomaticWorkflow?.getWorkflows?.workflows) {
      // setIsClickedLoadMore(false);
      setWorkflowsList(workflows.HexomaticWorkflow.getWorkflows.workflows);
      setIsWorkflowListReceived(true);
      setRealTimeStatusData(null);
    }
    if (workflows?.HexomaticWorkflow?.getWorkflows?.count) {
      setCount(workflows?.HexomaticWorkflow?.getWorkflows?.count);
    }
  }, [workflows]);

  useEffect(() => {
    localStorage.setItem('workflows-count', `${count || ''}`);
  }, [count]);

  useEffect(() => {
    //@ts-ignore
    const arr = workflowsList.map(item => (item = item.id));
    setWorkflowIds(arr);

    if (!workflowsList.length && localStorage.getItem('workflow-starter-mode') === null) {
      localStorage.setItem('workflow-starter-mode', 'wizard');
    }

    // if (workflowsList.length) {
    //   localStorage.setItem('workflow-starter-mode', 'advanced');
    // }
  }, [workflowsList]);

  const getIncludesTrafficInsights = useCallback(
    (val: any) => {
      const filteredList = workflowsList.filter((sub: any) => val === sub.id);
      if (
        filteredList.find((item: any) =>
          item.steps.find((step: any) => step.automation_id === 38 || step.automation_id === 11),
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    [workflowsList],
  );

  useEffect(() => {
    if (optionParameters.label === 'Restart workflow') {
      if (getIncludesTrafficInsights(+optionParameters.value)) {
        setTrafficInsightsWarning(true);
        return;
      }
      if (optionParameters.status !== 'DRAFT') {
        setShouldContinue({ isOpen: true, status: 'run_now' });
      } else {
        changeActive(+optionParameters.value, true, new Date().toISOString());
      }
    }

    if (optionParameters.label === 'Duplicate workflow') {
      if (
        hexomaticUser?.pricing_package === 'FREE'
        // (hexomaticUser?.pricing_package === 'BRONZE' && count >= 5) ||
        // (hexomaticUser?.pricing_package === 'SILVER' && count >= 10)
      ) {
        setShouldUpgrade(true);
      } else {
        handleCopyWorkflow(optionParameters.value);
      }
    }

    if (optionParameters.label === 'Make public' || optionParameters.label === 'Make private') {
      onMakePublic({
        id: optionParameters.value,
        is_public: optionParameters.label === 'Make public',
        recipeIds: optionParameters.recipeIds,
      });
    }
    if (optionParameters.label === 'Sync public workflow') {
      onUpdatePublic({
        id: optionParameters.value,
        recipeIds: optionParameters.recipeIds,
      });
    }
    if (optionParameters.label === 'Share Workflow') {
      if (hexomaticUser?.pricing_package === 'FREE') {
        setShouldUpgrade(true);
      } else {
        setShareModal(true);
      }
    }
    if (optionParameters.label === 'Schedule workflow') {
      if (hexomaticUser?.pricing_package === 'FREE' || hexomaticUser?.pricing_package === 'BRONZE') {
        setShouldUpgrade(true);
      } else {
        const filteredList = workflowsList.filter((sub: any) => +optionParameters.value === sub.id);
        if (
          filteredList.find((item: any) =>
            item.steps.find((step: any) => step.automation_id === 38 || step.automation_id === 11),
          )
        ) {
          setTrafficInsightsWarning(true);
          return;
        }
        setCustomSchedule(true);
      }
    }
    if (optionParameters.label === 'Trigger via Hexowatch') {
      const filteredList = workflowsList.filter((sub: any) => +optionParameters.value === sub.id);
      if (
        filteredList.find((item: any) =>
          item.steps.find((step: any) => step.automation_id === 38 || step.automation_id === 11),
        )
      ) {
        setTrafficInsightsWarning(true);
        return;
      }
      setTriggerWorkflow(true);
    }
  }, [optionParameters]);

  useEffect(() => {
    setIsCheckedAll(false);
    setCheckedIndexes([]);
  }, [limit]);

  useEffect(() => {
    if (!mounted.current) {
      //@ts-ignore
      mounted.current = true;
    } else {
      setIsCheckedAll(false);
      setCheckedIndexes([]);
      localStorage.setItem(
        'worklows-details',
        JSON.stringify({
          page: page,
          searchText: searchText,
        }),
      );
    }
  }, [page, searchText, filteredTags]);

  useEffect(() => {
    if (!mounted1.current) {
      //@ts-ignore
      mounted1.current = true;
    } else {
      setPage(1);
    }
  }, [searchText, selectedTypeOption, limit]);

  useEffect(() => {
    if (toasterInfo.show && (toasterInfo.errorMessage || toasterInfo.successMessage)) {
      setTimeout(
        () => {
          handleToastShow(false, '', '', '');
        },
        toasterInfo.successMessage && typeof toasterInfo.successMessage !== 'string' ? 30000 : 5000,
      );
    }
  }, [toasterInfo]);

  useEffect(() => {
    setWorkflowsList([]);
    setIsWorkflowListReceived(false);
  }, [viewMode]);

  // useEffect(() => {
  //   if (isChangeAutomationsList) {
  //     // setSelectedAutomations(selectedAutomationsList);
  //     setIsChangeAutomationsList(false);
  //   }
  //   if (isChangeTagsList) {
  //     setIsChangeTagsList(false);
  //   }
  // }, [isChangeAutomationsList, isChangeTagsList]);

  //-----------------------------------------------
  const handleToastShow = (show: boolean, errorMessage: string, loadingMessage: string, successMessage: any) => {
    setToasterInfo({ show: show, errorMessage, loadingMessage, successMessage });
  };

  const handleUpdateTags = async ({ id, tagList }: any) => {
    await updateWorkflowTags({
      variables: {
        settings: {
          workflow_id: id,
          tags: tagList.length === 0 ? null : tagList.map((item: any) => item.id),
        },
      },
    });
    refetch();
  };

  const { selectedTags, setSelectedTags, setUserTagsList, handleTagSelect, handleTagDelete } = useTagsMethods({
    workflowId: workflowId,
    setWorkflowId,
    handleUpdateTags,
  });

  useEffect(() => {
    userTags?.HexomaticTag?.getUserHexomaticTags?.tags &&
      setUserTagsList(userTags.HexomaticTag.getUserHexomaticTags.tags);
  }, [userTags]);

  const onMakePublic = async ({ id, is_public, recipeIds }: any) => {
    setMakingPublic(is_public);
    try {
      const data = await makePublic({
        variables: {
          settings: {
            workflow_id: id,
            is_public,
            recipeIds: recipeIds,
          },
        },
      });
      if (data?.data?.HexomaticPublicWorkflowOps?.createNewPublicWorkflow?.error_code) {
        handleToastShow(true, data.data.HexomaticPublicWorkflowOps.createNewPublicWorkflow.error_code, '', '');
      } else {
        await refetch();
        handleToastShow(true, '', '', is_public ? 'Your workflow is now Public.' : 'Your workflow is now Private.');
      }
    } catch (error) {
      handleToastShow(true, 'Something went wrong', '', '');
    }
  };

  const onUpdatePublic = async ({ id, recipeIds }: any) => {
    try {
      const data = await updatePublic({
        variables: {
          settings: {
            original_workflow_id: id,
            recipeIds: recipeIds,
          },
        },
      });
      if (data?.data?.HexomaticPublicWorkflowOps?.updatePublicWorkflow?.error_code) {
        handleToastShow(true, data.data.HexomaticPublicWorkflowOps.updatePublicWorkflow.error_code, '', '');
      } else {
        await refetch();
        handleToastShow(true, '', '', 'The public workflow was successfully updated.');
      }
    } catch (error) {
      handleToastShow(true, 'Something went wrong', '', '');
    }
  };

  const handleCopyWorkflow = async (id: number | string) => {
    const data = await copyWorkflow({
      variables: {
        settings: {
          workflow_id: optionParameters.value,
        },
      },
    });
    const error = data?.data?.HexomaticWorkflowOps?.duplicateWorkflow?.error_code;
    const copiedWorkflowId = data?.data?.HexomaticWorkflowOps?.duplicateWorkflow?.copiedWorkflowId;
    if (!error) {
      await refetch();
      handleToastShow(
        true,
        '',
        '',
        <div className="d-blcok d-md-flex">
          <div className="m-auto">Successfully duplicated.</div>{' '}
          {!!copiedWorkflowId && (
            <Link to={`/workflows/current-workflow/${copiedWorkflowId}`} className="clickable-text my-auto ml-1">
              Go to the workflow
            </Link>
          )}
        </div>,
      );
    } else {
      handleToastShow(true, 'Something went wrong.', '', '');
      return;
    }
  };

  const handleCheckboxClick = (index: number) => {
    const arr = [...checkedIndexes];
    if (checkedIndexes.indexOf(index) > -1) {
      arr.splice(checkedIndexes.indexOf(index), 1);
      setIsCheckedAll(false);
    } else arr.push(index);
    if (arr.length === workflowsList.length) setIsCheckedAll(true);
    setCheckedIndexes(arr);
  };

  const changeActive = async (id: number, active: boolean, date?: string) => {
    if (active && hexomaticUser?.left_automation_credit === 0) {
      setShouldUpgrade(true);
      return;
    }
    const filteredList = workflowsList.filter((sub: any) => id === sub.id);
    if (
      active &&
      filteredList.find((item: any) =>
        item.steps.find((step: any) => step.automation_id === 38 || step.automation_id === 11),
      )
    ) {
      setTrafficInsightsWarning(true);
      return;
    }
    const currWorkFlow = workflowsList.find((obj: any) => obj.id === id);
    let bool = currWorkFlow.steps.find(
      (obj: any) => obj.automation_type_name === 'Premium automations',
      // && obj.automation_id !== 143
    );
    if (
      active &&
      (user.left_premium_credits === 0 || !user.left_premium_credits) &&
      (hexomaticUser?.left_premium_automation_credit === 0 || !hexomaticUser?.left_premium_automation_credit) &&
      bool
    ) {
      hexomaticUser?.pricing_package !== 'FREE' && setBuyPremiumCredits(true);
      setShouldUpgrade(true);
      return;
    }

    setId(id);
    const { data } = await updateWorkflow({
      variables: {
        settings: {
          active: active,
          workflow_id: id,
          started_at: date && date,
          isDraft: date && false,
          isUpdated: false,
        },
      },
    });
    if (!data?.HexomaticWorkflowOps?.updateWorkflow?.error_code) {
      handleToastShow(true, '', '', 'Successfully updated!');
      await refetch();
      return;
    }
    handleToastShow(true, 'Something  went wrong.', '', '');
  };

  const handleUpdateWorkflowName = useCallback(
    async ({ name, id }: any) => {
      setNameChanged(true);
      if (!name) {
        setAlertMsg({ show: true, text: `Invalid name`, type: 'error' });
        return { message: null };
      }
      if ((name && (!name.trim() || !isValidWorkflowName(name))) || !name) {
        if (viewMode !== 'cardView') {
          setAlertMsg({ show: true, text: `Invalid name`, type: 'error' });
          return { message: 'Invalid name' };
        }
        return { message: 'Invalid name' };
      }

      const res = await updateWorkflow({
        variables: {
          settings: {
            name: name && name.trim(),
            workflow_id: id,
            isUpdated: false,
          },
        },
      });

      if (res?.data?.HexomaticWorkflowOps?.updateWorkflow?.error_code) {
        setAlertMsg({
          show: true,
          text:
            res?.data?.HexomaticWorkflowOps?.updateWorkflow?.error_code === 'ALREADY_EXISTS'
              ? `"${name}" already exists`
              : 'Something went wrong',
          type: 'error',
        });
        return { message: null };
      } else {
        try {
          await refetch();
          return { message: null };
        } catch (error) {}
      }
      setNameChanged(false);
    },
    [viewMode],
  );

  const handleUpdateWorkflowDescription = useCallback(
    async ({ description, id }: { description: string; id: string | number }) => {
      setNameChanged(true);
      if (description && !isValidKeyword(description)) {
        setAlertMsg({ show: true, text: `Description is not valid`, type: 'error' });
        return { message: 'Invalid description' };
      }

      const res = await updateWorkflow({
        variables: {
          settings: {
            description: description && description.trim(),
            workflow_id: id,
            isUpdated: false,
          },
        },
      });

      if (res?.data?.HexomaticWorkflowOps?.updateWorkflow?.error_code) {
        setAlertMsg({
          show: true,
          text: 'Something went wrong',
          type: 'error',
        });
        return { message: null };
      } else {
        try {
          await refetch();
          return { message: null };
        } catch (error) {}
      }
      setNameChanged(false);
    },
    [],
  );

  const handleDeleteWorkflow = async () => {
    const { data } = await deleteWorkflow({
      variables: {
        settings: {
          workflow_ids: showDeleteModal ? checkedIndexes : [+optionParameters.value],
        },
      },
    });

    if (data?.HexomaticWorkflowOps?.deleteWorkflows?.error_code) {
      handleToastShow(true, 'Something went wrong.', '', '');
      return;
    }
    if (
      viewMode !== 'cardView' &&
      count <= page * limit.value &&
      (optionParameters.value ? 1 : checkedIndexes.length) === workflowsList.length
    ) {
      setPage(page - 1);
    }
    const newData = await refetch();
    handleToastShow(true, '', '', 'Successfully deleted!');
    newData?.data?.HexomaticWorkflow?.getWorkflows && setCount(newData.data.HexomaticWorkflow.getWorkflows.count || 0);
    newData?.data?.HexomaticWorkflow?.getWorkflows &&
      localStorage.setItem('workflows-count', `${newData.data.HexomaticWorkflow.getWorkflows.count || ''}`);
    setCheckedIndexes([]);
    setIsCheckedAll(false);
  };

  const handleChangeState = async (bool: boolean) => {
    const filteredArr = workflowsList.filter((item: any) => checkedIndexes.includes(item.id));
    const filteredIndexList = filteredArr.filter(
      (item: any) => !item.steps.find((sub: any) => sub.automation_id === 38 || sub.automation_id === 11),
    );
    setStateValue(bool);
    const { data } = await updateWorkflowState({
      variables: {
        settings: {
          workflow_ids: bool
            ? filteredIndexList.map((item: any) => {
                return item.id;
              })
            : checkedIndexes,
          active: bool,
        },
      },
    });
    if (!data?.HexomaticWorkflowOps?.updateWorkflowStateBulk?.error_code) {
      setCheckedIndexes([]);
      setIsCheckedAll(false);
      await refetch();
      handleToastShow(true, '', '', 'Successfully updated!');
      return;
    }
    handleToastShow(true, 'Something went wrong.', '', '');
  };

  const handleCheckedAll = useCallback(() => {
    setIsCheckedAll(!isCheckedAll);
    isCheckedAll ? setCheckedIndexes([]) : setCheckedIndexes(workflowIds);
  }, [isCheckedAll, workflowIds]);

  const cancelDeletModal = () => {
    !showDeleteModal && setOptionParameters({ label: '', value: '', recipeIds: [] });
    showDeleteModal && setShowDeleteModal(false);
  };

  const handleDelete = () => setShowDeleteModal(true);

  // const getNamesForTooltip = (steps: any, cardView?: boolean, onlyOne?: boolean): string => {
  //   const text = steps.slice(onlyOne ? 1 : hide || cardView ? 2 : 5).map((current: any, index: number, arr: any) => {
  //     return ` ${current.automation_name}${index === arr.length - 1 ? '' : ','} `;
  //   });
  //   return text.join('');
  // };

  const getNamesForTooltip = useCallback((steps: any, count: number): string => {
    return steps.slice(count).map((current: any, index: number, arr: any) => {
      return ` ${current.automation_name}${index === arr.length - 1 ? '' : ','} `;
    });
  }, []);

  const handelLimitChange = (value: any) => {
    value.label != limit.label && setLimit(value);
    localStorage.setItem('workflowLimit', JSON.stringify(value));
    if (value.value * page > count) {
      setPage(Math.ceil(count / value.value));
    }
  };

  const returnShedule = (item: any) => {
    const currentWorkflow = realTimeStatusData && realTimeStatusData[item.id];
    return !currentWorkflow || (currentWorkflow && currentWorkflow.status === 'progress');
  };

  const returnActiveStatus = (item: any, reverseActive?: boolean) => {
    const currentWorkflow = realTimeStatusData && realTimeStatusData[item.id];

    return reverseActive
      ? currentWorkflow
        ? !currentWorkflow.active
        : !item.active
      : currentWorkflow
      ? currentWorkflow.active
      : item.active;
  };

  const returnStorageUsage = (item: any) => {
    const currentWorkflow = realTimeStatusData && realTimeStatusData[item.id];
    let storage =
      (currentWorkflow && currentWorkflow.storage ? currentWorkflow.storage : item && item.storage ? item.storage : 0) /
      //@ts-ignore
      (1000 * 1000);
    //@ts-ignore
    if (String(storage).length > 4) storage = storage.toFixed(2);
    return storage ? (storage > 999 ? (storage / 1000).toFixed(2) + ' GB' : storage + ' MB') : '0 MB';
  };

  const returnWorkflowStatus = (item: any) => {
    const currentWorkflow = realTimeStatusData && realTimeStatusData[item.id];
    return currentWorkflow ? currentWorkflow.status : item?.status;
  };

  const hoverText = (item: any): string => {
    if (returnWorkflowStatus(item) === 'PAUSED' && returnActiveStatus(item) === false) {
      return 'Click to run workflow';
    }

    if (returnWorkflowStatus(item) !== 'PAUSED' && returnActiveStatus(item) === false) {
      return 'Click to run workflow';
    }

    if (returnActiveStatus(item)) {
      return 'Click to pause';
    }
    return '';
  };

  // const canWorkflowBePublic = (array: any): boolean => {
  //   const hasRecipes = array.filter((item: any) => item.automation_name === 'Scraping recipe');
  //   if (!hasRecipes.length) return true;

  //   const everyIsPublic = hasRecipes.every((item: any) => {
  //     const { setting } = item;
  //     const parsedSetting = JSON.parse(setting);
  //     return parsedSetting.is_public ? true : false;
  //   });
  //   return everyIsPublic;
  // };

  // const onWizardModeChange = () => {
  //   setWizardToggle(!wizardToggle);
  //   if (localStorage.getItem('workflow-starter-mode') === 'wizard') {
  //     localStorage.setItem('workflow-starter-mode', 'advanced');
  //   } else {
  //     localStorage.setItem('workflow-starter-mode', 'wizard');
  //   }
  // };

  const handelChangeViewMode = useCallback(
    (val: 'cardView' | 'listView') => {
      if (val === viewMode) return;
      localStorage.setItem('workflow-view-mode', val);
      setViewMode(val);
      setPage(1);
    },
    [viewMode],
  );

  const handleCardLimit = (): void => {
    // setIsClickedLoadMore(true);
    if (count > cardLimit) setCardLimit(cardLimit + 24);
  };

  const addTag = useCallback(
    (id: any) => {
      const workflow = workflowsList.find((workflow: { id: any }) => workflow.id === id);
      if (workflow) {
        const { tags } = workflow;
        if (tags?.length >= 10) return;
        setShowTagModal(true);
        tags ? setSelectedTags(tags) : setSelectedTags([]);
        setWorkflowId(id);
      }
    },
    [workflowsList],
  );

  const deleteTagFromWorkflow = ({ tagId, workflowId }: { tagId: number; workflowId: number }): void => {
    const workflow = workflowsList.find((workflow: { id: any }) => workflow.id === workflowId);
    handleTagDelete(tagId, workflowId, workflow.tags);
  };

  const handleFilterByTag = (tagId: number, workflowId: number): void => {
    type CommonType = { label: string; value: string };

    const foundTag = workflowsList
      .find((workflow: { id: number }) => workflow.id === workflowId)
      .tags.find((tag: { id: number; name: string; color: string }) => tag.id === tagId);

    const currTag: CommonType = {
      label: foundTag.name,
      value: foundTag.id.toString(),
    };

    const isSelected = filteredTags?.some((item: CommonType) => +item.value === +currTag.value);

    if (isSelected) {
      const tags = filteredTags?.filter((item: CommonType) => +item.value !== +currTag.value);
      setFilteredTags(tags);
      const data = localStorage.getItem('workflows-filtration');
      const newArr = data ? JSON.parse(data) : [];
      if (newArr) {
        if (newArr.find((sub: any) => sub.label === 'Tags')) {
          newArr.map((sub: any) => {
            if (sub.label === 'Tags') {
              return { ...sub, selectedSubcategories: tags };
            }
          });
        } else {
          newArr.push({
            label: 'Tags',
            value: 'tags',
            selectedSubcategories: tags,
          });
        }
      }
      newArr && newArr.length && localStorage.setItem('workflows-filtration', JSON.stringify(newArr));
      setFiltersCount(newArr.length);
      return;
    }
    const data = localStorage.getItem('workflows-filtration');
    const newArr = data ? JSON.parse(data) : [];
    if (newArr) {
      if (newArr.find((sub: any) => sub.label === 'Tags')) {
        newArr.map((sub: any) => {
          if (sub.label === 'Tags') {
            return { ...sub, selectedSubcategories: filteredTags ? [...filteredTags, currTag] : [currTag] };
          }
        });
      } else {
        newArr.push({
          label: 'Tags',
          value: 'tags',
          selectedSubcategories: filteredTags ? [...filteredTags, currTag] : [currTag],
        });
      }
    }
    newArr && newArr.length && localStorage.setItem('workflows-filtration', JSON.stringify(newArr));
    setFiltersCount(newArr.length);
    setPage(1);
    setFilteredTags(filteredTags ? [...filteredTags, currTag] : [currTag]);
  };

  const handleCloseUpgradeModal = () => setShouldUpgrade(false);

  const handleActionClickUpgradeModal = () => {
    buyPremiumCredits ? setShowPaymentModal(true) : (window.location.href = 'https://hexomatic.com/pricing/');
    setShouldUpgrade(false);
  };

  const handleActionClickOptionParametersModal = () => {
    !showDeleteModal && setOptionParameters({ label: '', value: '', recipeIds: [] });
    showDeleteModal && setShowDeleteModal(false);
    handleDeleteWorkflow();
  };

  const handleSetAlertMsg = () => setAlertMsg({ show: false, text: '', type: '' });

  const handleSetInfoModal = () => setInfoModal({ open: false, error: false, message: '' });

  const handleClosePaymentModal = () => {
    setShowPaymentModal(false);
    setBuyPremiumCredits(false);
  };

  const handleContinue = async () => {
    if (shouldContinue.status === 'bulk') {
      if (hexomaticUser?.left_automation_credit === 0) {
        setShouldUpgrade(true);
        setShouldContinue({ ...shouldContinue, isOpen: false });
        return;
      }
      handleChangeState(true);
      setShouldContinue({ ...shouldContinue, isOpen: false });
    } else if (shouldContinue.status === 'run_now') {
      const filteredList = workflowsList.filter((sub: any) => +optionParameters.value === sub.id);
      if (
        filteredList.find((item: any) =>
          item.steps.find((step: any) => step.automation_id === 38 || step.automation_id === 11),
        )
      ) {
        setTrafficInsightsWarning(true);
        return;
      }
      changeActive(+optionParameters.value, true, new Date().toISOString());
      setShouldContinue({ ...shouldContinue, isOpen: false });
    } else if (shouldContinue.status === 'schedule') {
      const { data } = await updateWorkflow({
        variables: {
          settings: {
            workflow_id: optionParameters.value,
            started_at: scheduleSettings?.startDate || null,
            frequency: scheduleSettings?.frequency || '',
            schedule_config: scheduleSettings?.schedule_config || null,
            configs: {
              triggers:
                trigger && trigger.watchPropertyId
                  ? [
                      {
                        watchPropertyId: trigger.watchPropertyId,
                        watchIntegrationId: trigger.watchIntegrationId,
                      },
                    ]
                  : [],
              selectedOutputKeys: selectedOutputKeys,
              send_mail: sendMail,
            },
            active: true,
            isUpdated: true,
            isUpdateAndRun: true,
          },
        },
      });
      setShouldContinue({ ...shouldContinue, isOpen: false });
      if (!data?.HexomaticWorkflowOps?.updateWorkflow?.error_code) {
        handleToastShow(true, '', '', 'Successfully updated!');
        await refetch();
        return;
      }
      handleToastShow(true, 'Something  went wrong.', '', '');
    } else {
      changeActive(
        shouldContinue?.item?.id ? +shouldContinue?.item?.id : 0,
        returnActiveStatus(shouldContinue.item, true),
      );
      setShouldContinue({ ...shouldContinue, isOpen: false });
    }
  };

  const handelCancelContinue = () => {
    setShouldContinue({ ...shouldContinue, isOpen: false });
  };

  const handelCloseShareModal = () => setShareModal(false);
  const handelCloseSuccessfullySharedModal = () => setIsSuccessfullyShared(false);
  const handelActionClickModalCalendar = async (
    startDate?: string,
    frequency?: string,
    schedule_config?: ScheduleConfigType,
    triggerVal?: string,
  ) => {
    if (
      optionParameters.includePremium &&
      user?.left_premium_credits === 0 &&
      hexomaticUser?.left_premium_automation_credit === 0
    ) {
      setShouldUpgrade(true);
      hexomaticUser?.pricing_package !== 'FREE' ? setBuyPremiumCredits(true) : setBuyPremiumCredits(false);
      setCustomSchedule(false);
    } else {
      setShouldContinue({ isOpen: true, status: 'schedule' });
      startDate &&
        setScheduleSettings({ startDate: startDate, frequency: frequency, schedule_config: schedule_config });
      setTrigger([]);
      setCustomSchedule(false);
    }
  };

  const handelCancelClickModalCalendar = () => setCustomSchedule(false);

  const handelTriggerActinClick = async (
    startDate?: string,
    frequency?: string,
    schedule_config?: ScheduleConfigType,
    triggerVal?: string,
  ) => {
    if (
      optionParameters.includePremium &&
      user?.left_premium_credits === 0 &&
      hexomaticUser?.left_premium_automation_credit === 0
    ) {
      setShouldUpgrade(true);
      hexomaticUser?.pricing_package !== 'FREE' ? setBuyPremiumCredits(true) : setBuyPremiumCredits(false);
      setTriggerWorkflow(false);
    } else {
      setShouldContinue({ isOpen: true, status: 'schedule' });
      startDate &&
        setScheduleSettings({ startDate: startDate, frequency: frequency, schedule_config: schedule_config });
      triggerVal &&
        setTrigger({
          ...trigger,
          watchPropertyId: +triggerVal,
        });
      setTriggerWorkflow(false);
    }
  };
  const handelCloseTriggerModal = () => setTriggerWorkflow(false);

  const handelSetTrigger = (val: any) => val && setTrigger(val);

  const handelKeyUp = useCallback(
    (e: any) => {
      setStartSearch(setTimeout(() => e.target.value != searchText && setSearchText(e.target.value), 500));
    },
    [searchText],
  );

  const handelKeyDown = useCallback(() => {
    clearTimeout(startSearch);
  }, [startSearch, clearTimeout]);

  const handleSetTrigger = useCallback((value: any) => {
    value && setTrigger(value);
  }, []);

  const list: any = useMemo(() => {
    return [
      {
        type: 'input',
        value: undefined,
        defaultValue: searchText,
        onKeyUp: handelKeyUp,
        onKeyDown: handelKeyDown,
        className: `${filtersCount ? 'col-md-7 col-lg-7' : 'col-md-9 col-lg-9'} px-0 m-auto`,
        placeholder: 'Search',
        icon: loopIcon,
      },
    ];
  }, [handelKeyUp, handelKeyDown, filtersCount]);

  const handleDisableWorkflowTrigger = async (workflowId: number, watchIntegrationId: number) => {
    handleToastShow(true, '', 'Disabling...', '');

    try {
      const { data } = await disableTrigger({
        variables: {
          settings: {
            workflowId,
            watchIntegrationId,
          },
        },
      });

      const error_code = data?.HexomaticWorkflowOps?.disableTriggerWorkflow?.error_code;

      if (error_code) {
        if (error_code === 'WORKFLOW_IN_PROGRESS') {
          setAlertMsg({
            show: true,
            text: `You can't disable the trigger while the workflow is IN PROGRESS.`,
            type: 'error',
          });
          handleToastShow(false, '', '', '');
          return;
        }
        setAlertMsg({ show: true, text: 'Somethig went wrong', type: 'error' });
        handleToastShow(false, '', '', '');
        return;
      }
      handleToastShow(true, '', '', 'Disabled');

      refetch();
    } catch (err) {
      console.log(err);
    }
  };

  const handleScrollEvent = (e: any) => {
    const top: number = e.currentTarget.scrollTop;
    const el = document.getElementById(viewMode === 'cardView' ? 'workflow-search-container' : 'workflow-thead');
    if (el) {
      top > 20 ? el.classList.add('scroll') : el.classList.remove('scroll');
    }
  };

  useEffect(() => {
    const el = document.getElementById('workflow-search-container');
    el && el.classList.remove('scroll');
  }, [viewMode]);

  return (
    <div>
      {!searchText && !filtersCount && isWorkflowListReceived && !workflowsList.length && !loading ? null : (
        <TemplatesSection
          templatesList={hide ? publicWorkflowsList.slice(1) : publicWorkflowsList}
          handleShowAllClick={() => push('/public-workflows', { backUrl: hide ? '/dashboard' : '/workflows' })}
          createNewLinkTo={'/workflows/create'}
          blank={!publicWorkflowsList?.length}
          hide={hide}
        />
      )}
      <div
        className={`${
          loading || !!workflowsList.length || !isWorkflowListReceived || searchText || filtersCount ? 'page-content-v2' : ''
        } ${hide ? 'border-radius m-0' : ''}`}
        style={{
          height: isMobile ? '' : hide ? `auto` : `${tableMaxHeight}px`,
          minHeight: isMobile ? '' : hide ? '' : '400px',
        }}
      >
        {loading || !!workflowsList?.length || !isWorkflowListReceived || searchText || filtersCount ? (
          <div className="workflow-search-container pb-1" id="workflow-search-container">
            <SearchContainer
              title="Workflows"
              list={list}
              maxWidth="450px"
              showViewMode
              setViewMode={handelChangeViewMode}
              viewMode={viewMode}
              viewModeClassName={filtersCount ? 'px-2 col-md-5' : 'px-2 col-md-3'}
              showFilter
              filtersCount={filtersCount}
              filterContainer={
                <SearchContainer1
                  onClickOutside={() => setFilterPopupOpen(false)}
                  onApplyClick={handleFiltersApply}
                  // hide={hide}
                  show={filterPopupOpen}
                />
              }
              filterContainerOpen={filterPopupOpen}
              setFilterContainerOpen={(val: boolean) => setFilterPopupOpen(val)}
              handleButtonClick={() => push('/workflows/create')}
            />
          </div>
        ) : null}
        <>
          {workflowsList && workflowsList.length > 0 && isWorkflowListReceived && !loading ? (
            viewMode === 'cardView' ? (
              <div className={`card-view-wrapper-main ${hide ? 'hide' : ''}`}>
                <div className="row card-view-wrapper" id="workflow-cards" onScroll={handleScrollEvent}>
                  {workflowsList.map((item: any) => (
                    <Fragment key={item.id}>
                      <WorkflowCard
                        item={item}
                        hide={hide}
                        returnWorkflowStatus={returnWorkflowStatus}
                        returnActiveStatus={returnActiveStatus}
                        setShouldContinue={setShouldContinue}
                        changeActive={changeActive}
                        hoverText={hoverText}
                        handleUpdateWorkflowName={handleUpdateWorkflowName}
                        openedId={openedId}
                        setOpenedId={setOpenedId}
                        updateLoading={updateLoading}
                        optionParameters={optionParameters}
                        id={id}
                        setOpenExportModal={setOpenExportModal}
                        setSelectedWorkflowId={setSelectedWorkflowId}
                        setSelectedHeaders={setSelectedHeaders}
                        returnStorageUsage={returnStorageUsage}
                        getNamesForTooltip={getNamesForTooltip}
                        addTag={addTag}
                        allowingToAddTag={item.tags === null || item.tags?.length <= 9}
                        deleteTagFromWorkflow={deleteTagFromWorkflow}
                        handleFilterByTag={handleFilterByTag}
                        selectedFilterTag={filteredTags}
                        isAdmin={isAdmin}
                        setScheduleConfig={setScheduleConfig}
                        setStartedAt={setStartedAt}
                        setOptionParameters={setOptionParameters}
                        realTimeStatusData={realTimeStatusData}
                        setTrigger={handelSetTrigger}
                        setselectedOutputKeys={setSelectedOutputKeys}
                        setSendMail={setSendMail}
                        returnShedule={returnShedule}
                      />
                    </Fragment>
                  ))}
                </div>
                {workflowsList.length < count ? (
                  <div className="load-more-button my-2">
                    <div className="clickable-text" onClick={handleCardLimit}>
                      LOAD MORE
                    </div>
                  </div>
                ) : null}
              </div>
            ) : (
              <WorkflowTable
                hide={!!hide}
                handleDisableWorkflowTrigger={handleDisableWorkflowTrigger}
                isCheckedAll={isCheckedAll}
                checkedIndexes={checkedIndexes}
                handleCheckedAll={handleCheckedAll}
                sort={sort}
                setSort={setSort}
                stateLoading={stateLoading}
                stateValue={stateValue}
                setShouldContinue={setShouldContinue}
                handleChangeState={handleChangeState}
                handleDelete={handleDelete}
                workflowsList={workflowsList}
                handleCheckboxClick={handleCheckboxClick}
                returnWorkflowStatus={returnWorkflowStatus}
                returnActiveStatus={returnActiveStatus}
                changeActive={changeActive}
                id={id}
                updateLoading={updateLoading}
                optionParameters={optionParameters}
                hoverText={hoverText}
                handleUpdateWorkflowName={handleUpdateWorkflowName}
                openedId={openedId}
                setOpenedId={setOpenedId}
                addTag={addTag}
                count={count}
                limit={limit}
                handelLimitChange={handelLimitChange}
                page={page}
                setPage={setPage}
                handleUpdateWorkflowDescription={handleUpdateWorkflowDescription}
                openedIdDesc={openedIdDesc}
                setOpenedIdDesc={setOpenedIdDesc}
                selectedFilterTag={filteredTags}
                deleteTagFromWorkflow={deleteTagFromWorkflow}
                handleFilterByTag={handleFilterByTag}
                returnStorageUsage={returnStorageUsage}
                getNamesForTooltip={getNamesForTooltip}
                returnShedule={returnShedule}
                isAdmin={isAdmin}
                setOpenExportModal={setOpenExportModal}
                setSelectedWorkflowId={setSelectedWorkflowId}
                setSelectedHeaders={setSelectedHeaders}
                setScheduleConfig={setScheduleConfig}
                setStartedAt={setStartedAt}
                setOptionParameters={setOptionParameters}
                realTimeStatusData={realTimeStatusData}
                setTrigger={handleSetTrigger}
                setselectedOutputKeys={setSelectedOutputKeys}
                setSendMail={setSendMail}
                handleScrollEvent={handleScrollEvent}
              />
            )
          ) : loading || !isWorkflowListReceived ? (
            <TableCardFacadeLoading rowCount={10} tableView={viewMode === 'listView'} />
          ) : (
            <>
              {(searchText || filtersCount) && !workflowsList.length && isWorkflowListReceived ? (
                <div className="h-100 d-flex">
                  <NothingToShow />
                </div>
              ) : null}

              {!searchText && !filtersCount && !workflowsList.length && isWorkflowListReceived ? (
                <NothingToShowRecommended
                  setIsVideoModalOpen={setIsVideoModalOpen}
                  handleNewWorkflowBtn={() => push('/workflows/create')}
                  publicWorkflowsList={publicWorkflowsList}
                  hide={hide}
                />
              ) : null}
            </>
          )}
        </>
      </div>
      {showTagModal && (
        <ModalTagContainer
          title="ADD TAG"
          ShowPopup={showTagModal}
          setShowPopup={setShowTagModal}
          // longModal
          escButtonClick={setShowTagModal}
        >
          <TagsContent
            propSelectedTag={selectedTag}
            propSetSelectedTag={setSelectedTag}
            propSetSelectedTags={setSelectedTags}
            propSelectedTags={selectedTags}
            handleTagSelect={handleTagSelect}
            setShowTagModal={setShowTagModal}
            fromList
            setIsTagsChange={setIsTagsChange}
            // propSetUserTagsList={setUserTagsList}
          />
        </ModalTagContainer>
      )}
      {shouldUpgrade && (
        <Modal
          isModalOpen={shouldUpgrade}
          title={
            !buyPremiumCredits ? (
              'Please upgrade your plan to continue'
            ) : user.premium_credits_paypal_subscription_id &&
              (user.premium_credits_pricing_package === 'PC_26400' ||
                user.premium_credits_pricing_package === 'PC_264000') ? (
              <ContactUs showCloseIcon handleCloseModal={handleCloseUpgradeModal} />
            ) : (
              'Please add premium credits to continue'
            )
          }
          parentClass={
            user.premium_credits_paypal_subscription_id &&
            (user.premium_credits_pricing_package === 'PC_26400' ||
              user.premium_credits_pricing_package === 'PC_264000') &&
            buyPremiumCredits
              ? 'contact_us_modal'
              : ''
          }
          actionBtnText={
            user.premium_credits_paypal_subscription_id &&
            (user.premium_credits_pricing_package === 'PC_26400' ||
              user.premium_credits_pricing_package === 'PC_264000') &&
            buyPremiumCredits
              ? ''
              : 'Upgrade'
          }
          cancelBtnText={
            user.premium_credits_paypal_subscription_id &&
            (user.premium_credits_pricing_package === 'PC_26400' ||
              user.premium_credits_pricing_package === 'PC_264000') &&
            buyPremiumCredits
              ? ''
              : 'Cancel'
          }
          actionBtnColor="warning"
          cancelBtnColor="secondary"
          onActionClick={handleActionClickUpgradeModal}
          onCancelClick={handleCloseUpgradeModal}
          onlyBtns={
            !!(
              buyPremiumCredits &&
              user.premium_credits_paypal_subscription_id &&
              (user.premium_credits_pricing_package === 'PC_26400' ||
                user.premium_credits_pricing_package === 'PC_264000')
            )
          }
          escButtonClick={handleCloseUpgradeModal}
        />
      )}
      {(optionParameters.label === 'Delete' || showDeleteModal) && (
        <Modal
          isModalOpen={!!(optionParameters.label == 'Delete') || showDeleteModal}
          text={`Confirm deleting ${
            showDeleteModal && checkedIndexes && checkedIndexes.length > 1
              ? checkedIndexes.length + ' workflows'
              : 'the workflow'
          }`}
          actionBtnText={deleteLoading ? <Loader light={theme === 'light'} className="d-inline" /> : 'Yes'}
          cancelBtnText="No"
          actionBtnColor="#B72CFF"
          onActionClick={handleActionClickOptionParametersModal}
          onCancelClick={cancelDeletModal}
          escButtonClick={cancelDeletModal}
        />
      )}
      {alertMsg.show && (
        <ModalComponent
          isModalOpen={alertMsg.show}
          text={alertMsg.text}
          actionBtnText="OK"
          actionBtnColor={alertMsg.type}
          onActionClick={handleSetAlertMsg}
          escButtonClick={handleSetAlertMsg}
        />
      )}
      {infoModal.open && (
        <Modal
          isModalOpen={infoModal.open}
          text={infoModal.message}
          actionBtnText="OK"
          actionBtnColor={infoModal.error ? 'error' : 'success'}
          onActionClick={handleSetInfoModal}
          escButtonClick={handleSetInfoModal}
        />
      )}
      {showPaymentModal && (
        <CustomModal ShowPopup={showPaymentModal} noNavigate escButtonClick={handleClosePaymentModal}>
          <Payment
            name=""
            selectedValue={{ title: '105', value: 105 }}
            price={0}
            premium_credit
            setShowPopup={handleClosePaymentModal}
            // offer
          />
        </CustomModal>
      )}
      {openExportModal && (
        <CustomModal ShowPopup={openExportModal} noNavigate escButtonClick={() => setOpenExportModal(false)}>
          <ExportModalWithOptions
            workflowId={selectedWorkflowId}
            setShow={setOpenExportModal}
            setShowToastInfo={handleToastShow}
            showUpgradeModal={setShouldUpgrade}
            selectedHeaders={selectedHeaders}
            setSelectedHeaders={(val: any, id: any) => {
              setWorkflowsList(
                workflowsList.map(item => {
                  if (item.id === id) {
                    return {
                      ...item,
                      selected_headers: val,
                    };
                  } else {
                    return item;
                  }
                }),
              );
            }}
          />
        </CustomModal>
      )}
      {/*openMenuModal && (
        <CustomModal ShowPopup={openMenuModal} noNavigate>
          <MenuModal setOptionParameters={setOptionParameters} menuOption={optionForMenu} setShow={setOpenMenuModal} />
        </CustomModal>
      )*/}
      <Modal
        isModalOpen={trafficInsightsWarning}
        text="Traffic Insights automation can no longer be executed"
        actionBtnText="Ok"
        actionBtnColor="warning"
        cancelBtnColor="primary"
        onActionClick={() => setTrafficInsightsWarning(false)}
        escButtonClick={() => setTrafficInsightsWarning(false)}
      />
      {shouldContinue.isOpen && (
        <Modal
          isModalOpen={shouldContinue.isOpen}
          text={
            shouldContinue.status === 'progress'
              ? 'The workflow is currently running, this will restart the workflow losing existing data and credits used so far for it.'
              : shouldContinue.status === 'bulk'
              ? 'The completed workflows will restart, to download the previously generated data please go to History.'
              : 'The workflow will restart running. You can download the previously generated data in the History section.'
          }
          actionBtnText="Continue anyway"
          cancelBtnText="Cancel"
          actionBtnColor="warning"
          cancelBtnColor="secondary"
          onActionClick={handleContinue}
          onCancelClick={handelCancelContinue}
          escButtonClick={handelCancelContinue}
        />
      )}
      {toasterInfo.show && (
        <ToastLoader
          errorMessage={toasterInfo.errorMessage}
          loadingMessage={toasterInfo.loadingMessage}
          successMessage={toasterInfo.successMessage}
          onClose={
            toasterInfo.successMessage && typeof toasterInfo.successMessage !== 'string'
              ? () => setToasterInfo({ show: false, errorMessage: '', loadingMessage: '', successMessage: '' })
              : undefined
          }
        />
      )}
      {shareModal && (
        <CustomModal escButtonClick={handelCloseShareModal}>
          <ShareWorkflowModal
            setIsSuccessfullyShared={setIsSuccessfullyShared}
            workflowId={+optionParameters.value}
            onClose={handelCloseShareModal}
          />
        </CustomModal>
      )}
      <Modal
        isModalOpen={isSuccessfullyShared}
        text="Workflow has been successfully shared"
        actionBtnText="Ok"
        actionBtnColor="success"
        cancelBtnColor="primary"
        onActionClick={handelCloseSuccessfullySharedModal}
        escButtonClick={handelCloseSuccessfullySharedModal}
      />

      {isVideoModalOpen && (
        <div className="video-modal-main-wrapper">
          <CustomModal escButtonClick={() => setIsVideoModalOpen(false)}>
            <VideoModal src="https://player.vimeo.com/video/838966084" setIsVideoModalOpen={setIsVideoModalOpen} />
          </CustomModal>
        </div>
      )}

      {/* {viewMode === 'listView' && loading && !isClickedLoadMore && workflowsList && workflowsList.length > 0 && (
        <ToastLoader loadingMessage="Loading" />
      )} */}
      {customSchedule && (
        <ModalCalendar
          isModalOpen={customSchedule}
          text="Schedule workflow"
          actionBtnText="Proceed"
          cancelBtnText="Cancel"
          propTrigger={trigger}
          isScheduled
          onActionClick={handelActionClickModalCalendar}
          onCloseModal={handelCancelClickModalCalendar}
          onCancelClick={handelCancelClickModalCalendar}
          loading={updateLoading}
          schedule_config={schedule_config}
          frequency={optionParameters.frequency}
          isNew={false}
          startedAt={startedAt}
        />
      )}
      {triggerWorkflow && (
        <ModalCalendar
          isModalOpen={triggerWorkflow}
          text="Trigger via Hexowatch"
          actionBtnText="Proceed"
          cancelBtnText="Cancel"
          propTrigger={trigger}
          isScheduled={false}
          onActionClick={handelTriggerActinClick}
          onCloseModal={handelCloseTriggerModal}
          onCancelClick={handelCloseTriggerModal}
          loading={updateLoading}
          schedule_config={schedule_config}
          frequency={optionParameters.frequency}
          isNew={false}
          startedAt={startedAt}
        />
      )}
    </div>
  );
}
