import { FC, useEffect, useState, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { Col, Row } from 'react-bootstrap';

import NothingToShowDetailed from 'shared-components/atom/nothing-to-show-detailed';
import Loader from 'shared-components/atom/loader';
import TemplateCard from 'shared-components/atom/template-card';
import { ThemeContext } from 'contexts/theme-context';
import { HexomaticUserContext } from 'contexts/hexomatic-user';
import { GET_PUBLIC_WORKFLOWS } from 'graphql/queries';
import PublicWorkflowCard from '../public-workflow-card';

import { nothingToShowWorkflowIcon, playIcon } from 'assets/svg-icons';
import './styles.scss';

interface CommonType {
  label: string;
  value: string;
}

interface NothingToShowRecommendedProps {
  handleNewWorkflowBtn: (arg: CommonType) => void;
  setIsVideoModalOpen: (arg: boolean) => void;
  publicWorkflowsList: any;
  hide?: boolean;
}

const NothingToShowRecommended: FC<NothingToShowRecommendedProps> = ({
  handleNewWorkflowBtn,
  setIsVideoModalOpen,
  publicWorkflowsList,
  hide,
}) => {
  const { theme } = useContext(ThemeContext);
  const { hexomaticUser } = useContext(HexomaticUserContext);

  const getNamesForTooltip = useCallback((steps: any, count: number): string => {
    return steps
      .slice(count)
      .map(
        (current: any, index: number, arr: any[]) =>
          ` ${current.automation.name}${index === arr.length - 1 ? '' : ','} `,
      );
  }, []);

  return (
    <div className="nothing-to-show-recommended">
      <NothingToShowDetailed
        SVGImage={nothingToShowWorkflowIcon}
        fullText="Looks like you don’t have any workflows"
        dimColorText="Fortunately, it’s easy to create a new one."
        buttonText="New workflow"
        onClick={handleNewWorkflowBtn}
        secondOnClick={() => setIsVideoModalOpen(true)}
        secondButtonText="How to create"
        secondBtnIcon={playIcon}
      />
      <div className="recommended-wrapper">
        <hr className="w-100" />
        <h4 className="page-title mr-3">Recommendation</h4>

        {publicWorkflowsList.length ? (
          <>
            <div className="row" style={{ margin: '0 -5px' }}>
              {publicWorkflowsList?.map((item: any, index: number) => (
                <div style={{ padding: '5px' }} className="col-12 col-lg-6 col-xl-4">
                  <TemplateCard
                    theme={theme}
                    title={item.name}
                    description={item.description}
                    linkTo={`/workflows/current-workflow-template/${item.id}`}
                    steps={item.steps}
                    image={item.image}
                    fullText
                    categories={item.categories}
                    templatesPage={
                      !hide &&
                      index === publicWorkflowsList?.length - 1 &&
                      !!(hexomaticUser?.pricing_package && hexomaticUser?.pricing_package !== 'FREE')
                    }
                    demoLink={
                      !hide &&
                      index === publicWorkflowsList?.length - 1 &&
                      !!(hexomaticUser?.pricing_package && hexomaticUser?.pricing_package !== 'FREE')
                    }
                  />
                </div>
              ))}
            </div>
            <div className="d-flex align-items-center justify-content-center mt-3">
              <Link to="/public-workflows" className="clickable-text">
                SHOW ALL TEMPLATES
              </Link>
            </div>
          </>
        ) : (
          <Row style={{ margin: '0 -5px' }}>
            {Array.apply(null, Array(3)).map(item => (
              <Col sm={12} md={6} lg={4} style={{ minWidth: '220px', padding: '5px' }}>
                <div className="blank-template"></div>
              </Col>
            ))}
          </Row>
        )}
      </div>
    </div>
  );
};

export default NothingToShowRecommended;
