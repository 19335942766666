import React, { FC, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import { UserContext } from 'contexts/user-context';
//import Checkbox from 'shared-components/atom/checkbox';
import './styles.scss';

const outputs = [
  '_accessibility_audit_pass_count',
  '_accessibility_audit_fail_count',
  '_accessibility_audit_potential_count',
  '_accessibility_audit_manual_count',
  '_accessibility_audit_pdf_url',
];
const standards = [
  { label: 'IBM_Accessibility', value: 'IBM_Accessibility' },
  { label: 'WCAG_2_1', value: 'WCAG_2_1' },
  { label: 'WCAG_2_0', value: 'WCAG_2_0' },
];
const AccessibillityCheckerSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [fieldList, setFieldList] = useState<any>([]);
  const [options, setOptions] = useState<any>([]);
  const [logosList, setLogosList] = useState<any>([]);
  const [clicked, setClicked] = useState(false);
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [selectedLogo, setSelectedLogo] = useState<any>('');
  const [selectedOptions, setSelectedOptions] = useState<string>('');
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  //const [useLogo, setUseLogo] = useState(false);
  const { user } = useContext(UserContext);
  const {
    liftingSettings,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (fieldList?.length === 0 || !selectedOptions) return false;
    return true;
  };

  //recipe
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();

    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const key = dynamic_settings.keys[0].key;
      restoreSelectedSource({ key: key, fieldList: options, setSelectedSource: setSelectedSource });
      (dynamic_settings?.keys[0]?.settings?.logo || dynamic_settings?.keys[0]?.settings?.secondKey) &&
        setSelectedLogo(
          dynamic_settings.keys[0].settings.selected && user?.logo
            ? 'account_logo'
            : dynamic_settings.keys[0].settings.secondKey,
        );
      dynamic_settings?.keys[0]?.settings?.standart && setSelectedOptions(dynamic_settings.keys[0].settings.standart);
    }
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, []);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
      setClicked(true);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      setLogosList(fieldList);
      setOptions(fieldList);
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...outputs],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);

  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);
    let localSettings = {
      keys: [
        {
          key: fieldList.length && selectedSource ? selectedSource.value : null,
          settings: {
            logo: user && (selectedLogo === 'account_logo' ? user.logo : null),
            selected: selectedLogo === 'account_logo',
            standart: selectedOptions,
            secondKey: selectedLogo && selectedLogo !== 'account_logo' ? selectedLogo : null,
          },
        },
      ],
    };
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    
    liftingSettings({
      dynamicSettings: localSettings,
      outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...outputs],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [selectedSource, selectedOptions, selectedLogo]);

  useEffect(() => {
    selectedSource &&
      fieldList &&
      setLogosList(fieldList.filter((item: any) => item.value !== selectedSource.value) || []);
  }, [selectedSource]);

  useEffect(() => {
    setOptions(fieldList.filter((item: any) => item.value !== selectedLogo));
  }, [selectedLogo]);
  // const handleChangeLogo = (val: boolean) => {
  //   setUseLogo(val);
  //   !clicked && setClicked(true);
  // };
  const handleChangeSource = (selectedItem: any) => {
    setSelectedSource(selectedItem);
    !clicked && setClicked(true);
    setIsDirty();
  };
  const handleChangeStandard = (selectedItem: any) => {
    setSelectedOptions(selectedItem.value);
    !clicked && setClicked(true);
    setIsDirty();
  };
  const handleChangeLogo = (selectedItem: any) => {
    setSelectedLogo(selectedItem.value);
    !clicked && setClicked(true);
    setIsDirty();
  };
  return (
    <div>
      <Row>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Select source</span>
          <Select
            options={options}
            value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
            placeholder={'Select source'}
            onChange={handleChangeSource}
          />
        </Col>
        {/*user?.logo && (
          <Col lg={6} className="mb-4 d-flex checkbox-wrap">
            <span className="label secondary-color pr-2">Use account logo</span>
            <Checkbox checked={useLogo} onChange={handleChangeLogo} />
          </Col>
        )*/}
      </Row>
      <Row>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Select standard</span>
          <Select
            options={standards}
            value={selectedOptions}
            placeholder={'Select standard'}
            onChange={handleChangeStandard}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Select logo (Optional) </span>
          <Select
            options={
              user?.logo
                ? selectedLogo
                  ? [{ value: '', label: 'No logo' }, { value: 'account_logo', label: 'account logo' }, ...logosList]
                  : [{ value: 'account_logo', label: 'account logo' }, ...logosList]
                : selectedLogo
                ? [{ value: '', label: 'No logo' }, ...logosList]
                : logosList
            }
            value={selectedLogo}
            placeholder={'Select logo'}
            onChange={handleChangeLogo}
          />
        </Col>
      </Row>
    </div>
  );
};

export default React.memo(AccessibillityCheckerSettings);
