import { useState, useEffect, memo, FC } from 'react';

import { closePopupOnEsc } from 'helpers';
// import { Modal, Button, Spinner } from 'reactstrap';
import './styles.scss';

const Popup: FC<any> = ({ children, theme, escButtonClick }) => {
  useEffect(() => {
    const headerInnerWrapper = document.getElementsByClassName('header-inner-wrapper')[0] as HTMLDivElement;

    document.body.classList.add('overflow-hidden');
    document.body.classList.add('margin-6px');

    if (headerInnerWrapper) {
      headerInnerWrapper.style.width = '100vw';
    }
    return () => {
      document.body.classList.remove('overflow-hidden');
      document.body.classList.remove('margin-6px');
      localStorage.removeItem('packageInfo');
      if (headerInnerWrapper) {
        headerInnerWrapper.style.width = '100%';
      }
    };
  }, []);

  //! Close Popup on Esc button click
  useEffect(() => {
    closePopupOnEsc(() => escButtonClick());
  }, []);

  const [height, setHeight] = useState(document.body.clientHeight);
  return (
    <div
      className={`customModal ${theme === 'light' ? 'light' : 'dark'}`}
      onScroll={() => setHeight(document.body.clientHeight)}
    >
      <div className="modal-body">
        <div className="popup-alert">{children}</div>
      </div>
    </div>
  );
};

export default memo(Popup);
