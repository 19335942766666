import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
// import CalendarIcon from 'mdi-react/CalendarIcon';
// import { Button } from 'reactstrap';
// import { DatePickersProps } from './types';
import { calendarIcon, clockIcon } from '../../../assets/svg-icons';
import { getDateAndTime } from '../../../helpers';
import moment from 'moment';
import useClickOutside from '../../../hooks/useClickOutside';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import './styles.scss';

const getDate = (date: any) => {
  let lDate = getDateAndTime(moment.utc(parseInt(date)).format());
  let newDate = lDate && lDate.split(' ').splice(0, 3).join('  ');
  return newDate;
};

interface IDatePickers {
  handleChange: () => void;
  startPropDate: any;
  endPropDate?: any;
  minDate?: any;
  maxDate?: any;
  showTimeSelect?: any;
  selected?: any;
  placeholderText?: any;
  dateFormat?: any;
  workflowModal?: any;
  dontClose?: boolean;
  calendar?: boolean;
}

const DatePickers: React.FC<any> = ({
  startPropDate,
  endPropDate,
  handleChange,
  minDate,
  maxDate,
  showTimeSelect,
  selected,
  placeholderText,
  dateFormat,
  workflowModal,
  minTime,
  maxTime,
  dontClose,
  calendar
}) => {
  // const [isShowPicker, setIsShowPicker] = useState(false)
  const [startDate, setStartDate] = useState<null | Date>(null);
  const [endDate, setEndDate] = useState<null | Date>(new Date());
  const [startDateshow, setStartDateshow] = useState(false);
  const [endDateshow, setEndDateshow] = useState(false);
  const [isShowPicker, selectRef, setIsShowPicker] = useClickOutside();

  useEffect(() => {
    setStartDate(startPropDate);
    setEndDate(endPropDate);
  }, [startPropDate, endPropDate]);

  useEffect(() => {
    if (isShowPicker && endDate) {
      setIsShowPicker(false);
      handleChange(startDate, endDate);
    }
  }, [endDate]);

  useEffect(() => {
    if (workflowModal) {
      setEndDateshow(false);
      // setStartDateshow(false);
    }
  }, [workflowModal]);

  const onToggle = () => {
    if (!isShowPicker) {
      setIsShowPicker(!isShowPicker);
    }
  };

  const startshow = () => {
    setEndDateshow(false);
    setStartDateshow(!startDateshow);
    setIsShowPicker(true);
  };

  const endshow = () => {
    setStartDateshow(false);
    setEndDateshow(!endDateshow);
    setIsShowPicker(true);
  };
  return (
    <div className="schedule-block" ref={selectRef}>
      <div className="shcedule-wrapper">
        <button
          className="example-custom-input"
          onClick={e => {
            workflowModal && startshow();
            onToggle();
          }}
        >
          {/* <img src={CalendarIcon} className="calendar-icon" alt="icon" /> */}
          {calendar ? calendarIcon : clockIcon}
          {workflowModal ? (
            <span className="text">{moment(startDate).format('dddd, MMM DD, hh:mm A')}</span>
          ) : (
            <span className="text">
              {endDate ? (
                <>
                  <span className="calendar-date-field" onClick={() => startshow()}>
                    {moment(startDate ? startDate : new Date()).format('MMM D, YYYY')}
                  </span>
                  <span className="calendar-divider-field"> to </span>{' '}
                  <span className="calendar-date-field" onClick={() => endshow()}>
                    {moment(endDate).format('MMM D, YYYY')}
                  </span>
                </>
              ) : (
                <>
                  <span className="calendar-date-field" onClick={() => startshow()}>
                    {moment(startDate ? startDate : new Date()).format('MMM D, YYYY')}
                  </span>
                  <span className="calendar-divider-field"> to </span>{' '}
                  <span className="calendar-date-field" onClick={() => endshow()}>
                    End Date
                  </span>
                </>
              )}
            </span>
          )}
        </button>
        <div className="schedule-component">
          <>
            {startDateshow && (
              <div className={`date-time ${showTimeSelect ? 'react-datepicker-with-time' : ''}`}>
                <div className="vk-date-picker">
                  <div className="form__form-group-field">
                    <div className={`date-picker date-picker--interval align-items-center`}>
                      <DatePicker
                        selected={startDate}
                        showTimeSelect={showTimeSelect}
                        startDate={startDate}
                        onChange={(date: Date) => {
                          setStartDate(date);
                          !dontClose && setStartDateshow(false);
                          handleChange(date);
                        }}
                        placeholderText={placeholderText || 'select start date'}
                        dateFormat={dateFormat || 'MMM dd, yyyy'}
                        minDate={minDate ? minDate : new Date(2021, 4, 1)}
                        inline
                        onClickOutside={() => setStartDateshow(false)}
                        minTime={minTime ? minTime : null}
                        maxTime={maxTime ? maxTime : null}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {endDateshow && !workflowModal && (
              <div className="date-time">
                <div className="vk-date-picker">
                  <div className="form__form-group-field">
                    <div className={`date-picker date-picker--interval align-items-center`}>
                      <DatePicker
                        showTimeSelect={showTimeSelect}
                        startDate={endDate}
                        selected={endDate}
                        onChange={(date: Date) => {
                          setEndDate(date);
                          setEndDateshow(false);
                        }}
                        placeholderText="select end date"
                        dateFormat="MMM dd, yyyy"
                        minDate={startDate}
                        inline
                        onClickOutside={() => setEndDateshow(false)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default DatePickers;
