import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_ALL_INTEGRATIONS, GET_RENEWAL_DATE, LIST_PREMIUM_CREDITS } from 'graphql/queries';
import { Card } from 'react-bootstrap';
import { HexomaticUserContext } from 'contexts/hexomatic-user';
import { UserContext } from 'contexts/user-context';

import Emails from './Emails';
import Integrations from './Integrations';
import Api from './api';
import Tags from './Tags';
import SharingConfigs from './sharing-configs';
import CustomProgress from 'shared-components/atom/custom-progress-tooltip';
import CustomModal from 'shared-components/molecule/customModal';
import Payment from 'shared-components/molecule/payment';
import './styles.scss';

const HexowatchSettings = () => {
  const { user } = useContext(UserContext);
  const { hexomaticUser } = useContext(HexomaticUserContext);
  const [showModal, setShowModal] = useState(false);
  const { data: premiumCredits, loading: premiumCreditsLoading } = useQuery(LIST_PREMIUM_CREDITS);
  const {
    data: renewalDate,
    loading: renewalDateLoading,
    refetch: refetchRenewalDate,
  } = useQuery(GET_RENEWAL_DATE, {
    variables: {
      settings: {
        hexomatic: true,
        hexowatch: false,
        hexometer: false,
        premium_credits: true,
      },
    },
  });
  const {
    data: getAllIntegrations,
    loading: integtrationLoading,
    refetch: refetchIntegrations,
  } = useQuery(GET_ALL_INTEGRATIONS, {
    skip: !hexomaticUser.id,
    variables: {
      id: hexomaticUser.id,
    },
  });
  useEffect(() => {
    if (user && user.id) refetchRenewalDate();
  }, [user]);

  const calcUsage = (all: number, portion: number): number => {
    return ((all - portion) * 100) / all;
  };

  const handleRefetch = () => {
    refetchRenewalDate();
  };

  useEffect(() => {
    if (window.location.href.includes('integration_success')) {
      window.close();
    }
  }, []);

  return (
    <div className="settings-page pb-3">
      <h4 className="page-title">Settings</h4>
      <div className="row d-flex mx-0">
        <div className="col-xl-6 p-0 pr-xl-1 pl-lg-0 mt-2">
          <Card className="pulse-container py-0">
            {renewalDateLoading ? (
              <div className="pulse">
                <div className="line-dash-progress"></div>
              </div>
            ) : (
              <div className="card-body p-4" style={{ borderRadius: '6px', paddingTop: '1rem', paddingBottom: '1rem' }}>
                <div style={{ maxHeight: 'calc(100% - 24px)', height: '100%' }}>
                  <h2 className="auto-credits-title-h2 mb-3">Automation credits</h2>
                  <div className="d-flex">
                    <h2 className="auto-credits-title-h2  p-0 title-secondary mr-1">
                      <span className="primary-color">{`${hexomaticUser?.pricing_package || 'N/A'} ${
                        hexomaticUser?.subscription_status === 'trial'
                          ? 'Trial'
                          : hexomaticUser?.pricing_package_interval || ''
                      }`}</span>
                      <span className="text-secondary">{hexomaticUser?.automation_credit}/monthly</span>
                    </h2>
                    <span className="checks-consumed">
                      {(hexomaticUser?.automation_credit || 0) - (hexomaticUser?.left_automation_credit || 0)}/
                      {hexomaticUser.automation_credit || 0}
                    </span>
                  </div>
                  <h6 className="auto-credits-title align-items-center d-flex">
                    <CustomProgress
                      percent={
                        hexomaticUser?.automation_credit && typeof hexomaticUser?.left_automation_credit === 'number'
                          ? calcUsage(hexomaticUser?.automation_credit || 0, hexomaticUser?.left_automation_credit) || 0
                          : 0
                      }
                      text={
                        hexomaticUser?.automation_credit
                          ? `${
                              +(
                                hexomaticUser?.automation_credit - (hexomaticUser?.left_automation_credit || 0)
                              ).toFixed(1) || 0
                            } out of ${hexomaticUser?.automation_credit || 0}`
                          : ''
                      }
                      noMessage
                    />
                  </h6>

                  {!!renewalDate?.User?.getPackageRenewalDate &&
                    typeof renewalDate.User.getPackageRenewalDate.hexomatic === 'number' && (
                      <div className="d-flex ">
                        <h3 className="auto-credits-title-h3 wrap p-0 title-secondary mr-1 ">
                          Your allowance will renew{' '}
                          {!renewalDate.User.getPackageRenewalDate.hexomatic
                            ? ' ' + 'today'
                            : ' ' +
                              'in ' +
                              renewalDate.User.getPackageRenewalDate.hexomatic +
                              ` day${renewalDate.User.getPackageRenewalDate.hexomatic !== 1 ? 's ' : ' '}`}
                        </h3>
                      </div>
                    )}
                </div>
                <div className="d-flex justify-content-end">
                  <a className="upgrade-btn" href="https://hexomatic.com/pricing/">
                    Upgrade my plan
                    <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M10.3801 0L9.21333 1.14562L13.8307 5.6875H0V7.3125H5.91536H13.8307L9.21333 11.8544L10.3801 13L17 6.5L10.3801 0Z"
                        fill="#A801FF"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            )}
          </Card>
        </div>

        <div className="col-xl-6 pl-0 pl-xl-1 pr-0 pr-xl-0 mt-2">
          <Card className="pulse-container py-0">
            {renewalDateLoading ? (
              <div className="pulse">
                <div className="line-dash-progress"></div>
              </div>
            ) : (
              <div className="card-body p-4" style={{ borderRadius: '6px', paddingTop: '1rem', paddingBottom: '1rem' }}>
                {/* {userStatsLoading ? (
              <Loader light={theme === 'light'} />
            ) : ( */}
                <div className="d-flex flex-column" style={{ maxHeight: `calc(100% - 24px)`, height: '100%' }}>
                  <h2 className="auto-credits-title-h2 mb-3">Premium credits</h2>
                  <>
                    <p className="settings-plans-info-line">
                      <span className="info-name pr-1">Monthly premium credits:</span>
                      {user?.premium_credits_pricing_package === 'FREE' ? (
                        <span className="info-value">0</span>
                      ) : (
                        <span className="info-value">
                          {user?.left_premium_credits?.toFixed(2) || 0} left out of{' '}
                          {user?.premium_credits_pricing_package &&
                            premiumCredits?.Billing?.listPremiumCredits?.premiumCredits?.find(
                              (item: any) => item.name === user.premium_credits_pricing_package,
                            )?.limits.premium_credits_count}
                        </span>
                      )}
                    </p>

                    {(user?.premium_credits_pricing_package !== 'FREE' || !user?.premium_credits_pricing_package) && (
                      <>
                        <p className="settings-plans-info-line pt-1">
                          <span className="info-name">Credits will renew</span>
                          {'  '}
                          <span className="info-value">
                            {!renewalDate?.User?.getPackageRenewalDate?.premium_credits
                              ? 'today, '
                              : ' ' +
                                'in ' +
                                renewalDate?.User?.getPackageRenewalDate?.premium_credits +
                                ` day${renewalDate?.User?.getPackageRenewalDate?.premium_credits !== 1 ? 's' : ''}`}
                          </span>
                        </p>
                        <hr className="m-0 mt-2 border-color" />
                      </>
                    )}

                    <p className="pt-1 settings-plans-info-line">
                      <span className="info-name pr-1">Bonus one-time credits:</span>
                      <span className="info-value">
                        {(hexomaticUser?.left_premium_automation_credit &&
                          +hexomaticUser?.left_premium_automation_credit?.toFixed(2)) ||
                          0}
                      </span>
                    </p>
                  </>
                </div>

                {hexomaticUser &&
                  (hexomaticUser.pricing_package === 'GOLD' ||
                    hexomaticUser.pricing_package === 'SILVER' ||
                    hexomaticUser.pricing_package === 'BRONZE') &&
                  user?.premium_credits_pricing_package !== 'PC_264000' &&
                  user?.premium_credits_pricing_package !== 'PC_26400' && (
                    <div className="d-flex justify-content-end">
                      <div onClick={() => setShowModal(true)} className="upgrade-btn">
                        Add more
                        <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M10.3801 0L9.21333 1.14562L13.8307 5.6875H0V7.3125H5.91536H13.8307L9.21333 11.8544L10.3801 13L17 6.5L10.3801 0Z"
                            fill="#A801FF"
                          />
                        </svg>
                      </div>
                    </div>
                  )}
              </div>
            )}
          </Card>
        </div>
      </div>
      <div className="row d-flex m-0">
        {/* 1st column */}
        <div className="col-xl-6 p-0 pr-xl-1 pl-lg-0">
          <div className="row wrapper m-0">
            <div className="col-12 col-lg-12 mt-2 px-0">
              <Tags />
            </div>
            <div className="col-12 col-lg-12 mt-2 px-0">
              <Emails
                allIntegrations={getAllIntegrations}
                integtrationsLoading={integtrationLoading}
                refetchIntegrations={refetchIntegrations}
              />
            </div>
            <div className="col-12 col-lg-12 mt-2 px-0">
              {/* <SharingRecipes /> */}
              <SharingConfigs />
            </div>
          </div>
        </div>
        {/* 2nd column */}
        <div className="col-xl-6 p-0 pl-lg-1 pr-lg-0">
          <div className="row wrapper m-0">
            <div className="col-12 col-lg-12 mt-2 px-0">
              <Api
                allIntegrations={getAllIntegrations}
                integtrationsLoading={integtrationLoading}
                refetchIntegrations={refetchIntegrations}
              />
            </div>
            <div className="col-12 col-lg-12 mt-2 px-0">
              <Integrations
                allIntegrations={getAllIntegrations}
                integtrationsLoading={integtrationLoading}
                refetchIntegrations={refetchIntegrations}
              />
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <CustomModal ShowPopup={showModal} noNavigate escButtonClick={setShowModal}>
          <Payment
            setShowPopup={setShowModal}
            name={''}
            selectedValue={{ title: '105', value: 105 }}
            price={0}
            premium_credit
            refetch={handleRefetch}
            // offer
          />
        </CustomModal>
      )}
    </div>
  );
};

export default HexowatchSettings;
