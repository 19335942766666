import ModalContainer from 'shared-components/molecule/modal-container';
import { Popover, OverlayTrigger, Col, Row } from 'react-bootstrap';
import Input from 'shared-components/atom/input';
import Alert from 'shared-components/atom/alert-message';
import Button from 'shared-components/atom/button';
import './styles.scss';
const KonnectzitAndPabbly = ({
  errorMessage,
  setErrorMessage,
  inputText,
  showKonnectzitModal,
  setShowKonnectzitModal,
  setInputText,
  createdLoading,
  save,
  label,
  placeholder,
}: any) => {
  return (
    <ModalContainer
      ShowPopup={showKonnectzitModal}
      setShowPopup={() => {
        setShowKonnectzitModal(false);
        errorMessage && setErrorMessage('');
        setInputText('');
      }}
      className="modal-settings"
      escButtonClick={() => {
        setShowKonnectzitModal(false);
        errorMessage && setErrorMessage('');
        setInputText('');
      }}
    >
      <div className="pabbly-modal-block">
        <div className="pabbly-block">
          <label className="label">{label}</label>
          <div>
            <Input
              value={inputText}
              placeholder={placeholder || "Webhook URL"}
              onChange={e => {
                errorMessage && setErrorMessage('');
                setInputText(e.target.value);
              }}
            />
          </div>
        </div>
        {errorMessage && (
          <Row className="mb-4 w-100">
            <Col md={12}>
              <Alert type="error" className="w-100">
                {errorMessage}
              </Alert>
            </Col>
          </Row>
        )}
        <div className="btn-block">
          <Button
            name={'Save'}
            loading={createdLoading}
            disabled={!inputText.length}
            // onClick={() => (isUpdate ? updateWebhook() : createWebhook())}
            onClick={save}
          />
        </div>
      </div>
    </ModalContainer>
  );
};

export default KonnectzitAndPabbly;
