import { useLazyQuery } from '@apollo/react-hooks';
import { GET_PROGRESS_WORKFLOW_DATA } from 'graphql/queries';
import moment from 'moment';
import { statusTexts } from 'pages/workflows/helpers';
import { useEffect } from 'react';
import { FC, memo, useState } from 'react';
import Badge from 'shared-components/atom/badge';
import Tooltip from 'shared-components/atom/tooltip';
import { StatusEnums } from 'shared-components/atom/types';

const StatusBadge: FC<{
  realTimeStatusData: any;
  item: any;
  viewMode: 'cardView' | 'listView';
  index?: number;
  workflowsLength?: number;
}> = ({ realTimeStatusData, viewMode, index, item, workflowsLength }) => {
  const [currentWorkflow, setCurrentWorkflow] = useState(realTimeStatusData && realTimeStatusData[item.id]);
  const [progressInfo, setProgressInfo] = useState<any>(null);

  const [progressData] = useLazyQuery(GET_PROGRESS_WORKFLOW_DATA, {
    onCompleted(progressData) {
      if (progressData?.HexomaticWorkflow?.getWorkflowProgressData) {
        setProgressInfo({ ...progressData.HexomaticWorkflow.getWorkflowProgressData, id: item.id });
      }
    },
  });

  useEffect(() => {
    setCurrentWorkflow(realTimeStatusData && realTimeStatusData[item.id]);
  }, [realTimeStatusData]);

  return (
    <div
      onMouseOver={e => {
        if (item?.status === 'progress' && !currentWorkflow?.status && (!progressInfo || progressInfo.id !== item.id)) {
          progressData({
            variables: {
              workflowId: item.id,
            },
          });
        }
      }}
    >
      {currentWorkflow && currentWorkflow?.status ? (
        <Badge
          messageType={
            currentWorkflow.status === 'error'
              ? 'badge-alert'
              : currentWorkflow.status === 'completed'
              ? 'badge-success'
              : currentWorkflow.status === 'PAUSED'
              ? 'badge-secondary'
              : currentWorkflow.status === 'DRAFT'
              ? 'badge-warning'
              : currentWorkflow.status === 'CREDIT_REACHED' ||
                currentWorkflow.status === 'PREMIUM_CREDIT_REACHED' ||
                currentWorkflow.status === 'STORAGE_REACHED' ||
                currentWorkflow.status === 'CREDIT_STORAGE_REACHED' ||
                item.status === 'PREMIUM_CREDIT_STORAGE_REACHED'
              ? 'badge-limited-reached'
              : 'badge-primary'
          }
          width={115}
          buttonType="transparent"
        >
          {currentWorkflow.status === 'progress' && currentWorkflow.currentAutomation ? (
            <Tooltip
              placement={viewMode === 'cardView' ? 'right' : index === 0 ? 'bottom' : 'top'}
              text={`Processing ${currentWorkflow.currentAutomation} ${
                currentWorkflow.currentAutomation.indexOf('recipe') > -1 ? '' : 'automation'
              }, row #${currentWorkflow.currentIteration || 1}. ${
                currentWorkflow.lastActivityTime ? 'Last activity' : ''
              } ${currentWorkflow.lastActivityTime ? moment(currentWorkflow.lastActivityTime).fromNow() : ''}`}
              className="closer"
              isSmall
            >
              {item && item.status && currentWorkflow.status
                ? //@ts-ignore
                  StatusEnums[currentWorkflow.status]
                : StatusEnums.progress}
            </Tooltip>
          ) : item && item.status && currentWorkflow.status ? (
            //@ts-ignore
            StatusEnums[currentWorkflow.status]
          ) : (
            StatusEnums.progress
          )}
        </Badge>
      ) : item && item.status ? (
        <Badge
          messageType={
            item.status === 'error'
              ? 'badge-alert'
              : item.status === 'completed'
              ? 'badge-success'
              : item.status === 'PAUSED'
              ? 'badge-secondary'
              : item.status === 'DRAFT'
              ? 'badge-warning'
              : item.status === 'CREDIT_REACHED' ||
                item.status === 'PREMIUM_CREDIT_REACHED' ||
                item.status === 'STORAGE_REACHED' ||
                item.status === 'CREDIT_STORAGE_REACHED' ||
                item.status === 'PREMIUM_CREDIT_STORAGE_REACHED'
              ? 'badge-limited-reached'
              : 'badge-primary'
          }
          width={115}
          buttonType="transparent"
          hoverText={
            item &&
            (item.status === 'CREDIT_REACHED' ||
              item.status === 'PREMIUM_CREDIT_REACHED' ||
              item.status === 'STORAGE_REACHED' ||
              item.status === 'CREDIT_STORAGE_REACHED' ||
              item.status === 'PREMIUM_CREDIT_STORAGE_REACHED')
              ? //@ts-ignore
                statusTexts[item.status]
              : ''
          }
        >
          {item.status === 'progress' && progressInfo?.currentAutomation ? (
            <Tooltip
              placement={
                viewMode === 'cardView'
                  ? 'right'
                  : workflowsLength && workflowsLength - 1 === index
                  ? 'left'
                  : index === 0
                  ? 'bottom'
                  : 'top'
              }
              text={`Processing ${progressInfo.currentAutomation} ${
                progressInfo.currentAutomation.indexOf('recipe') > -1 ? '' : 'automation'
              }, row #${progressInfo.currentIteration || 1}. ${progressInfo.lastActivityTime ? 'Last activity' : ''} ${
                progressInfo.lastActivityTime ? moment(progressInfo.lastActivityTime).fromNow() : ''
              }`}
              className="closer"
              isSmall
            >
              {item && item.status
                ? //@ts-ignore
                  StatusEnums[item.status]
                : StatusEnums.progress}
            </Tooltip>
          ) : item && item.status ? (
            //@ts-ignore
            StatusEnums[item.status]
          ) : (
            StatusEnums.progress
          )}
        </Badge>
      ) : null}
    </div>
  );
};

export default memo(StatusBadge);
