import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Checkbox from 'shared-components/atom/checkbox';
import Input from 'shared-components/atom/input';
import Select from 'shared-components/atom/select';
import Alert from 'shared-components/atom/alert-message';
import CustomMultiSelect from 'shared-components/molecule/multiselect';
import { seoIntelligenceOptions } from '../../constants';
import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import './styles.scss';

const domainOptions = [
  { label: 'domain', value: 'domain' },
  { label: 'webpage', value: 'webpage' },
];

const backlinksStatusTypeOptions = [
  { label: 'All', value: 'all' },
  { label: 'Live', value: 'live' },
  { label: 'Lost', value: 'Lost' },
];

const limitValues = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '30', value: 30 },
  { label: '40', value: 40 },
  { label: '50', value: 50 },
  { label: '60', value: 60 },
  { label: '70', value: 70 },
  { label: '80', value: 80 },
  { label: '90', value: 90 },
  { label: '100', value: 100 },
  { label: '150', value: 150 },
  { label: '200', value: 200 },
  { label: '250', value: 250 },
  { label: '500', value: 500 },
  { label: '750', value: 750 },
  { label: '1000', value: 1000 },
];

const SEOReferringDomains: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [fieldList, setFieldList] = useState<any>([]);
  const [clicked, setClicked] = useState(false);
  const [source, setSource] = useState('');
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [selectedSourceType, setSelectedSourceType] = useState<any>({ label: 'domain', value: 'domain' });
  const [saveTo, setSaveTo] = useState({ label: '10', value: 10 });
  const [errorMessage, setErrorMessage] = useState('');
  const [selected, setSelected] = useState<any>([]);
  const [backlinksStatusType, setBacklinksStatusType] = useState({ label: 'Live', value: 'live' });
  // const [excludeInternalBacklinks, setExcludeInternalBacklinks] = useState(true);
  const zeroIndexOutput: string[] = automationIndex === 0 ? ['_backlink_intelligence_target'] : [];
  const [includeSubdomains, setIncludeSubdomains] = useState(true);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const {
    liftingSettings,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (errorMessage) {
      return false;
    }
    if (selected?.length <= 0) return false;
    if (fieldList?.length === 0 ? !source : Array.isArray(selectedSource) ? !selectedSource?.length : !selectedSource) return false;
    return true;
  };

  useEffect(() => {
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const { keys } = dynamic_settings;
      const { settings } = keys[0];

      const referringDomainConfigs = settings ? settings.fields : keys[0].fields;
      const isDomain = settings ? settings.isDomain : keys[0].isDomain;
      const mode = settings ? settings.mode : keys[0].mode;
      // filters: JSON.stringify(finList),
      const include_subdomains = settings ? settings.include_subdomains : keys[0].include_subdomains;
      const backlinks_status_type = settings ? settings.backlinks_status_type : keys[0].backlinks_status_type;
      const to = settings ? settings.to : keys[0].to;
      // const from = settings ? settings.from : keys[0].from;
      const inputValue = settings ? keys[0].inputValue : keys[0].target;

      inputValue && setSource(inputValue);
      setSelectedSourceType({
        label: isDomain ? 'domain' : 'webpage',
        value: isDomain ? 'domain' : 'webpage',
      });
      const saveVal = limitValues.find((item: any) => item.value === to);
      saveVal ? setSaveTo(saveVal) : setSaveTo({ label: '10', value: 10 });
      const arr = [] as any;
      referringDomainConfigs &&
        referringDomainConfigs.map((output: string) => {
          const val = seoIntelligenceOptions.find(item => item.value === output);
          val && arr.push(val);
        });
      setSelected(arr);
      const selectedBacklinksStatusType =
        backlinks_status_type && backlinksStatusTypeOptions.find(item => backlinks_status_type === item.value);
      selectedBacklinksStatusType && setBacklinksStatusType(selectedBacklinksStatusType);
      typeof include_subdomains === 'boolean' && setIncludeSubdomains(include_subdomains);
    }
  }, []);

  //recipe
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();

    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const key = dynamic_settings.keys[0].key;
      restoreSelectedSource({ key: key, fieldList: options, setSelectedSource: setSelectedSource });
    }
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [
          ...fieldList.map((item: { value: any }) => item.value),
          ...zeroIndexOutput,
          ...selected.map((item: any) => item.value),
        ],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, selected, fieldListWithTypes, automationOutputTypes]);

  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });

    let localSettings = {
      keys: [
        {
          inputValue: fieldList.length === 0 && automationIndex === 0 ? source.trim() : null,
          key: fieldList.length && selectedSource ? selectedSource.value : null,
          settings: {
            fields: selected.map((item: { value: any }) => item.value),
            isDomain: selectedSourceType.value === 'domain' ? true : false,
            // filters: JSON.stringify(finList),
            include_subdomains: includeSubdomains,
            backlinks_status_type: backlinksStatusType.value || 'live',
            internal_list_limit: null,
          },
        },
      ],
    };
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: localSettings,
      outputKeysData: [
        ...fieldList.map((item: { value: any }) => item.value),
        ...zeroIndexOutput,
        ...selected.map((item: any) => item.value),
      ],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [
    source,
    errorMessage,
    selectedSource,
    selectedSourceType,
    saveTo,
    selectedSourceType,
    selected,
    includeSubdomains,
    fieldList,
    fieldListWithTypes,
    automationOutputTypes,
    automationIndex,
  ]);

  return (
    <div className="google-automation-settings">
      <Row>
        {fieldList.length > 0 || automationIndex > 0 ? (
          <Col lg={6} className="mb-4">
            <span className="label secondary-color">Source</span>
            <Select
              options={fieldList}
              value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
              placeholder={'Select source'}
              onChange={(selectedItem: any) => {
                setSelectedSource(selectedItem);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
        ) : (
          <Col lg={6} className="mb-4">
            <span className="label secondary-color">{selectedSourceType.value === 'domain' ? 'Domain' : 'URL'}</span>
            <Input
              value={source}
              onChange={e => {
                setSource(e.target.value);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
        )}
        <Col lg={6}>
          <Row>
            <Col lg={6} className="mb-4">
              <span className="label secondary-color">Explore mode</span>
              <Select
                options={domainOptions}
                value={selectedSourceType}
                placeholder={'Select mode'}
                onChange={(selectedItem: any) => {
                  setSelectedSourceType(selectedItem);
                  !clicked && setClicked(true);
                  setIsDirty();
                }}
              />
            </Col>

            {/* <Col md={6}>
              <span className="label secondary-color">Limit results up to:</span>
              <Select
                options={limitValues}
                value={saveTo}
                placeholder={'Select limit'}
                onChange={(selectedItem: any) => {
                  setSaveTo(selectedItem);
                  !clicked && setClicked(true);
                }}
              />
            </Col> */}
            <Col lg={6} className="mb-4">
              <span className="label secondary-color">Status type</span>
              <Select
                options={backlinksStatusTypeOptions}
                value={backlinksStatusType}
                placeholder={'Select status type'}
                onChange={(selectedItem: any) => {
                  setBacklinksStatusType(selectedItem);
                  !clicked && setClicked(true);
                  setIsDirty();
                }}
                textDefault
              />
            </Col>
          </Row>
        </Col>
        <Col lg={6}>
          <span className="label secondary-color">Fields</span>
          <CustomMultiSelect
            options={seoIntelligenceOptions}
            value={selected}
            onChange={(sel: any) => {
              setSelected(sel);
              !clicked && setClicked(true);
              setIsDirty();
            }}
            labelledBy="Select"
          />
        </Col>
        <Col lg={6}>
          <Row>
            {/* <Col lg={6}>
              <span className="label secondary-color">Status type</span>
              <Select
                options={backlinksStatusTypeOptions}
                value={backlinksStatusType}
                placeholder={'Select status type'}
                onChange={(selectedItem: any) => {
                  setBacklinksStatusType(selectedItem);
                  !clicked && setClicked(true);
                }}
                textDefault
              />
            </Col> */}
            <Col lg={12} className="checkbox-wrapper">
              <div className="item-block mt-3">
                <Checkbox
                  checked={includeSubdomains}
                  label="Include sub domains"
                  onChange={() => {
                    setIncludeSubdomains(!includeSubdomains);
                    !clicked && setClicked(true);
                    setIsDirty();
                  }}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {errorMessage && (
        <Row>
          <Col md={12}>
            <Alert type="error" className="create-workflow">
              {errorMessage}
            </Alert>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default React.memo(SEOReferringDomains);
