import { FC } from 'react';
import Select from 'shared-components/atom/export-select';
import { checkedIconPrice, infinityIcon } from '../../../assets/svg-icons';
import { IPricingItem } from '../types';
import './styles.scss';

export const options = [
  { title: '10000', value: 1 },
  { title: '20000', value: 2 },
  { title: '30000', value: 3 },
  { title: '40000', value: 4 },
  { title: '50000', value: 5 },
  { title: '60000', value: 6 },
  { title: '70000', value: 7 },
  { title: '80000', value: 8 },
  { title: '90000', value: 9 },
  { title: '100000', value: 10 },
];

const PricingItem: FC<IPricingItem> = ({
  isBold,
  text,
  isChecked,
  unlimited,
  number,
  select,
  selectedValue,
  onChange,
}) => {
  return (
    <div className="pricing-item">
      {isBold ? (
        <div className="bold-item">{text}</div>
      ) : (
        <div className="d-flex">
          <div className="d-flex align-items-center justify-content-center">
            <div className={`${isChecked || unlimited ? 'icon-wrapper text-center' : 'number-wrapper m-auto'}`}>
              {isChecked ? checkedIconPrice : unlimited ? infinityIcon : number}
            </div>
          </div>
          <div className="text-wrapper ml-2 px-0">
            {select ? (
              <Select
                //@ts-ignore
                options={options}
                //@ts-ignore
                value={selectedValue.title}
                click={(val: any) => onChange && onChange({ value: Number(val), title: String(val * 10000) })}
                className="pricing-item-select"
              />
            ) : (
              text
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PricingItem;
