import { FC, useState, FormEvent, useEffect, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import Cards from 'react-credit-cards';
import Payment from 'payment';

import { CreditCardFormProps } from './types';
import CreditCardFormComponent from './card-form';
import { ThemeContext } from '../../../contexts/theme-context';

import Visa from '../../../assets/payments/Visa.png';
import AmericanExpress from '../../../assets/payments/AmericanExpress.png';
import MasterCard from '../../../assets/payments/MasterCard.png';
import UnionPay from '../../../assets/payments/UnionPay.png';
import Discover from '../../../assets/payments/Discover.png';
import DinersClub from '../../../assets/payments/DinersClub.png';
import JCB from '../../../assets/payments/JCB.png';

import Message from '../../../shared-components/atom/alert-message';
import Loader from '../../../shared-components/atom/loader';
import Button from '../../../shared-components/atom/button';

import 'react-credit-cards/es/styles-compiled.css';
import './styles.scss';
import { longArrowRight } from 'assets/svg-icons';
import CardItem from './card-item';

const CreditCardForm: FC<CreditCardFormProps> = ({
  onSubmit,
  handleChangeDeafault,
  handleRemove,
  cardInfo,
  loading,
  error,
  cards,
}) => {
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);
  const [cardData, setCardData] = useState({
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
  });
  const [formData, setFormData] = useState({
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
  });

  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    if (cardInfo) {
      setCardData({
        cvc: cardInfo.cvc || '',
        expiry: cardInfo.expiry || '',
        name: cardInfo.name || '',
        number: cardInfo.number || '',
        focus: '',
      });
      setFormData({
        ...formData,
      });
      setShowPaymentMethod(false);
    } else if (!loading) {
      setShowPaymentMethod(true);
    }
  }, [cardInfo]);
  useEffect(() => {
    setFormData({
      cvc: '',
      expiry: '',
      name: '',
      number: '',
      focus: '',
    });
  }, [showPaymentMethod]);
  const fieldChange = (e: FormEvent) => {
    const target = e.target as HTMLInputElement;
    setCardData({
      ...cardData,
      [target.name]: target.value,
    });
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const setFocusName = (e: FormEvent) => {
    const target = e.target as HTMLInputElement;
    setCardData({
      ...cardData,
      focus: target.name,
    });
    setFormData({
      ...formData,
      focus: target.name,
    });
  };

  const unsetFocusName = (e: FormEvent) => {
    setCardData({
      ...cardData,
      focus: '',
    });
    setFormData({
      ...formData,
      focus: '',
    });
  };

  const handlePaymentFormSubmit = async () => {
    await onSubmit(formData);
  };

  useEffect(() => {
    if (error) setShowPaymentMethod(true);
  }, [error]);

  useEffect(() => {
    cards && cards.length > 0 && setShowPaymentMethod(false);
  }, [cards]);

  return (
    <Row>
      {loading ? (
        <div className="text-center justify-content-center mt-4" style={{ width: '100%' }}>
          <Loader light={theme === 'light'} />
        </div>
      ) : (
        <>
          {showPaymentMethod && cards && cards.length > 0 && (
            <div className="position-relative w-100">
              <div
                className="cursor-pointer position-absolute goBack text-right w-100"
                onClick={() => setShowPaymentMethod(false)}
              >
                <span>{longArrowRight}</span>
              </div>
            </div>
          )}
          {!showPaymentMethod && cards && cards.length > 0 && (
            <div className="row justify-content-center mb-2 w-100 mx-0">
              {cards.map((card: any, index: number) => (
                <CardItem
                  isDefault={card.isDefault === 'true'}
                  type={card.brand}
                  number={card.lastDigits}
                  handleRemove={() => handleRemove(card.id)}
                  handleChangeDeafault={() => card.isDefault !== 'true' && handleChangeDeafault(card.id)}
                  index={index}
                  isDelete={cards.length > 1}
                />
              ))}
            </div>
          )}
          <Col md={12} xl={7}>
            {showPaymentMethod && (
              <>
                <CreditCardFormComponent
                  onSubmit={handlePaymentFormSubmit}
                  cardData={formData}
                  loading={loading}
                  onFieldBlur={unsetFocusName}
                  onFieldFocus={setFocusName}
                  onFieldChange={fieldChange}
                />
                <p>&nbsp;</p>
              </>
            )}
          </Col>
          <Col md={12} xl={showPaymentMethod ? 5 : 12} className="payment-card-wrapper">
            <Cards
              cvc={(!showPaymentMethod ? cardData : formData).cvc.toString().replace(/[0-9]/g, '*')}
              expiry={(!showPaymentMethod ? cardData : formData).expiry.toString().replace(/[0-9]/g, '*')}
              focused={(!showPaymentMethod ? cardData : formData).focus as any}
              name={(!showPaymentMethod ? cardData : formData).name.toString().replace(/[a-z,A-Z]/g, '*')}
              number={(!showPaymentMethod ? cardData : formData).number.toString().replace(/[0-9]/g, '*')}
              issuer={Payment.fns.cardType((!showPaymentMethod ? cardData : formData).number)}
              preview
            />
            <div className="payment-icons">
              <img src={Visa} alt="Visa" className="m-1" />
              <img src={MasterCard} alt="Master Card" className="m-1" />
              <img src={AmericanExpress} alt="American Express" className="m-1" />
              <img src={Discover} alt="Discover" className="m-1" />
              <img src={JCB} alt="JCB" className="m-1" />
              <img src={DinersClub} alt="Diners Club" className="m-1" />
              <img src={UnionPay} alt="Union Pay" className="m-1" />
            </div>
          </Col>
          <Col md={12}>
            {error && <Message text={error} type={'error'} />}
            {!showPaymentMethod && (
              <div className="mt-4 d-flex align-items-center justify-content-center">
                <Button onClick={() => setShowPaymentMethod(true)} name={'Add payment method'} />
              </div>
            )}
          </Col>
          <Col md={11} className="payment-footer-text">
            <div>
              <p>
                We do not store your credit card information in our system at any point in time. Your payment
                information is securely transmitted to a gateway (Stripe) that will manage your recurring subscription
                going forward. If you have any questions, please don't hesitate to contact us at billing@hexact.io
              </p>
            </div>
          </Col>
        </>
      )}
    </Row>
  );
};

export default CreditCardForm;
