import { FC, memo } from 'react';
import './styles.scss';

interface TableCardFacadeLoadingTypeProps {
  rowCount: number;
  tableView?: boolean;
}

const TableCardFacadeLoading: FC<TableCardFacadeLoadingTypeProps> = ({ rowCount, tableView }) => {
  return (
    <div className="table-card-facade-loading">
      {tableView
        ? Array.apply(null, Array(rowCount)).map(() => (
            <div className="table-facade-loading-row">
              {Array.apply(null, Array(5)).map(() => (
                <div className="table-facade-loading-col">
                  <div className="table-facade-loading-col-div"></div>
                </div>
              ))}
            </div>
          ))
        : 
            <div className="card-facade-loading-row">
              {Array.apply(null, Array(rowCount * 3)).map(() => (
                <div className="card-facade-loading-col">
                  <div className="card-facade-loading-col-div"></div>
                </div>
              ))}
            </div>
          }
    </div>
  );
};

export default memo(TableCardFacadeLoading);
