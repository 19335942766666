import React, { FC, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLazyQuery } from '@apollo/react-hooks';
import StretchLoading from 'shared-components/molecule/stretch-loading';
import Button from 'shared-components/atom/button';
import Select from 'shared-components/atom/select';
import Datalist from 'shared-components/atom/dataList';
import Tooltip from 'shared-components/atom/tooltip';
import Input from 'shared-components/atom/input';
import Alert from 'shared-components/atom/alert-message';

import { columnOptions } from '../../constants';
import { GET_GOOGLE_SPREAD_SHEETS, GET_SHEETS, GET_ALL_INTEGRATIONS } from 'graphql/queries';
import { HexomaticUserContext } from 'contexts/hexomatic-user';
import { HOST } from 'constants/host';
import { errorMessages } from 'constants/index';

import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import Checkbox from 'shared-components/atom/checkbox';
import CustomMultiSelect from 'shared-components/molecule/multiselect';
import { reloadIcon } from 'assets/svg-icons';
import './styles.scss';

const GoogleSheetImportSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  let int: any;
  const [googleOptions, setGoogleOptions] = useState<any>([]);
  const [spreadsheetOptions, setSpreadsheetOptions] = useState<any>([]);
  const [sheetOptions, setSheetOptions] = useState<any>([]);
  // const [selectedMaxCount, setSelectedMaxCount] = useState(googleSearchRestrictedCountOptions[0]);

  const [connected, setConnected] = useState<boolean>(false);
  const [selectedGoogle, setSelectedGoogle] = useState<any>(null);
  const [spreadsheetText, setSpreadsheetText] = useState('');
  const [selectedSheet, setSelectedSheet] = useState<any>(null);
  const [columns, setColumns] = useState<any>(columnOptions);
  const [errorMessage, setErrorMessage] = useState('');
  const { hexomaticUser } = useContext(HexomaticUserContext);
  const [userId, setUserId] = useState(hexomaticUser ? hexomaticUser.id : -1);
  const [clicked, setClicked] = useState(false);
  const [fieldList, setFieldList] = useState<any>([]);
  const [selectedColumn, setSelectedColumn] = useState<{ label: string; value: string } | null>(null);
  const [selectedColumns, setSelectedColumns] = useState<any>([]);
  const [includeHeader, setIncludeHeader] = useState(true);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);
  const [saveFrom, setSaveFrom] = useState<number | string>('');
  const [saveTo, setSaveTo] = useState<number | string>('');
  const [page, setPage] = useState(1);
  const [nextPageToken, setNextPageToken] = useState('');
  const [showGoogleMessage, setShowGoogleMessage] = useState(false);
  const [el, setEl] = useState(null as any);
  const [googleConnect, setGoogleConnect] = useState(false);

  const [getAllIntegrations, { data: integrationsData, loading: getIntegrationLoading }] = useLazyQuery(
    GET_ALL_INTEGRATIONS,
    {
      fetchPolicy: 'no-cache',
    },
  );
  const [getSheets, { data: sheetData, loading: getSheetsLoading }] = useLazyQuery(GET_SHEETS, {
    fetchPolicy: 'no-cache',
  });
  const [getSpreadSheets, { data: spreadsheetData, loading: getSpreadsheetsLoading }] = useLazyQuery(
    GET_GOOGLE_SPREAD_SHEETS,
    {
      fetchPolicy: 'no-cache',
    },
  );
  const [maxImportCount, setMaxImportCount] = useState(-1);

  const {
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    liftingSettings,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  useEffect(() => {
    if (sheetData?.HexomaticIntegrations?.getGoogleSheetIntegrationSpreadsheetsSheets?.sheets && selectedSheet) {
      const sel = sheetData.HexomaticIntegrations.getGoogleSheetIntegrationSpreadsheetsSheets.sheets.find(
        (item: any) => item.sheet_id === selectedSheet.value,
      );
      sel && setColumns(columnOptions.slice(0, sel.col_count));
      sel && setMaxImportCount(sel.row_count);
    }
  }, [selectedSheet, sheetData]);

  const checkFields = () => {
    if (!connected || !spreadsheetText || !selectedSheet || !selectedColumns?.length || errorMessage) return false;
    if (saveTo && saveFrom) {
      if (saveTo < saveFrom) {
        setErrorMessage('"Save from" value can`t be greater than or equal to "Save to" value');
        clearErrorMsg();
        return false;
      }
      if (saveFrom > saveTo) {
        setErrorMessage('"Save to" value can`t be less than or equal to "Save from" value');
        clearErrorMsg();
        return false;
      }
    } else if ((saveTo && !saveFrom) || (!saveTo && saveFrom)) {
      setErrorMessage('"Save from" and "Save to" values should be both provided');
      clearErrorMsg();
      return false;
    }
    return true;
  };

  //restore
  useEffect(() => {
    getAllIntegrations({
      variables: { id: userId },
    });
    if (automationSendSettings) {
      const currentAutomation = automationSendSettings.find((automation: any, index: any) => index === automationIndex);
      if (currentAutomation.dynamic_settings) {
        const { dynamic_settings, integration_settings } = currentAutomation;
        restoreData({ dynamic_settings, integration_settings });
      }
    }
  }, []);

  const restoreData = ({ dynamic_settings, integration_settings }: any) => {
    // setSelectedColumn({ label: dynamic_settings.column, value: dynamic_settings.column });
    setSelectedColumns(
      Array.isArray(dynamic_settings.column)
        ? dynamic_settings.column.map((column: any) => {
            return { label: column, value: column };
          })
        : [{ label: dynamic_settings.column, value: dynamic_settings.column }],
    );
  };

  //restore

  // getting integrations
  useEffect(() => {
    if (integrationsData?.HexomaticIntegrations?.getAllIntegrations?.google_sheet) {
      const { google_sheet } = integrationsData.HexomaticIntegrations.getAllIntegrations;
      const options = google_sheet.map((item: { id: any; data: any; name?: string }) => {
        const googleData = JSON.parse(item.data);
        return {
          label: item.name ? item.name : googleData && googleData.name,
          value: item.id,
        };
      });
      const currentAutomation = automationSendSettings.find((automation: any, index: any) => index === automationIndex);
      const { integration_settings } = currentAutomation;
      if (integration_settings) {
        const finded = options.find(
          (option: { value: any; label: any }) =>
            option.value === integration_settings._ref_data || option.label === integration_settings._ref_data,
        );
        if (finded) {
          setSelectedGoogle(finded);
        } else if (googleConnect && options?.length === 1) {
          setSelectedGoogle(options[0]);
          setClicked(true);
          setIsDirty();
        } else {
          setSelectedGoogle({ label: integration_settings._ref_data, value: integration_settings._ref_data });
        }
      }
      setGoogleOptions(options);
      options && options.length && setConnected(true);
    }
  }, [integrationsData]);

  useEffect(() => {
    selectedGoogle?.value &&
      getSpreadSheets({
        variables: {
          settings: {
            integration_id: selectedGoogle ? selectedGoogle.value : null,
          },
        },
      });
  }, [selectedGoogle]);

  useEffect(() => {
    setErrorMessage('');
    if (spreadsheetData?.HexomaticIntegrations?.getGoogleSheetIntegrationSpreadsheets) {
      const { error_code, spreadsheets } = spreadsheetData.HexomaticIntegrations.getGoogleSheetIntegrationSpreadsheets;
      if (error_code) {
        //@ts-ignore
        setErrorMessage(errorMessages[error_code] || error_code);
        setSelectedGoogle(null);
        return;
      }
      const options = spreadsheets?.files?.map((item: { name: any; id: any }) => ({
        label: item.name,
        value: item.id,
      }));
      spreadsheets.nextPageToken && setNextPageToken(spreadsheets.nextPageToken);
      const { dynamic_settings } = getDynamicAndIntegrationsSettings();
      if (dynamic_settings) {
        const finded = options.find((item: { value: any }) => item.value === dynamic_settings.spreadsheet_id);
        finded ? setSpreadsheetText(finded.label) : setSpreadsheetText('');
        const findedSheet = sheetOptions.find(
          (item: { label: string; value: any }) => item.label === dynamic_settings.sheet_name,
        );
        findedSheet
          ? setSelectedSheet(findedSheet)
          : dynamic_settings.sheet_name &&
            setSelectedSheet({ label: dynamic_settings.sheet_name, value: dynamic_settings.sheet_name });
        typeof dynamic_settings.header_option !== 'string'
          ? setIncludeHeader(true)
          : setIncludeHeader(dynamic_settings.header_option === 'include' ? true : false);
        typeof dynamic_settings.save_from === 'number' && setSaveFrom(dynamic_settings.save_from);
        typeof dynamic_settings.save_to === 'number' && setSaveTo(dynamic_settings.save_to);
      }
      if (page > 1) {
        setSpreadsheetOptions([...spreadsheetOptions, ...options]);
      } else {
        setSpreadsheetOptions(options);
      }
    }
  }, [spreadsheetData]);

  useEffect(() => {
    setErrorMessage('');
    if (sheetData?.HexomaticIntegrations?.getGoogleSheetIntegrationSpreadsheetsSheets) {
      const { error_code, sheets } = sheetData.HexomaticIntegrations.getGoogleSheetIntegrationSpreadsheetsSheets;
      if (error_code) {
        setErrorMessage(error_code);
        return;
      }
      const options = sheets.map((item: { title: string; sheet_id: number }) => ({
        label: item.title,
        value: item.sheet_id,
      }));

      const { dynamic_settings } = getDynamicAndIntegrationsSettings();
      if (dynamic_settings) {
        const finded = options.find(
          (item: { label: string; value: any }) => item.label === dynamic_settings.sheet_name,
        );
        if (finded) {
          setSelectedSheet(finded);
        } else {
          // selectedSheet && setSelectedSheet(null);
          // // selectedColumn && setSelectedColumn(null);
          // selectedColumns && setSelectedColumns([]);
        }
      }

      setSheetOptions(options);
    }
  }, [sheetData]);

  useEffect(() => {
    const selectedSpreadSheet = spreadsheetOptions.find((item: { label: string }) => item.label === spreadsheetText);
    if (selectedSpreadSheet) {
      getSheets({
        variables: {
          settings: {
            integration_id: selectedGoogle ? selectedGoogle.value : null,
            spreadsheet_id: selectedSpreadSheet.value,
          },
        },
      });
    }
  }, [spreadsheetText, spreadsheetOptions]);
  // // getting integrations

  //recipe
  useEffect(() => {
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();

    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      setConnected(true);
      typeof dynamic_settings.header_option !== 'string'
        ? setIncludeHeader(true)
        : setIncludeHeader(dynamic_settings.header_option === 'include' ? true : false);
      typeof dynamic_settings.save_from === 'number' && setSaveFrom(dynamic_settings.save_from);
      typeof dynamic_settings.save_to === 'number' && setSaveTo(dynamic_settings.save_to);
    }
    // options && options.length && setFieldList(options);
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    filteredOptions && filteredOptions.length && setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
  }, [selectedRecipes, isSettingsChange]);

  useEffect(() => {
    const jsonParse: any = {};
    selectedColumns.map((item: { value: string }) => (jsonParse[`_google_import_${item.value}`] = 'any'));
    liftingSettings({
      outputKeysData: [
        ...fieldList.map((item: { value: string }) => item.value),
        ...selectedColumns.map((item: { value: string }) => `_google_import_${item.value}`),
      ],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [fieldList, fieldListWithTypes, automationOutputTypes, selectedColumns]);

  //lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    setClicked(false);

    const selectedSpreadSheet = spreadsheetOptions.find((item: { label: string }) => item.label === spreadsheetText);

    !selectedSpreadSheet && setCompletedFields({ index: automationIndex, checked: false });

    const sortedColumns = [...selectedColumns];
    // sortedColumns.sort((a: any, b: any) => {
    //   if (a.value < b.value) return -1;
    //   if (a.value > b.value) return 1;
    //   return 0;
    // });

    let localSettings = {
      spreadsheet_id: selectedSpreadSheet?.value,
      existing_spreadsheet_name: selectedSpreadSheet?.label,
      sheet_name: selectedSheet && selectedSheet.label,
      column: sortedColumns.map((column: any) => column.value),
      header_option: includeHeader ? 'include' : 'not include',
      save_from: !saveFrom && saveTo ? 1 : saveFrom,
      save_to: !saveTo && saveFrom ? (maxImportCount !== -1 ? maxImportCount : saveTo) : saveTo,
    };
    const jsonParse: any = {};
    sortedColumns.map((item: { value: string }) => (jsonParse[`_google_import_${item.value}`] = 'any'));

    liftingSettings({
      integrationsSettings: {
        _ref_type: 'INTEGRATION',
        _ref_data: selectedGoogle ? selectedGoogle.value : null,
      },
      dynamicSettings: localSettings,
      outputKeysData: [
        ...fieldList.map((item: { value: string }) => item.value),
        ...sortedColumns.map((item: { value: string }) => `_google_import_${item.value}`),
      ],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [spreadsheetText, selectedColumns, selectedSheet, errorMessage, includeHeader, saveFrom, saveTo, selectedGoogle]);

  const showGoogle = (secondWindow: any, url: string) => {
    secondWindow && (secondWindow.location = url);
    setEl(secondWindow);
    setShowGoogleMessage(true);
    setGoogleConnect(true);
    int = setInterval(() => {
      if (!secondWindow || secondWindow.closed) {
        clearInterval(int);
        setShowGoogleMessage(false);
        setEl(null);
      }
    });
  };

  useEffect(() => {
    if (!showGoogleMessage && googleConnect) {
      getAllIntegrations({
        variables: { id: userId },
      });
      setErrorMessage('');
    }
  }, [el, showGoogleMessage, googleConnect, userId]);

  const fetchSpreadSheets = () => {
    getSpreadSheets({
      variables: {
        settings: {
          integration_id: selectedGoogle ? selectedGoogle.value : null,
          pageSize: 25,
          nextPageToken: page === 1 ? undefined : nextPageToken,
          search: spreadsheetText,
        },
      },
    });
  };

  const fetchSheets = () => {
    const selectedSpreadSheet = spreadsheetOptions.find((item: { label: string }) => item.label === spreadsheetText);
    selectedSpreadSheet &&
      getSheets({
        variables: {
          settings: {
            integration_id: selectedGoogle ? selectedGoogle.value : null,
            spreadsheet_id: selectedSpreadSheet?.value,
          },
        },
      });
  };

  useEffect(() => {
    selectedGoogle && fetchSpreadSheets();
  }, [selectedGoogle, page, spreadsheetText]);

  const clearErrorMsg = () => {
    setTimeout(() => {
      setErrorMessage('');
    }, 3000);
  };
  const handleSaveFrom = (e: { target: { value: any } }) => {
    errorMessage && setErrorMessage('');
    let val = e.target.value;
    val = val === '' ? '' : +val;
    if (isNaN(val)) {
      setErrorMessage('Please use only numbers');
      clearErrorMsg();
      return;
    }
    if (maxImportCount !== -1) {
      if (maxImportCount < val) {
        setErrorMessage('"Save from" value can`t be greater than the maximum number of available rows');
        clearErrorMsg();
        return;
      }
    }
    if (val && val < 0) {
      setErrorMessage('"Save from" value should be a positive number');
      clearErrorMsg();
      return false;
    }
    setSaveFrom(val);
    !clicked && setClicked(true);
    setIsDirty();
  };

  const handleSaveTo = (e: { target: { value: any } }) => {
    errorMessage && setErrorMessage('');
    let val = e.target.value;
    val = val === '' ? '' : +val;
    if (isNaN(val)) {
      setErrorMessage('Please use only numbers');
      clearErrorMsg();
      return;
    }

    if (maxImportCount !== -1) {
      if (maxImportCount < val) {
        setErrorMessage('"Save to" value can`t be greater than maximum number of available rows');
        clearErrorMsg();
        return;
      }
    }
    if (val && val < 0) {
      setErrorMessage('"Save to" value should be a positive number');
      clearErrorMsg();
      return false;
    }
    setSaveTo(val);
    !clicked && setClicked(true);
    setIsDirty();
  };

  return (
    <div className="google-automation-settings">
      {(connected === null || getSheetsLoading || getIntegrationLoading || getSpreadsheetsLoading) && (
        <StretchLoading />
      )}
      {connected === null || connected ? (
        <div>
          <Row className="mb-4">
            <Col lg={6}>
              <span className="label secondary-color">Account</span>
              <Select
                fullWidth
                options={googleOptions}
                value={selectedGoogle}
                placeholder={'Select account'}
                onChange={(selectedItem: any) => {
                  setSelectedGoogle(selectedItem);
                  !clicked && setClicked(true);
                  setIsDirty();
                }}
              ></Select>
            </Col>
            {selectedGoogle && (
              <Col lg={6}>
                <span className="label secondary-color">Spreadsheet</span>
                <div className="d-flex w-100">
                  <div className="spreadsheet-width">
                    <Datalist
                      list={spreadsheetOptions}
                      value={spreadsheetText}
                      placeholder={'Select spreadsheet'}
                      onChange={(text: string) => {
                        setSpreadsheetText(text);
                        setSelectedSheet(null);
                        // setSelectedColumn(null);
                        setSelectedColumns([]);
                        !clicked && setClicked(true);
                        setIsDirty();
                      }}
                      page={page}
                      setPage={setPage}
                      pageSize={24}
                    />{' '}
                  </div>
                  <Tooltip
                    trigger={['hover', 'focus']}
                    placement="left"
                    text="Refetch spreadsheets list"
                    className="reload-icon-tooltip mb-1"
                  >
                    <span
                      className="reload-icon"
                      onClick={() => {
                        page !== 1 ? setPage(1) : fetchSpreadSheets();
                      }}
                    >
                      {reloadIcon}
                    </span>
                  </Tooltip>
                </div>
              </Col>
            )}
          </Row>
          {spreadsheetText && sheetOptions.length > 0 && (
            <>
              <Row>
                <Col lg={6}>
                  <span className="label secondary-color">Sheet</span>
                  <div className="d-flex w-100">
                    <div className="spreadsheet-width">
                      <Select
                        options={sheetOptions}
                        value={selectedSheet}
                        placeholder={'Select sheet name'}
                        onChange={(selectedItem: any) => {
                          setSelectedSheet(selectedItem);
                          // setSelectedColumn(null);
                          setSelectedColumns([]);
                          !clicked && setClicked(true);
                          setIsDirty();
                        }}
                      />
                    </div>
                    <Tooltip
                      trigger={['hover', 'focus']}
                      placement="left"
                      text="Refetch sheets data"
                      className="reload-icon-tooltip mb-1"
                    >
                      <span className="reload-icon" onClick={fetchSheets}>
                        {reloadIcon}
                      </span>
                    </Tooltip>
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <span className="label secondary-color">Import</span>
                  <CustomMultiSelect
                    options={columns}
                    value={selectedColumns}
                    onChange={(sel: any) => {
                      setSelectedColumns(sel);
                      !clicked && setClicked(true);
                      setIsDirty();
                    }}
                    labelledBy="Select"
                    hasSelectAll={false}
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col md={6} lg={5} xl={4} className="mb-4 results-from-to">
                  <h5 className="pointer-title">From</h5>
                  <div className="custom-input-offset">
                    <span className="label secondary-color" style={{ whiteSpace: 'nowrap' }}>
                      Import rows (optional)
                    </span>
                    <Input value={saveFrom} onChange={handleSaveFrom} className="custom-input-offset" />
                  </div>
                </Col>
                <Col md={6} lg={5} xl={4} className="mb-4 results-from-to">
                  <h5 className="pointer-title">To</h5>
                  <div className="custom-input-offset">
                    <span className="label secondary-color" style={{ visibility: 'hidden' }}>
                      to
                    </span>
                    <Input value={saveTo} onChange={handleSaveTo} className="custom-input-offset" />
                  </div>
                </Col>
                <Col lg={2} xl={4} className="checkbox-wrapper">
                  <div className="item-block">
                    <Checkbox
                      checked={includeHeader}
                      label="Include header"
                      onChange={() => {
                        setIncludeHeader(!includeHeader);
                        !clicked && setClicked(true);
                        setIsDirty();
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </div>
      ) : (
        <Col lg={6} md={12}>
          <Button
            name="Connect"
            onClick={() => {
              const y = (window.outerHeight - 470) / 2;
              const x = (window.outerHeight - 400) / 2;
              const secWindow = window.open('', 'secondary', `width=400,height=470,top=${y},left=${x}`);
              showGoogle(
                secWindow,
                `${HOST.APP_API.APP}/social-callback/hexomatic/google-sheet?hexomatic_user_id=${hexomaticUser.id}`,
              );
            }}
          />
        </Col>
      )}
      {errorMessage && (
        <Row className="mt-4">
          <Col md={12}>
            <Alert type="error" className="create-workflow">
              {errorMessage}
            </Alert>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default React.memo(GoogleSheetImportSettings);
