import { FC, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { useQuery } from '@apollo/react-hooks';

import NothingToShowDetailed from 'shared-components/atom/nothing-to-show-detailed';
import Loader from 'shared-components/atom/loader';
import TemplateCard from 'shared-components/atom/template-card';
import { ThemeContext } from 'contexts/theme-context';
import { HexomaticUserContext } from 'contexts/hexomatic-user';

import { GET_PUBLIC_REICPES } from 'graphql/queries';
import PublicRecipeCard from '../public-recipe-card';

import { nothingToShowScrapingIcon, playIcon } from 'assets/svg-icons';
import './styles.scss';

interface CommonType {
  label: string;
  value: string;
}

interface NothingToShowRecommendedProps {
  handelNewRecipeCreate: (arg: CommonType) => void;
  setIsVideoModalOpen: (arg: boolean) => void;
  recipesCardArray: any;
}

const NothingToShowRecommended: FC<NothingToShowRecommendedProps> = ({
  handelNewRecipeCreate,
  setIsVideoModalOpen,
  recipesCardArray,
}) => {
  const { theme } = useContext(ThemeContext);
  const { hexomaticUser } = useContext(HexomaticUserContext);

  return (
    <div className="nothing-to-show-recommended">
      <NothingToShowDetailed
        SVGImage={nothingToShowScrapingIcon}
        fullText="Looks like you don’t have any recipes"
        dimColorText="Fortunately, it’s easy to create a new one."
        buttonText="New Recipe"
        onClick={handelNewRecipeCreate}
        secondOnClick={() => setIsVideoModalOpen(true)}
        secondButtonText="How to create"
        secondBtnIcon={playIcon}
      />
      <div className="recommended-wrapper">
        <hr className="w-100" />
        <h4 className="page-title mr-3">Recommendation</h4>
        {recipesCardArray?.length ? (
          <>
            <div className="row" style={{ margin: '0 -5px' }}>
              {recipesCardArray.map((obj: any, index: number) => (
                <div key={obj.name} style={{ padding: '5px' }} className="col-12 col-lg-6 col-xl-4">
                  <TemplateCard
                    theme={theme}
                    title={obj.name}
                    description={obj.description}
                    image={obj.image}
                    fullText
                    categories={obj.categories}
                    isNew={obj.is_new}
                    linkTo={''}
                    id={obj.id}
                    templatesPage={
                      index === recipesCardArray?.length - 1 &&
                      !!(hexomaticUser?.pricing_package &&
                      hexomaticUser?.pricing_package !== 'FREE')
                    }
                    demoLink={
                      index === recipesCardArray?.length - 1 &&
                      !!(hexomaticUser?.pricing_package &&
                      hexomaticUser?.pricing_package !== 'FREE')
                    }
                  />
                </div>
              ))}
            </div>
            <div className="d-flex align-items-center justify-content-center mt-3">
              <Link to="/scraping-public-recipes" className="clickable-text">
                SHOW ALL TEMPLATES
              </Link>
            </div>
          </>
        ) : (
          <Row style={{ margin: '0 -5px' }}>
            {Array.apply(null, Array(3)).map(item => (
              <Col sm={12} md={6} lg={4} style={{ minWidth: '220px', padding: '5px' }}>
                <div className="blank-template"></div>
              </Col>
            ))}
          </Row>
        )}
      </div>
    </div>
  );
};

export default NothingToShowRecommended;
