import { FC, useState, useContext, useEffect } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useMediaQuery } from 'react-responsive';

import { ThemeContext } from 'contexts/theme-context';
import { SidebarToggleContext } from 'contexts/sidebar-toggle-context';

import Input from 'shared-components/atom/input';
import Loader from 'shared-components/atom/loader';
import NothingToShow from 'shared-components/atom/nothing-to-show';
import Fields from 'shared-components/molecule/filter-fields';
import { FieldsProps } from 'shared-components/atom/types';
import Tooltip from 'shared-components/atom/tooltip';

import { eventStop } from 'helpers';

import { dashboardLampIcon, searchIcon, closeIcon, favoriteIcon, dragAndDropIcon } from 'assets/svg-icons';
import './right-sidebar.scss';

interface IRightSidebarProps {
  automations: { title: string; icon: string; fullInfo: any }[];
  handleAutomationSearch: (e: any) => void;
  recipes: { title: string; icon: string; fullInfo: any }[];
  publicRecipes: { title: string; icon: string; fullInfo: any }[];
  onClick: (id: number, index: number) => void;
  paddingBottom?: boolean;
  loading?: boolean;
  onChangeTabs?: (arg: string) => any;
  inputValue?: string;
  fields?: FieldsProps;
  toggle: boolean;
  setToggle: (val: boolean) => void;
  isScrapingTab?: boolean;
  isPublicScrapingTab?: boolean;
  handleMarkAsStarred?: (id: number, type: string) => void;
  handleRemoveFromStarred?: (id: number, type: string) => void;
  resetTab?: boolean;
}

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  background: isDragging ? 'white' : 'inherit',
  // styles we need to apply on draggables
  ...draggableStyle,
});

const RightSidebar: FC<IRightSidebarProps> = ({
  loading,
  automations,
  recipes,
  publicRecipes,
  onClick,
  paddingBottom,
  handleAutomationSearch,
  inputValue,
  onChangeTabs,
  fields,
  toggle,
  isScrapingTab,
  isPublicScrapingTab,
  setToggle,
  handleMarkAsStarred,
  handleRemoveFromStarred,
  resetTab,
}) => {
  const { theme } = useContext(ThemeContext);
  const { rightOpened } = useContext(SidebarToggleContext);

  const [defaultLoading, setDefaultLoading] = useState(true);
  const [addPaddingTop, setAddPaddingTop] = useState(false);

  const [automationTab, setAutomationTab] = useState('1');
  //const [toggle, setToggle] = useState(window && window.innerWidth < 505 ? false : true);

  const changeTabsActivity = (val: string) => {
    setAutomationTab(val);
    onChangeTabs && onChangeTabs(val);
  };

  useEffect(() => {
    if (isScrapingTab) {
      setAutomationTab('2');
      onChangeTabs && onChangeTabs('2');
    } else if (isPublicScrapingTab) {
      setAutomationTab('3');
      onChangeTabs && onChangeTabs('3');
    } else if (automationTab !== '1') {
      setAutomationTab('1');
      onChangeTabs && onChangeTabs('1');
    }
  }, [isScrapingTab, isPublicScrapingTab, resetTab]);

  const handelClose = () => {
    window.innerWidth < 992 && setToggle(false);
  };

  const isMobile = useMediaQuery({ maxWidth: 585 });
  const isTablet = useMediaQuery({ maxWidth: 1200, minWidth: 586 });

  useEffect(() => {
    setAddPaddingTop(!!document.getElementById('promo-header'));
  }, [document.getElementById('promo-header hexospark-prelaunch'), document.getElementById('promo-header')]);

  useEffect(() => {
    loading && defaultLoading && setDefaultLoading(false);
  }, [loading]);

  const style1 = {
    top: (document.getElementById('header')?.clientHeight || 0) + 'px',
  };
  const style2 = {
    top: (document.getElementById('header')?.clientHeight || 0) + 27 + 'px',
  };

  useEffect(() => {
    const el = document.getElementsByClassName('options-wrapper')[0];
    const promo = document.querySelector('#header')?.clientHeight || 0;
    const footerSticky = document.querySelector('.sticky-footer')?.clientHeight || 0;
    const footer = footerSticky ? 50 : -25;
    const offset = promo + footerSticky - footer;
    if (promo) {
      el.setAttribute('style', `height: calc(100% - ${offset}px)`);
    }
  }, [
    document.getElementById('option-template'),
    document.querySelector('.sticky-footer')?.clientHeight,
    document.querySelector('#header'),
  ]);

  return (
    <div className="right-sidebar">
      <button
        onClick={() => setToggle(!toggle)}
        className={`${toggle ? 'btn-show' : 'btn-hide'} ${rightOpened ? 'r-btn-hide' : ''}
       ${rightOpened && toggle ? 'btn-with-sidebar' : ''} `}
        style={style2}
      >
        {toggle ? closeIcon : dashboardLampIcon}
      </button>
      <nav
        className={`${toggle ? 'sidebar-show' : 'sidebar-hide'} ${rightOpened ? 'r-open' : ''}`}
        onClick={eventStop}
        style={style1}
      >
        <div className="search-item">
          <Input
            id="searchInput"
            leftIcon={searchIcon}
            value={inputValue}
            onChange={e => handleAutomationSearch(e.target.value)}
            placeholder={
              automationTab === '1'
                ? 'Search automation...'
                : automationTab === '2'
                ? 'Search scraping recipes...'
                : 'Search public scraping recipes...'
            }
          />
        </div>
        <div className="d-flex tabs justify-content-between px-2  position-relative ">
          <div
            className={`tab mr-2 ${automationTab === '1' ? 'active-tab' : ''}`}
            onClick={() => changeTabsActivity('1')}
          >
            Automations
          </div>
          <div
            className={`tab mr-2 ${automationTab === '2' ? 'active-tab' : ''}`}
            onClick={() => changeTabsActivity('2')}
          >
            Scraping Recipes
          </div>
          <div
            className={`tab public-recipe-tab ${automationTab === '3' ? 'active-tab' : ''}`}
            onClick={() => changeTabsActivity('3')}
          >
            <Tooltip
              placement="left"
              isSmall
              text="If you use a pubic scraping recipe, it will be added to your scraping recipes list."
            >
              <span>Public Scraping Recipes</span>
            </Tooltip>
          </div>
        </div>
        {automationTab === '1' && fields && (
          <Fields loading={fields.loading} lists={fields.lists} active={fields.active} onChange={fields.onChange} />
        )}
        <div className={`options-wrapper`} id="option-template">
          {loading || defaultLoading ? (
            Array.apply(null, Array(7)).map(() => (
              <div className="sidebar-element-facade">
                <div className="icon pulse"></div>
                <div className="main-content pulse"></div>
              </div>
            ))
          ) : (
            <div
              className={`options-wrapper ${
                (automationTab === '1' ? automations : automationTab === '2' ? recipes : publicRecipes).length === 0
                  ? 'with-center'
                  : ''
              }`}
            >
              {(automationTab === '1' ? automations : automationTab === '2' ? recipes : publicRecipes).length === 0 ? (
                <NothingToShow upgrade={automationTab === '3' && !inputValue ? true : false} />
              ) : (
                <>
                  {automationTab === '1' && (
                    <ul
                      style={{
                        maxHeight:
                          paddingBottom && isMobile && !addPaddingTop
                            ? 'calc(100% - 210px)'
                            : paddingBottom && isMobile && addPaddingTop
                            ? 'calc(100% - 280px)'
                            : paddingBottom && isTablet && !addPaddingTop
                            ? 'calc(100% - 180px)'
                            : paddingBottom && isTablet && addPaddingTop
                            ? 'calc(100% - 250px)'
                            : paddingBottom && !isTablet && !isMobile && addPaddingTop
                            ? 'calc(100% - 230px)'
                            : paddingBottom && !isTablet && !isMobile && !addPaddingTop
                            ? 'calc(100% - 130px)'
                            : 'calc(100% - 80px)',
                        overflow: 'auto',
                      }}
                    >
                      <Droppable droppableId="droppable2" isDropDisabled>
                        {(provided, snapshot) => (
                          <div ref={provided.innerRef}>
                            {automations.map(
                              (item, index) =>
                                item && (
                                  <Draggable
                                    key={item.title.toString()}
                                    draggableId={item.title.toString()}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                      >
                                        <li
                                          key={item.title}
                                          onClick={() => {
                                            onClick(item.fullInfo, index);
                                            handelClose();
                                          }}
                                          className="automation-line"
                                        >
                                          <span className="automation-card-icon">
                                            <img
                                              src={item.icon}
                                              alt={'Tool icon'}
                                              style={{
                                                width: item.fullInfo.id === 35 ? '35px' : '',
                                                height: item.fullInfo.id === 35 ? '35px' : '',
                                              }}
                                            />
                                          </span>
                                          <span className="text-wrapper text-truncate">{item.title}</span>
                                          <span
                                            className="text-wrapper favorite-icon ml-auto"
                                            onClick={(e: any) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              item?.fullInfo?.isFavorite
                                                ? handleRemoveFromStarred &&
                                                  handleRemoveFromStarred(item.fullInfo.id, 'AUTOMATION')
                                                : handleMarkAsStarred &&
                                                  handleMarkAsStarred(item.fullInfo.id, 'AUTOMATION');
                                            }}
                                          >
                                            <span className={item?.fullInfo?.isFavorite ? 'favorite-icon-bg' : ''}>
                                              {favoriteIcon}
                                            </span>
                                          </span>
                                          <span className="drag-and-drop">
                                            <Tooltip text="Drag and drop" placement="left">
                                              {dragAndDropIcon}
                                            </Tooltip>
                                          </span>
                                        </li>
                                      </div>
                                    )}
                                  </Draggable>
                                ),
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </ul>
                  )}
                  {automationTab !== '1' && (
                    <ul
                      style={{
                        maxHeight: paddingBottom ? 'calc(100% - 130px)' : 'calc(100% - 80px)',
                        overflow: 'auto',
                      }}
                    >
                      <Droppable droppableId="droppable2" isDropDisabled>
                        {(provided, snapshot) => (
                          <div ref={provided.innerRef}>
                            {(automationTab === '2' ? recipes : publicRecipes).map((item, index) => (
                              <Draggable key={item.title.toString()} draggableId={item.title.toString()} index={index}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                  >
                                    <li
                                      key={item.title}
                                      onClick={() => {
                                        onClick({ ...item.fullInfo, is_public: !(automationTab === '2') }, index);
                                        handelClose();
                                      }}
                                      className="automation-line"
                                    >
                                      <span className="automation-card-icon">
                                        <img src={item.icon} alt={'Tool icon'} />
                                      </span>
                                      <span className="text-wrapper text-truncate">{item.title}</span>
                                      {automationTab === '2' && (
                                        <span
                                          className="text-wrapper favorite-icon ml-auto"
                                          onClick={(e: any) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            item?.fullInfo?.isFavorite
                                              ? handleRemoveFromStarred &&
                                                handleRemoveFromStarred(item.fullInfo.id, 'SCRAPING_RECIPE')
                                              : handleMarkAsStarred &&
                                                handleMarkAsStarred(item.fullInfo.id, 'SCRAPING_RECIPE');
                                          }}
                                        >
                                          <span className={item?.fullInfo?.isFavorite ? 'favorite-icon-bg' : ''}>
                                            {favoriteIcon}
                                          </span>
                                        </span>
                                      )}
                                      <span
                                        className={automationTab === '2' ? 'drag-and-drop' : 'drag-and-drop ml-auto'}
                                      >
                                        <Tooltip text="Drag and drop" placement="left">
                                          {dragAndDropIcon}
                                        </Tooltip>
                                      </span>
                                    </li>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </ul>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </nav>
    </div>
  );
};

export default RightSidebar;
