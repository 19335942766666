import { FC, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
import CustomMultiSelect from 'shared-components/molecule/multiselect';
import Datalist from 'shared-components/atom/dataList';
import Alert from 'shared-components/atom/alert-message';
import {
  deeplFormalityTranslationsOptions,
  deepTraslationOptions,
  deepTraslationTargetOptions,
  formalityOptions,
} from '../../constants';
import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import { cacheFunction } from 'helpers';
import './styles.scss';

const SourceTypeOptions = [
  { label: 'Text', value: 'text' },
  { label: 'PDF URL', value: 'pdf' },
  { label: 'TXT URL', value: 'txt' },
  { label: 'DOCX URL', value: 'docx' },
  { label: 'PPTX URL', value: 'pptx' },
];

const DeepleTranslateApiSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  selectedRecipes,
  automationIndex,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [fieldList, setFieldList] = useState<any>([]);
  const [clicked, setClicked] = useState(false);
  const [languageFrom, setlanguageFrom] = useState('');
  const [languagesTo, setlanguagesTo] = useState<any>([]);
  const [selectedForamlity, setSelectedForamlity] = useState(formalityOptions[0]);
  const [sourceType, setSourceType] = useState<any>(SourceTypeOptions[0]);
  const [selectedSources, setSelectedSources] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const {
    liftingSettings,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (!languageFrom || languagesTo?.length === 0 || errorMessage) {
      return false;
    }
    if (selectedSources?.length === 0) return false;
    return true;
  };

  const oldModeRestore = ({ key, options }: any) => {
    if (key && typeof key === 'string') {
      const recreateSelected = options.find(
        (item: { value: string; label: string }) => item.value === key || item.label === key,
      );
      recreateSelected && setSelectedSources([recreateSelected]);
    }
    const getValues = options.filter(
      (option: { label: any; value: string }) => key.includes(option.label) || key.includes(option.value),
    );
    getValues && setSelectedSources(getValues);
  };

  useEffect(() => {
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const { keys } = dynamic_settings;
      const { settings } = keys[0];

      const source_lang = settings ? settings.source_lang : keys[0].source_lang;
      const target_lang = settings ? settings.target_lang : keys[0].target_lang;
      const formality = settings ? settings.formality : keys[0].formality;
      // const source_type = settings ? settings.documentType : null;

      const findedLanguageFrom = deepTraslationOptions.find(item => item.value === source_lang);
      let findedLanguageTo: any;
      const langToOptions = [
        ...(selectedForamlity.value === 'default' ? deepTraslationTargetOptions : deeplFormalityTranslationsOptions),
      ];
      if (typeof target_lang === 'string') {
        findedLanguageTo = langToOptions.find(item => item.value === target_lang);
        setlanguagesTo([findedLanguageTo]);
      } else {
        findedLanguageTo = target_lang?.map((target_lang_item: string) => {
          return langToOptions.find(item => item.value === target_lang_item);
        });
        findedLanguageTo && setlanguagesTo(findedLanguageTo);
      }
      const findedFormality = formalityOptions.find(item => item.value === formality);
      findedLanguageFrom && setlanguageFrom(findedLanguageFrom.label);
      findedFormality && setSelectedForamlity(findedFormality);

      // const findedSourceType = SourceTypeOptions.find(item => item.value === source_type);
      // findedSourceType && setSourceType(findedSourceType);
    }
  }, []);

  //recipe
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();

    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const { keys } = dynamic_settings;
      const { key, settings } = keys[0];
      if (!settings) {
        oldModeRestore({ key, options });
        return;
      }
      const gotKeys = Array.from(keys, ({ key }) => key);
      const getValues = options.filter(
        (option: { label: any; value: string }) => gotKeys.includes(option.label) || gotKeys.includes(option.value),
      );
      getValues && setSelectedSources(getValues);
    }
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSources(fieldList);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSources([]);
      setClicked(true);
    }
    if (update) {
      if (dynamic_settings?.keys?.length > 1) {
        const filteredValue = filteredOptions?.filter((item: any) =>
          dynamic_settings?.keys.find((sub: any) => item.value === sub.key),
        );
        setSelectedSources(filteredValue || []);
        setClicked(true);
        setFieldList(filteredOptions);
        setFieldListWithTypes(optionsWithTypes);
      } else {
        const isArr = Array.isArray(dynamic_settings?.keys?.[0]?.key);
        const foundItem = isArr ? null : filteredOptions?.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
        const selOptionFound = isArr
          ? filteredOptions.filter((item: any) =>
              dynamic_settings?.keys?.[0]?.key.find((sub: any) => item.value === sub),
            )
          : foundItem ? [foundItem] : [];
        setSelectedSources(!selOptionFound ? [] : selOptionFound);
        setClicked(true);
        setFieldList(filteredOptions);
        setFieldListWithTypes(optionsWithTypes);
      }
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const outputs = selectedSources.reduce((acc: string | any[], source: any) => {
        return acc.concat(
          languagesTo.map(
            (lang: { value: string }) => `${source.value}_deepl_translation_${lang.value.replace('-', '_')}`,
          ),
        );
      }, []);
      // const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      const jsonParse: any = {};
      outputs.map((item: any) => (jsonParse[item] = 'text'));
      liftingSettings({
        outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...outputs],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, selectedSources, fieldListWithTypes, automationOutputTypes, sourceType]);

  //memoize
  const handleLanguageFrom = (languageFrom: string) => {
    return deepTraslationOptions.find((item: { label: string; value: string }) => item.label === languageFrom);
  };
  const getMemoizeLanguageFromOption = useRef(cacheFunction(handleLanguageFrom));

  useEffect(() => {
    if (!clicked) return;
    // setClicked(false);

    const selectOptionFrom = getMemoizeLanguageFromOption.current(languageFrom);
    if (!selectOptionFrom) {
      setCompletedFields({ index: automationIndex, checked: false });
    } else {
      setCompletedFields({ index: automationIndex, checked: checkFields() });
    }

    let localSettings: any = { keys: null };
    localSettings.keys = selectedSources.map((item: { value: string }) => {
      return {
        inputValue: null,
        key: item?.value,
        settings: {
          source_lang: selectOptionFrom ? selectOptionFrom.value : null,
          target_lang: languagesTo.map((item: { value: any }) => item.value),
          formality: selectedForamlity.value,
          // documentType: sourceType.value,
        },
      };
    });

    const outputs = selectedSources.reduce((acc: string | any[], source: any) => {
      return acc.concat(
        languagesTo.map(
          (lang: { value: string }) => `${source.value}_deepl_translation_${lang.value.replace('-', '_')}`,
        ),
      );
    }, []);

    // const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    const jsonParse: any = {};
    outputs.map((item: any) => (jsonParse[item] = 'text'));
    liftingSettings({
      dynamicSettings: localSettings,
      outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...outputs],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [
    selectedSources,
    errorMessage,
    languageFrom,
    selectedForamlity,
    languagesTo,
    fieldList,
    fieldListWithTypes,
    automationOutputTypes,
    sourceType,
  ]);

  // const selectSourceType = (selectedItem: any) => {
  //   setSourceType(selectedItem);
  //   !clicked && setClicked(true);
  //   setIsDirty();
  // };

  return (
    <div className="deepl-translate-settings">
      <Row>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Source</span>
          <CustomMultiSelect
            options={fieldList}
            value={selectedSources}
            onChange={(sel: any) => {
              setSelectedSources(sel);
              !clicked && setClicked(true);
              setIsDirty();
            }}
            labelledBy="Select"
          />
        </Col>
        {/* <Col md={6} lg={3} className="mb-4">
          <span className="label secondary-color">Source type</span>
          <Select
            options={SourceTypeOptions}
            value={sourceType}
            placeholder={'Select column'}
            textDefault
            onChange={selectSourceType}
          ></Select>
        </Col> */}
        <Col lg={6} className="">
          <span className="label secondary-color">Translation style</span>
          <Select
            options={formalityOptions}
            value={selectedForamlity}
            placeholder={'Select formality'}
            onChange={(selectedItem: any) => {
              setSelectedForamlity(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
      </Row>
      <Row className="w-100">
        <Col lg={6} className="deepl-translate-select">
          <h5 className="pointer-title">From</h5>
          <div className="select-block">
            <span className="label secondary-color">Language</span>
            <Datalist
              list={deepTraslationOptions}
              value={languageFrom}
              placeholder={'Select language'}
              onChange={(text: string) => {
                setlanguageFrom(text);
                setClicked(true);
                setIsDirty();
              }}
            />
          </div>
        </Col>
        <Col lg={6} className="deepl-translate-select">
          <h5 className="pointer-title">To</h5>
          <div className="select-block">
            <span className="label secondary-color">Language</span>
            <CustomMultiSelect
              options={
                selectedForamlity.value === 'default' ? deepTraslationTargetOptions : deeplFormalityTranslationsOptions
              }
              value={languagesTo}
              onChange={(sel: any) => {
                setlanguagesTo(sel);
                !clicked && setClicked(true);
                setIsDirty();
              }}
              labelledBy="Select"
            />
          </div>
        </Col>
      </Row>
      {errorMessage && (
        <Row className="mt-4">
          <Col md={12}>
            <Alert type="error" className="create-workflow">
              {errorMessage}
            </Alert>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default DeepleTranslateApiSettings;
