import { FC, useEffect, useState, useContext, MouseEvent, useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { ThemeContext } from 'contexts/theme-context';
import { HexomaticUserContext } from 'contexts/hexomatic-user';
import { Col } from 'react-bootstrap';
import { UPDATE_SCRAPING_RECIPE } from 'graphql/mutations';
import Checkbox from 'shared-components/atom/checkbox';
import { IRecipeProps } from 'shared-components/organizm/types';
import Loader from 'shared-components/atom/loader';
import AlertComponent from 'shared-components/atom/alert-message';
import Sort from 'shared-components/atom/sort';
import Modal from 'shared-components/molecule/modal';
import CustomModal from 'shared-components/molecule/customModal';
import TablePagination from 'shared-components/molecule/table-pagination';
import Tooltip from 'shared-components/atom/tooltip';

import ShareRecipeModal from '../../components/share-recipe-modal';
import ListItem from '../listItem';
import { limitOptions } from 'constants/index';

import { deleteIcon } from 'assets/svg-icons';
import './styles.scss';

interface ScrapingList {
  recipesList: any[];
  handleRecipeDelete: (arrayOfIds?: number[], bulk?: boolean) => void;
  recipePage: number;
  setRecipePage: (arg: number) => void;
  limit: any;
  setLimit: (arg: any) => void;
  refetchScrapingList: () => void;
  deleteLoading: boolean;
  totalCount: number;
  recipesDataLoading: boolean;
  error?: string;
  refetchRecipe?: any;
  sort: { by: string; id: number };
  setSort: (arg: any) => void;
  handleCopyRecipe: (id: number) => void;
  handlePublicPrivate: (id: number, is_public: boolean, url: string) => void;
  handleMarkAsStarred?: (id: number) => void;
  handleRemoveFromStarred?: (id: number) => void;
  onScroll?: any;
  setShouldUpgrade?: () => void;
}

const ScrapingList: FC<ScrapingList> = ({
  limit,
  setLimit,
  recipesList,
  handleRecipeDelete,
  recipePage,
  setRecipePage,
  refetchRecipe,
  refetchScrapingList,
  deleteLoading,
  totalCount,
  recipesDataLoading,
  error,
  sort,
  setSort,
  handleCopyRecipe,
  handlePublicPrivate,
  handleMarkAsStarred,
  handleRemoveFromStarred,
  onScroll,
  setShouldUpgrade,
}) => {
  const { theme } = useContext(ThemeContext);
  const { hexomaticUser } = useContext(HexomaticUserContext);
  const { push } = useHistory();

  const [checkedIndexes, setCheckedIndexes] = useState([] as any);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [optionParameters, setOptionParameters] = useState<{ label: string; value: number | string; url: string }>({
    label: '',
    value: '',
    url: '',
  });

  const [recipeIds, setRecipeIds] = useState([] as any);
  const [openedId, setOpenedId] = useState<any>(null);
  const [openedDescId, setOpenedDescId] = useState<any>(null);
  const [shareModal, setShareModal] = useState(false);
  const [isSuccessfullyShared, setIsSuccessfullyShared] = useState(false);
  const [tableMaxHeight, setTableMaxHeight] = useState(800);

  const [updateRecipe, { error: updateRecipeError, loading: updateRecipeLoading }] =
    useMutation(UPDATE_SCRAPING_RECIPE);

  useEffect(() => {
    const arr = recipesList.map(item => (item = item.id));
    setRecipeIds(arr);
    setIsCheckedAll(false);
    setCheckedIndexes([]);
  }, [recipesList]);

  useEffect(() => {
    const innerHeight = window.innerHeight;
    const headerEl = document.getElementById('header');
    const headerHeight = headerEl?.offsetHeight;
    innerHeight &&
      headerHeight &&
      setTableMaxHeight(innerHeight - headerHeight - 374 > 230 ? innerHeight - headerHeight - 374 : 230);
  }, [window.innerHeight]);

  const handleCheckboxClick = (index: number) => {
    const arr = [...checkedIndexes];
    if (checkedIndexes.includes(index)) {
      arr.splice(checkedIndexes.indexOf(index), 1);
      setIsCheckedAll(false);
    } else {
      arr.push(index);
    }

    if (arr.length === recipesList.length) {
      setIsCheckedAll(true);
    }

    setCheckedIndexes(arr);
  };

  useEffect(() => {
    setIsCheckedAll(false);
    setCheckedIndexes([]);
  }, [recipePage, limit]);

  const eventStop = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDeleteRecipe = async () => {
    if (checkedIndexes?.length && !optionParameters?.value) {
      handleRecipeDelete(checkedIndexes, true);
    }
    optionParameters?.value && handleRecipeDelete([+optionParameters.value]);

    !showDeleteModal && setOptionParameters({ label: '', value: '', url: '' });
    showDeleteModal && setShowDeleteModal(false);
    setCheckedIndexes([]);
    setIsCheckedAll(false);
  };

  const handleCheckedAll = () => {
    setIsCheckedAll(!isCheckedAll);
    isCheckedAll ? setCheckedIndexes([]) : setCheckedIndexes(recipeIds);
  };

  const cancelDeletModal = () => {
    !showDeleteModal && setOptionParameters({ label: '', value: '', url: '' });
    showDeleteModal && setShowDeleteModal(false);
  };

  const handelLimitChange = (value: any) => {
    value.label !== limit.label && setLimit(value);
    localStorage.setItem('recipeListLimit', JSON.stringify(value));
    if (value.value * recipePage > totalCount) {
      setRecipePage(Math.ceil(totalCount / value.value));
    }
  };

  useEffect(() => {
    if (optionParameters.label === 'Delete recipe') {
      handleRecipeDelete([+optionParameters.value]);
    } else if (optionParameters.label === 'Duplicate recipe') {
      handleCopyRecipe(+optionParameters.value);
    } else if (optionParameters.label === 'Use in a workflow') {
      if (hexomaticUser?.pricing_package === 'FREE') {
        setShouldUpgrade && setShouldUpgrade();
        return;
      };
      localStorage.setItem('workflow-recipe-id', `${optionParameters.value}`);
      push('workflows/create');
    } else if (optionParameters.label === 'Make public' || optionParameters.label === 'Make private') {
      handlePublicPrivate(+optionParameters.value, optionParameters.label === 'Make public', optionParameters.url);
    } else if (optionParameters.label === 'Share recipe') {
      setShareModal(true);
    }
  }, [optionParameters]);

  const MemoizedList = useCallback(() => {
    return recipesList.map((item: IRecipeProps, idx: number) => {
      return (
        <ListItem
          key={item.id}
          checkedIndexes={checkedIndexes}
          handleCheckboxClick={handleCheckboxClick}
          item={item}
          setOptionParameters={setOptionParameters}
          recipesListLength={recipesList.length}
          idx={idx}
          updateRecipe={updateRecipe}
          refetchRecipe={refetchRecipe}
          refetchScrapingList={refetchScrapingList}
          openedId={openedId}
          setOpenedId={setOpenedId}
          openedDescId={openedDescId}
          setOpenedDescId={setOpenedDescId}
          handleCopyRecipe={handleCopyRecipe}
          isFavorite={item.isFavorite}
          handleMarkAsStarred={handleMarkAsStarred}
          handleRemoveFromStarred={handleRemoveFromStarred}
        />
      );
    });
  }, [recipesList, openedId, openedDescId, checkedIndexes]);

  const isMobile = useMediaQuery({maxWidth: 991});

  return (
    <div className="w-100 pb-3">
      {recipesList && recipesList.length > 0 ? (
        <div className="card-body recipe-tabel-wrapper">
          <div id="recipe-tabel" style={{ maxHeight: isMobile ? '400px' : `${tableMaxHeight}px` }} onScroll={onScroll}>
            <table className="table table--bordered table table-hover">
              <thead id='recipe-thead'>
                <tr>
                  <th className="pl-4" style={{ width: '60px' }}>
                    <span className="scraping-header-checkbox" onClick={e => eventStop(e)}>
                      <Checkbox checked={isCheckedAll} onChange={handleCheckedAll} />{' '}
                    </span>
                  </th>
                  <th className="d-flex">
                    <span className="selected-check-count">{checkedIndexes.length ? checkedIndexes.length : ''}</span>
                    <div className="recipe-name ">
                      Name <Sort id={3} sort={sort} onClick={val => setSort(val)} />
                    </div>
                  </th>
                  <th className="pl-4 no-wrap text-center">
                    Created Date
                    <Sort id={1} sort={sort} onClick={val => setSort(val)} />
                  </th>
                  <th className="pl-4 no-wrap text-center">
                    Last Modified
                    <Sort id={2} sort={sort} onClick={val => setSort(val)} />
                  </th>
                  <th className='use-in-workflow-mobile' style={{ width: '150px', minWidth: '150px' }}></th>
                  <th style={{ width: '40px', paddingRight: '20px', border: 'none' }}>
                    {!!checkedIndexes.length && (
                      <div className="d-flex">
                        {/* <span onClick={handleDelete} className="icons">
                            {play}
                          </span>
                          <span onClick={handleDelete} className="icons">
                            {pause}
                          </span> */}

                        <Tooltip text="Delete Recipes" placement="left">
                          <button
                            onClick={() => {
                              setOptionParameters({ label: '', value: '', url: '' });
                              setShowDeleteModal(true);
                            }}
                            className="delete-icon bulk"
                          >
                            {deleteIcon}
                          </button>
                        </Tooltip>
                      </div>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>{MemoizedList()}</tbody>
            </table>

            <div className="mr-4 row mt-4 rows-count">
              <Col className="d-flex m-auto" md={5}>
                {error && <AlertComponent type="error" text={error} className="ml-3" />}
              </Col>
              {
                <Col className="d-flex m-auto" md={2}>
                  {(recipesDataLoading || deleteLoading) && <Loader light={theme === 'light'} className="p-0 m-auto" />}
                </Col>
              }
            </div>
          </div>
          <TablePagination
            count={totalCount}
            limit={limit}
            setPage={(page: any) => page !== recipePage && setRecipePage(page)}
            handleLimitChange={handelLimitChange}
            page={recipePage}
            limitOptions={limitOptions}
            showPagination={Math.ceil(totalCount / limit.value) > 1}
          />
        </div>
      ) : updateRecipeLoading ? (
        <Col md={10} style={{ marginTop: '200px' }}>
          <Loader light={theme === 'light'} />
        </Col>
      ) : null}
      {(optionParameters.label === 'Delete' || showDeleteModal) && (
        <Modal
          isModalOpen={!!(optionParameters.label === 'Delete') || showDeleteModal}
          text={`Confirm deleting ${showDeleteModal && checkedIndexes && checkedIndexes.length > 1
              ? checkedIndexes.length + ' recipes'
              : 'the recipe'
            }`}
          actionBtnText={deleteLoading ? <Loader light={theme === 'light'} className="d-inline" /> : 'Yes'}
          cancelBtnText="No"
          actionBtnColor="#B72CFF"
          onActionClick={handleDeleteRecipe}
          onCancelClick={cancelDeletModal}
          escButtonClick={cancelDeletModal}
        />
      )}
      {shareModal && (
        <CustomModal escButtonClick={() => setShareModal(false)}>
          <ShareRecipeModal
            setIsSuccessfullyShared={setIsSuccessfullyShared}
            recipeId={+optionParameters.value}
            onClose={() => setShareModal(false)}
          />
        </CustomModal>
      )}

      <Modal
        isModalOpen={isSuccessfullyShared}
        text={'Recipe has been successfully shared'}
        actionBtnText="Ok"
        actionBtnColor={'success'}
        cancelBtnColor={'primary'}
        onActionClick={() => setIsSuccessfullyShared(false)}
        escButtonClick={() => setIsSuccessfullyShared(false)}
      />
    </div>
  );
};

export default ScrapingList;
