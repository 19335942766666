import { FC, useContext, useEffect, useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../contexts/theme-context';
import { HexomaticUserContext } from 'contexts/hexomatic-user';
// import Counter from 'shared-components/molecule/counter';
import Tooltip from 'shared-components/atom/tooltip';

import { useQuery } from '@apollo/react-hooks';
// import { getGravatar } from 'helpers';
import { HEXOFY_USER, USER_PLANS_QUERY, USER_QUERY } from 'graphql/queries';

import background1 from 'assets/limited-time-ltd/background-1.svg';
import background2 from 'assets/limited-time-ltd/background-2.svg';
import promoText from 'assets/limited-time-ltd/promo-text.svg';
import mobileBanner from 'assets/limited-time-ltd/mobile-banner.svg';

import background1Hs from 'assets/annual-pc-promo/background-1.svg';
import background2Hs from 'assets/annual-pc-promo/background-2.svg';
import promoTextHs from 'assets/annual-pc-promo/promo-text-new.svg';
import mobileBannerHs from 'assets/annual-pc-promo/mobile-banner-new.svg';

import {
  questionIcon,
  menuNavbarIcon,
  // bellIcon,
  downArrow,
  myPlansIcon,
  profileIcon,
  logoutIcon,
  burgerButton,
  cap,
  tasksIcon,
  closeIcon,
  giftIcon,
  checkedIcon,
} from 'assets/svg-icons';
import hexometerLogo from 'assets/logos/hexometer-logo.svg';
import hexowatchLogo from 'assets/logos/hexowatch-logo.svg';
import hexosparkLogo from 'assets/logos/hexospark-icon.svg';
import hexomaticLogo from 'assets/logos/hexomatic-logo.svg';
import hexofyLogo from 'assets/logos/hexofy-icon.svg';
import logoColor from 'assets/logos/hexomatic-logo-rectangle.svg';
import logoWhite from 'assets/logos/hexomatic-logo-rectangle-white.svg';
import SunButton from 'shared-components/atom/dark-theme-button';
import Select from 'shared-components/atom/select';
import CustomModal from 'shared-components/molecule/customModal';
import Payment from 'shared-components/molecule/payment';
import HeaderAlert from './header-alert';
import './layout.scss';
import { useMediaQuery } from 'react-responsive';
import ModeSwitcher from './mode-switcher';
import InviteModal from 'shared-components/organizm/invite-modal';
import Counter from 'shared-components/molecule/counter';
import { getPromoDate } from 'helpers';

interface IHeaderProps {
  toggle: boolean;
  setToggle: (toggle: boolean) => void;
  isAlert: any;
  setIsAlert: any;
  user: any;
  hexometerPlan: string;
  logo?: boolean;
  promoPage?: boolean;
}

const profileOptions = [
  {
    title: 'Profile',
    icon: profileIcon,
    path: '/profile',
  },
  {
    title: 'My Plans',
    icon: myPlansIcon,
    path: '/profile/my-plans',
  },
  {
    title: 'Tasks',
    icon: tasksIcon,
    path: '/tasks',
  },
  {
    title: 'Sign Out',
    icon: logoutIcon,
    path: '/sign-out',
  },
];

const Header: FC<IHeaderProps> = (
  { toggle, setToggle, isAlert, setIsAlert, user, logo, promoPage, hexometerPlan },
  props,
) => {
  // const { i18n } = useTranslation();
  const { theme, setTheme } = useContext(ThemeContext);
  const { hexomaticUser } = useContext(HexomaticUserContext);
  const [openPremiumCreditsPopup, setOpenPremiumCreditsPopup] = useState(false);
  // const [showFlashPromo, setShowFlashPromo] = useState(false);
  // const [hexosparkPrelaunch, setHexosparkPrelaunch] = useState(false);
  const [premiumsPromo, setPremiumsPromo] = useState(false);
  const [premiumsPromoHm, setPremiumsPromoHm] = useState(false);
  // const [leftSeats, setLeftSeats] = useState(0);
  // const [promoEndDate, setPromoEndDate] = useState(null as any);
  // const [backToSchool, setBackToSchool] = useState(false);
  const [productsList, setProductsList] = useState([
    {
      title: 'Hexomatic',
      icon: hexomaticLogo,
      path: '/dashboard',
    },
    {
      title: 'Hexowatch',
      icon: hexowatchLogo,
      path: 'https://dash.hexowatch.com/hexowatch',
    },
    {
      title: 'Hexospark',
      icon: hexosparkLogo,
      path: 'https://dash.hexospark.com',
    },
    {
      title: 'Hexometer',
      icon: hexometerLogo,
      path: 'https://dash.hexometer.com/',
    },
    {
      title: 'Hexofy',
      icon: hexofyLogo,
      path: 'https://hexofy.com/',
    },
  ]);

  // const { data: seatsLeft, refetch: refetchLeftSeats } = useQuery(LEFT_PREMMIUMS_COUNT);

  // useEffect(() => {
  //   if (seatsLeft?.User?.getPromoLeftSeats?.seats) {
  //     const count = JSON.parse(seatsLeft.User.getPromoLeftSeats.seats);
  //     count && count.left_count > 0 && setLeftSeats(count.left_count);
  //   }
  // }, [seatsLeft]);

  // useEffect(() => {
  //   refetchLeftSeats();
  // }, [user]);

  // const { data: seatsLeft } = useQuery(LEFT_PREMMIUMS_COUNT);

  // useEffect(() => {
  //   if (seatsLeft?.User?.getPromoLeftSeats?.seats) {
  //     const obj = JSON.parse(seatsLeft.User.getPromoLeftSeats.seats);
  //     const count = obj.PC_3_YEAR_PROMO || 0;
  //     count > 0 && setLeftSeats(count);
  //   }
  // }, [seatsLeft]);

  // const [buyHexowatchGetHexometer, setBuyHexowatchGetHexometer] = useState(false);
  // const [buyHexomatichGetHexometer, setBuyHexomatichGetHexometer] = useState(false);
  // const [buyHexomaticGetHexowatch, setBuyHexomaticGetHexowatch] = useState(false);
  // const [easterPromoHexowatchLTD, setEasterPromoHexowatchLTD] = useState(false);
  // const [leftSeats, setSeatsLeft] = useState(0);

  // const { data: seatsLeft } = useQuery(LEFT_PREMMIUMS_COUNT);
  // const { data: allPlansData, loading: allPlansLoading } = useQuery(USER_PLANS_QUERY, {
  //   fetchPolicy: 'no-cache',
  // });

  // const { data: hexofyUserData } = useQuery(HEXOFY_USER, {
  //   fetchPolicy: 'no-cache',
  // });

  // const {data: hexometerData, loading: hexometerLoading} = useQuery(GET_HEXOMETER_USER_SETTINGS, {
  //   skip: !(user && user.id),
  //   variables: {user_id: user && user.id && +user.id},
  // });

  // const { data: hexowatchData, loading: hexowatchLoading } = useQuery(GET_USER_WATCH_SETTINGS_QUERY );

  // const { data: fleshPromoDate } = useQuery(GET_FLASH_PROMO_DATA);

  const [checked, setChecked] = useState(false);
  const [userData, setUserData] = useState<any>(null);
  const { push } = useHistory();
  const [openInviteModal, setOpenInviteModal] = useState(false);

  // useEffect(() => {
  //   if (
  //     hexomaticUser &&
  //     hexomaticUser.pricing_package &&
  //     (hexomaticUser.pricing_package === 'FREE' || hexomaticUser.pricing_package_interval === 'LTD') &&
  //     !localStorage.getItem('holidays-promo-22')
  //   ) {
  //     setBackToSchool(true);
  //   } else {
  //     setBackToSchool(false);
  //   }
  // }, [hexomaticUser]);

  useEffect(() => {
    if (!hexometerPlan || hexometerPlan === 'FREE') {
      setProductsList([
        {
          title: 'Hexomatic',
          icon: hexomaticLogo,
          path: '/dashboard',
        },
        {
          title: 'Hexowatch',
          icon: hexowatchLogo,
          path: 'https://dash.hexowatch.com/hexowatch',
        },
        {
          title: 'Hexospark',
          icon: hexosparkLogo,
          path: 'https://dash.hexospark.com',
        },
        {
          title: 'Hexometer',
          icon: hexometerLogo,
          path: 'https://hexometer.com/pricing/',
        },
        {
          title: 'Hexofy',
          icon: hexofyLogo,
          path: 'https://hexofy.com/',
        },
      ]);
    } else {
      setProductsList([
        {
          title: 'Hexomatic',
          icon: hexomaticLogo,
          path: '/dashboard',
        },
        {
          title: 'Hexowatch',
          icon: hexowatchLogo,
          path: 'https://dash.hexowatch.com/hexowatch',
        },
        {
          title: 'Hexospark',
          icon: hexosparkLogo,
          path: 'https://dash.hexospark.com',
        },
        {
          title: 'Hexometer',
          icon: hexometerLogo,
          path: 'https://dash.hexometer.com/',
        },
        {
          title: 'Hexofy',
          icon: hexofyLogo,
          path: 'https://hexofy.com/',
        },
      ]);
    }
  }, [hexometerPlan]);

  // useEffect(() => {
  //   const watchPlan = hexowatchData?.UserWatchSettings?.get?.pricing_package;
  //   const watchInterval = hexowatchData?.UserWatchSettings?.get?.pricing_package_interval;

  //   const meterPlan = hexometerData?.HexometerUserSettings?.get?.pricing_package;
  //   const meterInterval = hexometerData?.HexometerUserSettings?.get?.pricing_package_interval;

  //   const maticPlan = hexomaticUser?.pricing_package;
  //   const maticInterval = hexomaticUser?.pricing_package_interval;

  //   const noPaidMatic = hexomaticUser && ((maticPlan && maticPlan === 'FREE') || !maticPlan);
  //   const noPaidWatch = hexometerData && ((watchPlan && watchPlan === 'FREE') || !watchPlan);
  //   const noPaidMeter = hexometerData && ((meterPlan && meterPlan === 'FREE') || !meterPlan);

  //   const hexowatchSub = watchPlan && watchPlan !== 'FREE' && watchInterval !== 'LTD';
  //   const hexometerSub = meterPlan && meterPlan !== 'FREE' && meterInterval !== 'LTD';
  //   const hexomaticSub = maticPlan && maticPlan !== 'FREE' && maticInterval !== 'LTD';

  //   const showBuyHexomaticGetHexowatch = noPaidMatic && hexometerSub && noPaidWatch;
  //   const showBuyHexowatchGetHexometer = noPaidWatch && hexomaticSub && noPaidMeter;
  //   const showBuyHexomatichGetHexometer = noPaidMatic && hexowatchSub && noPaidMeter;

  //    if (showBuyHexomaticGetHexowatch) {
  //       setBuyHexomaticGetHexowatch(true);
  //   } else if (showBuyHexowatchGetHexometer) {
  //       setBuyHexowatchGetHexometer(true);
  //   } else if (showBuyHexomatichGetHexometer) {
  //       setBuyHexomatichGetHexometer(true);
  //   }
  // }, [user, hexomaticUser, hexometerData, hexowatchData]);

  // useEffect(() => {
  //   if (
  //     seatsLeft &&
  //     seatsLeft.User &&
  //     seatsLeft.User.getPromoLeftSeats &&
  //     seatsLeft.User.getPromoLeftSeats.seats
  //   ) {
  //     const obj = JSON.parse(seatsLeft.User.getPromoLeftSeats.seats);
  //     const count = (obj.PC_SPECIAL_499 || 0) + (obj.PC_SPECIAL_999 || 0);
  //     count > 0 && setSeatsLeft(count);
  //   }
  // }, [seatsLeft]);

  // useEffect(() => {
  //   if (user?.promoData?.hexowatch_ltd && !localStorage.getItem('hexospark-prelaunch')) {
  //     setHexosparkPrelaunch(true);
  //   }
  // }, [user]);

  // const [promoClosed, setPromoClosed] = useState(true);

  // const [appsumo, setAppsumo] = useState(false);
  // const [holidaysSale, setHolidaysSale] = useState(false);
  // const [lastCall, setLastCall] = useState(false);

  //OPEN PROMO AFTER LTD PROMO END

  // useEffect(() => {
  //   const plan = hexomaticUser?.pricing_package;
  //   const flashPromo = fleshPromoDate?.User?.getHcFlashPromoCronDate?.end_date;
  //   if (hexomaticUser && plan === 'FREE' && flashPromo && parseInt(flashPromo) > new Date().getTime()) {
  //     setShowFlashPromo(true);
  //     setPromoEndDate(flashPromo);
  //   } else {
  //     setShowFlashPromo(false);
  //     setPromoEndDate(null);
  //   }
  // }, [hexomaticUser, fleshPromoDate]);

  // const [activeName, setActiveName] = useState('hexometer');
  const [userPhoto, setUserPhoto] = useState('');
  const { data: userInfo } = useQuery(USER_QUERY);

  // const [activeName, setActiveName] = useState(location.pathname.includes('/hexowatch') ? 'hexowatch' : 'hexometer')

  useEffect(() => {
    if (userInfo?.User?.get) {
      setUserData(userInfo.User.get);
    }
  }, [userInfo]);

  // const changeLanguage = (language: string) => {
  //   i18n.changeLanguage(language);
  // };

  useEffect(() => {
    if (user?.photo) {
      setUserPhoto(user?.photo);
    }
  }, [user]);

  const onChange = () => {
    setChecked(!checked);
    if (theme === 'light') {
      return setTheme('dark');
    }
    return setTheme('light');
  };
  // const onNavigate = (item: any) => {
  //   if (item.title === 'Hexometer') {
  //     document.title = 'Hexometer monitoring dashboard';
  //     const fav = document.getElementById('favicon') as HTMLLinkElement;
  //     fav.href = `https://storage.googleapis.com/website.dash.hexometer.com/favicon.ico`;
  //     // history.push(item.path);
  //   }
  //   if (item.title === 'Hexomate') {
  //     window.open(item.path);
  //   } else {
  //     document.title = 'Hexowatch dashboard';
  //     const fav = document.getElementById('favicon') as HTMLLinkElement;
  //     fav.href = `https://storage.googleapis.com/website.dash.hexometer.com/hexowatch-favicon.ico`;
  //     // history.push(item.path);
  //   }
  //   // setShowOptions(false);
  // };
  const handlePromoClose = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const rightMenu = document.getElementsByClassName('sidebar-show');
    const btnShow = document.getElementsByClassName('btn-show');
    //@ts-ignore
    rightMenu && rightMenu[0] && rightMenu[0].style && (rightMenu[0].style = {});
    //@ts-ignore
    btnShow && btnShow[0] && btnShow[0].style && (btnShow[0].style = {});
    const el = document.getElementById('annual-discount-sb');
    // const el1 = document.getElementById('promo-header');
    //@ts-ignore
    el && el.animate({ opacity: 0 }, 150);
    // el1 && el1.animate({ opacity: 0 }, 150);
    setTimeout(() => {
      // setBackToSchool(false);
      setPremiumsPromo(false);
      localStorage.setItem('annual-discount-sb', 'true');
    }, 150);
  };

  const handlePromoCloseHm = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const rightMenu = document.getElementsByClassName('sidebar-show');
    const btnShow = document.getElementsByClassName('btn-show');
    //@ts-ignore
    rightMenu && rightMenu[0] && rightMenu[0].style && (rightMenu[0].style = {});
    //@ts-ignore
    btnShow && btnShow[0] && btnShow[0].style && (btnShow[0].style = {});
    const el = document.getElementById('pc-promo-12-24');
    // const el1 = document.getElementById('promo-header');
    //@ts-ignore
    el && el.animate({ opacity: 0 }, 150);
    // el1 && el1.animate({ opacity: 0 }, 150);
    setTimeout(() => {
      // setBackToSchool(false);
      setPremiumsPromoHm(false);
      localStorage.setItem('pc-promo-12-24', 'true');
    }, 150);
  };

  // useEffect(() => {
  //   const val = localStorage.getItem('annual-discount-sb');
  //   const hexowatchPlan = allPlansData?.User?.getUserPlans?.hexowatch?.pricing_package;
  //   const hexomaticPlan = allPlansData?.User?.getUserPlans?.hexomatic?.pricing_package;
  //   const hexomaticPlanInterval = allPlansData?.User?.getUserPlans?.hexomatic?.pricing_package_interval;

  //   const hexowatchFree = hexowatchPlan === 'FREE' || (allPlansData?.User?.getUserPlans && !hexowatchPlan);
  //   const hexomaticFree = hexomaticPlan === 'FREE' || (allPlansData?.User?.getUserPlans && !hexomaticPlan);
  //   const hexomaticSilverLtd = hexomaticPlan === 'SILVER' && hexomaticPlanInterval === 'LTD';
  //   const validHexomatic = hexomaticSilverLtd || hexomaticFree;

  //   if (hexowatchFree && validHexomatic && !val) {
  //     setPremiumsPromo(true)
  //   } else {
  //     setPremiumsPromo(false);
  //   }
  // }, [allPlansData]);

  useEffect(() => {
    const val = localStorage.getItem('annual-discount-sb');

    const freeHexomatic = hexomaticUser?.pricing_package === 'FREE';
    if (!val && freeHexomatic) {
      setPremiumsPromo(true);
    } else {
      setPremiumsPromo(false);
    }
  }, [hexomaticUser]);

  // useEffect(() => {
  //   if (user?.createdAt) {
  //     const creationDate = user.createdAt;
  //     const date1 = new Date(creationDate).getTime();
  //     const date2 = new Date('2023-09-20T00:00:00.000Z').getTime();
  //     const diffTime = date1 < date2;
  //     if (hexomaticUser?.pricing_package === 'FREE' && !localStorage.getItem('pc-promo-jan-24') && diffTime) {
  //       setPremiumsPromoHm(true);
  //     } else {
  //       setPremiumsPromoHm(false);
  //     }
  //   }
  // }, [user, hexomaticUser]);

  // useEffect(() => {
  //   if (user?.promoData?.hexowatch_ltd && !localStorage.getItem('pc-promo-12-24')) {
  //     setPremiumsPromoHm(true);
  //   } else {
  //     setPremiumsPromoHm(false);
  //   }
  // }, [user]);

  // const handlePremiumsPromoClose = () => {
  //   const rightMenu = document.getElementsByClassName('sidebar-show');
  //   const btnShow = document.getElementsByClassName('btn-show');
  //   //@ts-ignore
  //   rightMenu && rightMenu[0] && rightMenu[0].style && (rightMenu[0].style = {});
  //   //@ts-ignore
  //   btnShow && btnShow[0] && btnShow[0].style && (btnShow[0].style = {});
  //   const el = document.getElementById('promo-header annual-discount-sb');
  //   const el1 = document.getElementById('promo-header');
  //   //@ts-ignore
  //   el && el.animate({ opacity: 0 }, 150);
  //   el1 && el1.animate({ opacity: 0 }, 150);
  //   setTimeout(() => {
  //     setPremiumsPromo(false);
  //     localStorage.setItem('annual-discount-sb', 'true');
  //   }, 150);
  // };

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isMobileLg = useMediaQuery({ maxWidth: 1500 });

  // useEffect(() => {
  //   if (allPlansData?.User?.getUserPlans) {
  //     const userPlans = allPlansData?.User?.getUserPlans;
  //     const hexomaticPlanName = userPlans.hexomatic?.pricing_package;
  //     const hexowatchPlanName = userPlans.hexowatch?.pricing_package;
  //     const hexometerPlanName = userPlans.hexometer?.pricing_package;
  //     const hexosparkPlanName = userPlans.hexospark?.pricing_package;
  //     const hexomaticPlanInterval = userPlans.hexomatic?.pricing_package_interval;
  //     const hexowatchPlanInterval = userPlans.hexowatch?.pricing_package_interval;
  //     const hexometerPlanInterval = userPlans.hexometer?.pricing_package_interval;
  //     const hexosparkPlanInterval = userPlans.hexospark?.pricing_package_interval;

  //     const hexofyPlanInterval = hexofyUserData?.HexofyUser?.getHexofyUser?.hfUser?.pricing_package_interval;
  //     const hexofyPlanName = hexofyUserData?.HexofyUser?.getHexofyUser?.hfUser?.pricing_package;

  //     const hexomaticFree = hexomaticPlanName === 'FREE' || (userPlans && !hexomaticPlanName);
  //     const hexowatchFree = hexowatchPlanName === 'FREE' || (userPlans && !hexowatchPlanName);
  //     const hexometerFree = hexometerPlanName === 'FREE' || (userPlans && !hexometerPlanName);
  //     const hexosparkFree = hexosparkPlanName === 'FREE' || (userPlans && !hexosparkPlanName);
  //     const hexofyFree = hexofyPlanName === 'FREE' || (userPlans && !hexofyPlanName);
  //     const showModal =
  //       (hexomaticFree || hexomaticPlanInterval === 'LTD') &&
  //       (hexowatchFree || hexowatchPlanInterval === 'LTD') &&
  //       (hexometerFree || hexometerPlanInterval === 'LTD') &&
  //       (hexofyFree || hexofyPlanInterval === 'LTD') &&
  //       (hexosparkFree || hexosparkPlanInterval === 'LTD');
  //     if (hexofyUserData?.HexofyUser?.getHexofyUser && showModal && !localStorage.getItem('everything-sb-24')) {
  //       setPremiumsPromoHm(true);
  //     } else {
  //       setPremiumsPromoHm(false);
  //     }
  //   }

  //   // const creationDate = user?.createdAt;
  //   // if (creationDate) {
  //   //   const date1 = new Date(creationDate).getTime();
  //   //   const date2 = new Date().getTime();
  //   //   const diffTime = Math.abs(date2 - date1);
  //   //   const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  //   //   const hexofyPlanName = hexofyUserData?.HexofyUser?.getHexofyUser?.hfUser?.pricing_package;
  //   //   const hexofyFree =
  //   //     hexofyPlanName === 'FREE' || (hexofyUserData?.HexofyUser?.getHexofyUser?.hfUser && !hexofyPlanName);
  //   //   if (
  //   //     hexofyUserData?.HexofyUser?.getHexofyUser &&
  //   //     diffDays >= 8 &&
  //   //     !localStorage.getItem('annual-discount-sb') &&
  //   //     hexofyFree
  //   //   ) {
  //   //     setPremiumsPromo(true);
  //   //   } else {
  //   //     setPremiumsPromo(false);
  //   //   }
  //   // }
  // }, [user, hexofyUserData, allPlansData]);

  useEffect(() => {
    const hexomaticPlan = hexomaticUser?.pricing_package && hexomaticUser?.pricing_package !== 'FREE';
    const freePc =
      user?.id && (user?.premium_credits_pricing_package === 'FREE' || !user?.premium_credits_pricing_package);
    const currentDate = new Date();
    const specifiedDate = new Date('Dec 26 2024 07:00:00 GMT');
    const promoClosed = currentDate.getTime() > specifiedDate.getTime();
    if (hexomaticPlan && freePc && !localStorage.getItem('pc-promo-12-24') && !promoClosed) {
      setPremiumsPromoHm(true);
    } else {
      setPremiumsPromoHm(false);
    }
  }, [user, hexomaticUser]);

  // useEffect(() => {
  //   if (user?.promoData?.hexomatic_ltd && !localStorage.getItem('annual-discount-sb')) {
  //     setPremiumsPromo(true);
  //   } else {
  //     setPremiumsPromo(false);
  //   }
  // }, [user]);

  //Sidebar height offset depending on header height changes
  // useEffect(() => {
  //   const headerEl = document.getElementById('header');
  //   const headerHeight = headerEl && headerEl.offsetHeight;

  //   const sidebarEl = document.getElementById('nav-bar');
  //   sidebarEl && (sidebarEl.style.height = `calc(100vh - ${headerHeight || 60}px)`);
  // }, [premiumsPromo, showFlashPromo]);

  useEffect(() => {
    setTimeout(() => {
      const el = document.getElementById('sticky-search-container');
      const elPadding = document.getElementById('sticky-search-container-padding');
      const header = document.getElementById('header');
      el && header?.offsetHeight && (el.style.top = `${header?.offsetHeight}px`);
      el && el.offsetHeight && elPadding && (elPadding.style.paddingTop = `${el.offsetHeight + 5}px`);
    }, 1000);
  }, [premiumsPromo, premiumsPromoHm]);

  return (
    <>
      <header
        id="header"
        className={`${promoPage ? 'promo-page' : ''} header ${toggle ? 'header-pd' : ''}`}
        style={{ padding: logo ? '0 2rem' : '' }}
      >
        <div className="header-inner-wrapper">
          {premiumsPromoHm ? (
            <>
              <div
                className="w-100 position-relative d-none d-lg-flex pc-credits"
                style={{ background: '#002A6A', cursor: 'pointer' }}
                onClick={
                  () => setOpenPremiumCreditsPopup(true)
                  // window.open('https://hexomatic.com/premiums_promo/?utm_source=hexomatic&utm_medium=hellobar')
                }
                id="pc-promo-12-24"
              >
                <img src={background1Hs} alt="" className="illustration1" />
                <div
                  className="w-100 d-flex counter-center m-auto"
                  style={{ height: '60px', position: 'relative', maxWidth: '950px', zIndex: 1 }}
                >
                  <img
                    src={promoTextHs}
                    alt="Promo"
                    className="m-auto"
                    style={{ maxHeight: '60px', maxWidth: '100%' }}
                  />
                  <Counter date="Dec 26 2024 07:00:00 GMT" text="DEAL ENDS IN..." light />
                </div>
                <img src={background2Hs} alt="" className="illustration2 illustration-xl-none" />
                <span className="close-promo" onClick={handlePromoCloseHm}>
                  {closeIcon}
                </span>
              </div>
              <div
                className="w-100 position-relative d-flex d-lg-none pc-credits"
                style={{ background: '#002A6A', cursor: 'pointer' }}
                onClick={
                  () => setOpenPremiumCreditsPopup(true)
                  // window.open('https://hexomatic.com/premiums_promo/?utm_source=hexomatic&utm_medium=hellobar')
                }
              >
                <img src={mobileBannerHs} alt="Promo" className="m-auto" style={{ maxWidth: '250px' }} />
                <div className="m-auto counter_absolute counter-center">
                  <Counter date="Dec 26 2024 07:00:00 GMT" text="DEAL ENDS IN..." light />
                </div>
                <span className="close-promo" onClick={handlePromoCloseHm}>
                  {closeIcon}
                </span>
              </div>
            </>
          ) : (
            // false && (
            premiumsPromo && (
              <>
                <div
                  className="w-100 position-relative d-none d-lg-flex"
                  style={{ background: '#C9F0FF', cursor: 'pointer' }}
                  onClick={() => window.open('https://hexomatic.com/pricing/?utm_source=hexomatic&utm_medium=hellobar')}
                  id="annual-discount-sb"
                >
                  <img src={background1} alt="" className="illustration1" />
                  <div
                    className="w-100 d-flex m-auto"
                    style={{ height: '60px', position: 'relative', maxWidth: '1000px', zIndex: 1 }}
                  >
                    {/* <div
                      className="text-truncate"
                      style={{
                        color: '#03B8FF',
                        fontSize: '36px',
                        fontWeight: 500,
                        position: 'absolute',
                        right: '68%',
                        top: '12px',
                        maxWidth: isMobileLg ? '30%' : 'calc(100% - 870px)',
                        lineHeight: '42px',
                      }}
                    >
                      Hey {user && user.firstName ? (user.firstName.indexOf(' ') > 0 ? user.firstName.slice(0, user.firstName.indexOf(' ')) : user.firstName) : 'there'}
                    </div> */}
                    <img
                      src={promoText}
                      alt=""
                      className="m-auto promo-text w-100"
                      style={{ maxWidth: '1000px', height: '100%' }}
                    />
                    <Counter date={getPromoDate()} text="DEAL ENDS IN..." textColor="#222d39" />
                  </div>
                  <img src={background2} alt="" className="illustration2" />
                  <span className="close-promo" onClick={handlePromoClose}>
                    {closeIcon}
                  </span>
                </div>
                <div
                  className="w-100 position-relative d-flex d-lg-none"
                  style={{ background: '#C9F0FF', cursor: 'pointer' }}
                  onClick={() => window.open('https://hexomatic.com/pricing/?utm_source=hexomatic&utm_medium=hellobar')}
                >
                  {/* <div
                    className="text-truncate"
                    style={{
                      color: '#03B8FF',
                      fontSize: '32px',
                      fontWeight: 500,
                      position: 'absolute',
                      right: '50%',
                      transform: 'translate(50%, -50%)',
                      top: '18px',
                      width: '100%',
                      textAlign: 'center',
                      maxWidth: '100%',
                      lineHeight: '42px',
                    }}
                  >
                    Hey {user && user.firstName ? (user.firstName.indexOf(' ') > 0 ? user.firstName.slice(0, user.firstName.indexOf(' ')) : user.firstName) : 'there'}
                  </div> */}
                  <img src={mobileBanner} alt="" className="m-auto" style={{ maxWidth: '260px' }} />
                  <div className="m-auto counter_absolute">
                    <Counter date={getPromoDate()} text="DEAL ENDS IN..." textColor="#222d39" />
                  </div>
                  <span className="close-promo" onClick={handlePromoClose}>
                    {closeIcon}
                  </span>
                </div>
              </>
            )
          )}
          {/* {appsumo && (
          <div
            className={`text-center d-flex flex-wrap justify-content-center ${
              !props.hideSidebar ? 'sidebar-padding' : ''
            }`}
            id="promo-header"
          >
            <img
              src={isMobile ? appsumoMobile : appsumoImg}
              style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
              onClick={() => window.open('https://promo.hexomatic.com', '_blank')}
            />
            <div className="d-flex flex-wrap justify-content-center" style={{ width: 'calc(100% - 30px)' }}>
              <div className="d-flex mr-2">
                <span className="promo-text">
                  <span onClick={() => window.open('https://promo.hexomatic.com', '_blank')} className="hexowatch-external-link">
                    Don't miss out on our last chance LTD on AppSumo ⚡
                  </span>{' '}
                </span>
              </div>
              <Counter hours />
            </div>
            <span className="close-promo" onClick={handlePromoClose}>
              {closeIcon}
            </span>
          </div>
        )} */}

          {/* {showFlashPromo && (
          <div
            className={`black-friday-topbar p-0 text-center d-flex flex-wrap justify-content-center cursor-pointer`}
            id="promo-header"
            onClick={() => push('/pricing')}
          >
            <div className="d-flex justify-content-center" style={{ width: '100%' }}>
              <img src={background1} alt="illustration1" className="illustration1 d-none d-xl-block" />
              <div className="d-flex d-md-flex-wrap justify-content-center">
                <img src={flashPromo} alt="holiday sale" className="holidays-sale p-2 mr-md-4" />
                <div className="m-auto">
                  <Counter date={getParsedDateAndTimeGMT(promoEndDate)} hours />
                </div>
              </div>
              <img src={background2} alt="illustration2" className="illustration2 d-none d-xl-block" />
            </div>
          </div>
        )} */}
          {/* {backToSchool && !promoPage && (
          <div
            className={`black-friday-topbar p-0 text-center d-flex flex-wrap justify-content-center cursor-pointer`}
            style={{ background: '#4D0076' }}
            id="promo-header holidays-promo-22"
            onClick={() => push('/pricing')}
          >
            <div className="justify-content-center d-none d-md-flex" style={{ width: '100%' }}>
              <img src={background1} alt="illustration1" className="illustration1" />
              <div className="d-md-flex d-md-flex-wrap justify-content-center w-100">
                <div className="d-flex mr-4">
                  <img src={addOn} className="d-none-xl-part mr-4" style={{maxWidth: '200px'}} />
                  <img
                    src={promoText1}
                    alt=""
                    className="py-0 px-2 promo-text-1"
                    style={{ maxWidth: '100%', zIndex: 1000, maxHeight: '68px' }}
                  />
                </div>
                  <div className="mt-4 ml-4 col-lg-2 col-12 p-0">
                    <Counter date="Dec 26 2022 07:00:00 GMT" />
                  </div>
              </div>
              <img src={background2} alt="illustration2" className="illustration2 d-none d-xl-block" />
            </div>
            <div className="d-block d-md-none justify-content-center" style={{ width: '100%' }}>
              <img src={promoBannerMobile} alt="Holidays Promo" style={{ maxWidth: '100%', maxHeight: '150px' }} />
              <div className="m-auto counter_absolute">
                <Counter date='Dec 26 2022 07:00:00 GMT' text='Lifetime deal ends in' />
              </div>
            </div>
            <span
              className="close-promo"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                handlePromoClose();
              }}
            >
              {closeIcon}
            </span>
          </div>
        )} */}
          {/* {premiumsPromo && (
            <div
              className={`black-friday-topbar p-0 text-center d-flex flex-wrap justify-content-center cursor-pointer`}
              style={{ background: '#4F21DD' }}
              id="promo-header annual-discount-sb"
              onClick={() => {
                window.open('https://hexofy.com', '_blank');
                // if (user?.verified) {
                //   setIsOpenModal(true);
                // } else if (user && !user.verified) {
                //   setShowVerificationModal(true);
                // }
              }}
            >
              <div className="justify-content-center d-none d-md-flex" style={{ width: '100%' }}>
                <img src={background1} alt="illustration1" className="illustration1" />
                <div className="d-md-flex d-md-flex-wrap justify-content-center w-100">
                  <div className="d-block d-md-flex position-relative" style={{ minHeight: '68px' }}>
                    <div className="m-auto d-flex">
                      <img
                        src={premiumsPromoText}
                        alt=""
                        className="py-0 px-2 promo-text-2"
                        style={{ maxWidth: '100%', zIndex: 1000, maxHeight: '68px' }}
                      />
                    </div>
                    <div className="m-0">
                      <Counter date="Mar 10 2023 07:00:00 GMT" text="BETA LAUNCH & PRICE INCREASE IN..." />
                    </div>
                  </div>
                </div>
                <img src={background2} alt="illustration2" className="illustration2 d-none d-xl-block" />
              </div>
              <div className="d-block d-md-none justify-content-center" style={{ width: '100%' }}>
                <img src={promoBannerMobile} alt="Hexofy beta launch" style={{ maxHeight: '180px' }} />
                <div className="m-auto counter_absolute">
                  <Counter date="Mar 10 2023 07:00:00 GMT" text="BETA LAUNCH & PRICE INCREASE IN..." />
                </div>
              </div>
              <span
                className="close-promo"
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handlePremiumsPromoClose();
                }}
              >
                {closeIcon}
              </span>
            </div>
          )} */}
          {/* {!showFlashPromo && hexosparkPrelaunch && (
          <div
            className={`black-friday-topbar p-0 text-center d-flex flex-wrap justify-content-center cursor-pointer ${
              !props.hideSidebar ? 'sidebar-padding' : ''
            }`}
            style={{ background: '#002A6A' }}
            id="promo-header hexospark-prelaunch"
            onClick={() => window.open('https://prelaunch.hexospark.com', '_blank')}
          >
            <div className="d-flex justify-content-center" style={{ width: '100%' }}>
              <img src={hexosparkBackground1} alt="illustration1" className="illustration1 d-none d-xl-block" />
              <div className="d-flex d-md-flex-wrap justify-content-center">
                <img src={hexospark} alt="holiday sale" className="holidays-sale p-2 mr-xl-4" />
                <div className="m-auto">
                  <Counter date="June 07 2022 19:00:00 GMT" leftSeats={leftSeats} />
                </div>
              </div>
              <img src={hexosparkBackground2} alt="illustration2" className="illustration2 d-none d-xl-block" />
            </div>
            <span
              className="close-promo"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                handlePromoClose();
              }}
            >
              {closeIcon}
            </span>
          </div>
        )} */}
          {/* {!showFlashPromo && (buyHexomaticGetHexowatch || buyHexomatichGetHexometer || buyHexowatchGetHexometer) && (
          <div
            className={`black-friday-topbar p-0 text-center d-flex flex-wrap justify-content-center cursor-pointer ${
              !props.hideSidebar ? 'sidebar-padding' : ''
            }`}
            id="promo-header"
              onClick={() => {
                if (buyHexomaticGetHexowatch || buyHexomatichGetHexometer) {
                  push('/pricing')
                } else if (buyHexowatchGetHexometer) {
                  window.open('https://dash.hexowatch.com/hexowatch/upgrade_hw')
                }
              }}
            >
            <div className="d-flex justify-content-center" style={{ width: '100%' }}>
              <img src={easterPromoBackground1} alt="illustration1" className="illustration1 d-none d-xl-block" />
              <div className="d-flex d-md-flex-wrap justify-content-center">
                <img src={
                  buyHexomaticGetHexowatch ? easterPromoBuyMaticGetWatch :
                  buyHexomatichGetHexometer ? easterPromoBuyMaticGetMeter :
                  buyHexowatchGetHexometer ? easterPromoBuyWatchGetMeter :
                  easterPromoLTD
                } alt="holiday sale" className="holidays-sale p-2 mr-md-4" />
                <div className="m-auto">
                  <Counter date={'Apr 20 2022 07:00:00 GMT'} hours />
                </div>
              </div>
              {!easterPromoHexowatchLTD ?
                : <img src={easterdPromoBackground2} alt="illustration2" className="illustration2 d-none d-xl-block" />
                <div className="illustration2-text d-none d-xl-block"> Seats Remaining <span style={{background: '#fff', color: '#6E72F6', display: 'inline-block', padding: '3px 10px', borderRadius: '4px', marginLeft: '5px'}}>{leftSeats}</span> </div>
              }
            </div>
          </div>
        )} */}
          <HeaderAlert />
          {promoPage ? null : (
            <div className="main-header">
              {!isMobile && (
                <div className="nav__logos d-flex justify-content-center">
                  <Link
                    className="nav___logos"
                    to="/"
                    onClick={() => window && window.innerWidth < 1025 && setToggle(!toggle)}
                  >
                    {/* <img src={theme === 'light' ? HexomaticLogoRect : HexomaticLogoRectWhite} alt="Hexomatic Logo" /> */}
                  </Link>
                </div>
              )}
              <div className="header__toggle">
                <button
                  className="burger-button"
                  onClick={() => setToggle(!toggle)}
                  onBlur={() => {
                    setTimeout(() => {
                      setToggle(!toggle);
                    }, 0);
                  }}
                >
                  {/* <img height="20px" src={burgerButtonIcon} alt="Burger button icon" /> */}
                  {burgerButton}
                  {/* <img height="20px" src={burger} alt="Burger button icon" /> */}
                </button>
                {user && logo && (
                  <img
                    src={toggle ? (theme === 'light' ? logoColor : logoWhite) : logoColor}
                    alt="Hexomatic Logo"
                    style={{ marginRight: 'auto' }}
                  />
                )}
              </div>

              {/* <div>
        <button onClick={() => changeLanguage('en')} type="button">
          EN
        </button>
        <button onClick={() => changeLanguage('de')} type="button">
          DE
        </button>
      </div> */}
              <div className="d-flex navigation-wrapper">
                <div className="d-flex align-items-center">
                  <a
                    className=" text-decoration-none d-flex align-items-center "
                    href="https://hexomatic.com/academy/tutorials"
                    target="_blank"
                    rel="noopener"
                  >
                    <Tooltip trigger={['hover', 'focus']} placement="left" text="Academy">
                      {cap}
                    </Tooltip>

                    {/* <span className="d-none d-md-block">Hexomatic Academy</span> */}
                  </a>

                  <a className="svg-theme c-default" href="https://hexomatic.com/faq/" target="_blank" rel="noopener">
                    <Tooltip trigger={['hover', 'focus']} placement="left" text="FAQ">
                      {questionIcon}
                    </Tooltip>
                  </a>

                  {/* <span className={`tooltip-text tooltip-text-${toggle}`}> {questionIcon} </span> */}

                  <a
                    onClick={() => {
                      localStorage.setItem('invite-clicked', 'true');
                      setOpenInviteModal(true);
                    }}
                    className="pr-2 svg-theme c-default"
                    target="_blank"
                    rel="noopener"
                  >
                    <Tooltip
                      trigger={['hover', 'focus']}
                      placement="left"
                      text="Refer a friend and get 50 premium credits each!"
                    >
                      {giftIcon}
                    </Tooltip>
                  </a>
                  <div className="mr-1 header__img">
                    <Link to="/profile">
                      {userData?.email ? (
                        <img src={userPhoto || 'https://hexomatic.com/avatar-hexomatic.svg'} alt={userData.firstName} />
                      ) : (
                        <div className="pulse">
                          <div className="circle-placeholder"></div>
                        </div>
                      )}
                    </Link>
                  </div>
                  <div className="px-2 svg-theme">
                    <Select showLeft icon={downArrow} noBorder>
                      {profileOptions &&
                        profileOptions.map((item: any) => {
                          if (hexomaticUser && !hexomaticUser.isPerformer && item.title === 'Tasks') return;
                          return (
                            <NavLink className="option nav-link-component" key={item.title} to={item.path}>
                              <span style={{ width: '20px' }}>{item.icon}</span>
                              <span className="ml-3">{item.title}</span>
                            </NavLink>
                          );
                        })}
                    </Select>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="px-3 svg-theme">
                    <Select showLeft icon={menuNavbarIcon} noBorder>
                      <div className="option-title">
                        <h5 className="text-center pl-1 pr-1">Hexact Solutions</h5>
                        <hr />
                      </div>
                      {productsList &&
                        productsList.map((item: any) => {
                          return (
                            <a
                              className="option nav-link-component"
                              key={item.title}
                              href={item.path}
                              target={item.path.includes('https') ? '_blank' : ''}
                              rel="noopener"
                            >
                              <img src={item.icon} style={{ width: 20, height: 20 }} />
                              <span className="ml-3">{item.title}</span>
                              {item.title === 'Hexomatic' && (
                                <span className="ml-auto current-product">{checkedIcon}</span>
                              )}
                            </a>
                          );
                        })}
                    </Select>
                  </div>
                  <div className="pl-1 d-flex align-items-center">
                    {/* <SunButton onChange={onChange} /> */}
                    <ModeSwitcher />

                    {/* <ToggleBtn onChange={onChange} checked={theme === 'dark'} /> */}
                  </div>
                </div>
              </div>
            </div>
          )}
          {openInviteModal && <InviteModal onClose={() => setOpenInviteModal(false)} isDarkMode={theme === 'dark'} />}
        </div>
      </header>
      {openPremiumCreditsPopup && (
        <CustomModal
          ShowPopup={openPremiumCreditsPopup}
          setShowPopup={setOpenPremiumCreditsPopup}
          hideModalBody
          className={'payment-modal'}
          escButtonClick={setOpenPremiumCreditsPopup}
        >
          <Payment
            name={''}
            selectedValue={{ title: '100/monthly', value: 100 }}
            price={0}
            premium_credit
            setShowPopup={setOpenPremiumCreditsPopup}
            // offer={!!window.location.href.includes('#premium-credits')}
            // offer
          />
        </CustomModal>
      )}
    </>
  );
};

export default Header;
