import { FC, useContext, useEffect, useRef, useState } from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { Modal, Row, Col } from 'react-bootstrap';
import Tooltip from 'shared-components/atom/tooltip';
import { ThemeContext } from 'contexts/theme-context';
import { closeIcon, copyIconModal } from 'assets/svg-icons';
import giftIcon from 'assets/hexomatic-gift.svg';
import facebook from 'assets/share-icons/facebook.svg';
import twitter from 'assets/share-icons/twitter.svg';
import twitterLight from 'assets/share-icons/twitterLight.svg';
import mail from 'assets/share-icons/mail.svg';
import linkedin from 'assets/share-icons/linkedin.svg';
import telegram from 'assets/share-icons/telegram.svg';
import whatsapp from 'assets/share-icons/whatsapp.svg';
// import reddit from 'assets/share-icons/whatsapp.svg';
import { UserContext } from 'contexts/user-context';
import './styles.scss';
import { closePopupOnEsc } from 'helpers';

interface InviteModalProps {
  onClose: () => void;
  isDarkMode?: boolean;
}

const InviteModal: FC<InviteModalProps> = ({ onClose, isDarkMode }) => {
  const { user } = useContext(UserContext);
  const [copyText, setCopyText] = useState(
    `Hey! Register at Hexomatic and use my code ${user.referral_code} to get 50 premium credits when you upgrade to any paid plan. (conditions apply) https://hexomatic.com/?referralCode=${user.referral_code}/#registration`,
  );

  useEffect(() => {
    setCopyText(
      `Hey! Register at Hexomatic and use my code ${user.referral_code} to get 50 premium credits when you upgrade to any paid plan. (conditions apply) https://hexomatic.com/?referralCode=${user.referral_code}/#registration`,
    );
  }, [user]);

  const inputRef = useRef(null);
  const onInputClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    //@ts-ignore
    navigator.clipboard.writeText(copyText || '');
    const inputDom = inputRef.current;
    if (!inputDom) {
      return;
    }
    //@ts-ignore
    inputDom.focus();
    //@ts-ignore
    inputDom.setSelectionRange(0, copyText ? copyText.length : 0);
  };
  const { theme } = useContext(ThemeContext);

  //Close Popup on Esc button click
  useEffect(() => {
    closePopupOnEsc(() => onClose());
  }, []);

  return (
    <Modal show={true} toggle={() => {}} className={`modal-dialog--header theme-${theme} invite-modal`}>
      <div className="modal__header">
        <div className="panel__btns">
          <button className="panel__btn" onClick={() => onClose()} type="button">
            {closeIcon}
          </button>
        </div>
      </div>

      <div className="modal__body" style={{ padding: '15px' }}>
        <div className="d-flex">
          <img src={giftIcon} className="m-auto" />
        </div>
        <h5 className="invite-modal-title">Refer a friend</h5>
        <h5 className="invite-modal-title">and get 50 premium credits each!</h5>
        <p className="invite-modal-text py-2">
          For every friend who signs up for a paid plan we'll credit your account & your friends with 50 non-expiring
          premium credits.
        </p>
        <Tooltip trigger={['focus']} placement="bottom" text="Copied" className="textarea-tooltip">
          <textarea
            value={copyText}
            readOnly
            ref={inputRef}
            className={`invite-modal-share-text py-2 primary-text mb-4`}
            onClick={onInputClick}
          />
        </Tooltip>
        <Row className="text-center">
          <Col md={12}></Col>
          <Col md={12} className="d-flex justify-content-center flex-wrap">
            <Tooltip trigger={['focus']} placement="bottom" text="Copied">
              <span className="copy-icon m-vertical-auto" onClick={onInputClick}>
                {copyIconModal}
              </span>
            </Tooltip>
            <EmailShareButton
              subject="Refer a friend and get 50 premium credits each!"
              body={copyText.replace(`https://hexomatic.com/?referralCode=${user.referral_code}/#registration`, '')}
              url={`https://hexomatic.com/?referralCode=${user.referral_code}/#registration`}
            >
              <div className="share-icon-container">
                <img src={mail} />
              </div>
            </EmailShareButton>
            <FacebookShareButton
              quote={copyText}
              url={`https://hexomatic.com/?referralCode=${user.referral_code}/#registration`}
            >
              <div className="share-icon-container">
                <img src={facebook} />
              </div>
            </FacebookShareButton>

            <LinkedinShareButton
              summary={copyText}
              url={`https://hexomatic.com/?referralCode=${user.referral_code}/#registration`}
            >
              <div className="share-icon-container">
                <img src={linkedin} />
              </div>
            </LinkedinShareButton>
            {/* <RedditShareButton
                title={copyText}
                url={`https://hexomatic.com/?referralCode=${user.referral_code}/#registration`}
              >
                <div className="share-icon-container">
                  <img src={reddit} />
                </div>
              </RedditShareButton> */}
            <TelegramShareButton
              title={copyText.replace(`https://hexomatic.com/?referralCode=${user.referral_code}/#registration`, '')}
              url={`https://hexomatic.com/?referralCode=${user.referral_code}/#registration`}
            >
              <div className="share-icon-container">
                <img src={telegram} />
              </div>
            </TelegramShareButton>
            <TwitterShareButton
              title={copyText.replace(`https://hexomatic.com/?referralCode=${user.referral_code}/#registration`, '')}
              url={`https://hexomatic.com/?referralCode=${user.referral_code}/#registration`}
            >
              <div className="share-icon-container">
                <img src={isDarkMode ? twitterLight : twitter} />
              </div>
            </TwitterShareButton>
            <WhatsappShareButton
              title={copyText.replace(`https://hexomatic.com/?referralCode=${user.referral_code}/#registration`, '')}
              url={`https://hexomatic.com/?referralCode=${user.referral_code}/#registration`}
            >
              <div className="share-icon-container">
                <img src={whatsapp} />
              </div>
            </WhatsappShareButton>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default InviteModal;
