import { useEffect, useState, FC } from 'react';
import {
  deleteIconModal,
  globeIcon,
  runIconModal,
  lockIconModal,
  copyIconModal,
  shareIcon,
  updateIcon,
  clockIconNew,
  triggerIcon,
} from '../../../../assets/svg-icons';
import CustomModal from 'shared-components/molecule/customModal';
import MenuModal from '../menu-modal';

interface IProps {
  returnActiveStatus: (item: any, reverseActive?: boolean | undefined) => boolean;
  returnWorkflowStatus: (item: any) => string;
  item: any;
  setShowActionModal: (val: boolean) => void;
  setOptionParameters: any;
  setScheduleConfig: any;
  setStartedAt: any;
  isAdmin: boolean;
  setTrigger: any;
  setselectedOutputKeys: any;
  setSendMail: any;
}
const ActionsModal: FC<IProps> = ({
  returnActiveStatus,
  returnWorkflowStatus,
  item,
  setShowActionModal,
  setOptionParameters,
  setScheduleConfig,
  setStartedAt,
  isAdmin,
  setTrigger,
  setselectedOutputKeys,
  setSendMail,
}) => {
  //---------------------State---------------------
  const [optionForMenu, setOptionForMenu] = useState([] as any);
  const [openMenuModal, setOpenMenuModal] = useState(false);
  const [includePremium] = useState(
    !!(
      item &&
      item.steps &&
      item.steps.find(
        (el: any) => el.automation_type_name === 'Premium automations',
        // && el.automation_id !== 143
      )
    ),
  );

  //--------------------useEffect---------------------

  useEffect(() => {
    const menuOption =
      returnActiveStatus(item, true) &&
      (returnWorkflowStatus(item) === 'error' ||
        returnWorkflowStatus(item) === 'completed' ||
        returnWorkflowStatus(item) === 'DRAFT') &&
      isAdmin
        ? [
            {
              label: 'Restart workflow',
              value: item.id,
              icon: runIconModal,
              status: item?.status,
              includePremium,
            },
            { label: 'Duplicate workflow', value: item.id, icon: copyIconModal },
            {
              label: !item.is_public ? 'Make public' : 'Make private',
              value: item.id,
              icon: !item.is_public ? globeIcon : lockIconModal,
              recipeIds: item.steps
                .filter((item: any) => {
                  const settings = item.setting && JSON.parse(item.setting);
                  if (settings?.predefined_settings?.recipeId) {
                    return item;
                  }
                })
                .map((item: any) => {
                  const settings = item.setting && JSON.parse(item.setting);
                  if (settings?.predefined_settings?.recipeId) {
                    return settings?.predefined_settings?.recipeId;
                  }
                }),
            },
            item.is_public
              ? {
                  label: 'Sync public workflow',
                  value: item.id,
                  icon: updateIcon,
                  recipeIds: item.steps
                    .filter((item: any) => {
                      const settings = item.setting && JSON.parse(item.setting);
                      if (settings?.predefined_settings?.recipeId) {
                        return item;
                      }
                    })
                    .map((item: any) => {
                      const settings = item.setting && JSON.parse(item.setting);
                      if (settings?.predefined_settings?.recipeId) {
                        return settings?.predefined_settings?.recipeId;
                      }
                    }),
                }
              : undefined,
            { label: 'Share Workflow', value: item.id, icon: shareIcon },
            { label: 'Delete', value: item.id, icon: deleteIconModal },
            {
              label: 'Schedule workflow',
              frequency: item.frequency,
              value: item.id,
              icon: clockIconNew,
              includePremium,
            },
            {
              label: 'Trigger via Hexowatch',
              value: item.id,
              icon: triggerIcon,
              includePremium,
            },
          ]
        : returnActiveStatus(item, true) &&
          (returnWorkflowStatus(item) === 'error' ||
            returnWorkflowStatus(item) === 'completed' ||
            returnWorkflowStatus(item) === 'DRAFT') &&
          !isAdmin
        ? [
            {
              label: 'Restart workflow',
              value: item.id,
              icon: runIconModal,
              status: item?.status,
              includePremium,
            },
            { label: 'Duplicate workflow', value: item.id, icon: copyIconModal },
            { label: 'Share Workflow', value: item.id, icon: shareIcon },

            { label: 'Delete', value: item.id, icon: deleteIconModal },
            {
              label: 'Schedule workflow',
              frequency: item.frequency,
              value: item.id,
              icon: clockIconNew,
              includePremium,
            },
            {
              label: 'Trigger via Hexowatch',
              value: item.id,
              icon: triggerIcon,
              includePremium,
            },
          ]
        : isAdmin
        ? [
            { label: 'Duplicate workflow', value: item.id, icon: copyIconModal },
            {
              label: !item.is_public ? 'Make public' : 'Make private',
              value: item.id,
              icon: !item.is_public ? globeIcon : lockIconModal,
            },
            item.is_public
              ? {
                  label: 'Sync public workflow',
                  value: item.id,
                  icon: updateIcon,
                }
              : undefined,
            { label: 'Share Workflow', value: item.id, icon: shareIcon },
            { label: 'Delete', value: item.id, icon: deleteIconModal },
            {
              label: 'Schedule workflow',
              frequency: item.frequency,
              value: item.id,
              icon: clockIconNew,
              includePremium,
            },
            {
              label: 'Trigger via Hexowatch',
              value: item.id,
              icon: triggerIcon,
              includePremium,
            },
          ]
        : [
            { label: 'Duplicate workflow', value: item.id, icon: copyIconModal },
            { label: 'Share Workflow', value: item.id, icon: shareIcon },
            { label: 'Delete', value: item.id, icon: deleteIconModal },
            {
              label: 'Schedule workflow',
              frequency: item.frequency,
              value: item.id,
              icon: clockIconNew,
              includePremium,
            },
            {
              label: 'Trigger via Hexowatch',
              value: item.id,
              icon: triggerIcon,
              includePremium,
            },
          ];
    setOptionForMenu(menuOption);
    setOpenMenuModal(true);
    setScheduleConfig(item.schedule_config);
    setStartedAt(item.started_at);
    item?.schedule_config?.triggers && item.schedule_config.triggers[0] && setTrigger(item.schedule_config.triggers[0]);
    item?.configs?.selectedOutputKeys && setselectedOutputKeys(item.configs.selectedOutputKeys);
    item?.configs && setSendMail(item.configs.send_mail);
  }, [item, isAdmin, setScheduleConfig, returnActiveStatus, returnWorkflowStatus, setStartedAt]);
  //----------------------------------------------------

  //----------------------------------------------------
  return (
    <CustomModal ShowPopup={openMenuModal} noNavigate escButtonClick={setShowActionModal}>
      <MenuModal setOptionParameters={setOptionParameters} menuOption={optionForMenu} setShow={setShowActionModal} />
    </CustomModal>
  );
};

export default ActionsModal;
