import { FC, memo } from 'react';
import './styles.scss';

interface ILoader {
  light?: boolean;
  size?: string;
  className?: string;
}

const Loader: FC<ILoader> = ({ light, size, className }) => (
  <div className={`custom-loader ${className && className}`}>
    <div className={`loader ${light ? 'light' : ''} ${size ? size : ''}`}></div>
  </div>
);

export default memo(Loader);
