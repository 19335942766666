import { useContext, useState, FC } from 'react';
import { ThemeContext } from 'contexts/theme-context';
import { Col, Row } from 'react-bootstrap';
import Pagination from 'shared-components/molecule/pagination';
import Select from 'shared-components/atom/select';
import Loader from 'shared-components/atom/loader';
import AlertComponent from 'shared-components/atom/alert-message';

interface ILimit {
  value: number;
  label: string;
}
interface IFooter {
  limit: ILimit;
  title?: string;
  page: number;
  count: number;
  setPage: (val: number) => void;
  limitChange: (val: ILimit) => void;
  loading?: boolean;
  error?: string;
}
const PaginationAndRowPerPage: FC<IFooter> = ({ limit, title, page, count, setPage, limitChange, loading, error }) => {
  //----------------------Context----------------------------
  const { theme } = useContext(ThemeContext);
  //---------------------Functions---------------------------
  const handelLimitChange = (val: ILimit) => {
    title && localStorage.setItem(title + 'Limit', JSON.stringify(val));
    limitChange(val);
  };
  const handelPageChange = (val: number) => {
    if (val === page) return;
    title && localStorage.setItem(title + 'Page', String(val));
    setPage(val);
  };

  return (
    <>
      <Row>
        <Col className="d-flex  justify-content-center" md={3}>
          {error && <AlertComponent type="error" text={error} className="ml-4" />}
        </Col>
        <Col className="d-flex  justify-content-center" md={6}>
          {loading && <Loader light={theme === 'light'} className="p-0 m-auto" />}
        </Col>
        <Col className="d-flex justify-content-end mt-2" md={3}>
          <p className="label">Rows per page:</p>
          <Select
            value={limit}
            options={[
              { label: '5', value: 5 },
              { label: '15', value: 15 },
              { label: '25', value: 25 },
              { label: '30', value: 30 },
              { label: '50', value: 50 },
            ]}
            onChange={handelLimitChange}
            width={80}
            noTextTransform
            className="rows-per-page"
          />
        </Col>
      </Row>
      {Math.ceil(count / limit.value) > 1 && (
        <div className="h-100 d-flex align-items-center justify-content-center mt-2">
          <Pagination
            activePage={page}
            onChange={handelPageChange}
            totalCount={Math.ceil(count / limit.value)}
            itemsPerPage={1}
          />
        </div>
      )}
    </>
  );
};

export default PaginationAndRowPerPage;
