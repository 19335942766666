import { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import Input from 'shared-components/atom/input';

const resultsLimitOptions = [
  { label: 'All', value: 'all' },
  { label: '1', value: 1 },
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '20', value: 20 },
];

const filterConditionsOptions = [
  { label: 'Select condition', value: null },
  { label: 'Contains', value: 'CONTAINS' },
  { label: 'Does not contain', value: 'DOES_NOT_CONTAIN' },
];

const EmailDiscoverySettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);
  const [clicked, setClicked] = useState(false);
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [resultsLimit, setResultsLimit] = useState<{ label: string; value: number | string }>({
    label: '10',
    value: 10,
  });
  const [filterText, setFilterText] = useState('');
  const [filterCondition, setFilterCondition] = useState<{ label: string; value: null | string }>({
    label: 'Select condition',
    value: null,
  });
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const {
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    liftingSettings,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (!selectedSource || (filterCondition && filterCondition?.value && !filterText)) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings && fieldList.length > 0) {
      const keys = dynamic_settings.keys;
      const { key, settings } = keys[0];
      const max_results_per_domain = settings ? settings.max_results_per_domain : keys[0].max_results_per_domain;
      const condition = settings ? settings.condition : keys[0].condition;
      const filter_data = settings ? settings.filter_data : keys[0].filter_data;

      if (max_results_per_domain) {
        const max_results_per_domain_selected = resultsLimitOptions.find(item => item.value === max_results_per_domain);
        max_results_per_domain_selected && setResultsLimit(max_results_per_domain_selected);
      }
      if (condition) {
        const condition_selected = filterConditionsOptions.find(item => item.value === condition);
        condition_selected && setFilterCondition(condition_selected);
      }
      filter_data && setFilterText(filter_data);
      restoreSelectedSource({ key: key, fieldList: fieldList, setSelectedSource: setSelectedSource });
    }
  }, [fieldList]);

  //recipe fields or output fields
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    let optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      //its set from props
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [
          ...fieldList.map((item: { value: any }) => item.value),
          ...[
            ...fieldList.map((item: { value: any }) => item.value),
            ...['_discovered_email', '_discovered_email_verified'],
          ],
        ],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);

  //lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: {
        keys: [
          {
            key: fieldList.length && selectedSource ? selectedSource.value : null,
            inputValue: null,
            settings: {
              condition: filterCondition && filterCondition.value ? filterCondition.value : null,
              filter_data: filterCondition && filterCondition.value ? filterText.trim() : null,
              max_results_per_domain: resultsLimit.value,
            },
          },
        ],
      },
      outputKeysData: [
        ...fieldList.map((item: { value: any }) => item.value),
        ...['_discovered_email', '_discovered_email_verified'],
      ],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [selectedSource, filterCondition, filterText, resultsLimit, fieldList, fieldListWithTypes, automationOutputTypes]);

  return (
    <div className="">
      <Row>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Source</span>
          <Select
            fullWidth
            options={fieldList}
            value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
            placeholder={'Select source'}
            onChange={(selectedItem: any) => {
              setSelectedSource(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Result limit per domain</span>
          <Select
            fullWidth
            options={resultsLimitOptions}
            value={resultsLimit}
            placeholder={'Select limit'}
            onChange={(selectedItem: any) => {
              setResultsLimit(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Filter condition (optional)</span>
          <Select
            fullWidth
            options={filterConditionsOptions}
            value={filterCondition}
            placeholder={'Select condition'}
            onChange={(selectedItem: any) => {
              !selectedItem.value && setFilterText('');
              setFilterCondition(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
        {filterCondition && filterCondition.value && (
          <Col lg={6} className="mb-4">
            <span className="label secondary-color">Filter value</span>
            <Input
              value={filterText}
              onChange={e => {
                setFilterText(e.target.value);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default EmailDiscoverySettings;
