import { FC, useState, useContext } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { ThemeContext } from '../../../contexts/theme-context';
import { ToolCardProps } from '../types';
import Loader from '../../atom/loader';
import { downArrow } from '../../../assets/svg-icons';
import classNames from 'classnames';
import './styles.scss';

const ToolCard: FC<ToolCardProps> = ({
  rescan,
  handleRescan,
  title,
  titleWithIcon,
  children,
  loading,
  buttons,
  viewMoreLink,
  className,
  helpIcon,
  icon,
  linkIcon,
  iconLink,
  helpMessage,
  centerTitle,
  hexowatch,
  openClose,
  iconColor,
  noDivider,
  noSidePadding,
  rightAddon,
}) => {
  // const [tooltipOpen, setTooltipOpen] = useState(false);
  const [hideToolCard, setHideToolCard] = useState(true);

  // const toggle = () => {
  //   setTooltipOpen(!tooltipOpen);
  // };
  const { theme } = useContext(ThemeContext);
  return (
    <div
      className={classNames('panel card scan-tool-card pb-0 h-auto', {
        'panel--divider': !noDivider,
        panel__title__no__divider: noDivider,
        className: className,
      })}
    >
      <div
        className="panel__body card-body"
        style={{ paddingLeft: noSidePadding ? '0px' : '', paddingRight: noSidePadding ? '0' : '' }}
      >
        {loading && (
          <div className="panel__refresh">
            {hexowatch ? (
              <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Loader light={theme === 'light'} />
              </div>
            ) : (
              <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Loader light={theme === 'light'} />
              </div>
            )}
          </div>
        )}
        <div
          className={classNames('panel__title d-flex', {
            'justify-content-center': centerTitle,
            panel__title__no__divider: noDivider,
          })}
          style={{ border: hideToolCard && openClose ? 'none' : '', cursor: openClose ? 'pointer' : 'default' }}
          onClick={() => setHideToolCard(!hideToolCard)}
        >
          {icon && (
            <div
              id={
                title && typeof title === 'string'
                  ? title.replace(/\s/g, '')
                  : titleWithIcon
                  ? titleWithIcon.toString().replace(/\s/g, '')
                  : ''
              }
              style={{ background: iconColor || '' }}
              className="panel__title__icon"
            >
              {icon}
            </div>
          )}
          {helpMessage && helpIcon && title && typeof title === 'string' && (
            <>
              <span data-tip={helpMessage}>{helpIcon}</span>
              {/*<Tooltip  />*/}
            </>
          )}
          {(title || titleWithIcon) && (
            <h5
              className="bold-text title"
              style={{ fontSize: '14px', paddingTop: 0, paddingLeft: noSidePadding ? '40px' : '10px' }}
            >
              {titleWithIcon || title}
            </h5>
          )}
          {iconLink && (
            <>
              <a href={iconLink} target="_blank" rel="noopener noreferrer" className="external_link_icon">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M15 10.8333V15.8333C15 16.2754 14.8244 16.6993 14.5118 17.0118C14.1993 17.3244 13.7754 17.5 13.3333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V6.66667C2.5 6.22464 2.67559 5.80072 2.98816 5.48816C3.30072 5.17559 3.72464 5 4.16667 5H9.16667"
                    stroke="#16AC59"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.5 2.5H17.5V7.5"
                    stroke="#16AC59"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.33398 11.6667L17.5007 2.5"
                    stroke="#16AC59"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>{' '}
                <small>HTTP status codes</small>
              </a>
            </>
          )}
          {/* {title && icon && (() => {
            if (helpMessage) return (
              <>
                <div id={title.replace(/\s/g, '')} className='title_icon'>{icon}</div>
                
              </>
              );
            if (iconLink) return (
             
            return (
              <span className='title_right_icon'>{icon}</span>
              )
            })()
          } */}
          {(buttons || viewMoreLink) && (
            <ButtonGroup className="btn-group--icons">
              {viewMoreLink && (
                <Button
                  href={`https://hexometer.com/${viewMoreLink}`}
                  target="_blank"
                  color="secondary"
                  className="icon icon--right"
                  //outline
                  size="sm"
                >
                  <span className="lnr lnr-magic-wand" />{' '}
                  {title && typeof title === 'string' && title.indexOf('W3C') > -1 ? 'Details' : 'Check Again'}
                </Button>
              )}
              {buttons}
            </ButtonGroup>
          )}
          {rescan && (
            <Button
              onClick={handleRescan}
              color="secondary"
              className={`icon icon--right ${buttons || viewMoreLink ? 'm-0 ml-2' : ''}`}
              //outline
              size="sm"
              style={{ padding: '10px', margin: !(buttons || viewMoreLink) ? '0 0 0 auto' : '' }}
            >
              <span className="lnr lnr-redo" />
            </Button>
          )}
          {rightAddon}
          {openClose && (
            <p className="close__open">{<span className={!hideToolCard ? 'arrow-rotate' : ''}>{downArrow}</span>}</p>
          )}
        </div>
        {(!hideToolCard || !openClose) && (
          <div
            className={classNames('panel__content', {
              overflowAuto: title && typeof title === 'string' && title.indexOf('HTTPS') > -1,
            })}
            style={{ paddingLeft: noSidePadding ? 0 : '', paddingRight: noSidePadding ? 0 : '' }}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

export default ToolCard;
