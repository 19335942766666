// import { allowedDragSettings } from "constants";
import AutomationLongCard from 'pages/workflows/components/automation-long-card';
import AutomationSettingsFactory from '../../../../automation-settings';
import { listMenuIcon, loopIcon } from '../../../../assets/svg-icons/index';
import NothingToShow from 'shared-components/atom/nothing-to-show';
import Loader from 'shared-components/atom/loader';
import recipeIcon from '../../../../assets/icons/recipe.svg';
import { allowedDragSettings } from '../../../../constants';
import { useContext } from 'react';
import { UserContext } from 'contexts/user-context';
import SearchResultList from '../search-result-list';

const SelectedAutomationItem = ({
  value: item,
  index,
  theme,
  showAutomationSearch,
  onSelectAutomation,
  deleteAutomatinoOrRecipe,
  getShow,
  equalSelectedAutomationAndSettings,
  extractedRecipesFromSelectedAutomations,
  deeplLight,
  automationSendSettings,
  setCompletedFields,
  setAutomationSendSettings,
  onChangeTimeout,
  inputValue,
  isAutomationTab,
  elRef,
  automationsForInput,
  recipesForInput,
  publicRecipesForInput,
  loadingAutomationsInput,
  showSearch,
  noAddIcon,
  showDeletedRecipesInWorkflow,
  setShowDeletedRecipesInWorkflow,
  errorMessage,
  isSettingsChange,
  forZapier,
  setErrorMessage,
  setIsDirty,
  last,
  workflowId,
  lastUpdatedAutomations,
  resetLastUpdatedAutomations,
}: any) => {
  const id = item.recipe ? item.recipe.id : item.id;
  const type = !item.recipe ? 'automation' : item.recipe.is_public === false ? 'recipe' : 'public_recipe';
  const recipeActions = item.recipe && item.recipe.actions && JSON.parse(item.recipe.actions);
  const recipeOutputKeys: any = {};
  recipeActions?.pages?.length && recipeActions.pages[recipeActions.pages.length - 1] && recipeActions.pages[recipeActions.pages.length - 1].elements.map((item: any) => recipeOutputKeys[item.name] = item.type);

  return (
    <>
      {/* its need to show before automation if recipe deleted */}
      {showDeletedRecipesInWorkflow !== null && showDeletedRecipesInWorkflow.includes(-1) && (
        <AutomationLongCard
          title={`Deleted ${index} Recipe`}
          description={'Your recipe was deleted'}
          icon={''}
          noAddIcon={true}
          handleAutomationDelete={(indexNumber: number) => {
            setShowDeletedRecipesInWorkflow(showDeletedRecipesInWorkflow.filter((item: any) => item !== -1));
            // deleteAutomatinoOrRecipe({
              //   indexNumber: indexNumber - 1,
              //   index: index - 1,
              //   id: null,
              //   multi: item.multi,
              //   type: 'deleted',
              // })
            }  
          }
          recipe={{ name: `Deleted Recipe`, url: 'Your recipe was deleted' }}
          indexNumber={index}
          overflowUnset={true}
          // propShowSettings={false}
          minimizeIcon={false}
          dragable={false}
        ></AutomationLongCard>
      )}
      {!item?.createNewClicked && (
        <AutomationLongCard
          title={`${item.name ? item.name : ''}`}
          description={item.short_description}
          longDescription={item.recipe && item.recipe.description ? item.recipe.description : item.long_description}
          id={item.id}
          type={item.type && item.type.name}
          creditPrice={item.prices && (item.prices[0]?.credit_price || item.prices[1]?.credit_price)}
          premiumPrice={item.prices && (item.prices[0]?.premium_credit_price || item.prices[1]?.premium_credit_price)}
          icon={item.icon_url === 34 && theme === 'dark' ? deeplLight : item.icon_url}
          setAddNew={(indexNumber: number) => showAutomationSearch(index)}
          handleAutomationDelete={(indexNumber: number) => {
              deleteAutomatinoOrRecipe({ indexNumber, index, id: id, multi: item.multi, type: type })
            }
          }
          resetLastUpdatedAutomations={resetLastUpdatedAutomations}
          recipe={item.recipe}
          indexNumber={index}
          overflowUnset={true}
          // propShowSettings={getShow(item, index)}
          lastUpdatedAutomations={lastUpdatedAutomations}
          minimizeIcon={index === 0 && item.recipe ? false : true}
          dragable={allowedDragSettings.includes(id)}
          last={last}
          output_input={{ outputs: item.recipe && recipeOutputKeys ? JSON.stringify(recipeOutputKeys) : item.outputs, inputs: item.recipe ? 'URL' : item.inputs }}
        >
          {equalSelectedAutomationAndSettings && (
            <AutomationSettingsFactory
              id={id}
              automationIndex={index}
              selectedRecipes={extractedRecipesFromSelectedAutomations}
              automationSendSettings={automationSendSettings}
              setCompletedFields={setCompletedFields}
              setAutomationSendSettings={(val: any) => setAutomationSendSettings(val, index)}
              isRecipe={item.recipe}
              isSettingsChange={isSettingsChange}
              errorMessage={errorMessage}
              forZapier={forZapier}
              setErrorMessage={setErrorMessage}
              setIsDirty={setIsDirty}
              automationOutputTypes={item.outputs}
              automationInputTypes={item.inputs}
              workflowId={workflowId || ''}
            />
          )}
        </AutomationLongCard>
      )}
      {/* search block in automation list */}
      {((showSearch.index === index && showSearch.show) || item?.createNewClicked) && (
        <AutomationLongCard
          inputField
          handleInputValue={e => onChangeTimeout(e)}
          inputValue={inputValue}
          inputPlaceholder="Search..."
          icon={loopIcon}
          noAddIcon={noAddIcon}
          propShowSettings={!!inputValue}
          selectedRef={elRef}
        >
          {inputValue && inputValue.length >= 1 && (
            <>
              {(automationsForInput && automationsForInput.length > 0) ||
              (recipesForInput && recipesForInput.length > 0) ||
              (publicRecipesForInput && publicRecipesForInput.length > 0) ? (
                <SearchResultList
                  list={[automationsForInput, recipesForInput, publicRecipesForInput]}
                  theme={theme}
                  deeplLight={deeplLight}
                  automationSendSettings={automationSendSettings}
                  onSelectAutomation={onSelectAutomation}
                  index={index}
                />
              ) : (
                !loadingAutomationsInput && <NothingToShow />
              )}
              {loadingAutomationsInput && <Loader light={true} />}
            </>
          )}
        </AutomationLongCard>
      )}
      {/* its need to show after automation if recipe deleted */}
      {showDeletedRecipesInWorkflow !== null &&
        showDeletedRecipesInWorkflow.includes(index) &&
        !showDeletedRecipesInWorkflow.includes(-1) && (
          <AutomationLongCard
            title={'Deleted Recipe'}
            description={'Your recipe was deleted'}
            icon={''}
            noAddIcon={true}
            handleAutomationDelete={(indexNumber: number) => {
              setShowDeletedRecipesInWorkflow(showDeletedRecipesInWorkflow.filter((item: any) => item !== indexNumber));
              // deleteAutomatinoOrRecipe({ indexNumber, index, id: null, multi: item.multi, type: 'deleted' });
            }}
            recipe={{ name: 'Deleted Recipe', url: 'Your recipe was deleted' }}
            indexNumber={index}
            overflowUnset={true}
            // propShowSettings={false}
            minimizeIcon={false}
            dragable={false}
          />
        )}
    </>
  );
};

export default SelectedAutomationItem;
