import { FC, useState, useContext, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { UserContext } from 'contexts/user-context';

// import Loader from '../loader/index'
// import { AutomationCardsProps } from '../types';
import Select from 'shared-components/atom/select';
import Modal from 'shared-components/molecule/modal';
import Payment from 'shared-components/molecule/payment';
import CustomModal from 'shared-components/molecule/customModal';

import { favoriteIcon, tableActionsIcon } from 'assets/svg-icons';
import recipeIcon from 'assets/icons/image-placeholder.svg';
import Template from '../template';
import Tooltip from 'shared-components/atom/tooltip';
import ContactUs from 'shared-components/molecule/payment/contact-us';
import Button from 'shared-components/atom/button';
import '../../styles.scss';
import { eventStop } from 'helpers';
import { HexomaticUserContext } from 'contexts/hexomatic-user';
import MenuModal from 'pages/workflows/components/menu-modal';

const RecipeCards: FC<any> = ({
  options,
  onChange,
  title,
  description,
  id,
  checkbox,
  logo,
  isFavorite,
  handleMarkAsStarred,
  handleRemoveFromStarred,
  publicRecipies,
}) => {
  const { push } = useHistory();
  const { user } = useContext(UserContext);
  const { hexomaticUser } = useContext(HexomaticUserContext);
  // const [isOpen, setIsOpen] = useState(false);
  const [shouldUpgrade, setShouldUpgrade] = useState(false);
  const [buyPremiumCredits, setBuyPremiumCredits] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [openMenuModal, setOpenMenuModal] = useState(false);
  const location = useLocation();

  const handleClick = () => {
    // location.pathname.includes('scraping-public-recipes')
    //   ? setIsOpen(true)
    //   : 
      push(`/scraping-recipes/current-recipe/${id}`);
  };

  const handleShowPaymentMethods = () => {
    setShowPaymentModal(false);
    setBuyPremiumCredits(false);
    // setIsOpen(true);
  };

  const cancelClick = () => {
    setShowPaymentModal(false);
    setShouldUpgrade(false);
    // setIsOpen(true);
  };

  const actionClick = () => {
    buyPremiumCredits ? setShowPaymentModal(true) : (window.location.href = 'https://hexomatic.com/pricing/');
    setShouldUpgrade(false);
  };

  const handleUseInWorkflow = useCallback(
    (e: any) => {
      if (hexomaticUser.pricing_package === 'FREE') {
        setShouldUpgrade(true);
        return;
      }
      localStorage.setItem('workflow-recipe-id', `${id}`);
      push('workflows/create');
    },
    [id, hexomaticUser],
  );

  return (
    <>
      <div className={`recipe-card ${publicRecipies ? 'public' : ''}`}>
        <div className="image-block">
          <div className="action-btns-hover">
            <div className="d-flex">
              <Button name={'Use in a workflow'} onClick={handleUseInWorkflow} lightPink className="mr-2" />
              <Button name={'Edit recipe'} onClick={handleClick} lightPink />
            </div>
          </div>
          <div className={`img-wrapper ${logo ? '' : 'blank-img'}`}>
            <img src={logo || recipeIcon} />
          </div>
        </div>

        <div className="bottom-wrapper">
          <div className="d-flex" onClick={handleClick} style={{width: 'calc(100% - 30px)'}}>
            {!publicRecipies && (
              <div
                className="d-flex my-auto"
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  isFavorite
                  ? handleRemoveFromStarred && handleRemoveFromStarred(id)
                  : handleMarkAsStarred && handleMarkAsStarred(id)
                }
                 
                }
              >
                <Tooltip
                  trigger={['hover']}
                  placement="right"
                  text={
                    isFavorite
                      ? 'Click to remove from starred recipes list'
                      : 'Click to add into the starred recipies list'
                  }
                  className="mb-1"
                >
                  <span className={isFavorite ? 'favorite-icon-bg mr-2' : 'mr-2'}>{favoriteIcon}</span>
                </Tooltip>
              </div>
            )}
            <Tooltip placement="bottom" text={title} className="width-fit-content w-100">
              <p className="title text-truncate">{title}</p>
            </Tooltip>
          </div>

          {location.pathname.includes('scraping-recipes') && (
            <div className="action-block" onClick={() => setOpenMenuModal(!openMenuModal)}>
              {tableActionsIcon}
            </div>
          )}
        </div>

        {/* {isOpen && (
          <Template
            id={id}
            setIsOpen={setIsOpen}
            setShouldUpgrade={setShouldUpgrade}
            setBuyPremiumCredits={setBuyPremiumCredits}
          />
        )} */}
      </div>
      <Modal
        isModalOpen={shouldUpgrade}
        title={
          !buyPremiumCredits ? (
            'Please upgrade your plan to continue'
          ) : user.premium_credits_paypal_subscription_id &&
            (user.premium_credits_pricing_package === 'PC_26400' ||
              user.premium_credits_pricing_package === 'PC_264000') ? (
            <ContactUs showCloseIcon handleCloseModal={cancelClick} />
          ) : (
            'Please add premium credits to continue'
          )
        }
        parentClass={
          user.premium_credits_paypal_subscription_id &&
          (user.premium_credits_pricing_package === 'PC_26400' ||
            user.premium_credits_pricing_package === 'PC_264000') &&
          buyPremiumCredits
            ? 'contact_us_modal'
            : ''
        }
        actionBtnText={
          user.premium_credits_paypal_subscription_id &&
          (user.premium_credits_pricing_package === 'PC_26400' ||
            user.premium_credits_pricing_package === 'PC_264000') &&
          buyPremiumCredits
            ? ''
            : 'Upgrade'
        }
        cancelBtnText={
          user.premium_credits_paypal_subscription_id &&
          (user.premium_credits_pricing_package === 'PC_26400' ||
            user.premium_credits_pricing_package === 'PC_264000') &&
          buyPremiumCredits
            ? ''
            : 'Cancel'
        }
        actionBtnColor="warning"
        cancelBtnColor="secondary"
        onActionClick={actionClick}
        onCancelClick={cancelClick}
        onlyBtns={
          !!(
            buyPremiumCredits &&
            user.premium_credits_paypal_subscription_id &&
            (user.premium_credits_pricing_package === 'PC_26400' ||
              user.premium_credits_pricing_package === 'PC_264000')
          )
        }
        escButtonClick={() => setShouldUpgrade(false)}
      />
      {showPaymentModal && (
        <CustomModal ShowPopup={showPaymentModal} escButtonClick={() => setShowPaymentModal(false)}>
          <Payment
            name={''}
            selectedValue={{ title: '105/monthly', value: 105 }}
            price={0}
            premium_credit
            setShowPopup={handleShowPaymentMethods}
            // offer
          />
        </CustomModal>
      )}
       {openMenuModal && (
        <CustomModal ShowPopup={openMenuModal} noNavigate escButtonClick={() => setOpenMenuModal(false)}>
          <MenuModal
            setOptionParameters={(el: any) => {
              if (
                el.label === 'Share recipe' &&
                (hexomaticUser?.pricing_package === 'FREE')
              ) {
                setShouldUpgrade(true);
                return;
              }
              onChange({ value: el.value, label: el.label, url: el.url });
            }}
            menuOption={options}
            setShow={setOpenMenuModal}
          />
        </CustomModal>
      )}
    </>
  );
};

export default RecipeCards;
