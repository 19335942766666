import { FC, useState, useEffect, Suspense } from 'react';
import { ApolloProvider } from '@apollo/react-components';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

import { UserContext } from './contexts/user-context';
import { ThemeContext } from './contexts/theme-context';
import { HexomaticUserContext } from './contexts/hexomatic-user';
import { StateChangeContext } from './contexts/state-change-context';

import client from './graphql';
import Layout from './layout';
import Routes from './routes';
import { HexomaticUserType, StateChangeType, UserType } from './types';
import AuthLoader from './Authentication';
import { useThemeDetector } from 'hooks/useThemeDetect';

import './i18n';
import { getRawCookie, getUserToken } from 'helpers';
// import Loader from './shared-components/atom/loader';

// const MainLayout = lazy(() => import('./Layout'));
// import Router from './routes';

// Styles
// import 'bootstrap/dist/css/bootstrap.css';

// import {UserContext} from '../shared/contexts/UserContext';
// import {ThemeContext} from '../shared/contexts/ThemeContext';

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 3000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

/**
 * App functional component.
 * Application initial component. Checks user
 * state and detect initial route.
 * @return {JSX}
 */

function getInitialTheme(): string {
  const savedTheme = localStorage.getItem('theme');
  return savedTheme || 'light';
}

function getInitialSystemTheme(): string {
  const savedSystemTheme = localStorage.getItem('systemTheme');
  return savedSystemTheme || 'not_selected';
}

const App: FC = () => {
  const [user, setUser] = useState({} as UserType);
  const [theme, setTheme] = useState(getInitialTheme());
  const [systemTheme] = useState(getInitialSystemTheme());
  const [hexomaticUser, setHexomaticUser] = useState({} as HexomaticUserType);
  const [stateChange, setStateChange] = useState({} as StateChangeType);
  const [accessToken, setAccessToken] = useState('');
  const [showRoutes, setShowRoutes] = useState(false);
  const isSystemDark = useThemeDetector();

  useEffect(() => {
    if (isSystemDark && systemTheme === 'not_selected') {
      localStorage.setItem('theme', 'dark');
      localStorage.setItem('systemTheme', 'selected');
      setTheme('dark');
    }

    if (systemTheme === 'selected') {
      localStorage.setItem('theme', theme);
    }
  }, [isSystemDark]);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  useEffect(() => {
    if (getUserToken() === accessToken) {
      setShowRoutes(true);
    } else if (!getRawCookie('device_key') || !getRawCookie('ref_token') || !getRawCookie('__session')) {
      if (window.location.href.includes('current-workflow')) {
        localStorage.setItem('current-workflow', window.location.href);
      }
      if (window.location.href.includes('/workflows/create')) {
        localStorage.setItem('navigate-create-workflow', '/workflows/create');
      }
      if (window.location.href.includes('/pricing')) {
        localStorage.setItem('navigate-pricing', '/pricing');
      }
      if (window.location.href.includes('/black-friday-deal') || window.location.href.includes('/spring-break-promo')) {
        localStorage.setItem('promo-page-path', '/spring-break-promo');
      }
      if (!window.location.href.includes('/dev-login')) {
        setTimeout(() => {
          window.location.href =
            window.location.href.indexOf('localhost') > -1
              ? 'http://localhost:3000/dev-login'
              : window.location.href.indexOf('37.186.119.181') > -1
              ? 'http://37.186.119.181:3279/dev-login'
              : 'https://hexomatic.com/login/';
          return <></>;
        }, 0);
      }
    }
  }, [accessToken, getUserToken()]);

  return (
    <ApolloProvider client={client}>
      <AlertProvider template={AlertTemplate} {...options} className="alert-provider">
        <UserContext.Provider value={{ user, setUser }}>
          <HexomaticUserContext.Provider value={{ hexomaticUser, setHexomaticUser }}>
            <ThemeContext.Provider value={{ theme, setTheme }}>
              <AuthLoader setAccessToken={(token: string) => setAccessToken(token)}>
                {showRoutes && (
                  <StateChangeContext.Provider value={{ stateChange, setStateChange }}>
                    <div className={theme === 'dark' ? 'theme-dark' : 'theme-light'}>
                      <Suspense
                        fallback={<div className="html-background">{/* <Loader light={theme === 'light'} /> */}</div>}
                      >
                        <Layout>
                          <Routes />
                        </Layout>
                      </Suspense>
                    </div>
                  </StateChangeContext.Provider>
                )}
              </AuthLoader>
            </ThemeContext.Provider>
          </HexomaticUserContext.Provider>
        </UserContext.Provider>
      </AlertProvider>
    </ApolloProvider>
  );
};

export default App;
