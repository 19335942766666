import { FC, useState, useEffect, useContext, useCallback, useRef } from 'react';
import { useMutation, useQuery, useSubscription } from '@apollo/react-hooks';
import { WorkflowsRouteParams, WorkflowLogDataType } from '../../../types';
import { GET_WORKFLOWS_LOGS, GET_WORKFLOW_BY_ID, GET_WORKFLOW_RESULT_CSV } from 'graphql/queries';
import { getDateAndTimeWithoutUTC, getParsedDateAndTime } from '../../../helpers';
import Tabs from '../components/tabs';
import { ThemeContext } from '../../../contexts/theme-context';
import Loader from '../../../shared-components/atom/loader';

import { WORKFLOW_LOG_SUBSCRIPTION } from 'graphql/subscriptions';
import { isValidWorkflowName } from 'constants/validations';
import { UPDATE_WORKFLOW } from 'graphql/mutations';
import { HexomaticUserContext } from 'contexts/hexomatic-user';
import NothingToShow from 'shared-components/atom/nothing-to-show';
import EditableTextOpen from 'shared-components/molecule/editable-text-open';

import { dotIcon } from 'assets/svg-icons';

import './styles.scss';
import '../styles.scss';

const WorkFlowLogs: FC<WorkflowsRouteParams> = ({ match }) => {
  const { workflowId } = match.params;
  const [workflowInfo, setWorkflowInfo] = useState(null as any);
  const [workflowName, setWorkflowName] = useState('');
  const [logsList, setLogsList] = useState<WorkflowLogDataType[]>([]);
  const { theme } = useContext(ThemeContext);
  const [page, setPage] = useState(1);
  const [is, setIs] = useState(true);
  //@ts-ignore
  const [sort, setSort] = useState(localStorage.getItem('logs-sort') ? eval(localStorage.getItem('logs-sort')) : false);
  const [refetchLoading, setRefetchLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [logData, setLogData] = useState([] as any);
  const [selectedHeaders, setSelectedHeaders] = useState(null as any);

  const { hexomaticUser } = useContext(HexomaticUserContext);

  const { data: workflowData, refetch: workflowDataRefetch } = useQuery(GET_WORKFLOW_BY_ID, {
    skip: !workflowId,
    variables: {
      settings: {
        workflow_id: workflowId ? parseInt(workflowId) : -1,
      },
    },
  });

  const { data: workflowRealTimeLogData } = useSubscription(WORKFLOW_LOG_SUBSCRIPTION, {
    skip: !workflowId || !hexomaticUser?.id,
    variables: { workflowId: workflowId ? parseInt(workflowId) : -1, hexomaticUserId: hexomaticUser?.id },
  });
  const observer = useRef();

  const lastLogRef = useCallback(
    node => {
      if (refetchLoading) return;
      //@ts-ignore
      if (observer.current) observer.current.disconnect();
      //@ts-ignore
      observer.current = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting && hasMore) {
            setPage(prevPage => prevPage + 1);
          }
        },
        { threshold: 1 },
      );
      //@ts-ignore
      if (node) observer.current.observe(node);
    },
    [refetchLoading, hasMore],
  );

  useEffect(() => {
    if (workflowRealTimeLogData?.workflowLogEvent?.log) {
      const data = logsList ? [...logsList] : [];
      const reversed = sort
        ? workflowRealTimeLogData.workflowLogEvent.log.reverse()
        : workflowRealTimeLogData.workflowLogEvent.log;
      const realtimeRawList = reversed.map((item: any) => {
        const value = data.find(
          (originalListItem: any) =>
            new Date(parseInt(originalListItem.log.time)).getTime() === new Date(item.time).getTime() &&
            originalListItem.log.log === item.log,
        );
        return !value
          ? {
              log: {
                log: item.log,
                time: item.time.includes('Z') ? new Date(item.time).getTime() : new Date(parseInt(item.time)).getTime(),
                type: item.type,
              },
            }
          : null;
      });
      const realtimeList = realtimeRawList.filter((item: any) => item);
      const newList = sort ? realtimeList.concat(data) : data.concat(realtimeList);
      setLogsList(newList);
    }
  }, [workflowRealTimeLogData]);

  const { data, loading, refetch } = useQuery(GET_WORKFLOWS_LOGS, {
    fetchPolicy: refetchLoading ? 'no-cache' : 'network-only',
    variables: {
      settings: {
        workflow_id: workflowId ? parseInt(workflowId) : -1,
        filter: {
          page: page,
          limit: 50,
          sort_by: sort ? 'DESC' : 'ASC',
          sort_order: sort ? 'DESC' : 'ASC',
        },
      },
    },
  });

  // const [getCSV] = useLazyQuery(GET_WORKFLOW_RESULT_CSV, {
  //   onCompleted(csvdata) {
  //     const url = csvdata?.HexomaticWorkflow?.getWorkflowResultCSV?.csv_url;
  //     if (url) {
  //       window.open(url);
  //     }
  //   },
  // });

  const [updateWorkflow, { loading: updateWorkflowLoading }] = useMutation(UPDATE_WORKFLOW);

  useEffect(() => {
    if (workflowData?.HexomaticWorkflow?.getWorkflow?.workflow) {
      setWorkflowInfo(workflowData.HexomaticWorkflow.getWorkflow.workflow);
      setWorkflowName(workflowData.HexomaticWorkflow.getWorkflow.workflow.name);
      workflowData.HexomaticWorkflow.getWorkflow.workflow.selected_headers &&
        setSelectedHeaders(workflowData.HexomaticWorkflow.getWorkflow.workflow.selected_headers);
    }
  }, [workflowData]);

  useEffect(() => {
    if (data?.HexomaticWorkflow?.getWorkflowLogs?.logs) {
      setLogData(data?.HexomaticWorkflow?.getWorkflowLogs?.logs);
      setHasMore(data?.HexomaticWorkflow?.getWorkflowLogs?.logs?.length >= 50);
    }
  }, [data]);

  useEffect(() => {
    page === 1 ? setLogsList(logData) : setLogsList([...logsList, ...logData]);
  }, [logData]);

  useEffect(() => {
    logsList.length && logsList.length < page * 50 ? setIs(false) : setIs(true);
  }, [page, logsList]);

  const handelRefetch = async () => {
    setRefetchLoading(true);
    setPage(1);
    await refetch();
    setRefetchLoading(false);
  };

  const value = logsList.map((item: any, index) => {
    if (item.log && item.log.log) {
      return item.log ? `${index > 0 ? '\n' : ''} - ${getParsedDateAndTime(item.log.time)} - ${item.log.log}` : '';
    } else if (item.log && item.time) {
      return item.log ? `${index > 0 ? '\n' : ''} - ${getDateAndTimeWithoutUTC(item.time)} - ${item.log}` : '';
    }
  });

  const workflowNameUpdate = async ({ id, name }: any) => {
    if ((name && (!name.trim() || !isValidWorkflowName(name))) || !name) {
      return { message: 'Invalid name' };
    }
    if (!workflowId && !id) {
      setWorkflowName(name);
      return;
    }

    const result = await updateWorkflow({
      variables: {
        settings: {
          workflow_id: id,
          name: name && name.trim(),
          isUpdated: false,
        },
      },
    });
    if (result?.data?.HexomaticWorkflowOps?.updateWorkflow?.error_code) {
      const { error_code } = result.data.HexomaticWorkflowOps.updateWorkflow;
      //@ts-ignore
      if (errorMessages && errorMessages[error_code]) {
        if (error_code === 'ALREADY_EXISTS') {
          if (name) return { message: `"${name.length > 25 ? name.slice(0, 25) + '...' : name}" already exists` };
        } else {
          //@ts-ignore
          return { message: errorMessages[error_code] };
        }
      } else {
        return { message: error_code };
      }
    } else {
      setWorkflowName(name);
      return { message: null };
    }
  };

  const changeSortOption = () => {
    setPage(1);
    setSort(!sort);
    localStorage.setItem('logs-sort', String(!sort));
  };

  useEffect(() => {
    workflowDataRefetch();
  }, [workflowId]);

  return (
    <>
      <div className="workflow-header">
        {workflowName ? (
          <EditableTextOpen
            name={workflowName || ''}
            id={+workflowId}
            onSave={async ({ id, text }: any) => {
              return await workflowNameUpdate({ id, name: text });
            }}
            maxTextCount={64}
            absoluteError={true}
            errorMessageLength={50}
            propLoading={updateWorkflowLoading}
          />
        ) : (
          <div style={{ width: '1px', height: '33px' }}></div>
        )}
      </div>
      {workflowId && (
        <Tabs
          loading={!workflowData?.HexomaticWorkflow?.getWorkflow}
          status={workflowData?.HexomaticWorkflow?.getWorkflow?.workflow?.execution_data}
          selectedHeaders={selectedHeaders}
          setSelectedHeaders={setSelectedHeaders}
          workflowId={+workflowId}
          active={2}
          handelRefetch={handelRefetch}
          sortBy={sort}
          changeSortBy={changeSortOption}
        />
      )}
      <div className="workflow-logs-conatiner">
        {((loading && !(logsList && logsList.length > 0)) || refetchLoading) && <Loader light={theme === 'light'} />}
        {!refetchLoading && logsList && logsList.length > 0 ? (
          <div className="position-relative">
            <div className="logs-wrapper workflow">
              {logsList &&
                logsList.map((item: any, index: number) => {
                  if (logsList.length === index + 1) {
                    return (
                      <div className="d-flex" ref={lastLogRef}>
                        <div className="dotdash">
                          <div className="dot-dash">{dotIcon}</div>
                          <div className={logsList?.length !== 1 ? 'last-dash dash' : 'dash'}></div>
                        </div>
                        <div className="log-container">
                          <div className="data">{item?.log?.log ? item.log.log : typeof item.log === 'string' ? item.log : ''}</div>
                          <div className="date">{getParsedDateAndTime(item?.log?.log ? item.log.time : item.time)}</div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div className="d-flex">
                        <div className="dotdash">
                          <div className="dot-dash">{dotIcon}</div>
                          <div className="dash"></div>
                        </div>
                        <div className="log-container" ref={lastLogRef}>
                          <div className="data">{item?.log?.log ? item.log.log : typeof item.log === 'string' ? item.log : ''}</div>
                          <div className="date">{getParsedDateAndTime(item?.log?.log ? item.log.time : item.time)}</div>
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
            {!refetchLoading && loading && logsList && logsList.length > 0 && page !== 1 && (
              <div style={{ marginTop: '-50px' }}>
                <Loader light={theme === 'light'} />
              </div>
            )}
          </div>
        ) : (
          !loading && (
            <div className="workflow-logs-conatiner">
              <NothingToShow text="No logs found" />
            </div>
          )
        )}
      </div>
    </>
  );
};

export default WorkFlowLogs;
