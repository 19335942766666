import { useContext, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { ThemeContext } from 'contexts/theme-context';
import PaginationAndRowPerPage from 'shared-components/organizm/pagination-row-per-page';
import SearchContainer from 'shared-components/molecule/search-container';
import CardWrapper from './components/card';
import Actions from './components/actions';
import Loader from 'shared-components/atom/loader';
import NothingToShow from 'shared-components/atom/nothing-to-show';
import { Col, Row } from 'react-bootstrap';
import { GET_TASKS } from 'graphql/queries';

import './styles.scss';

interface ITasks {
  id: number;
  question: string;
  status: string;
}
const Tasks = () => {
  //----------------------Context----------------------------
  const { theme } = useContext(ThemeContext);
  //-----------------------State-----------------------------
  const [option, setOption] = useState<{ label: string; value: number | string | null }>(
    localStorage.getItem('tasksOption')
      ? //@ts-ignore
        JSON.parse(localStorage.getItem('tasksOption'))
      : { label: 'All', value: null },
  );
  const [typeOption, setTypeOption] = useState<{ label: string; value: number | string | null }>(
    localStorage.getItem('tasksTypeOption')
      ? //@ts-ignore
        JSON.parse(localStorage.getItem('tasksTypeOption'))
      : { label: 'All', value: null },
  );
  const [searchText, setSearchText] = useState('');
  const [startSearch, setStartSearch] = useState(setTimeout(() => {}));
  const [totalCount, setTotalCount] = useState(0);
  const [sort, setSort] = useState({
    by: 'DESC',
    id: 1,
  });
  const [page, setPage] = useState<number>(
    localStorage.getItem('tasksPage') ? Number(localStorage.getItem('tasksPage')) : 1,
  );
  //@ts-ignore
  const [limit, setLimit] = useState<{ label: string; value: number }>(
    //@ts-ignore
    localStorage.getItem('tasksLimit')
      ? //@ts-ignore
        JSON.parse(localStorage.getItem('tasksLimit'))
      : { label: '15', value: 15 },
  );
  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [tasks, setTasks] = useState<ITasks[]>([]);
  //---------------------Variables---------------------------
  const typeOptions = [
    {
      label: 'All',
      value: null,
    },
    {
      label: 'New',
      value: 'new',
    },
    {
      label: 'In progress',
      value: 'progress',
    },
    {
      label: 'Paused',
      value: 'paused',
    },
    {
      label: 'Archived',
      value: 'archived',
    },
    {
      label: 'Completed',
      value: 'completed',
    },
  ];
  const options = [
    {
      label: 'All',
      value: null,
    },
    {
      label: 'YES/NO',
      value: 'yes_no',
    },
    {
      label: 'Detailed answers',
      value: 'human_powered',
    },
  ];
  //---------------------Queries-----------------------------
  const { data, loading, refetch } = useQuery(GET_TASKS, {
    fetchPolicy: 'no-cache',
    variables: {
      settings: {
        filter: {
          status: typeOption.value,
          question: searchText,
          type: option.value && option.value,
        },
        page: page,
        pageSize: limit.value,
        order: sort.by,
        sortBy:
          sort.id === 1
            ? 'humanValidation_created_at'
            : sort.id === 2
            ? 'humanValidation_question'
            : sort.id === 3
            ? 'humanValidation_quantity'
            : sort.id === 4
            ? 'humanValidation_status'
            : 'humanValidation_automation_id',
      },
    },
  });

  //---------------------useEffects--------------------------
  useEffect(() => {
    if (data?.HexomaticHumanValidationsTasks?.getHumanValidationsTasks) {
      setTasks(data.HexomaticHumanValidationsTasks.getHumanValidationsTasks.tasks || []);
      setTotalCount(data.HexomaticHumanValidationsTasks.getHumanValidationsTasks.count || 0);
      setCheckedItems([]);
    }
  }, [data]);
  //---------------------Functions---------------------------
  const changeSelectedItems = (val: number) => {
    if (!!checkedItems.find((id: any) => id === val)) {
      setCheckedItems(checkedItems.filter((id: any) => id !== val));
      setCheckAll(false);
      return;
    }
    setCheckedItems([...checkedItems, val]);
    if (tasks.length === [...checkedItems, val].length) setCheckAll(true);
  };

  const handleSelectAll = (val: boolean) => {
    setCheckAll(val);
    val
      ? setCheckedItems(
          tasks.map(item => {
            return item.id;
          }),
        )
      : setCheckedItems([]);
  };

  const handelLimitChange = (value: any) => {
    value.label != limit.label && setLimit(value);
    if (value.value * page > totalCount) {
      setPage(Math.ceil(totalCount / value.value));
    }
  };

  const handelChangeStatus = (option: any) => {
    setOption(option);
    localStorage.setItem('tasksOption', JSON.stringify(option));
  };
  const handelChangeType = (option: any) => {
    setTypeOption(option);
    localStorage.setItem('tasksTypeOption', JSON.stringify(option));
  };
  return (
    <div className="tasks-page">
      <h4 className="page-title">Tasks</h4>
      <div className="mt-3 pb-1">
        <SearchContainer
          typeSelector
          options={options}
          typeOptions={typeOptions}
          selectedValue={option}
          selectedTypeValue={typeOption}
          handleSelectClick={handelChangeStatus}
          handleTypeSelectClick={handelChangeType}
          inputPlaceholder="Search a task"
          handleSearch={e => {}}
          handelKeyUp={e =>
            setStartSearch(setTimeout(() => e.target.value != searchText && setSearchText(e.target.value), 500))
          }
          handelKeyDown={() => clearTimeout(startSearch)}
        >
          <Actions checkedItems={checkedItems} refetch={refetch} />
        </SearchContainer>
      </div>
      {loading && tasks.length < 1 ? (
        <Row>
          <Col lg={8} xl={6} style={{ marginTop: '200px' }}>
            <Loader light={theme === 'light'} />
          </Col>
        </Row>
      ) : tasks.length > 0 ? (
        <>
          <CardWrapper
            sort={sort}
            setSort={setSort}
            checkAll={checkAll}
            setCheckAll={handleSelectAll}
            items={tasks}
            checkedItems={checkedItems}
            setCheckedItems={changeSelectedItems}
          />
          <PaginationAndRowPerPage
            limit={limit}
            page={page}
            title="tasks"
            count={totalCount}
            setPage={setPage}
            limitChange={handelLimitChange}
            loading={loading}
          />
        </>
      ) : (
        <Row>
          <Col lg={8} xl={6} style={{ marginTop: '200px' }}>
            <NothingToShow />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Tasks;
