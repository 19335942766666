import { FC, useCallback } from 'react';
import { closeIcon } from 'assets/svg-icons';
import ModalCard from 'shared-components/atom/modal-card';

import './styles.scss';

interface MenuModalProps {
  setShow: (arg: boolean) => void;
  menuOption: { label: string; icon: any; value: number; status?: number; includePremium?: boolean }[];
  setOptionParameters: (arg: any) => void;
}

const MenuModal: FC<MenuModalProps> = ({ setOptionParameters, menuOption, setShow }) => {
  //const handleClick = useCallback(() => {}, []);
  const handleSetShow = useCallback(() => {
    setShow(false);
  }, [setShow]);
  return (
    <div className="menu-modal-wrapper">
      <div className="close-button-wrapper">
        <button onClick={handleSetShow}>{closeIcon}</button>
      </div>
      <div className="modal-card-main-wrapper">
        {menuOption.map(
          (el: any) =>
            el && (
              <ModalCard
                key={el.label}
                onClick={() => {
                  setOptionParameters(el);
                  setShow(false);
                }}
                title={el.label}
                icon={el.icon}
              />
            ),
        )}
      </div>
    </div>
  );
};

export default MenuModal;
