import { ThemeContext } from 'contexts/theme-context';
import { useContext } from 'react';
import Loader from '../../atom/loader';
import './styles.scss';

const StretchLoading = () => {
  const {theme} = useContext(ThemeContext);
  return (
    <div className="stretch-loading">
      <Loader light={theme === 'light'} />
    </div>
  );
};

export default StretchLoading;
