import { useMutation } from '@apollo/react-hooks';
import { UserContext } from 'contexts/user-context';
import { LOG_OUT } from 'graphql/mutations';
import { useContext, useEffect } from 'react';
import {
  removeRefreshToken,
  removeRefreshHash,
  removeAccessToken,
  removeUserEmail,
  removeHideDash,
  removeUserSignedUpViaGoogle,
  getRawCookie,
  removeRawCookie,
  extensionIds,
} from 'helpers';

const sendUserDataToExtension = () => {
  //@ts-ignore
  if (window.chrome && window.chrome.runtime) {
    extensionIds.forEach(async (item: string) => {
      try {
        //@ts-ignore
        await window.chrome.runtime.sendMessage(item, { cookie: null, refToken: null, deviceKey: null });
      } catch {
        return;
      }
    });
  }

  (function () {
    document.dispatchEvent(
      new CustomEvent('extension-cookie-event', {
        detail: { cookie: null, refToken: null, deviceKey: null },
      }),
    );
  })();
};

export default () => {
  const { user } = useContext(UserContext);
  const [log_out] = useMutation(LOG_OUT);

  const handleLogOut = async (id: any) => {
    const { data } = await log_out({ variables: { id, device_key: getRawCookie('device_key') } });
    if (data && data.UserOps && data.UserOps.logout && !data.UserOps.logout.error) {
      removeRawCookie('__session');
      removeRawCookie('device_key');
      removeRawCookie('ref_token');
      removeRefreshToken();
      removeRefreshHash();
      removeAccessToken();
      removeUserEmail();
      removeHideDash();
      removeUserSignedUpViaGoogle();
      sendUserDataToExtension();
      if (!window.location.href.includes('/dev-login')) {
        window.location.href.indexOf('localhost') > -1
          ? window.location.replace('http://localhost:3000/dev-login')
          : window.location.href.indexOf('37.186.119.181') > -1
          ? window.location.replace('http://37.186.119.181:3279/dev-login')
          : window.location.replace('https://hexomatic.com/');
      }
    }
  };
  useEffect(() => {
    if (user && user.id) {
      handleLogOut(+user.id);
    }
  }, [user]);

  return <></>;
};
