import { automationOutputs, automationWithoutOutput } from '../../../constants';
import React, { FC, useEffect, useState, useContext, ReactElement, Children } from 'react';
import { createPortal } from 'react-dom';
import Markdown from 'shared-components/atom/react-markdown';
import { closeIcon } from '../../../assets/svg-icons';
import { ThemeContext } from '../../../contexts/theme-context';
import AutomationInputOutput from '../automation-input-output';
import './styles.scss';
import { closePopupOnEsc } from 'helpers';

const body = document.getElementsByTagName('body')[0];
const el = document.createElement('div');
el.setAttribute('id', 'popup');

interface InfoModalProps {
  isModalOpen: boolean;
  setShowModal: (bool: boolean) => void;
  description: string;
  title: string | ReactElement;
  id?: number;
  type?: string;
  creditPrice?: number | string;
  premiumPrice?: number | string;
  className?: string;
  children?: any;
  parentClass?: string;
  icon?: any;
  output_input: { outputs: any; inputs: any } | undefined;
  recipeName?: string;
}

const InfoModal: FC<InfoModalProps> = ({
  isModalOpen,
  setShowModal,
  description,
  title,
  id,
  type,
  creditPrice,
  premiumPrice,
  className,
  children,
  parentClass,
  icon,
  output_input,
  recipeName,
}) => {
  const [element, setElement] = useState<HTMLDivElement>(el);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    if (!isModalOpen) return;

    body.appendChild(element);
    document.body.style.overflow = 'hidden';
    return () => {
      const getEl = document.getElementById('popup');
      getEl && body.removeChild(getEl);
      document.body.style.overflow = 'unset';
    };
  }, [isModalOpen]);

  //Close Popup on Esc button click
  useEffect(() => {
    isModalOpen && closePopupOnEsc(() => setShowModal(false));
  }, [isModalOpen]);
  //@ts-ignore
  return isModalOpen
    ? createPortal(
        <>
          <div className={`overlay theme-${theme} ${parentClass ? parentClass : ''}`}>
            <div
              className={`modal-dialog--default custom-alert-block info-modal theme-${theme} ${
                parentClass ? parentClass : ''
              }`}
            >
              <div className="modal-header">
                <div>
                  {recipeName ? <>{recipeName} description</> : <div>How to use {title}</div>}
                  {type && (
                    <h6 className="m-0 credits-info">
                      {type && type ? `${type} | ` : ''}
                      {creditPrice ? `Credits: ${creditPrice} ` : ''}
                      {creditPrice && premiumPrice && !(id === 6 || id === 7 || id === 81 || id === 97) ? ' | ' : ''}
                      {premiumPrice && !(id === 6 || id === 7 || id === 81 || id === 97)
                        ? `Premium Credits: ${id === 125 ? 'Based on query volume' : premiumPrice}`
                        : ''}
                    </h6>
                  )}
                </div>

                <button
                  className="panel__btn"
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    setShowModal(false);
                  }}
                  type="button"
                >
                  {closeIcon}
                </button>
              </div>

              <div className="modal-content">
                {description && <Markdown text={description} />}
                {output_input && !automationWithoutOutput.find((el: number) => el === id) && (
                  <AutomationInputOutput
                    inputList={output_input.inputs ? output_input.inputs.split(',') : []}
                    outputList={
                      output_input.outputs && JSON.parse(output_input.outputs)
                        ? Object.keys(JSON.parse(output_input.outputs))
                        : //@ts-ignore
                        automationOutputs[id]
                        ? //@ts-ignore
                          automationOutputs[id]
                        : ['none']
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </>,
        element,
      )
    : null;
};

export default React.memo(InfoModal);
