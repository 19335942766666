import { ThemeContext } from 'contexts/theme-context';
import { eventStop, getDateAndTime } from 'helpers';
import {
  FC,
  Fragment,
  memo,
  useCallback,
  useContext,
  useState,
  SyntheticEvent,
  useMemo,
  useReducer,
  useEffect,
} from 'react';
import { useHistory } from 'react-router';
import { useMediaQuery } from 'react-responsive';
import Loader from 'shared-components/atom/loader';
import Tooltip from 'shared-components/atom/tooltip';
import TimeElement from 'shared-components/atom/show-time';
import { StatusEnums } from 'shared-components/atom/types';
import EditableText from 'shared-components/molecule/editable-text';
import recipeIcon from 'assets/icons/recipe.svg';
import deeplLight from 'assets/images/deepL-light.svg';
import WorklowItemTags from '../workflow-item-tags';
import ActionsModal from '../actions-modal';
import StatusBadge from '../status-badge';
import {
  clockIconWithoutBorder,
  sharedPeopleIcon,
  triggerIcon,
  closeIcon,
  clockIcon,
  downloadIcon,
  pause,
  play,
  storageIcon,
  tableActionsIcon,
} from 'assets/svg-icons';
import './styles.scss';
import WorkflowItemTags from '../workflow-item-tags';
import { Link } from 'react-router-dom';

interface ICard {
  hide?: boolean;
  item: any;
  returnWorkflowStatus: (
    item: any,
    val?: boolean,
  ) =>
    | 'progress'
    | 'completed'
    | 'error'
    | 'PAUSED'
    | 'DRAFT'
    | 'CREDIT_REACHED'
    | 'PREMIUM_CREDIT_REACHED'
    | 'STORAGE_REACHED'
    | 'CREDIT_STORAGE_REACHED';
  returnActiveStatus: (item: any, reverseActive?: boolean) => boolean;
  setShouldContinue: (val: any) => void;
  changeActive: (id: number, active: boolean, date?: string) => void;
  hoverText: (item: any) => any;
  handleUpdateWorkflowName: (val: { id: number; name?: string }) => void;
  openedId: number;
  setOpenedId: (id: number) => void;
  updateLoading: boolean;
  optionParameters: { label: string; value: number | string; status?: string; recipeIds?: any };
  id: number;
  setOpenExportModal: (val: boolean) => void;
  setSelectedWorkflowId: (id: number) => void;
  setSelectedHeaders: (val: any) => void;
  returnStorageUsage: (item: any) => string;
  getNamesForTooltip: (steps: any, count: number) => string;
  addTag?: (data: any) => void;
  deleteTagFromWorkflow?: (data: any) => void;
  allowingToAddTag?: boolean;
  handleFilterByTag?: (arg1: number, arg2: number) => void;
  returnShedule: (item: any) => string;
  selectedFilterTag?: any;
  isAdmin: boolean;
  setStartedAt: any;
  setScheduleConfig: any;
  setOptionParameters: any;
  realTimeStatusData: any;
  setTrigger: any;
  setselectedOutputKeys: any;
  setSendMail: any;
}
const WorkflowCard: FC<ICard> = ({
  item,
  hide,
  returnWorkflowStatus,
  returnActiveStatus,
  setShouldContinue,
  changeActive,
  hoverText,
  handleUpdateWorkflowName,
  openedId,
  setOpenedId,
  updateLoading,
  optionParameters,
  id,
  setOpenExportModal,
  setSelectedWorkflowId,
  setSelectedHeaders,
  returnStorageUsage,
  getNamesForTooltip,
  addTag,
  returnShedule,
  allowingToAddTag,
  deleteTagFromWorkflow,
  handleFilterByTag,
  selectedFilterTag,
  isAdmin,
  setScheduleConfig,
  setStartedAt,
  setOptionParameters,
  realTimeStatusData,
  setTrigger,
  setselectedOutputKeys,
  setSendMail,
}) => {
  const { theme } = useContext(ThemeContext);
  const { push } = useHistory();
  const [showActionModal, setShowActionModal] = useState(false);
  const [showTriggerActionModal, setShowTriggerActionModal] = useState(false);
  const [checkWidth, setCheckWidth] = useState(false);
  const [resize, setResize] = useReducer((val: boolean) => !val, false);

  const isMobile = useMediaQuery({ maxWidth: 1250 });

  useEffect(() => {
    const handleResize = () => {
      setResize();
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const width = useMemo(() => {
    const tableWidth = document.getElementById('workflow-cards');
    return tableWidth?.offsetWidth || 0;
  }, [document.getElementById('workflow-cards'), resize]);

  const tagsCount = useMemo(() => {
    const singleCard = document.getElementsByClassName('single-card-item');
    //@ts-ignore
    const width = singleCard && singleCard[0] && singleCard[0].offsetWidth;

    return width ? Math.round(width / 150) : isMobile ? 2 : 4;
  }, [width, isMobile, resize]);

  const handleOpenModal = useCallback(
    (e: any) => {
      eventStop(e);
      setShowActionModal(true);
    },
    [eventStop],
  );

  const handleRedirect = useCallback(() => {
    push(`/workflows/current-workflow/${item.id}`);
  }, [item]);

  const handlePlayPause = useCallback(
    (e: any) => {
      eventStop(e);
      if (returnWorkflowStatus(item) === StatusEnums.DRAFT) return;
      returnActiveStatus(item, true) && returnWorkflowStatus(item) === 'completed'
        ? setShouldContinue({ isOpen: true, status: returnWorkflowStatus(item), item: item })
        : changeActive(+item.id, returnActiveStatus(item, true));
    },
    [returnWorkflowStatus, returnActiveStatus, setShouldContinue, changeActive, item],
  );

  const handleSave = useCallback(
    ({ id, text }: any) => handleUpdateWorkflowName({ id, name: text }),
    [handleUpdateWorkflowName],
  );

  const handleDownload = useCallback(
    (e: any) => {
      eventStop(e);
      if (!(item && returnWorkflowStatus(item) === 'completed')) return;
      setOpenExportModal(true);
      setSelectedWorkflowId(item.id);
      setSelectedHeaders(item.selected_headers);
    },
    [returnWorkflowStatus, setOpenExportModal, setSelectedWorkflowId, item, setSelectedHeaders],
  );

  const showClockIcon = (next_scan_time: any, last_scan_time: any, frequency: string, id: number) => {
    const currentWorkflow = realTimeStatusData && realTimeStatusData[id];
    return (
      (frequency &&
        (currentWorkflow?.next_scan_time || next_scan_time) &&
        new Date(currentWorkflow?.next_scan_time || next_scan_time).getTime() > new Date().getTime()) ||
      ((currentWorkflow?.next_scan_time || next_scan_time) &&
        new Date(currentWorkflow?.next_scan_time || next_scan_time).getTime() > new Date().getTime())
    );
  };

  const handleOpenWorkflow = useCallback(() => {
    push(`/workflows/current-workflow/${item.id}`);
  }, [item]);

  const handleDisableTriggerModalOpen = useCallback((e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowTriggerActionModal(true);
  }, []);
  return (
    <>
      <div className={`workflow-card col-12 col-md-6 ${hide ? 'col-lg-6' : 'col-lg-4'}`}>
        <Link to={`/workflows/current-workflow/${item.id}`} className="single-card-item ">
          <div className="d-flex justify-content-between px-12 align-items-center el-one">
            <div className="d-flex name-wrap ">
              <span className="play-pause-icon m-vertical-auto ml-2 mr-2" onClick={handlePlayPause}>
                {id === item.id && updateLoading && optionParameters.label !== 'Restart workflow' ? (
                  <div className="loader-margin">
                    <Loader light={theme === 'light'} />
                  </div>
                ) : returnWorkflowStatus(item) !== StatusEnums.DRAFT ? (
                  <Tooltip placement="right" text={hoverText(item)} className="m-auto">
                    {returnActiveStatus(item) ? pause : play}
                  </Tooltip>
                ) : (
                  <div className="draft-play-icon-card">{play}</div>
                )}
              </span>
              <div className="w-100">
                <EditableText
                  absoluteError
                  key={item.id}
                  name={item.name}
                  id={item.id}
                  onSave={handleSave}
                  maxTextCount={64}
                  openedInfo={openedId}
                  setOpenInfo={setOpenedId}
                  addTag={addTag}
                  allowingToAddTag={allowingToAddTag}
                  showTooltip
                  viewOption="cardView"
                  checkWidth={checkWidth}
                  setCheckWidth={setCheckWidth}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-center automations-icons">
                {item.steps &&
                  item.steps.map(
                    (subItem: any, index: number) =>
                      index < (item.steps.length === 2 ? 2 : 1) &&
                      subItem.automation_icon_url && (
                        <Fragment key={subItem.automation_name}>
                          <Tooltip placement="left" text={subItem.automation_name}>
                            <div
                              className={`automation-card-icon ${
                                index === 0 && item.steps.length > 2 ? 'overlaping' : ''
                              }`}
                            >
                              <img
                                src={
                                  subItem.automation_id === 43
                                    ? recipeIcon
                                    : subItem.automation_id === 34 && theme === 'dark'
                                    ? deeplLight
                                    : subItem.automation_icon_url
                                }
                                alt="Workflow icon"
                                className={`${subItem.automation_id === 35 ? 'img_35' : ''}`}
                              />
                            </div>
                          </Tooltip>
                        </Fragment>
                      ),
                  )}
                {item.steps && item.steps.length > 2 && (
                  <Tooltip placement="left" text={getNamesForTooltip(item.steps, 1)}>
                    <div className="automation-card-icon overlaping">
                      <span>+{item.steps.length - 1}</span>
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>

          {item.tags ? (
            <div className="workflow-item-tags my-2">
              <WorkflowItemTags
                itemId={item.id}
                tagsCount={tagsCount}
                wiewType={hide ? 'card' : 'table'}
                propTags={item.tags.map((sub: any) => {
                  return {
                    ...sub,
                    active: !!selectedFilterTag.find((val: any) => +val?.value === sub.id),
                  };
                })}
                deleteAction={deleteTagFromWorkflow}
                handleFilterByTag={handleFilterByTag}
              />
            </div>
          ) : (
            <div className=""></div>
          )}

          <div className="workflow-card-icons">
            <div onClick={handleOpenWorkflow} className="text-center pl-1 m_w-30">
              <Tooltip text="Shared workflow " placement="right">
                <span className="shared-workflow-icon ">{item.token_to_share && sharedPeopleIcon}</span>
              </Tooltip>
            </div>

            <div onClick={handleOpenWorkflow} className="text-center pl-1 pr-1 clock-schedule">
              {item?.schedule_config?.triggers &&
              item.schedule_config.triggers[0] &&
              item.schedule_config.triggers[0].watchPropertyId ? (
                <Tooltip
                  text={isMobile ? item.schedule_config.triggers[0].name : ''}
                  placement="top"
                  className="schedule-hover"
                >
                  <div className="watch-trigger-wrapper-card text-truncate">
                    <span>{triggerIcon}</span>
                    <span className="ml-2 ">{item.schedule_config.triggers[0]?.name}</span>
                    <span className="ml-2 close-btn-wrapper" onClick={handleDisableTriggerModalOpen}>
                      {closeIcon}
                    </span>
                  </div>
                </Tooltip>
              ) : (item?.frequency || (item?.next_scan_time && new Date(item?.next_scan_time) > new Date())) &&
                ((returnShedule(item) && !item?.status) || item?.status === 'progress') ? (
                <Tooltip
                  text="Next scan time will be set after workflow completes"
                  placement="right"
                  className="schedule-hover"
                >
                  <span className="globe-icon-wrapper">{clockIconWithoutBorder}</span>
                </Tooltip>
              ) : showClockIcon(item?.next_scan_time, item?.last_scan_time, item?.frequency, item?.id) &&
                item?.status !== 'PAUSED' ? (
                <Tooltip
                  text={item?.next_scan_time && getDateAndTime(item?.next_scan_time)}
                  placement="right"
                  className="schedule-hover"
                >
                  <span className="globe-icon-wrapper">{clockIconWithoutBorder}</span>
                </Tooltip>
              ) : (
                item?.frequency &&
                item?.status === 'PAUSED' && (
                  <Tooltip text=" Workflow is paused" placement="top" className="schedule-hover">
                    <span className="globe-icon-wrapper">{clockIconWithoutBorder}</span>
                  </Tooltip>
                )
              )}
            </div>
          </div>
          <div className="single-card-item-footer d-flex">
            <div className="d-flex align-items-center mr-2">
              <div className="mr-2">
                <TimeElement time={item.created_at} tooltipPosition={'right'} />
              </div>
              <div className="d-flex align-items-center mr-2 secondary-color">{returnStorageUsage(item)}</div>
              <StatusBadge item={item} realTimeStatusData={realTimeStatusData} viewMode="cardView" />
            </div>
            <div className="position-relative icon-wrap ml-auto">
              {item && returnWorkflowStatus(item) === 'completed' ? (
                <Tooltip text="Export the result" placement="left">
                  <span
                    onClick={handleDownload}
                    className={`download-icon-wrapper${
                      item && returnWorkflowStatus(item) === 'completed' ? '-active cursor-pointer' : ''
                    }`}
                  >
                    {downloadIcon}
                  </span>
                </Tooltip>
              ) : (
                <span
                  onClick={handleDownload}
                  className={`download-icon-wrapper${
                    item && returnWorkflowStatus(item) === 'completed' ? '-active cursor-pointer' : ''
                  }`}
                >
                  {downloadIcon}
                </span>
              )}

              <span className="settings-icon cursor-pointer" onClick={handleOpenModal}>
                {tableActionsIcon}
              </span>
            </div>
          </div>
        </Link>
      </div>
      {showActionModal && (
        <ActionsModal
          item={item}
          isAdmin={isAdmin}
          setShowActionModal={setShowActionModal}
          setScheduleConfig={setScheduleConfig}
          setStartedAt={setStartedAt}
          setOptionParameters={setOptionParameters}
          returnActiveStatus={returnActiveStatus}
          returnWorkflowStatus={returnWorkflowStatus}
          setTrigger={setTrigger}
          setselectedOutputKeys={setselectedOutputKeys}
          setSendMail={setSendMail}
        />
      )}
    </>
  );
};
export default memo(WorkflowCard);
