import { FC } from 'react';
import { checkedIcon, closeIcon, editIcon } from 'assets/svg-icons';
import { ThemeContext } from 'contexts/theme-context';
import { useContext, useState } from 'react';
import AlertComponent from 'shared-components/atom/alert-message';
import Loader from 'shared-components/atom/loader';
import { isValidKeyword, isValidWorkflowName } from 'constants/validations';
import './styles.scss';

interface IRecipeName {
  optionParameters: {
    label: string;
    value: string | number;
  };
  id: number;
  name: string;
  setOptionParameters: (val: { label: string; value: string | number }) => void;
  refetchScrapingList: () => void;
  refetchRecipe?: any;
  updateRecipe?: any;
  updateRecipeLoading?: boolean;
  className?: string;
}

const RecipeName: FC<IRecipeName> = ({
  optionParameters,
  id,
  name,
  setOptionParameters,
  refetchRecipe,
  refetchScrapingList,
  updateRecipe,
  updateRecipeLoading,
  className,
}) => {
  const { theme } = useContext(ThemeContext);
  const [inputValue, setInputValue] = useState(name);
  const [alertMessage, setAlertMessage] = useState('');

  const eventStop = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleEnterKeyPress = (e: any, name?: string) => {
    if (e.key === 'Enter') {
      name !== inputValue ? handleUpdateRecipeName() : setOptionParameters({ label: '', value: '' });
    }
  };
  const handleUpdateRecipeName = async (recipeName?: string) => {
    if (
      (!inputValue && !recipeName) ||
      (recipeName && !isValidWorkflowName(recipeName)) ||
      (inputValue && !isValidKeyword(inputValue))
    ) {
      setAlertMessage('Invalid name');
      return;
    }

    const res = await updateRecipe({
      variables: {
        settings: {
          name: recipeName || inputValue,
          id: +optionParameters.value,
        },
      },
    });

    if (res?.data?.HexomaticScrapingRecipeOps?.updateScrapingRecipe?.error_code) {
      setAlertMessage(
        res?.data?.HexomaticScrapingRecipeOps?.updateScrapingRecipe?.error_code === 'ALREADY_EXISTS'
          ? 'Already exists'
          : 'Something went wrong',
      );
    } else {
      setOptionParameters({ value: '', label: '' });
      try {
        refetchRecipe && (await refetchRecipe());
        await refetchScrapingList();
      } catch (error) {}
    }
  };

  return (
    <>
      {!(optionParameters.value && optionParameters.label === 'UpdateName' && id === optionParameters.value) ? (
        <span className="pl-2 m-vertical-auto py-2 d-flex">
          <h5 className="text-truncate recipe-name-wrapper ml-0">{name}</h5>
          <span
            className="edit-icon"
            onClick={(e: any) => {
              eventStop(e);
              setInputValue(name);
              setOptionParameters({ label: 'UpdateName', value: id });
            }}
          >
            {editIcon}
          </span>
        </span>
      ) : (
        <span className="pl-2 m-vertical-auto no-wrap">
          <input
            className={`p-1 change-input ${className ? className : ''} ${className && alertMessage ? 'error' : ''}`}
            autoFocus={true}
            placeholder={name}
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            onClick={(e: any) => eventStop(e)}
            onKeyDown={e => handleEnterKeyPress(e, name)}
          />
          {alertMessage && (
            <span className="mr-2">
              <AlertComponent type="error" text={alertMessage} />
            </span>
          )}

          {updateRecipeLoading ? (
            <span className={`d-inline-block ${className ? '' : 'h-100'}`}>
              <Loader size="sm" light={theme === 'light'} className="d-inline-block m-0 p-0 align-middle" />
            </span>
          ) : (
            <>
              <span
                className="cursor-pointer mr-2"
                onClick={(e: any) => {
                  eventStop(e);
                  name !== inputValue ? handleUpdateRecipeName() : setOptionParameters({ value: '', label: '' });
                }}
              >
                {checkedIcon}
              </span>
              <span
                className="cursor-pointer"
                onClick={(e: any) => {
                  eventStop(e);
                  setOptionParameters({ value: '', label: '' });
                  setInputValue('');
                  setAlertMessage('');
                }}
              >
                {closeIcon}
              </span>
            </>
          )}
        </span>
      )}
    </>
  );
};

export default RecipeName;
