import { FC, useContext, useEffect, useState } from 'react';
import { Modal, Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import Input from 'shared-components/atom/input';
import Alert from 'shared-components/atom/alert-message';
import { closeIcon, infoIcon } from 'assets/svg-icons';
import { ThemeContext } from 'contexts/theme-context';
import cropWidth from '../../../assets/images/crop-width.png';
import cropHeight from '../../../assets/images/crop-height.png';
import cropX from '../../../assets/images/crop-x.png';
import cropY from '../../../assets/images/crop-y.png';
import cropFull from '../../../assets/images/crop-full.png';

const imageSaveOptions = [
  { label: 'Default', value: 'default' },
  { label: 'PNG', value: 'png' },
  { label: 'JPEG', value: 'jpeg' },
  { label: 'JPG', value: 'jpg' },
  { label: 'WebP', value: 'webp' },
  { label: 'GIF', value: 'gif' },
];

const widthHeightOptions = [
  { label: 'full', value: 'full' },
  { label: 'px', value: 'px' },
  { label: 'percent', value: 'percent' },
];

const positionOptions = [
  { label: 'px', value: 'px' },
  { label: 'percent', value: 'percent' },
  { label: 'center', value: 'center' },
];

const infoImages = {
  width: cropWidth,
  height: cropHeight,
  x: cropX,
  y: cropY,
};

const ImageCrop: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const { theme } = useContext(ThemeContext);
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);
  const [clicked, setClicked] = useState(false);
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [selectedSaveAsOption, setSelectedSaveAsOption] = useState({ label: 'Default', value: 'default' });
  const [imageModal, setImageModal] = useState({ show: false, type: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const [coordinates, setCoordinates] = useState({
    width: {
      option: 'px',
      value: 1 as any,
    },
    height: {
      option: 'px',
      value: 1 as any,
    },
    x: {
      option: 'px',
      value: 0 as any,
    },
    y: {
      option: 'px',
      value: 0 as any,
    },
  });

  const {
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    liftingSettings,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if ((coordinates?.width?.value === 0 || coordinates?.width?.value === '0') && coordinates?.width?.option !== 'full') {
      setErrorMessage('Crop width should be greater than 0');
      return false;
    } else if (
      (coordinates?.height?.value === 0 || coordinates?.height?.value === '0') &&
      coordinates?.height?.option !== 'full'
    ) {
      setErrorMessage('Crop width should be greater than 0');
      return false;
    } else if (
      !selectedSource ||
      !selectedSaveAsOption ||
      (coordinates?.width?.option !== 'full' && !coordinates?.width?.value) ||
      (coordinates?.height?.option !== 'full' && !coordinates?.height?.value) ||
      (coordinates?.x?.option !== 'center' && coordinates?.x?.value === '') ||
      (coordinates?.y?.option !== 'center' && coordinates?.y?.value === '')
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings && fieldList.length > 0) {
      const keys = dynamic_settings.keys;
      const { key, settings } = keys[0];
      const selectedValue = settings && settings.type;
      settings &&
        setCoordinates({
          width: settings.width,
          height: settings.height,
          y: settings.y,
          x: settings.x,
        });
      const selectedType = imageSaveOptions.find(item => item.value === selectedValue);
      selectedType && setSelectedSaveAsOption(selectedType);
      restoreSelectedSource({ key: key, fieldList: fieldList, setSelectedSource: setSelectedSource });
    }
  }, [fieldList]);

  //recipe fields or output fields
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...['_croped_image']],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);

  //lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: {
        keys: [
          {
            key: fieldList.length && selectedSource ? selectedSource.value : null,
            inputValue: null,
            settings: {
              y: { option: coordinates.y.option, value: coordinates.y.value ? +coordinates.y.value : 0 },
              x: { option: coordinates.x.option, value: coordinates.x.value ? +coordinates.x.value : 0 },
              width: {
                option: coordinates.width.option,
                value: coordinates.width.value ? +coordinates.width.value : 1,
              },
              height: {
                option: coordinates.height.option,
                value: coordinates.height.value ? +coordinates.height.value : 1,
              },
              type: selectedSaveAsOption.value,
            },
          },
        ],
      },
      outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...['_croped_image']],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [selectedSource, coordinates, selectedSaveAsOption, automationOutputTypes, fieldList, fieldListWithTypes]);

  const handleInputChange = (type: 'width' | 'height' | 'x' | 'y', val: number) => {
    let value = val && val < 0 ? val * -1 : val;
    coordinates[type].option === 'percent' && value > 100 && (value = 100);
    coordinates[type].option === 'px' && value > 10000 && (value = 10000);

    setCoordinates({
      ...coordinates,
      [type]: {
        ...coordinates[type],
        value: Math.round(value),
      },
    });
  };

  const handleOptionChange = (type: 'width' | 'height' | 'x' | 'y', val: string) => {
    setCoordinates({
      ...coordinates,
      [type]: { ...coordinates[type], option: val },
    });
  };

  return (
    <>
      <div className="">
        <Row>
          <Col lg={6} className="mb-4">
            <span className="label secondary-color">Source</span>
            <Select
              fullWidth
              options={fieldList}
              value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
              placeholder={'Select source'}
              onChange={(selectedItem: any) => {
                setSelectedSource(selectedItem);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
          <Col lg={6} className="mb-4">
            <div className="select-block">
              <span className="label secondary-color">Save as</span>
              <Select
                fullWidth
                options={imageSaveOptions}
                value={selectedSaveAsOption}
                placeholder={'Select type'}
                onChange={(val: any) => {
                  setSelectedSaveAsOption(val);
                  !clicked && setClicked(true);
                  setIsDirty();
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Row>
              <Col md={6} className="mb-4">
                <span className="label secondary-color">
                  Crop Width{' '}
                  <span onClick={() => setImageModal({ show: true, type: 'width' })} className="cursor-pointer">
                    {infoIcon}
                  </span>
                </span>
                <Input
                  value={coordinates.width.value}
                  placeholder={''}
                  type="number"
                  onChange={e => {
                    setErrorMessage('');
                    handleInputChange('width', e.target.value);
                    !clicked && setClicked(true);
                    setIsDirty();
                  }}
                  disabled={coordinates.width.option === 'full'}
                />
              </Col>
              <Col md={6} className="mb-4">
                <span className="label secondary-color invisible">type</span>
                <Select
                  fullWidth
                  options={widthHeightOptions}
                  value={{ label: coordinates.width.option, value: coordinates.width.option }}
                  placeholder={'Select type'}
                  onChange={(val: any) => {
                    handleOptionChange('width', val.value);
                    !clicked && setClicked(true);
                    setIsDirty();
                  }}
                  textDefault
                />
              </Col>
              <Col md={6} className="mb-4">
                <span className="label secondary-color">
                  Crop Height
                  <span onClick={() => setImageModal({ show: true, type: 'height' })} className="cursor-pointer">
                    {infoIcon}
                  </span>
                </span>
                <Input
                  value={coordinates.height.value}
                  placeholder={''}
                  type="number"
                  onChange={e => {
                    setErrorMessage('');
                    handleInputChange('height', e.target.value);
                    !clicked && setClicked(true);
                    setIsDirty();
                  }}
                  disabled={coordinates.height.option === 'full'}
                />
              </Col>
              <Col md={6} className="mb-4">
                <span className="label secondary-color invisible">type</span>
                <Select
                  fullWidth
                  options={widthHeightOptions}
                  value={{ label: coordinates.height.option, value: coordinates.height.option }}
                  placeholder={'Select type'}
                  onChange={(val: any) => {
                    handleOptionChange('height', val.value);
                    !clicked && setClicked(true);
                    setIsDirty();
                  }}
                  textDefault
                />
              </Col>
              <Col md={6} className="mb-4">
                <span className="label secondary-color">
                  Crop Position X{' '}
                  <span onClick={() => setImageModal({ show: true, type: 'x' })} className="cursor-pointer">
                    {infoIcon}
                  </span>
                </span>
                <Input
                  value={coordinates.x.value}
                  placeholder={''}
                  type="number"
                  onChange={e => {
                    handleInputChange('x', e.target.value);
                    !clicked && setClicked(true);
                    setIsDirty();
                  }}
                  disabled={coordinates.x.option === 'center'}
                />
              </Col>
              <Col md={6} className="mb-4">
                <span className="label secondary-color invisible">type</span>
                <Select
                  fullWidth
                  options={positionOptions}
                  value={{ label: coordinates.x.option, value: coordinates.x.option }}
                  placeholder={'Select type'}
                  onChange={(val: any) => {
                    handleOptionChange('x', val.value);
                    !clicked && setClicked(true);
                    setIsDirty();
                  }}
                  textDefault
                />
              </Col>
              <Col md={6} className="mb-4">
                <span className="label secondary-color">
                  Crop Position Y{' '}
                  <span onClick={() => setImageModal({ show: true, type: 'y' })} className="cursor-pointer">
                    {infoIcon}
                  </span>
                </span>
                <Input
                  value={coordinates.y.value}
                  placeholder={''}
                  type="number"
                  onChange={e => {
                    handleInputChange('y', e.target.value);
                    !clicked && setClicked(true);
                    setIsDirty();
                  }}
                  disabled={coordinates.y.option === 'center'}
                />
              </Col>
              <Col md={6} className="mb-4">
                <span className="label secondary-color invisible">type</span>
                <Select
                  fullWidth
                  options={positionOptions}
                  value={{ label: coordinates.y.option, value: coordinates.y.option }}
                  placeholder={'Select type'}
                  onChange={(val: any) => {
                    handleOptionChange('y', val.value);
                    !clicked && setClicked(true);
                    setIsDirty();
                  }}
                  textDefault
                />
              </Col>
            </Row>
          </Col>
          <Col lg={6} style={{ maxWidth: '100%' }} className="d-flex">
            <img src={cropFull} style={{ width: '100%', height: 'auto', maxWidth: '600px' }} className="m-auto" />
          </Col>
        </Row>
        {errorMessage && (
          <Row className="mb-4">
            <Col md={12}>
              <Alert type="error" className="create-workflow">
                {errorMessage}
              </Alert>
            </Col>
          </Row>
        )}
      </div>

      <Modal
        show={imageModal.show}
        toggle={() => {}}
        className={`crop-image-popup modal-dialog--header theme-${theme}`}
      >
        <button
          className="panel__btn"
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            setImageModal({ show: false, type: '' });
          }}
          type="button"
        >
          {closeIcon}
        </button>
        <div className="modal__body" style={{ padding: '30px' }}>
          {
            //@ts-ignore
            <img src={infoImages[imageModal.type]} style={{ maxWidth: '100%', height: 'auto', width: '100%' }} />
          }
        </div>
      </Modal>
    </>
  );
};

export default ImageCrop;
