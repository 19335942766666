import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
import TextArea from 'shared-components/atom/texatarea';
import CustomMultiSelect from 'shared-components/molecule/multiselect';
import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import Checkbox from 'shared-components/atom/checkbox';

const ChatGPT4: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const promptConstOptions = [
    { label: 'Custom prompt', value: '' },
    { label: 'Summarize this text into bullet points', value: 'Summarize this text into bullet points:' },
    {
      label: 'Summarize the key information from this text into one sentence without using any bullet points:',
      value: 'Summarize the key information from this text into one sentence without using any bullet points:',
    },
    {
      label:
        'Rewrite this text to make it unique and undetectable by plagiarism checkers, whilst using a similar writing style:',
      value:
        'Rewrite this text to make it unique and undetectable by plagiarism checkers, whilst using a similar writing style:',
    },
    {
      label:
        'Rewrite this text to improve clarity and use copywriting formulas such as AIDA or PAS if appropriate to the context:',
      value:
        'Rewrite this text to improve clarity and use copywriting formulas such as AIDA or PAS if appropriate to the context:',
    },
  ];
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);
  const [clicked, setClicked] = useState(false);
  const [selectedSources, setSelectedSources] = useState<{ label: string; value: string }[]>([]);
  const [promptOption, setPromptOption] = useState({ label: 'Custom prompt', value: '' });
  const [promptText, setPromptText] = useState('');
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);
  const [values, setValues] = useState<any>([]);
  const [fullData, setFullData] = useState(false);

  const {
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    liftingSettings,
    restoreSelectedSource,
    outputFormat,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (!selectedSources?.length || !promptText?.trim()) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings && fieldList.length > 0) {
      const { key, settings } = dynamic_settings.keys[0];
      if (settings) {
        const { prompt } = settings;
        const promptObj = promptConstOptions.find(item => item.value === prompt);
        prompt && setPromptText(prompt);
        typeof settings.fullData === 'boolean' && setFullData(settings.fullData);

        prompt && promptObj
          ? setPromptOption(promptObj)
          : setPromptOption({
              label: 'Custom prompt',
              value: '',
            });
      }

      restoreSelectedSource({
        key: Array.isArray(key) ? key.filter((item: any) => fieldList.find(sub => sub.value === item)) : key,
        fieldList: fieldList,
        setSelectedSource: val => (!Array.isArray(val) ? setSelectedSources([val]) : setSelectedSources(val)),
      });
    } else if (fieldList?.length && !dynamic_settings) {
      setSelectedSources([]);
    }
  }, [fieldList, automationSendSettings.length]);

  //recipe fields or output fields
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;

    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    let fieldsArr: any = [];
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings && dynamic_settings.keys) {
      const { keys } = dynamic_settings;
      const recreateSelectedFields = keys.map((item: any) => {
        fieldsArr.push(item.key);
        const outputDataType = item.settings ? item.settings.outputDataType : item.outputDataType;
        return {
          key: item.key,
          value: outputDataType,
        };
      });
      const getValues = options.filter(
        (option: { label: string; value: string }) =>
          fieldsArr.includes(option.value) || fieldsArr.includes(option.label),
      );
      setValues(getValues);
    }
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSources(fieldList);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const settings = dynamic_settings?.keys?.[0]?.settings;
    const update = filteredOptions.length !== fieldList.length || changed;
    if (!settings) {
      setPromptText('');
      setFullData(false);
      setPromptOption({ label: 'Custom prompt', value: '' });
    }
    if (update && filteredOptions?.length) {
      if (dynamic_settings?.keys?.length > 1) {
        const filteredValue = filteredOptions?.filter((item: any) =>
          dynamic_settings?.keys.find((sub: any) => item.value === sub.key),
        );

        setSelectedSources(filteredValue || []);
        setClicked(true);
        setFieldList(filteredOptions);
        setFieldListWithTypes(optionsWithTypes);
      } else {
        const isArr = Array.isArray(dynamic_settings?.keys?.[0]?.key);
        const foundValue = !isArr
          ? filteredOptions?.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value)
          : null;
        const filteredValue = isArr
          ? filteredOptions?.filter((item: any) =>
              dynamic_settings?.keys?.[0]?.key.find((sub: any) => item.value === sub),
            )
          : [];
        const selOptionFound = isArr ? filteredValue : foundValue ? [foundValue] : [];

        setSelectedSources(selOptionFound);
        setClicked(true);
        setFieldList(filteredOptions);
        setFieldListWithTypes(optionsWithTypes);
      }
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const newVals = [
        outputFormat('', '_prompt_ChatGPT_4', automationIndex),
        outputFormat('', '_ChatGPT_4', automationIndex),
      ];
      const jsonParse: any = {};
      newVals.map((key: any) => (jsonParse[key] = 'text'));

      liftingSettings({
        outputKeysData: [
          ...fieldList.map((item: { value: any }) => item.value),
          outputFormat('', '_prompt_ChatGPT_4', automationIndex),
          outputFormat('', '_ChatGPT_4', automationIndex),
        ],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, fieldListWithTypes, automationOutputTypes, promptOption, selectedSources]);

  //lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);
    const labels = selectedSources?.map(item => item.label);
    const values = selectedSources?.map(item => item.value);
    const vals = labels?.length && values.length ? [{ label: labels.join(' '), value: values.join('_') }] : [];
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) || {} : {};

    const newVals =
      vals && vals.length
        ? [outputFormat('', '_prompt_ChatGPT_4', automationIndex), outputFormat('', '_ChatGPT_4', automationIndex)]
        : [];

    newVals && newVals.length && newVals.map((key: any) => (jsonParse[key] = 'text'));

    liftingSettings({
      dynamicSettings: {
        keys: [
          {
            key: fieldList.length && selectedSources?.length ? selectedSources.map((item: any) => item.value) : null,
            settings: {
              prompt: promptText,
              fullData: fullData,
            },
          },
        ],
      },
      outputKeysData: [
        ...fieldList.map((item: { value: any }) => item.value),
        outputFormat('', '_prompt_ChatGPT_4', automationIndex),
        outputFormat('', '_ChatGPT_4', automationIndex),
      ],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [selectedSources, fieldList, fieldListWithTypes, automationOutputTypes, promptOption, promptText, fullData]);

  return (
    <Row>
      <Col lg={6} className="mb-3">
        <span className="label secondary-color">Source</span>
        <CustomMultiSelect
          options={fieldList}
          value={selectedSources}
          onChange={(sel: any) => {
            setSelectedSources(sel);
            !clicked && setClicked(true);
            setIsDirty();
          }}
          labelledBy="Select"
        />
        <div className="mt-1">
          <Checkbox
            checked={fullData}
            label="Run on entire selection"
            onChange={() => {
              setFullData(!fullData);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </div>
      </Col>
      <Col lg={6} className="checkbox-wrapper mt-0">
        <span className="label secondary-color">Prompt Options</span>
        <Select
          fullWidth
          options={promptConstOptions}
          value={promptOption}
          placeholder={'Select'}
          onChange={(custom: any) => {
            setPromptOption(custom);
            setPromptText(custom.value);
            !clicked && setClicked(true);
            setIsDirty();
          }}
        />
      </Col>

      <Col lg={12} className="checkbox-wrapper mt-0">
        <span className="label secondary-color">Prompt</span>
        <TextArea
          value={promptText}
          onChange={(e: any) => {
            setPromptText(e.target.value);
            setPromptOption(promptConstOptions[0]);
            !clicked && setClicked(true);
            setIsDirty();
          }}
          placeholder="Enter Prompt"
        />
      </Col>
    </Row>
  );
};

export default ChatGPT4;
