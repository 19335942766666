import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import CustomMultiSelect from 'shared-components/molecule/multiselect';
import Select from 'shared-components/atom/select';
import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import Checkbox from 'shared-components/atom/checkbox';
import Switcher from 'shared-components/molecule/switcher';
import './styles.scss';

const extractLinksOptions = [
  { label: ' internal links', value: '_internal_links' },
  { label: ' external links', value: '_external_links' },
  { label: ' anchor text', value: '_anchor_text' },
];

const ExtractLinksFromAPageSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [selected, setSelected] = useState<any>([]);
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);
  const [clicked, setClicked] = useState(false);
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [includeSourceLinks, setIncludeSourceLinks] = useState(false);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);
  const [selectedMode, setSelectedMode] = useState('Standard');

  const {
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    liftingSettings,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (selected?.length === 0 || !selectedSource) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const { predefined_settings, dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings && dynamic_settings?.keys[0]?.settings) {
      // new structure
      const { options, include_source_links, fast } = dynamic_settings.keys[0].settings;
      setSelectedMode(fast ? 'Fast' : 'Standard');
      const selectedOptions = options.map((option: string) => {
        const finded = extractLinksOptions.find(item => item.value === option || item.label === option);
        return finded ? finded : { label: option, value: option };
      });
      selectedOptions && setSelected(selectedOptions);
      setIncludeSourceLinks(!!include_source_links);
    } else if (predefined_settings) {
      // old sturcture
      const selectedOptions = predefined_settings.options.map((option: string) => {
        const finded = extractLinksOptions.find(item => item.value === option || item.label === option);
        return finded ? finded : { label: option, value: option };
      });
      selectedOptions && setSelected(selectedOptions);
      setIncludeSourceLinks(!!predefined_settings.include_source_links);
    }
    if (dynamic_settings && fieldList.length > 0) {
      const key = dynamic_settings.keys[0].key;

      restoreSelectedSource({ key: key, fieldList: fieldList, setSelectedSource: setSelectedSource });
    }
  }, [fieldList]);

  //recipe fields or output fields
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [
          ...fieldList.map((item: { value: any }) => item.value),
          ...(selected.filter(
            (item: { value: string }) => item.value === '_internal_links' || item.value === '_external_links',
          )?.length === 2
            ? selected.length === extractLinksOptions.length
              ? ['_all_links', '_link_type', '_anchor_text']
              : ['_all_links', '_link_type']
            : selected.map((social: { value: any }) => social.value)),
        ],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, fieldListWithTypes, automationOutputTypes, selected, extractLinksOptions]);

  //lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);

    let localSettings = {
      options: selected.map((item: { value: any }) => item.value),
      include_source_links: includeSourceLinks,
      fast: selectedMode === 'Fast',
    };
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: {
        keys: [
          {
            key: fieldList.length && selectedSource ? selectedSource.value : null,
            inputValue: null,
            settings: localSettings,
          },
        ],
      },
      outputKeysData: [
        ...fieldList.map((item: { value: any }) => item.value),
        ...(selected.filter(
          (item: { value: string }) => item.value === '_internal_links' || item.value === '_external_links',
        )?.length === 2
          ? selected.length === extractLinksOptions.length
            ? ['_all_links', '_link_type', '_anchor_text']
            : ['_all_links', '_link_type']
          : selected.map((social: { value: any }) => social.value)),
      ],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [selected, selectedSource, includeSourceLinks, fieldList, fieldListWithTypes, automationOutputTypes, selectedMode]);

  const handleSwitcherClick = (val: string) => {
    setSelectedMode(val);
    !clicked && setClicked(true);
    setIsDirty();
  };

  return (
    <div className="">
      <Row>
        <Col md={12} className="d-flex">
          <Switcher
            leftText="Fast"
            rightText="Standard"
            handleSwitcherClick={handleSwitcherClick}
            selectedValue={selectedMode}
            hoverTextLeft="Loads pages faster but may have a lower success rate."
            // hoverTextRight="Loads pages slower but may have a higher success rate."
          />
        </Col>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Source</span>
          <Select
            fullWidth
            options={fieldList}
            value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
            placeholder={'Select source'}
            onChange={(selectedItem: any) => {
              setSelectedSource(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col lg={6}>
          <span className="label secondary-color">Select links type</span>
          <CustomMultiSelect
            options={extractLinksOptions}
            value={selected}
            onChange={(sel: any) => {
              setSelected(sel);
              !clicked && setClicked(true);
              setIsDirty();
            }}
            labelledBy="Select"
          />
        </Col>
        <Col lg={6} className="col-block">
          <div className="item-block">
            <Checkbox
              checked={includeSourceLinks}
              label="Include source links"
              onChange={() => {
                setIncludeSourceLinks(!includeSourceLinks);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ExtractLinksFromAPageSettings;
