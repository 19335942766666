import { FC } from 'react';
import { getParsedDate } from 'helpers';

interface IUserInfo {
  className: string;
  name: string;
  email: string;
}

interface IUserFullInfo {
  info: any;
  isCreator?: boolean;
  count?: number;
}

const UserInfo: FC<IUserInfo> = ({ className, name, email }) => (
  <div className={`position-absolute user-info ${className ? className : ''}`}>
    <div>
      User name: <span>{name}</span>
    </div>
    <div>
      <span>Email:</span> {email}
    </div>
  </div>
);

const UserFullInfo: FC<IUserFullInfo> = ({ info, isCreator, count }) => (
  <>
    {isCreator ? (
      <div className="task-information">
        <div className="position-relative">
          Task creator:{' '}
          <div className="creator d-inline cursor-pointer">
            {info?.userInfo?.creator?.firstName} {info?.userInfo?.creator?.lastName}
            <UserInfo
              className="creator-info"
              name={`${info?.userInfo?.creator?.firstName + ' ' + info?.userInfo?.creator?.lastName}`}
              email={info?.userInfo?.creator?.email}
            />
          </div>
        </div>
        <div>
          Date created: <span>{getParsedDate(info.created_at)}</span>
        </div>
        <div>
          Premium credits per answer: <span>{(+info.price || (info.type === 'yes-no' ? 2.5 : 5)) / 2}</span>
        </div>
      </div>
    ) : (
      <div className="task-information p-0">
        <div>
          Task started: <span>{getParsedDate(info.taskStartTime)}</span>
        </div>
        <div>
          Time spent: <span className="text-danger bold">{info.spentTime} min</span>
        </div>
        <div>
          Answered: <span>{count} questions</span>
        </div>
        <div>
          Earned: <span>{((info.price || info.type === 'yes-no' ? 2.5 : 5) * (count || 0)) / 2} premium credits</span>
        </div>
        <div className="position-relative ">
          User name:
          <div className="user-wrap d-inline cursor-pointer pl-1">
            {info?.userInfo?.performer?.firstName} {info?.userInfo?.performer?.lastName}
            <UserInfo
              className="user-wrap-info"
              name={`${info?.userInfo?.performer?.firstName + ' ' + info?.userInfo?.performer?.lastName}`}
              email={info?.userInfo?.performer?.email}
            />
          </div>
        </div>
      </div>
    )}
  </>
);

export default UserFullInfo;
