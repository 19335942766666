import { FC, useContext, useEffect, useState, memo, useMemo } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { Col, Row } from 'react-bootstrap';

import Tooltip from 'shared-components/atom/tooltip';
import Input from 'shared-components/atom/input';
import StretchLoading from 'shared-components/molecule/stretch-loading';
import Button from 'shared-components/atom/button';
import Select from 'shared-components/atom/select';
import Datalist from 'shared-components/atom/dataList';
import RadioButton from 'shared-components/atom/radio-button';
import Checkbox from 'shared-components/atom/checkbox';
import CustomMultiSelect from 'shared-components/molecule/multiselect';
import Message from 'shared-components/atom/alert-message';

import { GET_GOOGLE_SPREAD_SHEETS, GET_SHEETS, GET_ALL_INTEGRATIONS } from 'graphql/queries';
import { HexomaticUserContext } from 'contexts/hexomatic-user';
import { HOST } from 'constants/host';

import { useAutomationGlobalMethods } from '../../hooks';
import { columnOptions } from '../../constants';
import { errorMessages } from 'constants/index';
import { AutomationSettingsFactoryTypesProps } from '../../types';

import { reloadIcon } from 'assets/svg-icons';
import './styles.scss';

const options: { label: string; value: number }[] = [
  { label: 'append', value: 1 },
  { label: 'update', value: 2 },
];

const GoogleSheetSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  let int: any;
  const { hexomaticUser } = useContext(HexomaticUserContext);
  const [userId] = useState(hexomaticUser ? hexomaticUser.id : -1);

  const [connected, setConnected] = useState(false);
  const [showUpdateAndAppendFields, setShowUpdateAndAppendFields] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [isIncludeHeaders, setIsIncludeHeaders] = useState(false);
  const [insertDateTime, setInsertDateTime] = useState(false);

  const [spreadsheetText, setSpreadsheetText] = useState('');
  const [spreadsheetId, setSpreadsheetId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [newSpreadSheetName, setNewSpreadSheetName] = useState('');

  const [selectedGoogle, setSelectedGoogle] = useState<any>(null);
  const [selectedSheet, setSelectedSheet] = useState<any>(null);

  const [appendOrUpdate, setAppendOrUpdate] = useState<{ label: string; value: number }>({ label: 'append', value: 1 });
  const [spreadsheetOptions, setSpreadsheetOptions] = useState<any>([{ label: 'add new spreadsheet', value: 'new' }]);
  const [columns, setColumns] = useState<any>(columnOptions);
  const [exportDateColumn, setExportDateColumn] = useState<any>('');

  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);
  const [googleOptions, setGoogleOptions] = useState<any>([]);
  const [sheetOptions, setSheetOptions] = useState<any>([]);
  const [fieldList, setFieldList] = useState<any>([]);
  const [selectedFields, setSelectedFields] = useState<any>([]);
  const [values, setValues] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [nextPageToken, setNextPageToken] = useState('');
  const [showGoogleMessage, setShowGoogleMessage] = useState(false);
  const [el, setEl] = useState(null as any);
  const [googleConnect, setGoogleConnect] = useState(false);
  const [refData, setRefData] = useState(null as any);

  const [getAllIntegrations, { data: integrationsData, loading: getIntegrationLoading }] = useLazyQuery(
    GET_ALL_INTEGRATIONS,
    {
      fetchPolicy: 'no-cache',
    },
  );
  const [getSheets, { data: sheetData, loading: getSheetsLoading }] = useLazyQuery(GET_SHEETS, {
    fetchPolicy: 'network-only',
  });
  const [getSpreadSheets, { data: spreadsheetData, loading: getSpreadsheetsLoading }] = useLazyQuery(
    GET_GOOGLE_SPREAD_SHEETS,
    { fetchPolicy: 'network-only' },
  );

  const {
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    liftingSettings,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = (): boolean => {
    const ADD_NEW_SPREADSHEET = 'add new spreadsheet';

    if (!connected || !spreadsheetText || errorMessage) return false;
    if (spreadsheetText === ADD_NEW_SPREADSHEET && !newSpreadSheetName) {
      return false;
    }

    let bool = true;

    if (!values?.length && spreadsheetText !== ADD_NEW_SPREADSHEET) return false;

    values?.forEach((item: { label: any; value: any }) => {
      const found = selectedFields?.find((field: { recipeField: any }) => field.recipeField === item.label);
      if (found) {
        if (!found.value) bool = false;
      }
      const selectedFound = selectedFields?.find(
        (sub: { key: string; value: string }) => item.value === sub.key || item.label === sub.key,
      );
      if (!selectedFound?.value && spreadsheetText !== ADD_NEW_SPREADSHEET) {
        bool = false;
      }
    });

    if (spreadsheetText !== ADD_NEW_SPREADSHEET && !selectedSheet) {
      return false;
    }
    if (insertDateTime && spreadsheetText !== ADD_NEW_SPREADSHEET && !exportDateColumn) {
      return false;
    }
    return bool;
  };

  //! Restore
  useEffect(() => {
    getAllIntegrations({
      variables: { id: userId },
    });
    if (automationSendSettings) {
      const currentAutomation = automationSendSettings.find((automation: any, index: any) => index === automationIndex);
      if (currentAutomation.dynamic_settings) {
        const { dynamic_settings, integration_settings } = currentAutomation;
        restoreData({ dynamic_settings, integration_settings });
      }
    }
  }, []);

  const restoreData = ({ dynamic_settings }: any) => {
    setAppendOrUpdate(options[dynamic_settings.append ? 0 : 1]);
    setIsIncludeHeaders(!!dynamic_settings.include_headers);
    setInsertDateTime(!!dynamic_settings.include_exported_date);
  };
  //! Restore

  //! Getting integrations
  useEffect(() => {
    if (integrationsData?.HexomaticIntegrations?.getAllIntegrations?.google_sheet) {
      const { google_sheet } = integrationsData.HexomaticIntegrations.getAllIntegrations;
      const options = google_sheet.map((item: { id: any; data: any; name: string }) => {
        const googleData = JSON.parse(item.data);
        return {
          label: item.name ? item.name : googleData && googleData.name,
          value: item.id,
        };
      });
      const currentAutomation = automationSendSettings.find((automation: any, index: any) => index === automationIndex);
      const { integration_settings } = currentAutomation;
      if (integration_settings) {
        const finded = options.find(
          (option: { value: any; label: any }) =>
            option.value === integration_settings._ref_data || option.label === integration_settings._ref_data,
        );
        if (finded) {
          setSelectedGoogle(finded);
          setRefData(null);
        } else if (options?.length === 1) {
          setSelectedGoogle(options[0]);
          setRefData(null);
          setIsDirty();
          setClicked(true);
        } else if (integration_settings._ref_data) {
          setRefData(integration_settings._ref_data);
          // setSelectedGoogle({ label: integration_settings._ref_data, value: integration_settings._ref_data });
        }
      }
      setGoogleOptions(options);
      options && options.length ? setConnected(true) : setConnected(false);
    }
  }, [integrationsData]);

  const fetchSpreadSheets = () => {
    if (!selectedGoogle?.value && !spreadsheetText.trim()) {
      return;
    }
    getSpreadSheets({
      variables: {
        settings: {
          integration_id: selectedGoogle ? selectedGoogle.value : null,
          pageSize: 25,
          nextPageToken: page === 1 ? undefined : nextPageToken,
          search: spreadsheetText,
        },
      },
    });
  };

  useEffect(() => {
    selectedGoogle && fetchSpreadSheets();
  }, [selectedGoogle, page, spreadsheetText]);

  useEffect(() => {
    setErrorMessage('');
    if (spreadsheetData?.HexomaticIntegrations?.getGoogleSheetIntegrationSpreadsheets) {
      const { error_code, spreadsheets } = spreadsheetData.HexomaticIntegrations.getGoogleSheetIntegrationSpreadsheets;
      if (error_code) {
        //@ts-ignore
        setErrorMessage(errorMessages[error_code] || error_code);
        setSelectedGoogle(null);
        setRefData(null);
        return;
      }
      const options = spreadsheets?.files?.map((item: { name: any; id: any }) => ({
        label: item.name,
        value: item.id,
      }));
      spreadsheets.nextPageToken && setNextPageToken(spreadsheets.nextPageToken);

      const { dynamic_settings } = getDynamicAndIntegrationsSettings();
      if (dynamic_settings) {
        setIsIncludeHeaders(!!dynamic_settings.include_headers);
        setInsertDateTime(!!dynamic_settings.include_exported_date);
        if (dynamic_settings.spreadsheet_id === 'new') {
          setSpreadsheetText('add new spreadsheet');
          setNewSpreadSheetName(dynamic_settings.new_spreadsheet_name);
          setSpreadsheetId('');
        }
        const finded = options.find((item: { value: any }) => item.value === dynamic_settings.spreadsheet_id);
        if (finded) {
          setSpreadsheetText(finded.label);
          setSpreadsheetId('');
        }
      }
      if (page > 1) {
        setSpreadsheetOptions([...spreadsheetOptions, ...options]);
      } else {
        setSpreadsheetOptions([{ label: 'add new spreadsheet', value: 'new' }, ...options]);
      }
    }
  }, [spreadsheetData]);

  useEffect(() => {
    setErrorMessage('');
    if (sheetData?.HexomaticIntegrations?.getGoogleSheetIntegrationSpreadsheetsSheets) {
      const { error_code, sheets } = sheetData.HexomaticIntegrations.getGoogleSheetIntegrationSpreadsheetsSheets;
      if (error_code) {
        setErrorMessage(error_code);
        return;
      }
      const options = sheets.map((item: { title: string; sheet_id: number }) => ({
        label: item.title,
        value: item.sheet_id,
      }));

      const { dynamic_settings } = getDynamicAndIntegrationsSettings();
      if (dynamic_settings) {
        const finded = options.find(
          (item: { label: string; value: any }) => item.label === dynamic_settings.sheet_name,
        );
        finded && setSelectedSheet(finded);
      }
      setSheetOptions(options);
    }
  }, [sheetData]);

  useEffect(() => {
    if (spreadsheetText === 'add new spreadsheet') {
      setSheetOptions([]);
      showUpdateAndAppendFields && setShowUpdateAndAppendFields(false);
    } else {
      const selectedSpreadSheet = spreadsheetOptions.find((item: { label: string }) => item.label === spreadsheetText);
      if (selectedSpreadSheet) {
        getSheets({
          variables: {
            settings: {
              integration_id: selectedGoogle ? selectedGoogle.value : null,
              spreadsheet_id: selectedSpreadSheet.value,
            },
          },
        });
      }
      !showUpdateAndAppendFields && setShowUpdateAndAppendFields(true);
    }
  }, [spreadsheetText, spreadsheetOptions]);
  //! Getting integrations

  //! Recipe
  useEffect(() => {
    if (selectedFields.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();

    const { dynamic_settings, integration_settings } = getDynamicAndIntegrationsSettings();
    if (integration_settings) {
      const finded = googleOptions.find(
        (option: { value: any; label: any }) =>
          option.value === integration_settings._ref_data || option.label === integration_settings._ref_data,
      );
      if (finded) {
        setSelectedGoogle(finded);
        setRefData(null);
      } else if (integration_settings._ref_data) {
        setRefData(integration_settings._ref_data);
      }
    }
    if (dynamic_settings) {
      setIsIncludeHeaders(!!dynamic_settings.include_headers);
      setInsertDateTime(!!dynamic_settings.include_exported_date);
      if (dynamic_settings.spreadsheet_id === 'new') {
        setSpreadsheetText('add new spreadsheet');
        setSpreadsheetId('');
        setNewSpreadSheetName(dynamic_settings.new_spreadsheet_name);
      } else if (dynamic_settings.existing_spreadsheet_name) {
        setSpreadsheetText(dynamic_settings.existing_spreadsheet_name);
        setSpreadsheetId(dynamic_settings.spreadsheet_id);
        const finded = sheetOptions.find(
          (item: { label: string; value: any }) => item.label === dynamic_settings.sheet_name,
        );
        finded
          ? setSelectedSheet(finded)
          : dynamic_settings.sheet_name &&
            setSelectedSheet({ label: dynamic_settings.sheet_name, value: dynamic_settings.sheet_name });
      }
    }

    if (dynamic_settings) {
      setConnected(true);
      const { keys } = dynamic_settings;
      if (keys) {
        let selectedArr = keys.map((item: { key: any; value: string }) => {
          const finded = options.find(
            (option: { label: string; value: string }) => option.value === item.key || option.label === item.key,
          );
          item.key === '_export_date' && setExportDateColumn({ label: item.value, value: item.value });
          return {
            label: finded ? finded.label : null,
            value: finded ? finded.value : null,
          };
        });
        const selFields = selectedArr.filter((elem: any) => elem.label !== null);
        setValues(selFields);
        const reCreateSelectedFields =
          selFields &&
          !!selFields.length &&
          selFields.map((item: any, index: number) => {
            const finded = keys.find(
              (keyItem: { key: any }) => keyItem.key === item.label || keyItem.key === item.value,
            );
            if (finded) {
              return { key: finded.key, value: finded.value };
            }
            return { key: item.label, value: '' };
          });
        reCreateSelectedFields && setSelectedFields(reCreateSelectedFields);
      }
    }
    // else {
    //   const data =
    //     options &&
    //     !!options.length &&
    //     dynamicColumns?.length &&
    //     dynamicColumns?.length >= options.length &&
    //     options.map((item: any, index: number) => {
    //       return { key: item.label, value: item.value || '' };
    //     });
    //   data?.length && setSelectedFields(data);
    // }
    //! Options && options.length && setFieldList(options);
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    filteredOptions && filteredOptions.length && setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
  }, [selectedRecipes, isSettingsChange]);

  // useEffect(() => {
  //   if (fieldList?.length === 1) {
  //     setValues(fieldList);
  //     setIsDirty();
  //     setClicked(true);
  //   }
  // }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings, integration_settings } = getDynamicAndIntegrationsSettings();
    if (integration_settings) {
      const finded = googleOptions.find(
        (option: { value: any; label: any }) =>
          option.value === integration_settings._ref_data || option.label === integration_settings._ref_data,
      );
      if (finded) {
        setSelectedGoogle(finded);
        setRefData(null);
      } else if (integration_settings._ref_data) {
        setRefData(integration_settings._ref_data);
      }
    }
    if (dynamic_settings) {
      setIsIncludeHeaders(!!dynamic_settings.include_headers);
      setInsertDateTime(!!dynamic_settings.include_exported_date);
      if (dynamic_settings.spreadsheet_id === 'new') {
        setSpreadsheetText('add new spreadsheet');
        setSpreadsheetId('');
        setNewSpreadSheetName(dynamic_settings.new_spreadsheet_name);
      } else if (dynamic_settings.existing_spreadsheet_name) {
        setSpreadsheetText(dynamic_settings.existing_spreadsheet_name);
        setSpreadsheetId(dynamic_settings.spreadsheet_id);
        const finded = sheetOptions.find(
          (item: { label: string; value: any }) => item.label === dynamic_settings.sheet_name,
        );
        finded
          ? setSelectedSheet(finded)
          : dynamic_settings.sheet_name &&
            setSelectedSheet({ label: dynamic_settings.sheet_name, value: dynamic_settings.sheet_name });
      }
    }
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setValues([]);
      setClicked(true);
    }
    if (update) {
      if (dynamic_settings?.keys?.length > 1) {
        const { keys } = dynamic_settings;
        const filteredValue = filteredOptions?.filter((item: any) =>
          dynamic_settings?.keys.find((sub: any) => item.value === sub.key),
        );
        const reCreateSelectedFields =
          filteredValue &&
          !!filteredValue.length &&
          filteredValue.map((item: any, index: number) => {
            const finded = keys.find(
              (keyItem: { key: any }) => keyItem.key === item.label || keyItem.key === item.value,
            );
            if (finded) {
              return { key: finded.key, value: finded.value };
            }
            return { key: item.label, value: '' };
          });
        reCreateSelectedFields && setSelectedFields(reCreateSelectedFields);
        setValues(filteredValue || []);
        // setClicked(true);
        setFieldList(filteredOptions);
        setFieldListWithTypes(optionsWithTypes);
      } else {
        const isArr = Array.isArray(dynamic_settings?.keys?.[0]?.key);
        const foundValue = !isArr
          ? filteredOptions?.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value)
          : null;
        const filteredValue = isArr
          ? filteredOptions?.filter((item: any) =>
              dynamic_settings?.keys?.[0]?.key.find((sub: any) => item.value === sub),
            )
          : [];
        const selOptionFound = isArr ? filteredValue : foundValue ? [foundValue] : [];
        const reCreateSelectedFields =
          selOptionFound &&
          !!selOptionFound?.length &&
          selOptionFound.map((item: any, index: number) => {
            const finded = dynamic_settings?.keys?.find(
              (keyItem: { key: any }) => keyItem.key === item.label || keyItem.key === item.value,
            );
            if (finded) {
              return { key: finded.key, value: finded.value };
            }
            return { key: item.label, value: '' };
          });
        reCreateSelectedFields && setSelectedFields(reCreateSelectedFields);
        setValues(selOptionFound);
        setClicked(true);
        setFieldList(filteredOptions);
        setFieldListWithTypes(optionsWithTypes);
      }
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [...fieldList.map((item: { value: string }) => item.value)],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);

  //! Lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);

    const selectedSpreadSheetFound = spreadsheetOptions.find(
      (item: { label: string }) => item.label === spreadsheetText,
    );
    const restoredOption = spreadsheetText && spreadsheetId ? { label: spreadsheetText, value: spreadsheetId } : null;
    const selectedSpreadSheet = selectedSpreadSheetFound || restoredOption;

    let flag = true;
    const sendColumns: any = [];
    values.forEach((item: { label: any; value: string }) => {
      const finded = selectedFields.find((field: { key: any }) => field.key === item.label || field.key === item.value);
      if (finded) {
        if (!finded.value) flag = false;
        sendColumns.push({ key: item.value, value: finded.value });
      }
    });
    if (insertDateTime && spreadsheetText !== 'add new spreadsheet') {
      !sendColumns.find((item: any) => item.key === '_export_date') &&
        sendColumns.push({ key: '_export_date', value: exportDateColumn.value });
    } else {
      let ind = -1;
      sendColumns.map((item: any, index: number) => item.key === '_export_date' && (ind = index));
      ind !== -1 && sendColumns.splice(ind, 1);
    }
    !flag && setCompletedFields({ index: automationIndex, checked: flag });

    let localSettings = {
      create_new: spreadsheetText === 'add new spreadsheet',
      append: spreadsheetText === 'add new spreadsheet' ? null : appendOrUpdate.label === 'append',
      update: spreadsheetText === 'add new spreadsheet' ? null : appendOrUpdate.label !== 'append',
      spreadsheet_id: selectedSpreadSheet?.value,
      existing_spreadsheet_name: selectedSpreadSheet?.label,
      sheet_name: selectedSheet && selectedSheet.label,
      keys: sendColumns,
      new_spreadsheet_name: spreadsheetText === 'add new spreadsheet' ? newSpreadSheetName : null,
      include_headers: isIncludeHeaders,
      include_exported_date: insertDateTime,
    };
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      integrationsSettings: {
        _ref_type: 'INTEGRATION',
        _ref_data: selectedGoogle ? selectedGoogle.value : refData ? refData : null,
      },
      dynamicSettings: localSettings,
      outputKeysData: [...fieldList.map((item: { value: string }) => item.value)],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [
    spreadsheetText,
    errorMessage,
    selectedSheet,
    appendOrUpdate,
    selectedFields,
    values,
    newSpreadSheetName,
    isIncludeHeaders,
    insertDateTime,
    selectedGoogle,
    exportDateColumn,
    refData,
    spreadsheetId,
  ]);

  const fetchSheets = () => {
    const selectedSpreadSheet = spreadsheetOptions.find((item: { label: string }) => item.label === spreadsheetText);
    selectedSpreadSheet &&
      getSheets({
        variables: {
          settings: {
            integration_id: selectedGoogle ? selectedGoogle.value : null,
            spreadsheet_id: selectedSpreadSheet?.value,
          },
        },
      });
  };

  const handleMultiInputs = (value: string | boolean, keyName: string, valueName: string) => {
    const copiedSelectedFields = [...selectedFields];

    const arr = copiedSelectedFields.map((field: { key: string; value: any }) => {
      if (field.key === keyName || field.key === valueName) {
        return { ...field, value: value };
      }
      return { ...field };
    });
    setSelectedFields(arr);
  };

  const findFrom = (value: string, label: string) => {
    const finded = selectedFields.find(
      (field: { key: string; value: string }) => field.key === label || field.key === value,
    );
    if (finded) {
      return finded.value;
    }
    return '';
  };

  const dynamicColumns = useMemo(() => {
    let list: { label: string; value: string }[] = [...columns];
    if (list.length && selectedFields.length) {
      for (const item of selectedFields) {
        for (let i = 0; i < list.length; i++) {
          if (item.value === list[i].value) {
            list.splice(i, 1);
            i--;
          }
        }
      }
    }
    list = list?.filter((item: any) => item.value !== exportDateColumn.value);

    return list;
  }, [selectedFields, columns, exportDateColumn]);

  const showGoogle = (secondWindow: any, url: string) => {
    secondWindow && (secondWindow.location = url);
    setEl(secondWindow);
    setShowGoogleMessage(true);
    setGoogleConnect(true);
    int = setInterval(() => {
      if (!secondWindow || secondWindow.closed) {
        clearInterval(int);
        setShowGoogleMessage(false);
        setEl(null);
      }
    });
  };

  useEffect(() => {
    if (!showGoogleMessage && googleConnect) {
      getAllIntegrations({
        variables: { id: userId },
      });
    }
  }, [el, showGoogleMessage, googleConnect, userId]);

  return (
    <div className="google-automation-settings">
      {(connected === null || getSheetsLoading || getIntegrationLoading || getSpreadsheetsLoading) && (
        <StretchLoading />
      )}
      {connected === null || connected ? (
        <>
          <Row className="mb-4">
            <Col lg={6}>
              <span className="label secondary-color">Account</span>
              <Select
                fullWidth
                options={googleOptions}
                value={selectedGoogle}
                placeholder={'Select account'}
                onChange={(selectedItem: any) => {
                  setSelectedGoogle(selectedItem);
                  setRefData(null);
                  !clicked && setClicked(true);
                  setIsDirty();
                }}
              />
            </Col>
          </Row>
          {selectedGoogle?.value && (
            <Row className="mb-4">
              <Col lg={6}>
                <span className="label secondary-color">Spreadsheet</span>
                <div className="d-flex w-100">
                  <div className={`spreadsheet-width ${errorMessage ? 'pointer-events-none' : ''}`}>
                    <Datalist
                      list={spreadsheetOptions}
                      value={spreadsheetText}
                      placeholder={'Select spreadsheet'}
                      onChange={(text: string) => {
                        setSpreadsheetText(text);
                        setSpreadsheetId('');
                        setSelectedSheet(null);
                        !clicked && setClicked(true);
                        setIsDirty();
                      }}
                      page={page}
                      setPage={setPage}
                      pageSize={25}
                    />
                  </div>
                  <Tooltip
                    trigger={['hover', 'focus']}
                    placement="left"
                    text="Refetch spreadsheets list"
                    className="reload-icon-tooltip mb-1"
                  >
                    <span className="reload-icon" onClick={() => (page !== 1 ? setPage(1) : fetchSpreadSheets())}>
                      {reloadIcon}
                    </span>
                  </Tooltip>
                </div>
              </Col>
              {spreadsheetText === 'add new spreadsheet' ? (
                <Col lg={3}>
                  <span className="label secondary-color">Spreadsheet name</span>
                  <Input
                    value={newSpreadSheetName}
                    onChange={e => {
                      setNewSpreadSheetName(e.target.value);
                      !clicked && setClicked(true);
                      setIsDirty();
                    }}
                  />
                </Col>
              ) : (
                spreadsheetText &&
                showUpdateAndAppendFields && (
                  <Col lg={6} className="radio-block">
                    {options.map(item => (
                      <div key={item.label} className="radio-wrapper">
                        <RadioButton
                          enabled={appendOrUpdate ? item.value === appendOrUpdate.value : false}
                          onChange={() => {
                            setAppendOrUpdate(item);
                            !clicked && setClicked(true);
                            setIsDirty();
                          }}
                          label={item.label}
                        />
                      </div>
                    ))}
                  </Col>
                )
              )}
              <Col lg={6} className="checkbox-wrapper">
                <div className="item-block mt-4">
                  <Checkbox
                    checked={isIncludeHeaders}
                    label="include headers"
                    onChange={() => {
                      setIsIncludeHeaders(!isIncludeHeaders);
                      !clicked && setClicked(true);
                      setIsDirty();
                    }}
                  />
                  <Checkbox
                    checked={insertDateTime}
                    label="insert date/time"
                    onChange={() => {
                      setInsertDateTime(!insertDateTime);
                      !clicked && setClicked(true);
                      setIsDirty();
                    }}
                  />
                </div>
              </Col>
            </Row>
          )}
          {spreadsheetText && sheetOptions.length > 0 && (
            <>
              <Row className="mb-4">
                <Col lg={6}>
                  <span className="label secondary-color">Sheet</span>
                  <div className="d-flex w-100">
                    <div className="spreadsheet-width">
                      <Select
                        options={sheetOptions}
                        value={selectedSheet}
                        placeholder={'Select sheet name'}
                        onChange={(selectedItem: any) => {
                          setSelectedSheet(selectedItem);
                          !clicked && setClicked(true);
                          setIsDirty();
                        }}
                      />
                    </div>
                    <Tooltip
                      trigger={['hover', 'focus']}
                      placement="left"
                      text="Refetch sheets data"
                      className="reload-icon-tooltip mb-1"
                    >
                      <span className="reload-icon" onClick={fetchSheets}>
                        {reloadIcon}
                      </span>
                    </Tooltip>
                  </div>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col lg={6} className="">
                  <span className="label secondary-color">Select fields for export</span>
                  <CustomMultiSelect
                    options={fieldList}
                    value={values}
                    onChange={(sel: any) => {
                      setValues(sel);
                      setSelectedFields(
                        sel.map((item: any) => {
                          return {
                            key: item.label,
                            value: selectedFields.find((sub: any) => sub.key === item.label)?.value || '',
                          };
                        }),
                      );
                      !clicked && setClicked(true);
                      setIsDirty();
                    }}
                    labelledBy="Select"
                  />
                </Col>
              </Row>
              {insertDateTime && spreadsheetText !== 'add new spreadsheet' && (
                <Row className="mb-4">
                  <Col lg={6} className="">
                    <span className="label secondary-color">export - date</span>
                    <Select
                      value={exportDateColumn}
                      options={dynamicColumns}
                      placeholder={'Select column'}
                      onChange={(selectedItem: any) => {
                        setExportDateColumn(selectedItem);
                        !clicked && setClicked(true);
                        setIsDirty();
                      }}
                    />
                  </Col>
                </Row>
              )}

              {selectedFields?.length > 0 &&
                values.map((property: { label: string; value: string }, index: number) => (
                  <Row key={index} className="mb-4">
                    <Col lg={6} className="">
                      <span className="label secondary-color">{property.label} - export</span>
                      <Select
                        options={dynamicColumns}
                        value={findFrom(property.value, property.label)}
                        placeholder={'Select column'}
                        onChange={(selectedItem: any) => {
                          handleMultiInputs(selectedItem.label, property.label, property.value);
                          !clicked && setClicked(true);
                          setIsDirty();
                        }}
                      ></Select>
                    </Col>
                  </Row>
                ))}
            </>
          )}
        </>
      ) : (
        <Col lg={6} md={12}>
          <Button
            name="Connect"
            onClick={() => {
              const y = (window.outerHeight - 470) / 2;
              const x = (window.outerHeight - 400) / 2;
              const secWindow = window.open('', 'secondary', `width=400,height=470,top=${y},left=${x}`);
              showGoogle(
                secWindow,
                `${HOST.APP_API.APP}/social-callback/hexomatic/google-sheet?hexomatic_user_id=${hexomaticUser.id}`,
              );
            }}
          />
        </Col>
      )}
      {errorMessage && <Message type="error" text={errorMessage} className="my-2 mr-2" />}
    </div>
  );
};

export default memo(GoogleSheetSettings);
