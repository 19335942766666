import React, { useContext, useState, FormEvent, useEffect } from 'react';
import { withAlert } from 'react-alert';
import { Row, Col } from 'react-bootstrap';
import countryList from 'react-select-country-list';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { ProfileSettingsProps } from '../types';
import { UserContext } from 'contexts/user-context';
import { GetCountry } from 'helpers';
import { USER_QUERY } from 'graphql/queries';
import { SCHEDULE_USER_TO_DELETE, UPDATE_USER_INFORMATION } from 'graphql/mutations';
import { isValidUrl, isValidDomain } from 'constants/validations';
import { taxIds, states } from 'constants/profile';
import Button from 'shared-components/atom/button';
import AlertMessage from 'shared-components/atom/alert-message';
import CustomInput from 'shared-components/atom/input';
import CustomSelect from 'shared-components/atom/select';
import DropZoneField from 'shared-components/molecule/drop-zone';
import ModalComponent from 'shared-components/molecule/modal';
import { warningRedIcon } from 'assets/svg-icons';
import DataList from 'shared-components/atom/dataList';
import WindowedSelect from 'react-windowed-select';
import '../styles.scss';

const industryOptions = [
  { label: 'eCommerce', value: 'eCommerce' },
  { label: 'Education', value: 'Education' },
  { label: 'Entertainment', value: 'Entertainment' },
  { label: 'Financial Services', value: 'Financial Services' },
  { label: 'Gambling', value: 'Gambling' },
  { label: 'Gaming', value: 'Gaming' },
  { label: 'Government', value: 'Government' },
  { label: 'Healthcare', value: 'Healthcare' },
  { label: 'Information Technology', value: 'Information Technology' },
  { label: 'Legal Services', value: 'Legal Services' },
  { label: 'Marketing & Advertising', value: 'Marketing & Advertising' },
  { label: 'News & Media', value: 'News & Media' },
  { label: 'Nonprofit', value: 'Nonprofit' },
  { label: 'Online Services', value: 'Online Services' },
  { label: 'Other', value: 'Other' },
  { label: 'Professional Services', value: 'Professional Services' },
  { label: 'Real Estate', value: 'Real Estate' },
  { label: 'Retail', value: 'Retail' },
  { label: 'Travel & Tourism', value: 'Travel & Tourism' },
  { label: 'Utilities', value: 'Utilities' },
];
const countryOptions = countryList().getData();

const ProfileSettings: React.FC<ProfileSettingsProps> = (props: ProfileSettingsProps) => {
  const { user, setUser } = useContext(UserContext);

  //--------------------State------------------------------
  //const [modalSuccess, setModalSuccess] = useState(false);
  const [refetchLoading, setRefetchLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [userFields, setUserFields] = useState<any>(null);
  const [preview, setPreview] = useState('');
  const [shouldBeDeletedAt, setShouldBeDeletedAt] = useState('');
  const [fieldError, setFieldError] = useState('');
  const [countryVal, setCountryVal] = useState((userFields && userFields.countryUser) || '');
  const [industryVal, setIndustryVal] = useState((userFields && userFields.industry) || '');
  const [isChangeImage, setIsChangeImage] = useState(false);
  const [taxList, setTaxList] = useState([]);
  const [tax_id, setTaxId] = useState<any>('');
  const [state, setState] = useState<any>('');
  const [errorMessage, setErrorMessage] = useState('');
  //--------------------Query--------------------------------
  const { data: userAccountStatus, loading: toBeDeletedLoading, refetch } = useQuery(USER_QUERY);
  const { data: userInfo, loading: userLoading, refetch: fetchUserData } = useQuery(USER_QUERY);
  const [updateMutation, { loading: updateLoading, error }] = useMutation(UPDATE_USER_INFORMATION);
  const [deleteMutation, { loading: deleteLoading }] = useMutation(SCHEDULE_USER_TO_DELETE);

  const loading = updateLoading || userLoading;
  //----------------------useEffect-------------------
  useEffect(() => {
    if (userInfo && userInfo.User && userInfo.User.get) {
      setUserFields({ ...userInfo.User.get, ...(userInfo.User.get.info || {}) });
      setUser(userInfo.User.get);
      setIndustryVal(userInfo.User.get.industry);
      setCountryVal(userInfo.User.get.countryUser);
      if (userInfo.User.get.countryUser && userInfo.User.get.info?.state) {
        setState(
          states[
            userInfo.User.get.countryUser === 'AU' || userInfo.User.get.countryUser === 'Australia'
              ? 'Australia'
              : 'United States'
          ].find(item => item.value === userInfo.User.get.info?.state),
        );
      }
      userInfo.User.get.info?.tax_type && setTaxId(userInfo.User.get.info?.tax_type);
    }
  }, [userInfo]);

  useEffect(() => {
    if (countryVal && typeof countryVal === 'string') {
      const country = countryVal.replaceAll(' ', '_').toLocaleLowerCase();
      //@ts-ignore
      if (taxIds[country]) {
        //@ts-ignore
        setTaxList(taxIds[country]);
      } else setTaxList([]);
    }
  }, [countryVal]);

  useEffect(() => {
    if (taxList && taxList.length > 0 && tax_id && typeof tax_id === 'string') {
      setTaxId(taxList.find((item: any) => item.value === tax_id));
    }
  }, [taxList]);

  useEffect(() => {
    setFieldError('');
    setErrorMessage('');
    const value = userFields ? userFields.countryUser : null;
    if (value && value.length <= 3) {
      let label = GetCountry(value);
      label && setCountryVal((label && label.name) || 'Select Country');
    }
  }, [userAccountStatus, user, userFields]);

  useEffect(() => {
    if (userAccountStatus && userAccountStatus.User && userAccountStatus.User.get) {
      setShouldBeDeletedAt(userAccountStatus.User.get.shouldBeDeletedAt);
    }
  }, [userAccountStatus]);

  useEffect(() => {
    error && error.message && setErrorMessage(error.message.replace('GraphQL error:', ''));
  }, [error]);

  //-------------------Functions------------------------
  const changeCountry = (value: any) => {
    !(value && value.value) && setCountryVal('');
    setTaxId('');
    setState('');
    setUserFields({
      ...userFields,
      countryUser: value && value.value ? value.value : '',
      tax_value: '',
      city: '',
      address1: '',
      address2: '',
    });
  };

  const changeState = (value: any) => {
    setState(value);
    setUserFields({
      ...userFields,
      city: '',
      address1: '',
      address2: '',
    });
  };
  const changeTax = (value: any) => {
    setTaxId(value);
    setUserFields({
      ...userFields,
      tax_value: '',
    });
  };
  const changeIndustry = (value: any) => {
    setIndustryVal(value);
    setUserFields({
      ...userFields,
      industry: value.value,
    });
  };

  const handleCancel = () => {
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  const handleError = (message: string) => {
    const { alert } = props;
    alert.show({ message }, { type: 'error' });
  };

  const fieldChange = (e: FormEvent) => {
    const target = e.target as HTMLInputElement;
    setUserFields({
      ...userFields,
      [target.name]: target.value,
    });
  };

  const fieldLogoChange = async (file: any) => {
    setIsChangeImage(true);
    setPreview(file.preview);
    if (file.size > 300000) {
      setFieldError('The entered image size is too large');
      return;
    }
    setFieldError('');
    await updateMutation({
      variables: {
        settings: {
          logo: file.url ? file.url : '',
          mimeType: file.mimeType,
          firstName: userFields.firstName,
          lastName: userFields.lastName,
          companyWebsite: userFields.companyWebsite,
          industry: userFields.industry,
          country: userFields.countryUser,
          title: userFields.title,
          company: userFields.company,
          phone: userFields.phone,
          info: {
            address1: userFields.address1,
            address2: userFields.address2,
            city: userFields.city,
            state:
              countryVal !== 'United States' && countryVal !== 'Australia'
                ? ''
                : state && state.value
                ? state.value
                : '',
            zip: userFields.zip,
            tax_type: userFields.tax_value && tax_id ? tax_id.value : null,
            tax_value: userFields.tax_value,
          },
        },
      },
    });
    setRefetchLoading(true);
    await fetchUserData();
    setRefetchLoading(false);
  };
  const handleSave = async (e: FormEvent) => {
    e.preventDefault();
    setIsChangeImage(false);
    if (userFields) {
      if (!userFields.firstName.trim() || !userFields.lastName.trim()) {
        (!userFields.firstName.trim() || !userFields.lastName.trim()) &&
          setFieldError('User first and last names cannot be empty');
      } else if (
        userFields.companyWebsite &&
        !isValidUrl(userFields.companyWebsite) &&
        !isValidDomain(userFields.companyWebsite)
      ) {
        setFieldError('Please enter valid website URL');
      } else {
        setFieldError('');
        try {
          const res = await updateMutation({
            variables: {
              settings: {
                firstName: userFields.firstName,
                lastName: userFields.lastName,
                companyWebsite: userFields.companyWebsite,
                industry: userFields.industry,
                country: userFields.countryUser,
                title: userFields.title,
                company: userFields.company,
                phone: userFields.phone,
                info: {
                  address1: userFields.address1,
                  address2: userFields.address2,
                  city: userFields.city,
                  state:
                    countryVal !== 'United States' && countryVal !== 'Australia'
                      ? ''
                      : state && state.value
                      ? state.value
                      : '',
                  zip: userFields.zip,
                  tax_type: userFields.tax_value && tax_id ? tax_id.value : null,
                  tax_value: userFields.tax_value,
                },
              },
            },
          });
          if (
            res &&
            res.data &&
            res.data.UserOps &&
            res.data.UserOps.updateInformation &&
            res.data.UserOps.updateInformation.error
          ) {
            setErrorMessage(res.data.UserOps.updateInformation.message || 'Something went wrong.');
            return;
          }
          if (res) {
            setRefetchLoading(true);
            await fetchUserData();
            setRefetchLoading(false);
          }
        } catch (e: any) {
          console.log(e);
          setErrorMessage('Something went wrong.');
          setRefetchLoading(false);
        }
      }
    }
  };
  //---------------------------------------------------
  return (
    <div className="profile-settings col-12">
      <Row>
        {userFields ? (
          <>
            <Col lg={6} sm={12}>
              <CustomInput
                placeholder="First Name"
                name="firstName"
                label="First Name"
                value={userFields.firstName || ''}
                onChange={fieldChange}
                maxLength={255}
              />
            </Col>
            <Col lg={6} sm={12}>
              <CustomInput
                placeholder="Last Name"
                name="lastName"
                label="Last Name"
                value={userFields.lastName || ''}
                onChange={fieldChange}
                maxLength={255}
              />
            </Col>

            <Col lg={6} sm={12}>
              <CustomInput
                placeholder="Job title"
                name="title"
                label="Job title"
                value={userFields.title || ''}
                onChange={fieldChange}
                maxLength={2000}
              />
            </Col>
            <Col lg={6} sm={12}>
              <CustomInput
                placeholder="+1 302 0000000"
                name="phone"
                type="number"
                label="Phone Number"
                value={userFields.phone || ''}
                onChange={fieldChange}
              />
            </Col>

            <Col lg={6} sm={12} className="">
              <label htmlFor="" className="label-select">
                Industry
              </label>
              <CustomSelect
                className="industry"
                options={industryOptions}
                value={industryVal}
                onChange={changeIndustry}
                placeholder="Select industry"
              />
            </Col>
            <Col lg={6} sm={12}>
              <CustomInput
                placeholder="Website"
                name="companyWebsite"
                label="Website"
                value={userFields.companyWebsite || ''}
                onChange={fieldChange}
                maxLength={2000}
              />
            </Col>
            <div className="custom-hr updates mx-3" />
            <Col lg={6} sm={12}>
              <Row>
                <Col sm={12} className="data_list_row">
                  <label htmlFor="" className="label-select ">
                    Country
                  </label>
                  <WindowedSelect
                    options={countryOptions}
                    onChange={changeCountry}
                    value={countryVal ? { label: countryVal } : ''}
                    className="custom-multi-select"
                    isClearable
                    placeholder="Select country"
                  />
                </Col>
                <Col
                  sm={12}
                  className={`data_list_row ${
                    countryVal !== 'United States' && countryVal !== 'Australia' ? 'disabled-col' : ''
                  } `}
                >
                  <label htmlFor="" className="label-select ">
                    State
                  </label>
                  <WindowedSelect
                    //@ts-ignore
                    options={states[countryVal]}
                    onChange={changeState}
                    value={state}
                    className="custom-multi-select"
                    autoComplete={countryVal !== 'United States' && countryVal !== 'Australia' ? 'off-off' : ''}
                    placeholder="Select state"
                    isClearable
                  />
                </Col>
                <Col sm={12}>
                  <CustomInput
                    placeholder="Company Name"
                    name="company"
                    label="Company Name"
                    value={userFields.company || ''}
                    onChange={fieldChange}
                    maxLength={2000}
                  />
                </Col>
                <Col sm={12} className={`${taxList && taxList.length < 1 ? 'disabled-col' : ''}`}>
                  <label htmlFor="" className="label-select ">
                    Tax ID
                  </label>
                  <Row className="d-flex">
                    <Col sm={4} className="pr-0">
                      <CustomSelect
                        className="country mb-1"
                        options={taxList}
                        value={tax_id}
                        onChange={changeTax}
                        textUppercase={!!(tax_id && tax_id.value)}
                        placeholder="Select ID"
                        textDefault={!(tax_id && tax_id.value)}
                      />
                    </Col>
                    <Col sm={8}>
                      <CustomInput
                        placeholder={tax_id ? tax_id.example : ''}
                        name="tax_value"
                        value={userFields.tax_value || ''}
                        onChange={fieldChange}
                        maxLength={2000}
                        parentClassName="mb-1"
                      />
                    </Col>
                    <Col sm={12} className={`mb-3 ${tax_id && tax_id.description ? 'visible' : 'invisible'}`}>
                      <span className="description">
                        {tax_id && tax_id.description ? tax_id.description : 'description'}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col lg={6} sm={12}>
              <Row>
                <Col sm={12}>
                  <CustomInput
                    placeholder="City"
                    name="city"
                    label="City"
                    value={userFields.city || ''}
                    onChange={fieldChange}
                    maxLength={2000}
                  />
                </Col>
                <Col sm={12}>
                  <CustomInput
                    placeholder="Address"
                    name="address1"
                    label="Address line 1"
                    value={userFields.address1 || ''}
                    onChange={fieldChange}
                    maxLength={2000}
                  />
                </Col>
                <Col sm={12}>
                  <CustomInput
                    placeholder="Address"
                    name="address2"
                    label="Address line 2"
                    value={userFields.address2 || ''}
                    onChange={fieldChange}
                    maxLength={2000}
                  />
                </Col>

                <Col sm={12}>
                  <CustomInput
                    placeholder="Postal code"
                    name="zip"
                    label="Postal code"
                    value={userFields.zip || ''}
                    onChange={fieldChange}
                    maxLength={2000}
                  />
                </Col>
              </Row>
            </Col>

            <Col sm={12}>
              {warningRedIcon}{' '}
              <span className="information">Information above will be included in your future invoices.</span>
            </Col>
            <div className="d-flex justify-content-between col-12 error-wrapper">
              <div className={`w-100 m-0 mt-1 ${fieldError || errorMessage ? 'visibility' : 'invisible'}`}>
                <AlertMessage type={'error'} text={fieldError || errorMessage} />
              </div>
              <div className="save-btn">
                <Button onClick={handleSave} name="Save" loading={!isChangeImage && (loading || refetchLoading)} />
              </div>
            </div>

            <div className="dr-zone-block">
              <DropZoneField
                customHeight={false}
                name="files"
                onChange={fieldLogoChange}
                value={userFields.logo || preview}
                loading={isChangeImage && (loading || refetchLoading)}
              />
            </div>
          </>
        ) : (
          <>
            {[...new Array(6)].map((i: any) => (
              <Col lg={6} sm={12}>
                <div className="pulse">
                  <div className="line-label"></div>
                  <div className="line-input"></div>
                </div>
              </Col>
            ))}

            <div className="custom-hr updates mx-3" />

            {[...new Array(8)].map((i: any) => (
              <Col lg={6} sm={12}>
                <div className="pulse">
                  <div className="line-label"></div>
                  <div className="line-input"></div>
                </div>
              </Col>
            ))}

            <Col sm={12}>
              <div className="pulse">
                <div className="line-upload-file"></div>
              </div>
            </Col>
          </>
        )}
      </Row>
      <ModalComponent
        isModalOpen={showAlert}
        text={'We are happy with your decision'}
        actionBtnText={'OK'}
        actionBtnColor={'success'}
        onActionClick={() => setShowAlert(false)}
        escButtonClick={() => setShowAlert(false)}
      />
    </div>
  );
};

// @ts-ignore
export default withAlert()(ProfileSettings);
