import { FC, useState, useEffect } from 'react';
import Tooltip from 'shared-components/atom/tooltip';
import Checkbox from 'shared-components/atom/checkbox';

import audioImage from 'assets/images/upload-images/uploaded-images-for-list/audio-icon.svg';
import imageImage from 'assets/images/upload-images/uploaded-images-for-list/image-icon.svg';
import jsonImage from 'assets/images/upload-images/uploaded-images-for-list/json-icon.svg';
import csvImage from 'assets/images/upload-images/uploaded-images-for-list/csv-icon.svg';
import txtImage from 'assets/images/upload-images/uploaded-images-for-list/txt-icon.svg';
import pdfImage from 'assets/images/upload-images/uploaded-images-for-list/pdf-icon.svg';

import audioImageGray from 'assets/images/upload-images/upload-images-for-list-gray/audio-icon.svg';
import imageImageGray from 'assets/images/upload-images/upload-images-for-list-gray/image-icon.svg';
import jsonImageGray from 'assets/images/upload-images//upload-images-for-list-gray/json-icon.svg';
import csvImageGray from 'assets/images/upload-images/upload-images-for-list-gray/csv-icon.svg';
import txtImageGray from 'assets/images/upload-images/upload-images-for-list-gray/txt-icon.svg';
import pdfImageGray from 'assets/images/upload-images/upload-images-for-list-gray/pdf-icon.svg';

import { closeIcon, checkIcon, grayTrashIcon, infoIconCircle } from 'assets/svg-icons/index';
import { getMB } from 'helpers';
import './styles.scss';

type dataType = { url: string; size: number; name: string; error?: boolean };

interface UploadedFilesListProps {
  data: dataType[];
  setDeleteUrl: (arg: string) => void;
  setConfirmModal: (arg: { show: boolean; text: string; type: null | string; callback?: any }) => void;
  dataFormat: { label: string; value: string };
  setDeleteUrlArr: (arg: string[]) => void;
}

interface FormatOptionType {
  label: string;
  image: '*.svg';
  imageGray: '*.svg';
}

const formatOptions: FormatOptionType[] = [
  { label: 'csv-file', image: csvImage, imageGray: csvImageGray },
  { label: 'text-file', image: txtImage, imageGray: txtImageGray },
  { label: 'json-file', image: jsonImage, imageGray: jsonImageGray },
  { label: 'image-file', image: imageImage, imageGray: imageImageGray },
  { label: 'audio-file', image: audioImage, imageGray: audioImageGray },
  { label: 'pdf-file', image: pdfImage, imageGray: pdfImageGray },
];

const UploadedFilesList: FC<UploadedFilesListProps> = ({
  dataFormat,
  data,
  setDeleteUrl,
  setConfirmModal,
  setDeleteUrlArr,
}) => {
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [seletedURLs, setSeletedURLs] = useState<string[]>([]);
  const [dataLength, setDataLength] = useState(0);

  const getImage = (isGray: boolean | undefined) => {
    if (dataFormat) {
      return isGray
        ? formatOptions.find((item: FormatOptionType) => dataFormat?.value === item.label)?.imageGray
        : formatOptions.find((item: FormatOptionType) => dataFormat?.value === item.label)?.image;
    }
  };

  const handleSelectUrls = (url: string): void => {
    const set = new Set(seletedURLs);

    if (set.has(url)) {
      set.delete(url);
      setSeletedURLs([...set]);
      setIsSelectAll(false);
      return;
    }
    set.add(url);
    setSeletedURLs([...set]);
    if (set.size === dataLength) {
      setIsSelectAll(true);
    }
  };

  useEffect(() => {
    if (isSelectAll) {
      const urls = data.filter((item: dataType) => item.url !== '').map((item: dataType) => item.url);
      setSeletedURLs(urls);
      return;
    }
    if (!isSelectAll && seletedURLs.length === dataLength) {
      setSeletedURLs([]);
    }
  }, [isSelectAll]);

  useEffect(() => {
    if (seletedURLs.length < dataLength) {
      setIsSelectAll(false);
    }
  }, [seletedURLs]);

  useEffect(() => {
    setDataLength(data.filter((item: dataType) => item.url !== '').length);
  }, [data]);

  return (
    <div className="mb-2">
      <div className="uploaded-list-wrapper">
        {dataLength > 1 ? (
          <div className="d-flex mb-2 align-items-center justify-content-start">
            <Checkbox
              labelRight={`${seletedURLs.length || ''} Select all`}
              className="select-all mr-2"
              checked={isSelectAll}
              onChange={() => setIsSelectAll(prev => !prev)}
            />
            <button
              disabled={seletedURLs.length === 0}
              className="delete-trash-icon"
              onClick={() => {
                setDeleteUrlArr(seletedURLs);
                setConfirmModal({
                  show: true,
                  text: `Are you sure you want to delete selected file${seletedURLs.length > 1 ? 's' : ''}?`,
                  type: 'delete',
                });
              }}
            >
              {grayTrashIcon}
              <span> Delete</span>
            </button>
          </div>
        ) : null}
        <div style={{ maxHeight: '470px', overflow: 'auto' }}>
          {data.map((obj: dataType) => (
            <div className="ls-item" key={obj.url}>
              <div className="d-flex align-item-center w-100 justify-content-between">
                <div className="ls-item-main">
                  {dataLength > 1 && !obj.error ? (
                    <Checkbox
                      className="ml-2"
                      checked={seletedURLs.includes(obj.url)}
                      onChange={() => handleSelectUrls(obj.url)}
                    />
                  ) : null}
                  <div className="d-flex align-items-center py-2 mx-3" style={{ maxWidth: 'calc(100% - 20px)' }}>
                    <img
                      className={`${dataLength > 1 && obj.error ? 'error-img' : ''}`}
                      src={getImage(obj?.error)}
                      alt={obj.name}
                    />
                    <div className="ml-2" style={{ maxWidth: 'calc(100% - 20px)' }}>
                      <p title={obj.name} className="text-truncate">
                        {obj.name}
                      </p>
                      {obj.size === 0 ? null : <p className="no-wrap">{getMB(obj.size)}</p>}
                    </div>
                  </div>
                  <span>
                    {obj.error ? (
                      <Tooltip placement="right" text="Upload failed">
                        {infoIconCircle}
                      </Tooltip>
                    ) : (
                      checkIcon
                    )}
                  </span>
                </div>
                {obj.error ? null : (
                  <span
                    className="delete-icon"
                    onClick={() => {
                      setDeleteUrl(obj.url);
                      setConfirmModal({
                        show: true,
                        text: 'Are you sure you want to delete the file?',
                        type: 'delete',
                      });
                    }}
                  >
                    {closeIcon}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UploadedFilesList;
