import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
import { traficConfigOptions } from '../../constants';
import { useAutomationGlobalMethods } from '../../hooks';
import Input from 'shared-components/atom/input';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import CustomMultiSelect from 'shared-components/molecule/multiselect';
import { isValidUrl } from 'constants/validations';
import './styles.scss';

const TrafficInsightsSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setErrorMessage,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [fieldList, setFieldList] = useState<any>([]);
  const [clicked, setClicked] = useState(false);
  const [targetText, setTargetText] = useState('');
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [selectedTrafigConfigs, setSelectedTrafigConfigs] = useState<any>([]);
  const [error, setError] = useState(false);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const {
    liftingSettings,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (error) return false;
    if (!selectedTrafigConfigs || selectedTrafigConfigs?.length === 0) return false;
    if (fieldList?.length === 0) {
      if (!targetText) return false;
    } else {
      if (!selectedSource) return false;
    }
    return true;
  };

  //recipe
  useEffect(() => {
    // if (fieldList.length > 0) return;

    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();

    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const { keys } = dynamic_settings;
      const { settings } = keys[0];

      const inputValue = settings ? keys[0].inputValue : keys[0].target;
      const traffic_configs = settings ? settings.traffic_configs : keys[0].traffic_configs;

      const restoreSelectedConfigs = traffic_configs.map((configName: string) => {
        const findedField = traficConfigOptions.find(
          configOption => configOption.value === configName || configOption.label === configName,
        );
        return findedField;
      });
      restoreSelectedSource({ key: keys[0].key, fieldList: options, setSelectedSource: setSelectedSource });
      setSelectedTrafigConfigs(restoreSelectedConfigs);
      setTargetText(inputValue);
    }
    const filteredOptions = automationInputTypes && !automationInputTypes.includes('any') ? 
    options.filter((item: any) => (optionsWithTypes && optionsWithTypes[item.value] && (automationInputTypes.includes(optionsWithTypes[item.value]) || optionsWithTypes[item.value] === 'any')) || !(optionsWithTypes && optionsWithTypes[item.value])) :
    [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      outputKeysData: [
        ...fieldList.map((item: { value: any }) => item.value),
        ...selectedTrafigConfigs.map((item: { value: any }) => item.value),
      ],
      outputKeysDataWithTypes: {...fieldListWithTypes, ...jsonParse},
    });
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);

  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    setClicked(false);
    const hashId: any = new Date().getTime().toString();

    let localSettings = {
      keys: [
        {
          inputValue: fieldList.length === 0 && automationIndex === 0 ? targetText.trim() : null,
          key: fieldList.length && selectedSource ? selectedSource.value : null,
          settings: {
            // target: fieldList.length === 0 ? targetText : null,
            traffic_configs: selectedTrafigConfigs
              ? [...selectedTrafigConfigs.map((item: { value: any }) => item.value)]
              : [],
            uniqueHash: hashId,
          },
        },
      ],
    };
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: localSettings,
      outputKeysData: [
        ...fieldList.map((item: { value: any }) => item.value),
        ...selectedTrafigConfigs.map((item: { value: any }) => item.value),
      ],
      outputKeysDataWithTypes: {...fieldListWithTypes, ...jsonParse},
    });
  }, [targetText, selectedSource, selectedTrafigConfigs, fieldList, fieldListWithTypes, automationOutputTypes, automationIndex]);

  const handleChangeInput = (e: any) => {
    setTargetText(e.target.value);
    setError(!isValidUrl(e.target.value));
    setErrorMessage && setErrorMessage(!isValidUrl(e.target.value) ? 'URL is not valid' : '');
    !clicked && setClicked(true);
    setIsDirty();
  };
  const handleSelectChange = (selectedItem: any) => {
    setSelectedSource(selectedItem);
    !clicked && setClicked(true);
    setIsDirty();
  };
  const handleSelectChangeMultiselect = (el: any) => {
    setSelectedTrafigConfigs(el);
    !clicked && setClicked(true);
    setIsDirty();
  };

  return (
    <div className="trafic-insight-settings">
      <Row className="mb-4">
        {fieldList.length > 0 || automationIndex > 0 ? (
          <Col lg={6} className="">
            <span className="label secondary-color">Source</span>
            <Select
              options={fieldList}
              value={selectedSource}
              placeholder={'Select source'}
              onChange={handleSelectChange}
            />
          </Col>
        ) : (
          <Col lg={6} className="">
            <span className="label secondary-color">Website URL</span>
            <Input value={targetText} className={`${error ? 'border-danger' : ''}`} onChange={handleChangeInput} />
          </Col>
        )}
      </Row>
      <Row className="mb-4">
        <Col lg={6}>
          <span className="label secondary-color">Trafic config</span>
          <CustomMultiSelect
            options={traficConfigOptions}
            value={selectedTrafigConfigs}
            onChange={handleSelectChangeMultiselect}
            labelledBy="Select"
          />
        </Col>
      </Row>
    </div>
  );
};

export default React.memo(TrafficInsightsSettings);
