import { FC, useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { HOST } from 'constants/host';
import { GET_WORKFLOW_RESULT_CSV, GET_WORKFLOW_RESULT_JSON } from 'graphql/queries';

import Select from 'shared-components/atom/select';
import Checkbox from 'shared-components/atom/checkbox';
import Loader from 'shared-components/atom/loader';
import Button from 'shared-components/atom/button';
import Tooltip from 'shared-components/atom/tooltip';

import useCustomDownloadOption from './useCustomDownloadOption';

import { closeIcon, downloadIcon, infoIcon } from 'assets/svg-icons';
import './styles.scss';

const exportOptionsArr: ReadonlyArray<{ label: string; value: string }> = [
  { label: 'All', value: 'all' },
  { label: 'Custom', value: 'custom' },
];

const downloadJSON = ({ data, fileName }: { data: any; fileName: string }): void => {
  let json: any = JSON.stringify(data);
  json = [json];
  var blob1 = new Blob(json, { type: 'application/json;charset=utf-8' });
  var url = window.URL || window.webkitURL;
  let link: any = url.createObjectURL(blob1);
  var a = document.createElement('a');
  a.download = fileName;
  a.href = link;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

interface ExportModalWithOptionsProps {
  workflowId: number;
  setShow: (arg: boolean) => void;
  scanId?: string;
  setShowToastInfo?: (show: boolean, errorMessage: string, loadingMessage: string, successMessage: string) => void;
  showUpgradeModal?: (val: boolean) => void;
  noCustom?: boolean;
  selectedHeaders?: any;
  setSelectedHeaders?: any;
}

const ExportModalWithOptions: FC<ExportModalWithOptionsProps> = ({
  workflowId,
  setShow,
  setShowToastInfo,
  showUpgradeModal,
  scanId,
  noCustom,
  selectedHeaders,
  setSelectedHeaders,
}) => {
  const history = useHistory();
  const [exportOption, setExportOption] = useState<{ label: string; value: string }>(exportOptionsArr[0]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const {
    columns,
    loading: columnLoading,
    gqlError,
    error: columnError,
  } = useCustomDownloadOption(exportOption.value === 'custom', +workflowId);

  const [selected, setSelected] = useState<any>();
  const [loadingSheets, setLoadingSheets] = useState(false);
  const [error, setError] = useState(false);
  const [customImportCheckbox, setCustomImportCheckbox] = useState({} as any);
  const [newTab, setNewTab] = useState(null as any);

  const handleLinkClick = (url: any, google?: boolean): void => {
    if (google && newTab) {
      newTab && (newTab.location.href = url);
    } else {
      const a = document.createElement('a');
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const [getCSV, { loading }] = useLazyQuery(GET_WORKFLOW_RESULT_CSV, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      const url = data?.HexomaticWorkflow?.getWorkflowResultCSV?.csv_url;
      const error = data?.HexomaticWorkflow?.getWorkflowResultCSV?.error_code;
      if (url) {
        if (selected === 'Download CSV') {
          handleLinkClick(url);
          setShowToastInfo && setShowToastInfo(true, '', '', 'Download completed!');
          setShow(false);
        } else if (selected === 'Google Sheets') {
          handleLinkClick(
            `${HOST.APP_API.APP}/social-callback/google/drive/v1/uploadFile?url=${url.replaceAll('&', '%26')}`,
            true,
          );
          setLoadingSheets(false);
          setShow(false);
          setShowToastInfo && setShowToastInfo(false, '', '', '');
        }
        setSelectedHeaders && setSelectedHeaders(
          customImportCheckbox && Object.values(customImportCheckbox).length && exportOption.value === 'custom'
            ? Object.entries(customImportCheckbox)
                .map(item => {
                  if (item[1]) {
                    return item[0];
                  }
                })
                .filter((sub: any) => sub)
            : null,
          workflowId
        );
      }
      if (error) {
        newTab && newTab.close();
        setNewTab(null);
        if (error.includes('credits')) {
          showUpgradeModal && showUpgradeModal(true);
          setShowToastInfo && setShowToastInfo(false, '', '', '');
        } else {
          setShowToastInfo &&
            setShowToastInfo(
              true,
              error === 'CSV_DATA_IS_TO_LARGE'
                ? 'CSV data is too large, please download JSON.'
                : error === 'Credit limit reached'
                ? 'Credit limit reached.'
                : 'Something went wrong. Please try again',
              '',
              '',
            );
        }
        setShow(false);
      }
    },
  });

  const [getJSON, { loading: loadingJSON }] = useLazyQuery(GET_WORKFLOW_RESULT_JSON, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      const url = data?.HexomaticWorkflow?.getWorkflowResultJSON?.json_url;
      const error = data?.HexomaticWorkflow?.getWorkflowResultJSON?.error_code;
      setShowToastInfo && setShowToastInfo(true, '', 'Download will begin shortly...', '');
      setShow(false);
      if (url) {
        setShowToastInfo && setShowToastInfo(true, '', '', 'Download completed!');
        setShow(false);
        fetch(url)
          .then(res => res.json())
          .then(data => {
            downloadJSON({ data, fileName: `${url.split('/')[url.split('/').length - 1]}` });
          });
        setSelectedHeaders && setSelectedHeaders(
          customImportCheckbox && Object.values(customImportCheckbox).length && exportOption.value === 'custom'
            ? Object.entries(customImportCheckbox)
                .map(item => {
                  if (item[1]) {
                    return item[0];
                  }
                })
                .filter((sub: any) => sub)
            : null,
          workflowId,
        );
      }

      if (error && error.includes('credits')) {
        showUpgradeModal && showUpgradeModal(true);
        setShowToastInfo && setShowToastInfo(false, '', '', '');
      } else if (error) {
        setShowToastInfo &&
          setShowToastInfo(
            true,
            error === 'Credit limit reached' ? 'Credit limit reached.' : 'Something went wrong. Please try again',
            '',
            '',
          );
      }
    },
  });

  useEffect(() => {
    if (selectedHeaders?.length) {
      setExportOption(exportOptionsArr[1]);
    }
  }, [selectedHeaders]);

  useEffect(() => {
    const list: { [key: string]: boolean } = {};
    if (!isAllSelected && Object.values(customImportCheckbox).filter(item => !item).length) {
      return;
    }
    Object.keys(customImportCheckbox).forEach((item: string) => {
      list[item] = isAllSelected;
    });
    setCustomImportCheckbox(list);
  }, [isAllSelected]);

  useEffect(() => {
    if (columns.length) {
      const list: { [key: string]: boolean } = {};
      columns.forEach((item: string) => {
        const bool = selectedHeaders ? !!selectedHeaders.find((sub: string) => sub === item) : false;
        list[item] = bool;
      });

      setCustomImportCheckbox(list);
    }
  }, [columns, selectedHeaders]);

  useEffect(() => {
    if (loadingJSON || loading) {
      setShowToastInfo && setShowToastInfo(true, '', 'Preparing file for download...', '');
      return;
    }

    if (gqlError || columnError) {
      setShowToastInfo && setShowToastInfo(true, 'Someting went wrong try again', '', '');
    }
  }, [loadingJSON, loading, columnError, gqlError]);

  const getCSVFunc = (option: string, headers?: string[]) => {
    setSelected(option);

    if (option === 'Download JSON') {
      getJSON({
        variables: {
          settings: {
            workflow_id: +workflowId,
            scanId: scanId ? scanId : null,
            headers,
          },
        },
      });
      return;
    }

    if (option === 'Google Sheets') {
      setLoadingSheets(true);
      const newTab = window.open();
      setNewTab(newTab);
    }

    getCSV({
      variables: {
        settings: {
          workflow_id: +workflowId,
          scanId: scanId ? scanId : null,
          headers,
        },
      },
    });
  };

  const downloadCustom = (option: string): void => {
    const arr: string[] = [];
    Object.entries(customImportCheckbox).forEach((el: any) => {
      if (el[1]) {
        arr.push(el[0]);
      }
    });

    if (!arr.length) {
      setShowToastInfo &&
        setShowToastInfo(true, columns.length === 0 ? 'Something went wrong.' : 'Select at least one option.', '', '');
      return;
    }

    if (option === 'Download JSON') {
      getCSVFunc('Download JSON', arr);
    } else if (option === 'Download CSV') {
      getCSVFunc('Download CSV', arr);
    } else {
      setLoadingSheets(true);
      getCSVFunc('Google Sheets', arr);
    }
  };
  useEffect(() => {
    if (columns.length && !loading) {
      const val = !Object.values(customImportCheckbox).filter(item => !item).length;
      if (val !== isAllSelected) {
        setIsAllSelected(val);
      }
    }
  }, [customImportCheckbox]);

  return (
    <div className="export-modal-with-options-wrapper">
      <div className="close-button-wrapper">
        <button onClick={() => setShow(false)}>{closeIcon}</button>
      </div>

      <div className="modal-card-main-wrapper">
        {!noCustom && (
          <div className="d-md-flex">
            <div>
              <span className="select-label primary-color">
                Select columns to include in the results file:
                <Tooltip
                  trigger={['hover']}
                  placement="bottom"
                  text="This enables you to select which columns to include when exporting the results of your workflow."
                  className="reload-icon-tooltip mb-1"
                >
                  <span className="reload-icon">{infoIcon}</span>
                </Tooltip>
              </span>
              <Select
                options={exportOptionsArr}
                value={exportOption}
                placeholder={'Select source'}
                onChange={(selectedItem: { label: string; value: string }) => {
                  setExportOption(selectedItem);
                }}
              />
            </div>
            {exportOption.value === 'custom' && (
              <Checkbox
                checked={isAllSelected}
                label="Select all"
                onChange={() => setIsAllSelected(!isAllSelected)}
                className="mt-3 ml-2"
              />
            )}
          </div>
        )}

        {exportOption.value === 'all' ? (
          <div className={`export-buttons-wrapper all w-100 ${noCustom ? 'justify-content-center' : ''}`}>
            <Button
              loading={loading && !loadingSheets}
              onClick={() => getCSVFunc('Download CSV')}
              name="CSV"
              leftIcon={downloadIcon}
              disabled={loadingJSON || loadingSheets}
            />
            <Button
              loading={loadingJSON}
              onClick={() => getCSVFunc('Download JSON')}
              name="JSON"
              leftIcon={downloadIcon}
              disabled={loading || loadingSheets}
            />
            <Button
              onClick={() => {
                getCSVFunc('Google Sheets');
              }}
              loading={loadingSheets}
              name="Google Sheets"
              leftIcon={downloadIcon}
              disabled={(loading && !loadingSheets) || loadingJSON}
            />
          </div>
        ) : (
          <div className="d-flex w-100  flex-column justify-content-center">
            {columnLoading ? (
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            ) : (
              <>
                <div className="export-option-checkbox-wrapper">
                  {Object.entries(customImportCheckbox).map((el: any) => (
                    <Checkbox
                      // hoverText={el[0]}
                      key={el[0]}
                      checked={el[1]}
                      label={el[0]}
                      onChange={() => setCustomImportCheckbox({ ...customImportCheckbox, [el[0]]: !el[1] })}
                    />
                  ))}
                </div>

                <div className="export-buttons-wrapper">
                  <Button
                    loading={loading && !loadingSheets}
                    onClick={() => downloadCustom('Download CSV')}
                    name="CSV"
                    leftIcon={downloadIcon}
                    disabled={loadingJSON || loadingSheets}
                  />
                  <Button
                    loading={loadingJSON}
                    onClick={() => downloadCustom('Download JSON')}
                    name="JSON"
                    leftIcon={downloadIcon}
                    disabled={loading || loadingSheets}
                  />
                  <Button
                    onClick={() => {
                      downloadCustom('Google Sheets');
                    }}
                    loading={loadingSheets}
                    name="Google Sheets"
                    leftIcon={downloadIcon}
                    disabled={(loading && !loadingSheets) || loadingJSON}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>

      <p className={`select-label primary-color footer-text ${noCustom ? 'justify-content-center' : ''}`}>
        * Exporting results consumes 1 automation credit
      </p>
    </div>
  );
};

export default ExportModalWithOptions;
