import { FC, FormEvent } from 'react';
import { infoIcon } from 'assets/svg-icons';
import './styles.scss';

interface IAppProps {
  value: string;
  onChange: any;
  placeholder?: string;
  name?: string;
  rows?: number;
  className?: string;
  onBlur?: any;
  onFocus?: any;
  disableResize?: boolean;
  infoText?: string;
  maxLength?: number;
}

const TextArea: FC<IAppProps> = ({
  value,
  onChange,
  placeholder,
  name,
  rows,
  className,
  onBlur,
  onFocus,
  disableResize,
  infoText,
  maxLength
}) => {
  const fieldChangeIndex = (e: FormEvent) => {
    onChange(e);
  };

  return (
    <>
      <textarea
        className={`textarea ${className ? className : ''} ${disableResize ? 'disable-resize' : ''}`}
        name={name || 'datainput'}
        placeholder={placeholder}
        value={value}
        rows={rows || 6}
        onChange={fieldChangeIndex}
        onBlur={() => onBlur && onBlur()}
        onFocus={() => onFocus && onFocus()}
        maxLength={maxLength}
      />
      {infoText && (
        <div>
          <span className="info-icon">{infoIcon}</span>
          <span className="info-message">{infoText}</span>
        </div>
      )}
    </>
  );
};

export default TextArea;
