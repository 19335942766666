import { useState } from 'react';
import Button from 'shared-components/atom/button';
import Input from 'shared-components/atom/input';
import { CREATE_API_KEY } from 'graphql/mutations';
import { GET_ALL_INTEGRATIONS, GET_API_KEY } from 'graphql/queries';
import ToolCard from 'shared-components/molecule/tool-card';
import { eyeIcon } from 'assets/svg-icons/index';
import { useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useRef } from 'react';
import { HexomaticUserContext } from 'contexts/hexomatic-user';
import { Col, Row } from 'react-bootstrap';
import Tooltip from 'shared-components/atom/tooltip';
import { useContext } from 'react';
import Modal from 'shared-components/molecule/modal';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { HOST } from 'constants/host';
import Alert from 'shared-components/atom/alert-message';
import { newTabIcon, spinnerIcon } from 'assets/svg-icons';

const Api = ({ allIntegrations, integrationsLoading, refetchIntegrations }: any) => {
  const [genterateKey, setGenterateKey] = useState(null);
  const [createApiKey, { data: cratedApiData, loading: apiLoading }] = useMutation(CREATE_API_KEY);
  const { data: apiData, loading: getLoading } = useQuery(GET_API_KEY);
  const [hidden, setHidden] = useState(true);
  const [isUpdate, setIsUpdate] = useState(true);
  const inputRef = useRef(null);
  const { hexomaticUser } = useContext(HexomaticUserContext);
  const [shouldUpgrade, setShouldUpgrade] = useState(false);
  const [webhookUrl, setWebhookUrl] = useState('');
  const [initialWebhook, setInitialWebhook] = useState('');
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [refetchLoading, setRefetchLoading] = useState(false);

  // const {
  //   data: integrationsData,
  //   loading: integrationsLoading,
  //   refetch: refetchIntegrations,
  // } = useQuery(GET_ALL_INTEGRATIONS, {
  //   fetchPolicy: 'network-only',
  // });

  useEffect(() => {
    if (allIntegrations?.HexomaticIntegrations?.getAllIntegrations?.hexomaticWebhook) {
      const { hexomaticWebhook } = allIntegrations.HexomaticIntegrations.getAllIntegrations;
      if (hexomaticWebhook && hexomaticWebhook.length === 0) {
        return;
      }
      const { data } = hexomaticWebhook[0];
      const parsedData = JSON.parse(data);
      parsedData && setWebhookUrl(parsedData.webhookUrl);
      parsedData && parsedData.webhookUrl && setInitialWebhook(parsedData.webhookUrl);
      setIsUpdate(true);
    }
  }, [allIntegrations]);

  useEffect(() => {
    if (cratedApiData?.HexomaticUserOps?.createWatchApiKey?.watch_api_key) {
      setGenterateKey(cratedApiData.HexomaticUserOps.createWatchApiKey.watch_api_key);
    }
  }, [cratedApiData]);

  useEffect(() => {
    if (apiData?.HexomaticUser?.getWatchApiKey?.watch_api_key) {
      setGenterateKey(apiData.HexomaticUser.getWatchApiKey.watch_api_key);
    }
  }, [apiData]);

  const rightIconClick = (e: { stopPropagation: any }) => {
    e.stopPropagation();
    setHidden(!hidden);
  };

  const onInputClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    //@ts-ignore
    navigator.clipboard.writeText(genterateKey ? genterateKey : '');
    const inputDom = inputRef.current;
    if (!inputDom) {
      return;
    }
    //@ts-ignore
    inputDom.focus();
    //@ts-ignore
    inputDom.setSelectionRange(0, genterateKey ? genterateKey.length : 0);
  };

  const checkAndGenerateKey = () => {
    if (hexomaticUser.pricing_package !== 'GOLD') {
      setShouldUpgrade(true);
      return;
    }
    createApiKey();
  };

  const createWebhook = async () => {
    setLoading(true);
    const res = await axios.post(`${HOST.APP_API.APP}/hexomatic/webhook`, {
      webhookUrl,
      userId: hexomaticUser.id,
    });
    setLoading(false);
    if (res && res.data.message === 'ok') {
      setRefetchLoading(true);
      refetchIntegrations();
      setRefetchLoading(false);
    } else {
      setErrorMessage(res.data.message);
      clearErrorTimeout();
    }
  };

  const updateWebhook = async () => {
    if (!webhookUrl) {
      deleteWebhook();
      return;
    }
    setLoading(true);
    const res = await axios.put(`${HOST.APP_API.APP}/hexomatic/webhook`, {
      webhookUrl,
      userId: hexomaticUser.id,
    });
    setLoading(false);
    if (res && res.data.message === 'ok') {
      setRefetchLoading(true);
      refetchIntegrations();
      setRefetchLoading(false);
    } else {
      setErrorMessage(res.data.message);
      clearErrorTimeout();
    }
    setRefetchLoading(true);
    refetchIntegrations();
    setRefetchLoading(false);
  };

  const deleteWebhook = async () => {
    setLoading(true);
    const res = await axios.delete(`${HOST.APP_API.APP}/hexomatic/webhook/${hexomaticUser.id}`);
    setLoading(false);
    if (res && res.data.message === 'Deleted') {
      setRefetchLoading(true);
      refetchIntegrations();
      setRefetchLoading(false);
      setInitialWebhook('');
    } else {
      setErrorMessage(res.data.message);
      clearErrorTimeout();
    }
  };

  const clearErrorTimeout = () => {
    setTimeout(() => {
      setErrorMessage('');
    }, 5000);
  };

  return (
    <ToolCard
      title="API/Webhook"
      hexowatch
      loading={apiLoading || refetchLoading || integrationsLoading}
      icon={<div className="icon-wrapper-settings-email">{spinnerIcon}</div>}
      openClose
    >
      <div className="api-webhook">
        <div className={`api-webhook-settings ${!hidden ? 'active' : ''}`}>
          {genterateKey && hexomaticUser.pricing_package === 'GOLD' ? (
            <div>
              <label className="label">API key</label>
              <Tooltip trigger={['click', 'focus']} placement="top" text="API key copied to clipboard">
                <div className={`input-wrapper ${!hidden ? 'active' : ''}`}>
                  <span className={`icon right pointer noselect`} onClick={rightIconClick}>
                    {eyeIcon}
                  </span>
                  <input
                    type={hidden ? 'password' : 'text'}
                    value={genterateKey || ''}
                    readOnly
                    ref={inputRef}
                    className={`input-field right-icon`}
                    onClick={onInputClick}
                  />
                </div>
              </Tooltip>
              <a
                href="https://hexomatic.com/api-documentation/"
                className="clickable-text-with-icon"
                target="_blank"
                rel="noopener"
              >
                API documentation {newTabIcon}
              </a>
            </div>
          ) : (
            <div className="btn-and-link">
              <Button
                name="Generate API key"
                loading={getLoading}
                onClick={() => checkAndGenerateKey()}
                disabled={!hexomaticUser || !hexomaticUser.pricing_package}
              />
              <a
                href="https://hexomatic.com/api-documentation/"
                className="clickable-text-with-icon"
                target="_blank"
                rel="noopener"
              >
                API documentation {newTabIcon}
              </a>
            </div>
          )}
        </div>
        <div className="webhook-block">
          <label className="label">Webhook</label>
          <div>
            <Input value={webhookUrl} onChange={e => setWebhookUrl(e.target.value)} />
          </div>
        </div>
        {errorMessage && (
          <Row className="mb-4">
            <Col md={12}>
              <Alert type="error">{errorMessage}</Alert>
            </Col>
          </Row>
        )}
        <div className="btn-block">
          <Button
            name={'Save'}
            loading={loading}
            onClick={() => (isUpdate ? updateWebhook() : createWebhook())}
            disabled={!initialWebhook && !webhookUrl}
          />
        </div>
      </div>
      <Modal
        isModalOpen={shouldUpgrade}
        title={'Please upgrade your plan to continue'}
        actionBtnText={'Upgrade'}
        cancelBtnText={'Cancel'}
        actionBtnColor={'warning'}
        cancelBtnColor={'secondary'}
        onActionClick={() => {
          window.location.href='https://hexomatic.com/pricing/'
        }}
        onCancelClick={() => setShouldUpgrade(false)}
        escButtonClick={() => setShouldUpgrade(false)}
      />
    </ToolCard>
  );
};

export default Api;
