import { FC, memo, useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';

import { UPDATE_HEXOMATIC_USER_SETTINGS } from 'graphql/mutations';
import { GET_HEXOMATIC_USER_SETTINGS } from 'graphql/queries';

import ToolCard from 'shared-components/molecule/tool-card';
import ToggleBtn from 'shared-components/atom/toggle';
import Message from 'shared-components/atom/alert-message';

import { shareIcon, workflowIcon } from 'assets/svg-icons/index';
import recipeIcon from 'assets/icons/recipe.svg';

import './styles.scss';

const SharingConfigs: FC = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isAllowedRecipe, setIsAllowedRecipe] = useState(true);
  const [isAllowedWorkflow, setIsAllowedWorkflow] = useState(true);

  const { data: userSettings, loading, refetch } = useQuery(GET_HEXOMATIC_USER_SETTINGS);
  const [updateUserSettings, { loading: updateSettingsLoading }] = useMutation(UPDATE_HEXOMATIC_USER_SETTINGS);

  useEffect(() => {
    if (userSettings?.HexomaticUser?.getUserSettings) {
      typeof userSettings?.HexomaticUser?.getUserSettings?.allow_sharing_recipe === 'boolean' &&
        setIsAllowedRecipe(userSettings.HexomaticUser.getUserSettings.allow_sharing_recipe);

      typeof userSettings?.HexomaticUser?.getUserSettings?.allow_sharing_workflow === 'boolean' &&
        setIsAllowedWorkflow(userSettings.HexomaticUser.getUserSettings.allow_sharing_workflow);
    }
  }, [userSettings]);

  const handleRecipeShare = async (): Promise<any> => {
    const res = await updateUserSettings({
      fetchPolicy: 'no-cache',
      variables: {
        settings: {
          allow_sharing_recipe: !isAllowedRecipe,
        },
      },
    });
    if (res?.data?.HexomaticUserOps?.createUserSettings?.error_code) {
      setErrorMessage('Something went wrong. Please try again.');
    } else {
      setIsAllowedRecipe(!isAllowedRecipe);
      refetch();
    }
  };

  const handleWorkflowShare = async (): Promise<any> => {
    const res = await updateUserSettings({
      fetchPolicy: 'no-cache',
      variables: {
        settings: {
          allow_sharing_workflow: !isAllowedWorkflow,
        },
      },
    });
    if (res?.data?.HexomaticUserOps?.createUserSettings?.error_code) {
      setErrorMessage('Something went wrong. Please try again.');
    } else {
      setIsAllowedWorkflow(!isAllowedWorkflow);
      refetch();
    }
  };

  return (
    <ToolCard
      title="Sharing configs"
      hexowatch
      loading={loading || updateSettingsLoading}
      icon={<div className="icon-wrapper-settings-sharing-configs">{shareIcon}</div>}
      openClose
    >
      <div className="sharing-configs-wrapper">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <img className="mr-3" height="20px" width="20px" src={recipeIcon} alt="scraping recipe" />

            <h5>Allow sharing scraping recipes with me</h5>
          </div>
          <ToggleBtn onChange={handleRecipeShare} checked={isAllowedRecipe} />
        </div>

        <div className="d-flex mt-4 align-items-center justify-content-between">
          <div className="d-flex">
            <div className="mr-3">{workflowIcon}</div>
            <h5>Allow sharing workflows with me</h5>
          </div>
          <ToggleBtn onChange={handleWorkflowShare} checked={isAllowedWorkflow} />
        </div>
      </div>

      {errorMessage && (
        <div className="mt-3 text-truncate">
          <Message type="error" text={errorMessage} />
        </div>
      )}
    </ToolCard>
  );
};

export default memo(SharingConfigs);
