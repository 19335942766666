import { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
import WindowedSelect from 'react-windowed-select';
import { tripadvisorSearchRestrictedCountOptions } from '../../constants';
import tripadvisorLocationByCountryName from '../../constants/tripadvisor/location';
import { useAutomationGlobalMethods } from '../../hooks';
import Input from 'shared-components/atom/input';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import Alert from 'shared-components/atom/alert-message';
import './styles.scss';

const output = [
  '_tripadvisor_rank_group',
  '_tripadvisor_rank_absolute',
  '_tripadvisor_title',
  '_tripadvisor_url_path',
  '_tripadvisor_is_sponsored',
  '_tripadvisor_reviews_count',
  '_tripadvisor_rating_type',
  '_tripadvisor_rating_value',
  '_tripadvisor_rating_votes_count',
  '_tripadvisor_rating_max',
];

const TripadvisorSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [fieldList, setFieldList] = useState<any>([]);
  const [clicked, setClicked] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedMaxSearchCount, setSelectedMaxSearchCount] = useState(tripadvisorSearchRestrictedCountOptions[0]);
  const [selectedSource, setSelectedSource] = useState<any>([]);
  const [saveFrom, setSaveFrom] = useState(1);
  const [saveTo, setSaveTo] = useState(30);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedByCountryLocation, setSelectedByCountryLocation] = useState<{ label: string; value: string } | null>(
    null,
  );
  const [selectedByStateLocation, setSelectedByStateLocation] = useState<{ label: string; value: string } | null>(null);
  const [regionOptions, setRegionOptions] = useState<any>(null);
  const [unitedStatesOptions, setUnitedStatesOptions] = useState<any>(null);
  const [unitedStateOptions, setUnitedStateOptions] = useState<any>(null);
  const [selectFullLocation, setSelectFullLocation] = useState<{ label: string; value: string } | null>(null);

  const zeroIndexOutput: string[] = automationIndex === 0 ? ['_tripadvisor_keyword'] : [];
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const {
    liftingSettings,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (errorMessage || !saveFrom || !saveTo || !selectedMaxSearchCount || !selectFullLocation) {
      return false;
    }
    if (fieldList?.length === 0 ? !searchKeyword?.trim() : Array.isArray(selectedSource) ? !selectedSource?.length : !selectedSource) return false;
    return true;
  };

  const restoreStates = async ({ stateName, location_code }: any) => {
    const filename = stateName.split(' ').join('-');
    const { options } = await getExactUnitedStateOptions(filename);
    const findedLocation = options.find((item: { value: string }) => item.value === location_code);
    findedLocation && setSelectFullLocation(findedLocation);
    setUnitedStateOptions(options);
    setSelectedByStateLocation({ label: stateName, value: stateName });
  };

  const restoreRegions = async ({ countryName, location_code }: any) => {
    const filename = countryName.split(' ').join('-');
    if (filename === 'United-States') {
      const { options } = await getUnitedStatesOptions();
      setUnitedStatesOptions(options);
      return;
    }
    const { options } = await getbyCountriesLocationOptions(filename);
    const findedLocation = options.find((item: { value: string }) => item.value === location_code);
    findedLocation && setSelectFullLocation(findedLocation);
    if (options.length > 1) {
      setRegionOptions(options);
    }
  };

  useEffect(() => {
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const { keys } = dynamic_settings;
      const { settings } = keys[0];
      const depth = settings.depth;
      const inputValue = keys[0].inputValue;
      const location_code = settings.location_code;
      const save_from = settings.save_from;
      const save_to = settings.save_to;
      const countryName = settings.countryName;
      const stateName = settings.stateName;

      setSearchKeyword(inputValue);
      const findedCount = tripadvisorSearchRestrictedCountOptions.find(item => item.value === depth);
      findedCount && setSelectedMaxSearchCount(findedCount);
      countryName && setSelectedByCountryLocation({ label: countryName, value: countryName });
      if (stateName) {
        restoreStates({ stateName, location_code });
      }
      countryName && restoreRegions({ countryName, location_code });
      //there may be zero here, since initially these fields were not
      setSaveFrom(save_from === undefined ? 1 : save_from);
      setSaveTo(save_to === undefined ? 30 : save_to);
    }
  }, []);

  //recipe
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();

    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const key = dynamic_settings.keys[0].key;
      restoreSelectedSource({ key: key, fieldList: options, setSelectedSource: setSelectedSource });
    }
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...zeroIndexOutput, ...output],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);

  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);
    const hashId: any = new Date().getTime().toString();

    let localSettings = {
      keys: [
        {
          inputValue: fieldList.length === 0 && automationIndex === 0 ? searchKeyword.trim() : null,
          key: fieldList.length && selectedSource ? selectedSource.value : null,
          settings: {
            depth: selectedMaxSearchCount ? +selectedMaxSearchCount.value : null,
            location_code: selectFullLocation ? selectFullLocation.value : null,
            save_from: saveFrom,
            save_to: saveTo,
            uniqueHash: hashId,
            //for front
            countryName: selectedByCountryLocation?.value,
            stateName: selectedByStateLocation?.value,
          },
        },
      ],
    };
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: localSettings,
      outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...zeroIndexOutput, ...output],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [
    searchKeyword,
    selectedSource,
    selectedMaxSearchCount,
    saveTo,
    errorMessage,
    saveFrom,
    selectFullLocation,
    selectedByCountryLocation,
    selectedByStateLocation,
    automationIndex,
  ]);

  const clearErrorMsg = () => {
    setTimeout(() => {
      setErrorMessage('');
    }, 3000);
  };

  const handleSaveFrom = (e: { target: { value: any } }) => {
    let val = e.target.value;
    if (isNaN(val)) {
      setErrorMessage('Please use only numbers');
      clearErrorMsg();
      return;
    }
    if (selectedMaxSearchCount.value <= +val) {
      setErrorMessage('"Save from" value can`t be greater than or equal to "Search result" value');
      clearErrorMsg();
      return;
    }
    if (saveTo <= +val) {
      setErrorMessage('Save from" value can`t be greater than or equal to "Save to result" value');
      clearErrorMsg();
      return;
    }
    setSaveFrom(+val);
    errorMessage && setErrorMessage('');
    !clicked && setClicked(true);
    setIsDirty();
  };

  const handleSaveTo = (e: { target: { value: any } }) => {
    let val = e.target.value;
    if (isNaN(val)) {
      setErrorMessage('Please use only numbers');
      clearErrorMsg();
      return;
    }
    if (selectedMaxSearchCount.value < +val) {
      setErrorMessage('"Save to" value can`t be greater than "Search result" value');
      clearErrorMsg();
      return;
    }
    setSaveTo(+val);
    errorMessage && setErrorMessage('');
    !clicked && setClicked(true);
    setIsDirty();
  };

  const getExactUnitedStateOptions = async (filename: string) => {
    return await import(`../../constants/tripadvisor/by-united-states/${filename}.tsx`);
  };

  const getbyCountriesLocationOptions = async (filename: string) => {
    return await import(`../../constants/tripadvisor/by-countries/${filename}.tsx`);
  };

  const getUnitedStatesOptions = async () => {
    return await import(`../../constants/tripadvisor/by-united-states/index`);
  };

  const onSelectUnitedStates = async (selectedOption: any) => {
    if (selectedOption && selectedByStateLocation && selectedOption.value === selectedByStateLocation.value) return;
    !clicked && setClicked(true);
    setIsDirty();
    const filename = selectedOption.label.split(' ').join('-');
    const { options } = await getExactUnitedStateOptions(filename);
    setUnitedStateOptions(options);
    setSelectedByStateLocation(selectedOption);
    setSelectFullLocation(null);
  };

  const onSelectLocation = async (selectedOption: any) => {
    if (selectedOption && selectedByCountryLocation && selectedOption.value === selectedByCountryLocation.value) return;
    !clicked && setClicked(true);
    setIsDirty();
    const filename = selectedOption.label.split(' ').join('-');
    if (filename === 'United-States') {
      const { options } = await getUnitedStatesOptions();
      setUnitedStatesOptions(options);
      setRegionOptions(null);
      setSelectFullLocation(null);
      setSelectedByCountryLocation(selectedOption);
      return;
    }
    const { options } = await getbyCountriesLocationOptions(filename);
    if (options.length === 1) {
      setSelectFullLocation(options[0]);
      setUnitedStatesOptions(options);
      setRegionOptions(null);
    } else {
      setSelectFullLocation(null);
      setRegionOptions(options);
    }
    setUnitedStateOptions(null);
    setUnitedStatesOptions(null);
    setSelectedByStateLocation(null);
    setSelectedByCountryLocation(selectedOption);
  };
  return (
    <div className="google-automation-settings">
      <Row className="first-row">
        {fieldList.length > 0 || automationIndex > 0 ? (
          <Col lg={6} className="mb-4">
            <span className="label secondary-color">Keyword</span>
            <Select
              options={fieldList}
              value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
              placeholder={'Select source'}
              onChange={(selectedItem: any) => {
                setSelectedSource(selectedItem);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
        ) : (
          <Col lg={6} className="mb-4">
            <span className="label secondary-color">Keyword</span>
            <Input
              value={searchKeyword}
              onChange={e => {
                setSearchKeyword(e.target.value);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
        )}
        <Col xl={6} className="search-fields">
          <Row>
            <Col lg={4} xl={4} className="mb-4">
              <span className="label secondary-color">Search results</span>
              <Select
                fullWidth
                options={tripadvisorSearchRestrictedCountOptions}
                value={selectedMaxSearchCount}
                placeholder={'Select column'}
                onChange={(selectedItem: any) => {
                  setSelectedMaxSearchCount(selectedItem);
                  setSaveFrom(1);
                  setSaveTo(selectedItem.value);
                  !clicked && setClicked(true);
                  setIsDirty();
                }}
              />
            </Col>
            <Col lg={4} xl={4} className="mb-4 results-from-to">
              <h5 className="pointer-title">From</h5>
              <div>
                <span className="label secondary-color" style={{ whiteSpace: 'nowrap' }}>
                  Save results
                </span>
                <Input value={saveFrom} onChange={handleSaveFrom} />
              </div>
            </Col>
            <Col lg={4} xl={4} className="mb-4 results-from-to">
              <h5 className="pointer-title">To</h5>
              <div>
                <span className="label secondary-color" style={{ visibility: 'hidden' }}>
                  to
                </span>
                <Input value={saveTo} onChange={handleSaveTo} />
              </div>
            </Col>
          </Row>
        </Col>

        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Country</span>
          <WindowedSelect
            options={tripadvisorLocationByCountryName}
            onChange={onSelectLocation}
            value={selectedByCountryLocation}
          />
        </Col>
        {regionOptions && (
          <Col lg={6} className="mb-4">
            <span className="label secondary-color">City</span>
            <WindowedSelect
              options={regionOptions}
              onChange={(selectedOption: any) => {
                if (selectedOption && selectFullLocation && selectedOption.value === selectFullLocation.value) return;
                !clicked && setClicked(true);
                setIsDirty();
                setSelectFullLocation(selectedOption);
              }}
              value={selectFullLocation}
              open
            />
          </Col>
        )}

        {unitedStatesOptions && (
          <Col lg={6} className="mb-4">
            <span className="label secondary-color">State</span>
            <WindowedSelect
              options={unitedStatesOptions}
              onChange={onSelectUnitedStates}
              value={selectedByStateLocation}
            />
          </Col>
        )}
        {unitedStateOptions && (
          <Col lg={6} className="mb-4">
            <span className="label secondary-color">City</span>
            <WindowedSelect
              options={unitedStateOptions}
              onChange={(selectedOption: any) => {
                if (selectedOption && selectFullLocation && selectedOption.value === selectFullLocation.value) return;
                !clicked && setClicked(true);
                setIsDirty();
                setSelectFullLocation(selectedOption);
              }}
              value={selectFullLocation}
            />
          </Col>
        )}
      </Row>
      {errorMessage && (
        <Row className="mb-4">
          <Col md={12}>
            <Alert type="error" className="create-workflow">
              {errorMessage}
            </Alert>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default TripadvisorSettings;
