import React, { FC, useEffect, useState } from 'react';
import { useAutomationGlobalMethods } from '../../hooks';
import { Col, Row } from 'react-bootstrap';
import Input from 'shared-components/atom/input';
import Select from 'shared-components/atom/select';

const outputs = [
  '_rss_feed_title',
  '_rss_feed_link',
  '_rss_feed_description',
  '_rss_feed_image_url',
  '_rss_feed_image_title',
  '_rss_feed_image_link',
  '_rss_feed_item_title',
  '_rss_feed_item_link',
  '_rss_feed_item_guid',
  '_rss_feed_item_category',
  '_rss_feed_item_enclosure_url',
  '_rss_feed_item_enclosure_type',
  '_rss_feed_item_description',
  '_rss_feed_item_pubDate',
];

const RssSettings: FC<any> = ({
  automationSendSettings,
  selectedRecipes,
  automationIndex,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);
  const [clicked, setClicked] = useState(false);
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [inputText, setInputText] = useState('');
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const {
    getPreviousAutomationOutputOrPreviousRecipKeys,
    liftingSettings,
    restoreSelectedSource,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (fieldList?.length === 0) {
      if (!inputText) {
        return false;
      }
    } else {
      if (!selectedSource) {
        return false;
      }
    }
    return true;
  };

  //getting previous outputs
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const currentAutomation = automationSendSettings.find((automation: any, index: any) => index === automationIndex);
    const { dynamic_settings } = currentAutomation;

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (automationSendSettings) {
      const currentAutomation = automationSendSettings.find((automation: any, index: any) => index === automationIndex);
      const { dynamic_settings } = currentAutomation;
      if (dynamic_settings) {
        if (fieldList.length === 0) {
          const input = dynamic_settings.keys[0].input || dynamic_settings.keys[0].inputValue;
          setInputText(input);
        } else {
          const key = dynamic_settings.keys[0].key;
          restoreSelectedSource({ key: key, fieldList: fieldList, setSelectedSource: setSelectedSource });
        }
      }
    }
  }, [fieldList]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...outputs],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);

  //lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: {
        keys: [
          {
            inputValue: fieldList?.length === 0 && automationIndex === 0 ? inputText.trim() : null,
            key: fieldList?.length && selectedSource ? selectedSource.value : null,
            settings: null,
          },
        ],
      },
      outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...outputs],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [inputText, selectedSource]);

  return (
    <div className="">
      {fieldList.length > 0 || automationIndex > 0 ? (
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Source</span>
          <Select
            options={fieldList}
            value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
            placeholder={'Select source'}
            onChange={(selectedItem: any) => {
              setSelectedSource(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
      ) : (
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">URL</span>
          <Input
            value={inputText}
            onChange={e => {
              setInputText(e.target.value);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
      )}
    </div>
  );
};

export default React.memo(RssSettings);
