import { FC, Fragment, memo, useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'contexts/theme-context';
import Tag from 'pages/settings/tag/tag';

import { hexToRgbA, insertion_Sort } from 'helpers';
import ThreePoints from './three-points/index';

import './styles.scss';

interface WorkflowItemTagsProps {
  wiewType: 'card' | 'table';
  itemId: number;
  propTags: {
    color: string;
    id: number;
    name: string;
  };
  deleteAction?: (arg: { tagId: number; workflowId: number }) => void;
  handleFilterByTag?: (arg1: number, arg2: number) => void;
  cardView?: boolean;
  tagsCount?: number;
}

const WorkflowItemTags: FC<WorkflowItemTagsProps> = ({
  wiewType,
  itemId,
  propTags,
  deleteAction,
  handleFilterByTag,
  cardView,
  tagsCount,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // const [tooltipOpen, setTooltipOpen] = useState({
  //   tags: false,
  // });
  const { theme } = useContext(ThemeContext);

  const localTags = insertion_Sort(propTags);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, [window.innerWidth]);

  // const ToolTags = () => {
  //   setTooltipOpen({ ...tooltipOpen, tags: !tooltipOpen.tags });
  // };

  const getTextLength = (text: string) => {
    return text.length;
  };

  const showAllTags = (newArr: any) => {
    // const newArr = insertion_Sort(watch_property_tags && watch_property_tags)
    return (
      <div className="tags d-flex align-items-center tags-scroll">
        {newArr.map((item: { color: any; name: any; id: any }, index: any) => {
          return (
            <div
              className="tag"
              key={item.id}
              style={{
                color: item.color,
                border: `1px solid ${item.color}`,
                padding: '4px 10px',
                marginRight: '10px',
                marginBottom: '10px',
                borderRadius: '4px',
              }}
            >
              <span className={`${getTextLength(item.name) > 25 ? 'cut-text' : ''}`}>
                {getTextLength(item.name) > 25 ? item.name.slice(0, 25) + '...' : item.name}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  const [tagsShowCount, setTagsShowCount] = useState(tagsCount || 1);
  const [showThreePoints, setShowThreePoints] = useState(true);

  const getLength = (count: number, maxText: number) => {
    let length = 0;
    let innerCount = 0;
    localTags.forEach((item: any, i: number) => {
      let textLength = getTextLength(item.name);
      textLength = textLength > 28 ? 28 : textLength;
      if (innerCount + textLength < maxText) {
        innerCount += getTextLength(item.name);
        if (length < (wiewType === 'card' ? 3 : 4)) {
          length++;
        }
      }
    });

    length = windowWidth >= 1600 && length === 0 ? 1 : length;

    return { length: length, innerCount };
  };

  const calculateCountOfTags = (maxText: number) => {
    let { length } = getLength(0, maxText);
    setTagsShowCount(length);
  };

  useEffect(() => {
    if (tagsCount) {
      calculateCountOfTags(tagsCount * 10);
    } else {
      if (windowWidth >= 1050) {
        calculateCountOfTags(wiewType === 'card' ? 6 : 5); //16
      }

      if (windowWidth >= 1440) {
        calculateCountOfTags(wiewType === 'card' ? 8 : 10); //16
      }

      if (windowWidth >= 1600) {
        calculateCountOfTags(wiewType === 'card' ? 8 : 20); //30
      }

      if (windowWidth >= 1800) {
        calculateCountOfTags(wiewType === 'card' ? 11 : 25); //40
      }
    }
  }, [tagsCount, localTags]);

  return (
    <div className="tags visual-tags">
      {localTags &&
        localTags
          .filter((item: any, i: number) => i < tagsShowCount + (showThreePoints ? 1 : 0))
          .map((item: { color: any; name: any; id: any; active: boolean }, index: any) => {
            if (index < tagsShowCount && showThreePoints) {
              return (
                <Fragment key={item.id}>
                  {deleteAction && handleFilterByTag ? (
                    <Tag
                      name={item.name}
                      color={item.color}
                      bgColor={hexToRgbA(item.color, 0.1)}
                      onClick={() => deleteAction({ tagId: item.id, workflowId: itemId })}
                      onFilterTagClick={() => handleFilterByTag(item.id, itemId)}
                      active={item.active}
                    />
                  ) : (
                    <div
                      key={item.name}
                      className="tag"
                      style={{ color: item.color, border: `1px solid ${item.color}`, borderRadius: '4px' }}
                    >
                      <span className={`${getTextLength(item.name) > 20 ? 'cut-text' : ''}`}>{item.name}</span>
                    </div>
                  )}
                </Fragment>
              );
            } else {
              return (
                <ThreePoints
                  key={item.id}
                  itemId={itemId}
                  localTags={localTags}
                  tagsShowCount={tagsShowCount}
                  showAllTags={showAllTags}
                  theme={theme}
                  item={item}
                  cardView={cardView}
                />
              );
            }
          })}
    </div>
  );
};

export default memo(WorkflowItemTags);
