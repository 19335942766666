import { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import './styles.scss';

export const fileTypeOptions = [
  { label: 'json', value: 'json' },
  { label: 'csv', value: 'csv' },
  { label: 'xml', value: 'xml' },
];

const ImageConverterSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [selectedUnitFrom, setSelectedUnitFrom] = useState<{ label: string; value: string } | null>(null);
  const [selectedUnitTo, setSelectedUnitTo] = useState<{ label: string; value: string } | null>(null);
  const [fieldList, setFieldList] = useState<any>([]);
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [clicked, setClicked] = useState(false);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const checkFields = () => {
    if (!selectedSource || selectedSource?.length === 0 || !selectedUnitFrom || !selectedUnitTo) {
      return false;
    }
    return true;
  };

  const {
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    liftingSettings,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  //recipe fields or output fields
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();

    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings && options.length > 0) {
      const { keys } = dynamic_settings;
      const { settings } = keys && keys[0];
      const from = settings?.from;
      const to = settings?.to;

      restoreSelectedSource({ key: keys[0].key, fieldList: options, setSelectedSource: setSelectedSource });
      const unitFrom = fileTypeOptions.find((item: { value: any }) => item.value === from);
      unitFrom && setSelectedUnitFrom(unitFrom);
      const unitTo = fileTypeOptions.find((item: { value: any }) => item.value === to);
      unitTo && setSelectedUnitTo(unitTo);
    } else {
      setSelectedSource(options.map((item: any, index: number) => ({ label: item.label, value: '' })));
    }
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
          (item: any) =>
            (optionsWithTypes &&
              optionsWithTypes[item.value] &&
              (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                optionsWithTypes[item.value] === 'any')) ||
            !(optionsWithTypes && optionsWithTypes[item.value]),
        )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
          (item: any) =>
            (optionsWithTypes &&
              optionsWithTypes[item.value] &&
              (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                optionsWithTypes[item.value] === 'any')) ||
            !(optionsWithTypes && optionsWithTypes[item.value]),
        )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [...fieldList.map((item: { value: any }) => item.value), '_converted_file'],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);

  //lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);
    let isUploaded = false;
    const dataInput = automationSendSettings.find((item: { automation_id: number }) => item.automation_id === 47);
    const type = !dataInput ? null : dataInput.dynamic_settings.keys[0].settings.type;
    if (type === 'text-file' || type === 'csv-file') {
      isUploaded = true;
    }

    let localSettings = {
      keys: [
        {
          inputValue: null,
          key: fieldList.length && selectedSource ? selectedSource.value : null,
          settings: {
            to: selectedUnitTo ? selectedUnitTo.value : null,
            from: selectedUnitFrom ? selectedUnitFrom.value : null,
            isUploadedFile: isUploaded,
          },
        },
      ],
    };
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: localSettings,
      //@ts-ignore
      outputKeysData: [...fieldList.map((item: { value: any }) => item.value), '_converted_file'],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [selectedUnitFrom, selectedUnitTo, selectedSource, fieldList, fieldListWithTypes, automationOutputTypes]);

  return (
    <div className="image-converter-settings">
      <Row className="mb-4">
        <Col lg={6}>
          <span className="label secondary-color">Select field</span>
          <Select
            value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
            options={fieldList}
            fullWidth
            onChange={(selected: any) => {
              setSelectedSource(selected);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          ></Select>
        </Col>
      </Row>
      <Row className="">
        <Col lg={6} className="type-select mb-4">
          <h5 className="pointer-title">From</h5>
          <div className="select-block">
            <Select
              value={selectedUnitFrom}
              options={fileTypeOptions}
              fullWidth
              onChange={(selectedItem: any) => {
                setSelectedUnitFrom(selectedItem);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            ></Select>
          </div>
        </Col>
        <Col lg={6} className="type-select mb-4">
          <h5 className="pointer-title">To</h5>
          <div className="select-block">
            <Select
              value={selectedUnitTo}
              options={fileTypeOptions}
              fullWidth
              onChange={(selectedItem: any) => {
                setSelectedUnitTo(selectedItem);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            ></Select>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ImageConverterSettings;
