import { FC, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { CHANGE_TASK_ANSWER } from 'graphql/queries';
import Question from './question';
interface IQuestions {
  questions: {
    link: string;
    createdAt: string;
    answer?: string;
    id: number;
  }[];
  disabled?: boolean;
  taskId: string;
  refetch: any;
  status: string;
  type: string;
}
const Questions: FC<IQuestions> = ({ questions, taskId, disabled, refetch, status, type }) => {
  const [changeAnswer, { loading }] = useMutation(CHANGE_TASK_ANSWER);
  const [checkedId, setCheckedId] = useState(-1);

  const handleChangeAnswer = async (id: number, answer: string, type: string) => {
    if (loading) return;
    setCheckedId(id);
    const res = await changeAnswer({
      variables: {
        taskId: taskId,
        elementId: id,
        answer: answer,
        type,
      },
    });
    if (res?.data?.HexomaticHumanValidationsTasksOps) {
      refetch();
    }
  };
  return (
    <div className="position-relative">
      <div className={`tasks-opacity position-absolute ${disabled ? '' : 'd-none'}`}></div>
      <div className={`tasks-wrapper ${disabled ? 'opacity' : ''}`}>
        {questions &&
          questions.length > 0 &&
          questions.map((question, index) => (
            <Question
              link={question.link}
              index={index + 1}
              date={question.createdAt}
              value={question.answer}
              handleChangeAnswer={val =>
                handleChangeAnswer(question.id, val || '', question.answer === null ? 'create' : 'update')
              }
              status={status}
              type={type}
              loading={loading && checkedId === question.id}
            />
          ))}
      </div>
    </div>
  );
};
export default Questions;
