import { FC, memo, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { ThemeContext } from 'contexts/theme-context';
import { HexomaticUserContext } from 'contexts/hexomatic-user';
import TemplateCard from 'shared-components/atom/template-card';
import { rightArrow } from 'assets/svg-icons';
import './styles.scss';

const TemplatesSection: FC<{
  templatesList: any;
  isAll?: boolean;
  handleShowAllClick?: any;
  createNewLinkTo?: string;
  blank?: boolean;
  hide?: boolean;
}> = ({ templatesList, isAll, handleShowAllClick, createNewLinkTo, hide }) => {
  const [showConcierge, setShowConcierge] = useState(false);
  const isSM = useMediaQuery({ maxWidth: 1600 });
  const { theme } = useContext(ThemeContext);
  const { hexomaticUser } = useContext(HexomaticUserContext);
  const [showArrows, setShowArrows] = useState({ left: false, right: true });

  useEffect(() => {
    const paidUser = hexomaticUser?.pricing_package && hexomaticUser?.pricing_package !== 'FREE';
    setShowConcierge(!!paidUser && !hide);
  }, [hexomaticUser, hide]);

  const handleScrollLeft = () => {
    const el = document?.getElementById('templates-section');
    el?.scrollBy({
      left: -100,
      behavior: 'smooth',
    });
  };

  const handleScrollRight = () => {
    const el = document?.getElementById('templates-section');
    el?.scrollBy({
      left: 100,
      behavior: 'smooth',
    });
  };

  const handleScroll = () => {
    const el = document?.getElementById('templates-section');
    const scrollPosition = el?.scrollWidth || 0;
    const scrollLeft = el?.scrollLeft || 0;
    setShowArrows({
      left: scrollLeft > 1,
      right: scrollLeft < (scrollPosition - 245),
    });
  };

  return (
    <>
      <div className="d-flex w-100 position-relative">
        {showArrows?.left && (
          <div className="templates-section-arrow left" onClick={handleScrollLeft}>
            {rightArrow}
          </div>
        )}
        {showArrows?.right && (
          <div className="templates-section-arrow right" onClick={handleScrollRight}>
            {rightArrow}
          </div>
        )}
        {isAll ? null : (
          <div style={{ padding: '5px 5px 5px 0' }}>
            <TemplateCard theme={theme} createNewLinkTo={createNewLinkTo} />
          </div>
        )}
        <div
          className={`templates-section d-flex ${hide ? 'hide' : ''} ${showConcierge ? 'show-concierge' : ''}`}
          id="templates-section"
          onScroll={handleScroll}
        >
          <Row className="m-0" style={{ flexWrap: 'nowrap' }}>
            {templatesList.length
              ? templatesList.map((item: any, index: number) => (
                  <Col
                    sm={templatesList.length === 2 || (isSM && showConcierge) ? 6 : 4}
                    style={{ minWidth: '220px', padding: '5px' }}
                    className={index === 2 && isSM && showConcierge ? 'd-none' : ''}
                  >
                    {item.var_url ? (
                      <TemplateCard
                        theme={theme}
                        title={item.name}
                        description={item.description}
                        image={item.image}
                        isNew={item.is_new}
                        linkTo={''}
                        id={item.id}
                      />
                    ) : (
                      <TemplateCard
                        theme={theme}
                        title={item.name}
                        description={item.description}
                        linkTo={item.linkTo}
                        steps={item.steps}
                        image={item.image}
                      />
                    )}
                  </Col>
                ))
              : Array.apply(null, Array(hide ? 2 : 3)).map(item => (
                  <Col
                    sm={hide || (isSM && showConcierge) ? 6 : 4}
                    style={{ minWidth: '220px', width: '600px', padding: '5px' }}
                  >
                    <div className="blank-template"></div>
                  </Col>
                ))}
          </Row>
          {showConcierge ? (
            <div style={{ padding: '5px 0px 5px 5px' }}>
              <TemplateCard theme={theme} demoLink />
            </div>
          ) : null}
        </div>
      </div>
      {isAll ? null : (
        <Col md={12} className="mb-2 mt-1 text-center">
          <span className="text-center clickable-text" onClick={handleShowAllClick}>
            SHOW ALL TEMPLATES
          </span>
        </Col>
      )}
    </>
  );
};

export default memo(TemplatesSection);
