import { FC, useState, useRef, useEffect, useContext } from 'react';
import axios, { Method } from 'axios';
import { getAccessToken } from 'helpers';
import { HOST } from 'constants/host';
import uploadPhotoIcon from 'assets/icons/photo-camera.svg';
import xButton from 'assets/icons/x-button.svg';

import ModalComponent from 'shared-components/molecule/modal';
import { UserContext } from 'contexts/user-context';
import Tooltip from 'shared-components/atom/tooltip';
import CountryList from 'react-select-country-list';
import { taxIds } from 'constants/profile';

interface UserAvatarProps {
  userEmail: string;
  userFirstName: string;
  userId: string;
}

const UserAvatar: FC<UserAvatarProps> = ({ userId, userEmail, userFirstName }) => {
  const ref = useRef<any>(null);
  const { user, setUser } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [methodType, setMethodType] = useState<'PUT' | 'POST'>('POST');

  const [alertMsg, setAlertMsg] = useState<{ show: boolean; text: string }>({ show: false, text: '' });
  const [imgSrc, setImgSrc] = useState('');

  const [hideDeleteButton, setHideDeleteButton] = useState(false);

  const [backgroundImage, setBackgroundImage] = useState('');
  const [completed, setCompleted] = useState(0);

  useEffect(() => {
    if (!imgSrc && user?.photo) {
      setImgSrc(user.photo);
      setMethodType('PUT');
      setHideDeleteButton(false);
    }

    if (user?.photo === null) {
      setHideDeleteButton(true);
      setImgSrc('https://hexomatic.com/avatar-hexomatic.svg');
      setMethodType('POST');
    }
    if (user && user.id) {
      const userFields = {
        ...user.info,
        company: user.company,
        companyWebsite: user.companyWebsite,
        countryUser: user.countryUser,
        firstName: user.firstName,
        industry: user.industry,
        lastName: user.lastName,
        title: user.title,
        phone: user.phone,
      };
      let fieldsCount = 12;
      if (user.countryUser === 'US') {
        fieldsCount += 1;
      }
      if (user.countryUser) {
        const name = (CountryList().getLabel(user.countryUser) || '').replaceAll(' ', '_').toLocaleLowerCase();
        //@ts-ignore
        if (taxIds[name] && taxIds[name].length) {
          fieldsCount += 2;
        }
      }
      const len = Object.values(userFields).filter((item: any) => !!item).length;
      const completed = Math.trunc(Math.round((len * 100) / fieldsCount));
      const inCompleted = 100 - completed;
      setBackgroundImage(
        `linear-gradient(90deg, #B72CFF ${completed}%, #D8E0ED ${completed}%, #D8E0ED ${inCompleted}%, #D8E0ED ${inCompleted}%)`,
      );
      setCompleted(completed);
    }
  }, [user, loading]);

  useEffect(() => {
    if (imgSrc && user) {
      setUser({
        ...user,
        photo: imgSrc,
      });
    }
  }, [imgSrc]);

  const onUpload = async (e: any, method: Method) => {
    setLoading(true);
    let file;
    let data;

    if (method !== 'DELETE') {
      file = e.target.files[0];
      if (!file) {
        setLoading(false);
        return;
      }
      if (file && file.size >= 3 * 1000 * 1000) {
        setLoading(false);
        setAlertMsg({ show: true, text: 'Maximum file size is 3MB' });
        return;
      }

      data = new FormData();

      if (method === 'PUT' || method === 'POST') {
        data.append('userId', `${userId}`);
        data.append('photo', file);
      }
    }

    try {
      let res;
      if (method === 'DELETE') {
        res = await axios.delete(`${HOST.APP_API.APP}/profile-photo/${userId}`, {
          headers: {
            Authorization: getAccessToken() || '',
          },
        });
      } else {
        res = await axios(`${HOST.APP_API.APP}/profile-photo`, {
          method,
          data,
          headers: {
            Authorization: getAccessToken() || '',
          },
        });
      }

      if (res && res.status === 200 && res.data && res.data.url) {
        setImgSrc(res.data.url);
        setMethodType('PUT');
        setHideDeleteButton(false);
        setLoading(false);
      }
      if (res && res.status === 200 && res.data && !res.data.url) {
        setMethodType('POST');
        setImgSrc('https://hexomatic.com/avatar-hexomatic.svg');
        setHideDeleteButton(true);
        setLoading(false);
      }
    } catch (e: any) {
      if (!e.response) {
        setAlertMsg({ show: true, text: 'Image dimentions are too big, recommended maximum size is 1000 X 1000' });
        setLoading(false);
        return;
      }

      if (e.response.data.error.message === 'value too long for type character varying(200)') {
        setAlertMsg({ show: true, text: 'Image name is too long' });
      } else {
        setAlertMsg({ show: true, text: e.response.data.error.message });
      }

      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className="user-avatar">
          <div className="pulse">
            <div className="circle"></div>
            <div className="completed-fields"></div>
          </div>
        </div>
      ) : (
        <>
          <div className="user-avatar pie" style={{ backgroundImage }}>
            <div className="pie-inner">
              <img src={imgSrc} className="img" alt={userFirstName} style={{ width: '125px', height: '125px' }} />
              <button className="upload-btn" onClick={() => ref.current.click()}>
                <Tooltip isSmall placement="bottom" text="Recommended dimensions 1000 x 1000" className="left-more">
                  <img className="upload-icon" src={uploadPhotoIcon} alt="camera icon" />
                </Tooltip>
              </button>

              {!hideDeleteButton && (
                <button className="delete-btn" onClick={() => onUpload(null, 'DELETE')}>
                  <img className="delete-icon" src={xButton} alt="delete image icon" />
                </button>
              )}
              <input
                ref={ref}
                type="file"
                name="myImage"
                accept="image/png, image/jpeg"
                style={{ display: 'none' }}
                onChange={e => onUpload(e, methodType)}
              />
            </div>
          </div>
          <div className="completed-fields">{completed}% Fields completed</div>
        </>
      )}
      {alertMsg.show && (
        <ModalComponent
          isModalOpen={alertMsg.show}
          text={alertMsg.text}
          actionBtnText={'OK'}
          actionBtnColor={'error'}
          onActionClick={() => setAlertMsg({ show: false, text: '' })}
          escButtonClick={() => setAlertMsg({ show: false, text: '' })}
        />
      )}
    </>
  );
};

export default UserAvatar;
