import { FC, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAutomationGlobalMethods } from '../../hooks';
import { useLazyQuery } from '@apollo/react-hooks';
import { HexomaticUserContext } from '../../../contexts/hexomatic-user';
import { GET_ALL_INTEGRATIONS, TEST_WEBHOOK_URL } from 'graphql/queries';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import { closeIcon } from 'assets/svg-icons';
import CustomMultiSelect from 'shared-components/molecule/multiselect';
import Alert from 'shared-components/atom/alert-message';
import StretchLoading from 'shared-components/molecule/stretch-loading';
import './styles.scss';

const IntegratelySettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const { hexomaticUser } = useContext(HexomaticUserContext);
  const [userId, setUserId] = useState(hexomaticUser ? hexomaticUser.id : -1);
  const [clicked, setClicked] = useState(false);
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);
  const [selectedWebhookUrls, setSelectedWebhookUrls] = useState<any>([]);
  const [integratelyOptions, setIntegratelyOptions] = useState<any>([]);
  const [showInput, setshowInput] = useState(false);
  const [inputText, setInputText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const [getAllIntegrations, { data: integrationsData, loading: integrationsLoading }] = useLazyQuery(
    GET_ALL_INTEGRATIONS,
    { fetchPolicy: 'network-only' },
  );

  // const [testWebhook, { data: testWebhookRes, loading: testWebhookLoading }] = useLazyQuery(TEST_WEBHOOK_URL, {
  //   fetchPolicy: 'network-only',
  // });

  const { getPreviousAutomationOutputOrPreviousRecipKeys, getDynamicAndIntegrationsSettings, liftingSettings, getPreviousAutomationOutputOrPreviousRecipKeysWithTypes } =
    useAutomationGlobalMethods({
      automationSendSettings,
      setAutomationSendSettings,
      selectedRecipes,
      automationIndex,
      id,
    });

  const checkFields = () => {
    if (!selectedWebhookUrls?.length) return false;
    return true;
  };

  //restore
  useEffect(() => {
    getAllIntegrations({
      variables: { id: userId },
    });
  }, []);

  //recipe fields or output fields
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex !== 0) {
      let options = getPreviousAutomationOutputOrPreviousRecipKeys();
      const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
      const filteredOptions = automationInputTypes && !automationInputTypes.includes('any') ? 
      options.filter((item: any) => (optionsWithTypes && optionsWithTypes[item.value] && (automationInputTypes.includes(optionsWithTypes[item.value]) || optionsWithTypes[item.value] === 'any')) || !(optionsWithTypes && optionsWithTypes[item.value])) :
      [...options];

      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
      // setFieldList(options);
    }
  }, [isSettingsChange]);

  useEffect(() => {
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      outputKeysData: fieldList.map((item: { value: any }) => item.value),
      outputKeysDataWithTypes: {...fieldListWithTypes, ...jsonParse},
    });
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);

  // getting integrations
  useEffect(() => {
    if (integrationsData?.HexomaticIntegrations?.getAllIntegrations?.hexomaticWebhook) {
      const { integrately } = integrationsData.HexomaticIntegrations.getAllIntegrations;
      const { dynamic_settings } = getDynamicAndIntegrationsSettings();
      if (dynamic_settings) {
        const { keys } = dynamic_settings;
        const webhooks = keys?.[0]?.settings?.webhookUrls;
        if (webhooks) {
          const newArr = webhooks.map((item: string) => ({
            label: integratelyOptions.find((sub: any) => sub.value === item)?.label || item,
            value: item,
          }));
          setSelectedWebhookUrls(newArr);
        }
        setClicked(true);
      }

      let integratelyArr: any = null;
      if (integrately && integrately.length > 0) {
        integratelyArr = integrately.map((item: any) => {
          const parsedData = JSON.parse(item.data);
          return { label: item.name || parsedData.url, value: parsedData.url };
        });
      }
      setIntegratelyOptions(integratelyArr || []);
      setClicked(true);
      setCompletedFields({ index: automationIndex, checked: checkFields() });
    }
  }, [integrationsData]);

  //lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    setClicked(false);
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {}
    liftingSettings({
      dynamicSettings: {
        keys: [
          {
            key: null,
            settings: {
              webhookUrls: selectedWebhookUrls.map((item: { value: any }) => item.value),
            },
            inputValue: null,
          },
        ],
      },
      outputKeysData: fieldList.map((item: { value: any }) => item.value),
      outputKeysDataWithTypes: {...fieldListWithTypes, ...jsonParse},
    });
  }, [selectedWebhookUrls]);

  const onDelete = (index: number) => {
    !clicked && setClicked(true);
    setIsDirty();
    const newValues = selectedWebhookUrls.filter((item: any, i: number) => i !== index);
    setSelectedWebhookUrls(newValues);
  };

  if (integrationsLoading) {
    return <StretchLoading />;
  }

  return (
    <div className="webhook-settings">
      <Row>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Select Webhook</span>
          <CustomMultiSelect
            options={integratelyOptions}
            value={selectedWebhookUrls}
            onChange={(sel: any) => {
              setSelectedWebhookUrls(sel);
              !clicked && setClicked(true);
              setIsDirty();
            }}
            labelledBy="Select"
          />
        </Col>
      </Row>
      {selectedWebhookUrls.map((item: { label: string }, i: number) => {
        return (
          <Row>
            <Col lg={12} className="mb-4">
              <div className="webhook-item">
                <div>
                  <span>{item.label}</span>
                </div>
                <span className="close-icon" data-tip="Click to delete" onClick={() => onDelete(i)}>
                  {closeIcon}
                </span>
              </div>
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export default IntegratelySettings;
