import { FC } from 'react';

import TextArea from 'shared-components/atom/texatarea';
import Input from 'shared-components/atom/input';
import Select from 'shared-components/atom/select';

import checkIcon from 'assets/icons/checkCircle.png';
import { cardEditIcon, closeIcon, copyIconModal, questionIcon } from 'assets/svg-icons';
import closeIconImg from 'assets/icons/closeCircle.png';
import Tooltip from 'shared-components/atom/tooltip';

//----------------------data---------------------------------//
export const scrapingDataTypes: { label: string; value: string }[] = [
  { label: 'Unset', value: 'unset' },
  { label: 'Source URL', value: 'img_src' },
  { label: 'Link URL', value: 'a_href' },
  { label: 'Text', value: 'text' },
  { label: 'Number', value: 'number' },
  { label: 'HTML tag', value: 'html_tag' },
  { label: 'Email address', value: 'email' },
  { label: 'Phone number', value: 'phone' },
  { label: 'Date', value: 'date' },
];
//------------------------------------------------------------//
//-------------------------Functions--------------------------//
//HELPERS
export const getType = (type: string) => {
  const selected = scrapingDataTypes.find(item => item.value === type);
  return selected?.label || '';
};

export const ActionPaginationCard: FC<any> = ({
  item,
  index,
  confirmedItems,
  handlePaginationRemove,
  handleActionRemove,
  loading,
}) => {
  return (
    <div className="editable-card mb-2 m-horizontal-auto position-relative" id={String(index)}>
      <div className="d-flex">
        <h5 className="dark-title-small mb-1">
          {item.pagination
            ? 'Pagination'
            : item.action &&
              (item.action.type === 'click' && item.action.element.includes('select > option:nth-child')
                ? 'Select option'
                : item.action.type)}
        </h5>
        <div className="card-btns d-flex position-absolute pt-3 pr-3 cursor-pointer">
          {((item.last && confirmedItems[confirmedItems.length - 1].action) || item.pagination) && (
            <span
              className={`card-close-icon ${item.action && loading ? 'cursor-not-allowed' : ''}`}
              onClick={() =>
                !loading && (item.pagination ? handlePaginationRemove(index) : handleActionRemove(item.action, index))
              }
            >
              <Tooltip placement="left" text="Delete">
                {closeIcon}
              </Tooltip>
            </span>
          )}
        </div>
      </div>
      {item.pagination && (
        <div className="pagination-wrapper">
          {Array.isArray(item.pagination) ? (
            item.pagination[0].includes('/') ? (
              item.pagination.map((single: string) => (
                <p
                  style={{ fontSize: '12px', fontWeight: 500, maxWidth: '100%' }}
                  className="text-truncate"
                  title={single}
                >
                  {single}
                </p>
              ))
            ) : (
              <p
                style={{ fontSize: '12px', fontWeight: 500, maxWidth: '100%' }}
                className="text-truncate"
                title={item.pagination.join(', ')}
              >
                {item.pagination.join(', ')}
              </p>
            )
          ) : (
            <>
              <p
                style={{ fontSize: '12px', fontWeight: 500, maxWidth: '100%' }}
                className="text-truncate"
                title={item.pagination.type
                  .replace('.hexoscraper-scale', '')
                  .replace('.hexoScrapOverlay', '')
                  .replace('.hexoScrapSelect', '')
                  .replace('.hexoScrapHover', '')
                  .replace('.hexoScrap-context', '')
                  .replace('.hexoScrap-only-this', '')
                  .replace('.hexoScrap-actions-ui', '')
                  .replace('.hexoScrap-overlay', '')}
              >
                {item.pagination.type
                  .replace('.hexoscraper-scale', '')
                  .replace('.hexoScrapOverlay', '')
                  .replace('.hexoScrapSelect', '')
                  .replace('.hexoScrapHover', '')
                  .replace('.hexoScrap-context', '')
                  .replace('.hexoScrap-only-this', '')
                  .replace('.hexoScrap-actions-ui', '')
                  .replace('.hexoScrap-overlay', '')}
              </p>
              <p style={{ fontSize: '12px', fontWeight: 500, maxWidth: '100%' }} className="text-truncate">
                from {item.pagination.from} to {item.pagination.to}
              </p>
            </>
          )}
        </div>
      )}
      {item.action && item.action.element && (
        <p className="action-information">
          {item.action.element
            .replace('.hexoscraper-scale', '')
            .replace('.hexoScrapOverlay', '')
            .replace('.hexoScrapSelect', '')
            .replace('.hexoScrapHover', '')
            .replace('.hexoScrap-context', '')
            .replace('.hexoScrap-only-this', '')
            .replace('.hexoScrap-actions-ui', '')
            .replace('.hexoScrap-overlay', '')}
        </p>
      )}
      {item.action && item.action.cookie && (
        <>
          <p className="action-information">Name: {item.action.cookie.name}</p>
          <p className="action-information">Value: {item.action.cookie.value}</p>
          {item.action.cookie.domain && <p className="action-information">Domain: {item.action.cookie.domain}</p>}
        </>
      )}
    </div>
  );
};

export const PathCard: FC<any> = ({
  item,
  index,
  handleChangePropertyPath,
  className,
  isNew,
  onBlur,
  onFocus,
  show,
}) => {
  return (
    <>
      <div className="d-flex mt-1 mb-1 custom-element">
        <div className="d-block mt-3">
          <div className="preview-card-label d-block">
            HTML XPath
            <Tooltip
              placement="right"
              text="XPath is a path expression to select nodes from document"
              isSmall
              // className="help-icon-tooltip"
            >
              <span className="ml-1 help-icon">{questionIcon}</span>
            </Tooltip>
          </div>
          <div className="preview-card-label d-block">OR</div>
          <div className="preview-card-label d-block">
            HTML Selector
            <Tooltip
              placement="right"
              text="A selector is the part of a CSS rule set that actually selects the content"
              isSmall
              // className="help-icon-tooltip"
            >
              <span className="ml-1 help-icon">{questionIcon}</span>
            </Tooltip>
          </div>
        </div>

        <TextArea
          name={isNew ? 'propertyPathNew' : 'propertyPathEdit'}
          onChange={(e: any) => handleChangePropertyPath(e, index)}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholder={`e.g. html > body > div OR //*[@id="1"]/p`}
          value={item.path}
          rows={4}
          className={className}
        />
      </div>
      {show && (
        <div className="d-flex mt-1 mb-1 custom-element">
          <span className="preview-card-label mt-3"></span>
          <p className="path-hint">
            {' '}
            * The selector automatically copies the exact element's path. If you want to select all similar elements
            remove ":nth-child(order_number)" from the desired element's tag.
          </p>
        </div>
      )}
    </>
  );
};
export const SpecifyTextCard: FC<any> = ({ item, index, handleChangeSpecifyElement, onBlur, onFocus }) => {
  return (
    <>
      <div className="d-flex mt-1 mb-1 custom-element">
        <span className="preview-card-label mt-3">
          Specify data point <br />
          (Optional)
          <Tooltip
            isSmall
            // className="help-icon-tooltip"
            placement="right"
            text="This is used to specify the exact element to extract from the selected Xpath or Selector, needs to contain tag 'class' or 'id'."
          >
            <span className="ml-1 help-icon">{questionIcon}</span>
          </Tooltip>
        </span>

        <TextArea
          onChange={(e: any) => handleChangeSpecifyElement(e, index)}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholder="specify data point"
          value={item.specify}
          rows={4}
        />
      </div>
    </>
  );
};
export const RegexCard: FC<any> = ({
  item,
  index,
  handleChangePropertyRegex,
  className,
  isNew,
  onBlur,
  onFocus,
  show,
}) => {
  return (
    <>
      <div className="d-flex mt-1 mb-1 custom-element">
        <span className="preview-card-label mt-3">
          Pattern <br />
          (Optional)
          <Tooltip
            isSmall
            // className="help-icon-tooltip"
            placement="right"
            text="This is a regular expression (sequence of characters) that specifies a search pattern."
          >
            <span className="ml-1 help-icon">{questionIcon}</span>
          </Tooltip>
        </span>

        <TextArea
          name={isNew ? 'propertyRegexNew' : 'propertyRegexEdit'}
          onChange={(e: any) => handleChangePropertyRegex(e, index)}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholder="insert regular expression"
          value={item.pattern}
          rows={4}
          className={className}
        />
      </div>
    </>
  );
};

export const TypeCard: FC<any> = ({
  item,
  index,
  handleChangeType,
  handleChangeAttribute,
  handelKeyDown,
  handelKeyUp,
  setItemIndex,
  danger,
  className,
}) => {
  return (
    <>
      <div className="d-flex">
        <span className="preview-card-label mr-xl-2">Type</span>
        <Select
          options={scrapingDataTypes}
          value={getType(item.type)}
          placeholder={'Select'}
          onChange={(value: any) => {
            handleChangeType(index, value && value.value, item.path);
            setItemIndex(index);
          }}
          fullWidth
          danger={danger}
          w100
          className={className || ''}
        />
      </div>

      {item.type === 'html_tag' && (
        <div className={`d-flex mt-2 custom-element-name align-items-center`}>
          <span className="preview-card-label py-0 my-0">Attribute</span>
          <Input
            dataUrl
            type="text"
            id="text"
            name="attribute"
            onChange={(e: any) => {
              handleChangeAttribute(e.target.value, index, item.path);
              setItemIndex(index);
            }}
            placeholder="type attribute"
            value={item.attribute}
            className={`property-name`}
            onKeyDown={handelKeyDown}
            onKeyUp={(e: any) => handelKeyUp(index, e.target.value, item.path)}
          />
        </div>
      )}
    </>
  );
};
export const CardHeader: FC<any> = ({ item, index, handleEditElement, handleRemove, handleCopyElement }) => {
  const remove = () => handleRemove(index);
  const copy = () => handleCopyElement(index);
  const edit = () => handleEditElement(index);
  return (
    <div className="d-flex position-relative">
      <h5 className="dark-title-small mb-3">Element {`${item.custom ? '(Custom)' : ''}`}</h5>
      <div className="card-btns d-flex cursor-pointer position-absolute">
        <span onClick={copy} className="card-copy-icon">
          <Tooltip text="Copy" placement="left">
            {copyIconModal}
          </Tooltip>
        </span>
        <span onClick={edit} className="card-edit-icon">
          <Tooltip text="Edit" placement="left">
            {cardEditIcon}
          </Tooltip>
        </span>
        <span className="card-close-icon" onClick={remove}>
          <Tooltip text="Delete" placement="left">
            {closeIcon}
          </Tooltip>
        </span>
      </div>
    </div>
  );
};
export const CardFooter: FC<any> = ({ handleConfirm, index, cancelEdit }) => {
  return (
    <div className="d-flex buttons-wrapper justify-content-end mt-2 mr-3" style={{ marginLeft: 'auto' }}>
      <span className="cancel mr-2" onClick={() => cancelEdit(index)}>
        Cancel
      </span>
      <span className="clickable-text" onClick={() => handleConfirm(index)}>
        Save
      </span>
    </div>
  );
};

export const CardName: FC<any> = ({ item, index, handleChangePropertyName, handleRemove, nameError, show }) => {
  return (
    <>
      <div className={`d-flex mt-1 ${item.name && nameError.includes(index) ? '' : 'mb-3'} custom-element-name`}>
        <span className="preview-card-label">Field Label</span>
        <Input
          dataUrl
          type="text"
          id="text"
          name="propertyName"
          onChange={(e: any) => handleChangePropertyName(e, index)}
          placeholder="name"
          value={item.name}
          className={`${show ? 'property-name-custom' : 'property-name'} `}
          error={nameError.includes(index) ? 'error' : undefined}
          maxLength={55}
        />
        {show && (
          <div className="position-absolute close-icon-custom cursor-pointer">
            <Tooltip placement="left" text="Delete">
              <span className="card-close-icon c-pointer" onClick={() => handleRemove(index)}>
                {closeIcon}
              </span>
            </Tooltip>
          </div>
        )}
      </div>
      {item.name && nameError.includes(index) && (
        <div className="text-danger name-error">Element with this field label already exists.</div>
      )}
    </>
  );
};
