import React, { FC, memo, useEffect, useState } from 'react';
import { useAutomationGlobalMethods } from '../../hooks';
import { Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import { mathFormulaOperationsOptions, mathFormulaTypeOptions } from '../../constants';
import Input from 'shared-components/atom/input';

const MathFormulaSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  selectedRecipes,
  automationIndex,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);
  const [clicked, setClicked] = useState(false);
  const [selectedSource, setSelectedSource] = useState<{ label: string; value: string } | null>(null);
  const [operation, setOperation] = useState<{ label: string; value: string } | null>(null);
  const [operandType, setOperandType] = useState<{ label: string; value: string } | null>(null);
  const [operand_2_source, setOperand_2_source] = useState<{ label: string; value: string } | null>(null);
  const [operand_2, setOperand_2] = useState<number | string>('');
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const {
    outputFormat,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    liftingSettings,
    restoreSelectedSource,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (!selectedSource || !operandType || !operation || !operandType) {
      return false;
    }
    if (operandType && operandType?.value === 'manually') {
      if (isNaN(+operand_2) || operand_2 === '') return false;
    }
    if (operandType && operandType?.value === 'from source') {
      if (!operand_2_source) return false;
    }
    return true;
  };

  useEffect(() => {
    if (automationSendSettings) {
      const currentAutomation = automationSendSettings.find((automation: any, index: any) => index === automationIndex);
      const { dynamic_settings } = currentAutomation;
      if (dynamic_settings && fieldList.length > 0) {
        const { operand_1, operand_2, operation, manually, key, settings } = dynamic_settings.keys[0];

        const operand1 = operand_1 ?? key ?? null;
        const operand2 = operand_2 ?? settings?.key ?? null;
        const operationType = operation ?? settings?.operation ?? null;
        const manuallyOperand = manually ?? settings?.manually;

        restoreSelectedSource({ key: operand1, fieldList: fieldList, setSelectedSource: setSelectedSource });
        manuallyOperand === false &&
          restoreSelectedSource({ key: operand2, fieldList: fieldList, setSelectedSource: setOperand_2_source });
        const findedOperation = mathFormulaOperationsOptions.find(item => item.value === operationType);
        const findedOperandType = mathFormulaTypeOptions.find(
          item => item.value === (manuallyOperand === true ? 'manually' : 'from source'),
        );
        if (manuallyOperand === true) {
          const findedOperand_2 = manuallyOperand ? operand2 : fieldList.find(item => item.value === operand2);
          setOperand_2(findedOperand_2);
        }
        findedOperation && setOperation(findedOperation);
        findedOperandType && setOperandType(findedOperandType);
      }
    }
  }, [fieldList]);

  //getting previous outputs
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const currentAutomation = automationSendSettings.find((automation: any, index: any) => index === automationIndex);
    const { dynamic_settings } = currentAutomation;

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [
          ...fieldList.map((item: { value: any }) => item.value),
          ...(operation ? [`_${operation.value}_result`] : []),
        ],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, operation, fieldListWithTypes, automationOutputTypes]);

  //lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: {
        keys: [
          {
            key: fieldList.length && selectedSource ? selectedSource.value : null,
            inputValue: null,
            settings: {
              key: operandType?.value === 'manually' ? (operand_2 === '' ? '' : +operand_2) : operand_2_source?.value,
              operation: operation?.value,
              manually: operandType && operandType.value === 'manually' ? true : false,
            },
          },
        ],
      },
      outputKeysData: [
        ...fieldList.map((item: { value: any }) => item.value),
        ...(operation ? [`_${operation.value}_result`] : []),
      ],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [selectedSource, operation, operandType, operand_2, operand_2_source, automationOutputTypes, fieldListWithTypes]);

  return (
    <div className="">
      <Row className="">
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Operand 1</span>
          <Select
            options={fieldList}
            value={selectedSource}
            placeholder={'Select'}
            onChange={(selectedItem: any) => {
              setSelectedSource(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Operand type</span>
          <Select
            options={mathFormulaTypeOptions}
            value={operandType}
            placeholder={'Select'}
            onChange={(selectedItem: any) => {
              setOperandType(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
      </Row>

      <Row className="">
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Operation</span>
          <Select
            options={mathFormulaOperationsOptions}
            value={operation}
            placeholder={'Select'}
            onChange={(selectedItem: any) => {
              setOperation(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
      </Row>
      {operandType && (
        <Row className="">
          {operandType.value === 'manually' ? (
            <Col lg={6} className="mb-4">
              <span className="label secondary-color">Operand 2</span>
              <Input
                value={operand_2}
                onChange={(e: { target: { value: any } }) => {
                  let val = e.target.value;
                  if (isNaN(val)) {
                    return;
                  }
                  setOperand_2(val);
                  !clicked && setClicked(true);
                  setIsDirty();
                }}
              />
            </Col>
          ) : (
            <Col lg={6} className="mb-4">
              <span className="label secondary-color">Operand 2</span>
              <Select
                options={fieldList}
                value={operand_2_source}
                placeholder={'Select'}
                onChange={(selectedItem: any) => {
                  setOperand_2_source(selectedItem);
                  !clicked && setClicked(true);
                  setIsDirty();
                }}
              />
            </Col>
          )}
        </Row>
      )}
    </div>
  );
};

export default memo(MathFormulaSettings);
