import { FC, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { GOOGLE_BIG_QUERY_DATA } from 'graphql/mutations';
import { HexomaticUserContext } from 'contexts/hexomatic-user';
import { UserContext } from 'contexts/user-context';

import CustomMultiSelect from 'shared-components/molecule/multiselect';
import Button from 'shared-components/atom/button';
import Input from 'shared-components/atom/input';
import Alert from 'shared-components/atom/alert-message';
import Badge from 'shared-components/atom/badge';
import Select from 'shared-components/atom/select';
import Datalist from 'shared-components/atom/dataList';
import Modal from 'shared-components/molecule/modal';
import StretchLoading from 'shared-components/molecule/stretch-loading';
import CustomModal from 'shared-components/molecule/customModal';
import Payment from 'shared-components/molecule/payment';

import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';

import { closeIcon, filterIcon, editIcon, saveIcon } from 'assets/svg-icons';
import './styles.scss';

const stringConditions = [
  { label: 'Contains', value: 'contains' },
  { label: 'Does not contain', value: 'doesNotContain' },
  { label: 'Begins with', value: 'beginsWith' },
  { label: 'Does not begin with', value: 'doesNotBeginWith' },
  { label: 'Ends with', value: 'endsWith' },
  { label: 'Does not end with', value: 'doesNotEndsWith' },
];

const intConditions = [
  { label: 'Is greater than', value: 'greater' },
  { label: 'Is greater than or equal', value: 'greaterOrEqual' },
  { label: 'Is less than', value: 'less' },
  { label: 'Is less than or equal', value: 'lessOrEqual' },
  { label: 'Equals', value: 'equal' },
  { label: 'Does not equal', value: 'notEqual' },
];

const condtionsList = [
  { label: 'Contains', value: 'contains' },
  { label: 'Does not contain', value: 'doesNotContain' },
  { label: 'Begins with', value: 'beginsWith' },
  { label: 'Does not begin with', value: 'doesNotBeginWith' },
  { label: 'Ends with', value: 'endsWith' },
  { label: 'Does not end with', value: 'doesNotEndsWith' },
  // { label: 'Exclude null values', value: 'notEqualToNull' },
  // { label: 'Exclude empty values', value: 'notEmpty' },
];

const GoogleBigData: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const { push } = useHistory();
  const { user } = useContext(UserContext);
  const { hexomaticUser } = useContext(HexomaticUserContext);

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [buyPremiumCredits, setBuyPremiumCredits] = useState(false);
  const [shouldUpgrade, setShouldUpgrade] = useState(false);

  const [selectedDatasetToConfirm, setSelectedDatasetToConfirm] = useState(null as any);
  const [selectedTableToConfirm, setSelectedTableToConfirm] = useState(null as any);
  const [selectedColumnsToConfirm, setSelectedColumnsToConfirm] = useState(null as any);

  const [clicked, setClicked] = useState(true);
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);

  const [datasetsList, setDatasetsList] = useState(null as any);
  const [tablesList, setTablesList] = useState(null as any);
  const [tablesColumnsList, setTablesColumnsList] = useState([] as any);

  const [selectedDataset, setSelectedDataset] = useState(null as any);
  const [selectedTable, setSelectedTable] = useState(null as any);

  const [selectedColumnsList, setSelectedColumnList] = useState([] as any);

  const [columsListWithFilters, setColumsListWithFilters] = useState([] as any);
  const [selectedFilterColumn, setSelectedFilterColumn] = useState(null as any);
  const [selectedCondition, setSelectedCondition] = useState(null as any);
  const [filterCriteria, setFilterCriteria] = useState<string | number>('');
  const [confirmClearingFilters, setConfirmClearingFilters] = useState(false);

  const [shouldSave, setShouldSave] = useState(true);
  const [showConsumptionModal, setShowConsumptionModal] = useState(false);
  const [credits, setCredits] = useState({
    automation: 0,
    premium: 0,
  });

  const [showFiltersModal, setShowFiltersModal] = useState(false);

  const [saveFrom, setSaveFrom] = useState<number | string>(0);
  const [saveTo, setSaveTo] = useState<number | string>(100);
  const [errorMessage, setErrorMessage] = useState('');

  const [getDatasets, { data: allDatasets, loading: datasetsloading }] = useMutation(GOOGLE_BIG_QUERY_DATA);
  const [getTables, { data: allTables, loading: tablesloading }] = useMutation(GOOGLE_BIG_QUERY_DATA);
  const [getColumns, { data: allColumns, loading: columnsloading }] = useMutation(GOOGLE_BIG_QUERY_DATA);
  const [getCreditsCalculation, { data: creditsCalculation, loading: creditsLoading }] =
    useMutation(GOOGLE_BIG_QUERY_DATA);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const {
    liftingSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    selectedRecipes,
    setAutomationSendSettings,
    automationIndex,
    id,
  });

  const checkFields = () => {
    // if (!connected || !selectedTableField || !selectedTable) return false;
    if (shouldSave) {
      return false;
    }
    if (saveFrom >= saveTo) {
      setErrorMessage('"Save To" value should be greater than "Save From" value.');
      return false;
    }
    return true;
  };

  //restore
  useEffect(() => {
    getDatasets({
      variables: { action: 'datasets', value: '', query: '' },
    });
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const { keys } = dynamic_settings;
      const { settings } = keys[0];
      setSaveFrom(settings.from);
      setSaveTo(settings.to);
      typeof settings.shouldSave === 'boolean' && setShouldSave(settings.shouldSave);
      setCredits({
        premium: typeof settings.premiumCredits === 'number' ? settings.premiumCredits : 0,
        automation: typeof settings.automationCredits === 'number' ? settings.automationCredits : 0,
      });
      settings.dataset &&
        setSelectedDataset((settings.dataset[0].toUpperCase() + settings.dataset.slice(1)).replace(/_/g, ' '));
      settings.table && setSelectedTable(settings.table.replace(/_/g, ' '));
      settings.selectedColumns &&
        setSelectedColumnList(
          settings.selectedColumns.map((item: any) => {
            return { label: item.replace(/_/g, ' '), value: item };
          }),
        );
      settings.conditions && setColumsListWithFilters(settings.conditions);

      settings?.dataset &&
        getTables({
          variables: { action: 'tables', value: settings.dataset, query: '' },
        });

      settings.dataset &&
        settings.table &&
        getColumns({
          variables: {
            action: 'columns',
            value: `${settings.dataset.toLowerCase().replace(/ /g, '_')}.${settings.table.replace(/ /g, '_')}`,
            query: '',
          },
        });
    }
  }, []);

  //recipe fields or output fields
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, []);

  // getting databases
  useEffect(() => {
    if (allDatasets?.BigQueryOps?.query?.data) {
      const datasetsList = JSON.parse(allDatasets.BigQueryOps.query.data);
      const options = datasetsList.map((item: any) => {
        const val = item.replace(/_/g, ' ');
        return {
          label: val && val[0].toUpperCase() + val.slice(1),
          value: item,
        };
      });
      setDatasetsList(options);
    }
  }, [allDatasets]);

  // getting tables
  useEffect(() => {
    if (allTables?.BigQueryOps?.query?.data) {
      const datasetTablesList = JSON.parse(allTables.BigQueryOps.query.data);
      const options = datasetTablesList.map((item: any) => {
        return {
          label: (typeof item === 'string' ? item : item.table_name).replace(/_/g, ' '),
          value: typeof item === 'string' ? item : item.table_name,
        };
      });

      setTablesList(options);
    }
  }, [allTables]);

  // getting table fields
  useEffect(() => {
    if (allColumns?.BigQueryOps?.query?.data) {
      const columnsList = JSON.parse(allColumns.BigQueryOps.query.data);
      const options = columnsList.map((item: any) => {
        return {
          label: (typeof item === 'string' ? item : item.column_name).replace(/_/g, ' '),
          value: typeof item === 'string' ? item : item.column_name,
          type: item.data_type,
        };
      });
      setTablesColumnsList(options);
    }
  }, [allColumns]);

  useEffect(() => {
    const outputData = [...fieldList.map((item: { value: any }) => item.value)];
    const valuses = ['_bigquery_fullcsv'];
    outputData.push('_bigquery_fullcsv');
    !shouldSave && selectedColumnsList.map((item: any) => outputData.push(item.value));
    !shouldSave && selectedColumnsList.map((item: any) => valuses.push(item.value));

    const jsonParse: any = {};
    valuses.map((item: any) => (jsonParse[item] = 'any'));
    liftingSettings({
      outputKeysData: outputData,
      outputKeysDataWithTypes: { ...jsonParse },
    });
  }, [fieldList]);

  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    if (!selectedDataset) return;
    setClicked(false);

    const conditionsList = selectedFilterColumn &&
      filterCriteria &&
      selectedCondition && {
        column: selectedFilterColumn.value,
        keyword: filterCriteria,
        condition: selectedCondition.value,
        orAnd: null,
      };

    const outputData = [...fieldList.map((item: { value: any }) => item.value)];
    outputData.push('_bigquery_fullcsv');
    const values = ['_bigquery_fullcsv'];
    !shouldSave && selectedColumnsList.map((item: any) => outputData.push(item.value));
    !shouldSave && selectedColumnsList.map((item: any) => values.push(item.value));

    const jsonParse: any = {};
    values.map((item: any) => (jsonParse[item] = 'any'));

    liftingSettings({
      dynamicSettings: {
        keys: [
          {
            inputValue: null,
            key: null,
            settings: {
              dataset: selectedDataset?.toLowerCase().replace(/ /g, '_'),
              table: selectedTable?.replace(/ /g, '_'),
              selectedColumns: selectedColumnsList?.map((item: any) => item.value),
              conditions: conditionsList ? [...columsListWithFilters, conditionsList] : columsListWithFilters,
              from: +saveFrom,
              to: +saveTo,
              shouldSave: shouldSave,
              automationCredits: +credits.automation,
              premiumCredits: +credits.premium,
            },
          },
        ],
      },
      outputKeysData: outputData,
      outputKeysDataWithTypes: { ...jsonParse },
    });
  }, [
    selectedDataset,
    selectedTable,
    selectedColumnsList,
    saveFrom,
    saveTo,
    columsListWithFilters,
    selectedFilterColumn,
    filterCriteria,
    selectedCondition,
    shouldSave,
    credits,
  ]);

  const handleSaveTo = (e: any) => {
    setErrorMessage('');
    let val = e.target.value;
    if (val < 0) {
      val = val * -1;
    }
    setSaveTo(val);
    !clicked && setClicked(true);
    setIsDirty();
  };

  const handleSaveFrom = (e: any) => {
    setErrorMessage('');
    let val = e.target.value;
    if (val < 0) {
      val = val * -1;
    }
    setSaveFrom(val);
    !clicked && setClicked(true);
    setIsDirty();
  };

  const handleExistingFilterColChange = (val: any, index: number) => {
    let list = columsListWithFilters;
    list = list.map((item: any, i: number) => {
      if (i === index) {
        return { ...item, column: val.value, condition: null, keyword: '' };
      } else {
        return item;
      }
    });
    setColumsListWithFilters(list);
  };

  const handleExistingFilterConditionChange = (val: any, index: number) => {
    let list = columsListWithFilters;
    list = list.map((item: any, i: number) => {
      if (i === index) {
        return { ...item, condition: val.value, keyword: '' };
      } else {
        return item;
      }
    });
    setColumsListWithFilters(list);
  };

  const handleFilterCriteriaChange = (e: any, index?: number, integer?: boolean) => {
    setErrorMessage('');
    let list = columsListWithFilters;
    if (typeof index === 'number') {
      list = list.map((item: any, i: number) => {
        if (i === index) {
          return { ...item, keyword: integer ? parseInt(e.target.value) : e.target.value };
        } else {
          return item;
        }
      });
      setColumsListWithFilters(list);
    } else {
      setFilterCriteria(integer ? parseInt(e.target.value) : e.target.value);
    }
    !clicked && setClicked(true);
    setIsDirty();
  };

  const handleAndOrClick = (operator: 'and' | 'or', index?: number) => {
    let list = columsListWithFilters;
    if (typeof index === 'number') {
      list = list.map((item: any, i: number) => {
        if (i === index) {
          return { ...item, orAnd: operator };
        } else {
          return item;
        }
      });
    } else {
      const newValue = {
        column: selectedFilterColumn.value,
        condition: selectedCondition.value,
        keyword: filterCriteria,
        orAnd: operator,
      };
      setSelectedCondition(null);
      setSelectedFilterColumn(null);
      setFilterCriteria('');
      list.push(newValue);
    }
    setColumsListWithFilters(list);
    !clicked && setClicked(true);
    setIsDirty();
  };

  const handleFilterDelete = (index?: number) => {
    setErrorMessage('');
    let list = columsListWithFilters;
    if (typeof index === 'number') {
      list = list.filter((item: any, i: number) => i !== index);
    } else {
      if (list.length === 0) {
        setShowFiltersModal(false);
        setSelectedFilterColumn(null);
        setSelectedCondition(null);
        setFilterCriteria('');
      } else {
        const col = selectedColumnsList?.find((sub: any) => sub.value === list[list.length - 1].column);
        const condition = condtionsList?.find((sub: any) => sub.value === list[list.length - 1].condition);

        col && setSelectedFilterColumn(col);
        condition && setSelectedCondition(condition);
        setFilterCriteria(list[list.length - 1].keyword || '');
        list.pop();
      }
    }
    setColumsListWithFilters(list);
  };

  const handleSave = async () => {
    !clicked && setClicked(true);
    setIsDirty();
    if (saveTo > 9999999999 || saveFrom > 999999999) {
      setErrorMessage('Please select valid number for Rows offset fields');
      return;
    }
    if (saveFrom >= saveTo) {
      setErrorMessage('"Save To" value should be greater than "Save From" value.');
      return;
    }
    if (showFiltersModal && (!selectedFilterColumn || !selectedCondition || !filterCriteria)) {
      setErrorMessage('Please fill out all the filter fields.');
      return;
    }
    const conditionsList = [...columsListWithFilters] || [];
    selectedFilterColumn &&
      filterCriteria &&
      selectedCondition &&
      conditionsList.push({
        column: selectedFilterColumn,
        keyword: filterCriteria,
        condition: selectedCondition,
        orAnd: null,
      });

    const data = await getCreditsCalculation({
      variables: {
        action: 'query',
        value: '',
        query: JSON.stringify({
          dataset: selectedDataset.toLowerCase().replace(/ /g, '_'),
          table: selectedTable.replace(/ /g, '_'),
          selectedColumns: selectedColumnsList.map((item: any) => item.value),
          conditions: conditionsList,
          from: +saveFrom,
          to: +saveTo,
        }),
      },
    });
    setErrorMessage('');
    if (data && data.data && data.data.BigQueryOps && data.data.BigQueryOps.query && data.data.BigQueryOps.query.data) {
      const calc = JSON.parse(data.data.BigQueryOps.query.data);
      const creditsToConsume = calc.credit_price || 0;
      const premiumCreditsToConsume = calc.premium_credit_price || 0;
      const leftCredits = hexomaticUser.left_automation_credit || 0;
      const leftPremiums = (hexomaticUser.left_premium_automation_credit || 0) + (user.left_premium_credits || 0);
      setCredits({ automation: creditsToConsume, premium: premiumCreditsToConsume });
      !clicked && setClicked(true);
      if (leftCredits - creditsToConsume < 0) {
        setShouldUpgrade(true);
        return;
      } else if (leftPremiums - premiumCreditsToConsume < 0) {
        setShouldUpgrade(true);
        setBuyPremiumCredits(true);
        return;
      } else if (creditsToConsume === 0 || premiumCreditsToConsume === 0) {
        setErrorMessage('Something went wrong. Please try again.');
        return;
      }
      setShowConsumptionModal(true);
    }
  };

  const getSelectedColumnObj = (val: any) => {
    const obj = selectedColumnsList.find((sub: any) => sub.value === val);
    return obj;
  };

  const cancelClick = () => {
    setShowPaymentModal(false);
    setShouldUpgrade(false);
    setBuyPremiumCredits(false);
  };

  const actionClick = () => {
    setShouldUpgrade(false);
    buyPremiumCredits ? setShowPaymentModal(true) : window.location.href='https://hexomatic.com/pricing/';
  };

  const handleShowPaymentMethods = () => {
    setShowPaymentModal(false);
    setBuyPremiumCredits(false);
  };

  return (
    <>
      <Row className={shouldSave ? '' : 'disabled-automation'}>
        {(datasetsloading || tablesloading || columnsloading || creditsLoading) && <StretchLoading />}
        {
          <>
            <Col md={6} className="mb-2">
              <span className="label secondary-color">Datasets</span>
              <Datalist
                list={datasetsList}
                value={selectedDataset}
                placeholder={'Select dataset'}
                onChange={(selectedItem: any) => {
                  if (
                    (columsListWithFilters && columsListWithFilters.length > 0) ||
                    (selectedCondition && selectedFilterColumn)
                  ) {
                    setSelectedDatasetToConfirm(selectedItem);
                    setSelectedTableToConfirm(null);
                    setConfirmClearingFilters(true);
                    setSelectedColumnsToConfirm(null);
                  } else {
                    setSelectedDataset(selectedItem);
                    setSelectedColumnList([]);
                    setTablesColumnsList([]);
                    setSelectedTable('');
                    setTablesList(null);
                    getTables({
                      variables: { action: 'tables', value: selectedItem.toLowerCase().replace(/ /g, '_'), query: '' },
                    });
                  }
                  !clicked && setClicked(true);
                  setIsDirty();
                }}
              />
            </Col>
            <Col md={6} className="mb-2">
              <span className="label secondary-color">Tables</span>
              <Datalist
                list={tablesList}
                value={selectedTable}
                placeholder={'Select table'}
                onChange={(selectedItem: any) => {
                  if (
                    (columsListWithFilters && columsListWithFilters.length > 0) ||
                    (selectedCondition && selectedFilterColumn)
                  ) {
                    setSelectedTableToConfirm(selectedItem);
                    setSelectedDatasetToConfirm(null);
                    setConfirmClearingFilters(true);
                    setSelectedColumnsToConfirm(null);
                  } else {
                    setSelectedTable(selectedItem);
                    setSelectedColumnList([]);
                    setTablesColumnsList([]);
                    getColumns({
                      variables: {
                        action: 'columns',
                        value: `${selectedDataset.toLowerCase().replace(/ /g, '_')}.${selectedItem.replace(/ /g, '_')}`,
                        query: '',
                      },
                    });
                  }
                  !clicked && setClicked(true);
                  setIsDirty();
                }}
              />
            </Col>
            <Col md={6} className="mb-2">
              <span className="label secondary-color">Columns</span>
              <div className="fields-with-filter">
                <CustomMultiSelect
                  options={tablesColumnsList}
                  value={selectedColumnsList}
                  onChange={(sel: any) => {
                    if (
                      (columsListWithFilters && columsListWithFilters.length > 0) ||
                      (selectedCondition && selectedFilterColumn)
                    ) {
                      setSelectedColumnsToConfirm(sel);
                      setConfirmClearingFilters(true);
                    } else {
                      setSelectedColumnList(sel);
                      !clicked && setClicked(true);
                      setIsDirty();
                    }
                  }}
                  labelledBy="Select"
                />
                <span
                  className={`filter-icon ${!selectedColumnsList || selectedColumnsList.length < 1 ? 'disabled' : ''}`}
                  onClick={() => setShowFiltersModal(true)}
                >
                  {filterIcon}
                </span>
              </div>
            </Col>
            <Col md={6} sm={12} className="mb-2 d-flex">
              <span className="label primary-color d-block mt-4 ml-3" style={{ fontWeight: 600 }}>
                Rows
              </span>
              <div className="d-flex">
                <div className="px-3">
                  <span className="label secondary-color">From</span>
                  <Input value={saveFrom} onChange={handleSaveFrom} type="number" />
                </div>
                <div>
                  <span className="label secondary-color">To</span>
                  <Input value={saveTo} onChange={handleSaveTo} type="number" />
                </div>
              </div>
            </Col>
            {(showFiltersModal || (columsListWithFilters && columsListWithFilters.length > 0)) && (
              <>
                <Col md={12} className="my-2">
                  <h5 className="primary-color">Match the result to the following criteria</h5>
                </Col>
                <Row className="filter-container mx-3">
                  {columsListWithFilters &&
                    !!columsListWithFilters.length &&
                    columsListWithFilters.map((item: any, index: any) => (
                      <>
                        <Col md={4}>
                          <span className="label secondary-color">Filter column</span>
                          <Select
                            options={selectedColumnsList}
                            value={getSelectedColumnObj(item.column)}
                            placeholder={'Select column'}
                            onChange={(val: any) => {
                              handleExistingFilterColChange(val, index);
                              !clicked && setClicked(true);
                              setIsDirty();
                            }}
                          />
                        </Col>
                        <Col md={4}>
                          <span className="label secondary-color">Condition</span>
                          <Select
                            options={
                              getSelectedColumnObj(item.column)?.type === 'STRING'
                                ? stringConditions
                                : !getSelectedColumnObj(item.column)?.type?.includes('ARRAY') &&
                                  (getSelectedColumnObj(item.column)?.type?.includes('INT') ||
                                    getSelectedColumnObj(item.column)?.type?.includes('FLOAT'))
                                ? intConditions
                                : condtionsList
                            }
                            value={condtionsList.find(sub => sub.value === item.condition)}
                            placeholder={'Select condition'}
                            onChange={(val: any) => {
                              handleExistingFilterConditionChange(val, index);
                              !clicked && setClicked(true);
                              setIsDirty();
                            }}
                          />
                        </Col>
                        <Col md={4} className="d-flex">
                          <div>
                            <span className="label secondary-color">Criteria</span>
                            <Input
                              value={item.keyword}
                              onChange={(e: any) => {
                                handleFilterCriteriaChange(
                                  e,
                                  index,
                                  !getSelectedColumnObj(item.column)?.type?.includes('ARRAY') &&
                                    (getSelectedColumnObj(item.column)?.type?.includes('INT') ||
                                      getSelectedColumnObj(item.column)?.type?.includes('FLOAT')),
                                );
                                !clicked && setClicked(true);
                                setIsDirty();
                              }}
                              type={
                                !getSelectedColumnObj(item.column)?.type?.includes('ARRAY') &&
                                (getSelectedColumnObj(item.column)?.type?.includes('INT') ||
                                  getSelectedColumnObj(item.column)?.type?.includes('FLOAT'))
                                  ? 'number'
                                  : 'text'
                              }
                            />
                          </div>
                          <div className="d-flex mt-3">
                            <Badge
                              messageType="badge-product"
                              buttonType={item.orAnd === 'and' ? 'background' : 'light-background'}
                              className="cursor-pointer mx-2"
                              onClick={() => handleAndOrClick('and', index)}
                            >
                              <span>and</span>
                            </Badge>
                            <Badge
                              messageType="badge-product"
                              buttonType={item.orAnd === 'or' ? 'background' : 'light-background'}
                              className="cursor-pointer mx-2"
                              onClick={() => handleAndOrClick('or', index)}
                            >
                              <span>or</span>
                            </Badge>
                            <div className="m-vertical-auto cursor-pointer" onClick={() => handleFilterDelete(index)}>
                              {closeIcon}
                            </div>
                          </div>
                        </Col>
                      </>
                    ))}
                  {showFiltersModal && (
                    <>
                      <Col md={4}>
                        <span className="label secondary-color">Filter column</span>
                        <Select
                          options={selectedColumnsList}
                          value={selectedFilterColumn}
                          placeholder={'Select column'}
                          onChange={(val: any) => {
                            setErrorMessage('');
                            setSelectedFilterColumn(val);
                            setSelectedCondition(null);
                            setFilterCriteria('');
                            !clicked && setClicked(true);
                            setIsDirty();
                          }}
                        />
                      </Col>
                      <Col md={4}>
                        <span className="label secondary-color">Condition</span>
                        <Select
                          options={
                            selectedFilterColumn?.type === 'STRING'
                              ? stringConditions
                              : !selectedFilterColumn?.type?.includes('ARRAY') &&
                                (selectedFilterColumn?.type?.includes('INT') ||
                                  selectedFilterColumn?.type?.includes('FLOAT'))
                              ? intConditions
                              : condtionsList
                          }
                          value={selectedCondition}
                          placeholder={'Select condition'}
                          onChange={(val: any) => {
                            setErrorMessage('');
                            setSelectedCondition(val);
                            setFilterCriteria('');
                            !clicked && setClicked(true);
                            setIsDirty();
                          }}
                        />
                      </Col>
                      <Col md={4} className="d-flex">
                        <div>
                          <span className="label secondary-color">Criteria</span>
                          <Input
                            value={filterCriteria}
                            onChange={(e: any) =>
                              handleFilterCriteriaChange(
                                e,
                                undefined,
                                selectedFilterColumn?.type?.includes('ARRAY') &&
                                  (selectedFilterColumn?.type?.includes('INT') ||
                                    selectedFilterColumn?.type?.includes('FLOAT')),
                              )
                            }
                            type={
                              selectedFilterColumn?.type?.includes('ARRAY') &&
                              (selectedFilterColumn?.type?.includes('INT') ||
                                selectedFilterColumn?.type?.includes('FLOAT'))
                                ? 'number'
                                : 'text'
                            }
                          />
                        </div>

                        {columsListWithFilters && columsListWithFilters.length >= 15 ? (
                          <div className="d-flex mt-4">
                            <div className="m-vertical-auto cursor-pointer">{closeIcon}</div>
                          </div>
                        ) : (
                          <div className="d-flex mt-3">
                            <Badge
                              messageType={
                                !selectedFilterColumn || !selectedCondition ? 'badge-secondary' : 'badge-product'
                              }
                              buttonType={'light-background'}
                              className={`cursor-pointer mx-2 ${
                                !selectedFilterColumn || !selectedCondition ? 'disabled-badge' : ''
                              }`}
                              onClick={() => handleAndOrClick('and')}
                            >
                              <span>and</span>
                            </Badge>
                            <Badge
                              messageType={
                                !selectedFilterColumn || !selectedCondition ? 'badge-secondary' : 'badge-product'
                              }
                              buttonType={'light-background'}
                              className={`cursor-pointer mx-2 ${
                                !selectedFilterColumn || !selectedCondition ? 'disabled-badge' : ''
                              }`}
                              onClick={() => handleAndOrClick('or')}
                            >
                              <span>or</span>
                            </Badge>
                            <div className="m-vertical-auto cursor-pointer" onClick={() => handleFilterDelete()}>
                              {closeIcon}
                            </div>
                          </div>
                        )}
                      </Col>
                    </>
                  )}
                </Row>
              </>
            )}
          </>
        }
      </Row>
      <Row>
        {!shouldSave && (
          <Col md={8} className="d-flex mt-2">
            <p className="my-auto">
              Automation will consume {credits.automation} automation credits and {credits.premium.toFixed(3)} premium
              credits.
            </p>
          </Col>
        )}
        <Col md={shouldSave ? 12 : 4}>
          <Button
            name={shouldSave ? 'Save' : 'Edit'}
            className="mt-2 ml-auto"
            loading={false}
            onClick={() => {
              if (shouldSave) {
                handleSave();
              } else {
                setShouldSave(true);
                !clicked && setClicked(true);
                setIsDirty();
              }
            }}
            leftIcon={shouldSave ? saveIcon : editIcon}
            disabled={!selectedDataset || !selectedTable || !selectedColumnsList || selectedColumnsList.length === 0}
          />
        </Col>
        {errorMessage && (
          <Col md={12}>
            <Alert type="error" className="create-workflow">
              {errorMessage}
            </Alert>
          </Col>
        )}
      </Row>
      <Modal
        isModalOpen={showConsumptionModal}
        title={`The automation will consume ${credits.automation} automation credits and ${credits.premium.toFixed(
          3,
        )} premium credits. Please confirm to continue`}
        actionBtnText={'Confirm'}
        cancelBtnText={'Go back'}
        actionBtnColor={'warning'}
        cancelBtnColor={'secondary'}
        onActionClick={() => {
          setShowConsumptionModal(false);
          setShouldSave(false);
          !clicked && setClicked(true);
          setIsDirty();
        }}
        onCancelClick={() => {
          setShowConsumptionModal(false);
          setShouldSave(true);
          !clicked && setClicked(true);
          setIsDirty();
        }}
        escButtonClick={() => {
          setShowConsumptionModal(false);
          setShouldSave(true);
          !clicked && setClicked(true);
          setIsDirty();
        }}
      />
      <Modal
        isModalOpen={shouldUpgrade}
        text={
          buyPremiumCredits
            ? `The automation will consume ${credits.premium.toFixed(
                3,
              )} premium credits and your preium credits balance is ${(
                (hexomaticUser.left_premium_automation_credit || 0) + (user.left_premium_credits || 0)
              ).toFixed(3)}.`
            : `The automation will consume ${credits.automation} automation credits and your credits balance is ${
                hexomaticUser.left_automation_credit || 0
              }.`
        }
        title={'Please upgrade to continue'}
        actionBtnText={'Upgrade'}
        cancelBtnText={'Cancel'}
        actionBtnColor={'warning'}
        cancelBtnColor={'secondary'}
        onActionClick={actionClick}
        onCancelClick={cancelClick}
        escButtonClick={cancelClick}
      />
      {showPaymentModal && (
        <CustomModal ShowPopup={showPaymentModal} escButtonClick={showPaymentModal}>
          <Payment
            name={''}
            selectedValue={{ title: '105/monthly', value: 105 }}
            price={0}
            premium_credit
            setShowPopup={handleShowPaymentMethods}
            // offer
          />
        </CustomModal>
      )}
      <Modal
        isModalOpen={confirmClearingFilters}
        title={`Please note that all the filters will be cleared.`}
        actionBtnText={'Confirm'}
        cancelBtnText={'Cancel'}
        actionBtnColor={'warning'}
        cancelBtnColor={'secondary'}
        onActionClick={() => {
          if (typeof selectedDatasetToConfirm === 'string') {
            setSelectedDataset(selectedDatasetToConfirm);
            setSelectedColumnList([]);
            setTablesColumnsList([]);
            setSelectedTable('');
            setTablesList(null);
            setSelectedFilterColumn(null);
            setFilterCriteria('');
            setSelectedCondition(null);
            setColumsListWithFilters([]);
            setShowFiltersModal(false);
            getTables({
              variables: {
                action: 'tables',
                value: selectedDatasetToConfirm.toLowerCase().replace(/ /g, '_'),
                query: '',
              },
            });
          } else if (typeof selectedTableToConfirm === 'string') {
            setSelectedTable(selectedTableToConfirm);
            setSelectedTableToConfirm(null);
            setSelectedColumnList([]);
            setTablesColumnsList([]);
            setSelectedFilterColumn(null);
            setFilterCriteria('');
            setSelectedCondition(null);
            setColumsListWithFilters([]);
            setShowFiltersModal(false);
            getColumns({
              variables: {
                action: 'columns',
                value: `${selectedDataset.toLowerCase().replace(/ /g, '_')}.${selectedTableToConfirm.replace(
                  / /g,
                  '_',
                )}`,
                query: '',
              },
            });
          } else if (selectedColumnsToConfirm) {
            setSelectedColumnList(selectedColumnsToConfirm);
            setSelectedFilterColumn(null);
            setFilterCriteria('');
            setSelectedCondition(null);
            setColumsListWithFilters([]);
            setShowFiltersModal(false);
          }
          !clicked && setClicked(true);
          setIsDirty();
          setConfirmClearingFilters(false);
        }}
        onCancelClick={() => setConfirmClearingFilters(false)}
        escButtonClick={() => setConfirmClearingFilters(false)}
      />
    </>
  );
};

export default GoogleBigData;
