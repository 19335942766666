import { FC, useContext, memo } from 'react';
import { ThemeContext } from 'contexts/theme-context';
import { closeIcon } from 'assets/svg-icons';
import pricingIcon from 'assets/payments/pricing-hint.png';
import pricingDarkIcon from 'assets/payments/pricing-hint-dark.png';

const ContactUs: FC<{ handleCloseModal?: any; showCloseIcon?: boolean; showBorder?: boolean }> = ({
  handleCloseModal,
  showCloseIcon,
  showBorder,
}) => {
  const { theme } = useContext(ThemeContext);

  return (
    <div className={`text-center all-disabled ${!showBorder ? 'without' : ''}`}>
      {showCloseIcon && <span onClick={handleCloseModal}>{closeIcon}</span>}
      <img src={theme === 'light' ? pricingIcon : pricingDarkIcon} alt="icon" />
      <div>Need to upgrade your plan? </div>
      <div className="support">
        Please contact{' '}
        <a href="mailto:support@hexomatic.com" className="color-hexomatic" aria-label={'mail'} target='_blank' rel='noopener'>
          support@hexomatic.com
        </a>
        {'.'}
      </div>
    </div>
  );
};

export default memo(ContactUs);
