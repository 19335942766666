export const columnOptions: { label: string; value: string }[] = [
  { label: 'A', value: 'A' },
  { label: 'B', value: 'B' },
  { label: 'C', value: 'C' },
  { label: 'D', value: 'D' },
  { label: 'E', value: 'E' },
  { label: 'F', value: 'F' },
  { label: 'G', value: 'G' },
  { label: 'H', value: 'H' },
  { label: 'I', value: 'I' },
  { label: 'J', value: 'J' },
  { label: 'K', value: 'K' },
  { label: 'L', value: 'L' },
  { label: 'M', value: 'M' },
  { label: 'N', value: 'N' },
  { label: 'O', value: 'O' },
  { label: 'P', value: 'P' },
  { label: 'Q', value: 'Q' },
  { label: 'R', value: 'R' },
  { label: 'S', value: 'S' },
  { label: 'T', value: 'T' },
  { label: 'U', value: 'U' },
  { label: 'V', value: 'V' },
  { label: 'W', value: 'W' },
  { label: 'X', value: 'X' },
  { label: 'Y', value: 'Y' },
  { label: 'Z', value: 'Z' },
  { label: 'AA', value: 'AA' },
  { label: 'AB', value: 'AB' },
  { label: 'AC', value: 'AC' },
  { label: 'AD', value: 'AD' },
  { label: 'AE', value: 'AE' },
  { label: 'AF', value: 'AF' },
  { label: 'AG', value: 'AG' },
  { label: 'AH', value: 'AH' },
  { label: 'AI', value: 'AI' },
  { label: 'AJ', value: 'AJ' },
  { label: 'AK', value: 'AK' },
  { label: 'AL', value: 'AL' },
  { label: 'AM', value: 'AM' },
  { label: 'AN', value: 'AN' },
  { label: 'AO', value: 'AO' },
  { label: 'AP', value: 'AP' },
  { label: 'AQ', value: 'AQ' },
  { label: 'AR', value: 'AR' },
  { label: 'AS', value: 'AS' },
  { label: 'AT', value: 'AT' },
  { label: 'AU', value: 'AU' },
  { label: 'AV', value: 'AV' },
  { label: 'AW', value: 'AW' },
  { label: 'AX', value: 'AX' },
  { label: 'AY', value: 'AY' },
  { label: 'AZ', value: 'AZ' },
];

export const voiceContryOptions = [
  { label: 'Afrikaans (South Africa)', value: 'af-ZA' },
  { label: 'Arabic', value: 'ar-XA' },
  { label: 'Bengali (India)', value: 'bn-IN' },
  { label: 'Bulgarian (Bulgaria)', value: 'bg-BG' },
  { label: 'Catalan (Spain)', value: 'ca-ES' },
  { label: 'Chinese (Hong Kong)', value: 'yue-HK' },
  { label: 'Czech (Czech Republic)', value: 'cs-CZ' },
  { label: 'Danish (Denmark)', value: 'da-DK' },
  { label: 'Dutch (Belgium)', value: 'nl-BE' },
  { label: 'Dutch (Netherlands)', value: 'nl-NL' },
  { label: 'English (Australia)', value: 'en-AU' },
  { label: 'English (India)', value: 'en-IN' },
  { label: 'English (UK)', value: 'en-GB' },
  { label: 'English (US)', value: 'en-US' },
  { label: 'Filipino (Philippines)', value: 'fil-PH' },
  { label: 'Finnish (Finland)', value: 'fi-FI' },
  { label: 'French (Canada)', value: 'fr-CA' },
  { label: 'French (France)', value: 'fr-FR' },
  { label: 'German (Germany)', value: 'de-DE' },
  { label: 'Greek (Greece)', value: 'el-GR' },
  { label: 'Gujarati (India)', value: 'gu-IN' },
  { label: 'Hindi (India)', value: 'hi-IN' },
  { label: 'Hungarian (Hungary)', value: 'hu-HU' },
  { label: 'Icelandic (Iceland)', value: 'is-IS' },
  { label: 'Indonesian (Indonesia)', value: 'id-ID' },
  { label: 'Italian (Italy)', value: 'it-IT' },
  { label: 'Japanese (Japan)', value: 'ja-JP' },
  { label: 'Kannada (India)', value: 'kn-IN' },
  { label: 'Korean (South Korea)', value: 'ko-KR' },
  { label: 'Latvian (Latvia)', value: 'lv-LV' },
  { label: 'Malay (Malaysia)', value: 'ms-MY' },
  { label: 'Malayalam (India)', value: 'ml-IN' },
  { label: 'Mandarin Chinese', value: 'cmn-CN' },
  { label: 'Norwegian (Norway)', value: 'nb-NO' },
  { label: 'Polish (Poland)', value: 'pl-PL' },
  { label: 'Portuguese (Brazil)', value: 'pt-BR' },
  { label: 'Portuguese (Portugal)', value: 'pt-PT' },
  { label: 'Romanian (Romania)', value: 'ro-RO' },
  // { label: 'Russian (Russia)', value: 'ru-RU' },
  { label: 'Serbian (Cyrillic)', value: 'sr-RS' },
  { label: 'Slovak (Slovakia)', value: 'sk-SK' },
  { label: 'Spanish (Spain)', value: 'es-ES' },
  { label: 'Spanish (US)', value: 'es-US' },
  { label: 'Swedish (Sweden)', value: 'sv-SE' },
  { label: 'Tamil (India)', value: 'ta-IN' },
  { label: 'Telugu (India)', value: 'te-IN' },
  { label: 'Thai (Thailand)', value: 'th-TH' },
  { label: 'Turkish (Turkey)', value: 'tr-TR' },
  { label: 'Ukrainian (Ukraine)', value: 'uk-UA' },
  { label: 'Vietnamese (Vietnam)', value: 'vi-VN' },
];

export const speechContryOptions: { label: string; value: string }[] = [
  { label: 'Afrikaans', value: 'af-ZA' },
  { label: 'Arabic (Gulf)', value: 'ar-AE' },
  { label: 'Arabic (Modern Standard)', value: 'ar-SA' },
  { label: 'Chinese (Simplified)', value: 'zh-CN' },
  { label: 'Chinese (Traditional)', value: 'zh-TW' },
  { label: 'Danish', value: 'da-DK' },
  { label: 'Dutch', value: 'nl-NL' },
  { label: 'English (Australian)', value: 'en-AU' },
  { label: 'English (British)', value: 'en-GB' },
  { label: 'English (Indian)', value: 'en-IN' },
  { label: 'English (Irish)', value: 'en-IE' },
  { label: 'English (New Zealand)', value: 'en-NZ' },
  { label: 'English (Scottish)', value: 'en-AB' },
  { label: 'English (South African)', value: 'en-ZA' },
  { label: 'English (US)', value: 'en-US' },
  { label: 'English (Welsh)', value: 'en-WL' },
  { label: 'French', value: 'fr-FR' },
  { label: 'French (Canadian)', value: 'fr-CA' },
  { label: 'Farsi', value: 'fa-IR' },
  { label: 'German', value: 'de-DE' },
  { label: 'German (Swiss)', value: 'de-CH' },
  { label: 'Hebrew', value: 'he-IL' },
  { label: 'Hindi (Indian)', value: 'hi-IN' },
  { label: 'Indonesian', value: 'id-ID' },
  { label: 'Italian', value: 'it-IT' },
  { label: 'Japanese', value: 'ja-JP' },
  { label: 'Korean', value: 'ko-KR' },
  { label: 'Malay', value: 'ms-MY' },
  { label: 'Portuguese', value: 'pt-PT' },
  { label: 'Portuguese (Brazilian)', value: 'pt-BR' },
  // { label: 'Russian', value: 'ru-RU' },
  { label: 'Spanish', value: 'es-ES' },
  { label: 'Spanish (US)', value: 'es-US' },
  { label: 'Tamil', value: 'ta-IN' },
  { label: 'Telugu', value: 'te-IN' },
  { label: 'Thai', value: 'th-TH' },
  { label: 'Turkish', value: 'tr-TR' },
];

export const deviceOptions = [
  {
    label: 'Mobile Small',
    value: 'MOBILE_SMALL',
  },
  {
    label: 'Mobile Medium',
    value: 'MOBILE_MEDIUM',
  },
  {
    label: 'Mobile Large',
    value: 'MOBILE_LARGE',
  },
  {
    label: 'Tablet',
    value: 'TABLET',
  },
  {
    label: 'Laptop Small',
    value: 'LAPTOP_SMALL',
  },
  {
    label: 'Laptop Medium',
    value: 'LAPTOP_MEDIUM',
  },
  {
    label: 'Laptop Large',
    value: 'LAPTOP_LARGE',
  },
  {
    label: 'Desktop 4k',
    value: 'DESKTOP_4K',
  },
];

export const screenShotDelayOptions = [
  { label: 5, value: 5 },
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 30, value: 30 },
];

export const mathFormulaOperationsOptions = [
  { label: '+', value: 'addition' },
  { label: '-', value: 'subtraction' },
  { label: '*', value: 'multiplication' },
  { label: '/', value: 'division' },
];

export const mathFormulaTypeOptions = [
  { label: 'from source', value: 'from source' },
  { label: 'manually', value: 'manually' },
];

export const numberOption: { label: string; value: string }[] = [
  { label: 'TO FIXED N', value: 'TO_FIXED_N' },
  { label: 'ABSOLUTE VALUE', value: 'ABSOLUTE_VALUE' },
  { label: 'ROUND A NUMBER', value: 'ROUND_A_NUMBER' },
  { label: 'FLOOR A NUMBER', value: 'FLOOR_A_NUMBER' },
  { label: 'NUMBER US FORMAT', value: 'NUMBER_USA_FORMAT' },
  { label: 'NUMBER EU FORMAT', value: 'NUMBER_EUR_FORMAT' },
];

export const fixNumbers: { label: string; value: number }[] = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
];

export const deepTraslationOptions: { label: string; value: string }[] = [
  { label: 'Bulgarian', value: 'BG' },
  { label: 'Chinese', value: 'ZH' },
  { label: 'Czech', value: 'CS' },
  { label: 'Danish', value: 'DA' },
  { label: 'Dutch', value: 'NL' },
  { label: 'English', value: 'EN' },
  { label: 'Estonian', value: 'ET' },
  { label: 'Finnish', value: 'FI' },
  { label: 'French', value: 'FR' },
  { label: 'German', value: 'DE' },
  { label: 'Greek', value: 'EL' },
  { label: 'Hungarian', value: 'HU' },
  { label: 'Indonesian', value: 'ID' },

  { label: 'Italian', value: 'IT' },
  { label: 'Japanese', value: 'JA' },
  { label: 'Latvian', value: 'LV' },
  { label: 'Lithuanian', value: 'LT' },
  { label: 'Polish', value: 'PL' },
  { label: 'Portuguese', value: 'PT' },
  { label: 'Romanian', value: 'RO' },
  // { label: 'Russian', value: 'RU' },
  { label: 'Slovak', value: 'SK' },
  { label: 'Slovenian', value: 'SL' },
  { label: 'Spanish', value: 'ES' },
  { label: 'Swedish', value: 'SV' },
  { label: 'Turkish', value: 'TR' },
  { label: 'Ukrainian', value: 'UK' },
];

export const deepTraslationTargetOptions: { label: string; value: string }[] = [
  { label: 'Bulgarian', value: 'BG' },
  { label: 'Chinese', value: 'ZH' },
  { label: 'Czech', value: 'CS' },
  { label: 'Danish', value: 'DA' },
  { label: 'Dutch', value: 'NL' },
  { label: 'English (American)', value: 'EN-US' },
  { label: 'English (British)', value: 'EN-GB' },
  { label: 'Estonian', value: 'ET' },
  { label: 'Finnish', value: 'FI' },
  { label: 'French', value: 'FR' },
  { label: 'German', value: 'DE' },
  { label: 'Greek', value: 'EL' },
  { label: 'Hungarian', value: 'HU' },
  { label: 'Indonesian', value: 'ID' },
  { label: 'Italian', value: 'IT' },
  { label: 'Japanese', value: 'JA' },
  { label: 'Latvian', value: 'LV' },
  { label: 'Lithuanian', value: 'LT' },
  { label: 'Polish', value: 'PL' },
  { label: 'Portuguese', value: 'PT' },
  { label: 'Portuguese (Brazilian)', value: 'PT-BR' },
  { label: 'Portuguese (all Portuguese varieties excluding Brazilian Portuguese)', value: 'PT-PT' },
  { label: 'Romanian', value: 'RO' },
  // { label: 'Russian', value: 'RU' },
  { label: 'Slovak', value: 'SK' },
  { label: 'Slovenian', value: 'SL' },
  { label: 'Spanish', value: 'ES' },
  { label: 'Swedish', value: 'SV' },
  { label: 'Turkish', value: 'TR' },
  { label: 'Ukrainian', value: 'UK' },
];

export const deeplFormalityTranslationsOptions: { label: string; value: string }[] = [
  { label: 'Dutch', value: 'NL' },
  { label: 'French', value: 'FR' },
  { label: 'German', value: 'DE' },
  { label: 'Indonesian', value: 'ID' },

  { label: 'Italian', value: 'IT' },
  { label: 'Polish', value: 'PL' },
  { label: 'Portuguese (Brazilian)', value: 'PT-BR' },
  { label: 'Portuguese (all Portuguese varieties excluding Brazilian Portuguese)', value: 'PT-PT' },
  // { label: 'Russian', value: 'RU' },
  { label: 'Spanish', value: 'ES' },
  { label: 'Turkish', value: 'TR' },
];

export const formalityOptions: { label: string; value: string }[] = [
  { label: 'automatic', value: 'default' },
  { label: 'formal', value: 'more' },
  { label: 'informal', value: 'less' },
];

export const cryptoCurrencyOptions: { label: string; value: string }[] = [
  { label: 'Aave', value: 'AAVE' },
  { label: 'Cardano', value: 'ADA' },
  { label: 'Algorand', value: 'ALGO' },
  { label: 'Amp', value: 'AMP' },
  { label: 'Cosmos', value: 'ATOM' },
  { label: 'Avalanche', value: 'AVAX' },
  { label: 'Axie Infinity', value: 'AXS' },
  { label: 'BakeryToken', value: 'BAKE' },
  { label: 'Basic Attention Token', value: 'BAT' },
  { label: 'Bitcoin Cash', value: 'BCH' },
  { label: 'Binance Coin', value: 'BNB' },
  { label: 'Bancor', value: 'BNT' },
  { label: 'Bitcoin SV', value: 'BSV' },
  { label: 'Bitcoin', value: 'BTC' },
  { label: 'Bitcoin BEP2', value: 'BTCB' },
  { label: 'Bitcoin Gold', value: 'BTG' },
  { label: 'BitTorrent', value: 'BTT' },
  { label: 'Binance USD', value: 'BUSD' },
  { label: 'PancakeSwap', value: 'CAKE' },
  { label: 'Celsius', value: 'CEL' },
  { label: 'Celo', value: 'CELO' },
  { label: 'SwissBorg', value: 'CHSB' },
  { label: 'Chiliz', value: 'CHZ' },
  { label: 'Compound', value: 'COMP' },
  { label: 'Crypto.com Coin', value: 'CRO' },
  { label: 'Curve DAO Token', value: 'CRV' },
  { label: 'Dai', value: 'DAI' },
  { label: 'Dash', value: 'DASH' },
  { label: 'Decred', value: 'DCR' },
  { label: 'DigiByte', value: 'DGB' },
  { label: 'Dogecoin', value: 'DOGE' },
  { label: 'Polkadot', value: 'DOT' },
  { label: 'Elrond', value: 'EGLD' },
  { label: 'Enjin Coin', value: 'ENJ' },
  { label: 'EOS', value: 'EOS' },
  { label: 'Ethereum Classic', value: 'ETC' },
  { label: 'Ethereum', value: 'ETH' },
  { label: 'Filecoin', value: 'FIL' },
  { label: 'Flow', value: 'FLOW' },
  { label: 'FTX Token', value: 'FTT' },
  { label: 'The Graph', value: 'GRT' },
  { label: 'Hedera Hashgraph', value: 'HBAR' },
  { label: 'Helium', value: 'HNT' },
  { label: 'Holo', value: 'HOT' },
  { label: 'Huobi Token', value: 'HT' },
  { label: 'HUSD', value: 'HUSD' },
  { label: 'Internet Computer', value: 'ICP' },
  { label: 'KuCoin Token', value: 'KCS' },
  { label: 'Klaytn', value: 'KLAY' },
  { label: 'Kusama', value: 'KSM' },
  { label: 'UNUS SED LEO', value: 'LEO' },
  { label: 'Chainlink', value: 'LINK' },
  { label: 'Litecoin', value: 'LTC' },
  { label: 'Terra', value: 'LUNA' },
  { label: 'Decentraland', value: 'MANA' },
  { label: 'Polygon', value: 'MATIC' },
  { label: 'Mdex', value: 'MDX' },
  { label: 'IOTA', value: 'MIOTA' },
  { label: 'Maker', value: 'MKR' },
  { label: 'NEAR Protocol', value: 'NEAR' },
  { label: 'Neo', value: 'NEO' },
  { label: 'Nexo', value: 'NEXO' },
  { label: 'OKB', value: 'OKB' },
  { label: 'Harmony', value: 'ONE' },
  { label: 'Ontology', value: 'ONT' },
  { label: 'Paxos Standard', value: 'PAX' },
  { label: 'Quant', value: 'QNT' },
  { label: 'Qtum', value: 'QTUM' },
  { label: 'Revain', value: 'REV' },
  { label: 'THORChain', value: 'RUNE' },
  { label: 'Ravencoin', value: 'RVN' },
  { label: 'Siacoin', value: 'SC' },
  { label: 'SHIBA INU', value: 'SHIB' },
  { label: 'Synthetix', value: 'SNX' },
  { label: 'Solana', value: 'SOL' },
  { label: 'Stacks', value: 'STX' },
  { label: 'SushiSwap', value: 'SUSHI' },
  { label: 'Telcoin', value: 'TEL' },
  { label: 'Theta Fuel', value: 'TFUEL' },
  { label: 'THETA', value: 'THETA' },
  { label: 'TRON', value: 'TRX' },
  { label: 'TrueUSD', value: 'TUSD' },
  { label: 'Uniswap', value: 'UNI' },
  { label: 'USD Coin', value: 'USDC' },
  { label: 'Tether', value: 'USDT' },
  { label: 'TerraUSD', value: 'UST' },
  { label: 'VeChain', value: 'VET' },
  { label: 'Waves', value: 'WAVES' },
  { label: 'Wrapped Bitcoin', value: 'WBTC' },
  { label: 'XinFin Network', value: 'XDC' },
  { label: 'NEM', value: 'XEM' },
  { label: 'Stellar', value: 'XLM' },
  { label: 'Monero', value: 'XMR' },
  { label: 'XRP', value: 'XRP' },
  { label: 'Tezos', value: 'XTZ' },
  { label: 'yearn.finance', value: 'YFI' },
  { label: 'Zcash', value: 'ZEC' },
  { label: 'Horizen', value: 'ZEN' },
  { label: 'Zilliqa', value: 'ZIL' },
  { label: '0x', value: 'ZRX' },
  { label: 'USD', value: 'USD' },
];

const newArr = cryptoCurrencyOptions.map(item => ({ label: item.value, value: item.label }));

export const currencyConverter: { label: string; value: string }[] = [
  { label: 'USD', value: 'USD' },
  { label: 'JPY', value: 'JPY' },
  { label: 'BGN', value: 'BGN' },
  { label: 'CZK', value: 'CZK' },
  { label: 'DKK', value: 'DKK' },
  { label: 'GBP', value: 'GBP' },
  { label: 'HUF', value: 'HUF' },
  { label: 'PLN', value: 'PLN' },
  { label: 'RON', value: 'RON' },
  { label: 'SEK', value: 'SEK' },
  { label: 'CHF', value: 'CHF' },
  { label: 'ISK', value: 'ISK' },
  { label: 'NOK', value: 'NOK' },
  { label: 'HRK', value: 'HRK' },
  // { label: 'RUB', value: 'RUB' },
  { label: 'TRY', value: 'TRY' },
  { label: 'AUD', value: 'AUD' },
  { label: 'BRL', value: 'BRL' },
  { label: 'CAD', value: 'CAD' },
  { label: 'CNY', value: 'CNY' },
  { label: 'HKD', value: 'HKD' },
  { label: 'IDR', value: 'IDR' },
  { label: 'ILS', value: 'ILS' },
  { label: 'INR', value: 'INR' },
  { label: 'KRW', value: 'KRW' },
  { label: 'MXN', value: 'MXN' },
  { label: 'MYR', value: 'MYR' },
  { label: 'NZD', value: 'NZD' },
  { label: 'PHP', value: 'PHP' },
  { label: 'SGD', value: 'SGD' },
  { label: 'THB', value: 'THB' },
  { label: 'ZAR', value: 'ZAR' },
  { label: 'EUR', value: 'EUR' },
];

export const traslationOptions: { label: string; value: string }[] = [
  { label: 'Afrikaans', value: 'af' },
  { label: 'Albanian', value: 'sq' },
  { label: 'Amharic', value: 'am' },
  { label: 'Arabic', value: 'ar' },
  { label: 'Armenian', value: 'hy' },
  { label: 'Azerbaijani', value: 'az' },
  { label: 'Basque', value: 'eu' },
  // { label: 'Belarusian', value: 'be' },
  { label: 'Bengali', value: 'bn' },
  { label: 'Bosnian', value: 'bs' },
  { label: 'Bulgarian', value: 'bg' },
  { label: 'Catalan', value: 'ca' },
  { label: 'Cebuano', value: 'ceb' },
  { label: 'Chichewa', value: 'ny' },
  { label: 'Chinese (Simplified)', value: 'zh-CN' },
  { label: 'Chinese (Traditional)', value: 'zh-TW' },
  { label: 'Corsican', value: 'co' },
  { label: 'Croatian', value: 'hr' },
  { label: 'Czech', value: 'cs' },
  { label: 'Danish', value: 'da' },
  { label: 'Dutch', value: 'nl' },
  { label: 'English', value: 'en' },
  { label: 'Esperanto', value: 'eo' },
  { label: 'Estonian', value: 'et' },
  { label: 'Filipino', value: 'tl' },
  { label: 'Finnish', value: 'fi' },
  { label: 'French', value: 'fr' },
  { label: 'Frisian', value: 'fy' },
  { label: 'Galician', value: 'gl' },
  { label: 'Georgian', value: 'ka' },
  { label: 'German', value: 'de' },
  { label: 'Greek', value: 'el' },
  { label: 'Gujarati', value: 'gu' },
  { label: 'Haitian Creole', value: 'ht' },
  { label: 'Hausa', value: 'ha' },
  { label: 'Hawaiian', value: 'haw' },
  { label: 'Hebrew', value: 'iw' },
  { label: 'Hindi', value: 'hi' },
  { label: 'Hmong', value: 'hmn' },
  { label: 'Hungarian', value: 'hu' },
  { label: 'Icelandic', value: 'is' },
  { label: 'Igbo', value: 'ig' },
  { label: 'Indonesian', value: 'id' },
  { label: 'Irish', value: 'ga' },
  { label: 'Italian', value: 'it' },
  { label: 'Japanese', value: 'ja' },
  { label: 'Javanese', value: 'jw' },
  { label: 'Kannada', value: 'kn' },
  { label: 'Kazakh', value: 'kk' },
  { label: 'Khmer', value: 'km' },
  { label: 'Kinyarwanda', value: 'rw' },
  { label: 'Korean', value: 'ko' },
  { label: 'Kurdish (Kurmanji)', value: 'ku' },
  { label: 'Kyrgyz', value: 'ky' },
  { label: 'Lao', value: 'lo' },
  { label: 'Latin', value: 'la' },
  { label: 'Latvian', value: 'lv' },
  { label: 'Lithuanian', value: 'lt' },
  { label: 'Luxembourgish', value: 'lb' },
  { label: 'Macedonian', value: 'mk' },
  { label: 'Malagasy', value: 'mg' },
  { label: 'Malay', value: 'ms' },
  { label: 'Malayalam', value: 'ml' },
  { label: 'Maltese', value: 'mt' },
  { label: 'Maori', value: 'mi' },
  { label: 'Marathi', value: 'mr' },
  { label: 'Mongolian', value: 'mn' },
  { label: 'Myanmar (Burmese)', value: 'my' },
  { label: 'Nepali', value: 'ne' },
  { label: 'Norwegian', value: 'no' },
  { label: 'Odia (Oriya)', value: 'or' },
  { label: 'Pashto', value: 'ps' },
  { label: 'Persian', value: 'fa' },
  { label: 'Polish', value: 'pl' },
  { label: 'Portuguese', value: 'pt' },
  { label: 'Punjabi', value: 'pa' },
  { label: 'Romanian', value: 'ro' },
  // { label: 'Russian', value: 'ru' },
  { label: 'Samoan', value: 'sm' },
  { label: 'Scots Gaelic', value: 'gd' },
  { label: 'Serbian', value: 'sr' },
  { label: 'Sesotho', value: 'st' },
  { label: 'Shona', value: 'sn' },
  { label: 'Sindhi', value: 'sd' },
  { label: 'Sinhala', value: 'si' },
  { label: 'Slovak', value: 'sk' },
  { label: 'Slovenian', value: 'sl' },
  { label: 'Somali', value: 'so' },
  { label: 'Spanish', value: 'es' },
  { label: 'Sundanese', value: 'su' },
  { label: 'Swahili', value: 'sw' },
  { label: 'Swedish', value: 'sv' },
  { label: 'Tajik', value: 'tg' },
  { label: 'Tamil', value: 'ta' },
  { label: 'Tatar', value: 'tt' },
  { label: 'Telugu', value: 'te' },
  { label: 'Thai', value: 'th' },
  { label: 'Turkish', value: 'tr' },
  { label: 'Turkmen', value: 'tk' },
  { label: 'Ukrainian', value: 'uk' },
  { label: 'Urdu', value: 'ur' },
  { label: 'Uyghur', value: 'ug' },
  { label: 'Uzbek', value: 'uz' },
  { label: 'Vietnamese', value: 'vi' },
  { label: 'Welsh', value: 'cy' },
  { label: 'Xhosa', value: 'xh' },
  { label: 'Yiddish', value: 'yi' },
  { label: 'Yoruba', value: 'yo' },
  { label: 'Zulu', value: 'zu' },
];

export const videoLinksSocial = [
  { label: 'youtube', value: 'youtube' },
  { label: 'vimeo', value: 'vimeo' },
  { label: 'rutube', value: 'rutube' },
  { label: 'facebook', value: 'facebook' },
  { label: 'instagram', value: 'instagram' },
];

export const socialOptions: { label: string; value: string }[] = [
  { label: 'facebook', value: '_facebook' },
  { label: 'youtube', value: '_youtube' },
  { label: 'snapchat', value: '_snapchat' },
  { label: 'twitter', value: '_twitter' },
  { label: 'vkontakte', value: '_vkontakte' },
  { label: 'telegram', value: '_telegram' },
  { label: 'skype', value: '_skype' },
  { label: 'linkedin', value: '_linkedin' },
  { label: 'angellist', value: '_angellist' },
  { label: 'instagram', value: '_instagram' },
  { label: 'bitbucket', value: '_bitbucket' },
  { label: 'twitch', value: '_twitch' },
  { label: 'reddit', value: '_reddit' },
  { label: 'flipboard', value: '_flipboard' },
  { label: 'steam', value: '_steam' },
  { label: 'ebay', value: '_ebay' },
  { label: 'vimeo', value: '_vimeo' },
  { label: 'soundcloud', value: '_soundcloud' },
  { label: 'mixcloud', value: '_mixcloud' },
  { label: 'bandcamp', value: '_bandcamp' },
  { label: 'flickr', value: '_flickr' },
  { label: 'fotolog', value: '_fotolog' },
  { label: 'canva', value: '_canva' },
  { label: 'disqus', value: '_disqus' },
  { label: 'houzz', value: '_houzz' },
  { label: 'wordpress', value: '_wordpress' },
  { label: 'fiverr', value: '_fiverr' },
  { label: 'pinterest', value: '_pinterest' },
  { label: 'badoo', value: '_badoo' },
  { label: 'medium', value: '_medium' },
  { label: 'dailymotio', value: '_dailymotio' },
  { label: 'vine', value: '_vine' },
  { label: 'about.me', value: '_about.me' },
  { label: 'quora', value: '_quora' },
  { label: 'gravatar', value: '_gravatar' },
  { label: 'bitly', value: '_bitly' },
  { label: 'behance', value: '_behance' },
  { label: 'tripadvisor', value: '_tripadvisor' },
  { label: 'dribble', value: '_dribble' },
  { label: 'wattpad', value: '_wattpad' },
  { label: 'fanpop', value: '_fanpop' },
  { label: 'patreon', value: '_patreon' },
  { label: 'slideshare', value: '_slideshare' },
  { label: 'codeacademy', value: '_codeacademy' },
  { label: 'cybrary', value: '_cybrary' },
  { label: 'keybase', value: '_keybase' },
  { label: 'paypal', value: '_paypal' },
  { label: 'instructables', value: '_instructables' },
  { label: 'deviantart', value: '_deviantart' },
  { label: 'foursquare', value: '_foursquare' },
  { label: 'hubpages', value: '_hubpages' },
  { label: 'kongregate', value: '_kongregate' },
  { label: 'imgur', value: '_imgur' },
  { label: 'wikipedia', value: '_wikipedia' },
  { label: 'gamespot', value: '_gamespot' },
  { label: 'roblox', value: '_roblox' },
  { label: 'photobucket', value: '_photobucket' },
  { label: 'polyvore', value: '_polyvore' },
  { label: 'tiktok', value: '_tiktok' },
  { label: 'linktree', value: '_linktree' },
  { label: 'linkfolio', value: '_linkfolio' },
  { label: 'spotify', value: '_spotify' },
  { label: 'tap.bio', value: '_tap.bio' },
  { label: 'linkin.bio', value: '_linkin.bio' },
  { label: 'campsite', value: '_campsite' },
  { label: 'swipop', value: '_swipop' },
  { label: 'ContactInBio', value: '_ContactInBio' },
  { label: 'bit.ly', value: '_bit.ly' },
  { label: 'shor.by', value: '_shor.by' },
  { label: 'discord', value: '_discord' },
];

export const dnsOptions: { label: string; value: string }[] = [
  { label: 'whois url', value: '_whois_url' },
  { label: 'whois domain', value: '_whois_domain' },
  { label: 'whois status', value: '_whois_status' },
  { label: 'whois exists', value: '_whois_exists' },
  { label: 'whois expires at', value: '_whois_expiresAt' },
  { label: 'whois last modified', value: '_whois_lastModified' },
  { label: 'whois registered at', value: '_whois_registeredAt' },
  { label: 'whois full text', value: '_whois_fullText' },
  { label: 'whois dns data', value: '_whois_dnsData' },
  { label: 'whois expiration days', value: '_whois_expirationDays' },
];

export const getPageContentOptions = [
  { label: 'custom selector', value: '_custom_selector' },
  { label: 'h1', value: '_h1' },
  { label: 'h2', value: '_h2' },
  { label: 'h3', value: '_h3' },
  { label: 'h4', value: '_h4' },
  { label: 'h5', value: '_h5' },
  { label: 'h6', value: '_h6' },
  { label: 'p', value: '_p' },
  { label: 'td', value: '_td' },
  { label: 'article', value: '_article' },
];

export const seoMetaTegsOptions: { label: string; value: string }[] = [
  { label: 'title tag', value: '_title_tag' },
  { label: 'meta description', value: '_meta_description' },
  { label: 'canonical tag', value: '_canonical_tag' },
  { label: 'robots meta tag', value: '_robots_meta_tag' },
  { label: 'og url', value: '_og_url' },
  { label: 'og type', value: '_og_type' },
  { label: 'og title', value: '_og_title' },
  { label: 'og description', value: '_og_description' },
  { label: 'og image', value: '_og_image' },
  { label: 'og site name', value: '_og_site_name' },
  { label: 'og image type', value: '_og_image_type' },
  { label: 'og image width', value: '_og_image_width' },
  { label: 'og image height', value: '_og_image_height' },
  { label: 'og locale', value: '_og_locale' },
  { label: 'twitter title', value: '_twitter_title' },
  { label: 'twitter description', value: '_twitter_description' },
  { label: 'twitter image', value: '_twitter_image' },
  { label: 'twitter site', value: '_twitter_site' },
  { label: 'twitter creator', value: '_twitter_creator' },
  { label: 'twitter card', value: '_twitter_card' },
  { label: 'twitter image alt', value: '_twitter_image_alt' },
  { label: 'twitter player', value: '_twitter_player' },
  { label: 'keywords', value: '_keywords' },
];

export const measurementOption: { label: string; value: string }[] = [
  { label: 'WEIGHT', value: 'MEASUREMENT_WEIGHT' },
  { label: 'LENGTH', value: 'MEASUREMENT_LENGTH' },
  { label: 'TEMPERATURE', value: 'MEASUREMENT_TEMPERATURE' },
  { label: 'TIME', value: 'MEASUREMENT_TIME' },
  { label: 'VOLUME', value: 'MEASUREMENT_VOLUME' },
  { label: 'AREA', value: 'MEASUREMENT_AREA' },
];

export const units = {
  MEASUREMENT_WEIGHT: [
    { label: 'KG', value: 'KG' },
    { label: 'G', value: 'G' },
    { label: 'MG', value: 'MG' },
  ],

  MEASUREMENT_LENGTH: [
    { label: 'CM', value: 'CM' },
    { label: 'M', value: 'M' },
    { label: 'KM', value: 'KM' },
    { label: 'MM', value: 'MM' },
    { label: 'FT', value: 'FT' },
    { label: 'MI', value: 'MI' },
    { label: 'IN', value: 'IN' },
  ],

  MEASUREMENT_TEMPERATURE: [
    { label: 'C', value: 'C' },
    { label: 'F', value: 'F' },
    { label: 'K', value: 'K' },
  ],

  MEASUREMENT_TIME: [
    { label: 'NS', value: 'NS' },
    { label: 'MS', value: 'MS' },
    { label: 'S', value: 's' },
    { label: 'MIN', value: 'MIN' },
    { label: 'H', value: 'H' },
    { label: 'D', value: 'D' },
    { label: 'WEEK', value: 'WEEK' },
    { label: 'MONTH', value: 'MONTH' },
    { label: 'YEAR', value: 'YEAR' },
  ],

  MEASUREMENT_VOLUME: [
    { label: 'L', value: 'L' },
    { label: 'ML', value: 'ML' },
  ],

  MEASUREMENT_AREA: [
    {
      label: (
        <span>
          MM
          <sup>
            <small>2</small>
          </sup>
        </span>
      ),
      value: 'MM2',
    },
    {
      label: (
        <span>
          CM
          <sup>
            <small>2</small>
          </sup>
        </span>
      ),
      value: 'CM2',
    },
    {
      label: (
        <span>
          M
          <sup>
            <small>2</small>
          </sup>
        </span>
      ),
      value: 'M2',
    },
  ],
};

export const textOptions: { label: string; value: string }[] = [
  { label: 'UPPER CASE', value: 'UPPER_CASE' },
  { label: 'LOWER CASE', value: 'LOWER_CASE' },
  { label: 'CAPITALIZE EACH WORD', value: 'CAPITALIZE_EACH_WORD' },
  { label: 'CAPITALIZE ALL EXCEPT FIRST LETTER', value: 'CAPITALIZE_ALL_EXCEPT_FIRST_LETTER' },
];

export const dateOptions: { label: string; value: string }[] = [
  { label: 'DD MM YYYY', value: 'DD_MM_YYYY' },
  { label: 'DD MM YYYY HH MM SS', value: 'DD_MM_YYYY_HH_MM_SS' },
  { label: 'DD MM YYYY HH MM', value: 'DD_MM_YYYY_HH_MM' },
  { label: 'MM DD YYYY', value: 'MM_DD_YYYY' },
  { label: 'MM DD YYYY HH MM SS', value: 'MM_DD_YYYY_HH_MM_SS' },
  { label: 'MM DD YYYY HH MM', value: 'MM_DD_YYYY_HH_MM' },
  { label: 'YYYY MM DD', value: 'YYYY_MM_DD' },
  { label: 'YYYY MM DD HH MM SS', value: 'YYYY_MM_DD_HH_MM_SS' },
  { label: 'YYYY MM DD HH MM', value: 'YYYY_MM_DD_HH_MM' },
  { label: 'DDD MMM DD YYYY', value: 'DDD_MMM_DD_YYYY' },
  { label: 'HH MM SS', value: 'HH_MM_SS' },
  { label: 'HH MM', value: 'HH_MM' },
];

export const googleSearchRestrictedCountOptions: { label: number; value: number }[] = [
  { label: 100, value: 100 },
  { label: 200, value: 200 },
  { label: 300, value: 300 },
  { label: 400, value: 400 },
  { label: 500, value: 500 },
  { label: 600, value: 600 },
  { label: 700, value: 700 },
];

export const yelpSearchRestrictedCountOptions: { label: number; value: number }[] = Array.from(Array(25), (_, x) => ({
  label: x * 10,
  value: x * 10,
})).filter(item => item.label !== 0);

export const tripadvisorSearchRestrictedCountOptions: { label: number; value: number }[] = Array.from(
  Array(150),
  (_, x) => ({
    label: x * 30,
    value: x * 30,
  }),
).filter(item => item.label >= 30);

export const trustPilotRestrictedCountOptions: { label: number; value: number }[] = Array.from(Array(224), (_, x) => ({
  label: x * 20,
  value: x * 20,
})).map(item => item.label === 0 ? {label: 10, value: 10} : item);

export const deviceTypeOptions = [
  { label: 'desktop', value: 'desktop' },
  { label: 'mobile', value: 'mobile' },
];

export const desktopOsOptions = [
  { label: 'windows', value: 'windows' },
  { label: 'macOS', value: 'macos' },
];

export const mobileOsOptions = [
  { label: 'android', value: 'android' },
  { label: 'ios', value: 'ios' },
];

export const fileTypeOptions = [
  { label: 'jpeg', value: 'jpeg' },
  { label: 'png', value: 'png' },
  { label: 'gif', value: 'gif' },
  { label: 'pdf', value: 'pdf' },
];

export const findFolderOptions = [
  { label: '.AIFF', value: '.AIFF' },
  { label: '.AIF', value: '.AIF' },
  { label: '.AU', value: '.AU' },
  { label: '.AVI', value: '.AVI' },
  { label: '.BAT', value: '.BAT' },
  { label: '.BMP', value: '.BMP' },
  { label: '.CLASS', value: '.CLASS' },
  { label: '.JAVA', value: '.JAVA' },
  { label: '.CSV', value: '.CSV' },
  { label: '.CVS', value: '.CVS' },
  { label: '.DBF', value: '.DBF' },
  { label: '.DIF', value: '.DIF' },

  { label: '.DOC', value: '.DOC' },
  { label: '.DOCX', value: '.DOCX' },
  { label: '.EPS', value: '.EPS' },
  { label: '.EXE', value: '.EXE' },
  { label: '.FM3', value: '.FM3' },
  { label: '.GIF', value: '.GIF' },
  { label: '.HQX', value: '.HQX' },
  { label: '.HTM', value: '.HTM' },
  { label: '.HTML', value: '.HTML' },
  { label: '.JPG', value: '.JPG' },
  { label: '.JPEG', value: '.JPEG' },
  { label: '.SVG', value: '.SVG' },

  { label: '.MAC', value: '.MAC' },
  { label: '.MAP', value: '.MAP' },
  { label: '.MDB', value: '.MDB' },
  { label: '.MID', value: '.MID' },
  { label: '.MIDI', value: '.MIDI' },
  { label: '.MOV', value: '.MOV' },
  { label: '.QT', value: '.QT' },
  { label: '.MTB', value: '.MTB' },
  { label: '.MTW', value: '.MTW' },
  { label: '.PDF', value: '.PDF' },

  { label: '.P65', value: '.P65' },
  { label: '.T65', value: '.T65' },
  { label: '.PNG', value: '.PNG' },
  { label: '.PPT', value: '.PPT' },
  { label: '.PPTX', value: '.PPTX' },
  { label: '.PSD', value: '.PSD' },
  { label: '.PSP', value: '.PSP' },
  { label: '.QXD', value: '.QXD' },
  { label: '.RA', value: '.RA' },
  { label: '.RTF', value: '.RTF' },

  { label: '.SIT', value: '.SIT' },
  { label: '.TAR', value: '.TAR' },
  { label: '.TIF', value: '.TIF' },
  { label: '.TXT', value: '.TXT' },
  { label: '.WAV', value: '.WAV' },
  { label: '.WK3', value: '.WK3' },
  { label: '.WKS', value: '.WKS' },
  { label: '.WPD', value: '.WPD' },
  { label: '.WP5', value: '.WP5' },
  { label: '.XLS', value: '.XLS' },
  { label: '.XLSX', value: '.XLSX' },
  { label: '.ZIP', value: '.ZIP' },
  { label: '.webP', value: '.webP' },
];

export const extractLinksOptions = [
  { label: 'all file links', value: '_all_file_links' },
  { label: 'internal file links', value: '_internal_file_links' },
  { label: 'external file links', value: '_external_file_links' },
];

export const amazonSortbyOptions = [
  { label: 'relevance', value: 'relevance' },
  { label: 'price low to high', value: 'price_low_to_high' },
  { label: 'price high to low', value: 'price_high_to_low' },
  { label: 'featured', value: 'featured' },
  { label: 'avg customer review', value: 'avg_customer_review' },
  { label: 'newest arrival', value: 'newest_arrival' },
];

export const googleShoppingSortbyOptions = [
  { label: 'review score', value: 'review_score' },
  { label: 'price low to high', value: 'price_low_to_high' },
  { label: 'price high to low', value: 'price_high_to_low' },
];

export const yelpSortbyOptions = [
  { label: 'recommended', value: 'recommended' },
  { label: 'rating', value: 'rating' },
  { label: 'reviews count', value: 'reviews_count' },
];

export const amazonLanguageCodeOptions = [
  {
    label: 'Arabic (Saudi Arabia)',
    value: 'ar_SA',
  },
  {
    label: 'Arabic (U.A.E.)',
    value: 'ar_AE',
  },
  {
    label: 'Chinese (Simplified)',
    value: 'zh_CN',
  },
  {
    label: 'Chinese (Traditional)',
    value: 'zh_TW',
  },
  {
    label: 'Czech (Czech Republic)',
    value: 'cs_CZ',
  },
  {
    label: 'Dutch (Netherlands)',
    value: 'nl_NL',
  },
  {
    label: 'English (Australia)',
    value: 'en_AU',
  },
  {
    label: 'English (Canada)',
    value: 'en_CA',
  },
  {
    label: 'English (India)',
    value: 'en_IN',
  },
  {
    label: 'English (United Kingdom)',
    value: 'en_GB',
  },
  {
    label: 'English (United States)',
    value: 'en_US',
  },
  {
    label: 'French (Canada)',
    value: 'fr_CA',
  },
  {
    label: 'French (France)',
    value: 'fr_FR',
  },
  {
    label: 'German (Germany)',
    value: 'de_DE',
  },
  {
    label: 'Hebrew (Israel)',
    value: 'he_IL',
  },
  {
    label: 'Hindi (India)',
    value: 'hi_IN',
  },
  {
    label: 'Italian (Italy)',
    value: 'it_IT',
  },
  {
    label: 'Japanese (Japan)',
    value: 'ja_JP',
  },
  {
    label: 'Korean (Korea)',
    value: 'ko_KR',
  },
  {
    label: 'Polish (Poland)',
    value: 'pl_PL',
  },
  {
    label: 'Portuguese (Brazil)',
    value: 'pt_BR',
  },
  {
    label: 'Spanish (Mexico)',
    value: 'es_MX',
  },
  {
    label: 'Spanish (Spain)',
    value: 'es_ES',
  },
  {
    label: 'Spanish (United States)',
    value: 'es_US',
  },
  {
    label: 'Swedish (Sweden)',
    value: 'sv_SE',
  },
  {
    label: 'Turkish (Turkey)',
    value: 'tr_TR',
  },
];

export const yelpLanguageNameOptions = [
  { label: 'Chinese', value: 'Chinese' },
  { label: 'Danish', value: 'Danish' },
  { label: 'Dutch', value: 'Dutch' },
  { label: 'English', value: 'English' },
  { label: 'Filipino', value: 'Filipino' },
  { label: 'Finnish', value: 'Finnish' },
  { label: 'French', value: 'French' },
  { label: 'German', value: 'German' },
  { label: 'Italian', value: 'Italian' },
  { label: 'Japanese', value: 'Japanese' },
  { label: 'Malay', value: 'Malay' },
  { label: 'Norwegian (Bokmål)', value: 'Norwegian (Bokmål)' },
  { label: 'Polish', value: 'Polish' },
  { label: 'Portuguese', value: 'Portuguese' },
  { label: 'Spanish', value: 'Spanish' },
  { label: 'Swedish', value: 'Swedish' },
  { label: 'Turkish', value: 'Turkish' },
];

export const googleShoppingLanguageNameOptions = [
  { label: 'Afrikaans', value: 'af' },
  { label: 'Akan', value: 'ak' },
  { label: 'Albanian', value: 'sq' },
  { label: 'Amharic', value: 'am' },
  { label: 'Arabic', value: 'ar' },
  { label: 'Armenian', value: 'hy' },
  { label: 'Azeri', value: 'az' },
  { label: 'Balinese', value: 'ban' },
  { label: 'Basque', value: 'eu' },
  // { label: 'Belarusian', value: 'be' },
  { label: 'Bengali', value: 'bn' },
  { label: 'Bosnian', value: 'bs' },
  { label: 'Bulgarian', value: 'bg' },
  { label: 'Burmese', value: 'my' },
  { label: 'Catalan', value: 'ca' },
  { label: 'Cebuano', value: 'ceb' },
  { label: 'Chichewa', value: 'ny' },
  { label: 'Chinese (Simplified)', value: 'zh-CN' },
  { label: 'Chinese (Traditional)', value: 'zh-TW' },
  { label: 'Croatian', value: 'hr' },
  { label: 'Czech', value: 'cs' },
  { label: 'Danish', value: 'da' },
  { label: 'Dutch', value: 'nl' },
  { label: 'English', value: 'en' },
  { label: 'Espanol (Latinoamerica)', value: 'es-419' },
  { label: 'Estonian', value: 'et' },
  { label: 'Ewe', value: 'ee' },
  { label: 'Faroese', value: 'fo' },
  { label: 'Farsi', value: 'fa' },
  { label: 'Filipino', value: 'fil' },
  { label: 'Finnish', value: 'fi' },
  { label: 'French', value: 'fr' },
  { label: 'Frisian', value: 'fy' },
  { label: 'Ga', value: 'gaa' },
  { label: 'Galician', value: 'gl' },
  { label: 'Ganda', value: 'lg' },
  { label: 'Georgian', value: 'ka' },
  { label: 'German', value: 'de' },
  { label: 'Greek', value: 'el' },
  { label: 'Gujarati', value: 'gu' },
  { label: 'Haitian', value: 'ht' },
  { label: 'Hausa', value: 'ha' },
  { label: 'Hebrew', value: 'he' },
  { label: 'Hebrew (old)', value: 'iw' },
  { label: 'Hindi', value: 'hi' },
  { label: 'Hungarian', value: 'hu' },
  { label: 'Icelandic', value: 'is' },
  { label: 'IciBemba', value: 'bem' },
  { label: 'Igbo', value: 'ig' },
  { label: 'Indonesian', value: 'id' },
  { label: 'Irish', value: 'ga' },
  { label: 'Italian', value: 'it' },
  { label: 'Japanese', value: 'ja' },
  { label: 'Kannada', value: 'kn' },
  { label: 'Kazakh', value: 'kk' },
  { label: 'Khmer', value: 'km' },
  { label: 'Kinyarwanda', value: 'rw' },
  { label: 'Kirundi', value: 'rn' },
  { label: 'Kongo', value: 'kg' },
  { label: 'Korean', value: 'ko' },
  { label: 'Kreol morisien', value: 'mfe' },
  { label: 'Kreol Seselwa', value: 'crs' },
  { label: 'Krio', value: 'kri' },
  { label: 'Kurdish', value: 'ckb' },
  { label: 'Kyrgyz', value: 'ky' },
  { label: 'Lao', value: 'lo' },
  { label: 'Latvian', value: 'lv' },
  { label: 'Lingala', value: 'ln' },
  { label: 'Lithuanian', value: 'lt' },
  { label: 'Luo', value: 'ach' },
  { label: 'Macedonian', value: 'mk' },
  { label: 'Malagasy', value: 'mg' },
  { label: 'Malay', value: 'ms' },
  { label: 'Malayam', value: 'ml' },
  { label: 'Maltese', value: 'mt' },
  { label: 'Maori', value: 'mi' },
  { label: 'Marathi', value: 'mr' },
  { label: 'Mongolian', value: 'mn' },
  { label: 'Montenegro', value: 'sr-ME' },
  { label: 'Nepali', value: 'ne' },
  { label: 'Northern Sotho', value: 'nso' },
  { label: 'Norwegian', value: 'no' },
  { label: 'Nyankole', value: 'nyn' },
  { label: 'Oromo', value: 'om' },
  { label: 'Pashto', value: 'ps' },
  { label: 'Pidgin', value: 'pcm' },
  { label: 'Polish', value: 'pl' },
  { label: 'Portuguese', value: 'pt' },
  { label: 'Portuguese (Brazil)', value: 'pt-BR' },
  { label: 'Portuguese (Portugal)', value: 'pt-PT' },
  { label: 'Punjabi', value: 'pa' },
  { label: 'Quechua', value: 'qu' },
  { label: 'Romanian', value: 'ro' },
  { label: 'Romansh', value: 'rm' },
  // { label: 'Russian', value: 'ru' },
  { label: 'Serbian', value: 'sr' },
  { label: 'Serbian (Latin)', value: 'sr-Latn' },
  { label: 'Sesotho', value: 'st' },
  { label: 'Shona', value: 'sn' },
  { label: 'Silozi', value: 'loz' },
  { label: 'Sindhi', value: 'sd' },
  { label: 'Sinhalese', value: 'si' },
  { label: 'Slovak', value: 'sk' },
  { label: 'Slovenian', value: 'sl' },
  { label: 'Somali', value: 'so' },
  { label: 'Spanish', value: 'es' },
  { label: 'Swahili', value: 'sw' },
  { label: 'Swedish', value: 'sv' },
  { label: 'Tajik', value: 'tg' },
  { label: 'Tamil', value: 'ta' },
  { label: 'Telugu', value: 'te' },
  { label: 'Thai', value: 'th' },
  { label: 'Tigrinya', value: 'ti' },
  { label: 'Tonga (Tonga Islands)', value: 'to' },
  { label: 'Tshiluba', value: 'lua' },
  { label: 'Tswana', value: 'tn' },
  { label: 'Tumbuka', value: 'tum' },
  { label: 'Turkish', value: 'tr' },
  { label: 'Turkmen', value: 'tk' },
  { label: 'Ukrainian', value: 'uk' },
  { label: 'Urdu', value: 'ur' },
  { label: 'Uzbek', value: 'uz' },
  { label: 'Vietnamese', value: 'vi' },
  { label: 'Wolof', value: 'wo' },
  { label: 'Xhosa', value: 'xh' },
  { label: 'Yoruba', value: 'yo' },
  { label: 'Zulu', value: 'zu' },
];

export const googleShopingCountriesOptions = [
  { label: 'Argentina', value: 2032 },
  { label: 'Australia', value: 2036 },
  { label: 'Austria', value: 2040 },
  { label: 'Belgium', value: 2056 },
  { label: 'Brazil', value: 2076 },
  { label: 'Canada', value: 2124 },
  { label: 'Chile', value: 2152 },
  { label: 'Taiwan', value: 2158 },
  { label: 'Colombia', value: 2170 },
  { label: 'Czechia', value: 2203 },
  { label: 'Denmark', value: 2208 },
  { label: 'France', value: 2250 },
  { label: 'Germany', value: 2276 },
  { label: 'Greece', value: 2300 },
  { label: 'Hong Kong', value: 2344 },
  { label: 'Hungary', value: 2348 },
  { label: 'India', value: 2356 },
  { label: 'Indonesia', value: 2360 },
  { label: 'Ireland', value: 2372 },
  { label: 'Israel', value: 2376 },
  { label: 'Italy', value: 2380 },
  { label: 'Japan', value: 2392 },
  { label: 'Liberia', value: 2430 },
  { label: 'Malaysia', value: 2458 },
  { label: 'Martinique', value: 2474 },
  { label: 'Mexico', value: 2484 },
  { label: 'Netherlands', value: 2528 },
  { label: 'New Zealand', value: 2554 },
  { label: 'Norway', value: 2578 },
  { label: 'Philippines', value: 2608 },
  { label: 'Poland', value: 2616 },
  { label: 'Portugal', value: 2620 },
  { label: 'Romania', value: 2642 },
  // { label: 'Russia', value: 2643 },
  { label: 'Saudi Arabia', value: 2682 },
  { label: 'Singapore', value: 2702 },
  { label: 'Vietnam', value: 2704 },
  { label: 'South Africa', value: 2710 },
  { label: 'Spain', value: 2724 },
  { label: 'Sweden', value: 2752 },
  { label: 'Switzerland', value: 2756 },
  { label: 'Thailand', value: 2764 },
  { label: 'United Arab Emirates', value: 2784 },
  { label: 'Turkey', value: 2792 },
  { label: 'Ukraine', value: 2804 },
  { label: 'United Kingdom', value: 2826 },
  { label: 'United States', value: 2840 },
];

export const amazonSearchAdressesOptions: { label: string; value: number }[] = [
  { label: 'Australia', value: 2036 },
  { label: 'Brazil', value: 2076 },
  { label: 'Canada', value: 2124 },
  { label: 'China', value: 2156 },
  { label: 'France', value: 2250 },
  { label: 'Germany', value: 2276 },
  { label: 'India', value: 2356 },
  { label: 'Italy', value: 2380 },
  { label: 'Japan', value: 2392 },
  { label: 'Mexico', value: 2484 },
  { label: 'Netherlands', value: 2528 },
  { label: 'Poland', value: 2616 },
  { label: 'Saudi Arabia', value: 2682 },
  { label: 'Singapore', value: 2702 },
  { label: 'Spain', value: 2724 },
  { label: 'Sweden', value: 2752 },
  { label: 'United Arab Emirates', value: 2784 },
  { label: 'Turkey', value: 2792 },
  { label: 'United Kingdom', value: 2826 },
  { label: 'United States', value: 2840 },
];

export const sentimentLanguageOption: ReadonlyArray<{ label: string; value: string }> = [
  { label: 'Arabic', value: 'ar' },
  { label: 'Chinese (Simplified)', value: 'zh' },
  { label: 'Chinese (Traditional)', value: 'zh-Hant' },
  { label: 'Dutch', value: 'nl' },
  { label: 'English', value: 'en' },
  { label: 'French', value: 'fr' },
  { label: 'German', value: 'de' },
  { label: 'Indonesian', value: 'id' },
  { label: 'Italian', value: 'it' },
  { label: 'Japanese', value: 'ja' },
  { label: 'Korean', value: 'ko' },
  { label: 'Portuguese (Brazilian & Continental)', value: 'pt' },
  { label: 'Spanish', value: 'es' },
  { label: 'Thai', value: 'th' },
  { label: 'Turkish', value: 'tr' },
  { label: 'Vietnamese', value: 'vi' },
];

export const countryOptions: { label: string; value: number; country_coe: string }[] = [
  { label: 'Afghanistan', value: 2004, country_coe: 'AF' },
  { label: 'Albania', value: 2008, country_coe: 'AL' },
  { label: 'Algeria', value: 2012, country_coe: 'DZ' },
  { label: 'American Samoa', value: 2016, country_coe: 'AS' },
  { label: 'Andorra', value: 2020, country_coe: 'AD' },
  { label: 'Angola', value: 2024, country_coe: 'AO' },
  { label: 'Antarctica', value: 2010, country_coe: 'AQ' },
  { label: 'Antigua and Barbuda', value: 2028, country_coe: 'AG' },
  { label: 'Argentina', value: 2032, country_coe: 'AR' },
  { label: 'Armenia', value: 2051, country_coe: 'AM' },
  { label: 'Australia', value: 2036, country_coe: 'AU' },
  { label: 'Austria', value: 2040, country_coe: 'AT' },
  { label: 'Azerbaijan', value: 2031, country_coe: 'AZ' },
  { label: 'Bahrain', value: 2048, country_coe: 'BH' },
  { label: 'Bangladesh', value: 2050, country_coe: 'BD' },
  { label: 'Barbados', value: 2052, country_coe: 'BB' },
  // { label: 'Belarus', value: 2112, country_coe: 'BY' },
  { label: 'Belgium', value: 2056, country_coe: 'BE' },
  { label: 'Belize', value: 2084, country_coe: 'BZ' },
  { label: 'Benin', value: 2204, country_coe: 'BJ' },
  { label: 'Bhutan', value: 2064, country_coe: 'BT' },
  { label: 'Bolivia', value: 2068, country_coe: 'BO' },
  { label: 'Bosnia and Herzegovina', value: 2070, country_coe: 'BA' },
  { label: 'Botswana', value: 2072, country_coe: 'BW' },
  { label: 'Brazil', value: 2076, country_coe: 'BR' },
  { label: 'Brunei', value: 2096, country_coe: 'BN' },
  { label: 'Bulgaria', value: 2100, country_coe: 'BG' },
  { label: 'Burkina Faso', value: 2854, country_coe: 'BF' },
  { label: 'Burundi', value: 2108, country_coe: 'BI' },
  { label: 'Cambodia', value: 2116, country_coe: 'KH' },
  { label: 'Cameroon', value: 2120, country_coe: 'CM' },
  { label: 'Canada', value: 2124, country_coe: 'CA' },
  { label: 'Cape Verde', value: 2132, country_coe: 'CV' },
  { label: 'Caribbean Netherlands', value: 2535, country_coe: 'BQ' },
  { label: 'Central African Republic', value: 2140, country_coe: 'CF' },
  { label: 'Chad', value: 2148, country_coe: 'TD' },
  { label: 'Chile', value: 2152, country_coe: 'CL' },
  { label: 'China', value: 2156, country_coe: 'CN' },
  { label: 'Christmas Island', value: 2162, country_coe: 'CX' },
  { label: 'Cocos (Keeling) Islands', value: 2166, country_coe: 'CC' },
  { label: 'Colombia', value: 2170, country_coe: 'CO' },
  { label: 'Comoros', value: 2174, country_coe: 'KM' },
  { label: 'Cook Islands', value: 2184, country_coe: 'CK' },
  { label: 'Costa Rica', value: 2188, country_coe: 'CR' },
  { label: "Cote d'Ivoire", value: 2384, country_coe: 'CI' },
  { label: 'Croatia', value: 2191, country_coe: 'HR' },
  { label: 'Curacao', value: 2531, country_coe: 'CW' },
  { label: 'Cyprus', value: 2196, country_coe: 'CY' },
  { label: 'Czechia', value: 2203, country_coe: 'CZ' },
  { label: 'Democratic Republic of the Congo', value: 2180, country_coe: 'CD' },
  { label: 'Denmark', value: 2208, country_coe: 'DK' },
  { label: 'Djibouti', value: 2262, country_coe: 'DJ' },
  { label: 'Dominica', value: 2212, country_coe: 'DM' },
  { label: 'Dominican Republic', value: 2214, country_coe: 'DO' },
  { label: 'Ecuador', value: 2218, country_coe: 'EC' },
  { label: 'Egypt', value: 2818, country_coe: 'EG' },
  { label: 'El Salvador', value: 2222, country_coe: 'SV' },
  { label: 'Equatorial Guinea', value: 2226, country_coe: 'GQ' },
  { label: 'Eritrea', value: 2232, country_coe: 'ER' },
  { label: 'Estonia', value: 2233, country_coe: 'EE' },
  { label: 'Eswatini', value: 2748, country_coe: 'SZ' },
  { label: 'Ethiopia', value: 2231, country_coe: 'ET' },
  { label: 'Federated States of Micronesia', value: 2583, country_coe: 'FM' },
  { label: 'Fiji', value: 2242, country_coe: 'FJ' },
  { label: 'Finland', value: 2246, country_coe: 'FI' },
  { label: 'France', value: 2250, country_coe: 'FR' },
  { label: 'French Polynesia', value: 2258, country_coe: 'PF' },
  { label: 'French Southern and Antarctic Lands', value: 2260, country_coe: 'TF' },
  { label: 'Gabon', value: 2266, country_coe: 'GA' },
  { label: 'Georgia', value: 2268, country_coe: 'GE' },
  { label: 'Germany', value: 2276, country_coe: 'DE' },
  { label: 'Ghana', value: 2288, country_coe: 'GH' },
  { label: 'Greece', value: 2300, country_coe: 'GR' },
  { label: 'Grenada', value: 2308, country_coe: 'GD' },
  { label: 'Guam', value: 2316, country_coe: 'GU' },
  { label: 'Guatemala', value: 2320, country_coe: 'GT' },
  { label: 'Guernsey', value: 2831, country_coe: 'GG' },
  { label: 'Guinea', value: 2324, country_coe: 'GN' },
  { label: 'Guinea-Bissau', value: 2624, country_coe: 'GW' },
  { label: 'Guyana', value: 2328, country_coe: 'GY' },
  { label: 'Haiti', value: 2332, country_coe: 'HT' },
  { label: 'Heard Island and McDonald Islands', value: 2334, country_coe: 'HM' },
  { label: 'Honduras', value: 2340, country_coe: 'HN' },
  { label: 'Hungary', value: 2348, country_coe: 'HU' },
  { label: 'Iceland', value: 2352, country_coe: 'IS' },
  { label: 'India', value: 2356, country_coe: 'IN' },
  { label: 'Indonesia', value: 2360, country_coe: 'ID' },
  { label: 'Iraq', value: 2368, country_coe: 'IQ' },
  { label: 'Ireland', value: 2372, country_coe: 'IE' },
  { label: 'Israel', value: 2376, country_coe: 'IL' },
  { label: 'Italy', value: 2380, country_coe: 'IT' },
  { label: 'Jamaica', value: 2388, country_coe: 'JM' },
  { label: 'Japan', value: 2392, country_coe: 'JP' },
  { label: 'Jersey', value: 2832, country_coe: 'JE' },
  { label: 'Jordan', value: 2400, country_coe: 'JO' },
  { label: 'Kazakhstan', value: 2398, country_coe: 'KZ' },
  { label: 'Kenya', value: 2404, country_coe: 'KE' },
  { label: 'Kiribati', value: 2296, country_coe: 'KI' },
  { label: 'Kuwait', value: 2414, country_coe: 'KW' },
  { label: 'Kyrgyzstan', value: 2417, country_coe: 'KG' },
  { label: 'Laos', value: 2418, country_coe: 'LA' },
  { label: 'Latvia', value: 2428, country_coe: 'LV' },
  { label: 'Lebanon', value: 2422, country_coe: 'LB' },
  { label: 'Lesotho', value: 2426, country_coe: 'LS' },
  { label: 'Liberia', value: 2430, country_coe: 'LR' },
  { label: 'Libya', value: 2434, country_coe: 'LY' },
  { label: 'Liechtenstein', value: 2438, country_coe: 'LI' },
  { label: 'Lithuania', value: 2440, country_coe: 'LT' },
  { label: 'Luxembourg', value: 2442, country_coe: 'LU' },
  { label: 'Madagascar', value: 2450, country_coe: 'MG' },
  { label: 'Malawi', value: 2454, country_coe: 'MW' },
  { label: 'Malaysia', value: 2458, country_coe: 'MY' },
  { label: 'Maldives', value: 2462, country_coe: 'MV' },
  { label: 'Mali', value: 2466, country_coe: 'ML' },
  { label: 'Malta', value: 2470, country_coe: 'MT' },
  { label: 'Marshall Islands', value: 2584, country_coe: 'MH' },
  { label: 'Mauritania', value: 2478, country_coe: 'MR' },
  { label: 'Mauritius', value: 2480, country_coe: 'MU' },
  { label: 'Mexico', value: 2484, country_coe: 'MX' },
  { label: 'Moldova', value: 2498, country_coe: 'MD' },
  { label: 'Monaco', value: 2492, country_coe: 'MC' },
  { label: 'Mongolia', value: 2496, country_coe: 'MN' },
  { label: 'Montenegro', value: 2499, country_coe: 'ME' },
  { label: 'Morocco', value: 2504, country_coe: 'MA' },
  { label: 'Mozambique', value: 2508, country_coe: 'MZ' },
  { label: 'Myanmar (Burma)', value: 2104, country_coe: 'MM' },
  { label: 'Namibia', value: 2516, country_coe: 'NA' },
  { label: 'Nauru', value: 2520, country_coe: 'NR' },
  { label: 'Nepal', value: 2524, country_coe: 'NP' },
  { label: 'Netherlands', value: 2528, country_coe: 'NL' },
  { label: 'New Caledonia', value: 2540, country_coe: 'NC' },
  { label: 'New Zealand', value: 2554, country_coe: 'NZ' },
  { label: 'Nicaragua', value: 2558, country_coe: 'NI' },
  { label: 'Niger', value: 2562, country_coe: 'NE' },
  { label: 'Nigeria', value: 2566, country_coe: 'NG' },
  { label: 'Niue', value: 2570, country_coe: 'NU' },
  { label: 'Norfolk Island', value: 2574, country_coe: 'NF' },
  { label: 'North Macedonia', value: 2807, country_coe: 'MK' },
  { label: 'Northern Mariana Islands', value: 2580, country_coe: 'MP' },
  { label: 'Norway', value: 2578, country_coe: 'NO' },
  { label: 'Oman', value: 2512, country_coe: 'OM' },
  { label: 'Pakistan', value: 2586, country_coe: 'PK' },
  { label: 'Palau', value: 2585, country_coe: 'PW' },
  { label: 'Panama', value: 2591, country_coe: 'PA' },
  { label: 'Papua New Guinea', value: 2598, country_coe: 'PG' },
  { label: 'Paraguay', value: 2600, country_coe: 'PY' },
  { label: 'Peru', value: 2604, country_coe: 'PE' },
  { label: 'Philippines', value: 2608, country_coe: 'PH' },
  { label: 'Pitcairn Islands', value: 2612, country_coe: 'PN' },
  { label: 'Poland', value: 2616, country_coe: 'PL' },
  { label: 'Portugal', value: 2620, country_coe: 'PT' },
  { label: 'Qatar', value: 2634, country_coe: 'QA' },
  { label: 'Republic of the Congo', value: 2178, country_coe: 'CG' },
  { label: 'Romania', value: 2642, country_coe: 'RO' },
  // { label: 'Russia', value: 2643, country_coe: 'RU' },
  { label: 'Rwanda', value: 2646, country_coe: 'RW' },
  { label: 'Saint Helena, Ascension and Tristan da Cunha', value: 2654, country_coe: 'SH' },
  { label: 'Saint Kitts and Nevis', value: 2659, country_coe: 'KN' },
  { label: 'Saint Lucia', value: 2662, country_coe: 'LC' },
  { label: 'Saint Pierre and Miquelon', value: 2666, country_coe: 'PM' },
  { label: 'Saint Vincent and the Grenadines', value: 2670, country_coe: 'VC' },
  { label: 'Samoa', value: 2882, country_coe: 'WS' },
  { label: 'San Marino', value: 2674, country_coe: 'SM' },
  { label: 'Sao Tome and Principe', value: 2678, country_coe: 'ST' },
  { label: 'Saudi Arabia', value: 2682, country_coe: 'SA' },
  { label: 'Senegal', value: 2686, country_coe: 'SN' },
  { label: 'Serbia', value: 2688, country_coe: 'RS' },
  { label: 'Seychelles', value: 2690, country_coe: 'SC' },
  { label: 'Sierra Leone', value: 2694, country_coe: 'SL' },
  { label: 'Singapore', value: 2702, country_coe: 'SG' },
  { label: 'Sint Maarten', value: 2534, country_coe: 'SX' },
  { label: 'Slovakia', value: 2703, country_coe: 'SK' },
  { label: 'Slovenia', value: 2705, country_coe: 'SI' },
  { label: 'Solomon Islands', value: 2090, country_coe: 'SB' },
  { label: 'Somalia', value: 2706, country_coe: 'SO' },
  { label: 'South Africa', value: 2710, country_coe: 'ZA' },
  { label: 'South Georgia and the South Sandwich Islands', value: 2239, country_coe: 'GS' },
  { label: 'South Korea', value: 2410, country_coe: 'KR' },
  { label: 'Spain', value: 2724, country_coe: 'ES' },
  { label: 'Sri Lanka', value: 2144, country_coe: 'LK' },
  { label: 'Suriname', value: 2740, country_coe: 'SR' },
  { label: 'Sweden', value: 2752, country_coe: 'SE' },
  { label: 'Switzerland', value: 2756, country_coe: 'CH' },
  { label: 'Tajikistan', value: 2762, country_coe: 'TJ' },
  { label: 'Tanzania', value: 2834, country_coe: 'TZ' },
  { label: 'Thailand', value: 2764, country_coe: 'TH' },
  { label: 'The Bahamas', value: 2044, country_coe: 'BS' },
  { label: 'The Gambia', value: 2270, country_coe: 'GM' },
  { label: 'Timor-Leste', value: 2626, country_coe: 'TL' },
  { label: 'Togo', value: 2768, country_coe: 'TG' },
  { label: 'Tokelau', value: 2772, country_coe: 'TK' },
  { label: 'Tonga', value: 2776, country_coe: 'TO' },
  { label: 'Trinidad and Tobago', value: 2780, country_coe: 'TT' },
  { label: 'Tunisia', value: 2788, country_coe: 'TN' },
  { label: 'Turkey', value: 2792, country_coe: 'TR' },
  { label: 'Turkmenistan', value: 2795, country_coe: 'TM' },
  { label: 'Tuvalu', value: 2798, country_coe: 'TV' },
  { label: 'Uganda', value: 2800, country_coe: 'UG' },
  { label: 'Ukraine', value: 2804, country_coe: 'UA' },
  { label: 'United Arab Emirates', value: 2784, country_coe: 'AE' },
  { label: 'United Kingdom', value: 2826, country_coe: 'GB' },
  { label: 'United States', value: 2840, country_coe: 'US' },
  { label: 'United States Minor Outlying Islands', value: 2581, country_coe: 'UM' },
  { label: 'Uruguay', value: 2858, country_coe: 'UY' },
  { label: 'Uzbekistan', value: 2860, country_coe: 'UZ' },
  { label: 'Vanuatu', value: 2548, country_coe: 'VU' },
  { label: 'Vatican City', value: 2336, country_coe: 'VA' },
  { label: 'Venezuela', value: 2862, country_coe: 'VE' },
  { label: 'Vietnam', value: 2704, country_coe: 'VN' },
  { label: 'Wallis and Futuna', value: 2876, country_coe: 'WF' },
  { label: 'Yemen', value: 2887, country_coe: 'YE' },
  { label: 'Zambia', value: 2894, country_coe: 'ZM' },
  { label: 'Zimbabwe', value: 2716, country_coe: 'ZW' },
];

export const BingSearchcountryOptions: { label: string; value: number }[] = [
  { value: 2004, label: 'Afghanistan' },
  { value: 2008, label: 'Albania' },
  { value: 2012, label: 'Algeria' },
  { value: 2016, label: 'American Samoa' },
  { value: 2020, label: 'Andorra' },
  { value: 2024, label: 'Angola' },
  { value: 2028, label: 'Antigua and Barbuda' },
  { value: 2031, label: 'Azerbaijan' },
  { value: 2032, label: 'Argentina' },
  { value: 2036, label: 'Australia' },
  { value: 2040, label: 'Austria' },
  { value: 2044, label: 'The Bahamas' },
  { value: 2048, label: 'Bahrain' },
  { value: 2050, label: 'Bangladesh' },
  { value: 2051, label: 'Armenia' },
  { value: 2052, label: 'Barbados' },
  { value: 2056, label: 'Belgium' },
  { value: 2060, label: 'Bermuda' },
  { value: 2064, label: 'Bhutan' },
  { value: 2068, label: 'Bolivia' },
  { value: 2070, label: 'Bosnia and Herzegovina' },
  { value: 2072, label: 'Botswana' },
  { value: 2076, label: 'Brazil' },
  { value: 2084, label: 'Belize' },
  { value: 2090, label: 'Solomon Islands' },
  { value: 2092, label: 'British Virgin Islands' },
  { value: 2096, label: 'Brunei' },
  { value: 2100, label: 'Bulgaria' },
  { value: 2104, label: 'Myanmar (Burma)' },
  { value: 2108, label: 'Burundi' },
  // { value: 2112, label: 'Belarus' },
  { value: 2116, label: 'Cambodia' },
  { value: 2120, label: 'Cameroon' },
  { value: 2124, label: 'Canada' },
  { value: 2132, label: 'Cape Verde' },
  { value: 2136, label: 'Cayman Islands' },
  { value: 2140, label: 'Central African Republic' },
  { value: 2144, label: 'Sri Lanka' },
  { value: 2148, label: 'Chad' },
  { value: 2152, label: 'Chile' },
  { value: 2156, label: 'China' },
  { value: 2158, label: 'Taiwan' },
  { value: 2170, label: 'Colombia' },
  { value: 2178, label: 'Republic of the Congo' },
  { value: 2180, label: 'Democratic Republic of the Congo' },
  { value: 2184, label: 'Cook Islands' },
  { value: 2188, label: 'Costa Rica' },
  { value: 2191, label: 'Croatia' },
  { value: 2196, label: 'Cyprus' },
  { value: 2203, label: 'Czechia' },
  { value: 2204, label: 'Benin' },
  { value: 2208, label: 'Denmark' },
  { value: 2212, label: 'Dominica' },
  { value: 2214, label: 'Dominican Republic' },
  { value: 2218, label: 'Ecuador' },
  { value: 2222, label: 'El Salvador' },
  { value: 2231, label: 'Ethiopia' },
  { value: 2233, label: 'Estonia' },
  { value: 2242, label: 'Fiji' },
  { value: 2246, label: 'Finland' },
  { value: 2250, label: 'France' },
  { value: 2262, label: 'Djibouti' },
  { value: 2266, label: 'Gabon' },
  { value: 2268, label: 'Georgia' },
  { value: 2270, label: 'The Gambia' },
  { value: 2275, label: 'Palestine' },
  { value: 2276, label: 'Germany' },
  { value: 2288, label: 'Ghana' },
  { value: 2292, label: 'Gibraltar' },
  { value: 2296, label: 'Kiribati' },
  { value: 2300, label: 'Greece' },
  { value: 2304, label: 'Greenland' },
  { value: 2312, label: 'Guadeloupe' },
  { value: 2316, label: 'Guam' },
  { value: 2320, label: 'Guatemala' },
  { value: 2328, label: 'Guyana' },
  { value: 2332, label: 'Haiti' },
  { value: 2340, label: 'Honduras' },
  { value: 2344, label: 'Hong Kong' },
  { value: 2348, label: 'Hungary' },
  { value: 2352, label: 'Iceland' },
  { value: 2356, label: 'India' },
  { value: 2360, label: 'Indonesia' },
  { value: 2368, label: 'Iraq' },
  { value: 2372, label: 'Ireland' },
  { value: 2376, label: 'Israel' },
  { value: 2380, label: 'Italy' },
  { value: 2384, label: "Cote d'Ivoire" },
  { value: 2388, label: 'Jamaica' },
  { value: 2392, label: 'Japan' },
  { value: 2398, label: 'Kazakhstan' },
  { value: 2400, label: 'Jordan' },
  { value: 2404, label: 'Kenya' },
  { value: 2410, label: 'South Korea' },
  { value: 2414, label: 'Kuwait' },
  { value: 2417, label: 'Kyrgyzstan' },
  { value: 2418, label: 'Laos' },
  { value: 2422, label: 'Lebanon' },
  { value: 2426, label: 'Lesotho' },
  { value: 2428, label: 'Latvia' },
  { value: 2434, label: 'Libya' },
  { value: 2438, label: 'Liechtenstein' },
  { value: 2440, label: 'Lithuania' },
  { value: 2442, label: 'Luxembourg' },
  { value: 2446, label: 'Macao' },
  { value: 2450, label: 'Madagascar' },
  { value: 2454, label: 'Malawi' },
  { value: 2458, label: 'Malaysia' },
  { value: 2462, label: 'Maldives' },
  { value: 2466, label: 'Mali' },
  { value: 2470, label: 'Malta' },
  { value: 2480, label: 'Mauritius' },
  { value: 2484, label: 'Mexico' },
  { value: 2496, label: 'Mongolia' },
  { value: 2498, label: 'Moldova' },
  { value: 2499, label: 'Montenegro' },
  { value: 2500, label: 'Montserrat' },
  { value: 2504, label: 'Morocco' },
  { value: 2508, label: 'Mozambique' },
  { value: 2512, label: 'Oman' },
  { value: 2516, label: 'Namibia' },
  { value: 2520, label: 'Nauru' },
  { value: 2524, label: 'Nepal' },
  { value: 2528, label: 'Netherlands' },
  { value: 2548, label: 'Vanuatu' },
  { value: 2554, label: 'New Zealand' },
  { value: 2558, label: 'Nicaragua' },
  { value: 2562, label: 'Niger' },
  { value: 2566, label: 'Nigeria' },
  { value: 2570, label: 'Niue' },
  { value: 2574, label: 'Norfolk Island' },
  { value: 2578, label: 'Norway' },
  { value: 2583, label: 'Federated States of Micronesia' },
  { value: 2586, label: 'Pakistan' },
  { value: 2591, label: 'Panama' },
  { value: 2598, label: 'Papua New Guinea' },
  { value: 2600, label: 'Paraguay' },
  { value: 2604, label: 'Peru' },
  { value: 2608, label: 'Philippines' },
  { value: 2612, label: 'Pitcairn Islands' },
  { value: 2616, label: 'Poland' },
  { value: 2620, label: 'Portugal' },
  { value: 2626, label: 'Timor-Leste' },
  { value: 2630, label: 'Puerto Rico' },
  { value: 2634, label: 'Qatar' },
  { value: 2642, label: 'Romania' },
  // { value: 2643, label: 'Russia' },
  { value: 2646, label: 'Rwanda' },
  { value: 2654, label: 'Saint Helena, Ascension and Tristan da Cunha' },
  { value: 2660, label: 'Anguilla' },
  { value: 2670, label: 'Saint Vincent and the Grenadines' },
  { value: 2674, label: 'San Marino' },
  { value: 2678, label: 'Sao Tome and Principe' },
  { value: 2682, label: 'Saudi Arabia' },
  { value: 2686, label: 'Senegal' },
  { value: 2688, label: 'Serbia' },
  { value: 2690, label: 'Seychelles' },
  { value: 2694, label: 'Sierra Leone' },
  { value: 2702, label: 'Singapore' },
  { value: 2703, label: 'Slovakia' },
  { value: 2704, label: 'Vietnam' },
  { value: 2705, label: 'Slovenia' },
  { value: 2706, label: 'Somalia' },
  { value: 2710, label: 'South Africa' },
  { value: 2716, label: 'Zimbabwe' },
  { value: 2724, label: 'Spain' },
  { value: 2752, label: 'Sweden' },
  { value: 2756, label: 'Switzerland' },
  { value: 2762, label: 'Tajikistan' },
  { value: 2764, label: 'Thailand' },
  { value: 2768, label: 'Togo' },
  { value: 2772, label: 'Tokelau' },
  { value: 2776, label: 'Tonga' },
  { value: 2780, label: 'Trinidad and Tobago' },
  { value: 2784, label: 'United Arab Emirates' },
  { value: 2788, label: 'Tunisia' },
  { value: 2792, label: 'Turkey' },
  { value: 2795, label: 'Turkmenistan' },
  { value: 2800, label: 'Uganda' },
  { value: 2804, label: 'Ukraine' },
  { value: 2807, label: 'North Macedonia' },
  { value: 2818, label: 'Egypt' },
  { value: 2826, label: 'United Kingdom' },
  { value: 2831, label: 'Guernsey' },
  { value: 2832, label: 'Jersey' },
  { value: 2834, label: 'Tanzania' },
  { value: 2840, label: 'United States' },
  { value: 2850, label: 'U.S. Virgin Islands' },
  { value: 2854, label: 'Burkina Faso' },
  { value: 2858, label: 'Uruguay' },
  { value: 2860, label: 'Uzbekistan' },
  { value: 2862, label: 'Venezuela' },
  { value: 2882, label: 'Samoa' },
  { value: 2894, label: 'Zambia' },
];

export const traficConfigOptions: { label: string; value: string }[] = [
  { label: 'all traffic', value: '_all_traffic' },
  { label: 'global rank', value: '_global_rank' },
  { label: 'country rank', value: '_country_rank' },
  { label: 'country rank country', value: '_country_rank_country' },
  { label: 'category rank', value: '_category_rank' },
  { label: 'category rank category', value: '_category_rank_category' },
  { label: 'audience visits', value: '_audience_visits' },
  { label: 'audience time on site avg', value: '_audience_time_on_site_avg' },
  { label: 'audience page views avg', value: '_audience_page_views_avg' },
  { label: 'audience bounce rate', value: '_audience_bounce_rate' },
  { label: 'site description', value: '_site_description' },
  { label: 'search organic value', value: '_search_organic_value' },
  { label: 'search organic percent', value: '_search_organic_percent' },
  { label: 'search ad value', value: '_search_ad_value' },
  { label: 'search ad percent', value: '_search_ad_percent' },
  { label: 'direct value', value: '_direct_value' },
  { label: 'direct percent', value: '_direct_percent' },
  { label: 'referring value', value: '_referring_value' },
  { label: 'referring percent', value: '_referring_percent' },
  { label: 'top referring site 1', value: '_top_referring_site_1' },
  { label: 'top referring site 1 value', value: '_top_referring_site_1_value' },
  { label: 'top referring site 1 percent', value: '_top_referring_site_1_percent' },
  { label: 'top referring site 2', value: '_top_referring_site_2' },
  { label: 'top referring site 2 value', value: '_top_referring_site_2_value' },
  { label: 'top referring site 2 percent', value: '_top_referring_site_2_percent' },
  { label: 'top referring site 3', value: '_top_referring_site_3' },
  { label: 'top referring site 3 value', value: '_top_referring_site_3_value' },
  { label: 'top referring site 3 percent', value: '_top_referring_site_3_percent' },
  { label: 'top referring site 4', value: '_top_referring_site_4' },
  { label: 'top referring site 4 value', value: '_top_referring_site_4_value' },
  { label: 'top referring site 4 percent', value: '_top_referring_site_4_percent' },
  { label: 'top referring site 5', value: '_top_referring_site_5' },
  { label: 'top referring site 5 value', value: '_top_referring_site_5_value' },
  { label: 'top referring site 5 percent', value: '_top_referring_site_5_percent' },
  { label: 'referral destination top 1', value: '_referral_destination_top_1' },
  { label: 'referral destination top 2', value: '_referral_destination_top_2' },
  { label: 'referral destination top 3', value: '_referral_destination_top_3' },
  { label: 'referral destination top 4', value: '_referral_destination_top_4' },
  { label: 'traffic country 1', value: '_traffic_country_1' },
  { label: 'traffic country 1 value', value: '_traffic_country_1_value' },
  { label: 'traffic country 1 percent', value: '_traffic_country_1_percent' },
  { label: 'traffic country 2', value: '_traffic_country_2' },
  { label: 'traffic country 2 value', value: '_traffic_country_2_value' },
  { label: 'traffic country 2 percent', value: '_traffic_country_2_percent' },
  { label: 'traffic country 3', value: '_traffic_country_3' },
  { label: 'traffic country 3 value', value: '_traffic_country_3_value' },
  { label: 'traffic country 3 percent', value: '_traffic_country_3_percent' },
  { label: 'traffic country 4', value: '_traffic_country_4' },
  { label: 'traffic country 4 value', value: '_traffic_country_4_value' },
  { label: 'traffic country 4 percent', value: '_traffic_country_4_percent' },
  { label: 'traffic country 5', value: '_traffic_country_5' },
  { label: 'traffic country 5 value', value: '_traffic_country_5_value' },
  { label: 'traffic country 5 percent', value: '_traffic_country_5_percent' },
  { label: 'social value', value: '_social_value' },
  { label: 'social percent', value: '_social_percent' },
  { label: 'mail value', value: '_mail_value' },
  { label: 'mail percent', value: '_mail_percent' },
];

export const spellcheckToolLanguages: { label: string; value: string }[] = [
  // {
  //   label: 'Belarusian',
  //   value: 'be-BY',
  // },
  {
    label: 'Catalan',
    value: 'ca-ES',
  },
  {
    label: 'Catalan (Valencian)',
    value: 'ca-ES-valencia',
  },
  {
    label: 'Danish',
    value: 'da-DK',
  },
  {
    label: 'Dutch',
    value: 'nl',
  },
  {
    label: 'English (Australian)',
    value: 'en-AU',
  },
  {
    label: 'English (Canadian)',
    value: 'en-CA',
  },
  {
    label: 'English (GB)',
    value: 'en-GB',
  },
  {
    label: 'English (New Zealand)',
    value: 'en-NZ',
  },
  {
    label: 'English (South African)',
    value: 'en-ZA',
  },
  {
    label: 'English (US)',
    value: 'en-US',
  },
  {
    label: 'French',
    value: 'fr',
  },
  {
    label: 'Galician',
    value: 'gl-ES',
  },
  {
    label: 'German',
    value: 'de',
  },
  {
    label: 'German (Austria)',
    value: 'de-AT',
  },
  {
    label: 'German (Germany)',
    value: 'de-DE',
  },
  {
    label: 'German (Swiss)',
    value: 'de-CH',
  },
  {
    label: 'Greek',
    value: 'el-GR',
  },
  {
    label: 'Irish',
    value: 'ga-IE',
  },
  {
    label: 'Italian',
    value: 'it',
  },
  {
    label: 'Polish',
    value: 'pl-PL',
  },
  {
    label: 'Portuguese',
    value: 'pt',
  },
  {
    label: 'Portuguese (Brazil)',
    value: 'pt-BR',
  },
  {
    label: 'Portuguese (Portugal)',
    value: 'pt-PT',
  },
  {
    label: 'Romanian',
    value: 'ro-RO',
  },
  // {
  //   label: 'Russian',
  //   value: 'ru-RU',
  // },
  {
    label: 'Simple German',
    value: 'de-DE-x-simple-language',
  },
  {
    label: 'Slovak',
    value: 'sk-SK',
  },
  {
    label: 'Slovenian',
    value: 'sl-SI',
  },
  {
    label: 'Spanish',
    value: 'es',
  },
  {
    label: 'Swedish',
    value: 'sv',
  },
  {
    label: 'Ukrainian',
    value: 'uk-UA',
  },
];

export const spellcheckDatapoints = [
  {
    label: 'Screenshot with highlights',
    value: '_grammar_spelling_audit_screenshot_image',
    order_index: 1,
  },
  {
    label: 'Number of issues',
    value: '_grammar_spelling_audit_issues_found',
    order_index: 2,
  },
  {
    label: 'Errors found',
    value: '_grammar_spelling_audit_word',
    order_index: 3,
  },
  {
    label: 'Iterations',
    value: '_grammar_spelling_audit_iterations',
    order_index: 4,
  },
  {
    label: 'Error type',
    value: '_grammar_spelling_audit_error_type',
    order_index: 5,
  },
  {
    label: 'Suggested replacements',
    value: '_grammar_spelling_audit_suggested_replacement',
    order_index: 6,
  },
];

export const YahooCountriesOptions: { label: string; value: number }[] = [
  { label: 'Albania', value: 2008 },
  { label: 'Algeria', value: 2012 },
  { label: 'Argentina', value: 2032 },
  { label: 'Armenia', value: 2051 },
  { label: 'Australia', value: 2036 },
  { label: 'Austria', value: 2040 },
  { label: 'Azerbaijan', value: 2031 },
  { label: 'Bahrain', value: 2048 },
  { label: 'Belgium', value: 2056 },
  { label: 'Bolivia', value: 2068 },
  { label: 'Bosnia and Herzegovina', value: 2070 },
  { label: 'Brazil', value: 2076 },
  { label: 'Bulgaria', value: 2100 },
  { label: 'Canada', value: 2124 },
  { label: 'Chile', value: 2152 },
  { label: 'China', value: 2156 },
  { label: 'Colombia', value: 2170 },
  { label: 'Costa Rica', value: 2188 },
  { label: 'Croatia', value: 2191 },
  { label: 'Czechia', value: 2203 },
  { label: 'Democratic Republic of the Congo', value: 2180 },
  { label: 'Denmark', value: 2208 },
  { label: 'Ecuador', value: 2218 },
  { label: 'Egypt', value: 2818 },
  { label: 'El Salvador', value: 2222 },
  { label: 'Estonia', value: 2233 },
  { label: 'Finland', value: 2246 },
  { label: 'France', value: 2250 },
  { label: 'Georgia', value: 2268 },
  { label: 'Germany', value: 2276 },
  { label: 'Greece', value: 2300 },
  { label: 'Guatemala', value: 2320 },
  { label: 'Honduras', value: 2340 },
  { label: 'Hong Kong', value: 2344 },
  { label: 'Hungary', value: 2348 },
  { label: 'Iceland', value: 2352 },
  { label: 'India', value: 2356 },
  { label: 'Indonesia', value: 2360 },
  { label: 'Iraq', value: 2368 },
  { label: 'Ireland', value: 2372 },
  { label: 'Israel', value: 2376 },
  { label: 'Italy', value: 2380 },
  { label: 'Japan', value: 2392 },
  { label: 'Kenya', value: 2404 },
  { label: 'Kuwait', value: 2414 },
  { label: 'Latvia', value: 2428 },
  { label: 'Lebanon', value: 2422 },
  { label: 'Libya', value: 2434 },
  { label: 'Lithuania', value: 2440 },
  { label: 'Luxembourg', value: 2442 },
  { label: 'Malaysia', value: 2458 },
  { label: 'Malta', value: 2470 },
  { label: 'Mexico', value: 2484 },
  { label: 'Morocco', value: 2504 },
  { label: 'Netherlands', value: 2528 },
  { label: 'New Zealand', value: 2554 },
  { label: 'Nicaragua', value: 2558 },
  { label: 'North Macedonia', value: 2807 },
  { label: 'Norway', value: 2578 },
  { label: 'Oman', value: 2512 },
  { label: 'Pakistan', value: 2586 },
  { label: 'Panama', value: 2591 },
  { label: 'Paraguay', value: 2600 },
  { label: 'Peru', value: 2604 },
  { label: 'Philippines', value: 2608 },
  { label: 'Poland', value: 2616 },
  { label: 'Portugal', value: 2620 },
  { label: 'Puerto Rico', value: 2630 },
  { label: 'Qatar', value: 2634 },
  { label: 'Romania', value: 2642 },
  // { label: 'Russia', value: 2643 },
  { label: 'Saudi Arabia', value: 2682 },
  { label: 'Singapore', value: 2702 },
  { label: 'South Korea', value: 2410 },
  { label: 'Spain', value: 2724 },
  { label: 'Sweden', value: 2752 },
  { label: 'Switzerland', value: 2756 },
  { label: 'Taiwan', value: 2158 },
  { label: 'Thailand', value: 2764 },
  { label: 'Tunisia', value: 2788 },
  { label: 'Turkey', value: 2792 },
  { label: 'Ukraine', value: 2804 },
  { label: 'United Arab Emirates', value: 2784 },
  { label: 'United Kingdom', value: 2826 },
  { label: 'United States', value: 2840 },
  { label: 'Vietnam', value: 2704 },
];

export const baiduCountryOptions: { label: string; value: number }[] = [
  { label: 'Algeria', value: 9073622 },
  { label: 'Anguilla', value: 1000004 },
  { label: 'Antigua and Barbuda', value: 1000138 },
  { label: 'Argentina', value: 9047593 },
  { label: 'Armenia', value: 9070053 },
  { label: 'Australia', value: 9053250 },
  { label: 'Austria', value: 9047618 },
  { label: 'Azerbaijan', value: 1000998 },
  { label: 'Bahrain', value: 1001478 },
  { label: 'Bangladesh', value: 9074038 },
  { label: 'Barbados', value: 1001785 },
  // { label: 'Belarus', value: 9062750 },
  { label: 'Belgium', value: 9047676 },
  { label: 'Bosnia and Herzegovina', value: 9062733 },
  { label: 'Brazil', value: 9074303 },
  { label: 'Bulgaria', value: 9047694 },
  { label: 'Cambodia', value: 1009829 },
  { label: 'Canada', value: 9077369 },
  { label: 'Cape Verde', value: 9067614 },
  { label: 'Cayman Islands', value: 1003697 },
  { label: 'Chad', value: 1012726 },
  { label: 'Chile', value: 9048049 },
  { label: 'China', value: 1003592 },
  { label: 'Colombia', value: 9074849 },
  { label: 'Costa Rica', value: 9068561 },
  { label: 'Croatia', value: 9050389 },
  { label: 'Cyprus', value: 1003703 },
  { label: 'Czechia', value: 1003850 },
  { label: 'Democratic Republic of the Congo', value: 9069515 },
  { label: 'Denmark', value: 9067636 },
  { label: 'Dominican Republic', value: 9076624 },
  { label: 'Ecuador', value: 9076341 },
  { label: 'Egypt', value: 9073652 },
  { label: 'El Salvador', value: 1012110 },
  { label: 'Estonia', value: 9067645 },
  { label: 'Ethiopia', value: 1005570 },
  { label: 'Faroe Islands', value: 1006448 },
  { label: 'Finland', value: 9072483 },
  { label: 'France', value: 9068861 },
  { label: 'French Polynesia', value: 1011784 },
  { label: 'Gabon', value: 1006450 },
  { label: 'Georgia', value: 1007469 },
  { label: 'Germany', value: 9072451 },
  { label: 'Ghana', value: 9073674 },
  { label: 'Gibraltar', value: 9067655 },
  { label: 'Greece', value: 9067678 },
  { label: 'Grenada', value: 9067651 },
  { label: 'Guadeloupe', value: 1007479 },
  { label: 'Guam', value: 9067727 },
  { label: 'Guatemala', value: 1007583 },
  { label: 'Guinea', value: 9067657 },
  { label: 'Hungary', value: 9050457 },
  { label: 'Iceland', value: 1007962 },
  { label: 'India', value: 9300678 },
  { label: 'Indonesia', value: 9074383 },
  { label: 'Iraq', value: 1007949 },
  { label: 'Ireland', value: 9067729 },
  { label: 'Israel', value: 9056901 },
  { label: 'Italy', value: 9050717 },
  { label: 'Jamaica', value: 1009018 },
  { label: 'Japan', value: 9053422 },
  { label: 'Jersey', value: 1006454 },
  { label: 'Kazakhstan', value: 9063099 },
  { label: 'Kenya', value: 9073680 },
  { label: 'Kosovo', value: 1028594 },
  { label: 'Latvia', value: 9067750 },
  { label: 'Lesotho', value: 9067734 },
  { label: 'Libya', value: 1009908 },
  { label: 'Lithuania', value: 9062284 },
  { label: 'Luxembourg', value: 9067749 },
  { label: 'Madagascar', value: 1009994 },
  { label: 'Malaysia', value: 9073068 },
  { label: 'Maldives', value: 9075811 },
  { label: 'Malta', value: 9072704 },
  { label: 'Martinique', value: 1010223 },
  { label: 'Mauritania', value: 9067752 },
  { label: 'Mauritius', value: 9073705 },
  { label: 'Mexico', value: 9074021 },
  { label: 'Moldova', value: 9063100 },
  { label: 'Mongolia', value: 1010217 },
  { label: 'Montenegro', value: 1028591 },
  { label: 'Morocco', value: 9073697 },
  { label: 'Mozambique', value: 9073713 },
  { label: 'Myanmar (Burma)', value: 9065341 },
  { label: 'Namibia', value: 1010279 },
  { label: 'Nepal', value: 1011034 },
  { label: 'Netherlands', value: 9068871 },
  { label: 'New Caledonia', value: 1010281 },
  { label: 'New Zealand', value: 9067754 },
  { label: 'Nicaragua', value: 1010302 },
  { label: 'Nigeria', value: 9073718 },
  { label: 'Norway', value: 9067753 },
  { label: 'Pakistan', value: 9075178 },
  { label: 'Palau', value: 1011189 },
  { label: 'Panama', value: 1011101 },
  { label: 'Peru', value: 9076627 },
  { label: 'Philippines', value: 9060962 },
  { label: 'Poland', value: 9051330 },
  { label: 'Portugal', value: 9051350 },
  { label: 'Puerto Rico', value: 9067758 },
  { label: 'Qatar', value: 1011785 },
  { label: 'Reunion', value: 9073355 },
  { label: 'Romania', value: 9051370 },
  // { label: 'Russia', value: 9075128 },
  { label: 'Saint Kitts and Nevis', value: 1009830 },
  { label: 'Samoa', value: 1028583 },
  { label: 'Saudi Arabia', value: 9076460 },
  { label: 'Senegal', value: 9073720 },
  { label: 'Serbia', value: 9076634 },
  { label: 'Slovakia', value: 9062579 },
  { label: 'Slovenia', value: 9067824 },
  { label: 'South Africa', value: 9073767 },
  { label: 'South Korea', value: 1030794 },
  { label: 'Spain', value: 9049252 },
  { label: 'Sri Lanka', value: 9076077 },
  { label: 'Suriname', value: 1012114 },
  { label: 'Sweden', value: 9067823 },
  { label: 'Switzerland', value: 9048030 },
  { label: 'Taiwan', value: 9040380 },
  { label: 'Tanzania', value: 9073726 },
  { label: 'Thailand', value: 9074847 },
  { label: 'Tonga', value: 1012757 },
  { label: 'Tunisia', value: 9076596 },
  { label: 'Turkey', value: 9056900 },
  { label: 'Turkmenistan', value: 1012755 },
  { label: 'Turks and Caicos Islands', value: 9067847 },
  { label: 'U.S. Virgin Islands', value: 1028578 },
  { label: 'Uganda', value: 9073729 },
  { label: 'Ukraine', value: 1030582 },
  { label: 'United Arab Emirates', value: 9047580 },
  { label: 'United Kingdom', value: 9050376 },
  { label: 'United States', value: 9297833 },
  { label: 'Uruguay', value: 1012872 },
  { label: 'Uzbekistan', value: 1028523 },
  { label: 'Venezuela', value: 9076628 },
  { label: 'Vietnam', value: 9074119 },
  { label: 'Zambia', value: 1028769 },
  { label: 'Zimbabwe', value: 1028773 },
];

export const crawlerOptions: { label: string; value: string; order_index: number }[] = [
  { label: 'Internal Links', value: 'internal', order_index: 1 },
  { label: 'External Links', value: 'external', order_index: 2 },
  { label: 'Page URLs', value: 'page', order_index: 3 },
  { label: 'Source URLs', value: 'url', order_index: 4 },
];

export const seoBacklinkOptions: { label: string; value: string }[] = [
  // { label: 'backlink domain', value: '_backlink_domain' },
  { label: 'backlink domain from', value: '_backlink_domain_from' },
  { label: 'backlink url from', value: '_backlink_url_from' },
  { label: 'backlink url from https', value: '_backlink_url_from_https' },
  { label: 'backlink url to', value: '_backlink_url_to' },
  { label: 'backlink url to https', value: '_backlink_url_to_https' },
  { label: 'backlink tld from', value: '_backlink_tld_from' },
  { label: 'backlink is new', value: '_backlink_is_new' },
  { label: 'backlink is lost', value: '_backlink_is_lost' },
  { label: 'backlink page from rank', value: '_backlink_page_from_rank' },
  { label: 'backlink domain from rank', value: '_backlink_domain_from_rank' },
  { label: 'backlink domain from platform type', value: '_backlink_domain_from_platform_type' },
  { label: 'backlink domain from is ip', value: '_backlink_domain_from_is_ip' },
  { label: 'backlink domain from ip', value: '_backlink_domain_from_ip' },
  { label: 'backlink page from external links', value: '_backlink_page_from_external_links' },
  { label: 'backlink page from internal links', value: '_backlink_page_from_internal_links' },
  { label: 'backlink page from size', value: '_backlink_page_from_size' },
  { label: 'backlink page from encoding', value: '_backlink_page_from_encoding' },
  { label: 'backlink page from language', value: '_backlink_page_from_language' },
  { label: 'backlink page from title', value: '_backlink_page_from_title' },
  { label: 'backlink page from status code', value: '_backlink_page_from_status_code' },
  { label: 'backlink first seen', value: '_backlink_first_seen' },
  { label: 'backlink prev seen', value: '_backlink_prev_seen' },
  { label: 'backlink last seen', value: '_backlink_last_seen' },
  { label: 'backlink item type', value: '_backlink_item_type' },
  { label: 'backlink attributes', value: '_backlink_attributes' },
  { label: 'backlink dofollow', value: '_backlink_dofollow' },
  { label: 'backlink original', value: '_backlink_original' },
  { label: 'backlink alt', value: '_backlink_alt' },
  { label: 'backlink anchor', value: '_backlink_anchor' },
  { label: 'backlink text pre', value: '_backlink_text_pre' },
  { label: 'backlink text post', value: '_backlink_text_post' },
  { label: 'backlink semantic location', value: '_backlink_semantic_location' },
  { label: 'backlink links count', value: '_backlink_links_count' },
  { label: 'backlink group count', value: '_backlink_group_count' },
  { label: 'backlink is broken', value: '_backlink_is_broken' },
  { label: 'backlink url to status code', value: '_backlink_url_to_status_code' },
];

export const seoReferringDomainsOptions = [
  { label: 'SEO referring domain', value: '_seo_referring_domain' },
  { label: 'SEO referring domain rank', value: '_seo_referring_domain_rank' },
  { label: 'SEO referring domain backlinks', value: '_seo_referring_domain_backlinks' },
  { label: 'SEO referring domain first seen', value: '_seo_referring_domain_first_seen' },
  { label: 'SEO referring domain lost date', value: '_seo_referring_domain_lost_date' },
  { label: 'SEO referring domain broken backlinks', value: '_seo_referring_domain_broken_backlinks' },
  { label: 'SEO referring domain broken pages', value: '_seo_referring_domain_broken_pages' },
  { label: 'SEO referring main domains', value: '_seo_referring_main_domains' },
  { label: 'SEO referring ips', value: '_seo_referring_ips' },
  { label: 'SEO referring subnets', value: '_seo_referring_subnets' },
  { label: 'SEO referring pages', value: '_seo_referring_pages' },
  { label: 'SEO referring links tld', value: '_seo_referring_links_tld' },
  { label: 'SEO referring links types', value: '_seo_referring_links_types' },
  { label: 'SEO referring links attributes', value: '_seo_referring_links_attributes' },
  { label: 'SEO referring links platform types', value: '_seo_referring_links_platform_types' },
  { label: 'SEO referring links semantic locations', value: '_seo_referring_links_semantic_locations' },
];

export const seoIntelligenceOptions = [
  { label: 'Backlink intelligence first seen', value: '_backlink_intelligence_first_seen' },
  { label: 'Backlink intelligence lost date', value: '_backlink_intelligence_lost_date' },
  { label: 'Backlink intelligence rank', value: '_backlink_intelligence_rank' },
  { label: 'Backlink intelligence backlinks', value: '_backlink_intelligence_backlinks' },
  { label: 'Backlink intelligence crawled pages', value: '_backlink_intelligence_crawled_pages' },
  { label: 'Backlink intelligence info server', value: '_backlink_intelligence_info_server' },
  { label: 'Backlink intelligence info cms', value: '_backlink_intelligence_info_cms' },
  { label: 'Backlink intelligence info platform type', value: '_backlink_intelligence_info_platform_type' },
  { label: 'Backlink intelligence info IP address', value: '_backlink_intelligence_info_ip_address' },
  { label: 'Backlink intelligence info is IP', value: '_backlink_intelligence_info_is_ip' },
  { label: 'Backlink intelligence internal links count', value: '_backlink_intelligence_internal_links_count' },
  { label: 'Backlink intelligence external links count', value: '_backlink_intelligence_external_links_count' },
  { label: 'Backlink intelligence broken backlinks', value: '_backlink_intelligence_broken_backlinks' },
  { label: 'Backlink intelligence broken pages', value: '_backlink_intelligence_broken_pages' },
  { label: 'Backlink intelligence referring domains', value: '_backlink_intelligence_referring_domains' },
  { label: 'Backlink intelligence referring main domains', value: '_backlink_intelligence_referring_main_domains' },
  { label: 'Backlink intelligence referring IPs', value: '_backlink_intelligence_referring_ips' },
  { label: 'Backlink intelligence referring subnets', value: '_backlink_intelligence_referring_subnets' },
  { label: 'Backlink intelligence referring pages', value: '_backlink_intelligence_referring_pages' },
  { label: 'Backlink intelligence referring links tld', value: '_backlink_intelligence_referring_links_tld' },
  { label: 'Backlink intelligence referring links types', value: '_backlink_intelligence_referring_links_types' },
  {
    label: 'Backlink intelligence referring links attributes',
    value: '_backlink_intelligence_referring_links_attributes',
  },
  {
    label: 'Backlink intelligence referring links platform types',
    value: '_backlink_intelligence_referring_links_platform_types',
  },
  {
    label: 'Backlink intelligence referring links semantic locations',
    value: '_backlink_intelligence_referring_links_semantic_locations',
  },
];

export const textFromImageLanguagesOptions = [
  { label: 'Afrikaans', value: 'af' },
  { label: 'Albanian', value: 'sq' },
  { label: 'Arabic', value: 'ar' },
  { label: 'Armenian', value: 'hy' },
  // { label: 'Belorussian', value: 'be' },
  { label: 'Bengali', value: 'bn' },
  { label: 'Bulgarian', value: 'bg' },
  { label: 'Catalan', value: 'ca' },
  { label: 'Chinese', value: 'zh' },
  { label: 'Croatian', value: 'hr' },
  { label: 'Czech', value: 'cs' },
  { label: 'Danish', value: 'da' },
  { label: 'Dutch', value: 'nl' },
  { label: 'English', value: 'en' },
  { label: 'Estonian', value: 'et' },
  { label: 'Filipino', value: 'fil (or tl)' },
  { label: 'Finnish', value: 'fi' },
  { label: 'French', value: 'fr' },
  { label: 'German', value: 'de' },
  { label: 'Greek', value: 'el' },
  { label: 'Gujarati', value: 'gu' },
  { label: 'Hebrew', value: 'iw' },
  { label: 'Hindi', value: 'hi' },
  { label: 'Hungarian', value: 'hu' },
  { label: 'Icelandic', value: 'is' },
  { label: 'Indonesian', value: 'id' },
  { label: 'Italian', value: 'it' },
  { label: 'Japanese', value: 'ja' },
  { label: 'Kannada', value: 'kn' },
  { label: 'Khmer', value: 'km' },
  { label: 'Korean', value: 'ko' },
  { label: 'Lao', value: 'lo' },
  { label: 'Latvian', value: 'lv' },
  { label: 'Lithuanian', value: 'lt' },
  { label: 'Macedonian', value: 'mk' },
  { label: 'Malay', value: 'ms' },
  { label: 'Malayalam', value: 'ml' },
  { label: 'Marathi', value: 'mr' },
  { label: 'Nepali', value: 'ne' },
  { label: 'Norwegian', value: 'no' },
  { label: 'Persian', value: 'fa' },
  { label: 'Polish', value: 'pl' },
  { label: 'Portuguese', value: 'pt' },
  { label: 'Punjabi', value: 'pa' },
  { label: 'Romanian', value: 'ro' },
  // { label: 'Russian', value: 'ru' },
  // { label: 'Russian(Old Orthography)', value: 'ru-PETR1708' },
  { label: 'Serbian', value: 'sr' },
  { label: 'Serbian(Latin)', value: 'sr-Latn' },
  { label: 'Slovak', value: 'sk' },
  { label: 'Slovenian', value: 'sl' },
  { label: 'Spanish', value: 'es' },
  { label: 'Swedish', value: 'sv' },
  { label: 'Tamil', value: 'ta' },
  { label: 'Telugu', value: 'te' },
  { label: 'Thai', value: 'th' },
  { label: 'Turkish', value: 'tr' },
  { label: 'Ukrainian', value: 'uk' },
  { label: 'Vietnamese', value: 'vi' },
  { label: 'Yiddish', value: 'yi' },
];

export const conditionOptions = [
  { label: 'Contains', value: 'contains' },
  { label: 'Does not contain', value: 'doesNotContains' },
  { label: 'Is greater than', value: 'greater' },
  { label: 'Is less than', value: 'less' },
  { label: 'Is greater than or equal', value: 'greaterThan' },
  { label: 'Is less than or equal', value: 'lessThan' },
  { label: 'Equals', value: 'equal' },
  { label: 'Is not equal to', value: 'notEqual' },
  { label: 'Begins with', value: 'startsWith' },
  { label: 'Does not begin with', value: 'doesNotStartsWith' },
  { label: 'Ends with', value: 'endsWith' },
  { label: 'Does not end with', value: 'doesNotEndsWith' },
];

export const conditionAssociateObject: { [key: string]: string } = {
  greater: 'Is greater than',
  less: 'Is less than',
  greaterThan: 'Is greater than or equal',
  lessThan: 'Is less than or equal',
  equal: 'Equals',
  notEqual: 'Is not equal to',
  contains: 'Contains',
  doesNotContains: 'Does not contain',
  startsWith: 'Begins with',
  doesNotStartsWith: 'Does not begin with',
  endsWith: 'Ends with',
  doesNotEndsWith: 'Does not end with',
};

export const chartTypes = [
  {
    label: 'Pie chart',
    value: 'pie',
  },
  {
    label: 'Line chart',
    value: 'line',
  },
  {
    label: 'Bar chart',
    value: 'bar',
  },
];
