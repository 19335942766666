import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import CustomMultiSelect from 'shared-components/molecule/multiselect';
import Select from '../../../shared-components/atom/select';
import { textOptions } from '../../constants';
import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import './styles.scss';

const TextTransformation: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [clicked, setClicked] = useState(false);
  const [fieldList, setFieldList] = useState<any>([]);
  const [selectedFields, setSelectedFields] = useState<any>([]);
  const [values, setValues] = useState<any>([]);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const {
    outputFormat,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    liftingSettings,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (values?.length === 0) {
      return false;
    }
    let bool = true;
    values?.forEach((item: { label: any }) => {
      const finded = selectedFields.find((field: { key: any }) => field.key === item.label);
      if (finded) {
        if (!finded.value) bool = false;
      }
    });
    return bool;
  };

  //recipe fields or output fields
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();

    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings && dynamic_settings.keys) {
      const { keys } = dynamic_settings;
      let fieldsArr: any = [];
      //temporary for old keys
      const textObj: any = {
        CAPITALIZE_WORD: 'CAPITALIZE_EACH_WORD',
        START_LOWER_OTHER_UPPER: 'CAPITALIZE_ALL_EXCEPT_FIRST_LETTER',
      };
      const recreateSelectedFields = keys.map((item: any) => {
        fieldsArr.push(item.key);
        const outputDataType = item.settings ? item.settings.outputDataType : item.outputDataType;
        const findedOptionValue = textOptions.find(
          option => option.value === (textObj[outputDataType] ? textObj[outputDataType] : outputDataType),
        );
        return {
          key: item.key,
          value: findedOptionValue
            ? findedOptionValue.label
            : textObj[outputDataType]
            ? textObj[outputDataType]
            : outputDataType,
        };
      });
      const getValues = options.filter(
        (option: { label: string; value: string }) =>
          fieldsArr.includes(option.value) || fieldsArr.includes(option.label),
      );
      setValues(getValues);
      const newOptions = options.map((option: { label: any; value: string }) => {
        const finded = recreateSelectedFields.find(
          (field: { key: any }) => field.key === option.label || field.key === option.value,
        );
        if (finded) return { key: option.label, value: finded.value };
        return { key: option.label, value: textOptions[0].label };
      });
      setSelectedFields(newOptions);
    } else {
      setSelectedFields(options.map((item: any, index: number) => ({ key: item.label, value: textOptions[0].label })));
    }
    // setClicked(true);
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setValues(fieldList);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setValues([]);
      setClicked(true);
    }
    if (update) {
      if (dynamic_settings?.keys?.length > 1) {
        const filteredValue = filteredOptions?.filter((item: any) =>
          dynamic_settings?.keys.find((sub: any) => item.value === sub.key),
        );

        setValues(filteredValue || []);
        setClicked(true);
        setFieldList(filteredOptions);
        setFieldListWithTypes(optionsWithTypes);
      } else {
        const isArr = Array.isArray(dynamic_settings?.keys?.[0]?.key);
        const foundValue = !isArr
          ? filteredOptions?.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value)
          : null;
        const filteredValue = isArr
          ? filteredOptions?.filter((item: any) =>
              dynamic_settings?.keys?.[0]?.key.find((sub: any) => item.value === sub),
            )
          : [];
        const selOptionFound = isArr ? filteredValue : foundValue ? [foundValue] : [];

        setValues(selOptionFound);
        setClicked(true);
        setFieldList(filteredOptions);
        setFieldListWithTypes(optionsWithTypes);
      }
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const obj = {};
      //@ts-ignore
      values.map((item: { label: any }) => (obj[outputFormat(item.label, 'text_transformation')] = 'text'));
      const jsonParse =
        automationOutputTypes && automationOutputTypes !== 'null' ? JSON.parse(automationOutputTypes) : obj;
      liftingSettings({
        outputKeysData: [
          ...fieldList.map((item: { label: any; value: string }) => item.value),
          ...values.map((item: { label: any }) => outputFormat(item.label, 'text_transformation')),
        ],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, fieldListWithTypes, automationOutputTypes, values]);

  //lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);

    let localSettings = {
      keys: values.map((item: { label: any; value: any }) => {
        const finded = selectedFields?.find((field: { key: any }) => field.key === item.label);
        const findedOptionValue = textOptions?.find(item => finded.value === item.label || finded.value === item.value);
        return {
          key: item.value,
          inputValue: null,
          settings: {
            outputDataType: findedOptionValue ? findedOptionValue.value : null,
            add: true,
          },
        };
      }),
    };
    const obj = {};
    //@ts-ignore
    values.map((item: { label: any }) => (obj[outputFormat(item.label, 'text_transformation')] = 'text'));
    const jsonParse =
      automationOutputTypes && automationOutputTypes !== 'null' ? JSON.parse(automationOutputTypes) : obj;
    liftingSettings({
      dynamicSettings: localSettings,
      outputKeysData: [
        ...fieldList.map((item: { label: any; value: string }) => item.value),
        ...values.map((item: { label: any }) => outputFormat(item.label, 'text_transformation')),
      ],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [selectedFields, values, fieldList, fieldListWithTypes, automationOutputTypes]);

  const handleMultiInputs = (value: string | boolean, keyName: string) => {
    const copiedSelectedFields = [...selectedFields];
    const arr = copiedSelectedFields.map((field: { key: string; value: any }) => {
      if (field.key === keyName) {
        return { ...field, value: value };
      }
      return { ...field };
    });
    setSelectedFields(arr);
  };

  const findFrom = (label: string) => {
    const finded = selectedFields.find((field: { key: string }) => field.key === label);
    if (finded) {
      return finded.value;
    }
    return '';
  };

  return (
    <div className="measurment-unit-settings">
      <Row className="mb-4">
        <Col lg={6} className="">
          <span className="label secondary-color">Select fields for transformation</span>
          <CustomMultiSelect
            options={fieldList}
            value={values}
            onChange={(sel: any) => {
              setValues(sel);
              !clicked && setClicked(true);
              setIsDirty();
            }}
            labelledBy="Select"
          />
        </Col>
      </Row>
      {selectedFields.length > 0 &&
        values.map((property: { label: string; value: string }, index: number) => (
          <Row key={index} className="mb-4">
            <Col lg={6} className="">
              <span className="label secondary-color">{property.label} - convert to</span>
              <Select
                options={textOptions}
                value={findFrom(property.label)}
                placeholder={'Select column'}
                onChange={(selectedItem: any) => {
                  if (findFrom(property.label) === selectedItem.label) return;
                  handleMultiInputs(selectedItem.label, property.label);
                  !clicked && setClicked(true);
                  setIsDirty();
                }}
              ></Select>
            </Col>
          </Row>
        ))}
    </div>
  );
};

export default TextTransformation;
