import { FC } from 'react';
import { Toast } from 'react-bootstrap';
import { greenCheckIcon, closeIconWithBackground, closeIcon } from 'assets/svg-icons';
import Loader from 'shared-components/atom/loader';

import './styles.scss';

interface ToastLoaderProps {
  errorMessage?: string;
  loadingMessage?: string;
  successMessage?: any;
  onClose?: () => void;
}

const ToastLoader: FC<ToastLoaderProps> = ({ errorMessage, loadingMessage, successMessage, onClose }) => {
  return (
    <div className="toast-upper-container">
      <Toast>
        <Toast.Body className="d-flex">
          {errorMessage || successMessage ? (
            <div className="d-flex">
              <div className='d-flex'>
                <span className="mr-2 my-auto">{errorMessage ? closeIconWithBackground : greenCheckIcon}</span>{' '}
                {errorMessage || successMessage}
              </div>
              {onClose && (
                <div className="ml-auto cursor-pointer" onClick={onClose}>
                  <span className="ml-4">{closeIcon}</span>
                </div>
              )}
            </div>
          ) : (
            <>
              <Loader className="mr-2" /> {loadingMessage}
            </>
          )}
        </Toast.Body>
      </Toast>
    </div>
  );
};

export default ToastLoader;
