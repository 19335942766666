import { FC } from 'react';
import { options } from 'shared-components/molecule/pricingItem';

import { arrowDownIcon } from '../../../assets/svg-icons';
import Select from '../export-select';
import Radio from '../radio';
import { IPaymentItem } from '../types';

import './styles.scss';

const PaymentItem: FC<IPaymentItem> = ({
  name,
  selectedValue,
  selectedPremium,
  price,
  showPackeges,
  onChange,
  selected,
  checked,
  onRadioChange,
  disabled,
  handelSelectChange,
  user,
  premium_credit,
  premiumOptions,
  setShowMonthly,
  showMonthly,
  ltdPrice,
  ltdPriceGold,
  ltdPriceSilver,
  ltdPriceAjusted,
  offer,
  theme,
}) => {
  const handelSelect = (val: any) => {
    if (handelSelectChange) {
      if (premium_credit) {
        handelSelectChange(premiumOptions.find((item: any) => item.value === val));
      } else {
        handelSelectChange({ value: Number(val), title: String(val * 10000) });
      }
    }
  };
  return (
    <>
      <div
        className={`payment_toolbar d-flex justify-content-between align-items-center ${
          !selected ? 'no-selected' : ''
        } ${disabled ? 'disabled' : ''} ${premium_credit ? 'premium_credit-toolbar' : ''}`}
      >
        <div className="d-flex align-items-center">
          {!premium_credit && <Radio checked={checked} onClick={onRadioChange} className={'mt-2'} />}
          <div className="">
            <div>
              <div className="d-flex align-items-center automations-credits">
                {!premium_credit && (
                  <div className="package" onClick={onRadioChange}>
                    {name[0] + name.slice(1).toLocaleLowerCase()}
                  </div>
                )}
                <div className={`${name.toUpperCase() === 'GOLD' || premium_credit ? 'd-block' : 'd-none'}`}>
                  <div className="credits">{premium_credit ? 'Premium Credits' : 'Automation Credits'}</div>
                  <div className={`${premium_credit ? 'premium_credit_select' : ''} d-flex`}>
                    <div onClick={() => selected && showPackeges && onChange && onChange()}>
                      <Select
                        //@ts-ignore
                        options={
                          premium_credit
                            ? premiumOptions.map((item: any) => {
                                return { title: item.newTitle, value: item.value };
                              })
                            : options
                        }
                        //@ts-ignore
                        value={premium_credit && selectedPremium ? selectedPremium.newTitle : selectedValue.title}
                        click={handelSelect}
                        className={`pricing-item-select`}
                      />
                    </div>
                    {premium_credit && selectedPremium && (
                      <div className="select-label premium-credit-price-large">
                        ${selectedPremium.price}/{selectedPremium.interval}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {premium_credit && selectedPremium && (
                <div className="select-label premium-credit-price-mobile">
                  ${selectedPremium.price}/{selectedPremium.interval}
                </div>
              )}
              <div className="purchase">
                {/* $
                {name === "Gold" && selectedValue
                  ? selectedValue.value * price
                  : price}
                /mo,  */}
                ${premium_credit && selectedPremium
                  ? selectedPremium.price + '*'
                  : name === 'Gold' || name === 'GOLD'
                  ? ltdPriceGold
                    ? selectedValue.value * ltdPriceGold - ltdPriceAjusted
                    : selectedValue.value * price
                  : name === 'Silver' || name === 'SILVER'
                  ? ltdPriceSilver
                    ? ltdPriceSilver
                    : price
                  : price}{' '}
                {name.includes('trial') ? 'billed after 7-day free trial' : 'billed upon purchase'}
              </div>
            </div>
          </div>
        </div>
        {selected && !premium_credit && user?.pricing_package !== 'SILVER' && user?.pricing_package !== 'GOLD' && (
          <div className={`change-plan ${showPackeges ? 'rotate' : ''}`} onClick={onChange}>
            <span>Change Plan</span> {arrowDownIcon}
          </div>
        )}
      </div>
      {premium_credit && offer && (
        <div className="d-flex">
          <span
            className="clickable-text mr-2"
            style={{ display: 'inline-block', marginLeft: 'auto' }}
            onClick={() => setShowMonthly(false)}
          >
            Show flash promo
          </span>
        </div>
      )}
    </>
  );
};

export default PaymentItem;
