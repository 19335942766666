import { useMutation, useQuery } from '@apollo/react-hooks';
import { LOG_OUT_ALL_DEVICES, SCHEDULE_USER_TO_DELETE } from 'graphql/mutations';
import { USER_QUERY } from 'graphql/queries';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Devices from './devices';
import TwoFactor from './two-factor';
import ModalComponent from 'shared-components/molecule/modal';
import { useHistory } from 'react-router-dom';
import Input from 'shared-components/atom/input';
import { eyeIcon } from 'assets/svg-icons';
import Message from 'shared-components/atom/alert-message';

const Security = () => {
  //---------------------State------------------------
  const [userData, setUserData] = useState<any>(null);
  const [shouldBeDeletedAt, setShouldBeDeletedAt] = useState('');
  const [modalSuccess, setModalSuccess] = useState(false);
  const [errorAlert, setErrorAlert] = useState({ state: false, text: '' });
  const [showAlert, setShowAlert] = useState(false);
  const [logOutModal, setLogOutModal] = useState(false);
  const [password, setPassword] = useState<string | undefined>();
  const [passwordError, setPasswordError] = useState<string>('');
  const [passwordType, setPasswordType] = useState(false);
  const history = useHistory();
  const [showSignOutFromDevices, setShowSignOutFromDevices] = useState(false);

  //--------------------Queries----------------------
  const { data: userInfo, loading: toBeDeletedLoading, refetch } = useQuery(USER_QUERY, { fetchPolicy: 'no-cache' });
  const [deleteMutation, { loading: deleteLoading }] = useMutation(SCHEDULE_USER_TO_DELETE);
  const [logOut, { loading: logOutLoading }] = useMutation(LOG_OUT_ALL_DEVICES);
  //---------------------UseEffect----------------------
  useEffect(() => {
    if (userInfo?.User?.get) {
      let shouldBeDeletedAt = userInfo.User.get.shouldBeDeletedAt;
      setShouldBeDeletedAt(shouldBeDeletedAt);
      setUserData(userInfo.User.get);
    }
  }, [userInfo]);
  //-------------Functions--------------
  const handleError = (message: string) => {
    setErrorAlert({ state: true, text: message });
  };

  const handleCancel = () => {
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };
  const cancelAccountDelete = async () => {
    const { data } = await deleteMutation({ variables: { cancel: true } });
    if (!data) return;
    const {
      UserOps: {
        scheduleToDelete: { error },
      },
    } = data;
    if (error) {
      handleError('Cancellation Failed!');
    } else {
      handleCancel();
      await refetch();
    }
  };

  const handleDeleteAccount = async () => {
    const { data } = await deleteMutation({ variables: { cancel: false } });
    if (!data) return;
    const {
      UserOps: {
        scheduleToDelete: { error },
      },
    } = data;
    if (error) {
      handleError('Deletion Failed!');
    } else {
      setModalSuccess(false);
      await refetch();
    }
  };

  const handleChangePassword = (e: any) => {
    setPassword(e.target.value);
    setPasswordError('');
  };
  const handleShowLogOutModal = () => setLogOutModal(true);

  const handleLogOut = async () => {
    if (!password) return;
    const res = await logOut({
      variables: {
        password: password,
      },
    });
    if (res?.data?.UserOps?.logoutFromAllDevices?.error) {
      setPasswordError('Invalid password');
      setPassword('');
    } else {
      window.location.href = 'https://hexomatic.com/login/'
    }
  };

  const handleLogOutKeyUp = (e: any) => {
    if (e.key === 'Enter') handleLogOut();
  };

  const handleCancelLogOut = () => {
    setLogOutModal(false);
    setPassword(undefined);
    setPasswordType(false);
    setPasswordError('');
  };

  return (
    <>
      <div className="security-container col-12">
        <TwoFactor />
        <Devices setDevices={setShowSignOutFromDevices} />
      </div>
      <div className="d-sm-flex flex-wrap delete-block col-12">
        <div className="d-md-flex">
          <div className="pr-md-2">
            {userData && !userData.shouldBeDeletedAt ? (
              <span
                className={`delete ${deleteLoading || toBeDeletedLoading ? 'disabled' : ''}`}
                onClick={() => {
                  setModalSuccess(true);
                }}
              >
                Delete my account
              </span>
            ) : (
              <span className={`deletion-info text-center ${!userData ? 'd-none' : ''}`}>
                {' '}
                Your account is scheduled to be deleted {moment(parseInt(shouldBeDeletedAt)).fromNow()}.
                <span
                  className={`cancel ${deleteLoading || toBeDeletedLoading ? 'disabled' : ''}`}
                  onClick={cancelAccountDelete}
                >
                  {' '}
                  Cancel Deletion
                  {(deleteLoading || toBeDeletedLoading) && <Spinner className="ml-2" size="sm" animation="border" />}
                </span>
              </span>
            )}
          </div>
        </div>
        {showSignOutFromDevices && (
          <div className="ml-sm-auto">
            <span className={`clickable-text cursor-pointer`} onClick={handleShowLogOutModal}>
              Sign out from all devices
            </span>
          </div>
        )}
      </div>
      <ModalComponent
        isModalOpen={modalSuccess}
        text='Please confirm account deletion with all data connected to it, this will also cancel any active subscription(s).'
        actionBtnText='I wish to proceed with account deletion'
        cancelBtnText='No, take me back to safety'
        actionBtnColor='danger'
        cancelBtnColor='secondary'
        onActionClick={handleDeleteAccount}
        onCancelClick={() => setModalSuccess(false)}
        loading={deleteLoading || toBeDeletedLoading}
        escButtonClick={() => setModalSuccess(false)}
        wrapButtons
      />
      <ModalComponent
        isModalOpen={showAlert}
        text='We are happy with your decision'
        actionBtnText='OK'
        actionBtnColor='success'
        onActionClick={() => setShowAlert(false)}
        escButtonClick={() => setShowAlert(false)}
      />
      <ModalComponent
        isModalOpen={errorAlert.state}
        text={errorAlert.text}
        actionBtnText='OK'
        actionBtnColor='danger'
        onActionClick={() => setShowAlert(false)}
        escButtonClick={() => setShowAlert(false)}
      />
      <ModalComponent
        isModalOpen={logOutModal}
        parentClass="logout-modal"
        text={
          <>
            <div className="position-relative">
              <div className="text">
              Please confirm that you want to Sign out from all devices
              </div>
              <Input
                type={!passwordType ? 'password' : 'text'}
                value={password}
                onChange={handleChangePassword}
                error={password !== undefined && !password && passwordError ? 'error' : ''}
                placeholder="password"
                className="pr-4"
                autocomplete={false}
                onKeyUp={handleLogOutKeyUp}
              />
              <span
                className={`eye-icon position-absolute ${passwordType ? 'active' : ''}`}
                onClick={() => setPasswordType(!passwordType)}
              >
                {eyeIcon}
              </span>
            </div>

            <Message
              type="error"
              text={passwordError}
              className={`px-2 mt-1 ${passwordError ? 'visible' : 'invisible'}`}
            />
          </>
        }
        actionBtnText='Confirm'
        cancelBtnText='Cancel'
        actionBtnColor='danger'
        cancelBtnColor='danger'
        onActionClick={handleLogOut}
        onCancelClick={handleCancelLogOut}
        loading={logOutLoading}
        escButtonClick={handleCancelLogOut}
      />
    </>
  );
};

export default Security;
