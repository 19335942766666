import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
import Alert from 'shared-components/atom/alert-message';
import CustomMultiSelect from 'shared-components/molecule/multiselect';
import Input from 'shared-components/atom/input';
import { seoBacklinkOptions } from '../../constants';
import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import './styles.scss';

const domainOptions = [
  { label: 'domain', value: 'domain' },
  { label: 'webpage', value: 'webpage' },
];

const field = { label: 'backlink domain', value: '_backlink_domain' };

// const aggregationOptions = [
//   { label: 'Show all backlinks', value: 'as_is' },
//   { label: 'Show one backlink per domain', value: 'one_per_domain' },
//   { label: 'Show one backlink per anchor', value: 'one_per_anchor' },
// ];

// const listOfOperators = {
//   domain_from: [
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//     { label: 'like', value: 'like' },
//     { label: 'not_like', value: 'not_like' },
//   ],
//   url_from: [
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//     { label: 'like', value: 'like' },
//     { label: 'not_like', value: 'not_like' },
//   ],
//   url_from_https: [
//     { label: '=', value: '=' },
//     { label: '<', value: '<' },
//     { label: '>', value: '>' },
//   ],
//   domain_to: [
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//     { label: 'like', value: 'like' },
//     { label: 'not_like', value: 'not_like' },
//   ],
//   url_to: [
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//     { label: 'like', value: 'like' },
//     { label: 'not_like', value: 'not_like' },
//   ],
//   url_to_https: [
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//   ],
//   tld_from: [
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//     { label: 'like', value: 'like' },
//     { label: 'not_like', value: 'not_like' },
//   ],
//   is_new: [
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//   ],
//   is_lost: [
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//   ],
//   rank: [
//     { label: '<', value: '<' },
//     { label: '<=', value: '<=' },
//     { label: '>', value: '>' },
//     { label: '>=', value: '>=' },
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//     { label: 'in', value: 'in' },
//     { label: 'not_in', value: 'not_in' },
//   ],
//   page_from_rank: [
//     { label: '<', value: '<' },
//     { label: '<=', value: '<=' },
//     { label: '>', value: '>' },
//     { label: '>=', value: '>=' },
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//     { label: 'in', value: 'in' },
//     { label: 'not_in', value: 'not_in' },
//   ],
//   domain_from_rank: [
//     { label: '<', value: '<' },
//     { label: '<=', value: '<=' },
//     { label: '>', value: '>' },
//     { label: '>=', value: '>=' },
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//     { label: 'in', value: 'in' },
//     { label: 'not_in', value: 'not_in' },
//   ],
//   domain_from_is_ip: [
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//   ],
//   domain_from_ip: [
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//     { label: 'like', value: 'like' },
//     { label: 'not_like', value: 'not_like' },
//   ],
//   page_from_external_links: [
//     { label: '<', value: '<' },
//     { label: '<=', value: '<=' },
//     { label: '>', value: '>' },
//     { label: '>=', value: '>=' },
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//     { label: 'in', value: 'in' },
//     { label: 'not_in', value: 'not_in' },
//   ],
//   page_from_internal_links: [
//     { label: '<', value: '<' },
//     { label: '<=', value: '<=' },
//     { label: '>', value: '>' },
//     { label: '>=', value: '>=' },
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//     { label: 'in', value: 'in' },
//     { label: 'not_in', value: 'not_in' },
//   ],
//   page_from_size: [
//     { label: '<', value: '<' },
//     { label: '<=', value: '<=' },
//     { label: '>', value: '>' },
//     { label: '>=', value: '>=' },
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//     { label: 'in', value: 'in' },
//     { label: 'not_in', value: 'not_in' },
//   ],
//   page_from_encoding: [
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//     { label: 'like', value: 'like' },
//     { label: 'not_like', value: 'not_like' },
//   ],
//   page_from_language: [
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//     { label: 'like', value: 'like' },
//     { label: 'not_like', value: 'not_like' },
//   ],
//   page_from_title: [
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//     { label: 'like', value: 'like' },
//     { label: 'not_like', value: 'not_like' },
//   ],
//   page_from_status_code: [
//     { label: '<', value: '<' },
//     { label: '<=', value: '<=' },
//     { label: '>', value: '>' },
//     { label: '>=', value: '>=' },
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//     { label: 'in', value: 'in' },
//     { label: 'not_in', value: 'not_in' },
//   ],
//   first_seen: [
//     { label: '<', value: '<' },
//     { label: '<=', value: '<=' },
//     { label: '>', value: '>' },
//     { label: '>=', value: '>=' },
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//     { label: 'in', value: 'in' },
//     { label: 'not_in', value: 'not_in' },
//   ],
//   prev_seen: [
//     { label: '<', value: '<' },
//     { label: '<=', value: '<=' },
//     { label: '>', value: '>' },
//     { label: '>=', value: '>=' },
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//     { label: 'in', value: 'in' },
//     { label: 'not_in', value: 'not_in' },
//   ],
//   last_seen: [
//     { label: '<', value: '<' },
//     { label: '<=', value: '<=' },
//     { label: '>', value: '>' },
//     { label: '>=', value: '>=' },
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//     { label: 'in', value: 'in' },
//     { label: 'not_in', value: 'not_in' },
//   ],
//   item_type: [
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//     { label: 'like', value: 'like' },
//     { label: 'not_like', value: 'not_like' },
//   ],
//   dofollow: [
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//   ],
//   original: [
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//   ],
//   alt: [
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//     { label: 'like', value: 'like' },
//     { label: 'not_like', value: 'not_like' },
//   ],
//   anchor: [
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//     { label: 'like', value: 'like' },
//     { label: 'not_like', value: 'not_like' },
//   ],
//   text_pre: [
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//     { label: 'like', value: 'like' },
//     { label: 'not_like', value: 'not_like' },
//   ],
//   text_post: [
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//     { label: 'like', value: 'like' },
//     { label: 'not_like', value: 'not_like' },
//   ],
//   semantic_location: [
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//     { label: 'like', value: 'like' },
//     { label: 'not_like', value: 'not_like' },
//   ],
//   links_count: [
//     { label: '<', value: '<' },
//     { label: '<=', value: '<=' },
//     { label: '>', value: '>' },
//     { label: '>=', value: '>=' },
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//     { label: 'in', value: 'in' },
//     { label: 'not_in', value: 'not_in' },
//   ],
//   group_count: [
//     { label: '<', value: '<' },
//     { label: '<=', value: '<=' },
//     { label: '>', value: '>' },
//     { label: '>=', value: '>=' },
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//     { label: 'in', value: 'in' },
//     { label: 'not_in', value: 'not_in' },
//   ],
//   is_broken: [
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//   ],
//   url_to_status_code: [
//     { label: '<', value: '<' },
//     { label: '<=', value: '<=' },
//     { label: '>', value: '>' },
//     { label: '>=', value: '>=' },
//     { label: '=', value: '=' },
//     { label: '<>', value: '<>' },
//     { label: 'in', value: 'in' },
//     { label: 'not_in', value: 'not_in' },
//   ],
// };

// const filtersField = [
//   { label: 'domain from', value: 'domain_from' },
//   { label: 'url from', value: 'url_from' },
//   { label: 'url from https', value: 'url_from_https' },
//   { label: 'domain to', value: 'domain_to' },
//   { label: 'url to', value: 'url_to' },
//   { label: 'url to https', value: 'url_to_https' },
//   { label: 'tld from', value: 'tld_from' },
//   { label: 'is new', value: 'is_new' },
//   { label: 'is lost', value: 'is_lost' },
//   { label: 'rank', value: 'rank' },
//   { label: 'page from rank', value: 'page_from_rank' },
//   { label: 'domain from rank', value: 'domain_from_rank' },
//   { label: 'domain from is ip', value: 'domain_from_is_ip' },
//   { label: 'domain from ip', value: 'domain_from_ip' },
//   { label: 'page from external links', value: 'page_from_external_links' },
//   { label: 'page from internal links', value: 'page_from_internal_links' },
//   { label: 'page from size', value: 'page_from_size' },
//   { label: 'page from encoding', value: 'page_from_encoding' },
//   { label: 'page from language', value: 'page_from_language' },
//   { label: 'page from title', value: 'page_from_title' },
//   { label: 'page from status code', value: 'page_from_status_code' },
//   { label: 'first seen', value: 'first_seen' },
//   { label: 'prev seen', value: 'prev_seen' },
//   { label: 'last seen', value: 'last_seen' },
//   { label: 'item type', value: 'item_type' },
//   { label: 'dofollow', value: 'dofollow' },
//   { label: 'original', value: 'original' },
//   { label: 'alt', value: 'alt' },
//   { label: 'anchor', value: 'anchor' },
//   { label: 'text pre', value: 'text_pre' },
//   { label: 'text post', value: 'text_post' },
//   { label: 'semantic location', value: 'semantic_location' },
//   { label: 'links count', value: 'links_count' },
//   { label: 'group count', value: 'group_count' },
//   { label: 'is broken', value: 'is_broken' },
//   { label: 'url to status code', value: 'url_to_status_code' },
// ];

const limitValues = [
  { label: '1000', value: 1000 },
  { label: '2000', value: 2000 },
  { label: '3000', value: 3000 },
  { label: '4000', value: 4000 },
  { label: '5000', value: 5000 },
  { label: '6000', value: 6000 },
  { label: '7000', value: 7000 },
  { label: '8000', value: 8000 },
  { label: '9000', value: 9000 },
  { label: '10,000', value: 10000 },
  { label: '15,000', value: 15000 },
  { label: '20,000', value: 20000 },
  { label: '25,000', value: 25000 },
];

const SEOBacklinkExplorer: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [fieldList, setFieldList] = useState<any>([]);
  const [clicked, setClicked] = useState(false);
  const [source, setSource] = useState('');
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [selectedSourceType, setSelectedSourceType] = useState<any>({ label: 'domain', value: 'domain' });
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);
  // const [saveFrom, setSaveFrom] = useState<number | string>(1);
  const [saveTo, setSaveTo] = useState({ label: '1000', value: 1000 });
  const [errorMessage, setErrorMessage] = useState('');
  const [selected, setSelected] = useState<any>([]);
  // const [aggregationOption, setAggregationOption] = useState({ label: 'Show all backlinks', value: 'as_is' });
  // const [filterBtn, setFilterBtn] = useState(true);
  // const [filtersList, setFiltersList] = useState([
  //   [{ label: 'domain from', value: 'domain_from' }, { label: '=', value: '=' }, { value: '' }, { operator: '' }],
  // ] as any);

  const {
    liftingSettings,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (errorMessage) {
      return false;
    }
    if (selected?.length <= 0) return false;
    if (fieldList?.length === 0 ? !source : Array.isArray(selectedSource) ? !selectedSource?.length : !selectedSource) return false;
    return true;
  };

  useEffect(() => {
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const { keys } = dynamic_settings;
      const { settings } = keys[0];

      const backlinkConfigs = settings ? settings.backlinkConfigs : keys[0].backlinkConfigs;
      const isDomain = settings ? settings.isDomain : keys[0].isDomain;
      const mode = settings ? settings.mode : keys[0].mode;
      const to = settings ? settings.to : keys[0].to;
      const from = settings ? settings.from : keys[0].from;
      const inputValue = settings ? keys[0].inputValue : keys[0].target;

      inputValue && setSource(inputValue);
      // keys[0].key && setFiltersList(keys[0].key);
      setSelectedSourceType({
        label: isDomain ? 'domain' : 'webpage',
        value: isDomain ? 'domain' : 'webpage',
      });
      // const aggregationObj = aggregationOptions.find(item => item.value === keys[0].mode);
      // aggregationObj && setAggregationOption(aggregationObj);
      //there may be zero here, since initially these fields were not
      // setSaveFrom(keys[0].from === undefined ? 1 : keys[0].from);
      const saveVal = limitValues.find((item: any) => item.value === to);
      saveVal ? setSaveTo(saveVal) : setSaveTo({ label: '1000', value: 1000 });
      const arr = [] as any;
      backlinkConfigs &&
        backlinkConfigs.map((output: string) => {
          const val = seoBacklinkOptions.find(item => item.value === output);
          val && arr.push(val);
        });
      setSelected(arr);
      // const filters = keys[0].filters && JSON.parse(keys[0].filters);
      // const list: any = [];
      // filters.map((item: any) => {
      //     if (Array.isArray(item)) {
      //       const arr = item[0].split(',');
      //       const selecedFiled = filtersField.find(val => val.value === arr[0]);
      //       const selectedOperator =
      //       //@ts-ignore
      //         listOfOperators[arr[0]] && listOfOperators[arr[0]].find(val => val.value === arr[1].trim());
      //       const value = arr[2];
      //       list.push([selecedFiled, selectedOperator, { value: value }]);
      //     } else {
      //       list[list.length - 1].push({ operator: item });
      //     }
      // });
      // list.length > 0 && setFiltersList(list);
      // list.length > 0 && setFilterBtn(false);
    }
  }, []);

  //recipe
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const key = dynamic_settings.keys[0].key;
      restoreSelectedSource({ key: key, fieldList: options, setSelectedSource: setSelectedSource });
    }
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, []);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [
          ...fieldList.map((item: { value: any }) => item.value),
          ...selected.map((item: any) => item.value),
        ],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, selected, fieldListWithTypes, automationOutputTypes]);

  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);
    // const selectOption = countryOptions.find((item: { label: string; value: number }) => item.label === countryText);

    // !selectOption && setCompletedFields(false);
    // const finList: any = [];
    // filtersList.map((item: any) => {
    //   const itemValue = [
    //     `${item[0].value}, ${item[1].value}, ${
    //       item[2].value === 'true' && (item[1].value === '=' || item[1].value === '<>')
    //         ? true
    //         : item[2].value === 'false' && (item[1].value === '=' || item[1].value === '<>')
    //         ? false
    //         : typeof +item[2].value === 'number' && item[1].value === 'like' && item[1].value === 'not_like'
    //         ? +item[2].value
    //         : item[2].value.indexOf(0) === '[' &&
    //           item[2].value.indexOf(item[2].value.length - 1) === ']' &&
    //           (item[1].value === 'in' || item[1].value === 'not_in')
    //         ? JSON.parse(item[2].value)
    //         : item[1].value === 'like' || item[1].value === 'not_like'
    //         ? `%${item[2].value}%`
    //         : item[2].value
    //     }`,
    //   ];
    //   if (item[3].operator) {
    //     finList.push(item[3].operator);
    //     finList.push(itemValue);
    //   } else {
    //     finList.push(itemValue);
    //   }
    // });

    let localSettings = {
      keys: [
        {
          inputValue: fieldList.length === 0 && automationIndex === 0 ? source.trim() : null,
          key: fieldList.length && selectedSource ? selectedSource.value : null,
          settings: {
            backlinkConfigs: selected.map((item: { value: any }) => item.value),
            isDomain: selectedSourceType.value === 'domain' ? true : false,
            mode: 'as_is',
            // filters: JSON.stringify(finList),
            to: saveTo.value,
            from: 0,
          },
        },
      ],
    };
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: localSettings,
      outputKeysData: [
        ...fieldList.map((item: { value: any }) => item.value),
        ...selected.map((item: any) => item.value),
      ],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [
    source,
    errorMessage,
    selectedSource,
    selectedSourceType,
    saveTo,
    selectedSourceType,
    selected,
    fieldList,
    fieldListWithTypes,
    automationOutputTypes,
    automationIndex,
  ]);

  const clearErrorMsg = () => {
    setTimeout(() => {
      setErrorMessage('');
    }, 3000);
  };

  // const handleAddFilter = (condition: string) => {
  //   const values = [...filtersList];
  //   values.push([
  //     { label: 'domain from', value: 'domain_from' },
  //     { label: '=', value: '=' },
  //     { value: '' },
  //     { operator: condition },
  //   ]);
  //   setFiltersList(values);
  // };

  // const handleFilterDelete = (index: any) => {
  //   const values = [...filtersList];
  //   values.splice(index, 1);
  //   setFiltersList(values);
  // };

  // const handleFieldNameUpdate = (value: any, index: number) => {
  //   const values = [...filtersList];
  //   values[index][0] = value;
  //   values[index][1] = { label: '=', value: '=' };
  //   setFiltersList(values);
  // };

  // const handleFieldValueUpdate = (value: any, index: number) => {
  //   const values = [...filtersList];
  //   values[index][2] = { value: value };
  //   setFiltersList(values);
  // };

  // const handleOperatorUpdate = (value: any, index: number) => {
  //   const values = [...filtersList];
  //   values[index][1] = value;
  //   setFiltersList(values);
  // };

  return (
    <div className="google-automation-settings">
      <Row>
        {fieldList.length > 0 || automationIndex > 0 ? (
          <Col lg={6} className="mb-4">
            <span className="label secondary-color">Source</span>
            <Select
              options={fieldList}
              value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
              placeholder={'Select source'}
              onChange={(selectedItem: any) => {
                setSelectedSource(selectedItem);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
        ) : (
          <Col lg={6} className="mb-4">
            <span className="label secondary-color">{selectedSourceType.value === 'domain' ? 'Domain' : 'URL'}</span>
            <Input
              value={source}
              onChange={e => {
                setSource(e.target.value);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
        )}
        <Col lg={6}>
          <Row>
            <Col md={6} className="mb-4">
              <span className="label secondary-color">Explore mode</span>
              <Select
                options={domainOptions}
                value={selectedSourceType}
                placeholder={'Select mode'}
                onChange={(selectedItem: any) => {
                  setSelectedSourceType(selectedItem);
                  !clicked && setClicked(true);
                  setIsDirty();
                }}
              />
            </Col>
            {/* <Col md={3}>
              <span className="label secondary-color">From</span>
              <Input value={saveFrom} onChange={handleSaveFrom} />
            </Col> */}
            <Col md={6} className="mb-4">
              <span className="label secondary-color">Limit results up to:</span>
              <Select
                options={limitValues}
                value={saveTo}
                placeholder={'Select limit'}
                onChange={(selectedItem: any) => {
                  setSaveTo(selectedItem);
                  !clicked && setClicked(true);
                  setIsDirty();
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col lg={6}>
          <span className="label secondary-color">Fields</span>
          <CustomMultiSelect
            options={automationIndex === 0 ? [field, ...seoBacklinkOptions] : seoBacklinkOptions}
            value={selected}
            onChange={(sel: any) => {
              setSelected(sel);
              !clicked && setClicked(true);
              setIsDirty();
            }}
            labelledBy="Select"
          />
        </Col>
        {/* <Col lg={6} className="mb-4">
          <span className="label secondary-color">Aggregation</span>
          <Select
            fullWidth
            options={aggregationOptions}
            value={aggregationOption}
            placeholder={'Select aggregation'}
            onChange={(selectedItem: any) => {
              setAggregationOption(selectedItem);
              !clicked && setClicked(true);
            }}
            textDefault
          />
        </Col> */}
        {/* <Col lg={8} className="mb-4">
          {filterBtn ?
            <Button name={'Add filters'} onClick={() => setFilterBtn(false)} /> :
            filtersList.map((item: any, index: number) => (
            <Row>
              <Col lg={5} className="pl-3 pr-1">
                <span className="label secondary-color">Filter Field</span>
                <Select
                  fullWidth
                  //@ts-ignore
                  options={filtersField}
                  value={item[0].value}
                  placeholder={'Select field'}
                  onChange={(selectedItem: any) => {
                    handleFieldNameUpdate(selectedItem, index);
                    !clicked && setClicked(true);
                  }}
                />
              </Col>
              <Col lg={2} className="pl-1 pr-1">
                <span className="label secondary-color">Operator</span>
                <Select
                  fullWidth
                  //@ts-ignore
                  options={listOfOperators[item[0].value]}
                  value={item[1].value}
                  placeholder={'Select operator'}
                  onChange={(selectedItem: any) => {
                    handleOperatorUpdate(selectedItem, index);
                    !clicked && setClicked(true);
                  }}
                />
              </Col>
              <Col lg={3} className="pl-1 pr-3">
                <span className="label secondary-color">Value</span>
                <Input
                  value={item[2].value}
                  onChange={e => {
                    handleFieldValueUpdate(e.target.value, index);
                    !clicked && setClicked(true);
                  }}
                />
              </Col>

              <Col lg={2} className="pl-1 pr-3 d-flex" style={{ marginTop: '30px' }}>
                {(index === filtersList.length - 1) && index < 7 ? (
                  <>
                    <div className="filter-button" onClick={() => handleAddFilter('and')}>
                      <span>and</span>
                    </div>
                    <div className="filter-button" onClick={() => handleAddFilter('or')}>
                      <span>or</span>
                    </div>
                  </>
                ) : index === 7 ? <></> :
                (filtersList[index + 1] &&
                  filtersList[index + 1][3] && (
                    <span className="m-vertical-auto">{filtersList[index + 1][3].operator}</span>
                  )
                )}
                {(
                  <div className="filter-button-delete" >
                    <span onClick={() => {index === 0 ? 
                    setFilterBtn(true) :
                    handleFilterDelete(index)
                  }}>{closeIcon}</span>
                  </div>
                )}
              </Col>
            </Row>
          ))}
        </Col> */}
      </Row>
      {errorMessage && (
        <Row>
          <Col md={12}>
            <Alert type="error" className="create-workflow">
              {errorMessage}
            </Alert>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default React.memo(SEOBacklinkExplorer);
