import { memo, useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { UserContext } from 'contexts/user-context';

import { isValidKeyword, isValidWorkflowName } from 'constants/validations';
import { publicAllowedUserIDs } from 'constants/index';
import { HexomaticUserContext } from 'contexts/hexomatic-user';

import Checkbox from 'shared-components/atom/checkbox';
import TimeElement from 'shared-components/atom/show-time';
import EditableText from 'shared-components/molecule/editable-text';
import ModalComponent from 'shared-components/molecule/modal';
import CustomModal from 'shared-components/molecule/customModal';
// import ToastLoader from 'shared-components/molecule/toast-loader';
import MenuModal from 'pages/workflows/components/menu-modal';
import Modal from 'shared-components/molecule/modal';

import {
  workflowIcon,
  deleteIcon,
  // copyIcon,
  tableActionsIcon,
  globeIcon,
  lockIconModal,
  copyIconModal,
  shareIcon,
  favoriteIcon,
} from 'assets/svg-icons';
import Tooltip from 'shared-components/atom/tooltip';
import Button from 'shared-components/atom/button';

import './styles.scss';

const ListItem = ({
  checkedIndexes,
  handleCheckboxClick,
  item,
  setOptionParameters,
  // recipesListLength,
  // idx,
  // handleCopyRecipe,
  updateRecipe,
  refetchRecipe,
  refetchScrapingList,
  openedId,
  setOpenedId,
  openedDescId,
  setOpenedDescId,
  handleMarkAsStarred,
  handleRemoveFromStarred,
}: any) => {
  const isMobile = useMediaQuery({ maxWidth: 1240 });
  const { hexomaticUser } = useContext(HexomaticUserContext);
  const { user } = useContext(UserContext);

  const { push } = useHistory();
  const [alertMsg, setAlertMsg] = useState<any>({ show: false, text: '', type: '' });
  const [shouldUpgrade, setShouldUpgrade] = useState(false);
  const [openMenuModal, setOpenMenuModal] = useState(false);
  const [optionForMenu, setOptionForMenu] = useState([] as any);
  const [isAdmin, setIsAdmin] = useState(publicAllowedUserIDs.includes(user.id));

  useEffect(() => {
    if (user) {
      setIsAdmin(publicAllowedUserIDs.includes(user.id));
    }
  }, [user]);

  const handleMenuModalOpen = (e: any, item: any): void => {
    e.preventDefault();
    e.stopPropagation();
    const menuOption = [
      {
        label: 'Use in a workflow',
        value: item.id,
        icon: workflowIcon,
        url: '',
      },
      {
        label: 'Duplicate recipe',
        value: item.id,
        icon: copyIconModal,
        url: '',
      },
      {
        label: 'Share recipe',
        value: item.id,
        icon: shareIcon,
        url: '',
      },
    ];
    isAdmin &&
      menuOption.push({
        label: item.is_public ? 'Make private' : 'Make public',
        value: item.id,
        icon: item.is_public ? lockIconModal : globeIcon,
        url: item.url,
      });
    menuOption.push({
      label: 'Delete recipe',
      value: item.id,
      icon: deleteIcon,
      url: '',
    });

    setOptionForMenu(menuOption);
    setOpenMenuModal(true);
  };

  const handleUpdateRecipeNameAndDescription = async ({ id, text, type }: any) => {
    if (type === 'name' && (!text || !isValidWorkflowName(text))) {
      setAlertMsg({ show: true, text: `Invalid name`, type: 'error' });
      return { message: null };
    }
    const res =
      type === 'name'
        ? await updateRecipe({
            variables: {
              settings: {
                name: text.trim(),
                id: id,
              },
            },
          })
        : await updateRecipe({
            variables: {
              settings: {
                description: text.trim(),
                id: id,
              },
            },
          });
    if (res?.data?.HexomaticScrapingRecipeOps?.updateScrapingRecipe?.error_code) {
      if (type === 'name') {
        setAlertMsg({
          show: true,
          text:
            res?.data?.HexomaticScrapingRecipeOps?.updateScrapingRecipe?.error_code === 'ALREADY_EXISTS'
              ? `"${text}" already exists`
              : 'Something went wrong',
          type: 'error',
        });
        return { message: null };
      } else {
        setAlertMsg({
          show: true,
          text: `${res.data.HexomaticScrapingRecipeOps.updateScrapingRecipe.error_code}`,
          type: 'error',
        });
        return { message: null };
      }
      // return { message: 'Already exists' };
    } else {
      try {
        refetchRecipe && (await refetchRecipe());
        await refetchScrapingList();
      } catch (error) {}
    }
    return { message: null };
  };

  return (
    <>
      <Link to={`/scraping-recipes/current-recipe/${item.id}`} className="cursor-pointer scraping-row">
        <td style={{ width: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '32px' }}>
          <span
            className="d-flex justify-content-between align-items-center pl-3"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Checkbox checked={checkedIndexes.indexOf(item.id) > -1} onChange={() => handleCheckboxClick(item.id)} />
          </span>
          <span
            className="ml-3"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              item.isFavorite ? handleRemoveFromStarred(item.id) : handleMarkAsStarred(item.id);
            }}
          >
            <Tooltip
              trigger={['hover']}
              placement="right"
              text={
                item.isFavorite
                  ? 'Click to remove from starred recipes list'
                  : 'Click to add into the starred recipies list'
              }
              className="mb-1 "
            >
              <span className={item.isFavorite ? 'favorite-icon-bg mr-2' : 'mr-2'}>{favoriteIcon}</span>
            </Tooltip>
          </span>
        </td>
        <td onClick={() => push(`/scraping-recipes/current-recipe/${item.id}`)}>
          <div className="d-flex">
            <EditableText
              key={item.id}
              name={item.name}
              id={item.id}
              onSave={async ({ id, text }: any) => {
                return await handleUpdateRecipeNameAndDescription({ id, text, type: 'name' });
              }}
              maxTextCount={64}
              openedInfo={openedId}
              setOpenInfo={id => {
                setOpenedId(id);
                setOpenedDescId(null);
              }}
            />
          </div>

          {item.description && (
            <span className="description">
              <EditableText
                isDescription
                key={`${item.id}-${uuidv4()}`}
                name={item.description}
                id={item.id}
                onSave={async ({ id, text }: any) => {
                  return await handleUpdateRecipeNameAndDescription({ id, text, type: 'description' });
                }}
                maxTextCount={300}
                openedInfo={openedDescId}
                setOpenInfo={id => {
                  setOpenedId(null);
                  setOpenedDescId(id);
                }}
              />
            </span>
          )}
        </td>
        <td
          style={{ width: '150px' }}
          className="text-center"
          onClick={() => push(`/scraping-recipes/current-recipe/${item.id}`)}
        >
          <TimeElement time={item.created_at} />
        </td>
        <td
          style={{ width: '150px' }}
          className="text-center"
          onClick={() => push(`/scraping-recipes/current-recipe/${item.id}`)}
        >
          <TimeElement time={item.updated_at} />
        </td>
        <td
          style={{ width: '150px', minWidth: '150px' }}
          className={`${isMobile ? 'use-in-workflow-mobile' : 'use-in-workflow'}`}
        >
          <div
            className="clickable-text"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              setOptionParameters({
                label: 'Use in a workflow',
                value: item.id,
                icon: workflowIcon,
                url: '',
              });
            }}
          >
            Use in a workflow
          </div>
        </td>
        <td style={{ width: '40px', paddingRight: '20px' }}>
          <span className="p-2" onClick={e => handleMenuModalOpen(e, item)}>
            {tableActionsIcon}
          </span>
        </td>
        {/* <td style={{ width: '40px', paddingRight: '20px' }}>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="left"
            overlay={
              <Popover id="popover-trigger-hover-focus" className="popover-trigger-hover-focus-right">
                Use in a workflow
              </Popover>
            }
          >
            <button
              className="delete-icon"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                localStorage.setItem('workflow-recipe-id', `${item.id}`);
                push('workflows/create');
              }}
            >
              {workflowIcon}
            </button>
          </OverlayTrigger>
        </td>
        <td style={{ width: '40px', paddingRight: '20px' }}>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="left"
            overlay={
              <Popover id="popover-trigger-hover-focus" className="popover-trigger-hover-focus-right">
                Duplicate recipe
              </Popover>
            }
          >
            <button
              className="delete-icon"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                handleCopyRecipe(item.id)
              }}
            >
              {copyIcon}
            </button>
          </OverlayTrigger>
        </td>
        <td style={{ width: '40px', paddingRight: '20px' }}>
          <div
            className={`${
              idx === recipesListLength - 1 && 'change-option-position'
            } d-flex align-items-center menu-container`}
          >
            <OverlayTrigger
              trigger={['hover', 'focus']}
              placement="left"
              overlay={
                <Popover id="popover-trigger-hover-focus" className="popover-trigger-hover-focus-right">
                  Delete Recipe
                </Popover>
              }
            >
              <button
                className="delete-icon"
                onClick={() => setOptionParameters({ label: 'Delete', value: `${item.id}` })}
              >
                {deleteIcon}
              </button>
            </OverlayTrigger>
          </div>
        </td> */}
      </Link>
      {alertMsg.show && (
        <ModalComponent
          isModalOpen={alertMsg.show}
          text={alertMsg.text}
          actionBtnText={'OK'}
          actionBtnColor={alertMsg.type}
          onActionClick={() => setAlertMsg({ show: false, text: '', type: '' })}
          escButtonClick={() => setAlertMsg({ show: false, text: '', type: '' })}
        />
      )}
      {openMenuModal && (
        <CustomModal ShowPopup={openMenuModal} noNavigate escButtonClick={() => setOpenMenuModal(false)}>
          <MenuModal
            setOptionParameters={(el: any) => {
              if (
                el.label === 'Share recipe' &&
                (hexomaticUser?.pricing_package === 'FREE')
              ) {
                setShouldUpgrade(true);
                return;
              }
              setOptionParameters({ value: el.value, label: el.label, url: el.url });
            }}
            menuOption={optionForMenu}
            setShow={setOpenMenuModal}
          />
        </CustomModal>
      )}
      <Modal
        isModalOpen={shouldUpgrade}
        title="Please upgrade your plan to continue"
        actionBtnText="Upgrade"
        cancelBtnText="Cancel"
        actionBtnColor="warning"
        cancelBtnColor="secondary"
        onActionClick={() => {
          setShouldUpgrade(false);
          window.location.href = 'https://hexomatic.com/pricing/';
        }}
        onCancelClick={() => setShouldUpgrade(false)}
        escButtonClick={() => setShouldUpgrade(false)}
      />
    </>
  );
};

export default memo(ListItem);
