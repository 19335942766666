import { FC, useState, useEffect, useContext } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { UserContext } from 'contexts/user-context';
import { isValidEmail } from 'constants/validations';
import { GENERATE_SHARE_LINK, INVALIDATE_SHARE_LINK } from 'graphql/mutations';
import { GET_SHARED_EMAILS } from 'graphql/queries';
import { removeDublicateStrings } from 'helpers';

import Button from 'shared-components/atom/button';
import Message from 'shared-components/atom/alert-message';
import Loader from 'shared-components/atom/loader';
import ToggleBtn from 'shared-components/atom/toggle';
import Checkbox from 'shared-components/atom/checkbox';
import EmailInputList from 'shared-components/molecule/email-input-list';

import recipeIcon from 'assets/icons/recipe.svg';
import { copyLinkIcon, closeIcon, removeCircleIcon } from 'assets/svg-icons';
import './styles.scss';

interface ShareRecipeModalProps {
  onClose: (e: any) => void;
  recipeId: number;
  setIsSuccessfullyShared: (arg: any) => void;
}

const ShareRecipeModal: FC<ShareRecipeModalProps> = ({ setIsSuccessfullyShared, onClose, recipeId }) => {
  const { user } = useContext(UserContext);

  const [error, setError] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isCantShareOpen, setIsCantShareOpen] = useState(false);
  const [isValidLink, setIsValidLink] = useState(true);
  const [sendCopyToMe, setSendCopyToMe] = useState(false);
  const [shareLinkLoading, setShareLinkLoading] = useState(false);
  const [shareLinkEmailsLoading, setShareLinkEmailsLoading] = useState(false);
  const [linkInvalidationLoading, setLinkInvalidationLoading] = useState(false);

  const [emailsArray, setEmailsArray] = useState<string[]>([]);
  const [sharedEmailsArray, setSharedEmailsArray] = useState<string[]>([]);
  const [cantShareEmailList, setCantShareEmailList] = useState<string[]>([]);
  const [fetchedEmailsArray, setFetchedEmailsArray] = useState<string[]>([]);

  const [inputText, setInputText] = useState('');
  const [errorTxt, setErrorTxt] = useState('');

  const { data: sharedUserEmails, loading: sharedUserEmailsLoading } = useQuery(GET_SHARED_EMAILS, {
    fetchPolicy: 'no-cache',
    variables: {
      settings: {
        recipeId,
      },
    },
  });

  const [deleteUserEmail, { loading: deleteEmailLoading }] = useMutation(INVALIDATE_SHARE_LINK);
  const [getShareLink] = useMutation(GENERATE_SHARE_LINK);

  useEffect(() => {
    if (sharedUserEmails?.HexomaticScrapingRecipe?.getEmailsOfSharedRecipe?.emails) {
      setSharedEmailsArray(sharedUserEmails.HexomaticScrapingRecipe.getEmailsOfSharedRecipe.emails);
      setFetchedEmailsArray(sharedUserEmails.HexomaticScrapingRecipe.getEmailsOfSharedRecipe.emails);
      const isValid =
        sharedUserEmails?.HexomaticScrapingRecipe?.getEmailsOfSharedRecipe?.isShareLinkValid === null ||
        sharedUserEmails?.HexomaticScrapingRecipe?.getEmailsOfSharedRecipe?.isShareLinkValid;
      setIsValidLink(isValid);
    }
  }, [sharedUserEmails]);

  const handleDeleteEmailQuery = async (email: string): Promise<any> => {
    if (fetchedEmailsArray.includes(email)) {
      const { data } = await deleteUserEmail({
        variables: {
          settings: {
            recipeId,
            email,
          },
        },
      });

      const { error_code } = data?.HexomaticScrapingRecipeOps?.invalidateRecipeShareLink;
      if (error_code) {
        setError(true);
        if (error_code === 'LEGACY_USER_NOT_FOUND') {
          setErrorTxt('User not found.');
        }
        return;
      }
    }

    const sharedArr = sharedEmailsArray.filter((el: string) => el !== email);
    setSharedEmailsArray(sharedArr);
  };
  const handleGenerateLinkAndCopy = async (): Promise<any> => {
    setShareLinkLoading(true);
    const { data } = await getShareLink({
      variables: {
        settings: {
          recipeId,
          sendEmail: false,
        },
      },
    });

    const { link, error_code } = data?.HexomaticScrapingRecipeOps?.generateRecipeShareLink;

    if (error_code) {
      setError(true);
      setShareLinkLoading(false);
      return;
    }

    if (link) {
      await navigator?.clipboard?.writeText(link);
      setIsCopied(true);
      setShareLinkLoading(false);
    }
  };

  const handleGenerateWithEmail = async (): Promise<any> => {
    if ((isValidEmail(inputText) && inputText !== user.email) || emailsArray.length) {
      setShareLinkEmailsLoading(true);

      const emails: string[] = [...emailsArray];

      if (isValidEmail(inputText)) {
        emails.push(inputText);
      }

      setInputText('');

      if (sendCopyToMe && user.email) {
        emails.push(user.email);
      }

      const { data } = await getShareLink({
        variables: {
          settings: {
            recipeId,
            sendEmail: true,
            emails,
          },
        },
      });

      const { error_code, restrictedEmails } = data?.HexomaticScrapingRecipeOps?.generateRecipeShareLink;
      if (error_code) {
        setError(true);
        setShareLinkEmailsLoading(false);
        return;
      }

      if (restrictedEmails.length) {
        setIsCantShareOpen(true);
        setShareLinkEmailsLoading(false);
        setCantShareEmailList(restrictedEmails);
        return;
      }

      setError(false);
      setIsSuccessfullyShared(true);
      setShareLinkEmailsLoading(false);
      onClose(true);
    }
  };

  const handleInvalidateLink = async (): Promise<any> => {
    setLinkInvalidationLoading(true);
    const { data } = await deleteUserEmail({
      variables: {
        settings: {
          recipeId,
          valid: !isValidLink,
        },
      },
    });

    const { error_code } = data?.HexomaticScrapingRecipeOps?.invalidateRecipeShareLink;
    if (error_code) {
      setError(true);
      setErrorTxt('Something went wrong');
      setLinkInvalidationLoading(false);
      return;
    }
    setLinkInvalidationLoading(false);
    setIsValidLink(!isValidLink);
  };

  const handleBack = (): void => {
    const arr = removeDublicateStrings(emailsArray, cantShareEmailList);
    setEmailsArray(arr);
    setIsCantShareOpen(false);
  };

  useEffect(() => {
    if (emailsArray.length < 16) {
      setError(false);
      setErrorTxt('');
    }
  }, [emailsArray, inputText]);

  return isCantShareOpen ? (
    <div className="share-recipe-modal-main-cant-share">
      <div className="d-flex justify-content-center mt-2">
        <span className="modal-head-icon">{removeCircleIcon}</span>
      </div>
      <p className="text-center mt-3">You can not share the recipe with</p>

      <div className="d-flex mt-3 align-items-center justify-content-center flex-wrap">
        {cantShareEmailList.map((el: string) => (
          <span key={el} className="mt-1 mr-2 text-center text-truncate text-secondary">
            {el}
          </span>
        ))}
      </div>
      <div className="d-flex justify-content-center mt-4">
        <Button name="Go back" onClick={handleBack} />
      </div>
    </div>
  ) : (
    <div className="share-recipe-modal-main">
      <button className="panel__btn" onClick={onClose} type="button">
        {closeIcon}
      </button>
      <div className="d-flex align-items-center mb-4">
        <img className="mr-2" src={recipeIcon} alt="recipie icon" />
        <span>Share scraping recipe with people</span>
      </div>

      <EmailInputList
        inputText={inputText.trim()}
        setInputText={setInputText}
        emailsArray={emailsArray}
        setEmailsArray={setEmailsArray}
        setErrorTxt={setErrorTxt}
        setError={setError}
        user={user}
      />

      <div className="already-shared-emails-list">
        {(deleteEmailLoading && !linkInvalidationLoading) || sharedUserEmailsLoading ? (
          <Loader className="p-1" light />
        ) : (
          <>
            {sharedEmailsArray.map((el: string) => (
              <div className="emails-list" key={el}>
                {el}
                <span onClick={() => handleDeleteEmailQuery(el)}>{closeIcon}</span>
              </div>
            ))}
          </>
        )}
      </div>
      <div className="d-flex justify-content-between mt-4">
        <div className="d-flex align-items-center">
          <Checkbox checked={sendCopyToMe} onChange={() => setSendCopyToMe(!sendCopyToMe)} />
          <span className="ml-2">Send copy to me</span>
        </div>

        <div className="d-flex">
          {/* <Button className="mr-3" outline name="cancel" onClick={onClose} /> */}
          <Button name="send" loading={shareLinkEmailsLoading} onClick={handleGenerateWithEmail} />
        </div>
      </div>
      <hr />

      <div className="d-flex mt-4 mb-2 align-items-center justify-content-between">
        <div className="generate-link">
          <span>{shareLinkLoading || linkInvalidationLoading ? <Loader className="p-0" light /> : copyLinkIcon}</span>
          <div className="ml-2 d-flex align-items-center">
            Anyone with the link
            <ToggleBtn className="ml-2" onChange={handleInvalidateLink} checked={isValidLink} />
          </div>
        </div>
        <button className="copy-link" onClick={handleGenerateLinkAndCopy}>
          {isCopied ? 'Copied' : 'Copy link'}
        </button>
      </div>

      {error && <Message className="mt-3" type="error" text={errorTxt || 'Something went wrong.'} />}
    </div>
  );
};

export default ShareRecipeModal;
