import { FC, memo } from 'react';
import './styles.scss';

interface ISort {
  id: number;
  onClick: (e: any) => void;
  sort: {
    id: number;
    by: string;
  };
}

const Sort: FC<ISort> = ({ id, sort, onClick }) => {
  const click = () => {
    onClick({
      id,
      by: id !== sort.id ? 'DESC' : sort.by === 'DESC' ? 'ASC' : 'DESC',
    });
  };

  return (
    <span className={`sort-icon ${id === sort.id ? '' : 'sort-icon-transparent'}`} onClick={click}>
      {(id === sort.id && sort.by === 'DESC') || id !== sort.id ? (
        <span className="lnr lnr-arrow-up ml-2" />
      ) : (
        <span className="lnr lnr-arrow-down ml-2" />
      )}
    </span>
  );
};

export default memo(Sort);
