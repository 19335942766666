import { FC, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import Switcher from 'shared-components/molecule/switcher';
import Select from 'shared-components/atom/select';
import DataList from 'shared-components/atom/dataList';
import { useAutomationGlobalMethods } from 'automation-settings/hooks';
import { residential_geo_proxy_country_list } from 'constants/countries/residential-geo-proxy-countries';
import { proxyList } from 'constants/index';
import { cacheFunction } from 'helpers';
import { infoIcon } from 'assets/svg-icons';

const resultsLimitOptions = [
  { label: 'All', value: 'all' },
  { label: '1', value: 1 },
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '20', value: 20 },
];

const PhoneNumberSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [selectedMode, setSelectedMode] = useState('Standard');
  const [clicked, setClicked] = useState(false);
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);
  const [resultsLimit, setResultsLimit] = useState<{ label: string; value: number | string }>({
    label: '5',
    value: 5,
  });

  const [selectedProxy, setSelectedProxy] = useState({ label: 'Data Center', value: '' });
  const [residentialGEOCountry, setResidentialGEOCountry] = useState('');
  //memoize
  const handleResidentialCountryOptions = (residentialGEOCountry: string) => {
    return residential_geo_proxy_country_list.find(item => item.label === residentialGEOCountry)?.value;
  };
  const getMemoizeResidentialCountryOption = useRef(cacheFunction(handleResidentialCountryOptions));

  const {
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    liftingSettings,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  useEffect(() => {
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    if (dynamic_settings && fieldList.length > 0) {
      const keys = dynamic_settings.keys;
      const { key, settings } = keys[0];
      const proxy = settings?.proxy;
      if (proxy) {
        const selectedProxy = proxyList.find((val: any) => val.value === proxy.type);
        const selectedCountry = residential_geo_proxy_country_list.find(
          (country: any) => country.value === proxy.country_code,
        );
        selectedProxy && setSelectedProxy(selectedProxy);
        selectedCountry && setResidentialGEOCountry(selectedCountry.label);
      }

      const maxResults = settings ? settings.maxResults : keys[0].maxResults;
      const fast = settings && settings.fast ? 'Fast' : 'Standard';
      setSelectedMode(fast);

      if (maxResults) {
        const maxResults_selected = resultsLimitOptions.find(item => item.value === maxResults);
        maxResults_selected && setResultsLimit(maxResults_selected);
      }

      restoreSelectedSource({ key: key, fieldList: fieldList, setSelectedSource: setSelectedSource });
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  const checkFields = () => {
    if (!selectedSource) {
      return false;
    }

    return true;
  };
  useEffect(() => {
    if (fieldList?.length) {
      //its set from props
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [
          ...fieldList.map((item: { value: any }) => item.value),
          ...[...fieldList.map((item: { value: any }) => item.value), ...['_phone_numbers']],
        ],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);

  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: {
        keys: [
          {
            key: fieldList.length && selectedSource ? selectedSource.value : null,
            inputValue: null,
            settings: {
              proxy: {
                type: selectedProxy.value,
                country_code: getMemoizeResidentialCountryOption.current(residentialGEOCountry) || '',
              },
              maxResults: resultsLimit.value,
              fast: selectedMode === 'Fast',
            },
          },
        ],
      },
      outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...['_phone_numbers']],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [
    selectedSource,
    resultsLimit,
    fieldList,
    fieldListWithTypes,
    automationOutputTypes,
    selectedMode,
    selectedProxy,
    residentialGEOCountry,
  ]);

  const handleSwitcherClick = (val: string) => {
    setSelectedMode(val);
    !clicked && setClicked(true);
    setIsDirty();
  };

  const handleChangeResidentialGEOCountry = (text: string) => {
    // setError('');
    setResidentialGEOCountry(text);
    !clicked && setClicked(true);
    setIsDirty();
  };

  return (
    <Row>
      <Col md={12} className="d-flex">
        <Switcher
          leftText="Fast"
          rightText="Standard"
          handleSwitcherClick={handleSwitcherClick}
          selectedValue={selectedMode}
          hoverTextLeft="Loads pages faster but may have a lower success rate."
          // hoverTextRight="Loads pages slower but may have a higher success rate."
        />
      </Col>
      <Col lg={6} className="mb-2">
        <span className="label secondary-color">Source</span>
        <Select
          fullWidth
          options={fieldList}
          value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
          placeholder={'Select source'}
          onChange={(selectedItem: any) => {
            setSelectedSource(selectedItem);
            !clicked && setClicked(true);
            setIsDirty();
          }}
        />
      </Col>
      <Col lg={6} className="mb-2">
        <span className="label secondary-color">Result limit per domain</span>
        <Select
          fullWidth
          options={resultsLimitOptions}
          value={resultsLimit}
          placeholder={'Select limit'}
          onChange={(selectedItem: any) => {
            setResultsLimit(selectedItem);
            !clicked && setClicked(true);
            setIsDirty();
          }}
        />
      </Col>
      <Col lg={6} className="mb-2">
        <span className="label secondary-color">Proxy mode</span>
        <Select
          options={proxyList}
          value={selectedProxy}
          placeholder={'proxy mode'}
          onChange={(selectedItem: any) => {
            setSelectedProxy(selectedItem);
            !clicked && setClicked(true);
            setIsDirty();
          }}
        />
        {selectedProxy.value && (
          <div className="mt-1">
            <span className="info-icon">{infoIcon}</span>
            <span className="info-message">
              This proxy consumes {selectedProxy.value === 'premium' ? '0.12' : '0.1'} premium credits per MB
            </span>
          </div>
        )}
      </Col>

      {selectedProxy.value === 'premium' && (
        <Col lg={6} className="mb-2">
          <span className="label secondary-color">Country</span>
          <DataList
            list={residential_geo_proxy_country_list}
            value={residentialGEOCountry}
            placeholder={'Select country'}
            onChange={(text: string) => {
              handleChangeResidentialGEOCountry(text);
            }}
          />
        </Col>
      )}
    </Row>
  );
};

export default PhoneNumberSettings;
