import { FC, useState, useRef, useEffect, useContext } from 'react';
import uploadPhotoIcon from 'assets/icons/photo-camera.svg';
import xButton from 'assets/icons/x-button.svg';
import ModalComponent from 'shared-components/molecule/modal';
import Compressor from 'compressorjs';
import './styles.scss';
import { closePopupOnEsc } from 'helpers';

interface UploadImageProps {
  defaultImg?: any;
  uploadedImg: string;
  onChange: (data: any) => void;
  compressMode?: boolean;
  compressedImgSize?: number;
  compressedImgQuality?: number;
}

const UploadImage: FC<UploadImageProps> = ({
  defaultImg,
  onChange,
  uploadedImg,
  compressMode,
  compressedImgSize,
  compressedImgQuality,
}) => {
  const ref = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const [alertMsg, setAlertMsg] = useState<{ show: boolean; text: string }>({ show: false, text: '' });
  const [hideDeleteButton, setHideDeleteButton] = useState(true);

  //Close Popup on Esc button click
  useEffect(() => {
    closePopupOnEsc(() => setAlertMsg({show: false, text: ''}));
  }, []);

  useEffect(() => {
    setHideDeleteButton(false);
  }, [uploadedImg]);

  const toBase64Format = (file: any) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      onChange(reader.result);
      setLoading(false);
      setHideDeleteButton(false);
    };
  };

  const compressImg = (image: any) => {
    new Compressor(image, {
      quality: compressedImgQuality || NaN, // 0.6 can also be used, but its not recommended to go below.
      maxWidth: compressedImgSize || 768,
      success: compressedResult => {
        // compressedResult has the compressed file.
        toBase64Format(compressedResult);
      },
    });
  };

  const onUpload = async (e: any) => {
    setLoading(true);
    let file = e.target.files[0];
    if (!file) {
      setLoading(false);
      return;
    }
    if (file && file.size >= 3 * 1000 * 1000) {
      setLoading(false);
      setAlertMsg({ show: true, text: 'Maximum file size is 3MB' });
      return;
    }

    if (compressMode) {
      compressImg(file);
      return;
    }
    toBase64Format(file);
  };

  const deleteImg = () => {
    setHideDeleteButton(true);
    setLoading(false);
    onChange('');
  };

  return (
    <>
      {loading ? (
        <div className="upload-image-block">
          <div className="pulse">
            <div className="circle"></div>
          </div>
        </div>
      ) : (
        <div className="upload-image-block">
          {uploadedImg ? (
            <div className="iamge-wrp">
              <img src={uploadedImg} className="img" />
            </div>
          ) : (
            defaultImg && <img src={defaultImg} className="img" />
          )}
          <button className="upload-btn" onClick={() => ref.current.click()}>
            <img className="upload-icon" src={uploadPhotoIcon} alt="camera icon" />
          </button>
          {!hideDeleteButton && (
            <button className="delete-btn" onClick={() => deleteImg()}>
              <img className="delete-icon" src={xButton} alt="delete image icon" />
            </button>
          )}
          <input
            ref={ref}
            type="file"
            name="myImage"
            accept="image/png, image/jpeg"
            style={{ display: 'none' }}
            onChange={onUpload}
          />
        </div>
      )}
      {alertMsg.show && (
        <ModalComponent
          isModalOpen={alertMsg.show}
          text={alertMsg.text}
          actionBtnText={'OK'}
          actionBtnColor={'error'}
          onActionClick={() => setAlertMsg({ show: false, text: '' })}
          escButtonClick={() => setAlertMsg({ show: false, text: '' })}
        />
      )}
    </>
  );
};

export default UploadImage;
