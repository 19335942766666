import { FC, memo, useState } from 'react';
import WindowedSelect from 'react-windowed-select';
import Checkbox from 'shared-components/atom/checkbox';
import './styles.scss';

interface IColumn {
  outputKey: { label: string; value: string };
  outputKeyType: string;
  index: number;
  value: any;
  selectedList: any[];
  setSelectedList: any;
  type: string;
  contactOptions: any;
  organizationOptions: any;
  skippedValues: any;
  setSkippedValues: any;
}

const Column: FC<IColumn> = ({
  outputKey,
  index,
  value,
  selectedList,
  type,
  setSelectedList,
  outputKeyType,
  contactOptions,
  organizationOptions,
  skippedValues,
  setSkippedValues,
}) => {
  const [isSkip, setIsSkip] = useState(false);
  const multiFields = ['social url', 'activity', 'language', 'industry', 'phone', 'website url'];

  const handleChange = (val: any) => {
    let new_list = [...selectedList];
    if (val?.skip) {
      setSkippedValues([...skippedValues, outputKey.value]);
    } else {
      setSkippedValues(skippedValues.filter((item: any) => item !== outputKey.value));
    }

    if (val?.skip) {
      setSelectedList(
        new_list.map((item: any) => (item?.outputKey === outputKey?.value ? { ...item, skip: true } : item)),
      );
    } else {
      new_list[index] = { ...val, outputKey: outputKey.value };
      setSelectedList(new_list);
    }
    if (val?.value && isSkip) {
      setIsSkip(false);
    }
  };

  const handleChecked = () => {
    if (!isSkip && !skippedValues?.includes(outputKey.value)) {
      handleChange({ skip: true });
      setIsSkip(true);
      return;
    }
    handleChange({ skip: false });
    setIsSkip(false);
  };

  return (
    <div className={`csv-column-wrapper`}>
      <div className="csv-column-body text-left">
        <div className="d-sm-flex justify-content-between">
          <WindowedSelect
            options={type === 'crm_contact' ? contactOptions : organizationOptions}
            onChange={handleChange}
            value={value?.label && !value?.skip ? value : 'Select a field'}
            className="automation-select"
            isClearable
            placeholder="Select a field"
            isOptionDisabled={(val: any) =>
              selectedList?.find((item: any) => !multiFields.includes(item?.label) && val?.label === item?.label) &&
              value?.label !== val?.label
            }
          />
          <div className="d-flex align-items-center mt-2 mt-sm-0">
            <Checkbox checked={isSkip || skippedValues?.includes(outputKey.value)} onChange={handleChecked} />
            <span className="pl-2">Skip Column</span>
          </div>
        </div>
        <div className="first-value">{outputKey.label || ''}</div>
        <div className="second-value">type: {outputKeyType || ''}</div>
      </div>
    </div>
  );
};

export default memo(Column);
