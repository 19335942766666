import { FC, useContext } from 'react';
import { useHistory } from 'react-router';

import { blankScraping, blankWorkflow } from 'assets/svg-icons';
import Button from 'shared-components/atom/button';
import './styles.scss';
import { StateChangeContext } from 'contexts/state-change-context';

const elements: {
  name: string;
  pathBlank: string;
  pathTemplate: string;
  icon: JSX.Element;
}[] = [
  {
    name: 'Workflow',
    pathBlank: '/workflows/create',
    pathTemplate: '/public-workflows',
    icon: blankWorkflow,
  },
  {
    name: 'Scraping Recipe',
    pathBlank: '/scraping-recipes/create',
    pathTemplate: '/scraping-public-recipes',
    icon: blankScraping,
  },
];

const CreateNewWorkflowOrRecipe: FC<{ onClick: () => void }> = ({ onClick }) => {
  const { push } = useHistory();
  const { setStateChange } = useContext(StateChangeContext);

  const handleClick = (val: string) => {
    onClick();
    setStateChange({clear: true});
    push(val);
  };
  return (
    <div className="create-new-element row">
      <div className="col-12 text-center page-title mb-2">Create new</div>
      {elements.map(item => (
        <div className="col-12 col-md-6 p-1">
          <div className="create-new-card">
            <div className="main-wrapper">
              <div className="m-auto">
                <div className="create-new-icon">{item.icon}</div>
                <div className="create-new-card-name">{item.name}</div>
              </div>
            </div>

            <div className="w-100 action-btns">
              <Button
                name={'Blank'}
                onClick={() => handleClick(item.pathBlank)}
                lightPink
                className="mx-auto my-2 w-100 text-center justify-content-center"
              />
              <Button
                name={'Template'}
                onClick={() => handleClick(item.pathTemplate)}
                lightPink
                className="mx-auto w-100 text-center justify-content-center"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default CreateNewWorkflowOrRecipe;
