import { useState, useEffect, FC } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_USER_WATCH_TAGS } from 'graphql/queries';
import { CREATE_USER_WATCH_TAGS, DELETE_USER_WATCH_TAGS, UPDATE_USER_WATCH_TAGS } from 'graphql/mutations';
import { ChromePicker } from 'react-color';
import ModalComponent from 'shared-components/molecule/modal';
import Tag from './tag/tag';
// import Message from '../../../shared/components/messages/index';
import { hexToRgbA } from 'helpers/index';
// import Loader from '';
import Loader from 'shared-components/atom/loader';
import Button from 'shared-components/atom/button';
import Message from 'shared-components/atom/messages';
import './styles.scss';

const colors = [
  '#F35D5D',
  '#E5A544',
  '#FD8B39',
  '#0D978F',
  '#0FBA72',
  '#0AB0F7',
  '#0A69F7',
  '#D60AF7',
  '#F651B4',
  '#7E35F6',
];

const Tags: FC<any> = ({
  propSelectedTag,
  handleTagSelect,
  propSelectedTags,
  propSetSelectedTags,
  propSetSelectedTag,
  setShowTagModal,
  fromList,
  setIsTagsChange,
}) => {
  const [tagsList, setTagsList] = useState<string[] | []>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [tagName, setTagName] = useState('');
  const [tagColor, setTagColor] = useState(colors[0]);
  const [tagId, setTagId] = useState('');
  const [refetchLoading, setRefetchLoading] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const [updateColorPickerOpen, setUpdateColorPickerOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState<any>(null);
  const [insightAction, setInsightAction] = useState(false);

  const { data: userTags, loading, refetch } = useQuery(GET_USER_WATCH_TAGS);
  const [createUserTag, { loading: createLoading }] = useMutation(CREATE_USER_WATCH_TAGS);
  const [updateUserTag, { loading: updateLoading }] = useMutation(UPDATE_USER_WATCH_TAGS);
  const [deleteUserTag, { loading: deleteLoading }] = useMutation(DELETE_USER_WATCH_TAGS);

  useEffect(() => {}, [tagId]);

  const onSelectTag = (tag: any) => {
    const findedTag = propSelectedTags ? propSelectedTags.find((item: { name: any }) => item.name === tag.name) : false;
    if (!findedTag) {
      handleTagSelect(tag.id);
      setShowTagModal(false);
    } else {
      setErrorMessage('this tag already exists in property tags');
    }
  };

  useEffect(() => {
    if (propSelectedTag) {
      setSelectedTag(propSelectedTag);
    }
  }, [propSelectedTag]);

  useEffect(() => {
    if (userTags?.HexomaticTag?.getUserHexomaticTags?.tags) {
      const { tags } = userTags.HexomaticTag.getUserHexomaticTags;
      setTagsList(tags);
    }
  }, [userTags]);

  const handleCreate = async () => {
    setSelectedTag(null);
    setColorPickerOpen(false);
    setTagName('');
    setErrorMessage('');
    setTagColor('#FB6F6F');
    if (!tagName || !tagName.trim()) {
      setErrorMessage('Please enter tag name');
    } else {
      const { data } = await createUserTag({
        variables: {
          settings: {
            name: tagName.trim(),
            color: tagColor,
          },
        },
      });
      const { error } = data && data.HexomaticTagOps && data.HexomaticTagOps.createUserHexomaticTag;
      if (error) {
        setErrorMessage(error);
      } else {
        setIsTagsChange && setIsTagsChange(true);
        setRefetchLoading(true);
        await refetch();
        const id = data.HexomaticTagOps.createUserHexomaticTag.hexomatic_tag;
        const newTag = { id: id, name: tagName.trim(), color: tagColor };
        propSetSelectedTags([...propSelectedTags, newTag]);
        handleTagSelect(id, newTag);
        setRefetchLoading(false);
        propSetSelectedTag(false);
        setShowTagModal(false);
      }
    }
  };

  const handleUpdate = async () => {
    if (!tagName || !tagName.trim()) {
      setErrorMessage('Please enter tag name');
      return;
    }
    const { data } = await updateUserTag({
      variables: {
        settings: {
          hexomatic_tag_id: selectedTag.id,
          name: selectedTag.name,
          color: selectedTag.color,
        },
      },
    });
    const { error } = data && data.HexomaticTagOps && data.HexomaticTagOps.updateUserHexomaticTag;
    if (error) {
      setErrorMessage(error);
    } else {
      setRefetchLoading(true);
      setIsTagsChange && setIsTagsChange(true);
      await refetch();
      if (!fromList) {
        propSetSelectedTags([
          ...propSelectedTags.map((tag: any) => {
            if (tag.id === selectedTag.id) {
              return { id: tag.id, name: tagName.trim(), color: tagColor };
            }
            return tag;
          }),
        ]);
      }
      setRefetchLoading(false);
      setSelectedTag(null);
      propSetSelectedTag(false);
      if (insightAction) {
        setInsightAction(false);
      } else {
        setShowTagModal(false);
      }
    }
  };

  const handleDelete = async () => {
    setSelectedTag(null);
    setErrorMessage('');
    setModalOpened(false);
    const { data } = await deleteUserTag({
      variables: {
        settings: {
          hexomatic_tag_id: tagId,
        },
      },
    });
    const { error } = data && data.HexomaticTagOps && data.HexomaticTagOps.deleteUserHexomaticTag;
    if (error) {
      setErrorMessage(error);
    } else {
      setIsTagsChange && setIsTagsChange(true);
      setRefetchLoading(false);
      await refetch();
      setRefetchLoading(false);
    }
  };

  useEffect(() => {
    if (selectedTag && selectedTag.id) {
      setTagColor(selectedTag.color);
      setTagName(selectedTag.name);
    } else {
      setTagColor(colors[0]);
      setTagName('');
      propSetSelectedTag(false);
    }
  }, [selectedTag]);

  // const handleTagName = (value: any): void => {
  //   if (value.length <= 119) {
  //     setTagName(value.trim());
  //   }
  // };

  return (
    <>
      <div className="tags-settings modal-tags">
        <div className="tag-name-block">
          <span className="label">Tag name</span>
          <input
            autoCapitalize="none"
            type="text"
            className="tag-input"
            placeholder="Add tag name..."
            value={tagName}
            onChange={e => {
              if (e.target.value && e.target.value.length > 64) return;
              setErrorMessage('');
              setTagName(e.target.value);
              selectedTag && setSelectedTag({ ...selectedTag, name: e.target.value });
            }}
          />
        </div>
        <div className="color-picker-block">
          <span className="label">Color</span>
          <div className="wrapper">
            <div className="colors">
              {colors.map(color => (
                <div
                  key={color}
                  className="color"
                  style={
                    tagColor === color ? { background: 'white', border: `5px solid ${color}` } : { background: color }
                  }
                  onClick={() => {
                    setTagColor(color);
                    selectedTag && setSelectedTag({ ...selectedTag, color: color });
                  }}
                ></div>
              ))}
            </div>
            <div className="">
              <div className="color-picker-wrapper" onClick={() => setUpdateColorPickerOpen(!updateColorPickerOpen)}>
                <button className="picked-color" style={{ position: 'relative' }}>
                  <div className="d-flex" onClick={() => setUpdateColorPickerOpen(!updateColorPickerOpen)}>
                    <p className="color-picker__color" style={{ width: '80px', margin: 'auto' }}>
                      <span className="sign">#</span>
                      {selectedTag
                        ? selectedTag.color.slice(1, selectedTag.color.length)
                        : tagColor.slice(1, tagColor.length)}
                    </p>
                  </div>
                  {updateColorPickerOpen && (
                    <div style={{ position: 'absolute', top: '50px', left: '-30px', zIndex: 100 }}>
                      <ChromePicker
                        disableAlpha
                        color={selectedTag ? selectedTag.color : tagColor}
                        onChange={color =>
                          selectedTag ? setSelectedTag({ ...selectedTag, color: color.hex }) : setTagColor(color.hex)
                        }
                        onChangeComplete={color =>
                          selectedTag ? setSelectedTag({ ...selectedTag, color: color.hex }) : setTagColor(color.hex)
                        }
                      />
                    </div>
                  )}
                </button>
              </div>
            </div>
            {/* {selectedTag ? (
              <>
                <button style={{ marginRight: '10px' }} className="button" onClick={handleUpdate}>
                  save
                </button>
                <button className="button" onClick={() => setSelectedTag(null)}>
                  cancel
                </button>
              </>
            ) : (
              <button className="button" onClick={handleCreate} disabled={createLoading}>
                {(loading || createLoading || refetchLoading) && <Loader light={true} />} Add
              </button>
            )} */}
            {selectedTag ? (
              <>
                <Button name="Save" onClick={handleUpdate} />
                &nbsp;
                <Button name="Cancel" onClick={() => setSelectedTag(null)} />
              </>
            ) : (
              <Button name="Add" onClick={handleCreate} />
            )}
          </div>
        </div>
        <div className="tags-block">
          <span className="label">List of tags</span>

          <div className="badge-wrapper">
            {tagsList && tagsList.length > 0 ? (
              //@ts-ignore
              tagsList.map((item: any) => {
                return (
                  (!selectedTag || selectedTag.id !== item.id) && (
                    <div
                      key={item.name}
                      className="mb-2"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        setErrorMessage('');
                        onSelectTag(item);
                      }}
                    >
                      <Tag
                        key={item.name}
                        name={item.name}
                        color={item.color}
                        bgColor={hexToRgbA(item.color, 0.1)}
                        onClick={() => {
                          setTagId(item.id);
                          setModalOpened(true);
                        }}
                        onTagClick={() => {
                          setInsightAction(true);
                          setSelectedTag(item);
                        }}
                        settings
                        disabled={selectedTag}
                      />
                    </div>
                  )
                );
              })
            ) : (
              <p className="text-center m-2 primary-color">
                <small>Nothing to show</small>
              </p>
            )}
          </div>
        </div>
        {errorMessage && <Message type="error" text={errorMessage} />}
        <ModalComponent
          isModalOpen={modalOpened}
          text={`Confirm deleting the tag`}
          actionBtnText={'Delete'}
          cancelBtnText={'Cancel'}
          actionBtnColor={'danger'}
          cancelBtnColor={'secondary'}
          onActionClick={handleDelete}
          onCancelClick={() => setModalOpened(false)}
          escButtonClick={() => setModalOpened(false)}
        />
      </div>
    </>
  );
};

export default Tags;
