import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
import { useAutomationGlobalMethods } from '../hooks';
import { AutomationSettingsFactoryTypesProps } from '../types';

const RecipesGeneralSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  selectedRecipes,
  automationIndex,
  setCompletedFields,
  setAutomationSendSettings,
  id,
}) => {
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);
  const [fieldListWithTypes, setFieldListWithTypes] = useState<{ label: string; value: string }[]>([]);
  const [clicked, setClicked] = useState(false);
  const [selectedSource, setSelectedSource] = useState<any>([]);
  const [outputFields, setOutputFields] = useState<{ label: string; value: string }[]>([]);
  const [outputFieldsWithTypes, setOutputFieldsWithTypes] = useState<{ label: string; value: string }[]>([]);

  const {
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    getDynamicAndIntegrationsSettings,
    liftingSettings,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if ((selectedSource && Array.isArray(selectedSource) && !selectedSource?.length) || !selectedSource) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings && fieldList.length) {
      const { keys } = dynamic_settings;
      if (keys && keys.length) {
        restoreSelectedSource({ key: keys[0].key, fieldList: fieldList, setSelectedSource: setSelectedSource });
      }
    }
  }, [fieldList]);

  //getting previous outputs
  useEffect(() => {
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    let optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    let currentRecipe =
      selectedRecipes && selectedRecipes.find((item: { recipe: { id: any } }) => item.recipe.id === id);
    const outputOptions: any = [];
    const outputOptionsWithTypes: any = {};
    if (currentRecipe?.recipe) {
      let data = JSON.parse(currentRecipe.recipe.actions);
      while (typeof data === 'string') {
        data = JSON.parse(data);
      }
      data &&
        data.pages &&
        data.pages[0] &&
        data.pages[0].elements.forEach((element: { property: any; type: any }) => {
          element.property && outputOptions.push({ label: element.property, value: element.property });
          element.property &&
            (outputOptionsWithTypes[element.property] =
              element.type === 'text' || element.type === 'HTML tag' || element.type === 'date'
                ? 'text'
                : element.type === 'img_src' || element.type === 'a_href'
                ? 'url'
                : element.type === 'number'
                ? 'number'
                : element.type === 'email'
                ? 'email address'
                : element.type === 'phone'
                ? 'phone number'
                : 'any');
        });
    }

    setClicked(true);
    const filteredOptions = options.filter(
      (item: any) =>
        (optionsWithTypes &&
          optionsWithTypes[item.value] &&
          (optionsWithTypes[item.value] === 'any' || optionsWithTypes[item.value] === 'url')) ||
        !(optionsWithTypes && optionsWithTypes[item.value]),
    );

    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    JSON.stringify({ ...optionsWithTypes, ...outputOptionsWithTypes }) !== JSON.stringify(outputFieldsWithTypes) && (changed = true)

    const update = filteredOptions.length !== fieldList.length || changed;
    if (update) {
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
  
      setOutputFields([...options, ...outputOptions]);
      setOutputFieldsWithTypes({ ...optionsWithTypes, ...outputOptionsWithTypes });

      if (dynamic_settings) {
        const { keys } = dynamic_settings;
        if (keys && keys.length) {
          restoreSelectedSource({ key: keys[0].key, fieldList: filteredOptions, setSelectedSource: setSelectedSource });
        }
      } else {
        setSelectedSource([])
      }
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (outputFields.length) {
      liftingSettings({
        outputKeysData: ['__url', ...outputFields.map((item: { label: any; value: string }) => item.value)],
        outputKeysDataWithTypes: { __url: 'url', ...outputFieldsWithTypes },
      });
    }
  }, [fieldList, outputFieldsWithTypes, outputFields]);

  //lifting data
  useEffect(() => {
    if (automationIndex === 0) {
      liftingSettings({
        dynamicSettings: 'null',
      });
    }
    automationIndex !== 0 && setCompletedFields({ index: automationIndex, checked: checkFields() });
    if (!clicked) return;
    setClicked(false);

    liftingSettings({
      dynamicSettings: selectedSource.value ? {
        keys: [{ key: selectedSource && selectedSource.value }],
      } : 'null',
      outputKeysData: ['__url', ...outputFields.map((item: { label: any; value: string }) => item.value)],
      outputKeysDataWithTypes: { __url: 'url', ...outputFieldsWithTypes },
    });
  }, [selectedSource, automationIndex]);

  if (automationIndex === 0) {
    return null;
  }
  return (
    <div className="">
      <Row className="mb-4">
        <Col lg={6} className="">
          <span className="label secondary-color">Source</span>
          <Select
            options={fieldList}
            value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
            placeholder={'Select source'}
            onChange={(selectedItem: any) => {
              setSelectedSource(selectedItem);
              !clicked && setClicked(true);
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default React.memo(RecipesGeneralSettings);
