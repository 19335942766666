import { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import CustomMultiSelect from 'shared-components/molecule/multiselect';

const userEndpointsOptions = [
  { label: 'user by id', value: 'user_by_id' },
  { label: 'user by usename', value: 'user_by_usename' },
];

const fieldsOptions = [
  { label: 'created at', value: 'created_at' },
  { label: 'description', value: 'description' },
  { label: 'id', value: 'id' },
  { label: 'location', value: 'location' },
  { label: 'name', value: 'name' },
  { label: 'pinned tweet id', value: 'pinned_tweet_id' },
  { label: 'profile image url', value: 'profile_image_url' },
  { label: 'protected', value: 'protected' },
  { label: 'url', value: 'url' },
  { label: 'username', value: 'username' },
  { label: 'verified', value: 'verified' },
  { label: 'withheld', value: 'withheld' },
  { label: 'followers count', value: 'followers_count' },
  { label: 'following count', value: 'following_count' },
  { label: 'tweet count', value: 'tweet_count' },
  { label: 'listed count', value: 'listed_count' },
];

const TwiterSocialShareMetricsSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);
  const [clicked, setClicked] = useState(false);
  const [selectedSource, setSelectedSource] = useState<{ label: string; value: string } | null>(null);
  const [selectedEndpoint, setSelectedEndpoint] = useState<{ label: string; value: string } | null>(null);
  const [selectedFields, setSelectedFields] = useState<{ label: string; value: string }[]>([]);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const {
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    liftingSettings,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (!selectedSource || selectedFields?.length === 0 || !selectedEndpoint) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings && fieldList.length > 0) {
      const { key, settings } = dynamic_settings.keys[0];
      if (settings) {
        const { endpoint, fields } = settings;
        const findedEndpointOption = userEndpointsOptions.find((item: any) => item.value === endpoint);
        // const findedFieldOption = fieldsOptions.find((item: any) => item.value === fields);
        const findedFieldOption = fields.map((selectedField: string) => {
          const finded = fieldsOptions.find(item => item.value === selectedField || item.label === selectedField);
          return finded ? finded : { label: selectedField, value: selectedField };
        });
        findedEndpointOption && setSelectedEndpoint(findedEndpointOption);
        findedFieldOption && setSelectedFields(findedFieldOption);
      }
      restoreSelectedSource({ key: key, fieldList: fieldList, setSelectedSource: setSelectedSource });
    }
  }, [fieldList]);

  //recipe fields or output fields
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked || selectedFields.length === 0) return;
      let outputs: any = selectedFields.map(field => `_twitter_${field.value}`);
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};

      liftingSettings({
        outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...outputs],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, selectedFields, fieldListWithTypes, automationOutputTypes]);

  //lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);

    let outputs: any = selectedFields.map(field => `_twitter_${field.value}`);
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: {
        keys: [
          {
            key: fieldList.length && selectedSource ? selectedSource.value : null,
            inputValue: null,
            settings: {
              endpoint: selectedEndpoint?.value,
              fields: selectedFields.map(item => item.value),
            },
          },
        ],
      },

      outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...outputs],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [selectedSource, selectedEndpoint, selectedFields, fieldList, fieldListWithTypes, automationOutputTypes]);

  return (
    <div className="discover-whois-settings">
      <Row>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Source</span>
          <Select
            fullWidth
            options={fieldList}
            value={selectedSource}
            placeholder={'Select source'}
            onChange={(selectedItem: any) => {
              setSelectedSource(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Endpoints</span>
          <Select
            fullWidth
            options={userEndpointsOptions}
            value={selectedEndpoint}
            placeholder={'Select endpoint'}
            onChange={(selectedItem: any) => {
              setSelectedEndpoint(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Fields</span>
          <CustomMultiSelect
            options={fieldsOptions}
            value={selectedFields}
            onChange={(sel: any) => {
              setSelectedFields(sel);
              !clicked && setClicked(true);
              setIsDirty();
            }}
            labelledBy="Select"
          />
        </Col>
      </Row>
    </div>
  );
};

export default TwiterSocialShareMetricsSettings;
