// Apollo client modules
import { ApolloClient } from 'apollo-client';
import { ApolloLink, split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache } from 'apollo-cache-inmemory';

// HOSTS
import { HOST } from '../constants/host';
import { getAccessToken, getRawCookie } from '../helpers';

const { URL, WS } = HOST.APP_API;

/**
 * Creating the httpLink that will connect your ApolloClient
 * instance with the GraphQL API.
 */
const httpLink = createHttpLink({
  uri: URL,
  credentials: 'include',
});

/**
 * Getting authentication token from local storage.
 * If it exists, returns  the headers to the context.
 * And `httpLink` can read them
 */
const authLink = setContext((_, { headers = {} }) => ({
  headers: {
    ...headers,
    authorization: getAccessToken() || '',
    device_key: getRawCookie('device_key') || '',
    hxroot: getRawCookie('root') || '',
  },
}));

const wsLink = new WebSocketLink({
  uri: WS,
  options: {
    reconnect: true,
    connectionParams: () => ({
      token: getAccessToken() || '',
      device_key: getRawCookie('device_key') || '',
    }),
  },
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

const ErrorHandler = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    );
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

/**
 * Initializing ApolloClient by passing settings
 * Object
 */
const client = new ApolloClient({
  link: ApolloLink.from([ErrorHandler, authLink, link]),
  // cache: new InMemoryCache(),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  connectToDevTools: true,
});

export default client;
