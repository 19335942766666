import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
//import { staticValue } from '../../constants';
import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import TextArea from 'shared-components/atom/texatarea';

import './styles.scss';

const HumanAutomation: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
}) => {
  const [fieldList, setFieldList] = useState<any>([]);
  const [clicked, setClicked] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedSource, setSelectedSource] = useState<any>([]);
  const [price, setPrice] = useState(2.5);

  const {
    liftingSettings,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (fieldList?.length === 0 || !searchKeyword) return false;
    return true;
  };

  useEffect(() => {
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const { keys } = dynamic_settings;
      const { settings } = keys[0];
      const keywords = settings ? settings.keywords : keys[0].keywords;
      const price = settings ? settings.price : keys[0].price;
      setSearchKeyword(keywords.join(', '));
      setPrice(price);
    }
  }, []);

  //recipe
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const key = dynamic_settings.keys[0].key;
      restoreSelectedSource({ key: key, fieldList: options, setSelectedSource: setSelectedSource });
    }
    setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    liftingSettings({
      outputKeysData: [
        ...fieldList.map((item: { value: any }) => item.value),
        '_human_validation_question',
        '_human_validation_answer',
      ],
    });
  }, [fieldList]);

  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);
    let localSettings = {
      keys: [
        {
          inputValue: null,
          key: fieldList.length && selectedSource ? selectedSource.value : null,
          settings: {
            keywords: searchKeyword.split(',').map(item => item.trim()),
            price,
          },
        },
      ],
    };
    liftingSettings({
      dynamicSettings: localSettings,
    });
  }, [searchKeyword, selectedSource, price]);

  const handleRadioChange = (val: number) => {
    setPrice(val);
    !clicked && setClicked(true);
  };

  return (
    <div>
      <Row>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Select source</span>
          <Select
            options={fieldList}
            value={selectedSource}
            placeholder={'Select source'}
            onChange={(selectedItem: any) => {
              setSelectedSource(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
        {/*<Col lg={6} className="mb-4">
          <span className="label secondary-color">Choose reward per (premium credits)</span>
          <div className="d-flex align-items-center h-75">
            <Radio className="white" label="1." value="1" checked={price === 1} onClick={() => handleRadioChange(1)} />
            <Radio className="white" label="2." value="2" checked={price === 2} onClick={() => handleRadioChange(2)} />
            <Radio className="white" label="3." value="3" checked={price === 3} onClick={() => handleRadioChange(3)} />
          </div>
          </Col>*/}
      </Row>
      <Row>
        <Col xl={12}>
          <span className="label secondary-color">Question</span>
          <TextArea
            value={searchKeyword}
            onChange={(e: any) => {
              setSearchKeyword(e.target.value);
              !clicked && setClicked(true);
              setIsDirty();
            }}
            placeholder="What do you want to ask?"
          />
        </Col>
      </Row>
    </div>
  );
};
export default React.memo(HumanAutomation);
