import { closePopupOnEsc } from 'helpers';
import React, { useState, useEffect, useContext } from 'react';
import { createPortal } from 'react-dom';
import { closeIcon } from '../../../assets/svg-icons';
import { ThemeContext } from '../../../contexts/theme-context';

import './styles.scss';

const body = document.getElementsByTagName('body')[0];
const el = document.createElement('div');
el.setAttribute('id', 'modal-container');

const Popup: React.FC<any> = React.memo(
  ({
    setShowPopup,
    createNote,
    escButtonClick,
    children,
    ShowPopup,
    title,
    leftIcon,
    action,
    longModal,
    defaultTextTitle,
    hideModalBody,
    className,
  }) => {
    const [element, setElement] = useState<HTMLDivElement>(el);
    const { theme } = useContext(ThemeContext);
    useEffect(() => {
      if (!ShowPopup) return;

      body.appendChild(element);
      document.body.style.overflow = 'hidden';
      return () => {
        const getEl = document.getElementById('modal-container');
        getEl && body.removeChild(getEl);
        document.body.style.overflow = 'unset';
      };
    }, [ShowPopup]);

    //Close Popup on Esc button click
    useEffect(() => {
      ShowPopup && closePopupOnEsc(() => escButtonClick());
    }, [ShowPopup]);

    return ShowPopup
      ? createPortal(
          <>
            <div id="modal-container" className={`overlay theme-${theme}`}>
              <div
                className={`modal-container theme-${theme} ${longModal ? 'long-modal' : ''} ${
                  className ? className : ''
                }`}
              >
                <div className="modal__body" style={{ justifyContent: 'start' }}>
                  {!hideModalBody && (
                    <>
                      {leftIcon && (
                        <span className="modal-left-icon" onClick={action}>
                          {leftIcon}
                        </span>
                      )}
                      <h4
                        className={`modal-title ${theme === 'light' ? 'black' : 'white'} ${
                          defaultTextTitle ? 'default-text' : ''
                        }`}
                      >
                        {title}
                      </h4>
                      <hr className="modal-hr" />
                      <div className="panel__btns">
                        <button className="panel__btn" onClick={() => setShowPopup(false)} type="button">
                          <span className="modal-close">{closeIcon}</span>
                        </button>
                      </div>
                    </>
                  )}
                </div>
                <div className="popup-alert">{children}</div>
              </div>
            </div>
          </>,
          element,
        )
      : null;
  },
);

export default Popup;
