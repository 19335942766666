import { FC, useEffect, useState, useContext } from 'react';
import { createPortal } from 'react-dom';
import { Row } from 'react-bootstrap';
import { ThemeContext } from 'contexts/theme-context';
import Button from 'shared-components/atom/button';
import Input from 'shared-components/atom/input';
import Message from 'shared-components/atom/alert-message';
import TextArea from 'shared-components/atom/texatarea';
import Checkbox from 'shared-components/atom/checkbox';
import CustomSelect from 'shared-components/atom/select';

import CustomElement from './customElement';
import Radio from 'shared-components/atom/radio-button';
import { isValidUrl, getDomain, isValidDomain } from 'constants/validations';

import {
  longArrowLeft,
  closeIcon,
  actionClickIcon,
  actionCookieIcon,
  actionDelayIcon,
  actionTypeIcon,
  scrollIcon,
  selectAllIcon,
  selectSingleIcon,
  paginationIcon,
  actionRefreshIcon,
  storageIcon,
  actionBLockIcon,
  eyeIcon,
  urlIcon,
  loadMoreIcon,
  actionSelectIcon,
  enterIcon,
} from '../../../assets/svg-icons';
import './styles.scss';
import '../../../i18n';
import Select from 'shared-components/atom/export-select';
import { closePopupOnEsc } from 'helpers';

const ElementClickModal: FC<any> = ({
  isModalOpen,
  onCloseModal,
  handleOnlyThis,
  handleAll,
  handlePagination,
  handleAdvancedPagination,
  handleTypeAction,
  handleClickAction,
  handleActionStatic,
  showAllActions,
  browserMode,
  notAllow,
  handleBlockElement,
  notAllowCookies,
  advancedLimit15,
  address,
  handleLoadMore,
  options,
  selectOptionIsUsed,
  lastEl,
}) => {
  //------------------data----------------------------//
  const body = document.getElementsByTagName('body')[0];
  const el = document.createElement('div');
  el.setAttribute('id', 'popup');

  const delayoptions = [
    {
      title: '5 seconds',
      value: '5 seconds',
    },
    {
      title: '10 seconds',
      value: '10 seconds',
    },
    {
      title: '15 seconds',
      value: '15 seconds',
    },
    {
      title: '30 seconds',
      value: '30 seconds',
    },
    // {
    //   title: '60 seconds',
    //   value: '60 seconds',
    // },
  ];

  //-------------------------------------------------//

  //-----------------Context-------------------------//
  const { theme } = useContext(ThemeContext);
  //-------------------------------------------------//
  //-------------------State-------------------------//
  const [element] = useState<HTMLDivElement>(el);
  const [typeModalOpen, setTypeModalOpen] = useState(false);
  const [paginationModalOpen, setPaginationModalOpen] = useState(false);
  const [advancedPaginationModalOpen, setAdvancedPaginationModalOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState('');
  const [pageFromNumber, setPageFromNumber] = useState('');
  const [pageToNumber, setPageToNumber] = useState('');
  const [typeText, setTypeText] = useState('');
  const [paginationSampleLink, setPaginationSampleLink] = useState('');
  const [dynamicPageinationPath, setDynamicPageinationPath] = useState('');
  const [error, setError] = useState('');
  const [URLInputError, setURLInputError] = useState(false);
  const [fromDateError, setFromDateError] = useState(false);
  const [toDateError, setToDateError] = useState(false);
  const [delay, setDelay] = useState('5 seconds');
  const [scroll, setScroll] = useState('');
  const [cookie, setCookie] = useState({ name: '', value: '', domain: '' });
  const [storage, setStorage] = useState({ name: '', value: '' });

  const [delayModal, setDelayModal] = useState(false);
  const [scrollModal, setScrollModal] = useState(false);
  const [cookieModal, setCookieModal] = useState(false);
  const [storageModal, setStorageModal] = useState(false);
  const [clickModal, setClickModal] = useState(false);
  const [loadMoreModal, setLoadMoreModal] = useState(false);
  const [urlModal, setUrlModal] = useState(false);
  const [blockModal, setBlockModal] = useState(false);
  const [selectModal, setSelectModal] = useState(false);
  const [enterModal, setEnterModal] = useState(false);

  const [isPassword, setIsPassword] = useState(false);
  const [paginationType, setPaginationType] = useState({
    number: true,
    letter: false,
    dynamic: false,
  });
  const [showPassword, setShowPassword] = useState(false);

  const [customPath, setCustomPath] = useState('');
  const [custom, setCustom] = useState(false);
  const [step, setStep] = useState('');
  const [clickCount, setClickCount] = useState('');

  const [selectValue, setSelectValue] = useState({ label: '', value: '' });

  const [isFullPageScroll, setIsFullPageScroll] = useState(true);
  //-------------------------------------------------//

  //--------------------useEffects-------------------//
  useEffect(() => {
    if (!isModalOpen) return;

    body.appendChild(element);
    return () => {
      const getEl = document.getElementById('popup');
      getEl && body.removeChild(getEl);
      clearState();
      //setOptions([]);
    };
  }, [isModalOpen]);

  useEffect(() => {
    setSelectValue(options[0] ? options[0] : { label: '', value: '' });
  }, [options]);

  useEffect(() => {
    if (paginationType.dynamic) {
      setDynamicPageinationPath(lastEl);
    } else {
      setDynamicPageinationPath('');
    }
  }, [lastEl, paginationType]);

  //-------------------------------------------------//
  //--------------------Functions-------------------//
  const clearState = () => {
    setPageNumber('');
    setCookie({ name: '', value: '', domain: '' });
    setStorage({ name: '', value: '' });
    setDelay('5 seconds');
    setScroll('');
    setPageFromNumber('');
    setPageToNumber('');
    setTypeText('');
    setPaginationSampleLink('');
    setError('');
    setStep('');
    setClickCount('');
    setURLInputError(false);
    setFromDateError(false);
    setToDateError(false);
    setIsPassword(false);
    setPaginationType({
      number: true,
      letter: false,
      dynamic: false,
    });
    setShowPassword(false);
    setCustom(false);
    setCustomPath('');
    setTypeModalOpen(false);
    setPaginationModalOpen(false);
    setCookieModal(false);
    setStorageModal(false);
    setDelayModal(false);
    setScrollModal(false);
    setClickModal(false);
    setBlockModal(false);
    setUrlModal(false);
    setLoadMoreModal(false);
    setSelectModal(false);
    setIsFullPageScroll(true);
    setEnterModal(false);
  };

  const handelClick = () => {
    clearState();
  };

  const backTo = () => {
    setAdvancedPaginationModalOpen(false);
    clearState();
  };

  const handleClose = () => {
    backTo();
    onCloseModal();
  };

  const advancedPaginationaction = () => {
    setURLInputError(false);
    setFromDateError(false);
    setToDateError(false);
    if (!pageFromNumber || !pageToNumber || (!paginationSampleLink && !dynamicPageinationPath)) {
      setError('Please fill the requried fields');
      !paginationSampleLink && setURLInputError(true);
      !pageFromNumber && setFromDateError(true);
      !pageToNumber && setToDateError(true);
    } else if (!paginationType.dynamic && paginationSampleLink.indexOf('%page%') === -1) {
      setURLInputError(true);
      setError('Entered URL should contain %page%');
    } else if ((paginationType.number || paginationType.dynamic) && +pageFromNumber > +pageToNumber) {
      setFromDateError(true);
      setToDateError(true);
      setError('TO value cannot be smaller than FROM value');
    } else if (paginationType.letter && pageFromNumber.charCodeAt(0) > pageToNumber.charCodeAt(0)) {
      setFromDateError(true);
      setToDateError(true);
      setError('TO value cannot be smaller than FROM value');
    } else {
      handelClick();
      handleAdvancedPagination(
        pageFromNumber,
        pageToNumber,
        paginationSampleLink,
        paginationType.number ? step : '',
        paginationType.dynamic ? lastEl : '',
      );
      setAdvancedPaginationModalOpen(false);
    }
  };
  const clickAction = () => {
    if (!customPath.trim()) {
      setError('HTML XPath/selector is required');
      return;
    }
    handleClose();
    handleClickAction(customPath, clickCount);
  };
  const blockAction = () => {
    if (!customPath) {
      setError('HTML XPath/selector is required');
      return;
    }
    handleClose();
    handleBlockElement(customPath);
  };
  const typeAction = () => {
    if (!typeText && custom && !customPath.trim()) {
      setError('All fields are requried');
      return;
    }
    if (!typeText) {
      setError('Text is required');
      return;
    }
    if (custom && !customPath.trim()) {
      setError('HTML XPath/selector is required');
      return;
    }
    handleClose();
    handleTypeAction(typeText, customPath);
  };

  const loadMoreAction = () => {
    if (!clickCount) {
      setError('Click count is required');
      return;
    }
    if (custom && !customPath) {
      setError('HTML XPath/selector is required');
      return;
    }
    handleClose();
    handleLoadMore(clickCount, customPath);
  };
  const goToAction = () => {
    if (!customPath.trim()) {
      setError('URL is required');
      return;
    }
    if (!isValidUrl(customPath) && !isValidDomain(customPath)) {
      setError('URL is not valid');
      return;
    }
    if (getDomain(customPath) !== getDomain(address)) {
      setError('Please provide an internal URL');
      return;
    }
    handleClose();
    handleGoToAction(customPath);
  };
  const handleSelectAction = () => {
    if (!selectValue) return;
    handleClickAction('', selectValue.value);
  };
  const handleProceed = () => {
    if (paginationModalOpen)
      handlePagination(paginationType.letter ? typeText : pageNumber, paginationType.number ? step || '1' : '');
    else if (advancedPaginationModalOpen) advancedPaginationaction();
    else if (delayModal) handleDelayAction(delay.replace(' seconds', ''));
    else if (scrollModal) handleScrollAction(scroll);
    else if (cookieModal) handleCookieAction(cookie);
    else if (storageModal) handleStorageAction(storage);
    else if (clickModal) clickAction();
    else if (blockModal) blockAction();
    else if (urlModal) goToAction();
    else if (loadMoreModal) loadMoreAction();
    else if (selectModal) handleSelectAction();
    else if (enterModal) handleEnterAction(customPath);
    else typeAction();
    if (
      !advancedPaginationModalOpen &&
      !clickModal &&
      !blockModal &&
      !urlModal &&
      !typeModalOpen &&
      !loadMoreModal &&
      !selectModal &&
      !scrollModal &&
      !enterModal
    )
      handelClick();
  };
  const handleGoToAction = (url: string) => {
    handleActionStatic(
      {
        type: 'Go to URL',
        element: url,
      },
      {
        a: 'gotourl',
        p: url,
      },
    );
  };
  const handleScrollAction = (scroll: string) => {
    if (!scroll.trim()) {
      setError('Scroll size is required');
      return;
    }
    if (!isFullPageScroll && custom && !customPath.trim()) {
      setError('HTML XPath/selector is required');
      return;
    }
    handelClick();
    handleActionStatic(
      {
        type: 'scroll',
        element: scroll + ' px',
      },
      {
        a: 'scroll',
        v: scroll,
        p: isFullPageScroll ? '' : customPath ? customPath : lastEl,
      },
    );
  };

  const handleDelayAction = (delay: string) => {
    handleActionStatic(
      {
        type: 'delay',
        element: delay + ' seconds',
      },
      {
        a: 'delay',
        v: delay + '000',
      },
    );
  };

  const handleCookieAction = (cookie: any) => {
    if (!cookie.name || !cookie.domain) return;
    handleActionStatic(
      {
        type: 'cookie',
        cookie: {
          name: cookie.name,
          value: cookie.value,
          domain: cookie.domain,
        },
      },
      {
        a: 'cookie',
        d: cookie.domain,
        v: cookie.value,
        n: cookie.name,
      },
    );
  };

  const handleStorageAction = (storage: any) => {
    if (!storage.name) return;
    handleActionStatic(
      {
        type: 'local storage',
        cookie: {
          name: storage.name,
          value: storage.value,
        },
      },
      {
        a: 'local_storage',
        v: storage.value,
        n: storage.name,
      },
    );
  };

  const handleRefreshAction = () => {
    handleActionStatic({ type: 'refresh' }, { a: 'refresh' });
  };

  const handleEnterAction = (path?: string) => {
    if (enterModal && !path) {
      setError('HTML XPath/selector is required');
      return;
    }
    handleClose();
    handleActionStatic({ type: 'enter', element: path ? path : lastEl }, { a: 'enter', p: path ? path : lastEl });
  };

  const modalsState = () => {
    return (
      !paginationModalOpen &&
      !typeModalOpen &&
      !advancedPaginationModalOpen &&
      !cookieModal &&
      !storageModal &&
      !delayModal &&
      !scrollModal &&
      !clickModal &&
      !blockModal &&
      !urlModal &&
      !loadMoreModal &&
      !selectModal &&
      !enterModal
    );
  };

  const modalStateOpen = () => {
    return (
      paginationModalOpen ||
      typeModalOpen ||
      advancedPaginationModalOpen ||
      scrollModal ||
      delayModal ||
      cookieModal ||
      storageModal ||
      clickModal ||
      blockModal ||
      urlModal ||
      loadMoreModal ||
      selectModal ||
      enterModal
    );
  };

  const elemetsFields = [
    {
      show: showAllActions,
      icon: selectSingleIcon,
      iconType: false,
      onClick: handleOnlyThis,
      text: 'Select single',
      title: '',
    },
    {
      show: showAllActions && browserMode,
      icon: loadMoreIcon,
      iconType: true,
      onClick: () => setLoadMoreModal(true),
      text: 'Load more',
      title: 'For Full-stack browser mode',
      notAllow,
      hide: !showAllActions,
    },
    {
      show: showAllActions,
      icon: selectAllIcon,
      iconType: false,
      onClick: handleAll,
      text: 'Select all',
      title: '',
    },
    {
      show: browserMode,
      icon: urlIcon,
      iconType: true,
      onClick: () => setUrlModal(true),
      text: 'Go to URL',
      title: 'For Full-stack browser mode',
      notAllow,
    },
    {
      show: showAllActions,
      icon: paginationIcon,
      iconType: false,
      onClick: () => setPaginationModalOpen(true),
      text: 'Paginate (automatic)',
      title: '',
    },
    {
      show: showAllActions && browserMode,
      icon: scrollIcon,
      iconType: true,
      onClick: () => setScrollModal(true),
      text: 'Scroll',
      title: 'For Full-stack browser mode',
      notAllow,
      hide: !showAllActions,
    },
    {
      show: true,
      icon: paginationIcon,
      iconType: false,
      onClick: () => setAdvancedPaginationModalOpen(true),
      text: 'Paginate (advanced)',
      title: '',
    },
    {
      show: browserMode,
      icon: actionDelayIcon,
      iconType: true,
      onClick: () => setDelayModal(true),
      text: 'Delay',
      title: 'For Full-stack browser mode',
      notAllow,
    },
    {
      show: showAllActions,
      icon: actionTypeIcon,
      iconType: true,
      onClick: () => setTypeModalOpen(true),
      text: 'Type',
      title: '',
      notAllow,
      hide: !showAllActions,
    },
    {
      show: browserMode,
      icon: actionCookieIcon,
      iconType: true,
      onClick: () => setCookieModal(true),
      text: 'Cookie',
      title: 'For Full-stack browser mode',
      notAllow,
      notAllowCookies,
    },
    {
      show: showAllActions,
      icon: actionClickIcon,
      iconType: true,
      onClick: handleClickAction,
      text: 'Click',
      title: '',
      notAllow,
      hide: !showAllActions,
    },
    {
      show: browserMode,
      icon: storageIcon,
      iconType: false,
      onClick: () => setStorageModal(true),
      text: 'Local storage',
      title: 'For Full-stack browser mode',
      notAllow,
      notAllowCookies,
    },
    {
      show: showAllActions && browserMode,
      icon: enterIcon,
      iconType: true,
      onClick: handleEnterAction,
      text: 'Enter key press',
      title: 'For Full-stack browser mode',
      notAllow,
      hide: !showAllActions,
    },
    {
      show: browserMode,
      icon: actionRefreshIcon,
      iconType: true,
      onClick: handleRefreshAction,
      text: 'Refresh',
      title: 'For Full-stack browser mode',
      notAllow,
    },
    {
      show: showAllActions && browserMode && options.length && !selectOptionIsUsed,
      icon: actionSelectIcon,
      iconType: true,
      onClick: () => setSelectModal(true),
      text: 'Select option',
      title: !browserMode
        ? 'For Full-stack browser mode'
        : selectOptionIsUsed
        ? 'You can use this action only once'
        : browserMode
        ? 'Element is not detected'
        : '',
      notAllow,
      hide: !showAllActions,
    },
    {
      show: showAllActions && browserMode,
      icon: actionBLockIcon,
      iconType: true,
      onClick: handleBlockElement,
      text: 'Block element',
      title: 'For Full-stack browser mode',
      notAllow,
      hide: !showAllActions,
    },
    {
      show: !showAllActions,
      icon: actionBLockIcon,
      iconType: true,
      onClick: () => setBlockModal(true),
      text: 'Block element (custom selector)',
      title: 'For Full-stack browser mode',
      notAllow,
      hide: showAllActions || browserMode,
    },
  ];

  const elemetsFields1 = [
    {
      show: showAllActions,
      icon: selectSingleIcon,
      iconType: false,
      onClick: handleOnlyThis,
      text: 'Select single',
      title: '',
    },
    {
      show: browserMode,
      icon: urlIcon,
      iconType: true,
      onClick: () => setUrlModal(true),
      text: 'Go to URL',
      title: 'For Full-stack browser mode',
      notAllow,
    },
    {
      show: showAllActions,
      icon: selectAllIcon,
      iconType: false,
      onClick: handleAll,
      text: 'Select all',
      title: '',
    },
    {
      show: !showAllActions,
      icon: scrollIcon,
      iconType: true,
      onClick: () => {
        setScrollModal(true);
        setIsFullPageScroll(false);
        setCustom(true);
      },
      text: 'Scroll (custom selector)',
      title: '',
      notAllow,
      hide: showAllActions,
    },
    {
      show: showAllActions,
      icon: paginationIcon,
      iconType: false,
      onClick: () => setPaginationModalOpen(true),
      text: 'Paginate (automatic)',
      title: '',
    },
    {
      show: browserMode,
      icon: actionDelayIcon,
      iconType: true,
      onClick: () => setDelayModal(true),
      text: 'Delay',
      title: 'For Full-stack browser mode',
      notAllow,
    },
    {
      show: true,
      icon: paginationIcon,
      iconType: false,
      onClick: () => setAdvancedPaginationModalOpen(true),
      text: 'Paginate (advanced)',
      title: '',
    },
    {
      show: browserMode,
      icon: actionCookieIcon,
      iconType: true,
      onClick: () => setCookieModal(true),
      text: 'Cookie',
      title: 'For Full-stack browser mode',
      notAllow,
      notAllowCookies,
    },
    {
      show: !showAllActions,
      icon: actionTypeIcon,
      iconType: true,
      onClick: () => {
        setTypeModalOpen(true);
        setCustom(true);
      },
      text: 'Type (custom selector)',
      title: '',
      notAllow,
      hide: showAllActions,
    },
    {
      show: browserMode,
      icon: storageIcon,
      iconType: false,
      onClick: () => setStorageModal(true),
      text: 'Local storage',
      title: 'For Full-stack browser mode',
      notAllow,
      notAllowCookies,
    },
    {
      show: !showAllActions,
      icon: actionClickIcon,
      iconType: true,
      onClick: () => setClickModal(true),
      text: 'Click (custom selector)',
      title: '',
      notAllow,
      hide: showAllActions,
    },
    {
      show: browserMode,
      icon: actionRefreshIcon,
      iconType: true,
      onClick: handleRefreshAction,
      text: 'Refresh',
      title: 'For Full-stack browser mode',
      notAllow,
    },
    {
      show: !showAllActions,
      icon: enterIcon,
      iconType: true,
      onClick: () => setEnterModal(true),
      text: 'Enter key press (custom selector)',
      title: 'For Full-stack browser mode',
      notAllow,
      hide: showAllActions || browserMode,
    },
    {
      show: !showAllActions,
      icon: actionBLockIcon,
      iconType: true,
      onClick: () => setBlockModal(true),
      text: 'Block element (custom selector)',
      title: 'For Full-stack browser mode',
      notAllow,
      hide: showAllActions || browserMode,
    },
    {
      show: !showAllActions,
      icon: loadMoreIcon,
      iconType: true,
      onClick: () => {
        setLoadMoreModal(true);
        setCustom(true);
      },
      text: 'Load more (custom selector)',
      title: '',
      notAllow,
      hide: showAllActions,
    },
  ];
  //---------------------------------------------//
  const handleChangePageFrom = (e: any) => {
    setPageFromNumber(e.target.value);
  };

  const handleChangePageTo = (e: any) => {
    const parsedStep = parseInt(step) || 1;
    paginationType.letter
      ? setPageToNumber(e.target.value)
      : setPageToNumber(advancedLimit15 ? String(15 * parsedStep) : e.target.value);
  };

  const handleChangeToLetter = () => {
    setPageToNumber('');
    setPageFromNumber('');
    setPaginationType({ number: false, letter: true, dynamic: false });
  };
  const handleChangeToNumber = () => {
    setPageToNumber('');
    setPageFromNumber('');
    setPaginationType({ number: true, letter: false, dynamic: false });
  };
  const handleChangeToDynamic = () => {
    setPageToNumber('');
    setPageFromNumber('');
    setPaginationType({ number: false, letter: false, dynamic: true });
  };
  const handleChangeClickCount = (e: any) => {
    setClickCount(!e.target.value ? '' : +e.target.value < 1 ? '1' : +e.target.value < 16 ? e.target.value : '15');
  };
  //Close Popup on Esc button click
  useEffect(() => {
    if (isModalOpen) {
      const el = document.getElementsByTagName('body');
      el && el[0] && (el[0].tabIndex = -1);
      el && el[0] && el[0].focus();
      setTimeout(() => {
        closePopupOnEsc(() => handleClose());
      });
    }
  }, [isModalOpen]);
  return isModalOpen
    ? createPortal(
        <>
          <div className={`overlay theme-${theme}`}>
            <div className={`modal-dialog--default custom-alert-block theme-${theme} `} style={{ minWidth: '230px' }}>
              <div className="modal-content text-left actions-modal">
                <>
                  <div className="modal__body d-block">
                    <div className="panel__btns__scraper mb-2">
                      {modalStateOpen() && (
                        <button className="panel__btn__scraper" onClick={backTo} type="button">
                          {longArrowLeft}
                        </button>
                      )}
                      {onCloseModal && (
                        <button className="panel__btn__scraper right_btn" onClick={handleClose} type="button">
                          {closeIcon}
                        </button>
                      )}
                    </div>
                    {modalsState() && (
                      <Row>
                        {!browserMode || (showAllActions && browserMode)
                          ? elemetsFields.map((item: any) => {
                              return (
                                <div className="col-md-6">
                                  <CustomElement
                                    show={item.show}
                                    notAllow={item.notAllow}
                                    icon={item.icon}
                                    iconType={item.iconType}
                                    onClick={item.onClick}
                                    text={item.text}
                                    title={item.title}
                                    hide={item.hide}
                                    notAllowCookies={item.notAllowCookies}
                                  />
                                </div>
                              );
                            })
                          : elemetsFields1.map((item: any) => {
                              return (
                                <div className="col-md-6">
                                  <CustomElement
                                    show={item.show}
                                    notAllow={item.notAllow}
                                    icon={item.icon}
                                    iconType={item.iconType}
                                    onClick={item.onClick}
                                    text={item.text}
                                    title={item.title}
                                    hide={item.hide}
                                    notAllowCookies={item.notAllowCookies}
                                  />
                                </div>
                              );
                            })}
                      </Row>
                    )}
                    {modalStateOpen() && (
                      <div
                        style={{
                          maxWidth: advancedPaginationModalOpen ? '' : '',
                          margin: 'auto',
                          textAlign: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {selectModal ? (
                          <>
                            <CustomSelect value={selectValue} options={options} onChange={setSelectValue} />
                          </>
                        ) : advancedPaginationModalOpen ? (
                          <div className="advanced-pagination">
                            <div className="pagination-url d-flex justify-content-between w-md-50 mx-auto mb-2">
                              <Radio
                                enabled={paginationType.number}
                                onChange={handleChangeToNumber}
                                label="Number"
                                className="pr-3"
                              />
                              <Radio
                                enabled={paginationType.letter}
                                onChange={handleChangeToLetter}
                                label="Letter"
                                className="pr-3"
                              />
                              <Radio
                                enabled={paginationType.dynamic}
                                onChange={handleChangeToDynamic}
                                label="Dynamic"
                              />
                            </div>
                            <div className="pagination-url d-flex">
                              <span className={`input-label primary-color ${paginationType.dynamic ? 'mr-2' : ''}`}>
                                {paginationType.dynamic ? 'Path' : 'URL'}
                              </span>
                              {!paginationType.dynamic ? (
                                <Input
                                  type="url"
                                  name="url"
                                  placeholder=""
                                  onChange={e => setPaginationSampleLink(e.target.value)}
                                  autocomplete
                                  className="mb-4"
                                  error={URLInputError ? 'error' : undefined}
                                  value={paginationSampleLink}
                                />
                              ) : (
                                <Input
                                  type="text"
                                  name="path"
                                  placeholder=""
                                  onChange={e => setDynamicPageinationPath(e.target.value)}
                                  autocomplete
                                  value={dynamicPageinationPath}
                                  className="mb-4"
                                  error={!dynamicPageinationPath ? 'error' : undefined}
                                />
                              )}
                            </div>
                            <div
                              className={`pagination-path-label color primary-color ${
                                paginationType.dynamic ? 'ml-5' : ''
                              }`}
                            >
                              {paginationType.dynamic
                                ? 'Specify the path of the "next" pagination button'
                                : 'https://example.com/%page%'}
                            </div>
                            <div className="pagination-numbers d-flex mt-3">
                              <div className="d-flex mr-2">
                                <span className="input-label primary-color">
                                  {paginationType.dynamic ? 'from' : '%page%'}
                                </span>
                                <Input
                                  type={paginationType.letter ? 'text' : 'number'}
                                  maxLength={paginationType.letter ? 1 : undefined}
                                  placeholder=""
                                  onChange={handleChangePageFrom}
                                  value={pageFromNumber.replace('-', '')}
                                  error={fromDateError ? 'error' : undefined}
                                />
                              </div>
                              <div className="d-flex">
                                <span className="input-label primary-color">to</span>
                                <Input
                                  type={paginationType.letter ? 'text' : 'number'}
                                  placeholder=""
                                  maxLength={paginationType.letter ? 1 : undefined}
                                  onChange={handleChangePageTo}
                                  value={pageToNumber.replace('-', '')}
                                  error={toDateError ? 'error' : undefined}
                                />
                              </div>
                              <div className={`pl-2 ${paginationType.number ? 'd-flex' : 'd-none'}`}>
                                <span className="input-label primary-color">Gap</span>
                                <Input
                                  type="number"
                                  placeholder=""
                                  value={step.replace('-', '')}
                                  onChange={e => setStep(e.target.value)}
                                  min="1"
                                />
                              </div>
                            </div>
                          </div>
                        ) : cookieModal || storageModal ? (
                          <>
                            <Input
                              type={'text'}
                              placeholder={cookieModal ? 'Input cookie name' : 'Input property name'}
                              onChange={e =>
                                cookieModal
                                  ? setCookie({ ...cookie, name: e.target.value })
                                  : setStorage({ ...storage, name: e.target.value })
                              }
                              value={cookieModal ? cookie.name : storage.name}
                            />
                            <br />
                            <Input
                              type={'text'}
                              placeholder={cookieModal ? 'Input cookie value' : 'Input property value'}
                              onChange={e =>
                                cookieModal
                                  ? setCookie({ ...cookie, value: e.target.value })
                                  : setStorage({ ...storage, value: e.target.value })
                              }
                              value={cookieModal ? cookie.value : storage.value}
                            />
                            <br />

                            {cookieModal && (
                              <Input
                                type={'text'}
                                placeholder={'Input cookie domain'}
                                onChange={e => setCookie({ ...cookie, domain: e.target.value })}
                                value={cookie.domain}
                              />
                            )}
                          </>
                        ) : delayModal ? (
                          <div className="delay-select">
                            <span className="label">Select delay interval</span>
                            <Select options={delayoptions} value={delay} click={val => setDelay(val)} />
                          </div>
                        ) : clickModal || blockModal || urlModal || loadMoreModal || enterModal ? (
                          <>
                            {!(loadMoreModal && !custom) && (
                              <TextArea
                                value={customPath}
                                onChange={(e: any) => setCustomPath(e.target.value)}
                                placeholder={`Type ${urlModal ? 'URL' : 'HTML XPath/selector'}`}
                                className={`mt-2`}
                              />
                            )}
                            {loadMoreModal && (
                              <Input
                                type="number"
                                placeholder="Click count"
                                value={clickCount.replace('-', '')}
                                onChange={handleChangeClickCount}
                                className={`w-100 mt-2`}
                                min="1"
                                max="15"
                              />
                            )}
                          </>
                        ) : (
                          <div className="position-relative type-input-wrapper">
                            {paginationModalOpen && (
                              <div className="pagination-url d-flex justify-content-between w-75 mx-auto mb-2">
                                <Radio enabled={paginationType.number} onChange={handleChangeToNumber} label="Number" />
                                <Radio enabled={paginationType.letter} onChange={handleChangeToLetter} label="Letter" />
                              </div>
                            )}
                            {scrollModal && (
                              <Checkbox
                                className="mb-2"
                                checked={isFullPageScroll}
                                onChange={setIsFullPageScroll}
                                labelRight="Full page scroll"
                              />
                            )}
                            <Input
                              type={
                                isPassword && !showPassword
                                  ? 'password'
                                  : (paginationModalOpen && paginationType.number) || scrollModal
                                  ? 'number'
                                  : 'text'
                              }
                              placeholder={
                                paginationModalOpen && paginationType.number
                                  ? 'How many pages?'
                                  : paginationModalOpen
                                  ? 'Input a letter'
                                  : scrollModal
                                  ? 'Input scroll size'
                                  : 'Input text'
                              }
                              onChange={e =>
                                paginationModalOpen && paginationType.number
                                  ? setPageNumber(
                                      browserMode && +e.target.value > (+step || 1) * 15
                                        ? String((+step || 1) * 15)
                                        : e.target.value,
                                    )
                                  : scrollModal
                                  ? setScroll(+e.target.value > 100000 ? '100000' : e.target.value)
                                  : setTypeText(e.target.value)
                              }
                              maxLength={paginationModalOpen && paginationType.letter ? 1 : undefined}
                              value={
                                paginationModalOpen && paginationType.number
                                  ? +pageNumber > (browserMode ? (+step || 1) * 15 : 999)
                                    ? browserMode
                                      ? (+step || 1) * 15
                                      : 999
                                    : pageNumber.replace('-', '')
                                  : scrollModal
                                  ? +scroll > 100000
                                    ? 100000
                                    : scroll.replace('-', '')
                                  : typeText
                              }
                            />
                            {scrollModal && !isFullPageScroll && custom && (
                              <TextArea
                                value={customPath}
                                onChange={(e: any) => setCustomPath(e.target.value)}
                                placeholder="Type HTML XPath/selector"
                                className="mt-2"
                              />
                            )}
                            {paginationModalOpen && (
                              <Input
                                type="number"
                                placeholder="Page gap"
                                value={step.replace('-', '')}
                                onChange={e => setStep(e.target.value)}
                                className={`w-100 mt-2 ${paginationType.number ? 'visible' : 'invisible'}`}
                                min="1"
                              />
                            )}
                            {typeModalOpen && (
                              <>
                                <div className="d-flex pt-2">
                                  <Checkbox checked={isPassword} onChange={() => setIsPassword(!isPassword)} />
                                  <div className="pl-2 password-text ">Password</div>
                                </div>
                                {isPassword && (
                                  <div
                                    className={`eye-icon position-absolute ${showPassword ? 'active' : ''}`}
                                    onClick={() => setShowPassword(!showPassword)}
                                  >
                                    {eyeIcon}
                                  </div>
                                )}
                                {custom && (
                                  <TextArea
                                    value={customPath}
                                    onChange={(e: any) => setCustomPath(e.target.value)}
                                    placeholder="Type HTML XPath/selector"
                                    className={`mt-2`}
                                  />
                                )}
                              </>
                            )}
                          </div>
                        )}
                        {error && (
                          <div className="mt-4">
                            <Message type="error" text={error} />
                          </div>
                        )}
                        <Button name="Proceed" className="m-horizontal-auto mt-4" onClick={handleProceed} />
                      </div>
                    )}
                  </div>
                </>
              </div>
            </div>
          </div>
        </>,
        element,
      )
    : null;
};

export default ElementClickModal;
