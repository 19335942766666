import { FC, memo, useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Modal as ModalBootstrap } from 'react-bootstrap';
import Badge from 'shared-components/atom/badge';
import Button from 'shared-components/atom/button';
import {
  createNewIconLight,
  createNewIconDark,
  workflowStepBackground,
  workflowLastStepBackground,
  newLabelIcon,
  closeIcon,
  editIcon,
} from 'assets/svg-icons';
import './styles.scss';
import { HexomaticUserContext } from 'contexts/hexomatic-user';
import { eventStop } from 'helpers';
import Modal from 'shared-components/molecule/modal';
import { UserContext } from 'contexts/user-context';
import ContactUs from 'shared-components/molecule/payment/contact-us';
import Payment from 'shared-components/molecule/payment';
import CustomModal from 'shared-components/molecule/customModal';
import Template from 'pages/scraping-recipes/components/template';
import popupMobile from 'assets/concierge/popup-mobile.svg';
import popupDesktop from 'assets/concierge/popup-desktop.svg';
import bg from 'assets/concierge/bg.svg';

const TemplateCard: FC<{
  theme: string;
  title?: string;
  description?: string;
  linkTo?: string;
  createNewLinkTo?: string;
  steps?: any;
  image?: any;
  categories?: any;
  fullText?: boolean;
  isNew?: boolean;
  id?: any;
  demoLink?: boolean;
  templatesPage?: boolean;
}> = ({
  theme,
  title,
  description,
  linkTo,
  createNewLinkTo,
  steps,
  image,
  categories,
  fullText,
  isNew,
  id,
  demoLink,
  templatesPage
}) => {
  const { push } = useHistory();
  const { user } = useContext(UserContext);
  const { hexomaticUser } = useContext(HexomaticUserContext);
  // const [isOpen, setIsOpen] = useState(false);
  const [shouldUpgrade, setShouldUpgrade] = useState(false);
  const [buyPremiumCredits, setBuyPremiumCredits] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showConcierge, setShowConcierge] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const handleShowPaymentMethods = () => {
    setShowPaymentModal(false);
    setBuyPremiumCredits(false);
    push(`/scraping-public-recipes/current-recipe/${id}`);
  };
  const cancelClick = () => {
    if (hexomaticUser.pricing_package === 'FREE') {
      setShouldUpgrade(false);
      return;
    }
    setShowPaymentModal(false);
    push(`/scraping-public-recipes/current-recipe/${id}`);
  };
  const handleUseInWorkflow = useCallback(
    (e: any) => {
      if (hexomaticUser.pricing_package === 'FREE') {
        setShouldUpgrade(true);
        eventStop(e);
        return;
      }
      eventStop(e);
      localStorage.setItem('workflow-public-recipe-id', `${id}`);
      push('workflows/create');
    },
    [id, hexomaticUser],
  );

  const actionClick = () => {
    setShouldUpgrade(false);
    buyPremiumCredits ? setShowPaymentModal(true) : (window.location.href = 'https://hexomatic.com/pricing/');
  };

  const handleClick = () => {
    if (hexomaticUser.pricing_package === 'FREE') {
      setShouldUpgrade(true);
      return;
    }

    push(`/scraping-public-recipes/current-recipe/${id}`);
  };

  return (
    <>
      {demoLink ? (
        <div
          className={`template-card-container concierge w-100 position-relative ${templatesPage ? 'no-min-width' : ''}`}
          onClick={() => setShowConcierge(true)}
        >
          <img src={bg} className="concierge-img" />
          <div style={{ position: 'absolute', top: '50%', left: '20px', transform: 'translate(0%, -50%)' }}>
            <div className="title">Let us create your</div> <div className="title">scraping recipes!</div>
            <p className="subtitle mt-2">
              Our 'done for you' concierge <br />
              service is just a click away
            </p>
          </div>
          <div className="d-flex justify-content-end">
            <div
              className="upgrade-btn mt-auto absolute cursor-pointer"
            >
              Learn more
              <svg width="12" height="8" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.0568 0L9.89009 1.14562L14.5075 5.6875H0.676758V7.3125H6.59212H14.5075L9.89009 11.8544L11.0568 13L17.6768 6.5L11.0568 0Z"
                  fill="#B72CFF"
                />
              </svg>
            </div>
          </div>
        </div>
      ) : (
        <div className={`template-card-container ${title ? '' : 'sm'}`}>
          <div
            className="d-flex"
            onClick={() => (linkTo || createNewLinkTo) && push(linkTo || createNewLinkTo || '')}
            style={{ height: categories ? 'calc(100% - 40px)' : '' }}
          >
            {id ? (
              <div className="actions-background">
                <div className="d-flex m-auto">
                  <Button name='Use in a workflow' onClick={handleUseInWorkflow} lightPink className="mr-2" />
                  <div className='recipe-edit-icon' onClick={handleClick}>{editIcon}</div>
                </div>
              </div>
            ) : null}
            <div className={`mx-auto ${title ? '' : 'text-center'} w-100`}>
              <div className={`template-card-img ${!steps ? 'create-new' : ''}`}>
                {steps ? (
                  <div
                    className={`d-flex ${title ? '' : 'justify-content-center'}`}
                    style={{ maxWidth: '100%', overflow: 'hidden' }}
                  >
                    {steps
                      .filter((item: any, index: number) => index <= 3)
                      .map((item: any, index: number) => (
                        <div className="workflow-step">
                          <div className="workflow-step-bg">
                            {index === steps.length - 1 || (steps.length > 4 && index === 3)
                              ? workflowLastStepBackground
                              : workflowStepBackground}
                          </div>
                          {steps.length > 4 && index === 3 ? (
                            <div className="workflow-step-img d-flex" style={{ height: '34px', width: '34px' }}>
                              <span>+ {steps.length - 3}</span>
                            </div>
                          ) : (
                            <img
                              src={item.automation.icon_url}
                              alt={item.automation.name}
                              style={{ maxWidth: '100%', maxHeight: '34px' }}
                              className="workflow-step-img"
                            />
                          )}
                        </div>
                      ))}
                  </div>
                ) : image ? (
                  <div className="template-img d-flex">
                    <img src={image} alt={title} />
                  </div>
                ) : theme === 'dark' ? (
                  createNewIconDark
                ) : (
                  createNewIconLight
                )}
              </div>
              <div className={`template-card-title my-auto ${fullText ? '' : 'sm'}`}>
                {title ? title : 'Create new'} {isNew ? <span className="ml-2">{newLabelIcon}</span> : null}
              </div>
              {description && (
                <div className={`template-card-description ${fullText ? '' : 'text-truncate'}`}>{description}</div>
              )}
            </div>
          </div>
          {categories ? (
            <div className="d-flex px-1 align-items-center pt-2">
              {categories.map((item: any) => (
                <div key={item.name} className="mr-2">
                  <Badge
                    messageType={
                      item.name === 'ChatGPT'
                        ? 'badge-chatgpt'
                        : item.name === 'Sales'
                        ? 'badge-warning'
                        : item.name === 'Marketing'
                        ? 'badge-product'
                        : 'badge-success'
                    }
                    buttonType="light-background"
                  >
                    <span>{item.name}</span>
                  </Badge>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      )}
      <Modal
        isModalOpen={shouldUpgrade}
        title={
          !buyPremiumCredits ? (
            'Please upgrade your plan to continue'
          ) : user.premium_credits_paypal_subscription_id &&
            (user.premium_credits_pricing_package === 'PC_264000' ||
              user.premium_credits_pricing_package === 'PC_26400') ? (
            <ContactUs showCloseIcon handleCloseModal={cancelClick} />
          ) : (
            'Please add premium credits to continue'
          )
        }
        parentClass={
          user.premium_credits_paypal_subscription_id &&
          (user.premium_credits_pricing_package === 'PC_26400' ||
            user.premium_credits_pricing_package === 'PC_264000') &&
          buyPremiumCredits
            ? 'contact_us_modal'
            : ''
        }
        actionBtnText={
          user.premium_credits_paypal_subscription_id &&
          (user.premium_credits_pricing_package === 'PC_26400' ||
            user.premium_credits_pricing_package === 'PC_264000') &&
          buyPremiumCredits
            ? ''
            : 'Upgrade'
        }
        cancelBtnText={
          user.premium_credits_paypal_subscription_id &&
          (user.premium_credits_pricing_package === 'PC_26400' ||
            user.premium_credits_pricing_package === 'PC_264000') &&
          buyPremiumCredits
            ? ''
            : 'Cancel'
        }
        actionBtnColor="warning"
        cancelBtnColor="secondary"
        onActionClick={actionClick}
        onCancelClick={cancelClick}
        onlyBtns={
          !!(
            buyPremiumCredits &&
            user.premium_credits_paypal_subscription_id &&
            (user.premium_credits_pricing_package === 'PC_26400' ||
              user.premium_credits_pricing_package === 'PC_264000')
          )
        }
        escButtonClick={() => setShouldUpgrade(false)}
      />
      {showPaymentModal && (
        <CustomModal ShowPopup={showPaymentModal} escButtonClick={() => setShowPaymentModal(false)}>
          <Payment
            name={''}
            selectedValue={{ title: '105/monthly', value: 105 }}
            price={0}
            premium_credit
            setShowPopup={handleShowPaymentMethods}
          />
        </CustomModal>
      )}
      {/* {isOpen && (
        <Template
          id={id}
          setIsOpen={setIsOpen}
          setShouldUpgrade={setShouldUpgrade}
          setBuyPremiumCredits={setBuyPremiumCredits}
          title={title}
        />
      )} */}
      {showConcierge && (
        <ModalBootstrap
          show={!!showConcierge}
          toggle={() => {}}
          className={`promo-modal modal-dialog--header theme-${theme}`}
        >
          <button
            className="panel__btn dark"
            style={{ marginTop: '5px', marginRight: '10px' }}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              setShowConcierge(false);
            }}
            type="button"
          >
            {closeIcon}
          </button>
          <div className="modal__body" style={{ padding: '0', background: '#301371' }}>
            <img
              onClick={() => window.open('https://calendly.com/hexact/concierge-service-hexact', '_blank')}
              src={isMobile ? popupMobile : popupDesktop}
              alt="Promo"
              className="cursor-pointer"
            />
          </div>
        </ModalBootstrap>
      )}
    </>
  );
};

export default memo(TemplateCard);
