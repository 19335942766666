import { FC, memo } from 'react';
import { Col } from 'react-bootstrap';
import AutomationLongCard from '../automation-long-card';
import './styles.scss';

interface IProps {
  list: { name: string; type: string; id: number; iconUrl: string }[];
  handleClick: (item: { name: string; id: number }) => void;
  blank?: boolean;
}

const RecommendedAutomations: FC<IProps> = ({ list, handleClick, blank }) => (
  <div className="recommended-list workflow-logs-conatiner">
    <div className="recommended-list-header secondary-color">{blank ? 'Suggested starting points' : 'Recommended automations'}</div>
    <div className={`row w-100 m-0`}>
      {list.map((item: any) => (
        <Col lg={12 / list.length} onClick={() => handleClick(item)} className="rec-padding">
          <AutomationLongCard noAddIcon description={item.type} title={item.name} recommended icon={item.iconUrl} />
        </Col>
      ))}
    </div>
  </div>
);

export default memo(RecommendedAutomations);
