import { memo, FC, useCallback } from 'react';
import Button from '../../atom/button';
import Input from '../../atom/input';

import Select from '../../atom/select';
import CustomMultiSelect from '../multiselect';

import { dotsmenuIcon, favoriteIcon, filterIcon, listMenuIcon, plusIcon } from 'assets/svg-icons';
import './styles.scss';

interface IElement {
  type: 'input' | 'select' | 'multi select';
  value?: any;
  defaultValue?: any;
  onChange?: (val: any) => void;
  onKeyDown?: (val: any) => void;
  onKeyUp?: (val: any) => void;
  placeholder?: string;
  options?: any;
  className?: string;
  elClassName?: string;
  icon?: any;
}
interface IProps {
  buttonName?: string;
  buttonClassName?: string;
  handleButtonClick?: (data?: any) => void;
  selectButtonOptions?: ReadonlyArray<{ label: string; value: string }>;
  list: IElement[];
  showViewMode?: boolean;
  setViewMode?: (value: 'cardView' | 'listView') => void;
  viewMode?: 'cardView' | 'listView';
  viewModeClassName?: string;
  showFilter?: boolean;
  filtersCount?: number;
  filterContainer?: any;
  filterContainerOpen?: boolean;
  setFilterContainerOpen?: any;
  title?: string;
  maxWidth?: string;
  showStarIcon?: boolean;
  starIconClick?: any;
  starred?: boolean;
  lgSelect?: boolean;
}

const SearchContainer: FC<IProps> = ({
  buttonName,
  buttonClassName,
  handleButtonClick,
  selectButtonOptions,
  list,
  showViewMode,
  setViewMode,
  viewMode,
  viewModeClassName,
  showFilter,
  filtersCount,
  filterContainer,
  filterContainerOpen,
  setFilterContainerOpen,
  title,
  maxWidth,
  showStarIcon,
  starIconClick,
  starred,
  lgSelect,
}) => {
  const handelChangeCardView = useCallback(() => {
    setViewMode && setViewMode('cardView');
  }, [setViewMode]);
  const handelChangeListView = useCallback(() => {
    setViewMode && setViewMode('listView');
  }, [setViewMode]);
  
  return (
    <div className="search-container-wrapper">
      {title ? <div className="page-title my-md-auto mb-2 pl-2">{title}</div> : null}
      <div className="ml-md-auto d-block d-md-flex flex-wrap">
        {list &&
          list.length > 0 &&
          list.map((item: IElement, i: number) =>
            item.type === 'input' ? (
              <Input
                defaultValue={item.defaultValue ? item.defaultValue : item.value ? item.value : undefined}
                placeholder={item.placeholder}
                onChange={item.onChange && item.onChange}
                onKeyUp={item.onKeyUp && item.onKeyUp}
                onKeyDown={item.onKeyDown && item.onKeyDown}
                value={item.value ? item.value : undefined}
                leftIcon={item.icon}
              />
            ) : item.type === 'select' ? (
              <Select
                fullWidth
                options={item.options}
                onChange={item.onChange}
                value={item.value}
                className={`p-1 ${lgSelect ? 'lg' : ''}`}
              />
            ) : (
              <CustomMultiSelect
                options={item.options}
                value={item.value}
                onChange={item.onChange}
                labelledBy={item.placeholder || ''}
                className="p-1 search-container-multiselect"
              />
            ),
          )}
        {buttonName && handleButtonClick && (
          <div className="m-1">
            {!selectButtonOptions ? (
              <Button name={buttonName} leftIcon={plusIcon} onClick={handleButtonClick} className="d-inline" />
            ) : (
              <Select
                showBottom
                icon={<Button name={buttonName} leftIcon={plusIcon} className="d-inline" />}
                options={selectButtonOptions}
                className="btn-select"
                onChange={handleButtonClick}
              />
            )}
          </div>
        )}
        {showStarIcon ? (
          <div onClick={starIconClick} className={`starred-toggle favorite-icon-bg ${starred ? 'active' : ''} mx-1`}>
            <span className="m-auto">{favoriteIcon} Starred</span>
          </div>
        ) : null}
        {(showViewMode || showFilter) && (
          <div className={`d-flex`}>
            {showFilter && (
              <div className={`filters-main-wrapper d-flex mx-1`}>
                <div className={`select-recipes-view`}>
                  <button
                    className={`view-mode-icon colored-icon filter ${filtersCount ? 'filter-button' : ''}`}
                    style={{ width: filtersCount ? '100px' : '32px', padding: filtersCount ? '0 10px' : '' }}
                    onClick={() => setFilterContainerOpen(!filterContainerOpen)}
                  >
                    {filtersCount ? (
                      <span style={{ color: '#B72CFF', fontWeight: 700, fontSize: '14px', marginRight: '10px' }}>
                        {filtersCount}{' '}
                        {filtersCount === 1 ? 'Filter' : filtersCount && filtersCount > 1 ? 'Filters' : ''}
                      </span>
                    ) : null}
                    {filterIcon}
                  </button>
                </div>
                {filterContainerOpen && filterContainer}
              </div>
            )}
            {showViewMode && (
              <div className="select-recipes-view ml-1">
                <button
                  className="view-mode-icon colored-icon"
                  onClick={() => (viewMode === 'listView' ? handelChangeCardView() : handelChangeListView())}
                >
                  {viewMode === 'listView' ? dotsmenuIcon : listMenuIcon}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(SearchContainer);
