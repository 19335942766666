import { FC, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
import WindowedSelect from 'react-windowed-select';
import { amazonLanguageCodeOptions, amazonSearchAdressesOptions } from '../../constants';
import { useAutomationGlobalMethods } from '../../hooks';
import Input from 'shared-components/atom/input';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import Alert from 'shared-components/atom/alert-message';
// import { cacheFunction } from 'helpers';
import './styles.scss';

const output = [
  '_amazon_product_check_url',
  '_amazon_product_type',
  '_amazon_product_rank_group',
  '_amazon_product_rank_absolute',
  '_amazon_product_position',
  '_amazon_product_title',
  '_amazon_product_details',
  '_amazon_product_image_url',
  '_amazon_product_author',
  '_amazon_product_parent_asin',
  '_amazon_all_product_asins',
  '_amazon_product_price_from',
  '_amazon_product_price_to',
  '_amazon_product_currency',
  '_amazon_product_is_amazon_choice',
  '_amazon_product_rating_position',
  '_amazon_product_rating_type',
  '_amazon_product_rating_value',
  '_amazon_product_rating_votes_count',
  '_amazon_product_rating_max',
  '_amazon_product_is_newer_model_available',
  '_amazon_product_newer_model_title',
  '_amazon_product_newer_model_asin',
  '_amazon_product_newer_model_category',
  '_amazon_product_newer_model_url',
  '_amazon_product_images_list',
  '_amazon_product_publisher',
  '_amazon_product_language',
  '_amazon_product_file_size',
  '_amazon_product_word_wise',
  '_amazon_product_best_sellers_rank',
  '_amazon_product_customer_reviews',
  '_amazon_product_description',
  '_amazon_product_is_available',
];

const AmazonProductDataSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [fieldList, setFieldList] = useState<any>([]);
  const [clicked, setClicked] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [countryText, setCountryText] = useState({ label: 'United States', value: 2840 });
  const [languageText, setLanguageText] = useState({
    label: 'English (United States)',
    value: 'en_US',
  });
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const zeroIndexOutput: string[] = automationIndex === 0 ? ['_amazon_product_asin'] : [];
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const {
    liftingSettings,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (errorMessage || !countryText || !languageText) {
      return false;
    }
    if (fieldList?.length === 0 ? !searchKeyword : Array.isArray(selectedSource) ? !selectedSource?.length : !selectedSource) return false;
    return true;
  };

  useEffect(() => {
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const { keys } = dynamic_settings;
      const { settings } = keys[0];
      const location_code = settings ? settings.location_code : keys[0].location_code;
      const language_code = settings ? settings.language_code : keys[0].language_code;
      const inputValue = settings ? keys[0].inputValue : keys[0].asin;

      setSearchKeyword(inputValue);
      const findedCountry = amazonSearchAdressesOptions.find(item => item.value === location_code);
      const findedSelectedLangCode = amazonLanguageCodeOptions.find(item => item.value === language_code);
      findedCountry && setCountryText(findedCountry);
      findedSelectedLangCode && setLanguageText(findedSelectedLangCode);
    }
  }, []);

  //recipe
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();

    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      const key = dynamic_settings.keys[0].key;
      restoreSelectedSource({ key: key, fieldList: options, setSelectedSource: setSelectedSource });
    }
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...zeroIndexOutput, ...output],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);

  // //memoize
  // const handleAmazonSearchAdressesOptions = (countryText: string) => {
  //   return amazonSearchAdressesOptions.find((item: { label: string; value: number }) => item.label === countryText);
  // };
  // const getMemoizeAmazonSearchAdressesOption = useRef(cacheFunction(handleAmazonSearchAdressesOptions));

  // const handleAmazonLanguageCodeOptions = (languageText: string) => {
  //   return amazonLanguageCodeOptions.find((item: { label: string; value: string }) => item.label === languageText);
  // };
  // const getMemoizeCryptoCurrencyToOption = useRef(cacheFunction(handleAmazonLanguageCodeOptions));

  useEffect(() => {
    if (!clicked) return;
    // setClicked(false);
    const hashId: any = new Date().getTime().toString();
    if (!countryText || !languageText) {
      setCompletedFields({ index: automationIndex, checked: false });
    } else {
      setCompletedFields({ index: automationIndex, checked: checkFields() });
    }

    let localSettings = {
      keys: [
        {
          inputValue: fieldList.length === 0 && automationIndex === 0 ? searchKeyword.trim() : null,
          key: fieldList.length && selectedSource ? selectedSource.value : null,
          settings: {
            // asin: fieldList.length === 0 ? searchKeyword : null,
            location_code: countryText ? countryText.value : null,
            language_code: languageText ? languageText.value : null,
            uniqueHash: hashId,
          },
        },
      ],
    };
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: localSettings,
      outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...zeroIndexOutput, ...output],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [searchKeyword, errorMessage, selectedSource, countryText, languageText, automationIndex]);

  return (
    <div className="google-automation-settings">
      <Row className="first-row">
        {fieldList.length > 0 || automationIndex > 0 ? (
          <Col lg={6} className="mb-4">
            <span className="label secondary-color">ASIN</span>
            <Select
              options={fieldList}
              value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
              placeholder={'Select source'}
              onChange={(selectedItem: any) => {
                setSelectedSource(selectedItem);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
        ) : (
          <Col lg={6} className="mb-4">
            <span className="label secondary-color">ASIN</span>
            <Input
              value={searchKeyword}
              onChange={e => {
                setSearchKeyword(e.target.value);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Language</span>
          <WindowedSelect
            options={amazonLanguageCodeOptions}
            onChange={(selectedOption: any) => {
              if (selectedOption && languageText && selectedOption.value === languageText.value) return;
              setLanguageText(selectedOption);
              !clicked && setClicked(true);
              setIsDirty();
            }}
            value={languageText}
          />
        </Col>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Country</span>
          <WindowedSelect
            options={amazonSearchAdressesOptions}
            onChange={(selectedOption: any) => {
              if (selectedOption && countryText && selectedOption.value === countryText.value) return;
              setCountryText(selectedOption);
              !clicked && setClicked(true);
              setIsDirty();
            }}
            value={countryText}
          />
        </Col>
      </Row>
      {errorMessage && (
        <Row className="mb-4">
          <Col md={12}>
            <Alert type="error" className="create-workflow">
              {errorMessage}
            </Alert>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default AmazonProductDataSettings;
