import { useState, useEffect, useContext, FC, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AutomationDetailsProps, ICard } from '../../../types';
import { ThemeContext } from '../../../contexts/theme-context';
import { automationOutputs, automationWithoutOutput } from '../../../constants';
import { GET_AUTOMATION_BY_ID, GET_AUTOMATIONS } from 'graphql/queries';
import { DELETE_FAVORITE, MAKE_FAVORITE } from 'graphql/mutations';

import AutomationDetailsContent from '../../../shared-components/molecule/automation-details-content';
import AutomationDetailsHeader from '../../../shared-components/molecule/automation-details-header';
import AutomationInputOutput from 'shared-components/molecule/automation-input-output';
import AutomationCards from '../../../shared-components/molecule/automation-card';
import NothingToShow from '../../../shared-components/atom/nothing-to-show';
import Breadcrumb from '../../../shared-components/atom/breadcrumb';
import Loader from '../../../shared-components/atom/loader';
import PublicWorkflowCard from '../../workflows/components/public-workflow-card';

import deeplLight from 'assets/images/deepL-light.svg';

import '../../../i18n';

import './styles.scss';

const Automation: FC<AutomationDetailsProps> = ({
  match: {
    params: { automationID },
  },
}) => {
  const [selectedOption, setSelectedOption] = useState<{ label: string; value: number | string | null }>({
    label: 'All',
    value: 0,
  });
  const { t, i18n } = useTranslation();
  const [automationInfo, setAutomationInfo] = useState({} as any);
  const [automationsList, setAutomationsList] = useState<ICard[]>([]);
  const [searchText, setSearchText] = useState('');

  const {
    data: automation,
    loading,
    refetch,
  } = useQuery(GET_AUTOMATION_BY_ID, {
    variables: {
      settings: {
        automation_id: +automationID,
      },
    },
  });
  const { theme } = useContext(ThemeContext);
  const [markAsStarred] = useMutation(MAKE_FAVORITE, {
    fetchPolicy: 'no-cache',
  });
  // const { data: otherSimilar, refetch: otherSimilarRefetch } = useQuery(GET_AUTOMATIONS, {
  //   variables: {
  //     settings: {
  //       filter: {
  //         active: true,
  //         page: 1,
  //         limit: 3,
  //         date_from: null,
  //         date_to: null,
  //         sort_order: null,
  //         sort_by: null,
  //         ids: [parseInt(automationID)],
  //         search: {
  //           search_field: null,
  //           search_value: null,
  //         },
  //       },
  //     },
  //   },
  // });
  const [toasterInfo, setToasterInfo] = useState({
    show: false,
    errorMessage: '',
    loadingMessage: '',
    successMessage: '',
  });

  const handleToastShow = (show: boolean, errorMessage: string, loadingMessage: string, successMessage: string) => {
    setToasterInfo({ show: show, errorMessage, loadingMessage, successMessage });
  };

  // useEffect(() => {
  //   if (otherSimilar?.HexomaticAutomation?.getAutomations?.automations) {
  //     setAutomationsList(otherSimilar.HexomaticAutomation.getAutomations.automations);
  //   }
  // }, [otherSimilar]);

  useEffect(() => {
    if (automation?.HexomaticAutomation?.getAutomation?.automation) {
      setAutomationInfo(automation.HexomaticAutomation.getAutomation.automation);
    }
  }, [automation]);

  const getNamesForTooltip = useCallback((steps: any, count: number): string => {
    return steps.slice(count).map((curent: any, index: number, arr: any) => {
      return ` ${curent?.automation?.name}${index === arr.length - 1 ? '' : ','} `;
    });
  }, []);

  const changeError = () => {
    setTimeout(() => {
      handleToastShow(false, '', '', '');
    }, 5000);
  };
  const [deleteFromStarred] = useMutation(DELETE_FAVORITE, {
    fetchPolicy: 'no-cache',
  });
  const handleMarkAsStarred = async (id: number) => {
    handleToastShow(true, '', 'Adding into starred automations list...', '');
    const data = await markAsStarred({
      variables: {
        settings: {
          type: 'AUTOMATION',
          favorite_item_ids: [id],
        },
      },
    });
    const { error } = data?.data?.FavoriteOpts?.addToFavorites;
    if (error) {
      handleToastShow(true, 'Something went wrong. Please try again.', '', '');
    } else {
      refetch();
      // otherSimilarRefetch();
      handleToastShow(true, '', '', 'Successfully added into starred automations list.');
    }
    changeError();
  };
  const handleRemoveFromStarred = async (id: number) => {
    handleToastShow(true, '', 'Removing from starred automations list...', '');
    const data = await deleteFromStarred({
      variables: {
        type: 'AUTOMATION',
        favorite_item_ids: [id],
      },
    });
    const { error } = data?.data?.FavoriteOpts?.deleteFromFavorites;
    if (error) {
      handleToastShow(true, 'Something went wrong. Please try again.', '', '');
    } else {
      refetch();
      // otherSimilarRefetch();
      handleToastShow(true, '', '', 'Successfully removed from starred automations list.');
    }
    changeError();
  };

  const props = {
    active: true,
    rate: 4.1,
    isNew: true,
    options: [
      { label: 'Option 1', value: 0 },
      { label: 'Option 2', value: 1 },
    ],
    onChange: () => {},
    handleMarkAsStarred: (id: number) => handleMarkAsStarred(id),
    handleRemoveFromStarred: (id: number) => handleRemoveFromStarred(id),
  };

  return (
    <>
      <div className="breadcrumb-wrap">
        <Breadcrumb
          items={
            automationInfo?.category
              ? [
                  {
                    to: `/automations`,
                    name: 'Automations',
                    active: false,
                  },
                  {
                    to: `/automations/categories/${automationInfo?.category?.id}`,
                    name: automationInfo?.category?.name,
                    active: false,
                  },
                  {
                    to: `/automations/${automationID}`,
                    name: automationInfo?.name || '',
                    active: true,
                  },
                ]
              : [
                  {
                    to: `/automations`,
                    name: 'Automations',
                    active: false,
                  },
                  {
                    to: `/automations/${automationID}`,
                    name: automationInfo?.name || '',
                    active: true,
                  },
                ]
          }
          noBorderLine
        />
      </div>
      {/* <div>
        <SearchContainer
          options={[
            { label: 'All', value: 0 },
            { label: 'Option 1', value: 0 },
            { label: 'Option 2', value: 1 },
          ]}
          selectedValue={selectedOption}
          handleSelectClick={option => setSelectedOption(option)}
          inputPlaceholder="Search automation..."
          handleSearch={e => setSearchText(e.target.value)}
        />
      </div> */}

      {loading ? (
        <Loader light={theme === 'light'} />
      ) : (
        <div className="row automation-details mt-0 pb-3">
          {automationInfo.id ? (
            <>
              <div className="col-12" style={{ maxWidth: '1200px' }}>
                <div className="automation-details-wrapper">
                  <div className="details-wrapper">
                    <AutomationDetailsHeader
                      props={{
                        ...props,
                        ...automationInfo,
                      }}
                    />
                    <AutomationDetailsContent description={automationInfo.long_description} />
                    {!automationWithoutOutput.find((el: number) => el === automationInfo.id) && (
                      <AutomationInputOutput
                        inputList={automationInfo.inputs ? automationInfo.inputs.split(',') : []}
                        outputList={
                          automationInfo.outputs && JSON.parse(automationInfo.outputs)
                            ? Object.keys(JSON.parse(automationInfo.outputs))
                            : //@ts-ignore
                            automationOutputs[automationInfo.id]
                            ? //@ts-ignore
                              automationOutputs[automationInfo.id]
                            : ['none']
                        }
                      />
                    )}
                  </div>
                  {automationInfo?.long_description_details?.trim() ? (
                    <div className="mt-4">
                      <h5 className="dark-title m-2 no-transform">How to use {automationInfo.name} automation</h5>
                      <AutomationDetailsContent description={automationInfo.long_description_details} />
                      {/* <Button
                      loading={false}
                      onClick={() => handleAutomationClick(automationInfo.has_source, automationInfo.id)}
                      name="Create Workflow"
                    /> */}
                    </div>
                  ) : null}
                </div>

                {automationInfo.containedPublicWorkflows && !!automationInfo.containedPublicWorkflows.length && (
                  <div>
                    <h5 className="dark-title m-2 no-transform">
                      Workflows containing {automationInfo.name} automation
                    </h5>
                    <div className="row m-0">
                      {automationInfo.containedPublicWorkflows.map((workflow: any) => (
                        <PublicWorkflowCard
                          item={{
                            id: workflow.public_workflow_id,
                            name: workflow.public_workflow_name,
                            description: workflow.public_workflow_description,
                            steps: workflow.steps.map((subItem: any) => {
                              return {
                                automation: { name: subItem.automation_name, icon_url: subItem.automation_icon_url },
                              };
                            }),
                            categories: workflow.categories,
                          }}
                          getNamesForTooltip={getNamesForTooltip}
                          fullWidth
                        />
                      ))}
                    </div>
                  </div>
                )}
              </div>
              {/* <div className="col-12 col-md-5 col-lg-6 col-xl-4">
                <div className="recommend-title mx-auto mx-md-0">
                  <div>Other similar</div>
                  <div className="see-all">
                    <Link to={`/automations/types/${automationInfo.type.id}/categories/${automationInfo.category.id}`}>
                      Show all
                    </Link>
                  </div>
                </div>
                <div className="recommend-automations">
                  {!!automationsList.length ? (
                    automationsList.map(item => (
                      <div className="recommend-automation">
                        <AutomationCards
                          id={item.id}
                          title={item.name}
                          description={item.short_description}
                          icon={item.id === 34 && theme === 'dark' ? deeplLight : item.icon_url}
                          isNew={item.is_new}
                          rate={4.1}
                          active={true}
                          credit_price={item.credit_price}
                          premium_credit_price={item.premium_credit_price}
                          automationType={item.type && item.type.name}
                          className="mx-auto mx-md-0"
                          handleMarkAsStarred={props.handleMarkAsStarred}
                          handleRemoveFromStarred={props.handleRemoveFromStarred}
                          isFavorite={item.isFavorite}
                        />
                      </div>
                    ))
                  ) : (
                    <NothingToShow text="Automations is not found" />
                  )}
                </div>
              </div> */}
            </>
          ) : (
            <div className="col-12">
              <NothingToShow text="Automation is not found." />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Automation;
