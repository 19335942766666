import { FC, useState, useEffect, memo, useContext } from 'react';
import Button from 'shared-components/atom/button';
import Select from 'shared-components/atom/select';
import Input from 'shared-components/atom/input';
import DataList from 'shared-components/atom/dataList';
import { residential_geo_proxy_country_list } from 'constants/countries/residential-geo-proxy-countries';
import { scraperRecipeProxy, scraperRecipeBrowser } from 'constants/index';
import Checkbox from 'shared-components/atom/checkbox';
import Switch from 'shared-components/atom/switch-with-label';
import Tooltip from 'shared-components/atom/tooltip';
import { ThemeContext } from 'contexts/theme-context';
import { infoIconCircle } from 'assets/svg-icons';
import proxyHint from '../../../assets/proxy-hint.svg';
import proxyHintDark from '../../../assets/proxy-hint-dark.svg';
import './styles.scss';

const Preview: FC<any> = ({
  selectedProxy,
  handleEnterKeyPress,
  setAddress,
  address,
  selectedBrowser,
  setBlockAds,
  setSelectedBrowser,
  handleChangeSelectedProxy,
  residentialGEOCountry,
  handlePreview,
  updateRecipId,
  loading,
  handleChangeResidentialGEOCountry,
  blockAds,
  confirmedItemsLength,
  disabled,
  className,
  isTemplate,
  variables,
  setVariables,
  editUrl,
  // autoscrape,
  // setAutoscrape,
}) => {
  const { theme } = useContext(ThemeContext);
  //-------------------------states-----------------------------//
  const [arr, setArr] = useState((editUrl || '').split(/W*(%var_)[a-z]{1,1000}%/));

  useEffect(() => {
    editUrl && setArr(editUrl.split(/W*(%var_)[a-z]{1,1000}%/));
  }, [editUrl]);

  const [errorMessage, setErrorMessage] = useState('');
  //------------------------------------------------------------//
  //-------------------------useEffects-------------------------//

  useEffect(() => {
    setVariables &&
      setVariables(
        arr.map((item: any) => {
          return item === '%var_' ? '' : item;
        }),
      );
  }, [arr]);
  //------------------------------------------------------------//
  //-------------------------Functions--------------------------//
  const handelAddressChange = (e: any) => {
    setAddress(e.target.value);
  };
  const handleClick = () => {
    handlePreview(!!updateRecipId || confirmedItemsLength, {}, true);
  };
  const inputChange = (e: any, idx: number) => {
    const arr = [...variables];
    arr[idx] = e.target.value;
    setVariables(arr);
  };
  //------------------------------------------------------------//
  const Fields = (
    <>
      <div className='browser-mode'>
        <Select
          options={scraperRecipeBrowser}
          value={selectedBrowser}
          onChange={(value: any) => setSelectedBrowser(value)}
          fullWidth
          width={110}
          className="mr-2"
          label={
            <>
              <div className="mr-1">Browser mode</div>
              <Tooltip
                trigger={['hover']}
                placement="bottom"
                className="d-inline teams-tooltip"
                content={{
                  title: 'Browser mode options',
                  text: (
                    <>
                      <div className="teams-hint">
                        <strong>Fast mode</strong> uses our custom browser tuned for stealth, speed and efficiency. Some
                        websites using JS or certain DOM elements may not render though
                      </div>
                      <div className="teams-hint">
                        <strong>Full-stack</strong> uses a full stack version of our custom browser tuned for
                        compatibility. This is slower and might get higher detection of elements
                      </div>
                    </>
                  ),
                }}
              >
                <span className="help-icon cursor-pointer">{infoIconCircle}</span>
              </Tooltip>
            </>
          }
          disabled={disabled || false}
        />
      </div>
      {/* <Select
        options={scraperRecipeProxy}
        value={selectedProxy}
        onChange={handleChangeSelectedProxy}
        fullWidth
        width={165}
        className="mr-2 proxy"
        label="Proxy mode"
      /> */}

      <div className={selectedProxy?.value !== 'premium' || loading ? 'no-proxy position-relative' : 'position-relative'}>
        <img src={theme === 'dark' ? proxyHintDark : proxyHint} className="proxy-hint" />
        <div className="d-flex">
          <Switch
            setChecked={() =>
              handleChangeSelectedProxy({ label: '', value: selectedProxy?.value === 'premium' ? '' : 'premium' })
            }
            checked={selectedProxy?.value === 'premium'}
          />
          <div className="select-label primary-color ml-2 d-flex">
            <div className="mr-1">Residential proxy</div>
            <div className="proxy-hint-sm">
              <Tooltip text='Enable "Residential proxy" for higher success rate' placement={'bottom'}  trigger={['hover']}>
                {infoIconCircle}
              </Tooltip>
            </div>
          </div>
        </div>

        <DataList
          list={residential_geo_proxy_country_list}
          value={residentialGEOCountry}
          placeholder={'Select country'}
          onChange={(text: string) => {
            handleChangeResidentialGEOCountry(text);
          }}
          // label="Proxy Country"
          width={210}
          setErrorMessage={(msg: any) => setErrorMessage(msg)}
        />
        {selectedProxy?.value ? <div className='note-label primary-color'>{infoIconCircle} Consumes 0.12 premium credits per MB</div> : null}
      </div>
    </>
  );

  return (
    <div className={`scraped-inputs ${className ? className : ''} pr-0 py-0`}>
      <div
        className="input"
        style={{
          maxWidth: 'calc(100% - 500px)',
        }}
      >
        <div className="url">
          {!isTemplate ? (
            <Input
              onKeyDown={handleEnterKeyPress}
              dataUrl
              type="url"
              id="lname"
              name="url"
              className="website-url preview-input"
              onChange={handelAddressChange}
              placeholder="URL"
              value={address}
              autocomplete
            />
          ) : (
            <>
              <div className="text-left mb-2">
                <div>
                  <strong>Template URL:</strong>
                </div>{' '}
                <div style={{ wordBreak: 'break-all' }}>{address}</div>
              </div>
              {arr.length > 1 && (
                <div className="text-left mb-2">
                  <div>
                    <strong>New URL:</strong>
                  </div>
                  {arr.map((item: any, idx: number) => (
                    <>
                      {item === '%var_' ? (
                        <input className="variables-input" value={variables[idx]} onChange={e => inputChange(e, idx)} />
                      ) : (
                        item
                      )}
                    </>
                  ))}
                </div>
              )}
            </>
          )}
          <div className="d-flex">
            {/* <div className="mt-1 d-flex mr-4">
              <div className="d-flex align-items-center" style={{ height: '26px' }}>
                <Checkbox checked={autoscrape} onChange={() => setAutoscrape(!autoscrape)} />
              </div>
              <div className="block-ads-label mt-1 ml-2">Suggest fields to capture</div>
            </div> */}

            {selectedBrowser.label === 'Full-stack' && (
              <div className="mt-1 d-flex">
                <div className="d-flex align-items-center" style={{ height: '26px' }}>
                  <Checkbox checked={blockAds} onChange={() => setBlockAds(!blockAds)} />
                </div>
                <div className="block-ads-label mt-1 ml-2">Ads blocker</div>
              </div>
            )}
            {/* <div className="select-label primary-color mt-2 note-label">
              {infoIconCircle} Enable "Use proxy" for higher success rate{' '}
            </div> */}
          </div>
        </div>
      </div>
      {isTemplate ? <div className="row ml-0">{Fields}</div> : <>{Fields}</>}
      <Button name="Preview" onClick={handleClick} className={`prev-btn ml-2 mt-19px`} disabled={loading} />
    </div>
  );
};

export default memo(Preview);
