import LZUTF8 from 'lzutf8';
import React from 'react';
class Scraper extends React.Component {
  constructor(props) {
    super(props);
    this.url = props && props.url;
    this.urlQueryString = props && props.urlQueryString;
    this.editUrl = props && props.editUrl;
    this.property = 0;
    this.collectData = [];
    this.finalizingData = [];
    this.lastActionIndex = -1;
    this.webviewId = 'webview';
    this.ip = props && props.ip;
    // this.propertyTpl = `<input type="text" value="propertyName#property-id#" class="property-#property-id#" /> <span class="count"></span> <span class="confirm" ><img data-confirm="1" src=${checkIcon} alt='check'/></span> <span class="close-btn"><img data-close="1" src=${closeIcon} alt='close'/></span>`;
  }

  /*
   * @description collect action
   */
  collectAction = action => {
    this.collectData.push(action);
  };

  /*
   * @description collect action
   */
  undoAction = () => {
    // console.log(`undo: 0`, this.collectData)

    let undo = this.collectData.length > 1 ? this.collectData.pop() : this.collectData;

    // console.log(`undo: 1`, undo)

    undo = this.collectData.length > 1 ? this.collectData.pop() : this.collectData[0];

    // console.log(`undo: 2`, undo)

    return undo;
  };
  sendAction = (action, el, dataArg) => {
    let element = '';
    let data = '';
    if (
      action === 'select_cancel' ||
      action === 'select_only_this' ||
      action === 'click' ||
      action === 'type' ||
      action === 'select_all' ||
      action === 'pagination_advanced' ||
      action === 'pagination'
    ) {
      element = el;
      data = dataArg;
    } else if (action !== 'action_undo') {
      element = this.getElement('[data-action-selected-element]')?.innerHTML.replaceAll('&gt;', '>');
      data = this.getElement('[data-action-value]')?.value;
    }
    // undo
    else if (action === 'action_undo' && el && dataArg) {
      element = el;
      data = dataArg;
    } else if (action === 'action_undo' && !el && !dataArg) {
      const undo_data = this.undoAction().action;
      element = undo_data.element;
      data = undo_data;
    }
    this.sendMessage('action', this.b64EncodeUnicode(JSON.stringify({ action, element, data })));
  };

  /*
   * @description Actions controller
   */
  actions = (type, params = {}) => {
    if (type === 'goto') {
      this.goTo(params);
    }
    if (type === 'pagination_advanced' || type === 'pagination') {
      this.pagination();
    }
    if (type === 'links') {
      this.getLinks(params);
    }
    if (type === 'add-property') {
      this.addProperty();
    }
  };

  /*
   * @description send token to allow show preview page
   */
  token = () => {
    //TODO: in future need add crypt class with salt
    this.sendMessage('token', new Date().getTime());
  };

  /*
   * @description Pagination activate for next select element
   */
  pagination = (count, step) => {
    try {
      this.sendMessage(
        'pagination',
        this.b64EncodeUnicode(
          JSON.stringify({
            path: this.finalizingData[0].paths[0],
            count: count,
            step: step,
          }),
        ),
      );
    } catch (error) {}
  };

  /*
   * @description Finalizing data
   */
  finalizing = data => {
    if (data.action === 'newAction') {
      this.finalizingData.push({
        url: data.url,
        elements: data.elements,
      });
      ++this.lastActionIndex;
    }
    if (data.action === 'insertElements') {
      let keys = Object.keys(data.elements);
      if (keys[0] && keys[0] === '0') {
        keys = [data.elements[0].path];
      }
      this.finalizingData[this.lastActionIndex].paths = keys;
    }
    if (data.action === 'saveAction') {
      for (const p of this.finalizingData[this.lastActionIndex].paths) {
        this.finalizingData[this.lastActionIndex].elements.push({
          property: data.property,
          path: p,
          type: this.getElement('[data-elementType]').value,
        });
      }
      delete this.finalizingData[this.lastActionIndex].paths;
    }
  };

  /*
   * @description Get links, phones, emails and etc..
   */
  getLinks = type => {
    this.sendMessage('links', type);
  };

  /*
   * @description Property action
   */
  propertyAction = action => {
    if (action === 'close') {
      this.getElement('.property-list div').style.display = 'none';
    } else if (action === 'pagination_advanced' || action === 'pagination') {
      this.sendMessage('confirmed', 1);
    } else {
      const el = this.getElement(`.property-name`);
      el && el.parentElement.classList.add('done');
      this.sendMessage('confirmed', 1);
    }
    // this.getElement('[data-addproperty]').style.display = 'block';
  };

  /*
   * @description Property action
   */
  setProperty = data => {
    //TODO: will be removed on production
    // this.getElement('.scraped .data pre').innerHTML = this.dump(Object.entries(data), 0)

    if (this.getElement('.scraped .btns')) this.getElement('.scraped .btns').style.display = 'block';
    if (this.getElement('.scraped-left .item:not(.done) .confirm')) {
      this.getElement('.scraped-left .item:not(.done) .confirm').style.display = 'inline';
    }
    const countEl = this.getElement(`.property-root-${this.property} .count`);
    countEl && (countEl.innerHTML = ((data && data.data && data.data.length) || 0) + ' items');
  };

  /*
   * @description Add property
   */
  addProperty = () => {
    this.property++;
    const input = document.createElement('div');

    input.classList.add(`item`);
    input.classList.add(`property-root-${this.property}`);
    input.innerHTML = this.propertyTpl.replaceAll('#property-id#', this.property);

    // this.getElement('[data-propertylist]').appendChild(input);
    // this.getElement('[data-addproperty]').style.display = 'none';
    this.sendMessage('property', this.getElement(`.property-${this.property}`).value);
  };

  /*
   * @description Encode action data to base64
   */
  b64EncodeUnicode = str => {
    str = JSON.stringify(str);
    return Buffer.from(str, 'utf-8').toString('base64');
  };

  /*
   * @description Goto and open entered link
   */
  goTo = ({ data, goToData }) => {
    let url = this.getElement('[data-url]') && this.getElement('[data-url]').value;
    const defaultUrl = url;
    const idx = url && url.indexOf('#');

    if (idx !== -1) url = url && url.slice(0, idx);
    const hashRaw = defaultUrl.replace(url, '');
    const hash = hashRaw.replace('#', '');
    let go_url = url;
    if (this.collectData.length === 0) {
      this.collectData.push({
        action: {
          type: 'click',
          element: 'body',
          form_data: {
            queryString: hashRaw,
            action: url,
            method: 'get',
          },
        },
      });
    } else {
      if (data.action && data.queryString) {
        go_url = `${data.action}/${data.queryString}`;
      }
    }

    this.finalizing({
      action: 'newAction',
      url: go_url,
      elements: [],
    });
    this.getElement('.hexoScrapWebview').innerHTML =
      // this.editUrl ? `<iframe width="1890" style="-webkit-transform:scale(0.55);-moz-transform-scale(0.55);" sandbox="allow-scripts allow-forms allow-same-origin" referrerpolicy='origin-when-cross-origin' src="${this.editUrl}" id="${this.webviewId}"></iframe>` :
      `<iframe width="1890" style="-webkit-transform:scale(0.55);-moz-transform-scale(0.55);" sandbox="allow-scripts allow-forms allow-same-origin" referrerpolicy='origin-when-cross-origin' src="${
        this.ip ? 'http://' + this.ip + '/' : this.url
      }webview/${this.editUrl ? this.editUrl : url}${
        this.editUrl ? (this.editUrl.indexOf('?') > -1 ? '&' : '?') : url && url.indexOf('?') > -1 ? '&' : '?'
      }${
        !goToData
          ? 'data=' + this.b64EncodeUnicode(data)
          : '_browser_actions=' +
            LZUTF8.compress(JSON.stringify(goToData).replace(/\n|\t/g, ' '), {
              outputEncoding: 'Base64',
            })
      }${this.urlQueryString || ''}${hash && '&_hash='}${hash && hash}" id="${this.webviewId}"></iframe>
      `;
  };

  /*
   * @description Element query
   */
  getElement = css => {
    return document.querySelector(css);
  };

  /*
   * @description Goto and open entered link
   */
  sendMessage = (key, value = '') => {
    const data = '{"' + key + '": "' + value + '"}';
    const el = document.getElementById(this.webviewId);
    el && el.contentWindow.postMessage(data, '*');
  };

  //TODO: temp, for displaying visual
  dump = (arr, level) => {
    var dumped_text = '';
    if (!level) level = 0;

    var level_padding = '';
    for (var j = 0; j < level + 1; j++) level_padding += '    ';

    if (typeof arr == 'object') {
      for (var item in arr) {
        var value = arr[item];
        if (typeof value == 'object') {
          dumped_text += level_padding + "'" + item + "' ...\n";
          dumped_text += this.dump(value, level + 1);
        } else {
          dumped_text += level_padding + "'" + item + '\' => "' + value + '"\n';
        }
      }
    } else {
      dumped_text = '===>' + arr + '<===(' + typeof arr + ')';
    }
    return dumped_text;
  };
}

export default Scraper;
