import { FC, useEffect, useState } from 'react';
import { useAutomationGlobalMethods } from '../../hooks';
import { Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import { chartTypes } from '../../constants';
import Input from 'shared-components/atom/input';
import CustomMultiSelect from 'shared-components/molecule/multiselect';
import Checkbox from 'shared-components/atom/checkbox';
import './styles.scss';

const DataVisualizationSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  selectedRecipes,
  automationIndex,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [list, setList] = useState<{ label: string; value: string }[]>([]);
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);
  const [secondFieldList, setSecondFieldList] = useState<{ label: string; value: string }[]>([]);
  const [clicked, setClicked] = useState(false);
  const [selectedSource, setSelectedSource] = useState<{ label: string; value: string } | null>(null);
  const [selectedTypes, setSelectedTypes] = useState<{ label: string; value: string }[]>([]);
  const [isLandscape, setIsLandscape] = useState(false);
  const [titles, setTitles] = useState({ pie: '', line: '', bar: '' });
  const [values, setValues] = useState({ pie: [], line: [], bar: [] });
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const {
    getPreviousAutomationOutputOrPreviousRecipKeys,
    liftingSettings,
    restoreSelectedSource,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (!selectedSource || selectedTypes?.length === 0) {
      return false;
    }
    let check = true;
    selectedTypes?.map(item => {
      if (
        //@ts-ignore
        !titles[item.value]
      ) {
        check = false;
        return;
      }
      //@ts-ignore
      if (item && item.value === 'pie' && !values.pie.value) {
        check = false;
        return;
      }
      //@ts-ignore
      if (item && item.value !== 'pie' && !values[item.value].length) {
        check = false;
        return;
      }
    });
    return check;
  };

  useEffect(() => {
    if (automationSendSettings) {
      const currentAutomation = automationSendSettings.find((automation: any, index: any) => index === automationIndex);
      const { dynamic_settings } = currentAutomation;
      if (dynamic_settings) {
        const newValues: any = {};
        const newTitles: any = {};
        let selectedValues: any = [];

        const { key, settings } = dynamic_settings.keys[0];
        let landscape = settings ? settings.landscape : false;

        setIsLandscape(!!landscape);
        settings?.types && setSelectedTypes(settings.types);
        selectedValues = selectedValues.map((item: any) => {
          return !item ? '' : item && !item.value ? item : item.value;
        });
        setFieldList(list);
        // const secondNewList = list.filter(item => item.value !== key);
        setSecondFieldList(list);
        //change values when sources have been changed
        dynamic_settings.keys.map((item: any) => {
          if (item.settings) {
            selectedValues.push(...item.settings.valueKeys);
            newTitles[item.settings.chartType] = item.settings.title;
            const arr = list.map(item => {
              return item.value;
            });
            if (item.settings.chartType === 'pie') {
              if (arr.includes(item.settings.valueKeys[0])) {
                newValues.pie = {
                  label: item.settings.valueKeys[0].replaceAll('_', ' ').trim(),
                  value: item.settings.valueKeys[0],
                };
              } else newValues.pie = [];
            } else {
              const newarr: any = [];
              item.settings.valueKeys.map((item: any) => {
                if (arr.includes(item)) {
                  newarr.push({ label: item.replaceAll('_', ' ').trim(), value: item });
                }
              });
              newValues[item.settings.chartType] = newarr;
            }
          }
        });
        
        setTitles({ ...titles, ...newTitles });
        setValues({ ...values, ...newValues });
        restoreSelectedSource({ key: key, fieldList: fieldList, setSelectedSource: setSelectedSource });
      }
    }
  }, [list]);

  //getting previous outputs
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);

    // setFieldList(options);
    setSecondFieldList(filteredOptions);
    setList(filteredOptions);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const currentAutomation = automationSendSettings.find((automation: any, index: any) => index === automationIndex);
    const { dynamic_settings } = currentAutomation;

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [...fieldList.map((item: { value: any }) => item.value), '_data_visualization'],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, selectedTypes, fieldListWithTypes, automationOutputTypes]);
  //lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);
    const keys: any = [];
    selectedTypes.map(item => {
      //@ts-ignore
      const newValues: any =
        item.value === 'pie'
          ? //@ts-ignore
            typeof values.pie === 'string'
            ? [values.pie]
            : //@ts-ignore
            values.pie.value
            ? //@ts-ignore
              typeof values.pie.value === 'string'
              ? //@ts-ignore
                [values.pie.value]
              : //@ts-ignore
                values.pie.value
            : []
          : //@ts-ignore
            values[item.value].map(item => {
              return item.value;
            });
      keys.push({
        key: fieldList.length && selectedSource ? selectedSource.value : null,
        inputValue: null,
        settings: {
          //@ts-ignore
          title: titles[item.value],
          chartType: item.value,
          valueKeys: newValues,
          landscape: isLandscape,
          types: selectedTypes,
        },
      });
    });
   
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: {
        keys: keys,
      },
      outputKeysData: [...list.map((item: { value: any }) => item.value), '_data_visualization'],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [fieldList, selectedSource, selectedTypes, isLandscape, titles, values, fieldListWithTypes, automationOutputTypes]);

  const handleChangeType = (sel: any) => {
    setSelectedTypes(sel);
    const charts: any = [];
    const newValues: any = { ...values };
    const newTitles: any = { ...titles };
    sel.map((item: any) => item && charts.push(item.value));
    ['pie', 'line', 'bar'].map((item: string) => {
      if (!charts.includes(item)) {
        newTitles[item] = '';
        newValues[item] = [];
      }
    });
    setValues(newValues);
    setTitles(newTitles);
    !clicked && setClicked(true);
    setIsDirty();
  };
  const handleChangeLabel = (sel: any) => {
    setSelectedSource(sel);
    !clicked && setClicked(true);
    setIsDirty();
    setSecondFieldList(list.filter(item => item.value !== sel.value));
  };
  const handleChangeValue = (sel: any, item: string) => {
    setValues({ ...values, [item]: sel });
    if (item === 'pie') {
      setFieldList(list.filter(item => item.value !== sel.value));
    } else {
      const newList: { label: string; value: string }[] = [];
      list.map((item: { value: string; label: string }) => {
        if (!sel.includes(item)) {
          newList.push(item);
        }
      });
      setFieldList(newList);
    }
    !clicked && setClicked(true);
    setIsDirty();
  };
  const handleChangeTitle = (val: string, item: string) => {
    setTitles({ ...titles, [item]: val });
    !clicked && setClicked(true);
    setIsDirty();
  };
  return (
    <div className="screenshot-settings">
      <Row className="">
        <Col lg={5} className="mb-4">
          <span className="label secondary-color">Label</span>
          <Select options={fieldList} value={selectedSource} placeholder={'Select'} onChange={handleChangeLabel} />
        </Col>
        <Col lg={5} className="mb-4">
          <span className="label secondary-color">Chart type</span>
          <CustomMultiSelect
            options={chartTypes}
            value={selectedTypes}
            onChange={handleChangeType}
            labelledBy="select"
          />
        </Col>
        <Col lg={2} sm={12} className="checkbox-wrapper m-auto pb-2">
          <div className="item-block">
            <Checkbox
              checked={isLandscape}
              label="Landscape"
              onChange={() => {
                setIsLandscape(!isLandscape);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </div>
        </Col>
      </Row>
      {selectedTypes.length > 0 &&
        selectedTypes.map(
          (item: { label: string; value: string }) =>
            item && (
              <Row className="">
                <Col lg={5} className="mb-4">
                  <span className="label secondary-color">{item.label} value</span>
                  {item.value === 'pie' ? (
                    <Select
                      options={secondFieldList}
                      value={values.pie}
                      placeholder={'Select'}
                      onChange={(selectedItem: any) => handleChangeValue(selectedItem, item.value)}
                    />
                  ) : (
                    <CustomMultiSelect
                      options={secondFieldList}
                      //@ts-ignore
                      value={values[item.value]}
                      onChange={(selectedItem: any) => handleChangeValue(selectedItem, item.value)}
                      labelledBy="select"
                    />
                  )}
                </Col>
                <Col lg={5} className="mb-4">
                  <span className="label secondary-color"> {item.label} title</span>
                  <Input
                    onChange={(e: any) => handleChangeTitle(e.target.value, item.value)}
                    //@ts-ignore
                    value={titles[item.value]}
                  />
                </Col>
              </Row>
            ),
        )}
    </div>
  );
};

export default DataVisualizationSettings;
