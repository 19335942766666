import { FC, useEffect, useState } from 'react';
import { useAutomationGlobalMethods } from '../../hooks';
import { Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import { deviceOptions, screenShotDelayOptions } from '../../constants';
import Input from 'shared-components/atom/input';
import CustomMultiSelect from 'shared-components/molecule/multiselect';
import ToggleBtn from 'shared-components/atom/toggle';
import { useMediaQuery } from 'react-responsive';
import Checkbox from 'shared-components/atom/checkbox';
import './styles.scss';

const CreateScreenshotSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  selectedRecipes,
  automationIndex,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);
  const [clicked, setClicked] = useState(false);
  const [selectedSource, setSelectedSource] = useState<{ label: string; value: string } | null>(null);
  const [selectedDevices, setSelectedDevices] = useState<{ label: string; value: string }[]>([]);
  const [heightLocal, setHeight] = useState<number | null>(null);
  const [delay, setDelay] = useState(screenShotDelayOptions[0]);
  const [showHeightInput, setShowHeightInput] = useState(false);
  const [isAdsBlock, setIsAdsBlock] = useState(false);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const {
    getPreviousAutomationOutputOrPreviousRecipKeys,
    liftingSettings,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
    restoreSelectedSource,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (!selectedSource || selectedDevices?.length === 0) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (automationSendSettings) {
      const currentAutomation = automationSendSettings.find((automation: any, index: any) => index === automationIndex);
      const { dynamic_settings } = currentAutomation;
      if (dynamic_settings && fieldList.length > 0) {
        const { height: screenHeight, key, device: deviceType, delay: delayTime, settings } = dynamic_settings.keys[0];
        let height = settings ? settings.height : screenHeight;
        let device = settings ? settings.device : deviceType;
        let delay = settings ? settings.delay : delayTime;
        let ads_block = settings ? settings.ads_block : false;
        // let block_popups = settings ? settings?.block_popups : null;
        setIsAdsBlock(!!ads_block);

        const findDeviceType = deviceOptions.find(deviceOption => deviceOption.value === device);
        restoreSelectedSource({ key: key, fieldList: fieldList, setSelectedSource: setSelectedSource });
        const findedScreenShotDelay = !delay
          ? null
          : screenShotDelayOptions.find((item: { value: any }) => item.value === delay);
        findedScreenShotDelay && setDelay(findedScreenShotDelay);
        setHeight(height ? height : null);
        height && setShowHeightInput(true);
        if (typeof device === 'string') {
          setSelectedDevices(findDeviceType ? [findDeviceType] : [{ label: device, value: device }]);
        } else {
          const findedDevices = !device
            ? []
            : device.map((deviceOption: string) => {
                const findedOption = deviceOptions.find(item => item.value === deviceOption);
                return findedOption;
              });
          setSelectedDevices(findedDevices);
        }
        height && setShowHeightInput(true);
      }
    }
  }, [fieldList]);

  //getting previous outputs
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const currentAutomation = automationSendSettings.find((automation: any, index: any) => index === automationIndex);
    const { dynamic_settings } = currentAutomation;

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [
          ...fieldList.map((item: { value: any }) => item.value),
          ...selectedDevices.map((item: { value: string }) => '_device_screenshot_' + item.value.toLocaleLowerCase()),
        ],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, selectedDevices, automationOutputTypes, fieldListWithTypes]);

  //lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: {
        keys: [
          {
            key: fieldList.length && selectedSource ? selectedSource.value : null,
            inputValue: null,
            settings: {
              device: selectedDevices.map((item: { value: string }) => item.value),
              height: heightLocal ? +heightLocal : null,
              delay: delay.value,
              ads_block: isAdsBlock,
            },
          },
        ],
      },
      outputKeysData: [
        ...fieldList.map((item: { value: any }) => item.value),
        ...selectedDevices.map((item: { value: string }) => '_device_screenshot_' + item.value.toLocaleLowerCase()),
      ],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [selectedSource, selectedDevices, heightLocal, delay, isAdsBlock, showHeightInput]);

  const isMobile = useMediaQuery({ maxWidth: 992 });
  const isSmallLaptop = useMediaQuery({ maxWidth: 1550, minWidth: 992 });

  return (
    <div className="screenshot-settings">
      <Row className="">
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Source</span>
          <Select
            options={fieldList}
            value={selectedSource}
            placeholder={'Select'}
            onChange={(selectedItem: any) => {
              setSelectedSource(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Devices</span>
          <CustomMultiSelect
            options={deviceOptions}
            value={selectedDevices}
            onChange={(sel: any) => {
              setSelectedDevices(sel);
              !clicked && setClicked(true);
              setIsDirty();
            }}
            labelledBy="select"
          />
        </Col>
      </Row>
      <Row className="">
        <Col lg={6} className="mb-4">
          <span className="label secondary-color">Delay (seconds)</span>
          <Select
            options={screenShotDelayOptions}
            value={delay}
            placeholder={'Select'}
            onChange={(selectedItem: any) => {
              setDelay(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          />
        </Col>
        <Col
          lg={6}
          className={`mb-4 ${isSmallLaptop && showHeightInput ? '' : 'mt-3'} ${isMobile ? '' : 'd-flex'}`}
          style={{ flexWrap: isSmallLaptop ? 'wrap' : 'unset' }}
        >
          <ToggleBtn
            onChange={() => {
              setHeight(null);
              setShowHeightInput(!showHeightInput);
              !clicked && setClicked(true);
              setIsDirty();
            }}
            label1={'Full height'}
            label2={'Custom height'}
            checked={showHeightInput}
            className="small"
          />
          {showHeightInput && (
            <Input
              value={heightLocal || ''}
              onChange={(e: { target: { value: any } }) => {
                let val = e.target.value;
                if (isNaN(val)) {
                  return;
                }
                setHeight(val);
                !clicked && setClicked(true);
                setIsDirty();
              }}
              placeholder={'Type height in px'}
              className={isMobile ? 'mt-2' : isSmallLaptop ? '' : 'ml-2'}
            />
          )}
        </Col>
        <Col lg={6} sm={12} className="checkbox-wrapper">
          <div className="item-block">
            <Checkbox
              checked={isAdsBlock}
              label="Ads block"
              onChange={() => {
                setIsAdsBlock(!isAdsBlock);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CreateScreenshotSettings;
