import { FC, useEffect, useState, useContext, useRef, useCallback, useMemo } from 'react';
// import { useTranslation } from 'react-i18next';
// import 'i18n';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Link, useHistory, useLocation } from 'react-router-dom';
// import { useMediaQuery } from 'react-responsive';
import { Col, Row } from 'react-bootstrap';
import { GET_RECIPIES_LIST, GET_RECIPIES_CARD, GET_PUBLIC_REICPES } from 'graphql/queries';
import {
  DELETE_FAVORITE,
  DELETE_RECIPE,
  DUPLICATE_SCRAPING_RECIPE,
  IMPORT_RECIPE,
  MAKE_FAVORITE,
  MAKE_PUBLIC_RECIPE,
} from 'graphql/mutations';
import {
  deleteIcon,
  deleteIconModal,
  globeIcon,
  lockIconModal,
  copyIconModal,
  dashboardWorkflowIcon,
  shareIcon,
  loopIcon,
} from 'assets/svg-icons';

import { publicAllowedUserIDs } from 'constants/index';
import { HexomaticUserContext } from 'contexts/hexomatic-user';
import { UserContext } from 'contexts/user-context';
import { ThemeContext } from 'contexts/theme-context';

import Button from 'shared-components/atom/button';
import Loader from 'shared-components/atom/loader';
import Message from 'shared-components/atom/alert-message';
import ToastLoader from 'shared-components/molecule/toast-loader';
import SearchContainer from 'shared-components/molecule/search-container/updated';
import Modal from 'shared-components/molecule/modal';
import CustomModal from 'shared-components/molecule/customModal';
import VideoModal from 'shared-components/atom/video-modal';
import Tooltip from 'shared-components/atom/tooltip';
import NothingToShow from 'shared-components/atom/nothing-to-show';

import ScrapingList from './components/scraping-recipe-table';
import RecipeCard from './components/recipe-card';
import UrlVariables from './components/url-variables';
import ShareRecipeModal from './components/share-recipe-modal';
import NothingToShowRecommended from './components/nothing-to-show-recommended';

import './create/styles.scss';
import TemplatesSection from 'shared-components/organizm/templates-section';
import TableCardFacadeLoading from 'shared-components/molecule/table-card-facade-loading';
import { useMediaQuery } from 'react-responsive';

const btnOptions = [
  { label: 'Create new', value: 'create new' },
  { label: 'From a template', value: 'from a template' },
];

const ScrapingRecipes: FC = () => {
  const { push } = useHistory();
  const { search } = useLocation();
  const mounted = useRef();
  const mounted1 = useRef();
  const isMobile = useMediaQuery({ maxWidth: 991 });
  const details: {
    page: number;
    searchText: string;
    selectedOption: { label: string; value: number | string | boolean | undefined | null };
  } = localStorage.getItem('recipes-details') ? JSON.parse(localStorage.getItem('recipes-details') || '') : null;
  const [viewMode, setViewMode] = useState<'listView' | 'cardView'>(
    //@ts-ignore
    localStorage.getItem('recipe-view-mode')
      ? localStorage.getItem('recipe-view-mode') || (isMobile ? 'cardView' : 'listView')
      : isMobile
      ? 'cardView'
      : 'listView',
  );
  // const { t, i18n } = useTranslation();
  // const changeLanguage = (language: string) => {
  //   i18n.changeLanguage(language);
  // };

  //---------------Context---------------------------------
  const { user } = useContext(UserContext);
  const { hexomaticUser } = useContext(HexomaticUserContext);
  const { theme } = useContext(ThemeContext);
  //------------------State----------------------------------
  const [shouldUpgrade, setShouldUpgrade] = useState(false);
  const [creditLimitReached, setCreditLimitReached] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [isSuccessfullyShared, setIsSuccessfullyShared] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [isRecipeListReceived, setIsRecipeListReceived] = useState(false);
  const [tableMaxHeight, setTableMaxHeight] = useState(800);

  const [checkedIndexes, setCheckedIndexes] = useState([] as any);
  const [infoModal, setInfoModal] = useState<any>({
    message: '',
    error: false,
    open: false,
    cb: null,
    btn: '',
    close: false,
  });
  const [toasterInfo, setToasterInfo] = useState({
    show: false,
    errorMessage: '',
    loadingMessage: '',
    successMessage: '',
  });
  const handleToastShow = (show: boolean, errorMessage: string, loadingMessage: string, successMessage: any) => {
    setToasterInfo({ show, errorMessage, loadingMessage, successMessage });
  };

  const [makePublic, { data: makePublicData, loading: makePublicLoading }] = useMutation(MAKE_PUBLIC_RECIPE);
  const [deleteRecipe, { error: err, loading: deleteLoading }] = useMutation(DELETE_RECIPE);
  const [copyRecipe, { loading: copyLoading }] = useMutation(DUPLICATE_SCRAPING_RECIPE);
  const [importRecipe] = useMutation(IMPORT_RECIPE);

  const [isAdmin, setIsAdmin] = useState(publicAllowedUserIDs.includes(user.id));
  // const [selectedOption, setSelectedOption] = useState<{ label: string; value: number | string | undefined | null }>({
  //   label: 'All',
  //   value: undefined,
  // });
  const [isFavorite, setIsFavorite] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>(viewMode === 'listView' && details?.selectedOption ? details.selectedOption : {
    label: 'All',
    value: undefined,
  });
  const [error, setError] = useState('');
  const [recipesList, setRecipesList] = useState([] as any[]);
  const [recipesCardArray, setRecipesCard] = useState([] as any[]);
  const [publicRecipesCard, setPublicRecipesCard] = useState<any>([]);
  // const [searchText, setSearchText] = useState('');

  const [searchText, setSearchText] = useState(viewMode === 'listView' && details?.searchText ? details.searchText : '');
  const [startSearch, setStartSearch] = useState(setTimeout(() => {}));
  const [totalCount, setTotalCount] = useState(0);
  const [cardLimit, setCardLimit] = useState(24);
  const [cardPage, setCardPage] = useState(1);
  const [refetchLoading, setRefetchLoading] = useState(false);
  const [optionParameters, setOptionParameters] = useState<{ label: string; value: number | string; url?: string }>({
    label: '',
    value: '',
  });
  const [makingPublic, setMakingPublic] = useState(false);
  const [isClickedLoadMore, setIsClickedLoadMore] = useState<boolean>(false);

  const { data: publicRecipes } = useQuery(GET_PUBLIC_REICPES, {
    fetchPolicy: 'network-only',
    variables: {
      settings: {
        filter: {
          page: 1,
          most_used: true,
          limit: 4,
        },
      },
    },
  });

  useEffect(() => {
    if (publicRecipes?.HexomaticPublicScrapingRecipe?.getPublicScrapingRecipes?.public_scraping_recipes) {
      setPublicRecipesCard(
        publicRecipes.HexomaticPublicScrapingRecipe.getPublicScrapingRecipes.public_scraping_recipes,
      );
    }
  }, [publicRecipes]);

  useEffect(() => {
    if (deleteLoading || refetchLoading || makePublicLoading || copyLoading) {
      const loadingMessage = makePublicLoading
        ? makingPublic
          ? 'Making public...'
          : 'Making private...'
        : refetchLoading
        ? 'In process...'
        : deleteLoading
        ? 'Deleting...'
        : copyLoading && 'Duplicating...';
      handleToastShow(true, '', loadingMessage || '', '');
    }
  }, [deleteLoading, refetchLoading, makePublicLoading, copyLoading]);

  const [recipePage, setRecipePage] = useState(viewMode === 'listView' && details && details.page ? details.page : 1);
  const [limit, setLimit] = useState<{ label: string; value: number }>(
    localStorage.getItem('recipeListLimit')
      ? JSON.parse(localStorage.getItem('recipeListLimit') || '')
      : { label: '15', value: 15 },
  );
  const [sort, setSort] = useState<{ by: string; id: number }>(
    localStorage.getItem('scraping-recipe-sort')
      ? JSON.parse(localStorage.getItem('scraping-recipe-sort') || '')
      : {
          by: 'DESC',
          id: 1,
        },
  );
  const [editUrl, setEditUrl] = useState('');
  const [isUrlValid, setIsUrlValid] = useState(false);
  //------------------------Queries------------------------------
  const [markAsStarred] = useMutation(MAKE_FAVORITE, {
    fetchPolicy: 'no-cache',
  });
  const [deleteFromStarred] = useMutation(DELETE_FAVORITE, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    sort && localStorage.setItem('scraping-recipe-sort', JSON.stringify(sort));
  }, [sort]);

  const {
    data: recipesData,
    loading: recipesDataLoading,
    refetch,
  } = useQuery(GET_RECIPIES_LIST, {
    skip: viewMode !== 'listView',
    variables: {
      settings: {
        filter: {
          page: recipePage || null,
          limit: limit.value || null,
          date_from: null,
          date_to: null,
          sort_order: null,
          sort_by_creation_date: sort.id === 1,
          sort_by_updated_date: sort.id === 2,
          sort_by_name: sort.id === 3,
          sort_by: sort.by,
          is_public:
            selectedOption.label === 'Imported' || selectedOption.label === 'Starred'
              ? null
              : selectedOption.label && selectedOption.value,
          is_imported:
            selectedOption.label === 'All'
              ? selectedOption.value
              : selectedOption.label === 'Starred'
              ? null
              : selectedOption.label === 'Imported',
          isFavorite: isFavorite,
          // is_public: false,
          search: {
            search_field: null,
            search_value: searchText,
          },
        },
      },
    },
  });

  const {
    data: recipesCardData,
    loading: recipesCardDataLoading,
    refetch: refetchCard,
  } = useQuery(GET_RECIPIES_CARD, {
    skip: viewMode !== 'cardView',
    variables: {
      settings: {
        filter: {
          page: cardPage,
          limit: cardLimit,
          date_from: null,
          date_to: null,
          sort_order: null,
          sort_by_creation_date: true,
          sort_by_updated_date: false,
          sort_by_name: false,
          sort_by: 'DESC',
          is_public:
            selectedOption.label === 'Imported' || selectedOption.label === 'Starred'
              ? null
              : selectedOption.label && selectedOption.value,
          is_imported:
            selectedOption.label === 'All'
              ? selectedOption.value
              : selectedOption.label === 'Starred'
              ? null
              : selectedOption.label === 'Imported',
          isFavorite: isFavorite,
          // is_public: undefined,
          search: {
            search_field: null,
            search_value: searchText,
          },
        },
      },
    },
  });

  const handleMarkAsStarred = async (id: number) => {
    handleToastShow(true, '', 'Adding into starred recipies list...', '');
    const data = await markAsStarred({
      variables: {
        settings: {
          type: 'SCRAPING_RECIPE',
          favorite_item_ids: [id],
        },
      },
    });
    const { error } = data?.data?.FavoriteOpts?.addToFavorites;
    if (error) {
      handleToastShow(true, 'Something went wrong. Please try again.', '', '');
    } else {
      viewMode === 'cardView' ? refetchCard() : refetch();
      handleToastShow(true, '', '', 'Successfully added into starred recipies list.');
    }
    hideToast();
  };

  const handleRemoveFromStarred = async (id: number) => {
    handleToastShow(true, '', 'Removing from starred recipies list...', '');
    const data = await deleteFromStarred({
      variables: {
        type: 'SCRAPING_RECIPE',
        favorite_item_ids: [id],
      },
    });
    const { error } = data?.data?.FavoriteOpts?.deleteFromFavorites;
    if (error) {
      handleToastShow(true, 'Something went wrong. Please try again.', '', '');
    } else {
      viewMode === 'cardView' ? refetchCard() : refetch();
      handleToastShow(true, '', '', 'Successfully removed from starred recipies list.');
    }
    hideToast();
  };

  const hideToast = () => {
    setTimeout(() => {
      handleToastShow(false, '', '', '');
    }, 5000);
  };

  //------------------------useEffect--------------------------

  useEffect(() => {
    if (search.includes('token=') && !search.includes('ba_token')) {
      (async function () {
        const { data } = await importRecipe({
          variables: {
            settings: {
              token: search.slice(7),
            },
          },
        });
        const { error_code } = data?.HexomaticScrapingRecipeOps?.importSharedScrapingRecipe;
        if (error_code === "Can't create scraping recipe") {
          setShouldUpgrade(true);
          return;
        } else {
          if (error_code) {
            if (error_code === 'CANNOT_SHARE_UPGRADE_PACKAGE') {
              setShouldUpgrade(true);
              return;
            }
            setError('Something went wrong.');
            return;
          }
        }

        if (viewMode === 'cardView') {
          refetchCard();
          return;
        }
        refetch();
      })();
    }
  }, [search]);

  useEffect(() => {
    const innerHeight = window.innerHeight;
    const headerEl = document.getElementById('header');
    const headerHeight = headerEl?.offsetHeight;
    innerHeight &&
      headerHeight &&
      setTableMaxHeight(innerHeight - headerHeight - 220 > 360 ? innerHeight - headerHeight - 220 : 360);
  }, [window.innerHeight]);

  useEffect(() => {
    if (user) setIsAdmin(publicAllowedUserIDs.includes(user.id));
  }, [user]);

  const handleCopyRecipe = async (id: number | string) => {
    if (
      totalCount === 1 &&
      (hexomaticUser?.pricing_package === 'FREE')
    ) {
      setShouldUpgrade(true);
      return;
    }

    const data = await copyRecipe({
      variables: {
        settings: {
          scraping_recipe_id: id,
        },
      },
    });

    const error = data?.data?.HexomaticScrapingRecipeOps?.duplicateScrapingRecipe?.error_code;
    const copiedRecipeId = data?.data?.HexomaticScrapingRecipeOps?.duplicateScrapingRecipe?.copiedRecipeId;

    if (!error) {
      setRefetchLoading(true);
      await refetch();
      setRefetchLoading(false);
      handleToastShow(
        true,
        '',
        '',
        <div className="d-block d-md-flex">
          <div className="m-auto">Successfully duplicated.</div>{' '}
          {!!copiedRecipeId && (
            <Link to={`/scraping-recipes/current-recipe/${copiedRecipeId}`} className="clickable-text my-auto ml-1">
              Go to the recipe
            </Link>
          )}
        </div>,
      );
    } else {
      handleToastShow(true, 'Something went wrong.', '', '');
      return;
    }
  };

  useEffect(() => {
    const label = optionParameters.label && optionParameters.label.toLocaleLowerCase();
    if (label === 'update') push(`/scraping-recipes/current-recipe/${optionParameters.value}`);
    if (label === 'make public' || label === 'make private') {
      onMakePublic({
        id: optionParameters.value,
        is_public: label === 'make public' ? true : false,
        var_url: editUrl,
      });
    }
    if (label === 'use in a workflow') {
      localStorage.setItem('workflow-recipe-id', `${optionParameters.value}`);
      push(`/workflows/create`);
    }
    if (label === 'duplicate recipe') {
      handleCopyRecipe(optionParameters.value);
    }
    if (label === 'share recipe') {
      if (hexomaticUser?.pricing_package === 'FREE') {
        setShouldUpgrade(true);
        return;
      }
      setShareModal(true);
    }
  }, [optionParameters, hexomaticUser]);

  useEffect(() => {
    if (recipesData?.HexomaticScrapingRecipe?.getScrapingRecipes?.scraping_recipes) {
      const { scraping_recipes, total } = recipesData.HexomaticScrapingRecipe.getScrapingRecipes;
      setRecipesList(scraping_recipes);
      setTotalCount(total);
      setIsRecipeListReceived(true);
    }
  }, [recipesData]);

  useEffect(() => {
    if (recipesCardDataLoading) return;
    if (recipesCardData?.HexomaticScrapingRecipe?.getScrapingRecipes?.scraping_recipes) {
      setIsClickedLoadMore(false);
      const { scraping_recipes, total } = recipesCardData.HexomaticScrapingRecipe.getScrapingRecipes;
      cardPage === 1 ? setRecipesCard(scraping_recipes) : setRecipesCard([...recipesCardArray, ...scraping_recipes]);
      setTotalCount(total);
      setIsRecipeListReceived(true);
    }
  }, [recipesCardData, cardPage]);

  useEffect(() => {
    if (viewMode === 'cardView') {
      refetchCard();
      return;
    }
    refetch();
  }, [searchText, isFavorite]);

  useEffect(() => {
    if (viewMode === 'cardView') {
      refetchCard();
      return;
    }
    refetch();
  }, [viewMode]);

  useEffect(() => {
    if (!mounted.current) {
      //@ts-ignore
      mounted.current = true;
    } else {
      localStorage.setItem(
        'recipes-details',
        JSON.stringify({
          page: recipePage,
          searchText: searchText,
          selectedOption: selectedOption,
        }),
      );
    }
  }, [recipePage, searchText, selectedOption]);

  useEffect(() => {
    if (!mounted1.current) {
      //@ts-ignore
      mounted1.current = true;
    } else {
      setRecipePage(1);
    }
  }, [searchText, selectedOption, limit]);

  useEffect(() => {
    if (makePublicData?.HexomaticPublicScrapingRecipeOps?.createPublicScrapingRecipe) {
      const { error_code } = makePublicData.HexomaticPublicScrapingRecipeOps.createPublicScrapingRecipe;
      setError(error_code);
    } else if (makePublicData) {
      setInfoModal({ ...infoModal, open: true, cb: refetchCard });
    }
  }, [makePublicData]);
  //------------------------Functions----------------------------
  const changeError = () => {
    setError('Something went wrong.');
    setTimeout(() => {
      setError('');
    }, 5000);
  };

  const handleDelete = async (arrayOfIds?: number[]) => {
    const data = [];
    if (arrayOfIds && arrayOfIds.length > 0) data.push(...arrayOfIds);
    else {
      if (showDeleteModal && checkedIndexes.length > 0) data.push(...checkedIndexes);
      else data.push(+optionParameters.value);
    }
    try {
      const res = await deleteRecipe({
        variables: {
          settings: {
            scraping_recipe_ids: data,
          },
        },
      });
      setRecipesCard(
        recipesCardArray.map((item: any) => {
          return {
            ...item,
            checked: false,
          };
        }),
      );
      setOptionParameters({ label: '', value: '', url: '' });
      setCheckedIndexes([]);
      setRefetchLoading(true);
      setRefetchLoading(false);
      setShowDeleteModal(false);

      if (res?.data?.HexomaticScrapingRecipeOps?.deleteScrapingRecipeBulk?.error_code) {
        changeError();
        return;
      }
      if (totalCount <= recipePage * limit.value && data.length === recipesList.length) {
        setRecipePage(recipePage - 1);
        return;
      }

      await refetch();
      await refetchCard();
      handleToastShow(true, '', '', 'Successfully deleted!');
    } catch (err) {
      setOptionParameters({ label: '', value: '', url: '' });
      setShowDeleteModal(false);
      changeError();
      handleToastShow(true, 'Something went wrong.', '', '');
    }
  };

  const handleCardLimit = (): void => {
    setIsClickedLoadMore(true);
    if (totalCount > cardLimit) setCardPage(cardPage + 1);
  };

  const handelChangeViewMode = (val: 'cardView' | 'listView') => {
    if (val === viewMode) return;
    setRecipesCard(
      recipesCardArray.map((item: any) => {
        return {
          ...item,
          checked: false,
        };
      }),
    );
    localStorage.setItem('recipe-view-mode', val);
    localStorage.removeItem('recipes-details');
    setRecipePage(1);
    setViewMode(val);
    setCheckedIndexes([]);
    setIsRecipeListReceived(false);
  };

  const handelNewRecipeCreate = (item: any) => {
    if (
      (hexomaticUser?.pricing_package === 'FREE') &&
      recipesList &&
      recipesList.length
    ) {
      setShouldUpgrade(true);
      setCreditLimitReached(false);
      return;
    } else if (hexomaticUser?.left_credits === 0) {
      setShouldUpgrade(true);
      setCreditLimitReached(true);
      return;
    }
    push('/scraping-recipes/create');
  };

  const onMakePublic = async ({ id, is_public, url }: any) => {
    setMakingPublic(is_public);
    setInfoModal({
      ...infoModal,
      id: id,
      message: is_public ? (
        <UrlVariables url={optionParameters.url || url || ''} setEditUrl={setEditUrl} setError={setIsUrlValid} />
      ) : (
        'Your recipe is now Private.'
      ),
      open: is_public ? true : false,
      btn: is_public ? 'Complete' : 'OK',
      close: is_public ? true : false,
    });
    !is_public &&
      (await makePublic({
        variables: {
          settings: {
            original_scraping_recipe_id: id ? +id : null,
            is_public: is_public,
            var_url: editUrl,
          },
        },
      }));
    handleToastShow(false, '', '', '');
  };

  const handleConfirm = async () => {
    if (infoModal.close) {
      if (isUrlValid) return;
      setInfoModal({ error: false, message: 'We will check your recipe and let you know', btn: 'OK' });
      await makePublic({
        variables: {
          settings: {
            original_scraping_recipe_id: optionParameters.value
              ? +optionParameters.value
              : infoModal.id
              ? infoModal.id
              : null,
            is_public: true,
            var_url: editUrl,
          },
        },
      });
      handleToastShow(false, '', '', '');
      return;
    }
    setInfoModal({ open: false, error: false, message: '', close: false });
    infoModal.cb && infoModal.cb();
  };
  const cancelClick = () => {
    setOptionParameters({ label: '', value: '', url: '' });
    setShowDeleteModal(false);
  };
  //--------------------------------------------------------------------

  // const DeleteButton = () => {
  //   return (
  //     <div className="d-flex mx-3 bg-white view-mode-icon ">
  //       <Tooltip placement="left" text="Delete Recipes">
  //         <button onClick={() => setShowDeleteModal(true)} className="delete-icon bulk border-0 bg-white m-auto">
  //           {deleteIcon}
  //         </button>
  //       </Tooltip>
  //     </div>
  //   );
  // };

  // const isTablet = useMediaQuery({ maxWidth: 992, minWidth: 586 });
  // const isMobile = useMediaQuery({ maxWidth: 585 });

  useEffect(() => {
    if (toasterInfo.show && (toasterInfo.errorMessage || toasterInfo.successMessage)) {
      setTimeout(
        () => {
          handleToastShow(false, '', '', '');
        },
        toasterInfo.successMessage && typeof toasterInfo.successMessage !== 'string' ? 30000 : 5000,
      );
    }
  }, [toasterInfo]);

  const handelKeyUp = useCallback(
    (e: any) => {
      setStartSearch(setTimeout(() => e.target.value != searchText && setSearchText(e.target.value), 500));
    },
    [searchText],
  );

  const handelKeyDown = useCallback(() => {
    clearTimeout(startSearch);
  }, [startSearch, clearTimeout]);

  const list: any[] = useMemo(() => {
    return [
      {
        type: 'select',
        value: selectedOption,
        className: 'px-2 py-1 col-md-4 m-auto',
        options: [
          { label: 'All', value: undefined },
          // { label: 'Starred', value: '' },
          { label: 'Private', value: false },
          { label: 'Public', value: true },
          { label: 'Imported', value: null },
        ],
        onChange: setSelectedOption,
      },
      {
        type: 'input',
        value: undefined,
        defaultValue: searchText,
        className: 'px-2 py-1 col-md-6 m-auto',
        placeholder: 'Search...',
        onKeyUp: handelKeyUp,
        onKeyDown: handelKeyDown,
        // onChange: handelKeyUp,
        icon: loopIcon,
      },
    ];
  }, [selectedOption, handelKeyUp, handelKeyDown, isFavorite, searchText]);

  const handleScrollEvent = (e: any) => {
    const top: number = e.currentTarget.scrollTop;
    const el = document.getElementById(viewMode === 'cardView' ? 'recipe-search-container' : 'recipe-thead');
    if (el) {
      top > 20 ? el.classList.add('scroll') : el.classList.remove('scroll');
    }
  };

  useEffect(() => {
    const el = document.getElementById('recipe-search-container');
    el && el.classList.remove('scroll');
  }, [viewMode]);

  return (
    <div>
      {!recipesList.length &&
      !recipesCardArray.length &&
      isRecipeListReceived &&
      !recipesCardDataLoading &&
      !recipesDataLoading &&
      !searchText &&
      selectedOption.label === 'All' &&
      !isFavorite ? null : (
        <TemplatesSection
          templatesList={publicRecipesCard?.filter((item: any, index: number) => index <= 2)}
          handleShowAllClick={() => push('/scraping-public-recipes', { backUrl: '/scraping-recipes' })}
          createNewLinkTo={'/scraping-recipes/create'}
          blank={!publicRecipesCard?.length}
        />
      )}
      <div
        className={`${
          recipesDataLoading ||
          recipesCardDataLoading ||
          (!recipesDataLoading && recipesList.length) ||
          (!recipesDataLoading && recipesCardArray.length) ||
          !isRecipeListReceived ||
          searchText ||
          isFavorite ||
          selectedOption.label !== 'All'
            ? 'page-content-v2'
            : ''
        }`}
        style={{
          height: isMobile
            ? ''
            : !recipesList.length &&
              !recipesCardArray.length &&
              isRecipeListReceived &&
              !recipesCardDataLoading &&
              !recipesDataLoading &&
              !searchText &&
              selectedOption.label === 'All' &&
              !isFavorite
            ? ''
            : `${tableMaxHeight}px`,
        }}
      >
        {recipesDataLoading ||
        recipesCardDataLoading ||
        (!recipesDataLoading && recipesList.length) ||
        !isRecipeListReceived ||
        (!recipesCardDataLoading && recipesCardArray.length) ||
        searchText ||
        selectedOption.label !== 'All' ||
        isFavorite ? (
          <div className="recipe-search-container pb-1" id="recipe-search-container">
            <SearchContainer
              list={list}
              setViewMode={handelChangeViewMode}
              showViewMode
              viewMode={viewMode}
              viewModeClassName="col-md-2"
              selectButtonOptions={btnOptions}
              title="Scraping recipes"
              starIconClick={() => setIsFavorite(!isFavorite)}
              starred={isFavorite}
              showStarIcon
            />
          </div>
        ) : null}

        {recipesDataLoading || recipesCardDataLoading || !isRecipeListReceived ? (
          <TableCardFacadeLoading rowCount={10} tableView={viewMode === 'listView'} />
        ) : (
          <Row className={`recipes-row ${viewMode}`}>
            {(recipesCardArray.length || recipesList.length)  && isRecipeListReceived ? (
              <>
                {viewMode === 'cardView' ? (
                  <>
                    <div
                      className="row m-0"
                      style={{ maxHeight: 'calc(100% - 20px)', overflow: 'auto', width: '100%' }}
                      onScroll={handleScrollEvent}
                    >
                      {recipesCardArray.map((obj: any, index: number) => (
                        <Col md={6} lg={6} xl={4} key={obj.name} className="px-1 py-1 scraping-recipe-card">
                          <RecipeCard
                            title={obj.name}
                            description={obj.description}
                            id={obj.id}
                            logo={obj.image}
                            isFavorite={obj.isFavorite}
                            handleMarkAsStarred={handleMarkAsStarred}
                            handleRemoveFromStarred={handleRemoveFromStarred}
                            options={
                              !isAdmin
                                ? [
                                    { label: 'Use in a workflow', value: obj.id, icon: dashboardWorkflowIcon },
                                    { label: 'Duplicate Recipe', value: obj.id, icon: copyIconModal },
                                    { label: 'Share Recipe', value: obj.id, icon: shareIcon },
                                    { label: 'Delete', value: obj.id, icon: deleteIconModal },
                                  ]
                                : [
                                    { label: 'Use in a workflow', value: obj.id, icon: dashboardWorkflowIcon },
                                    { label: 'Duplicate Recipe', value: obj.id, icon: copyIconModal },
                                    { label: 'Share Recipe', value: obj.id, icon: shareIcon },
                                    {
                                      icon: !obj.is_public ? globeIcon : lockIconModal,
                                      label: !obj.is_public ? 'Make public' : 'Make private',
                                      value: obj.id,
                                    },
                                    {
                                      label: 'Delete',
                                      value: obj.id,
                                      icon: deleteIconModal,
                                    },
                                  ]
                            }
                            onChange={(item: { label: string; value: string | number }) => {
                              setOptionParameters({ value: item.value, label: item.label, url: obj.url });
                            }}
                          />
                        </Col>
                      ))}
                    </div>
                    <Col
                      className={`load-more-button my-2 ${recipesCardArray.length < totalCount ? '' : 'invisible'}`}
                      xl={12}
                    >
                      <div className="clickable-text" onClick={handleCardLimit}>
                        LOAD MORE
                      </div>
                    </Col>
                  </>
                ) : (
                  <ScrapingList
                    sort={sort}
                    setSort={setSort}
                    recipePage={recipePage}
                    setRecipePage={setRecipePage}
                    handleRecipeDelete={(id, bulk) => {
                      !bulk && setOptionParameters({ label: 'Delete recipe', value: `${id}` });
                      bulk ? handleDelete(id) : setShowDeleteModal(true);
                    }}
                    recipesList={recipesList}
                    handleMarkAsStarred={handleMarkAsStarred}
                    handleRemoveFromStarred={handleRemoveFromStarred}
                    limit={limit}
                    setLimit={setLimit}
                    refetchScrapingList={refetch}
                    deleteLoading={deleteLoading || refetchLoading}
                    totalCount={totalCount}
                    recipesDataLoading={false}
                    error={error}
                    handleCopyRecipe={(id: any) => handleCopyRecipe(id)}
                    handlePublicPrivate={(id: number, is_public: boolean, url: string) => {
                      onMakePublic({
                        id: id,
                        is_public: is_public,
                        var_url: editUrl,
                        url: url,
                      });
                    }}
                    onScroll={handleScrollEvent}
                    setShouldUpgrade={() => setShouldUpgrade(true)}
                  />
                )}
              </>
            ) : !recipesList.length &&
              !recipesCardArray.length &&
              !recipesCardDataLoading &&
              isRecipeListReceived &&
              !recipesDataLoading &&
              (searchText || selectedOption.label !== 'All' || isFavorite) ? (
              <div className="w-100 h-100 d-flex">
                <NothingToShow />
              </div>
            ) : null}
          </Row>
        )}

        {!recipesList.length &&
        !recipesCardArray.length &&
        !recipesCardDataLoading &&
        !recipesDataLoading &&
        isRecipeListReceived &&
        !searchText &&
        selectedOption.label === 'All' &&
        !isFavorite ? (
          <NothingToShowRecommended
            handelNewRecipeCreate={handelNewRecipeCreate}
            setIsVideoModalOpen={setIsVideoModalOpen}
            recipesCardArray={publicRecipesCard}
          />
        ) : null}

        <Modal
          isModalOpen={shouldUpgrade}
          title="Please upgrade your plan to continue"
          actionBtnText="Upgrade"
          cancelBtnText="Cancel"
          actionBtnColor="warning"
          cancelBtnColor="secondary"
          onActionClick={() => {
            setShouldUpgrade(false);
            window.location.href = 'https://hexomatic.com/pricing/';
          }}
          onCancelClick={() => setShouldUpgrade(false)}
          escButtonClick={() => setShouldUpgrade(false)}
        />

        {(optionParameters.label === 'Delete' || showDeleteModal) && (
          <Modal
            isModalOpen={!!(optionParameters.label === 'Delete') || showDeleteModal}
            text={`Confirm deleting ${
              showDeleteModal && checkedIndexes && checkedIndexes.length > 1
                ? checkedIndexes.length + ' recipes'
                : 'the recipe'
            }`}
            actionBtnText={'Yes'}
            cancelBtnText="No"
            actionBtnColor="#B72CFF"
            onActionClick={() => {
              cancelClick();
              handleDelete();
            }}
            onCancelClick={cancelClick}
            escButtonClick={cancelClick}
          />
        )}
        {infoModal.open && (
          <Modal
            isModalOpen={infoModal.open}
            text={infoModal.message}
            actionBtnText={infoModal.btn}
            actionBtnColor={infoModal.error ? 'error' : infoModal.close ? 'none' : 'success'}
            onActionClick={handleConfirm}
            cancelBtnText={infoModal.close && 'Cancel'}
            onCancelClick={() => setInfoModal({ open: false, error: false, message: '' })}
            escButtonClick={() => setInfoModal({ open: false, error: false, message: '' })}
          />
        )}
        {error && viewMode === 'cardView' && (
          <div className="mb-2">
            <Message type="error" text={error} />
          </div>
        )}
        {toasterInfo.show && (
          <ToastLoader
            errorMessage={toasterInfo.errorMessage}
            loadingMessage={toasterInfo.loadingMessage}
            successMessage={toasterInfo.successMessage}
            onClose={
              toasterInfo.successMessage && typeof toasterInfo.successMessage !== 'string'
                ? () => setToasterInfo({ show: false, errorMessage: '', loadingMessage: '', successMessage: '' })
                : undefined
            }
          />
        )}

        {shareModal && (
          <CustomModal escButtonClick={() => setShareModal(false)}>
            <ShareRecipeModal
              setIsSuccessfullyShared={setIsSuccessfullyShared}
              recipeId={+optionParameters.value}
              onClose={() => setShareModal(false)}
            />
          </CustomModal>
        )}

        {isVideoModalOpen && (
          <div className="video-modal-main-wrapper">
            <CustomModal escButtonClick={() => setIsVideoModalOpen(false)}>
              <VideoModal src="https://player.vimeo.com/video/838966927" setIsVideoModalOpen={setIsVideoModalOpen} />
            </CustomModal>
          </div>
        )}
        <Modal
          isModalOpen={isSuccessfullyShared}
          text={'Recipe has been successfully shared'}
          actionBtnText="Ok"
          actionBtnColor={'success'}
          cancelBtnColor={'primary'}
          onActionClick={() => setIsSuccessfullyShared(false)}
          escButtonClick={() => setIsSuccessfullyShared(false)}
        />
        {!isClickedLoadMore && recipesCardDataLoading && recipesCardArray && recipesCardArray.length > 0 && (
          <ToastLoader loadingMessage="Loading" />
        )}
        {recipesDataLoading && recipesList && recipesList.length > 0 && <ToastLoader loadingMessage="Loading" />}
      </div>
    </div>
  );
};

export default ScrapingRecipes;
