import { FC, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { Col, Row } from 'react-bootstrap';

import { CREATE_SQL_CONNECTION, UPDATE_SQL_CONNECTION } from 'graphql/queries';

import Input from 'shared-components/atom/input';
import Button from 'shared-components/atom/button';
import Alert from 'shared-components/atom/alert-message';
import Select from 'shared-components/atom/select';
import CustomModal from 'shared-components/molecule/customModal';

import { closeIcon, eyeIcon } from 'assets/svg-icons';

import './styles.scss';

interface SQLModalProps {
  openSqlModal: boolean;
  setOpenSqlModal: (val: boolean) => void;
  updateSqlConnectionId: null | number;
  refetchDataList: () => void;
  item?: any;
}

const sqlTypes: { label: string; value: string }[] = [
  { label: 'MariaDB', value: 'maria' },
  { label: 'MSSQL', value: 'mssql' },
  { label: 'MySQL', value: 'mysql' },
  { label: 'PostgreSQL', value: 'postgres' },
];

const SQLModal: FC<SQLModalProps> = ({
  openSqlModal,
  setOpenSqlModal,
  updateSqlConnectionId,
  refetchDataList,
  item,
}) => {
  const [sqlType, setSqlType] = useState<{ label: string; value: string }>(sqlTypes[0]);

  const [showPassword, setShowPassword] = useState(false);
  const [customLoading, setCustomLoading] = useState(false);

  // const [sqlInitialType, setSqlInitialType] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [sqlHost, setSqlHost] = useState('');
  const [sqlPort, setSqlPort] = useState('');
  const [sqlUsername, setSqlUsername] = useState('');
  // const [sqlPassword, setSqlPassword] = useState('');
  const [sqlUnsetPassword, setSqlUnsetPassword] = useState('');

  const [sqlDatabase, setSqlDatabase] = useState('');

  useEffect(() => {
    setErrorMessage('');
  }, [sqlType, sqlHost, sqlPort, sqlUsername, sqlDatabase]);

  useEffect(() => {
    if (item) {
      const selectedType = sqlTypes.find((val: any) => item.type === val.value);
      selectedType && setSqlType(selectedType);
      // selectedType && setSqlInitialType(selectedType.value);

      setSqlHost(item.host);
      setSqlPort(item.port);
      setSqlUsername(item.username);
      // setSqlPassword(item.password);
      setSqlDatabase(item.database);
    }
  }, [item]);

  const handleModalClose = () => {
    setOpenSqlModal(false);
    setSqlType(sqlTypes[0]);
    setSqlHost('');
    setSqlPort('');
    setSqlUsername('');
    // setSqlPassword('');
    setSqlDatabase('');
  };

  const [createSQLConnection, { loading: createSQLConnectionLoading, data: createSQLConnectionData }] = useLazyQuery(
    CREATE_SQL_CONNECTION,
    {
      onCompleted(createSQLConnectionData) {
        setCustomLoading(true);
        if (createSQLConnectionData?.HexomaticIntegrations?.createSQLConnection?.error_code) {
          setErrorMessage('Something went wrong. Please try again.');
        } else {
          handleModalClose();
          refetchDataList();
        }
        setCustomLoading(false);
      },
    },
  );
  const [updateSQLConnection, { loading: updateSQLConnectionLoading, data: updateSQLConnectionData }] = useLazyQuery(
    UPDATE_SQL_CONNECTION,
    {
      onCompleted(updateSQLConnectionData) {
        setCustomLoading(true);
        if (updateSQLConnectionData?.HexomaticIntegrations?.updateSQLConnection?.error_code) {
          setErrorMessage('Something went wrong. Please try again.');
        } else {
          handleModalClose();
          refetchDataList();
        }
        setCustomLoading(false);
      },
    },
  );

  const handleCreateSQLConnection = async () => {
    if (
      !sqlHost?.trim() ||
      (typeof sqlPort === 'string' ? !sqlPort?.trim() : !sqlPort) ||
      !sqlUsername?.trim() ||
      !sqlDatabase?.trim() ||
      !sqlUnsetPassword?.trim()
    ) {
      setErrorMessage('Please fill in all the required fields.');
      return;
    } else if (typeof sqlPort === 'string' && isNaN(parseInt(sqlPort?.trim()))) {
      setErrorMessage('Port field should be a number.');
      return;
    }

    updateSqlConnectionId
      ? updateSQLConnection({
          variables: {
            settings: {
              id: updateSqlConnectionId,
              type: sqlType.value,
              host: sqlHost?.trim(),
              port: typeof sqlPort === 'string' ? sqlPort?.trim() && +sqlPort : sqlPort,
              username: sqlUsername?.trim(),
              password: sqlUnsetPassword?.trim(),
              database: sqlDatabase?.trim(),
            },
          },
        })
      : createSQLConnection({
          variables: {
            settings: {
              type: sqlType.value,
              host: sqlHost?.trim(),
              port: typeof sqlPort === 'string' ? sqlPort?.trim() && +sqlPort : sqlPort,
              username: sqlUsername?.trim(),
              password: sqlUnsetPassword?.trim(),
              database: sqlDatabase?.trim(),
            },
          },
        });
  };

  return (
    <CustomModal ShowPopup={openSqlModal} noNavigate escButtonClick={() => {
      setOpenSqlModal(false);
      setSqlType(sqlTypes[0]);
      setSqlHost('');
      setSqlPort('');
      setSqlUsername('');
      setSqlDatabase('');
    }}>
      <div className="sql-modal-wrapper">
        <div className="close-button-wrapper">
          <button
            onClick={() => {
              setOpenSqlModal(false);
              setSqlType(sqlTypes[0]);
              setSqlHost('');
              setSqlPort('');
              setSqlUsername('');
              setSqlDatabase('');
            }}
          >
            {closeIcon}
          </button>
        </div>
        <h5 className="text-center dark-text">Please fill in the fields</h5>
        <Row>
          <Col lg={6} className="mb-2">
            <span className="input-label">Type</span>
            <Select
              options={sqlTypes}
              value={sqlType}
              placeholder={'Select type'}
              onChange={(selectedItem: any) => setSqlType(selectedItem)}
            />
          </Col>
          <Col lg={6} className="mb-2">
            <span className="input-label">Host</span>
            <Input
              value={sqlHost}
              onChange={e => setSqlHost(e.target.value)}
              className={errorMessage && !sqlHost?.trim() ? 'error-border' : ''}
            />
          </Col>
          <Col lg={6} className="mb-2">
            <span className="input-label">Port</span>
            <Input
              value={sqlPort}
              onChange={e => setSqlPort(e.target.value)}
              className={
                errorMessage &&
                ((typeof sqlPort === 'string' ? !sqlPort?.trim() : !sqlPort) ||
                  errorMessage === 'Port field should be a number.')
                  ? 'error-border'
                  : ''
              }
            />
          </Col>
          <Col lg={6} className="mb-2">
            <span className="input-label">Username</span>
            <Input
              value={sqlUsername}
              onChange={e => {
                setSqlUsername(e.target.value);
              }}
              className={errorMessage && !sqlUsername?.trim() ? 'error-border' : ''}
            />
          </Col>
          {
            <Col lg={6} className="mb-2">
              <span className="input-label">Password</span>
              <Input
                value={sqlUnsetPassword}
                onChange={e => setSqlUnsetPassword(e.target.value)}
                type={showPassword ? 'text' : 'password'}
                rightAddon={
                  <div
                    className={`eye-icon position-absolute ${showPassword ? 'active' : ''}`}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {eyeIcon}
                  </div>
                }
                className={errorMessage ? 'error-border pr-4' : 'pr-4'}
              />
            </Col>
          }
          <Col lg={6} className="mb-2">
            <span className="input-label">Database</span>
            <Input
              value={sqlDatabase}
              onChange={e => {
                setSqlDatabase(e.target.value);
              }}
              className={errorMessage && !sqlDatabase?.trim() ? 'error-border' : ''}
            />
          </Col>
          {errorMessage && (
            <Col md={12} className="mt-2 mb-2 text-center">
              <Alert type="error" className="create-workflow">
                {errorMessage}
              </Alert>
            </Col>
          )}
          <Col md={12} className="mb-2">
            <Button
              name="Connect"
              outline
              className="btn_integration not-integrated m-auto"
              onClick={handleCreateSQLConnection}
              disabled={createSQLConnectionLoading || customLoading || updateSQLConnectionLoading}
              loading={createSQLConnectionLoading || customLoading || updateSQLConnectionLoading}
            />
          </Col>
        </Row>
      </div>
    </CustomModal>
  );
};

export default SQLModal;
