import { FC, useContext, useEffect, useState } from 'react';
import Radio from '../../atom/radio';
import Counter from '../counter';
import holidaySale from 'assets/premiums-promo/popup-text.svg';
import holidaySaleMobile from 'assets/premiums-promo/popup-text-mobile.svg';

import { useMediaQuery } from 'react-responsive';
import { UserContext } from 'contexts/user-context';

interface IItem {
  title: string;
  value: number;
  price: number;
  id: string;
  paypal_id: string;
}

interface IOffer {
  selectedPremium: IItem;
  setSelectedPremium: (val: IItem) => void;
  premiumOptions: any;
  setShowMonthly?: any;
  showMonthly?: any;
}
const PremiumCreditOffer: FC<IOffer> = ({
  selectedPremium,
  setSelectedPremium,
  showMonthly,
  setShowMonthly,
  premiumOptions,
}) => {
  const vals = [
    { title: '100', value: 100, price: 99, id: '142e0015-0880-45dc-98aa-e42c3cdc85b0' },
    { title: '150', value: 150, price: 139, id: 'de149f7f-c322-4c3a-bb74-5b34138fe8ee' },
    { title: '250', value: 250, price: 225, id: '1881cb30-5f14-4851-a879-ace6406743e6' },
    { title: '500', value: 500, price: 399, id: 'd9b6550e-ce98-4ac7-b943-92260be12a49' },
    { title: '1500', value: 1500, price: 999, id: '856d61f0-19fa-4f7c-aa7a-6230b30e45b1' },
  ];
  const [localPCoptions, setLocalPCOptions] = useState(premiumOptions);
  const { user } = useContext(UserContext);
  const discount = [15, 20, 25, 33, 33];
  const real_price = [119, 179, 299, 599, 1499];
  const isMobile = useMediaQuery({ maxWidth: 991 });
  const isMobileSM = useMediaQuery({ maxWidth: 585 });

  useEffect(() => {
    premiumOptions?.length && setLocalPCOptions(premiumOptions.filter((item: any) => vals.find((sub: any) => sub.price === item.price)));
  }, [premiumOptions]);

  useEffect(() => {
    localPCoptions?.length && setSelectedPremium(localPCoptions[0]);
  }, [localPCoptions]);


  return (
    <>
      <div
        className="offer-information d-block d-sm-flex mb-3 p-2 justify-content-center text-center"
        style={{ background: '#00205B' }}
      >
        <img
          src={isMobile ? holidaySaleMobile : holidaySale}
          alt="promo"
          className="mx-auto mx-sm-0 my-auto"
          // style={{ maxWidth: isMobile ? '100%' : 'calc(100% - 200px)' }}
        />
        <div className="ml-sm-4">
          <Counter date="Oct 26 2024 07:00:00 GMT" text='DEAL ENDS IN...' />
        </div>

        {/* <img src={illustration2} alt='illustration1' className='illustration2 d-none d-lg-block' /> */}
        {/* <div className='d-flex flex-wrap justify-content-center'>
        <div className="mr-2 promo-text-padding">
          <div className='mt-3'><img src={blackFridayText} alt='Black Friday Special' /></div>
          <div className="promo-text">
            <span>
              Lock in up to <span className='highlighted-text'>33% lifetime discount</span> on premium credits with our special BF promo
            </span>{' '}
          </div>
        </div>
        <Counter />
      </div> */}
      </div>

      {/* <div className="text-center mb-2">
        Your premium credits allowance can be accessed via Hexowatch and Hexomatic.
      </div> */}

      <div className="row payment_toolbar py-2">
        {localPCoptions.length >= 1 &&
          localPCoptions.map((item: IItem, index: number) => (
            <div className="col-12 col-md-6 d-flex mb-1">
              <Radio checked={item.value === selectedPremium.value} onClick={() => setSelectedPremium(item)} />
              <div
                className={`cursor-pointer mt-auto mb-auto ${
                  item.value === selectedPremium.value ? 'selected_credit' : ''
                }`}
                onClick={() => setSelectedPremium(item)}
              >
                <div>
                  <span className="credit_bold">{item.value}</span> credits monthly allowance
                </div>
                <div>
                  <span className="text-decoration-line-through">
                    ${real_price[index + (5 - localPCoptions.length)]}
                  </span>{' '}
                  <span className="credit_bold">${item.price}</span> per year
                  <span className="credit_bold ml-1">(Save {discount[index + (5 - localPCoptions.length)]}%)</span>
                </div>
              </div>
            </div>
          ))}
      </div>
      {user && user.premium_credits_pricing_package_interval !== 'ANNUAL' && (
        <div className="d-flex">
          <span
            className="clickable-text mr-2"
            style={{ display: 'inline-block', marginLeft: 'auto' }}
            onClick={() => setShowMonthly(true)}
          >
            Show regular monthly plans
          </span>
        </div>
      )}
    </>
  );
};

export default PremiumCreditOffer;
