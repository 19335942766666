import { FC, useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';

import Button from 'shared-components/atom/button';
import Message from 'shared-components/atom/messages';

import { actionRefreshIcon, closeIcon, questionIcon, refreshIcon } from 'assets/svg-icons';
import {
  ActionPaginationCard,
  CardFooter,
  CardHeader,
  CardName,
  getType,
  PathCard,
  RegexCard,
  SpecifyTextCard,
  TypeCard,
} from './cards';
import { isValidPath } from 'constants/validations';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { chooseName } from 'helpers';
import Tooltip from 'shared-components/atom/tooltip';

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  maxWidth: '450px',
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: `0 auto`,
  // styles we need to apply on draggables
  ...draggableStyle,
});

const RightSide: FC<any> = ({
  confirmedItems,
  dangerItem,
  itemIndex,
  handelAddAction,
  browserMode,
  handlePaginationRemove,
  handleActionRemove,
  handleRemove,
  changeEditState,
  setCurrentTypeElement,
  nameError,
  setNameError,
  completed,
  handleConfirm,
  cancelEdit,
  path,
  pattern,
  setPath,
  setPattern,
  setConfirmedItems,
  setDangerItem,
  setItemIndex,
  setCompleted,
  handleChangeType,
  loading,
  actionsIndexes,
  handelKeyUp,
  handelKeyDown,
  handleRetry,
  error,
  specify,
  setSpecify,
  autoscrapedData,
  handleElementSelect,
  handleHighlight,
  handleRemoveHighlight,
  suggestionsBox,
  setSuggestionsBox,
  setScrollToBottomProp,
  scrollToBottomProp,
}) => {
  //---------------------------State-----------------------------//
  const [blur, setBlur] = useState(false);
  const [cardsContainerHeight, setCardsContainerHeight] = useState('calc(100vh - 380px)');
  //-------------------------Functions--------------------------//
  //EVENTS
  const handleAddCustomElement = () => {
    if (dangerItem === -1) {
      setCompleted(true);
      const arr = [...confirmedItems];
      arr.push({
        name: chooseName(confirmedItems) || `propertyName${arr.length + 1}`,
        count: 0,
        path: '',
        type: 'unset',
        done: false,
        custom: true,
      });
      setDangerItem(arr.length - 1);
      setItemIndex(arr.length - 1);
      setConfirmedItems(arr);
      setScrollToBottomProp(true);
    } else {
      setScrollToBottomProp(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const el = document.getElementById(`cards-container`);
      scrollToBottomProp && el?.scrollBy({ top: el.scrollHeight, behavior: 'smooth' });
      setScrollToBottomProp(false);
    }, 100);
  }, [scrollToBottomProp]);

  const handleEditElement = (index: number) => {
    const arr = [...confirmedItems];
    arr[index].done = false;
    setConfirmedItems(arr);
    setCurrentTypeElement(confirmedItems[index].type);
  };

  const handleCopyElement = (index: number) => {
    const el = { ...confirmedItems[index] };
    const name = el.name + '_copy_';
    const names = confirmedItems.filter(
      (item: { name: string }) =>
        item && item.name && item.name.includes(name) && !item.name.replace(name, '').includes('_copy_'),
    );
    el.name = name + (names.length + 1);
    setConfirmedItems([...confirmedItems, el]);
    setCurrentTypeElement(confirmedItems[index].type);
  };

  const handleChangePropertyName = (e: any, index: number) => {
    const arr = [...confirmedItems];
    if (e.target.value[0] === ' ' || e.target.value[0] === '_') e.target.value = e.target.value.slice(1);

    if (!isNaN(+e.target.value[0])) {
      e.target.value = '_' + e.target.value;
    }
    const contain =
      !e.target.value.replaceAll(' ', '_') || arr.find(item => item.name === e.target.value.replaceAll(' ', '_'));
    contain ? setNameError([...nameError, index]) : setNameError(nameError.filter((item: number) => item !== index));
    arr.splice(index, 1, {
      ...arr[index],
      name: e.target.value.replaceAll(' ', '_'),
    });
    setConfirmedItems(arr);
  };

  const handleChangePropertyPath = (e: any, index: number) => {
    const arr = [...confirmedItems];
    arr[index].path.trim() !== e.target.value.trim() && setBlur(true);
    arr.splice(index, 1, {
      ...arr[index],
      path: e.target.value,
    });
    setConfirmedItems(arr);
    setDangerItem(-1);
    setItemIndex(index);
  };

  const handleChangePropertyRegex = (e: any, index: number) => {
    const arr = [...confirmedItems];
    (arr[index].pattern || '').trim() !== e.target.value.trim() && setBlur(true);
    arr.splice(index, 1, {
      ...arr[index],
      pattern: e.target.value,
    });
    setConfirmedItems(arr);
    setItemIndex(index);
  };

  const handleChangeSpecifyText = (e: any, index: number) => {
    const arr = [...confirmedItems];
    (arr[index].specify || '').trim() !== e.target.value.trim() && setBlur(true);
    arr.splice(index, 1, {
      ...arr[index],
      specify: e.target.value,
    });
    setConfirmedItems(arr);
    setItemIndex(index);
  };
  const handleDragElement = (item: any) => {
    let arr = [...confirmedItems];
    if (item.destination.index > item.source.index) {
      arr.splice(item.destination.index + 1, 0, arr[item.source.index]);
      arr.splice(item.source.index, 1);
    } else {
      arr.splice(item.source.index, 1);
      arr.splice(item.destination.index, 0, confirmedItems[item.source.index]);
    }
    setConfirmedItems(arr);
  };

  const handleDragEnd = (item: any) => {
    if (item.destination.index === item.source.index) return;
    if (confirmedItems[item.source.index].pagination) {
      handleDragElement(item);
      return;
    }
    let start = -1;
    let end = -1;
    actionsIndexes.map((action: number) => {
      if (action < item.source.index) start = action;
      if (action > item.source.index && end === -1) end = action;
    });
    if (!actionsIndexes.length) handleDragElement(item);
    else {
      if (item.destination.index <= start || (end !== -1 && item.destination.index >= end)) return;
      handleDragElement(item);
    }
  };
  const getSampleValue = (index: number) => {
    if (blur) {
      handleConfirm(index, true);
      setBlur(false);
    }
  };
  const handleChangeAttribute = (attribute: string, index: number) => {
    const arr = [...confirmedItems];
    arr.splice(index, 1, { ...arr[index], attribute: attribute || '' });
    setConfirmedItems(arr);
  };

  useEffect(() => {
    const suggestionsBoxHeight = document.getElementById('suggestions-card');
    if (suggestionsBox && autoscrapedData?.length && suggestionsBoxHeight?.offsetHeight) {
      setCardsContainerHeight(`calc(100vh - 380px - ${suggestionsBoxHeight.offsetHeight}px)`);
    } else {
      setCardsContainerHeight('calc(100vh - 380px)');
    }
  }, [suggestionsBox, autoscrapedData]);

  //------------------------------------------------------------//
  return (
    <Col
      sm={{ span: 10, offset: 1 }}
      md={{ span: 8, offset: 2 }}
      lg={{ span: 12, offset: 0 }}
      xl={{ span: 4, offset: 0 }}
      className="scraped-right"
    >
      <div>
        <div className="d-flex justify-content-between mx-auto" style={{ maxWidth: '450px' }}>
          <Button
            outline
            name="+ Add custom element"
            onClick={() => !loading && handleAddCustomElement()}
            className={`add-custom-el-btn mb-4 mr-2 ${dangerItem !== -1 || loading ? 'cursor-not-allowed' : ''}`}
          />
          <Button
            outline
            name="+ Add actions"
            onClick={() => !loading && dangerItem === -1 && handelAddAction()}
            className={`add-custom-el-btn mb-4 ${browserMode === 'Fast' ? 'invisible' : 'visible'} ${
              dangerItem !== -1 || loading ? 'cursor-not-allowed' : ''
            }`}
          />
        </div>
        {suggestionsBox && autoscrapedData?.length ? (
          <div style={{ maxWidth: '450px', margin: 'auto' }}>
            <div className="suggestions-card" id="suggestions-card">
              <div className="suggestions-card-label">Suggested fields to capture</div>
              <div className="close-suggestions-box" onClick={() => setSuggestionsBox(false)}>
                {closeIcon}
              </div>
              <div className="suggestions-card-items">
                {autoscrapedData?.map((item: any, index: number) => (
                  <div
                    onClick={() => handleElementSelect(item, index)}
                    className="suggestion-item"
                    onMouseEnter={() => handleHighlight(item)}
                    onMouseLeave={() => handleRemoveHighlight(item)}
                  >
                    {item.property}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : null}
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {confirmedItems && confirmedItems.length > 0 && (
                  <div
                    className="cards-container"
                    id="cards-container"
                    style={{ maxHeight: cardsContainerHeight, height: cardsContainerHeight }}
                  >
                    {confirmedItems.map((item: any, index: number) => {
                      if (typeof item.title === 'object') item.title = item?.title && item.title[0];
                      if (item.value) {
                        item.value = item.value
                          .replace('hexoscraper-scale', '')
                          .replace('hexoScrapOverlay', '')
                          .replace('hexoScrapSelect', '')
                          .replace('hexoScrapHover', '')
                          .replace('hexoScrap-context', '')
                          .replace('hexoScrap-only-this', '')
                          .replace('hexoScrap-actions-ui', '')
                          .replace('hexoScrap-overlay', '')
                          .replace('class=""', '');
                      }
                      return (
                        <Draggable
                          key={(index + 1).toString()}
                          draggableId={(index + 1).toString()}
                          index={index}
                          isDragDisabled={
                            item.pagination
                              ? false
                              : item.action || (!item.action && (!item.type || !item.path))
                              ? true
                              : false
                          }
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                              onMouseEnter={() => handleHighlight(item)}
                              onMouseLeave={() => handleRemoveHighlight(item)}
                            >
                              <div
                                className={`m-auto ${
                                  item.pagination || (!item.action && (item.type || !item.path)) ? 'dragable-card' : ''
                                }`}
                                onClick={() => getSampleValue(index)}
                              >
                                {item.pagination || item.action ? (
                                  <ActionPaginationCard
                                    item={item}
                                    index={index}
                                    confirmedItems={confirmedItems}
                                    handlePaginationRemove={handlePaginationRemove}
                                    handleActionRemove={handleActionRemove}
                                    loading={loading}
                                  />
                                ) : (
                                  <div
                                    className="editable-card m-horizontal-auto mb-2 position-relative"
                                    id={String(index)}
                                  >
                                    {item.done && (
                                      <CardHeader
                                        item={item}
                                        index={index}
                                        handleEditElement={handleEditElement}
                                        handleCopyElement={handleCopyElement}
                                        handleRemove={handleRemove}
                                      />
                                    )}
                                    {!item.done ? (
                                      <>
                                        {!item.custom && (
                                          <div className="edit-card-header mb-2 position-relative">
                                            <div
                                              className={`mr-2 edit-tab ${item.simpleEdit ? 'active' : ''}`}
                                              onClick={() => changeEditState(true, index)}
                                            >
                                              Fields
                                            </div>
                                            <div
                                              className={`mr-2 edit-tab ${!item.simpleEdit ? 'active' : ''}`}
                                              onClick={() => changeEditState(false, index)}
                                            >
                                              Refine Selector
                                            </div>
                                            <div className="edit-card-close position-absolute">
                                              <Tooltip placement="left" text="Delete">
                                                <span
                                                  className="card-close-icon c-pointer"
                                                  onClick={() => handleRemove(index)}
                                                >
                                                  {closeIcon}
                                                </span>
                                              </Tooltip>
                                            </div>
                                          </div>
                                        )}
                                        {item.custom ? (
                                          <>
                                            <CardName
                                              item={item}
                                              index={index}
                                              handleChangePropertyName={handleChangePropertyName}
                                              handleRemove={handleRemove}
                                              nameError={nameError}
                                              show
                                            />
                                            {/* {nameError === index && (
                                                <Message type="error" text="Name must start with a number or letter." />
                                              )} */}
                                            <TypeCard
                                              item={item}
                                              index={index}
                                              handleChangeType={handleChangeType}
                                              handleChangeAttribute={handleChangeAttribute}
                                              handelKeyUp={handelKeyUp}
                                              handelKeyDown={handelKeyDown}
                                              setItemIndex={setItemIndex}
                                              danger={dangerItem === index && !completed && !item.type}
                                              className={`custom-universal-select-with-margin ${index === confirmedItems.length - 1 && index > 0 ? 'last-element' : ''}`}
                                            />
                                            <PathCard
                                              item={item}
                                              index={index}
                                              handleChangePropertyPath={handleChangePropertyPath}
                                              className={
                                                !isValidPath(item.path) && dangerItem === index && !completed
                                                  ? `border-danger`
                                                  : ''
                                              }
                                              isNew={true}
                                              onBlur={() => {
                                                item.path && path !== item.path && handleConfirm(index, true);
                                              }}
                                              onFocus={() => setPath(item.path)}
                                              show
                                            />
                                            <SpecifyTextCard
                                              item={item}
                                              index={index}
                                              SpecifyTextCard={handleChangeSpecifyText}
                                              isNew={true}
                                              onBlur={() => {
                                                item.specify && specify !== item.specify && handleConfirm(index, true);
                                              }}
                                              onFocus={() => setSpecify(item.specify)}
                                            />
                                            <RegexCard
                                              item={item}
                                              index={index}
                                              handleChangePropertyRegex={handleChangePropertyRegex}
                                              isNew={true}
                                              onBlur={() => {
                                                item.pattern && pattern !== item.pattern && handleConfirm(index, true);
                                              }}
                                              onFocus={() => setPattern(item.pattern)}
                                            />
                                            {/* {nameError === index && (
                                              <Message type="error" text="xPath must start with a number or letter." />
                                            )} */}
                                            <CardFooter
                                              handleConfirm={handleConfirm}
                                              index={index}
                                              cancelEdit={cancelEdit}
                                            />
                                          </>
                                        ) : (
                                          <>
                                            {item.simpleEdit ? (
                                              <>
                                                <CardName
                                                  item={item}
                                                  index={index}
                                                  handleChangePropertyName={handleChangePropertyName}
                                                  handleRemove={handleRemove}
                                                  nameError={nameError}
                                                />

                                                {/* {nameError === index && (
                                                  <Message type="error" text="Name must start with a number or letter." />
                                                )} */}
                                                <div
                                                  className={`${
                                                    item.name && nameError.includes(index) ? 'mt-0' : 'mt-2'
                                                  } mb-1`}
                                                >
                                                  <span className="preview-card-label"># Elements</span>
                                                  <span className="count mr-4">
                                                    {item.count || 0} item{item.count > 1 ? 's' : ''}
                                                  </span>{' '}
                                                </div>
                                                {/* <div className="mt-2 mb-1 d-flex">
                                                  <span className="preview-card-label">Path</span>
                                                  <span className="break-all" title={item.path && item.path.length > 200 && item.path}>
                                                    {item.path && item.path.length > 200 ? `${item.path.slice(0, 200)} ...` : item.path}
                                                  </span>{' '}
                                                </div> */}

                                                <TypeCard
                                                  item={item}
                                                  index={index}
                                                  handleChangeType={handleChangeType}
                                                  handleChangeAttribute={handleChangeAttribute}
                                                  handelKeyUp={handelKeyUp}
                                                  handelKeyDown={handelKeyDown}
                                                  setItemIndex={setItemIndex}
                                                  danger={dangerItem === index && !completed && !item.type}
                                                  className={`custom-universal-select-with-margin ${index === confirmedItems.length - 1 && index > 0 ? 'last-element' : ''}`}
                                                />
                                                {item.type === 'html_tag' && item.attribute && (
                                                  <div className="d-flex mt-1">
                                                    <div className="d-flex">
                                                      <span className="preview-card-label">Attribute</span>
                                                      <p style={{ fontSize: '12px', fontWeight: 500 }}>
                                                        {item.attribute}
                                                      </p>
                                                    </div>
                                                  </div>
                                                )}
                                                {item.type && (
                                                  <div className="mt-2 mb-1 d-flex">
                                                    <span className="preview-card-label">Sample Value</span>
                                                    <span
                                                      className="break-all mr-2"
                                                      title={
                                                        item.type && item.value && item.length > 200
                                                          ? item.value
                                                          : item.title && item.title.length > 200 && item.title
                                                      }
                                                    >
                                                      {item.value
                                                        ? item.value.length > 200
                                                          ? `${item.value.slice(0, 200)} ...`
                                                          : item.value
                                                        : item.title && item.title.length > 200
                                                        ? `${item.title.slice(0, 200)} ...`
                                                        : item.title || 'Data not found'}
                                                    </span>{' '}
                                                  </div>
                                                )}
                                                <CardFooter
                                                  handleConfirm={handleConfirm}
                                                  index={index}
                                                  cancelEdit={cancelEdit}
                                                />
                                              </>
                                            ) : (
                                              <div>
                                                <PathCard
                                                  item={item}
                                                  index={index}
                                                  handleChangePropertyPath={handleChangePropertyPath}
                                                  className={
                                                    !isValidPath(item.path) && dangerItem === index && !completed
                                                      ? `border-danger`
                                                      : ''
                                                  }
                                                  isNew={false}
                                                  onBlur={() => {
                                                    item.path && path !== item.path && handleConfirm(index, true);
                                                  }}
                                                  onFocus={() => setPath(item.path)}
                                                />
                                                <SpecifyTextCard
                                                  item={item}
                                                  index={index}
                                                  handleChangeSpecifyElement={handleChangeSpecifyText}
                                                  isNew={true}
                                                  onBlur={() => {
                                                    item.specify &&
                                                      specify !== item.specify &&
                                                      handleConfirm(index, true);
                                                  }}
                                                  onFocus={() => setSpecify(item.specify)}
                                                />
                                                <RegexCard
                                                  item={item}
                                                  index={index}
                                                  handleChangePropertyRegex={handleChangePropertyRegex}
                                                  isNew={false}
                                                  onBlur={() => {
                                                    item.pattern &&
                                                      pattern !== item.pattern &&
                                                      handleConfirm(index, true);
                                                  }}
                                                  onFocus={() => setPattern(item.pattern)}
                                                />
                                                {nameError === index && (
                                                  <Message
                                                    type="error"
                                                    text="HTML XPath/Selector must start with a number or letter."
                                                  />
                                                )}
                                                <CardFooter
                                                  handleConfirm={handleConfirm}
                                                  index={index}
                                                  cancelEdit={cancelEdit}
                                                />
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <div>
                                        <div className="d-flex mb-1" style={{ marginTop: '-10px' }}>
                                          <span className="preview-card-label">Field Label</span>
                                          <p style={{ fontSize: '12px', fontWeight: 500 }} className="card-name">
                                            {item.name}
                                          </p>
                                        </div>
                                        <div className="mt-2 mb-1">
                                          <span className="preview-card-label"># Elements</span>
                                          <span className="count mr-4" style={{ fontSize: '12px', fontWeight: 500 }}>
                                            {item.count} items
                                          </span>{' '}
                                        </div>
                                        <div className="mt-2 mb-1 d-flex">
                                          <span className="preview-card-label">
                                            XPath/Selector
                                            <Tooltip
                                              placement="right"
                                              element={
                                                <div className="text-left fw_400">
                                                  <div>
                                                    <strong>XPath</strong> is a path expression to <br />
                                                    select nodes from document
                                                  </div>
                                                  <div>
                                                    <strong>Selector</strong> is the part of a CSS rule set <br />
                                                    that actually selects the content
                                                  </div>
                                                </div>
                                              }
                                            >
                                              <span className="ml-1 help-icon">{questionIcon}</span>
                                            </Tooltip>
                                          </span>
                                          <span
                                            className="break-all mr-2"
                                            title={item.path && item.path.length > 200 && item.path}
                                          >
                                            {item.path && item.path.length > 200
                                              ? `${item.path.slice(0, 200)} ...`
                                              : item.path}
                                          </span>{' '}
                                        </div>
                                        {item.pattern && item.pattern && (
                                          <div className="mt-2 mb-1 d-flex">
                                            <span className="preview-card-label">
                                              Pattern
                                              <Tooltip
                                                isSmall
                                                placement="right"
                                                text=" This is a regular expression (sequence of characters) that specifies
                                                    a search pattern."
                                                className="change-visibility"
                                              >
                                                <span className="ml-1 help-icon">{questionIcon}</span>
                                              </Tooltip>
                                            </span>
                                            <span
                                              className="break-all mr-2"
                                              title={item.pattern && item.pattern.length > 200 && item.pattern}
                                            >
                                              {item.pattern && item.pattern.length > 200
                                                ? `${item.pattern.slice(0, 200)} ...`
                                                : item.pattern}
                                            </span>{' '}
                                          </div>
                                        )}
                                        <div className="d-flex">
                                          <div className="d-flex">
                                            <span className="preview-card-label">Type</span>
                                            <p style={{ fontSize: '12px', fontWeight: 500 }}>{getType(item.type)}</p>
                                          </div>
                                        </div>
                                        {item.type === 'html_tag' && item.attribute && (
                                          <div className="d-flex mt-1">
                                            <div className="d-flex">
                                              <span className="preview-card-label">Attribute</span>
                                              <p style={{ fontSize: '12px', fontWeight: 500 }}>{item.attribute}</p>
                                            </div>
                                          </div>
                                        )}
                                        <div className="mt-2 mb-1 d-flex">
                                          <span className="preview-card-label">Sample Value</span>
                                          <span
                                            className="break-all mr-2"
                                            title={
                                              item.type && item.value && item.value.length > 200
                                                ? item.value
                                                : item.title && item.title.length > 200 && item.title
                                            }
                                          >
                                            {item.value
                                              ? item.value.length > 200
                                                ? `${item.value.slice(0, 200)} ...`
                                                : item.value
                                              : item.title && item.title.length > 200
                                              ? `${item.title.slice(0, 200)} ...`
                                              : item.title || 'Data not found'}
                                          </span>{' '}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                                {index === confirmedItems.length - 1 && (item.action || error) && (
                                  <Button
                                    className={`retry-all  ${loading ? 'cursor-not-allowed' : ''}`}
                                    name="Retry"
                                    leftIcon={actionRefreshIcon}
                                    onClick={handleRetry}
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </Col>
  );
};
export default RightSide;
