import { eventStop } from 'helpers';
import useClickOutside from 'hooks/useClickOutside';
import { FC, ReactChild, ReactElement, useEffect, useState, memo, useCallback } from 'react';
import { blockTags } from '../../../constants';
import './styles.scss';
type OverlayTriggerType = 'hover' | 'click' | 'focus';
interface ITooltip {
  placement?: 'left' | 'right' | 'top' | 'bottom';
  text?: string;
  element?: ReactElement;
  children: ReactChild;
  isHover?: boolean;
  className?: string;
  isSmall?: boolean;
  content?: {
    title: string;
    text: ReactElement;
  };
  trigger?: OverlayTriggerType[];
  oneLine?: boolean;
  maxLengthInRow?: number;
  notRelative?: boolean;
}
const Tooltip: FC<ITooltip> = ({
  placement = 'top',
  text,
  element,
  children,
  isHover = true,
  className,
  isSmall,
  content,
  trigger = ['hover'],
  oneLine,
  maxLengthInRow,
  notRelative,
}) => {
  const [show, setShow] = useState(false);
  const [toggle] = useState(Math.floor(Math.random() * 100000));
  const [inline, setInline] = useState(false);
  const [height, setHeight] = useState(25);
  const [isOnlyClick] = useState(trigger.length === 1 && trigger[0] === 'click');
  const [showOptions, selectRef, setShowOptions] = useClickOutside(false, val => {
    setShow(val);
  });

  useEffect(() => {
    if (
      (placement === 'bottom' || placement === 'top') &&
      document.querySelector(`.tooltip-text-${toggle}`) &&
      document.querySelector(`#tooltip-hover-${toggle}`)
    ) {
      //@ts-ignore
      document.querySelector(`#tooltip-hover-${toggle}`).classList.add('d-block');
      const height =
        //@ts-ignore
        document.querySelector(`.tooltip-text-${toggle}`)?.offsetHeight;
      setHeight(height || 28);
      //@ts-ignore
      document.querySelector(`#tooltip-hover-${toggle}`).classList.remove('d-block');
    }
  }, [document.querySelector(`.tooltip-text-${toggle}`)]);

  useEffect(() => {
    //@ts-ignore
    const el = String(children?.type);
    setInline(!blockTags.find((item: string) => item === el));
    if (!trigger.includes('focus')) return;
    //@ts-ignore
    const class_name = (children.props.className || '').trim().replaceAll(' ', '.').replaceAll('..', '.');
    class_name && document.querySelector(`.${class_name}`)?.classList.add('tooltip-children');
    let isIncluded = false;
    isIncluded =
      class_name &&
      (document.querySelector(`.tooltip-${toggle} .${class_name}`)?.tagName === 'TEXTAREA' ||
        document.querySelector(`.tooltip-${toggle} .${class_name}`)?.tagName === 'INPUT' ||
        document.querySelector(`.tooltip-${toggle} .${class_name}`)?.tagName === 'BUTTON' ||
        document.querySelector(`.tooltip-${toggle} .${class_name}`)?.tagName === 'SELECT');

    const elem =
      class_name &&
      (isIncluded
        ? document.querySelector(`.tooltip-${toggle} .${class_name}`)
        : document.querySelector(`.tooltip-${toggle} .${class_name} input`) ||
          document.querySelector(`.tooltip-${toggle} .${class_name} textarea`) ||
          document.querySelector(`.tooltip-${toggle} .${class_name} select`) ||
          document.querySelector(`.tooltip-${toggle} .${class_name} button`));

    elem &&
      elem.addEventListener('focus', () => {
        const a = document.getElementById(`tooltip-hover-${toggle}`);
        if (a) {
          a.classList.add('d-block');
        }
      });
    elem &&
      elem.addEventListener('blur', () => {
        const a = document.getElementById(`tooltip-hover-${toggle}`);
        if (a) {
          a.classList.remove('d-block');
        }
      });
  }, [children, toggle]);

  const handleClick = () => {
    isOnlyClick && setShow(!show);
  };

  const getClasses = useCallback(() => {
    let classes = '';
    trigger.forEach(item => {
      classes += `${item} `;
    });
    return classes;
  }, []);

  return (
    <div
      className={`tooltip-wrapper tooltip-${toggle} ${notRelative ? '' : 'position-relative'} ${className ? className : ''} ${
        !isOnlyClick && inline ? 'd-inline' : ''
      } ${getClasses() ? getClasses() : ''} `}
      onClick={handleClick}
      ref={selectRef}
    >
      <div
        id={`tooltip-hover-${toggle}`}
        className={`position-absolute ${
          isOnlyClick ? 'tooltip-click' : isHover ? 'tooltip-hover' : 'd-none'
        } tooltip-hover-${placement} ${
          text && (oneLine ? '' : text.length > (isSmall ? maxLengthInRow || 50 : 120)) ? 'no-wrap' : ''
        } ${inline ? 'inline' : ''} ${content ? 'content' : ''} ${!show ? 'not-show' : ''} ${
          isSmall && text && text.length > (maxLengthInRow || 50) ? 'top_more' : ''
        } ${!text && !element && !content ? 'd-none' : ''}`}
        style={{ minHeight: height + 'px' }}
        onClick={eventStop}
      >
        {((isOnlyClick && show) || !isOnlyClick) && (
          <>
            {text && <span className={`tooltip-text tooltip-text-${toggle}`}>{text}</span>}
            {element && <span className={`tooltip-text tooltip-text-${toggle}`}>{element}</span>}
            {content && (
              <div className="content-wrapper">
                <h3 className="title">{content.title}</h3>
                <div className="content">{content.text}</div>
              </div>
            )}
          </>
        )}
      </div>
      {children}
    </div>
  );
};

export default memo(Tooltip);
