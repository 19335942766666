import React, { FC, useState, useEffect, useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { CartesianGrid, Legend, XAxis, YAxis, ResponsiveContainer, Tooltip, LineChart, Line, Label } from 'recharts';
import { useQuery } from '@apollo/react-hooks';
import { GET_DAILY_CREDITS_OF_USER, GET_DAILY_STATS_OF_USER } from 'graphql/queries';
import moment from 'moment';

// import { barChartIcon, graphChartIcon, refreshIcon } from 'assets/svg-icons';
import Calendar from '../../molecule/calendar';
import Loader from '../../atom/loader';

import { useContext } from 'react';
import { ThemeContext } from 'contexts/theme-context';
import Minimize from 'shared-components/atom/minimize-icon';
import './styles.scss';

function createMockdata(d1: any, d2: any, arg: string): any[] {
  const date1 = moment(d1);
  const date2 = moment(d2);

  const diff = date2.diff(date1, 'days');

  const array = [];
  let num = diff;

  while (num >= 0) {
    if (arg === 'credit') {
      array.push({
        name: moment(moment(d2).subtract(num, 'days')).format('YYYY-MM-DD').slice(5, 10),
        'Automation Credits': 0,
        'Premium Credits': 0,
      });
    }
    if (arg === 'traffic') {
      array.push({
        name: moment(moment(d2).subtract(num, 'days')).format('YYYY-MM-DD').slice(5, 10),
        Total: 0,
        'Data Center': 0,
        Residential: 0,
        'Residential GEO': 0,
      });
    }

    num--;
  }
  return array;
}

const DashboardChart: FC = () => {
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const { theme } = useContext(ThemeContext);
  const [startDateCredits, setStartDateCredits] = useState(new Date());
  const [endDateCredits, setEndDateCredits] = useState(new Date());
  const [minDateCredits, setMinDateCredits] = useState(new Date());

  const [startDateTraffic, setStartDateTraffic] = useState(new Date());
  const [endDateTraffic, setEndDateTraffic] = useState(new Date());
  const [minDateTraffic, setMinDateTraffic] = useState(new Date());

  const [dailyStatsArray, setDailyStatsArray] = useState([] as any);
  const [dailyCreditsArray, setDailyCreditsArray] = useState([] as any);

  const [showInitialLoader, setShowInitialLoader] = useState(false);

  const [showCreditsChart, setShowCreditsChart] = useState(false);
  const [showTrafficChart, setShowTrafficChart] = useState(false);

  const [trafficTotals, setTrafficTotals] = useState(null as any);
  const [creditsTotal, setCreditsTotals] = useState(null as any);
  const [traffic, setTraffic] = useState(true);

  const creditChartLables = [
    {
      label: `Automation Credits (${creditsTotal?.automationCredits || 0})`,
      color: '#2FA84F',
    },
    {
      label: `Premium Credits (${(creditsTotal?.premiumCredits || 0).toFixed(2)})`,
      color: '#B72CFF',
    },
  ];

  const trafficChartLables = [
    {
      label: `Total (${(trafficTotals?.total || 0).toFixed(2)} MB / ${(
        (trafficTotals?.proxy_unmetered || 0) * 0.1 +
        (trafficTotals?.proxy_premium || 0) * 0.12
      ).toFixed(2)} premium credits)`,
      color: '#EA3D2F',
    },
    {
      label: `Data Center (${(trafficTotals?.network || 0).toFixed(2)} MB)`,
      color: '#F3AA18',
    },
    {
      label: `Residential (${(trafficTotals?.proxy_unmetered || 0).toFixed(2)} MB / ${(
        (trafficTotals?.proxy_unmetered || 0) * 0.1
      ).toFixed(2)} premium credits)`,
      color: '#2FA84F',
    },
    {
      label: `Residential GEO (${(trafficTotals?.proxy_premium || 0).toFixed(2)} MB / ${(
        (trafficTotals?.proxy_premium || 0) * 0.12
      ).toFixed(2)} premium credits)`,
      color: '#B72CFF',
    },
  ];

  const { data: dailyCredits, loading: dailyCreditsLoading } = useQuery(GET_DAILY_CREDITS_OF_USER, {
    fetchPolicy: 'no-cache',
    skip: startDateCredits?.getTime() === endDateCredits?.getTime(),
    variables: {
      settings: {
        date_from: moment(startDateCredits).format('YYYY-MM-DD') + 'T00:00:00',
        date_to: moment(endDateCredits).format('YYYY-MM-DD') + 'T23:59:59',
      },
    },
  });

  const { data: dailyStats, loading: dailyStatsLoading } = useQuery(GET_DAILY_STATS_OF_USER, {
    fetchPolicy: 'no-cache',
    skip: startDateTraffic?.getTime() === endDateTraffic?.getTime(),
    variables: {
      settings: {
        date_from: moment(startDateTraffic).format('YYYY-MM-DD') + 'T00:00:00',
        date_to: moment(endDateTraffic).format('YYYY-MM-DD') + 'T23:59:59',
      },
    },
  });

  useEffect(() => {
    if (dailyCredits?.HexomaticUser?.getDailyCreditsOfUser?.daily_credits) {
      const parsed = JSON.parse(dailyCredits?.HexomaticUser?.getDailyCreditsOfUser?.daily_credits);
      setDailyCreditsArray(parsed?.creditsData);
      setShowInitialLoader(false);
      let creditsSum = 0;
      let premiumsSum = 0;
      parsed?.creditsData.map((item: any) => {
        if (typeof item.credits.credit === 'number') {
          creditsSum += item.credits.credit;
        }
        if (typeof item.credits.premium_credit === 'number') {
          premiumsSum += item.credits.premium_credit;
        }
      });
      setCreditsTotals({
        automationCredits: creditsSum,
        premiumCredits: premiumsSum,
      });
    }
  }, [dailyCredits, startDateTraffic]);

  useEffect(() => {
    if (dailyStats?.HexomaticUser?.getDailyStatsOfUser?.daily_stats) {
      const parsed = JSON.parse(dailyStats?.HexomaticUser?.getDailyStatsOfUser?.daily_stats);
      setDailyStatsArray(parsed?.trafficData);
      setShowInitialLoader(false);
    }
    if (dailyStats?.HexomaticUser?.getDailyStatsOfUser?.total_stats) {
      setTrafficTotals({
        request: (dailyStats.HexomaticUser.getDailyStatsOfUser.total_stats.request || 0) / 1000000,
        network: (dailyStats.HexomaticUser.getDailyStatsOfUser.total_stats.network || 0) / 1000000,
        proxy_unmetered: (dailyStats.HexomaticUser.getDailyStatsOfUser.total_stats.proxy_unmetered || 0) / 1000000,
        proxy_premium: (dailyStats.HexomaticUser.getDailyStatsOfUser.total_stats.proxy_premium || 0) / 1000000,
        total: (dailyStats.HexomaticUser.getDailyStatsOfUser.total_stats.total || 0) / 1000000,
      });
    }
  }, [dailyStats, startDateCredits]);

  useEffect(() => {
    const minDate = new Date(1621540800000);
    minDate.setDate(1);
    setMinDateCredits(minDate);
    const beginningDate = moment();
    if (beginningDate.diff(minDate, 'day') > 7) {
      const date = beginningDate.subtract(30, 'day').toDate();
      date.setHours(0, 0, 0);
      setStartDateCredits(date);
    } else {
      minDate.setHours(0, 0, 0);
      setStartDateCredits(minDate);
    }
    if (localStorage.getItem('showTrafficChart')) {
      setShowTrafficChart(eval(localStorage.getItem('showTrafficChart') || 'true'));
    }
    if (localStorage.getItem('showCreditsChart')) {
      setShowCreditsChart(eval(localStorage.getItem('showCreditsChart') || 'true'));
    }
  }, []);

  useEffect(() => {
    const minDate = new Date(1621540800000);
    minDate.setDate(1);
    setMinDateTraffic(minDate);
    const beginningDate = moment();
    if (beginningDate.diff(minDate, 'day') > 7) {
      const date = beginningDate.subtract(30, 'day').toDate();
      date.setHours(0, 0, 0);
      setStartDateTraffic(date);
    } else {
      minDate.setHours(0, 0, 0);
      setStartDateTraffic(minDate);
    }
  }, []);

  const createCreditData = useMemo(() => {
    return (
      dailyCreditsArray.length &&
      dailyCreditsArray.map((item: any) => ({
        name: item?.date.slice(5, 10),
        'Automation Credits': +item?.credits?.credit.toFixed(2) || 0,
        'Premium Credits': +item?.credits?.premium_credit.toFixed(2) || 0,
      }))
    );
  }, [dailyCreditsArray]);

  const createTrafficData = useMemo(() => {
    return (
      dailyStatsArray.length &&
      dailyStatsArray.map((item: any) => ({
        name: item.date.slice(5, 10),
        Total: +(item?.stats?.total / 1000 / 1000).toFixed(2) || 0,
        'Data Center': +(item?.stats?.network / 1000 / 1000).toFixed(2) || 0,
        Residential: +(item?.stats?.proxy_unmetered / 1000 / 1000).toFixed(2) || 0,
        'Residential GEO': +(item?.stats?.proxy_premium / 1000 / 1000).toFixed(2) || 0,
      }))
    );
  }, [dailyStatsArray]);

  // const getDataInterval = (length: number): number => Math.round(length / 30);
  const getDataInterval = (length: number): number => {
    return Math.round(length / 30);
  };
  const handleChangeChartVisibility = () => {
    setShowCreditsChart(!showCreditsChart);
    localStorage.setItem('showCreditsChart', String(!showCreditsChart));
  };
  const handleChangeTrafficChartVisibility = () => {
    setShowTrafficChart(!showTrafficChart);
    localStorage.setItem('showTrafficChart', String(!showTrafficChart));
  };
  const handelChange = (start: Date, end: Date) => {
    setStartDateCredits(start && new Date(start?.setHours(0, 0, 0, 999)));
    setEndDateCredits(end && new Date(end?.setHours(23, 59, 59, 999)));
    setShowInitialLoader(true);
  };
  const handleChangeTraffic = (start: Date, end: Date) => {
    setStartDateTraffic(start && new Date(start?.setHours(0, 0, 0, 999)));
    setEndDateTraffic(end && new Date(end?.setHours(23, 59, 59, 999)));
    setShowInitialLoader(true);
  };
  const handleTabChange = (e: any, showTraffic: boolean) => {
    e.preventDefault();
    e.stopPropagation();
    setTraffic(showTraffic);
    !showTrafficChart && setShowTrafficChart(true);
  };

  return (
    <div className="mt-2 chart-pulse-container">
      {showInitialLoader || dailyStatsLoading ? (
        <div className="pulse mb-2">
          <div className="line-graph"></div>
        </div>
      ) : (
        <Card className="event-feed-chart">
          <div
            className={`card-body-wrapper ${showTrafficChart ? '' : 'cursor-pointer'}`}
            onClick={() => !showTrafficChart && setShowTrafficChart(true)}
          >
            <div className={`stats-header ${showTrafficChart ? 'mb-2' : ''}`}>
              <div className="chart-tabs-container">
                <div className={`tab-left ${traffic ? 'active' : ''}`} onClick={(e) => handleTabChange(e, true)}>
                  Traffic Usage
                </div>
                <div className={`tab-right ${traffic ? '' : 'active'}`} onClick={(e) => handleTabChange(e, false)}>
                  Credits Usage
                </div>
              </div>
              <div
                onClick={handleChangeTrafficChartVisibility}
                className={`position-absolute cursor-pointer chart-icon`}
              >
                <Minimize bool={showTrafficChart} />
              </div>
              {showTrafficChart &&
                (traffic ? (
                  <Calendar
                    handleChange={handleChangeTraffic}
                    startPropDate={startDateTraffic}
                    endPropDate={endDateTraffic}
                    maxDate={new Date()}
                    minDate={minDateTraffic}
                    calendar
                  />
                ) : (
                  <Calendar
                    handleChange={handelChange}
                    startPropDate={startDateCredits}
                    endPropDate={endDateCredits}
                    maxDate={new Date()}
                    minDate={minDateCredits}
                    calendar
                  />
                ))}
            </div>
            {showTrafficChart && (
              <div className="chart-container">
                {!showInitialLoader || !dailyStatsLoading ? (
                  traffic ? (
                    <ResponsiveContainer>
                      <LineChart
                        margin={{ right: 20, top: 10 }}
                        width={730}
                        height={200}
                        data={createTrafficData || createMockdata(startDateTraffic, endDateTraffic, 'traffic')}
                      >
                        <XAxis
                          dataKey="name"
                          axisLine={false}
                          tickLine={false}
                          angle={-60}
                          tickMargin={20}
                          interval={
                            (createTrafficData.length <= 40 && !isMobile) ||
                            (createMockdata(startDateTraffic, endDateTraffic, 'traffic').length <= 40 && !isMobile)
                              ? 0
                              : isMobile
                              ? getDataInterval(
                                  createTrafficData.length ||
                                    createMockdata(startDateCredits, endDateCredits, 'traffic').length,
                                ) * 2 // number 2 posibly needs to be changed
                              : getDataInterval(
                                  createTrafficData.length ||
                                    createMockdata(startDateCredits, endDateCredits, 'traffic').length,
                                )
                          }
                          tick={{ fill: '#b7b3b3' }}
                        />
                        <YAxis
                          axisLine={false}
                          tickLine={false}
                          tickMargin={5}
                          minTickGap={2}
                          interval={0}
                          tick={{ fill: '#b7b3b3' }}
                        />
                        <Tooltip cursor={{ fill: 'transparent' }} />
                        <Legend
                          content={
                            <div className="d-flex" style={{ flexWrap: 'wrap', marginTop: '30px', marginLeft: '50px' }}>
                              {trafficChartLables.map((item: { label: string; color: string }) => (
                                <div key={item.label} style={{ marginRight: '20px' }}>
                                  <div className="custom-legend-checks">
                                    <div className="legend-div" style={{ background: item.color }}></div>{' '}
                                    {item.label.slice(0, item.label.indexOf('('))}
                                  </div>
                                  <div className="custom-legend-checks light pl-3">
                                    {item.label.slice(item.label.indexOf('('))}
                                  </div>
                                </div>
                              ))}
                            </div>
                          }
                        />
                        <CartesianGrid
                          strokeDasharray="6 3"
                          vertical={false}
                          stroke={theme === 'dark' ? '#353e53' : '#E3E5E6'}
                        />
                        <Line
                          type="monotone"
                          dataKey="Total"
                          stroke="#EA3D2F"
                          activeDot={{ r: 3 }}
                          dot={false}
                          strokeWidth={2}
                        />
                        <Line
                          type="monotone"
                          dataKey="Data Center"
                          stroke="#F3AA18"
                          activeDot={{ r: 3 }}
                          dot={false}
                          strokeWidth={2}
                        />
                        <Line
                          type="monotone"
                          dataKey="Residential"
                          stroke="#2FA84F"
                          activeDot={{ r: 3 }}
                          dot={false}
                          strokeWidth={2}
                        />
                        <Line
                          type="monotone"
                          dataKey="Residential GEO"
                          stroke="#B72CFF"
                          activeDot={{ r: 3 }}
                          dot={false}
                          strokeWidth={2}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  ) : (
                    <ResponsiveContainer>
                      <LineChart
                        margin={{ right: 20, top: 10 }}
                        width={730}
                        height={200}
                        data={createCreditData || createMockdata(startDateCredits, endDateCredits, 'credit')}
                      >
                        <XAxis
                          dataKey="name"
                          axisLine={false}
                          tickLine={false}
                          angle={-60}
                          tickMargin={20}
                          interval={
                            (createCreditData.length <= 40 && !isMobile) ||
                            (createMockdata(startDateCredits, endDateCredits, 'credit').length <= 40 && !isMobile)
                              ? 0
                              : isMobile
                              ? getDataInterval(
                                  createCreditData.length ||
                                    createMockdata(startDateCredits, endDateCredits, 'credit').length,
                                ) * 2 // number 2 posibly needs to be changed
                              : getDataInterval(
                                  createCreditData.length ||
                                    createMockdata(startDateCredits, endDateCredits, 'credit').length,
                                )
                          }
                          tick={{ fill: '#b7b3b3' }}
                        />
                        <YAxis
                          axisLine={false}
                          tickLine={false}
                          tickMargin={5}
                          minTickGap={2}
                          interval={0}
                          tick={{ fill: '#b7b3b3' }}
                        />
                        <Tooltip cursor={{ fill: 'transparent' }} />
                        <Legend
                          content={
                            <div className="d-flex" style={{ flexWrap: 'wrap', marginTop: '30px', marginLeft: '50px' }}>
                              {creditChartLables.map((item: { label: string; color: string }) => (
                                <div key={item.label} className="custom-legend-checks" style={{ marginRight: '20px' }}>
                                  <div className="legend-div" style={{ background: item.color }}></div> {item.label}
                                </div>
                              ))}
                            </div>
                          }
                        />
                        <CartesianGrid
                          strokeDasharray="6 3"
                          vertical={false}
                          stroke={theme === 'dark' ? '#353e53' : '#E3E5E6'}
                        />
                        <Line
                          type="monotone"
                          dataKey="Automation Credits"
                          stroke="#2FA84F"
                          activeDot={{ r: 3 }}
                          dot={false}
                          strokeWidth={2}
                        />
                        <Line
                          type="monotone"
                          dataKey="Premium Credits"
                          stroke="#B72CFF"
                          activeDot={{ r: 3 }}
                          dot={false}
                          strokeWidth={2}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  )
                ) : (
                  <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Loader light={theme === 'light'} size="sm" />
                  </div>
                )}
              </div>
            )}
          </div>
        </Card>
      )}
    </div>
  );
};

export default React.memo(DashboardChart);
