import { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import { useAutomationGlobalMethods } from 'automation-settings/hooks';
import Select from 'shared-components/atom/select';
import CustomMultiSelect from 'shared-components/molecule/multiselect';

const fields = [
  { label: 'Logo', value: '_logo' },
  { label: 'Favicon', value: '_favicon' },
];

const LogoFaviconScraper: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);
  const [clicked, setClicked] = useState(false);
  const [selectedSource, setSelectedSource] = useState<any>(null);
  const [selectedFields, setSelectedFields] = useState(fields);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const {
    getPreviousAutomationOutputOrPreviousRecipKeys,
    liftingSettings,
    restoreSelectedSource,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    setAutomationSendSettings,
    selectedRecipes,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (!selectedSource || !selectedFields || !selectedFields.length) {
      return false;
    }
    return true;
  };

  //getting previous outputs
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, [isSettingsChange]);

  useEffect(() => {
    if (fieldList?.length === 1) {
      setSelectedSource(fieldList[0]);
      setIsDirty();
      setClicked(true);
    }
  }, [fieldList]);

  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const currentAutomation = automationSendSettings.find((automation: any, index: any) => index === automationIndex);
    const { dynamic_settings } = currentAutomation;

    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];
    let changed = false;
    filteredOptions.map((item: any) => !fieldList.find((sub: any) => sub.value === item.value) && (changed = true));
    const update = filteredOptions.length !== fieldList.length || changed;
    if (filteredOptions.length === 0) {
      setSelectedSource(null);
      setClicked(true);
    }
    if (update) {
      const selOptionFound = filteredOptions.find((item: any) => dynamic_settings?.keys?.[0]?.key === item.value);
      setSelectedSource(!selOptionFound ? null : selOptionFound);
      setClicked(true);
      setFieldList(filteredOptions);
      setFieldListWithTypes(optionsWithTypes);
    }
  }, [automationSendSettings]);

  useEffect(() => {
    if (automationSendSettings) {
      const currentAutomation = automationSendSettings.find((automation: any, index: any) => index === automationIndex);
      const { dynamic_settings } = currentAutomation;
      if (dynamic_settings) {
        const { settings, key } = dynamic_settings.keys[0];
        restoreSelectedSource({ key: key, fieldList: fieldList, setSelectedSource: setSelectedSource });
        const arr = [] as any;
        settings?.logo && arr.push({ label: 'Logo', value: '_logo' });
        settings?.favicon && arr.push({ label: 'Favicon', value: '_favicon' });
        setSelectedFields(arr);
      }
    }
  }, [fieldList]);

  useEffect(() => {
    if (fieldList?.length) {
      if (clicked) return;
      const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
      liftingSettings({
        outputKeysData: [
          ...fieldList.map((item: { value: any }) => item.value),
          ...selectedFields.map((item: any) => item.value),
        ],
        outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
      });
    }
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);

  //lifting data
  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    // setClicked(false);
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: {
        keys: [
          {
            inputValue: null,
            key: fieldList.length && selectedSource ? selectedSource.value : null,
            settings: {
              favicon: !!selectedFields.find((item: any) => item.value === '_favicon'),
              logo: !!selectedFields.find((item: any) => item.value === '_logo'),
            },
          },
        ],
      },
      outputKeysData: [
        ...fieldList.map((item: { value: any }) => item.value),
        ...selectedFields.map((item: any) => item.value),
      ],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [selectedSource, selectedFields, fieldListWithTypes, automationOutputTypes]);

  return (
    <Row className="">
      <Col lg={6} className="mb-4">
        <span className="label secondary-color">Source</span>
        <Select
          options={fieldList}
          value={Array.isArray(selectedSource) ? selectedSource[0] : selectedSource}
          placeholder={'Select source'}
          onChange={(selectedItem: any) => {
            setSelectedSource(selectedItem);
            !clicked && setClicked(true);
            setIsDirty();
          }}
        />
      </Col>
      <Col lg={6}>
        <span className="label secondary-color">Fields</span>
        <CustomMultiSelect
          options={fields}
          value={selectedFields}
          onChange={(sel: any) => {
            setSelectedFields(sel);
            !clicked && setClicked(true);
            setIsDirty();
          }}
          labelledBy="select"
        />
      </Col>
    </Row>
  );
};

export default LogoFaviconScraper;
