import { FC } from 'react';
import { Breadcrumb, BreadcrumbItem, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { BreadcrumbComponentProps } from '../types';
import { convertURLToName, cleanURL } from 'helpers';

import './styles.scss';

const BreadcrumbLinks: FC<BreadcrumbComponentProps> = ({
  items,
  scanDate,
  urlName,
  rightAddOn,
  text,
  className,
  noBorderLine
}) => {
  return (
    <div className={`d-flex ${className ? className : ''}`} style={{ flexWrap: 'wrap' }}>
      <Col lg={rightAddOn ? 6 : 12} className="p-0">
        <Breadcrumb>
          {items.map(item => (
            <BreadcrumbItem key={item.to} active={item.active}>
              {item.externalLink ? (
                <>
                  <span>{item.name}</span>{' '}
                  <a href={cleanURL(item.to)} target="_blank" rel='noopener'>
                    {item.to}
                  </a>
                </>
              ) : item.onClick ? (
                <li className='breadcrumb-item' onClick={(e) => {e.stopPropagation(); e.preventDefault(); item.onClick && item.onClick()}}>{item.name}</li>
              ) : item.active ? (
                item.name
              ) : (
                <Link to={item.to}>{item.name}</Link>
              )}
            </BreadcrumbItem>
          ))}
          {!noBorderLine && (
            <div className="editable-name">
              <span className="edit-border" />
            </div>
          )}
        </Breadcrumb>
      </Col>

      {rightAddOn && (
        <Col lg={6} style={{ textAlign: 'end' }}>
          {rightAddOn}
        </Col>
      )}
      <Col md={12}>
        {scanDate && (urlName || text) && (
          <div className="breadcrumb-bottom-details">
            <span className="breadcrumb-bottom-details-url">{urlName ? convertURLToName(urlName) : text}</span>
            <span className="breadcrumb-bottom-details-date">
              <span>Scan Date:</span>
              {scanDate}
            </span>
          </div>
        )}
      </Col>
    </div>
  );
};

export default BreadcrumbLinks;
