import { FC, useContext, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { ThemeContext } from 'contexts/theme-context';
import { archiveIcon, deleteIcon } from 'assets/svg-icons';
import { Col } from 'react-bootstrap';
import Loader from 'shared-components/atom/loader';
import Modal from 'shared-components/molecule/modal';
import { DELETE_TASKS, ARCHIVE_TASKS } from 'graphql/queries';
import AlertComponent from 'shared-components/atom/alert-message';

interface IActions {
  checkedItems: number[];
  refetch: any;
}
const Actions: FC<IActions> = ({ checkedItems, refetch }) => {
  const { theme } = useContext(ThemeContext);
  const [modalState, setModalState] = useState('');
  const [deleteTasks, { loading }] = useMutation(DELETE_TASKS);
  const [archiveTasks, { loading: archiveLoading }] = useMutation(ARCHIVE_TASKS);
  const [error, setError] = useState('');
  const action = () => {
    modalState === 'Delete' ? handleDeleteTasks() : handleArchiveTasks();
  };
  const cancel = () => {
    setModalState('');
    refetch();
  };

  const handleDeleteTasks = async () => {
    const resp = await deleteTasks({
      variables: {
        taskIds: checkedItems.map(item => {
          return +item;
        }),
      },
    });
    if (resp?.data?.HexomaticHumanValidationsTasksOps?.deleteHumanValidationTasks?.error) {
      setError('You can delete only tasks with the status "Completed"');
      setTimeout(() => setError(''), 5000);
    }
    cancel();
  };
  const handleArchiveTasks = async () => {
    const resp = await archiveTasks({
      variables: {
        taskIds: checkedItems.map(item => {
          return +item;
        }),
      },
    });

    if (resp?.data?.HexomaticHumanValidationsTasksOps?.archiveHumanValidationTasks?.error) {
      setError('You can archive only tasks with the status "Completed"');
      setTimeout(() => setError(''), 5000);
    }
    cancel();
  };
  return (
    <>
      <Col md={9} className={`px-2 pt-1 tasks-actions my-auto ${checkedItems.length < 1 ? 'invisible' : 'visible'}`}>
        <div className="d-flex">
          <div className="d-flex text-center mr-xl-auto" style={{ width: 'fit-content', maxHeight: '30px' }}>
            <AlertComponent type="error" text={error} className={`${error ? 'visible' : 'invisible'}`} />
          </div>
          <div className="d-flex text-center ml-xl-auto" style={{ width: 'fit-content' }}>
            <div className="archive  position-relative mx-2">
              <div className="cursor-pointer" onClick={() => setModalState('Archive')}>
                {archiveIcon}
              </div>
              <div className="icon-label position-absolute">Archive</div>
            </div>
            <div className="delete position-relative mx-2">
              <div className="cursor-pointer" onClick={() => setModalState('Delete')}>
                {deleteIcon}
              </div>
              <div className="icon-label position-absolute">Delete</div>
            </div>
          </div>
        </div>
      </Col>
      <Modal
        isModalOpen={!!modalState}
        text={`Confirm ${modalState === 'Delete' ? 'deleting' : 'archiving'} ${
          modalState && checkedItems && checkedItems.length > 1 ? checkedItems.length + ' tasks' : 'the task'
        }`}
        actionBtnText={loading || archiveLoading ? <Loader light={theme === 'light'} className="d-inline" /> : 'Yes'}
        cancelBtnText="No"
        actionBtnColor="#B72CFF"
        onActionClick={action}
        onCancelClick={cancel}
        escButtonClick={cancel}
      />
    </>
  );
};

export default Actions;
