import { FC, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAutomationGlobalMethods } from '../../hooks';
import Select from 'shared-components/atom/select';
import Datalist from 'shared-components/atom/dataList';
import { useLazyQuery } from '@apollo/react-hooks';
import { HexomaticUserContext } from 'contexts/hexomatic-user';
import { GET_SQL_CONNECTIONS, GET_SQL_DATABASE_TABLES, GET_SQL_TABLE_COLLECTION_NAMES } from 'graphql/queries';
import Button from 'shared-components/atom/button';
import Input from 'shared-components/atom/input';
import Alert from 'shared-components/atom/alert-message';
import StretchLoading from 'shared-components/molecule/stretch-loading';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import './styles.scss';

const SQLDatabaseConnector: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [options, setOptions] = useState<any>([]);
  const [slackintegration, setSelectedintegration] = useState<any>(null);
  const { hexomaticUser } = useContext(HexomaticUserContext);
  const [userId, setUserId] = useState(hexomaticUser ? hexomaticUser.id : -1);
  const [clicked, setClicked] = useState(true);
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);
  const [connected, setConnected] = useState<any>(null);
  const [tablesList, setTablesList] = useState(null as any);
  const [selectedTable, setSelectedTable] = useState(null as any);
  const [tablesFieldsList, setTablesFieldsList] = useState(null as any);
  const [selectedTableField, setSelectedTableField] = useState(null as any);
  const [saveFrom, setSaveFrom] = useState<number | string>(0);
  const [saveTo, setSaveTo] = useState<number | string>(100);
  const [errorMessage, setErrorMessage] = useState('');
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const [getAllIntegrations, { data: integrationsData, loading: integrationsLoading }] = useLazyQuery(
    GET_SQL_CONNECTIONS,
    { fetchPolicy: 'network-only' },
  );

  const [getDatabaseTables, { data: databaseTables, loading: databaseTablesLoading }] = useLazyQuery(
    GET_SQL_DATABASE_TABLES,
    { fetchPolicy: 'network-only' },
  );

  const [getDatabaseFields, { data: databaseTablesFields, loading: databaseTablesFieldsLoading }] = useLazyQuery(
    GET_SQL_TABLE_COLLECTION_NAMES,
    { fetchPolicy: 'network-only' },
  );

  const {
    liftingSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
  } = useAutomationGlobalMethods({
    automationSendSettings,
    selectedRecipes,
    setAutomationSendSettings,
    automationIndex,
    id,
  });

  const checkFields = () => {
    if (
      !connected ||
      !selectedTableField ||
      !selectedTable ||
      (tablesList && !tablesList.find((item: any) => item.label === selectedTable)) ||
      (tablesFieldsList && !tablesFieldsList.find((item: any) => item.label === selectedTableField))
    )
      return false;
    if (saveFrom >= saveTo) {
      setErrorMessage('"Save To" value should be greater than "Save From" value.');
      return false;
    }
    return true;
  };

  //restore
  useEffect(() => {
    getAllIntegrations({
      variables: { id: userId },
    });
    const { dynamic_settings } = getDynamicAndIntegrationsSettings();
    if (dynamic_settings) {
      setConnected(true);
      const { keys } = dynamic_settings;
      const { settings } = keys[0];
      setSaveFrom(settings.from);
      setSaveTo(settings.to);
      setSelectedTable(settings.tableName);
      setSelectedTableField(settings.collectionName);

      setSelectedintegration({
        label: `${settings.config.type}-${settings.config.database}`,
        value: settings.config.id,
        config: settings.config,
      });
      settings?.config?.id &&
        getDatabaseTables({
          variables: {
            settings: {
              id: settings.config.id,
            },
          },
        });
      settings?.config?.id &&
        getDatabaseFields({
          variables: {
            settings: {
              id: settings.config.id,
              tableName: settings.tableName,
            },
          },
        });
    }
  }, []);

  //recipe fields or output fields
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const filteredOptions =
      automationInputTypes && !automationInputTypes.includes('any')
        ? options.filter(
            (item: any) =>
              (optionsWithTypes &&
                optionsWithTypes[item.value] &&
                (automationInputTypes.includes(optionsWithTypes[item.value]) ||
                  optionsWithTypes[item.value] === 'any')) ||
              !(optionsWithTypes && optionsWithTypes[item.value]),
          )
        : [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, []);

  // getting databases
  useEffect(() => {
    if (integrationsData?.HexomaticIntegrations?.getSQLConnections?.connections) {
      const options = integrationsData.HexomaticIntegrations.getSQLConnections.connections.map((item: any) => {
        return {
          label: item.name ? item.name : `${item.type}-${item.database}`,
          value: item.id,
          config: {
            database: item.database,
            host: item.host,
            password: item.password,
            port: item.port,
            type: item.type,
            username: item.username,
            id: item.id,
          },
        };
      });
      if (slackintegration) {
        const selObj = options.find((item: any) => item.value === slackintegration.value);
        selObj && selObj.label && setSelectedintegration({ ...slackintegration, label: selObj.label });
      }
      // options && setSelectedintegration(options[0]);
      setOptions(options);
      setConnected(options && options.length > 0 ? true : false);
    }
  }, [integrationsData]);

  // getting tables
  useEffect(() => {
    if (databaseTables?.HexomaticIntegrations?.getSQLDatabaseTables?.tables) {
      const options = databaseTables.HexomaticIntegrations.getSQLDatabaseTables.tables.map((item: any) => {
        return {
          label: item,
          value: slackintegration.value,
        };
      });

      // options && setSelectedTable(options[0]);
      // options[0] &&
      // getDatabaseFields({
      //   variables: {
      //     settings: {
      //       id: options[0].value,
      //       tableName: options[0].label,
      //     },
      //   },
      // })
      setTablesList(options);
    }
  }, [databaseTables]);

  // getting table fields
  useEffect(() => {
    if (databaseTablesFields?.HexomaticIntegrations?.getSQLTableCollectionNames?.collection_names) {
      const options = databaseTablesFields.HexomaticIntegrations.getSQLTableCollectionNames.collection_names.map(
        (item: any) => {
          return {
            label: item,
            value: slackintegration.value,
          };
        },
      );
      // options && setSelectedTableField(options[0]);
      setTablesFieldsList(options);
    }
  }, [databaseTablesFields]);

  useEffect(() => {
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...['_db_field']],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [fieldList, fieldListWithTypes, automationOutputTypes]);

  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    if (!slackintegration) return;
    // setClicked(false);
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: {
        keys: [
          {
            inputValue: null,
            key: null,
            settings: {
              config: slackintegration?.config,
              tableName: selectedTable,
              collectionName: selectedTableField,
              from: +saveFrom,
              to: +saveTo,
            },
          },
        ],
      },
      outputKeysData: [...fieldList.map((item: { value: any }) => item.value), ...['_db_field']],
      outputKeysDataWithTypes: { ...fieldListWithTypes, ...jsonParse },
    });
  }, [slackintegration, selectedTableField, selectedTable, saveFrom, saveTo, connected]);

  const openSetting = () => {
    window.open(`http://${window.location.host}/settings`, '_blank');
  };

  const handleSaveTo = (e: any) => {
    setErrorMessage('');
    let val = e.target.value;
    if (val < 0) {
      val = val * -1;
    }
    setSaveTo(val);
    !clicked && setClicked(true);
    setIsDirty();
  };

  const handleSaveFrom = (e: any) => {
    setErrorMessage('');
    let val = e.target.value;
    if (val < 0) {
      val = val * -1;
    }
    setSaveFrom(val);
    !clicked && setClicked(true);
    setIsDirty();
  };

  return (
    <Row className="slack-settings mb-4">
      {(connected === null || integrationsLoading || databaseTablesLoading || databaseTablesFieldsLoading) && (
        <StretchLoading />
      )}
      {connected === null || connected ? (
        <>
          <Col md={6} className="mb-2">
            <span className="label secondary-color">SQL Database</span>
            <Select
              value={slackintegration ? slackintegration.label : ''}
              options={options}
              fullWidth
              onChange={(selectedItem: any) => {
                setSelectedintegration(selectedItem);
                setSelectedTableField('');
                setTablesFieldsList(null as any);
                setSelectedTable('');
                setTablesList(null as any);
                getDatabaseTables({
                  variables: {
                    settings: {
                      id: selectedItem.value,
                    },
                  },
                });
                !clicked && setClicked(true);
                setIsDirty();
              }}
            ></Select>
          </Col>
          <Col md={6} className="mb-2">
            <span className="label secondary-color">Tables</span>
            <Datalist
              list={tablesList}
              value={selectedTable}
              placeholder={'Select table'}
              onChange={(selectedItem: any) => {
                setSelectedTable(selectedItem);
                setSelectedTableField('');
                setTablesFieldsList(null as any);
                getDatabaseFields({
                  variables: {
                    settings: {
                      id: slackintegration.value,
                      tableName: selectedItem,
                    },
                  },
                });
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
          <Col md={6} className="mb-2">
            <span className="label secondary-color">Fields</span>
            <Datalist
              list={tablesFieldsList}
              value={selectedTableField}
              placeholder={'Select field'}
              onChange={(selectedItem: any) => {
                setSelectedTableField(selectedItem);
                !clicked && setClicked(true);
                setIsDirty();
              }}
            />
          </Col>
          <Col md={3} sm={6} className="mb-2">
            <span className="label secondary-color">From</span>
            <Input value={saveFrom} onChange={handleSaveFrom} type="number" />
          </Col>
          <Col md={3} sm={6} className="mb-2">
            <span className="label secondary-color">To</span>
            <Input value={saveTo} onChange={handleSaveTo} type="number" />
          </Col>
        </>
      ) : (
        <Col lg={6} md={12}>
          <Button name={'Please check the Settings>Integrations to Connect'} onClick={openSetting} />
        </Col>
      )}
      {errorMessage && (
        <Col md={12}>
          <Alert type="error" className="create-workflow">
            {errorMessage}
          </Alert>
        </Col>
      )}
    </Row>
  );
};

export default SQLDatabaseConnector;
