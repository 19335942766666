import { FC, SVGProps, useState, ReactElement } from 'react';
import useClickOutside from '../../../hooks/useClickOutside';
import { useContext } from 'react';
import { ThemeContext } from 'contexts/theme-context';
import Loader from '../loader';
import { downloadIcon, arrowDownIcon } from '../../../assets/svg-icons';
import Tooltip from 'shared-components/atom/tooltip';
import './styles.scss';

interface ISelectProps {
  value: string | ReactElement;
  options: { title: string; value: string; icon?: SVGProps<SVGElement> }[];
  noDefaultValue?: boolean | undefined;
  click: (arg0: string) => void;
  loading?: boolean;
  marginLeftAuto?: boolean;
  icon?: boolean;
  centerMenu?: boolean;
  className?: string;
}

const Select: FC<ISelectProps> = ({
  value,
  options,
  noDefaultValue,
  click,
  loading,
  marginLeftAuto,
  icon,
  centerMenu,
  className,
}) => {
  const [showOptions, selectRef, setShowOptions] = useClickOutside(false, val => {
    setIsShow(val);
  });
  const { theme } = useContext(ThemeContext);
  const [isShow, setIsShow] = useState(false);
  return (
    <div
      className={`export-custom-select noselect ${marginLeftAuto ? 'md-left-auto' : ''} ${
        centerMenu ? 'center-menu' : ''
      }`}
      ref={selectRef}
      onClick={() => {
        setIsShow(!isShow);
      }}
    >
      <div
        className={`${className ? className : ''} select ${!noDefaultValue && !icon ? 'styled' : ''} ${
          loading ? 'disabled' : ''
        }`}
        onClick={() => setShowOptions(!showOptions)}
      >
        {loading && <Loader light={theme === 'light'} size="sm" />}
        {icon && (
          <Tooltip trigger={['hover', 'focus']} placement="right" text="Download results">
            {downloadIcon}
          </Tooltip>
        )}
        {!noDefaultValue && !icon && <span className="selected-value">{value}</span>}
        {!icon && <span className={`arrow-icon ${isShow && showOptions ? 'rotate' : ''}`}>{arrowDownIcon}</span>}
      </div>
      {isShow && showOptions && !loading && (
        <div className={`options ${className ? className + '-options' : ''}`}>
          {options &&
            options.map((item: any) => (
              <div
                className={`option ${className ? className + '-option' : ''} ${item.title == value ? 'active' : ''}`}
                key={item.title}
                onClick={() => {
                  click(item.value);
                  setShowOptions(false);
                }}
              >
                <span>
                  <span className="option-icon">{item.icon}</span>
                  {item.title}
                </span>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default Select;
