import { useState } from 'react';
import { RestoreSelectedSourceTypes } from './types';

export const useManySelect = () => {
  const [values, setValues] = useState<any>([]);

  const onSelect = (selected: { label: string; value: number }) => {
    let arr = [...values];
    let hasChecked = arr.find((id: any) => id === selected.value);
    if (hasChecked) {
      const newArr = arr.filter((id: any) => id !== selected.value);
      setValues([...newArr]);
    } else {
      setValues([...arr, selected]);
    }
  };

  return {
    values,
    setValues,
    onSelect,
  };
};

export const useAutomationGlobalMethods = ({
  automationSendSettings,
  setAutomationSendSettings,
  automationIndex,
  id,
}: any) => {
  const liftingSettings = ({
    dynamicSettings = null,
    integrationsSettings = null,
    preDefinedSettings = null,
    outputKeysData = null,
    outputKeysDataWithTypes = null,
  }: any) => {
    const settings = [...automationSendSettings];
    let outputs: string[] = [];
    let outputsWithTypes: any = null;
    let arr = settings.map((item, index) => {
      const current = index === automationIndex ? (outputKeysData ? outputKeysData : null) : item.output_keys_data;
      const currentWithKeys = index === automationIndex ? (outputKeysDataWithTypes ? outputKeysDataWithTypes : null) : typeof item.output_keys_data_with_types === 'string' ? JSON.parse(item.output_keys_data_with_types) : item.output_keys_data_with_types;
      //@ts-ignore
      const UniqueArr = Array.isArray(current) ? [...new Set([...outputs, ...current])] : null;
      //@ts-ignore
      const UniqueObj = outputsWithTypes ? {...outputsWithTypes, ...currentWithKeys} : {...currentWithKeys};

      if (UniqueArr) outputs = [...UniqueArr];
      if (UniqueObj) outputsWithTypes = {...UniqueObj};
      
      if (index === automationIndex) {
        return {
          ...item,
          ...{
            predefined_settings: preDefinedSettings ? preDefinedSettings : item.predefined_settings,
            dynamic_settings: dynamicSettings === 'null' ? null : dynamicSettings ? dynamicSettings : item.dynamic_settings,
            integration_settings: integrationsSettings ? integrationsSettings : item.integration_settings,
            output_keys_data: !outputKeysData ? item.output_keys_data : !!outputs.length ? outputs : null,
            output_keys_data_with_types: !outputKeysDataWithTypes ? typeof item.output_keys_data_with_types === 'string' ? item.output_keys_data_with_types : JSON.stringify(item.output_keys_data_with_types) : !!outputsWithTypes ? typeof outputsWithTypes === 'string' ? outputsWithTypes : JSON.stringify(outputsWithTypes) : null,
          },
        };
      }
      return {
        ...item,
        ...{
          output_keys_data: !!outputs.length ? outputs : null,
          output_keys_data_with_types: !!outputsWithTypes ? typeof outputsWithTypes === 'string' ? outputsWithTypes : JSON.stringify(outputsWithTypes) : null,
        },
      };
    });
    setAutomationSendSettings(arr);
  };

  const getDynamicAndIntegrationsSettings = () => {
    const nullCase = { predefined_settings: null, dynamic_settings: null, integration_settings: null };
    const currentAutomation = automationSendSettings.find((automation: any, index: any) => index === automationIndex);
    if (!currentAutomation) return nullCase;
    if (
      currentAutomation.automation_id !== id &&
      currentAutomation?.predefined_settings?.recipeId &&
      currentAutomation.predefined_settings.recipeId !== id
    )
      return nullCase;
    if (
      currentAutomation.dynamic_settings ||
      currentAutomation.integration_settings ||
      currentAutomation.predefined_settings
    ) {
      const { predefined_settings, dynamic_settings, integration_settings } = currentAutomation;
      return { predefined_settings, dynamic_settings, integration_settings };
    } else {
      return nullCase;
    }
  };

  const checkTransform = (key: string) => {
    if (!key) return key;
    const bool = key === '__url' ? false : true;
    if (bool) return key.split('_').join(' ').trim();
    return key;
  };

  const outputFormat = (key: string, TransformType: string, ind?: number, noUnderline?: boolean) => {
    if (key) {
      let keyNameUnderline = key.split(' ').join('_');
      keyNameUnderline = key[0] === '_' || noUnderline ? keyNameUnderline : `_${keyNameUnderline}`;
      return `${keyNameUnderline}_${TransformType[0] === '_' ? TransformType.slice(1) : TransformType}${ind ? '_' : ''}${ind ? ind : ''}`;
    } else if (TransformType && ind) {
      return `${TransformType}${ind ? '_' : ''}${ind ? ind : ''}`;
    }
  };

  const getJustAutomationOutputKeys = (prevAutomation: any) => {
    let options: any = [];
    if (!prevAutomation?.recipe && prevAutomation?.output_keys_data) {
      options = prevAutomation.output_keys_data.map((key: any) => {
        return {
          label: checkTransform(key),
          value: key,
        };
      });
    }
    return options;
  };

  const getJustAutomationOutputKeysWithTypes = (prevAutomation: any) => {
    const data = prevAutomation && prevAutomation.output_keys_data_with_types && typeof prevAutomation.output_keys_data_with_types === 'string' ? JSON.parse(prevAutomation.output_keys_data_with_types) : prevAutomation && prevAutomation.output_keys_data_with_types || [];
    return data;
  };

  const getPreviousAutomationOutputOrPreviousRecipKeys = (settings?: any) => {
    let prevAutomation = automationSendSettings && automationSendSettings[automationIndex - 1];
    let options: any = [];
    if (settings) automationSendSettings = settings;
    options = getJustAutomationOutputKeys(prevAutomation);
    const item = automationSendSettings[automationIndex];
    const source = item.source;
    if (source && !source.source_csv && !source.source_txt && !prevAutomation?.recipe) return options;
    if (source && !source.source_csv && source.source_txt) {
      const dataInput = automationSendSettings.find((item: { automation_id: number }) => item.automation_id === 47);
      if (
        !dataInput ||
        (dataInput &&
          dataInput.dynamic_settings &&
          (dataInput.dynamic_settings.keys[0].settings.type === 'Structured data' ||
            dataInput.dynamic_settings.keys[0].settings.type === 'csv-file' ||
            dataInput.dynamic_settings.keys[0].settings.type === 'image-file' ||
            dataInput.dynamic_settings.keys[0].settings.type === 'audio-file' ||
            dataInput.dynamic_settings.keys[0].settings.type === 'pdf-file'))
      )
        options = [];
      if (
        dataInput &&
        dataInput.dynamic_settings &&
        dataInput.dynamic_settings.keys[0].settings.type !== 'Structured data' &&
        dataInput.dynamic_settings.keys[0].settings.type !== 'csv-file' &&
        dataInput.dynamic_settings.keys[0].settings.type !== 'image-file' &&
        dataInput.dynamic_settings.keys[0].settings.type !== 'audio-file' &&
        dataInput.dynamic_settings.keys[0].settings.type !== 'pdf-file'
      ) {
        options = options.filter((item: any) => item.label === 'data input') || [];
      }
    }
    if (source && source.source_csv && !source.source_txt) {
      const inputs = automationSendSettings.filter(
        (item: { automation_id: number }) =>
          item.automation_id !== 47 && item.automation_id !== 5 && item.automation_id !== 1 && item.automation_id !== 3,
      );
      const dataInput = automationSendSettings.find((item: { automation_id: number }) => item.automation_id === 47);

      if (
        (!dataInput && inputs.length === 1) ||
        (dataInput &&
          dataInput.dynamic_settings &&
          dataInput.dynamic_settings.keys[0].settings.type !== 'Structured data' &&
          dataInput.dynamic_settings.keys[0].settings.type !== 'csv-file' &&
          dataInput.dynamic_settings.keys[0].settings.type !== 'image-file' &&
          dataInput.dynamic_settings.keys[0].settings.type !== 'pdf-file' &&
          dataInput.dynamic_settings.keys[0].settings.type !== 'audio-file' &&
          inputs.length === 1)
      ) {
        options = options.filter((item: any) => item.label !== 'data input') || [];
      }
    }
    if (options.length > 0) return options;

    if (prevAutomation?.recipe) {
      let data = JSON.parse(prevAutomation.recipe.actions);
      data = typeof data === 'string' ? JSON.parse(data) : data;
      data.pages[0].elements.forEach((element: { property: any }) => {
        element.property && options.push({ label: element.property, value: element.property });
      });
    }
    return options;
  };

  const getPreviousAutomationOutputOrPreviousRecipKeysWithTypes = (settings?: any) => {
    let prevAutomation = automationSendSettings && automationSendSettings[automationIndex - 1];
    let options: any = [];
    if (settings) automationSendSettings = settings;
    options = getJustAutomationOutputKeysWithTypes(prevAutomation);
    // const item = automationSendSettings[automationIndex];
    // const source = item.source;
    // if (source && !source.source_csv && !source.source_txt && !prevAutomation?.recipe) return options;

    // if (options.length > 0) return options;

    // if (prevAutomation?.recipe) {
    //   let data = JSON.parse(prevAutomation.recipe.actions);
    //   data = typeof data === 'string' ? JSON.parse(data) : data;
    //   data.pages[0].elements.forEach((element: { property: any }) => {
    //     element.property && options.push({ label: element.property, value: element.property });
    //   });
    // }
    return options;
  };

  const restoreSelectedSource = ({ key, fieldList, setSelectedSource }: RestoreSelectedSourceTypes) => {
    if (Array.isArray(key)) {
      const arr = key.map((val) => {
        const recreateSelected = fieldList.find(
          (item: { value: string; label: string }) => item.value === val || item.label === val,
        );
        return recreateSelected;
      });
      arr && setSelectedSource(arr);
    } else {
      const recreateSelected = fieldList.find(
        (item: { value: string; label: string }) => item.value === key || item.label === key,
      );
      recreateSelected && setSelectedSource(recreateSelected);
    }
  };

  return {
    outputFormat,
    liftingSettings,
    restoreSelectedSource,
    getDynamicAndIntegrationsSettings,
    getPreviousAutomationOutputOrPreviousRecipKeys,
    getPreviousAutomationOutputOrPreviousRecipKeysWithTypes,
  };
};
