import { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'shared-components/atom/select';
import { useAutomationGlobalMethods } from '../../hooks';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import StretchLoading from 'shared-components/molecule/stretch-loading';
import Button from 'shared-components/atom/button';
import { GET_WORDPRESS_CATEGORIES_BY_ID, GET_WORDPRESS_DATA } from 'graphql/queries';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import './styles.scss';
import Tooltip from 'shared-components/atom/tooltip';
import { infoIcon } from 'assets/svg-icons';

const outputs = [
  '_wordpress_id',
  '_wordpress_date',
  '_wordpress_modified',
  '_wordpress_slug',
  '_wordpress_status',
  '_wordpress_link',
  '_wordpress_author',
  '_wordpress_comment_status',
  '_wordpress_format',
  '_wordpress_generated_slug',
];

const statuses = [
  { label: 'draft', value: 'draft' },
  { label: 'publish', value: 'publish' },
];

const WordpressSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  selectedRecipes,
  automationIndex,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [connected, setConnected] = useState<any>(null);
  const [integration, setIntegration] = useState<any>(null);
  const [list, setList] = useState<{ label: string; value: string }[]>([]);
  const [title, setTitle] = useState<null | { label: string; value: string }>(null);
  const [content, setContent] = useState<null | { label: string; value: string }>(null);
  const [slug, setSlug] = useState<null | { label: string; value: string }>(null);
  const [fieldList, setFieldList] = useState<any>(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState<null | number>(null);
  const [clicked, setClicked] = useState(false);
  const [status, setStatus] = useState<null | { label: string; value: string }>(null);
  const [selectedCategory, setSelectedCategory] = useState<{ label: string; value: number } | null>(null);
  const [categoriesList, setCategoriesList] = useState([]);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const { data: wordpressData, loading } = useQuery(GET_WORDPRESS_DATA);

  const [wordpressCategoriesData, { loading: categoriesLoading }] = useLazyQuery(GET_WORDPRESS_CATEGORIES_BY_ID, {
    fetchPolicy: 'no-cache',
    onCompleted(data: any) {
      if (data?.HexomaticIntegrations?.getWordPressCategories?.categories) {
        const list = data.HexomaticIntegrations.getWordPressCategories.categories.map((item: any) => {
          return { label: item.name, value: item.id };
        });
        setCategoriesList(list);
      }
    },
  });

  const { liftingSettings, getDynamicAndIntegrationsSettings, getPreviousAutomationOutputOrPreviousRecipKeys, getPreviousAutomationOutputOrPreviousRecipKeysWithTypes } =
    useAutomationGlobalMethods({
      automationSendSettings,
      setAutomationSendSettings,
      selectedRecipes,
      automationIndex,
      id,
    });

  const checkFields = () => {
    if (
      !status?.label ||
      !fieldList ||
      !title ||
      !content ||
      !title?.value ||
      !content?.value ||
      !selectedCategory?.value
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (wordpressData?.HexomaticIntegrations?.getWordPressConnections?.connections) {
      if (
        wordpressData.HexomaticIntegrations.getWordPressConnections.connections &&
        wordpressData.HexomaticIntegrations.getWordPressConnections.connections.length > 0
      ) {
        setIntegration(
          wordpressData.HexomaticIntegrations.getWordPressConnections.connections.map((item: any) => {
            return { ...item, label: item.name || item.username + '/' + item.url, value: item.id };
          }),
        );
        setConnected(true);
      } else setConnected(false);
    }
  }, [wordpressData]);
  useEffect(() => {
    const { predefined_settings, dynamic_settings } = getDynamicAndIntegrationsSettings();
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();

    const filteredOptions = automationInputTypes && !automationInputTypes.includes('any') ? 
    options.filter((item: any) => (optionsWithTypes && optionsWithTypes[item.value] && (automationInputTypes.includes(optionsWithTypes[item.value]) || optionsWithTypes[item.value] === 'any')) || !(optionsWithTypes && optionsWithTypes[item.value])) :
    [...options];
    setList([...(filteredOptions ? filteredOptions : [])]);
    setFieldListWithTypes(optionsWithTypes);
    
    if (dynamic_settings && predefined_settings) {
      setConnected(true);
      const { keys } = dynamic_settings;
      const { url, username, password, id, name } = predefined_settings;
      setFieldList({ url, username, password, id, label: name || username + '/' + url, value: id });
      id &&
        wordpressCategoriesData({
          variables: {
            settings: { id },
          },
        });

      const { settings, key } = keys[0];
      const { status, category, secondKey } = settings;
      const list = secondKey.split(',');
      const isExists = (str: string) => {
        const el = options.filter((item: { value: string }) => item.value === str);
        return el && el.length > 0;
      };
      key && setTitle({ label: key.replaceAll('_', ' ').trim(), value: key });
      list[0] && isExists(list[0]) && setContent({ label: list[0].replaceAll('_', ' ').trim(), value: list[0] });
      list[1] && isExists(list[1]) && setSlug({ label: list[1].replaceAll('_', ' ').trim(), value: list[1] });
      setSelectedCategoryId(category);
      status && setStatus({ label: status, value: status });
    }
  }, [isSettingsChange]);

  useEffect(() => {
    const selected = categoriesList.find((item: any) => item.value === selectedCategoryId);
    selected && setSelectedCategory(selected);
  }, [selectedCategoryId, categoriesList]);

  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    setClicked(false);

    const localSettings = {
      status: status?.value,
      secondKey: content?.value + (slug?.value ? ',' + slug.value : ''),
      category: selectedCategory?.value,
    };
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: {
        keys: [
          {
            inputValue: null,
            key: title?.value,
            settings: localSettings,
          },
        ],
      },
      preDefinedSettings: {
        url: fieldList?.url,
        username: fieldList?.username,
        password: fieldList?.password,
        id: fieldList?.id,
        name: fieldList?.name || '',
      },
      outputKeysData: outputs,
      outputKeysDataWithTypes: {...fieldListWithTypes, ...jsonParse},
    });
  }, [slug, status, fieldList, title, content, selectedCategory, fieldListWithTypes]);

  const handleChangeStatus = (selectedItem: any) => {
    setStatus(selectedItem);
    !clicked && setClicked(true);
    setIsDirty();
  };
  const handleChangeSelect = (selectedItem: any) => {
    setFieldList(selectedItem);
    selectedItem &&
      wordpressCategoriesData({
        variables: {
          settings: { id: selectedItem.id },
        },
      });
    !clicked && setClicked(true);
    setIsDirty();
  };
  const handleChangeSlug = (selectedItem: any) => {
    setSlug(!selectedItem.value ? null : selectedItem);
    !clicked && setClicked(true);
    setIsDirty();
  };
  const handleChangeCategory = (selectedItem: any) => {
    setSelectedCategoryId(null);
    setSelectedCategory(selectedItem);
    !clicked && setClicked(true);
    setIsDirty();
  };
  const handleChangeTitle = (selectedItem: any) => {
    setTitle(selectedItem);
    !clicked && setClicked(true);
    setIsDirty();
  };
  const handleChangeContent = (selectedItem: any) => {
    setContent(selectedItem);
    !clicked && setClicked(true);
    setIsDirty();
  };
  const openSetting = () => {
    window.open(`http://${window.location.host}/settings`, '_blank');
  };
  return (
    <div className="wordpress">
      <div>
        {(connected === null || categoriesLoading || loading) && <StretchLoading />}
        {connected === null || connected ? (
          <>
            <Row>
              <Col lg={4} className="">
                <span className="label secondary-color">Account</span>
                <Select
                  fullWidth
                  options={integration}
                  value={fieldList}
                  placeholder={'Select'}
                  onChange={handleChangeSelect}
                />
              </Col>

              <Col lg={4}>
                <div className="select-block">
                  <span className="label secondary-color">Status</span>
                  <Select
                    fullWidth
                    options={statuses}
                    value={status}
                    placeholder={'Select status'}
                    onChange={handleChangeStatus}
                  />
                </div>
              </Col>
              <Col lg={4} className="">
                <span className="label secondary-color">Select category</span>
                <Select
                  fullWidth
                  options={categoriesList}
                  value={selectedCategory}
                  placeholder={'Select'}
                  onChange={handleChangeCategory}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={4} className="order_1">
                <span className="label secondary-color">Select title</span>
                <Select fullWidth options={list} value={title} placeholder={'Select '} onChange={handleChangeTitle} />
              </Col>
              <Col lg={4} className="order_3">
                <span className="label secondary-color">Select content</span>
                <Select
                  fullWidth
                  options={list}
                  value={content}
                  placeholder={'Select'}
                  onChange={handleChangeContent}
                />
              </Col>
              <Col lg={4} className="">
                <span className="label secondary-color">Select slug (optional)</span>
                <Tooltip
                  trigger={['hover', 'focus']}
                  placement="right"
                  text="In WordPress the slug is the editable part of the URL placed at the end of the URL. Most often it contains keywords, day, month, time, author name etc."
                  className="label-tooltip-wrapper"
                >
                  <span className="label secondary-color width-fit-content">{infoIcon}</span>
                </Tooltip>
                <Select
                  fullWidth
                  options={slug && slug.value ? [{ label: 'No slug', value: '' }, ...list] : list}
                  value={slug}
                  placeholder={'Select'}
                  onChange={handleChangeSlug}
                />
              </Col>
            </Row>
          </>
        ) : (
          <Col lg={6} md={12}>
            <Button name={'Please check the Settings>Integrations to Connect'} onClick={openSetting} />
          </Col>
        )}
      </div>
    </div>
  );
};

export default WordpressSettings;
