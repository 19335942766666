import { FC, useContext, useEffect, useState } from 'react';
import { Card, Modal } from 'react-bootstrap';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import {
  GET_AUTOMATIONS,
  GET_USER_STATS,
  GET_USER_WORKFLOW_STATUSES,
  GET_RENEWAL_DATE,
  GET_HEXOMATIC_USER,
} from 'graphql/queries';
import { UserContext } from 'contexts/user-context';
import Button from 'shared-components/atom/button';

import clockIcon from 'assets/icons/watch.svg';
import conciergeLight from 'assets/concierge/dashboard.svg';
import conciergeDark from 'assets/concierge/dashboard-dark.svg';
import popupMobile from 'assets/concierge/popup-mobile.svg';
import popupDesktop from 'assets/concierge/popup-desktop.svg';
import CustomProgress from 'shared-components/atom/custom-progress-tooltip';

import PieChart from 'shared-components/organizm/pie-chart';

import { HexomaticUserContext } from 'contexts/hexomatic-user';
import { premiumCreditPlans } from 'constants/index';
import {
  closeIcon,
  newLabel,
  plusIcon,
  productivityIconDark,
  productivityIconLight,
  productivityStar,
} from 'assets/svg-icons';
import tutorials from 'assets/images/tutorials.svg';
import CustomModal from 'shared-components/molecule/customModal';
import Payment from 'shared-components/molecule/payment';
import { useHistory, useLocation } from 'react-router';
import moment from 'moment';
import './styles.scss';
import { Link } from 'react-router-dom';
import { ThemeContext } from 'contexts/theme-context';
import Minimize from 'shared-components/atom/minimize-icon';
import { useMediaQuery } from 'react-responsive';

const COLORS = ['#1db954', '#0ab8f7', '#f42c2c', '#6c757d'];

const DashboardStats: FC<{showPieChart?: boolean}> = ({showPieChart}) => {
  const pieData = [
    { name: 'Done', value: 0, color: '#1db954' },
    { name: 'In Progress', value: 0, color: '#0ab8f7' },
    { name: 'Fail', value: 0, color: '#f42c2c' },
    { name: 'Paused', value: 0, color: '#6c757d' },
    { name: 'Draft', value: 0, color: '#fd7e14' },
    { name: 'Limit reached', value: 0, color: '#B94E41' },
  ];
  //---------------------------------------
  const location = useLocation();
  const history = useHistory();
  //--------------Context-------------------
  const { user } = useContext(UserContext);
  const { theme } = useContext(ThemeContext);
  const { push } = useHistory();
  const { hexomaticUser, setHexomaticUser } = useContext(HexomaticUserContext);
  //------------State----------------------
  const [userStatsObject, setUserStatsObject] = useState({} as any);
  const [userSettingsObject, setUserSettingsObject] = useState({} as any);
  const [userWorkflowObject, setUserWorkflowObject] = useState({} as any);
  const [totalWorkflows, setTotalWorkflows] = useState(0);
  const [pricingPackage, setPricingPackage] = useState(hexomaticUser?.pricing_package);
  const [pricingPackageCount, setPricingPackageCount] = useState(hexomaticUser?.pricing_package_count || 1);
  const [showModal, setShowModal] = useState(false);
  const [showConciergeDetails, setShowConciergeDetails] = useState(false);

  const [newAutomations, setNewAutomations] = useState([]);
  const [showWorkflowsStats, setShowWorkflowsStats] = useState(false);
  const [showConcierge, setShowConcierge] = useState(true);
  const [showTutorials, setShowTutorials] = useState(true);
  const [paidUserWithoutWorkflow, setPaidUserWithoutWorkflow] = useState(false);
  const [showAutomationCredits, setShowAutomationCredits] = useState(false);
  const [showPremiumCredits, setShowPremiumCredits] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  //-------------Queries-------------------
  const [hexomaticUserInfo, { data: hexomaticUserData }] = useLazyQuery(GET_HEXOMATIC_USER, {
    fetchPolicy: 'no-cache',
  });
  const { data: userStats, loading: userStatsLoading } = useQuery(GET_USER_STATS);
  const {
    data: userWorkflowStatuses,
    loading: userWorkflowStatusesLoading,
    refetch,
  } = useQuery(GET_USER_WORKFLOW_STATUSES);

  const {
    data: renewalDate,
    loading: renewalDateLoading,
    refetch: refetchRenewalDate,
  } = useQuery(GET_RENEWAL_DATE, {
    variables: {
      settings: {
        hexomatic: true,
        hexowatch: false,
        hexometer: false,
        premium_credits: true,
      },
    },
  });
  const { data: automations, loading: automationLoading } = useQuery(GET_AUTOMATIONS, {
    variables: {
      settings: {
        filter: {
          active: true,
          page: null,
          limit: null,
          date_from: null,
          date_to: null,
          sort_order: null,
          sort_by: null,
          hasSource: null,
          automation_kind: undefined,
          search: {
            search_field: null,
            search_value: null,
          },
          sortField: 'updated_at',
          sortDir: 'DESC',
        },
      },
    },
  });
  //------------------------useEffects-----------------
  useEffect(() => {
    refetch();
    hexomaticUserInfo();
    if (location.hash === '#modal_open') {
      history.replace('/dashboard');
      setShowModal(true);
    }
    if (localStorage.getItem('showWorkflowsStats')) {
      setShowWorkflowsStats(eval(localStorage.getItem('showWorkflowsStats') || 'true'));
    }
    if (localStorage.getItem('showAutomationCredits')) {
      setShowAutomationCredits(eval(localStorage.getItem('showAutomationCredits') || 'true'));
    }
    if (localStorage.getItem('showPremiumCredits')) {
      setShowPremiumCredits(eval(localStorage.getItem('showPremiumCredits') || 'true'));
    }
    if (localStorage.getItem('showConcierge')) {
      setShowConcierge(eval(localStorage.getItem('showConcierge') || 'true'));
    }
    if (localStorage.getItem('showTutorials')) {
      setShowTutorials(eval(localStorage.getItem('showTutorials') || 'true'));
    }
  }, []);

  useEffect(() => {
    if (user && user.id) refetchRenewalDate();
  }, [user]);

  useEffect(() => {
    if (
      hexomaticUser?.pricing_package &&
      hexomaticUser?.pricing_package !== 'FREE'
      // && totalWorkflows === 0
    ) {
      setPaidUserWithoutWorkflow(true);
    } else {
      setPaidUserWithoutWorkflow(false);
    }
  }, [hexomaticUser]);

  useEffect(() => {
    if (hexomaticUserData?.HexomaticUser?.getByLegacyId) {
      setHexomaticUser({ ...hexomaticUserData.HexomaticUser.getByLegacyId, photo: user?.photo });
    }
  }, [hexomaticUserData]);
  useEffect(() => {
    if (userStats?.HexomaticUser?.getUserStats?.user_stats) {
      const parsed = JSON.parse(userStats?.HexomaticUser?.getUserStats?.user_stats);
      setUserStatsObject(parsed?.userStats?.stats);
      setUserSettingsObject(parsed?.userStats?.settings);
    }
  }, [userStats]);

  useEffect(() => {
    setPricingPackage(hexomaticUser?.pricing_package);
    hexomaticUser?.pricing_package_count && setPricingPackageCount(hexomaticUser.pricing_package_count);
  }, [hexomaticUser]);

  useEffect(() => {
    if (userWorkflowStatuses?.HexomaticUser?.getUserWorkflowStatuses?.workflow_statuses) {
      const parsed = JSON.parse(userWorkflowStatuses?.HexomaticUser?.getUserWorkflowStatuses?.workflow_statuses);
      setUserWorkflowObject(parsed?.final_data);
      const arr = parsed?.final_data ? Object.values(parsed?.final_data) : [];
      let sum = 0;
      arr.map((item: any) => (sum += item));
      setTotalWorkflows(sum);
    }
  }, [userWorkflowStatuses]);

  useEffect(() => {
    if (automations?.HexomaticAutomation?.getAutomations?.automations) {
      const listOfNewAutomations = automations?.HexomaticAutomation?.getAutomations?.automations.filter(
        (item: any) => item.is_new,
      );

      setNewAutomations(listOfNewAutomations);
    }
  }, [automations]);
  //----------------------------------------------------//
  const calcUsage = (all: number, portion: number): number => {
    return ((all - portion) * 100) / all;
  };
  const calcStorage = (byte: number): number => {
    const mb = +(byte / 1000 / 1000).toFixed(1);
    return hexomaticUser?.storage ? (mb * 100) / (hexomaticUser?.storage / 1000000) : 0;
  };

  const getStorageCapacity = (): string => {
    const storage = hexomaticUser.storage ? hexomaticUser.storage / 1000000 : 0;
    return `${storage}MB`;
  };

  const getStorageUsed = (): string => {
    const storage = hexomaticUser.storage_used ? (hexomaticUser.storage_used / 1000000).toFixed(1) : 0;
    return `${storage}MB`;
  };

  const createPieChartData = (obj: any): { name: string; value: number; color: string }[] => {
    pieData.forEach((el: any) => {
      for (const key in obj) {
        if (key === 'progress') {
          if (el.name === 'In Progress') {
            el.value = obj[key];
          }
        }
        if (key === 'completed') {
          if (el.name === 'Done') {
            el.value = obj[key];
          }
        }
        if (key === 'error') {
          if (el.name === 'Fail') {
            el.value = obj[key];
          }
        }
        if (key === 'PAUSED') {
          if (el.name === 'Paused') {
            el.value = obj[key];
          }
        }
        if (key === 'DRAFT') {
          if (el.name === 'Draft') {
            el.value = obj[key];
          }
        }
        if (
          key === 'CREDIT_REACHED' ||
          key === 'PREMIUM_CREDIT_REACHED' ||
          key === 'STORAGE_REACHED' ||
          key === 'CREDIT_STORAGE_REACHED' ||
          key === 'PREMIUM_CREDIT_STORAGE_REACHED'
        ) {
          if (el.name === 'Limit reached') {
            el.value =
              (obj['CREDIT_REACHED'] || 0) +
              (obj['PREMIUM_CREDIT_REACHED'] || 0) +
              (obj['STORAGE_REACHED'] || 0) +
              (obj['CREDIT_STORAGE_REACHED'] || 0) +
              (obj['PREMIUM_CREDIT_STORAGE_REACHED'] || 0);
          }
        }
      }
    });

    return pieData;
  };
  const handleRefetch = () => {
    refetchRenewalDate();
  };
  const handleChangeWorkflowVisibility = () => {
    setShowWorkflowsStats(!showWorkflowsStats);
    localStorage.setItem('showWorkflowsStats', String(!showWorkflowsStats));
  };
  const handleChangeAutomationCreditsVisibility = () => {
    setShowAutomationCredits(!showAutomationCredits);
    localStorage.setItem('showAutomationCredits', String(!showAutomationCredits));
  };
  const handleChangePCVisibility = () => {
    setShowPremiumCredits(!showPremiumCredits);
    localStorage.setItem('showPremiumCredits', String(!showPremiumCredits));
  };

  const handleChangeConciergeVisibility = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setShowConcierge(!showConcierge);
    localStorage.setItem('showConcierge', String(!showConcierge));
  };

  const handleChangeTutorialsVisibility = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setShowTutorials(!showTutorials);
    localStorage.setItem('showTutorials', String(!showTutorials));
  };

  return (
    <div className="mt-xl-2 stats-container">
      <div className='stats-container-col p-0 pr-md-2 pl-md-1'>
      {paidUserWithoutWorkflow && (
        <Card
          className={`pulse-container ${showConcierge ? 'cursor-pointer' : ''}`}
          style={{ paddingBottom: '10px', height: 'auto' }}
          onClick={() => showConcierge && setShowConciergeDetails(true)}
        >
          <div className={`card-body px-0 d-flex position-relative ${showConcierge ? 'py-0' : 'py-3'}`}>
            {!showConcierge && (
              <h2
                onClick={e => handleChangeConciergeVisibility(e)}
                className={`auto-credits-title-h2 cursor-pointer ${showConcierge ? 'pb-3' : 'pb-0'} pl-3`}
              >
                Let us create your scraping recipes!
              </h2>
            )}
            <div onClick={e => handleChangeConciergeVisibility(e)} className={`position-absolute chart-icon`}>
              <Minimize bool={showConcierge} />
            </div>
            {showConcierge && (
              <img src={theme === 'dark' ? conciergeDark : conciergeLight} alt="" className="consierge-img" />
            )}
            {showConcierge && (
              <div className="d-flex justify-content-end">
                <div
                  className="upgrade-btn mt-auto absolute cursor-pointer"
                  // onClick={() => setShowConciergeDetails(true)}
                >
                  Learn more
                  <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.0568 0L9.89009 1.14562L14.5075 5.6875H0.676758V7.3125H6.59212H14.5075L9.89009 11.8544L11.0568 13L17.6768 6.5L11.0568 0Z"
                      fill="#B72CFF"
                    />
                  </svg>
                </div>
              </div>
            )}
          </div>
        </Card>
      )}
     {showPieChart ?
      <Card className="pulse-container" style={{ paddingBottom: '10px', height: 'auto'  }}>
        {userWorkflowStatusesLoading || userStatsLoading ? (
          <div className="pulse">
            <div className="line-pie"></div>
          </div>
        ) : (
          <div className="card-body px-0 py-3">
            <>
              <h2
                onClick={handleChangeWorkflowVisibility}
                className={`auto-credits-title-h2 cursor-pointer ${showWorkflowsStats ? 'pb-3' : 'pb-0'} pl-3`}
              >
                Workflows
              </h2>
              <div onClick={handleChangeWorkflowVisibility} className={`position-absolute chart-icon`}>
                <Minimize bool={showWorkflowsStats} />
              </div>
              {showWorkflowsStats && (
                <div className="d-lg-flex d-xl-block flex-column">
                  <div className="mb-1 " style={{ marginLeft: '8px' }}>
                    <PieChart
                      data={userWorkflowObject.hasOwnProperty && createPieChartData(userWorkflowObject)}
                      colors={COLORS}
                      width={320}
                      height={160}
                      innerRadius={62}
                      outerRadius={74}
                      noChangeWhenMobile
                      click={() => {}}
                      label={totalWorkflows}
                      secondLabel={'Total Workflows'}
                    />
                    <div className="pr-4 pl-4">
                      <h6 className="auto-credits-title  align-items-center d-flex">
                        <span>Storage used: </span>
                        <CustomProgress
                          percent={calcStorage(hexomaticUser?.storage_used) || 0}
                          text={`${getStorageUsed()} out of ${getStorageCapacity()}`}
                        />
                      </h6>
                    </div>
                  </div>
                </div>
              )}
            </>
            {/* )} */}
          </div>
        )}
      </Card> : null}
      <Card
        className={`pulse-container ${showTutorials ? 'cursor-pointer' : ''}`}
        style={{ paddingBottom: '10px', height: 'auto'  }}
        onClick={() => showTutorials && window.open('https://hexomatic.com/academy/tutorials/', '_blank')}
      >
        <div className={`card-body px-0 ${showTutorials ? 'py-0' : 'py-3'}`}>
          <h2
            onClick={e => handleChangeTutorialsVisibility(e)}
            className={`auto-credits-title-h2 cursor-pointer ${showTutorials ? 'py-3' : 'pb-0'} pl-3`}
          >
            Latest from the academy
          </h2>

          <div onClick={e => handleChangeTutorialsVisibility(e)} className={`position-absolute chart-icon`}>
            <Minimize bool={showTutorials} />
          </div>
          {showTutorials && (
            <div className="d-flex w-100">
              <img src={tutorials} alt="" className="m-auto w-100 px-2" />
            </div>
          )}
          {showTutorials && (
            <div className="d-flex justify-content-end my-3 mr-3">
              <div className="upgrade-btn mt-auto cursor-pointer">
                Access all our tutorials
                <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.0568 0L9.89009 1.14562L14.5075 5.6875H0.676758V7.3125H6.59212H14.5075L9.89009 11.8544L11.0568 13L17.6768 6.5L11.0568 0Z"
                    fill="#B72CFF"
                  />
                </svg>
              </div>
            </div>
          )}
        </div>
      </Card>
      </div>
      <div className='stats-container-col p-0 pr-md-2 pl-md-1 second-child'>
      <Card className="pulse-container new-card" style={{ height: 'auto', paddingBottom: '10px' }}>
        {automationLoading ? (
          <div className="pulse">
            <div className="line-dash-progress"></div>
          </div>
        ) : (
          <div className="card-body">
            <div className="d-flex flex-column">
              <div className="d-flex flex-row pl-3 pt-3 pb-2">
                <h2 className="auto-credits-title-h2">What's new at Hexomatic </h2>
                <div className="position-absolute new-at-hexomatic">{newLabel}</div>
              </div>
              <div className="new-automations-list">
                {newAutomations.length > 0 &&
                  newAutomations.map(
                    (item: any, index: number) =>
                      index < 8 && (
                        <div
                          key={item.name}
                          className="new-automation"
                          onClick={() => history.push(`/automations/${item.id}`)}
                        >
                          <div className="d-flex pl-3 py-2 w-100 cursor-pointer">
                            <div className="automation-icon mr-3">
                              <img src={item.icon_url} alt={'Tool icon'} />
                            </div>
                            <div>
                              <h5 className="text-truncate secondart-text">{item.name}</h5>
                              <h6 className="text-truncate ml_10 secondart-text">{item.type.name}</h6>
                              <h6 className="text-truncate ml_10 secondart-text">
                                Last updated: {moment(new Date(+item.updated_at)).format('MMM DD, YYYY')}
                              </h6>
                            </div>
                          </div>
                        </div>
                      ),
                  )}
              </div>
            </div>
          </div>
        )}
      </Card>
      </div>
   
      {showModal && (
        <CustomModal ShowPopup={showModal} noNavigate escButtonClick={setShowModal}>
          <Payment
            setShowPopup={setShowModal}
            name={''}
            selectedValue={{ title: '105', value: 105 }}
            price={0}
            premium_credit
            refetch={handleRefetch}
            // offer
          />
        </CustomModal>
      )}
      {showConciergeDetails && (
        <Modal
          show={!!showConciergeDetails}
          toggle={() => {}}
          className={`promo-modal modal-dialog--header theme-${theme}`}
        >
          <button
            className="panel__btn dark"
            style={{ marginTop: '5px', marginRight: '10px' }}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              setShowConciergeDetails(false);
            }}
            type="button"
          >
            {closeIcon}
          </button>
          <div className="modal__body" style={{ padding: '0', background: '#301371' }}>
            <img
              onClick={() => window.open('https://calendly.com/hexact/concierge-service-hexact', '_blank')}
              src={isMobile ? popupMobile : popupDesktop}
              alt=""
              className="cursor-pointer"
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default DashboardStats;
