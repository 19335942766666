import { FC, KeyboardEvent } from 'react';

import { isValidEmail } from 'constants/validations';
import { UserType } from 'types';

import { closeIcon } from 'assets/svg-icons';
import './styles.scss';

interface EmailInputListProps {
  emailsArray: string[];
  setEmailsArray: (arg: string[]) => void;
  inputText: string;
  setInputText: (arg: string) => void;
  setErrorTxt: (arg: string) => void;
  setError: (arg: boolean) => void;
  user: UserType;
}

const EmailInputList: FC<EmailInputListProps> = ({
  inputText,
  setInputText,
  emailsArray,
  setEmailsArray,
  setErrorTxt,
  setError,
  user,
}) => {
  const handleEnterKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === ',' || e.key === ' ') {
      if (isValidEmail(inputText) && inputText !== user.email) {
        if (emailsArray.length > 14) {
          setError(true);
          setErrorTxt('Maximum allowed emails for share are 15');
          return;
        }

        const newSet = new Set(emailsArray);

        if (newSet.has(inputText)) {
          setError(true);
          return;
        }
        setError(false);
        newSet.add(inputText);

        setEmailsArray([...newSet]);
        setInputText('');
        setErrorTxt('');
      }
    }
  };

  return (
    <div className="share-emails-input-wrapper">
      <div className="share-emails-div">
        {emailsArray.map((el: string) => (
          <div className="share-emails-list" key={el}>
            {el}
            <span
              className="close-icon-email"
              onClick={() => setEmailsArray(emailsArray.filter((email: string) => email !== el))}
            >
              {closeIcon}
            </span>
          </div>
        ))}

        <input
          onKeyDown={handleEnterKeyPress}
          value={inputText}
          placeholder="Add an email"
          onChange={e => setInputText(e.target.value)}
          type="text"
        />
      </div>
    </div>
  );
};

export default EmailInputList;
