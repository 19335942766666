import { FC, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAutomationGlobalMethods } from '../../hooks';
import Select from 'shared-components/atom/select';
import { useLazyQuery } from '@apollo/react-hooks';
import { HexomaticUserContext } from 'contexts/hexomatic-user';
import { GET_ALL_INTEGRATIONS } from 'graphql/queries';
import Button from 'shared-components/atom/button';
import StretchLoading from 'shared-components/molecule/stretch-loading';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import './styles.scss';

const IntegromationSettings: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  const [options, setOptions] = useState<any>([]);
  const [integromatIntegration, setIntegromatIntegration] = useState<any>(null);
  const { hexomaticUser } = useContext(HexomaticUserContext);
  const [userId, setUserId] = useState(hexomaticUser ? hexomaticUser.id : -1);
  const [clicked, setClicked] = useState(true);
  const [fieldList, setFieldList] = useState<{ label: string; value: string }[]>([]);
  const [connected, setConnected] = useState<any>(null);
  const [fieldListWithTypes, setFieldListWithTypes] = useState([]);

  const [getAllIntegrations, { data: integrationsData, loading: integrationsLoading }] = useLazyQuery(
    GET_ALL_INTEGRATIONS,
    { fetchPolicy: 'network-only' },
  );

  const { liftingSettings, getPreviousAutomationOutputOrPreviousRecipKeys, getDynamicAndIntegrationsSettings, getPreviousAutomationOutputOrPreviousRecipKeysWithTypes } =
    useAutomationGlobalMethods({
      automationSendSettings,
      selectedRecipes,
      setAutomationSendSettings,
      automationIndex,
      id,
    });

  const checkFields = () => {
    if (!connected) return false;
    return true;
  };

  //restore
  useEffect(() => {
    getAllIntegrations({
      variables: { id: userId },
    });
  }, []);

  //recipe fields or output fields
  useEffect(() => {
    // if (fieldList.length > 0) return;
    if (automationIndex === 0) return;
    let options = getPreviousAutomationOutputOrPreviousRecipKeys();
    const optionsWithTypes = getPreviousAutomationOutputOrPreviousRecipKeysWithTypes();
    const filteredOptions = automationInputTypes && !automationInputTypes.includes('any') ? 
    options.filter((item: any) => (optionsWithTypes && optionsWithTypes[item.value] && (automationInputTypes.includes(optionsWithTypes[item.value]) || optionsWithTypes[item.value] === 'any')) || !(optionsWithTypes && optionsWithTypes[item.value])) :
    [...options];

    setFieldList(filteredOptions);
    setFieldListWithTypes(optionsWithTypes);
    // setFieldList(options);
  }, []);

  // getting integrations
  useEffect(() => {
    if (integrationsData?.HexomaticIntegrations?.getAllIntegrations?.telegram) {
      const { integromat } = integrationsData.HexomaticIntegrations.getAllIntegrations;
      const options = integromat.map((item: { data: any; id: any; name: any }) => {
        let data = JSON.parse(item.data);
        return {
          label: item.name || data.name,
          value: item.id,
        };
      });
      const { integration_settings } = getDynamicAndIntegrationsSettings();
      if (integration_settings) {
        const finded = options.find((item: { value: any }) => item.value === integration_settings._ref_data);
        finded && setIntegromatIntegration(finded);
        // setClicked(false);
      } else {
        options && setIntegromatIntegration(options[0]);
      }

      setOptions(options);
      setConnected(options && options.length > 0 ? true : false);
      setClicked(true);
    }
  }, [integrationsData]);

  useEffect(() => {
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      outputKeysData: fieldList.map((item: { value: any }) => item.value),
      outputKeysDataWithTypes: {...fieldListWithTypes, ...jsonParse},
    });
  }, [fieldList, automationOutputTypes, fieldListWithTypes]);

  useEffect(() => {
    if (!clicked) return;
    setCompletedFields({ index: automationIndex, checked: checkFields() });
    if (!integromatIntegration) return;
    // setClicked(false);
    const jsonParse = automationOutputTypes ? JSON.parse(automationOutputTypes) : {};
    liftingSettings({
      dynamicSettings: {
        keys: null,
      },
      integrationsSettings: {
        _ref_type: 'INTEGRATION',
        _ref_data: integromatIntegration.value,
      },
      outputKeysData: fieldList.map((item: { value: any }) => item.value),
      outputKeysDataWithTypes: {...fieldListWithTypes, ...jsonParse},
    });
  }, [integromatIntegration, connected]);

  const openSetting = () => {
    window.open(`http://${window.location.host}/settings`, '_blank');
  };

  return (
    <Row className="slack-settings mb-4">
      {(connected === null || integrationsLoading) && <StretchLoading />}
      {connected === null || connected ? (
        <Col lg={6}>
          <span className="label secondary-color">Select Webhook</span>
          <span style={{ marginLeft: '10px' }}></span>
          <Select
            value={integromatIntegration ? integromatIntegration.label : ''}
            // manySelect
            options={options}
            fullWidth
            onChange={(selectedItem: any) => {
              if (selectedItem.label === (integromatIntegration ? integromatIntegration.label : '')) return;
              setIntegromatIntegration(selectedItem);
              !clicked && setClicked(true);
              setIsDirty();
            }}
          ></Select>
        </Col>
      ) : (
        <Col lg={6} md={12}>
          <Button name={'Please check the Settings>Integrations to Connect'} onClick={openSetting} />
        </Col>
      )}
    </Row>
  );
};

export default IntegromationSettings;
