import { FC } from 'react';
import { AutomationSettingsFactoryTypesProps } from '../../types';
import SingleSourceSelectPatternSettings from 'automation-settings/single-source-select-settings-pattern';

const ZipAutomation: FC<AutomationSettingsFactoryTypesProps> = ({
  automationSendSettings,
  automationIndex,
  selectedRecipes,
  setAutomationSendSettings,
  setCompletedFields,
  id,
  isSettingsChange,
  setIsDirty,
  automationOutputTypes,
  automationInputTypes,
}) => {
  return (
    <SingleSourceSelectPatternSettings
      id={id}
      automationIndex={automationIndex}
      selectedRecipes={selectedRecipes}
      automationSendSettings={automationSendSettings}
      setCompletedFields={setCompletedFields}
      setAutomationSendSettings={setAutomationSendSettings}
      outputKeys={['_files_compressor']}
      multiSelect
      zip
      isSettingsChange={isSettingsChange}
      setIsDirty={setIsDirty}
      automationOutputTypes={automationOutputTypes}
      automationInputTypes={automationInputTypes}
    />
  );
};

export default ZipAutomation;
