import { FC, memo, ReactElement, ReactNode, useRef, useLayoutEffect, useState } from 'react';

import Button from 'shared-components/atom/button';
import Select from 'shared-components/atom/select';

import { plusIcon } from 'assets/svg-icons';
import './styles.scss';

interface INothingToShowDetailed {
  text?: string;
  fullText?: string;
  dimColorText?: string;
  SVGImage: ReactNode;
  buttonText?: string;
  selectButtonOptions?: ReadonlyArray<{ value: string; label: string; icon?: SVGAElement }>;
  className?: string;
  btnIcon?: ReactElement;
  onClick?: (arg: any) => void;
  secondOnClick?: (arg: any) => void;
  secondButtonText?: string;
  secondBtnIcon?: ReactElement;
}

const NothingToShowDetailed: FC<INothingToShowDetailed> = ({
  onClick,
  buttonText,
  fullText,
  text,
  SVGImage,
  dimColorText,
  selectButtonOptions,
  className,
  btnIcon,
  secondOnClick,
  secondButtonText,
  secondBtnIcon,
}) => {
  const btnRef = useRef<HTMLButtonElement | null>(null);

  const [btnWidth, setBtnWidth] = useState(140);

  useLayoutEffect(() => {
    if (btnRef.current) {
      const rect = btnRef.current.getBoundingClientRect();
      rect.width && setBtnWidth(Math.ceil(rect.width));
    }
  }, [btnRef.current, selectButtonOptions]);

  return (
    <div className="with-center">
      <span className={`svg-wrapper ${className || ''}`}>{SVGImage}</span>
      <span className="empty">{fullText || `Looks like you don't have ${text}`}. </span>
      <p className="empty-list">{dimColorText}</p>
      {buttonText && onClick ? (
        <div className="noting-detailed-btn-wrapper">
          {selectButtonOptions ? (
            <Select
              showBottom
              icon={<Button ref={btnRef} name={buttonText} leftIcon={plusIcon} className="search-btn" />}
              options={selectButtonOptions}
              width={btnWidth}
              className="btn-select"
              onChange={onClick}
            />
          ) : (
            <Button className="first-btn" onClick={onClick} name={buttonText} leftIcon={btnIcon || plusIcon} />
          )}
          {secondOnClick && secondButtonText && (
            <Button outline onClick={secondOnClick} name={secondButtonText} leftIcon={secondBtnIcon || plusIcon} />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default memo(NothingToShowDetailed);
